import ZTag from "@zomentum/atoms/dist/ZTag";
import { ZGrayRow, ZTypography } from "@zomentum/atoms";
import { ETwoToneIcons } from "@zomentum/atoms/dist/ZIcon/interface";
import ZSection from "@zomentum/molecules/dist/ZSection";
import { Col, Skeleton } from "antd";
import React from "react";
import { ISKUProductInfoProps, ISKUProductRowProps } from "./interface";
import ZCustomFields from "@molecules/ZCustomFields";

const { ZText } = ZTypography;

const ZSKUProductRow: React.FC<ISKUProductRowProps> = ({
	isLoading,
	label,
	value
}) => (
	<>
		<Col span={7} className="mb-3">
			<ZText>{label}</ZText>
		</Col>
		<Col span={17} className="mb-3">
			<Skeleton loading={isLoading} active title paragraph={false}>
				{typeof value === "string" ? <ZText>{value}</ZText> : value}
			</Skeleton>
		</Col>
	</>
);

const ZSKUProductInfo: React.FC<ISKUProductInfoProps> = ({
	currentSKUProduct,
	isLoading,
	shouldApplyLeftPaddingForChildren = true
}) => {
	return (
		<ZSection
			heading="Product Info"
			bottomBorder={false}
			isTwoToneIcon
			icon={ETwoToneIcons.WARNING}
			shouldApplyLeftPaddingForChildren={
				shouldApplyLeftPaddingForChildren
			}
		>
			<ZGrayRow>
				<ZSKUProductRow
					label="Name"
					value={currentSKUProduct?.name}
					isLoading={isLoading}
				/>
				<ZSKUProductRow
					label="Type"
					value={currentSKUProduct?.type}
					isLoading={isLoading}
				/>
				<ZSKUProductRow
					label="Product Collection"
					value={
						<div className="gap-1">
							{currentSKUProduct?.marketplace_listings.map(
								listing => (
									<ZTag key={listing.id} color={"blue"}>
										{listing.name}
									</ZTag>
								)
							)}
						</div>
					}
					isLoading={isLoading}
				/>
				<ZSKUProductRow
					label="UOM"
					value={currentSKUProduct?.unit_of_measure}
					isLoading={isLoading}
				/>
				<ZSKUProductRow
					label="Description"
					value={currentSKUProduct?.description}
					isLoading={isLoading}
				/>
				{!!currentSKUProduct?.custom_fields?.length && (
					<ZCustomFields
						valueFlex={{ span: 17 }}
						customFields={currentSKUProduct.custom_fields}
						isLoading={isLoading}
						keyFlex={{ span: 7 }}
						rowRequired={false}
						className="mb-3"
					/>
				)}
			</ZGrayRow>
		</ZSection>
	);
};

export default ZSKUProductInfo;
