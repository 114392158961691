import { currencyFormatter } from "@zomentum/utils/dist/Currency";
import { handleMarkupMargin } from "@/Components/Utils/PricingCalculator";
import { capitalizeAfterUnderscore } from "@zomentum/utils";
import ZTag from "@zomentum/atoms/dist/ZTag";
import { ZTypography, ZCollapse, ZIcon, ZPopover } from "@zomentum/atoms";
import { EIcons } from "@zomentum/atoms/dist/ZIcon/interface";
import { FontWeights } from "@zomentum/atoms/dist/ZTypography";
import { Col, Row } from "antd";
import React from "react";
import { IPricingPlanBarProps } from "./interface";

const { ZText } = ZTypography;

const ZPricingPlanBar: React.FC<IPricingPlanBarProps> = ({
	pricingPlans,
	onPricingPlanDelete,
	onPricingPlanEdit,
	shouldAddSetupPriceToMargin
}) => {
	return (
		<ZCollapse
			className="bg-transparent border-none"
			panels={pricingPlans.map((pricingPlan, index) => {
				const { markup, margin } = handleMarkupMargin(
					pricingPlan.cost_price,
					pricingPlan.sell_price,
					1,
					shouldAddSetupPriceToMargin ?? false,
					pricingPlan.setup_price
				);
				return {
					key: index,
					className:
						"bg-gray-3 mb-4 border border-solid border-gray-6 rounded-md",
					header: (
						<div className="flex items-center justify-between">
							<div className="flex space-x-2 mr-2 truncate">
								<div className="truncate">
									<ZText>{pricingPlan.name}</ZText>
								</div>
								<ZTag className="ml-2">
									{capitalizeAfterUnderscore(
										pricingPlan.billing_period
									)}
								</ZTag>
							</div>
							<div className="flex space-x-4">
								{onPricingPlanEdit && (
									<ZIcon
										name={EIcons.PEN}
										colorVariant={7}
										size="sm"
										className="cursor-pointer"
										onClick={() => onPricingPlanEdit(index)}
									/>
								)}
								{onPricingPlanDelete && (
									<ZIcon
										name={EIcons.TRASH}
										colorVariant={7}
										size="sm"
										className="cursor-pointer"
										onClick={() =>
											onPricingPlanDelete(index)
										}
									/>
								)}
							</div>
						</div>
					),
					render: (
						<div className="px-4 py-2">
							<Row gutter={24} align="middle">
								<Col span={14}>
									<ZText>
										{!!pricingPlan?.description
											? pricingPlan.description
											: "[NO DESCRIPTION]"}
									</ZText>
								</Col>
								<Col offset={1} span={9}>
									<div className="text-right">
										<ZText weight={FontWeights.MEDIUM}>
											{currencyFormatter(
												pricingPlan.sell_price
											)}
										</ZText>
										<ZText> per unit</ZText>
										<ZPopover
											className="ml-2 "
											placement="topRight"
											overlayStyle={{
												width: "fit-content",
												minWidth: "220px"
											}}
											content={
												<div>
													<div className="flex justify-between">
														<p>Cost Price</p>
														<p className="ml-4">{`$${pricingPlan.cost_price}`}</p>
													</div>
													<div className="flex justify-between">
														<p>Markup</p>
														<p className="ml-4">{`${markup?.toFixed(
															2
														)}%`}</p>
													</div>
													<div className="flex justify-between">
														<p>Margin</p>
														<p className="ml-4">{`${margin?.toFixed(
															2
														)}%`}</p>
													</div>
													<div className="flex justify-between">
														<p>Selling Price</p>
														<p className="ml-4">{`${pricingPlan.sell_price}`}</p>
													</div>
													{!!pricingPlan.setup_price && (
														<div className="flex justify-between">
															<p>Setup Price</p>
															<p className="ml-4">{`${pricingPlan.setup_price}`}</p>
														</div>
													)}
												</div>
											}
										>
											<ZIcon
												name={EIcons.INFOCIRCLE}
												colorVariant={7}
												size="sm"
											/>
										</ZPopover>
									</div>
								</Col>
							</Row>
							<Row gutter={24} className="mt-2" align="middle">
								<Col span={6}>
									<ZText>Vendor SKU No</ZText>
								</Col>
								<Col span={18}>
									<ZText>
										{!!pricingPlan?.vendor_sku_no?.length
											? pricingPlan.vendor_sku_no
											: "--"}
									</ZText>
								</Col>
							</Row>
						</div>
					)
				};
			})}
		/>
	);
};

export default ZPricingPlanBar;
