'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var IconsSprite$1 = require('../packages/config/assets/IconsSprite.svg.js');
var _commonjsHelpers = require('./_commonjsHelpers.js');

var IconsSprite = _commonjsHelpers.getCjsExportFromNamespace(IconsSprite$1);

exports["default"] = IconsSprite;
