import { Dispatch, Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";

import { GlobalState } from "@/Reducers/interface";
import {
	Pipeline,
	OpportunityStage,
	DeletePipelineOrStageRequest
} from "@zomentum/contracts/dist/Pipelines";

import {
	getPipelines,
	reorderPipeline,
	deletePipelineOrStage
} from "@/Services/Vendor/Pipeline";

export enum PipelinesActionTypes {
	SHOW_PIPELINES_LOADER = "SHOW_PIPELINES_LOADER",
	HIDE_PIPELINES_LOADER = "HIDE_PIPELINES_LOADER",
	PIPELINE_REQUESTED = "PIPELINE_REQUESTED",
	PIPELINE_SUCCESSFUL = "PIPELINE_SUCCESSFUL",
	PIPELINE_FAILED = "PIPELINE_FAILED",
	PIPELINE_DETAILS_REQUESTED = "PIPELINE_DETAILS_REQUESTED",
	PIPELINE_DETAILS_UPDATED = "PIPELINE_DETAILS_UPDATED",
	PIPELINE_DETAILS_FAILED = "PIPELINE_DETAILS_FAILED",
	SET_CURRENT_PIPELINE = "SET_CURRENT_PIPELINE",
	DELETE_PIPELINE_OR_STAGE_REQUESTED = "DELETE_PIPELINE_OR_STAGE_REQUESTED",
	DELETE_PIPELINE_OR_STAGE_IN_PROGRESS = "DELETE_PIPELINE_OR_STAGE_IN_PROGRESS",
	DELETE_PIPELINE_OR_STAGE_FAILED = "DELETE_PIPELINE_OR_STAGE_FAILED"
}

export type ShowPipelinesLoaderAction =
	Action<PipelinesActionTypes.SHOW_PIPELINES_LOADER>;
export type HidePipelinesLoaderAction =
	Action<PipelinesActionTypes.HIDE_PIPELINES_LOADER>;
export type PipelineRequestedAction =
	Action<PipelinesActionTypes.PIPELINE_REQUESTED>;
export interface PipelineSuccessfulAction
	extends Action<PipelinesActionTypes.PIPELINE_SUCCESSFUL> {
	readonly currentPipeline: Pipeline | null;
}
export type PipelineFailedAction = Action<PipelinesActionTypes.PIPELINE_FAILED>;
export type PipelineDetailsRequestedAction =
	Action<PipelinesActionTypes.PIPELINE_DETAILS_REQUESTED>;
export interface PipelineDetailsUpdatedAction
	extends Action<PipelinesActionTypes.PIPELINE_DETAILS_UPDATED> {
	readonly currentActivePipeline: Pipeline;
}
export type PipelineDetailsFailedAction =
	Action<PipelinesActionTypes.PIPELINE_DETAILS_FAILED>;
export type DeletePipelineOrStageRequestedAction =
	Action<PipelinesActionTypes.DELETE_PIPELINE_OR_STAGE_REQUESTED>;
export type DeletePipelineOrStageInProgressAction =
	Action<PipelinesActionTypes.DELETE_PIPELINE_OR_STAGE_IN_PROGRESS>;
export type DeletePipelineOrStageFailedAction =
	Action<PipelinesActionTypes.DELETE_PIPELINE_OR_STAGE_FAILED>;

export interface SetActivePipelineAction
	extends Action<PipelinesActionTypes.SET_CURRENT_PIPELINE> {
	readonly currentPipeline: Pipeline;
}

export type PipelinesActions =
	| ShowPipelinesLoaderAction
	| HidePipelinesLoaderAction
	| PipelineRequestedAction
	| PipelineSuccessfulAction
	| PipelineFailedAction
	| PipelineDetailsUpdatedAction
	| DeletePipelineOrStageRequestedAction
	| DeletePipelineOrStageInProgressAction
	| DeletePipelineOrStageFailedAction
	| SetActivePipelineAction;

export interface PipelinesDispatch {
	showPipelinesLoader: ActionCreator<
		ThunkAction<
			ShowPipelinesLoaderAction,
			GlobalState,
			null,
			ShowPipelinesLoaderAction
		>
	>;
	hidePipelinesLoader: ActionCreator<
		ThunkAction<
			HidePipelinesLoaderAction,
			GlobalState,
			null,
			HidePipelinesLoaderAction
		>
	>;
	getPipeline: ActionCreator<
		ThunkAction<
			Promise<PipelineSuccessfulAction>,
			GlobalState,
			null,
			PipelineSuccessfulAction
		>
	>;
	updateOpportunityStages: ActionCreator<
		ThunkAction<
			Promise<PipelineDetailsUpdatedAction>,
			GlobalState,
			OpportunityStage[],
			PipelineDetailsUpdatedAction
		>
	>;
	addOpportunityStage: ActionCreator<
		ThunkAction<
			Promise<PipelineDetailsUpdatedAction>,
			GlobalState,
			string | undefined,
			PipelineDetailsUpdatedAction
		>
	>;
	deletePipelineOrStage: ActionCreator<
		ThunkAction<
			Promise<DeletePipelineOrStageInProgressAction>,
			GlobalState,
			DeletePipelineOrStageRequest,
			DeletePipelineOrStageInProgressAction
		>
	>;
	setActivePipeline: ActionCreator<
		ThunkAction<
			SetActivePipelineAction,
			GlobalState,
			Pipeline,
			SetActivePipelineAction
		>
	>;
}

export const PipelinesActionCreators: PipelinesDispatch = {
	showPipelinesLoader: () => {
		return (dispatch: Dispatch): ShowPipelinesLoaderAction => {
			const showPipelinesLoaderAction: ShowPipelinesLoaderAction = {
				type: PipelinesActionTypes.SHOW_PIPELINES_LOADER
			};
			return dispatch(showPipelinesLoaderAction);
		};
	},
	hidePipelinesLoader: () => {
		return (dispatch: Dispatch): HidePipelinesLoaderAction => {
			const hidePipelinesLoaderAction: HidePipelinesLoaderAction = {
				type: PipelinesActionTypes.HIDE_PIPELINES_LOADER
			};
			return dispatch(hidePipelinesLoaderAction);
		};
	},
	setActivePipeline: (currentActivePipeline: Pipeline) => {
		return (dispatch: Dispatch): SetActivePipelineAction => {
			const setPipelineAction: SetActivePipelineAction = {
				type: PipelinesActionTypes.SET_CURRENT_PIPELINE,
				currentPipeline: currentActivePipeline
			};
			return dispatch(setPipelineAction);
		};
	},
	getPipeline: () => {
		return async (
			dispatch: Dispatch
		): Promise<PipelineSuccessfulAction> => {
			const pipelinesRequestedAction: PipelineRequestedAction = {
				type: PipelinesActionTypes.PIPELINE_REQUESTED
			};
			dispatch(pipelinesRequestedAction);
			try {
				const pipelinesResponse = await getPipelines();
				const pipeline =
					pipelinesResponse.data.find(
						pipeline => pipeline.is_default
					) ?? null;
				const pipelinesSuccessfulAction: PipelineSuccessfulAction = {
					type: PipelinesActionTypes.PIPELINE_SUCCESSFUL,
					currentPipeline: pipeline
				};
				return dispatch(pipelinesSuccessfulAction);
			} catch (error) {
				const pipelinesFailedAction: PipelineFailedAction = {
					type: PipelinesActionTypes.PIPELINE_FAILED
				};
				dispatch(pipelinesFailedAction);

				return Promise.reject(error);
			}
		};
	},
	updateOpportunityStages: (
		currentPipeline: Pipeline,
		opportunityStages: OpportunityStage[]
	) => {
		return async (
			dispatch: Dispatch
		): Promise<PipelineDetailsUpdatedAction> => {
			if (!currentPipeline) {
				throw new Error("there is no current pipeline");
			}
			try {
				const pipelineDetailsRequestedAction: PipelineDetailsRequestedAction =
					{
						type: PipelinesActionTypes.PIPELINE_DETAILS_REQUESTED
					};
				dispatch(pipelineDetailsRequestedAction);
				const pipelineDetailsUpdatedAction: PipelineDetailsUpdatedAction =
					{
						type: PipelinesActionTypes.PIPELINE_DETAILS_UPDATED,
						currentActivePipeline: {
							...currentPipeline,
							opportunity_stages: opportunityStages
						}
					};
				await reorderPipeline({
					opportunityStages,
					pipelineId: currentPipeline.id
				});
				return dispatch(pipelineDetailsUpdatedAction);
			} catch (error) {
				const pipelineDetailsFailedAction: PipelineDetailsFailedAction =
					{
						type: PipelinesActionTypes.PIPELINE_DETAILS_FAILED
					};
				dispatch(pipelineDetailsFailedAction);

				return Promise.reject(error);
			}
		};
	},
	addOpportunityStage: (
		currentPipeline: Pipeline,
		defaultOpportunityStageName: string | undefined
	) => {
		return async (
			dispatch: Dispatch
		): Promise<PipelineDetailsUpdatedAction> => {
			const currPipeline = currentPipeline;

			if (!currPipeline) {
				throw new Error("there is no current pipeline");
			}

			const newStage = {
				stage_name: defaultOpportunityStageName || "New Stage"
			};
			const newOpportunityStages: (
				| OpportunityStage
				| Omit<OpportunityStage, "id">
			)[] = [...currPipeline.opportunity_stages];
			newOpportunityStages.push(newStage);

			try {
				const pipelineDetailsRequestedAction: PipelineDetailsRequestedAction =
					{
						type: PipelinesActionTypes.PIPELINE_DETAILS_REQUESTED
					};
				dispatch(pipelineDetailsRequestedAction);

				const pipelineResponse = await reorderPipeline({
					opportunityStages: newOpportunityStages,
					pipelineId: currPipeline.id
				});

				const pipelineDetailsUpdatedAction: PipelineDetailsUpdatedAction =
					{
						type: PipelinesActionTypes.PIPELINE_DETAILS_UPDATED,
						currentActivePipeline: pipelineResponse
					};

				return dispatch(pipelineDetailsUpdatedAction);
			} catch (error) {
				const pipelineDetailsFailedAction: PipelineDetailsFailedAction =
					{
						type: PipelinesActionTypes.PIPELINE_DETAILS_FAILED
					};
				dispatch(pipelineDetailsFailedAction);

				return Promise.reject(error);
			}
		};
	},
	// Delete an existing pipeline or stage
	deletePipelineOrStage: (
		deletePipelineOrStageRequest: DeletePipelineOrStageRequest
	) => {
		return async (
			dispatch: Dispatch
		): Promise<DeletePipelineOrStageInProgressAction> => {
			const deletePipelineRequestedAction: DeletePipelineOrStageRequestedAction =
				{
					type: PipelinesActionTypes.DELETE_PIPELINE_OR_STAGE_REQUESTED
				};
			dispatch(deletePipelineRequestedAction);
			try {
				await deletePipelineOrStage(deletePipelineOrStageRequest);
				const deletePipelineOrStageInProgressAction: DeletePipelineOrStageInProgressAction =
					{
						type: PipelinesActionTypes.DELETE_PIPELINE_OR_STAGE_IN_PROGRESS
					};
				return dispatch(deletePipelineOrStageInProgressAction);
			} catch (error) {
				const deletePipelineOrStageFailedAction: DeletePipelineOrStageFailedAction =
					{
						type: PipelinesActionTypes.DELETE_PIPELINE_OR_STAGE_FAILED
					};
				dispatch(deletePipelineOrStageFailedAction);

				return Promise.reject(error);
			}
		};
	}
};

export default PipelinesActionCreators;
