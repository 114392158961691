'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.EIconSizes = void 0;
(function (EIconSizes) {
  EIconSizes["large"] = "lg";
  EIconSizes["middle"] = "md";
  EIconSizes["small"] = "sm";
})(exports.EIconSizes || (exports.EIconSizes = {}));
