'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.EInputNumberWidths = void 0;
(function (EInputNumberWidths) {
  EInputNumberWidths["sm"] = "sm";
  EInputNumberWidths["md"] = "md";
  EInputNumberWidths["lg"] = "lg";
  EInputNumberWidths["xl"] = "xl";
  EInputNumberWidths["full"] = "full";
})(exports.EInputNumberWidths || (exports.EInputNumberWidths = {}));
