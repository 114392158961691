'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var common = require('../../common.js');
var index = require('../User/index.js');

exports.VendorPartnerStatus = void 0;
(function (VendorPartnerStatus) {
  VendorPartnerStatus["DRAFT"] = "draft";
  VendorPartnerStatus["INVITED"] = "invited";
  VendorPartnerStatus["ACCEPTED"] = "accepted";
  VendorPartnerStatus["REJECTED"] = "rejected";
  VendorPartnerStatus["APPLIED"] = "applied";
  VendorPartnerStatus["SUSPENDED"] = "suspended";
  VendorPartnerStatus["WITHDRAWN"] = "request_withdrawn";
})(exports.VendorPartnerStatus || (exports.VendorPartnerStatus = {}));
var InviteDetails = /** @class */function () {
  function InviteDetails() {
    this.accepted_at = new Date();
    this.initiated_at = new Date();
    this.initiated_by = "";
    this.reminded_at = new Array();
  }
  return InviteDetails;
}();
var bulkUpdatePartnerFormValues = /** @class */function () {
  function bulkUpdatePartnerFormValues() {
    this.custom_field_form_values = {};
  }
  return bulkUpdatePartnerFormValues;
}();
var VendorPartnerManager = /** @class */function () {
  function VendorPartnerManager() {
    this.created = new Date();
    this.deleted = false;
    this.email = "";
    this.id = "";
    this.is_disabled = false;
    this.name = new common.Name();
    this.phone = "";
    this.role = index.VendorUserRole.VendorPrimaryRole;
    this.roles = [];
    this.updated = new Date();
    this.user_settings = new index.VendorUserSettings();
    this.vendor_company_id = "";
  }
  return VendorPartnerManager;
}();
var VendorPartner = /** @class */function () {
  function VendorPartner(name, subDomain, linkedinUrl, website, facebookUrl, twitterUrl, contacts, payment_source_added, vendor_program_invite_details, last_active_details) {
    if (contacts === void 0) {
      contacts = new Array();
    }
    this.id = "";
    this.created = new Date();
    this.updated = new Date();
    this.deleted = false;
    this.name = "";
    this.sub_domain = "";
    this.vendor_company_id = "";
    this.vendor_user_id = "";
    this.status = exports.VendorPartnerStatus.DRAFT;
    this.contacts = new Array();
    this.suspend_reason = "";
    this.suspended_time = new Date();
    this.partner_type = "";
    this.primary_contact = "";
    this.owner_user = new index.VendorUserData();
    this.primary_email = "";
    this.primary_phone = "";
    this.country = null;
    this.currency = null;
    this.state = null;
    this.vendor_program_invite_details = new InviteDetails();
    this.partner_metadata_id = "";
    this.partner_manager = null;
    this.tier_id = null;
    this.tier_detail = null;
    this.revenue = 0;
    this.won_opportunities = 0;
    this.last_active_details = null;
    this.name = name;
    this.sub_domain = subDomain;
    this.linkedinUrl = linkedinUrl;
    this.website = website;
    this.facebookUrl = facebookUrl;
    this.twitterUrl = twitterUrl;
    this.contacts = contacts;
    this.payment_source_added = payment_source_added;
    this.vendor_program_invite_details = vendor_program_invite_details;
    this.last_active_details = last_active_details;
  }
  return VendorPartner;
}();
var VendorPartnerContact = /** @class */function () {
  function VendorPartnerContact() {
    this.id = "";
    this.name = "";
    this.is_primary = false;
    this.email = "";
  }
  return VendorPartnerContact;
}();
var VendorPartnerCount = /** @class */function () {
  function VendorPartnerCount() {
    this.all = 0;
  }
  return VendorPartnerCount;
}();
var VendorPartnerResponse = /** @class */function () {
  function VendorPartnerResponse() {
    this.data = new Array();
    this.filtered_count_map = new VendorPartnerCount();
  }
  return VendorPartnerResponse;
}();
var InviteVendorPartnerRequest = /** @class */function () {
  function InviteVendorPartnerRequest(full_name, tier_id, email, company_name, country, state, phone, custom_fields, partner_manager_id) {
    this.email = "";
    this.full_name = "";
    this.company_name = "";
    this.country = "";
    this.state = "";
    this.phone = "";
    this.partner_manager_id = "";
    this.custom_fields = new Array();
    this.tier_id = "";
    this.email = email;
    this.full_name = full_name;
    this.company_name = company_name;
    this.custom_fields = custom_fields;
    this.country = country;
    this.state = state;
    this.phone = phone;
    this.partner_manager_id = partner_manager_id;
    this.tier_id = tier_id;
  }
  return InviteVendorPartnerRequest;
}();
var EditVendorPartnerRequest = /** @class */function () {
  function EditVendorPartnerRequest(partner_id, company_name, owner_user, partner_manager_id, phone, state, country, tier_id, custom_fields, status) {
    if (custom_fields === void 0) {
      custom_fields = new Array();
    }
    this.custom_fields = new Array();
    this.partner_id = partner_id;
    this.company_name = company_name;
    this.owner_user = owner_user;
    this.partner_manager_id = partner_manager_id;
    this.phone = phone;
    this.state = state;
    this.country = country;
    this.tier_id = tier_id;
    this.custom_fields = custom_fields;
    this.status = status;
  }
  return EditVendorPartnerRequest;
}();
var VendorPartnerInviteContact = /** @class */function () {
  function VendorPartnerInviteContact() {
    this.name = "";
    this.phone = "";
    this.email = "";
    this.id = "";
  }
  return VendorPartnerInviteContact;
}();
var VendorPartnerInviteDetails = /** @class */function () {
  function VendorPartnerInviteDetails() {
    this.name = "";
    this.phone = "";
    this.logo = "";
    this.contacts = new Array();
    this.is_existing_partner = false;
  }
  return VendorPartnerInviteDetails;
}();
var VendorInviteDataRequest = /** @class */function () {
  function VendorInviteDataRequest(email) {
    this.email = "";
    this.email = email;
  }
  return VendorInviteDataRequest;
}();
var GetVendorPartnersRequest = /** @class */function () {
  function GetVendorPartnersRequest(filters, keywords, included_child_entities, limit) {
    this.keywords = null;
    this.limit = 0;
    this.included_child_entities = new Array();
    this.filters = new Array();
    this.filters = filters || [];
    this.keywords = keywords || "";
    this.included_child_entities = included_child_entities || [];
    this.limit = limit || 100;
  }
  return GetVendorPartnersRequest;
}();

exports.EditVendorPartnerRequest = EditVendorPartnerRequest;
exports.GetVendorPartnersRequest = GetVendorPartnersRequest;
exports.InviteDetails = InviteDetails;
exports.InviteVendorPartnerRequest = InviteVendorPartnerRequest;
exports.VendorInviteDataRequest = VendorInviteDataRequest;
exports.VendorPartner = VendorPartner;
exports.VendorPartnerContact = VendorPartnerContact;
exports.VendorPartnerCount = VendorPartnerCount;
exports.VendorPartnerInviteContact = VendorPartnerInviteContact;
exports.VendorPartnerInviteDetails = VendorPartnerInviteDetails;
exports.VendorPartnerManager = VendorPartnerManager;
exports.VendorPartnerResponse = VendorPartnerResponse;
exports.bulkUpdatePartnerFormValues = bulkUpdatePartnerFormValues;
