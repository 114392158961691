import React, { ReactNode, useEffect, useState } from "react";
import {
	CustomFiltersMetaData,
	CustomViewFilterCriteria
} from "@zomentum/contracts/dist/UI";
import { LabeledValue } from "antd/lib/select";
import moment from "moment";
import { DefaultTimestampFormat, ZomentumEntities } from "@zomentum/contracts";
import {
	SalesActivityAutomationActionUpdatableFieldValue,
	SalesActivityAutomationMetadataFilterOperator,
	SalesActivityAutomationActionUpdatableFieldValueType
} from "@zomentum/contracts/dist/Others/SalesActivity";
import { Dictionary } from "@zomentum/contracts/dist/Utils";
import { capitalizeFirstLetter } from "@zomentum/utils";
import {
	GlobalSearchEntityType,
	GlobalSearchRequest
} from "@zomentum/contracts/dist/Others/GlobalSearch";
import { getGlobalSearch } from "@zomentum/utils/dist/Service/GlobalSearch";
import debounce from "lodash/debounce";
import { IOptionProps } from "@zomentum/atoms/dist/ZSelect/interface";
import ZFilterMenu from "./ZFilterMenu";
import {
	IFilterMenuProps,
	IFilterMenuUIProps,
	IEntityFilterMap,
	TFilterOperator,
	TFilterValue
} from "./interface";
import getFieldForValueConstructor from "./ZFieldForValue";
import ZMessage from "@zomentum/molecules/dist/ZMessage";
import { convertSearchEntityTypeToOption } from "@zomentum/utils";
import { useFeatureFlag } from "@zomentum/hooks/dist/hooks/useFeature";

const FilterMenu: React.FC<IFilterMenuProps> = ({
	taxCategories = [],
	taxRegions = [],
	...props
}) => {
	const useAtlasSearch = useFeatureFlag("use_atlas_search");
	const [isVisible, setIsVisible] = useState<boolean>(false);

	const [selectedMetaFilter, setSelectedMetaFilter] =
		useState<CustomFiltersMetaData | null>(null);

	const [currentActiveFilter, setCurrentActiveFilter] =
		useState<CustomViewFilterCriteria | null>(null);

	const [fieldKeyword, setFieldKeyword] = useState<string>("");
	const [operatorKeyword, setOperatorKeyword] = useState<string>("");
	const [dynamicSearchResult, setDynamicSearchResult] = useState<
		SalesActivityAutomationActionUpdatableFieldValue<string>[]
	>([]);
	const [dynamicSearchTerm, setDynamicSearchTerm] = useState<string>("");
	const [dynamicDataLoader, setDynamicDataLoader] = useState<boolean>(false);

	const handleFieldKeywordChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setFieldKeyword(event.target.value);
	};
	const handleOperatorKeywordChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setOperatorKeyword(event.target.value);
	};

	const onVisibleChange = (currentVisibility: boolean) => {
		setIsVisible(currentVisibility);
		if (!currentVisibility) {
			setSelectedMetaFilter(null);
			setCurrentActiveFilter(null);
			setFieldKeyword("");
			setOperatorKeyword("");
			setDynamicSearchResult([]);
			setDynamicSearchTerm("");
		}
	};

	const removeSelectedFilterField = () => {
		const updatedCurrentActiveFilter: CustomViewFilterCriteria = {
			field_display_name: null,
			field_name: null,
			filter_entity_type: null,
			values: new Array<TFilterValue>(),
			filter_operator: null,
			linked_entity_type: null
		};

		setCurrentActiveFilter(updatedCurrentActiveFilter);
	};

	const removeSelectedOperator = () => {
		if (!currentActiveFilter) {
			console.error("No filter field selected");
			return;
		}

		const updatedCurrentActiveFilter: CustomViewFilterCriteria = {
			...currentActiveFilter,
			filter_operator: null,
			values: []
		};

		setCurrentActiveFilter(updatedCurrentActiveFilter);
	};

	const handleFilterFieldSelect = (selectedFilter: CustomFiltersMetaData) => {
		const updatedCurrentActiveFilter: CustomViewFilterCriteria = {
			field_display_name: selectedFilter.field_display_name,
			field_name: selectedFilter.field_name,
			filter_entity_type: selectedFilter.filter_entity_type,
			values: new Array<
				SalesActivityAutomationActionUpdatableFieldValue<string>
			>(),
			filter_operator: null,
			linked_entity_type: selectedFilter.linked_entity_type,
			linked_entity_types: selectedFilter.linked_entity_types
		};

		setFieldKeyword("");
		setCurrentActiveFilter(updatedCurrentActiveFilter);
		setSelectedMetaFilter(selectedFilter);
	};

	const handleOperatorSelect = (selectedOperator: TFilterOperator) => {
		if (!currentActiveFilter) {
			console.error("No filter field selected");
			return;
		}

		const updatedCurrentActiveFilter: CustomViewFilterCriteria = {
			...currentActiveFilter,
			filter_operator: selectedOperator
		};

		setFieldKeyword("");
		setCurrentActiveFilter(updatedCurrentActiveFilter);
	};

	const updateCurrentFilterValue = (values: TFilterValue[]): void => {
		if (!currentActiveFilter) {
			console.error("No filter selected");
			return;
		}

		const updatedFilter: CustomViewFilterCriteria = {
			...currentActiveFilter,
			values: [...values]
		};

		setCurrentActiveFilter(updatedFilter);
	};

	const handleCurrentFilterSelectArrayValue = (values: LabeledValue[]) => {
		const updatedValue: TFilterValue[] = values.map(value => ({
			display_value: value.label?.toString() ?? "",
			id_value: value.key || "",
			value_type:
				SalesActivityAutomationActionUpdatableFieldValueType.Fixed
		}));
		updateCurrentFilterValue(updatedValue);
	};

	const handleCurrentFilterSelectValue = (value: LabeledValue) => {
		const updatedValue: TFilterValue[] = [
			{
				display_value: value.label?.toString() ?? "",
				id_value: value.key || "",
				value_type:
					SalesActivityAutomationActionUpdatableFieldValueType.Fixed
			}
		];
		updateCurrentFilterValue(updatedValue);
	};

	const handleCurrentFilterValue = (
		value?: string | number | null,
		isDate?: boolean
	) => {
		if (value !== 0 && !value) {
			updateCurrentFilterValue([]);
			return;
		}

		const displayValue = isDate ? value + " days" : value + "";

		const updatedValue: TFilterValue[] = [
			{
				display_value: displayValue,
				id_value: value + "",
				value_type:
					SalesActivityAutomationActionUpdatableFieldValueType.Fixed
			}
		];
		updateCurrentFilterValue(updatedValue);
	};

	const handleCurrentFilterDateValue = (
		date: moment.Moment | null,
		key?: SalesActivityAutomationMetadataFilterOperator | null
	) => {
		if (!currentActiveFilter) {
			console.error("No filter selected");
			return;
		}

		if (!date) {
			updateCurrentFilterValue([]);
			return;
		}

		const updatedValue: TFilterValue[] = [
			{
				display_value: moment(date).format(DefaultTimestampFormat),
				id_value: date?.toISOString(),
				value_type:
					SalesActivityAutomationActionUpdatableFieldValueType.Fixed
			}
		];

		if (key) {
			if (
				key ===
				SalesActivityAutomationMetadataFilterOperator.IsBeforeFixedDate
			) {
				updatedValue[0].id_value = date.endOf("day").toISOString();
			}
			if (
				key ===
				SalesActivityAutomationMetadataFilterOperator.IsAfterFixedDate
			) {
				updatedValue[0].id_value = date.startOf("day").toISOString();
			}
		}

		updateCurrentFilterValue(updatedValue);
	};

	const validSearchFilterList = props.filterOptions?.filter(filter =>
		filter.field_display_name
			?.toLowerCase()
			.includes(fieldKeyword.toLowerCase())
	);

	const filtersMap = validSearchFilterList?.reduce(
		(
			finalFiltersMap: IEntityFilterMap[],
			current: CustomFiltersMetaData
		) => {
			const index = finalFiltersMap.findIndex(
				filter => filter.entity === current.filter_entity_type
			);

			if (current.filter_entity_type) {
				if (index > -1) {
					const newFilters: CustomFiltersMetaData[] = [
						...finalFiltersMap[index].filters,
						current
					];
					finalFiltersMap[index] = {
						entity: finalFiltersMap[index].entity,
						filters: [...newFilters]
					};
				} else {
					const newData: IEntityFilterMap = {
						entity: current.filter_entity_type,
						filters: [current]
					};
					finalFiltersMap.push(newData);
				}
			}

			return finalFiltersMap;
		},
		[]
	);

	const filteredAllowedOperators =
		selectedMetaFilter?.valid_filter_operators?.filter(operator =>
			operator.display_value
				?.toLowerCase()
				.includes(operatorKeyword.toLowerCase())
		) ?? [];

	const handleSearch = debounce((value: string) => {
		setDynamicSearchTerm(value);
		if (value) {
			if (value.length >= 3) {
				handleRealtimeFetch(value);
			} else {
				setDynamicSearchResult([]);
			}
		} else {
			setDynamicSearchResult([]);
			setDynamicSearchTerm("");
		}
	}, 300);

	const handleRealtimeFetch = async (searchTerm: string) => {
		try {
			setDynamicDataLoader(true);
			const fieldName = currentActiveFilter?.field_name;
			const linkedEntityType = currentActiveFilter?.linked_entity_type;
			const linkedEntities = currentActiveFilter?.linked_entity_types;

			if (!fieldName) {
				throw new Error("No selected field");
			}

			if (!linkedEntityType) {
				throw new Error("No linked entity");
			}

			const globalSearchEntities: GlobalSearchEntityType[] = [];
			if (linkedEntities && linkedEntities.length > 1) {
				linkedEntities.map(linkedEntityType => {
					globalSearchEntities.push(
						linkedEntityType === ZomentumEntities.Opportunity
							? GlobalSearchEntityType.Opportunity
							: linkedEntityType === ZomentumEntities.Client
							? GlobalSearchEntityType.Client
							: linkedEntityType ===
							  ZomentumEntities.ClientContact
							? GlobalSearchEntityType.ClientContact
							: linkedEntityType ===
							  ZomentumEntities.VendorPartner
							? GlobalSearchEntityType.VendorPartner
							: linkedEntityType === ZomentumEntities.VendorClient
							? GlobalSearchEntityType.VendorClient
							: GlobalSearchEntityType.VendorClient
					);
				});
			}

			const globalSearchEntity: GlobalSearchEntityType | null =
				linkedEntityType === ZomentumEntities.Opportunity
					? GlobalSearchEntityType.Opportunity
					: linkedEntityType === ZomentumEntities.Client
					? GlobalSearchEntityType.Client
					: linkedEntityType === ZomentumEntities.ClientContact
					? GlobalSearchEntityType.ClientContact
					: linkedEntityType === ZomentumEntities.VendorPartner
					? GlobalSearchEntityType.VendorPartner
					: linkedEntityType === ZomentumEntities.VendorClient
					? GlobalSearchEntityType.VendorClient
					: null;

			// if there are any more fields for dynamic search,
			// then create an array for them and change the GlobalSearchEntityType accordingly
			// write the converter function as well if not already present

			if (!globalSearchEntity) {
				throw new Error("Invalid filter for dynamic search");
			}

			const globalSearchRequest = new GlobalSearchRequest(
				searchTerm,
				globalSearchEntities.length
					? globalSearchEntities
					: [globalSearchEntity],
				50,
				null,
				useAtlasSearch
			);

			const globalSearchResponse = await getGlobalSearch(
				globalSearchRequest
			);

			const options =
				globalSearchResponse?.data.map(
					convertSearchEntityTypeToOption
				) || [];
			setDynamicSearchResult(options);
		} catch (error) {
			console.error(error);
			typeof error === "string" && ZMessage.error(error);
		} finally {
			setDynamicDataLoader(false);
		}
	};

	const initialLabelvalue = (
		value: SalesActivityAutomationActionUpdatableFieldValue<string> | null
	): ReactNode => {
		return value?.display_value ?? "";
	};

	const getSelectOptions = (
		values:
			| SalesActivityAutomationActionUpdatableFieldValue<string>[]
			| null
	): IOptionProps[] => {
		const options = values?.map(value => {
			return {
				value: value.id_value ?? "",
				name: capitalizeFirstLetter(value.display_value)
			};
		});

		return options ?? [];
	};

	const getSelectOptionGroups = (
		refactoredOptions: Dictionary<
			SalesActivityAutomationActionUpdatableFieldValue<string>[]
		>
	) => {
		const optGroups = Object.entries(refactoredOptions).map(
			([optionGroup]) => {
				return {
					label: optionGroup,
					selectOptions: getSelectOptions(
						refactoredOptions[optionGroup]
					)
				};
			}
		);

		return optGroups;
	};

	const getOptionsProps = (
		refactoredOptions: Dictionary<
			SalesActivityAutomationActionUpdatableFieldValue<string>[]
		> | null,
		values:
			| SalesActivityAutomationActionUpdatableFieldValue<string>[]
			| null
	) => {
		return refactoredOptions
			? {
					isOptionsGroup: true,
					optionsGroup: getSelectOptionGroups(refactoredOptions)
			  }
			: {
					selectOptions: getSelectOptions(values)
			  };
	};

	const onFilterPopoverBackButtonClick = () => {
		if (!currentActiveFilter) {
			setIsVisible(false);
			setSelectedMetaFilter(null);
			setCurrentActiveFilter(null);
			setFieldKeyword("");
			setOperatorKeyword("");

			return;
		}

		if (!!currentActiveFilter?.values.length) {
			const updatedCurrentActiveFilter: CustomViewFilterCriteria = {
				...currentActiveFilter,
				values: []
			};

			setCurrentActiveFilter(updatedCurrentActiveFilter);
		} else if (!!currentActiveFilter?.filter_operator) {
			const updatedCurrentActiveFilter: CustomViewFilterCriteria = {
				...currentActiveFilter,
				filter_operator: null,
				values: []
			};

			setCurrentActiveFilter(updatedCurrentActiveFilter);
		} else if (!!currentActiveFilter?.field_name) {
			const updatedCurrentActiveFilter: CustomViewFilterCriteria = {
				field_display_name: null,
				field_name: null,
				filter_entity_type: null,
				values: new Array<TFilterValue>(),
				filter_operator: null,
				linked_entity_type: null
			};

			setCurrentActiveFilter(updatedCurrentActiveFilter);
		} else {
			setIsVisible(false);
		}
	};

	const handleFilterSubmit = () => {
		if (currentActiveFilter) {
			props.onSubmit(currentActiveFilter, props.index);
			setIsVisible(false);
			setSelectedMetaFilter(null);
			setCurrentActiveFilter(null);
			setFieldKeyword("");
			setOperatorKeyword("");
			setDynamicSearchResult([]);
			setDynamicSearchTerm("");
		}
	};

	const showOperatorList =
		!!currentActiveFilter?.field_display_name &&
		!currentActiveFilter?.filter_operator &&
		!!selectedMetaFilter?.valid_filter_operators;

	const showValueField =
		!!currentActiveFilter?.field_display_name &&
		!!currentActiveFilter?.filter_operator?.display_value &&
		currentActiveFilter?.filter_operator?.id_value !==
			SalesActivityAutomationMetadataFilterOperator.IsEmpty &&
		currentActiveFilter?.filter_operator?.id_value !==
			SalesActivityAutomationMetadataFilterOperator.IsNotEmpty;

	const shouldDisableSubmitButton =
		!currentActiveFilter?.field_display_name ||
		!currentActiveFilter?.filter_operator ||
		(currentActiveFilter.filter_operator.id_value ===
			SalesActivityAutomationMetadataFilterOperator.IsEmpty ||
		currentActiveFilter.filter_operator.id_value ===
			SalesActivityAutomationMetadataFilterOperator.IsNotEmpty
			? false
			: !!currentActiveFilter?.values[0]
			? !currentActiveFilter?.values[0].id_value
			: true);

	useEffect(() => {
		if (isVisible) {
			setSelectedMetaFilter(
				props.filterOptions?.find(
					filter =>
						filter.field_name === props.selectedFilter?.field_name
				) ?? null
			);
			setCurrentActiveFilter(props.selectedFilter);
		} else {
			props.closePopoverCallback();
		}
	}, [isVisible]);

	const getFieldForValue = () =>
		getFieldForValueConstructor({
			taxCategories,
			taxRegions,
			currentActiveFilter,
			initialLabelvalue,
			selectedMetaFilter,
			handleCurrentFilterSelectArrayValue,
			handleCurrentFilterValue,
			dynamicDataLoader,
			dynamicSearchTerm,
			dynamicSearchResult,
			handleSearch,
			getOptionsProps,
			handleCurrentFilterSelectValue,
			handleCurrentFilterDateValue
		});

	const ZFilterMenuProps: IFilterMenuUIProps = {
		isVisible,
		onVisibleChange,
		currentActiveFilter,
		fieldKeyword,
		handleFieldKeywordChange,
		removeSelectedFilterField,
		removeSelectedOperator,
		filtersMap,
		handleFilterFieldSelect,
		showOperatorList,
		operatorKeyword,
		handleOperatorKeywordChange,
		filteredAllowedOperators,
		handleOperatorSelect,
		showValueField,
		getFieldForValue,
		onFilterPopoverBackButtonClick,
		shouldDisableSubmitButton,
		handleFilterSubmit,
		selectedFilter: props.selectedFilter
	};

	return <ZFilterMenu {...ZFilterMenuProps}>{props.children}</ZFilterMenu>;
};

export default FilterMenu;
