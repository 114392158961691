'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var MergeTagListToSkip = ["Receiver"];
var MergeTagChildrenListToSkip = ["Partner.Url", "VendorOpportunity.Url", "Partner.Password"];
exports.EMergeTagStatus = void 0;
(function (EMergeTagStatus) {
  EMergeTagStatus["SUCCESS"] = "sucess";
  EMergeTagStatus["TWOMERGETAGS"] = "2mergetags";
  EMergeTagStatus["ONEMERGETAGWITHNUMBER"] = "1mergetagandnumber";
})(exports.EMergeTagStatus || (exports.EMergeTagStatus = {}));

exports.MergeTagChildrenListToSkip = MergeTagChildrenListToSkip;
exports.MergeTagListToSkip = MergeTagListToSkip;
