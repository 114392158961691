import {
	filterOutFormulaFields,
	getCurrencySettings,
	processCustomFieldFormValues,
	shortenText
} from "@zomentum/utils";
import { ZLostTag, ZTooltip, ZWonTag } from "@zomentum/atoms";
import ZTypography from "@zomentum/atoms/dist/ZTypography";
import {
	CustomField,
	CustomFieldType
} from "@zomentum/contracts/dist/Settings";
import useClientAndContactsDrawer from "@/Hooks/useClientAndContactsDrawer";
import { GlobalState } from "@/Reducers/interface";
import {
	addVendorOpportunity,
	editVendorOpportunity
} from "@/Services/Vendor/Opportunity";
import ZUserSelect from "@organisms/ZUserSelect";
import { EInputNumberWidths } from "@zomentum/atoms/dist/ZInputNumber/interface";
import { OpportunitySource, ZomentumRoutes } from "@zomentum/contracts";
import {
	VendorOpportunityFormValues,
	VendorOpportunityRequest
} from "@zomentum/contracts/dist/Vendor/Opportunity";
import { ZMessage } from "@zomentum/molecules";
import ZDrawer from "@zomentum/molecules/dist/ZDrawer";
import { ZForm } from "@zomentum/organisms";
import {
	EWidgets,
	IFormFieldProps,
	IFormProps,
	IFormSectionProps
} from "@zomentum/organisms/dist/ZForm/interface";
import { useForm } from "antd/lib/form/Form";
import moment, { Moment } from "moment";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { IOpportunityDrawerProps, OpportunityDrawerMode } from "./interface";
import { getInitialValueForCustomField } from "@/Components/UI/CustomFields";
import { getCustomFieldProps, getWidget } from "@zomentum/organisms/dist/ZForm";
import useVendorPartnerDrawer from "@/Hooks/useVendorPartnerDrawer";
import { useComponentDidUpdate } from "@zomentum/hooks";
import { ChildEntityHydrationParams } from "@zomentum/contracts/dist/Utils";
import FormBuilder from "antd-form-builder";
import PipelinesActionCreators from "@/Actions/Vendor/Pipelines";
import useDispatch from "@/Components/Utils/useDispatch";

const OpportunityDrawer: React.FC<IOpportunityDrawerProps> = ({
	defaultOpportunityStage,
	visible,
	onClose,
	mode,
	currentActiveOpportunity,
	vendorEndClientId,
	isPreviewFromClientsPage = false
}) => {
	const forceUpdate = FormBuilder.useForceUpdate();
	const [form] = useForm<VendorOpportunityFormValues>();
	const history = useHistory();

	const {
		vendorUser,
		currentActivePartner,
		currentActivePipeline,
		customFieldListVendorOpportunity
	} = useSelector((state: GlobalState) => ({
		vendorUser: state.vendorUser.vendorUserData,
		currentActivePartner: state.vendorPartner.currentActivePartner,
		currentActivePipeline: state.vendorPipelines.currentActivePipeline,
		customFieldListVendorOpportunity: filterOutFormulaFields(
			state.customFields.customFieldList.vendor_opportunity
		)
	}));
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [customFieldList, setCustomFieldList] = useState<Array<CustomField>>(
		[]
	);
	const [systemFieldList, setSystemFieldList] = useState<Array<CustomField>>(
		[]
	);

	const shouldSharePartnerInitialValue =
		currentActiveOpportunity?.partner_company_id
			? !!currentActiveOpportunity?.partner_company_id.length
			: currentActivePartner?.id
			? !!currentActivePartner?.id.length
			: false;
	const initialPartnerCompanyId =
		currentActiveOpportunity?.partner_company_id ??
		currentActivePartner?.id ??
		undefined;

	const [shouldSharePartner, setShouldSharePartner] = useState(
		shouldSharePartnerInitialValue
	);

	const isEditMode = mode === OpportunityDrawerMode.EDIT;

	const {
		renderClientSelectField,
		renderLinkedContactsField,
		renderPrimaryContactField,
		clientAndContactDrawers,
		loading: clientLoading,
		handleResetClient,
		selectedClient,
		setSelectedClient,
		initialPrimaryContact
	} = useClientAndContactsDrawer(form, visible, currentActiveOpportunity);

	useComponentDidUpdate(() => {
		if (!isEditMode) return;
		form.setFieldsValue({
			primary_client_user_id: initialPrimaryContact
		});
	}, [currentActiveOpportunity, initialPrimaryContact]);

	const { renderVendorPartnerSelectField, isPartnersLoading } =
		useVendorPartnerDrawer(
			visible,
			currentActiveOpportunity,
			currentActivePartner
		);

	const getCustomFields = () => {
		setSystemFieldList(
			customFieldListVendorOpportunity.filter(
				res => res.system_field_id !== null
			)
		);
		setCustomFieldList(
			customFieldListVendorOpportunity.filter(
				res => res.system_field_id == null
			)
		);
	};
	const isPartnerDetailsRoute = useRouteMatch(
		ZomentumRoutes.VendorPartnersDetail
	);

	const handleOnFinish = async (values: VendorOpportunityFormValues) => {
		if (isEditMode && !currentActiveOpportunity) return;
		setLoading(true);
		try {
			if (isPreviewFromClientsPage) {
				values.vendor_client_company_id = selectedClient ?? "";
			}
			const customFieldFormValues = values.custom_field_form_values;
			let opportunityRequest = new VendorOpportunityRequest({
				...values,
				included_child_entities: [
					ChildEntityHydrationParams.VENDOR_CLIENT_COMPANIES_NAME,
					ChildEntityHydrationParams.COMPANIES_ALL,
					ChildEntityHydrationParams.VENDOR_CLIENT_COMPANIES_ALL,
					ChildEntityHydrationParams.VENDOR_CLIENT_COMPANIES_NAME,
					ChildEntityHydrationParams.PARTNER_MANAGER_ALL,
					ChildEntityHydrationParams.PARTNER_OPPORTUNITIES_INSIGHTS
				]
			});
			if (customFieldFormValues && customFieldList?.length > 0) {
				opportunityRequest = {
					...opportunityRequest,
					custom_fields: processCustomFieldFormValues(
						customFieldFormValues,
						customFieldList
					)
				};
			}

			if (isEditMode && !!currentActiveOpportunity) {
				const response = await editVendorOpportunity(
					currentActiveOpportunity.id,
					opportunityRequest
				);
				let successMsg = "Opportunity updated successfully";
				if (
					!!response.partner_company &&
					!currentActiveOpportunity.partner_company
				) {
					successMsg += ` and shared with ${response.partner_company?.name}`;
				} else if (
					!response.partner_company &&
					!!currentActiveOpportunity.partner_company
				) {
					successMsg += ` and unshared with ${currentActiveOpportunity.partner_company?.name}`;
				}
				ZMessage.success(successMsg);
				await onClose(
					response,
					currentActiveOpportunity.opportunity_pipeline_stage_id
				);
			} else {
				const response = await addVendorOpportunity(opportunityRequest);
				let successMsg = "Opportunity added successfully";
				if (response.partner_company)
					successMsg += ` and shared with ${response.partner_company?.name}`;
				ZMessage.success(successMsg, undefined, undefined, {
					buttonText: "View",
					buttonProps: {
						onClick: () => {
							history.push(
								ZomentumRoutes.VendorOpportunityDetail.replace(
									":id",
									response.id
								)
							);
						}
					}
				});
				await onClose(response);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const resetFields = () => {
		form.resetFields();
		setShouldSharePartner(false);
		handleResetClient();
	};

	const handleOnClose = () => {
		onClose();
	};

	const onChangeShareOpportunityWithPartner = (checked: boolean) => {
		setShouldSharePartner(checked);
		if (!checked)
			form.setFieldsValue({
				partner_company_id: undefined
			});
	};

	const disableDatesBeforeToday = (currentDate: Moment | null) => {
		if (currentDate) {
			return currentDate.isBefore(moment().subtract(1, "day"));
		}
		return false;
	};

	const selectedOpportunityPipelineStageId = form.getFieldValue(
		"opportunity_pipeline_stage_id"
	);
	const selectedStage = currentActivePipeline?.opportunity_stages.find(
		stage => stage.id === selectedOpportunityPipelineStageId
	);
	const isTerminalStageSelected =
		selectedStage?.is_terminal_lost_state ||
		selectedStage?.is_terminal_win_state;

	const opportunityPipelineStageOptions = useMemo(
		() =>
			currentActivePipeline?.opportunity_stages.map(stage => ({
				name: (
					<Fragment>
						{stage.stage_name}
						{stage.is_terminal_win_state && <ZWonTag />}
						{stage.is_terminal_lost_state && <ZLostTag />}
					</Fragment>
				),
				value: stage.id
			})) ?? [],
		[currentActivePipeline?.opportunity_stages]
	);

	const isShareOpportunityEnabled =
		(currentActiveOpportunity?.vendor_opportunity_source ===
			OpportunitySource.VENDOR ||
			!isEditMode) &&
		!isTerminalStageSelected;

	const getFormFields = () => {
		const fields: IFormFieldProps[] = [
			{
				key: "name",
				label: "Title",
				required: true,
				message: "Please enter title",
				widget: EWidgets.INPUT,
				initialValue: currentActiveOpportunity?.name ?? undefined,
				widgetProps: {
					placeholder: "Enter title",
					className: "w-full"
				}
			},
			{
				key: "opportunity_type",
				label: "Opportunity Type",
				widget: EWidgets.SELECT,
				required: systemFieldList.find(
					field =>
						field.system_field_id ===
						"vendor_opportunity_opportunity_type"
				)?.mandatory_field_details?.for_ui,
				initialValue:
					currentActiveOpportunity?.opportunity_type ?? undefined,
				widgetProps: {
					placeholder: "Select opportunity type",
					selectOptions:
						systemFieldList.find(
							c =>
								c.system_field_id ==
								"vendor_opportunity_opportunity_type"
						)?.allowed_dropdown_values ?? []
				}
			},
			{
				key: "owner_user_id",
				label: "Owner",
				required: systemFieldList.find(
					field =>
						field.system_field_id ===
						"vendor_opportunity_owner_user_id"
				)?.mandatory_field_details?.for_ui,
				initialValue:
					currentActiveOpportunity?.owner_user_id ?? vendorUser.id,
				widget: ({ value, onChange }) => (
					<ZUserSelect
						value={value}
						onChange={onChange}
						selectedUser={currentActiveOpportunity?.owner_user}
					/>
				)
			},
			{
				key: "partner_manager_id",
				label: "Partner Manager",
				required: systemFieldList.find(
					field =>
						field.system_field_id ===
						"vendor_opportunity_partner_manager"
				)?.mandatory_field_details?.for_ui,
				initialValue: currentActiveOpportunity
					? currentActiveOpportunity?.partner_manager?.id
					: vendorUser.id,
				widget: ({ value, onChange }) => (
					<ZUserSelect
						showSearch={true}
						allowClear={true}
						disabled={loading}
						placeholder="Please select partner manager"
						filterCurrentUser={false}
						value={value}
						onChange={v => onChange(v)}
					/>
				)
			},
			{
				key: "opportunity_pipeline_stage_id",
				label: "Opportunity Stage",
				widget: EWidgets.SELECT,
				required: true,
				initialValue:
					currentActiveOpportunity?.opportunity_pipeline_stage_id ??
					opportunityPipelineStageOptions?.[0]?.value,
				widgetProps: {
					placeholder: "Select opportunity stage",
					selectOptions: opportunityPipelineStageOptions
				},
				getValueFromEvent: (value: string) => {
					forceUpdate();
					return value;
				}
			}
		];
		if (selectedStage?.is_terminal_win_state) {
			fields.push({
				key: "won_reason",
				label: "Won Reason",
				message: "Please enter won reason",
				widget: EWidgets.INPUT,
				initialValue: currentActiveOpportunity?.won_reason ?? "",
				widgetProps: {
					placeholder: "Please enter won reason",
					className: "w-full"
				}
			});
		}
		if (selectedStage?.is_terminal_lost_state) {
			fields.push({
				key: "loss_reason",
				label: "Loss Reason",
				message: "Please enter loss reason",
				widget: EWidgets.INPUT,
				initialValue: currentActiveOpportunity?.loss_reason ?? "",
				widgetProps: {
					placeholder: "Please enter loss reason",
					className: "w-full"
				}
			});
		}
		const isInTerminalStage =
			!!currentActiveOpportunity?.pipeline_stage?.is_terminal_win_state ||
			!!currentActiveOpportunity?.pipeline_stage?.is_terminal_lost_state;
		if (isInTerminalStage) {
			fields.push({
				key: "close_date",
				label: "Close Date",
				required: true,
				initialValue: moment(currentActiveOpportunity?.close_date),
				widget: EWidgets.DATEPICKER,
				widgetProps: {
					disabled: true
				}
			});
		} else {
			fields.push({
				key: "estimated_close_date",
				label: "Expected Close Date",
				required: systemFieldList.find(
					field =>
						field.system_field_id ===
						"vendor_opportunity_estimated_close_date"
				)?.mandatory_field_details?.for_ui,
				initialValue: currentActiveOpportunity?.estimated_close_date
					? moment(currentActiveOpportunity?.estimated_close_date)
					: undefined,
				widget: EWidgets.DATEPICKER,
				widgetProps: {
					disabledDate: disableDatesBeforeToday
				}
			});
		}
		fields.push({
			key: "estimated_opportunity_value",
			label: "Estimated Value",
			widget: EWidgets.INPUTNUMBER,
			required: systemFieldList.find(
				field =>
					field.system_field_id ===
					"vendor_opportunity_estimated_value"
			)?.mandatory_field_details?.for_ui,
			initialValue:
				currentActiveOpportunity?.estimated_opportunity_value ??
				undefined,
			tooltip:
				"Estimated Value is the total approximate value of the deal. This value helps your partners priortise the deal accordingly.",
			widgetProps: {
				addOnAfter: getCurrencySettings().currency,
				width: EInputNumberWidths.lg
			}
		});
		fields.push({
			key: "share_opportunity_with_partner",
			widget: EWidgets.CHECKBOX,
			initialValue: shouldSharePartner,
			disabled: !isShareOpportunityEnabled,
			widgetProps: {
				checked: shouldSharePartner,
				checkboxOption: "Share Opportunity with Partner",
				onChange: onChangeShareOpportunityWithPartner
			}
		});
		fields.push({
			required: shouldSharePartner,
			message: "Please select partner",
			key: "partner_company_id",
			label: "Partner",
			initialValue: initialPartnerCompanyId,
			widget: ({ value, onChange }) =>
				renderVendorPartnerSelectField(
					value,
					onChange,
					!isShareOpportunityEnabled
				),
			className: !shouldSharePartner ? "hidden" : ""
		});
		fields.push({
			key: "vendor_client_company_id",
			label: "End Client",
			tooltip: !!currentActiveOpportunity?.partner_opportunity_status
				? "Client can't be changed on shared opportunities. Stop sharing the opportunity first to change client."
				: undefined,
			initialValue:
				currentActiveOpportunity?.vendor_client_company_id ??
				vendorEndClientId ??
				undefined,
			widget: ({ value, onChange }) =>
				renderClientSelectField(
					value,
					onChange,
					!!currentActiveOpportunity?.partner_opportunity_status
				),
			rules: [
				{
					required: true,
					validator: (_: any, value: string) => {
						if (!value?.length) {
							return Promise.reject("Please select client");
						}
						return Promise.resolve();
					}
				}
			]
		});
		fields.push({
			key: "primary_client_user_id",
			label: "Primary contact",
			required: systemFieldList.find(
				field =>
					field.system_field_id ===
					"vendor_opportunity_primary_client_user_id"
			)?.mandatory_field_details?.for_ui,
			initialValue:
				currentActiveOpportunity?.primary_client_user_id ?? undefined,
			widget: ({ value, onChange }) =>
				renderPrimaryContactField(value, onChange)
		});
		fields.push({
			key: "linked_client_user_ids",
			label: "Linked contacts",
			required: systemFieldList.find(
				field =>
					field.system_field_id ===
					"vendor_opportunity_linked_client_user_ids"
			)?.mandatory_field_details?.for_ui,
			initialValue:
				currentActiveOpportunity?.linked_client_user_ids ?? [],
			widget: ({ value, onChange }) =>
				renderLinkedContactsField(value, onChange)
		});
		customFieldList.forEach((customField: CustomField) => {
			const isDateCustomField =
				customField.field_type === CustomFieldType.DateField;
			const initialValue = currentActiveOpportunity
				? getInitialValueForCustomField(
						currentActiveOpportunity,
						isDateCustomField,
						customField
				  )
				: undefined;
			fields.push({
				key: `custom_field_form_values.${customField.id}`,
				label: (
					<ZTooltip
						title={customField.display_name}
						placement="top"
						showtooltip={customField.display_name.length >= 23}
					>
						<ZTypography.ZText level={1}>
							{shortenText(customField.display_name, 23)}
						</ZTypography.ZText>
					</ZTooltip>
				),
				initialValue: initialValue,
				required: !!customField.mandatory_field_details?.for_ui,
				message: "Please enter " + customField.display_name,
				widget: getWidget(customField.field_type),
				widgetProps: getCustomFieldProps(customField),
				className: "large-custom-field"
			});
		});
		return fields;
	};

	const sections: IFormSectionProps[] = [
		{
			hideDivider: true,
			shouldHideSectionBottomPadding: true,
			meta: {
				formItemLayout: [6, 18],
				fields: getFormFields()
			}
		}
	];

	const formProps: IFormProps = {
		form,
		key: "opportunity-form",
		sections,
		isRightFooter: true,
		isStickFooter: true,
		isCancelAllowed: true,
		shouldDisableBeforeTouch: !isEditMode,
		loading: loading || clientLoading || isPartnersLoading,
		onFinish: handleOnFinish,
		onCancel: handleOnClose,
		submitButtonText: shouldSharePartner ? "Save and Share" : "Save"
	};

	const getDrawerTitle = () => {
		if (mode === OpportunityDrawerMode.EDIT) {
			return "Edit Opportunity";
		}
		return "Add Opportunity";
	};

	useEffect(() => {
		if (!currentActivePipeline) {
			dispatch(PipelinesActionCreators.getPipeline());
		}
	}, []);

	useEffect(() => {
		if (!visible) {
			resetFields();
			return;
		}
		getCustomFields();
		if (!!defaultOpportunityStage)
			form.setFieldsValue({
				opportunity_pipeline_stage_id: defaultOpportunityStage
			});
		if (
			isPartnerDetailsRoute &&
			mode === OpportunityDrawerMode.ADD &&
			currentActivePartner?.id
		) {
			setShouldSharePartner(true);
			form.setFieldsValue({
				share_opportunity_with_partner: true
			});
			form.setFieldsValue({
				partner_company_id: currentActivePartner.id
			});
		}
	}, [visible]);

	useEffect(() => {
		if (currentActiveOpportunity) {
			const shouldSharePartner =
				!!currentActiveOpportunity.partner_company_id?.length;
			setShouldSharePartner(shouldSharePartner);
			const initialValues = {
				name: currentActiveOpportunity?.name ?? undefined,
				opportunity_type:
					currentActiveOpportunity?.opportunity_type ?? undefined,
				owner_user_id:
					currentActiveOpportunity?.owner_user_id ?? vendorUser.id,
				partner_manager_id: currentActiveOpportunity
					? currentActiveOpportunity?.partner_manager?.id ?? undefined
					: vendorUser.id,
				opportunity_pipeline_stage_id:
					currentActiveOpportunity?.opportunity_pipeline_stage_id ??
					opportunityPipelineStageOptions?.[0]?.value,
				estimated_opportunity_value:
					currentActiveOpportunity?.estimated_opportunity_value ??
					undefined,
				partner_company_id: initialPartnerCompanyId,
				vendor_client_company_id:
					currentActiveOpportunity?.vendor_client_company_id ??
					vendorEndClientId ??
					undefined,
				primary_client_user_id:
					currentActiveOpportunity?.primary_client_user_id ??
					undefined,
				linked_client_user_ids:
					currentActiveOpportunity?.linked_client_user_ids ?? [],
				share_opportunity_with_partner: shouldSharePartner,
				custom_field_form_values: {}
			};
			if (selectedStage?.is_terminal_win_state) {
				initialValues["won_reason"] =
					currentActiveOpportunity?.won_reason ?? "";
			}
			if (selectedStage?.is_terminal_lost_state) {
				initialValues["loss_reason"] =
					currentActiveOpportunity?.loss_reason ?? "";
			}
			const isInTerminalStage =
				!!currentActiveOpportunity?.pipeline_stage
					?.is_terminal_win_state ||
				!!currentActiveOpportunity?.pipeline_stage
					?.is_terminal_lost_state;
			if (isInTerminalStage) {
				initialValues["close_date"] = moment(
					currentActiveOpportunity?.close_date
				);
			} else {
				initialValues["estimated_close_date"] =
					currentActiveOpportunity?.estimated_close_date
						? moment(currentActiveOpportunity?.estimated_close_date)
						: undefined;
			}
			customFieldList.forEach((customField: CustomField) => {
				const isDateCustomField =
					customField.field_type === CustomFieldType.DateField;
				const initialValue = currentActiveOpportunity
					? getInitialValueForCustomField(
							currentActiveOpportunity,
							isDateCustomField,
							customField
					  )
					: undefined;
				initialValues["custom_field_form_values"][customField.id] =
					initialValue;
			});
			form.setFieldsValue(initialValues);
		}
	}, [currentActiveOpportunity]);

	useEffect(() => {
		if (!!vendorEndClientId?.length) {
			setSelectedClient(vendorEndClientId);
		}
	}, [vendorEndClientId, visible]);

	useEffect(() => {
		if (!!currentActiveOpportunity?.vendor_client_company?.id.length) {
			setSelectedClient(
				currentActiveOpportunity.vendor_client_company.id
			);
		}
	}, [currentActiveOpportunity, visible]);

	useComponentDidUpdate(() => {
		if (isTerminalStageSelected) {
			setShouldSharePartner(shouldSharePartnerInitialValue);
			form.setFieldsValue({
				partner_company_id: initialPartnerCompanyId
			});
		}
	}, [isTerminalStageSelected]);

	return (
		<ZDrawer
			visible={visible}
			title={getDrawerTitle()}
			onClose={handleOnClose}
			removeDefaultPadding
			destroyOnClose
		>
			<ZForm {...formProps} className="py-4" />
			{clientAndContactDrawers}
		</ZDrawer>
	);
};

export default OpportunityDrawer;
