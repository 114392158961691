'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index = require('./Product/index.js');

exports.MarketplaceCollection = void 0;
(function (MarketplaceCollection) {
  MarketplaceCollection["JOINEDPROGRAM"] = "joined_program";
})(exports.MarketplaceCollection || (exports.MarketplaceCollection = {}));
exports.MarketplaceCollectionRoute = void 0;
(function (MarketplaceCollectionRoute) {
  MarketplaceCollectionRoute["JOINEDPROGRAM"] = "joined-programs";
})(exports.MarketplaceCollectionRoute || (exports.MarketplaceCollectionRoute = {}));
var CreateMarketplaceVendorReferralRequest = /** @class */function () {
  function CreateMarketplaceVendorReferralRequest(vendorName, vendorWebsite, productCategory) {
    this.vendor_name = "";
    this.vendor_website = "";
    this.product_category = index.MarketplaceCategory.PRODUCTIVITY;
    this.vendor_name = vendorName;
    this.vendor_website = vendorWebsite;
    this.product_category = productCategory;
  }
  return CreateMarketplaceVendorReferralRequest;
}();
var AcceptPartnerProgramInviteRequest = /** @class */function () {
  function AcceptPartnerProgramInviteRequest(vendorId) {
    this.vendor_id = "";
    this.vendor_id = vendorId;
  }
  return AcceptPartnerProgramInviteRequest;
}();
var MyVendor = /** @class */function () {
  function MyVendor() {
    this.id = "";
    this.name = "";
    this.status = null;
    this.initiated_at = null;
    this.accepted_at = null;
    this.industry = null;
    this.logo = "";
    this.tier_with_vendor = null;
  }
  return MyVendor;
}();
var MyVendorsCount = /** @class */function () {
  function MyVendorsCount() {
    this.all = 0;
  }
  return MyVendorsCount;
}();
var MyVendorsResponse = /** @class */function () {
  function MyVendorsResponse() {
    this.data = new Array();
  }
  return MyVendorsResponse;
}();
var MyVendorsPaginationResponse = /** @class */function () {
  function MyVendorsPaginationResponse() {
    this.data = new Array();
    this.filtered_count_map = new MyVendorsCount();
  }
  return MyVendorsPaginationResponse;
}();
exports.MyVendorsTabType = void 0;
(function (MyVendorsTabType) {
  MyVendorsTabType["ALL_VENDORS"] = "all_vendors";
  MyVendorsTabType["MY_VENDORS"] = "my_vendors";
})(exports.MyVendorsTabType || (exports.MyVendorsTabType = {}));
exports.MarketplaceComissionsTabType = void 0;
(function (MarketplaceComissionsTabType) {
  MarketplaceComissionsTabType["COMMISSIONS"] = "commissions";
  MarketplaceComissionsTabType["TRANSACTIONS"] = "transactions";
})(exports.MarketplaceComissionsTabType || (exports.MarketplaceComissionsTabType = {}));

exports.AcceptPartnerProgramInviteRequest = AcceptPartnerProgramInviteRequest;
exports.CreateMarketplaceVendorReferralRequest = CreateMarketplaceVendorReferralRequest;
exports.MyVendor = MyVendor;
exports.MyVendorsCount = MyVendorsCount;
exports.MyVendorsPaginationResponse = MyVendorsPaginationResponse;
exports.MyVendorsResponse = MyVendorsResponse;
