'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../../../node_modules/tslib/tslib.es6.mjs.js');
var index = require('../../actions/Blocks/index.js');

var initialBlockState = {
  isLoading: false,
  isQuoteBlockInDocumentChanged: false,
  blockMapper: {},
  currentActiveBlock: new Array(),
  currentActiveSection: null,
  loadingQuoteBlockIds: [],
  inputFieldPopUpVisible: false,
  backgroundColorForTextBlockVisible: false,
  inputFieldSelector: null,
  isProductConfigureDrawerVisible: false,
  productData: null,
  sectionIndex: 0,
  blockId: "",
  editorType: null,
  readonlyProductDrawer: false,
  headerFooterColumnId: null
};
var BlocksReducer = function BlocksReducer(state, action) {
  var _a, _b, _c, _d, _e;
  var _f;
  if (state === void 0) {
    state = initialBlockState;
  }
  switch (action.type) {
    case index.BlocksActionTypes.SHOW_BLOCK_LOADER:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          isLoading: true
        });
      }
    case index.BlocksActionTypes.HIDE_BLOCK_LOADER:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          isLoading: false
        });
      }
    case index.BlocksActionTypes.GET_BLOCK_MAPPER_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          blockMapper: action.blockMapper
        });
      }
    case index.BlocksActionTypes.UPDATE_BLOCK_MAPPER_REQUESTED:
      {
        return state;
      }
    case index.BlocksActionTypes.UPDATE_BLOCK_MAPPER_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          blockMapper: tslib_es6.__assign(tslib_es6.__assign({}, state.blockMapper), action.blockMapper)
        });
      }
    case index.BlocksActionTypes.UPDATE_BLOCK_FOCUS:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentActiveBlock: action.currentActiveBlock
        });
      }
    case index.BlocksActionTypes.UPDATE_SECTION_FOCUS:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentActiveSection: action.currentActiveSection
        });
      }
    case index.BlocksActionTypes.RESET_BLOCK_FOCUS:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentActiveBlock: new Array(),
          currentActiveSection: null
        });
      }
    case index.BlocksActionTypes.CREATE_BLOCK_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          blockMapper: tslib_es6.__assign(tslib_es6.__assign({}, state.blockMapper), (_a = {}, _a[action.blockData.id] = action.blockData, _a))
        });
      }
    case index.BlocksActionTypes.UPDATE_BLOCK_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          isQuoteBlockInDocumentChanged: !!action.isQuoteBlock,
          blockMapper: tslib_es6.__assign(tslib_es6.__assign({}, state.blockMapper), (_b = {}, _b[action.blockData.id] = action.blockData, _b))
        });
      }
    case index.BlocksActionTypes.HIDE_BLOCK_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          blockMapper: tslib_es6.__assign(tslib_es6.__assign({}, state.blockMapper), (_c = {}, _c[action.blockData.id] = action.blockData, _c))
        });
      }
    case index.BlocksActionTypes.HIDE_BLOCK_REQUESTED:
      {
        return state;
      }
    case index.BlocksActionTypes.HIDE_BLOCK_FAILED:
      {
        return state;
      }
    case index.BlocksActionTypes.UNHIDE_BLOCK_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          blockMapper: tslib_es6.__assign(tslib_es6.__assign({}, state.blockMapper), (_d = {}, _d[action.blockData.id] = action.blockData, _d))
        });
      }
    case index.BlocksActionTypes.UNHIDE_BLOCK_REQUESTED:
      {
        return state;
      }
    case index.BlocksActionTypes.UNHIDE_BLOCK_FAILED:
      {
        return state;
      }
    case index.BlocksActionTypes.DELL_QUOTE_IMPORT_SUCCESSFUL:
      {
        var newBlockMapper = tslib_es6.__assign({}, state.blockMapper);
        if (action.blockData) {
          newBlockMapper[action.blockData.id] = action.blockData;
        }
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          blockMapper: newBlockMapper
        });
      }
    case index.BlocksActionTypes.EDIT_QUOTE_LABEL_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          blockMapper: tslib_es6.__assign(tslib_es6.__assign({}, state.blockMapper), (_e = {}, _e[action.blockData.id] = action.blockData, _e))
        });
      }
    case index.BlocksActionTypes.EDIT_QUOTE_LABEL_FAILED:
      {
        return state;
      }
    case index.BlocksActionTypes.EDIT_QUOTE_LABEL_REQUESTED:
      {
        return state;
      }
    case index.BlocksActionTypes.UPDATE_BLOCK_REQUESTED:
      {
        return state;
      }
    case index.BlocksActionTypes.UPDATE_BLOCK_FAILED:
      {
        return state;
      }
    case index.BlocksActionTypes.GET_BLOCK_MAPPER_REQUESTED:
      {
        return state;
      }
    case index.BlocksActionTypes.GET_BLOCK_MAPPER_FAILED:
      {
        return state;
      }
    case index.BlocksActionTypes.UPDATE_BLOCK_MAPPER_FAILED:
      {
        return state;
      }
    case index.BlocksActionTypes.CREATE_BLOCK_REQUESTED:
      {
        return state;
      }
    case index.BlocksActionTypes.CREATE_BLOCK_FAILED:
      {
        return state;
      }
    case index.BlocksActionTypes.DELETE_BLOCK_REQUESTED:
      {
        return state;
      }
    case index.BlocksActionTypes.DELETE_BLOCK_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          blockMapper: action.blockMapper
        });
      }
    case index.BlocksActionTypes.DELETE_BLOCK_FAILED:
      {
        return state;
      }
    case index.BlocksActionTypes.UPDATE_QUOTE_BLOCK_LOADER_IDS:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          loadingQuoteBlockIds: action.quoteBlockIds
        });
      }
    case index.BlocksActionTypes.QUICKBOOKS_SYNC_TOOLTIP_SHOW:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          isQuoteBlockInDocumentChanged: true
        });
      }
    case index.BlocksActionTypes.QUICKBOOKS_SYNC_TOOLTIP_HIDE:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          isQuoteBlockInDocumentChanged: false
        });
      }
    case index.BlocksActionTypes.SHOW_INPUT_FIELD_POP_UP:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          inputFieldPopUpVisible: true
        });
      }
    case index.BlocksActionTypes.HIDE_INPUT_FIELD_POP_UP:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          inputFieldPopUpVisible: false
        });
      }
    case index.BlocksActionTypes.SHOW_BACKGROUND_COLOR_PICKER:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          backgroundColorForTextBlockVisible: true
        });
      }
    case index.BlocksActionTypes.HIDE_BACKGROUND_COLOR_PICKER:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          backgroundColorForTextBlockVisible: false
        });
      }
    case index.BlocksActionTypes.SHOW_PRODUCT_CONFIGURE_DRAWER:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          isProductConfigureDrawerVisible: true,
          blockId: action.blockId,
          sectionIndex: action.sectionIndex,
          productData: action.productData,
          readonlyProductDrawer: (_f = action.readonly) !== null && _f !== void 0 ? _f : false
        });
      }
    case index.BlocksActionTypes.HIDE_PRODUCT_CONFIGURE_DRAWER:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          isProductConfigureDrawerVisible: false
        });
      }
    case index.BlocksActionTypes.UPDATE_EDITOR_TYPE:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          editorType: action.editorType
        });
      }
    case index.BlocksActionTypes.UPDATE_HEADER_FOOTER_COLUMN_ID:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          headerFooterColumnId: action.headerFooterColumnId
        });
      }
    default:
      {
        return state;
      }
  }
};
var BlockReducer = BlocksReducer;

exports["default"] = BlockReducer;
exports.initialBlockState = initialBlockState;
