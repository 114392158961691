import { ZTypography } from "@zomentum/atoms";
import React from "react";
import ZTable from "@zomentum/organisms/dist/ZTable";
import "./index.less";
import { IEntityDetailsTableProps } from "./interface";

const ZEntityDetailsTable: React.FC<IEntityDetailsTableProps> = ({
	className = "",
	title,
	records,
	labelWidth
}) => {
	const columns = [
		{
			dataIndex: "field",
			key: "field",
			title,
			width: labelWidth
		},
		{
			dataIndex: "value",
			key: "value"
		}
	];
	return (
		<div className={`z-entity-details-table ${className}`}>
			<div className="p-4 bg-geekBlue-1">
				<ZTypography.ZTitle level={1}>{title}</ZTypography.ZTitle>
			</div>
			<ZTable
				showHeader={false}
				columns={columns}
				dataSource={records.filter(record => !!record.value)}
				loading={false}
				pagination={false}
				isTopBorder={false}
			/>
		</div>
	);
};

export default ZEntityDetailsTable;
