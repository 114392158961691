import { Reducer } from "redux";

import { SettingsActions, SettingsActionTypes } from "@/Actions/Settings";

export interface SettingsState {
	readonly isLoading: boolean;
	readonly appUpdateBannerVisible: boolean;
	readonly connectWiseDrawerOpen: boolean;
	readonly zapierDrawerOpen: boolean;
	readonly kaseyaDrawerOpen: boolean;
	readonly accountSettingsDrawerOpen: boolean;
	readonly accountPasswordDrawerOpen: boolean;
	readonly addCompanyUserDrawerOpen: boolean;
	readonly editCompanyUserDrawerOpen: boolean;
	readonly companyLogoModalOpen: boolean;
	readonly ingramDrawerOpen: boolean;
	readonly techDataDrawerOpen: boolean;
	readonly dAndHDrawerOpen: boolean;
	readonly synnexDrawerOpen: boolean;
	readonly connectBoosterDrawerOpen: boolean;
	readonly addCustomFieldDrawerOpen: boolean;
	readonly editCustomFieldDrawerOpen: boolean;
	readonly commissionsDrawerOpen: boolean;
	readonly dickerDataDrawerOpen: boolean;
	readonly westcoastDrawerOpen: boolean;
	readonly rolesPermissionsDrawerOpen: boolean;
	readonly viewPermissionsDrawerOpen: boolean;
}

export const initialSettingsState: SettingsState = {
	isLoading: false,
	appUpdateBannerVisible: false,
	connectWiseDrawerOpen: false,
	zapierDrawerOpen: false,
	kaseyaDrawerOpen: false,
	accountSettingsDrawerOpen: false,
	accountPasswordDrawerOpen: false,
	addCompanyUserDrawerOpen: false,
	editCompanyUserDrawerOpen: false,
	companyLogoModalOpen: false,
	ingramDrawerOpen: false,
	techDataDrawerOpen: false,
	dAndHDrawerOpen: false,
	synnexDrawerOpen: false,
	connectBoosterDrawerOpen: false,
	addCustomFieldDrawerOpen: false,
	editCustomFieldDrawerOpen: false,
	commissionsDrawerOpen: false,
	dickerDataDrawerOpen: false,
	westcoastDrawerOpen: false,
	rolesPermissionsDrawerOpen: false,
	viewPermissionsDrawerOpen: false
};

export const SettingsReducer: Reducer<SettingsState, SettingsActions> = (
	state: SettingsState = initialSettingsState,
	action: SettingsActions
): SettingsState => {
	switch (action.type) {
		case SettingsActionTypes.SHOW_SETTINGS_LOADER: {
			return {
				...state,
				isLoading: true
			};
		}
		case SettingsActionTypes.HIDE_SETTINGS_LOADER: {
			return {
				...state,
				isLoading: false
			};
		}
		case SettingsActionTypes.SHOW_APP_UPDATE_BANNER: {
			return {
				...state,
				appUpdateBannerVisible: true
			};
		}
		case SettingsActionTypes.HIDE_APP_UPDATE_BANNER: {
			return {
				...state,
				appUpdateBannerVisible: false
			};
		}
		case SettingsActionTypes.CONNECTWISE_DRAWER_OPENED: {
			return {
				...state,
				connectWiseDrawerOpen: true
			};
		}
		case SettingsActionTypes.CONNECTWISE_DRAWER_CLOSED: {
			return {
				...state,
				connectWiseDrawerOpen: false
			};
		}
		case SettingsActionTypes.KASEYA_DRAWER_OPENED: {
			return {
				...state,
				kaseyaDrawerOpen: true
			};
		}
		case SettingsActionTypes.KASEYA_DRAWER_CLOSED: {
			return {
				...state,
				kaseyaDrawerOpen: false
			};
		}
		case SettingsActionTypes.ZAPIER_DRAWER_OPENED: {
			return {
				...state,
				zapierDrawerOpen: true
			};
		}
		case SettingsActionTypes.ZAPIER_DRAWER_CLOSED: {
			return {
				...state,
				zapierDrawerOpen: false
			};
		}
		case SettingsActionTypes.ACCOUNT_SETTINGS_DRAWER_OPENED: {
			return {
				...state,
				accountSettingsDrawerOpen: true
			};
		}
		case SettingsActionTypes.ACCOUNT_SETTINGS_DRAWER_CLOSED: {
			return {
				...state,
				accountSettingsDrawerOpen: false
			};
		}
		case SettingsActionTypes.ADD_COMPANY_USER_DRAWER_OPENED: {
			return {
				...state,
				addCompanyUserDrawerOpen: true
			};
		}
		case SettingsActionTypes.ADD_COMPANY_USER_DRAWER_CLOSED: {
			return {
				...state,
				addCompanyUserDrawerOpen: false
			};
		}
		case SettingsActionTypes.EDIT_COMPANY_USER_DRAWER_OPENED: {
			return {
				...state,
				editCompanyUserDrawerOpen: true
			};
		}
		case SettingsActionTypes.EDIT_COMPANY_USER_DRAWER_CLOSED: {
			return {
				...state,
				editCompanyUserDrawerOpen: false
			};
		}
		case SettingsActionTypes.COMPANY_LOGO_MODAL_OPENED: {
			return {
				...state,
				companyLogoModalOpen: true
			};
		}
		case SettingsActionTypes.COMPANY_LOGO_MODAL_CLOSED: {
			return {
				...state,
				companyLogoModalOpen: false
			};
		}
		case SettingsActionTypes.INGRAM_DRAWER_OPENED: {
			return {
				...state,
				ingramDrawerOpen: true
			};
		}
		case SettingsActionTypes.INGRAM_DRAWER_CLOSED: {
			return {
				...state,
				ingramDrawerOpen: false
			};
		}
		case SettingsActionTypes.TECH_DATA_DRAWER_OPENED: {
			return {
				...state,
				techDataDrawerOpen: true
			};
		}
		case SettingsActionTypes.TECH_DATA_DRAWER_CLOSED: {
			return {
				...state,
				techDataDrawerOpen: false
			};
		}
		case SettingsActionTypes.D_AND_H_DRAWER_OPENED: {
			return {
				...state,
				dAndHDrawerOpen: true
			};
		}
		case SettingsActionTypes.D_AND_H_DRAWER_CLOSED: {
			return {
				...state,
				dAndHDrawerOpen: false
			};
		}
		case SettingsActionTypes.SYNNEX_DRAWER_OPENED: {
			return {
				...state,
				synnexDrawerOpen: true
			};
		}
		case SettingsActionTypes.SYNNEX_DRAWER_CLOSED: {
			return {
				...state,
				synnexDrawerOpen: false
			};
		}
		case SettingsActionTypes.CB_DRAWER_OPENED: {
			return {
				...state,
				connectBoosterDrawerOpen: true
			};
		}
		case SettingsActionTypes.CB_DRAWER_CLOSED: {
			return {
				...state,
				connectBoosterDrawerOpen: false
			};
		}
		case SettingsActionTypes.ADD_CUSTOM_FIELD_DRAWER_OPENED: {
			return {
				...state,
				addCustomFieldDrawerOpen: true
			};
		}
		case SettingsActionTypes.ADD_CUSTOM_FIELD_DRAWER_CLOSED: {
			return {
				...state,
				addCustomFieldDrawerOpen: false
			};
		}
		case SettingsActionTypes.EDIT_CUSTOM_FIELD_DRAWER_OPENED: {
			return {
				...state,
				editCustomFieldDrawerOpen: true
			};
		}
		case SettingsActionTypes.EDIT_CUSTOM_FIELD_DRAWER_CLOSED: {
			return {
				...state,
				editCustomFieldDrawerOpen: false
			};
		}
		case SettingsActionTypes.COMMISSIONS_DRAWER_OPENED: {
			return {
				...state,
				commissionsDrawerOpen: true
			};
		}
		case SettingsActionTypes.COMMISSIONS_DRAWER_CLOSED: {
			return {
				...state,
				commissionsDrawerOpen: false
			};
		}
		case SettingsActionTypes.TEST_CONNECTWISE_CREDENTIALS_REQUESTED: {
			return state;
		}
		case SettingsActionTypes.TEST_CONNECTWISE_CREDENTIALS_SUCCESSFUL: {
			return state;
		}
		case SettingsActionTypes.TEST_CONNECTWISE_CREDENTIALS_FAILED: {
			return state;
		}
		case SettingsActionTypes.TEST_SYNCRO_CREDENTIALS_REQUESTED: {
			return state;
		}
		case SettingsActionTypes.TEST_SYNCRO_CREDENTIALS_SUCCESSFUL: {
			return state;
		}
		case SettingsActionTypes.TEST_SYNCRO_CREDENTIALS_FAILED: {
			return state;
		}
		case SettingsActionTypes.TEST_KASEYA_CREDENTIALS_REQUESTED: {
			return state;
		}
		case SettingsActionTypes.TEST_KASEYA_CREDENTIALS_SUCCESSFUL: {
			return state;
		}
		case SettingsActionTypes.TEST_KASEYA_CREDENTIALS_FAILED: {
			return state;
		}
		case SettingsActionTypes.CONNECTWISE_TAX_IMPORT_REQUESTED: {
			return state;
		}
		case SettingsActionTypes.CONNECTWISE_TAX_IMPORT_SUCCESSFUL: {
			return state;
		}
		case SettingsActionTypes.CONNECTWISE_TAX_IMPORT_FAILED: {
			return state;
		}
		case SettingsActionTypes.AUTOTASK_TAX_IMPORT_REQUESTED: {
			return state;
		}
		case SettingsActionTypes.AUTOTASK_TAX_IMPORT_SUCCESSFUL: {
			return state;
		}
		case SettingsActionTypes.AUTOTASK_TAX_IMPORT_FAILED: {
			return state;
		}
		case SettingsActionTypes.SHOW_DICKER_DATA_DRAWER: {
			return {
				...state,
				dickerDataDrawerOpen: true
			};
		}
		case SettingsActionTypes.HIDE_DICKER_DATA_DRAWER: {
			return {
				...state,
				dickerDataDrawerOpen: false
			};
		}
		case SettingsActionTypes.SHOW_WESTCOAST_DRAWER: {
			return {
				...state,
				westcoastDrawerOpen: true
			};
		}
		case SettingsActionTypes.HIDE_WESTCOAST_DRAWER: {
			return {
				...state,
				westcoastDrawerOpen: false
			};
		}
		case SettingsActionTypes.SHOW_ROLES_PERMISSIONS_DRAWER: {
			return {
				...state,
				rolesPermissionsDrawerOpen: true
			};
		}
		case SettingsActionTypes.HIDE_ROLES_PERMISSIONS_DRAWER: {
			return {
				...state,
				rolesPermissionsDrawerOpen: false
			};
		}
		case SettingsActionTypes.SHOW_VIEW_PERMISSIONS_DRAWER: {
			return {
				...state,
				viewPermissionsDrawerOpen: true
			};
		}
		case SettingsActionTypes.HIDE_VIEW_PERMISSIONS_DRAWER: {
			return {
				...state,
				viewPermissionsDrawerOpen: false
			};
		}
		case SettingsActionTypes.AUTOTASK_STAGE_MAPPING_REQUESTED: {
			return state;
		}
		case SettingsActionTypes.AUTOTASK_STAGE_MAPPING_SUCCESSFUL: {
			return state;
		}
		case SettingsActionTypes.AUTOTASK_STAGE_MAPPING_FAILED: {
			return state;
		}
		case SettingsActionTypes.UPDATED_SYNC_DATA_REQUESTED: {
			return state;
		}
		case SettingsActionTypes.UPDATED_SYNC_DATA_SUCCESSFUL: {
			return state;
		}
		case SettingsActionTypes.UPDATED_SYNC_DATA_FAILED: {
			return state;
		}
		default: {
			return state;
		}
	}
};

export default SettingsReducer;
