'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.VendorSubscriptionStatus = void 0;
(function (VendorSubscriptionStatus) {
  VendorSubscriptionStatus["APPLIED"] = "applied";
  VendorSubscriptionStatus["ACCEPTED"] = "accepted";
  VendorSubscriptionStatus["REJECTED"] = "rejected";
  VendorSubscriptionStatus["INVITED"] = "invited";
  VendorSubscriptionStatus["NOTAPPLIED"] = "not_applied";
  VendorSubscriptionStatus["SUSPENDED"] = "suspended";
  VendorSubscriptionStatus["WITHDRAWN"] = "request_withdrawn";
})(exports.VendorSubscriptionStatus || (exports.VendorSubscriptionStatus = {}));
var JoinedLikeVendorSubscriptionStatus = [exports.VendorSubscriptionStatus.ACCEPTED, exports.VendorSubscriptionStatus.INVITED];
exports.VendorIndustry = void 0;
(function (VendorIndustry) {
  VendorIndustry["PROJECT_MANAGEMENT"] = "project_management";
  VendorIndustry["SECURITY"] = "security";
  VendorIndustry["BUSINESS_PRODUCTIVITY"] = "business_productivity";
  VendorIndustry["INFRASTRUCTURE_SOFTWARE"] = "infrastructure_software";
  VendorIndustry["SAAS"] = "saas";
  VendorIndustry["DATA_PRODUCTS"] = "data_products";
  VendorIndustry["IOT"] = "iot";
  VendorIndustry["MACHINE_LEARNING"] = "machine_learning";
  VendorIndustry["PROFESSIONAL_SERVICES"] = "professional_services";
  VendorIndustry["EDUCATION_RESEARCH"] = "education_research";
  VendorIndustry["FINANCIAL_SERVICES"] = "financial_services";
  VendorIndustry["HEALTHCARE_LIFE_SCIENCE"] = "healthcare_life_science";
  VendorIndustry["MEDIA_ENTERTAINMENT"] = "media_entertainment";
  VendorIndustry["INDUSTRIAL"] = "industrial";
  VendorIndustry["OTHERS"] = "others";
})(exports.VendorIndustry || (exports.VendorIndustry = {}));
var VendorCompany = /** @class */function () {
  function VendorCompany() {
    this.created = new Date();
    this.updated = new Date();
    this.id = "";
    this.name = "";
    this.description = "";
    this.sub_domain = "";
    this.twitter_url = "";
    this.website_url = "";
    this.facebook_url = "";
    this.billing_address = null;
    this.favicon = "";
    this.logo = "";
    this.certifications = new Array();
    this.industry = exports.VendorIndustry.SAAS;
    this.unique_selling_point = "";
    this.support_email = "";
    this.support_contact = "";
    this.tier_with_vendor = null;
    this.un_suspend_request_time = null;
  }
  return VendorCompany;
}();
var VendorCompaniesResponse = /** @class */function () {
  function VendorCompaniesResponse() {
    this.data = new Array();
  }
  return VendorCompaniesResponse;
}();

exports.JoinedLikeVendorSubscriptionStatus = JoinedLikeVendorSubscriptionStatus;
exports.VendorCompaniesResponse = VendorCompaniesResponse;
exports.VendorCompany = VendorCompany;
