import React, { useState } from "react";
import ZDynamicSelect from "@zomentum/molecules/dist/ZDynamicSelect";
import { IOpportunitySelectProps } from "./interface";
import { IOptionProps } from "@zomentum/atoms/dist/ZSelect/interface";
import {
	GlobalSearchEntityType,
	GlobalSearchRequest
} from "@zomentum/contracts/dist/Others/GlobalSearch";
import { getGlobalSearch } from "@zomentum/utils/dist/Service/GlobalSearch";
import { VendorOpportunity } from "@zomentum/contracts/dist/Vendor/Opportunity";

const ZVendorOpportunitySelect: React.FC<IOpportunitySelectProps> = props => {
	const { defaultOptions, className, onChange, ...rest } = props;

	const formattedDefaultOptions =
		defaultOptions?.map(currOption => ({
			name: currOption.name,
			value: currOption.id
		})) ?? [];

	const [opportunities, setOpportunities] = useState<IOptionProps[]>([]);

	const searchOpportunities = async (value: string) => {
		try {
			const opportunitySearchRequest = new GlobalSearchRequest(
				value,
				[GlobalSearchEntityType.VendorOpportunity],
				10,
				null
			);
			const searchResult = await getGlobalSearch(
				opportunitySearchRequest
			);
			setOpportunities(
				searchResult.data.map(entity => {
					const opportunity = entity.obj as VendorOpportunity;
					return {
						name: opportunity.name,
						value: opportunity.id
					};
				})
			);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<ZDynamicSelect
			defaultOptions={formattedDefaultOptions}
			fetchData={searchOpportunities}
			options={opportunities}
			onChange={onChange}
			className={className}
			{...rest}
		/>
	);
};

export default ZVendorOpportunitySelect;
