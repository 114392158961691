import React, { useState, useMemo } from "react";
import EmailThreadActionCreators from "@/Actions/Others/EmailThread";
import ZInput from "@zomentum/molecules/dist/ZInput";
import ZInputNumber from "@zomentum/atoms/dist/ZInputNumber";
import ZSelect from "@zomentum/atoms/dist/ZSelect";
import ZRadioGroup from "@zomentum/atoms/dist/ZRadioGroup";
import ZTypography, { FontWeights } from "@zomentum/atoms/dist/ZTypography";
import ZModal from "@zomentum/atoms/dist/ZModal";
import ZTable from "@zomentum/organisms/dist/ZTable";
import ZDatePicker from "@zomentum/molecules/dist/ZDatePicker";
import { useSelector } from "react-redux";
import {
	MergeTagPreference,
	MergeTagVariable,
	MergeTagVariableDataType
} from "@zomentum/contracts/dist/Documents";
import { convertToISODate } from "@zomentum/utils";
import { IEmptyMergeTagsModalProps } from "./interface";
import { GlobalState } from "@/Reducers/interface";
import { ZomentumEntities } from "@zomentum/contracts";
import { MergeTagInput } from "@zomentum/contracts/dist/Documents";
import { ZMessage } from "@zomentum/molecules";
import useDispatch from "@/Components/Utils/useDispatch";
const { ZText, ZSectionHeading } = ZTypography;

const EmptyMergeTagsAlertModal: React.FC<IEmptyMergeTagsModalProps> = props => {
	const dispatch = useDispatch();
	const { allMergeTags } = useSelector((state: GlobalState) => {
		return {
			allMergeTags: state.emailThread.emailMergeTagsSystemVariable
		};
	});
	const [buildEmptyMergeTagData, setBuildEmptyMergeTagData] = useState([]);
	const [mergeTagPreference, setMergeTagPreference] =
		useState<MergeTagPreference>(MergeTagPreference.BLANK);
	const [mergeTagInputData, setMergeTagInputData] = useState<
		Record<string, any>
	>({});
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const handleMergeTagInput = (
		key: string,
		value?: string | number | null
	) => {
		value = value || value == 0 ? ("" + value).trim() : null;
		setMergeTagInputData({
			...mergeTagInputData,
			[key]: value ? value : null
		});
	};
	const handleNumberInputKeyDown = (
		e: React.KeyboardEvent<HTMLInputElement>
	) => {
		if (e.key === ".") {
			e.preventDefault();
			return;
		}
	};

	const renderInputFieldForMergeTag = (record: MergeTagVariable) => {
		if (record?.is_editable)
			switch (record?.data_type) {
				case MergeTagVariableDataType.DATE:
					return (
						<ZDatePicker
							className="w-full"
							onChange={e =>
								handleMergeTagInput(
									record?.variable_name,
									convertToISODate(e)
								)
							}
						/>
					);
				case MergeTagVariableDataType.DOUBLE:
					return (
						<ZInputNumber
							placeholder="Enter Value"
							width="full"
							disabled={!record?.is_editable}
							onChange={e =>
								handleMergeTagInput(record?.variable_name, e)
							}
						/>
					);
				case MergeTagVariableDataType.NUMBER:
					return (
						<ZInputNumber
							placeholder="Enter Value"
							width="full"
							disabled={!record?.is_editable}
							min={0}
							step={1}
							onChange={e =>
								handleMergeTagInput(record?.variable_name, e)
							}
							onKeyDown={handleNumberInputKeyDown}
						/>
					);
				case MergeTagVariableDataType.DROPDOWN:
					return (
						<ZSelect
							getPopupContainer={trigger =>
								window.document.querySelector(".z-table") ||
								trigger.parentNode
							}
							onChange={(e: string) =>
								handleMergeTagInput(record?.variable_name, e)
							}
							placeholder="Select Value"
							className="w-full"
							selectOptions={record?.allowed_values ?? []}
						/>
					);
				default:
					return (
						<ZInput
							width="full"
							placeholder="Enter Value"
							disabled={!record?.is_editable}
							onChange={e =>
								handleMergeTagInput(
									record?.variable_name,
									e.target.value
								)
							}
						/>
					);
			}
		else
			return (
				<ZInput width="full" placeholder="No data available" disabled />
			);
	};
	const emptyMergeTagColumn = [
		{
			title: "Merge Tags",
			dataIndex: "display_name",
			key: "display_name",
			render: (merge_tag: string) => {
				return (
					<div className="ant-legacy-form-item-required">
						<ZText className="bg-yellow-2 px-1">{`{{${merge_tag}}}`}</ZText>
					</div>
				);
			}
		},
		{
			title: "Values",
			dataIndex: "record",
			key: "record",
			render: (_text: unknown, record: MergeTagVariable) =>
				renderInputFieldForMergeTag(record)
		}
	];
	const handleRadioButtonChange = (value: MergeTagPreference) => {
		setMergeTagPreference(value);

		if (value === MergeTagPreference.ENTRY) {
			const emptyMergeTagData: any = [];
			allMergeTags?.map(merge_tag => {
				props.emptyMergeTags.map(tag => {
					if (tag === merge_tag.variable_name) {
						emptyMergeTagData.push(merge_tag);
					}
				});
			});

			setBuildEmptyMergeTagData(emptyMergeTagData);
		}
	};
	const handleUpdateMergeTags = async () => {
		if (
			props.isMergeTagMissing &&
			mergeTagPreference === MergeTagPreference.ENTRY &&
			(props.opportunityId || props.partnerId)
		) {
			const mergeTagsPayload: MergeTagInput = {
				entity_id: props.opportunityId ?? props.partnerId ?? "",
				entity_type:
					props.opportunityId && props.partnerId
						? ZomentumEntities.VendorOpportunity
						: props.opportunityId
						? ZomentumEntities.VendorOpportunity
						: ZomentumEntities.VendorPartner,
				merge_tags: []
			};
			Object.entries(mergeTagInputData).forEach(([key, value]) => {
				mergeTagsPayload.merge_tags.push({
					key: key,
					value: value ?? ""
				});
			});
			try {
				await dispatch(
					EmailThreadActionCreators.updateMergeTagsValue(
						mergeTagsPayload
					)
				);
				ZMessage.success("Merge tags updated");
			} catch (error) {
				console.error(error);
				setIsSubmitting(false);
				return;
			}
		}
	};
	const onSubmit = async () => {
		try {
			setIsSubmitting(true);
			await handleUpdateMergeTags();
			setIsSubmitting(false);
			props.confirmFromModal(mergeTagPreference);
		} catch (error) {
			console.error(error);
		}
	};
	const onCancel = () => {
		props.closeModal();
	};
	const isAnyMergeTagInputEmpty = useMemo(
		() => !!props.emptyMergeTags?.find(tag => !mergeTagInputData[tag]),
		[mergeTagInputData, props.emptyMergeTags]
	);
	const isOkButtonDisabled =
		mergeTagPreference === MergeTagPreference.ENTRY &&
		isAnyMergeTagInputEmpty;
	return (
		<ZModal
			title=""
			closable
			centered
			size="md"
			confirmLoading={isSubmitting}
			destroyOnClose
			visible={props.visible}
			onCancel={onCancel}
			okText={`${props.confirmText}`}
			okButtonProps={{ disabled: isOkButtonDisabled }}
			onOk={onSubmit}
		>
			{props.emptyMergeTags && !!props.emptyMergeTags.length && (
				<>
					<ZSectionHeading className="pt-6">
						Empty Merge Tags
					</ZSectionHeading>

					{props.emptyMergeTags && (
						<ZText colorVariant={7} className="mb-2 block">
							Total merge tags without assigned values:{" "}
							<ZText weight={FontWeights.MEDIUM}>
								{props.emptyMergeTags.length}
							</ZText>
						</ZText>
					)}

					{mergeTagPreference !== MergeTagPreference.ENTRY && (
						<div className="mb-2">
							{props.emptyMergeTags?.map((tag, index) => {
								const resolvedLabel = allMergeTags.find(
									mergeTag => mergeTag.variable_name === tag
								);

								return (
									<ZText
										className="bg-yellow-2 mr-2 px-1"
										key={index}
									>{`{{${
										resolvedLabel?.display_name ?? tag
									}}}`}</ZText>
								);
							})}
						</div>
					)}

					<ZRadioGroup
						onChange={e => handleRadioButtonChange(e.target.value)}
						value={mergeTagPreference}
						vertical
						options={[
							{
								label: "Replace all empty merge tags with blank",
								value: MergeTagPreference.BLANK
							},
							{
								label: "Do not replace (merge tags will be visible)",
								value: MergeTagPreference.REPLACE
							},
							{
								label: "Individual value entry for unassigned merge tags",
								value: MergeTagPreference.ENTRY
							}
						]}
						className="mb-4"
					/>

					{mergeTagPreference === MergeTagPreference.ENTRY &&
						!!buildEmptyMergeTagData.length && (
							<ZTable
								dataSource={buildEmptyMergeTagData}
								columns={emptyMergeTagColumn}
								size="small"
								bordered
								pagination={false}
								scroll={{
									y: "200px"
								}}
								rowKey={record => record.display_name}
								isTopBorder={false}
								loading={false}
							/>
						)}
				</>
			)}
		</ZModal>
	);
};

export default EmptyMergeTagsAlertModal;
