import { ETwoToneIcons } from "@zomentum/atoms/dist/ZIcon/interface";
import ZModal from "@zomentum/atoms/dist/ZModal";
import ZSection from "@zomentum/molecules/dist/ZSection";
import React from "react";
import ZPricingPlanBar from "@templates/ZVendor/ZPricingPlanBar";
import ZSKUProductInfo from "@templates/ZVendor/ZProductSKUs/molecules/ZSKUProductInfo";
import { ISKUPublishModalProps } from "./interface";
import { ZAlert, ZButton, ZTypography } from "@zomentum/atoms";

const { ZText } = ZTypography;

const ZSKUPublishModal: React.FC<ISKUPublishModalProps> = ({
	visible,
	onCancel,
	onPublish,
	currentSKU,
	loading,
	showSaveButton,
	onSave
}) => {
	return currentSKU ? (
		<ZModal
			visible={visible}
			title={`${currentSKU.name} Summary`}
			onCancel={onCancel}
			confirmLoading={loading}
			onOk={onPublish}
			size="md"
			footer={
				<div className="flex justify-between">
					<ZButton onClick={onCancel} loading={loading}>
						Cancel
					</ZButton>
					<div>
						{showSaveButton && (
							<ZButton
								onClick={onSave}
								type="default"
								className="border-blue-6 text-blue-6"
								loading={loading}
							>
								Save
							</ZButton>
						)}
						<ZButton
							onClick={onPublish}
							type="primary"
							loading={loading}
						>
							Yes, Publish
						</ZButton>
					</div>
				</div>
			}
		>
			<ZSKUProductInfo
				currentSKUProduct={currentSKU}
				isLoading={loading}
				shouldApplyLeftPaddingForChildren={false}
			/>
			{!!currentSKU?.pricings && (
				<ZSection
					heading="Product Pricing"
					bottomBorder={false}
					isTwoToneIcon
					icon={ETwoToneIcons.DOLLAR}
					shouldApplyLeftPaddingForChildren={false}
				>
					<ZPricingPlanBar pricingPlans={currentSKU?.pricings} />
				</ZSection>
			)}
			<ZAlert
				type="warning"
				showIcon
				message={
					<ZText level={0} colorVariant={8}>
						Once published, you can only clone the SKU. Editing or
						Deleting SKU is not allowed.
					</ZText>
				}
			/>
		</ZModal>
	) : null;
};

export default ZSKUPublishModal;
