'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.ExceptionType = void 0;
(function (ExceptionType) {
    ExceptionType["CfAuthorizationException"] = "cf_authorization_exception";
    ExceptionType["InvalidMergeTagTypeException"] = "invalid_merge_tag_exception";
    ExceptionType["UnexpectedDatabaseException"] = "unexpected_database_exception";
    ExceptionType["CompanyNotFoundException"] = "company_not_found_exception";
    ExceptionType["UserNotFoundException"] = "user_not_found_exception";
    ExceptionType["PipelineException"] = "pipeline_exception";
    ExceptionType["BadParamsQueryBuilderException"] = "bad_params_query_builder_exception";
    ExceptionType["UniquenessConstraintException"] = "uniqueness_constraint_exception";
    ExceptionType["DryRunException"] = "dry_run_exception";
    ExceptionType["EntityNotFoundException"] = "entity_not_found_exception";
    ExceptionType["ValidationException"] = "validation_exception";
    ExceptionType["UnsafePutRequestException"] = "unsafe_put_request_exception";
    ExceptionType["BadRequestException"] = "bad_request_exception";
    ExceptionType["InternalServerException"] = "internal_server_exception";
    ExceptionType["AuthSupportException"] = "auth_support_exception";
    ExceptionType["ExpiredTokenException"] = "expired_Token_exception";
    ExceptionType["ReAuthorizeException"] = "re_authorize_exception";
    ExceptionType["AccessException"] = "access_exception";
    ExceptionType["IntegrationException"] = "integration_exception";
    ExceptionType["ServiceUnavailableException"] = "service_unavailable_exception";
    ExceptionType["PdfGenerationException"] = "pdf_generation_exception";
    ExceptionType["InsufficientRuleException"] = "insufficient_balance";
    ExceptionType["ConflictException"] = "conflict_exception";
})(exports.ExceptionType || (exports.ExceptionType = {}));
exports.messageType = void 0;
(function (messageType) {
    messageType["SUCCESS"] = "success";
    messageType["ERROR"] = "error";
    messageType["WARN"] = "warn";
    messageType["INFO"] = "info";
})(exports.messageType || (exports.messageType = {}));
exports.ErrorType = void 0;
(function (ErrorType) {
    ErrorType[ErrorType["ERROR"] = 0] = "ERROR";
    ErrorType[ErrorType["SUCCESS"] = 200] = "SUCCESS";
    ErrorType[ErrorType["ERROR_HELP"] = 400] = "ERROR_HELP";
    ErrorType[ErrorType["ERROR_REPORT"] = 500] = "ERROR_REPORT";
})(exports.ErrorType || (exports.ErrorType = {}));
exports.MixpanelErrorTypes = void 0;
(function (MixpanelErrorTypes) {
    MixpanelErrorTypes["NEED_HELP_BUTTON_CLICKED"] = "Need help button clicked";
    MixpanelErrorTypes["REPORT_THIS_BUTTON_CLICKED"] = "Report this button clicked";
    MixpanelErrorTypes["NEED_HELP_ERROR_SHOWN"] = "Need help error shown";
    MixpanelErrorTypes["REPORT_THIS_ERROR_SHOWN"] = "Report this error shown";
    MixpanelErrorTypes["DEFAULT_SERVER_ERROR"] = "Error other than need-help/report-this shown";
})(exports.MixpanelErrorTypes || (exports.MixpanelErrorTypes = {}));
