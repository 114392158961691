'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../../../node_modules/tslib/tslib.es6.mjs.js');
var index = require('../../actions/Parent/index.js');

var initialParentState = {
  currentActiveDocument: null,
  currentActiveTemplate: null,
  taxCategoriesList: new Array(),
  taxRegionsList: new Array()
};
var ParentStateReducer = function ParentStateReducer(state, action) {
  var _a;
  if (state === void 0) {
    state = initialParentState;
  }
  switch (action.type) {
    case index.ParentStateActionTypes.UPDATE_PARENT_STATE:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), action.parentState);
      }
    case index.ParentStateActionTypes.UPDATE_CURRENT_ACTIVE_DOCUMENT:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentActiveDocument: action.currentActiveDocument
        });
      }
    case index.ParentStateActionTypes.UPDATE_CURRENT_ACTIVE_TEMPLATE:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentActiveTemplate: action.currentActiveTemplate
        });
      }
    case index.ParentStateActionTypes.UPDATE_CURRENT_DOC_PAYMENT_SUMMARY_SUCCESSFUL:
      {
        var currentActiveDocument = state.currentActiveDocument ? tslib_es6.__assign({}, state.currentActiveDocument) : null;
        if (!action.paymentSummary || !currentActiveDocument) {
          return tslib_es6.__assign({}, state);
        }
        var _b = action.paymentSummary,
          total = _b.total,
          total_adjustment_fees = _b.total_adjustment_fees,
          total_discount = _b.total_discount,
          total_tax = _b.total_tax,
          commission = _b.commission,
          margin = _b.margin,
          margin_percentage = _b.margin_percentage;
        currentActiveDocument.total_discount = total_discount;
        currentActiveDocument.commission = commission;
        currentActiveDocument.total_tax = total_tax;
        if ((_a = currentActiveDocument.revisions) === null || _a === void 0 ? void 0 : _a.length) {
          if (total !== null) {
            var newTotal = tslib_es6.__assign({}, total);
            newTotal.margin_percentage = margin_percentage !== null && margin_percentage !== void 0 ? margin_percentage : 0;
            newTotal.margin_value = margin !== null && margin !== void 0 ? margin : 0;
            currentActiveDocument.revisions[0].total = newTotal;
          } else currentActiveDocument.revisions[0].total = total !== null && total !== void 0 ? total : undefined;
        }
        if (currentActiveDocument.payment) {
          currentActiveDocument.payment.payment_options = total_adjustment_fees !== null && total_adjustment_fees !== void 0 ? total_adjustment_fees : undefined;
        }
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentActiveDocument: currentActiveDocument
        });
      }
    default:
      {
        return state;
      }
  }
};
var ParentStateReducer$1 = ParentStateReducer;

exports["default"] = ParentStateReducer$1;
exports.initialParentState = initialParentState;
