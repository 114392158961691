'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var _a;
exports.Modules = void 0;
(function (Modules) {
  Modules["ALIGN"] = "align";
  Modules["OPPORTUNITY"] = "opportunity";
  Modules["DOCUMENT"] = "document";
  Modules["INTEGRATION"] = "integration";
})(exports.Modules || (exports.Modules = {}));
exports.Stages = void 0;
(function (Stages) {
  //opportunity module
  Stages["OPPORTUNITY_INTRODUCTION"] = "opportunity_introduction";
  Stages["ADD_OPPORTUNITY"] = "add_opportunity";
  Stages["OPPORTUNITY_ACTIVITIES"] = "activities";
  //document module
  Stages["DOCUMENT_INTRODUCTION"] = "document_introduction";
  Stages["ADD_DOCUMENT"] = "add_document";
  Stages["SELECTING_TEMPLATE"] = "selecting_template";
  Stages["EXPLORE_EDITOR"] = "explore_editor";
  //Align module
  Stages["ALIGN_INTRODUCTION"] = "align_introduction";
  Stages["ALIGN_VENDORS_AND_RESOURCES"] = "vendors_and_resources";
  Stages["ALIGN_EXPLORE_RESOURCES"] = "explore_resources";
  //Integration module
  Stages["INTEGRATION_SELECT_APP"] = "select_app";
  Stages["INTEGRATION_INTRODUCTION"] = "integration_introduction";
  Stages["INTEGRATION_APPS"] = "integrating_apps";
})(exports.Stages || (exports.Stages = {}));
var ModuleStageMap = (_a = {}, _a[exports.Modules.ALIGN] = [exports.Stages.ALIGN_INTRODUCTION, exports.Stages.ALIGN_VENDORS_AND_RESOURCES, exports.Stages.ALIGN_EXPLORE_RESOURCES], _a[exports.Modules.OPPORTUNITY] = [exports.Stages.OPPORTUNITY_INTRODUCTION, exports.Stages.ADD_OPPORTUNITY, exports.Stages.OPPORTUNITY_ACTIVITIES], _a[exports.Modules.DOCUMENT] = [exports.Stages.DOCUMENT_INTRODUCTION, exports.Stages.ADD_DOCUMENT, exports.Stages.SELECTING_TEMPLATE, exports.Stages.EXPLORE_EDITOR], _a[exports.Modules.INTEGRATION] = [exports.Stages.INTEGRATION_SELECT_APP, exports.Stages.INTEGRATION_INTRODUCTION, exports.Stages.INTEGRATION_APPS], _a);
exports.Steps = void 0;
(function (Steps) {
  Steps["WELCOME_VIDEO"] = "welcome_video";
  /* document module */
  //INTRODUCTION
  Steps["HIGHLIGHT_DOCUMENT_MENUITEM"] = "highlight_document_menuitem";
  Steps["SHOW_DOCUMENT_INTRO_VIDEO"] = "show_document_intro_video";
  //ADD_DOCUMENT
  Steps["HIGHLIGHT_ADD_DOCUMENT_BUTTON"] = "highlight_add_document_button";
  Steps["SHOW_SELECT_TEMPLATE"] = "show_select_template";
  //SELECTING_TEMPLATE
  Steps["HIGHLIGHT_SELECT_TEMPLATE"] = "highlight_select_template";
  Steps["SHOW_CREATE_DOCUMENT"] = "show_create_document";
  //EXPLORE_EDITOR
  Steps["SHOW_DRAG_DROP_BLOCK"] = "show_drag_drop_block";
  Steps["SHOW_BLOCK_SAVE_BLOCK"] = "show_block_save_block";
  Steps["SHOW_SEND_DOCUMENT_IN_EDITOR"] = "show_send_document";
  Steps["SHOW_SEND_EMAIL"] = "show_send_email";
  Steps["SHOW_GO_BACK_TO_DOCUMENT_LIST"] = "show_go_back_to_document_list";
  Steps["HIGHLIGHT_CREATED_DOCUMENT_IN_LIST"] = "highlight_created_document_in_list";
  /* opportunity module */
  //INTRODUCTION
  Steps["HIGHLIGHT_OPPORTUNITY_MENUITEM"] = "highlight_opportunity_menuitem";
  Steps["SHOW_OPPORTUNITY_INTRO_VIDEO"] = "show_opportunity_intro_video";
  //ADD OPPORTUNITY
  Steps["HIGHLIGHT_ADD_OPPORTUNITY_BUTTON"] = "highlight_add_opportunity_button";
  Steps["HIGHLIGHT_ADD_OPPORTUNITY_DRAWER"] = "highlight_add_opportunity_drawer";
  Steps["HIGHLIGHT_OPPORTUNITY_SHARE_WITH_VENDOR_CHECKBOX"] = "highlight_opportunity_share_with_vendor_checkbox";
  Steps["HIGHLIGHT_OPPORTUNITY_SAVE_BUTTON"] = "highlight_opportunity_save_button";
  //OPPROTUNITY ACTIVITIES
  Steps["SHOW_OPPORTUNITY_CONGRATULATION_MODAL"] = "show_opportunity_congratulation_modal";
  /* align module */
  //INTRODUCTION
  Steps["HIGHLIGHT_ALIGN_MENUITEM"] = "highlight_align_menuitem";
  Steps["HIGHLIGHT_ALIGN_RESOURCES_BUTTON"] = "highlight_align_resources_button";
  Steps["HIGHLIGHT_ALIGN_VENDOR_RESOURCE_FOLDER"] = "highlight_align_vendor_resource_folder";
  Steps["HIGHLIGHT_ALIGN_VENDOR_RESOURCE_FILE"] = "highlight_align_vendor_resource_file";
  Steps["SHOW_ALIGN_CONGRATULATION_MODAL"] = "show_align_congratulation_modal";
})(exports.Steps || (exports.Steps = {}));
exports.WelcomeVideoStatus = void 0;
(function (WelcomeVideoStatus) {
  WelcomeVideoStatus["UNOPENED"] = "unopened";
  WelcomeVideoStatus["OPENED"] = "opened";
  WelcomeVideoStatus["SKIPPED"] = "skipped";
  WelcomeVideoStatus["WATCHED"] = "watched";
  WelcomeVideoStatus["DONT_SHOW_AGAIN"] = "dont_show_again";
})(exports.WelcomeVideoStatus || (exports.WelcomeVideoStatus = {}));
exports.SignupStatusValues = void 0;
(function (SignupStatusValues) {
  SignupStatusValues["LINK_UNOPENED"] = "link_unopened";
  SignupStatusValues["LINK_OPENED"] = "link_opened";
  SignupStatusValues["COMPLETED"] = "complete";
})(exports.SignupStatusValues || (exports.SignupStatusValues = {}));
var GuideIntroductionSteps = [exports.Steps.HIGHLIGHT_DOCUMENT_MENUITEM, exports.Steps.HIGHLIGHT_OPPORTUNITY_MENUITEM, exports.Steps.HIGHLIGHT_ALIGN_MENUITEM];

exports.GuideIntroductionSteps = GuideIntroductionSteps;
exports.ModuleStageMap = ModuleStageMap;
