import {
	VendorOpportunityCount,
	VendorOpportunityView,
	VendorOpportunity,
	VendorOpportunityRequest
} from "@zomentum/contracts/dist/Vendor/Opportunity";
import { Dispatch } from "redux";
import { OpportunityDrawerMode } from "@pages/Vendor/Opportunities/Drawers/OpportunityDrawer/interface";
import {
	createOpportunityView,
	updateOpportunityView,
	deleteOpportunityView,
	getAllOpportunityViews,
	editVendorOpportunity
} from "@/Services/Vendor/Opportunity";
import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { CustomViewResponse, CustomView } from "@zomentum/contracts/dist/UI";
import { KanbanState } from "@/Reducers/Vendor/Opportunity";
import { GlobalState } from "@/Reducers/interface";
import { DropResult } from "react-beautiful-dnd";
import { move, reorder } from "@/Components/Utils";
import { ChildEntityHydrationParams } from "@zomentum/contracts/dist/Utils";
import { areFiltersChanged } from "@zomentum/utils/dist/common";

export enum VendorOpportunityActionTypes {
	SHOW_OPPORTUNITY_LOADER = "SHOW_OPPORTUNITY_LOADER",
	HIDE_OPPORTUNITY_LOADER = "HIDE_OPPORTUNITY_LOADER",
	UPDATE_KANBAN_STATE = "UPDATE_KANBAN_STATE",
	OPEN_ADD_OPPORTUNITY_DRAWER = "OPEN_ADD_OPPORTUNITY_DRAWER",
	CLOSE_ADD_OPPORTUNITY_DRAWER = "CLOSE_ADD_OPPORTUNITY_DRAWER",
	SHOW_REASSIGN_MODAL = "SHOW_REASSIGN_MODAL",
	HIDE_REASSIGN_MODAL = "HIDE_REASSIGN_MODAL",
	SHOW_DELETE_OPPORTUNITY_MODAL = "SHOW_DELETE_OPPORTUNITY_MODAL",
	HIDE_DELETE_OPPORTUNITY_MODAL = "HIDE_DELETE_OPPORTUNITY_MODAL",
	UPDATE_OPPORTUNITY_LIST = "UPDATE_OPPORTUNITY_LIST",
	UPDATE_OPPORTUNITY_LIST_COUNT = "UPDATE_OPPORTUNITY_LIST_COUNT",
	UPDATE_ACTIVE_OPPORTUNITY_VIEW = "UPDATE_ACTIVE_OPPORTUNITY_VIEW",
	UPDATE_CURRENT_ACTIVE_OPPORTUNITY = "UPDATE_CURRENT_ACTIVE_OPPORTUNITY",
	DELETE_OPPORTUNITY_ON_KANBAN = "DELETE_OPPORTUNITY_ON_KANBAN",
	OPEN_UPCOMING_ACTIVITIES_DRAWER = "OPEN_UPCOMING_ACTIVITIES_DRAWER",
	CLOSE_UPCOMING_ACTIVITIES_DRAWER = "CLOSE_UPCOMING_ACTIVITIES_DRAWER",

	CREATE_OPPORTUNITY_VIEW_REQUESTED = "CREATE_OPPORTUNITY_VIEW_REQUESTED",
	CREATE_OPPORTUNITY_VIEW_SUCCESSFUL = "CREATE_OPPORTUNITY_VIEW_SUCCESSFUL",
	CREATE_OPPORTUNITY_VIEW_FAILED = "CREATE_OPPORTUNITY_VIEW_FAILED",

	UPDATE_OPPORTUNITY_VIEW_REQUESTED = "UPDATE_OPPORTUNITY_VIEW_REQUESTED",
	UPDATE_OPPORTUNITY_VIEW_SUCCESSFUL = "UPDATE_OPPORTUNITY_VIEW_SUCCESSFUL",
	UPDATE_OPPORTUNITY_VIEW_FAILED = "UPDATE_OPPORTUNITY_VIEW_FAILED",

	DELETE_OPPORTUNITY_VIEW_REQUESTED = "DELETE_OPPORTUNITY_VIEW_REQUESTED",
	DELETE_OPPORTUNITY_VIEW_SUCCESSFUL = "DELETE_OPPORTUNITY_VIEW_SUCCESSFUL",
	DELETE_OPPORTUNITY_VIEW_FAILED = "DELETE_OPPORTUNITY_VIEW_FAILED",

	UPDATE_OPPORTUNITY_CURRENT_ACTIVE_VIEW = "UPDATE_OPPORTUNITY_CURRENT_ACTIVE_VIEW",
	RESET_OPPORTUNITY_CURRENT_ACTIVE_VIEW = "RESET_OPPORTUNITY_CURRENT_ACTIVE_VIEW",

	FETCH_ALL_OPPORTUNITY_VIEWS_REQUESTED = "FETCH_ALL_OPPORTUNITY_VIEWS_REQUESTED",
	FETCH_ALL_OPPORTUNITY_VIEWS_SUCCESSFUL = "FETCH_ALL_OPPORTUNITY_VIEWS_SUCCESSFUL",
	FETCH_ALL_OPPORTUNITY_VIEWS_FAILED = "FETCH_ALL_OPPORTUNITY_VIEWS_FAILED",
	OPPORTUNITIES_RESET = "OPPORTUNITIES_RESET"
}

export type ShowOpportunityLoaderAction =
	Action<VendorOpportunityActionTypes.SHOW_OPPORTUNITY_LOADER>;

export type HideOpportunityLoaderAction =
	Action<VendorOpportunityActionTypes.HIDE_OPPORTUNITY_LOADER>;

export interface UpdateKanbanStateAction
	extends Action<VendorOpportunityActionTypes.UPDATE_KANBAN_STATE> {
	readonly kanbanState: KanbanState;
}

export interface OpenAddOpportunityDrawerAction
	extends Action<VendorOpportunityActionTypes.OPEN_ADD_OPPORTUNITY_DRAWER> {
	readonly opportunityDrawerMode: OpportunityDrawerMode;
	readonly currentActiveOpportunity: VendorOpportunity | null;
}

export type CloseAddOpportunityDrawerAction =
	Action<VendorOpportunityActionTypes.CLOSE_ADD_OPPORTUNITY_DRAWER>;

export interface ShowReAssignModalAction
	extends Action<VendorOpportunityActionTypes.SHOW_REASSIGN_MODAL> {
	readonly currentActiveOpportunity: VendorOpportunity;
}

export interface HideReAssignModalAction
	extends Action<VendorOpportunityActionTypes.HIDE_REASSIGN_MODAL> {
	readonly opportunityList: VendorOpportunity[];
}

export interface ShowDeleteOpportunityModalAction
	extends Action<VendorOpportunityActionTypes.SHOW_DELETE_OPPORTUNITY_MODAL> {
	readonly currentActiveOpportunity: VendorOpportunity;
}

export type HideDeleteOpportunityModalAction =
	Action<VendorOpportunityActionTypes.HIDE_DELETE_OPPORTUNITY_MODAL>;

export interface UpdateOpportunityListAction
	extends Action<VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_LIST> {
	readonly opportunityList: VendorOpportunity[];
}

export interface UpdateOpportunityListAction
	extends Action<VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_LIST> {
	readonly opportunityList: VendorOpportunity[];
}

export interface UpdateOpportunityListCountAction
	extends Action<VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_LIST_COUNT> {
	readonly opportunityListCount: VendorOpportunityCount | null;
}

export interface UpdateActiveOpportunityViewAction
	extends Action<VendorOpportunityActionTypes.UPDATE_ACTIVE_OPPORTUNITY_VIEW> {
	readonly activeOpportunityView: VendorOpportunityView;
}

export interface UpdateCurrentActiveOpportunityAction
	extends Action<VendorOpportunityActionTypes.UPDATE_CURRENT_ACTIVE_OPPORTUNITY> {
	readonly currentActiveOpportunity: VendorOpportunity | null;
}

export interface DeleteOpportunityOnKanbanAction
	extends Action<VendorOpportunityActionTypes.DELETE_OPPORTUNITY_ON_KANBAN> {
	readonly opportunity: VendorOpportunity;
}

export interface OpenUpcomingActivitiesDrawerAction
	extends Action<VendorOpportunityActionTypes.OPEN_UPCOMING_ACTIVITIES_DRAWER> {
	readonly currentActiveOpportunity: VendorOpportunity;
}

export type CloseUpcomingActivitiesDrawerAction =
	Action<VendorOpportunityActionTypes.CLOSE_UPCOMING_ACTIVITIES_DRAWER>;

export interface UpdateCurrentActiveViewAction
	extends Action<VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_CURRENT_ACTIVE_VIEW> {
	readonly currentActiveView: CustomView;
	readonly isViewChanged: boolean;
}

export type CreateOpportunityViewRequestedAction =
	Action<VendorOpportunityActionTypes.CREATE_OPPORTUNITY_VIEW_REQUESTED>;
export interface CreateOpportunityViewSuccessfulAction
	extends Action<VendorOpportunityActionTypes.CREATE_OPPORTUNITY_VIEW_SUCCESSFUL> {
	readonly view: CustomView;
}
export type CreateOpportunityViewFailedAction =
	Action<VendorOpportunityActionTypes.CREATE_OPPORTUNITY_VIEW_FAILED>;

export type UpdateOpportunityViewRequestedAction =
	Action<VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_VIEW_REQUESTED>;
export interface UpdateOpportunityViewSuccessfulAction
	extends Action<VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_VIEW_SUCCESSFUL> {
	readonly view: CustomView;
}
export type UpdateOpportunityViewFailedAction =
	Action<VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_VIEW_FAILED>;

export type DeleteOpportunityViewRequestedAction =
	Action<VendorOpportunityActionTypes.DELETE_OPPORTUNITY_VIEW_REQUESTED>;
export interface DeleteOpportunityViewSuccessfulAction
	extends Action<VendorOpportunityActionTypes.DELETE_OPPORTUNITY_VIEW_SUCCESSFUL> {
	readonly deletedView: CustomView;
	readonly initialView: CustomView;
}
export type OpportunitiesResetAction =
	Action<VendorOpportunityActionTypes.OPPORTUNITIES_RESET>;
export type DeleteOpportunityViewFailedAction =
	Action<VendorOpportunityActionTypes.DELETE_OPPORTUNITY_VIEW_FAILED>;

export type ResetCurrentActiveViewAction =
	Action<VendorOpportunityActionTypes.RESET_OPPORTUNITY_CURRENT_ACTIVE_VIEW>;

export type FetchAllOpportunityViewRequested =
	Action<VendorOpportunityActionTypes.FETCH_ALL_OPPORTUNITY_VIEWS_REQUESTED>;
export interface FetchAllOpportunityViewSuccessful
	extends Action<VendorOpportunityActionTypes.FETCH_ALL_OPPORTUNITY_VIEWS_SUCCESSFUL> {
	readonly viewList: CustomViewResponse;
}
export type FetchAllOpportunityViewFailed =
	Action<VendorOpportunityActionTypes.FETCH_ALL_OPPORTUNITY_VIEWS_FAILED>;

export type VendorOpportunityActions =
	| ShowOpportunityLoaderAction
	| HideOpportunityLoaderAction
	| UpdateKanbanStateAction
	| OpenAddOpportunityDrawerAction
	| CloseAddOpportunityDrawerAction
	| ShowReAssignModalAction
	| HideReAssignModalAction
	| ShowDeleteOpportunityModalAction
	| HideDeleteOpportunityModalAction
	| UpdateOpportunityListAction
	| UpdateOpportunityListCountAction
	| UpdateActiveOpportunityViewAction
	| UpdateCurrentActiveOpportunityAction
	| DeleteOpportunityOnKanbanAction
	| OpenUpcomingActivitiesDrawerAction
	| CloseUpcomingActivitiesDrawerAction
	| CreateOpportunityViewRequestedAction
	| CreateOpportunityViewSuccessfulAction
	| CreateOpportunityViewFailedAction
	| UpdateOpportunityViewRequestedAction
	| UpdateOpportunityViewSuccessfulAction
	| UpdateOpportunityViewFailedAction
	| DeleteOpportunityViewRequestedAction
	| DeleteOpportunityViewSuccessfulAction
	| DeleteOpportunityViewFailedAction
	| ResetCurrentActiveViewAction
	| FetchAllOpportunityViewRequested
	| FetchAllOpportunityViewSuccessful
	| UpdateCurrentActiveViewAction
	| OpportunitiesResetAction
	| FetchAllOpportunityViewFailed;

export interface VendorOpportunityDispatch {
	showOpportunityLoader: ActionCreator<
		ThunkAction<
			ShowOpportunityLoaderAction,
			GlobalState,
			null,
			ShowOpportunityLoaderAction
		>
	>;
	updateCurrentActiveView: ActionCreator<
		ThunkAction<
			UpdateCurrentActiveViewAction,
			GlobalState,
			{ selectCurrentActiveView: CustomView; discardingView?: boolean },
			UpdateCurrentActiveViewAction
		>
	>;
	resetOpportunities: ActionCreator<
		ThunkAction<
			OpportunitiesResetAction,
			GlobalState,
			VendorOpportunity,
			OpportunitiesResetAction
		>
	>;
	acceptPendingOpportunity: ActionCreator<
		ThunkAction<
			void,
			GlobalState,
			VendorOpportunity,
			UpdateKanbanStateAction
		>
	>;
	createOpportunityView: ActionCreator<
		ThunkAction<
			Promise<CreateOpportunityViewSuccessfulAction>,
			GlobalState,
			CustomView,
			CreateOpportunityViewSuccessfulAction
		>
	>;
	updateOpportunityView: ActionCreator<
		ThunkAction<
			Promise<UpdateOpportunityViewSuccessfulAction>,
			GlobalState,
			CustomView,
			UpdateOpportunityViewSuccessfulAction
		>
	>;
	deleteOpportunityView: ActionCreator<
		ThunkAction<
			Promise<DeleteOpportunityViewSuccessfulAction>,
			GlobalState,
			{ deleteView: CustomView; initialView: CustomView },
			DeleteOpportunityViewSuccessfulAction
		>
	>;
	resetViews: ActionCreator<
		ThunkAction<
			ResetCurrentActiveViewAction,
			GlobalState,
			null,
			ResetCurrentActiveViewAction
		>
	>;
	fetchAllOpportunityView: ActionCreator<
		ThunkAction<
			Promise<FetchAllOpportunityViewSuccessful>,
			GlobalState,
			null,
			FetchAllOpportunityViewSuccessful
		>
	>;
	hideOpportunityLoader: ActionCreator<
		ThunkAction<
			HideOpportunityLoaderAction,
			GlobalState,
			null,
			HideOpportunityLoaderAction
		>
	>;
	updateKanbanStateOnEditOrAddOpportunity: ActionCreator<
		ThunkAction<
			UpdateKanbanStateAction,
			GlobalState,
			null,
			UpdateKanbanStateAction
		>
	>;
	updateKanbanState: ActionCreator<
		ThunkAction<
			UpdateKanbanStateAction,
			GlobalState,
			null,
			UpdateKanbanStateAction
		>
	>;
	openAddOpportunityDrawer: ActionCreator<
		ThunkAction<
			OpenAddOpportunityDrawerAction,
			GlobalState,
			null,
			OpenAddOpportunityDrawerAction
		>
	>;
	closeAddOpportunityDrawer: ActionCreator<
		ThunkAction<
			CloseAddOpportunityDrawerAction,
			GlobalState,
			null,
			CloseAddOpportunityDrawerAction
		>
	>;
	showReAssignModal: ActionCreator<
		ThunkAction<
			ShowReAssignModalAction,
			GlobalState,
			null,
			ShowReAssignModalAction
		>
	>;
	hideReAssignModal: ActionCreator<
		ThunkAction<
			HideReAssignModalAction,
			GlobalState,
			null,
			HideReAssignModalAction
		>
	>;
	showDeleteOpportunityModal: ActionCreator<
		ThunkAction<
			ShowDeleteOpportunityModalAction,
			GlobalState,
			null,
			ShowDeleteOpportunityModalAction
		>
	>;
	hideDeleteOpportunityModal: ActionCreator<
		ThunkAction<
			HideDeleteOpportunityModalAction,
			GlobalState,
			null,
			HideDeleteOpportunityModalAction
		>
	>;
	updateOpportunityList: ActionCreator<
		ThunkAction<
			UpdateOpportunityListAction,
			GlobalState,
			null,
			UpdateOpportunityListAction
		>
	>;
	updateOpportunityListCount: ActionCreator<
		ThunkAction<
			UpdateOpportunityListCountAction,
			GlobalState,
			null,
			UpdateOpportunityListCountAction
		>
	>;
	updateActiveOpportunityView: ActionCreator<
		ThunkAction<
			UpdateActiveOpportunityViewAction,
			GlobalState,
			null,
			UpdateActiveOpportunityViewAction
		>
	>;
	updateCurrentActiveOpportunity: ActionCreator<
		ThunkAction<
			UpdateCurrentActiveOpportunityAction,
			GlobalState,
			null,
			UpdateCurrentActiveOpportunityAction
		>
	>;
	deleteOpportunityOnKanban: ActionCreator<
		ThunkAction<
			DeleteOpportunityOnKanbanAction,
			GlobalState,
			null,
			DeleteOpportunityOnKanbanAction
		>
	>;
	openUpcomingActivitiesDrawer: ActionCreator<
		ThunkAction<
			OpenUpcomingActivitiesDrawerAction,
			GlobalState,
			null,
			OpenUpcomingActivitiesDrawerAction
		>
	>;
	closeUpcomingActivitiesDrawer: ActionCreator<
		ThunkAction<
			CloseUpcomingActivitiesDrawerAction,
			GlobalState,
			null,
			CloseUpcomingActivitiesDrawerAction
		>
	>;
	reorderOpportunityInKanban: ActionCreator<
		ThunkAction<
			Promise<UpdateKanbanStateAction>,
			GlobalState,
			DropResult,
			UpdateKanbanStateAction
		>
	>;
}

export const VendorOpportunityActionCreators: VendorOpportunityDispatch = {
	showOpportunityLoader: () => {
		return (dispatch: Dispatch): ShowOpportunityLoaderAction => {
			const showOpportunityLoaderAction: ShowOpportunityLoaderAction = {
				type: VendorOpportunityActionTypes.SHOW_OPPORTUNITY_LOADER
			};
			return dispatch(showOpportunityLoaderAction);
		};
	},
	hideOpportunityLoader: () => {
		return (dispatch: Dispatch): HideOpportunityLoaderAction => {
			const hideOpportunityLoaderAction: HideOpportunityLoaderAction = {
				type: VendorOpportunityActionTypes.HIDE_OPPORTUNITY_LOADER
			};
			return dispatch(hideOpportunityLoaderAction);
		};
	},
	resetOpportunities: () => {
		return (dispatch: Dispatch) => {
			const opportunitiesResetAction: OpportunitiesResetAction = {
				type: VendorOpportunityActionTypes.OPPORTUNITIES_RESET
			};
			return dispatch(opportunitiesResetAction);
		};
	},
	acceptPendingOpportunity: (opportunity: VendorOpportunity) => {
		return (dispatch: Dispatch, getState: () => GlobalState) => {
			const prevKanbanState = getState().vendorOpportunity.kanbanState;
			const oldList =
				prevKanbanState.list[
					opportunity.opportunity_pipeline_stage_id
				] ?? [];
			const updateKanbanStateAction: UpdateKanbanStateAction = {
				type: VendorOpportunityActionTypes.UPDATE_KANBAN_STATE,
				kanbanState: {
					...prevKanbanState,
					data: {
						...prevKanbanState.data,
						[opportunity.id]: opportunity
					},
					list: {
						...prevKanbanState.list,
						[opportunity.opportunity_pipeline_stage_id]:
							oldList?.includes(opportunity.id)
								? oldList
								: [opportunity.id, ...oldList]
					},
					count: {
						...prevKanbanState.count,
						[opportunity.opportunity_pipeline_stage_id]:
							prevKanbanState.count[
								opportunity.opportunity_pipeline_stage_id
							] + 1
					},
					estimatedValue: {
						...prevKanbanState.estimatedValue,
						[opportunity.opportunity_pipeline_stage_id]:
							prevKanbanState.estimatedValue[
								opportunity.opportunity_pipeline_stage_id
							] + 1
					}
				}
			};
			const prevOpportunityList =
				getState().vendorOpportunity.opportunityList;
			const updateOpportunityListAction: UpdateOpportunityListAction = {
				type: VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_LIST,
				opportunityList: [opportunity, ...prevOpportunityList]
			};
			dispatch(updateOpportunityListAction);
			dispatch(updateKanbanStateAction);
		};
	},
	updateKanbanStateOnEditOrAddOpportunity: (
		opportunity: VendorOpportunity
	) => {
		return (
			dispatch: Dispatch,
			getState: () => GlobalState
		): UpdateKanbanStateAction => {
			const prevKanbanState = getState().vendorOpportunity.kanbanState;
			const hasChangedStages =
				!!prevKanbanState.data[opportunity.id] &&
				prevKanbanState.data[opportunity.id]
					.opportunity_pipeline_stage_id !==
					opportunity.opportunity_pipeline_stage_id;

			const isNewOpportunity = !prevKanbanState.data[opportunity.id];

			const count: Record<string, number> = {
				...prevKanbanState.count
			};
			const estimatedValue: Record<string, number> = {
				...prevKanbanState.estimatedValue
			};
			const list: Record<string, string[]> = {
				...prevKanbanState.list
			};

			if (isNewOpportunity) {
				count[opportunity.opportunity_pipeline_stage_id] += 1;
				estimatedValue[opportunity.opportunity_pipeline_stage_id] +=
					opportunity.estimated_opportunity_value ?? 0;
				list[opportunity.opportunity_pipeline_stage_id].unshift(
					opportunity.id
				);
			} else if (hasChangedStages) {
				count[opportunity.opportunity_pipeline_stage_id] += 1;
				count[
					prevKanbanState.data[
						opportunity.id
					].opportunity_pipeline_stage_id
				] -= 1;
				estimatedValue[opportunity.opportunity_pipeline_stage_id] +=
					opportunity.estimated_opportunity_value ?? 0;
				estimatedValue[
					prevKanbanState.data[
						opportunity.id
					].opportunity_pipeline_stage_id
				] -=
					prevKanbanState.data[opportunity.id]
						.estimated_opportunity_value ?? 0;
				list[opportunity.opportunity_pipeline_stage_id].unshift(
					opportunity.id
				);
				list[
					prevKanbanState.data[
						opportunity.id
					].opportunity_pipeline_stage_id
				] = list[
					prevKanbanState.data[opportunity.id]
						.opportunity_pipeline_stage_id
				].filter(opportunityId => opportunityId !== opportunity.id);
			}

			const updateKanbanStateAction: UpdateKanbanStateAction = {
				type: VendorOpportunityActionTypes.UPDATE_KANBAN_STATE,
				kanbanState: {
					...prevKanbanState,
					data: {
						...prevKanbanState.data,
						[opportunity.id]: opportunity
					},
					list,
					count,
					estimatedValue
				}
			};
			return dispatch(updateKanbanStateAction);
		};
	},
	updateKanbanState: (newKanbanState: KanbanState) => {
		return (dispatch: Dispatch): UpdateKanbanStateAction => {
			const updateKanbanStateAction: UpdateKanbanStateAction = {
				type: VendorOpportunityActionTypes.UPDATE_KANBAN_STATE,
				kanbanState: newKanbanState
			};
			return dispatch(updateKanbanStateAction);
		};
	},
	openAddOpportunityDrawer: (
		vendorOpportunity: VendorOpportunity | null = null
	) => {
		return (dispatch: Dispatch): OpenAddOpportunityDrawerAction => {
			const openAddOpportunityDrawerAction: OpenAddOpportunityDrawerAction =
				{
					type: VendorOpportunityActionTypes.OPEN_ADD_OPPORTUNITY_DRAWER,
					opportunityDrawerMode: vendorOpportunity
						? OpportunityDrawerMode.EDIT
						: OpportunityDrawerMode.ADD,
					currentActiveOpportunity: vendorOpportunity
				};
			return dispatch(openAddOpportunityDrawerAction);
		};
	},
	closeAddOpportunityDrawer: () => {
		return (dispatch: Dispatch): CloseAddOpportunityDrawerAction => {
			const closeAddOpportunityDrawerAction: CloseAddOpportunityDrawerAction =
				{
					type: VendorOpportunityActionTypes.CLOSE_ADD_OPPORTUNITY_DRAWER
				};
			return dispatch(closeAddOpportunityDrawerAction);
		};
	},
	showReAssignModal: (opportunity: VendorOpportunity) => {
		return (dispatch: Dispatch): ShowReAssignModalAction => {
			const showReAssignModalAction: ShowReAssignModalAction = {
				type: VendorOpportunityActionTypes.SHOW_REASSIGN_MODAL,
				currentActiveOpportunity: opportunity
			};
			return dispatch(showReAssignModalAction);
		};
	},
	hideReAssignModal: (updatedOpportunity: VendorOpportunity) => {
		return (dispatch: Dispatch, state): HideReAssignModalAction => {
			const updatedOpportunityList = [
				...state().vendorOpportunity.opportunityList
			].filter(opportunity => opportunity?.id !== updatedOpportunity?.id);
			const hideReAssignModalAction: HideReAssignModalAction = {
				type: VendorOpportunityActionTypes.HIDE_REASSIGN_MODAL,
				opportunityList: updatedOpportunity
					? [updatedOpportunity, ...updatedOpportunityList]
					: updatedOpportunityList
			};

			return dispatch(hideReAssignModalAction);
		};
	},
	showDeleteOpportunityModal: (opportunity: VendorOpportunity) => {
		return (dispatch: Dispatch): ShowDeleteOpportunityModalAction => {
			const showDeleteOpportunityModalAction: ShowDeleteOpportunityModalAction =
				{
					type: VendorOpportunityActionTypes.SHOW_DELETE_OPPORTUNITY_MODAL,
					currentActiveOpportunity: opportunity
				};
			return dispatch(showDeleteOpportunityModalAction);
		};
	},
	hideDeleteOpportunityModal: () => {
		return (dispatch: Dispatch): HideDeleteOpportunityModalAction => {
			const hideDeleteOpportunityModalAction: HideDeleteOpportunityModalAction =
				{
					type: VendorOpportunityActionTypes.HIDE_DELETE_OPPORTUNITY_MODAL
				};
			return dispatch(hideDeleteOpportunityModalAction);
		};
	},
	updateOpportunityList: (opportunityList: VendorOpportunity[]) => {
		return (dispatch: Dispatch): UpdateOpportunityListAction => {
			const updateOpportunityListAction: UpdateOpportunityListAction = {
				type: VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_LIST,
				opportunityList
			};
			return dispatch(updateOpportunityListAction);
		};
	},
	updateOpportunityListCount: (
		opportunityListCount: VendorOpportunityCount | null
	) => {
		return (dispatch: Dispatch): UpdateOpportunityListCountAction => {
			const updateOpportunityListCountAction: UpdateOpportunityListCountAction =
				{
					type: VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_LIST_COUNT,
					opportunityListCount
				};
			return dispatch(updateOpportunityListCountAction);
		};
	},
	updateActiveOpportunityView: (opportunityView: VendorOpportunityView) => {
		return (dispatch: Dispatch): UpdateActiveOpportunityViewAction => {
			const updateActiveOpportunityViewAction: UpdateActiveOpportunityViewAction =
				{
					type: VendorOpportunityActionTypes.UPDATE_ACTIVE_OPPORTUNITY_VIEW,
					activeOpportunityView: opportunityView
				};
			return dispatch(updateActiveOpportunityViewAction);
		};
	},
	updateCurrentActiveOpportunity: (opportunity: VendorOpportunity | null) => {
		return (dispatch: Dispatch): UpdateCurrentActiveOpportunityAction => {
			const updateCurrentActiveOpportunityAction: UpdateCurrentActiveOpportunityAction =
				{
					type: VendorOpportunityActionTypes.UPDATE_CURRENT_ACTIVE_OPPORTUNITY,
					currentActiveOpportunity: opportunity
				};
			return dispatch(updateCurrentActiveOpportunityAction);
		};
	},
	deleteOpportunityOnKanban: (opportunity: VendorOpportunity) => {
		return (dispatch: Dispatch): DeleteOpportunityOnKanbanAction => {
			const deleteOpportunityOnKanbanAction: DeleteOpportunityOnKanbanAction =
				{
					type: VendorOpportunityActionTypes.DELETE_OPPORTUNITY_ON_KANBAN,
					opportunity
				};
			return dispatch(deleteOpportunityOnKanbanAction);
		};
	},
	openUpcomingActivitiesDrawer: (vendorOpportunity: VendorOpportunity) => {
		return (dispatch: Dispatch): OpenUpcomingActivitiesDrawerAction => {
			const openUpcomingActivitiesDrawerAction: OpenUpcomingActivitiesDrawerAction =
				{
					type: VendorOpportunityActionTypes.OPEN_UPCOMING_ACTIVITIES_DRAWER,
					currentActiveOpportunity: vendorOpportunity
				};
			return dispatch(openUpcomingActivitiesDrawerAction);
		};
	},
	closeUpcomingActivitiesDrawer: () => {
		return (dispatch: Dispatch): CloseUpcomingActivitiesDrawerAction => {
			const closeUpcomingActivitiesDrawerAction: CloseUpcomingActivitiesDrawerAction =
				{
					type: VendorOpportunityActionTypes.CLOSE_UPCOMING_ACTIVITIES_DRAWER
				};
			return dispatch(closeUpcomingActivitiesDrawerAction);
		};
	},
	updateCurrentActiveView: (
		selectedCurrentActiveView: CustomView,
		discardingView?: boolean
	) => {
		return (
			dispatch: Dispatch,
			getState: () => GlobalState
		): UpdateCurrentActiveViewAction => {
			const currentActiveView =
				getState().vendorOpportunity.currentActiveView;
			const prevViewData =
				getState().vendorOpportunity.viewList?.data.find(
					view => view.id === selectedCurrentActiveView?.id
				);
			const isSameView =
				currentActiveView?.id === selectedCurrentActiveView?.id;

			if (isSameView) {
				// if its a custom view then it would have an id
				if (!!prevViewData) {
					const hasFiltersChanged = areFiltersChanged(
						prevViewData.filter_criteria,
						selectedCurrentActiveView.filter_criteria
					);

					const hasColumnsChanged =
						JSON.stringify(selectedCurrentActiveView.columns) !==
						JSON.stringify(prevViewData.columns);

					const updateCurrentActiveViewAction: UpdateCurrentActiveViewAction =
						{
							type: VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_CURRENT_ACTIVE_VIEW,
							currentActiveView: selectedCurrentActiveView,
							isViewChanged: discardingView
								? false
								: hasFiltersChanged || hasColumnsChanged
						};
					return dispatch(updateCurrentActiveViewAction);
				}
			}

			const updateCurrentActiveViewAction: UpdateCurrentActiveViewAction =
				{
					type: VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_CURRENT_ACTIVE_VIEW,
					currentActiveView: selectedCurrentActiveView,
					isViewChanged: discardingView ? true : false
				};
			return dispatch(updateCurrentActiveViewAction);
		};
	},
	createOpportunityView: (view: CustomView) => {
		return async (
			dispatch: Dispatch
		): Promise<CreateOpportunityViewSuccessfulAction> => {
			const createOpportunityViewRequestedAction: CreateOpportunityViewRequestedAction =
				{
					type: VendorOpportunityActionTypes.CREATE_OPPORTUNITY_VIEW_REQUESTED
				};

			dispatch(createOpportunityViewRequestedAction);

			try {
				const response = await createOpportunityView(view);
				const createOpportunityViewSuccessfulAction: CreateOpportunityViewSuccessfulAction =
					{
						type: VendorOpportunityActionTypes.CREATE_OPPORTUNITY_VIEW_SUCCESSFUL,
						view: response
					};
				return dispatch(createOpportunityViewSuccessfulAction);
			} catch (error) {
				const createOpportunityViewFailedAction: CreateOpportunityViewFailedAction =
					{
						type: VendorOpportunityActionTypes.CREATE_OPPORTUNITY_VIEW_FAILED
					};
				dispatch(createOpportunityViewFailedAction);

				return Promise.reject(error);
			}
		};
	},
	updateOpportunityView: (view: CustomView) => {
		return async (
			dispatch: Dispatch
		): Promise<UpdateOpportunityViewSuccessfulAction> => {
			const updateOpportunityViewRequestedAction: UpdateOpportunityViewRequestedAction =
				{
					type: VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_VIEW_REQUESTED
				};

			dispatch(updateOpportunityViewRequestedAction);

			try {
				const response = await updateOpportunityView(view);
				const updateOpportunityViewSuccessfulAction: UpdateOpportunityViewSuccessfulAction =
					{
						type: VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_VIEW_SUCCESSFUL,
						view: response
					};
				return dispatch(updateOpportunityViewSuccessfulAction);
			} catch (error) {
				const updateOpportunityViewFailedAction: UpdateOpportunityViewFailedAction =
					{
						type: VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_VIEW_FAILED
					};
				dispatch(updateOpportunityViewFailedAction);

				return Promise.reject(error);
			}
		};
	},
	deleteOpportunityView: (
		deleteView: CustomView,
		initialView: CustomView
	) => {
		return async (
			dispatch: Dispatch
		): Promise<DeleteOpportunityViewSuccessfulAction> => {
			const deleteOpportunityViewRequestedAction: DeleteOpportunityViewRequestedAction =
				{
					type: VendorOpportunityActionTypes.DELETE_OPPORTUNITY_VIEW_REQUESTED
				};

			dispatch(deleteOpportunityViewRequestedAction);

			try {
				await deleteOpportunityView(deleteView.id);
				const deleteOpportunityViewSuccessfulAction: DeleteOpportunityViewSuccessfulAction =
					{
						type: VendorOpportunityActionTypes.DELETE_OPPORTUNITY_VIEW_SUCCESSFUL,
						deletedView: deleteView,
						initialView: initialView
					};
				return dispatch(deleteOpportunityViewSuccessfulAction);
			} catch (error) {
				const deleteOpportunityViewFailedAction: DeleteOpportunityViewFailedAction =
					{
						type: VendorOpportunityActionTypes.DELETE_OPPORTUNITY_VIEW_FAILED
					};
				dispatch(deleteOpportunityViewFailedAction);

				return Promise.reject(error);
			}
		};
	},
	resetViews: () => {
		return (dispatch: Dispatch): ResetCurrentActiveViewAction => {
			const resetCurrentActiveViewAction: ResetCurrentActiveViewAction = {
				type: VendorOpportunityActionTypes.RESET_OPPORTUNITY_CURRENT_ACTIVE_VIEW
			};

			return dispatch(resetCurrentActiveViewAction);
		};
	},
	fetchAllOpportunityView: () => {
		return async (
			dispatch: Dispatch
		): Promise<FetchAllOpportunityViewSuccessful> => {
			const fetchAllOpportunityViewRequested: FetchAllOpportunityViewRequested =
				{
					type: VendorOpportunityActionTypes.FETCH_ALL_OPPORTUNITY_VIEWS_REQUESTED
				};

			dispatch(fetchAllOpportunityViewRequested);

			try {
				const response = await getAllOpportunityViews();
				const fetchAllOpportunityViewSuccessful: FetchAllOpportunityViewSuccessful =
					{
						type: VendorOpportunityActionTypes.FETCH_ALL_OPPORTUNITY_VIEWS_SUCCESSFUL,
						viewList: response
					};
				return dispatch(fetchAllOpportunityViewSuccessful);
			} catch (error) {
				console.error(error);

				const fetchAllOpportunityViewFailed: FetchAllOpportunityViewFailed =
					{
						type: VendorOpportunityActionTypes.FETCH_ALL_OPPORTUNITY_VIEWS_FAILED
					};
				dispatch(fetchAllOpportunityViewFailed);

				return Promise.reject(error);
			}
		};
	},
	reorderOpportunityInKanban:
		(
			result: DropResult,
			terminatedReasons: Pick<
				VendorOpportunityRequest,
				"won_reason" | "loss_reason"
			> = {
				won_reason: null,
				loss_reason: null
			}
		) =>
		async (
			dispatch: Dispatch,
			getState: () => GlobalState
		): Promise<UpdateKanbanStateAction> => {
			try {
				const kanban = { ...getState().vendorOpportunity.kanbanState };
				const kanbanData = {
					...getState().vendorOpportunity.kanbanState.data
				};

				if (!result.destination) {
					throw new Error("DroppableID is not available");
				}
				const {
					source: {
						droppableId: sourceDroppableId,
						index: sourceDroppableIndex
					},
					destination: {
						droppableId: destDroppableId,
						index: destDroppableIndex
					},
					draggableId
				} = result;
				const selectedOpportunity = kanbanData[draggableId];
				const selectedEstimatedValue =
					selectedOpportunity.estimated_opportunity_value ?? 0;
				const sourceOpportunityStageId =
					sourceDroppableId.split("::")[1];
				const destOpportunityStageId = destDroppableId.split("::")[1];

				const sourceList = [
					...(kanban.list[sourceOpportunityStageId] ?? [])
				];
				const destList = [
					...(kanban.list[destOpportunityStageId] ?? [])
				];
				const selectedOpportunityId = sourceList[sourceDroppableIndex];

				let updatedPositionData: Record<string, any[]>;

				const hasChangedStages =
					sourceOpportunityStageId !== destOpportunityStageId;

				if (!hasChangedStages) {
					updatedPositionData = {
						[sourceOpportunityStageId]: reorder(
							sourceList,
							sourceDroppableIndex,
							destDroppableIndex
						)
					};
				} else {
					updatedPositionData = move(
						sourceList,
						destList,
						{
							droppableId: sourceOpportunityStageId,
							index: sourceDroppableIndex
						},
						{
							droppableId: destOpportunityStageId,
							index: destDroppableIndex
						}
					);
				}

				if (hasChangedStages) {
					kanban.list = {
						...kanban.list,
						...updatedPositionData
					};
					kanban.count = {
						...kanban.count,
						[sourceOpportunityStageId]:
							kanban.count[sourceOpportunityStageId] - 1,
						[destOpportunityStageId]:
							kanban.count[destOpportunityStageId] + 1
					};
					kanban.estimatedValue = {
						...kanban.estimatedValue,
						[sourceOpportunityStageId]:
							kanban.estimatedValue[sourceOpportunityStageId] -
							selectedEstimatedValue,
						[destOpportunityStageId]:
							kanban.estimatedValue[destOpportunityStageId] +
							selectedEstimatedValue
					};
					kanban.loading = {
						...kanban.loading,
						[selectedOpportunityId]: true
					};
					const updateKanbanStateAction: UpdateKanbanStateAction = {
						type: VendorOpportunityActionTypes.UPDATE_KANBAN_STATE,
						kanbanState: kanban
					};
					dispatch(updateKanbanStateAction);
				}

				const oldOpportunityData: VendorOpportunity = {
					...kanban.data[selectedOpportunityId]
				};
				delete oldOpportunityData.partner_company;
				const opportunityUpdateRequest: VendorOpportunityRequest = {
					...oldOpportunityData,
					partner_manager_id: oldOpportunityData.partner_manager.id,
					partner_company_id:
						oldOpportunityData.partner_company_id ?? null,
					...terminatedReasons,
					opportunity_pipeline_stage_id: destOpportunityStageId,
					included_child_entities: [
						ChildEntityHydrationParams.CLIENT_COMPANIES_NAME,
						ChildEntityHydrationParams.DOCUMENTS_ALL
					]
				};

				const opportunityResponse = await editVendorOpportunity(
					oldOpportunityData.id,
					opportunityUpdateRequest
				);

				const updateKanbanStateAction: UpdateKanbanStateAction = {
					type: VendorOpportunityActionTypes.UPDATE_KANBAN_STATE,
					kanbanState: {
						list: kanban.list,
						count: kanban.count,
						estimatedValue: kanban.estimatedValue,
						loading: {
							...kanban.loading,
							[selectedOpportunityId]: false
						},
						data: {
							...kanban.data,
							[selectedOpportunityId]: opportunityResponse
						}
					}
				};
				return dispatch(updateKanbanStateAction);
			} catch (error) {
				console.error(error);
				return Promise.reject(error);
			}
		}
};
