'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var _commonjsHelpers = require('../../../../_virtual/_commonjsHelpers.js');

var _interface = _commonjsHelpers.createCommonjsModule(function (module, exports) {

Object.defineProperty(exports, '__esModule', {
  value: true
});
exports.EColors = void 0;
(function (EColors) {
  EColors["RED"] = "red";
  EColors["VOLCANO"] = "volcano";
  EColors["ORANGE"] = "orange";
  EColors["GOLD"] = "gold";
  EColors["YELLOW"] = "yellow";
  EColors["LIME"] = "lime";
  EColors["GREEN"] = "green";
  EColors["CYAN"] = "cyan";
  EColors["BLUE"] = "blue";
  EColors["GEEKBLUE"] = "geekBlue";
  EColors["PURPLE"] = "purple";
  EColors["MAGENTA"] = "magenta";
  EColors["GRAY"] = "gray";
  EColors["TRANSPARENT"] = "transparent";
})(exports.EColors || (exports.EColors = {}));
});

_commonjsHelpers.unwrapExports(_interface);
_interface.EColors;

exports.__moduleExports = _interface;
