'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var ContactSearchAndSortParams = /** @class */function () {
  function ContactSearchAndSortParams(currentPage, sortBy, sortOrder, search) {
    if (currentPage === void 0) {
      currentPage = 1;
    }
    if (sortBy === void 0) {
      sortBy = "";
    }
    if (sortOrder === void 0) {
      sortOrder = "";
    }
    if (search === void 0) {
      search = "";
    }
    this.currentPage = currentPage;
    this.sortBy = sortBy;
    this.sortOrder = sortOrder;
    this.search = search;
  }
  ContactSearchAndSortParams.prototype.isInitialSearch = function () {
    return this.currentPage === 1 && this.sortBy === "" && this.sortOrder === "" && this.search === "";
  };
  return ContactSearchAndSortParams;
}();

exports.ContactSearchAndSortParams = ContactSearchAndSortParams;
