'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var useTestEnvironment = function () {
  return process.env.NODE_ENV === "test";
};
var useTestEnvironment$1 = useTestEnvironment;

exports["default"] = useTestEnvironment$1;
