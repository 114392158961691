import React from "react";
import ReactDOM from "react-dom";
import ZImage from "@zomentum/atoms/dist/ZImage";
import ZButton from "@zomentum/atoms/dist/ZButton";
import ZModal from "@zomentum/atoms/dist/ZModal";
import ZVideo from "@zomentum/organisms/dist/ZVideo";
import { EIcons } from "@zomentum/atoms/dist/ZIcon/interface";
import { MarketplaceProductMediaType } from "./interface";
import "./index.less";

function showPreviewModal(
	fileType: MarketplaceProductMediaType,
	mediaUrl: string | null
): void {
	const div = document.createElement("div");
	document.body.appendChild(div);
	function destroy() {
		const unmountResult = ReactDOM.unmountComponentAtNode(div);
		if (unmountResult && div.parentNode) {
			div.parentNode.removeChild(div);
		}
	}
	ReactDOM.render(
		<ZModal
			visible={true}
			footer={null}
			title={null}
			removeDefaultPadding
			className="media-preview-modal w-min"
			modalBodyClassName="h-0 p-0 relative"
			closeIcon={null}
			ignoreSize
			centered={false}
		>
			<div className="w-full flex items-center">
				{mediaUrl &&
					(fileType === MarketplaceProductMediaType.IMAGE ? (
						<ZImage
							src={mediaUrl}
							alt="Product"
							className="mx-auto w-auto max-h-124"
							preview={false}
						/>
					) : (
						<ZVideo
							video={mediaUrl}
							id="video-player"
							className="video-preview-player"
							showPlayButton
						/>
					))}
			</div>
			<ZButton
				iconName={EIcons.CANCEL}
				iconPlacement="right"
				iconSize="small"
				type="text"
				className="absolute -top-8 right-0 text-gray-1 hover:bg-none p-0"
				onClick={destroy}
			>
				Close
			</ZButton>
		</ZModal>,
		div
	);
}

export default {
	showPreviewModal
};
