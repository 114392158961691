import { ZomentumRoutes, V2ZomentumRoutes } from "@zomentum/contracts";
import { isDocumentClientView } from "@zomentum/contracts/dist/Utils";
import useMobileView from "@zomentum/hooks/dist/hooks/useMobileView";
import AppLeftMenu from "@organisms/ZLeftMenu";
import { Layout } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";
import { GlobalState } from "@/Reducers/interface";

interface IDashboardLayout {
	readonly updateServiceWorker: (
		registration: ServiceWorkerRegistration
	) => void;
}

export const DashboardLayout: React.FC<IDashboardLayout> = ({
	children,
	updateServiceWorker
}) => {
	const { appUpdateBannerVisible } = useSelector((state: GlobalState) => ({
		appUpdateBannerVisible: state.settings.appUpdateBannerVisible
	}));

	const isDocumentRoute = useRouteMatch(ZomentumRoutes.DocumentsDetail);
	const isVerifyPaymentRoute = useRouteMatch(ZomentumRoutes.VerifyPayment);
	const isTemplateRoute = useRouteMatch(ZomentumRoutes.TemplatesDetail);
	const isDocumentSuccessSignature = useRouteMatch(
		ZomentumRoutes.DocumentSuccessSignature
	);
	const isLoginOrSignup = useRouteMatch(ZomentumRoutes.LoginOrSignup);
	const isLogin = useRouteMatch(ZomentumRoutes.Login);
	const isVerifyEmail = useRouteMatch(ZomentumRoutes.VerifyEmail);
	const isSetupAccount = useRouteMatch(ZomentumRoutes.SetupAccount);
	const isAuthorize = useRouteMatch(ZomentumRoutes.Authorize);
	const isDocumentsPdfView = useRouteMatch(ZomentumRoutes.DocumentsPdfView);
	const isDocumentsPreview = useRouteMatch(ZomentumRoutes.DocumentsPreview);
	const isPurchaseOrderSubmitPage = useRouteMatch(
		ZomentumRoutes.PurchaseOrderSubmitPage
	);
	const isPurchaseOrderCreatePage = useRouteMatch(
		ZomentumRoutes.PurchaseOrderAddPage
	);
	const isPurchaseOrderDetailPage = useRouteMatch(
		ZomentumRoutes.PurchaseOrderDetailPage
	);

	const isBillingRoute = useRouteMatch(ZomentumRoutes.Billing);
	const isBillingPageRoute = useRouteMatch(V2ZomentumRoutes.BILLING);
	// const authToken = cache.get(ZomentumStorageKeys.Token, true) as AuthToken;
	const shouldHideLayout = [
		isDocumentRoute?.isExact,
		isTemplateRoute?.isExact,
		isDocumentSuccessSignature?.isExact,
		isLoginOrSignup?.isExact,
		isLogin?.isExact,
		isSetupAccount?.isExact,
		isVerifyEmail?.isExact,
		isAuthorize?.isExact,
		isDocumentsPdfView?.isExact,
		isDocumentsPreview?.isExact,
		isPurchaseOrderSubmitPage?.isExact,
		isPurchaseOrderCreatePage?.isExact,
		isPurchaseOrderDetailPage?.isExact,
		isBillingRoute?.isExact,
		isBillingPageRoute?.isExact,
		isVerifyPaymentRoute?.isExact
	].some(a => !!a);

	const isMobileView = useMobileView();

	const location = useLocation();
	const isEndClientView = isDocumentClientView(location.pathname);
	const isPdfView = useRouteMatch(ZomentumRoutes.DocumentsPdfView)?.isExact;

	useEffect(() => {
		const autoRefresh = isEndClientView || isPdfView || isLoginOrSignup;

		if (autoRefresh && appUpdateBannerVisible) {
			// reload when update is available in public page, the in next reload 'appUpdateBannerVisible' will become false
			// as content is updated
			window.location.reload();
		}
	}, [appUpdateBannerVisible]);

	return (
		<Layout style={{ flexGrow: 1 }}>
			{!shouldHideLayout && !isMobileView && (
				<AppLeftMenu updateServiceWorker={updateServiceWorker} />
			)}
			<Layout.Content className="outer-container">
				{children}
			</Layout.Content>
		</Layout>
	);
};
