import {
	VendorOpportunityCount,
	VendorOpportunityView,
	VendorOpportunity
} from "@zomentum/contracts/dist/Vendor/Opportunity";
import { OpportunityDrawerMode } from "@pages/Vendor/Opportunities/Drawers/OpportunityDrawer/interface";
import {
	CustomViewResponse,
	CustomView,
	CustomViewsMetadata
} from "@zomentum/contracts/dist/UI";
import {
	VendorOpportunityActions,
	VendorOpportunityActionTypes
} from "@/Actions/Vendor/Opportunity";

export interface KanbanState {
	list: Record<string, string[]>;
	count: Record<string, number>;
	loading: Record<string, boolean>;
	data: Record<string, VendorOpportunity>;
	estimatedValue: Record<string, number>;
}

export interface VendorOpportunityState {
	readonly loading: boolean;
	readonly isLoadingKanbanData: boolean;
	readonly kanbanState: KanbanState;
	readonly opportunityDrawerVisible: boolean;
	readonly opportunityDrawerMode: OpportunityDrawerMode;
	readonly currentActiveOpportunity: VendorOpportunity | null;
	readonly reAssignModalVisible: boolean;
	readonly opportunityList: VendorOpportunity[];
	readonly opportunityListCount: VendorOpportunityCount | null;
	readonly activeOpportunityView: VendorOpportunityView;
	readonly viewList: CustomViewResponse | null;
	readonly currentActiveView: CustomView | null;
	readonly viewMetadata: CustomViewsMetadata | null;
	readonly isViewChanged: boolean;
	readonly deleteModalVisible: boolean;
	readonly upcomingActivitiesDrawerVisible: boolean;
}

export const initialState: VendorOpportunityState = {
	loading: false,
	isLoadingKanbanData: false,
	kanbanState: {
		list: {},
		loading: {},
		count: {},
		data: {},
		estimatedValue: {}
	},
	opportunityDrawerVisible: false,
	opportunityDrawerMode: OpportunityDrawerMode.EDIT,
	currentActiveOpportunity: null,
	reAssignModalVisible: false,
	opportunityList: [],
	opportunityListCount: null,
	activeOpportunityView: VendorOpportunityView.Kanban,
	deleteModalVisible: false,
	upcomingActivitiesDrawerVisible: false,
	viewList: null,
	currentActiveView: null,
	viewMetadata: null,
	isViewChanged: false
};

const VendorOpportunityReducer = (
	state: VendorOpportunityState = initialState,
	action: VendorOpportunityActions
): VendorOpportunityState => {
	switch (action.type) {
		case VendorOpportunityActionTypes.SHOW_OPPORTUNITY_LOADER:
			return {
				...state,
				loading: true
			};
		case VendorOpportunityActionTypes.HIDE_OPPORTUNITY_LOADER:
			return {
				...state,
				loading: false
			};
		case VendorOpportunityActionTypes.UPDATE_KANBAN_STATE:
			return {
				...state,
				kanbanState: {
					...state.kanbanState,
					...action.kanbanState
				}
			};
		case VendorOpportunityActionTypes.OPEN_ADD_OPPORTUNITY_DRAWER:
			return {
				...state,
				opportunityDrawerMode: action.opportunityDrawerMode,
				currentActiveOpportunity: action.currentActiveOpportunity,
				opportunityDrawerVisible: true
			};
		case VendorOpportunityActionTypes.CLOSE_ADD_OPPORTUNITY_DRAWER:
			return {
				...state,
				opportunityDrawerVisible: false,
				currentActiveOpportunity: null
			};
		case VendorOpportunityActionTypes.SHOW_REASSIGN_MODAL:
			return {
				...state,
				reAssignModalVisible: true,
				currentActiveOpportunity: action.currentActiveOpportunity
			};
		case VendorOpportunityActionTypes.HIDE_REASSIGN_MODAL:
			return {
				...state,
				reAssignModalVisible: false,
				currentActiveOpportunity: null,
				opportunityList: action.opportunityList
			};
		case VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_LIST:
			return {
				...state,
				opportunityList: action.opportunityList
			};
		case VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_LIST_COUNT:
			return {
				...state,
				opportunityListCount: action.opportunityListCount
			};
		case VendorOpportunityActionTypes.UPDATE_ACTIVE_OPPORTUNITY_VIEW:
			return {
				...state,
				activeOpportunityView: action.activeOpportunityView
			};
		case VendorOpportunityActionTypes.SHOW_DELETE_OPPORTUNITY_MODAL:
			return {
				...state,
				currentActiveOpportunity: action.currentActiveOpportunity,
				deleteModalVisible: true
			};
		case VendorOpportunityActionTypes.HIDE_DELETE_OPPORTUNITY_MODAL:
			return {
				...state,
				currentActiveOpportunity: null,
				deleteModalVisible: false
			};
		case VendorOpportunityActionTypes.FETCH_ALL_OPPORTUNITY_VIEWS_REQUESTED: {
			return state;
		}
		case VendorOpportunityActionTypes.FETCH_ALL_OPPORTUNITY_VIEWS_SUCCESSFUL: {
			return {
				...state,
				viewList: action.viewList
			};
		}
		case VendorOpportunityActionTypes.FETCH_ALL_OPPORTUNITY_VIEWS_FAILED: {
			return state;
		}
		case VendorOpportunityActionTypes.CREATE_OPPORTUNITY_VIEW_REQUESTED: {
			return state;
		}
		case VendorOpportunityActionTypes.CREATE_OPPORTUNITY_VIEW_SUCCESSFUL: {
			return {
				...state,
				currentActiveView: action.view,
				viewList: state.viewList
					? {
							...state.viewList,
							data: [...(state.viewList?.data ?? []), action.view]
					  }
					: state.viewList,
				isViewChanged: false
			};
		}
		case VendorOpportunityActionTypes.CREATE_OPPORTUNITY_VIEW_FAILED: {
			return state;
		}
		case VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_VIEW_REQUESTED: {
			return state;
		}
		case VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_VIEW_SUCCESSFUL: {
			return {
				...state,
				currentActiveView: action.view,
				viewList: state.viewList
					? {
							...state.viewList,
							data: state.viewList?.data?.map(view =>
								view.id === action.view.id ? action.view : view
							)
					  }
					: state.viewList,
				isViewChanged: false
			};
		}
		case VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_VIEW_FAILED: {
			return state;
		}
		case VendorOpportunityActionTypes.DELETE_OPPORTUNITY_VIEW_REQUESTED: {
			return state;
		}
		case VendorOpportunityActionTypes.DELETE_OPPORTUNITY_VIEW_SUCCESSFUL: {
			return {
				...state,
				currentActiveView: action.initialView,
				viewList: state.viewList
					? {
							...state.viewList,
							data: state.viewList?.data?.filter(
								view => view.id !== action.deletedView.id
							)
					  }
					: state.viewList,
				isViewChanged: false
			};
		}
		case VendorOpportunityActionTypes.DELETE_OPPORTUNITY_VIEW_FAILED: {
			return state;
		}
		case VendorOpportunityActionTypes.UPDATE_OPPORTUNITY_CURRENT_ACTIVE_VIEW: {
			return {
				...state,
				currentActiveView: action.currentActiveView,
				isViewChanged: action.isViewChanged
			};
		}
		case VendorOpportunityActionTypes.RESET_OPPORTUNITY_CURRENT_ACTIVE_VIEW: {
			return {
				...state,
				currentActiveView: null,
				isViewChanged: false,
				viewList: null,
				viewMetadata: null
			};
		}
		case VendorOpportunityActionTypes.UPDATE_CURRENT_ACTIVE_OPPORTUNITY:
			return {
				...state,
				currentActiveOpportunity: action.currentActiveOpportunity
			};
		case VendorOpportunityActionTypes.DELETE_OPPORTUNITY_ON_KANBAN: {
			const selectedOpportunity =
				state.kanbanState.data[action.opportunity.id];
			const selectedEstimatedValue =
				selectedOpportunity.estimated_opportunity_value ?? 0;
			const kanbanList = [
				...state.kanbanState.list[
					action.opportunity.opportunity_pipeline_stage_id
				]
			];
			const newKanbanList = (kanbanList || []).filter(
				currOppId => action.opportunity.id !== currOppId
			);
			return {
				...state,
				kanbanState: {
					...state.kanbanState,
					count: {
						...state.kanbanState.count,
						[action.opportunity.id]:
							state.kanbanState.count[
								action.opportunity.opportunity_pipeline_stage_id
							] - 1
					},
					list: {
						...state.kanbanState.list,
						[action.opportunity.opportunity_pipeline_stage_id]:
							newKanbanList
					},
					estimatedValue: {
						...state.kanbanState.estimatedValue,
						[action.opportunity.opportunity_pipeline_stage_id]:
							state.kanbanState.estimatedValue[
								action.opportunity.opportunity_pipeline_stage_id
							] - selectedEstimatedValue
					}
				}
			};
		}
		case VendorOpportunityActionTypes.OPEN_UPCOMING_ACTIVITIES_DRAWER:
			return {
				...state,
				upcomingActivitiesDrawerVisible: true,
				currentActiveOpportunity: action.currentActiveOpportunity
			};
		case VendorOpportunityActionTypes.CLOSE_UPCOMING_ACTIVITIES_DRAWER:
			return {
				...state,
				upcomingActivitiesDrawerVisible: false,
				currentActiveOpportunity: null
			};
		default:
			return state;
	}
};

export default VendorOpportunityReducer;
