import { areFiltersChanged, updateMergeTagsDisplayName } from "@zomentum/utils";
import { GlobalState } from "@/Reducers/interface";
import {
	Block,
	BlockMapper,
	BlockType,
	TextBlockData
} from "@zomentum/contracts/dist/ProposalBuilder/Block";
import {
	LOADING_BLOCK_PLACEHOLDER,
	VendorTemplateUpdateBlockListSequenceRequest,
	VendorTemplateDetail,
	VendorTemplateSidePanelEnum,
	VendorTemplateEditOrCloneRequest
} from "@zomentum/contracts/dist/Vendor/Templates";
import {
	createNewBlock,
	draggableIdToBlockTypeMapper
} from "@zomentum/contracts/dist/ProposalBuilder/Block";
import {
	editVendorTemplate,
	getVendorTemplateDetails,
	updateTemplateBlockList,
	deleteVendorTemplate,
	getAllTemplateViews,
	createTemplateView,
	updateTemplateView,
	deleteTemplateView
} from "@/Services/Vendor/Templates";
import { addBlock } from "@/Services/Vendor/Templates/Block";
import { Action, ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import {
	BlocksActionTypes,
	CreateBlockRequestedAction,
	CreateBlockSuccessfulAction,
	GetBlockMapperRequestedAction,
	GetBlockMapperSuccessfulAction
} from "@modules/proposal-builder/dist/store/actions/Blocks";
import { VendorTemplateBlockRepositionRequest } from "@zomentum/contracts/dist/Vendor/Templates";
import { MergeTagVariable } from "@zomentum/contracts/dist/Documents";
import { proposalBuilderStore } from "@modules/proposal-builder/dist/store";
import { CustomViewResponse, CustomView } from "@zomentum/contracts/dist/UI";

export enum VendorTemplateActionTypes {
	SHOW_VENDOR_TEMPLATE_LOADER = "SHOW_VENDOR_TEMPLATE_LOADER",
	HIDE_VENDOR_TEMPLATE_LOADER = "HIDE_VENDOR_TEMPLATE_LOADER",
	VENDOR_TEMPLATE_DETAILS_REQUESTED = "VENDOR_TEMPLATE_DETAILS_REQUESTED",
	VENDOR_TEMPLATE_DETAILS_SUCCESSFUL = "VENDOR_TEMPLATE_DETAILS_SUCCESSFUL",
	VENDOR_TEMPLATE_DETAILS_FAILED = "VENDOR_TEMPLATE_DETAILS_FAILED",

	UPDATE_TEMPLATE_REVISION_BLOCK_LIST = "UPDATE_TEMPLATE_REVISION_BLOCK_LIST",

	UPDATE_VENDOR_TEMPLATE_DETAILS_REQUESTED = "UPDATE_VENDOR_TEMPLATE_DETAILS_REQUESTED",
	UPDATE_VENDOR_TEMPLATE_DETAILS_SUCCESSFUL = "UPDATE_VENDOR_TEMPLATE_DETAILS_SUCCESSFUL",
	UPDATE_VENDOR_TEMPLATE_DETAILS_FAILED = "UPDATE_VENDOR_TEMPLATE_DETAILS_FAILED",

	SHOW_TEMPLATE_MODAL_LOADER = "SHOW_TEMPLATE_MODAL_LOADER",
	HIDE_TEMPLATE_MODAL_LOADER = "HIDE_TEMPLATE_MODAL_LOADER",
	GET_TEMPLATES_REQUESTED = "GET_TEMPLATES_REQUESTED",
	GET_TEMPLATES_SUCCESSFUL = "GET_TEMPLATES_SUCCESSFUL",
	GET_TEMPLATES_FAILED = "GET_TEMPLATES_FAILED",
	RESET_TEMPLATES_LIST = "RESET_TEMPLATES_LIST",

	GET_TEMPLATE_DETAILS_REQUESTED = "GET_TEMPLATE_DETAILS_REQUESTED",
	GET_TEMPLATE_DETAILS_SUCCESSFUL = "GET_TEMPLATE_DETAILS_SUCCESSFUL",
	GET_TEMPLATE_DETAILS_FAILED = "GET_TEMPLATE_DETAILS_FAILED",
	RESET_TEMPLATE_DETAILS = "RESET_TEMPLATE_DETAILS",

	TEMPLATE_DETAILS_REQUESTED = "TEMPLATE_DETAILS_REQUESTED",
	TEMPLATE_DETAILS_SUCCESSFUL = "TEMPLATE_DETAILS_SUCCESSFUL",
	TEMPLATE_DETAILS_FAILED = "TEMPLATE_DETAILS_FAILED",
	TEMPLATE_DETAILS_UPDATED = "TEMPLATE_DETAILS_UPDATED",

	DELETE_TEMPLATE_REQUESTED = "DELETE_TEMPLATE_REQUESTED",
	DELETE_TEMPLATE_SUCCESSFUL = "DELETE_TEMPLATE_SUCCESSFUL",
	DELETE_TEMPLATE_FAILED = "DELETE_TEMPLATE_FAILED",

	ADD_TEMPLATE_REQUESTED = "ADD_TEMPLATE_REQUESTED",
	ADD_TEMPLATE_SUCCESSFUL = "ADD_TEMPLATE_SUCCESSFUL",
	ADD_TEMPLATE_FAILED = "ADD_TEMPLATE_FAILED",

	EDIT_TEMPLATE_REQUESTED = "EDIT_TEMPLATE_REQUESTED",
	EDIT_TEMPLATE_SUCCESSFUL = "EDIT_TEMPLATE_SUCCESSFUL",
	EDIT_TEMPLATE_FAILED = "EDIT_TEMPLATE_FAILED",

	ADD_TEMPLATE_DRAWER_OPENED = "ADD_TEMPLATE_DRAWER_OPENED",
	ADD_TEMPLATE_DRAWER_CLOSED = "ADD_TEMPLATE_DRAWER_CLOSED",

	EDIT_TEMPLATE_DRAWER_OPENED = "EDIT_TEMPLATE_DRAWER_OPENED",
	EDIT_TEMPLATE_DRAWER_CLOSED = "EDIT_TEMPLATE_DRAWER_CLOSED",

	CHANGE_TEMPLATE_ACTIVE_TAB = "CHANGE_TEMPLATE_ACTIVE_TAB",

	CREATE_TEMPLATE_FROM_DOCUMENT_REQUESTED = "CREATE_TEMPLATE_FROM_DOCUMENT_REQUESTED",
	CREATE_TEMPLATE_FROM_DOCUMENT_SUCCESSFUL = "CREATE_TEMPLATE_FROM_DOCUMENT_SUCCESSFUL",
	CREATE_TEMPLATE_FROM_DOCUMENT_FAILED = "CREATE_TEMPLATE_FROM_DOCUMENT_FAILED",

	OPEN_TEMPLATE_PREVIEW_MODAL = "OPEN_TEMPLATE_PREVIEW_MODAL",
	CLOSE_TEMPLATE_PREVIEW_MODAL = "CLOSE_TEMPLATE_PREVIEW_MODAL",

	SET_TEMPLATE_SIDE_PANEL = "SET_TEMPLATE_SIDE_PANEL",
	OPEN_TEMPLATE_SIDE_PANEL = "OPEN_TEMPLATE_SIDE_PANEL",
	CLOSE_TEMPLATE_SIDE_PANEL = "CLOSE_TEMPLATE_SIDE_PANEL",

	UPDATE_SIGNATURE_ASSIGNEE_REQUESTED = "UPDATE_SIGNATURE_ASSIGNEE_REQUESTED",
	UPDATE_SIGNATURE_ASSIGNEE_SUCCESSFUL = "UPDATE_SIGNATURE_ASSIGNEE_SUCCESSFUL",
	UPDATE_SIGNATURE_ASSIGNEE_FAILED = "UPDATE_SIGNATURE_ASSIGNEE_FAILED",

	// Merge tag actions
	UPDATE_MERGE_TAG_REQUESTED = "UPDATE_MERGE_TAG_REQUESTED",
	UPDATE_MERGE_TAG_SUCCESSFUL = "UPDATE_MERGE_TAG_SUCCESSFUL",
	UPDATE_MERGE_TAG_FAILED = "UPDATE_MERGE_TAG_FAILED",

	SHOW_PREVIEW_VENDOR_TEMPLATE_MODAL = "SHOW_PREVIEW_VENDOR_TEMPLATE_MODAL",
	HIDE_PREVIEW_VENDOR_TEMPLATE_MODAL = "HIDE_PREVIEW_VENDOR_TEMPLATE_MODAL",

	// VIEWS
	FETCH_ALL_TEMPLATE_VIEWS_REQUESTED = "FETCH_ALL_TEMPLATE_VIEWS_REQUESTED",
	FETCH_ALL_TEMPLATE_VIEWS_SUCCESSFUL = "FETCH_ALL_TEMPLATE_VIEWS_SUCCESSFUL",
	FETCH_ALL_TEMPLATE_VIEWS_FAILED = "FETCH_ALL_TEMPLATE_VIEWS_FAILED",

	CREATE_TEMPLATE_VIEW_REQUESTED = "CREATE_TEMPLATE_VIEW_REQUESTED",
	CREATE_TEMPLATE_VIEW_SUCCESSFUL = "CREATE_TEMPLATE_VIEW_SUCCESSFUL",
	CREATE_TEMPLATE_VIEW_FAILED = "CREATE_TEMPLATE_VIEW_FAILED",

	UPDATE_TEMPLATE_VIEW_REQUESTED = "UPDATE_TEMPLATE_VIEW_REQUESTED",
	UPDATE_TEMPLATE_VIEW_SUCCESSFUL = "UPDATE_TEMPLATE_VIEW_SUCCESSFUL",
	UPDATE_TEMPLATE_VIEW_FAILED = "UPDATE_TEMPLATE_VIEW_FAILED",

	DELETE_TEMPLATE_VIEW_REQUESTED = "DELETE_TEMPLATE_VIEW_REQUESTED",
	DELETE_TEMPLATE_VIEW_SUCCESSFUL = "DELETE_TEMPLATE_VIEW_SUCCESSFUL",
	DELETE_TEMPLATE_VIEW_FAILED = "DELETE_TEMPLATE_VIEW_FAILED",

	RESET_TEMPLATE_CURRENT_ACTIVE_VIEW = "RESET_TEMPLATE_CURRENT_ACTIVE_VIEW",
	UPDATE_TEMPLATE_CURRENT_ACTIVE_VIEW = "UPDATE_TEMPLATE_CURRENT_ACTIVE_VIEW"
}

export type ShowVendorTemplateLoaderAction =
	Action<VendorTemplateActionTypes.SHOW_VENDOR_TEMPLATE_LOADER>;
export type HideVendorTemplateLoaderAction =
	Action<VendorTemplateActionTypes.HIDE_VENDOR_TEMPLATE_LOADER>;
export type VendorTemplateDetailsRequestedAction =
	Action<VendorTemplateActionTypes.VENDOR_TEMPLATE_DETAILS_REQUESTED>;
export interface VendorTemplateDetailsSuccessfulAction
	extends Action<VendorTemplateActionTypes.VENDOR_TEMPLATE_DETAILS_SUCCESSFUL> {
	readonly currentActiveTemplate: VendorTemplateDetail;
}
export type VendorTemplateDetailsFailedAction =
	Action<VendorTemplateActionTypes.VENDOR_TEMPLATE_DETAILS_FAILED>;
export interface SetTemplateSidePanelAction
	extends Action<VendorTemplateActionTypes.SET_TEMPLATE_SIDE_PANEL> {
	readonly templateSidePanel: VendorTemplateSidePanelEnum;
}
export type UpdateVendorTemplateDetailsRequestedAction =
	Action<VendorTemplateActionTypes.UPDATE_VENDOR_TEMPLATE_DETAILS_REQUESTED>;
export interface UpdateVendorTemplateDetailsSuccessfulAction
	extends Action<VendorTemplateActionTypes.UPDATE_VENDOR_TEMPLATE_DETAILS_SUCCESSFUL> {
	readonly currentActiveTemplate: VendorTemplateDetail;
}
export type UpdateVendorTemplateDetailsFailedAction =
	Action<VendorTemplateActionTypes.UPDATE_VENDOR_TEMPLATE_DETAILS_FAILED>;
export interface UpdateDocumentRevisionBlockListAction
	extends Action<VendorTemplateActionTypes.UPDATE_TEMPLATE_REVISION_BLOCK_LIST> {
	readonly block_list: string[];
}
export interface UpdateTemplateDetailsSuccessfulAction
	extends Action<VendorTemplateActionTypes.UPDATE_VENDOR_TEMPLATE_DETAILS_SUCCESSFUL> {
	readonly currentActiveTemplate: VendorTemplateDetail;
}

export type UpdateMergeTagRequestedAction =
	Action<VendorTemplateActionTypes.UPDATE_MERGE_TAG_REQUESTED>;
export type UpdateMergeTagFailedAction =
	Action<VendorTemplateActionTypes.UPDATE_MERGE_TAG_FAILED>;
export interface UpdateMergeTagSuccessfulAction
	extends Action<VendorTemplateActionTypes.UPDATE_MERGE_TAG_SUCCESSFUL> {
	readonly customMergeTagVariables?: MergeTagVariable[];
}
export type DeleteTemplateRequestedAction =
	Action<VendorTemplateActionTypes.DELETE_TEMPLATE_REQUESTED>;
export type DeleteTemplateSuccessfulAction =
	Action<VendorTemplateActionTypes.DELETE_TEMPLATE_SUCCESSFUL>;
export type DeleteTemplateFailedAction =
	Action<VendorTemplateActionTypes.DELETE_TEMPLATE_FAILED>;

export type ShowVendorTemplatePreviewModalAction =
	Action<VendorTemplateActionTypes.SHOW_PREVIEW_VENDOR_TEMPLATE_MODAL>;
export type HideVendorTemplatePreviewModalAction =
	Action<VendorTemplateActionTypes.HIDE_PREVIEW_VENDOR_TEMPLATE_MODAL>;

export type OpenTemplateSidePaneAction =
	Action<VendorTemplateActionTypes.OPEN_TEMPLATE_SIDE_PANEL>;
export type CloseTemplateSidePaneAction =
	Action<VendorTemplateActionTypes.CLOSE_TEMPLATE_SIDE_PANEL>;

export type FetchAllTemplateViewRequested =
	Action<VendorTemplateActionTypes.FETCH_ALL_TEMPLATE_VIEWS_REQUESTED>;
export interface FetchAllTemplateViewSuccessful
	extends Action<VendorTemplateActionTypes.FETCH_ALL_TEMPLATE_VIEWS_SUCCESSFUL> {
	readonly viewList: CustomViewResponse;
}
export type FetchAllTemplateViewFailed =
	Action<VendorTemplateActionTypes.FETCH_ALL_TEMPLATE_VIEWS_FAILED>;

export interface UpdateCurrentActiveViewAction
	extends Action<VendorTemplateActionTypes.UPDATE_TEMPLATE_CURRENT_ACTIVE_VIEW> {
	readonly currentActiveView: CustomView;
	readonly isViewChanged: boolean;
}
export type ResetCurrentActiveViewAction =
	Action<VendorTemplateActionTypes.RESET_TEMPLATE_CURRENT_ACTIVE_VIEW>;

export type CreateTemplateViewRequestedAction =
	Action<VendorTemplateActionTypes.CREATE_TEMPLATE_VIEW_REQUESTED>;
export interface CreateTemplateViewSuccessfulAction
	extends Action<VendorTemplateActionTypes.CREATE_TEMPLATE_VIEW_SUCCESSFUL> {
	readonly view: CustomView;
}
export type CreateTemplateViewFailedAction =
	Action<VendorTemplateActionTypes.CREATE_TEMPLATE_VIEW_FAILED>;

export type UpdateTemplateViewRequestedAction =
	Action<VendorTemplateActionTypes.UPDATE_TEMPLATE_VIEW_REQUESTED>;
export interface UpdateTemplateViewSuccessfulAction
	extends Action<VendorTemplateActionTypes.UPDATE_TEMPLATE_VIEW_SUCCESSFUL> {
	readonly view: CustomView;
}
export type UpdateTemplateViewFailedAction =
	Action<VendorTemplateActionTypes.UPDATE_TEMPLATE_VIEW_FAILED>;

export type DeleteTemplateViewRequestedAction =
	Action<VendorTemplateActionTypes.DELETE_TEMPLATE_VIEW_REQUESTED>;
export interface DeleteTemplateViewSuccessfulAction
	extends Action<VendorTemplateActionTypes.DELETE_TEMPLATE_VIEW_SUCCESSFUL> {
	readonly deletedView: CustomView;
	readonly initialView: CustomView;
}
export type DeleteTemplateViewFailedAction =
	Action<VendorTemplateActionTypes.DELETE_TEMPLATE_VIEW_FAILED>;

export type TemplateDetailsRequestedAction =
	Action<VendorTemplateActionTypes.TEMPLATE_DETAILS_REQUESTED>;
export interface TemplateDetailsSuccessfulAction
	extends Action<VendorTemplateActionTypes.TEMPLATE_DETAILS_SUCCESSFUL> {
	readonly currentActiveTemplate: VendorTemplateDetail;
}
export type TemplateDetailsFailedAction =
	Action<VendorTemplateActionTypes.TEMPLATE_DETAILS_FAILED>;

export type VendorTemplateActions =
	| ShowVendorTemplateLoaderAction
	| HideVendorTemplateLoaderAction
	| VendorTemplateDetailsRequestedAction
	| VendorTemplateDetailsSuccessfulAction
	| VendorTemplateDetailsFailedAction
	| SetTemplateSidePanelAction
	| UpdateMergeTagRequestedAction
	| UpdateMergeTagSuccessfulAction
	| UpdateMergeTagFailedAction
	| UpdateVendorTemplateDetailsSuccessfulAction
	| UpdateVendorTemplateDetailsRequestedAction
	| UpdateVendorTemplateDetailsFailedAction
	| ShowVendorTemplatePreviewModalAction
	| HideVendorTemplatePreviewModalAction
	| OpenTemplateSidePaneAction
	| CloseTemplateSidePaneAction
	| FetchAllTemplateViewRequested
	| FetchAllTemplateViewSuccessful
	| FetchAllTemplateViewFailed
	| UpdateCurrentActiveViewAction
	| CreateTemplateViewRequestedAction
	| CreateTemplateViewSuccessfulAction
	| CreateTemplateViewFailedAction
	| UpdateTemplateViewRequestedAction
	| UpdateTemplateViewSuccessfulAction
	| UpdateTemplateViewFailedAction
	| DeleteTemplateViewRequestedAction
	| DeleteTemplateViewSuccessfulAction
	| DeleteTemplateViewFailedAction
	| TemplateDetailsRequestedAction
	| TemplateDetailsSuccessfulAction
	| TemplateDetailsFailedAction
	| ResetCurrentActiveViewAction;

export interface VendorTemplateDispatch {
	showVendorTemplateLoader: ActionCreator<
		ThunkAction<
			ShowVendorTemplateLoaderAction,
			GlobalState,
			null,
			ShowVendorTemplateLoaderAction
		>
	>;
	hideVendorTemplateLoader: ActionCreator<
		ThunkAction<
			HideVendorTemplateLoaderAction,
			GlobalState,
			null,
			HideVendorTemplateLoaderAction
		>
	>;
	getVendorTemplateDetails: ActionCreator<
		ThunkAction<
			Promise<VendorTemplateDetailsSuccessfulAction>,
			GlobalState,
			string,
			VendorTemplateDetailsSuccessfulAction
		>
	>;
	setSidePanel: ActionCreator<
		ThunkAction<
			SetTemplateSidePanelAction,
			GlobalState,
			VendorTemplateSidePanelEnum,
			SetTemplateSidePanelAction
		>
	>;
	addBlockToList: ActionCreator<
		ThunkAction<
			Promise<UpdateVendorTemplateDetailsSuccessfulAction>,
			GlobalState,
			VendorTemplateUpdateBlockListSequenceRequest,
			UpdateVendorTemplateDetailsSuccessfulAction
		>
	>;
	linkBlockToRevision: ActionCreator<
		ThunkAction<
			Promise<UpdateVendorTemplateDetailsSuccessfulAction>,
			GlobalState,
			VendorTemplateUpdateBlockListSequenceRequest,
			UpdateVendorTemplateDetailsSuccessfulAction
		>
	>;
	repositionBlockInRevision: ActionCreator<
		ThunkAction<
			Promise<UpdateVendorTemplateDetailsSuccessfulAction>,
			GlobalState,
			VendorTemplateBlockRepositionRequest,
			UpdateVendorTemplateDetailsSuccessfulAction
		>
	>;
	deleteBlockFromRevision: ActionCreator<
		ThunkAction<
			Promise<UpdateVendorTemplateDetailsSuccessfulAction>,
			GlobalState,
			{ blockIds: string[] },
			UpdateVendorTemplateDetailsSuccessfulAction
		>
	>;

	updateCurrentTemplateDetails: ActionCreator<
		ThunkAction<
			Promise<UpdateVendorTemplateDetailsSuccessfulAction>,
			GlobalState,
			VendorTemplateDetail,
			UpdateVendorTemplateDetailsSuccessfulAction
		>
	>;
	updateMergeTags: ActionCreator<
		ThunkAction<
			Promise<UpdateMergeTagSuccessfulAction>,
			GlobalState,
			MergeTagVariable[],
			UpdateMergeTagSuccessfulAction
		>
	>;
	deleteVendorTemplate: ActionCreator<
		ThunkAction<
			Promise<DeleteTemplateSuccessfulAction>,
			GlobalState,
			{ templateId: string },
			DeleteTemplateSuccessfulAction
		>
	>;
	showVendorTemplatePreviewModal: ActionCreator<
		ThunkAction<
			ShowVendorTemplatePreviewModalAction,
			GlobalState,
			null,
			ShowVendorTemplatePreviewModalAction
		>
	>;
	hideVendorTemplatePreviewModal: ActionCreator<
		ThunkAction<
			HideVendorTemplatePreviewModalAction,
			GlobalState,
			null,
			HideVendorTemplatePreviewModalAction
		>
	>;
	openTemplateSidePanel: ActionCreator<
		ThunkAction<
			OpenTemplateSidePaneAction,
			GlobalState,
			null,
			OpenTemplateSidePaneAction
		>
	>;
	closeTemplateSidePanel: ActionCreator<
		ThunkAction<
			CloseTemplateSidePaneAction,
			GlobalState,
			null,
			CloseTemplateSidePaneAction
		>
	>;
	fetchAllTemplateView: ActionCreator<
		ThunkAction<
			Promise<FetchAllTemplateViewSuccessful>,
			GlobalState,
			null,
			FetchAllTemplateViewSuccessful
		>
	>;
	updateCurrentActiveView: ActionCreator<
		ThunkAction<
			UpdateCurrentActiveViewAction,
			GlobalState,
			{ selectCurrentActiveView: CustomView; discardingView?: boolean },
			UpdateCurrentActiveViewAction
		>
	>;
	createTemplateView: ActionCreator<
		ThunkAction<
			Promise<CreateTemplateViewSuccessfulAction>,
			GlobalState,
			CustomView,
			CreateTemplateViewSuccessfulAction
		>
	>;
	updateTemplateView: ActionCreator<
		ThunkAction<
			Promise<UpdateTemplateViewSuccessfulAction>,
			GlobalState,
			CustomView,
			UpdateTemplateViewSuccessfulAction
		>
	>;
	deleteTemplateView: ActionCreator<
		ThunkAction<
			Promise<DeleteTemplateViewSuccessfulAction>,
			GlobalState,
			{ deleteView: CustomView; initialView: CustomView },
			DeleteTemplateViewSuccessfulAction
		>
	>;
	resetViews: ActionCreator<
		ThunkAction<
			ResetCurrentActiveViewAction,
			GlobalState,
			null,
			ResetCurrentActiveViewAction
		>
	>;
}

export const VendorTemplateActionCreators: VendorTemplateDispatch = {
	showVendorTemplateLoader: () => {
		return (dispatch: Dispatch): ShowVendorTemplateLoaderAction => {
			const ShowTemplateLoaderAction: ShowVendorTemplateLoaderAction = {
				type: VendorTemplateActionTypes.SHOW_VENDOR_TEMPLATE_LOADER
			};
			return dispatch(ShowTemplateLoaderAction);
		};
	},
	setSidePanel: panelTab => {
		return (dispatch: Dispatch): SetTemplateSidePanelAction => {
			const setTemplateSidePanelAction: SetTemplateSidePanelAction = {
				type: VendorTemplateActionTypes.SET_TEMPLATE_SIDE_PANEL,
				templateSidePanel: panelTab
			};
			return dispatch(setTemplateSidePanelAction);
		};
	},
	deleteVendorTemplate:
		(templateId: string) =>
		async (dispatch: Dispatch): Promise<DeleteTemplateSuccessfulAction> => {
			const deleteTemplateRequestedAction: DeleteTemplateRequestedAction =
				{
					type: VendorTemplateActionTypes.DELETE_TEMPLATE_REQUESTED
				};
			dispatch(deleteTemplateRequestedAction);

			try {
				await deleteVendorTemplate(templateId);
				const templatesSuccessfulAction: DeleteTemplateSuccessfulAction =
					{
						type: VendorTemplateActionTypes.DELETE_TEMPLATE_SUCCESSFUL
					};
				return dispatch(templatesSuccessfulAction);
			} catch (error) {
				const deleteTemplateFailedAction: DeleteTemplateFailedAction = {
					type: VendorTemplateActionTypes.DELETE_TEMPLATE_FAILED
				};
				dispatch(deleteTemplateFailedAction);

				return Promise.reject(error);
			}
		},
	updateCurrentTemplateDetails: (
		currentActiveTemplate: VendorTemplateDetail
	) => {
		return async (
			dispatch: Dispatch
		): Promise<UpdateTemplateDetailsSuccessfulAction> => {
			const updateCurrentDocumentRequestedAction: UpdateVendorTemplateDetailsRequestedAction =
				{
					type: VendorTemplateActionTypes.UPDATE_VENDOR_TEMPLATE_DETAILS_REQUESTED
				};
			dispatch(updateCurrentDocumentRequestedAction);
			try {
				const editVendorTemplateRequest: VendorTemplateEditOrCloneRequest =
					{
						name: currentActiveTemplate.name,
						document_type: currentActiveTemplate.document_type,
						merge_tag_custom_variables:
							currentActiveTemplate.merge_tag_custom_variables,
						status: currentActiveTemplate.status,
						defaults: currentActiveTemplate.defaults
					};
				const editVendorTemplateResponse = await editVendorTemplate(
					currentActiveTemplate.id,
					editVendorTemplateRequest
				);
				const updateCurrentDocumentSuccessfulAction: UpdateTemplateDetailsSuccessfulAction =
					{
						type: VendorTemplateActionTypes.UPDATE_VENDOR_TEMPLATE_DETAILS_SUCCESSFUL,
						currentActiveTemplate: editVendorTemplateResponse
					};
				return dispatch(updateCurrentDocumentSuccessfulAction);
			} catch (error) {
				const updateCurrentDocumentFailedAction: UpdateVendorTemplateDetailsFailedAction =
					{
						type: VendorTemplateActionTypes.UPDATE_VENDOR_TEMPLATE_DETAILS_FAILED
					};
				dispatch(updateCurrentDocumentFailedAction);
				return Promise.reject(error);
			}
		};
	},
	hideVendorTemplateLoader: () => {
		return (dispatch: Dispatch): HideVendorTemplateLoaderAction => {
			const hideVendorTemplateLoaderAction: HideVendorTemplateLoaderAction =
				{
					type: VendorTemplateActionTypes.HIDE_VENDOR_TEMPLATE_LOADER
				};
			return dispatch(hideVendorTemplateLoaderAction);
		};
	},
	getVendorTemplateDetails: (TemplateId: string) => {
		return async (
			dispatch: Dispatch
		): Promise<VendorTemplateDetailsSuccessfulAction> => {
			const vendorTemplateDetailsRequestedAction: VendorTemplateDetailsRequestedAction =
				{
					type: VendorTemplateActionTypes.VENDOR_TEMPLATE_DETAILS_REQUESTED
				};
			dispatch(vendorTemplateDetailsRequestedAction);
			try {
				const vendorTemplateDetailsResponse =
					await getVendorTemplateDetails(TemplateId);
				// requesting for the blocks
				const getBlockMapperRequestedAction: GetBlockMapperRequestedAction =
					{
						type: BlocksActionTypes.GET_BLOCK_MAPPER_REQUESTED
					};
				proposalBuilderStore.dispatch(getBlockMapperRequestedAction);

				const blockMapper: BlockMapper =
					(vendorTemplateDetailsResponse.blocks &&
						vendorTemplateDetailsResponse.blocks.data.reduce(
							(total: BlockMapper, block: Block) => ({
								...total,
								[block.id]: block
							}),
							{}
						)) ||
					{};

				const mergeTags = [
					...(vendorTemplateDetailsResponse.merge_tags_system_variables ??
						[]),
					...(vendorTemplateDetailsResponse.merge_tag_custom_variables ??
						[])
				];

				Object.keys(blockMapper).forEach(blockId => {
					if (
						blockMapper[blockId]?.type === BlockType.TEXT &&
						mergeTags
					) {
						(blockMapper[blockId].data as TextBlockData).text =
							updateMergeTagsDisplayName(
								mergeTags,
								(blockMapper[blockId].data as TextBlockData)
									.text
							);
					}
				});

				// updating the blocks to the state
				const getBlockMapperSuccessfulAction: GetBlockMapperSuccessfulAction =
					{
						type: BlocksActionTypes.GET_BLOCK_MAPPER_SUCCESSFUL,
						blockMapper
					};
				proposalBuilderStore.dispatch(getBlockMapperSuccessfulAction);
				const vendorTemplateDetailsSuccessfulAction: VendorTemplateDetailsSuccessfulAction =
					{
						type: VendorTemplateActionTypes.VENDOR_TEMPLATE_DETAILS_SUCCESSFUL,
						currentActiveTemplate: vendorTemplateDetailsResponse
					};
				return dispatch(vendorTemplateDetailsSuccessfulAction);
			} catch (error) {
				const VendorTemplateDetailsFailedAction: VendorTemplateDetailsFailedAction =
					{
						type: VendorTemplateActionTypes.VENDOR_TEMPLATE_DETAILS_FAILED
					};
				dispatch(VendorTemplateDetailsFailedAction);

				return Promise.reject(error);
			}
		};
	},
	linkBlockToRevision:
		(
			updateDocumentRevisionRequest: VendorTemplateUpdateBlockListSequenceRequest
		) =>
		async (
			dispatch: Dispatch,
			getState: () => GlobalState
		): Promise<UpdateVendorTemplateDetailsSuccessfulAction> => {
			const { draggableId, index } = updateDocumentRevisionRequest;
			const currentTemplate =
				getState().vendorTemplate.currentActiveTemplate;
			if (!currentTemplate) {
				throw new Error("There is no active Template");
			}

			const latestBlockList = currentTemplate.block_list;
			latestBlockList.splice(index, 0, draggableId);
			currentTemplate.block_list = [...latestBlockList];

			const updatingDocumentWithNewBlockId: UpdateVendorTemplateDetailsSuccessfulAction =
				{
					type: VendorTemplateActionTypes.UPDATE_VENDOR_TEMPLATE_DETAILS_SUCCESSFUL,
					currentActiveTemplate: currentTemplate
				};

			await updateTemplateBlockList({
				templateId: currentTemplate.id,
				blockList: latestBlockList
			});
			return dispatch(updatingDocumentWithNewBlockId);
		},
	repositionBlockInRevision:
		(repositionRevisionRequest: VendorTemplateBlockRepositionRequest) =>
		async (
			dispatch: Dispatch,
			getState: () => GlobalState
		): Promise<UpdateVendorTemplateDetailsSuccessfulAction> => {
			try {
				const { sourceIndex, destinationIndex } =
					repositionRevisionRequest;
				const currentTemplate =
					getState().vendorTemplate.currentActiveTemplate;
				if (!currentTemplate) {
					throw new Error("There is no active Template");
				}

				const latestBlockList = currentTemplate.block_list;
				latestBlockList.splice(
					destinationIndex,
					0,
					latestBlockList.splice(sourceIndex, 1)[0]
				);
				currentTemplate.block_list = latestBlockList;

				const updatingDocumentWithNewBlockId: UpdateVendorTemplateDetailsSuccessfulAction =
					{
						type: VendorTemplateActionTypes.UPDATE_VENDOR_TEMPLATE_DETAILS_SUCCESSFUL,
						currentActiveTemplate: currentTemplate
					};

				await updateTemplateBlockList({
					templateId: currentTemplate.id,
					blockList: latestBlockList
				});
				return dispatch(updatingDocumentWithNewBlockId);
			} catch (error) {
				const updateTemplateDetailsFailedAction: UpdateVendorTemplateDetailsFailedAction =
					{
						type: VendorTemplateActionTypes.UPDATE_VENDOR_TEMPLATE_DETAILS_FAILED
					};
				dispatch(updateTemplateDetailsFailedAction);

				return Promise.reject(error);
			}
		},
	addBlockToList: (
		updateBlockListSequenceRequest: VendorTemplateUpdateBlockListSequenceRequest
	) => {
		return async (
			dispatch: Dispatch,
			getState: () => GlobalState
		): Promise<UpdateVendorTemplateDetailsSuccessfulAction> => {
			const blockPlaceholder =
				LOADING_BLOCK_PLACEHOLDER + "::" + Date.now();
			const { draggableId, index } = updateBlockListSequenceRequest;

			try {
				let currentTemplate =
					getState().vendorTemplate.currentActiveTemplate;
				if (!currentTemplate) {
					throw new Error("There is no active Template");
				}
				const templateId = currentTemplate.id;

				// showing loader in the document
				let latestBlockList = currentTemplate.block_list;
				latestBlockList.splice(index, 0, blockPlaceholder);
				const updateCurrentTemplateRequestedAction: UpdateVendorTemplateDetailsRequestedAction =
					{
						type: VendorTemplateActionTypes.UPDATE_VENDOR_TEMPLATE_DETAILS_REQUESTED
					};
				dispatch(updateCurrentTemplateRequestedAction);

				// creating the block
				const createBlockRequestedAction: CreateBlockRequestedAction = {
					type: BlocksActionTypes.CREATE_BLOCK_REQUESTED
				};
				proposalBuilderStore.dispatch(createBlockRequestedAction);
				const blockType = draggableIdToBlockTypeMapper[draggableId];
				const blockDataRequest = createNewBlock(
					blockType,
					undefined,
					undefined,
					true
				);
				const blockDataResponse = await addBlock(blockDataRequest);
				const { payment_summary: paymentSummary, ...blockData } =
					blockDataResponse;
				// updating the revision with the new block id and data
				const createBlockSuccessfulAction: CreateBlockSuccessfulAction =
					{
						type: BlocksActionTypes.CREATE_BLOCK_SUCCESSFUL,
						blockData: blockData
					};
				proposalBuilderStore.dispatch(createBlockSuccessfulAction);

				const newBlockId = blockDataResponse.id;
				currentTemplate =
					getState().vendorTemplate.currentActiveTemplate;
				if (!currentTemplate) {
					throw new Error("There is no active Template");
				}

				latestBlockList = currentTemplate.block_list;
				const placeholderIndex =
					latestBlockList.indexOf(blockPlaceholder);
				latestBlockList.splice(placeholderIndex, 1, newBlockId);
				currentTemplate.block_list = latestBlockList;
				updateTemplateBlockList({
					templateId,
					blockList: latestBlockList
				});

				const updatingTemplateWithNewBlockId: UpdateVendorTemplateDetailsSuccessfulAction =
					{
						type: VendorTemplateActionTypes.UPDATE_VENDOR_TEMPLATE_DETAILS_SUCCESSFUL,
						currentActiveTemplate: currentTemplate
					};
				// updateTemplateBlockList({
				// 	blockList: latestBlockList,
				// 	templateId: currentTemplate.id
				// });
				return dispatch(updatingTemplateWithNewBlockId);
			} catch (error) {
				const updateTemplateDetailsFailedAction: UpdateVendorTemplateDetailsFailedAction =
					{
						type: VendorTemplateActionTypes.UPDATE_VENDOR_TEMPLATE_DETAILS_FAILED
					};
				dispatch(updateTemplateDetailsFailedAction);

				return Promise.reject(error);
			}
		};
	},
	deleteBlockFromRevision: ({ blockIds }: { blockIds: string[] }) => {
		return async (
			dispatch: Dispatch,
			getState: () => GlobalState
		): Promise<UpdateTemplateDetailsSuccessfulAction> => {
			try {
				const updateTemplateDetailsRequestedAction: UpdateVendorTemplateDetailsRequestedAction =
					{
						type: VendorTemplateActionTypes.UPDATE_VENDOR_TEMPLATE_DETAILS_REQUESTED
					};
				dispatch(updateTemplateDetailsRequestedAction);

				// updating the document revision
				const currentTemplate =
					getState().vendorTemplate.currentActiveTemplate;
				if (!currentTemplate) {
					throw new Error("There is no active document");
				}
				let latestBlockListIds =
					currentTemplate.block_list || new Array<string>();
				latestBlockListIds = latestBlockListIds.filter(
					id => !blockIds.includes(id)
				);
				currentTemplate.block_list = latestBlockListIds;

				// making http calls
				await updateTemplateBlockList({
					templateId: currentTemplate.id,
					blockList: latestBlockListIds
				});

				// updating the state
				const updateDocumentRevisionBlockListAction: UpdateTemplateDetailsSuccessfulAction =
					{
						type: VendorTemplateActionTypes.UPDATE_VENDOR_TEMPLATE_DETAILS_SUCCESSFUL,
						currentActiveTemplate: currentTemplate
					};
				return dispatch(updateDocumentRevisionBlockListAction);
			} catch (error) {
				console.error(error);
				const updateTemplateDetailsFailedAction: UpdateVendorTemplateDetailsFailedAction =
					{
						type: VendorTemplateActionTypes.UPDATE_VENDOR_TEMPLATE_DETAILS_FAILED
					};
				dispatch(updateTemplateDetailsFailedAction);

				return Promise.reject(error);
			}
		};
	},
	updateMergeTags: (mergeTagVariables: MergeTagVariable[]) => {
		return async (
			dispatch: Dispatch,
			getState: () => GlobalState
		): Promise<UpdateMergeTagSuccessfulAction> => {
			const updateMergeTagRequestAction: UpdateMergeTagRequestedAction = {
				type: VendorTemplateActionTypes.UPDATE_MERGE_TAG_REQUESTED
			};
			dispatch(updateMergeTagRequestAction);
			try {
				const currentActiveTemplate =
					getState().vendorTemplate.currentActiveTemplate;
				if (!currentActiveTemplate) {
					throw new Error("There is no active template");
				} else {
					const newTemplateToUpdate: VendorTemplateDetail = {
						...currentActiveTemplate,
						merge_tag_custom_variables: mergeTagVariables
					};

					const editDocumentResponse = editVendorTemplate(
						newTemplateToUpdate.id,
						newTemplateToUpdate
					);
					const updateMergeTagSuccessfulAction: UpdateMergeTagSuccessfulAction =
						{
							type: VendorTemplateActionTypes.UPDATE_MERGE_TAG_SUCCESSFUL,
							customMergeTagVariables: (
								await editDocumentResponse
							).merge_tag_custom_variables
						};
					return dispatch(updateMergeTagSuccessfulAction);
				}
			} catch (error) {
				const updateMergeTagFailedAction: UpdateMergeTagFailedAction = {
					type: VendorTemplateActionTypes.UPDATE_MERGE_TAG_FAILED
				};
				dispatch(updateMergeTagFailedAction);

				return Promise.reject(error);
			}
		};
	},
	showVendorTemplatePreviewModal: () => {
		return (dispatch: Dispatch): ShowVendorTemplatePreviewModalAction => {
			const ShowTemplatePreviewModalAction: ShowVendorTemplatePreviewModalAction =
				{
					type: VendorTemplateActionTypes.SHOW_PREVIEW_VENDOR_TEMPLATE_MODAL
				};
			return dispatch(ShowTemplatePreviewModalAction);
		};
	},
	hideVendorTemplatePreviewModal: () => {
		return (dispatch: Dispatch): HideVendorTemplatePreviewModalAction => {
			const HideTemplatePreviewModalAction: HideVendorTemplatePreviewModalAction =
				{
					type: VendorTemplateActionTypes.HIDE_PREVIEW_VENDOR_TEMPLATE_MODAL
				};
			return dispatch(HideTemplatePreviewModalAction);
		};
	},
	openTemplateSidePanel: () => {
		return (dispatch: Dispatch): OpenTemplateSidePaneAction => {
			const openDocumentSidePaneAction: OpenTemplateSidePaneAction = {
				type: VendorTemplateActionTypes.OPEN_TEMPLATE_SIDE_PANEL
			};
			return dispatch(openDocumentSidePaneAction);
		};
	},
	closeTemplateSidePanel: () => {
		return (dispatch: Dispatch): CloseTemplateSidePaneAction => {
			const closeDocumentSidePaneAction: CloseTemplateSidePaneAction = {
				type: VendorTemplateActionTypes.CLOSE_TEMPLATE_SIDE_PANEL
			};
			return dispatch(closeDocumentSidePaneAction);
		};
	},
	fetchAllTemplateView: () => {
		return async (
			dispatch: Dispatch
		): Promise<FetchAllTemplateViewSuccessful> => {
			const fetchAllTemplateViewRequested: FetchAllTemplateViewRequested =
				{
					type: VendorTemplateActionTypes.FETCH_ALL_TEMPLATE_VIEWS_REQUESTED
				};

			dispatch(fetchAllTemplateViewRequested);

			try {
				const response = await getAllTemplateViews();
				const fetchAllTemplateViewSuccessful: FetchAllTemplateViewSuccessful =
					{
						type: VendorTemplateActionTypes.FETCH_ALL_TEMPLATE_VIEWS_SUCCESSFUL,
						viewList: response
					};
				return dispatch(fetchAllTemplateViewSuccessful);
			} catch (error) {
				console.error(error);

				const fetchAllTemplateViewFailed: FetchAllTemplateViewFailed = {
					type: VendorTemplateActionTypes.FETCH_ALL_TEMPLATE_VIEWS_FAILED
				};
				dispatch(fetchAllTemplateViewFailed);

				return Promise.reject(error);
			}
		};
	},
	updateCurrentActiveView: (
		selectedCurrentActiveView: CustomView,
		discardingView?: boolean
	) => {
		return (
			dispatch: Dispatch,
			getState: () => GlobalState
		): UpdateCurrentActiveViewAction => {
			const currentActiveView =
				getState().vendorTemplate.currentActiveView;
			const prevViewData = getState().vendorTemplate.viewList?.data.find(
				view => view.id === selectedCurrentActiveView?.id
			);

			const isSameView =
				currentActiveView?.id === selectedCurrentActiveView?.id;

			if (isSameView) {
				// if its a custom view then it would have an id
				if (prevViewData?.id) {
					const hasFiltersChanged = areFiltersChanged(
						prevViewData.filter_criteria,
						selectedCurrentActiveView.filter_criteria
					);

					const hasColumnsChanged =
						JSON.stringify(selectedCurrentActiveView.columns) !==
						JSON.stringify(prevViewData.columns);

					const updateCurrentActiveViewAction: UpdateCurrentActiveViewAction =
						{
							type: VendorTemplateActionTypes.UPDATE_TEMPLATE_CURRENT_ACTIVE_VIEW,
							currentActiveView: selectedCurrentActiveView,
							isViewChanged: discardingView
								? false
								: hasFiltersChanged || hasColumnsChanged
						};
					return dispatch(updateCurrentActiveViewAction);
				}

				// if its a system view then no id

				if (currentActiveView) {
					const hasFiltersChanged = areFiltersChanged(
						currentActiveView.filter_criteria,
						selectedCurrentActiveView.filter_criteria
					);

					const hasColumnsChanged =
						JSON.stringify(selectedCurrentActiveView.columns) !==
						JSON.stringify(currentActiveView?.columns);

					const updateCurrentActiveViewAction: UpdateCurrentActiveViewAction =
						{
							type: VendorTemplateActionTypes.UPDATE_TEMPLATE_CURRENT_ACTIVE_VIEW,
							currentActiveView: selectedCurrentActiveView,
							isViewChanged: discardingView
								? false
								: hasFiltersChanged || hasColumnsChanged
						};
					return dispatch(updateCurrentActiveViewAction);
				}
			}

			const updateCurrentActiveViewAction: UpdateCurrentActiveViewAction =
				{
					type: VendorTemplateActionTypes.UPDATE_TEMPLATE_CURRENT_ACTIVE_VIEW,
					currentActiveView: selectedCurrentActiveView,
					isViewChanged: discardingView ? true : false
				};
			return dispatch(updateCurrentActiveViewAction);
		};
	},
	createTemplateView: (view: CustomView) => {
		return async (
			dispatch: Dispatch
		): Promise<CreateTemplateViewSuccessfulAction> => {
			const createTemplateViewRequestedAction: CreateTemplateViewRequestedAction =
				{
					type: VendorTemplateActionTypes.CREATE_TEMPLATE_VIEW_REQUESTED
				};

			dispatch(createTemplateViewRequestedAction);

			try {
				const response = await createTemplateView(view);
				const createTemplateViewSuccessfulAction: CreateTemplateViewSuccessfulAction =
					{
						type: VendorTemplateActionTypes.CREATE_TEMPLATE_VIEW_SUCCESSFUL,
						view: response
					};
				return dispatch(createTemplateViewSuccessfulAction);
			} catch (error) {
				const createTemplateViewFailedAction: CreateTemplateViewFailedAction =
					{
						type: VendorTemplateActionTypes.CREATE_TEMPLATE_VIEW_FAILED
					};
				dispatch(createTemplateViewFailedAction);

				return Promise.reject(error);
			}
		};
	},
	updateTemplateView: (view: CustomView) => {
		return async (
			dispatch: Dispatch
		): Promise<UpdateTemplateViewSuccessfulAction> => {
			const updateTemplateViewRequestedAction: UpdateTemplateViewRequestedAction =
				{
					type: VendorTemplateActionTypes.UPDATE_TEMPLATE_VIEW_REQUESTED
				};

			dispatch(updateTemplateViewRequestedAction);

			try {
				const response = await updateTemplateView(view);
				const updateTemplateViewSuccessfulAction: UpdateTemplateViewSuccessfulAction =
					{
						type: VendorTemplateActionTypes.UPDATE_TEMPLATE_VIEW_SUCCESSFUL,
						view: response
					};
				return dispatch(updateTemplateViewSuccessfulAction);
			} catch (error) {
				const updateTemplateViewFailedAction: UpdateTemplateViewFailedAction =
					{
						type: VendorTemplateActionTypes.UPDATE_TEMPLATE_VIEW_FAILED
					};
				dispatch(updateTemplateViewFailedAction);

				return Promise.reject(error);
			}
		};
	},
	deleteTemplateView: (deleteView: CustomView, initialView: CustomView) => {
		return async (
			dispatch: Dispatch
		): Promise<DeleteTemplateViewSuccessfulAction> => {
			const deleteTemplateViewRequestedAction: DeleteTemplateViewRequestedAction =
				{
					type: VendorTemplateActionTypes.DELETE_TEMPLATE_VIEW_REQUESTED
				};

			dispatch(deleteTemplateViewRequestedAction);

			try {
				await deleteTemplateView(deleteView.id);
				const deleteTemplateViewSuccessfulAction: DeleteTemplateViewSuccessfulAction =
					{
						type: VendorTemplateActionTypes.DELETE_TEMPLATE_VIEW_SUCCESSFUL,
						deletedView: deleteView,
						initialView: initialView
					};
				return dispatch(deleteTemplateViewSuccessfulAction);
			} catch (error) {
				const deleteTemplateViewFailedAction: DeleteTemplateViewFailedAction =
					{
						type: VendorTemplateActionTypes.DELETE_TEMPLATE_VIEW_FAILED
					};
				dispatch(deleteTemplateViewFailedAction);

				return Promise.reject(error);
			}
		};
	},
	resetViews: () => {
		return (dispatch: Dispatch): ResetCurrentActiveViewAction => {
			const resetCurrentActiveViewAction: ResetCurrentActiveViewAction = {
				type: VendorTemplateActionTypes.RESET_TEMPLATE_CURRENT_ACTIVE_VIEW
			};

			return dispatch(resetCurrentActiveViewAction);
		};
	}
};
