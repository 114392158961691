import {
	VendorClient,
	VendorPartner,
	VendorOpportunity,
	VendorClientContact,
	SKUProduct
} from "@zomentum/contracts";
import { CustomField } from "@zomentum/contracts/dist/Settings";
import moment from "moment";

export const getInitialValueForCustomField = (
	currentActiveEntity:
		| VendorOpportunity
		| VendorPartner
		| VendorClient
		| VendorClientContact
		| SKUProduct,
	isDateCustomField: boolean,
	customField: CustomField
) => {
	const values = currentActiveEntity?.custom_fields?.find(
		field => field.id === customField.id
	)?.values;
	const initialValue = values
		? isDateCustomField && values[0]
			? moment(values[0])
			: values[0]
		: undefined;
	return initialValue;
};
