'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var _commonjsHelpers = require('./_commonjsHelpers.js');
var index = require('../node_modules/omit.js/es/index.js');

var require$$4 = /*@__PURE__*/_commonjsHelpers.getAugmentedNamespace(index);

exports["default"] = require$$4;
