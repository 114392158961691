'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.GlobalSearchEntityType = void 0;
(function (GlobalSearchEntityType) {
  GlobalSearchEntityType["Client"] = "client_company";
  GlobalSearchEntityType["Document"] = "document";
  GlobalSearchEntityType["ClientContact"] = "client_user";
  GlobalSearchEntityType["Product"] = "item";
  GlobalSearchEntityType["Opportunity"] = "opportunity";
  GlobalSearchEntityType["SalesActivity"] = "sales_activity";
  GlobalSearchEntityType["Supplier"] = "supplier";
  GlobalSearchEntityType["Task"] = "task";
  GlobalSearchEntityType["Meeting"] = "meeting";
  GlobalSearchEntityType["LogEmail"] = "log_email";
  GlobalSearchEntityType["LogCall"] = "log_call";
  GlobalSearchEntityType["User"] = "user";
  GlobalSearchEntityType["VendorCompany"] = "company";
  GlobalSearchEntityType["VendorResource"] = "vendor_resource";
  GlobalSearchEntityType["MarketplaceListing"] = "marketplace_listing";
  GlobalSearchEntityType["Comment"] = "comment";
  GlobalSearchEntityType["MarketplaceSKU"] = "marketplace_sku";
  GlobalSearchEntityType["VendorTemplates"] = "template";
  GlobalSearchEntityType["VendorClient"] = "vendor_client_company";
  GlobalSearchEntityType["VendorOpportunity"] = "vendor_opportunity";
  GlobalSearchEntityType["VendorPartner"] = "partner_metadata";
  GlobalSearchEntityType["VendorUser"] = "vendor_user";
  GlobalSearchEntityType["VendorCompanies"] = "vendor_company";
})(exports.GlobalSearchEntityType || (exports.GlobalSearchEntityType = {}));
var GlobalSearchRequest = /** @class */function () {
  function GlobalSearchRequest(keywords, includedEntities, limit, nextCursor, useAtlasSearch) {
    this.keywords = keywords;
    this.includedEntities = includedEntities;
    this.limit = limit;
    this.nextCursor = nextCursor;
    this.useAtlasSearch = useAtlasSearch;
  }
  return GlobalSearchRequest;
}();

exports.GlobalSearchRequest = GlobalSearchRequest;
