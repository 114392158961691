import { Reducer } from "redux";

import {
	LibraryItem,
	UserEmailSignature
} from "@zomentum/contracts/dist/Users";

import { UsersActionTypes, UsersActions } from "@/Actions/User";

export interface UsersState {
	readonly libraryList: LibraryItem[];
	readonly isLibraryLoading: boolean;
	readonly emailSignatures: UserEmailSignature;
}

export const initialUserState: UsersState = {
	libraryList: [],
	isLibraryLoading: false,
	emailSignatures: new UserEmailSignature()
};

export const UserReducer: Reducer<UsersState, UsersActions> = (
	state: UsersState = initialUserState,
	action: UsersActions
): UsersState => {
	switch (action.type) {
		case UsersActionTypes.SHOW_LIBRARY_LOADER: {
			return { ...state, isLibraryLoading: true };
		}
		case UsersActionTypes.HIDE_LIBRARY_LOADER: {
			return { ...state, isLibraryLoading: false };
		}
		case UsersActionTypes.FETCH_LIBRARY_LIST_SUCCESSFUL: {
			return { ...state, libraryList: action.libraryList };
		}
		case UsersActionTypes.FETCH_EMAIL_SIGNATURES_SUCCESSFUL: {
			return {
				...state,
				emailSignatures: action.emailSignature
			};
		}
		case UsersActionTypes.UPDATE_EMAIL_SIGNATURES_SUCCESSFUL: {
			return {
				...state,
				emailSignatures: action.emailSignature
			};
		}
		default: {
			return state;
		}
	}
};

export default UserReducer;
