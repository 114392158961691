'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var TwoToneIconsSprite = require('../packages/config/assets/TwoToneIconsSprite.svg.js');
var _commonjsHelpers = require('./_commonjsHelpers.js');

var TwoToneIconSprite = _commonjsHelpers.getCjsExportFromNamespace(TwoToneIconsSprite);

exports["default"] = TwoToneIconSprite;
