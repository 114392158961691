import React, { useState } from "react";
import { ZAlert, ZModal, ZRadioGroup, ZTypography } from "@zomentum/atoms";
import { IClientConflictModalProps } from "./interface";
import { Col, Row } from "antd";
import { RadioChangeEvent } from "antd/es/radio/interface";
import { VendorClientEditOrAddRequest } from "@zomentum/contracts";
import "./index.less";
import { useForm } from "antd/es/form/Form";
import {
	EWidgets,
	IFormProps,
	IFormSectionProps
} from "@zomentum/organisms/dist/ZForm/interface";
import ZForm from "@zomentum/organisms/dist/ZForm";
import { getShippingAddressRowText } from "@zomentum/utils/dist/common";

const ZClientConflictModal: React.FC<IClientConflictModalProps> = ({
	conflictedVendorClient,
	pendingOpportunity,
	visible,
	onCancel,
	acceptPendingOpportunity,
	createClient
}) => {
	const [form] = useForm();
	const [isLoading, setIsLoading] = useState(false);
	const [shareWithExistingClient, setShareWithExistingClient] =
		useState(true);
	const onProceed = async () => {
		try {
			setIsLoading(true);
			if (shareWithExistingClient) {
				await acceptPendingOpportunity(conflictedVendorClient.id);
			} else {
				await form.validateFields();
				const createClientRequest: VendorClientEditOrAddRequest = {
					name: form.getFieldValue("newClientName"),
					partner_manager_id: null,
					owner_user_id: null,
					fax: pendingOpportunity?.client_company?.fax ?? "",
					website: pendingOpportunity?.client_company?.website ?? "",
					linked_in_url:
						pendingOpportunity?.client_company?.linked_in_url ?? "",
					facebook_url:
						pendingOpportunity?.client_company?.facebook_url ?? "",
					twitter_url:
						pendingOpportunity?.client_company?.twitter_url ?? "",
					company_type:
						pendingOpportunity?.client_company?.company_type ??
						pendingOpportunity?.client_company
							?.company_types?.[0] ??
						null,
					billing_address:
						pendingOpportunity?.client_company?.billing_address ??
						null
				};
				createClientRequest.custom_fields =
					pendingOpportunity.client_company?.vendor_custom_fields ??
					[];
				const newClient = await createClient(createClientRequest);
				await acceptPendingOpportunity(newClient.id);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};
	const sections: IFormSectionProps[] = [
		{
			meta: {
				fields: [
					{
						wrapperCol: { span: 24 },
						key: "newClientName",
						widget: EWidgets.INPUT,
						className: "mb-0",
						widgetProps: {
							vertical: true,
							className: "w-full",
							placeholder: "Enter Client Name"
						},
						required: true,
						message: "Enter Client Name"
					}
				]
			}
		}
	];
	const formProps: IFormProps = {
		form,
		sections,
		key: "newClientNameForm",
		isSubmitAllowed: false
	};
	return (
		<ZModal
			size="md"
			visible={visible}
			onCancel={onCancel}
			title="Client Conflict Details"
			okText="Proceed"
			className="z-client-conflict-modal"
			confirmLoading={isLoading}
			onOk={onProceed}
		>
			<ZAlert
				className="mb-6"
				showIcon={true}
				type="warning"
				message={`Another client with the same name ${conflictedVendorClient.name} already exists in your CRM.`}
			/>
			<div className="mb-6 p-4 bg-gray-2 border-gray-4 border-solid border rounded">
				<Row className="mb-4">
					<Col span={24}>
						<ZTypography.ZTitle level={1} colorVariant={8}>
							Details of Client Shared by Partner
						</ZTypography.ZTitle>
					</Col>
				</Row>
				<Row>
					<Col span={8}>
						<ZTypography.ZText level={1}>
							Client Name:
						</ZTypography.ZText>
					</Col>
					<Col span={16}>
						<ZTypography.ZText level={1}>
							{pendingOpportunity.client_company?.name}
						</ZTypography.ZText>
					</Col>
				</Row>
				{pendingOpportunity.client_company?.billing_address && (
					<Row>
						<Col span={8}>
							<ZTypography.ZText level={1}>
								Billing Address:
							</ZTypography.ZText>
						</Col>
						<Col span={16}>
							<ZTypography.ZText level={1}>
								{getShippingAddressRowText(
									pendingOpportunity.client_company
										.billing_address
								)}
							</ZTypography.ZText>
						</Col>
					</Row>
				)}
			</div>
			<ZTypography.ZTitle level={1} colorVariant={10} className="mb-4">
				Do you wish to add the shared opportunity under the existing
				client or create a new client?
			</ZTypography.ZTitle>
			<ZRadioGroup
				space={24}
				onChange={(e: RadioChangeEvent) =>
					setShareWithExistingClient(
						e.target.value === 0 ? true : false
					)
				}
				options={[
					{
						label: (
							<ZTypography.ZText level={1} colorVariant={8}>
								Proceed with existing client
							</ZTypography.ZText>
						),
						value: 0
					},
					{
						label: (
							<div>
								<ZTypography.ZText level={1} colorVariant={8}>
									Create New Client
								</ZTypography.ZText>
								{!shareWithExistingClient && (
									<div className="ml-n6 mt-4">
										<ZAlert
											message="New Client"
											description="The shared opportunity will be added under the new client. Clients must have unique names in the CRM, add a different name to create new client."
											type="info"
											className="mb-6"
										/>
										<ZForm {...formProps} />
									</div>
								)}
							</div>
						),
						value: 1
					}
				]}
				value={shareWithExistingClient ? 0 : 1}
			/>
			{shareWithExistingClient && (
				<div className="min-h-46">
					<div className="mt-4 p-4 bg-gray-2 border-gray-4 border-solid border rounded">
						<Row className="mb-4">
							<Col span={24}>
								<ZTypography.ZTitle level={1} colorVariant={8}>
									Details of Client Existing in your CRM
								</ZTypography.ZTitle>
							</Col>
						</Row>
						<Row>
							<Col span={8}>
								<ZTypography.ZText level={1}>
									Client Name:
								</ZTypography.ZText>
							</Col>
							<Col span={16}>
								<ZTypography.ZText level={1}>
									{conflictedVendorClient.name}
								</ZTypography.ZText>
							</Col>
						</Row>
						{conflictedVendorClient.billing_address && (
							<Row>
								<Col span={8}>
									<ZTypography.ZText level={1}>
										Billing Address:
									</ZTypography.ZText>
								</Col>
								<Col span={16}>
									<ZTypography.ZText level={1}>
										{getShippingAddressRowText(
											conflictedVendorClient.billing_address
										)}
									</ZTypography.ZText>
								</Col>
							</Row>
						)}
					</div>
				</div>
			)}
		</ZModal>
	);
};

export default ZClientConflictModal;
