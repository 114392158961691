'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../../../node_modules/tslib/tslib.es6.mjs.js');
var index = require('../../../services/SalesActivity/index.js');
var contracts = require('@zomentum/contracts');
var index$1 = require('../../../services/Opportunity/index.js');
var index$2 = require('../../../services/Clients/index.js');
var molecules = require('@zomentum/molecules');
var utils = require('@zomentum/utils');

exports.SalesActivityActionTypes = void 0;
(function (SalesActivityActionTypes) {
  // Sales Activity Get Actions
  SalesActivityActionTypes["SHOW_SALES_ACTIVITY_LOADER"] = "SHOW_SALES_ACTIVITY_LOADER";
  SalesActivityActionTypes["HIDE_SALES_ACTIVITY_LOADER"] = "HIDE_SALES_ACTIVITY_LOADER";
  SalesActivityActionTypes["SALES_ACTIVITY_ACTIVE_TAB_CHANGED"] = "SALES_ACTIVITY_ACTIVE_TAB_CHANGED";
  SalesActivityActionTypes["ALL_SALES_ACTIVITY_REQUESTED"] = "ALL_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["ALL_SALES_ACTIVITY_SUCCESSFUL"] = "ALL_SALES_ACTIVITY_SUCCESSFUL";
  SalesActivityActionTypes["ALL_SALES_ACTIVITY_FAILED"] = "ALL_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["ALL_COMPLETED_SALES_ACTIVITY_REQUESTED"] = "ALL_COMPLETED_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["ALL_COMPLETED_SALES_ACTIVITY_SUCCESSFUL"] = "ALL_COMPLETED_SALES_ACTIVITY_SUCCESSFUL";
  SalesActivityActionTypes["ALL_COMPLETED_SALES_ACTIVITY_FAILED"] = "ALL_COMPLETED_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["ALL_OVERDUE_SALES_ACTIVITY_REQUESTED"] = "ALL_OVERDUE_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["ALL_OVERDUE_SALES_ACTIVITY_SUCCESSFUL"] = "ALL_OVERDUE_SALES_ACTIVITY_SUCCESSFUL";
  SalesActivityActionTypes["ALL_OVERDUE_SALES_ACTIVITY_FAILED"] = "ALL_OVERDUE_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["ALL_UPCOMING_SALES_ACTIVITY_REQUESTED"] = "ALL_UPCOMING_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["ALL_UPCOMING_SALES_ACTIVITY_SUCCESSFUL"] = "ALL_UPCOMING_SALES_ACTIVITY_SUCCESSFUL";
  SalesActivityActionTypes["ALL_UPCOMING_SALES_ACTIVITY_FAILED"] = "ALL_UPCOMING_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["ALL_SALES_ACTIVITY_RESET"] = "ALL_SALES_ACTIVITY_RESET";
  SalesActivityActionTypes["NOTES_SALES_ACTIVITY_REQUESTED"] = "NOTES_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["NOTES_SALES_ACTIVITY_SUCCESSFUL"] = "NOTES_SALES_ACTIVITY_SUCCESSFUL";
  SalesActivityActionTypes["NOTES_SALES_ACTIVITY_FAILED"] = "NOTES_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["NOTES_PINNED_SALES_ACTIVITY_REQUESTED"] = "NOTES_PINNED_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["NOTES_PINNED_SALES_ACTIVITY_SUCCESSFUL"] = "NOTES_PINNED_SALES_ACTIVITY_SUCCESSFUL";
  SalesActivityActionTypes["NOTES_PINNED_SALES_ACTIVITY_FAILED"] = "NOTES_PINNED_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["NOTES_COMPLETED_SALES_ACTIVITY_REQUESTED"] = "NOTES_COMPLETED_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["NOTES_COMPLETED_SALES_ACTIVITY_SUCCESSFUL"] = "NOTES_COMPLETED_SALES_ACTIVITY_SUCCESSFUL";
  SalesActivityActionTypes["NOTES_COMPLETED_SALES_ACTIVITY_FAILED"] = "NOTES_COMPLETED_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["NOTES_SALES_ACTIVITY_RESET"] = "NOTES_SALES_ACTIVITY_RESET";
  SalesActivityActionTypes["TASKS_SALES_ACTIVITY_REQUESTED"] = "TASKS_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["TASKS_SALES_ACTIVITY_SUCCESSFUL"] = "TASKS_SALES_ACTIVITY_SUCCESSFUL";
  SalesActivityActionTypes["TASKS_SALES_ACTIVITY_FAILED"] = "TASKS_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["TASKS_COMPLETED_SALES_ACTIVITY_REQUESTED"] = "TASKS_COMPLETED_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["TASKS_COMPLETED_SALES_ACTIVITY_SUCCESSFUL"] = "TASKS_COMPLETED_SALES_ACTIVITY_SUCCESSFUL";
  SalesActivityActionTypes["TASKS_COMPLETED_SALES_ACTIVITY_FAILED"] = "TASKS_COMPLETED_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["TASKS_OVERDUE_SALES_ACTIVITY_REQUESTED"] = "TASKS_OVERDUE_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["TASKS_OVERDUE_SALES_ACTIVITY_SUCCESSFUL"] = "TASKS_OVERDUE_SALES_ACTIVITY_SUCCESSFUL";
  SalesActivityActionTypes["TASKS_OVERDUE_SALES_ACTIVITY_FAILED"] = "TASKS_OVERDUE_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["TASKS_UPCOMING_SALES_ACTIVITY_REQUESTED"] = "TASKS_UPCOMING_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["TASKS_UPCOMING_SALES_ACTIVITY_SUCCESSFUL"] = "TASKS_UPCOMING_SALES_ACTIVITY_SUCCESSFUL";
  SalesActivityActionTypes["TASKS_UPCOMING_SALES_ACTIVITY_FAILED"] = "TASKS_UPCOMING_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["TASKS_SALES_ACTIVITY_RESET"] = "TASKS_SALES_ACTIVITY_RESET";
  SalesActivityActionTypes["MEETINGS_SALES_ACTIVITY_REQUESTED"] = "MEETINGS_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["MEETINGS_SALES_ACTIVITY_SUCCESSFUL"] = "MEETINGS_SALES_ACTIVITY_SUCCESSFUL";
  SalesActivityActionTypes["MEETINGS_SALES_ACTIVITY_FAILED"] = "MEETINGS_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["MEETINGS_COMPLETED_SALES_ACTIVITY_REQUESTED"] = "MEETINGS_COMPLETED_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["MEETINGS_COMPLETED_SALES_ACTIVITY_SUCCESSFUL"] = "MEETINGS_COMPLETED_SALES_ACTIVITY_SUCCESSFUL";
  SalesActivityActionTypes["MEETINGS_COMPLETED_SALES_ACTIVITY_FAILED"] = "MEETINGS_COMPLETED_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["MEETINGS_UPCOMING_SALES_ACTIVITY_REQUESTED"] = "MEETINGS_UPCOMING_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["MEETINGS_UPCOMING_SALES_ACTIVITY_SUCCESSFUL"] = "MEETINGS_UPCOMING_SALES_ACTIVITY_SUCCESSFUL";
  SalesActivityActionTypes["MEETINGS_UPCOMING_SALES_ACTIVITY_FAILED"] = "MEETINGS_UPCOMING_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["MEETINGS_SALES_ACTIVITY_RESET"] = "MEETINGS_SALES_ACTIVITY_RESET";
  SalesActivityActionTypes["CALLS_SALES_ACTIVITY_REQUESTED"] = "CALLS_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["CALLS_SALES_ACTIVITY_SUCCESSFUL"] = "CALLS_SALES_ACTIVITY_SUCCESSFUL";
  SalesActivityActionTypes["CALLS_SALES_ACTIVITY_FAILED"] = "CALLS_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["CALLS_SALES_ACTIVITY_RESET"] = "CALLS_SALES_ACTIVITY_RESET";
  SalesActivityActionTypes["EMAILS_SALES_ACTIVITY_REQUESTED"] = "EMAIL_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["EMAILS_SALES_ACTIVITY_SUCCESSFUL"] = "EMAIL_SALES_ACTIVITY_SUCCESSFUL";
  SalesActivityActionTypes["EMAILS_SALES_ACTIVITY_FAILED"] = "EMAIL_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["EMAILS_SALES_ACTIVITY_RESET"] = "EMAILS_SALES_ACTIVITY_RESET";
  SalesActivityActionTypes["UPDATE_CURRENT_ACTIVE_SALES_ACTIVITY"] = "UPDATE_CURRENT_ACTIVE_SALES_ACTIVITY";
  SalesActivityActionTypes["HISTORY_ACTIVITIES_REQUESTED"] = "HISTORY_ACTIVITIES_REQUESTED";
  SalesActivityActionTypes["HISTORY_ACTIVITIES_SUCCESSFUL"] = "HISTORY_ACTIVITIES_SUCCESSFUL";
  SalesActivityActionTypes["HISTORY_ACTIVITIES_FAILED"] = "HISTORY_ACTIVITIES_FAILED";
  SalesActivityActionTypes["HISTORY_ACTIVITIES_RESET"] = "HISTORY_ACTIVITIES_RESET";
  SalesActivityActionTypes["GET_ONE_MEETING_REQUESTED"] = "GET_ONE_MEETING_REQUESTED";
  SalesActivityActionTypes["GET_ONE_MEETING_SUCCESSFUL"] = "GET_ONE_MEETING_SUCCESSFUL";
  SalesActivityActionTypes["GET_ONE_MEETING_FAILED"] = "GET_ONE_MEETING_FAILED";
  SalesActivityActionTypes["GET_ONE_TASK_FAILED"] = "GET_ONE_TASK_FAILED";
  SalesActivityActionTypes["GET_ONE_TASK_REQUESTED"] = "GET_ONE_TASK_REQUESTED";
  SalesActivityActionTypes["GET_ONE_TASK_SUCCESSFUL"] = "GET_ONE_TASK_SUCCESSFUL";
  SalesActivityActionTypes["GET_ONE_NOTE_FAILED"] = "GET_ONE_NOTE_FAILED";
  SalesActivityActionTypes["GET_ONE_NOTE_REQUESTED"] = "GET_ONE_NOTE_REQUESTED";
  SalesActivityActionTypes["GET_ONE_NOTE_SUCCESSFUL"] = "GET_ONE_NOTE_SUCCESSFUL";
  SalesActivityActionTypes["GET_ONE_LOG_CALL_FAILED"] = "GET_ONE_LOG_CALL_FAILED";
  SalesActivityActionTypes["GET_ONE_LOG_CALL_REQUESTED"] = "GET_ONE_LOG_CALL_REQUESTED";
  SalesActivityActionTypes["GET_ONE_LOG_CALL_SUCCESSFUL"] = "GET_ONE_LOG_CALL_SUCCESSFUL";
  SalesActivityActionTypes["GET_ONE_LOG_EMAIL_FAILED"] = "GET_ONE_LOG_EMAIL_FAILED";
  SalesActivityActionTypes["GET_ONE_LOG_EMAIL_REQUESTED"] = "GET_ONE_LOG_EMAIL_REQUESTED";
  SalesActivityActionTypes["GET_ONE_LOG_EMAIL_SUCCESSFUL"] = "GET_ONE_LOG_EMAIL_SUCCESSFUL";
  // Sales Activity Delete / Add / Edit Actions
  SalesActivityActionTypes["DELETE_COMMENT_REQUESTED"] = "DELETE_COMMENT_REQUESTED";
  SalesActivityActionTypes["DELETE_COMMENT_SUCCESSFUL"] = "DELETE_COMMENT_SUCCESSFUL";
  SalesActivityActionTypes["DELETE_COMMENT_FAILED"] = "DELETE_COMMENT_FAILED";
  SalesActivityActionTypes["ADD_COMMENT_REQUESTED"] = "ADD_COMMENT_REQUESTED";
  SalesActivityActionTypes["ADD_COMMENT_SUCCESSFUL"] = "ADD_COMMENT_SUCCESSFUL";
  SalesActivityActionTypes["ADD_COMMENT_FAILED"] = "ADD_COMMENT_FAILED";
  SalesActivityActionTypes["EDIT_COMMENT_REQUESTED"] = "EDIT_COMMENT_REQUESTED";
  SalesActivityActionTypes["EDIT_COMMENT_SUCCESSFUL"] = "EDIT_COMMENT_SUCCESSFUL";
  SalesActivityActionTypes["EDIT_COMMENT_FAILED"] = "EDIT_COMMENT_FAILED";
  SalesActivityActionTypes["DELETE_TASK_REQUESTED"] = "DELETE_TASK_REQUESTED";
  SalesActivityActionTypes["DELETE_TASK_SUCCESSFUL"] = "DELETE_TASK_SUCCESSFUL";
  SalesActivityActionTypes["DELETE_TASK_FAILED"] = "DELETE_TASK_FAILED";
  SalesActivityActionTypes["ADD_TASK_REQUESTED"] = "ADD_TASK_REQUESTED";
  SalesActivityActionTypes["ADD_TASK_SUCCESSFUL"] = "ADD_TASK_SUCCESSFUL";
  SalesActivityActionTypes["ADD_TASK_FAILED"] = "ADD_TASK_FAILED";
  SalesActivityActionTypes["EDIT_TASK_REQUESTED"] = "EDIT_TASK_REQUESTED";
  SalesActivityActionTypes["EDIT_TASK_SUCCESSFUL"] = "EDIT_TASK_SUCCESSFUL";
  SalesActivityActionTypes["EDIT_TASK_FAILED"] = "EDIT_TASK_FAILED";
  SalesActivityActionTypes["DELETE_MEETING_REQUESTED"] = "DELETE_MEETING_REQUESTED";
  SalesActivityActionTypes["DELETE_MEETING_SUCCESSFUL"] = "DELETE_MEETING_SUCCESSFUL";
  SalesActivityActionTypes["DELETE_MEETING_FAILED"] = "DELETE_MEETING_FAILED";
  SalesActivityActionTypes["ADD_MEETING_REQUESTED"] = "ADD_MEETING_REQUESTED";
  SalesActivityActionTypes["ADD_MEETING_SUCCESSFUL"] = "ADD_MEETING_SUCCESSFUL";
  SalesActivityActionTypes["ADD_MEETING_FAILED"] = "ADD_MEETING_FAILED";
  SalesActivityActionTypes["EDIT_MEETING_REQUESTED"] = "EDIT_MEETING_REQUESTED";
  SalesActivityActionTypes["EDIT_MEETING_SUCCESSFUL"] = "EDIT_MEETING_SUCCESSFUL";
  SalesActivityActionTypes["EDIT_MEETING_FAILED"] = "EDIT_MEETING_FAILED";
  SalesActivityActionTypes["DELETE_LOG_CALL_REQUESTED"] = "DELETE_LOG_CALL_REQUESTED";
  SalesActivityActionTypes["DELETE_LOG_CALL_SUCCESSFUL"] = "DELETE_LOG_CALL_SUCCESSFUL";
  SalesActivityActionTypes["DELETE_LOG_CALL_FAILED"] = "DELETE_LOG_CALL_FAILED";
  SalesActivityActionTypes["ADD_LOG_CALL_REQUESTED"] = "ADD_LOG_CALL_REQUESTED";
  SalesActivityActionTypes["ADD_LOG_CALL_SUCCESSFUL"] = "ADD_LOG_CALL_SUCCESSFUL";
  SalesActivityActionTypes["ADD_LOG_CALL_FAILED"] = "ADD_LOG_CALL_FAILED";
  SalesActivityActionTypes["EDIT_LOG_CALL_REQUESTED"] = "EDIT_LOG_CALL_REQUESTED";
  SalesActivityActionTypes["EDIT_LOG_CALL_SUCCESSFUL"] = "EDIT_LOG_CALL_SUCCESSFUL";
  SalesActivityActionTypes["EDIT_LOG_CALL_FAILED"] = "EDIT_LOG_CALL_FAILED";
  SalesActivityActionTypes["DELETE_LOG_EMAIL_REQUESTED"] = "DELETE_LOG_EMAIL_REQUESTED";
  SalesActivityActionTypes["DELETE_LOG_EMAIL_SUCCESSFUL"] = "DELETE_LOG_EMAIL_SUCCESSFUL";
  SalesActivityActionTypes["DELETE_LOG_EMAIL_FAILED"] = "DELETE_LOG_EMAIL_FAILED";
  SalesActivityActionTypes["ADD_LOG_EMAIL_REQUESTED"] = "ADD_LOG_EMAIL_REQUESTED";
  SalesActivityActionTypes["ADD_LOG_EMAIL_SUCCESSFUL"] = "ADD_LOG_EMAIL_SUCCESSFUL";
  SalesActivityActionTypes["ADD_LOG_EMAIL_FAILED"] = "ADD_LOG_EMAIL_FAILED";
  SalesActivityActionTypes["EDIT_LOG_EMAIL_REQUESTED"] = "EDIT_LOG_EMAIL_REQUESTED";
  SalesActivityActionTypes["EDIT_LOG_EMAIL_SUCCESSFUL"] = "EDIT_LOG_EMAIL_SUCCESSFUL";
  SalesActivityActionTypes["EDIT_LOG_EMAIL_FAILED"] = "EDIT_LOG_EMAIL_FAILED";
  SalesActivityActionTypes["SYNC_WITH_PSA_REQUESTED"] = "SYNC_WITH_PSA_REQUESTED";
  SalesActivityActionTypes["SYNC_WITH_PSA_SUCCESSFUL"] = "SYNC_WITH_PSA_SUCCESSFUL";
  SalesActivityActionTypes["SYNC_WITH_PSA_FAILED"] = "SYNC_WITH_PSA_FAILED";
  // Sales Activity Automation Actions
  SalesActivityActionTypes["SALES_ACTIVITY_AUTOMATIONS_REQUESTED"] = "SALES_ACTIVITY_AUTOMATIONS_REQUESTED";
  SalesActivityActionTypes["SALES_ACTIVITY_AUTOMATIONS_SUCCESSFUL"] = "SALES_ACTIVITY_AUTOMATIONS_SUCCESSFUL";
  SalesActivityActionTypes["SALES_ACTIVITY_AUTOMATIONS_FAILED"] = "SALES_ACTIVITY_AUTOMATIONS_FAILED";
  SalesActivityActionTypes["SALES_ACTIVITY_AUTOMATION_METADATA_REQUESTED"] = "SALES_ACTIVITY_AUTOMATION_METADATA_REQUESTED";
  SalesActivityActionTypes["SALES_ACTIVITY_AUTOMATION_METADATA_SUCCESSFUL"] = "SALES_ACTIVITY_AUTOMATION_METADATA_SUCCESSFUL";
  SalesActivityActionTypes["SALES_ACTIVITY_AUTOMATION_METADATA_FAILED"] = "SALES_ACTIVITY_AUTOMATION_METADATA_FAILED";
  SalesActivityActionTypes["SALES_ACTIVITY_AUTOMATION_DETAILS_REQUESTED"] = "SALES_ACTIVITY_AUTOMATION_DETAILS_REQUESTED";
  SalesActivityActionTypes["SALES_ACTIVITY_AUTOMATION_DETAILS_SUCCESSFUL"] = "SALES_ACTIVITY_AUTOMATION_DETAILS_SUCCESSFUL";
  SalesActivityActionTypes["SALES_ACTIVITY_AUTOMATION_DETAILS_FAILED"] = "SALES_ACTIVITY_AUTOMATION_DETAILS_FAILED";
  SalesActivityActionTypes["SALES_ACTIVITY_AUTOMATION_DETAILS_RESET"] = "SALES_ACTIVITY_AUTOMATION_DETAILS_RESET";
  SalesActivityActionTypes["REORDER_SALES_ACTIVITY_AUTOMATION_REQUESTED"] = "REORDER_SALES_ACTIVITY_AUTOMATION_REQUESTED";
  SalesActivityActionTypes["REORDER_SALES_ACTIVITY_AUTOMATION_SUCCESSFUL"] = "REORDER_SALES_ACTIVITY_AUTOMATION_SUCCESSFUL";
  SalesActivityActionTypes["REORDER_SALES_ACTIVITY_AUTOMATION_FAILED"] = "REORDER_SALES_ACTIVITY_AUTOMATION_FAILED";
  SalesActivityActionTypes["RESET_SALES_ACTIVITY_AUTOMATIONS"] = "RESET_SALES_ACTIVITY_AUTOMATIONS";
  SalesActivityActionTypes["DELETE_SALES_ACTIVITY_AUTOMATION_REQUESTED"] = "DELETE_SALES_ACTIVITY_AUTOMATION_REQUESTED";
  SalesActivityActionTypes["DELETE_SALES_ACTIVITY_AUTOMATION_SUCCESSFUL"] = "DELETE_SALES_ACTIVITY_AUTOMATION_SUCCESSFUL";
  SalesActivityActionTypes["DELETE_SALES_ACTIVITY_AUTOMATION_FAILED"] = "DELETE_SALES_ACTIVITY_AUTOMATION_FAILED";
  SalesActivityActionTypes["ADD_SALES_ACTIVITY_AUTOMATION_REQUESTED"] = "ADD_SALES_ACTIVITY_AUTOMATION_REQUESTED";
  SalesActivityActionTypes["ADD_SALES_ACTIVITY_AUTOMATION_SUCCESSFUL"] = "ADD_SALES_ACTIVITY_AUTOMATION_SUCCESSFUL";
  SalesActivityActionTypes["ADD_SALES_ACTIVITY_AUTOMATION_FAILED"] = "ADD_SALES_ACTIVITY_AUTOMATION_FAILED";
  SalesActivityActionTypes["EDIT_SALES_ACTIVITY_AUTOMATION_REQUESTED"] = "EDIT_SALES_ACTIVITY_AUTOMATION_REQUESTED";
  SalesActivityActionTypes["EDIT_SALES_ACTIVITY_AUTOMATION_SUCCESSFUL"] = "EDIT_SALES_ACTIVITY_AUTOMATION_SUCCESSFUL";
  SalesActivityActionTypes["EDIT_SALES_ACTIVITY_AUTOMATION_FAILED"] = "EDIT_SALES_ACTIVITY_AUTOMATION_FAILED";
  SalesActivityActionTypes["UPDATE_CURRENT_ACTIVE_SALES_ACTIVITY_AUTOMATION"] = "UPDATE_CURRENT_ACTIVE_SALES_ACTIVITY_AUTOMATION";
  SalesActivityActionTypes["FETCH_SALES_ACTIVITY_AND_AUTOMATION_VIEW_METADATA_REQUESTED"] = "FETCH_SALES_ACTIVITY_AND_AUTOMATION_VIEW_METADATA_REQUESTED";
  SalesActivityActionTypes["FETCH_SALES_ACTIVITY_AND_AUTOMATION_VIEW_METADATA_SUCCESSFUL"] = "FETCH_SALES_ACTIVITY_AND_AUTOMATION_VIEW_METADATA_SUCCESSFUL";
  SalesActivityActionTypes["FETCH_SALES_ACTIVITY_AND_AUTOMATION_VIEW_METADATA_FAILED"] = "FETCH_SALES_ACTIVITY_AND_AUTOMATION_VIEW_METADATA_FAILED";
  SalesActivityActionTypes["FETCH_SALES_ACTIVITY_AND_AUTOMATION_VIEWS_REQUESTED"] = "FETCH_SALES_ACTIVITY_AND_AUTOMATION_VIEWS_REQUESTED";
  SalesActivityActionTypes["FETCH_SALES_ACTIVITY_AND_AUTOMATION_VIEWS_SUCCESSFUL"] = "FETCH_SALES_ACTIVITY_AND_AUTOMATION_VIEWS_SUCCESSFUL";
  SalesActivityActionTypes["FETCH_SALES_ACTIVITY_AND_AUTOMATION_VIEWS_FAILED"] = "FETCH_SALES_ACTIVITY_AND_AUTOMATION_VIEWS_FAILED";
  SalesActivityActionTypes["UPDATE_SALES_ACTIVITY_AND_AUTOMATION_CURRENT_ACTIVE_VIEW"] = "UPDATE_SALES_ACTIVITY_AND_AUTOMATION_CURRENT_ACTIVE_VIEW";
  SalesActivityActionTypes["CREATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_REQUESTED"] = "CREATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_REQUESTED";
  SalesActivityActionTypes["CREATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_SUCCESSFUL"] = "CREATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_SUCCESSFUL";
  SalesActivityActionTypes["CREATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_FAILED"] = "CREATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_FAILED";
  SalesActivityActionTypes["UPDATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_REQUESTED"] = "UPDATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_REQUESTED";
  SalesActivityActionTypes["UPDATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_SUCCESSFUL"] = "UPDATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_SUCCESSFUL";
  SalesActivityActionTypes["UPDATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_FAILED"] = "UPDATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_FAILED";
  SalesActivityActionTypes["DELETE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_REQUESTED"] = "DELETE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_REQUESTED";
  SalesActivityActionTypes["DELETE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_SUCCESSFUL"] = "DELETE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_SUCCESSFUL";
  SalesActivityActionTypes["DELETE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_FAILED"] = "DELETE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_FAILED";
  SalesActivityActionTypes["FETCH_SALES_ACTIVITY_AND_AUTOMATION_LIST_REQUESTED"] = "FETCH_SALES_ACTIVITY_AND_AUTOMATION_LIST_REQUESTED";
  SalesActivityActionTypes["FETCH_SALES_ACTIVITY_AND_AUTOMATION_LIST_SUCCESSFUL"] = "FETCH_SALES_ACTIVITY_AND_AUTOMATION_LIST_SUCCESSFUL";
  SalesActivityActionTypes["FETCH_SALES_ACTIVITY_AND_AUTOMATION_LIST_FAILED"] = "FETCH_SALES_ACTIVITY_AND_AUTOMATION_LIST_FAILED";
  // Sales Activity Drawer Actions
  SalesActivityActionTypes["ADD_COMMENT_DRAWER_OPENED"] = "ADD_COMMENT_DRAWER_OPENED";
  SalesActivityActionTypes["ADD_COMMENT_DRAWER_CLOSED"] = "ADD_COMMENT_DRAWER_CLOSED";
  SalesActivityActionTypes["EDIT_COMMENT_DRAWER_OPENED"] = "EDIT_COMMENT_DRAWER_OPENED";
  SalesActivityActionTypes["EDIT_COMMENT_DRAWER_CLOSED"] = "EDIT_COMMENT_DRAWER_CLOSED";
  SalesActivityActionTypes["ADD_TASK_DRAWER_OPENED"] = "ADD_TASK_DRAWER_OPENED";
  SalesActivityActionTypes["ADD_TASK_DRAWER_CLOSED"] = "ADD_TASK_DRAWER_CLOSED";
  SalesActivityActionTypes["EDIT_TASK_DRAWER_OPENED"] = "EDIT_TASK_DRAWER_OPENED";
  SalesActivityActionTypes["EDIT_TASK_DRAWER_CLOSED"] = "EDIT_TASK_DRAWER_CLOSED";
  SalesActivityActionTypes["ADD_MEETING_DRAWER_OPENED"] = "ADD_MEETING_DRAWER_OPENED";
  SalesActivityActionTypes["ADD_MEETING_DRAWER_CLOSED"] = "ADD_MEETING_DRAWER_CLOSED";
  SalesActivityActionTypes["EDIT_MEETING_DRAWER_OPENED"] = "EDIT_MEETING_DRAWER_OPENED";
  SalesActivityActionTypes["EDIT_MEETING_DRAWER_CLOSED"] = "EDIT_MEETING_DRAWER_CLOSED";
  SalesActivityActionTypes["ADD_LOG_CALL_DRAWER_OPENED"] = "ADD_LOG_CALL_DRAWER_OPENED";
  SalesActivityActionTypes["ADD_LOG_CALL_DRAWER_CLOSED"] = "ADD_LOG_CALL_DRAWER_CLOSED";
  SalesActivityActionTypes["EDIT_LOG_CALL_DRAWER_OPENED"] = "EDIT_LOG_CALL_DRAWER_OPENED";
  SalesActivityActionTypes["EDIT_LOG_CALL_DRAWER_CLOSED"] = "EDIT_LOG_CALL_DRAWER_CLOSED";
  SalesActivityActionTypes["ADD_LOG_EMAIL_DRAWER_OPENED"] = "ADD_LOG_EMAIL_DRAWER_OPENED";
  SalesActivityActionTypes["ADD_LOG_EMAIL_DRAWER_CLOSED"] = "ADD_LOG_EMAIL_DRAWER_CLOSED";
  SalesActivityActionTypes["EDIT_LOG_EMAIL_DRAWER_OPENED"] = "EDIT_LOG_EMAIL_DRAWER_OPENED";
  SalesActivityActionTypes["EDIT_LOG_EMAIL_DRAWER_CLOSED"] = "EDIT_LOG_EMAIL_DRAWER_CLOSED";
  SalesActivityActionTypes["UPCOMING_ACTIVITIES_DRAWER_OPENED"] = "UPCOMING_ACTIVITIES_DRAWER_OPENED";
  SalesActivityActionTypes["UPCOMING_ACTIVITIES_DRAWER_CLOSED"] = "UPCOMING_ACTIVITIES_DRAWER_CLOSED";
  SalesActivityActionTypes["AUDIT_LOGS_DRAWER_OPENED"] = "AUDIT_LOGS_DRAWER_OPENED";
  SalesActivityActionTypes["AUDIT_LOGS_DRAWER_CLOSED"] = "AUDIT_LOGS_DRAWER_CLOSED";
  SalesActivityActionTypes["FETCH_USERS_ALL_SALES_ACTIVITY_REQUESTED"] = "FETCH_USERS_ALL_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["FETCH_USERS_ALL_SALES_ACTIVITY_SUCCESSFULLY"] = "FETCH_USERS_ALL_SALES_ACTIVITY_SUCCESSFULLY";
  SalesActivityActionTypes["FETCH_USERS_ALL_SALES_ACTIVITY_FAILED"] = "FETCH_USERS_ALL_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["RESET_USERS_ALL_SALES_ACTIVITY"] = "RESET_USERS_ALL_SALES_ACTIVITY";
  SalesActivityActionTypes["FETCH_OPPORTUNITIES_FOR_SALES_ACTIVITY_REQUESTED"] = "FETCH_OPPORTUNITIES_FOR_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["FETCH_OPPORTUNITIES_FOR_SALES_ACTIVITY_SUCCESSFUL"] = "FETCH_OPPORTUNITIES_FOR_SALES_ACTIVITY_SUCCESSFUL";
  SalesActivityActionTypes["FETCH_OPPORTUNITIES_FOR_SALES_ACTIVITY_FAILED"] = "FETCH_OPPORTUNITIES_FOR_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["FETCH_CLIENTS_FOR_SALES_ACTIVITY_REQUESTED"] = "FETCH_CLIENTS_FOR_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["FETCH_CLIENTS_FOR_SALES_ACTIVITY_SUCCESSFUL"] = "FETCH_CLIENTS_FOR_SALES_ACTIVITY_SUCCESSFUL";
  SalesActivityActionTypes["FETCH_CLIENTS_FOR_SALES_ACTIVITY_FAILED"] = "FETCH_CLIENTS_FOR_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["FETCH_CLIENT_CONTACTS_FOR_SALES_ACTIVITY_REQUESTED"] = "FETCH_CLIENT_CONTACTS_FOR_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["FETCH_CLIENT_CONTACTS_FOR_SALES_ACTIVITY_SUCCESSFUL"] = "FETCH_CLIENT_CONTACTS_FOR_SALES_ACTIVITY_SUCCESSFUL";
  SalesActivityActionTypes["FETCH_CLIENT_CONTACTS_FOR_SALES_ACTIVITY_FAILED"] = "FETCH_CLIENT_CONTACTS_FOR_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["RESET_SALES_ACTIVITY_OPPORTUNITIES"] = "RESET_SALES_ACTIVITIES_OPPORTUNITIES";
  SalesActivityActionTypes["RESET_SALES_ACTIVITY_CLIENTS"] = "RESET_SALES_ACTIVITY_CLIENTS";
  SalesActivityActionTypes["RESET_SALES_ACTIVITY_CLIENT_CONTACTS"] = "RESET_SALES_ACTIVITY_CLIENTS_CONTACTS";
  SalesActivityActionTypes["ADD_GLOBAL_COMMENT_DRAWER_OPENED"] = "ADD_GLOBAL_COMMENT_DRAWER_OPENED";
  SalesActivityActionTypes["ADD_GLOBAL_COMMENT_DRAWER_CLOSED"] = "ADD_GLOBAL_COMMENT_DRAWER_CLOSED";
  SalesActivityActionTypes["ADD_GLOBAL_TASK_DRAWER_OPENED"] = "ADD_GLOBAL_TASK_DRAWER_OPENED";
  SalesActivityActionTypes["ADD_GLOBAL_TASK_DRAWER_CLOSED"] = "ADD_GLOBAL_TASK_DRAWER_CLOSED";
  SalesActivityActionTypes["ADD_GLOBAL_MEETING_DRAWER_OPENED"] = "ADD_GLOBAL_MEETING_DRAWER_OPENED";
  SalesActivityActionTypes["ADD_GLOBAL_MEETING_DRAWER_CLOSED"] = "ADD_GLOBAL_MEETING_DRAWER_CLOSED";
  SalesActivityActionTypes["ADD_GLOBAL_LOG_CALL_DRAWER_OPENED"] = "ADD_GLOBAL_LOG_CALL_DRAWER_OPENED";
  SalesActivityActionTypes["ADD_GLOBAL_LOG_CALL_DRAWER_CLOSED"] = "ADD_GLOBAL_LOG_CALL_DRAWER_CLOSED";
  SalesActivityActionTypes["ADD_GLOBAL_LOG_EMAIL_DRAWER_OPENED"] = "ADD_GLOBAL_LOG_EMAIL_DRAWER_OPENED";
  SalesActivityActionTypes["ADD_GLOBAL_LOG_EMAIL_DRAWER_CLOSED"] = "ADD_GLOBAL_LOG_EMAIL_DRAWER_CLOSED";
  SalesActivityActionTypes["EDIT_GLOBAL_COMMENT_DRAWER_OPENED"] = "EDIT_GLOBAL_COMMENT_DRAWER_OPENED";
  SalesActivityActionTypes["EDIT_GLOBAL_COMMENT_DRAWER_CLOSED"] = "EDIT_GLOBAL_COMMENT_DRAWER_CLOSED";
  SalesActivityActionTypes["EDIT_GLOBAL_TASK_DRAWER_OPENED"] = "EDIT_GLOBAL_TASK_DRAWER_OPENED";
  SalesActivityActionTypes["EDIT_GLOBAL_TASK_DRAWER_CLOSED"] = "EDIT_GLOBAL_TASK_DRAWER_CLOSED";
  SalesActivityActionTypes["EDIT_GLOBAL_MEETING_DRAWER_OPENED"] = "EDIT_GLOBAL_MEETING_DRAWER_OPENED";
  SalesActivityActionTypes["EDIT_GLOBAL_MEETING_DRAWER_CLOSED"] = "EDIT_GLOBAL_MEETING_DRAWER_CLOSED";
  SalesActivityActionTypes["EDIT_GLOBAL_LOG_CALL_DRAWER_OPENED"] = "EDIT_GLOBAL_LOG_CALL_DRAWER_OPENED";
  SalesActivityActionTypes["EDIT_GLOBAL_LOG_CALL_DRAWER_CLOSED"] = "EDIT_GLOBAL_LOG_CALL_DRAWER_CLOSED";
  SalesActivityActionTypes["EDIT_GLOBAL_LOG_EMAIL_DRAWER_OPENED"] = "EDIT_GLOBAL_LOG_EMAIL_DRAWER_OPENED";
  SalesActivityActionTypes["EDIT_GLOBAL_LOG_EMAIL_DRAWER_CLOSED"] = "EDIT_GLOBAL_LOG_EMAIL_DRAWER_CLOSED";
  SalesActivityActionTypes["UPDATE_ACTIVE_FILTERS"] = "UPDATE_ACTIVE_FILTERS";
  SalesActivityActionTypes["ADD_ACTIVITY_TO_USERS_SALES_ACTIVITY"] = "ADD_ACTIVITY_TO_USERS_SALES_ACTIVITY";
  SalesActivityActionTypes["UPDATE_SINGLE_USERS_SALES_ACTIVITY"] = "UPDATE_SINGLE_USERS_SALES_ACTIVITY";
  SalesActivityActionTypes["GET_ONE_SALES_ACTIVITY_FAILED"] = "GET_ONE_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["GET_ONE_SALES_ACTIVITY_REQUESTED"] = "GET_ONE_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["GET_ONE_SALES_ACTIVITY_SUCCESSFUL"] = "GET_ONE_SALES_ACTIVITY_SUCCESSFUL";
  SalesActivityActionTypes["DELETE_ONE_SALES_ACTIVITY_REQUESTED"] = "DELETE_ONE_SALES_ACTIVITY_REQUESTED";
  SalesActivityActionTypes["DELETE_ONE_SALES_ACTIVITY_SUCCESSFULLY"] = "DELETE_ONE_SALES_ACTIVITY_SUCCESSFULLY";
  SalesActivityActionTypes["DELETE_ONE_SALES_ACTIVITY_FAILED"] = "DELETE_ONE_SALES_ACTIVITY_FAILED";
  SalesActivityActionTypes["FETCHING_MEETING_CLASH_STATUS_REQUESTED"] = "FETCHING_MEETING_CLASH_STATUS_REQUESTED";
  SalesActivityActionTypes["FETCHING_MEETING_CLASH_STATUS_SUCCESSFUL"] = "FETCHING_MEETING_CLASH_STATUS_SUCCESSFUL";
  SalesActivityActionTypes["FETCHING_MEETING_CLASH_STATUS_FAILED"] = "FETCHING_MEETING_CLASH_STATUS_FAILED";
  SalesActivityActionTypes["FETCH_ALL_PSA_AUDIT_LOGS_REQUESTED"] = "FETCH_ALL_PSA_AUDIT_LOGS_REQUESTED";
  SalesActivityActionTypes["FETCH_ALL_PSA_AUDIT_LOGS_SUCCESSFUL"] = "FETCH_ALL_PSA_AUDIT_LOGS_SUCCESSFUL";
  SalesActivityActionTypes["FETCH_ALL_PSA_AUDIT_LOGS_FAILED"] = "FETCH_ALL_PSA_AUDIT_LOGS_FAILED";
  SalesActivityActionTypes["RETRY_FAILED_AUDIT_LOG_REQUESTED"] = "RETRY_FAILED_AUDIT_LOG_REQUESTED";
  SalesActivityActionTypes["RETRY_FAILED_AUDIT_LOG_SUCCESFUL"] = "RETRY_FAILED_AUDIT_LOG_SUCCESFUL";
  SalesActivityActionTypes["RETRY_FAILED_AUDIT_LOG_FAILED"] = "RETRY_FAILED_AUDIT_LOG_FAILED";
  SalesActivityActionTypes["AUDIT_LOG_REDIRECT_URL_REQUESTED"] = "AUDIT_LOG_REDIRECT_URL_REQUESTED";
  SalesActivityActionTypes["AUDIT_LOG_REDIRECT_URL_SUCCESFUL"] = "AUDIT_LOG_REDIRECT_URL_SUCCESFUL";
  SalesActivityActionTypes["AUDIT_LOG_REDIRECT_URL_FAILED"] = "AUDIT_LOG_REDIRECT_URL_FAILED";
  SalesActivityActionTypes["UPDATE_CURRENT_ACTIVE_SALES_ACTIVITY_AUTOMATION_ACTION_INDEX"] = "UPDATE_CURRENT_ACTIVE_SALES_ACTIVITY_AUTOMATION_ACTION_INDEX";
  SalesActivityActionTypes["SHOW_PAYMENT_DRAWER"] = "SHOW_PAYMENT_DRAWER";
  SalesActivityActionTypes["HIDE_PAYMENT_DRAWER"] = "HIDE_PAYMENT_DRAWER";
  SalesActivityActionTypes["UPDATE_SELECTED_DOCUMENT_TYPE"] = "UPDATE_SELECTED_DOCUMENT_TYPE";
  SalesActivityActionTypes["GET_CONFLICT_MEETING_DETAIL_SUCCESSFUL"] = "GET_CONFLICT_MEETING_DETAIL_SUCCESSFUL";
  SalesActivityActionTypes["UPDATE_SELECTED_OWNER"] = "UPDATE_SELECTED_OWNER";
  SalesActivityActionTypes["UPDATE_AUDIT_LOGS_COUNT"] = "UPDATE_AUDIT_LOGS_COUNT";
})(exports.SalesActivityActionTypes || (exports.SalesActivityActionTypes = {}));
var SalesActivityActionCreators = {
  // Sales Activity Get Actions
  showSalesActivityLoader: function showSalesActivityLoader() {
    return function (dispatch) {
      var showSalesActivityLoaderAction = {
        type: exports.SalesActivityActionTypes.SHOW_SALES_ACTIVITY_LOADER
      };
      return dispatch(showSalesActivityLoaderAction);
    };
  },
  hideSalesActivityLoader: function hideSalesActivityLoader() {
    return function (dispatch) {
      var hideSalesActivityLoaderAction = {
        type: exports.SalesActivityActionTypes.HIDE_SALES_ACTIVITY_LOADER
      };
      return dispatch(hideSalesActivityLoaderAction);
    };
  },
  salesActivityActiveTabChanged: function salesActivityActiveTabChanged(salesActivityActiveTab) {
    return function (dispatch) {
      var salesActivityActiveTabChangedAction = {
        type: exports.SalesActivityActionTypes.SALES_ACTIVITY_ACTIVE_TAB_CHANGED,
        salesActivityActiveTab: salesActivityActiveTab
      };
      return dispatch(salesActivityActiveTabChangedAction);
    };
  },
  getAllSalesActivity: function getAllSalesActivity(allSalesActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var allSalesActivityRequestedAction, allSalesActivityResponse, allSalesActivitySuccessfulAction, error_1, allSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              allSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.ALL_SALES_ACTIVITY_REQUESTED
              };
              dispatch(allSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getAllSalesActivity(allSalesActivityRequest)];
            case 2:
              allSalesActivityResponse = _a.sent();
              allSalesActivitySuccessfulAction = {
                type: exports.SalesActivityActionTypes.ALL_SALES_ACTIVITY_SUCCESSFUL,
                allSalesActivity: allSalesActivityResponse,
                paginate: allSalesActivityRequest.paginate
              };
              return [2 /*return*/, dispatch(allSalesActivitySuccessfulAction)];
            case 3:
              error_1 = _a.sent();
              allSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.ALL_SALES_ACTIVITY_FAILED
              };
              dispatch(allSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_1)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  getAllCompletedSalesActivity: function getAllCompletedSalesActivity(allCompletedSalesActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var allCompletedSalesActivityRequestedAction, allCompletedSalesActivityResponse, allCompletedSalesActivitySuccessfulAction, error_2, allCompletedSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              allCompletedSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.ALL_COMPLETED_SALES_ACTIVITY_REQUESTED
              };
              dispatch(allCompletedSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getAllCompletedSalesActivity(allCompletedSalesActivityRequest)];
            case 2:
              allCompletedSalesActivityResponse = _a.sent();
              allCompletedSalesActivitySuccessfulAction = {
                type: exports.SalesActivityActionTypes.ALL_COMPLETED_SALES_ACTIVITY_SUCCESSFUL,
                allSalesActivity: allCompletedSalesActivityResponse,
                paginate: allCompletedSalesActivityRequest.paginate
              };
              return [2 /*return*/, dispatch(allCompletedSalesActivitySuccessfulAction)];
            case 3:
              error_2 = _a.sent();
              allCompletedSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.ALL_COMPLETED_SALES_ACTIVITY_FAILED
              };
              dispatch(allCompletedSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_2)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  getAllOverdueSalesActivity: function getAllOverdueSalesActivity(allOverdueSalesActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var allOverdueSalesActivityRequestedAction, allOverdueSalesActivityResponse, allOverdueSalesActivitySuccessfulAction, error_3, allOverdueSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              allOverdueSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.ALL_OVERDUE_SALES_ACTIVITY_REQUESTED
              };
              dispatch(allOverdueSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getAllOverdueSalesActivity(allOverdueSalesActivityRequest)];
            case 2:
              allOverdueSalesActivityResponse = _a.sent();
              allOverdueSalesActivitySuccessfulAction = {
                type: exports.SalesActivityActionTypes.ALL_OVERDUE_SALES_ACTIVITY_SUCCESSFUL,
                allSalesActivity: allOverdueSalesActivityResponse,
                paginate: allOverdueSalesActivityRequest.paginate
              };
              return [2 /*return*/, dispatch(allOverdueSalesActivitySuccessfulAction)];
            case 3:
              error_3 = _a.sent();
              allOverdueSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.ALL_OVERDUE_SALES_ACTIVITY_FAILED
              };
              dispatch(allOverdueSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_3)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  getAllUpcomingSalesActivity: function getAllUpcomingSalesActivity(allUpcomingSalesActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var allUpcomingSalesActivityRequestedAction, allUpcomingSalesActivityResponse, allUpcomingSalesActivitySuccessfulAction, error_4, allUpcomingSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              allUpcomingSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.ALL_UPCOMING_SALES_ACTIVITY_REQUESTED
              };
              dispatch(allUpcomingSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getAllUpcomingSalesActivity(allUpcomingSalesActivityRequest)];
            case 2:
              allUpcomingSalesActivityResponse = _a.sent();
              allUpcomingSalesActivitySuccessfulAction = {
                type: exports.SalesActivityActionTypes.ALL_UPCOMING_SALES_ACTIVITY_SUCCESSFUL,
                allSalesActivity: allUpcomingSalesActivityResponse,
                paginate: allUpcomingSalesActivityRequest.paginate
              };
              return [2 /*return*/, dispatch(allUpcomingSalesActivitySuccessfulAction)];
            case 3:
              error_4 = _a.sent();
              allUpcomingSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.ALL_UPCOMING_SALES_ACTIVITY_FAILED
              };
              dispatch(allUpcomingSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_4)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  resetAllSalesActivity: function resetAllSalesActivity() {
    return function (dispatch) {
      var allSalesActivityResetAction = {
        type: exports.SalesActivityActionTypes.ALL_SALES_ACTIVITY_RESET
      };
      return dispatch(allSalesActivityResetAction);
    };
  },
  getNotesSalesActivity: function getNotesSalesActivity(notesSalesActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var notesSalesActivityRequestedAction, notesSalesActivityResponse, notesSalesActivitySuccessfulAction, error_5, notesSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              notesSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.NOTES_SALES_ACTIVITY_REQUESTED
              };
              dispatch(notesSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getNotesSalesActivity(notesSalesActivityRequest)];
            case 2:
              notesSalesActivityResponse = _a.sent();
              notesSalesActivitySuccessfulAction = {
                type: exports.SalesActivityActionTypes.NOTES_SALES_ACTIVITY_SUCCESSFUL,
                notesSalesActivity: notesSalesActivityResponse,
                paginate: notesSalesActivityRequest.paginate
              };
              return [2 /*return*/, dispatch(notesSalesActivitySuccessfulAction)];
            case 3:
              error_5 = _a.sent();
              notesSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.NOTES_SALES_ACTIVITY_FAILED
              };
              dispatch(notesSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_5)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  getNotesPinnedSalesActivity: function getNotesPinnedSalesActivity(notesPinnedSalesActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var notesPinnedSalesActivityRequestedAction, notesPinnedSalesActivityResponse, notesPinnedSalesActivitySuccessfulAction, error_6, notesPinnedSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              notesPinnedSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.NOTES_PINNED_SALES_ACTIVITY_REQUESTED
              };
              dispatch(notesPinnedSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getNotesPinnedSalesActivity(notesPinnedSalesActivityRequest)];
            case 2:
              notesPinnedSalesActivityResponse = _a.sent();
              notesPinnedSalesActivitySuccessfulAction = {
                type: exports.SalesActivityActionTypes.NOTES_PINNED_SALES_ACTIVITY_SUCCESSFUL,
                notesSalesActivity: notesPinnedSalesActivityResponse,
                paginate: notesPinnedSalesActivityRequest.paginate
              };
              return [2 /*return*/, dispatch(notesPinnedSalesActivitySuccessfulAction)];
            case 3:
              error_6 = _a.sent();
              notesPinnedSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.NOTES_PINNED_SALES_ACTIVITY_FAILED
              };
              dispatch(notesPinnedSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_6)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  getNotesCompletedSalesActivity: function getNotesCompletedSalesActivity(notesCompletedSalesActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var notesCompletedSalesActivityRequestedAction, notesCompletedSalesActivityResponse, notesCompletedSalesActivitySuccessfulAction, error_7, notesCompletedSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              notesCompletedSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.NOTES_COMPLETED_SALES_ACTIVITY_REQUESTED
              };
              dispatch(notesCompletedSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getNotesCompletedSalesActivity(notesCompletedSalesActivityRequest)];
            case 2:
              notesCompletedSalesActivityResponse = _a.sent();
              notesCompletedSalesActivitySuccessfulAction = {
                type: exports.SalesActivityActionTypes.NOTES_COMPLETED_SALES_ACTIVITY_SUCCESSFUL,
                notesSalesActivity: notesCompletedSalesActivityResponse,
                paginate: notesCompletedSalesActivityRequest.paginate
              };
              return [2 /*return*/, dispatch(notesCompletedSalesActivitySuccessfulAction)];
            case 3:
              error_7 = _a.sent();
              notesCompletedSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.NOTES_COMPLETED_SALES_ACTIVITY_FAILED
              };
              dispatch(notesCompletedSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_7)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  resetNotesSalesActivity: function resetNotesSalesActivity() {
    return function (dispatch) {
      var notesSalesActivityResetAction = {
        type: exports.SalesActivityActionTypes.NOTES_SALES_ACTIVITY_RESET
      };
      return dispatch(notesSalesActivityResetAction);
    };
  },
  getTasksSalesActivity: function getTasksSalesActivity(tasksSalesActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var tasksSalesActivityRequestedAction, tasksSalesActivityResponse, tasksSalesActivitySuccessfulAction, error_8, tasksSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              tasksSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.TASKS_SALES_ACTIVITY_REQUESTED
              };
              dispatch(tasksSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getTasksSalesActivity(tasksSalesActivityRequest)];
            case 2:
              tasksSalesActivityResponse = _a.sent();
              tasksSalesActivitySuccessfulAction = {
                type: exports.SalesActivityActionTypes.TASKS_SALES_ACTIVITY_SUCCESSFUL,
                tasksSalesActivity: tasksSalesActivityResponse,
                paginate: tasksSalesActivityRequest.paginate
              };
              return [2 /*return*/, dispatch(tasksSalesActivitySuccessfulAction)];
            case 3:
              error_8 = _a.sent();
              tasksSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.TASKS_SALES_ACTIVITY_FAILED
              };
              dispatch(tasksSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_8)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  getTasksCompletedSalesActivity: function getTasksCompletedSalesActivity(tasksCompletedSalesActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var tasksCompletedSalesActivityRequestedAction, tasksCompletedSalesActivityResponse, tasksCompletedSalesActivitySuccessfulAction, error_9, tasksCompletedSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              tasksCompletedSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.TASKS_COMPLETED_SALES_ACTIVITY_REQUESTED
              };
              dispatch(tasksCompletedSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getTasksCompletedSalesActivity(tasksCompletedSalesActivityRequest)];
            case 2:
              tasksCompletedSalesActivityResponse = _a.sent();
              tasksCompletedSalesActivitySuccessfulAction = {
                type: exports.SalesActivityActionTypes.TASKS_COMPLETED_SALES_ACTIVITY_SUCCESSFUL,
                tasksSalesActivity: tasksCompletedSalesActivityResponse,
                paginate: tasksCompletedSalesActivityRequest.paginate
              };
              return [2 /*return*/, dispatch(tasksCompletedSalesActivitySuccessfulAction)];
            case 3:
              error_9 = _a.sent();
              tasksCompletedSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.TASKS_COMPLETED_SALES_ACTIVITY_FAILED
              };
              dispatch(tasksCompletedSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_9)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  getTasksOverdueSalesActivity: function getTasksOverdueSalesActivity(tasksOverdueSalesActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var tasksOverdueSalesActivityRequestedAction, tasksOverdueSalesActivityResponse, tasksOverdueSalesActivitySuccessfulAction, error_10, tasksOverdueSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              tasksOverdueSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.TASKS_OVERDUE_SALES_ACTIVITY_REQUESTED
              };
              dispatch(tasksOverdueSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getTasksOverdueSalesActivity(tasksOverdueSalesActivityRequest)];
            case 2:
              tasksOverdueSalesActivityResponse = _a.sent();
              tasksOverdueSalesActivitySuccessfulAction = {
                type: exports.SalesActivityActionTypes.TASKS_OVERDUE_SALES_ACTIVITY_SUCCESSFUL,
                tasksSalesActivity: tasksOverdueSalesActivityResponse,
                paginate: tasksOverdueSalesActivityRequest.paginate
              };
              return [2 /*return*/, dispatch(tasksOverdueSalesActivitySuccessfulAction)];
            case 3:
              error_10 = _a.sent();
              tasksOverdueSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.TASKS_OVERDUE_SALES_ACTIVITY_FAILED
              };
              dispatch(tasksOverdueSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_10)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  getTasksUpcomingSalesActivity: function getTasksUpcomingSalesActivity(tasksUpcomingSalesActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var tasksUpcomingSalesActivityRequestedAction, tasksUpcomingSalesActivityResponse, tasksUpcomingSalesActivitySuccessfulAction, error_11, tasksUpcomingSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              tasksUpcomingSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.TASKS_UPCOMING_SALES_ACTIVITY_REQUESTED
              };
              dispatch(tasksUpcomingSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getTasksUpcomingSalesActivity(tasksUpcomingSalesActivityRequest)];
            case 2:
              tasksUpcomingSalesActivityResponse = _a.sent();
              tasksUpcomingSalesActivitySuccessfulAction = {
                type: exports.SalesActivityActionTypes.TASKS_UPCOMING_SALES_ACTIVITY_SUCCESSFUL,
                tasksSalesActivity: tasksUpcomingSalesActivityResponse,
                paginate: tasksUpcomingSalesActivityRequest.paginate
              };
              return [2 /*return*/, dispatch(tasksUpcomingSalesActivitySuccessfulAction)];
            case 3:
              error_11 = _a.sent();
              tasksUpcomingSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.TASKS_UPCOMING_SALES_ACTIVITY_FAILED
              };
              dispatch(tasksUpcomingSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_11)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  resetTasksSalesActivity: function resetTasksSalesActivity() {
    return function (dispatch) {
      var tasksSalesActivityResetAction = {
        type: exports.SalesActivityActionTypes.TASKS_SALES_ACTIVITY_RESET
      };
      return dispatch(tasksSalesActivityResetAction);
    };
  },
  getMeetingsSalesActivity: function getMeetingsSalesActivity(meetingsSalesActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var getMeetingsSalesActivityRequestedAction, getMeetingsSalesActivityResponse, getMeetingsSalesActivitySuccessfulAction, error_12, getMeetingsSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              getMeetingsSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.MEETINGS_SALES_ACTIVITY_REQUESTED
              };
              dispatch(getMeetingsSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getMeetingsSalesActivity(meetingsSalesActivityRequest)];
            case 2:
              getMeetingsSalesActivityResponse = _a.sent();
              getMeetingsSalesActivitySuccessfulAction = {
                type: exports.SalesActivityActionTypes.MEETINGS_SALES_ACTIVITY_SUCCESSFUL,
                meetingsSalesActivity: getMeetingsSalesActivityResponse,
                paginate: meetingsSalesActivityRequest.paginate
              };
              return [2 /*return*/, dispatch(getMeetingsSalesActivitySuccessfulAction)];
            case 3:
              error_12 = _a.sent();
              getMeetingsSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.MEETINGS_SALES_ACTIVITY_FAILED
              };
              dispatch(getMeetingsSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_12)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  getMeetingsCompletedSalesActivity: function getMeetingsCompletedSalesActivity(meetingsCompletedSalesActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var meetingsCompletedSalesActivityRequestedAction, meetingsCompletedSalesActivityResponse, meetingsCompletedSalesActivitySuccessfulAction, error_13, meetingsCompletedSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              meetingsCompletedSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.MEETINGS_COMPLETED_SALES_ACTIVITY_REQUESTED
              };
              dispatch(meetingsCompletedSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getMeetingsCompletedSalesActivity(meetingsCompletedSalesActivityRequest)];
            case 2:
              meetingsCompletedSalesActivityResponse = _a.sent();
              meetingsCompletedSalesActivitySuccessfulAction = {
                type: exports.SalesActivityActionTypes.MEETINGS_COMPLETED_SALES_ACTIVITY_SUCCESSFUL,
                meetingsSalesActivity: meetingsCompletedSalesActivityResponse,
                paginate: meetingsCompletedSalesActivityRequest.paginate
              };
              return [2 /*return*/, dispatch(meetingsCompletedSalesActivitySuccessfulAction)];
            case 3:
              error_13 = _a.sent();
              meetingsCompletedSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.MEETINGS_COMPLETED_SALES_ACTIVITY_FAILED
              };
              dispatch(meetingsCompletedSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_13)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  getMeetingsUpcomingSalesActivity: function getMeetingsUpcomingSalesActivity(meetingsUpcomingSalesActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var meetingsUpcomingSalesActivityRequestedAction, meetingsUpcomingSalesActivityResponse, meetingsUpcomingSalesActivitySuccessfulAction, error_14, meetingsUpcomingSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              meetingsUpcomingSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.MEETINGS_UPCOMING_SALES_ACTIVITY_REQUESTED
              };
              dispatch(meetingsUpcomingSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getMeetingsUpcomingSalesActivity(meetingsUpcomingSalesActivityRequest)];
            case 2:
              meetingsUpcomingSalesActivityResponse = _a.sent();
              meetingsUpcomingSalesActivitySuccessfulAction = {
                type: exports.SalesActivityActionTypes.MEETINGS_UPCOMING_SALES_ACTIVITY_SUCCESSFUL,
                meetingsSalesActivity: meetingsUpcomingSalesActivityResponse,
                paginate: meetingsUpcomingSalesActivityRequest.paginate
              };
              return [2 /*return*/, dispatch(meetingsUpcomingSalesActivitySuccessfulAction)];
            case 3:
              error_14 = _a.sent();
              meetingsUpcomingSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.MEETINGS_UPCOMING_SALES_ACTIVITY_FAILED
              };
              dispatch(meetingsUpcomingSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_14)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  resetMeetingsSalesActivity: function resetMeetingsSalesActivity() {
    return function (dispatch) {
      var meetingsSalesActivityResetAction = {
        type: exports.SalesActivityActionTypes.MEETINGS_SALES_ACTIVITY_RESET
      };
      return dispatch(meetingsSalesActivityResetAction);
    };
  },
  getCallsSalesActivity: function getCallsSalesActivity(callsSalesActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var callsSalesActivityRequestedAction, callsSalesActivityResponse, callsSalesActivitySuccessfulAction, error_15, callsSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              callsSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.CALLS_SALES_ACTIVITY_REQUESTED
              };
              dispatch(callsSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getCallsSalesActivity(callsSalesActivityRequest)];
            case 2:
              callsSalesActivityResponse = _a.sent();
              callsSalesActivitySuccessfulAction = {
                type: exports.SalesActivityActionTypes.CALLS_SALES_ACTIVITY_SUCCESSFUL,
                callsSalesActivity: callsSalesActivityResponse,
                paginate: callsSalesActivityRequest.paginate
              };
              return [2 /*return*/, dispatch(callsSalesActivitySuccessfulAction)];
            case 3:
              error_15 = _a.sent();
              callsSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.CALLS_SALES_ACTIVITY_FAILED
              };
              dispatch(callsSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_15)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  resetCallsSalesActivity: function resetCallsSalesActivity() {
    return function (dispatch) {
      var callsSalesActivityResetAction = {
        type: exports.SalesActivityActionTypes.CALLS_SALES_ACTIVITY_RESET
      };
      return dispatch(callsSalesActivityResetAction);
    };
  },
  getEmailsSalesActivity: function getEmailsSalesActivity(emailsSalesActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var emailsSalesActivityRequestedAction, emailsSalesActivityResponse, emailsSalesActivitySuccessfulAction, error_16, emailsSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              emailsSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.EMAILS_SALES_ACTIVITY_REQUESTED
              };
              dispatch(emailsSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getEmailsSalesActivity(emailsSalesActivityRequest)];
            case 2:
              emailsSalesActivityResponse = _a.sent();
              emailsSalesActivitySuccessfulAction = {
                type: exports.SalesActivityActionTypes.EMAILS_SALES_ACTIVITY_SUCCESSFUL,
                emailsSalesActivity: emailsSalesActivityResponse,
                paginate: emailsSalesActivityRequest.paginate
              };
              return [2 /*return*/, dispatch(emailsSalesActivitySuccessfulAction)];
            case 3:
              error_16 = _a.sent();
              emailsSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.EMAILS_SALES_ACTIVITY_FAILED
              };
              dispatch(emailsSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_16)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  resetEmailsSalesActivity: function resetEmailsSalesActivity() {
    return function (dispatch) {
      var emailsSalesActivityResetAction = {
        type: exports.SalesActivityActionTypes.EMAILS_SALES_ACTIVITY_RESET
      };
      return dispatch(emailsSalesActivityResetAction);
    };
  },
  updateCurrentActiveSalesActivity: function updateCurrentActiveSalesActivity(currentActiveSalesActivity) {
    return function (dispatch) {
      var updateCurrentActiveSalesActivityAction = {
        type: exports.SalesActivityActionTypes.UPDATE_CURRENT_ACTIVE_SALES_ACTIVITY,
        currentActiveSalesActivity: currentActiveSalesActivity
      };
      return dispatch(updateCurrentActiveSalesActivityAction);
    };
  },
  // Sales Activity Delete / Add / Edit Actions
  deleteComment: function deleteComment(deleteCommentRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var deleteCommentRequestedAction, deleteCommentResponse, deleteCommentSuccessfulAction, deleteCommentFailedAction, error_17, deleteCommentFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              deleteCommentRequestedAction = {
                type: exports.SalesActivityActionTypes.DELETE_COMMENT_REQUESTED
              };
              dispatch(deleteCommentRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.deleteComment(deleteCommentRequest)];
            case 2:
              deleteCommentResponse = _a.sent();
              if (deleteCommentResponse.deleted) {
                deleteCommentSuccessfulAction = {
                  type: exports.SalesActivityActionTypes.DELETE_COMMENT_SUCCESSFUL,
                  deleteComment: deleteCommentRequest
                };
                return [2 /*return*/, dispatch(deleteCommentSuccessfulAction)];
              } else {
                deleteCommentFailedAction = {
                  type: exports.SalesActivityActionTypes.DELETE_COMMENT_FAILED
                };
                dispatch(deleteCommentFailedAction);
                return [2 /*return*/, Promise.reject(contracts.DefaultErrorMessage)];
              }
            case 3:
              error_17 = _a.sent();
              deleteCommentFailedAction = {
                type: exports.SalesActivityActionTypes.DELETE_COMMENT_FAILED
              };
              dispatch(deleteCommentFailedAction);
              return [2 /*return*/, Promise.reject(error_17)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  addComment: function addComment(addCommentRequest, isCrossAssign) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var addCommentRequestedAction, addCommentResponse, addCommentSuccessfulAction, error_18, addCommentFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              addCommentRequestedAction = {
                type: exports.SalesActivityActionTypes.ADD_COMMENT_REQUESTED
              };
              dispatch(addCommentRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.addComment(addCommentRequest, isCrossAssign)];
            case 2:
              addCommentResponse = _a.sent();
              addCommentSuccessfulAction = {
                type: exports.SalesActivityActionTypes.ADD_COMMENT_SUCCESSFUL,
                addComment: addCommentResponse
              };
              return [2 /*return*/, dispatch(addCommentSuccessfulAction)];
            case 3:
              error_18 = _a.sent();
              addCommentFailedAction = {
                type: exports.SalesActivityActionTypes.ADD_COMMENT_FAILED
              };
              dispatch(addCommentFailedAction);
              return [2 /*return*/, Promise.reject(error_18)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  editComment: function editComment(editCommentRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var editCommentRequestedAction, editCommentResponse, editCommentSuccessfulAction, error_19, editCommentFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              editCommentRequestedAction = {
                type: exports.SalesActivityActionTypes.EDIT_COMMENT_REQUESTED
              };
              dispatch(editCommentRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.editComment(editCommentRequest)];
            case 2:
              editCommentResponse = _a.sent();
              editCommentSuccessfulAction = {
                type: exports.SalesActivityActionTypes.EDIT_COMMENT_SUCCESSFUL,
                editComment: editCommentResponse
              };
              return [2 /*return*/, dispatch(editCommentSuccessfulAction)];
            case 3:
              error_19 = _a.sent();
              editCommentFailedAction = {
                type: exports.SalesActivityActionTypes.EDIT_COMMENT_FAILED
              };
              dispatch(editCommentFailedAction);
              return [2 /*return*/, Promise.reject(error_19)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  deleteLogCall: function deleteLogCall(deleteLogCallRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var deleteLogCallRequestedAction, deleteLogCallResponse, deleteLogCallSuccessfulAction, deleteLogCallFailedAction, error_20, deleteLogCallFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              deleteLogCallRequestedAction = {
                type: exports.SalesActivityActionTypes.DELETE_LOG_CALL_REQUESTED
              };
              dispatch(deleteLogCallRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.deleteLogCall(deleteLogCallRequest)];
            case 2:
              deleteLogCallResponse = _a.sent();
              if (deleteLogCallResponse.deleted) {
                deleteLogCallSuccessfulAction = {
                  type: exports.SalesActivityActionTypes.DELETE_LOG_CALL_SUCCESSFUL,
                  deleteLogCall: deleteLogCallRequest
                };
                return [2 /*return*/, dispatch(deleteLogCallSuccessfulAction)];
              } else {
                deleteLogCallFailedAction = {
                  type: exports.SalesActivityActionTypes.DELETE_LOG_CALL_FAILED
                };
                dispatch(deleteLogCallFailedAction);
                return [2 /*return*/, Promise.reject(contracts.DefaultErrorMessage)];
              }
            case 3:
              error_20 = _a.sent();
              deleteLogCallFailedAction = {
                type: exports.SalesActivityActionTypes.DELETE_LOG_CALL_FAILED
              };
              dispatch(deleteLogCallFailedAction);
              return [2 /*return*/, Promise.reject(error_20)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  addLogCall: function addLogCall(addLogCallRequest, isCrossAssign) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var addLogCallRequestedAction, addLogCallResponse, addLogCallSuccessfulAction, error_21, addLogCallFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              addLogCallRequestedAction = {
                type: exports.SalesActivityActionTypes.ADD_LOG_CALL_REQUESTED
              };
              dispatch(addLogCallRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.addLogCall(addLogCallRequest, isCrossAssign)];
            case 2:
              addLogCallResponse = _a.sent();
              addLogCallSuccessfulAction = {
                type: exports.SalesActivityActionTypes.ADD_LOG_CALL_SUCCESSFUL,
                addLogCall: addLogCallResponse
              };
              return [2 /*return*/, dispatch(addLogCallSuccessfulAction)];
            case 3:
              error_21 = _a.sent();
              addLogCallFailedAction = {
                type: exports.SalesActivityActionTypes.ADD_LOG_CALL_FAILED
              };
              dispatch(addLogCallFailedAction);
              return [2 /*return*/, Promise.reject(error_21)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  editLogCall: function editLogCall(editLogCallRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var editLogCallRequestedAction, editLogCallResponse, editLogCallSuccessfulAction, error_22, editLogCallFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              editLogCallRequestedAction = {
                type: exports.SalesActivityActionTypes.EDIT_LOG_CALL_REQUESTED
              };
              dispatch(editLogCallRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.editLogCall(editLogCallRequest)];
            case 2:
              editLogCallResponse = _a.sent();
              editLogCallSuccessfulAction = {
                type: exports.SalesActivityActionTypes.EDIT_LOG_CALL_SUCCESSFUL,
                editLogCall: editLogCallResponse
              };
              return [2 /*return*/, dispatch(editLogCallSuccessfulAction)];
            case 3:
              error_22 = _a.sent();
              editLogCallFailedAction = {
                type: exports.SalesActivityActionTypes.EDIT_LOG_CALL_FAILED
              };
              dispatch(editLogCallFailedAction);
              return [2 /*return*/, Promise.reject(error_22)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  deleteLogEmail: function deleteLogEmail(deleteLogEmailRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var deleteLogEmailRequestedAction, deleteLogEmailResponse, deleteLogEmailSuccessfulAction, deleteLogEmailFailedAction, error_23, deleteLogEmailFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              deleteLogEmailRequestedAction = {
                type: exports.SalesActivityActionTypes.DELETE_LOG_EMAIL_REQUESTED
              };
              dispatch(deleteLogEmailRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.deleteLogEmail(deleteLogEmailRequest)];
            case 2:
              deleteLogEmailResponse = _a.sent();
              if (deleteLogEmailResponse.deleted) {
                deleteLogEmailSuccessfulAction = {
                  type: exports.SalesActivityActionTypes.DELETE_LOG_EMAIL_SUCCESSFUL,
                  deleteLogEmail: deleteLogEmailRequest
                };
                return [2 /*return*/, dispatch(deleteLogEmailSuccessfulAction)];
              } else {
                deleteLogEmailFailedAction = {
                  type: exports.SalesActivityActionTypes.DELETE_LOG_EMAIL_FAILED
                };
                dispatch(deleteLogEmailFailedAction);
                return [2 /*return*/, Promise.reject(contracts.DefaultErrorMessage)];
              }
            case 3:
              error_23 = _a.sent();
              deleteLogEmailFailedAction = {
                type: exports.SalesActivityActionTypes.DELETE_LOG_EMAIL_FAILED
              };
              dispatch(deleteLogEmailFailedAction);
              return [2 /*return*/, Promise.reject(error_23)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  addLogEmail: function addLogEmail(addLogEmailRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var addLogEmailRequestedAction, addLogEmailResponse, addLogEmailSuccessfulAction, error_24, addLogEmailFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              addLogEmailRequestedAction = {
                type: exports.SalesActivityActionTypes.ADD_LOG_EMAIL_REQUESTED
              };
              dispatch(addLogEmailRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.addLogEmail(addLogEmailRequest)];
            case 2:
              addLogEmailResponse = _a.sent();
              addLogEmailSuccessfulAction = {
                type: exports.SalesActivityActionTypes.ADD_LOG_EMAIL_SUCCESSFUL,
                addLogEmail: addLogEmailResponse
              };
              return [2 /*return*/, dispatch(addLogEmailSuccessfulAction)];
            case 3:
              error_24 = _a.sent();
              addLogEmailFailedAction = {
                type: exports.SalesActivityActionTypes.ADD_LOG_EMAIL_FAILED
              };
              dispatch(addLogEmailFailedAction);
              return [2 /*return*/, Promise.reject(error_24)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  editLogEmail: function editLogEmail(editLogEmailRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var editLogEmailRequestedAction, editLogEmailResponse, editLogEmailSuccessfulAction, error_25, editLogEmailFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              editLogEmailRequestedAction = {
                type: exports.SalesActivityActionTypes.EDIT_LOG_EMAIL_REQUESTED
              };
              dispatch(editLogEmailRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.editLogEmail(editLogEmailRequest)];
            case 2:
              editLogEmailResponse = _a.sent();
              editLogEmailSuccessfulAction = {
                type: exports.SalesActivityActionTypes.EDIT_LOG_EMAIL_SUCCESSFUL,
                editLogEmail: editLogEmailResponse
              };
              return [2 /*return*/, dispatch(editLogEmailSuccessfulAction)];
            case 3:
              error_25 = _a.sent();
              editLogEmailFailedAction = {
                type: exports.SalesActivityActionTypes.EDIT_LOG_EMAIL_FAILED
              };
              dispatch(editLogEmailFailedAction);
              return [2 /*return*/, Promise.reject(error_25)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  deleteTask: function deleteTask(deleteTaskRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var deleteTaskRequestedAction, deleteTaskResponse, deleteTaskSuccessfulAction, deleteTaskFailedAction, error_26, deleteTaskFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              deleteTaskRequestedAction = {
                type: exports.SalesActivityActionTypes.DELETE_TASK_REQUESTED
              };
              dispatch(deleteTaskRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.deleteTask(deleteTaskRequest)];
            case 2:
              deleteTaskResponse = _a.sent();
              if (deleteTaskResponse.deleted) {
                deleteTaskSuccessfulAction = {
                  type: exports.SalesActivityActionTypes.DELETE_TASK_SUCCESSFUL,
                  deleteTask: deleteTaskRequest
                };
                return [2 /*return*/, dispatch(deleteTaskSuccessfulAction)];
              } else {
                deleteTaskFailedAction = {
                  type: exports.SalesActivityActionTypes.DELETE_TASK_FAILED
                };
                dispatch(deleteTaskFailedAction);
                return [2 /*return*/, Promise.reject(contracts.DefaultErrorMessage)];
              }
            case 3:
              error_26 = _a.sent();
              deleteTaskFailedAction = {
                type: exports.SalesActivityActionTypes.DELETE_TASK_FAILED
              };
              dispatch(deleteTaskFailedAction);
              return [2 /*return*/, Promise.reject(error_26)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  addTask: function addTask(addTaskRequest, isCrossAssign) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var addTaskRequestedAction, addTaskResponse, addTaskSuccessfulAction, error_27, addTaskFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              addTaskRequestedAction = {
                type: exports.SalesActivityActionTypes.ADD_TASK_REQUESTED
              };
              dispatch(addTaskRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.addTask(addTaskRequest, isCrossAssign)];
            case 2:
              addTaskResponse = _a.sent();
              addTaskSuccessfulAction = {
                type: exports.SalesActivityActionTypes.ADD_TASK_SUCCESSFUL,
                addTask: addTaskResponse
              };
              return [2 /*return*/, dispatch(addTaskSuccessfulAction)];
            case 3:
              error_27 = _a.sent();
              addTaskFailedAction = {
                type: exports.SalesActivityActionTypes.ADD_TASK_FAILED
              };
              dispatch(addTaskFailedAction);
              return [2 /*return*/, Promise.reject(error_27)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  editTask: function editTask(editTaskRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var editTaskRequestedAction, editTaskResponse, editTaskSuccessfulAction, error_28, editTaskFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              editTaskRequestedAction = {
                type: exports.SalesActivityActionTypes.EDIT_TASK_REQUESTED
              };
              dispatch(editTaskRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.editTask(editTaskRequest)];
            case 2:
              editTaskResponse = _a.sent();
              editTaskSuccessfulAction = {
                type: exports.SalesActivityActionTypes.EDIT_TASK_SUCCESSFUL,
                editTask: editTaskResponse
              };
              return [2 /*return*/, dispatch(editTaskSuccessfulAction)];
            case 3:
              error_28 = _a.sent();
              editTaskFailedAction = {
                type: exports.SalesActivityActionTypes.EDIT_TASK_FAILED
              };
              dispatch(editTaskFailedAction);
              return [2 /*return*/, Promise.reject(error_28)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  deleteMeeting: function deleteMeeting(deleteMeetingRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var deleteMeetingRequestedAction, deleteMeetingResponse, deleteMeetingSuccessfulAction, deleteMeetingFailedAction, error_29, deleteMeetingFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              deleteMeetingRequestedAction = {
                type: exports.SalesActivityActionTypes.DELETE_MEETING_REQUESTED
              };
              dispatch(deleteMeetingRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.deleteMeeting(deleteMeetingRequest)];
            case 2:
              deleteMeetingResponse = _a.sent();
              if (deleteMeetingResponse.deleted) {
                deleteMeetingSuccessfulAction = {
                  type: exports.SalesActivityActionTypes.DELETE_MEETING_SUCCESSFUL,
                  deleteMeeting: deleteMeetingRequest
                };
                return [2 /*return*/, dispatch(deleteMeetingSuccessfulAction)];
              } else {
                deleteMeetingFailedAction = {
                  type: exports.SalesActivityActionTypes.DELETE_MEETING_FAILED
                };
                dispatch(deleteMeetingFailedAction);
                return [2 /*return*/, Promise.reject(contracts.DefaultErrorMessage)];
              }
            case 3:
              error_29 = _a.sent();
              deleteMeetingFailedAction = {
                type: exports.SalesActivityActionTypes.DELETE_MEETING_FAILED
              };
              dispatch(deleteMeetingFailedAction);
              return [2 /*return*/, Promise.reject(error_29)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  addMeeting: function addMeeting(addMeetingRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var addMeetingRequestedAction, addMeetingResponse, addMeetingSuccessfulAction, error_30, addMeetingFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              addMeetingRequestedAction = {
                type: exports.SalesActivityActionTypes.ADD_MEETING_REQUESTED
              };
              dispatch(addMeetingRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.addMeeting(addMeetingRequest)];
            case 2:
              addMeetingResponse = _a.sent();
              addMeetingSuccessfulAction = {
                type: exports.SalesActivityActionTypes.ADD_MEETING_SUCCESSFUL,
                addMeeting: addMeetingResponse
              };
              return [2 /*return*/, dispatch(addMeetingSuccessfulAction)];
            case 3:
              error_30 = _a.sent();
              addMeetingFailedAction = {
                type: exports.SalesActivityActionTypes.ADD_MEETING_FAILED
              };
              dispatch(addMeetingFailedAction);
              return [2 /*return*/, Promise.reject(error_30)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  editMeeting: function editMeeting(editMeetingRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var editMeetingRequestedAction, editMeetingResponse, editMeetingSuccessfulAction, error_31, editMeetingFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              editMeetingRequestedAction = {
                type: exports.SalesActivityActionTypes.EDIT_MEETING_REQUESTED
              };
              dispatch(editMeetingRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.editMeeting(editMeetingRequest)];
            case 2:
              editMeetingResponse = _a.sent();
              editMeetingSuccessfulAction = {
                type: exports.SalesActivityActionTypes.EDIT_MEETING_SUCCESSFUL,
                editMeeting: editMeetingResponse
              };
              return [2 /*return*/, dispatch(editMeetingSuccessfulAction)];
            case 3:
              error_31 = _a.sent();
              editMeetingFailedAction = {
                type: exports.SalesActivityActionTypes.EDIT_MEETING_FAILED
              };
              dispatch(editMeetingFailedAction);
              return [2 /*return*/, Promise.reject(error_31)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  // Sales Activity Automation Actions
  getSalesActivityAutomations: function getSalesActivityAutomations() {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var salesActivityAutomationsRequestedAction, salesActivityAutomationsResponse, salesActivityAutomationsSuccessfulAction, error_32, salesActivityAutomationsFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              salesActivityAutomationsRequestedAction = {
                type: exports.SalesActivityActionTypes.SALES_ACTIVITY_AUTOMATIONS_REQUESTED
              };
              dispatch(salesActivityAutomationsRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getSalesActivityAutomations()];
            case 2:
              salesActivityAutomationsResponse = _a.sent();
              salesActivityAutomationsSuccessfulAction = {
                type: exports.SalesActivityActionTypes.SALES_ACTIVITY_AUTOMATIONS_SUCCESSFUL,
                salesActivityAutomationList: salesActivityAutomationsResponse.data
              };
              return [2 /*return*/, dispatch(salesActivityAutomationsSuccessfulAction)];
            case 3:
              error_32 = _a.sent();
              salesActivityAutomationsFailedAction = {
                type: exports.SalesActivityActionTypes.SALES_ACTIVITY_AUTOMATIONS_FAILED
              };
              dispatch(salesActivityAutomationsFailedAction);
              return [2 /*return*/, Promise.reject(error_32)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  getSalesActivityAutomationMetadata: function getSalesActivityAutomationMetadata(salesActivityAutomationMetadataRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var salesActivityAutomationMetadataRequestedAction, salesActivityAutomationMetadataResponse, salesActivityAutomationMetadataSuccessfulAction, error_33, salesActivityAutomationMetadataFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              salesActivityAutomationMetadataRequestedAction = {
                type: exports.SalesActivityActionTypes.SALES_ACTIVITY_AUTOMATION_METADATA_REQUESTED
              };
              dispatch(salesActivityAutomationMetadataRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getSalesActivityAutomationMetadata(salesActivityAutomationMetadataRequest)];
            case 2:
              salesActivityAutomationMetadataResponse = _a.sent();
              salesActivityAutomationMetadataSuccessfulAction = {
                type: exports.SalesActivityActionTypes.SALES_ACTIVITY_AUTOMATION_METADATA_SUCCESSFUL,
                salesActivityAutomationFiltersList: salesActivityAutomationMetadataResponse.filters,
                opportunity_stages: salesActivityAutomationMetadataResponse.opportunity_stages,
                salesActivityAutomationMergeTags: salesActivityAutomationMetadataResponse === null || salesActivityAutomationMetadataResponse === void 0 ? void 0 : salesActivityAutomationMetadataResponse.merge_tags
              };
              return [2 /*return*/, dispatch(salesActivityAutomationMetadataSuccessfulAction)];
            case 3:
              error_33 = _a.sent();
              salesActivityAutomationMetadataFailedAction = {
                type: exports.SalesActivityActionTypes.SALES_ACTIVITY_AUTOMATION_METADATA_FAILED
              };
              dispatch(salesActivityAutomationMetadataFailedAction);
              return [2 /*return*/, Promise.reject(error_33)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  getSalesActivityAutomationDetails: function getSalesActivityAutomationDetails(salesActivityAutomationId) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var salesActivityAutomationDetailsRequestedAction, salesActivityAutomationDetailsResponse, salesActivityAutomationDetailsSuccessfulAction, error_34, salesActivityAutomationDetailsFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              salesActivityAutomationDetailsRequestedAction = {
                type: exports.SalesActivityActionTypes.SALES_ACTIVITY_AUTOMATION_DETAILS_REQUESTED
              };
              dispatch(salesActivityAutomationDetailsRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getSalesActivityAutomationDetails(salesActivityAutomationId)];
            case 2:
              salesActivityAutomationDetailsResponse = _a.sent();
              salesActivityAutomationDetailsSuccessfulAction = {
                type: exports.SalesActivityActionTypes.SALES_ACTIVITY_AUTOMATION_DETAILS_SUCCESSFUL,
                currentActiveSalesActivityAutomation: salesActivityAutomationDetailsResponse
              };
              return [2 /*return*/, dispatch(salesActivityAutomationDetailsSuccessfulAction)];
            case 3:
              error_34 = _a.sent();
              salesActivityAutomationDetailsFailedAction = {
                type: exports.SalesActivityActionTypes.SALES_ACTIVITY_AUTOMATION_DETAILS_FAILED
              };
              dispatch(salesActivityAutomationDetailsFailedAction);
              return [2 /*return*/, Promise.reject(error_34)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  resetSalesActivityAutomationDetails: function resetSalesActivityAutomationDetails() {
    return function (dispatch) {
      var salesActivityAutomationDetailsResetAction = {
        type: exports.SalesActivityActionTypes.SALES_ACTIVITY_AUTOMATION_DETAILS_RESET
      };
      return dispatch(salesActivityAutomationDetailsResetAction);
    };
  },
  reorderSalesActivityAutomation: function reorderSalesActivityAutomation(reorderedSalesActivityAutomationList) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var reorderSalesActivityAutomationRequestedAction, reorderSalesActivityAutomationResponse, reorderSalesActivityAutomationSuccessfulAction, error_35, reorderSalesActivityAutomationFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              reorderSalesActivityAutomationRequestedAction = {
                type: exports.SalesActivityActionTypes.REORDER_SALES_ACTIVITY_AUTOMATION_REQUESTED
              };
              dispatch(reorderSalesActivityAutomationRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.reorderSalesActivityAutomation(reorderedSalesActivityAutomationList)];
            case 2:
              reorderSalesActivityAutomationResponse = _a.sent();
              reorderSalesActivityAutomationSuccessfulAction = {
                type: exports.SalesActivityActionTypes.REORDER_SALES_ACTIVITY_AUTOMATION_SUCCESSFUL,
                salesActivityAutomationList: reorderSalesActivityAutomationResponse.data
              };
              return [2 /*return*/, dispatch(reorderSalesActivityAutomationSuccessfulAction)];
            case 3:
              error_35 = _a.sent();
              reorderSalesActivityAutomationFailedAction = {
                type: exports.SalesActivityActionTypes.REORDER_SALES_ACTIVITY_AUTOMATION_FAILED
              };
              dispatch(reorderSalesActivityAutomationFailedAction);
              return [2 /*return*/, Promise.reject(error_35)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  resetSalesActivityAutomations: function resetSalesActivityAutomations() {
    return function (dispatch) {
      var resetSalesActivityAutomationsAction = {
        type: exports.SalesActivityActionTypes.RESET_SALES_ACTIVITY_AUTOMATIONS
      };
      return dispatch(resetSalesActivityAutomationsAction);
    };
  },
  deleteSalesActivityAutomation: function deleteSalesActivityAutomation(deleteSalesActivityAutomationRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var deleteSalesActivityAutomationRequestedAction, deleteSalesActivityAutomationResponse, deleteSalesActivityAutomationSuccessfulAction, deleteSalesActivityAutomationFailedAction, error_36, deleteSalesActivityAutomationFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              deleteSalesActivityAutomationRequestedAction = {
                type: exports.SalesActivityActionTypes.DELETE_SALES_ACTIVITY_AUTOMATION_REQUESTED
              };
              dispatch(deleteSalesActivityAutomationRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.deleteSalesActivityAutomation(deleteSalesActivityAutomationRequest)];
            case 2:
              deleteSalesActivityAutomationResponse = _a.sent();
              if (deleteSalesActivityAutomationResponse.deleted) {
                deleteSalesActivityAutomationSuccessfulAction = {
                  type: exports.SalesActivityActionTypes.DELETE_SALES_ACTIVITY_AUTOMATION_SUCCESSFUL,
                  deleteSalesActivityAutomation: deleteSalesActivityAutomationRequest
                };
                return [2 /*return*/, dispatch(deleteSalesActivityAutomationSuccessfulAction)];
              } else {
                deleteSalesActivityAutomationFailedAction = {
                  type: exports.SalesActivityActionTypes.DELETE_SALES_ACTIVITY_AUTOMATION_FAILED
                };
                dispatch(deleteSalesActivityAutomationFailedAction);
                return [2 /*return*/, Promise.reject(contracts.DefaultErrorMessage)];
              }
            case 3:
              error_36 = _a.sent();
              deleteSalesActivityAutomationFailedAction = {
                type: exports.SalesActivityActionTypes.DELETE_SALES_ACTIVITY_AUTOMATION_FAILED
              };
              dispatch(deleteSalesActivityAutomationFailedAction);
              return [2 /*return*/, Promise.reject(error_36)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  addSalesActivityAutomation: function addSalesActivityAutomation(addSalesActivityAutomationRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var addSalesActivityAutomationRequestedAction, addSalesActivityAutomationResponse, addSalesActivityAutomationSuccessfulAction, error_37, addSalesActivityAutomationFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              addSalesActivityAutomationRequestedAction = {
                type: exports.SalesActivityActionTypes.ADD_SALES_ACTIVITY_AUTOMATION_REQUESTED
              };
              dispatch(addSalesActivityAutomationRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.addSalesActivityAutomation(addSalesActivityAutomationRequest)];
            case 2:
              addSalesActivityAutomationResponse = _a.sent();
              addSalesActivityAutomationSuccessfulAction = {
                type: exports.SalesActivityActionTypes.ADD_SALES_ACTIVITY_AUTOMATION_SUCCESSFUL,
                addSalesActivityAutomation: addSalesActivityAutomationResponse
              };
              return [2 /*return*/, dispatch(addSalesActivityAutomationSuccessfulAction)];
            case 3:
              error_37 = _a.sent();
              addSalesActivityAutomationFailedAction = {
                type: exports.SalesActivityActionTypes.ADD_SALES_ACTIVITY_AUTOMATION_FAILED
              };
              dispatch(addSalesActivityAutomationFailedAction);
              return [2 /*return*/, Promise.reject(error_37)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  editSalesActivityAutomation: function editSalesActivityAutomation(editSalesActivityAutomationRequest, action) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var editSalesActivityAutomationRequestedAction, editSalesActivityAutomationResponse, editSalesActivityAutomationSuccessfulAction, error_38, editSalesActivityAutomationFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              editSalesActivityAutomationRequestedAction = {
                type: exports.SalesActivityActionTypes.EDIT_SALES_ACTIVITY_AUTOMATION_REQUESTED
              };
              dispatch(editSalesActivityAutomationRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.editSalesActivityAutomation(editSalesActivityAutomationRequest)];
            case 2:
              editSalesActivityAutomationResponse = _a.sent();
              editSalesActivityAutomationSuccessfulAction = {
                type: exports.SalesActivityActionTypes.EDIT_SALES_ACTIVITY_AUTOMATION_SUCCESSFUL,
                editSalesActivityAutomation: editSalesActivityAutomationResponse
              };
              if (action) {
                molecules.ZMessage.success("Sales automation workflow is \n\t\t\t\t\t\t".concat(action === "enable_automation" ? "enabled" : "disabled"));
              }
              return [2 /*return*/, dispatch(editSalesActivityAutomationSuccessfulAction)];
            case 3:
              error_38 = _a.sent();
              editSalesActivityAutomationFailedAction = {
                type: exports.SalesActivityActionTypes.EDIT_SALES_ACTIVITY_AUTOMATION_FAILED
              };
              dispatch(editSalesActivityAutomationFailedAction);
              return [2 /*return*/, Promise.reject(error_38)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  updateCurrentActiveSalesActivityAutomation: function updateCurrentActiveSalesActivityAutomation(currentActiveSalesActivityAutomation) {
    return function (dispatch) {
      var updateCurrentActiveSalesActivityAutomationAction = {
        type: exports.SalesActivityActionTypes.UPDATE_CURRENT_ACTIVE_SALES_ACTIVITY_AUTOMATION,
        currentActiveSalesActivityAutomation: currentActiveSalesActivityAutomation
      };
      return dispatch(updateCurrentActiveSalesActivityAutomationAction);
    };
  },
  // Sales Activity Drawer Actions
  addCommentDrawerOpened: function addCommentDrawerOpened() {
    return function (dispatch) {
      var addCommentDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.ADD_COMMENT_DRAWER_OPENED
      };
      return dispatch(addCommentDrawerOpenedAction);
    };
  },
  addCommentDrawerClosed: function addCommentDrawerClosed() {
    return function (dispatch) {
      var addCommentDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.ADD_COMMENT_DRAWER_CLOSED
      };
      return dispatch(addCommentDrawerClosedAction);
    };
  },
  editCommentDrawerOpened: function editCommentDrawerOpened() {
    return function (dispatch) {
      var editCommentDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.EDIT_COMMENT_DRAWER_OPENED
      };
      return dispatch(editCommentDrawerOpenedAction);
    };
  },
  editCommentDrawerClosed: function editCommentDrawerClosed() {
    return function (dispatch) {
      var editCommentDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.EDIT_COMMENT_DRAWER_CLOSED
      };
      return dispatch(editCommentDrawerClosedAction);
    };
  },
  addTaskDrawerOpened: function addTaskDrawerOpened() {
    return function (dispatch) {
      var addTaskDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.ADD_TASK_DRAWER_OPENED
      };
      return dispatch(addTaskDrawerOpenedAction);
    };
  },
  addTaskDrawerClosed: function addTaskDrawerClosed() {
    return function (dispatch) {
      var addTaskDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.ADD_TASK_DRAWER_CLOSED
      };
      return dispatch(addTaskDrawerClosedAction);
    };
  },
  editTaskDrawerOpened: function editTaskDrawerOpened() {
    return function (dispatch) {
      var editTaskDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.EDIT_TASK_DRAWER_OPENED
      };
      return dispatch(editTaskDrawerOpenedAction);
    };
  },
  editTaskDrawerClosed: function editTaskDrawerClosed() {
    return function (dispatch) {
      var editTaskDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.EDIT_TASK_DRAWER_CLOSED
      };
      return dispatch(editTaskDrawerClosedAction);
    };
  },
  addMeetingDrawerOpened: function addMeetingDrawerOpened() {
    return function (dispatch) {
      var addMeetingDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.ADD_MEETING_DRAWER_OPENED
      };
      return dispatch(addMeetingDrawerOpenedAction);
    };
  },
  addMeetingDrawerClosed: function addMeetingDrawerClosed() {
    return function (dispatch) {
      var addMeetingDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.ADD_MEETING_DRAWER_CLOSED
      };
      return dispatch(addMeetingDrawerClosedAction);
    };
  },
  editMeetingDrawerOpened: function editMeetingDrawerOpened() {
    return function (dispatch) {
      var editMeetingDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.EDIT_MEETING_DRAWER_OPENED
      };
      return dispatch(editMeetingDrawerOpenedAction);
    };
  },
  editMeetingDrawerClosed: function editMeetingDrawerClosed() {
    return function (dispatch) {
      var editMeetingDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.EDIT_MEETING_DRAWER_CLOSED
      };
      return dispatch(editMeetingDrawerClosedAction);
    };
  },
  addLogCallDrawerOpened: function addLogCallDrawerOpened() {
    return function (dispatch) {
      var addLogCallDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.ADD_LOG_CALL_DRAWER_OPENED
      };
      return dispatch(addLogCallDrawerOpenedAction);
    };
  },
  addLogCallDrawerClosed: function addLogCallDrawerClosed() {
    return function (dispatch) {
      var addLogCallDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.ADD_LOG_CALL_DRAWER_CLOSED
      };
      return dispatch(addLogCallDrawerClosedAction);
    };
  },
  editLogCallDrawerOpened: function editLogCallDrawerOpened() {
    return function (dispatch) {
      var editLogCallDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.EDIT_LOG_CALL_DRAWER_OPENED
      };
      return dispatch(editLogCallDrawerOpenedAction);
    };
  },
  editLogCallDrawerClosed: function editLogCallDrawerClosed() {
    return function (dispatch) {
      var editLogCallDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.EDIT_LOG_CALL_DRAWER_CLOSED
      };
      return dispatch(editLogCallDrawerClosedAction);
    };
  },
  addLogEmailDrawerOpened: function addLogEmailDrawerOpened() {
    return function (dispatch) {
      var addLogEmailDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.ADD_LOG_EMAIL_DRAWER_OPENED
      };
      return dispatch(addLogEmailDrawerOpenedAction);
    };
  },
  addLogEmailDrawerClosed: function addLogEmailDrawerClosed() {
    return function (dispatch) {
      var addLogEmailDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.ADD_LOG_EMAIL_DRAWER_CLOSED
      };
      return dispatch(addLogEmailDrawerClosedAction);
    };
  },
  editLogEmailDrawerOpened: function editLogEmailDrawerOpened() {
    return function (dispatch) {
      var editLogEmailDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.EDIT_LOG_EMAIL_DRAWER_OPENED
      };
      return dispatch(editLogEmailDrawerOpenedAction);
    };
  },
  editLogEmailDrawerClosed: function editLogEmailDrawerClosed() {
    return function (dispatch) {
      var editLogEmailDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.EDIT_LOG_EMAIL_DRAWER_CLOSED
      };
      return dispatch(editLogEmailDrawerClosedAction);
    };
  },
  upcomingActivitiesDrawerOpened: function upcomingActivitiesDrawerOpened() {
    return function (dispatch) {
      var upcomingActivitiesDrawerOpened = {
        type: exports.SalesActivityActionTypes.UPCOMING_ACTIVITIES_DRAWER_OPENED
      };
      return dispatch(upcomingActivitiesDrawerOpened);
    };
  },
  upcomingActivitiesDrawerClosed: function upcomingActivitiesDrawerClosed() {
    return function (dispatch) {
      var upcomingActivitiesDrawerClosed = {
        type: exports.SalesActivityActionTypes.UPCOMING_ACTIVITIES_DRAWER_CLOSED
      };
      return dispatch(upcomingActivitiesDrawerClosed);
    };
  },
  auditLogsDrawerOpened: function auditLogsDrawerOpened() {
    return function (dispatch) {
      var auditLogsDrawerOpened = {
        type: exports.SalesActivityActionTypes.AUDIT_LOGS_DRAWER_OPENED
      };
      return dispatch(auditLogsDrawerOpened);
    };
  },
  auditLogsDrawerClosed: function auditLogsDrawerClosed() {
    return function (dispatch) {
      var auditLogsDrawerClosed = {
        type: exports.SalesActivityActionTypes.AUDIT_LOGS_DRAWER_CLOSED
      };
      return dispatch(auditLogsDrawerClosed);
    };
  },
  getHistoryActivities: function getHistoryActivities(getHistoryActivitiesRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var historyActivitiesRequestedAction, response, historyActivitiesSuccessfulAction, error_39, historyActivitiesFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              historyActivitiesRequestedAction = {
                type: exports.SalesActivityActionTypes.HISTORY_ACTIVITIES_REQUESTED
              };
              dispatch(historyActivitiesRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getHistoryActivities(getHistoryActivitiesRequest)];
            case 2:
              response = _a.sent();
              historyActivitiesSuccessfulAction = {
                type: exports.SalesActivityActionTypes.HISTORY_ACTIVITIES_SUCCESSFUL,
                historyResponse: response.data,
                next: response.next
              };
              return [2 /*return*/, dispatch(historyActivitiesSuccessfulAction)];
            case 3:
              error_39 = _a.sent();
              historyActivitiesFailedAction = {
                type: exports.SalesActivityActionTypes.HISTORY_ACTIVITIES_FAILED
              };
              dispatch(historyActivitiesFailedAction);
              return [2 /*return*/, Promise.reject(error_39)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  resetHistoryActivity: function resetHistoryActivity() {
    return function (dispatch) {
      var historyActivityResetAction = {
        type: exports.SalesActivityActionTypes.HISTORY_ACTIVITIES_RESET
      };
      return dispatch(historyActivityResetAction);
    };
  },
  getOneMeeting: function getOneMeeting(getOneSaleActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var getOneMeetingRequestedAction, response, getOneMeetingSuccessfulAction, error_40, getOneMeetingFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              getOneMeetingRequestedAction = {
                type: exports.SalesActivityActionTypes.GET_ONE_MEETING_REQUESTED
              };
              dispatch(getOneMeetingRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getOneMeeting(getOneSaleActivityRequest)];
            case 2:
              response = _a.sent();
              getOneMeetingSuccessfulAction = {
                type: exports.SalesActivityActionTypes.GET_ONE_MEETING_SUCCESSFUL,
                salesActivity: response
              };
              return [2 /*return*/, dispatch(getOneMeetingSuccessfulAction)];
            case 3:
              error_40 = _a.sent();
              getOneMeetingFailedAction = {
                type: exports.SalesActivityActionTypes.GET_ONE_MEETING_FAILED
              };
              dispatch(getOneMeetingFailedAction);
              return [2 /*return*/, Promise.reject(error_40)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  getOneTask: function getOneTask(getOneSaleActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var getOneTaskRequestedAction, response, getOneTaskSuccessfulAction, error_41, getOneTaskFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              getOneTaskRequestedAction = {
                type: exports.SalesActivityActionTypes.GET_ONE_TASK_REQUESTED
              };
              dispatch(getOneTaskRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getOneTask(getOneSaleActivityRequest)];
            case 2:
              response = _a.sent();
              getOneTaskSuccessfulAction = {
                type: exports.SalesActivityActionTypes.GET_ONE_TASK_SUCCESSFUL,
                salesActivity: response
              };
              return [2 /*return*/, dispatch(getOneTaskSuccessfulAction)];
            case 3:
              error_41 = _a.sent();
              getOneTaskFailedAction = {
                type: exports.SalesActivityActionTypes.GET_ONE_TASK_FAILED
              };
              dispatch(getOneTaskFailedAction);
              return [2 /*return*/, Promise.reject(error_41)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  getOneLogCall: function getOneLogCall(getOneSaleActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var getOneLogCallRequestedAction, response, getOneLogCallSuccessfulAction, error_42, getOneLogCallFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              getOneLogCallRequestedAction = {
                type: exports.SalesActivityActionTypes.GET_ONE_LOG_CALL_REQUESTED
              };
              dispatch(getOneLogCallRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getOneLogCall(getOneSaleActivityRequest)];
            case 2:
              response = _a.sent();
              getOneLogCallSuccessfulAction = {
                type: exports.SalesActivityActionTypes.GET_ONE_LOG_CALL_SUCCESSFUL,
                salesActivity: response
              };
              return [2 /*return*/, dispatch(getOneLogCallSuccessfulAction)];
            case 3:
              error_42 = _a.sent();
              getOneLogCallFailedAction = {
                type: exports.SalesActivityActionTypes.GET_ONE_LOG_CALL_FAILED
              };
              dispatch(getOneLogCallFailedAction);
              return [2 /*return*/, Promise.reject(error_42)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  getOneLogEmail: function getOneLogEmail(getOneSaleActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var getOneLogEmailRequestedAction, response, getOneLogEmailSuccessfulAction, error_43, getOneLogEmailFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              getOneLogEmailRequestedAction = {
                type: exports.SalesActivityActionTypes.GET_ONE_LOG_EMAIL_REQUESTED
              };
              dispatch(getOneLogEmailRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getOneLogEmail(getOneSaleActivityRequest)];
            case 2:
              response = _a.sent();
              getOneLogEmailSuccessfulAction = {
                type: exports.SalesActivityActionTypes.GET_ONE_LOG_EMAIL_SUCCESSFUL,
                salesActivity: response
              };
              return [2 /*return*/, dispatch(getOneLogEmailSuccessfulAction)];
            case 3:
              error_43 = _a.sent();
              getOneLogEmailFailedAction = {
                type: exports.SalesActivityActionTypes.GET_ONE_LOG_EMAIL_FAILED
              };
              dispatch(getOneLogEmailFailedAction);
              return [2 /*return*/, Promise.reject(error_43)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  getOneNote: function getOneNote(getOneSaleActivityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var getOneNoteRequestedAction, response, getOneNoteSuccessfulAction, error_44, getOneNoteFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              getOneNoteRequestedAction = {
                type: exports.SalesActivityActionTypes.GET_ONE_NOTE_REQUESTED
              };
              dispatch(getOneNoteRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getOneComment(getOneSaleActivityRequest)];
            case 2:
              response = _a.sent();
              getOneNoteSuccessfulAction = {
                type: exports.SalesActivityActionTypes.GET_ONE_NOTE_SUCCESSFUL,
                salesActivity: response
              };
              return [2 /*return*/, dispatch(getOneNoteSuccessfulAction)];
            case 3:
              error_44 = _a.sent();
              getOneNoteFailedAction = {
                type: exports.SalesActivityActionTypes.GET_ONE_NOTE_FAILED
              };
              dispatch(getOneNoteFailedAction);
              return [2 /*return*/, Promise.reject(error_44)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  fetchUsersAllSalesActivity: function fetchUsersAllSalesActivity(salesActivityPageRequest, companyUserList) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var fetchUsersAllSalesActivityRequestedAction, response, fetchUsersAllSalesActivitySuccessfullyAction, error_45, fetchUsersAllSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              fetchUsersAllSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.FETCH_USERS_ALL_SALES_ACTIVITY_REQUESTED
              };
              dispatch(fetchUsersAllSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getUsersAllSalesActivity(salesActivityPageRequest)];
            case 2:
              response = _a.sent();
              fetchUsersAllSalesActivitySuccessfullyAction = {
                type: exports.SalesActivityActionTypes.FETCH_USERS_ALL_SALES_ACTIVITY_SUCCESSFULLY,
                salesActivities: utils.hydrateUserData(companyUserList, response)
              };
              return [2 /*return*/, dispatch(fetchUsersAllSalesActivitySuccessfullyAction)];
            case 3:
              error_45 = _a.sent();
              fetchUsersAllSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.FETCH_USERS_ALL_SALES_ACTIVITY_FAILED
              };
              dispatch(fetchUsersAllSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_45)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  fetchOpportunitiesForSalesActivities: function fetchOpportunitiesForSalesActivities(opportunityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var fetchOpportunitiesForSalesActivityRequestedAction, opportunitiesResponse, fetchOpportunitiesForSalesActivitySuccessfullyAction, error_46, fetchOpportunitiesForSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              fetchOpportunitiesForSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.FETCH_OPPORTUNITIES_FOR_SALES_ACTIVITY_REQUESTED
              };
              dispatch(fetchOpportunitiesForSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index$1.getOpportunities(opportunityRequest)];
            case 2:
              opportunitiesResponse = _a.sent();
              fetchOpportunitiesForSalesActivitySuccessfullyAction = {
                type: exports.SalesActivityActionTypes.FETCH_OPPORTUNITIES_FOR_SALES_ACTIVITY_SUCCESSFUL,
                opportunities: opportunitiesResponse.data
              };
              return [2 /*return*/, dispatch(fetchOpportunitiesForSalesActivitySuccessfullyAction)];
            case 3:
              error_46 = _a.sent();
              fetchOpportunitiesForSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.FETCH_OPPORTUNITIES_FOR_SALES_ACTIVITY_FAILED
              };
              dispatch(fetchOpportunitiesForSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_46)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  fetchClientsForSalesActivities: function fetchClientsForSalesActivities(clientsRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var fetchClientsForSalesActivityRequestedAction, clientsResponse, fetchClientsForSalesActivitySuccessfullyAction, error_47, fetchClientsForSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              fetchClientsForSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.FETCH_CLIENTS_FOR_SALES_ACTIVITY_REQUESTED
              };
              dispatch(fetchClientsForSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index$2.getClients(clientsRequest)];
            case 2:
              clientsResponse = _a.sent();
              fetchClientsForSalesActivitySuccessfullyAction = {
                type: exports.SalesActivityActionTypes.FETCH_CLIENTS_FOR_SALES_ACTIVITY_SUCCESSFUL,
                clients: clientsResponse.data
              };
              return [2 /*return*/, dispatch(fetchClientsForSalesActivitySuccessfullyAction)];
            case 3:
              error_47 = _a.sent();
              fetchClientsForSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.FETCH_CLIENTS_FOR_SALES_ACTIVITY_FAILED
              };
              dispatch(fetchClientsForSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_47)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  fetchClientsContactForSalesActivities: function fetchClientsContactForSalesActivities(clientContactRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var fetchClientContactsForSalesActivityRequestedAction, clientContactsResponse, fetchClientContactsForSalesActivitySuccessfullyAction, error_48, fetchClientContactsForSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              fetchClientContactsForSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.FETCH_CLIENT_CONTACTS_FOR_SALES_ACTIVITY_REQUESTED
              };
              dispatch(fetchClientContactsForSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index$2.getClientContacts(clientContactRequest)];
            case 2:
              clientContactsResponse = _a.sent();
              fetchClientContactsForSalesActivitySuccessfullyAction = {
                type: exports.SalesActivityActionTypes.FETCH_CLIENT_CONTACTS_FOR_SALES_ACTIVITY_SUCCESSFUL,
                clientContacts: clientContactsResponse.data
              };
              return [2 /*return*/, dispatch(fetchClientContactsForSalesActivitySuccessfullyAction)];
            case 3:
              error_48 = _a.sent();
              console.error(error_48);
              fetchClientContactsForSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.FETCH_CLIENT_CONTACTS_FOR_SALES_ACTIVITY_FAILED
              };
              dispatch(fetchClientContactsForSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_48)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  resetSalesActivityOpportunities: function resetSalesActivityOpportunities() {
    return function (dispatch) {
      var resetSalesActivityOpportunitiesAction = {
        type: exports.SalesActivityActionTypes.RESET_SALES_ACTIVITY_OPPORTUNITIES
      };
      return dispatch(resetSalesActivityOpportunitiesAction);
    };
  },
  resetSalesActivityClients: function resetSalesActivityClients() {
    return function (dispatch) {
      var resetSalesActivityClientsAction = {
        type: exports.SalesActivityActionTypes.RESET_SALES_ACTIVITY_CLIENTS
      };
      return dispatch(resetSalesActivityClientsAction);
    };
  },
  resetSalesActivityClientContacts: function resetSalesActivityClientContacts() {
    return function (dispatch) {
      var resetSalesActivityClientContactsAction = {
        type: exports.SalesActivityActionTypes.RESET_SALES_ACTIVITY_CLIENT_CONTACTS
      };
      return dispatch(resetSalesActivityClientContactsAction);
    };
  },
  syncEntityWithPSA: function syncEntityWithPSA(syncEntityRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var syncWithPSARequestedAction, syncWithPSASuccessfulAction, error_49, syncWithPSAFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              syncWithPSARequestedAction = {
                type: exports.SalesActivityActionTypes.SYNC_WITH_PSA_REQUESTED
              };
              dispatch(syncWithPSARequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.syncEntityWithPsa(syncEntityRequest)];
            case 2:
              _a.sent();
              syncWithPSASuccessfulAction = {
                type: exports.SalesActivityActionTypes.SYNC_WITH_PSA_SUCCESSFUL
              };
              return [2 /*return*/, dispatch(syncWithPSASuccessfulAction)];
            case 3:
              error_49 = _a.sent();
              syncWithPSAFailedAction = {
                type: exports.SalesActivityActionTypes.SYNC_WITH_PSA_FAILED
              };
              dispatch(syncWithPSAFailedAction);
              return [2 /*return*/, Promise.reject(error_49)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  resetAllUsersSalesActivity: function resetAllUsersSalesActivity() {
    return function (dispatch) {
      var resetAllUserSalesActivityAction = {
        type: exports.SalesActivityActionTypes.RESET_USERS_ALL_SALES_ACTIVITY
      };
      return dispatch(resetAllUserSalesActivityAction);
    };
  },
  addGlobalTaskDrawerOpened: function addGlobalTaskDrawerOpened() {
    return function (dispatch) {
      var addGlobalTaskDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.ADD_GLOBAL_TASK_DRAWER_OPENED
      };
      return dispatch(addGlobalTaskDrawerOpenedAction);
    };
  },
  addGlobalTaskDrawerClosed: function addGlobalTaskDrawerClosed() {
    return function (dispatch) {
      var addGlobalTaskDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.ADD_GLOBAL_TASK_DRAWER_CLOSED
      };
      return dispatch(addGlobalTaskDrawerClosedAction);
    };
  },
  addGlobalCommentDrawerOpened: function addGlobalCommentDrawerOpened() {
    return function (dispatch) {
      var addGlobalCommentDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.ADD_GLOBAL_COMMENT_DRAWER_OPENED
      };
      return dispatch(addGlobalCommentDrawerOpenedAction);
    };
  },
  addGlobalCommentDrawerClosed: function addGlobalCommentDrawerClosed() {
    return function (dispatch) {
      var addGlobalCommentDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.ADD_GLOBAL_COMMENT_DRAWER_CLOSED
      };
      return dispatch(addGlobalCommentDrawerClosedAction);
    };
  },
  addGlobalLogCallDrawerOpened: function addGlobalLogCallDrawerOpened() {
    return function (dispatch) {
      var addGlobalLogCallDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.ADD_GLOBAL_LOG_CALL_DRAWER_OPENED
      };
      return dispatch(addGlobalLogCallDrawerOpenedAction);
    };
  },
  addGlobalLogCallDrawerClosed: function addGlobalLogCallDrawerClosed() {
    return function (dispatch) {
      var addGlobalLogCallDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.ADD_GLOBAL_LOG_CALL_DRAWER_CLOSED
      };
      return dispatch(addGlobalLogCallDrawerClosedAction);
    };
  },
  addGlobalLogEmailDrawerOpened: function addGlobalLogEmailDrawerOpened() {
    return function (dispatch) {
      var addGlobalLogEmailDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.ADD_GLOBAL_LOG_EMAIL_DRAWER_OPENED
      };
      return dispatch(addGlobalLogEmailDrawerOpenedAction);
    };
  },
  addGlobalLogEmailDrawerClosed: function addGlobalLogEmailDrawerClosed() {
    return function (dispatch) {
      var addGlobalLogEmailDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.ADD_GLOBAL_LOG_EMAIL_DRAWER_CLOSED
      };
      return dispatch(addGlobalLogEmailDrawerClosedAction);
    };
  },
  addGlobalMeetingDrawerOpened: function addGlobalMeetingDrawerOpened() {
    return function (dispatch) {
      var addGlobalMeetingDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.ADD_GLOBAL_MEETING_DRAWER_OPENED
      };
      return dispatch(addGlobalMeetingDrawerOpenedAction);
    };
  },
  addGlobalMeetingDrawerClosed: function addGlobalMeetingDrawerClosed() {
    return function (dispatch) {
      var addGlobalMeetingDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.ADD_GLOBAL_MEETING_DRAWER_CLOSED
      };
      return dispatch(addGlobalMeetingDrawerClosedAction);
    };
  },
  editGlobalTaskDrawerOpened: function editGlobalTaskDrawerOpened() {
    return function (dispatch) {
      var editGlobalTaskDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.EDIT_GLOBAL_TASK_DRAWER_OPENED
      };
      return dispatch(editGlobalTaskDrawerOpenedAction);
    };
  },
  editGlobalTaskDrawerClosed: function editGlobalTaskDrawerClosed() {
    return function (dispatch) {
      var editGlobalTaskDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.EDIT_GLOBAL_TASK_DRAWER_CLOSED
      };
      return dispatch(editGlobalTaskDrawerClosedAction);
    };
  },
  editGlobalCommentDrawerOpened: function editGlobalCommentDrawerOpened() {
    return function (dispatch) {
      var editGlobalCommentDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.EDIT_GLOBAL_COMMENT_DRAWER_OPENED
      };
      return dispatch(editGlobalCommentDrawerOpenedAction);
    };
  },
  editGlobalCommentDrawerClosed: function editGlobalCommentDrawerClosed() {
    return function (dispatch) {
      var editGlobalCommentDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.EDIT_GLOBAL_COMMENT_DRAWER_CLOSED
      };
      return dispatch(editGlobalCommentDrawerClosedAction);
    };
  },
  editGlobalLogCallDrawerOpened: function editGlobalLogCallDrawerOpened() {
    return function (dispatch) {
      var editGlobalLogCallDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.EDIT_GLOBAL_LOG_CALL_DRAWER_OPENED
      };
      return dispatch(editGlobalLogCallDrawerOpenedAction);
    };
  },
  editGlobalLogCallDrawerClosed: function editGlobalLogCallDrawerClosed() {
    return function (dispatch) {
      var editGlobalLogCallDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.EDIT_GLOBAL_LOG_CALL_DRAWER_CLOSED
      };
      return dispatch(editGlobalLogCallDrawerClosedAction);
    };
  },
  editGlobalLogEmailDrawerOpened: function editGlobalLogEmailDrawerOpened() {
    return function (dispatch) {
      var editGlobalLogEmailDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.EDIT_GLOBAL_LOG_EMAIL_DRAWER_OPENED
      };
      return dispatch(editGlobalLogEmailDrawerOpenedAction);
    };
  },
  editGlobalLogEmailDrawerClosed: function editGlobalLogEmailDrawerClosed() {
    return function (dispatch) {
      var editGlobalLogEmailDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.EDIT_GLOBAL_LOG_EMAIL_DRAWER_CLOSED
      };
      return dispatch(editGlobalLogEmailDrawerClosedAction);
    };
  },
  editGlobalMeetingDrawerOpened: function editGlobalMeetingDrawerOpened() {
    return function (dispatch) {
      var editGlobalMeetingDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.EDIT_GLOBAL_MEETING_DRAWER_OPENED
      };
      return dispatch(editGlobalMeetingDrawerOpenedAction);
    };
  },
  editGlobalMeetingDrawerClosed: function editGlobalMeetingDrawerClosed() {
    return function (dispatch) {
      var editGlobalMeetingDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.EDIT_GLOBAL_MEETING_DRAWER_CLOSED
      };
      return dispatch(editGlobalMeetingDrawerClosedAction);
    };
  },
  updateActiveFilters: function updateActiveFilters(activeFilters) {
    return function (dispatch) {
      var updateActiveFiltersAction = {
        type: exports.SalesActivityActionTypes.UPDATE_ACTIVE_FILTERS,
        activeFilters: activeFilters
      };
      return dispatch(updateActiveFiltersAction);
    };
  },
  addActivityToUsersSalesActivity: function addActivityToUsersSalesActivity(salesActivity) {
    return function (dispatch) {
      var addActivityToUsersSalesActivityAction = {
        type: exports.SalesActivityActionTypes.ADD_ACTIVITY_TO_USERS_SALES_ACTIVITY,
        salesActivity: salesActivity
      };
      return dispatch(addActivityToUsersSalesActivityAction);
    };
  },
  updateSingleUsersSalesActivity: function updateSingleUsersSalesActivity(salesActivity) {
    return function (dispatch) {
      var updateSingleUserSalesActivityAction = {
        type: exports.SalesActivityActionTypes.UPDATE_SINGLE_USERS_SALES_ACTIVITY,
        salesActivity: salesActivity
      };
      return dispatch(updateSingleUserSalesActivityAction);
    };
  },
  fetchOneSalesActivity: function fetchOneSalesActivity(getOneSaleActivityRequest, companyUserList) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var getOneSalesActivityRequestedAction, response, getOneSalesActivitySuccessfulAction, error_50, getOneSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              getOneSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.GET_ONE_SALES_ACTIVITY_REQUESTED
              };
              dispatch(getOneSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getOneSalesActivity(getOneSaleActivityRequest)];
            case 2:
              response = _a.sent();
              getOneSalesActivitySuccessfulAction = {
                type: exports.SalesActivityActionTypes.GET_ONE_SALES_ACTIVITY_SUCCESSFUL,
                salesActivity: utils.hydrateUserData(companyUserList, response)
              };
              return [2 /*return*/, dispatch(getOneSalesActivitySuccessfulAction)];
            case 3:
              error_50 = _a.sent();
              getOneSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.GET_ONE_SALES_ACTIVITY_FAILED
              };
              dispatch(getOneSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_50)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  deleteOneSalesActivity: function deleteOneSalesActivity(salesActivity) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var deleteOneSalesActivityRequestedAction, deleteOneSalesActivitySuccessfullyAction, error_51, deleteOneSalesActivityFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              deleteOneSalesActivityRequestedAction = {
                type: exports.SalesActivityActionTypes.DELETE_ONE_SALES_ACTIVITY_REQUESTED
              };
              dispatch(deleteOneSalesActivityRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.deleteOneSalesActivity(salesActivity)];
            case 2:
              _a.sent();
              deleteOneSalesActivitySuccessfullyAction = {
                type: exports.SalesActivityActionTypes.DELETE_ONE_SALES_ACTIVITY_SUCCESSFULLY,
                salesActivityId: salesActivity.id
              };
              return [2 /*return*/, dispatch(deleteOneSalesActivitySuccessfullyAction)];
            case 3:
              error_51 = _a.sent();
              deleteOneSalesActivityFailedAction = {
                type: exports.SalesActivityActionTypes.DELETE_ONE_SALES_ACTIVITY_FAILED
              };
              dispatch(deleteOneSalesActivityFailedAction);
              return [2 /*return*/, Promise.reject(error_51)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  fetchMeetingClashStatus: function fetchMeetingClashStatus(startTime, endTime, id) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var fetchMeetingClashStatusRequested, response, fetchMeetingClashStatusSuccessful, error_52, fetchMeetingClashStatusFailed;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              fetchMeetingClashStatusRequested = {
                type: exports.SalesActivityActionTypes.FETCHING_MEETING_CLASH_STATUS_REQUESTED
              };
              dispatch(fetchMeetingClashStatusRequested);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.fetchMeetingClashStatus(startTime, endTime, id)];
            case 2:
              response = _a.sent();
              fetchMeetingClashStatusSuccessful = {
                type: exports.SalesActivityActionTypes.FETCHING_MEETING_CLASH_STATUS_SUCCESSFUL,
                meetingStatus: response.status
              };
              return [2 /*return*/, dispatch(fetchMeetingClashStatusSuccessful)];
            case 3:
              error_52 = _a.sent();
              fetchMeetingClashStatusFailed = {
                type: exports.SalesActivityActionTypes.FETCHING_MEETING_CLASH_STATUS_FAILED
              };
              dispatch(fetchMeetingClashStatusFailed);
              return [2 /*return*/, Promise.reject(error_52)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  fetchPSAAuditLogs: function fetchPSAAuditLogs(fetchAuditLogsRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var fetchAllPSAAuditLogsRequestedAction, isPaymentLogs, response, fetchAllPSAAuditLogsSuccessfulAction, error_53, fetchAllPSAAuditLogsFailedAction;
        var _a, _b;
        return tslib_es6.__generator(this, function (_c) {
          switch (_c.label) {
            case 0:
              fetchAllPSAAuditLogsRequestedAction = {
                type: exports.SalesActivityActionTypes.FETCH_ALL_PSA_AUDIT_LOGS_REQUESTED
              };
              dispatch(fetchAllPSAAuditLogsRequestedAction);
              _c.label = 1;
            case 1:
              _c.trys.push([1, 3,, 4]);
              isPaymentLogs = !!((_a = fetchAuditLogsRequest.sync_source) === null || _a === void 0 ? void 0 : _a.length);
              return [4 /*yield*/, index.fetchAuditLogs(fetchAuditLogsRequest)];
            case 2:
              response = _c.sent();
              fetchAllPSAAuditLogsSuccessfulAction = {
                type: exports.SalesActivityActionTypes.FETCH_ALL_PSA_AUDIT_LOGS_SUCCESSFUL,
                auditLogs: response.data,
                paymentLogs: isPaymentLogs ? response.data : undefined,
                next: response.next,
                count: (_b = response.filtered_count_map) === null || _b === void 0 ? void 0 : _b.count
              };
              return [2 /*return*/, dispatch(fetchAllPSAAuditLogsSuccessfulAction)];
            case 3:
              error_53 = _c.sent();
              fetchAllPSAAuditLogsFailedAction = {
                type: exports.SalesActivityActionTypes.FETCH_ALL_PSA_AUDIT_LOGS_FAILED
              };
              dispatch(fetchAllPSAAuditLogsFailedAction);
              return [2 /*return*/, Promise.reject(error_53)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  retryFailedPSAAuditLog: function retryFailedPSAAuditLog(retryFailedAuditLogRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var retryFailedAuditLogsRequestAction, retryFailedAuditLogsSuccesfulAction, error_54, retryFailedAuditLogsFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              retryFailedAuditLogsRequestAction = {
                type: exports.SalesActivityActionTypes.RETRY_FAILED_AUDIT_LOG_REQUESTED
              };
              dispatch(retryFailedAuditLogsRequestAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.retryFailedAuditLog(retryFailedAuditLogRequest)];
            case 2:
              _a.sent();
              retryFailedAuditLogsSuccesfulAction = {
                type: exports.SalesActivityActionTypes.RETRY_FAILED_AUDIT_LOG_SUCCESFUL
              };
              return [2 /*return*/, dispatch(retryFailedAuditLogsSuccesfulAction)];
            case 3:
              error_54 = _a.sent();
              retryFailedAuditLogsFailedAction = {
                type: exports.SalesActivityActionTypes.RETRY_FAILED_AUDIT_LOG_FAILED
              };
              dispatch(retryFailedAuditLogsFailedAction);
              return [2 /*return*/, Promise.reject(error_54)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  getAuditLogRedirectUrl: function getAuditLogRedirectUrl(id) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var auditLogRedirectUrlRequestedAction, response, auditLogRedirectUrlSuccessfulAction, err_1, auditLogRedirectUrlFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              auditLogRedirectUrlRequestedAction = {
                type: exports.SalesActivityActionTypes.AUDIT_LOG_REDIRECT_URL_REQUESTED
              };
              dispatch(auditLogRedirectUrlRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getAuditLogRedirectUrl(id)];
            case 2:
              response = _a.sent();
              auditLogRedirectUrlSuccessfulAction = {
                type: exports.SalesActivityActionTypes.AUDIT_LOG_REDIRECT_URL_SUCCESFUL,
                redirectUrl: response
              };
              return [2 /*return*/, dispatch(auditLogRedirectUrlSuccessfulAction)];
            case 3:
              err_1 = _a.sent();
              auditLogRedirectUrlFailedAction = {
                type: exports.SalesActivityActionTypes.AUDIT_LOG_REDIRECT_URL_FAILED
              };
              dispatch(auditLogRedirectUrlFailedAction);
              return [2 /*return*/, Promise.reject(err_1)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  fetchCustomDistributorAuditLogs: function fetchCustomDistributorAuditLogs(fetchAuditLogsRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var fetchAllPSAAuditLogsRequestedAction, response, fetchAllPSAAuditLogsSuccessfulAction, error_55, fetchAllPSAAuditLogsFailedAction;
        var _a;
        return tslib_es6.__generator(this, function (_b) {
          switch (_b.label) {
            case 0:
              fetchAllPSAAuditLogsRequestedAction = {
                type: exports.SalesActivityActionTypes.FETCH_ALL_PSA_AUDIT_LOGS_REQUESTED
              };
              dispatch(fetchAllPSAAuditLogsRequestedAction);
              _b.label = 1;
            case 1:
              _b.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.fetchCustomDistributorAuditLogs(fetchAuditLogsRequest)];
            case 2:
              response = _b.sent();
              fetchAllPSAAuditLogsSuccessfulAction = {
                type: exports.SalesActivityActionTypes.FETCH_ALL_PSA_AUDIT_LOGS_SUCCESSFUL,
                auditLogs: response,
                next: response.next,
                count: (_a = response.filtered_count_map) === null || _a === void 0 ? void 0 : _a.count
              };
              return [2 /*return*/, dispatch(fetchAllPSAAuditLogsSuccessfulAction)];
            case 3:
              error_55 = _b.sent();
              fetchAllPSAAuditLogsFailedAction = {
                type: exports.SalesActivityActionTypes.FETCH_ALL_PSA_AUDIT_LOGS_FAILED
              };
              dispatch(fetchAllPSAAuditLogsFailedAction);
              return [2 /*return*/, Promise.reject(error_55)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  updateCurrentActiveSalesActivityAutomationActionIndex: function updateCurrentActiveSalesActivityAutomationActionIndex(updatedIndex) {
    return function (dispatch) {
      var updateCurrentActiveSalesActivityAutomationActionIndex = {
        type: exports.SalesActivityActionTypes.UPDATE_CURRENT_ACTIVE_SALES_ACTIVITY_AUTOMATION_ACTION_INDEX,
        index: updatedIndex
      };
      return dispatch(updateCurrentActiveSalesActivityAutomationActionIndex);
    };
  },
  showPaymentsDrawer: function showPaymentsDrawer() {
    return function (dispatch) {
      var paymentDrawerOpenedAction = {
        type: exports.SalesActivityActionTypes.SHOW_PAYMENT_DRAWER
      };
      return dispatch(paymentDrawerOpenedAction);
    };
  },
  hidePaymentsDrawer: function hidePaymentsDrawer() {
    return function (dispatch) {
      var paymentDrawerClosedAction = {
        type: exports.SalesActivityActionTypes.HIDE_PAYMENT_DRAWER
      };
      return dispatch(paymentDrawerClosedAction);
    };
  },
  updateSelectedDocumentType: function updateSelectedDocumentType(selectedDocumentType) {
    return function (dispatch) {
      var updatedSelectedDocumentAction = {
        type: exports.SalesActivityActionTypes.UPDATE_SELECTED_DOCUMENT_TYPE,
        selectedDocumentType: selectedDocumentType
      };
      return dispatch(updatedSelectedDocumentAction);
    };
  },
  // Sales Automation views and filter actions
  fetchSalesActivityAndAutomationViewMetadata: function fetchSalesActivityAndAutomationViewMetadata(entity) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var fetchSalesActivityViewMetadataRequested, response, fetchSalesActivityViewMetadataSuccessful, error_56, fetchSalesActivityViewMetadataFailed;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              fetchSalesActivityViewMetadataRequested = {
                type: exports.SalesActivityActionTypes.FETCH_SALES_ACTIVITY_AND_AUTOMATION_VIEW_METADATA_REQUESTED
              };
              dispatch(fetchSalesActivityViewMetadataRequested);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.fetchSalesActivityAndAutomationViewMetadata(entity)];
            case 2:
              response = _a.sent();
              fetchSalesActivityViewMetadataSuccessful = {
                type: exports.SalesActivityActionTypes.FETCH_SALES_ACTIVITY_AND_AUTOMATION_VIEW_METADATA_SUCCESSFUL,
                metadata: response
              };
              return [2 /*return*/, dispatch(fetchSalesActivityViewMetadataSuccessful)];
            case 3:
              error_56 = _a.sent();
              console.error(error_56);
              fetchSalesActivityViewMetadataFailed = {
                type: exports.SalesActivityActionTypes.FETCH_SALES_ACTIVITY_AND_AUTOMATION_VIEW_METADATA_FAILED
              };
              dispatch(fetchSalesActivityViewMetadataFailed);
              return [2 /*return*/, Promise.reject(error_56)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  fetchSalesActivityAndAutomationViewsList: function fetchSalesActivityAndAutomationViewsList(entity) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var fetchSalesActivityViewsListRequested, response, fetchSalesActivityViewListSuccessful, error_57, fetchSalesActivityViewListFailed;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              fetchSalesActivityViewsListRequested = {
                type: exports.SalesActivityActionTypes.FETCH_SALES_ACTIVITY_AND_AUTOMATION_VIEWS_REQUESTED
              };
              dispatch(fetchSalesActivityViewsListRequested);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getAllSalesActivityAndAutomationViews(entity)];
            case 2:
              response = _a.sent();
              fetchSalesActivityViewListSuccessful = {
                type: exports.SalesActivityActionTypes.FETCH_SALES_ACTIVITY_AND_AUTOMATION_VIEWS_SUCCESSFUL,
                viewsList: response
              };
              return [2 /*return*/, dispatch(fetchSalesActivityViewListSuccessful)];
            case 3:
              error_57 = _a.sent();
              console.error(error_57);
              fetchSalesActivityViewListFailed = {
                type: exports.SalesActivityActionTypes.FETCH_SALES_ACTIVITY_AND_AUTOMATION_VIEWS_FAILED
              };
              dispatch(fetchSalesActivityViewListFailed);
              return [2 /*return*/, Promise.reject(error_57)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  updateCurrentActiveView: function updateCurrentActiveView(selectedCurrentActiveView, discardingView) {
    return function (dispatch, getState) {
      var _a;
      var currentActiveView = getState().salesActivity.currentActiveView;
      var prevViewData = (_a = getState().salesActivity.viewList) === null || _a === void 0 ? void 0 : _a.data.find(function (view) {
        return view.id === (selectedCurrentActiveView === null || selectedCurrentActiveView === void 0 ? void 0 : selectedCurrentActiveView.id);
      });
      var isSameView = (currentActiveView === null || currentActiveView === void 0 ? void 0 : currentActiveView.id) === (selectedCurrentActiveView === null || selectedCurrentActiveView === void 0 ? void 0 : selectedCurrentActiveView.id);
      if (isSameView) {
        // if its a custom view then it would have an id
        if (prevViewData === null || prevViewData === void 0 ? void 0 : prevViewData.id) {
          var hasFiltersChanged = utils.areFiltersChanged(prevViewData.filter_criteria, selectedCurrentActiveView.filter_criteria);
          var hasColumnsChanged = JSON.stringify(selectedCurrentActiveView.columns) !== JSON.stringify(prevViewData.columns);
          var updateCurrentActiveViewAction_1 = {
            type: exports.SalesActivityActionTypes.UPDATE_SALES_ACTIVITY_AND_AUTOMATION_CURRENT_ACTIVE_VIEW,
            currentActiveView: selectedCurrentActiveView,
            isViewChanged: discardingView ? false : hasFiltersChanged || hasColumnsChanged
          };
          return dispatch(updateCurrentActiveViewAction_1);
        }
        // if its a system view then no id
        if (currentActiveView) {
          var hasFiltersChanged = utils.areFiltersChanged(currentActiveView.filter_criteria, selectedCurrentActiveView.filter_criteria);
          var hasColumnsChanged = JSON.stringify(selectedCurrentActiveView.columns) !== JSON.stringify(currentActiveView === null || currentActiveView === void 0 ? void 0 : currentActiveView.columns);
          var updateCurrentActiveViewAction_2 = {
            type: exports.SalesActivityActionTypes.UPDATE_SALES_ACTIVITY_AND_AUTOMATION_CURRENT_ACTIVE_VIEW,
            currentActiveView: selectedCurrentActiveView,
            isViewChanged: discardingView ? false : hasFiltersChanged || hasColumnsChanged
          };
          return dispatch(updateCurrentActiveViewAction_2);
        }
      }
      var updateCurrentActiveViewAction = {
        type: exports.SalesActivityActionTypes.UPDATE_SALES_ACTIVITY_AND_AUTOMATION_CURRENT_ACTIVE_VIEW,
        currentActiveView: selectedCurrentActiveView,
        isViewChanged: discardingView ? true : false
      };
      return dispatch(updateCurrentActiveViewAction);
    };
  },
  createSalesActivityAndAutomationView: function createSalesActivityAndAutomationView(view) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var createSalesActivityViewRequestedAction, updatedView, response_1, updatedResponse, createSalesActivityViewSuccessfulAction_1, response, createSalesActivityViewSuccessfulAction, error_58, createSalesActivityViewFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              createSalesActivityViewRequestedAction = {
                type: exports.SalesActivityActionTypes.CREATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_REQUESTED
              };
              dispatch(createSalesActivityViewRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 5,, 6]);
              if (!(view.entity_type === contracts.ZomentumEntities.SalesActivity)) return [3 /*break*/, 3];
              updatedView = tslib_es6.__assign(tslib_es6.__assign({}, view), {
                filter_criteria: []
              });
              return [4 /*yield*/, index.createSalesActivityAndAutomationView(updatedView)];
            case 2:
              response_1 = _a.sent();
              updatedResponse = tslib_es6.__assign(tslib_es6.__assign({}, response_1), {
                filter_criteria: view.filter_criteria
              });
              createSalesActivityViewSuccessfulAction_1 = {
                type: exports.SalesActivityActionTypes.CREATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_SUCCESSFUL,
                view: updatedResponse
              };
              return [2 /*return*/, dispatch(createSalesActivityViewSuccessfulAction_1)];
            case 3:
              return [4 /*yield*/, index.createSalesActivityAndAutomationView(view)];
            case 4:
              response = _a.sent();
              createSalesActivityViewSuccessfulAction = {
                type: exports.SalesActivityActionTypes.CREATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_SUCCESSFUL,
                view: response
              };
              return [2 /*return*/, dispatch(createSalesActivityViewSuccessfulAction)];
            case 5:
              error_58 = _a.sent();
              createSalesActivityViewFailedAction = {
                type: exports.SalesActivityActionTypes.CREATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_FAILED
              };
              dispatch(createSalesActivityViewFailedAction);
              return [2 /*return*/, Promise.reject(error_58)];
            case 6:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  updateSalesActivityAndAutomationView: function updateSalesActivityAndAutomationView(view) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var updateSalesActivityViewRequestedAction, updatedView, response_2, updatedResponse, updateSalesActivityViewSuccessfulAction_1, response, updateSalesActivityViewSuccessfulAction, error_59, updateSalesActivityViewFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              updateSalesActivityViewRequestedAction = {
                type: exports.SalesActivityActionTypes.UPDATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_REQUESTED
              };
              dispatch(updateSalesActivityViewRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 5,, 6]);
              if (!(view.entity_type === contracts.ZomentumEntities.SalesActivity)) return [3 /*break*/, 3];
              updatedView = tslib_es6.__assign(tslib_es6.__assign({}, view), {
                filter_criteria: []
              });
              return [4 /*yield*/, index.updateSalesActivityAndAutomationView(updatedView)];
            case 2:
              response_2 = _a.sent();
              updatedResponse = tslib_es6.__assign(tslib_es6.__assign({}, response_2), {
                filter_criteria: view.filter_criteria
              });
              updateSalesActivityViewSuccessfulAction_1 = {
                type: exports.SalesActivityActionTypes.UPDATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_SUCCESSFUL,
                view: updatedResponse
              };
              return [2 /*return*/, dispatch(updateSalesActivityViewSuccessfulAction_1)];
            case 3:
              return [4 /*yield*/, index.updateSalesActivityAndAutomationView(view)];
            case 4:
              response = _a.sent();
              updateSalesActivityViewSuccessfulAction = {
                type: exports.SalesActivityActionTypes.UPDATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_SUCCESSFUL,
                view: response
              };
              return [2 /*return*/, dispatch(updateSalesActivityViewSuccessfulAction)];
            case 5:
              error_59 = _a.sent();
              updateSalesActivityViewFailedAction = {
                type: exports.SalesActivityActionTypes.UPDATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_FAILED
              };
              dispatch(updateSalesActivityViewFailedAction);
              return [2 /*return*/, Promise.reject(error_59)];
            case 6:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  deleteSalesActivityAndAutomationView: function deleteSalesActivityAndAutomationView(deleteView, initialView) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var deleteSalesActivityViewRequestedAction, deleteSalesActivityViewSuccessfulAction, error_60, deleteSalesActivityViewFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              deleteSalesActivityViewRequestedAction = {
                type: exports.SalesActivityActionTypes.DELETE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_REQUESTED
              };
              dispatch(deleteSalesActivityViewRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.deleteSalesActivityAndAutomationView(deleteView.id)];
            case 2:
              _a.sent();
              deleteSalesActivityViewSuccessfulAction = {
                type: exports.SalesActivityActionTypes.DELETE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_SUCCESSFUL,
                deletedView: deleteView,
                initialView: initialView
              };
              return [2 /*return*/, dispatch(deleteSalesActivityViewSuccessfulAction)];
            case 3:
              error_60 = _a.sent();
              deleteSalesActivityViewFailedAction = {
                type: exports.SalesActivityActionTypes.DELETE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_FAILED
              };
              dispatch(deleteSalesActivityViewFailedAction);
              return [2 /*return*/, Promise.reject(error_60)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  fetchAllSalesActivityAndAutomation: function fetchAllSalesActivityAndAutomation(salesAutomationRequest) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var fetchSalesAutomationListRequested, response, fetchSalesAutomationListSuccessful, error_61, fetchSalesAutomationListFailed;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              fetchSalesAutomationListRequested = {
                type: exports.SalesActivityActionTypes.FETCH_SALES_ACTIVITY_AND_AUTOMATION_LIST_REQUESTED
              };
              dispatch(fetchSalesAutomationListRequested);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getSalesActivityAndAutomationListPost(salesAutomationRequest)];
            case 2:
              response = _a.sent();
              fetchSalesAutomationListSuccessful = {
                type: exports.SalesActivityActionTypes.FETCH_SALES_ACTIVITY_AND_AUTOMATION_LIST_SUCCESSFUL,
                salesAutomationList: response
              };
              return [2 /*return*/, dispatch(fetchSalesAutomationListSuccessful)];
            case 3:
              error_61 = _a.sent();
              fetchSalesAutomationListFailed = {
                type: exports.SalesActivityActionTypes.FETCH_SALES_ACTIVITY_AND_AUTOMATION_LIST_FAILED
              };
              dispatch(fetchSalesAutomationListFailed);
              return [2 /*return*/, Promise.reject(error_61)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  getConflictMeetingDetail: function getConflictMeetingDetail(startTime) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var response, getConflictMeetingSuccessfulAction, error_62;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              _a.trys.push([0, 2,, 3]);
              return [4 /*yield*/, index.getConflictMeetingsDetail(startTime)];
            case 1:
              response = _a.sent();
              getConflictMeetingSuccessfulAction = {
                type: exports.SalesActivityActionTypes.GET_CONFLICT_MEETING_DETAIL_SUCCESSFUL,
                conflictMeetings: response
              };
              return [2 /*return*/, dispatch(getConflictMeetingSuccessfulAction)];
            case 2:
              error_62 = _a.sent();
              return [2 /*return*/, Promise.reject(error_62)];
            case 3:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  updateSelectedOwnerView: function updateSelectedOwnerView(selectedOwnerView) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var updateSelectedOwnerViewAction;
        return tslib_es6.__generator(this, function (_a) {
          updateSelectedOwnerViewAction = {
            type: exports.SalesActivityActionTypes.UPDATE_SELECTED_OWNER,
            selectedOwnerView: selectedOwnerView
          };
          return [2 /*return*/, dispatch(updateSelectedOwnerViewAction)];
        });
      });
    };
  },
  updateAuditLogsCount: function updateAuditLogsCount(auditLogsCount) {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var updateAuditLogsCountAction;
        return tslib_es6.__generator(this, function (_a) {
          updateAuditLogsCountAction = {
            type: exports.SalesActivityActionTypes.UPDATE_AUDIT_LOGS_COUNT,
            auditLogsCount: auditLogsCount
          };
          return [2 /*return*/, dispatch(updateAuditLogsCountAction)];
        });
      });
    };
  }
};

exports.SalesActivityActionCreators = SalesActivityActionCreators;
exports["default"] = SalesActivityActionCreators;
