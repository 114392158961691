import ZDrawer from "@zomentum/molecules/dist/ZDrawer";
import React, { FC, ReactNode, useEffect, useState, useCallback } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { ZButton, ZIcon, ZSelect, ZTypography } from "@zomentum/atoms";
import { IAddProductSKUToBundleQuoteBlockDrawerProps } from "../AddProductSKUToQuoteBlockDrawer/interface";
import { EFontWeights } from "@zomentum/atoms/dist/ZTypography/interface";
import ZTable from "@zomentum/organisms/dist/ZTable";
import { EIcons } from "@zomentum/atoms/dist/ZIcon/interface";
import ZInput from "@zomentum/molecules/dist/ZInput";
import {
	BundledSKUSProduct,
	GlobalSearchEntityType,
	GlobalSearchRequest,
	ListPagePostRequest,
	SKUProduct,
	SKUProductStatus
} from "@zomentum/contracts";
import { getProductSKUsView } from "@/Services/Vendor/SKU";
import { ColumnType } from "antd/lib/table/interface";
import { SKUProductCatalogResultRow } from "../SKUProductCatalogResultRow";
import "../AddProductSKUToQuoteBlockDrawer/index.less";
import { constructProductSKUIdToPricingMapping } from "@/V2Utils";
import { getGlobalSearch } from "@zomentum/utils/dist/Service/GlobalSearch";
import ProductSKUDrawer from "@pages/Vendor/MarketplaceProductSKUs/ProductSKUDrawer";

import { EProductSKUDrawerState } from "@pages/Vendor/MarketplaceProductSKUs/ProductSKUDrawer/interface";

import { ProductSource, ProductType } from "@zomentum/contracts/dist/Products";
import { EditOrCloneProductDrawerMode } from "../AddProductSKUToQuoteBlockDrawer/interface";
import { Button } from "antd";
import { ZMessage } from "@zomentum/molecules";
import { useFeatureFlag } from "@zomentum/hooks/dist/hooks/useFeature";
const { ZText } = ZTypography;

const AddProductSKUToQuoteBlockDrawer: FC<
	IAddProductSKUToBundleQuoteBlockDrawerProps
> = ({
	visible,
	onSubmitBundleProducts,
	onClose,
	addNewProducts,
	addedProducts
}) => {
	const useAtlasSearch = useFeatureFlag("use_atlas_search");
	const [loading, setLoading] = useState(false);
	const [addNewProduct, setAddNewProduct] = useState(false);
	const [productSKUs, setProductSKUs] = useState<SKUProduct[]>([]);

	const [productSKUsNew, setProductSKUsNew] = useState<SKUProduct>();
	const [selectedProductSKUs, setSelectedProductSKUs] = useState<
		BundledSKUSProduct[]
	>([]);
	const [productVsPricing, setProductVsPricing] = useState<
		Record<string, string | null>
	>({});

	const handleOnClose = () => {
		setSelectedProductSKUs([]);
		onClose();
	};

	const getAllProductSkus = async (skuRequest: ListPagePostRequest) => {
		setLoading(true);
		try {
			const response = await getProductSKUsView(skuRequest);
			setProductSKUs(
				response.data.filter(
					product => product.type !== ProductType.Bundle
				)
			);
			setProductVsPricing(prevState => ({
				...prevState,
				...constructProductSKUIdToPricingMapping(response.data)
			}));
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const onPricingSelect = (productId: string, selectedPricingId?: string) => {
		setProductVsPricing(prevState => ({
			...prevState,
			[productId]: selectedPricingId ?? null
		}));
	};

	const renderProductColumn = (
		_text: unknown,
		record: SKUProduct
	): ReactNode => {
		return (
			<SKUProductCatalogResultRow
				record={record}
				selectedPricingId={productVsPricing[record?.id]}
				onPricingChange={onPricingSelect}
			/>
		);
	};

	const productColumns: ColumnType<SKUProduct>[] = [
		{
			dataIndex: "name",
			key: "name",
			render: renderProductColumn
		}
	];

	const handleProductRowEvent = (record: SKUProduct) => ({
		onClick: () => {
			const checkForProduct = selectedProductSKUs.some(
				product => product.id === record.id
			);

			if (!checkForProduct) setProductSKUsNew(record);
			else {
				setSelectedProductSKUs(prevState =>
					prevState.filter(product => product.id !== record.id)
				);
			}
		}
	});

	const rowSelection: any = {
		selectedRowKeys: selectedProductSKUs.map(product => product.id),
		columnWidth: 20,
		preserveSelectedRowKeys: true,
		type: "checkbox",
		onChange: (_, selectedProduct) => {
			setSelectedProductSKUs([...selectedProduct]);
		}
	};

	const handleSubmit = () => {
		const bundledProducts = selectedProductSKUs.filter(
			product => product.type === ProductType.Bundle
		);
		if (!!bundledProducts.length) {
			ZMessage.error("You cannot add a bundle to another bundle");
			return;
		}

		onSubmitBundleProducts(
			selectedProductSKUs
				.filter(product => product.type !== ProductType.Bundle)
				.map(selectedProduct => {
					const selectedProductPricing = productSKUs.filter(
						product => product.id === selectedProduct.id
					);
					const selectedPricing =
						selectedProductPricing[0].pricings.filter(pricing => {
							if (selectedProduct.id)
								return (
									pricing?.id ===
									productVsPricing[selectedProduct.id]
								);
							return false;
						});

					return {
						...selectedProduct,
						selected_pricing_id: selectedProduct?.id
							? productVsPricing[selectedProduct.id]
							: null,
						pricings: [selectedPricing[0]]
					};
				})
		);
		handleOnClose();
	};

	const searchProducts = async (keyword: string) => {
		if (!keyword?.length) return;
		const globalSearchRequest = new GlobalSearchRequest(
			keyword,
			[GlobalSearchEntityType.MarketplaceSKU],
			50,
			null,
			useAtlasSearch
		);
		setLoading(true);
		try {
			const skuSearchResponse = await getGlobalSearch(
				globalSearchRequest
			);

			const publishedSKUs = skuSearchResponse.data
				.map(entity => entity.obj as SKUProduct)
				.filter(
					product => product.status === SKUProductStatus.PUBLISHED
				);

			setProductSKUs(publishedSKUs);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const getAllProductsList = useCallback(() => {
		getAllProductSkus(
			new ListPagePostRequest({
				count_query_flag: true,
				filter_criterion: [],
				sort_criterion: [],
				page_no: 1,
				page_size: 25,
				includedChildEntities: []
			})
		);
	}, []);

	useEffect(() => {
		visible && getAllProductsList();
		setSelectedProductSKUs(addedProducts);
	}, [visible]);

	const renderTableHeader = (): React.ReactNode => {
		return (
			<Row className="document-product-drawer-product-header bg-gray-1">
				{addNewProducts && (
					<Col
						span={4}
						className="document-product-drawer-product-source"
					>
						<ZSelect
							getPopupContainer={trigger => trigger.parentNode}
							allowClear={false}
							defaultValue={ProductSource.Self}
							placeholder="Please choose the product source"
							dropdownClassName="w-min"
							className="create-product-dropdown w-full"
							options={[
								{ value: ProductSource.Self, label: "Self" }
							]}
							dropdownRender={menu => (
								<div>
									{menu}
									<ZTypography.ZLink
										level={1}
										className={`create-product-dropdown-cta block w-full`}
										onClick={() => setAddNewProduct(true)}
									>
										Create Product
									</ZTypography.ZLink>
								</div>
							)}
						/>
					</Col>
				)}
				<Col span={addNewProducts ? 20 : 24}>
					<ZInput
						placeholder="Enter product name"
						inputType="search"
						className={`w-full add-new-product`}
						enterButton={
							<Button
								type="primary"
								htmlType="button"
								icon={
									<ZIcon
										name={EIcons.SEARCH}
										size="sm"
										colorVariant={1}
										className="mr-2"
									/>
								}
								loading={loading}
							>
								Search
							</Button>
						}
						prefix={null}
						onSearch={searchProducts}
					/>
				</Col>
			</Row>
		);
	};

	return (
		<ZDrawer
			visible={visible}
			closable={true}
			destroyOnClose={true}
			onClose={handleOnClose}
			removeDefaultPadding
			title={
				<Row>
					<Col span={24}>
						<ZText weight={EFontWeights.MEDIUM} level={2}>
							Search Product
						</ZText>
					</Col>
				</Row>
			}
			footer={
				<div className="flex justify-end">
					<ZButton
						type="default"
						disabled={loading}
						className="mr-4"
						onClick={onClose}
					>
						Cancel
					</ZButton>
					<ZButton
						type="primary"
						disabled={loading || !productSKUs.length}
						onClick={handleSubmit}
					>
						Add Products
					</ZButton>
				</div>
			}
			className="add-product-sku-quote-drawer"
		>
			{addNewProducts && (
				<ProductSKUDrawer
					visible={addNewProduct}
					onClose={() => setAddNewProduct(false)}
					drawerState={EProductSKUDrawerState.ADD}
					onAdd={() => {
						setAddNewProduct(false);
						getAllProductsList();
					}}
				/>
			)}
			{!!productSKUsNew && (
				<ProductSKUDrawer
					currentSKU={productSKUsNew}
					visible={!!productSKUsNew}
					onClose={() => setProductSKUsNew(undefined)}
					drawerState={EProductSKUDrawerState.EDITORCLONE}
					mode={EditOrCloneProductDrawerMode.EDIT}
					bundleClone={true}
					handleEditBundleProduct={val => {
						setSelectedProductSKUs(prevState => [
							...prevState,
							val
						]);
					}}
				/>
			)}
			<ZTable<SKUProduct>
				title={renderTableHeader}
				showHeader={false}
				loading={loading}
				scroll={{ scrollToFirstRowOnChange: true }}
				size="small"
				rowKey="id"
				pagination={false}
				rowSelection={rowSelection}
				onRow={handleProductRowEvent}
				dataSource={productSKUs}
				columns={productColumns}
			/>
		</ZDrawer>
	);
};

export default AddProductSKUToQuoteBlockDrawer;
