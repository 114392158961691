import ZButton from "@zomentum/atoms/dist/ZButton";
import React, { useEffect, useState } from "react";
import ZIcon, { Icons } from "@zomentum/atoms/dist/ZIcon";
import FilterMenu from "./ZFilterMenu";
import ZFilterChip from "@zomentum/atoms/dist/ZFilterChip";
import ColumnsSettingsDnD from "./ZColumnsSettingsDnD";
import { CustomViewFilterCriteria } from "@zomentum/contracts/dist/UI";
import { capitalizeAfterUnderscore } from "@zomentum/utils";
import { SalesActivityAutomationActionUpdatableFieldValue } from "@zomentum/contracts/dist/Others/SalesActivity";
import { IFilterSettingsBarProps } from "./interface";
import ZTypography from "@zomentum/atoms/dist/ZTypography";
import ZPopover from "@zomentum/atoms/dist/ZPopover";
import ZTooltip from "@zomentum/atoms/dist/ZTooltip";
import { EIcons } from "@zomentum/atoms/dist/ZIcon/interface";
import "./index.less";

const FilterSettingsBar: React.FC<IFilterSettingsBarProps> = ({
	taxCategories,
	taxRegions,
	...props
}) => {
	const [activeFilters, setActiveFilters] = useState<
		CustomViewFilterCriteria[]
	>(props.activeFilters);

	const [selectedFilter, setSelectedFilter] =
		useState<CustomViewFilterCriteria | null>(null);

	const [columnFilterOpen, setColumnFilterOpen] = useState<boolean>(false);

	const addFilters = (
		filter: CustomViewFilterCriteria,
		selectedIndex: number
	) => {
		if (!selectedFilter) {
			const updatedFilters = activeFilters.concat(filter);
			setActiveFilters(updatedFilters);
			if (props.onFilterSubmit) {
				props.onFilterSubmit(updatedFilters);
			}
			return;
		}

		setSelectedFilter(null);

		const clonedArray = activeFilters.map((currentFilter, index) =>
			index === selectedIndex ? filter : currentFilter
		);
		setActiveFilters(clonedArray);
		if (props.onFilterSubmit) {
			props.onFilterSubmit(clonedArray);
		}
	};

	const deleteFilter = (filter: CustomViewFilterCriteria) => {
		const updatedFilters: CustomViewFilterCriteria[] = activeFilters.filter(
			f => f !== filter
		);
		setActiveFilters([...updatedFilters]);
		if (props.onFilterSubmit) {
			props.onFilterSubmit(updatedFilters);
		}
	};

	const updateActiveFilter = () => setActiveFilters(props.activeFilters);

	const showColumnPopover = () => setColumnFilterOpen(true);

	const hideColumnPopover = () => setColumnFilterOpen(false);

	const updatePopoverVisibility = (value: boolean) =>
		setColumnFilterOpen(value);

	const updateSelectedFilter = (filter: CustomViewFilterCriteria | null) =>
		setSelectedFilter(filter);

	const handlePopoverCloseCallback = () => {
		setSelectedFilter(null);
	};

	useEffect(() => {
		updateActiveFilter();
	});

	const hiddenFilters = ["_id"];

	const cleanedFilters = props.filters.filter(
		filter =>
			filter.field_name && !hiddenFilters.includes(filter.field_name)
	);

	const getDisplayValue = (
		value: SalesActivityAutomationActionUpdatableFieldValue<string> | null
	) => {
		return value?.display_value;
	};

	return (
		<div className="flex justify-between items-center z-filter-settings-bar">
			<div className="flex items-center">
				{props.leftComponents?.map((component, index) => (
					<div key={index} className="mr-4">
						{component}
					</div>
				))}
				{cleanedFilters.length > 0 && (
					<FilterMenu
						filterOptions={cleanedFilters}
						onSubmit={addFilters}
						selectedFilter={null}
						index={props.activeFilters.length + 1}
						closePopoverCallback={handlePopoverCloseCallback}
						taxCategories={taxCategories}
						taxRegions={taxRegions}
					>
						<ZButton
							type="primary"
							ghost
							className={`${
								!activeFilters.length
									? "text-gray-8 border-gray-5 "
									: ""
							} mr-2 shadow-none px-3 py-2`}
							disabled={!cleanedFilters ? true : false}
						>
							<ZIcon
								name={EIcons.FILTER}
								size="sm"
								className="mr-0"
							/>
						</ZButton>
					</FilterMenu>
				)}

				<div className="flex items-center">
					{activeFilters?.slice(0, 2).map((filter, index) => {
						const filterName = `${capitalizeAfterUnderscore(
							filter.field_display_name ?? ""
						)} ${
							filter.filter_operator?.display_value
						} ${filter.values
							.map(value => getDisplayValue(value))
							.join(", ")}`;

						return (
							<ZFilterChip
								className="w-full"
								key={`${filter.field_name}::${
									filter.filter_operator?.id_value
								}::${filter.values
									.map(v => v.id_value)
									.join("-")}`}
								deletable
								content={
									<FilterMenu
										filterOptions={cleanedFilters}
										onSubmit={addFilters}
										selectedFilter={selectedFilter}
										index={index}
										closePopoverCallback={
											handlePopoverCloseCallback
										}
										taxCategories={taxCategories}
										taxRegions={taxRegions}
									>
										<div
											onClick={() =>
												updateSelectedFilter(filter)
											}
											className="max-w-32 cursor-pointer truncate"
										>
											<ZTooltip
												title={filterName}
												placement={"top"}
											>
												<ZTypography.ZText className="whitespace-nowrap">
													{filterName}
												</ZTypography.ZText>
											</ZTooltip>
										</div>
									</FilterMenu>
								}
								onDelete={() => deleteFilter(filter)}
							/>
						);
					})}
				</div>
				{activeFilters.length > 2 && (
					<ZPopover
						placement="bottom"
						size="sm"
						trigger="click"
						overlayClassName="z-filter-settings-bar-more-filters-overlay"
						content={
							<div className="max-w-full overflow-hidden whitespace-pre overflow-ellipsis">
								{activeFilters
									?.slice(2)
									.map((filter, index) => (
										<ZFilterChip
											key={`${filter.field_name}::${
												filter.filter_operator?.id_value
											}::${filter.values
												.map(v => v.id_value)
												.join("-")}`}
											deletable
											className={`m-0 mb-2${
												index ===
												activeFilters.length - 3
													? "mb-0"
													: ""
											}`}
											content={
												<FilterMenu
													filterOptions={
														cleanedFilters
													}
													onSubmit={addFilters}
													selectedFilter={
														selectedFilter
													}
													index={index}
													closePopoverCallback={
														handlePopoverCloseCallback
													}
													taxCategories={
														taxCategories
													}
													taxRegions={taxRegions}
												>
													<div
														onClick={() =>
															updateSelectedFilter(
																filter
															)
														}
														className="cursor-pointer w-11/12 overflow-hidden whitespace-pre overflow-ellipsis"
													>
														<ZTypography.ZText>{`${capitalizeAfterUnderscore(
															filter.field_display_name ??
																""
														)} ${
															filter
																.filter_operator
																?.display_value
														} ${filter.values
															.map(value =>
																getDisplayValue(
																	value
																)
															)
															.join(
																", "
															)}`}</ZTypography.ZText>
													</div>
												</FilterMenu>
											}
											onDelete={() =>
												deleteFilter(filter)
											}
										/>
									))}
							</div>
						}
					>
						<div className="cursor-pointer">
							<ZFilterChip
								deletable={false}
								content={
									<div>
										{"+"}
										{activeFilters?.slice(2).length}
									</div>
								}
							/>
						</div>
					</ZPopover>
				)}
			</div>
			<div className="flex items-center">
				{!!props.settingsColumns?.length && props.onColumnsSubmit && (
					<ZPopover
						content={
							<ColumnsSettingsDnD
								columns={props.settingsColumns}
								onSubmit={props.onColumnsSubmit}
								hidePopover={hideColumnPopover}
							/>
						}
						removeDefaultPadding={true}
						placement="leftTop"
						trigger="click"
						size="sm"
						hideArrow={true}
						destroyTooltipOnHide
						visible={columnFilterOpen}
						onVisibleChange={updatePopoverVisibility}
					>
						<ZButton
							type="primary"
							ghost
							className="text-gray-8 border-gray-5 shadow-none px-3 py-2"
							onClick={showColumnPopover}
						>
							<ZIcon
								name={Icons.SETTING}
								size="sm"
								className="mr-0"
							/>
						</ZButton>
					</ZPopover>
				)}
				{props.rightComponents?.map((component, index) => (
					<div key={index} className="ml-2">
						{component}
					</div>
				))}
			</div>
		</div>
	);
};

export default FilterSettingsBar;
