'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../../node_modules/tslib/tslib.es6.mjs.js');
var index = require('../../services/index.js');

exports.OnboardingStateActionTypes = void 0;
(function (OnboardingStateActionTypes) {
  OnboardingStateActionTypes["UPDATE_CURRENT_GUIDE_PROGRESS"] = "UPDATE_CURRENT_GUIDE_PROGRESS";
  OnboardingStateActionTypes["UPDATE_PROGRESS_INITIALIZATION"] = "UPDATE_PROGRESS_INITIALIZATION";
  OnboardingStateActionTypes["UPDATE_ONBOARDING_MAPS"] = "UPDATE_ONBOARDING_MAPS";
  OnboardingStateActionTypes["CHECKLIST_BAR_DATA_REQUESTED"] = "CHECKLIST_BAR_DATA_REQUESTED";
  OnboardingStateActionTypes["CHECKLIST_BAR_DATA_UPDATE_REQUESTED"] = "CHECKLIST_BAR_DATA_UPDATE_REQUESTED";
  OnboardingStateActionTypes["CHECKLIST_BAR_DATA_SUCCESSFUL"] = "CHECKLIST_BAR_DATA_SUCCESSFUL";
  OnboardingStateActionTypes["CHECKLIST_BAR_DATA_FAILED"] = "CHECKLIST_BAR_DATA_FAILED";
  OnboardingStateActionTypes["UPDATE_MODULE_ORDER"] = "UPDATE_MODULE_ORDER";
  OnboardingStateActionTypes["DUMMY_CLIENT_DATA_REQUESTED"] = "DUMMY_CLIENT_DATA_REQUESTED";
  OnboardingStateActionTypes["DUMMY_CLIENT_DATA_SUCCESSFUL"] = "DUMMY_CLIENT_DATA_SUCCESSFUL";
  OnboardingStateActionTypes["DUMMY_CLIENT_DATA_FAILED"] = "DUMMY_CLIENT";
  OnboardingStateActionTypes["DOCUMENT_TEMPLATE_DATA_REQUESTED"] = "DOCUMENT_TEMPLATE_DATA_REQUESTED";
  OnboardingStateActionTypes["DOCUMENT_TEMPLATE_DATA_SUCCESSFUL"] = "DOCUMENT_TEMPLATE_DATA_SUCCESSFUL";
  OnboardingStateActionTypes["DOCUMENT_TEMPLATE_DATA_FAILED"] = "DOCUMENT_TEMPLATE_DATA_FAILED";
})(exports.OnboardingStateActionTypes || (exports.OnboardingStateActionTypes = {}));
var OnboardingActionCreators = {
  updateActiveProgressData: function updateActiveProgressData(currentGuideProgress) {
    return function (dispatch) {
      var updateActiveProgressDataAction = {
        type: exports.OnboardingStateActionTypes.UPDATE_CURRENT_GUIDE_PROGRESS,
        currentGuideProgress: currentGuideProgress
      };
      return dispatch(updateActiveProgressDataAction);
    };
  },
  updateProgressInitilization: function updateProgressInitilization(isProgressInitialized) {
    return function (dispatch) {
      var updateProgressInitializationAction = {
        type: exports.OnboardingStateActionTypes.UPDATE_PROGRESS_INITIALIZATION,
        isProgressInitialized: isProgressInitialized
      };
      return dispatch(updateProgressInitializationAction);
    };
  },
  updateOnboardingMaps: function updateOnboardingMaps(onboardingMaps) {
    return function (dispatch) {
      var updateOnboardingMapsAction = {
        type: exports.OnboardingStateActionTypes.UPDATE_ONBOARDING_MAPS,
        onboardingMaps: onboardingMaps
      };
      return dispatch(updateOnboardingMapsAction);
    };
  },
  updateModuleOrder: function updateModuleOrder(moduleOrder) {
    return function (dispatch) {
      var updateActiveProgressDataAction = {
        type: exports.OnboardingStateActionTypes.UPDATE_MODULE_ORDER,
        moduleOrder: moduleOrder
      };
      return dispatch(updateActiveProgressDataAction);
    };
  },
  getChecklistBarData: function getChecklistBarData() {
    return function (dispatch) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var getChecklistBarDataRequestedAction, checklistBarDataResponse, getChecklistBarDataSuccessfulAction, error_1, getChecklistBarDatatFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              getChecklistBarDataRequestedAction = {
                type: exports.OnboardingStateActionTypes.CHECKLIST_BAR_DATA_REQUESTED
              };
              dispatch(getChecklistBarDataRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              return [4 /*yield*/, index.getChecklistBarData()];
            case 2:
              checklistBarDataResponse = _a.sent();
              getChecklistBarDataSuccessfulAction = {
                type: exports.OnboardingStateActionTypes.CHECKLIST_BAR_DATA_SUCCESSFUL,
                checkListBarData: checklistBarDataResponse
              };
              dispatch(getChecklistBarDataSuccessfulAction);
              return [2 /*return*/, checklistBarDataResponse];
            case 3:
              error_1 = _a.sent();
              getChecklistBarDatatFailedAction = {
                type: exports.OnboardingStateActionTypes.CHECKLIST_BAR_DATA_FAILED
              };
              dispatch(getChecklistBarDatatFailedAction);
              return [2 /*return*/, Promise.reject(error_1)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  updateCheckListBarData: function updateCheckListBarData(_a) {
    var requestPayload = _a.requestPayload,
      _b = _a.hasAPIUpdate,
      hasAPIUpdate = _b === void 0 ? false : _b;
    return function (dispatch, getState) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var checklistBarDataUpdateRequestedAction, checklistBarDataResponse, progressId, checklistBarDataResponse_1, getChecklistBarDataSuccessfulAction, getChecklistBarDataSuccessfulAction, error_2, getChecklistBarDatatFailedAction;
        var _a, _b;
        return tslib_es6.__generator(this, function (_c) {
          switch (_c.label) {
            case 0:
              checklistBarDataUpdateRequestedAction = {
                type: exports.OnboardingStateActionTypes.CHECKLIST_BAR_DATA_UPDATE_REQUESTED
              };
              dispatch(checklistBarDataUpdateRequestedAction);
              _c.label = 1;
            case 1:
              _c.trys.push([1, 7,, 8]);
              checklistBarDataResponse = (_a = getState().checkListBarData) !== null && _a !== void 0 ? _a : {};
              if (!hasAPIUpdate) return [3 /*break*/, 5];
              progressId = (_b = getState().checkListBarData) === null || _b === void 0 ? void 0 : _b.id;
              if (!!progressId) return [3 /*break*/, 3];
              return [4 /*yield*/, index.getChecklistBarData()];
            case 2:
              checklistBarDataResponse_1 = _c.sent();
              getChecklistBarDataSuccessfulAction = {
                type: exports.OnboardingStateActionTypes.CHECKLIST_BAR_DATA_SUCCESSFUL,
                checkListBarData: checklistBarDataResponse_1
              };
              dispatch(getChecklistBarDataSuccessfulAction);
              progressId = checklistBarDataResponse_1.id;
              _c.label = 3;
            case 3:
              return [4 /*yield*/, index.updateCheckListBarData(progressId, requestPayload)];
            case 4:
              checklistBarDataResponse = _c.sent();
              return [3 /*break*/, 6];
            case 5:
              if (checklistBarDataResponse) {
                checklistBarDataResponse = tslib_es6.__assign(tslib_es6.__assign({}, checklistBarDataResponse), requestPayload);
              }
              _c.label = 6;
            case 6:
              if (checklistBarDataResponse) {
                getChecklistBarDataSuccessfulAction = {
                  type: exports.OnboardingStateActionTypes.CHECKLIST_BAR_DATA_SUCCESSFUL,
                  checkListBarData: checklistBarDataResponse
                };
                dispatch(getChecklistBarDataSuccessfulAction);
              }
              return [2 /*return*/, checklistBarDataResponse];
            case 7:
              error_2 = _c.sent();
              getChecklistBarDatatFailedAction = {
                type: exports.OnboardingStateActionTypes.CHECKLIST_BAR_DATA_FAILED
              };
              dispatch(getChecklistBarDatatFailedAction);
              return [2 /*return*/, Promise.reject(error_2)];
            case 8:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  getDummyClientData: function getDummyClientData() {
    return function (dispatch, getState) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var getDummyClientDataRequestedAction, oldClientDetail, clientDetail, getDummyClientDataSuccessfulAction, error_3, getDummyClientDataFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              getDummyClientDataRequestedAction = {
                type: exports.OnboardingStateActionTypes.DUMMY_CLIENT_DATA_REQUESTED
              };
              dispatch(getDummyClientDataRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              oldClientDetail = getState().clientDetail;
              if (oldClientDetail) {
                return [2 /*return*/, oldClientDetail];
              }
              return [4 /*yield*/, index.getDummyClientData()];
            case 2:
              clientDetail = _a.sent().data[0];
              getDummyClientDataSuccessfulAction = {
                type: exports.OnboardingStateActionTypes.DUMMY_CLIENT_DATA_SUCCESSFUL,
                clientDetail: clientDetail
              };
              dispatch(getDummyClientDataSuccessfulAction);
              return [2 /*return*/, clientDetail];
            case 3:
              error_3 = _a.sent();
              getDummyClientDataFailedAction = {
                type: exports.OnboardingStateActionTypes.DUMMY_CLIENT_DATA_FAILED
              };
              dispatch(getDummyClientDataFailedAction);
              return [2 /*return*/, Promise.reject(error_3)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  },

  getDocumentTemplateData: function getDocumentTemplateData() {
    return function (dispatch, getState) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var documentTemplateDataRequestedAction, oldDocumentTemplate, documentTemplate, documentTemplateDataSuccessfulAction, error_4, documentTemplateDataFailedAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              documentTemplateDataRequestedAction = {
                type: exports.OnboardingStateActionTypes.DOCUMENT_TEMPLATE_DATA_REQUESTED
              };
              dispatch(documentTemplateDataRequestedAction);
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3,, 4]);
              oldDocumentTemplate = getState().documentTemplate;
              if (oldDocumentTemplate) {
                return [2 /*return*/, oldDocumentTemplate];
              }
              return [4 /*yield*/, index.getDocumentTemplateData()];
            case 2:
              documentTemplate = _a.sent();
              documentTemplateDataSuccessfulAction = {
                type: exports.OnboardingStateActionTypes.DOCUMENT_TEMPLATE_DATA_SUCCESSFUL,
                documentTemplate: documentTemplate
              };
              dispatch(documentTemplateDataSuccessfulAction);
              return [2 /*return*/, documentTemplate];
            case 3:
              error_4 = _a.sent();
              documentTemplateDataFailedAction = {
                type: exports.OnboardingStateActionTypes.DOCUMENT_TEMPLATE_DATA_FAILED
              };
              dispatch(documentTemplateDataFailedAction);
              return [2 /*return*/, Promise.reject(error_4)];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    };
  }
};

var OnboardingActionCreators$1 = OnboardingActionCreators;

exports.OnboardingActionCreators = OnboardingActionCreators;
exports["default"] = OnboardingActionCreators$1;
