'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var React = require('react');

var usePrevious = function (value) {
  var ref = React.useRef();
  React.useEffect(function () {
    ref.current = value;
  });
  return ref.current;
};
var usePrevious$1 = usePrevious;

exports["default"] = usePrevious$1;
