import React, { useState } from "react";

import { ReactComponent as EmailTrackingIcon } from "../../../../../Assets/Others/EmailThread/EmailTracking.svg";
import { Dropdown, Menu, Checkbox } from "antd";
import { EmailTracking } from "@zomentum/contracts/dist/Others/EmailThread";
import { EFontWeights } from "@zomentum/atoms/dist/ZTypography/interface";
import { ZTypography } from "@zomentum/atoms";

const TrackEmail = ({
	updateEmailTracking,
	isTrackingEnabled,
	value
}: {
	updateEmailTracking: (emailTracking: EmailTracking) => void;
	isTrackingEnabled: boolean;
	value: EmailTracking | null;
}) => {
	const [isVisible, setisVisible] = useState(false);

	const [open, setOpen] = useState<boolean>(value?.opens || false);
	const [click, setClick] = useState<boolean>(value?.links || false);
	const [reply, setReply] = useState<boolean>(value?.thread_replies || false);

	const handleMoreOptionsDropdownChange = (value: boolean) => {
		setisVisible(value);
		if (!value) {
			const emailTracking: EmailTracking = new EmailTracking();
			emailTracking.links = click;
			emailTracking.opens = open;
			emailTracking.thread_replies = reply;
			updateEmailTracking(emailTracking);
		}
	};

	const handleOpenChange = () => {
		setOpen(!open);
	};
	const handleClickChange = () => {
		setClick(!click);
	};
	const handleReplyChange = () => {
		setReply(!reply);
	};

	return (
		<Dropdown
			visible={isVisible}
			onVisibleChange={handleMoreOptionsDropdownChange}
			trigger={["click"]}
			overlayClassName="email-tracking-dropdown"
			overlay={
				<Menu>
					<Menu.Item key="0">
						<Checkbox onChange={handleOpenChange} checked={open}>
							Opened
						</Checkbox>
					</Menu.Item>
					<Menu.Item key="1">
						<Checkbox onChange={handleClickChange} checked={click}>
							Clicked
						</Checkbox>
					</Menu.Item>
					<Menu.Item key="2">
						<Checkbox onChange={handleReplyChange} checked={reply}>
							Replied
						</Checkbox>
					</Menu.Item>
				</Menu>
			}
		>
			<div
				className={`email-tracking side-padding-15px ${
					isTrackingEnabled ? "active" : ""
				}`}
			>
				<EmailTrackingIcon />{" "}
				<ZTypography.ZText weight={EFontWeights.MEDIUM}>
					Track
				</ZTypography.ZText>
			</div>
		</Dropdown>
	);
};

export default TrackEmail;
