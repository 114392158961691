import { ZomentumRoutes } from "@zomentum/contracts";
import { EIcons } from "@zomentum/atoms/dist/ZIcon/interface";
import { ILeftMenuItem } from "./interface";

export default (pathname: string): Array<ILeftMenuItem> => {
	const routes: ILeftMenuItem[] = [
		{
			route: ZomentumRoutes.VendorDashboard,
			selected: pathname.includes(ZomentumRoutes.VendorDashboard)
				? true
				: false,
			title: "Dashboard",
			icon: EIcons.VENDORHOME,
			permission: null
		},
		{
			route: ZomentumRoutes.VendorOpportunityList,
			selected: pathname.includes(ZomentumRoutes.VendorOpportunityList)
				? true
				: false,
			title: "Opportunities",
			icon: EIcons.VENDOROPPORTUNITIES,
			permission: null
		},
		{
			route: ZomentumRoutes.VendorTemplates,
			selected: pathname.includes(ZomentumRoutes.VendorTemplates)
				? true
				: false,
			title: "Templates",
			icon: EIcons.VENDORTEMPLATES,
			permission: null
		},
		{
			route: ZomentumRoutes.VendorPartnerList,
			selected: pathname.includes(ZomentumRoutes.VendorPartnerList)
				? true
				: false,
			title: "Partners",
			icon: EIcons.VENDORPARTNERS,
			permission: null
		},
		{
			route: ZomentumRoutes.VendorClientList,
			selected: pathname.includes(ZomentumRoutes.VendorClientList)
				? true
				: false,
			title: "Clients",
			icon: EIcons.VENDORCLIENTS,
			permission: null
		},
		{
			route: ZomentumRoutes.FinancePage,
			selected: pathname.includes(ZomentumRoutes.FinancePage)
				? true
				: false,
			title: "Finance",
			icon: EIcons.FINANCE,
			permission: null
		},
		{
			route: ZomentumRoutes.VendorMarketplaceSKUs,
			selected: pathname.includes(ZomentumRoutes.VendorMarketplaceSKUs)
				? true
				: false,
			title: "Products",
			icon: EIcons.VENDORPRODUCTS,
			permission: null
		},
		{
			route: ZomentumRoutes.VendorResources,
			selected: pathname.includes(ZomentumRoutes.VendorResources)
				? true
				: false,
			title: "Resources",
			icon: EIcons.VENDORRESOURCES,
			permission: null
		},
		{
			route: ZomentumRoutes.SalesActivityAutomationsList,
			selected: pathname.includes(
				ZomentumRoutes.SalesActivityAutomationsList
			),
			title: "Sales Automation",
			icon: EIcons.NETWORK,
			permission: null
		},
		{
			route: ZomentumRoutes.VendorSettings,
			selected: pathname.includes(ZomentumRoutes.VendorSettings)
				? true
				: false,
			title: "Settings",
			icon: EIcons.VENDORSETTINGS,
			permission: null
		}
	];
	return routes;
};
