import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Skeleton from "antd/lib/skeleton";
import React from "react";
import {
	CustomField,
	CustomFieldType,
	DatePatterns
} from "@zomentum/contracts/dist/Settings";
import { capitalizeFirstLetter } from "@zomentum/contracts/dist/Utils";
import ZTypography from "@zomentum/atoms/dist/ZTypography";
import { customDateFormatter } from "@zomentum/utils";
import { TCustomFieldsProps, TSingleCustomFieldsProps } from "./interface";
import ZTooltip from "@zomentum/atoms/dist/ZTooltip";

const { ZText } = ZTypography;

const formatCustomFieldValue = (
	customField: CustomField,
	datePattern?: string
) => {
	if (customField.field_type === CustomFieldType.DateField) {
		return customField.values
			.map(value =>
				customDateFormatter(
					new Date(value),
					datePattern ?? DatePatterns.DD_HYPHEN_MMM_COMMA_YYYY
				)
			)
			.join(", ");
	}

	if (customField.field_type === CustomFieldType.DropdownFieldWithKeyValue) {
		const value = customField.allowed_dropdown_key_values?.find(
			field => field.key === customField.values[0]
		);

		return value?.value ?? "--";
	}

	return customField.values
		.map(value => capitalizeFirstLetter(value))
		.join(", ");
};

export const ZSingleCustomField: React.FC<TSingleCustomFieldsProps> = props => {
	const customValues = props.customField?.values?.filter(_ => !!_.trim());
	return (
		<>
			<Col className={`truncate ${props.className}`} {...props.keyFlex}>
				<ZTooltip
					title={props.customField.display_name}
					placement="top"
					showtooltip={props.customField.display_name?.length >= 20}
				>
					<ZText>
						{capitalizeFirstLetter(props.customField.display_name)}
					</ZText>
				</ZTooltip>
			</Col>
			<Col {...props.valueFlex}>
				<Skeleton
					loading={props.isLoading}
					active
					title={false}
					paragraph={{ rows: 1, width: "100%" }}
				>
					<ZText>
						{customValues?.length
							? formatCustomFieldValue(
									props.customField,
									props.datePattern
							  )
							: "--"}
					</ZText>
				</Skeleton>
			</Col>
		</>
	);
};

const ZCustomFields: React.FC<TCustomFieldsProps> = ({
	customFields,
	isLoading,
	keyFlex,
	valueFlex,
	gutter,
	datePattern,
	rowRequired = true,
	className
}) => {
	const customFieldsOutput = customFields.map(customField => {
		return rowRequired ? (
			<Row className="mb-3" key={customField.id} gutter={gutter}>
				<ZSingleCustomField
					keyFlex={keyFlex}
					customField={customField}
					isLoading={isLoading}
					valueFlex={valueFlex}
					datePattern={datePattern}
				/>
			</Row>
		) : (
			<ZSingleCustomField
				key={customField.id}
				keyFlex={keyFlex}
				customField={customField}
				isLoading={isLoading}
				valueFlex={valueFlex}
				datePattern={datePattern}
				className={className}
			/>
		);
	});
	return <>{customFieldsOutput}</>;
};

export default ZCustomFields;
