'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index = require('../User/index.js');

var VendorPartnerTier = /** @class */function () {
  function VendorPartnerTier() {
    this.name = "";
    this.id = "";
    this.owner_user = new index.VendorUserData();
    this.created = new Date();
    this.partners_count = 0;
  }
  return VendorPartnerTier;
}();
var VendorPartnerTiersResponse = /** @class */function () {
  function VendorPartnerTiersResponse() {
    this.data = new Array();
    this.filtered_count_map = null;
  }
  return VendorPartnerTiersResponse;
}();

exports.VendorPartnerTier = VendorPartnerTier;
exports.VendorPartnerTiersResponse = VendorPartnerTiersResponse;
