/* eslint-disable @typescript-eslint/no-empty-interface */
import { Dispatch, Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";

import NotificationFeedStream from "getstream";

import Notification from "antd/lib/notification";

import { DefaultErrorMessage } from "@zomentum/contracts";
import { GlobalState } from "@/Reducers/interface";
import {
	NotificationResponse,
	NotificationActivity
} from "@zomentum/contracts/dist/Others/NotificationFeed";
import { handleNotificationMenuRedirect } from "@/V2Utils/Notifications";

export enum NotificationFeedActionTypes {
	SHOW_NOTIFICATION_FEED_LOADER = "SHOW_NOTIFICATION_FEED_LOADER",
	HIDE_NOTIFICATION_FEED_LOADER = "HIDE_NOTIFICATION_FEED_LOADER",
	NOTIFICATION_FEED_REQUESTED = "NOTIFICATION_FEED_REQUESTED",
	NOTIFICATION_FEED_SUCCESSFUL = "NOTIFICATION_FEED_SUCCESSFUL",
	NOTIFICATION_FEED_FAILED = "NOTIFICATION_FEED_FAILED",
	SUBSCRIBE_NOTIFICATION_FEED_ACTIVITY_REQUESTED = "SUBSCRIBE_NOTIFICATION_FEED_ACTIVITY_REQUESTED",
	SUBSCRIBE_NOTIFICATION_FEED_ACTIVITY_SUCCESSFUL = "SUBSCRIBE_NOTIFICATION_FEED_ACTIVITY_SUCCESSFUL",
	SUBSCRIBE_NOTIFICATION_FEED_ACTIVITY_FAILED = "SUBSCRIBE_NOTIFICATION_FEED_ACTIVITY_FAILED",
	RESET_NOTIFICATION_FEED = "RESET_NOTIFICATION_FEED",
	ADD_NOTIFICATION_FEED_SUBSCRIPTION_ACTIVITY = "ADD_NOTIFICATION_FEED_SUBSCRIPTION_ACTIVITY",

	DELETE_NOTIFICATION_FEED_ACTIVITY_REQUESTED = "DELETE_NOTIFICATION_FEED_ACTIVITY_REQUESTED",
	DELETE_NOTIFICATION_FEED_ACTIVITY_SUCCESSFUL = "DELETE_NOTIFICATION_FEED_ACTIVITY_SUCCESSFUL",
	DELETE_NOTIFICATION_FEED_ACTIVITY_FAILED = "DELETE_NOTIFICATION_FEED_ACTIVITY_FAILED",

	BULK_DELETE_NOTIFICATION_FEED_ACTIVITY_REQUESTED = "BULK_DELETE_NOTIFICATION_FEED_ACTIVITY_REQUESTED",
	BULK_DELETE_NOTIFICATION_FEED_ACTIVITY_SUCCESSFUL = "BULK_DELETE_NOTIFICATION_FEED_ACTIVITY_SUCCESSFUL",
	BULK_DELETE_NOTIFICATION_FEED_ACTIVITY_FAILED = "BULK_DELETE_NOTIFICATION_FEED_ACTIVITY_FAILED",

	MARK_NOTIFICATION_FEED_ACTIVITY_AS_READ_REQUESTED = "MARK_NOTIFICATION_FEED_ACTIVITY_AS_READ_REQUESTED",
	MARK_NOTIFICATION_FEED_ACTIVITY_AS_READ_SUCCESSFUL = "MARK_NOTIFICATION_FEED_ACTIVITY_AS_READ_SUCCESSFUL",
	MARK_NOTIFICATION_FEED_ACTIVITY_AS_READ_FAILED = "MARK_NOTIFICATION_FEED_ACTIVITY_AS_READ_FAILED",

	MARK_ALL_NOTIFICATION_FEED_ACTIVITIES_AS_READ_REQUESTED = "MARK_ALL_NOTIFICATION_FEED_ACTIVITIES_AS_READ_REQUESTED",
	MARK_ALL_NOTIFICATION_FEED_ACTIVITIES_AS_READ_SUCCESSFUL = "MARK_ALL_NOTIFICATION_FEED_ACTIVITIES_AS_READ_SUCCESSFUL",
	MARK_ALL_NOTIFICATION_FEED_ACTIVITIES_AS_READ_FAILED = "MARK_ALL_NOTIFICATION_FEED_ACTIVITIES_AS_READ_FAILED"
}

export interface ShowNotificationFeedLoaderAction
	extends Action<NotificationFeedActionTypes.SHOW_NOTIFICATION_FEED_LOADER> {}
export interface HideNotificationFeedLoaderAction
	extends Action<NotificationFeedActionTypes.HIDE_NOTIFICATION_FEED_LOADER> {}
export interface NotificationFeedRequestedAction
	extends Action<NotificationFeedActionTypes.NOTIFICATION_FEED_REQUESTED> {}
export interface NotificationFeedSuccessfulAction
	extends Action<NotificationFeedActionTypes.NOTIFICATION_FEED_SUCCESSFUL> {
	notificationFeedActivityList: NotificationActivity[];
}
export interface NotificationFeedFailedAction
	extends Action<NotificationFeedActionTypes.NOTIFICATION_FEED_FAILED> {}
export interface SubscribeNotificationFeedActivityRequestedAction
	extends Action<NotificationFeedActionTypes.SUBSCRIBE_NOTIFICATION_FEED_ACTIVITY_REQUESTED> {}
export interface SubscribeNotificationFeedActivitySuccessfulAction
	extends Action<NotificationFeedActionTypes.SUBSCRIBE_NOTIFICATION_FEED_ACTIVITY_SUCCESSFUL> {}
export interface SubscribeNotificationFeedActivityFailedAction
	extends Action<NotificationFeedActionTypes.SUBSCRIBE_NOTIFICATION_FEED_ACTIVITY_FAILED> {}
export interface ResetNotificationFeedAction
	extends Action<NotificationFeedActionTypes.RESET_NOTIFICATION_FEED> {}
export interface DeleteNotificationFeedActivityRequestedAction
	extends Action<NotificationFeedActionTypes.DELETE_NOTIFICATION_FEED_ACTIVITY_REQUESTED> {}

export interface DeleteNotificationFeedActivitySuccessfulAction
	extends Action<NotificationFeedActionTypes.DELETE_NOTIFICATION_FEED_ACTIVITY_SUCCESSFUL> {
	deleteNotificationFeedActivity: NotificationActivity;
}

export interface DeleteNotificationFeedActivityFailedAction
	extends Action<NotificationFeedActionTypes.DELETE_NOTIFICATION_FEED_ACTIVITY_FAILED> {}

export interface BulkDeleteNotificationFeedActivitiesRequestedAction
	extends Action<NotificationFeedActionTypes.BULK_DELETE_NOTIFICATION_FEED_ACTIVITY_REQUESTED> {}

export interface BulkDeleteNotificationFeedActivitiesSuccessfulAction
	extends Action<NotificationFeedActionTypes.BULK_DELETE_NOTIFICATION_FEED_ACTIVITY_SUCCESSFUL> {
	foreignId: string;
}
export interface BulkDeleteNotificationFeedActivitiesFailedAction
	extends Action<NotificationFeedActionTypes.BULK_DELETE_NOTIFICATION_FEED_ACTIVITY_FAILED> {}

export type MarkNotificationFeedActivityAsReadRequestedAction =
	Action<NotificationFeedActionTypes.MARK_NOTIFICATION_FEED_ACTIVITY_AS_READ_REQUESTED>;

export interface MarkNotificationFeedActivityAsReadSuccessfulAction
	extends Action<NotificationFeedActionTypes.MARK_NOTIFICATION_FEED_ACTIVITY_AS_READ_SUCCESSFUL> {
	updatedNotificationFeedActivityList: NotificationActivity[];
}

export type MarkNotificationFeedActivityAsReadFailedAction =
	Action<NotificationFeedActionTypes.MARK_NOTIFICATION_FEED_ACTIVITY_AS_READ_FAILED>;

export type MarkAllNotificationFeedActivitiesAsReadRequestedAction =
	Action<NotificationFeedActionTypes.MARK_ALL_NOTIFICATION_FEED_ACTIVITIES_AS_READ_REQUESTED>;

export interface MarkAllNotificationFeedActivitiesAsReadSuccessfulAction
	extends Action<NotificationFeedActionTypes.MARK_ALL_NOTIFICATION_FEED_ACTIVITIES_AS_READ_SUCCESSFUL> {
	updatedNotificationFeedActivityList: NotificationActivity[];
}

export type MarkAllNotificationFeedActivitiesAsReadFailedAction =
	Action<NotificationFeedActionTypes.MARK_ALL_NOTIFICATION_FEED_ACTIVITIES_AS_READ_FAILED>;

export interface AddNotificationFeedActivitySubscriptionAction
	extends Action<NotificationFeedActionTypes.ADD_NOTIFICATION_FEED_SUBSCRIPTION_ACTIVITY> {
	addNotificationFeedSubscriptionActivity: NotificationActivity;
}

export type NotificationFeedActions =
	| ShowNotificationFeedLoaderAction
	| HideNotificationFeedLoaderAction
	| NotificationFeedRequestedAction
	| NotificationFeedSuccessfulAction
	| NotificationFeedFailedAction
	| SubscribeNotificationFeedActivityRequestedAction
	| SubscribeNotificationFeedActivitySuccessfulAction
	| SubscribeNotificationFeedActivityFailedAction
	| ResetNotificationFeedAction
	| DeleteNotificationFeedActivityRequestedAction
	| DeleteNotificationFeedActivitySuccessfulAction
	| DeleteNotificationFeedActivityFailedAction
	| BulkDeleteNotificationFeedActivitiesRequestedAction
	| BulkDeleteNotificationFeedActivitiesSuccessfulAction
	| BulkDeleteNotificationFeedActivitiesFailedAction
	| AddNotificationFeedActivitySubscriptionAction
	| MarkNotificationFeedActivityAsReadRequestedAction
	| MarkNotificationFeedActivityAsReadSuccessfulAction
	| MarkNotificationFeedActivityAsReadFailedAction
	| MarkAllNotificationFeedActivitiesAsReadRequestedAction
	| MarkAllNotificationFeedActivitiesAsReadSuccessfulAction
	| MarkAllNotificationFeedActivitiesAsReadFailedAction;

export interface NotificationFeedDispatch {
	showNotificationFeedLoader: ActionCreator<
		ThunkAction<
			ShowNotificationFeedLoaderAction,
			GlobalState,
			null,
			ShowNotificationFeedLoaderAction
		>
	>;

	hideNotificationFeedLoader: ActionCreator<
		ThunkAction<
			HideNotificationFeedLoaderAction,
			GlobalState,
			null,
			HideNotificationFeedLoaderAction
		>
	>;

	getNotificationFeed: ActionCreator<
		ThunkAction<
			Promise<NotificationFeedSuccessfulAction>,
			GlobalState,
			null,
			NotificationFeedSuccessfulAction
		>
	>;

	subscribeNotificationFeedActivity: ActionCreator<
		ThunkAction<
			Promise<SubscribeNotificationFeedActivitySuccessfulAction>,
			GlobalState,
			NotificationActivity,
			SubscribeNotificationFeedActivitySuccessfulAction
		>
	>;

	resetNotificationFeed: ActionCreator<
		ThunkAction<
			ResetNotificationFeedAction,
			GlobalState,
			null,
			ResetNotificationFeedAction
		>
	>;

	deleteNotificationFeedActivity: ActionCreator<
		ThunkAction<
			Promise<DeleteNotificationFeedActivitySuccessfulAction>,
			GlobalState,
			NotificationActivity,
			DeleteNotificationFeedActivitySuccessfulAction
		>
	>;

	bulkDeleteNotificationFeedActivities: ActionCreator<
		ThunkAction<
			Promise<BulkDeleteNotificationFeedActivitiesSuccessfulAction>,
			GlobalState,
			string,
			BulkDeleteNotificationFeedActivitiesSuccessfulAction
		>
	>;

	markNotificationFeedActivityAsRead: ActionCreator<
		ThunkAction<
			Promise<MarkNotificationFeedActivityAsReadSuccessfulAction>,
			GlobalState,
			NotificationActivity,
			MarkNotificationFeedActivityAsReadSuccessfulAction
		>
	>;

	markAllNotificationFeedActivitiesAsRead: ActionCreator<
		ThunkAction<
			Promise<MarkAllNotificationFeedActivitiesAsReadSuccessfulAction>,
			GlobalState,
			null,
			MarkAllNotificationFeedActivitiesAsReadSuccessfulAction
		>
	>;
}

export const NotificationFeedActionCreators: NotificationFeedDispatch = {
	showNotificationFeedLoader: () => {
		return (dispatch: Dispatch): ShowNotificationFeedLoaderAction => {
			const showNotificationFeedLoaderAction: ShowNotificationFeedLoaderAction =
				{
					type: NotificationFeedActionTypes.SHOW_NOTIFICATION_FEED_LOADER
				};
			return dispatch(showNotificationFeedLoaderAction);
		};
	},

	hideNotificationFeedLoader: () => {
		return (dispatch: Dispatch): HideNotificationFeedLoaderAction => {
			const hideNotificationFeedLoaderAction: HideNotificationFeedLoaderAction =
				{
					type: NotificationFeedActionTypes.HIDE_NOTIFICATION_FEED_LOADER
				};
			return dispatch(hideNotificationFeedLoaderAction);
		};
	},

	getNotificationFeed: () => {
		return async (
			dispatch: Dispatch,
			getState: () => GlobalState
		): Promise<NotificationFeedSuccessfulAction> => {
			const notificationFeedRequestedAction: NotificationFeedRequestedAction =
				{
					type: NotificationFeedActionTypes.NOTIFICATION_FEED_REQUESTED
				};

			dispatch(notificationFeedRequestedAction);

			try {
				const globalState = getState();
				const notificationConfig =
					globalState.vendorUser.vendorUserConfiguration;

				const {
					api_key: apiKey,
					token,
					app_id: appId
				} = notificationConfig;

				const notificationFeedStreamConnection =
					NotificationFeedStream.connect(apiKey, token, appId);

				if (!apiKey || !apiKey.length) {
					throw new Error(
						"User does not have an apiKey in his/her config"
					);
				}

				if (!globalState.vendorUser.vendorUserData.id?.length) {
					console.error(
						globalState.vendorUser.vendorUserData,
						"GetStream error when user id is not present"
					);
				}

				const notificationFeed = notificationFeedStreamConnection.feed(
					"notification",
					globalState.vendorUser.vendorUserData.id,
					token
				);

				const notificationFeedData: any = await notificationFeed.get({
					limit: 100
				});
				const notificationFeedResponse: NotificationResponse[] =
					notificationFeedData.results;

				// getting a flat array of activities
				const notificationFeedActivityList = notificationFeedResponse
					.map(notification => {
						return notification.activities.map(activity => ({
							...activity,
							notificationId: notification.id,
							isRead: notification.is_read,
							isSeen: notification.is_seen
						}));
					})
					.filter(a => !!a)
					.reduce((acc, val) => acc.concat(val), []);

				const notificationFeedSuccessfulAction: NotificationFeedSuccessfulAction =
					{
						type: NotificationFeedActionTypes.NOTIFICATION_FEED_SUCCESSFUL,
						notificationFeedActivityList
					};
				return dispatch(notificationFeedSuccessfulAction);
			} catch (error) {
				const notificationFeedFailedAction: NotificationFeedFailedAction =
					{
						type: NotificationFeedActionTypes.NOTIFICATION_FEED_FAILED
					};
				dispatch(notificationFeedFailedAction);

				return Promise.reject(error);
			}
		};
	},

	subscribeNotificationFeedActivity: () => {
		return async (
			dispatch: Dispatch,
			getState: () => GlobalState
		): Promise<SubscribeNotificationFeedActivitySuccessfulAction> => {
			const subscribeNotificationFeedActivityRequestedAction: SubscribeNotificationFeedActivityRequestedAction =
				{
					type: NotificationFeedActionTypes.SUBSCRIBE_NOTIFICATION_FEED_ACTIVITY_REQUESTED
				};
			dispatch(subscribeNotificationFeedActivityRequestedAction);
			try {
				const globalState = getState();
				const {
					api_key: apiKey,
					token,
					app_id: appId
				} = globalState.vendorUser.vendorUserConfiguration;
				if (!globalState.vendorUser.vendorUserData.id?.length) {
					console.error(
						globalState.vendorUser.vendorUserData,
						"GetStream error when user id is not present"
					);
				}
				if (apiKey && apiKey.length > 0) {
					const notificationFeedStreamConnection =
						NotificationFeedStream.connect(apiKey, token, appId);
					const notificationFeed =
						notificationFeedStreamConnection.feed(
							"notification",
							globalState.vendorUser.vendorUserData.id,
							token
						);

					await notificationFeed.subscribe(
						notificationFeedResponse => {
							if (
								notificationFeedResponse &&
								notificationFeedResponse.new &&
								notificationFeedResponse.new.length > 0
							) {
								const addNotificationFeedSubscriptionActivityList: NotificationActivity[] =
									notificationFeedResponse.new;
								addNotificationFeedSubscriptionActivityList.forEach(
									addNotificationFeedSubscriptionActivity => {
										const addNotificationFeedActivitySubscriptionAction: AddNotificationFeedActivitySubscriptionAction =
											{
												type: NotificationFeedActionTypes.ADD_NOTIFICATION_FEED_SUBSCRIPTION_ACTIVITY,
												addNotificationFeedSubscriptionActivity
											};
										dispatch(
											addNotificationFeedActivitySubscriptionAction
										);

										Notification.info({
											key: addNotificationFeedSubscriptionActivity.id,
											message:
												!!addNotificationFeedSubscriptionActivity
													.title.length &&
												!!addNotificationFeedSubscriptionActivity
													.actor.length
													? `${addNotificationFeedSubscriptionActivity.title} from ${addNotificationFeedSubscriptionActivity.actor}`
													: "Notification",

											description:
												addNotificationFeedSubscriptionActivity.message
													? addNotificationFeedSubscriptionActivity.message
													: "You have a new notification",
											placement: "bottomRight",
											duration: 10,
											onClick: () => {
												Notification.close(
													addNotificationFeedSubscriptionActivity.id
												);
												addNotificationFeedSubscriptionActivity.redirect_url !==
													"" &&
													handleNotificationMenuRedirect(
														addNotificationFeedSubscriptionActivity.redirect_url
													);
											}
										});
									}
								);
							}
						}
					);
					const subscribeNotificationFeedActivitySuccessfulAction: SubscribeNotificationFeedActivitySuccessfulAction =
						{
							type: NotificationFeedActionTypes.SUBSCRIBE_NOTIFICATION_FEED_ACTIVITY_SUCCESSFUL
						};
					return dispatch(
						subscribeNotificationFeedActivitySuccessfulAction
					);
				} else {
					const subscribeNotificationFeedActivityFailedAction: SubscribeNotificationFeedActivityFailedAction =
						{
							type: NotificationFeedActionTypes.SUBSCRIBE_NOTIFICATION_FEED_ACTIVITY_FAILED
						};
					dispatch(subscribeNotificationFeedActivityFailedAction);
					return Promise.reject(DefaultErrorMessage);
				}
			} catch (error) {
				const subscribeNotificationFeedActivityFailedAction: SubscribeNotificationFeedActivityFailedAction =
					{
						type: NotificationFeedActionTypes.SUBSCRIBE_NOTIFICATION_FEED_ACTIVITY_FAILED
					};
				dispatch(subscribeNotificationFeedActivityFailedAction);

				return Promise.reject(error);
			}
		};
	},

	resetNotificationFeed: () => {
		return (dispatch: Dispatch): ResetNotificationFeedAction => {
			const resetNotificationFeedAction: ResetNotificationFeedAction = {
				type: NotificationFeedActionTypes.RESET_NOTIFICATION_FEED
			};
			return dispatch(resetNotificationFeedAction);
		};
	},

	deleteNotificationFeedActivity: (
		deleteNotificationFeedActivityRequest: NotificationActivity
	) => {
		return async (
			dispatch: Dispatch,
			getState: () => GlobalState
		): Promise<DeleteNotificationFeedActivitySuccessfulAction> => {
			const deleteNotificationFeedActivityRequestedAction: DeleteNotificationFeedActivityRequestedAction =
				{
					type: NotificationFeedActionTypes.DELETE_NOTIFICATION_FEED_ACTIVITY_REQUESTED
				};

			dispatch(deleteNotificationFeedActivityRequestedAction);

			try {
				const globalState = getState();
				const {
					api_key: apiKey,
					token,
					app_id: appId
				} = globalState.vendorUser.vendorUserConfiguration;

				if (!globalState.vendorUser.vendorUserData.id?.length) {
					console.error(
						globalState.vendorUser.vendorUserData,
						"GetStream error when user id is not present"
					);
				}

				if (apiKey && apiKey.length > 0) {
					const notificationFeedStreamConnection =
						NotificationFeedStream.connect(apiKey, token, appId);
					const notificationFeed =
						notificationFeedStreamConnection.feed(
							"notification",
							globalState.vendorUser.vendorUserData.id,
							token
						);

					await notificationFeed.removeActivity(
						deleteNotificationFeedActivityRequest.id
					);

					const deleteNotificationFeedActivitySuccessfulAction: DeleteNotificationFeedActivitySuccessfulAction =
						{
							type: NotificationFeedActionTypes.DELETE_NOTIFICATION_FEED_ACTIVITY_SUCCESSFUL,
							deleteNotificationFeedActivity:
								deleteNotificationFeedActivityRequest
						};

					return dispatch(
						deleteNotificationFeedActivitySuccessfulAction
					);
				} else {
					const deleteNotificationFeedActivityFailedAction: DeleteNotificationFeedActivityFailedAction =
						{
							type: NotificationFeedActionTypes.DELETE_NOTIFICATION_FEED_ACTIVITY_FAILED
						};

					dispatch(deleteNotificationFeedActivityFailedAction);
					return Promise.reject(DefaultErrorMessage);
				}
			} catch (error) {
				const deleteNotificationFeedActivityFailedAction: DeleteNotificationFeedActivityFailedAction =
					{
						type: NotificationFeedActionTypes.DELETE_NOTIFICATION_FEED_ACTIVITY_FAILED
					};
				dispatch(deleteNotificationFeedActivityFailedAction);

				return Promise.reject(error);
			}
		};
	},

	bulkDeleteNotificationFeedActivities: (foreignId: string) => {
		return async (
			dispatch: Dispatch,
			getState: () => GlobalState
		): Promise<BulkDeleteNotificationFeedActivitiesSuccessfulAction> => {
			const bulkDeleteNotificationFeedActivitiesRequestedAction: BulkDeleteNotificationFeedActivitiesRequestedAction =
				{
					type: NotificationFeedActionTypes.BULK_DELETE_NOTIFICATION_FEED_ACTIVITY_REQUESTED
				};

			dispatch(bulkDeleteNotificationFeedActivitiesRequestedAction);

			try {
				const globalState = getState();
				const {
					api_key: apiKey,
					token,
					app_id: appId
				} = globalState.vendorUser.vendorUserConfiguration;

				if (!globalState.vendorUser.vendorUserData.id?.length) {
					console.error(
						globalState.vendorUser.vendorUserData,
						"GetStream error when user id is not present"
					);
				}

				if (apiKey && apiKey.length > 0) {
					const notificationFeedStreamConnection =
						NotificationFeedStream.connect(apiKey, token, appId);
					const notificationFeed =
						notificationFeedStreamConnection.feed(
							"notification",
							globalState.vendorUser.vendorUserData.id,
							token
						);

					await notificationFeed.removeActivity({ foreignId });

					const bulkDeleteNotificationFeedActivitiesSuccessfulAction: BulkDeleteNotificationFeedActivitiesSuccessfulAction =
						{
							type: NotificationFeedActionTypes.BULK_DELETE_NOTIFICATION_FEED_ACTIVITY_SUCCESSFUL,
							foreignId
						};

					return dispatch(
						bulkDeleteNotificationFeedActivitiesSuccessfulAction
					);
				} else {
					const bulkDeleteNotificationFeedActivitiesFailedAction: BulkDeleteNotificationFeedActivitiesFailedAction =
						{
							type: NotificationFeedActionTypes.BULK_DELETE_NOTIFICATION_FEED_ACTIVITY_FAILED
						};

					dispatch(bulkDeleteNotificationFeedActivitiesFailedAction);
					return Promise.reject(DefaultErrorMessage);
				}
			} catch (error) {
				const bulkDeleteNotificationFeedActivitiesFailedAction: BulkDeleteNotificationFeedActivitiesFailedAction =
					{
						type: NotificationFeedActionTypes.BULK_DELETE_NOTIFICATION_FEED_ACTIVITY_FAILED
					};
				dispatch(bulkDeleteNotificationFeedActivitiesFailedAction);

				return Promise.reject(error);
			}
		};
	},

	markNotificationFeedActivityAsRead: (
		markNotificationFeedActivityAsReadRequest: NotificationActivity
	) => {
		return async (
			dispatch: Dispatch,
			getState: () => GlobalState
		): Promise<MarkNotificationFeedActivityAsReadSuccessfulAction> => {
			const markNotificationFeedActivityAsReadRequestedAction: MarkNotificationFeedActivityAsReadRequestedAction =
				{
					type: NotificationFeedActionTypes.MARK_NOTIFICATION_FEED_ACTIVITY_AS_READ_REQUESTED
				};

			dispatch(markNotificationFeedActivityAsReadRequestedAction);

			try {
				const globalState = getState();
				const {
					api_key: apiKey,
					token,
					app_id: appId
				} = globalState.vendorUser.vendorUserConfiguration;

				if (!globalState.vendorUser.vendorUserData.id?.length) {
					console.error(
						globalState.vendorUser.vendorUserData,
						"GetStream error when user id is not present"
					);
				}

				if (apiKey && apiKey.length > 0) {
					const notificationFeedStreamConnection =
						NotificationFeedStream.connect(apiKey, token, appId);

					const notificationFeed =
						notificationFeedStreamConnection.feed(
							"notification",
							globalState.vendorUser.vendorUserData.id,
							token
						);

					await notificationFeed.get({
						mark_read: [
							markNotificationFeedActivityAsReadRequest.notificationId
						]
					});

					const updatedNotificationFeedActivityList = [
						...getState().notificationFeed
							.notificationFeedActivityList
					].map(notificationFeedActivity => {
						if (
							notificationFeedActivity.notificationId ===
							markNotificationFeedActivityAsReadRequest.notificationId
						) {
							notificationFeedActivity.isRead = true;
						}

						return notificationFeedActivity;
					});

					const markNotificationFeedActivityAsReadSuccessfulAction: MarkNotificationFeedActivityAsReadSuccessfulAction =
						{
							type: NotificationFeedActionTypes.MARK_NOTIFICATION_FEED_ACTIVITY_AS_READ_SUCCESSFUL,
							updatedNotificationFeedActivityList:
								updatedNotificationFeedActivityList
						};

					return dispatch(
						markNotificationFeedActivityAsReadSuccessfulAction
					);
				} else {
					const markNotificationFeedActivityAsReadFailedAction: MarkNotificationFeedActivityAsReadFailedAction =
						{
							type: NotificationFeedActionTypes.MARK_NOTIFICATION_FEED_ACTIVITY_AS_READ_FAILED
						};

					dispatch(markNotificationFeedActivityAsReadFailedAction);
					return Promise.reject(DefaultErrorMessage);
				}
			} catch (error) {
				const markNotificationFeedActivityAsReadFailedAction: MarkNotificationFeedActivityAsReadFailedAction =
					{
						type: NotificationFeedActionTypes.MARK_NOTIFICATION_FEED_ACTIVITY_AS_READ_FAILED
					};

				dispatch(markNotificationFeedActivityAsReadFailedAction);

				return Promise.reject(error);
			}
		};
	},

	markAllNotificationFeedActivitiesAsRead: () => {
		return async (
			dispatch: Dispatch,
			getState: () => GlobalState
		): Promise<MarkAllNotificationFeedActivitiesAsReadSuccessfulAction> => {
			const markAllNotificationFeedActivitiesAsReadRequestedAction: MarkAllNotificationFeedActivitiesAsReadRequestedAction =
				{
					type: NotificationFeedActionTypes.MARK_ALL_NOTIFICATION_FEED_ACTIVITIES_AS_READ_REQUESTED
				};

			dispatch(markAllNotificationFeedActivitiesAsReadRequestedAction);

			try {
				const globalState = getState();
				const {
					api_key: apiKey,
					token,
					app_id: appId
				} = globalState.vendorUser.vendorUserConfiguration;

				if (!globalState.vendorUser.vendorUserData.id?.length) {
					console.error(
						globalState.vendorUser.vendorUserData,
						"GetStream error when user id is not present"
					);
				}

				if (apiKey && apiKey.length > 0) {
					const notificationFeedStreamConnection =
						NotificationFeedStream.connect(apiKey, token, appId);

					const notificationFeed =
						notificationFeedStreamConnection.feed(
							"notification",
							globalState.vendorUser.vendorUserData.id,
							token
						);

					const notificationFeedActivityList = [
						...getState().notificationFeed
							.notificationFeedActivityList
					];

					await notificationFeed.get({
						mark_read: true
					});

					const updatedNotificationFeedActivityList =
						notificationFeedActivityList.map(
							notificationFeedActivity => {
								notificationFeedActivity.isRead = true;

								return notificationFeedActivity;
							}
						);

					const markAllNotificationFeedActivitiesAsReadSuccessfulAction: MarkAllNotificationFeedActivitiesAsReadSuccessfulAction =
						{
							type: NotificationFeedActionTypes.MARK_ALL_NOTIFICATION_FEED_ACTIVITIES_AS_READ_SUCCESSFUL,
							updatedNotificationFeedActivityList:
								updatedNotificationFeedActivityList
						};

					return dispatch(
						markAllNotificationFeedActivitiesAsReadSuccessfulAction
					);
				} else {
					const markAllNotificationFeedActivitiesAsReadFailedAction: MarkAllNotificationFeedActivitiesAsReadFailedAction =
						{
							type: NotificationFeedActionTypes.MARK_ALL_NOTIFICATION_FEED_ACTIVITIES_AS_READ_FAILED
						};

					dispatch(
						markAllNotificationFeedActivitiesAsReadFailedAction
					);
					return Promise.reject(DefaultErrorMessage);
				}
			} catch (error) {
				const markAllNotificationFeedActivitiesAsReadFailedAction: MarkAllNotificationFeedActivitiesAsReadFailedAction =
					{
						type: NotificationFeedActionTypes.MARK_ALL_NOTIFICATION_FEED_ACTIVITIES_AS_READ_FAILED
					};

				dispatch(markAllNotificationFeedActivitiesAsReadFailedAction);

				return Promise.reject(error);
			}
		};
	}
};

export default NotificationFeedActionCreators;
