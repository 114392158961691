'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var React = require('react');

/**
 *
 * @param element?: Optional. Get an element's client width & height.
 *
 * @description computes the screen's width and height on window resize event.
 *
 * @returns WindowSize: { width: number, height: number, elementSize?: Size }.
 *
 * @author Idrees
 */
var useWindowSize = function (element) {
  var _a = React.useState({
      width: undefined,
      height: undefined,
      elementSize: undefined
    }),
    windowSize = _a[0],
    setWindowSize = _a[1];
  React.useEffect(function () {
    var handleResize = function () {
      var size = {
        width: window.innerWidth,
        height: window.innerHeight
      };
      var elementWidth = element === null || element === void 0 ? void 0 : element.clientWidth;
      var elementHeight = element === null || element === void 0 ? void 0 : element.clientHeight;
      if (elementWidth !== undefined && elementHeight !== undefined) {
        size.elementSize = {
          width: elementWidth,
          height: elementHeight
        };
      }
      setWindowSize(size);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return function () {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return windowSize;
};
var useWindowSize$1 = useWindowSize;

exports["default"] = useWindowSize$1;
