'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../../../../node_modules/tslib/tslib.es6.mjs.js');
var index = require('../../../actions/Vendor/Partner/index.js');

var initialVendorPartnerState = {
  isLoading: false,
  editPartnerDrawerVisible: false
};
var VendorPartnerReducer = function VendorPartnerReducer(state, action) {
  if (state === void 0) {
    state = initialVendorPartnerState;
  }
  switch (action.type) {
    case index.VendorPartnerActionTypes.SHOW_VENDOR_PARTNER_LOADER:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          isLoading: true
        });
      }
    case index.VendorPartnerActionTypes.HIDE_VENDOR_PARTNER_LOADER:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          isLoading: false
        });
      }
    case index.VendorPartnerActionTypes.OPEN_EDIT_VENDOR_PARTNER_DRAWER:
      return tslib_es6.__assign(tslib_es6.__assign({}, state), {
        editPartnerDrawerVisible: true
      });
    case index.VendorPartnerActionTypes.CLOSE_EDIT_VENDOR_PARTNER_DRAWER:
      return tslib_es6.__assign(tslib_es6.__assign({}, state), {
        editPartnerDrawerVisible: false
      });
    default:
      {
        return state;
      }
  }
};
var VendorPartnerReducer$1 = VendorPartnerReducer;

exports.VendorPartnerReducer = VendorPartnerReducer;
exports["default"] = VendorPartnerReducer$1;
exports.initialVendorPartnerState = initialVendorPartnerState;
