'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var _interface = require('../components/VideoGuide/interface.js');
var index = require('../contracts/index.js');
var AlignCongratulationsFlash = require('../assets/AlignCongratulationsFlash.svg.js');

var _a;
exports.GuideType = void 0;
(function (GuideType) {
  GuideType["POPUP"] = "popup";
  GuideType["NORMAL"] = "normal";
  GuideType["VIDEO"] = "video";
  GuideType["POPUP_MODAL"] = "popup_modal";
})(exports.GuideType || (exports.GuideType = {}));
var WelcomeVideoGuide = {
  id: index.Steps.WELCOME_VIDEO,
  type: exports.GuideType.VIDEO,
  stageComplete: true,
  triggerAPI: false,
  guideUIProps: {
    fileType: _interface.GuideMediaType.VIDEO,
    fileName: "https://static.zomentum.com/videos/grow_onboarding/welcome_video.mp4",
    buttonText: "Start Product Tour"
  }
};
var StageStepsMap = (_a = {},
//Document Module
_a[index.Stages.DOCUMENT_INTRODUCTION] = [{
  id: index.Steps.HIGHLIGHT_DOCUMENT_MENUITEM,
  type: exports.GuideType.POPUP,
  triggerAPI: true,
  stageComplete: true,
  guideUIProps: {
    title: "Explore Documents",
    description: "Create, manage, edit, and send documents quickly for effective/faster client communication and win more deals.",
    placement: "leftTop",
    visible: true,
    closeOnClickOut: false,
    highlightId: "elem_" + index.Steps.HIGHLIGHT_DOCUMENT_MENUITEM,
    hasTransparentMask: false,
    buttonText: "Next",
    mediaUrl: "https://static.zomentum.com/videos/grow_onboarding/explore_document_module.mp4"
  }
}
/*{
    id: Steps.SHOW_DOCUMENT_INTRO_VIDEO,
    type: GuideType.VIDEO,
    triggerAPI: true,
    stageComplete: true,
    guideUIProps: {
        fileType: GuideMediaType.VIDEO,
        fileName:
            "https://s3.amazonaws.com/channelforcestage.com/videos/grow_onboarding/vendor_focus_on_the_value.mp4",
        buttonText: "Add Document"
    }
}*/], _a[index.Stages.ADD_DOCUMENT] = [{
  id: index.Steps.HIGHLIGHT_ADD_DOCUMENT_BUTTON,
  type: exports.GuideType.NORMAL,
  guideUIProps: {
    title: "Create Your First Document",
    description: "Use predefined templates, build quotes, and get instant approvals. ",
    placement: "bottom",
    visible: true,
    highlightId: "elem_" + index.Steps.HIGHLIGHT_ADD_DOCUMENT_BUTTON,
    hasTransparentMask: false,
    closeOnClickOut: false,
    hasArrow: true,
    width: 252
  }
}, {
  id: index.Steps.SHOW_SELECT_TEMPLATE,
  type: exports.GuideType.NORMAL,
  stageComplete: true,
  guideUIProps: {
    title: "Let's Select A Template",
    description: "We've already selected the fields for you to get started",
    placement: "top",
    visible: true,
    closeOnClickOut: false,
    hasArrow: true,
    highlightId: "elem_" + index.Steps.SHOW_SELECT_TEMPLATE,
    hasTransparentMask: true,
    width: 240
  }
}], _a[index.Stages.SELECTING_TEMPLATE] = [{
  id: index.Steps.HIGHLIGHT_SELECT_TEMPLATE,
  type: exports.GuideType.NORMAL,
  guideUIProps: {
    description: "Here you can select a template. Don't worry we have already selected one for you to begin with.",
    placement: "rightTop",
    visible: true,
    highlightId: "elem_" + index.Steps.HIGHLIGHT_SELECT_TEMPLATE,
    hasTransparentMask: false,
    closeOnClickOut: false,
    buttonText: "Next",
    hasArrow: false,
    width: 248
  }
}, {
  id: index.Steps.SHOW_CREATE_DOCUMENT,
  type: exports.GuideType.NORMAL,
  triggerAPI: true,
  stageComplete: true,
  guideUIProps: {
    description: "Create a document with selected template.",
    placement: "top",
    visible: true,
    closeOnClickOut: false,
    hasArrow: true,
    highlightId: "elem_" + index.Steps.SHOW_CREATE_DOCUMENT,
    hasTransparentMask: true,
    width: 192
  }
}], _a[index.Stages.EXPLORE_EDITOR] = [{
  id: index.Steps.SHOW_DRAG_DROP_BLOCK,
  type: exports.GuideType.POPUP,
  isEntityRequired: true,
  guideUIProps: {
    title: "Drag And Drop Blocks To The Editor",
    description: "Add text, image, video, and quote blocks to build your document.",
    note: "Try it: Select and drag a block to the editor.",
    placement: "leftTop",
    visible: true,
    closeOnClickOut: false,
    highlightId: "elem_" + index.Steps.SHOW_DRAG_DROP_BLOCK,
    hasTransparentMask: true,
    buttonText: "",
    mediaUrl: "https://static.zomentum.com/videos/grow_onboarding/explore_document_editor.mp4"
  }
}, {
  id: index.Steps.SHOW_BLOCK_SAVE_BLOCK,
  type: exports.GuideType.NORMAL,
  isEntityRequired: true,
  guideUIProps: {
    description: "Click on the icon to save this block as a Custom Block",
    placement: "topLeft",
    visible: true,
    closeOnClickOut: false,
    hasArrow: true,
    highlightId: "elem_" + index.Steps.SHOW_BLOCK_SAVE_BLOCK,
    hasTransparentMask: true,
    buttonText: "Save",
    width: 240
  }
}, {
  id: index.Steps.SHOW_SEND_DOCUMENT_IN_EDITOR,
  type: exports.GuideType.NORMAL,
  isEntityRequired: true,
  guideUIProps: {
    description: "Click here to send the Document",
    placement: "bottom",
    visible: true,
    closeOnClickOut: false,
    hasArrow: true,
    highlightId: "elem_" + index.Steps.SHOW_SEND_DOCUMENT_IN_EDITOR,
    hasTransparentMask: true,
    width: 172
  }
}, {
  id: index.Steps.SHOW_SEND_EMAIL,
  type: exports.GuideType.NORMAL,
  isEntityRequired: true,
  guideUIProps: {
    description: "Let's send the email",
    placement: "topRight",
    visible: false,
    closeOnClickOut: false,
    hasArrow: true,
    highlightId: "elem_" + index.Steps.SHOW_SEND_EMAIL,
    hasTransparentMask: true,
    width: 180
  }
}, {
  id: index.Steps.SHOW_GO_BACK_TO_DOCUMENT_LIST,
  type: exports.GuideType.NORMAL,
  stageComplete: true,
  isEntityRequired: true,
  guideUIProps: {
    description: "Explore this document to know more features of the editor. Following that, click here to go back to the Document Overview section.",
    placement: "bottom",
    visible: true,
    closeOnClickOut: false,
    hasArrow: true,
    highlightId: "elem_" + index.Steps.SHOW_GO_BACK_TO_DOCUMENT_LIST,
    hasTransparentMask: true,
    buttonText: "Got It",
    width: 320
  }
}, {
  id: index.Steps.HIGHLIGHT_CREATED_DOCUMENT_IN_LIST,
  type: exports.GuideType.NORMAL,
  moduleComplete: true,
  isEntityRequired: true,
  triggerAPI: true,
  guideUIProps: {
    description: "Access and track all created documents",
    placement: "bottomLeft",
    visible: true,
    closeOnClickOut: true,
    hasArrow: false,
    highlightId: "elem_" + index.Steps.HIGHLIGHT_CREATED_DOCUMENT_IN_LIST,
    hasTransparentMask: true,
    completeOnClickOut: true,
    buttonText: "Got It",
    width: 284
  }
}],
//Opportunity Module
_a[index.Stages.OPPORTUNITY_INTRODUCTION] = [{
  id: index.Steps.HIGHLIGHT_OPPORTUNITY_MENUITEM,
  type: exports.GuideType.POPUP,
  triggerAPI: true,
  stageComplete: true,
  guideUIProps: {
    title: "Explore Opportunities",
    description: "Get vendor deals, manage leads, and boost pipeline efficiency.",
    placement: "leftTop",
    visible: true,
    closeOnClickOut: false,
    highlightId: "elem_" + index.Steps.HIGHLIGHT_OPPORTUNITY_MENUITEM,
    hasTransparentMask: false,
    buttonText: "Next",
    mediaUrl: "https://static.zomentum.com/videos/grow_onboarding/explore_opportunity_module.mp4"
  }
}
/*{
    id: Steps.SHOW_OPPORTUNITY_INTRO_VIDEO,
    type: GuideType.VIDEO,
    triggerAPI: true,
    stageComplete: true,
    guideUIProps: {
        fileType: GuideMediaType.VIDEO,
        fileName:
            "https://s3.amazonaws.com/channelforcestage.com/videos/grow_onboarding/vendor_focus_on_the_value.mp4",
        buttonText: "Add Opportunity"
    }
}*/], _a[index.Stages.ADD_OPPORTUNITY] = [{
  id: index.Steps.HIGHLIGHT_ADD_OPPORTUNITY_BUTTON,
  type: exports.GuideType.NORMAL,
  guideUIProps: {
    title: "Create Your First Opportunity",
    description: "Opportunities are the best way to keep track of the evolution of your deal",
    placement: "bottom",
    visible: true,
    highlightId: "elem_".concat(index.Steps.HIGHLIGHT_ADD_OPPORTUNITY_BUTTON),
    closeOnClickOut: false,
    hasArrow: true,
    width: 262
  }
}, {
  id: index.Steps.HIGHLIGHT_ADD_OPPORTUNITY_DRAWER,
  type: exports.GuideType.NORMAL,
  guideUIProps: {
    description: "Continue adding this Opportunity with pre-filled Information",
    placement: "right",
    visible: true,
    highlightId: "elem_".concat(index.Steps.HIGHLIGHT_ADD_OPPORTUNITY_DRAWER),
    closeOnClickOut: false,
    buttonText: "Next",
    hasArrow: true,
    width: 240,
    maskOnClickableArea: true
  }
}, {
  id: index.Steps.HIGHLIGHT_OPPORTUNITY_SHARE_WITH_VENDOR_CHECKBOX,
  type: exports.GuideType.NORMAL,
  guideUIProps: {
    description: "Collaborate with your vendor by sharing this Opportunity with them!",
    placement: "leftTop",
    visible: true,
    highlightId: "elem_".concat(index.Steps.HIGHLIGHT_OPPORTUNITY_SHARE_WITH_VENDOR_CHECKBOX),
    closeOnClickOut: false,
    buttonText: "Got It",
    hasArrow: false,
    width: 264,
    maskOnClickableArea: true,
    hasInfoIcon: true
  }
}, {
  id: index.Steps.HIGHLIGHT_OPPORTUNITY_SAVE_BUTTON,
  type: exports.GuideType.NORMAL,
  stageComplete: true,
  triggerAPI: true,
  guideUIProps: {
    description: "Click here to create a new Opportunity.",
    placement: "topRight",
    visible: true,
    highlightId: "elem_".concat(index.Steps.HIGHLIGHT_OPPORTUNITY_SAVE_BUTTON),
    closeOnClickOut: false,
    hasArrow: true,
    width: 162
  }
}], _a[index.Stages.OPPORTUNITY_ACTIVITIES] = [{
  id: index.Steps.SHOW_OPPORTUNITY_CONGRATULATION_MODAL,
  type: exports.GuideType.POPUP_MODAL,
  stageComplete: true,
  moduleComplete: true,
  triggerAPI: true,
  isEntityRequired: true,
  guideUIProps: {
    title: "Congratulations!",
    confirmText: "Explore",
    mediaUrl: "https://static.zomentum.com/videos/grow_onboarding/explore_opportunity_details.mp4",
    description: "Great job on creating your first opportunity! Take your time to explore all the activities you need to manage this opportunity"
  }
}],
//Align Module
_a[index.Stages.ALIGN_INTRODUCTION] = [{
  id: index.Steps.HIGHLIGHT_ALIGN_MENUITEM,
  type: exports.GuideType.POPUP,
  stageComplete: true,
  triggerAPI: true,
  guideUIProps: {
    title: "Explore Resources In Align",
    description: "Find enablement content from vendors and manage them all in one place.",
    placement: "leftBottom",
    visible: true,
    highlightId: "elem_" + index.Steps.HIGHLIGHT_ALIGN_MENUITEM,
    closeOnClickOut: false,
    buttonText: "Next",
    mediaUrl: "https://static.zomentum.com/videos/grow_onboarding/explore_align_resources.mp4"
  }
}], _a[index.Stages.ALIGN_VENDORS_AND_RESOURCES] = [{
  id: index.Steps.HIGHLIGHT_ALIGN_RESOURCES_BUTTON,
  type: exports.GuideType.NORMAL,
  stageComplete: true,
  triggerAPI: true,
  guideUIProps: {
    description: "Checkout your vendor's enablement content.",
    placement: "right",
    visible: true,
    highlightId: "elem_" + index.Steps.HIGHLIGHT_ALIGN_RESOURCES_BUTTON,
    closeOnClickOut: false,
    hasArrow: true,
    width: 184
  }
}], _a[index.Stages.ALIGN_EXPLORE_RESOURCES] = [{
  id: index.Steps.HIGHLIGHT_ALIGN_VENDOR_RESOURCE_FOLDER,
  type: exports.GuideType.NORMAL,
  guideUIProps: {
    description: "Navigate through the folders to explore the resources.",
    placement: "right",
    visible: true,
    highlightId: "elem_" + index.Steps.HIGHLIGHT_ALIGN_VENDOR_RESOURCE_FOLDER,
    closeOnClickOut: false,
    buttonText: "Next",
    hasArrow: true,
    width: 216
  }
}, {
  id: index.Steps.HIGHLIGHT_ALIGN_VENDOR_RESOURCE_FILE,
  type: exports.GuideType.NORMAL,
  guideUIProps: {
    description: "Access your resources instantly.",
    placement: "right",
    visible: true,
    highlightId: "elem_" + index.Steps.HIGHLIGHT_ALIGN_VENDOR_RESOURCE_FILE,
    closeOnClickOut: false,
    buttonText: "Got It",
    hasArrow: true,
    width: 212
  }
}, {
  id: index.Steps.SHOW_ALIGN_CONGRATULATION_MODAL,
  type: exports.GuideType.POPUP_MODAL,
  stageComplete: true,
  moduleComplete: true,
  triggerAPI: true,
  guideUIProps: {
    illustration: AlignCongratulationsFlash.ReactComponent,
    title: "Great Job!",
    confirmText: "Got It",
    description: "You've completed the Align module. You can continue exploring Align or checkout other modules."
  }
}],
// Integration module
_a[index.Stages.INTEGRATION_SELECT_APP] = [], _a[index.Stages.INTEGRATION_INTRODUCTION] = [], _a[index.Stages.INTEGRATION_APPS] = [], _a);

exports.StageStepsMap = StageStepsMap;
exports.WelcomeVideoGuide = WelcomeVideoGuide;
