'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../../../../node_modules/tslib/tslib.es6.mjs.js');
var index = require('../../../actions/Vendor/Comissions/index.js');

var initialVendorComissionState = {
  isLoading: false,
  editComissionsDrawerVisible: false
};
var VendorPartnerReducer = function VendorPartnerReducer(state, action) {
  if (state === void 0) {
    state = initialVendorComissionState;
  }
  switch (action.type) {
    case index.VendorComissionsActionTypes.SHOW_VENDOR_COMISSIONS_LOADER:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          isLoading: true
        });
      }
    case index.VendorComissionsActionTypes.HIDE_VENDOR_COMISSIONS_LOADER:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          isLoading: false
        });
      }
    case index.VendorComissionsActionTypes.OPEN_EDIT_VENDOR_COMISSIONS_DRAWER:
      return tslib_es6.__assign(tslib_es6.__assign({}, state), {
        editComissionsDrawerVisible: true
      });
    case index.VendorComissionsActionTypes.CLOSE_EDIT_VENDOR_COMISSIONS_DRAWER:
      return tslib_es6.__assign(tslib_es6.__assign({}, state), {
        editComissionsDrawerVisible: false
      });
    default:
      {
        return state;
      }
  }
};
var VendorCommissionsReducer = VendorPartnerReducer;

exports.VendorPartnerReducer = VendorPartnerReducer;
exports["default"] = VendorCommissionsReducer;
exports.initialVendorComissionState = initialVendorComissionState;
