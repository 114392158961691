import { Reducer } from "redux";

import { Pipeline } from "@zomentum/contracts/dist/Pipelines";

import {
	PipelinesActionTypes,
	PipelinesActions
} from "@/Actions/Vendor/Pipelines";

export interface PipelinesState {
	readonly isLoading: boolean;
	readonly currentActivePipeline: Pipeline | null;
}

export const initialPipelineState: PipelinesState = {
	isLoading: false,
	currentActivePipeline: null
};

export const PipelinesReducer: Reducer<PipelinesState, PipelinesActions> = (
	state: PipelinesState = initialPipelineState,
	action: PipelinesActions
): PipelinesState => {
	switch (action.type) {
		case PipelinesActionTypes.SHOW_PIPELINES_LOADER: {
			return {
				...state,
				isLoading: true
			};
		}
		case PipelinesActionTypes.HIDE_PIPELINES_LOADER: {
			return {
				...state,
				isLoading: false
			};
		}
		case PipelinesActionTypes.PIPELINE_REQUESTED: {
			return {
				...state,
				isLoading: true
			};
		}
		case PipelinesActionTypes.PIPELINE_SUCCESSFUL: {
			return {
				...state,
				currentActivePipeline: action.currentPipeline,
				isLoading: false
			};
		}
		case PipelinesActionTypes.PIPELINE_FAILED: {
			return {
				...state,
				isLoading: false
			};
		}
		case PipelinesActionTypes.PIPELINE_DETAILS_UPDATED: {
			return {
				...state,
				currentActivePipeline: action.currentActivePipeline,
				isLoading: false
			};
		}
		case PipelinesActionTypes.DELETE_PIPELINE_OR_STAGE_REQUESTED: {
			return {
				...state,
				isLoading: true
			};
		}
		case PipelinesActionTypes.DELETE_PIPELINE_OR_STAGE_IN_PROGRESS: {
			return {
				...state,
				isLoading: false
			};
		}
		case PipelinesActionTypes.DELETE_PIPELINE_OR_STAGE_FAILED: {
			return {
				...state,
				isLoading: false
			};
		}
		case PipelinesActionTypes.SET_CURRENT_PIPELINE: {
			return {
				...state,
				currentActivePipeline: action.currentPipeline
			};
		}
		default: {
			return state;
		}
	}
};

export default PipelinesReducer;
