'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.ModulePermission = void 0;
(function (ModulePermission) {
  ModulePermission["READ"] = "READ";
  ModulePermission["CREATE_EDIT_DELETE_OWNED"] = "CREATE_EDIT_DELETE_OWNED";
  ModulePermission["EDIT_ALL"] = "EDIT_ALL";
})(exports.ModulePermission || (exports.ModulePermission = {}));
exports.Modules = void 0;
(function (Modules) {
  Modules["OPPORTUNITY"] = "OPPORTUNITY";
  Modules["SALES_AUTOMATION"] = "SALES_AUTOMATION";
  Modules["PIPELINE"] = "PIPELINE";
  Modules["CUSTOM_FIELD"] = "CUSTOM_FIELD";
  Modules["USER"] = "USER";
  Modules["TAX_SETUP"] = "TAX_SETUP";
  Modules["EMAIL"] = "EMAIL";
  Modules["DKIM"] = "DKIM";
  Modules["EMAIL_TEMPLATE"] = "EMAIL_TEMPLATE";
  Modules["CUSTOM_EMAIL_TEMPLATE"] = "CUSTOM_EMAIL_TEMPLATE";
  Modules["REPORT"] = "REPORT";
  Modules["CLIENT_COMPANY"] = "CLIENT_COMPANY";
  Modules["SALES_ACTIVITY"] = "SALES_ACTIVITY";
  Modules["ITEM"] = "ITEM";
  Modules["ROLES_PERMISSION"] = "ROLES_PERMISSION";
  Modules["APPROVAL_AUTOMATION"] = "APPROVAL_AUTOMATION";
  Modules["TEMPLATE"] = "TEMPLATE";
  Modules["DOCUMENT"] = "DOCUMENT";
  Modules["QBR"] = "QBR";
  Modules["ASSESSMENT"] = "ASSESSMENT";
  Modules["QUOTE_BLOCK"] = "QUOTE_BLOCK";
  Modules["PURCHASE_ORDER"] = "PURCHASE_ORDER";
  Modules["MS365_ASSESSMENT"] = "MS365_ASSESSMENT";
  Modules["SUPPLIER"] = "SUPPLIER";
})(exports.Modules || (exports.Modules = {}));
