import { sendMs365InviteToClientRequest } from "@zomentum/contracts/dist/Others/EmailThread/index";
import { Dispatch, Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";

import DownloadFile from "downloadjs";

import { ZomentumEntityRequest } from "@zomentum/contracts";
import { GlobalState } from "@/Reducers/interface";
import {
	MergeTagValueUpdateResponse,
	MergeTagVariable
} from "@zomentum/contracts/dist/Documents";
import {
	EmailThreadMessage,
	DownloadEmailThreadMessageFileRequest,
	SendEmailThreadMessageRequest,
	QueryParameters,
	FetchMergeTagsRequest,
	SendDocumentEmailThreadMessageRequest,
	ValidateEmailMergeTagsRequest,
	GetEmailPreviewRequest,
	OtherEntityEmailPreview,
	GetDocumentEmailPreviewRequest
} from "@zomentum/contracts/dist/Others/EmailThread";

import {
	getEmailThreadMessages,
	emailThreadMessageDownloadAttachment,
	sendEmailThreadMessage,
	sendEmailDocumentMessage,
	getMergeTags,
	fetchMergeTagsWithNoEntityId,
	validateEmailMergeTags,
	getEmailPreview,
	getDocumentEmailPreview,
	getMs365ConsentEmailPreview,
	updateMergeTagsByValue
} from "@/Services/Others/EmailThread";

import { ZomentumStorageKeys } from "@zomentum/contracts";
import { cache } from "@zomentum/contracts";
import { MergeTagInput } from "@zomentum/contracts/dist/Documents";

export enum EmailThreadActionTypes {
	SHOW_EMAIL_THREAD_LOADER = "SHOW_EMAIL_THREAD_LOADER",
	HIDE_EMAIL_THREAD_LOADER = "HIDE_EMAIL_THREAD_LOADER",
	EMAIL_THREAD_MESSAGES_REQUESTED = "EMAIL_THREAD_MESSAGES_REQUESTED",
	EMAIL_THREAD_MESSAGES_SUCCESSFUL = "EMAIL_THREAD_MESSAGES_SUCCESSFUL",
	EMAIL_THREAD_MESSAGES_FAILED = "EMAIL_THREAD_MESSAGES_FAILED",
	EMAIL_THREAD_MESSAGES_RESET = "EMAIL_THREAD_MESSAGES_RESET",
	EMAIL_THREAD_MESSAGE_DOWNLOAD_ATTACHMENT_REQUESTED = "EMAIL_THREAD_MESSAGE_DOWNLOAD_ATTACHMENT_REQUESTED",
	EMAIL_THREAD_MESSAGE_DOWNLOAD_ATTACHMENT_SUCCESSFUL = "EMAIL_THREAD_MESSAGE_DOWNLOAD_ATTACHMENT_SUCCESSFUL",
	EMAIL_THREAD_MESSAGE_DOWNLOAD_ATTACHMENT_FAILED = "EMAIL_THREAD_MESSAGE_DOWNLOAD_ATTACHMENT_FAILED",
	SEND_EMAIL_THREAD_MESSAGE_REQUESTED = "SEND_EMAIL_THREAD_MESSAGE_REQUESTED",
	SEND_EMAIL_THREAD_MESSAGE_SUCCESSFUL = "SEND_EMAIL_THREAD_MESSAGE_SUCCESSFUL",
	SEND_EMAIL_THREAD_MESSAGE_FAILED = "SEND_EMAIL_THREAD_MESSAGE_FAILED",
	SEND_EMAIL_DOCUMENT_MESSAGE_REQUESTED = "SEND_EMAIL_DOCUMENT_MESSAGE_REQUESTED",
	SEND_EMAIL_DOCUMENT_MESSAGE_SUCCESSFUL = "SEND_EMAIL_DOCUMENT_MESSAGE_SUCCESSFUL",
	SEND_EMAIL_DOCUMENT_MESSAGE_FAILED = "SEND_EMAIL_DOCUMENT_MESSAGE_FAILED",
	EMAIL_THREAD_MESSAGE_DRAWER_OPENED = "EMAIL_THREAD_MESSAGE_DRAWER_OPENED",
	EMAIL_THREAD_MESSAGE_DRAWER_CLOSED = "EMAIL_THREAD_MESSAGE_DRAWER_CLOSED",

	FETCH_EMAIL_MERGE_TAGS_REQUESTED = "FETCH_MERGE_TAGS_REQUESTED",
	FETCH_EMAIL_MERGE_TAGS_SUCCESSFUL = "FETCH_MERGE_TAGS_SUCCESSFUL",
	FETCH_EMAIL_MERGE_TAGS_FAILED = "FETCH_MERGE_TAGS_FAILED",

	VALIDATE_EMAIL_MERGE_TAGS_REQUESTED = "VALIDATE_MERGE_TAGS_REQUESTED",
	VALIDATE_EMAIL_MERGE_TAGS_SUCCESSFUL = "VALIDATE_MERGE_TAGS_SUCCESSFUL",
	VALIDATE_EMAIL_MERGE_TAGS_FAILED = "VALIDATE_MERGE_TAGS_FAILED",

	FETCH_EMAIL_MERGE_TAGS_WITH_NO_ENTITY_ID_REQUESTED = "FETCH_MERGE_TAGS_WITH_NO_ENTITY_ID_REQUESTED",
	FETCH_EMAIL_MERGE_TAGS_WITH_NO_ENTITY_ID_SUCCESSFUL = "FETCH_MERGE_TAGS_WITH_NO_ENTITY_ID_SUCCESSFUL",
	FETCH_EMAIL_MERGE_TAGS_WITH_NO_ENTITY_ID_FAILED = "FETCH_MERGE_TAGS_WITH_NO_ENTITY_ID_FAILED",

	RESET_EMAIL_MERGE_TAGS = "RESET_EMAIL_MERGE_TAGS",

	FETCH_DOCUMENT_EMAIL_PREVIEW_REQUESTED = "FETCH_DOCUMENT_EMAIL_PREVIEW_REQUESTED",
	FETCH_DOCUMENT_EMAIL_PREVIEW_SUCCESSFUL = "FETCH_DOCUMENT_EMAIL_PREVIEW_SUCCESSFUL",
	FETCH_DOCUMENT_EMAIL_PREVIEW_FAILED = "FETCH_DOCUMENT_EMAIL_PREVIEW_FAILED",
	RESET_DOCUMENT_EMAIL_PREVIEW = "RESET_DOCUMENT_EMAIL_PREVIEW",

	FETCH_OTHER_ENTITY_EMAIL_PREVIEW_REQUESTED = "FETCH_OTHER_ENTITY_EMAIL_PREVIEW_REQUESTED",
	FETCH_OTHER_ENTITY_EMAIL_PREVIEW_SUCCESSFUL = "FETCH_OTHER_ENTITY_EMAIL_PREVIEW_SUCCESSFUL",
	FETCH_OTHER_ENTITY_EMAIL_PREVIEW_FAILED = "FETCH_OTHER_ENTITY_EMAIL_PREVIEW_FAILED",
	RESET_EMAIL_PREVIEW = "RESET_EMAIL_PREVIEW",

	FETCH_MS365_CONSENT_EMAIL_PREVIEW_REQUESTED = "FETCH_MS365_CONSENT_EMAIL_PREVIEW_REQUESTED",
	FETCH_MS365_CONSENT_EMAIL_PREVIEW_SUCCESSFUL = "FETCH_MS365_CONSENT_EMAIL_PREVIEW_SUCCESSFUL",
	FETCH_MS365_CONSENT_EMAIL_PREVIEW_FAILED = "FETCH_MS365_CONSENT_EMAIL_PREVIEW_RFAILD",
	RESET_MS365_CONSENT_EMAIL_PREVIEW = "RESET_MS365_CONSENT_EMAIL_PREVIEW",

	RESET_EMPTY_MERGE_TAGS = "RESET_EMPTY_MERGE_TAGS",

	SEND_MS365_INVITE_TO_CLIENT_START = "SEND_MS365_INVITE_TO_CLIENT_START",
	SEND_MS365_INVITE_TO_CLIENT_STOP = "SEND_MS365_INVITE_TO_CLIENT_STOP",

	MISSING_EMAIL_MODAL_OPENED = "MISSING_EMAIL_MODAL_OPENED",
	MISSING_EMAIL_MODAL_CLOSED = "MISSING_EMAIL_MODAL_CLOSED",

	UPDATE_MERGE_TAGS_VALUE_REQUESTED = "UPDATE_MERGE_TAGS_VALUE_REQUESTED",
	UPDATE_MERGE_TAGS_VALUE_SUCCESSFUL = "UPDATE_MERGE_TAGS_VALUE_SUCCESSFUL",
	UPDATE_MERGE_TAGS_VALUE_FAILED = "UPDATE_MERGE_TAGS_VALUE_FAILED"
}

export type ShowEmailThreadLoaderAction =
	Action<EmailThreadActionTypes.SHOW_EMAIL_THREAD_LOADER>;
export type HideEmailThreadLoaderAction =
	Action<EmailThreadActionTypes.HIDE_EMAIL_THREAD_LOADER>;
export type EmailThreadMessagesRequestedAction =
	Action<EmailThreadActionTypes.EMAIL_THREAD_MESSAGES_REQUESTED>;
export interface EmailThreadMessagesSuccessfulAction
	extends Action<EmailThreadActionTypes.EMAIL_THREAD_MESSAGES_SUCCESSFUL> {
	readonly emailThreadMessages: EmailThreadMessage[];
}
export type EmailThreadMessagesFailedAction =
	Action<EmailThreadActionTypes.EMAIL_THREAD_MESSAGES_FAILED>;
export type EmailThreadMessagesResetAction =
	Action<EmailThreadActionTypes.EMAIL_THREAD_MESSAGES_RESET>;
export type EmailThreadMessageAttachmentDownloadRequestedAction =
	Action<EmailThreadActionTypes.EMAIL_THREAD_MESSAGE_DOWNLOAD_ATTACHMENT_REQUESTED>;
export type EmailThreadMessageAttachmentDownloadSuccessfulAction =
	Action<EmailThreadActionTypes.EMAIL_THREAD_MESSAGE_DOWNLOAD_ATTACHMENT_SUCCESSFUL>;
export type EmailThreadMessageAttachmentDownloadFailedAction =
	Action<EmailThreadActionTypes.EMAIL_THREAD_MESSAGE_DOWNLOAD_ATTACHMENT_FAILED>;
export type SendEmailThreadMessageRequestedAction =
	Action<EmailThreadActionTypes.SEND_EMAIL_THREAD_MESSAGE_REQUESTED>;
export interface SendEmailThreadMessageSuccessfulAction
	extends Action<EmailThreadActionTypes.SEND_EMAIL_THREAD_MESSAGE_SUCCESSFUL> {
	readonly emailThreadMessages: EmailThreadMessage[];
}
export type SendEmailThreadMessageFailedAction =
	Action<EmailThreadActionTypes.SEND_EMAIL_THREAD_MESSAGE_FAILED>;
export type SendEmailDocumentMessageRequestedAction =
	Action<EmailThreadActionTypes.SEND_EMAIL_DOCUMENT_MESSAGE_REQUESTED>;
export type SendEmailDocumentMessageSuccessfulAction =
	Action<EmailThreadActionTypes.SEND_EMAIL_DOCUMENT_MESSAGE_SUCCESSFUL>;
export type SendEmailDocumentMessageFailedAction =
	Action<EmailThreadActionTypes.SEND_EMAIL_DOCUMENT_MESSAGE_FAILED>;
export interface EmailThreadMessageDrawerOpenedAction
	extends Action<EmailThreadActionTypes.EMAIL_THREAD_MESSAGE_DRAWER_OPENED> {
	readonly sendEmailWithoutDocument: boolean;
}

export type EmailThreadMessageDrawerClosedAction =
	Action<EmailThreadActionTypes.EMAIL_THREAD_MESSAGE_DRAWER_CLOSED>;

export type MissingEmailModalOpenedAction =
	Action<EmailThreadActionTypes.MISSING_EMAIL_MODAL_OPENED>;
export type MissingEmailModalClosedAction =
	Action<EmailThreadActionTypes.MISSING_EMAIL_MODAL_CLOSED>;

export type FetchMergeTagsRequestedAction =
	Action<EmailThreadActionTypes.FETCH_EMAIL_MERGE_TAGS_REQUESTED>;
export interface FetchMergeTagsSuccessfulAction
	extends Action<EmailThreadActionTypes.FETCH_EMAIL_MERGE_TAGS_SUCCESSFUL> {
	readonly mergeTags: MergeTagVariable[];
}
export type FetchMergeTagsFailedAction =
	Action<EmailThreadActionTypes.FETCH_EMAIL_MERGE_TAGS_FAILED>;

export type ValidateMergeTagsRequestedAction =
	Action<EmailThreadActionTypes.VALIDATE_EMAIL_MERGE_TAGS_REQUESTED>;
export interface ValidateMergeTagsSuccessfulAction
	extends Action<EmailThreadActionTypes.VALIDATE_EMAIL_MERGE_TAGS_SUCCESSFUL> {
	readonly emptyMergeTags: string[];
}
export type ValidateMergeTagsFailedAction =
	Action<EmailThreadActionTypes.VALIDATE_EMAIL_MERGE_TAGS_FAILED>;

export type FetchMergeTagsWithNoEntityIdRequestedAction =
	Action<EmailThreadActionTypes.FETCH_EMAIL_MERGE_TAGS_WITH_NO_ENTITY_ID_REQUESTED>;
export interface FetchMergeTagsWithNoEntityIdSuccessfulAction
	extends Action<EmailThreadActionTypes.FETCH_EMAIL_MERGE_TAGS_WITH_NO_ENTITY_ID_SUCCESSFUL> {
	readonly mergeTags: MergeTagVariable[];
}
export type FetchMergeTagsWithNoEntityIdFailedAction =
	Action<EmailThreadActionTypes.FETCH_EMAIL_MERGE_TAGS_WITH_NO_ENTITY_ID_FAILED>;

export type FetchDocumentEmailPreviewRequestedAction =
	Action<EmailThreadActionTypes.FETCH_DOCUMENT_EMAIL_PREVIEW_REQUESTED>;
export interface FetchDocumentEmailPreviewSuccessfulAction
	extends Action<EmailThreadActionTypes.FETCH_DOCUMENT_EMAIL_PREVIEW_SUCCESSFUL> {
	readonly documentEmailPreview: string;
}
export type FetchDocumentEmailPreviewFailedAction =
	Action<EmailThreadActionTypes.FETCH_DOCUMENT_EMAIL_PREVIEW_FAILED>;

export type FetchOtherEntityEmailPreviewRequestedAction =
	Action<EmailThreadActionTypes.FETCH_OTHER_ENTITY_EMAIL_PREVIEW_REQUESTED>;
export interface FetchOtherEntityEmailPreviewSuccessfulAction
	extends Action<EmailThreadActionTypes.FETCH_OTHER_ENTITY_EMAIL_PREVIEW_SUCCESSFUL> {
	readonly otherEntityEmailPreview: OtherEntityEmailPreview;
}
export type FetchOtherEntityEmailPreviewFailedAction =
	Action<EmailThreadActionTypes.FETCH_OTHER_ENTITY_EMAIL_PREVIEW_FAILED>;

export type FetchMs365ConsentEmailPreviewRequestedAction =
	Action<EmailThreadActionTypes.FETCH_MS365_CONSENT_EMAIL_PREVIEW_REQUESTED>;
export interface FetchMs365ConsentEmailPreviewSuccessfulAction
	extends Action<EmailThreadActionTypes.FETCH_MS365_CONSENT_EMAIL_PREVIEW_SUCCESSFUL> {
	readonly ms365ConsentEmailPreview: string;
}
export type FetchMs365ConsentEmailPreviewFailedAction =
	Action<EmailThreadActionTypes.FETCH_MS365_CONSENT_EMAIL_PREVIEW_FAILED>;
export type ResetMS365ConsentEmailPreviewAction =
	Action<EmailThreadActionTypes.RESET_MS365_CONSENT_EMAIL_PREVIEW>;

export type ResetEmailMergeTagsAction =
	Action<EmailThreadActionTypes.RESET_EMAIL_MERGE_TAGS>;

export type ResetDocumentEmailPreviewAction =
	Action<EmailThreadActionTypes.RESET_DOCUMENT_EMAIL_PREVIEW>;
export type ResetEmailPreviewAction =
	Action<EmailThreadActionTypes.RESET_EMAIL_PREVIEW>;
export type ResetEmptyMergeTags =
	Action<EmailThreadActionTypes.RESET_EMPTY_MERGE_TAGS>;

export type UpdateMergeTagsRequestedAction =
	Action<EmailThreadActionTypes.UPDATE_MERGE_TAGS_VALUE_REQUESTED>;
export interface UpdateMergeTagsSuccessfulAction
	extends Action<EmailThreadActionTypes.UPDATE_MERGE_TAGS_VALUE_SUCCESSFUL> {
	readonly updateMergeTagErrors: MergeTagValueUpdateResponse;
}
export type UpdateMergeTagsFailedAction =
	Action<EmailThreadActionTypes.UPDATE_MERGE_TAGS_VALUE_FAILED>;

export type EmailThreadActions =
	| ShowEmailThreadLoaderAction
	| HideEmailThreadLoaderAction
	| EmailThreadMessagesRequestedAction
	| EmailThreadMessagesSuccessfulAction
	| EmailThreadMessagesFailedAction
	| EmailThreadMessagesResetAction
	| EmailThreadMessageAttachmentDownloadRequestedAction
	| EmailThreadMessageAttachmentDownloadSuccessfulAction
	| EmailThreadMessageAttachmentDownloadFailedAction
	| SendEmailThreadMessageRequestedAction
	| SendEmailThreadMessageSuccessfulAction
	| SendEmailThreadMessageFailedAction
	| SendEmailDocumentMessageRequestedAction
	| SendEmailDocumentMessageSuccessfulAction
	| SendEmailDocumentMessageFailedAction
	| EmailThreadMessageDrawerOpenedAction
	| EmailThreadMessageDrawerClosedAction
	| MissingEmailModalOpenedAction
	| MissingEmailModalClosedAction
	| FetchMergeTagsRequestedAction
	| FetchMergeTagsSuccessfulAction
	| FetchMergeTagsFailedAction
	| ResetEmailMergeTagsAction
	| FetchDocumentEmailPreviewRequestedAction
	| FetchDocumentEmailPreviewSuccessfulAction
	| FetchDocumentEmailPreviewFailedAction
	| ResetDocumentEmailPreviewAction
	| FetchMergeTagsWithNoEntityIdRequestedAction
	| FetchMergeTagsWithNoEntityIdSuccessfulAction
	| FetchMergeTagsWithNoEntityIdFailedAction
	| ValidateMergeTagsRequestedAction
	| ValidateMergeTagsSuccessfulAction
	| ValidateMergeTagsFailedAction
	| FetchOtherEntityEmailPreviewRequestedAction
	| FetchOtherEntityEmailPreviewSuccessfulAction
	| FetchOtherEntityEmailPreviewFailedAction
	| ResetEmailPreviewAction
	| FetchMs365ConsentEmailPreviewRequestedAction
	| FetchMs365ConsentEmailPreviewSuccessfulAction
	| FetchMs365ConsentEmailPreviewFailedAction
	| ResetMS365ConsentEmailPreviewAction
	| ResetEmptyMergeTags
	| UpdateMergeTagsRequestedAction
	| UpdateMergeTagsSuccessfulAction
	| UpdateMergeTagsFailedAction;

export interface EmailThreadDispatch {
	showEmailThreadLoader: ActionCreator<
		ThunkAction<
			ShowEmailThreadLoaderAction,
			GlobalState,
			null,
			ShowEmailThreadLoaderAction
		>
	>;
	hideEmailThreadLoader: ActionCreator<
		ThunkAction<
			HideEmailThreadLoaderAction,
			GlobalState,
			null,
			HideEmailThreadLoaderAction
		>
	>;
	getEmailThreadMessages: ActionCreator<
		ThunkAction<
			Promise<EmailThreadMessagesSuccessfulAction>,
			GlobalState,
			ZomentumEntityRequest,
			EmailThreadMessagesSuccessfulAction
		>
	>;
	resetEmailThreads: ActionCreator<
		ThunkAction<
			EmailThreadMessagesResetAction,
			GlobalState,
			null,
			EmailThreadMessagesResetAction
		>
	>;

	downloadEmailThreadMessageFile: ActionCreator<
		ThunkAction<
			Promise<EmailThreadMessageAttachmentDownloadSuccessfulAction>,
			GlobalState,
			DownloadEmailThreadMessageFileRequest,
			EmailThreadMessageAttachmentDownloadSuccessfulAction
		>
	>;
	sendEmailThreadMessage: ActionCreator<
		ThunkAction<
			Promise<SendEmailThreadMessageSuccessfulAction>,
			GlobalState,
			SendEmailThreadMessageRequest,
			SendEmailThreadMessageSuccessfulAction
		>
	>;
	sendEmailDocumentMessage: ActionCreator<
		ThunkAction<
			Promise<SendEmailDocumentMessageSuccessfulAction>,
			GlobalState,
			SendDocumentEmailThreadMessageRequest,
			SendEmailDocumentMessageSuccessfulAction
		>
	>;
	emailThreadMessageDrawerOpened: ActionCreator<
		ThunkAction<
			EmailThreadMessageDrawerOpenedAction,
			GlobalState,
			boolean,
			EmailThreadMessageDrawerOpenedAction
		>
	>;
	emailThreadMessageDrawerClosed: ActionCreator<
		ThunkAction<
			EmailThreadMessageDrawerClosedAction,
			GlobalState,
			null,
			EmailThreadMessageDrawerClosedAction
		>
	>;

	missingEmailModalOpened: ActionCreator<
		ThunkAction<
			MissingEmailModalOpenedAction,
			GlobalState,
			null,
			MissingEmailModalOpenedAction
		>
	>;
	missingEmailModalClosed: ActionCreator<
		ThunkAction<
			MissingEmailModalClosedAction,
			GlobalState,
			null,
			MissingEmailModalClosedAction
		>
	>;
	fetchMergeTags: ActionCreator<
		ThunkAction<
			Promise<FetchMergeTagsSuccessfulAction>,
			GlobalState,
			{ queryParameters: FetchMergeTagsRequest },
			FetchMergeTagsSuccessfulAction
		>
	>;
	fetchMergeTagsWithoutId: ActionCreator<
		ThunkAction<
			Promise<FetchMergeTagsWithNoEntityIdSuccessfulAction>,
			GlobalState,
			string,
			FetchMergeTagsWithNoEntityIdSuccessfulAction
		>
	>;
	resetMergeTags: ActionCreator<
		ThunkAction<
			ResetEmailMergeTagsAction,
			GlobalState,
			null,
			ResetEmailMergeTagsAction
		>
	>;
	previewDocumentEmail: ActionCreator<
		ThunkAction<
			Promise<FetchDocumentEmailPreviewSuccessfulAction>,
			GlobalState,
			GetDocumentEmailPreviewRequest,
			FetchDocumentEmailPreviewSuccessfulAction
		>
	>;
	previewEmail: ActionCreator<
		ThunkAction<
			Promise<FetchOtherEntityEmailPreviewSuccessfulAction>,
			GlobalState,
			Partial<GetEmailPreviewRequest>,
			FetchOtherEntityEmailPreviewSuccessfulAction
		>
	>;
	previewMs365ConsentEmail: ActionCreator<
		ThunkAction<
			Promise<FetchMs365ConsentEmailPreviewSuccessfulAction>,
			GlobalState,
			sendMs365InviteToClientRequest,
			FetchMs365ConsentEmailPreviewSuccessfulAction
		>
	>;
	resetDocumentEmailPreview: ActionCreator<
		ThunkAction<
			ResetDocumentEmailPreviewAction,
			GlobalState,
			null,
			ResetDocumentEmailPreviewAction
		>
	>;
	resetEmailPreview: ActionCreator<
		ThunkAction<
			ResetEmailPreviewAction,
			GlobalState,
			null,
			ResetEmailPreviewAction
		>
	>;
	resetMs365ConsentEmailPreview: ActionCreator<
		ThunkAction<
			ResetMS365ConsentEmailPreviewAction,
			GlobalState,
			null,
			ResetMS365ConsentEmailPreviewAction
		>
	>;
	validateEmailMergeTags: ActionCreator<
		ThunkAction<
			Promise<ValidateMergeTagsSuccessfulAction>,
			GlobalState,
			ValidateEmailMergeTagsRequest,
			ValidateMergeTagsSuccessfulAction
		>
	>;
	resetEmptyMergeTags: ActionCreator<
		ThunkAction<ResetEmptyMergeTags, GlobalState, null, ResetEmptyMergeTags>
	>;
	updateMergeTagsValue: ActionCreator<
		ThunkAction<
			Promise<UpdateMergeTagsSuccessfulAction>,
			GlobalState,
			MergeTagInput,
			UpdateMergeTagsSuccessfulAction
		>
	>;
}

export const EmailThreadActionCreators: EmailThreadDispatch = {
	showEmailThreadLoader: () => {
		return (dispatch: Dispatch): ShowEmailThreadLoaderAction => {
			const showEmailThreadLoaderAction: ShowEmailThreadLoaderAction = {
				type: EmailThreadActionTypes.SHOW_EMAIL_THREAD_LOADER
			};
			return dispatch(showEmailThreadLoaderAction);
		};
	},
	hideEmailThreadLoader: () => {
		return (dispatch: Dispatch): HideEmailThreadLoaderAction => {
			const hideEmailThreadLoaderAction: HideEmailThreadLoaderAction = {
				type: EmailThreadActionTypes.HIDE_EMAIL_THREAD_LOADER
			};
			return dispatch(hideEmailThreadLoaderAction);
		};
	},
	getEmailThreadMessages: (
		zomentumEntityRequest: ZomentumEntityRequest,
		userIp: string
	) => {
		return async (
			dispatch: Dispatch
		): Promise<EmailThreadMessagesSuccessfulAction> => {
			const emailThreadMessagesRequestedAction: EmailThreadMessagesRequestedAction =
				{
					type: EmailThreadActionTypes.EMAIL_THREAD_MESSAGES_REQUESTED
				};
			dispatch(emailThreadMessagesRequestedAction);
			try {
				const emailThreadMessagesResponse =
					await getEmailThreadMessages(zomentumEntityRequest, userIp);
				const emailThreadMessagesSuccessfulAction: EmailThreadMessagesSuccessfulAction =
					{
						type: EmailThreadActionTypes.EMAIL_THREAD_MESSAGES_SUCCESSFUL,
						emailThreadMessages: emailThreadMessagesResponse.data
					};
				return dispatch(emailThreadMessagesSuccessfulAction);
			} catch (error) {
				const emailThreadMessagesFailedAction: EmailThreadMessagesFailedAction =
					{
						type: EmailThreadActionTypes.EMAIL_THREAD_MESSAGES_FAILED
					};
				dispatch(emailThreadMessagesFailedAction);

				return Promise.reject(error);
			}
		};
	},
	resetEmailThreads: () => {
		return (dispatch: Dispatch): EmailThreadMessagesResetAction => {
			const emailThreadMessagesResetAction: EmailThreadMessagesResetAction =
				{
					type: EmailThreadActionTypes.EMAIL_THREAD_MESSAGES_RESET
				};
			return dispatch(emailThreadMessagesResetAction);
		};
	},
	downloadEmailThreadMessageFile: (
		downloadEmailThreadMessageFileRequest: DownloadEmailThreadMessageFileRequest
	) => {
		return async (
			dispatch: Dispatch
		): Promise<EmailThreadMessageAttachmentDownloadSuccessfulAction> => {
			const emailThreadMessageAttachmentDownloadRequestedAction: EmailThreadMessageAttachmentDownloadRequestedAction =
				{
					type: EmailThreadActionTypes.EMAIL_THREAD_MESSAGE_DOWNLOAD_ATTACHMENT_REQUESTED
				};
			dispatch(emailThreadMessageAttachmentDownloadRequestedAction);
			try {
				const emailThreadMessagesResponse =
					await emailThreadMessageDownloadAttachment(
						downloadEmailThreadMessageFileRequest
					);
				const emailThreadMessageAttachmentDownloadSuccessfulAction: EmailThreadMessageAttachmentDownloadSuccessfulAction =
					{
						type: EmailThreadActionTypes.EMAIL_THREAD_MESSAGE_DOWNLOAD_ATTACHMENT_SUCCESSFUL
					};

				DownloadFile(
					emailThreadMessagesResponse,
					downloadEmailThreadMessageFileRequest.file.filename
				);
				return dispatch(
					emailThreadMessageAttachmentDownloadSuccessfulAction
				);
			} catch (error) {
				const emailThreadMessageAttachmentDownloadFailedAction: EmailThreadMessageAttachmentDownloadFailedAction =
					{
						type: EmailThreadActionTypes.EMAIL_THREAD_MESSAGE_DOWNLOAD_ATTACHMENT_FAILED
					};
				dispatch(emailThreadMessageAttachmentDownloadFailedAction);

				return Promise.reject(error);
			}
		};
	},
	sendEmailThreadMessage: (
		sendEmailThreadMessageRequest: SendEmailThreadMessageRequest
	) => {
		return async (
			dispatch: Dispatch
		): Promise<SendEmailThreadMessageSuccessfulAction> => {
			const sendEmailThreadMessageRequestedAction: SendEmailThreadMessageRequestedAction =
				{
					type: EmailThreadActionTypes.SEND_EMAIL_THREAD_MESSAGE_REQUESTED
				};
			dispatch(sendEmailThreadMessageRequestedAction);
			try {
				const emailThreadMessagesResponse =
					await sendEmailThreadMessage(sendEmailThreadMessageRequest);
				const sendEmailThreadMessageSuccessfulAction: SendEmailThreadMessageSuccessfulAction =
					{
						type: EmailThreadActionTypes.SEND_EMAIL_THREAD_MESSAGE_SUCCESSFUL,
						emailThreadMessages: emailThreadMessagesResponse.data
					};
				return dispatch(sendEmailThreadMessageSuccessfulAction);
			} catch (error) {
				const sendEmailThreadMessageFailedAction: SendEmailThreadMessageFailedAction =
					{
						type: EmailThreadActionTypes.SEND_EMAIL_THREAD_MESSAGE_FAILED
					};
				dispatch(sendEmailThreadMessageFailedAction);

				return Promise.reject(error);
			}
		};
	},
	sendEmailDocumentMessage: (
		sendDocumentEmailThreadMessageRequest: SendDocumentEmailThreadMessageRequest
	) => {
		return async (
			dispatch: Dispatch
		): Promise<SendEmailDocumentMessageSuccessfulAction> => {
			const sendEmailDocumentMessageRequestedAction: SendEmailDocumentMessageRequestedAction =
				{
					type: EmailThreadActionTypes.SEND_EMAIL_DOCUMENT_MESSAGE_REQUESTED
				};
			dispatch(sendEmailDocumentMessageRequestedAction);
			try {
				await sendEmailDocumentMessage(
					sendDocumentEmailThreadMessageRequest
				);
				const sendEmailDocumentMessageSuccessfulAction: SendEmailDocumentMessageSuccessfulAction =
					{
						type: EmailThreadActionTypes.SEND_EMAIL_DOCUMENT_MESSAGE_SUCCESSFUL
					};
				const fromEmailInfo =
					sendDocumentEmailThreadMessageRequest?.from;
				const emailPrefered = fromEmailInfo?.email
					? { email: fromEmailInfo?.email, name: fromEmailInfo?.name }
					: { email: "noreply", name: null };
				cache.save(ZomentumStorageKeys.LastUsedEmail, emailPrefered);
				return dispatch(sendEmailDocumentMessageSuccessfulAction);
			} catch (error) {
				const sendEmailDocumentMessageFailedAction: SendEmailDocumentMessageFailedAction =
					{
						type: EmailThreadActionTypes.SEND_EMAIL_DOCUMENT_MESSAGE_FAILED
					};
				dispatch(sendEmailDocumentMessageFailedAction);

				return Promise.reject(error);
			}
		};
	},

	emailThreadMessageDrawerOpened: (sendEmailWithoutDocument?: boolean) => {
		return (dispatch: Dispatch): EmailThreadMessageDrawerOpenedAction => {
			const emailThreadMessageDrawerOpenedAction: EmailThreadMessageDrawerOpenedAction =
				{
					type: EmailThreadActionTypes.EMAIL_THREAD_MESSAGE_DRAWER_OPENED,
					sendEmailWithoutDocument: !!sendEmailWithoutDocument
				};

			return dispatch(emailThreadMessageDrawerOpenedAction);
		};
	},

	emailThreadMessageDrawerClosed: () => {
		return (dispatch: Dispatch): EmailThreadMessageDrawerClosedAction => {
			const emailThreadMessageDrawerClosedAction: EmailThreadMessageDrawerClosedAction =
				{
					type: EmailThreadActionTypes.EMAIL_THREAD_MESSAGE_DRAWER_CLOSED
				};
			return dispatch(emailThreadMessageDrawerClosedAction);
		};
	},

	missingEmailModalOpened: () => {
		return (dispatch: Dispatch): MissingEmailModalOpenedAction => {
			const missingEmailModalOpenedAction: MissingEmailModalOpenedAction =
				{
					type: EmailThreadActionTypes.MISSING_EMAIL_MODAL_OPENED
				};
			return dispatch(missingEmailModalOpenedAction);
		};
	},

	missingEmailModalClosed: () => {
		return (dispatch: Dispatch): MissingEmailModalClosedAction => {
			const missingEmailModalClosedAction: MissingEmailModalClosedAction =
				{
					type: EmailThreadActionTypes.MISSING_EMAIL_MODAL_CLOSED
				};
			return dispatch(missingEmailModalClosedAction);
		};
	},

	fetchMergeTags: (fetchMergeTagsRequest: FetchMergeTagsRequest) => {
		return async (
			dispatch: Dispatch
		): Promise<FetchMergeTagsSuccessfulAction> => {
			const fetchMergeTagsRequestedAction: FetchMergeTagsRequestedAction =
				{
					type: EmailThreadActionTypes.FETCH_EMAIL_MERGE_TAGS_REQUESTED
				};
			dispatch(fetchMergeTagsRequestedAction);
			try {
				const queryParameters: QueryParameters[] =
					fetchMergeTagsRequest.toEntityQueryStringforVendor();

				const response: MergeTagVariable[] = await getMergeTags(
					queryParameters
				);

				const reducedResponse = response.reduce(
					(
						newArray: MergeTagVariable[],
						current: MergeTagVariable
					) => {
						const isPresent = newArray.find(
							mergeTag =>
								mergeTag.variable_name === current.variable_name
						);
						if (!isPresent) {
							newArray = [...newArray, current];
						}
						return newArray;
					},
					[]
				);
				const fetchMergeTagsSuccessfulAction: FetchMergeTagsSuccessfulAction =
					{
						type: EmailThreadActionTypes.FETCH_EMAIL_MERGE_TAGS_SUCCESSFUL,
						mergeTags: reducedResponse
					};
				return dispatch(fetchMergeTagsSuccessfulAction);
			} catch (error) {
				const fetchMergeTagsFailedAction: FetchMergeTagsFailedAction = {
					type: EmailThreadActionTypes.FETCH_EMAIL_MERGE_TAGS_FAILED
				};
				dispatch(fetchMergeTagsFailedAction);

				return Promise.reject(error);
			}
		};
	},
	resetMergeTags: () => {
		return (dispatch: Dispatch): ResetEmailMergeTagsAction => {
			const resetEmailMergeTagsAction: ResetEmailMergeTagsAction = {
				type: EmailThreadActionTypes.RESET_EMAIL_MERGE_TAGS
			};
			return dispatch(resetEmailMergeTagsAction);
		};
	},
	previewDocumentEmail: (
		sendEmailThreadMessageRequest: GetDocumentEmailPreviewRequest
	) => {
		return async (
			dispatch: Dispatch
		): Promise<FetchDocumentEmailPreviewSuccessfulAction> => {
			const fetchEmailPreviewRequestedAction: FetchDocumentEmailPreviewRequestedAction =
				{
					type: EmailThreadActionTypes.FETCH_DOCUMENT_EMAIL_PREVIEW_REQUESTED
				};
			dispatch(fetchEmailPreviewRequestedAction);

			try {
				const emailPreview = await getDocumentEmailPreview(
					sendEmailThreadMessageRequest
				);
				const fetchEmailPreviewSuccessfulAction: FetchDocumentEmailPreviewSuccessfulAction =
					{
						type: EmailThreadActionTypes.FETCH_DOCUMENT_EMAIL_PREVIEW_SUCCESSFUL,
						documentEmailPreview: emailPreview[0] ?? ""
					};
				return dispatch(fetchEmailPreviewSuccessfulAction);
			} catch (error) {
				const fetchEmailPreviewFailedAction: FetchDocumentEmailPreviewFailedAction =
					{
						type: EmailThreadActionTypes.FETCH_DOCUMENT_EMAIL_PREVIEW_FAILED
					};
				dispatch(fetchEmailPreviewFailedAction);

				return Promise.reject(error);
			}
		};
	},
	previewEmail: (
		sendEmailThreadMessageRequest: Partial<GetEmailPreviewRequest>
	) => {
		return async (
			dispatch: Dispatch
		): Promise<FetchOtherEntityEmailPreviewSuccessfulAction> => {
			const fetchEmailPreviewRequestedAction: FetchOtherEntityEmailPreviewRequestedAction =
				{
					type: EmailThreadActionTypes.FETCH_OTHER_ENTITY_EMAIL_PREVIEW_REQUESTED
				};
			dispatch(fetchEmailPreviewRequestedAction);

			try {
				const response = await getEmailPreview(
					sendEmailThreadMessageRequest
				);
				const emailPreview: OtherEntityEmailPreview = {
					subject:
						(response[0]?.subject && response[0]?.subject) || "",
					body: response[0].body ?? "",
					system_template_info_view: {
						button_text:
							response[0]?.system_template_info_view
								?.button_text ?? "",
						heading:
							response[0]?.system_template_info_view?.heading ??
							"",
						end_salutation:
							response[0]?.system_template_info_view
								?.end_salutation ?? ""
					}
				};
				const fetchEmailPreviewSuccessfulAction: FetchOtherEntityEmailPreviewSuccessfulAction =
					{
						type: EmailThreadActionTypes.FETCH_OTHER_ENTITY_EMAIL_PREVIEW_SUCCESSFUL,
						otherEntityEmailPreview: emailPreview
					};
				return dispatch(fetchEmailPreviewSuccessfulAction);
			} catch (error) {
				const fetchEmailPreviewFailedAction: FetchOtherEntityEmailPreviewFailedAction =
					{
						type: EmailThreadActionTypes.FETCH_OTHER_ENTITY_EMAIL_PREVIEW_FAILED
					};
				dispatch(fetchEmailPreviewFailedAction);

				return Promise.reject(error);
			}
		};
	},
	previewMs365ConsentEmail: (
		clientId: string,
		sendMs365InviteToClientRequest: Partial<SendEmailThreadMessageRequest>
	) => {
		return async (
			dispatch: Dispatch
		): Promise<FetchMs365ConsentEmailPreviewSuccessfulAction> => {
			const fetchMs365ConsentPreviewRequestedAction: FetchMs365ConsentEmailPreviewRequestedAction =
				{
					type: EmailThreadActionTypes.FETCH_MS365_CONSENT_EMAIL_PREVIEW_REQUESTED
				};
			dispatch(fetchMs365ConsentPreviewRequestedAction);

			try {
				const emailPreview = await getMs365ConsentEmailPreview(
					clientId,
					sendMs365InviteToClientRequest
				);
				const fetchMs365ConsentPreviewSuccessfulAction: FetchMs365ConsentEmailPreviewSuccessfulAction =
					{
						type: EmailThreadActionTypes.FETCH_MS365_CONSENT_EMAIL_PREVIEW_SUCCESSFUL,
						ms365ConsentEmailPreview: emailPreview[0] ?? ""
					};
				return dispatch(fetchMs365ConsentPreviewSuccessfulAction);
			} catch (error) {
				const fetchMs365ConsentPreviewFailedAction: FetchMs365ConsentEmailPreviewFailedAction =
					{
						type: EmailThreadActionTypes.FETCH_MS365_CONSENT_EMAIL_PREVIEW_FAILED
					};
				dispatch(fetchMs365ConsentPreviewFailedAction);

				return Promise.reject(error);
			}
		};
	},
	resetDocumentEmailPreview: () => {
		return (dispatch: Dispatch): ResetDocumentEmailPreviewAction => {
			const resetEmailPreviewAction: ResetDocumentEmailPreviewAction = {
				type: EmailThreadActionTypes.RESET_DOCUMENT_EMAIL_PREVIEW
			};

			return dispatch(resetEmailPreviewAction);
		};
	},
	resetEmailPreview: () => {
		return (dispatch: Dispatch): ResetEmailPreviewAction => {
			const resetEmailPreviewAction: ResetEmailPreviewAction = {
				type: EmailThreadActionTypes.RESET_EMAIL_PREVIEW
			};

			return dispatch(resetEmailPreviewAction);
		};
	},
	resetMs365ConsentEmailPreview: () => {
		return (dispatch: Dispatch): ResetMS365ConsentEmailPreviewAction => {
			const resetEmailPreviewAction: ResetMS365ConsentEmailPreviewAction =
				{
					type: EmailThreadActionTypes.RESET_MS365_CONSENT_EMAIL_PREVIEW
				};

			return dispatch(resetEmailPreviewAction);
		};
	},
	fetchMergeTagsWithoutId: (entityType: string) => {
		return async (
			dispatch: Dispatch
		): Promise<FetchMergeTagsWithNoEntityIdSuccessfulAction> => {
			const fetchMergeTagsWithNoEntityIdRequestedAction: FetchMergeTagsWithNoEntityIdRequestedAction =
				{
					type: EmailThreadActionTypes.FETCH_EMAIL_MERGE_TAGS_WITH_NO_ENTITY_ID_REQUESTED
				};
			dispatch(fetchMergeTagsWithNoEntityIdRequestedAction);
			try {
				const response = await fetchMergeTagsWithNoEntityId(entityType);
				const fetchMergeTagsWithNoEntityIdSuccessfulAction: FetchMergeTagsWithNoEntityIdSuccessfulAction =
					{
						type: EmailThreadActionTypes.FETCH_EMAIL_MERGE_TAGS_WITH_NO_ENTITY_ID_SUCCESSFUL,
						mergeTags: response
					};
				return dispatch(fetchMergeTagsWithNoEntityIdSuccessfulAction);
			} catch (error) {
				const fetchMergeTagsWithNoEntityIdFailedAction: FetchMergeTagsWithNoEntityIdFailedAction =
					{
						type: EmailThreadActionTypes.FETCH_EMAIL_MERGE_TAGS_WITH_NO_ENTITY_ID_FAILED
					};
				dispatch(fetchMergeTagsWithNoEntityIdFailedAction);

				return Promise.reject(error);
			}
		};
	},
	validateEmailMergeTags: (
		validateEmailMergeTagsRequest: ValidateEmailMergeTagsRequest
	) => {
		return async (
			dispatch: Dispatch
		): Promise<ValidateMergeTagsSuccessfulAction> => {
			const validateEmailMergeTagsRequestedAction: ValidateMergeTagsRequestedAction =
				{
					type: EmailThreadActionTypes.VALIDATE_EMAIL_MERGE_TAGS_REQUESTED
				};
			dispatch(validateEmailMergeTagsRequestedAction);
			try {
				const response = await validateEmailMergeTags(
					validateEmailMergeTagsRequest
				);
				const emptyMergeTags: string[] =
					response[0].present_and_empty_tags;
				const validateEmailMergeTagsSuccessfulAction: ValidateMergeTagsSuccessfulAction =
					{
						type: EmailThreadActionTypes.VALIDATE_EMAIL_MERGE_TAGS_SUCCESSFUL,
						emptyMergeTags: emptyMergeTags
					};
				return dispatch(validateEmailMergeTagsSuccessfulAction);
			} catch (error) {
				const validateEmailMergeTagsFailedAction: ValidateMergeTagsFailedAction =
					{
						type: EmailThreadActionTypes.VALIDATE_EMAIL_MERGE_TAGS_FAILED
					};
				dispatch(validateEmailMergeTagsFailedAction);

				return Promise.reject(error);
			}
		};
	},
	resetEmptyMergeTags: () => {
		return (dispatch: Dispatch): ResetEmptyMergeTags => {
			const resetEmptyMergeTags: ResetEmptyMergeTags = {
				type: EmailThreadActionTypes.RESET_EMPTY_MERGE_TAGS
			};
			return dispatch(resetEmptyMergeTags);
		};
	},
	updateMergeTagsValue: (mergeTagsData: MergeTagInput) => {
		return async (
			dispatch: Dispatch
		): Promise<UpdateMergeTagsSuccessfulAction> => {
			const updateMergeTagsRequestedAction: UpdateMergeTagsRequestedAction =
				{
					type: EmailThreadActionTypes.UPDATE_MERGE_TAGS_VALUE_REQUESTED
				};
			dispatch(updateMergeTagsRequestedAction);
			try {
				const response = await updateMergeTagsByValue(mergeTagsData);
				const updateMergeTagsSuccessfulAction: UpdateMergeTagsSuccessfulAction =
					{
						type: EmailThreadActionTypes.UPDATE_MERGE_TAGS_VALUE_SUCCESSFUL,
						updateMergeTagErrors: response
					};
				return dispatch(updateMergeTagsSuccessfulAction);
			} catch (error) {
				const updateMergeTagsFailedAction: UpdateMergeTagsFailedAction =
					{
						type: EmailThreadActionTypes.UPDATE_MERGE_TAGS_VALUE_FAILED
					};
				dispatch(updateMergeTagsFailedAction);

				return Promise.reject(error);
			}
		};
	}
};

export default EmailThreadActionCreators;
