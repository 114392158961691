import {
	VendorUsersActions,
	VendorUsersActionTypes
} from "@/Actions/Vendor/User";
import { cache, NotificationConfiguration } from "@zomentum/contracts";
import {
	VendorUserCompany,
	VendorUserData,
	VendorUserIntegration
} from "@zomentum/contracts/dist/Vendor/User";
import { Reducer } from "redux";
import { CustomView, CustomViewResponse } from "@zomentum/contracts/dist/UI";

export interface VendorUserState {
	readonly isLoading: boolean;
	readonly vendorUserData: VendorUserData;
	readonly vendorUserCompany: VendorUserCompany;
	readonly vendorUserIntegration: VendorUserIntegration;
	readonly vendorUserList: VendorUserData[];
	readonly addVendorUserDrawerOpen: boolean;
	readonly editVendorUserDrawerOpen: boolean;
	readonly vendorUserConfiguration: NotificationConfiguration;
	readonly currentActiveView: CustomView | null;
	readonly viewList: CustomViewResponse | null;
	readonly isViewChanged: boolean;

	// readonly userData: UserData;
	// readonly configurationSettings: ConfigurationSettingsResponse;
	// readonly userCompany: UserCompany;
	// readonly companyUserList: UserData[];
	// readonly companyUserCount: CompanyUserCount;

	// readonly libraryList: LibraryItem[];
	// readonly isLibraryLoading: boolean;
	// readonly userCalendars: UserCalendar[] | null;
	// readonly emailSignatures: UserEmailSignature;
}

export const initialVendorUserState: VendorUserState = {
	isLoading: false,
	vendorUserData: new VendorUserData(),
	vendorUserCompany: new VendorUserCompany(),
	vendorUserIntegration: new VendorUserIntegration(),
	vendorUserList: [],
	addVendorUserDrawerOpen: false,
	editVendorUserDrawerOpen: false,
	vendorUserConfiguration: new NotificationConfiguration(),
	currentActiveView: null,
	viewList: null,
	isViewChanged: false
};

const getInitialVendorUserState = (): VendorUserState => {
	const data = {
		...initialVendorUserState,
		vendorUserData:
			cache.data.userData ?? initialVendorUserState.vendorUserData,
		vendorUserCompany:
			cache.data.userCompany ?? initialVendorUserState.vendorUserCompany
	};
	return data;
};

export const VendorUserReducer: Reducer<VendorUserState, VendorUsersActions> = (
	state: VendorUserState = getInitialVendorUserState(),
	action: VendorUsersActions
): VendorUserState => {
	switch (action.type) {
		case VendorUsersActionTypes.SHOW_VENDOR_USER_LOADER: {
			return {
				...state,
				isLoading: true
			};
		}
		case VendorUsersActionTypes.HIDE_VENDOR_USER_LOADER: {
			return {
				...state,
				isLoading: false
			};
		}
		case VendorUsersActionTypes.VENDOR_USER_DATA_SUCCESSFUL: {
			return {
				...state,
				vendorUserData: action.vendorUserData
			};
		}
		case VendorUsersActionTypes.VENDOR_USER_COMPANY_SUCCESSFUL: {
			return {
				...state,
				vendorUserCompany: action.vendorUserCompany
			};
		}
		case VendorUsersActionTypes.VENDOR_USER_INTEGRATION_SUCCESSFUL: {
			return {
				...state,
				vendorUserIntegration: action.vendorUserIntegration
			};
		}
		case VendorUsersActionTypes.ADD_VENDOR_USER_EMAIL_ALIAS_SUCCESSFUL: {
			return {
				...state,
				vendorUserIntegration:
					action.addVendorUserEmailAliasResponse.integrations
			};
		}
		case VendorUsersActionTypes.DELETE_VENDOR_USER_EMAIL_ALIAS_SUCCESSFUL: {
			return {
				...state,
				vendorUserIntegration:
					action.deleteVendorUserEmailAliasResponse.integrations
			};
		}
		case VendorUsersActionTypes.RESET_VENDOR_USER_STATE: {
			return initialVendorUserState;
		}
		case VendorUsersActionTypes.VENDOR_USER_LIST_SUCCESSFUL: {
			return {
				...state,
				vendorUserList: action.vendorUserList
			};
		}
		case VendorUsersActionTypes.UPDATE_VENDOR_USER_LIST: {
			return {
				...state,
				vendorUserList: action.vendorUserList
			};
		}
		case VendorUsersActionTypes.ADD_VENDOR_USER_DRAWER_OPENED: {
			return {
				...state,
				addVendorUserDrawerOpen: true
			};
		}
		case VendorUsersActionTypes.ADD_VENDOR_USER_DRAWER_CLOSED: {
			return {
				...state,
				addVendorUserDrawerOpen: false
			};
		}
		case VendorUsersActionTypes.EDIT_COMPANY_VENDOR_DRAWER_OPENED: {
			return {
				...state,
				editVendorUserDrawerOpen: true
			};
		}
		case VendorUsersActionTypes.EDIT_COMPANY_VENDOR_DRAWER_CLOSED: {
			return {
				...state,
				editVendorUserDrawerOpen: false
			};
		}
		case VendorUsersActionTypes.VENDOR_USER_CONFIG_SUCCESSFUL: {
			return {
				...state,
				vendorUserConfiguration: action.config
			};
		}
		case VendorUsersActionTypes.FETCH_ALL_VENDOR_USER_VIEWS_REQUESTED: {
			return state;
		}
		case VendorUsersActionTypes.FETCH_ALL_VENDOR_USER_VIEWS_SUCCESSFUL: {
			return {
				...state,
				viewList: action.viewList
			};
		}
		case VendorUsersActionTypes.FETCH_ALL_VENDOR_USER_VIEWS_FAILED: {
			return state;
		}
		case VendorUsersActionTypes.UPDATE_VENDOR_USER_CURRENT_ACTIVE_VIEW: {
			return {
				...state,
				currentActiveView: action.currentActiveView,
				isViewChanged: action.isViewChanged
			};
		}
		default: {
			return state;
		}
	}
};

export default VendorUserReducer;
