import React, { useState } from "react";
import ZDynamicSelect from "@zomentum/molecules/dist/ZDynamicSelect";
import { IClientSelectProps } from "./interface";
import {
	IOptionGroupProps,
	IOptionProps
} from "@zomentum/atoms/dist/ZSelect/interface";
import {
	GlobalSearchEntityType,
	GlobalSearchRequest
} from "@zomentum/contracts/dist/Others/GlobalSearch";
import { getGlobalSearch } from "@zomentum/utils/dist/Service/GlobalSearch";
import { VendorClient } from "@zomentum/contracts/dist/Vendor/Clients";

const ZVendorClientSelect: React.FC<IClientSelectProps> = props => {
	const { defaultOptions, className, onChange, ...rest } = props;

	const formattedDefaultOptions =
		defaultOptions?.map(currOption => ({
			name: currOption.name,
			value: currOption.id
		})) ?? [];

	const [clients, setClients] = useState<IOptionProps[]>([]);

	const searchClients = async (value: string) => {
		try {
			const clientSearchRequest = new GlobalSearchRequest(
				value,
				[GlobalSearchEntityType.VendorClient],
				10,
				null
			);
			const searchResult = await getGlobalSearch(clientSearchRequest);
			setClients(
				searchResult.data.map(entity => {
					const client = entity.obj as VendorClient;
					return {
						name: client.name,
						value: client.id
					};
				})
			);
		} catch (error) {
			console.error(error);
		}
	};

	const optionsGroup: IOptionGroupProps[] = [
		{
			label: "Clients Please type at least 3 letters to start searching clients",
			selectOptions: clients
		}
	];

	if (props.optGroup) optionsGroup.unshift(...props.optGroup);

	return (
		<ZDynamicSelect
			defaultOptions={formattedDefaultOptions}
			fetchData={searchClients}
			options={clients}
			onChange={onChange}
			className={className}
			dropdownRender={menu => {
				return (
					<div>
						{menu}
						{!props.hideAddClientAction && (
							<div
								className="add-entity-action"
								onMouseDown={$event => {
									$event.preventDefault();
								}}
								onClick={props?.showClientDrawer}
							>
								Add Client
							</div>
						)}
					</div>
				);
			}}
			isOptionsGroup={!!props.optGroup?.length}
			optionsGroup={optionsGroup}
			{...rest}
		/>
	);
};

export default ZVendorClientSelect;
