'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var common = require('../common.js');

var AuthToken = /** @class */function () {
  function AuthToken(token) {
    this.token = "";
    this.token = token;
  }
  return AuthToken;
}();
var Auth0LoginState = /** @class */function () {
  function Auth0LoginState() {}
  return Auth0LoginState;
}();
var LoginRequest = /** @class */function () {
  function LoginRequest(email, password, for_customer, partner_id) {
    if (for_customer === void 0) {
      for_customer = false;
    }
    if (partner_id === void 0) {
      partner_id = null;
    }
    this.email = email;
    this.for_customer = for_customer;
    this.partner_id = partner_id;
    this.password = password;
  }
  return LoginRequest;
}();
var LoginFormValues = /** @class */function () {
  function LoginFormValues() {
    this.email = "";
    this.password = "";
    this.remember = false;
  }
  return LoginFormValues;
}();
var SignupRequest = /** @class */function () {
  function SignupRequest(email, full_name, plan_id, tr_code, coupon, parent_account, no_of_additional_users, campaign_id, signup_document_type_selected, currency) {
    this.email = "";
    this.full_name = "";
    this.email = email;
    this.full_name = full_name;
    this.plan_id = plan_id;
    this.tr_code = tr_code;
    this.coupon = coupon;
    this.parent_account = parent_account;
    this.no_of_additional_users = no_of_additional_users;
    this.campaign_id = campaign_id;
    this.signup_document_type_selected = signup_document_type_selected;
    if (currency) {
      this.currency = currency;
    }
  }
  return SignupRequest;
}();
exports.NoOfUsersRange = void 0;
(function (NoOfUsersRange) {
  NoOfUsersRange["ONE"] = "one";
  NoOfUsersRange["TWO_TO_FIVE"] = "two_to_five";
  NoOfUsersRange["FIVE_TO_FIFTEEN"] = "five_to_fifteen";
  NoOfUsersRange["FIFTEEN_PLUS"] = "fifteen_plus";
})(exports.NoOfUsersRange || (exports.NoOfUsersRange = {}));
var UserCompanyConfigUpdateRequest = /** @class */function () {
  function UserCompanyConfigUpdateRequest() {}
  return UserCompanyConfigUpdateRequest;
}();
var UserCompanyConfigFormValues = /** @class */function () {
  function UserCompanyConfigFormValues() {
    this.company_name = "";
    this.domain = "";
    this.currency = common.Currency.USD;
  }
  return UserCompanyConfigFormValues;
}();
var SignupWithEmailValues = /** @class */function () {
  function SignupWithEmailValues() {
    this.email = "";
    this.full_name = "";
  }
  return SignupWithEmailValues;
}();
var ResendEmailVerificationRequest = /** @class */function () {
  function ResendEmailVerificationRequest(email) {
    if (email === void 0) {
      email = "";
    }
    this.email = "";
    this.email = email;
  }
  return ResendEmailVerificationRequest;
}();
var EmailVerificationResponse = /** @class */function () {
  function EmailVerificationResponse() {
    this.message = "";
  }
  return EmailVerificationResponse;
}();

exports.Auth0LoginState = Auth0LoginState;
exports.AuthToken = AuthToken;
exports.EmailVerificationResponse = EmailVerificationResponse;
exports.LoginFormValues = LoginFormValues;
exports.LoginRequest = LoginRequest;
exports.ResendEmailVerificationRequest = ResendEmailVerificationRequest;
exports.SignupRequest = SignupRequest;
exports.SignupWithEmailValues = SignupWithEmailValues;
exports.UserCompanyConfigFormValues = UserCompanyConfigFormValues;
exports.UserCompanyConfigUpdateRequest = UserCompanyConfigUpdateRequest;
