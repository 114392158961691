import { EColors } from "@zomentum/atoms/dist/ZColors/interface";

const colors: Record<EColors, string[]> = {
	[EColors.RED]: [
		"#fff1f0",
		"#ffccc7",
		"#ffa39e",
		"#ff7875",
		"#ff4d4f",
		"#f5222d",
		"#cf1322",
		"#a8071a",
		"#820014",
		"#5c0011"
	],
	[EColors.VOLCANO]: [
		"#fff2e8",
		"#ffd8bf",
		"#ffbb96",
		"#ff9c6e",
		"#ff7a45",
		"#fa541c",
		"#d4380d",
		"#ad2102",
		"#871400",
		"#610b00"
	],
	[EColors.ORANGE]: [
		"#fff7e6",
		"#ffe7ba",
		"#ffd591",
		"#ffc069",
		"#ffa940",
		"#fa8c16",
		"#d46b08",
		"#ad4e00",
		"#873800",
		"#612500"
	],
	[EColors.GOLD]: [
		"#fffbe6",
		"#fff1b8",
		"#ffe58f",
		"#ffd666",
		"#ffc53d",
		"#faad14",
		"#d48806",
		"#ad6800",
		"#874d00",
		"#613400"
	],
	[EColors.YELLOW]: [
		"#feffe6",
		"#ffffb8",
		"#fffb8f",
		"#fff566",
		"#ffec3d",
		"#fadb14",
		"#d4b106",
		"#ad8b00",
		"#876800",
		"#614700"
	],
	[EColors.LIME]: [
		"#fcffe6",
		"#f4ffb8",
		"#eaff8f",
		"#d3f261",
		"#bae637",
		"#a0d911",
		"#7cb305",
		"#5b8c00",
		"#3f6600",
		"#254000"
	],
	[EColors.GREEN]: [
		"#f6ffed",
		"#d9f7be",
		"#b7eb8f",
		"#95de64",
		"#73d13d",
		"#52c41a",
		"#389e0d",
		"#237804",
		"#135200",
		"#092b00"
	],
	[EColors.CYAN]: [
		"#e6fffb",
		"#b5f5ec",
		"#87e8de",
		"#5cdbd3",
		"#36cfc9",
		"#13c2c2",
		"#08979c",
		"#006d75",
		"#00474f",
		"#002329"
	],
	[EColors.BLUE]: [
		"#e6f7ff",
		"#bae7ff",
		"#91d5ff",
		"#69c0ff",
		"#4b85ff",
		"#2f70ff",
		"#096dd9",
		"#0050b3",
		"#003a8c",
		"#002766"
	],
	[EColors.GEEKBLUE]: [
		"#f0f5ff",
		"#d6e4ff",
		"#adc6ff",
		"#85a5ff",
		"#597ef7",
		"#2f54eb",
		"#1d39c4",
		"#10239e",
		"#061178",
		"#030852"
	],
	[EColors.PURPLE]: [
		"#f9f0ff",
		"#efdbff",
		"#d3adf7",
		"#b37feb",
		"#9254de",
		"#722ed1",
		"#531dab",
		"#391085",
		"#22075e",
		"#120338"
	],
	[EColors.MAGENTA]: [
		"#fff0f6",
		"#ffd6e7",
		"#ffadd2",
		"#ff85c0",
		"#f759ab",
		"#eb2f96",
		"#c41d7f",
		"#9e1068",
		"#780650",
		"#520339"
	],
	[EColors.GRAY]: [
		"#ffffff",
		"#fafafa",
		"#f5f5f5",
		"#e8e8e8",
		"#d9d9d9",
		"#bfbfbf",
		"#8c8c8c",
		"#595959",
		"#262626",
		"#000000"
	],
	[EColors.TRANSPARENT]: []
};

export default colors;
