'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var common = require('../../common.js');

var DocumentDefaults = /** @class */function () {
  function DocumentDefaults() {
    this.font_color = "";
    this.font_face = "";
    this.font_size = "";
  }
  return DocumentDefaults;
}();
var RevisionSigner = /** @class */function () {
  function RevisionSigner() {
    this.email = "";
    this.requester_id = "";
    this.requester_name = new common.Name();
    this.signed = false;
    this.assigned_sign_blocks = new Array();
  }
  return RevisionSigner;
}();
var SignatureDetails = /** @class */function () {
  function SignatureDetails() {
    this.signers = new Array();
  }
  return SignatureDetails;
}();
exports.ApprovalStatus = void 0;
(function (ApprovalStatus) {
  ApprovalStatus["RequestSent"] = "request_sent";
  ApprovalStatus["Approved"] = "approved";
  ApprovalStatus["Rejected"] = "rejected";
})(exports.ApprovalStatus || (exports.ApprovalStatus = {}));
exports.ApprovalOption = void 0;
(function (ApprovalOption) {
  ApprovalOption["Approve"] = "approve";
  ApprovalOption["Reject"] = "reject";
})(exports.ApprovalOption || (exports.ApprovalOption = {}));
exports.HeaderFooterColumnTypes = void 0;
(function (HeaderFooterColumnTypes) {
  HeaderFooterColumnTypes["PAGE_NUMBER"] = "page_number";
  HeaderFooterColumnTypes["IMAGE"] = "image";
  HeaderFooterColumnTypes["TEXT"] = "text";
})(exports.HeaderFooterColumnTypes || (exports.HeaderFooterColumnTypes = {}));

exports.DocumentDefaults = DocumentDefaults;
exports.RevisionSigner = RevisionSigner;
exports.SignatureDetails = SignatureDetails;
