import { Reducer } from "redux";

import { LoginActions, LoginActionTypes } from "@/Actions/Login";
import { Auth0LoginState } from "@zomentum/contracts/dist/Login";

export interface LoginState {
	readonly isLoading: boolean;
	readonly isLoggedIn: boolean;
	readonly auth0LoginState: Auth0LoginState;
}

const initialState: LoginState = {
	isLoading: false,
	isLoggedIn: false,
	auth0LoginState: {}
};

export const LoginReducer: Reducer<LoginState, LoginActions> = (
	state: LoginState = initialState,
	action: LoginActions
): LoginState => {
	switch (action.type) {
		case LoginActionTypes.SHOW_LOGIN_LOADER: {
			return {
				...state,
				isLoading: true
			};
		}
		case LoginActionTypes.HIDE_LOGIN_LOADER: {
			return {
				...state,
				isLoading: false
			};
		}
		case LoginActionTypes.LOGIN_REQUESTED: {
			return state;
		}
		case LoginActionTypes.LOGIN_SUCCESSFUL: {
			return {
				...state,
				isLoggedIn: true
			};
		}
		case LoginActionTypes.LOGIN_FAILED: {
			return {
				...state,
				isLoggedIn: false
			};
		}
		case LoginActionTypes.LOGIN_SESSION_SUCCESSFUL: {
			return {
				...state,
				isLoggedIn: true
			};
		}
		case LoginActionTypes.LOGIN_AUTH0_SUCCESSFUL: {
			return {
				...state,
				isLoggedIn: true
			};
		}
		case LoginActionTypes.LOGOUT_SUCCESSFUL: {
			return {
				...state,
				isLoggedIn: false
			};
		}
		case LoginActionTypes.REFRESH_TOKEN_SUCCESSFUL: {
			return {
				...state,
				isLoggedIn: true
			};
		}
		case LoginActionTypes.REFRESH_TOKEN_FAILED: {
			return {
				...state
				// isLoggedIn: false //TODO: @swadhin logout workaround. needs to be fixed properly
			};
		}
		case LoginActionTypes.LOGIN_AUTH0_STATE_RECEIVED: {
			return {
				...state,
				auth0LoginState: action.auth0LoginState
			};
		}
		case LoginActionTypes.SIGNUP_REQUESTED: {
			return state;
		}
		case LoginActionTypes.SIGNUP_SUCCESSFUL: {
			return state;
		}
		case LoginActionTypes.SIGNUP_FAILED: {
			return state;
		}
		case LoginActionTypes.RESEND_EMAIL_VERIFICATION_REQUESTED: {
			return state;
		}
		case LoginActionTypes.RESEND_EMAIL_VERIFICATION_SUCCESSFUL: {
			return state;
		}
		case LoginActionTypes.RESEND_EMAIL_VERIFICATION_FAILED: {
			return state;
		}
		default: {
			return state;
		}
	}
};

export default LoginReducer;
