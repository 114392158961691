'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../node_modules/tslib/tslib.es6.mjs.js');
var contracts = require('@zomentum/contracts');
var reactRouterDom = require('react-router-dom');

var useNotForPdfView = function () {
  var _a;
  var isPdfView = (_a = reactRouterDom.useRouteMatch(contracts.ZomentumRoutes.DocumentsPdfView)) === null || _a === void 0 ? void 0 : _a.isExact;
  var notForPdfView = function (action) {
    return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
      return tslib_es6.__generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (isPdfView) {
              return [2 /*return*/]; // do nothing
            }

            return [4 /*yield*/, action()];
          case 1:
            _a.sent();
            return [2 /*return*/];
        }
      });
    });
  };

  return {
    notForPdfView: notForPdfView
  };
};
var useNotForPdfView$1 = useNotForPdfView;

exports["default"] = useNotForPdfView$1;
