'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index = require('../Documents/index.js');

var AddAssessmentRequest = /** @class */function () {
  function AddAssessmentRequest() {
    this.name = "";
    this.opportunity_id = null;
    this.client_company_id = null;
    this.tax_region_id = null;
    this.recipients = new Array();
    this.assessment_data_logs = new Array();
  }
  return AddAssessmentRequest;
}();
var AssessmentsRequest = /** @class */function () {
  function AssessmentsRequest(filters, keywords, includeEntities) {
    this.filters = filters;
    this.keywords = keywords;
    this.includeEntities = includeEntities;
  }
  return AssessmentsRequest;
}();
exports.AssessmentDataType = void 0;
(function (AssessmentDataType) {
  AssessmentDataType["InfraAssetInfo"] = "infra_asset_info";
  AssessmentDataType["Vulnerability"] = "vulnerability";
})(exports.AssessmentDataType || (exports.AssessmentDataType = {}));
var InfraAssessmentData = /** @class */function () {
  function InfraAssessmentData() {
    this.ipv4_address = "";
    this.operating_system = "";
    this.operating_system_version = "";
    this.dns_host_name = "";
    this.data = "";
  }
  return InfraAssessmentData;
}();
var AssessmentDataLog = /** @class */function () {
  function AssessmentDataLog() {
    this.id = "";
    this.created = new Date();
    this.updated = new Date();
    this.type = null;
    this.company_id = "";
    this.data = null;
    this.hide = false;
  }
  return AssessmentDataLog;
}();
var EditAssessmentDataLogRequest = /** @class */function () {
  function EditAssessmentDataLogRequest() {
    this.id = "";
  }
  return EditAssessmentDataLogRequest;
}();
var AssessmentVulnerabilityDataLog = /** @class */function () {
  function AssessmentVulnerabilityDataLog() {
    this.id = "";
    this.created = new Date();
    this.updated = new Date();
    this.company_id = "";
    this.type = null;
    this.data = null;
    this.hide = false;
  }
  return AssessmentVulnerabilityDataLog;
}();
var AssessmentCategory = /** @class */function () {
  function AssessmentCategory() {
    this.assessment_data_list = new Array();
    this.issue_id = "";
    this.issue_type = "";
    this.manual_assessment_data_list = new Array();
    this.filter_on_status = null;
  }
  return AssessmentCategory;
}();
exports.AssessmentRiskLevel = void 0;
(function (AssessmentRiskLevel) {
  AssessmentRiskLevel["HIGH"] = "high_risk";
  AssessmentRiskLevel["MEDIUM"] = "medium_risk";
  AssessmentRiskLevel["LOW"] = "low_risk";
})(exports.AssessmentRiskLevel || (exports.AssessmentRiskLevel = {}));
var Assessment = /** @class */function () {
  function Assessment() {
    this.assessment_logs = {
      data: new Array()
    };
    this.company = null;
    this.company_id = "";
    this.created = new Date();
    this.document_id = "";
    this.id = "";
    this.issue_types_list = new Array();
    this.legend_labels = [];
    this.likelihood_labels = [];
    this.likelihood_header_label = "";
    this.manual_assessment_logs = {
      data: new Array()
    };
    this.name = "";
    this.recipients = [];
    this.representation_style = index.AssessmentRepresentationStyle.SEVERITY_LIKELIHOOD;
    this.severity_header_label = "";
    this.severity_labels = [];
    this.updated = new Date();
    this.user_id = "";
    this.user = null;
  }
  return Assessment;
}();
var AssessmentEditRequest = /** @class */function () {
  function AssessmentEditRequest() {
    this.severity_labels = [];
    this.legend_labels = [];
    this.severity_header_label = "";
    this.likelihood_header_label = "";
    this.likelihood_labels = [];
  }
  return AssessmentEditRequest;
}();
exports.AssessmentSeverity = void 0;
(function (AssessmentSeverity) {
  AssessmentSeverity["INTOLERABLE"] = "intolerable";
  AssessmentSeverity["TOLERABLE"] = "tolerable";
  AssessmentSeverity["ACCEPTABLE"] = "acceptable";
})(exports.AssessmentSeverity || (exports.AssessmentSeverity = {}));
exports.AssessmentLikelihood = void 0;
(function (AssessmentLikelihood) {
  AssessmentLikelihood["HIGHLY_LIKELY"] = "highly_likely";
  AssessmentLikelihood["POSSIBLE"] = "possible";
  AssessmentLikelihood["UNLIKELY"] = "unlikely";
})(exports.AssessmentLikelihood || (exports.AssessmentLikelihood = {}));
exports.IssueStatusEnum = void 0;
(function (IssueStatusEnum) {
  IssueStatusEnum["OPEN"] = "open";
  IssueStatusEnum["RESOLVED"] = "resolved";
  IssueStatusEnum["ALL"] = "Show all Issues";
})(exports.IssueStatusEnum || (exports.IssueStatusEnum = {}));
var AssessmentVulnerabilityData = /** @class */function () {
  function AssessmentVulnerabilityData() {
    this.cloud_assessment_type = null;
    this.issue_type = "";
    this.issue_sub_type = "";
    this.summary = "";
    this.severity = exports.AssessmentSeverity.ACCEPTABLE;
    this.likelihood = exports.AssessmentLikelihood.UNLIKELY;
    this.recommendation = "";
    this.status = exports.IssueStatusEnum.OPEN;
  }
  return AssessmentVulnerabilityData;
}();
var AssessmentDataLogRequestSubObj = /** @class */function () {
  function AssessmentDataLogRequestSubObj() {
    this.type = exports.AssessmentDataType.Vulnerability;
    this.data = new AssessmentVulnerabilityData();
  }
  return AssessmentDataLogRequestSubObj;
}();
var AssessmentsResponse = /** @class */function () {
  function AssessmentsResponse() {
    this.data = new Array();
  }
  return AssessmentsResponse;
}();
var AssessmentDataTypeLabelMapper = {
  Computers: exports.AssessmentDataType.InfraAssetInfo,
  "Web Servers": null,
  Printers: null,
  "Exchange Servers": null,
  "MS SQL Servers": null,
  "MX Records": null
};
exports.CloudAssessmentType = void 0;
(function (CloudAssessmentType) {
  CloudAssessmentType["Ms365"] = "ms365";
})(exports.CloudAssessmentType || (exports.CloudAssessmentType = {}));
var Ms365Assessment = /** @class */function () {
  function Ms365Assessment() {
    this.company_id = "";
    this.created = new Date();
    this.id = "";
    this.name = "";
    this.updated = new Date();
    this.user_id = "";
    this.user = null;
  }
  return Ms365Assessment;
}();

exports.AddAssessmentRequest = AddAssessmentRequest;
exports.Assessment = Assessment;
exports.AssessmentCategory = AssessmentCategory;
exports.AssessmentDataLog = AssessmentDataLog;
exports.AssessmentDataLogRequestSubObj = AssessmentDataLogRequestSubObj;
exports.AssessmentDataTypeLabelMapper = AssessmentDataTypeLabelMapper;
exports.AssessmentEditRequest = AssessmentEditRequest;
exports.AssessmentVulnerabilityData = AssessmentVulnerabilityData;
exports.AssessmentVulnerabilityDataLog = AssessmentVulnerabilityDataLog;
exports.AssessmentsRequest = AssessmentsRequest;
exports.AssessmentsResponse = AssessmentsResponse;
exports.EditAssessmentDataLogRequest = EditAssessmentDataLogRequest;
exports.InfraAssessmentData = InfraAssessmentData;
exports.Ms365Assessment = Ms365Assessment;
