import { VendorClient } from "@zomentum/contracts/dist/Vendor/Clients";
import { Action, ActionCreator, Dispatch } from "redux";
import { GlobalState } from "@/Reducers/interface";
import { ThunkAction } from "redux-thunk";
import { getClientDetails } from "@/Services/Vendor/Client";

export enum VendorClientActionTypes {
	SHOW_VENDOR_CLIENT_LOADER = "SHOW_VENDOR_CLIENT_LOADER",
	HIDE_VENDOR_CLIENT_LOADER = "HIDE_VENDOR_CLIENT_LOADER",
	VENDOR_CLIENT_DETAILS_REQUESTED = "VENDOR_CLIENT_DETAILS_REQUESTED",
	VENDOR_CLIENT_DETAILS_SUCCESSFUL = "VENDOR_CLIENT_DETAILS_SUCCESSFUL",
	VENDOR_CLIENT_DETAILS_FAILED = "VENDOR_CLIENT_DETAILS_FAILED",
	EDIT_VENDOR_CLIENT_DRAWER_OPENED = "EDIT_VENDOR_CLIENT_DRAWER_OPENED",
	EDIT_VENDOR_CLIENT_DRAWER_CLOSED = "EDIT_VENDOR_CLIENT_DRAWER_CLOSED"
}

export type ShowVendorClientLoaderAction =
	Action<VendorClientActionTypes.SHOW_VENDOR_CLIENT_LOADER>;
export type HideVendorClientLoaderAction =
	Action<VendorClientActionTypes.HIDE_VENDOR_CLIENT_LOADER>;
export type VendorClientDetailsRequestedAction =
	Action<VendorClientActionTypes.VENDOR_CLIENT_DETAILS_REQUESTED>;
export interface VendorClientDetailsSuccessfulAction
	extends Action<VendorClientActionTypes.VENDOR_CLIENT_DETAILS_SUCCESSFUL> {
	readonly currentActiveVendorClient: VendorClient;
}
export type VendorClientDetailsFailedAction =
	Action<VendorClientActionTypes.VENDOR_CLIENT_DETAILS_FAILED>;
export type EditVendorClientDrawerOpenedAction =
	Action<VendorClientActionTypes.EDIT_VENDOR_CLIENT_DRAWER_OPENED>;
export type EditVendorClientDrawerClosedAction =
	Action<VendorClientActionTypes.EDIT_VENDOR_CLIENT_DRAWER_CLOSED>;

export type VendorClientActions =
	| ShowVendorClientLoaderAction
	| HideVendorClientLoaderAction
	| VendorClientDetailsRequestedAction
	| VendorClientDetailsSuccessfulAction
	| VendorClientDetailsFailedAction
	| EditVendorClientDrawerOpenedAction
	| EditVendorClientDrawerClosedAction;

export interface VendorClientDispatch {
	showVendorClientLoader: ActionCreator<
		ThunkAction<
			ShowVendorClientLoaderAction,
			GlobalState,
			null,
			ShowVendorClientLoaderAction
		>
	>;
	hideVendorClientLoader: ActionCreator<
		ThunkAction<
			HideVendorClientLoaderAction,
			GlobalState,
			null,
			HideVendorClientLoaderAction
		>
	>;
	getVendorClientDetails: ActionCreator<
		ThunkAction<
			Promise<VendorClientDetailsSuccessfulAction>,
			GlobalState,
			string,
			VendorClientDetailsSuccessfulAction
		>
	>;
	showEditVendorClientDrawer: ActionCreator<
		ThunkAction<
			EditVendorClientDrawerOpenedAction,
			GlobalState,
			null,
			EditVendorClientDrawerOpenedAction
		>
	>;
	hideEditVendorClientDrawer: ActionCreator<
		ThunkAction<
			EditVendorClientDrawerClosedAction,
			GlobalState,
			null,
			EditVendorClientDrawerClosedAction
		>
	>;
}

export const VendorClientActionCreators: VendorClientDispatch = {
	showVendorClientLoader: () => {
		return (dispatch: Dispatch): ShowVendorClientLoaderAction => {
			const showVendorClientLoaderAction: ShowVendorClientLoaderAction = {
				type: VendorClientActionTypes.SHOW_VENDOR_CLIENT_LOADER
			};
			return dispatch(showVendorClientLoaderAction);
		};
	},
	hideVendorClientLoader: () => {
		return (dispatch: Dispatch): HideVendorClientLoaderAction => {
			const hideVendorClientLoaderAction: HideVendorClientLoaderAction = {
				type: VendorClientActionTypes.HIDE_VENDOR_CLIENT_LOADER
			};
			return dispatch(hideVendorClientLoaderAction);
		};
	},
	getVendorClientDetails: (clientId: string) => {
		return async (
			dispatch: Dispatch
		): Promise<VendorClientDetailsSuccessfulAction> => {
			const vendorClientDetailsRequestedAction: VendorClientDetailsRequestedAction =
				{
					type: VendorClientActionTypes.VENDOR_CLIENT_DETAILS_REQUESTED
				};
			dispatch(vendorClientDetailsRequestedAction);
			try {
				const vendorClientDetailsResponse = await getClientDetails(
					clientId
				);
				const vendorlientDetailsSuccessfulAction: VendorClientDetailsSuccessfulAction =
					{
						type: VendorClientActionTypes.VENDOR_CLIENT_DETAILS_SUCCESSFUL,
						currentActiveVendorClient: vendorClientDetailsResponse
					};
				return dispatch(vendorlientDetailsSuccessfulAction);
			} catch (error) {
				const vendorClientDetailsFailedAction: VendorClientDetailsFailedAction =
					{
						type: VendorClientActionTypes.VENDOR_CLIENT_DETAILS_FAILED
					};
				dispatch(vendorClientDetailsFailedAction);

				return Promise.reject(error);
			}
		};
	},
	showEditVendorClientDrawer: () => {
		return (dispatch: Dispatch): EditVendorClientDrawerOpenedAction => {
			const editVendorClientDrawerOpenedAction: EditVendorClientDrawerOpenedAction =
				{
					type: VendorClientActionTypes.EDIT_VENDOR_CLIENT_DRAWER_OPENED
				};
			return dispatch(editVendorClientDrawerOpenedAction);
		};
	},
	hideEditVendorClientDrawer: () => {
		return (dispatch: Dispatch): EditVendorClientDrawerClosedAction => {
			const editVendorClientDrawerClosedAction: EditVendorClientDrawerClosedAction =
				{
					type: VendorClientActionTypes.EDIT_VENDOR_CLIENT_DRAWER_CLOSED
				};
			return dispatch(editVendorClientDrawerClosedAction);
		};
	}
};
