'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.VendorComissionsActionTypes = void 0;
(function (VendorComissionsActionTypes) {
  VendorComissionsActionTypes["SHOW_VENDOR_COMISSIONS_LOADER"] = "SHOW_VENDOR_COMISSIONS_LOADER";
  VendorComissionsActionTypes["HIDE_VENDOR_COMISSIONS_LOADER"] = "HIDE_VENDOR_COMISSIONS_LOADER";
  VendorComissionsActionTypes["OPEN_EDIT_VENDOR_COMISSIONS_DRAWER"] = "OPEN_EDIT_VENDOR_COMISSIONS_DRAWER";
  VendorComissionsActionTypes["CLOSE_EDIT_VENDOR_COMISSIONS_DRAWER"] = "CLOSE_EDIT_VENDOR_COMISSIONS_DRAWER";
})(exports.VendorComissionsActionTypes || (exports.VendorComissionsActionTypes = {}));
var VendorComissionActionCreators = {
  showVendorComissionLoader: function showVendorComissionLoader() {
    return function (dispatch) {
      var showVendorOpportunityLoaderAction = {
        type: exports.VendorComissionsActionTypes.SHOW_VENDOR_COMISSIONS_LOADER
      };
      return dispatch(showVendorOpportunityLoaderAction);
    };
  },
  hideVendorComissionLoader: function hideVendorComissionLoader() {
    return function (dispatch) {
      var hideVendorOpportunityLoaderAction = {
        type: exports.VendorComissionsActionTypes.HIDE_VENDOR_COMISSIONS_LOADER
      };
      return dispatch(hideVendorOpportunityLoaderAction);
    };
  },
  openEditVendorComissionDrawer: function openEditVendorComissionDrawer() {
    return function (dispatch) {
      var openOpportunityDrawerAction = {
        type: exports.VendorComissionsActionTypes.OPEN_EDIT_VENDOR_COMISSIONS_DRAWER
      };
      return dispatch(openOpportunityDrawerAction);
    };
  },
  closeEditVendorComissionDrawer: function closeEditVendorComissionDrawer() {
    return function (dispatch) {
      var closeOpportunityDrawerAction = {
        type: exports.VendorComissionsActionTypes.CLOSE_EDIT_VENDOR_COMISSIONS_DRAWER
      };
      return dispatch(closeOpportunityDrawerAction);
    };
  }
};

exports.VendorComissionActionCreators = VendorComissionActionCreators;
exports["default"] = VendorComissionActionCreators;
