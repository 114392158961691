'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../node_modules/tslib/tslib.es6.mjs.js');
var Opportunity = require('@zomentum/contracts/dist/Opportunity');
var SalesActivity = require('@zomentum/contracts/dist/Others/SalesActivity');
var common$1 = require('@zomentum/contracts/dist/common');
var _interface = require('@zomentum/atoms/dist/ZIcon/interface');
var _interface$1 = require('@zomentum/atoms/dist/ZColors/interface');
var common = require('../common.js');

var getOpportunityCardColorCodeClass = function (opportunityShareStatus) {
  switch (opportunityShareStatus) {
    case Opportunity.OpportunityShareStatus.WAITING:
      return "waiting-opportunity-color-code";
    case Opportunity.OpportunityShareStatus.ACCEPTED:
      return "accepted-opportunity-color-code";
    case Opportunity.OpportunityShareStatus.REJECTED:
      return "rejected-opportunity-color-code";
    case Opportunity.OpportunityShareStatus.SHARED_BY_PARTNER:
    case Opportunity.OpportunityShareStatus.SHARED_BY_VENDOR:
      return "vendor-opportunity-color-code";
    default:
      return "self-opportunity-color-code";
  }
};
var getIconPropsBasedOnShareStatus = function (status) {
  switch (status) {
    case Opportunity.OpportunityShareStatus.WAITING:
      return {
        name: _interface.EIcons.CLOCK,
        color: _interface$1.EColors.CYAN
      };
    case Opportunity.OpportunityShareStatus.ACCEPTED:
      return {
        name: _interface.EIcons.CHECKCIRCLE,
        color: _interface$1.EColors.GREEN
      };
    case Opportunity.OpportunityShareStatus.REJECTED:
      return {
        name: _interface.EIcons.BLOCK,
        color: _interface$1.EColors.RED
      };
    default:
      return {
        name: _interface.EIcons.CHECKCIRCLE,
        color: _interface$1.EColors.GEEKBLUE
      };
  }
};
var getDisplayNameBasedOnShareStatus = function (status) {
  var isPartnerAlign = common.isVendorApp();
  switch (status) {
    case Opportunity.OpportunityShareStatus.SHARED_BY_PARTNER:
      return "Shared By Partner";
    case Opportunity.OpportunityShareStatus.SHARED_BY_VENDOR:
      return "Shared By Vendor";
    default:
      return "Shared With ".concat(isPartnerAlign ? "Partner" : "Vendor");
  }
};
var getAlertClassNameBasedOnShareStatus = function (status) {
  switch (status) {
    case Opportunity.OpportunityShareStatus.WAITING:
      return "bg-cyan-1 border-cyan-3";
    case Opportunity.OpportunityShareStatus.ACCEPTED:
      return "bg-green-1 border-green-3";
    case Opportunity.OpportunityShareStatus.REJECTED:
      return "bg-red-1 border-red-3";
    default:
      return "bg-geekBlue-1 border-geekBlue-3";
  }
};
var getTooltipTextBasedOnShareStatus = function (status, sharedAt) {
  switch (status) {
    case Opportunity.OpportunityShareStatus.WAITING:
      return "Waiting to Accept".concat(!!(sharedAt === null || sharedAt === void 0 ? void 0 : sharedAt.length) ? ". Shared on ".concat(sharedAt) : "");
    case Opportunity.OpportunityShareStatus.ACCEPTED:
    case Opportunity.OpportunityShareStatus.SHARED_BY_PARTNER:
    case Opportunity.OpportunityShareStatus.SHARED_BY_VENDOR:
      return "Accepted";
    case Opportunity.OpportunityShareStatus.REJECTED:
      return "Rejected";
    default:
      return "";
  }
};
var getTooltipTextBasedOnShareStatusOppDetails = function (status, sharedAt) {
  var isPartnerAlign = common.isVendorApp();
  switch (status) {
    case Opportunity.OpportunityShareStatus.WAITING:
      return "".concat(!!(sharedAt === null || sharedAt === void 0 ? void 0 : sharedAt.length) ? "Shared on: ".concat(sharedAt, ". ") : "", "Waiting to Accept. Updates made won\u2019t be visible to ").concat(isPartnerAlign ? "partner" : "vendor", " until they accept the opportunity.");
    case Opportunity.OpportunityShareStatus.ACCEPTED:
    case Opportunity.OpportunityShareStatus.SHARED_BY_PARTNER:
    case Opportunity.OpportunityShareStatus.SHARED_BY_VENDOR:
      return "Accepted";
    case Opportunity.OpportunityShareStatus.REJECTED:
      return "Rejected. Please reassign opportunity to different ".concat(isPartnerAlign ? "partner" : "vendor", ".");
    default:
      return "";
  }
};
var getUpdatedFiltersWithPipelineFilters = function (pipelineId, oldFilterCriterion) {
  if (oldFilterCriterion === void 0) {
    oldFilterCriterion = [];
  }
  var pipelineFilter = {
    field_display_name: "",
    filter_entity_type: common$1.ZomentumEntities.Opportunity,
    field_name: "opportunity_pipeline_id",
    filter_operator: {
      id_value: SalesActivity.SalesActivityAutomationMetadataFilterOperator.Is,
      display_value: "Is",
      value_type: null
    },
    values: [{
      id_value: pipelineId !== null && pipelineId !== void 0 ? pipelineId : "",
      display_value: "",
      value_type: null
    }],
    linked_entity_type: null
  };
  var pipelineFilterIndex = oldFilterCriterion === null || oldFilterCriterion === void 0 ? void 0 : oldFilterCriterion.findIndex(function (filter) {
    return filter.field_name === "opportunity_pipeline_id";
  });
  var updatedFilters = oldFilterCriterion;
  if (pipelineFilterIndex > -1) {
    var clonedFilters = tslib_es6.__spreadArray([], updatedFilters, true);
    clonedFilters.splice(pipelineFilterIndex, 0, pipelineFilter);
    updatedFilters = tslib_es6.__spreadArray([], clonedFilters, true);
  } else {
    updatedFilters = tslib_es6.__spreadArray(tslib_es6.__spreadArray([], updatedFilters, true), [pipelineFilter], false);
  }
  return updatedFilters;
};
var dummyAllPipeline = {
  id: "00000000000000000000000000000000",
  created: "2021-03-30T13:20:12.459Z",
  updated: "2023-11-15T06:07:55.696Z",
  name: "All Pipelines",
  company_id: "00000000000000000000000000000000",
  opportunity_stages: [],
  user_id: "00000000000000000000000000000000",
  is_default: false,
  connected_sync_sources: []
};

exports.dummyAllPipeline = dummyAllPipeline;
exports.getAlertClassNameBasedOnShareStatus = getAlertClassNameBasedOnShareStatus;
exports.getDisplayNameBasedOnShareStatus = getDisplayNameBasedOnShareStatus;
exports.getIconPropsBasedOnShareStatus = getIconPropsBasedOnShareStatus;
exports.getOpportunityCardColorCodeClass = getOpportunityCardColorCodeClass;
exports.getTooltipTextBasedOnShareStatus = getTooltipTextBasedOnShareStatus;
exports.getTooltipTextBasedOnShareStatusOppDetails = getTooltipTextBasedOnShareStatusOppDetails;
exports.getUpdatedFiltersWithPipelineFilters = getUpdatedFiltersWithPipelineFilters;
