'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

// 1mm  = 3.7795 px
var PX_PER_MM = 3.7795;
exports.EditorType = void 0;
(function (EditorType) {
  EditorType["GROW_DOCUMENT"] = "grewDocument";
  EditorType["GROW_TEMPLATE"] = "growTemplate";
  EditorType["PARTNER_ALIGN_TEMPLATE"] = "partnerAlignTemplate";
})(exports.EditorType || (exports.EditorType = {}));

exports.PX_PER_MM = PX_PER_MM;
