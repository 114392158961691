import {
	VendorTemplateActions,
	VendorTemplateActionTypes
} from "@/Actions/Vendor/Template";
import {
	VendorTemplateDetail,
	VendorTemplateSidePanelEnum
} from "@zomentum/contracts/dist/Vendor/Templates";
import { Reducer } from "redux";
import { CustomView, CustomViewResponse } from "@zomentum/contracts/dist/UI";

export class VendorTemplateState {
	readonly isTemplateSidePanelOpen: boolean = true;
	readonly isLoading: boolean = false;
	readonly currentActiveTemplate: VendorTemplateDetail | null = null;
	readonly activeTemplatePanel: VendorTemplateSidePanelEnum | null = null;
	readonly isTemplatePreviewModalOpen: boolean = false;
	readonly viewList: CustomViewResponse | null = null;
	readonly currentActiveView: CustomView | null = null;
	readonly isViewChanged: boolean = false;
}

export const initialVendorTemplateState: VendorTemplateState = {
	isTemplateSidePanelOpen: true,
	isLoading: false,
	currentActiveTemplate: null,
	activeTemplatePanel: VendorTemplateSidePanelEnum.EDIT,
	isTemplatePreviewModalOpen: false,
	viewList: null,
	currentActiveView: null,
	isViewChanged: false
};

export const VendorTemplateReducer: Reducer<
	VendorTemplateState,
	VendorTemplateActions
> = (
	state: VendorTemplateState = initialVendorTemplateState,
	action: VendorTemplateActions
) => {
	switch (action.type) {
		case VendorTemplateActionTypes.SHOW_VENDOR_TEMPLATE_LOADER:
			return {
				...state,
				isLoading: true
			};
		case VendorTemplateActionTypes.HIDE_VENDOR_TEMPLATE_LOADER:
			return {
				...state,
				isLoading: false
			};
		case VendorTemplateActionTypes.VENDOR_TEMPLATE_DETAILS_SUCCESSFUL:
			return {
				...state,
				currentActiveTemplate: action.currentActiveTemplate
			};
		case VendorTemplateActionTypes.SET_TEMPLATE_SIDE_PANEL: {
			return { ...state, activeTemplatePanel: action.templateSidePanel };
		}
		case VendorTemplateActionTypes.UPDATE_VENDOR_TEMPLATE_DETAILS_SUCCESSFUL:
			return {
				...state,
				currentActiveTemplate: action.currentActiveTemplate
			};
		case VendorTemplateActionTypes.SHOW_PREVIEW_VENDOR_TEMPLATE_MODAL:
			return {
				...state,
				isTemplatePreviewModalOpen: true
			};
		case VendorTemplateActionTypes.HIDE_PREVIEW_VENDOR_TEMPLATE_MODAL:
			return {
				...state,
				isTemplatePreviewModalOpen: false
			};
		case VendorTemplateActionTypes.OPEN_TEMPLATE_SIDE_PANEL: {
			return {
				...state,
				isTemplateSidePanelOpen: true
			};
		}
		case VendorTemplateActionTypes.CLOSE_TEMPLATE_SIDE_PANEL: {
			return {
				...state,
				isTemplateSidePanelOpen: false
			};
		}
		case VendorTemplateActionTypes.FETCH_ALL_TEMPLATE_VIEWS_REQUESTED: {
			return state;
		}
		case VendorTemplateActionTypes.FETCH_ALL_TEMPLATE_VIEWS_SUCCESSFUL: {
			return {
				...state,
				viewList: action.viewList
			};
		}
		case VendorTemplateActionTypes.FETCH_ALL_TEMPLATE_VIEWS_FAILED: {
			return state;
		}
		case VendorTemplateActionTypes.CREATE_TEMPLATE_VIEW_REQUESTED: {
			return state;
		}
		case VendorTemplateActionTypes.CREATE_TEMPLATE_VIEW_SUCCESSFUL: {
			return {
				...state,
				currentActiveView: action.view,
				viewList: state.viewList
					? {
							...state.viewList,
							data: [...(state.viewList?.data ?? []), action.view]
					  }
					: state.viewList,
				isViewChanged: false
			};
		}
		case VendorTemplateActionTypes.CREATE_TEMPLATE_VIEW_FAILED: {
			return state;
		}
		case VendorTemplateActionTypes.UPDATE_TEMPLATE_VIEW_REQUESTED: {
			return state;
		}
		case VendorTemplateActionTypes.UPDATE_TEMPLATE_VIEW_SUCCESSFUL: {
			return {
				...state,
				currentActiveView: action.view,
				viewList: state.viewList
					? {
							...state.viewList,
							data: state.viewList?.data?.map(view =>
								view.id === action.view.id ? action.view : view
							)
					  }
					: state.viewList,
				isViewChanged: false
			};
		}
		case VendorTemplateActionTypes.UPDATE_TEMPLATE_VIEW_FAILED: {
			return state;
		}
		case VendorTemplateActionTypes.DELETE_TEMPLATE_VIEW_REQUESTED: {
			return state;
		}
		case VendorTemplateActionTypes.DELETE_TEMPLATE_VIEW_SUCCESSFUL: {
			return {
				...state,
				currentActiveView: action.initialView,
				viewList: state.viewList
					? {
							...state.viewList,
							data: state.viewList?.data?.filter(
								view => view.id !== action.deletedView.id
							)
					  }
					: state.viewList,
				isViewChanged: false
			};
		}
		case VendorTemplateActionTypes.DELETE_TEMPLATE_VIEW_FAILED: {
			return state;
		}
		case VendorTemplateActionTypes.UPDATE_TEMPLATE_CURRENT_ACTIVE_VIEW: {
			return {
				...state,
				currentActiveView: action.currentActiveView,
				isViewChanged: action.isViewChanged
			};
		}
		case VendorTemplateActionTypes.TEMPLATE_DETAILS_REQUESTED: {
			return state;
		}
		case VendorTemplateActionTypes.TEMPLATE_DETAILS_SUCCESSFUL: {
			return {
				...state,
				currentActiveTemplate: action.currentActiveTemplate
			};
		}
		case VendorTemplateActionTypes.TEMPLATE_DETAILS_FAILED: {
			return state;
		}
		case VendorTemplateActionTypes.RESET_TEMPLATE_CURRENT_ACTIVE_VIEW: {
			return {
				...state,
				currentActiveView: null,
				isViewChanged: false,
				viewList: null,
				viewMetadata: null
			};
		}
		default:
			return state;
	}
};

export default VendorTemplateReducer;
