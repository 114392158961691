import { Reducer } from "redux";

import {
	CustomField,
	CustomFieldCountPartnerAlign
} from "@zomentum/contracts/dist/Settings";

import {
	CustomFieldsActions,
	CustomFieldsActionTypes,
	CountCustomFieldsActionTypes,
	CustomFieldsOperationActionTypes
} from "@/Actions/CustomFields";

export interface CustomFieldsState {
	readonly customFieldList: {
		vendor_opportunity: CustomField[];
		partner_metadata: CustomField[];
		vendor_client_company: CustomField[];
		vendor_client_user: CustomField[];
		marketplace_sku: CustomField[];
		log_call: CustomField[];
		template: CustomField[];
	};
	readonly customFieldCount: CustomFieldCountPartnerAlign;
	readonly customFieldIdMapping: Record<string, unknown>;
}

export const initialCustomFieldsState: CustomFieldsState = {
	customFieldList: {
		vendor_opportunity: new Array<CustomField>(),
		partner_metadata: new Array<CustomField>(),
		vendor_client_company: new Array<CustomField>(),
		vendor_client_user: new Array<CustomField>(),
		marketplace_sku: new Array<CustomField>(),
		log_call: new Array<CustomField>(),
		template: new Array<CustomField>()
	},
	customFieldCount: new CustomFieldCountPartnerAlign(),
	customFieldIdMapping: {}
};

export const customFieldIdMapping = (
	customFields: CustomField[]
): Record<string, CustomField[]> => {
	const reverseIdCustomFieldMapping: Record<string, CustomField[]> = {};

	Object.entries(customFields).forEach(([_, value]: any) => {
		reverseIdCustomFieldMapping[value.id] = value;
	});

	return reverseIdCustomFieldMapping;
};

export const CustomFieldsReducer: Reducer<
	CustomFieldsState,
	CustomFieldsActions
> = (
	state: CustomFieldsState = initialCustomFieldsState,
	action: CustomFieldsActions
): CustomFieldsState => {
	switch (action.type) {
		case CustomFieldsActionTypes.VENDOR_OPPORTUNITY_CUSTOM_FIELDS_SUCCESSFUL: {
			const opportunityIdMapping = customFieldIdMapping(
				action.customFieldList
			);

			return {
				...state,
				customFieldList: {
					...state.customFieldList,
					vendor_opportunity: action.customFieldList
				},
				customFieldIdMapping: {
					...state.customFieldIdMapping,
					...opportunityIdMapping
				}
			};
		}

		case CustomFieldsActionTypes.VENDOR_PARTNER_CUSTOM_FIELDS_SUCCESSFUL: {
			const clientIdMapping = customFieldIdMapping(
				action.customFieldList
			);
			return {
				...state,
				customFieldList: {
					...state.customFieldList,
					partner_metadata: action.customFieldList
				},
				customFieldIdMapping: {
					...state.customFieldIdMapping,
					...clientIdMapping
				}
			};
		}

		case CustomFieldsActionTypes.VENDOR_CLIENTS_CONTACTS_CUSTOM_FIELDS_SUCCESSFUL: {
			const contactsIdMapping = customFieldIdMapping(
				action.customFieldList
			);
			return {
				...state,
				customFieldList: {
					...state.customFieldList,
					vendor_client_user: action.customFieldList
				},
				customFieldIdMapping: {
					...state.customFieldIdMapping,
					...contactsIdMapping
				}
			};
		}

		case CustomFieldsActionTypes.VENDOR_MARKETPLACE_SKU_CUSTOM_FIELDS_SUCCESSFUL: {
			const documentIdMapping = customFieldIdMapping(
				action.customFieldList
			);
			return {
				...state,
				customFieldList: {
					...state.customFieldList,
					marketplace_sku: action.customFieldList
				},
				customFieldIdMapping: {
					...state.customFieldIdMapping,
					...documentIdMapping
				}
			};
		}

		case CustomFieldsActionTypes.VENDOR_SALES_LOG_CALL_CUSTOM_FIELDS_SUCCESSFUL: {
			const productIdMapping = customFieldIdMapping(
				action.customFieldList
			);
			return {
				...state,
				customFieldList: {
					...state.customFieldList,
					log_call: action.customFieldList
				},
				customFieldIdMapping: {
					...state.customFieldIdMapping,
					...productIdMapping
				}
			};
		}

		case CustomFieldsActionTypes.VENDOR_CLIENTS_CUSTOM_FIELDS_SUCCESSFUL: {
			const productIdMapping = customFieldIdMapping(
				action.customFieldList
			);
			return {
				...state,
				customFieldList: {
					...state.customFieldList,
					vendor_client_company: action.customFieldList
				},
				customFieldIdMapping: {
					...state.customFieldIdMapping,
					...productIdMapping
				}
			};
		}

		case CountCustomFieldsActionTypes.COUNT_CUSTOM_FIELD_LIST_SUCCESSFUL: {
			return {
				...state,
				customFieldCount: action.customFieldCount
			};
		}

		case CustomFieldsOperationActionTypes.REORDER_CUSTOM_FIELDS_SUCCESSFUL: {
			return {
				...state,
				customFieldList: {
					...state.customFieldList,
					[action.entityType]: action.customFieldList
				}
			};
		}

		default: {
			return state;
		}
	}
};

export default CustomFieldsReducer;
