'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index = require('../Assessments/index.js');
var index$1 = require('../Documents/index.js');

var HealthStatusVulnerabilitiesData = /** @class */function () {
  function HealthStatusVulnerabilitiesData() {
    this.category = "";
    this.category_health = 0;
    this.issues = new Array();
  }
  return HealthStatusVulnerabilitiesData;
}();
exports.QbrHealthStatusEnum = void 0;
(function (QbrHealthStatusEnum) {
  QbrHealthStatusEnum["CAUTION"] = "caution";
  QbrHealthStatusEnum["GOLD_STANDARD"] = "gold_standard";
  QbrHealthStatusEnum["HAZARD"] = "hazard";
  QbrHealthStatusEnum["HEALTHY"] = "healthy";
  QbrHealthStatusEnum["INSUFFICIENT_DATA"] = "insufficient_data";
  QbrHealthStatusEnum["NOT_APPLICABLE"] = "not_applicable";
  QbrHealthStatusEnum["WORK_IN_PROGRESS"] = "work_in_progress";
})(exports.QbrHealthStatusEnum || (exports.QbrHealthStatusEnum = {}));
var QbrIssue = /** @class */function () {
  function QbrIssue() {
    this.budget = 0;
    this.category = "";
    this.health = null;
    this.issue = "";
    this.severity = null;
    this.likelihood = null;
    this.quarter = "";
    this.risk = "";
    this.solution = "";
    this.status = index.IssueStatusEnum.OPEN;
    this.target_year = "";
    this.possible_risk_values = null;
    this.possible_status_values = null;
    this.possible_solution_values = null;
  }
  return QbrIssue;
}();
var HealthStatusDataLog = /** @class */function () {
  function HealthStatusDataLog() {
    this.id = "";
    this.created = new Date();
    this.updated = new Date();
    this.type = "";
    this.company_id = "";
    this.data = null;
    this.hide = false;
  }
  return HealthStatusDataLog;
}();
var EditQbrDataLogRequest = /** @class */function () {
  function EditQbrDataLogRequest() {
    this.id = "";
  }
  return EditQbrDataLogRequest;
}();
var HealthStatusCategory = /** @class */function () {
  function HealthStatusCategory() {
    this.category_id = "";
    this.name = "";
    this.filter_on_status = null;
    this.sub_category_list = new Array();
    this.category_health = exports.QbrHealthStatusEnum.INSUFFICIENT_DATA;
  }
  return HealthStatusCategory;
}();
var Qbr = /** @class */function () {
  function Qbr() {
    this.category_qbr_logs = {
      data: new Array()
    };
    this.company = null;
    this.company_id = "";
    this.created = new Date();
    this.document_id = "";
    this.health_status_data_list = new Array();
    this.id = "";
    this.legend_labels = [];
    this.likelihood_labels = [];
    this.likelihood_header_label = "";
    this.name = "";
    this.recipients = [];
    this.representation_style = index$1.QbrRepresentationStyle.BOTH;
    this.severity_header_label = "";
    this.severity_labels = [];
    this.updated = new Date();
    this.user_id = "";
    this.user = null;
  }
  return Qbr;
}();

exports.EditQbrDataLogRequest = EditQbrDataLogRequest;
exports.HealthStatusCategory = HealthStatusCategory;
exports.HealthStatusDataLog = HealthStatusDataLog;
exports.HealthStatusVulnerabilitiesData = HealthStatusVulnerabilitiesData;
exports.Qbr = Qbr;
exports.QbrIssue = QbrIssue;
