'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var utils = require('@zomentum/utils');
var useFeature = require('./useFeature.js');

var useNewQuoteBlockDesign = function (isQuoteBlockRevamped) {
  var isQuoteBlockRevampEnabled = useFeature.useFeatureFlag("quote_block_revamp");
  return isQuoteBlockRevampEnabled && isQuoteBlockRevamped && utils.isPartnerApp();
};
var useNewQuoteBlockDesign$1 = useNewQuoteBlockDesign;

exports["default"] = useNewQuoteBlockDesign$1;
