import { ZButton, ZPopover, ZRadioGroup, ZTypography } from "@zomentum/atoms";
import { EFontWeights } from "@zomentum/atoms/dist/ZTypography/interface";
import {
	getProductCatalogResultDisplayPrice,
	ProductSource
} from "@zomentum/contracts";
import { currencyFormatter } from "@zomentum/utils";
import { RadioChangeEvent } from "antd";
import React, { FC, useState } from "react";
import { IPricingPopover } from "./interface";
import "./index.less";

export const PricingPopover: FC<IPricingPopover> = ({
	pricingDetails,
	selectedPricing,
	onChange
}) => {
	const [visible, setVisible] = useState(false);

	const handleChange = (e: RadioChangeEvent) => {
		setVisible(false);
		onChange(e.target.value);
	};

	return (
		<div className="mt-2" onClick={e => e.stopPropagation()}>
			<ZPopover
				trigger="click"
				placement="bottomRight"
				overlayClassName="sku-pricing-popover"
				size="rg"
				onVisibleChange={setVisible}
				visible={visible}
				mouseLeaveDelay={0.4}
				content={
					<ZRadioGroup
						defaultValue={selectedPricing.id}
						onChange={handleChange}
						vertical
						className="w-full"
						spaceClassName="w-full"
						space={12}
						isRadioLabelWidthMax={false}
						options={pricingDetails.map(pricing => ({
							value: pricing.id ?? "",
							label: (
								<div className="flex w-full justify-between">
									<ZTypography.ZText className="mr-2">
										{pricing.name}
									</ZTypography.ZText>
									<ZTypography.ZText
										weight={EFontWeights.MEDIUM}
										level={1}
										className="text-right"
									>
										{currencyFormatter(
											getProductCatalogResultDisplayPrice(
												pricing,
												ProductSource.ZomentumMarketplace
											)
										)}
									</ZTypography.ZText>
								</div>
							)
						}))}
					/>
				}
			>
				<ZButton className="text-0 w-full" size="small">
					Select more prices
				</ZButton>
			</ZPopover>
		</div>
	);
};
