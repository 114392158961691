'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index = require('../User/index.js');

// TRANSACTIONS
exports.TransactionStatus = void 0;
(function (TransactionStatus) {
  TransactionStatus["PENDING"] = "pending";
  TransactionStatus["PAID"] = "paid";
  TransactionStatus["COLLECTED"] = "collected";
  TransactionStatus["FAILED"] = "failed";
  TransactionStatus["INPROGRESS"] = "in_progress";
  TransactionStatus["EXPORTED"] = "exported";
})(exports.TransactionStatus || (exports.TransactionStatus = {}));
exports.TransactionType = void 0;
(function (TransactionType) {
  TransactionType["COLLECTION"] = "collect";
  TransactionType["PAY"] = "pay";
})(exports.TransactionType || (exports.TransactionType = {}));
var TransactionCount = /** @class */function () {
  function TransactionCount() {
    this.all = 0;
  }
  return TransactionCount;
}();
var TransactionResponse = /** @class */function () {
  function TransactionResponse() {
    this.data = new Array();
    this.filtered_count_map = new TransactionCount();
  }
  return TransactionResponse;
}();
var ExportTransactionsResponse = /** @class */function () {
  function ExportTransactionsResponse(currency, payment_amount, recipient_identifier, recipient_wallet) {
    this.currency = currency;
    this.payment_amount = payment_amount;
    this.recipient_identifier = recipient_identifier;
    this.recipient_wallet = recipient_wallet;
  }
  return ExportTransactionsResponse;
}();
var Transaction = /** @class */function () {
  function Transaction(id, transaction_identifier, created, updated, deleted, partner, partner_company_id, status, type, due_date, amount, commission_record_ids, vendor_clients, is_system_generated, description, document_key, invoices, vendor, document_name) {
    this.id = "";
    this.amount = 0;
    this.id = id;
    this.transaction_identifier = transaction_identifier;
    this.created = created;
    this.updated = updated;
    this.deleted = deleted;
    this.partner = partner;
    this.partner_company_id = partner_company_id;
    this.status = status;
    this.type = type;
    this.due_date = due_date;
    this.amount = amount;
    this.commission_record_ids = commission_record_ids;
    this.vendor_clients = vendor_clients;
    this.is_system_generated = is_system_generated;
    this.description = description;
    this.document_key = document_key;
    this.invoices = invoices;
    this.vendor = vendor;
    this.document_name = document_name;
  }
  return Transaction;
}();
var TransactionRequest = /** @class */function () {
  function TransactionRequest(id) {
    this.filters = new Array();
    this.included_child_entities = [];
    this.partnerId = id;
  }
  return TransactionRequest;
}();
var AddOrEditTransactionRequest = /** @class */function () {
  function AddOrEditTransactionRequest(type, partner_company_id, amount, description, file_key, file_name, due_date, status) {
    this.amount = 0;
    this.description = "";
    this.type = type;
    this.partner_company_id = partner_company_id;
    this.amount = amount;
    this.description = description;
    this.file_key = file_key;
    this.file_name = file_name;
    this.due_date = due_date;
    this.status = status;
  }
  return AddOrEditTransactionRequest;
}();
var CommissionBreakup = /** @class */function () {
  function CommissionBreakup(commission_amount, commission_percent, commission_record, commission_record_id, created, deleted, id, invoice_id, partner, partner_company_id, total_amount, transaction, transaction_id, type, updated, vendor_client_company, vendor_client_company_id, vendor_company_id, client_company) {
    this.commission_amount = commission_amount;
    this.commission_percent = commission_percent;
    this.commission_record = commission_record;
    this.commission_record_id = commission_record_id;
    this.created = created;
    this.deleted = deleted;
    this.id = id;
    this.invoice_id = invoice_id;
    this.partner = partner;
    this.partner_company_id = partner_company_id;
    this.total_amount = total_amount;
    this.transaction = transaction;
    this.transaction_id = transaction_id;
    this.type = type;
    this.updated = updated;
    this.vendor_client_company = vendor_client_company;
    this.vendor_client_company_id = vendor_client_company_id;
    this.vendor_company_id = vendor_company_id;
    this.client_company = client_company;
  }
  return CommissionBreakup;
}();
var CommissionBreakupResponse = /** @class */function () {
  function CommissionBreakupResponse() {
    this.data = new Array();
    this.filtered_count_map = new CommissionRecordsCount();
  }
  return CommissionBreakupResponse;
}();
exports.CommissionTypes = void 0;
(function (CommissionTypes) {
  CommissionTypes["ONETIME"] = "one_time";
  CommissionTypes["RECURRING"] = "recurring";
  CommissionTypes["ONETIMEANDRECURRING"] = "one_time_and_recurring";
})(exports.CommissionTypes || (exports.CommissionTypes = {}));
// RECURRING COMMISSION RECORDS
var CommissionRecordsCount = /** @class */function () {
  function CommissionRecordsCount() {
    this.all = 0;
  }
  return CommissionRecordsCount;
}();
var CommissionRecordsResponse = /** @class */function () {
  function CommissionRecordsResponse() {
    this.data = new Array();
    this.filtered_count_map = new CommissionRecordsCount();
  }
  return CommissionRecordsResponse;
}();
var CommissionRecord = /** @class */function () {
  function CommissionRecord(id, commission_record_identifier, created, updated, deleted, creator, partner, vendor_client_company, commission_rule_id, commission_percent, is_system_generated, estimated_invoice_amount, vendor_company_id, partner_company_id, vendor_client_company_id, commission_rule_details, vendor, client_company) {
    this.id = id;
    this.commission_record_identifier = commission_record_identifier;
    this.created = created;
    this.updated = updated;
    this.deleted = deleted;
    this.creator = creator;
    this.partner = partner;
    this.vendor_client_company = vendor_client_company;
    this.commission_rule_id = commission_rule_id;
    this.commission_percent = commission_percent;
    this.is_system_generated = is_system_generated;
    this.estimated_invoice_amount = estimated_invoice_amount;
    this.vendor_company_id = vendor_company_id;
    this.partner_company_id = partner_company_id;
    this.vendor_client_company_id = vendor_client_company_id;
    this.commission_rule_details = commission_rule_details;
    this.vendor = vendor;
    this.client_company = client_company;
  }
  return CommissionRecord;
}();
var AddOrEditCommissionRecordRequest = /** @class */function () {
  function AddOrEditCommissionRecordRequest(partner_company_id, vendor_client_company_id, percentage) {
    this.partner_company_id = partner_company_id;
    this.vendor_client_company_id = vendor_client_company_id;
    this.percentage = percentage;
  }
  return AddOrEditCommissionRecordRequest;
}();
// INVOICES
var Invoice = /** @class */function () {
  function Invoice(id, created, updated, deleted, vendor_company_id, creator_user_id, invoice_id, billed_to, entity_id, amount, partner, vendor_client_company, creator, allow_transaction_creation, commissions) {
    this.id = "";
    this.id = id;
    this.created = created;
    this.updated = updated;
    this.deleted = deleted;
    this.vendor_company_id = vendor_company_id;
    this.creator_user_id = creator_user_id;
    this.invoice_id = invoice_id;
    this.billed_to = billed_to;
    this.entity_id = entity_id;
    this.amount = amount;
    this.partner = partner;
    this.vendor_client_company = vendor_client_company;
    this.creator = creator;
    this.allow_transaction_creation = allow_transaction_creation;
    this.commissions = commissions;
  }
  return Invoice;
}();
var InvoiceCount = /** @class */function () {
  function InvoiceCount() {
    this.all = 0;
  }
  return InvoiceCount;
}();
var InvoicesResponse = /** @class */function () {
  function InvoicesResponse() {
    this.data = new Array();
    this.filtered_count_map = new InvoiceCount();
  }
  return InvoicesResponse;
}();
var AddInvoiceRequest = /** @class */function () {
  function AddInvoiceRequest(billed_to, entity_id, amount, create_transaction) {
    this.billed_to = billed_to;
    this.entity_id = entity_id;
    this.amount = amount;
    this.amount = amount;
    this.create_transaction = create_transaction;
  }
  return AddInvoiceRequest;
}();
// COMMISSION RULES
exports.CommissionRuleStatus = void 0;
(function (CommissionRuleStatus) {
  CommissionRuleStatus["ACTIVE"] = "active";
  CommissionRuleStatus["INACTIVE"] = "inactive";
})(exports.CommissionRuleStatus || (exports.CommissionRuleStatus = {}));
var CommissionRuleCommissionDetails = /** @class */function () {
  function CommissionRuleCommissionDetails() {
    this.commission_percent = 0;
    this.commission_type = exports.CommissionTypes.ONETIME;
    this.commission_value = 0;
  }
  return CommissionRuleCommissionDetails;
}();
var CommissionRule = /** @class */function () {
  function CommissionRule() {
    this.actions = [];
    this.commission_details = new CommissionRuleCommissionDetails();
    this.company_id = "";
    this.vendor_company_id = "";
    this.created = new Date();
    this.updated = new Date();
    this.creator_user = new index.VendorUserData();
    this.creator_user_id = "";
    this.deleted = false;
    this.description = "";
    this.filter_criteria = [];
    this.filter_criteria_combiner = null;
    this.id = "";
    this.is_enabled = true;
    this.is_system_generated = false;
    this.last_triggered = null;
    this.times_triggered = null;
    this.title = "";
    this.trigger_entity_type = null;
    this.trigger_action_type = null;
  }
  return CommissionRule;
}();
var CommissionRuleCount = /** @class */function () {
  function CommissionRuleCount() {
    this.all = 0;
  }
  return CommissionRuleCount;
}();
var CommissionRulesResponse = /** @class */function () {
  function CommissionRulesResponse() {
    this.data = new Array();
    this.filtered_count_map = new CommissionRuleCount();
  }
  return CommissionRulesResponse;
}();
var AddEditCommissionRuleRequest = /** @class */function () {
  function AddEditCommissionRuleRequest(filter_criteria, is_enabled, filter_criteria_combiner, title, description, commission_type, commission_percent, commission_value) {
    this.filter_criteria = new Array();
    this.is_enabled = false;
    this.filter_criteria_combiner = null;
    this.title = "";
    this.description = "";
    this.commission_type = exports.CommissionTypes.RECURRING;
    this.commission_percent = 0;
    this.commission_value = 0;
    this.filter_criteria = filter_criteria;
    this.is_enabled = is_enabled;
    this.filter_criteria_combiner = filter_criteria_combiner;
    this.title = title;
    this.description = description;
    this.commission_type = commission_type;
    this.commission_percent = commission_percent;
    this.commission_value = commission_value;
  }
  return AddEditCommissionRuleRequest;
}();
// BULK IMPORT
var ColumnData = /** @class */function () {
  function ColumnData() {
    this.name = "";
    this.dataType = "";
    this.is_mandatory = true;
    this.possible_values = [];
  }
  return ColumnData;
}();
var BulkImportMetadataResponse = /** @class */function () {
  function BulkImportMetadataResponse() {
    this.existing_columns = {};
    this.columns_to_map = [];
  }
  return BulkImportMetadataResponse;
}();
var MappedData = /** @class */function () {
  function MappedData() {
    this.column_name = "";
    this.valid_values = [];
    this.invalid_values = [];
    this.count = 0;
  }
  return MappedData;
}();
var ValidateColumnMappingResponse = /** @class */function () {
  function ValidateColumnMappingResponse() {
    this.data = [];
  }
  return ValidateColumnMappingResponse;
}();

exports.AddEditCommissionRuleRequest = AddEditCommissionRuleRequest;
exports.AddInvoiceRequest = AddInvoiceRequest;
exports.AddOrEditCommissionRecordRequest = AddOrEditCommissionRecordRequest;
exports.AddOrEditTransactionRequest = AddOrEditTransactionRequest;
exports.BulkImportMetadataResponse = BulkImportMetadataResponse;
exports.ColumnData = ColumnData;
exports.CommissionBreakup = CommissionBreakup;
exports.CommissionBreakupResponse = CommissionBreakupResponse;
exports.CommissionRecord = CommissionRecord;
exports.CommissionRecordsCount = CommissionRecordsCount;
exports.CommissionRecordsResponse = CommissionRecordsResponse;
exports.CommissionRule = CommissionRule;
exports.CommissionRuleCommissionDetails = CommissionRuleCommissionDetails;
exports.CommissionRuleCount = CommissionRuleCount;
exports.CommissionRulesResponse = CommissionRulesResponse;
exports.ExportTransactionsResponse = ExportTransactionsResponse;
exports.Invoice = Invoice;
exports.InvoiceCount = InvoiceCount;
exports.InvoicesResponse = InvoicesResponse;
exports.MappedData = MappedData;
exports.Transaction = Transaction;
exports.TransactionCount = TransactionCount;
exports.TransactionRequest = TransactionRequest;
exports.TransactionResponse = TransactionResponse;
exports.ValidateColumnMappingResponse = ValidateColumnMappingResponse;
