'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.MeetingChoice = void 0;
(function (MeetingChoice) {
  MeetingChoice["SCHEDULE"] = "schedule";
  MeetingChoice["LOG"] = "log";
})(exports.MeetingChoice || (exports.MeetingChoice = {}));
exports.TimePickerType = void 0;
(function (TimePickerType) {
  TimePickerType["Hour"] = "Hour";
  TimePickerType["Minute"] = "Minute";
  TimePickerType["Second"] = "Second";
})(exports.TimePickerType || (exports.TimePickerType = {}));
