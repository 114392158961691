'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var IllustrationsSprite$1 = require('../packages/config/assets/IllustrationsSprite.svg.js');
var _commonjsHelpers = require('./_commonjsHelpers.js');

var IllustrationsSprite = _commonjsHelpers.getCjsExportFromNamespace(IllustrationsSprite$1);

exports["default"] = IllustrationsSprite;
