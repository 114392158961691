'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../../node_modules/tslib/tslib.es6.mjs.js');
var common = require('../../common.js');
var index$1 = require('../User/index.js');
var index = require('../../Users/index.js');

var VendorEmailTemplateRequest = /** @class */function () {
  function VendorEmailTemplateRequest() {
    this.name = "";
    this.subject = "";
    this.body = "";
    this.file_details = new Array();
    this.custom_template_type = null;
    this.system_template_info = null;
  }
  return VendorEmailTemplateRequest;
}();
var VendorEmailTemplate = /** @class */function (_super) {
  tslib_es6.__extends(VendorEmailTemplate, _super);
  function VendorEmailTemplate() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.id = "";
    _this.name = "";
    _this.subject = "";
    _this.body = "";
    _this.file_details = new Array();
    _this.merge_tags_system_variables = new Array();
    _this.user = new index.UserData();
    _this.created = new Date();
    _this.updated = new Date();
    _this.custom_template_type = null;
    _this.deleted = false;
    _this.defaultTemplate = false;
    _this.system_template_info = null;
    _this.vendor = new index$1.VendorUserData();
    _this.template_visible = true;
    return _this;
  }
  return VendorEmailTemplate;
}(common.ZomentumEntity);
var VendorEmailTemplatesDrawerFormValues = /** @class */function () {
  function VendorEmailTemplatesDrawerFormValues() {
    this.title = "";
    this.subject = "";
    this.body = "";
  }
  return VendorEmailTemplatesDrawerFormValues;
}();
exports.VendorCustomTemplateType = void 0;
(function (VendorCustomTemplateType) {
  VendorCustomTemplateType["PublishTemplate"] = "publish_template";
  VendorCustomTemplateType["UnpublishTemplate"] = "unpublish_template";
  VendorCustomTemplateType["OpportunityShared"] = "opportunity_shared";
  VendorCustomTemplateType["OpportunityReceived"] = "opportunity_received";
  VendorCustomTemplateType["AcceptInviteFlow"] = "accept_invite_flow";
  VendorCustomTemplateType["JoinVendorProgram"] = "join_vendor_program";
  VendorCustomTemplateType["InviteNewPartner"] = "invite_new_partner";
  VendorCustomTemplateType["InviteExistingPartner"] = "invite_existing_partner";
  VendorCustomTemplateType["AssignPartnerTier"] = "partner_tier_assigned";
  VendorCustomTemplateType["ChangePartnerTier"] = "partner_tier_changed";
})(exports.VendorCustomTemplateType || (exports.VendorCustomTemplateType = {}));
var VendorEmailTemplatesResponse = /** @class */function () {
  function VendorEmailTemplatesResponse() {
    this.filtered_count_map = null;
    this.data = new Array();
  }
  return VendorEmailTemplatesResponse;
}();
var VendorEntitySelectionFormValues = /** @class */function () {
  function VendorEntitySelectionFormValues() {
    this.client = "";
    this.opportunity = null;
    this.documents = null;
    this.recipients = new Array();
    this.partner = null;
  }
  return VendorEntitySelectionFormValues;
}();
var VendorSelectedEntityValues = /** @class */function () {
  function VendorSelectedEntityValues() {
    this.client = "";
    this.opportunity = null;
    this.documents = null;
    this.recipients = new Array();
    this.partner = null;
  }
  return VendorSelectedEntityValues;
}();
var VendorUseTemplateValues = /** @class */function () {
  function VendorUseTemplateValues() {
    this.subject = "";
    this.body = "";
    this.attachments = new Array();
    this.entity_details = new VendorSelectedEntityValues();
  }
  return VendorUseTemplateValues;
}();
var VendorEmailTemplateGetRequest = /** @class */function () {
  function VendorEmailTemplateGetRequest(filters, keywords) {
    this.filters = filters;
    this.keywords = keywords;
  }
  return VendorEmailTemplateGetRequest;
}();
var VendorPreviewEmailTemplateRequest = /** @class */function () {
  function VendorPreviewEmailTemplateRequest() {
    this.from = null;
    this.subject = "";
    this.body = "";
    this.should_replace_with_empty_string = true;
    this.document_id = "";
    this.template_type = exports.VendorCustomTemplateType.PublishTemplate;
  }
  return VendorPreviewEmailTemplateRequest;
}();
exports.VendorEmailTemplateAddOrEditMode = void 0;
(function (VendorEmailTemplateAddOrEditMode) {
  VendorEmailTemplateAddOrEditMode["ADD"] = "add";
  VendorEmailTemplateAddOrEditMode["EDIT"] = "edit";
})(exports.VendorEmailTemplateAddOrEditMode || (exports.VendorEmailTemplateAddOrEditMode = {}));

exports.VendorEmailTemplate = VendorEmailTemplate;
exports.VendorEmailTemplateGetRequest = VendorEmailTemplateGetRequest;
exports.VendorEmailTemplateRequest = VendorEmailTemplateRequest;
exports.VendorEmailTemplatesDrawerFormValues = VendorEmailTemplatesDrawerFormValues;
exports.VendorEmailTemplatesResponse = VendorEmailTemplatesResponse;
exports.VendorEntitySelectionFormValues = VendorEntitySelectionFormValues;
exports.VendorPreviewEmailTemplateRequest = VendorPreviewEmailTemplateRequest;
exports.VendorSelectedEntityValues = VendorSelectedEntityValues;
exports.VendorUseTemplateValues = VendorUseTemplateValues;
