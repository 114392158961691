'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.EInputWidths = void 0;
(function (EInputWidths) {
    EInputWidths["sm"] = "sm";
    EInputWidths["md"] = "md";
    EInputWidths["lg"] = "lg";
    EInputWidths["xl"] = "xl";
    EInputWidths["full"] = "full";
})(exports.EInputWidths || (exports.EInputWidths = {}));
