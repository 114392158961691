'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var _a;
var NotificationActivity = /** @class */function () {
  function NotificationActivity() {
    this.actor = "";
    this.cta = new Array();
    this.entity_id = "";
    this.entity_type = null;
    this.foreign_id = "";
    this.icon = "";
    this.id = "";
    this.iso_time = new Date();
    this.message = "";
    this.new_tab = false;
    this.object = "";
    this.origin = "";
    this.redirect_url = "";
    this.error_message = "";
    this.target = "";
    this.title = "";
    this.verb = "";
    this.notificationId = "";
    this.isRead = false;
    this.isSeen = false;
  }
  return NotificationActivity;
}();
exports.NotificationVerb = void 0;
(function (NotificationVerb) {
  NotificationVerb["KASEYA"] = "kaseya_initial_sync_finish";
  NotificationVerb["HALOPSA"] = "haloPSA_initial_sync_finish";
  NotificationVerb["SYNCRO"] = "syncro_initial_sync_finish";
  NotificationVerb["REPAIRSHOPR"] = "repairshopr_initial_sync_finish";
  NotificationVerb["CONNECTWISE"] = "connectwise_initial_sync_finish";
  NotificationVerb["AUTOTASK"] = "autotask_initial_sync_finish";
  NotificationVerb["QUICKBOOKS"] = "quickbooks_initial_sync_finish";
  NotificationVerb["HUBSPOT"] = "hubspot_initial_sync_finish";
  NotificationVerb["INFUSIONSOFT"] = "infusionsoft_initial_sync_finish";
})(exports.NotificationVerb || (exports.NotificationVerb = {}));
var NotificationVerbToReduxKeyMapping = (_a = {}, _a[exports.NotificationVerb.KASEYA] = "kaseya", _a[exports.NotificationVerb.HALOPSA] = "halo", _a[exports.NotificationVerb.SYNCRO] = "syncro", _a[exports.NotificationVerb.REPAIRSHOPR] = "repair_shopr", _a[exports.NotificationVerb.CONNECTWISE] = "connectwise", _a[exports.NotificationVerb.AUTOTASK] = "autotask", _a[exports.NotificationVerb.QUICKBOOKS] = "quickbooks", _a[exports.NotificationVerb.HUBSPOT] = "hubspot", _a[exports.NotificationVerb.INFUSIONSOFT] = "infusion_soft", _a);
exports.BannerType = void 0;
(function (BannerType) {
  BannerType["ADD_PAYMENT_METHOD"] = "add_payment_method";
})(exports.BannerType || (exports.BannerType = {}));
exports.BannerSeverity = void 0;
(function (BannerSeverity) {
  BannerSeverity["INFO"] = "info";
  BannerSeverity["WARNING"] = "warning";
  BannerSeverity["ERROR"] = "error";
})(exports.BannerSeverity || (exports.BannerSeverity = {}));
exports.BannerNotificationMessageType = void 0;
(function (BannerNotificationMessageType) {
  BannerNotificationMessageType["TEXT"] = "plain_text";
  BannerNotificationMessageType["FETCH_CHARGEBEE"] = "fetch_chargebee_hosted_page";
})(exports.BannerNotificationMessageType || (exports.BannerNotificationMessageType = {}));
var NotificationResponse = /** @class */function () {
  function NotificationResponse() {
    this.activities = new Array();
    this.activity_count = 0;
    this.actor_count = 0;
    this.created_at = new Date();
    this.group = "";
    this.id = "";
    this.is_read = false;
    this.is_seen = false;
    this.updated_at = new Date();
    this.verb = "";
    this.isRead = false;
  }
  return NotificationResponse;
}();
exports.NotificationFeedActivity = void 0;
(function (NotificationFeedActivity) {
  NotificationFeedActivity["PURCHASE_ORDER"] = "purchase_order";
})(exports.NotificationFeedActivity || (exports.NotificationFeedActivity = {}));
exports.NotificationModalType = void 0;
(function (NotificationModalType) {
  NotificationModalType["PendingOpportunities"] = "pending-opportunities";
  NotificationModalType["ChangeSuggestions"] = "change-suggestions";
  NotificationModalType["PendingUsers"] = "user-requests";
})(exports.NotificationModalType || (exports.NotificationModalType = {}));

exports.NotificationActivity = NotificationActivity;
exports.NotificationResponse = NotificationResponse;
exports.NotificationVerbToReduxKeyMapping = NotificationVerbToReduxKeyMapping;
