import { Reducer } from "redux";
import { CustomViewResponse, CustomView } from "@zomentum/contracts/dist/UI";
import { VendorClient } from "@zomentum/contracts/dist/Vendor/Clients";
import {
	VendorClientActions,
	VendorClientActionTypes
} from "@/Actions/Vendor/Client";

export class VendorClientState {
	readonly isLoading: boolean = false;
	readonly currentActiveClient: VendorClient | null = null;
	readonly viewList: CustomViewResponse | null = null;
	readonly currentActiveView: CustomView | null = null;
	readonly isViewChanged: boolean = false;
	readonly editVendorClientDrawerOpen: boolean = false;
}

export const initialVendorClientState: VendorClientState =
	new VendorClientState();

export const VendorClientReducer: Reducer<
	VendorClientState,
	VendorClientActions
> = (
	state: VendorClientState = initialVendorClientState,
	action: VendorClientActions
) => {
	switch (action.type) {
		case VendorClientActionTypes.SHOW_VENDOR_CLIENT_LOADER:
			return {
				...state,
				isLoading: true
			};
		case VendorClientActionTypes.HIDE_VENDOR_CLIENT_LOADER:
			return {
				...state,
				isLoading: false
			};
		case VendorClientActionTypes.VENDOR_CLIENT_DETAILS_SUCCESSFUL:
			return {
				...state,
				currentActiveClient: action.currentActiveVendorClient
			};
		case VendorClientActionTypes.EDIT_VENDOR_CLIENT_DRAWER_OPENED: {
			return {
				...state,
				editVendorClientDrawerOpen: true
			};
		}
		case VendorClientActionTypes.EDIT_VENDOR_CLIENT_DRAWER_CLOSED: {
			return {
				...state,
				editVendorClientDrawerOpen: false
			};
		}
		default:
			return state;
	}
};

export default VendorClientReducer;
