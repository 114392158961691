import LoginActionCreators from "@/Actions/Login";
import { UIConfigActionCreators } from "@/Actions/UI";
import {
	ChildException,
	ExceptionType,
	ServerError,
	ServerValidationErrorResponse
} from "@/Components/Utils/ServerError";
import {
	AuthToken,
	cache,
	ZomentumRoutes,
	ZomentumStorageKeys,
	ZomentumWindow
} from "@zomentum/contracts";
import { Auth0LoginState } from "@zomentum/contracts/dist/Login";
import useMobileView from "@zomentum/hooks/dist/hooks/useMobileView";
import ZMessage from "@zomentum/molecules/dist/ZMessage";
import { Auth0ContextInterface, useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosError } from "axios";
import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { initilizeAvo } from "@zomentum/utils";
import { DashboardLayout } from "../DashboardLayout";
import "./Dashboard.less";
import LoadThirdPartyScripts from "./LoadThirdPartyScripts";
import SalesActivityDrawers from "@modules/sales-activity/dist/pages/Drawers/SalesActivities";
import { GlobalState } from "@/Reducers/interface";
import EditProductSKUInQuoteBlockDrawer from "@pages/Vendor/Templates/TemplateDetailPage/Drawers/EditProductSKUInQuoteBlockDrawer";

const VendorDashboardRoutes = React.lazy(
	() => import("./DashboardRoutes/VendorDashboardRoutes")
);

// async function retry(fn: any, retriesLeft = 5, interval = 1000) {
// 	try {
// 		const response = await fn();
// 		return response;
// 	} catch (error) {
// 		setTimeout(() => {
// 			if (retriesLeft === 1) {
// 				// reject('maximum retries exceeded');
// 				throw error;
// 			}
// 			return retry(fn, retriesLeft - 1, interval);
// 		}, interval);
// 	}
// }

declare let window: ZomentumWindow;

if (process.env.REACT_APP_SHA) {
	window.REACT_APP_SHA = process.env.REACT_APP_SHA;
}

export interface Props extends RouteComponentProps {
	updateServiceWorker: (registration: ServiceWorkerRegistration) => void;
	auth0LoginState: Auth0LoginState;
}

export const AppDashboard: React.FC<Props> = props => {
	const auth0 = useAuth0();
	const dispatch = useDispatch();
	const logout = (auth0: Auth0ContextInterface) =>
		dispatch(LoginActionCreators.logout(auth0));

	const isMobileView = useMobileView();

	const { vendorUserData, favicon } = useSelector((state: GlobalState) => ({
		vendorUserData: state.vendorUser.vendorUserData,
		favicon: state.vendorUser.vendorUserCompany.favicon
	}));

	useEffect(() => {
		const currFavicon = document.getElementById(
			"favicon"
		) as HTMLLinkElement;
		if (favicon && currFavicon) {
			currFavicon.href = favicon;
		}
	}, []);

	useEffect(() => {
		if (
			props.auth0LoginState &&
			Object.keys(props.auth0LoginState).length !== 0
		) {
			dispatch(
				LoginActionCreators.auth0LoginStateReceived(
					props.auth0LoginState
				)
			);
		}
	}, [props.auth0LoginState]);

	useEffect(() => {
		dispatch(UIConfigActionCreators.updateMobileView(isMobileView));
	}, [isMobileView]);

	useEffect(() => {
		axios.interceptors.request.use(
			request => request,
			error => {
				return Promise.reject(error);
			}
		);
		axios.interceptors.response.use(
			response => {
				return response;
			},
			async (error: AxiosError<ServerValidationErrorResponse>) => {
				if (error?.response?.status === 401) {
					if (
						(
							error.response.data?.child_exceptions?.map(
								ex => ex.exception_type
							) || new Array<ChildException>()
						)?.includes(ExceptionType.ReAuthorizeException)
					) {
						ZMessage.success(error?.response?.data?.message, 2);
						await auth0.loginWithRedirect();
						return;
					} else if (error.config && !error.config.headers["retry"]) {
						let token = "";
						try {
							token = await auth0.getAccessTokenSilently();
						} catch (e) {
							console.warn(e);
							return logout(auth0);
						}
						const tokenInCache = cache.get(
							ZomentumStorageKeys.Token,
							true
						)?.token;
						if (tokenInCache && token && token.length > 0) {
							error.config.headers[
								"Authorization"
							] = `Bearer ${token}`;
							error.config.headers["retry"] = true;
							cache.save(
								ZomentumStorageKeys.Token,
								new AuthToken(token)
							);
							return axios(error.config);
						} else {
							logout(auth0);
						}
					} else {
						logout(auth0);
					}
				}

				if (error?.response?.status === 403) {
					ZMessage.error("Unauthorized to perform the action");
					return;
				}

				// TODO: add currentActivePartner, currentMarketplaceListing, etc all redux data to reportData by @Harmeet
				ZMessage.serverError({
					errorData: error,
					reportData: {
						vendorUserData
					},
					currentLocationPath: props.location.pathname
				});

				if (error?.response?.status === 500) {
					props.history.push(ZomentumRoutes.Error);
				}
				throw new ServerError(error);
			}
		);
	}, []);

	useEffect(() => {
		const mixPanelSecret = process.env.REACT_APP_MIXPANEL_TOKEN;
		if (
			!process.env.REACT_APP_AVO_INSPECTOR_ENV ||
			!process.env.REACT_APP_AVO_INSPECTOR_API ||
			!process.env.REACT_APP_AVO_INSPECTOR_APPLICATION_NAME
		) {
			console.error("Avo keys are missing");
		}
		if (mixPanelSecret) {
			initilizeAvo(
				process.env.REACT_APP_AVO_INSPECTOR_APPLICATION_NAME || "",
				process.env.REACT_APP_AVO_INSPECTOR_ENV || ""
			);
		}
	}, []);

	return (
		<>
			<DashboardLayout updateServiceWorker={props.updateServiceWorker}>
				<Suspense fallback={<div />}>
					<VendorDashboardRoutes />
				</Suspense>
				<SalesActivityDrawers />
				<EditProductSKUInQuoteBlockDrawer />
			</DashboardLayout>
			<LoadThirdPartyScripts />
		</>
	);
};

export default withRouter((props: Props) => {
	return <AppDashboard {...props} />;
});
