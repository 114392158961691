import {
	ConnectwiseCredentailsTestResponse,
	SyncroCredentailsTestResponse,
	ConnectwiseTaxImport,
	ImportTaxRequest,
	AutotaskMapping,
	AutotaskTaxImportRequest,
	AutotaskConnectFormValues,
	KaseyaCredentialsTestRequest,
	RepairShoprCredentialsTestRequest,
	RepairShoprCredentailsTestResponse
} from "@zomentum/contracts/dist/Settings/index";
import axios, { AxiosResponse } from "axios";

import {
	getRequest,
	putRequest,
	postRequest
} from "@zomentum/utils/dist/Service/BackendService";

import {
	AutotaskIntegrationResponse,
	ConnectwiseCredentialsTestRequest,
	SyncroCredentialsTestRequest
} from "@zomentum/contracts/dist/Settings";

const deleteEmailIntegrationUrl = "/v1/vendors/emails/delete";

const resetPasswordUrl = "/v2/users/password/reset/email";

const testAutotaskCredentialsURL =
	"/v2/companies/integrations/autotask/metadata";
const testConnectwiseCredentialsURL =
	"/v2/companies/integrations/connectwise/test";
const testKaseyaCredentialsURL = "/v2/companies/integrations/kaseya/test";

const testSyncroCredentialsURL = "/v2/companies/integrations/syncro/test";
const importConnectwiseTaxesURL =
	"/v2/companies/integrations/connectwise/tax/import";

const importAutotaskTaxesURL = "/v2/companies/integrations/autotask/import_tax";

const getAutotaskStageMappingURL =
	"/v2/companies/integrations/autotask/stage_mapping?selected_pipeline_id={pipeline_id}";

const testRepairShoprCredentialsURL =
	"/v2/companies/integrations/repairshopr/test";

export const deleteCompanyEmailIntegration = async (): Promise<void> => {
	try {
		const response: AxiosResponse<void> = await axios(
			putRequest(deleteEmailIntegrationUrl, {}, true, false, false)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const resetPasswordEmail = async (): Promise<void> => {
	try {
		const response: AxiosResponse<void> = await axios(
			getRequest(resetPasswordUrl, null, true, false, false)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const testAutotaskCredentials = async (
	testAutotaskCredentialsTestRequest: AutotaskIntegrationResponse
): Promise<AutotaskIntegrationResponse> => {
	let credentialsTestRequest = {};
	if (testAutotaskCredentialsTestRequest) {
		credentialsTestRequest = testAutotaskCredentialsTestRequest;
	}
	try {
		const response: AxiosResponse<AutotaskIntegrationResponse> =
			await axios(
				putRequest(
					testAutotaskCredentialsURL,
					credentialsTestRequest,
					true,
					false,
					false
				)
			);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const testConnectwiseCredentials = async (
	connectwiseCredentialsTestRequest: ConnectwiseCredentialsTestRequest
): Promise<ConnectwiseCredentailsTestResponse> => {
	let credentialsTestRequest = {};
	if (connectwiseCredentialsTestRequest) {
		credentialsTestRequest = connectwiseCredentialsTestRequest;
	}
	try {
		const response: AxiosResponse<ConnectwiseCredentailsTestResponse> =
			await axios(
				putRequest(
					testConnectwiseCredentialsURL,
					credentialsTestRequest,
					true,
					false,
					false
				)
			);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const testSyncroCredentials = async (
	syncroCredentialsTestRequest: SyncroCredentialsTestRequest
): Promise<SyncroCredentailsTestResponse> => {
	let credentialsTestRequest = {};
	if (syncroCredentialsTestRequest) {
		credentialsTestRequest = syncroCredentialsTestRequest;
	}
	try {
		const response: AxiosResponse<SyncroCredentailsTestResponse> =
			await axios(
				putRequest(
					testSyncroCredentialsURL,
					credentialsTestRequest,
					true,
					false,
					false
				)
			);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const importTaxForConnectwise = async (
	importTaxRequest: ImportTaxRequest
): Promise<ConnectwiseTaxImport> => {
	try {
		const response: AxiosResponse<ConnectwiseTaxImport> = await axios(
			postRequest(
				importConnectwiseTaxesURL,
				importTaxRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const importTaxForAutotask = async (
	autotaskTaxImportRequest: AutotaskTaxImportRequest
): Promise<AutotaskMapping> => {
	try {
		const response: AxiosResponse<AutotaskMapping> = await axios(
			putRequest(
				importAutotaskTaxesURL,
				autotaskTaxImportRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const getAutotaskStageMapping = async (
	testAutotaskCredentialsTestRequest: AutotaskConnectFormValues,
	selectedPipelineId: string
): Promise<AutotaskMapping> => {
	try {
		const response: AxiosResponse<AutotaskMapping> = await axios(
			putRequest(
				getAutotaskStageMappingURL.replace(
					"{pipeline_id}",
					selectedPipelineId
				),
				testAutotaskCredentialsTestRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const testKaseyaCredentials = async (
	kaseyaCredentialsTestRequest: KaseyaCredentialsTestRequest
): Promise<any> => {
	try {
		let credentialsTestRequest = {};
		if (kaseyaCredentialsTestRequest) {
			credentialsTestRequest = kaseyaCredentialsTestRequest;
		}
		const response: AxiosResponse<any> = await axios(
			putRequest(
				testKaseyaCredentialsURL,
				credentialsTestRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const testRepairShoprCredentials = async (
	repairShoprCredentialsTestRequest: RepairShoprCredentialsTestRequest
): Promise<RepairShoprCredentailsTestResponse> => {
	let credentialsTestRequest = {};
	if (repairShoprCredentialsTestRequest) {
		credentialsTestRequest = repairShoprCredentialsTestRequest;
	}
	try {
		const response: AxiosResponse<RepairShoprCredentailsTestResponse> =
			await axios(
				putRequest(
					testRepairShoprCredentialsURL,
					credentialsTestRequest,
					true,
					false,
					false
				)
			);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
