import axios, { AxiosResponse } from "axios";

import {
	getRequest,
	postRequest
} from "@zomentum/utils/dist/Service/BackendService";

import { ZomentumEntityRequest } from "@zomentum/contracts";
import {
	EmailThreadMessage,
	EmailThreadMessagesResponse,
	DownloadEmailThreadMessageFileRequest,
	SendEmailThreadMessageRequest,
	QueryParameters,
	SendDocumentEmailThreadMessageRequest,
	ValidateEmailMergeTagsRequest,
	GetEmailPreviewRequest,
	ValidateEmailMergeTagsResponse,
	EmailPreviewResponse,
	GetDocumentEmailPreviewRequest
} from "@zomentum/contracts/dist/Others/EmailThread";
import {
	MergeTagInput,
	MergeTagValueUpdateResponse,
	MergeTagVariable
} from "@zomentum/contracts/dist/Documents";
import { Client } from "@zomentum/contracts/dist/Clients";

const emailThreadMessagesUrl =
	"/v2/emails/messages?entity_type={entity_type}&entity_id={entity_id}&account_id={account_id}";
const emailThreadMessageDownloadAttachmentUrl =
	"/v2/emails/attachments/download/{file_id}";
const sendEmailThreadMessageUrl = "/v2/emails/send";
const sendEmailDocumentMessageUrl = "/v3/emails/send/document/{document_id}";
// entity_type: document_type || client_type || opportunity_type
const fetchMergeTags = "/v2/emails/merge_tags/?mail_type={entity_type}";
// const previewEmailUrl = '/v2/emails/preview/document/{document_id}';
const validateEmailMergeTagsUrl = "/v2/emails/validate";

const getEmailPreviewUrl = "/v2/emails/preview";

const previewDocumentV3URL = "/v3/emails/preview/document/{document_id}";

const sendMs365InviteToClientUrl =
	"/v2/ms365/send-consent-request/{client_id}/";

const previewMs365ConsentEmailUrl =
	"/v2/ms365/send-consent-request/{client_id}/preview/";

const updateMergeTagValues = "/v2/merge_tags";

export const getEmailThreadMessages = async (
	zomentumEntityRequest: ZomentumEntityRequest,
	userIp: string
): Promise<EmailThreadMessagesResponse> => {
	try {
		const response: AxiosResponse<EmailThreadMessagesResponse> =
			await axios(
				getRequest(
					emailThreadMessagesUrl
						.replace(
							"{entity_type}",
							zomentumEntityRequest.entityType
						)
						.replace("{entity_id}", zomentumEntityRequest.entityId)
						.replace(
							"{account_id}",
							zomentumEntityRequest.accountId
								? zomentumEntityRequest.accountId
								: ""
						),
					null,
					true,
					false,
					false,
					userIp
				)
			);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const emailThreadMessageDownloadAttachment = async (
	downloadEmailThreadMessageFileRequest: DownloadEmailThreadMessageFileRequest
): Promise<Blob> => {
	try {
		const response: AxiosResponse<Blob> = await axios(
			getRequest(
				emailThreadMessageDownloadAttachmentUrl.replace(
					"{file_id}",
					downloadEmailThreadMessageFileRequest.file.id
				),
				null,
				true,
				false,
				true
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const sendEmailThreadMessage = async (
	sendEmailThreadMessageRequest: SendEmailThreadMessageRequest
): Promise<EmailThreadMessagesResponse> => {
	try {
		const response: AxiosResponse<EmailThreadMessagesResponse> =
			await axios(
				postRequest(
					sendEmailThreadMessageUrl,
					sendEmailThreadMessageRequest,
					true,
					false,
					false
				)
			);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const sendEmailDocumentMessage = async (
	sendDocumentEmailThreadMessageRequest: SendDocumentEmailThreadMessageRequest
): Promise<EmailThreadMessage> => {
	try {
		const response: AxiosResponse<EmailThreadMessage> = await axios(
			postRequest(
				sendEmailDocumentMessageUrl.replace(
					"{document_id}",
					sendDocumentEmailThreadMessageRequest.document_id
				),
				sendDocumentEmailThreadMessageRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getMergeTags = async (
	queryParameters: QueryParameters[]
): Promise<MergeTagVariable[]> => {
	try {
		const response: AxiosResponse<MergeTagVariable[]>[] = await axios.all(
			queryParameters.map(({ entity_type, entity_id }) => {
				let fetchMergeTagsURL = fetchMergeTags.replace(
					"{entity_type}",
					entity_type
				);

				if (entity_id && entity_id.length > 0) {
					fetchMergeTagsURL = fetchMergeTagsURL + `&id=${entity_id}`;
				}

				return axios(
					getRequest(fetchMergeTagsURL, null, true, false, false)
				) as Promise<AxiosResponse<MergeTagVariable[]>>;
			})
		);

		const aggregateMergeTagResponse: MergeTagVariable[] = response
			.map(res => res.data)
			.reduce(
				(
					reducedValue: MergeTagVariable[],
					current: MergeTagVariable[]
				) => {
					return reducedValue.concat(current);
				},
				[]
			);

		return aggregateMergeTagResponse;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchMergeTagsWithNoEntityId = async (
	entityType: string
): Promise<MergeTagVariable[]> => {
	try {
		const response: AxiosResponse<MergeTagVariable[]> = await axios(
			getRequest(
				fetchMergeTags.replace("{entity_type}", entityType),
				null,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const validateEmailMergeTags = async (
	validateEmailMergeTagsRequest: ValidateEmailMergeTagsRequest
): Promise<ValidateEmailMergeTagsResponse[]> => {
	try {
		const response: AxiosResponse<ValidateEmailMergeTagsResponse[]> =
			await axios(
				postRequest(
					validateEmailMergeTagsUrl,
					validateEmailMergeTagsRequest,
					true,
					false,
					false
				)
			);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getDocumentEmailPreview = async (
	getDocumentPreviewRequest: GetDocumentEmailPreviewRequest
): Promise<string> => {
	try {
		const documentId = getDocumentPreviewRequest.documentId;

		const response: AxiosResponse<string> = await axios(
			postRequest(
				previewDocumentV3URL.replace("{document_id}", documentId),
				getDocumentPreviewRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (err) {
		return Promise.reject(err);
	}
};

export const getEmailPreview = async (
	getEmailPreviewRequest: Partial<GetEmailPreviewRequest>
): Promise<EmailPreviewResponse[]> => {
	try {
		const response: AxiosResponse<EmailPreviewResponse[]> = await axios(
			postRequest(
				getEmailPreviewUrl,
				getEmailPreviewRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getMs365ConsentEmailPreview = async (
	clientId: string,
	ms365ConsentPreviewRequest: Partial<SendEmailThreadMessageRequest>
): Promise<string> => {
	try {
		const response: AxiosResponse<string> = await axios(
			postRequest(
				previewMs365ConsentEmailUrl.replace("{client_id}", clientId),
				ms365ConsentPreviewRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (err) {
		return Promise.reject(err);
	}
};

export const sendMs365InviteToClient = async (
	clientId: string,
	ms365Invite: Partial<SendEmailThreadMessageRequest>
): Promise<Client> => {
	try {
		const response = await axios(
			postRequest(
				sendMs365InviteToClientUrl.replace("{client_id}", clientId),
				ms365Invite,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
export const updateMergeTagsByValue = async (
	mergeTagInput: MergeTagInput
): Promise<MergeTagValueUpdateResponse> => {
	try {
		const response: AxiosResponse<MergeTagValueUpdateResponse> =
			await axios(
				postRequest(
					updateMergeTagValues,
					mergeTagInput,
					true,
					false,
					false
				)
			);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
