'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.ChangeSuggestionSource = void 0;
(function (ChangeSuggestionSource) {
  ChangeSuggestionSource["Vendor"] = "vendor";
  ChangeSuggestionSource["Partner"] = "partner";
})(exports.ChangeSuggestionSource || (exports.ChangeSuggestionSource = {}));
exports.ChangeSuggestionStatus = void 0;
(function (ChangeSuggestionStatus) {
  ChangeSuggestionStatus["Requested"] = "requested";
  ChangeSuggestionStatus["Accepted"] = "accepted";
  ChangeSuggestionStatus["Rejected"] = "rejected";
})(exports.ChangeSuggestionStatus || (exports.ChangeSuggestionStatus = {}));
exports.ChangeSuggestionAction = void 0;
(function (ChangeSuggestionAction) {
  ChangeSuggestionAction["CREATION"] = "creation";
  ChangeSuggestionAction["MODIFICATION"] = "modification";
})(exports.ChangeSuggestionAction || (exports.ChangeSuggestionAction = {}));
