import {
	VendorPartnerTier,
	VendorPartnerAddTierRequest,
	VendorPartnerEditTierRequest,
	VendorPartnerTiersResponse
} from "@zomentum/contracts/dist/Vendor/Tiers";
import {
	getTableQueryFilters,
	includeChildEntities
} from "@zomentum/contracts/dist/Utils";
import { ChildEntityHydrationParams } from "@zomentum/contracts/dist/Utils";

import axios, { AxiosResponse } from "axios";
import {
	getRequest,
	postRequest,
	putRequest,
	deleteRequest
} from "@zomentum/utils/dist/Service/BackendService";
import {
	DeletedRecord,
	VendorResourceTierGetRequest
} from "@zomentum/contracts";

const getAllVendorTiersURL = "/v1/vendor/tiers";
const addVendorTierUrl = "/v1/vendor/tiers/";
const deleteVendorTierUrl = "/v1/vendor/tiers/:id";
const updateVendorTierUrl = "/v1/vendor/tiers/:id";

export const getAllVendorTiers =
	async (): Promise<VendorPartnerTiersResponse> => {
		try {
			const includedChildEntities = includeChildEntities([
				ChildEntityHydrationParams.VENDOR_PARTNER_COUNT,
				ChildEntityHydrationParams.VENDOR_USERS_ALL,
				ChildEntityHydrationParams.PARTNER_TIERS_COUNT
			]);
			const response: AxiosResponse<VendorPartnerTiersResponse> =
				await axios(
					getRequest(
						getAllVendorTiersURL + `?${includedChildEntities}`,
						null,
						true,
						false,
						false,
						undefined,
						undefined
					)
				);
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	};
export const addVendorTier = async (
	addVendorTierRequest: VendorPartnerAddTierRequest
): Promise<VendorPartnerTier> => {
	try {
		const includedChildEntities = includeChildEntities([
			ChildEntityHydrationParams.VENDOR_PARTNER_COUNT,
			ChildEntityHydrationParams.VENDOR_USERS_ALL
		]);
		const response: AxiosResponse<VendorPartnerTier> = await axios(
			postRequest(
				addVendorTierUrl + `?${includedChildEntities}`,
				addVendorTierRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const deleteVendorTier = async (
	tierId: string
): Promise<DeletedRecord> => {
	try {
		const response: AxiosResponse<DeletedRecord> = await axios(
			deleteRequest(
				deleteVendorTierUrl.replace(":id", tierId),
				null,
				true,
				false
			)
		);
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};
export const updateVendorTier = async (
	tierId: string,
	updateVendorTierRequest: VendorPartnerEditTierRequest
): Promise<VendorPartnerTier> => {
	try {
		const includedChildEntities = includeChildEntities([
			ChildEntityHydrationParams.VENDOR_PARTNER_COUNT,
			ChildEntityHydrationParams.VENDOR_USERS_ALL
		]);
		const response: AxiosResponse<VendorPartnerTier> = await axios(
			putRequest(
				updateVendorTierUrl.replace(":id", tierId) +
					`?${includedChildEntities}`,
				updateVendorTierRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const getAllTiersForResource = async (
	getResourceTierRequest: VendorResourceTierGetRequest
): Promise<VendorPartnerTiersResponse> => {
	try {
		let queryParams = `limit=${getResourceTierRequest.limit}`;
		const included_child_entities = includeChildEntities(
			getResourceTierRequest.included_child_entities
		);
		queryParams = `${queryParams}${
			included_child_entities && !!included_child_entities.length
				? `&${included_child_entities}`
				: ""
		}`;
		queryParams = `${queryParams}${
			getResourceTierRequest.keywords &&
			!!getResourceTierRequest.keywords.length
				? `&q=${getResourceTierRequest.keywords}`
				: ""
		}`;
		if (!!getResourceTierRequest.filters.length) {
			const appliedFilters = getTableQueryFilters(
				getResourceTierRequest.filters,
				false
			);
			queryParams = `${queryParams}${
				appliedFilters && !!appliedFilters.length
					? `&${appliedFilters}`
					: ""
			}`;
		}
		const response: AxiosResponse<VendorPartnerTiersResponse> = await axios(
			getRequest(
				`${getAllVendorTiersURL}?${queryParams}`,
				null,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};
