'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../node_modules/tslib/tslib.es6.mjs.js');
var index$3 = require('../contracts/index.js');
var React = require('react');
var index$1 = require('../store/context/index.js');
var index = require('../store/index.js');
var useOnboardingMaps = require('./useOnboardingMaps.js');
var index$4 = require('../services/index.js');
var Resources = require('@zomentum/contracts/dist/Vendor/Resources');
var Vendor = require('@zomentum/contracts/dist/Marketplace/Vendor');
var index$2 = require('../store/actions/index.js');

var getIncompleteStageDetail = function getIncompleteStageDetail(activeModuleIndex) {
  var _a, _b, _c, _d;
  var _e = index.onbordingStore.getState(),
    moduleOrder = _e.moduleOrder,
    checkListBarData = _e.checkListBarData,
    onboardingMaps = _e.onboardingMaps;
  var moduleConfig = (_a = checkListBarData === null || checkListBarData === void 0 ? void 0 : checkListBarData.modules) === null || _a === void 0 ? void 0 : _a[activeModuleIndex];
  var activeModule = null,
    activeStage = null,
    activeStep = null;
  if ((moduleConfig === null || moduleConfig === void 0 ? void 0 : moduleConfig.stages) && onboardingMaps) {
    var stageIndex = moduleConfig.stages.findIndex(function (completed) {
      return !completed.is_complete;
    });
    activeModule = moduleOrder[activeModuleIndex];
    var currentStages = onboardingMaps.moduleStages[activeModule];
    activeStage = currentStages[stageIndex];
    activeStep = (_d = (_c = (_b = onboardingMaps.stageSteps[activeStage]) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : null;
  }
  return {
    activeModule: activeModule,
    activeStage: activeStage,
    activeStep: activeStep
  };
};
var getModuleDetails = function getModuleDetails(moduleName) {
  var _a, _b;
  var _c = index.onbordingStore.getState(),
    moduleOrder = _c.moduleOrder,
    checkListBarData = _c.checkListBarData;
  var modulesIndex = moduleOrder.indexOf(moduleName);
  return (_b = (_a = checkListBarData === null || checkListBarData === void 0 ? void 0 : checkListBarData.modules) === null || _a === void 0 ? void 0 : _a[modulesIndex]) !== null && _b !== void 0 ? _b : null;
};
/**
 * The method calculate the next guide states based on current guide state.
 * @return {CurrentGuideProgress} - returns next guide state
 */
var getNextSteps = function getNextSteps() {
  var _a, _b, _c;
  var _d = index.onbordingStore.getState(),
    currentGuideProgress = _d.currentGuideProgress,
    checkListBarData = _d.checkListBarData,
    onboardingMaps = _d.onboardingMaps;
  var activeModule = currentGuideProgress.activeModule,
    activeStage = currentGuideProgress.activeStage,
    activeStep = currentGuideProgress.activeStep;
  if (activeModule && activeStage && onboardingMaps) {
    // move to next step based on store data
    var currentSteps = onboardingMaps.stageSteps[activeStage];
    var stepIndex = currentSteps.findIndex(function (step) {
      return step.id == activeStep;
    });
    if (stepIndex >= currentSteps.length - 1) {
      //move to next module or stage and steps
      var currentStages = onboardingMaps.moduleStages[activeModule];
      var stageIndex = currentStages.indexOf(activeStage);
      if (stageIndex >= currentStages.length - 1) {
        //move to next module
        activeModule = null;
        activeStage = null;
        activeStep = null;
      } else {
        //move to next stage and step
        activeStage = currentStages[stageIndex + 1];
        activeStep = (_b = (_a = onboardingMaps.stageSteps[activeStage][0]) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null;
      }
    } else {
      //move to next step
      activeStep = currentSteps[stepIndex + 1].id;
    }
  } else {
    // move to next step based on api data
    var activeModuleIndex = (_c = checkListBarData === null || checkListBarData === void 0 ? void 0 : checkListBarData.active_module) !== null && _c !== void 0 ? _c : null;
    if (activeModuleIndex !== null) {
      return getIncompleteStageDetail(activeModuleIndex);
    } else {
      //move to next module
      activeModule = null;
      activeStage = null;
      activeStep = null;
    }
  }
  return {
    activeModule: activeModule,
    activeStage: activeStage,
    activeStep: activeStep
  };
};
var navigateToNextModule = function navigateToNextModule(stepDetail, parentContext) {
  var _a, _b, _c;
  if (stepDetail.activeModule) {
    var moduleDetail = getModuleDetails(stepDetail.activeModule);
    var entityId = (_a = moduleDetail === null || moduleDetail === void 0 ? void 0 : moduleDetail.created_entity_id) !== null && _a !== void 0 ? _a : "";
    (_c = (_b = parentContext === null || parentContext === void 0 ? void 0 : parentContext.handles) === null || _b === void 0 ? void 0 : _b.handleRedirect) === null || _c === void 0 ? void 0 : _c.call(_b, stepDetail, entityId);
  }
};
/**
 * The hook returns a init method to trigger the onboarding guides
 * @return {{initOnboarding: Function}} - returns a method that makes api call to get guide progress data and init the guide ui
 */
var useInitOnboarding = function useInitOnboarding() {
  var parentContext = React.useContext(index$1.ParentContext);
  var dispatch = index.useOnboardingDispatch();
  var initializeMaps = useOnboardingMaps["default"]();
  var checkVendorResources = function checkVendorResources(modules) {
    return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
      var isAlignUser, vendorId, vendorCompany, resources, currentModules, alignModule, stages, newStages, requestPayload, request, error_1;
      var _a;
      return tslib_es6.__generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            isAlignUser = ((_a = modules === null || modules === void 0 ? void 0 : modules[0]) === null || _a === void 0 ? void 0 : _a.name) === index$3.Modules.ALIGN;
            if (!isAlignUser) return [2 /*return*/, true];
            _b.label = 1;
          case 1:
            _b.trys.push([1, 5,, 6]);
            vendorId = parentContext === null || parentContext === void 0 ? void 0 : parentContext.config.signUpInitiatingVendor;
            return [4 /*yield*/, index$4.getVendorDetails(vendorId)];
          case 2:
            vendorCompany = _b.sent();
            // if partner is not part of vendor anymore but onboarding is not done yet
            if (vendorCompany.status !== Vendor.VendorSubscriptionStatus.NOTAPPLIED) return [2 /*return*/, true];
            return [4 /*yield*/, index$4.getVendorResourcesByVendorId(vendorId, Resources.ROOT_RESOURCE_ID)];
          case 3:
            resources = _b.sent();
            currentModules = tslib_es6.__spreadArray([], modules, true);
            alignModule = currentModules[0];
            stages = alignModule.stages;
            if (!resources.data.length) {
              newStages = stages.map(function (stage) {
                return tslib_es6.__assign(tslib_es6.__assign({}, stage), {
                  is_complete: true
                });
              });
              // Mark other 2 stages as complete.
              newStages[0].is_complete = false;
              alignModule.stages = newStages;
            } else {
              // Make 3 stages
              alignModule.stages = stages.length === 1 ? [stages[0], {
                name: index$3.Stages.ALIGN_VENDORS_AND_RESOURCES,
                is_complete: false
              }, {
                name: index$3.Stages.ALIGN_EXPLORE_RESOURCES,
                is_complete: false
              }] : stages;
              currentModules[0] = alignModule;
            }
            requestPayload = {
              modules: currentModules
            };
            request = {
              requestPayload: requestPayload,
              hasAPIUpdate: true
            };
            return [4 /*yield*/, dispatch(index$2["default"].updateCheckListBarData(request))];
          case 4:
            _b.sent();
            return [2 /*return*/, !!!resources.data.length];
          case 5:
            error_1 = _b.sent();
            console.error(error_1);
            return [3 /*break*/, 6];
          case 6:
            return [2 /*return*/, true];
        }
      });
    });
  };
  var initOnboarding = function initOnboarding(isAuthenticatedFromSignIn, isPaymentNeeded) {
    if (isAuthenticatedFromSignIn === void 0) {
      isAuthenticatedFromSignIn = false;
    }
    if (isPaymentNeeded === void 0) {
      isPaymentNeeded = false;
    }
    return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
      var hasBotSource, _a, welcome_video, is_checklist_active, modules, moduleOrder, isResourcesEmpty, onboardingMaps, showWelcomeVideo, newSteps, e_1;
      var _b, _c;
      return tslib_es6.__generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            hasBotSource = ((_c = (_b = parentContext === null || parentContext === void 0 ? void 0 : parentContext.config) === null || _b === void 0 ? void 0 : _b.userData) === null || _c === void 0 ? void 0 : _c.bot_user_source) !== null;
            if (isPaymentNeeded || hasBotSource) return [2 /*return*/];
            _d.label = 1;
          case 1:
            _d.trys.push([1, 6,, 7]);
            return [4 /*yield*/, dispatch(index$2["default"].getChecklistBarData())];
          case 2:
            _a = _d.sent(), welcome_video = _a.welcome_video, is_checklist_active = _a.is_checklist_active, modules = _a.modules;
            moduleOrder = modules.map(function (module) {
              return module.name;
            });
            if (moduleOrder) {
              dispatch(index$2["default"].updateModuleOrder(moduleOrder));
            }
            return [4 /*yield*/, checkVendorResources(modules)];
          case 3:
            isResourcesEmpty = _d.sent();
            onboardingMaps = initializeMaps(isResourcesEmpty);
            dispatch(index$2["default"].updateOnboardingMaps(onboardingMaps));
            showWelcomeVideo = isAuthenticatedFromSignIn && (welcome_video === null || welcome_video === void 0 ? void 0 : welcome_video.status) && ![index$3.WelcomeVideoStatus.WATCHED, index$3.WelcomeVideoStatus.DONT_SHOW_AGAIN].includes(welcome_video === null || welcome_video === void 0 ? void 0 : welcome_video.status);
            if (showWelcomeVideo) {
              dispatch(index$2["default"].updateActiveProgressData({
                activeModule: null,
                activeStage: null,
                activeStep: index$3.Steps.WELCOME_VIDEO
              }));
              dispatch(index$2["default"].updateProgressInitilization(true));
            }
            //return if onboarding is disabled
            if (!is_checklist_active) {
              return [2 /*return*/];
            }

            newSteps = getNextSteps();
            // check for welcome videos status
            if (!showWelcomeVideo) {
              dispatch(index$2["default"].updateActiveProgressData(newSteps));
              dispatch(index$2["default"].updateProgressInitilization(true));
            }
            if (newSteps === null || newSteps === void 0 ? void 0 : newSteps.activeModule) {
              navigateToNextModule(newSteps, parentContext);
            }
            return [4 /*yield*/, dispatch(index$2["default"].getDummyClientData())];
          case 4:
            _d.sent();
            return [4 /*yield*/, dispatch(index$2["default"].getDocumentTemplateData())];
          case 5:
            _d.sent();
            return [3 /*break*/, 7];
          case 6:
            e_1 = _d.sent();
            console.error(e_1);
            return [3 /*break*/, 7];
          case 7:
            return [2 /*return*/];
        }
      });
    });
  };

  return {
    initOnboarding: initOnboarding
  };
};

exports.getIncompleteStageDetail = getIncompleteStageDetail;
exports.getModuleDetails = getModuleDetails;
exports.getNextSteps = getNextSteps;
exports.navigateToNextModule = navigateToNextModule;
exports.useInitOnboarding = useInitOnboarding;
