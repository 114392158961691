'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.VendorClientActionTypes = void 0;
(function (VendorClientActionTypes) {
  VendorClientActionTypes["SHOW_VENDOR_CLIENT_LOADER"] = "SHOW_VENDOR_CLIENT_LOADER";
  VendorClientActionTypes["HIDE_VENDOR_CLIENT_LOADER"] = "HIDE_VENDOR_CLIENT_LOADER";
  VendorClientActionTypes["OPEN_EDIT_VENDOR_CLIENT_DRAWER"] = "OPEN_EDIT_VENDOR_CLIENT_DRAWER";
  VendorClientActionTypes["CLOSE_EDIT_VENDOR_CLIENT_DRAWER"] = "CLOSE_EDIT_VENDOR_CLIENT_DRAWER";
})(exports.VendorClientActionTypes || (exports.VendorClientActionTypes = {}));
var VendorClientActionCreators = {
  showVendorClientLoader: function showVendorClientLoader() {
    return function (dispatch) {
      var showVendorOpportunityLoaderAction = {
        type: exports.VendorClientActionTypes.SHOW_VENDOR_CLIENT_LOADER
      };
      return dispatch(showVendorOpportunityLoaderAction);
    };
  },
  hideVendorClientLoader: function hideVendorClientLoader() {
    return function (dispatch) {
      var hideVendorOpportunityLoaderAction = {
        type: exports.VendorClientActionTypes.HIDE_VENDOR_CLIENT_LOADER
      };
      return dispatch(hideVendorOpportunityLoaderAction);
    };
  },
  openEditVendorClientDrawer: function openEditVendorClientDrawer() {
    return function (dispatch) {
      var openOpportunityDrawerAction = {
        type: exports.VendorClientActionTypes.OPEN_EDIT_VENDOR_CLIENT_DRAWER
      };
      return dispatch(openOpportunityDrawerAction);
    };
  },
  closeEditVendorClientDrawer: function closeEditVendorClientDrawer() {
    return function (dispatch) {
      var closeOpportunityDrawerAction = {
        type: exports.VendorClientActionTypes.CLOSE_EDIT_VENDOR_CLIENT_DRAWER
      };
      return dispatch(closeOpportunityDrawerAction);
    };
  }
};

exports.VendorClientActionCreators = VendorClientActionCreators;
exports["default"] = VendorClientActionCreators;
