'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../node_modules/tslib/tslib.es6.mjs.js');
var contracts = require('@zomentum/contracts');
var Products = require('@zomentum/contracts/dist/Products');
var ProposalBuilder = require('@zomentum/contracts/dist/ProposalBuilder');
var Block = require('@zomentum/contracts/dist/ProposalBuilder/Block');
var common = require('../common.js');

var formPageBasedBlocks = function (blockList, blockMapper) {
  var pageBasedBlocks = [];
  var blocksPerPage = [];
  blockList.forEach(function (blockId, index) {
    var _a;
    if (((_a = blockMapper[blockId]) === null || _a === void 0 ? void 0 : _a.type) === Block.BlockType.PAGE_BREAK) {
      blocksPerPage.push(blockId);
      pageBasedBlocks.push(blocksPerPage);
      blocksPerPage = [];
      return;
    }
    if (blockMapper[blockId]) {
      blocksPerPage.push(blockId);
    }
    if (index === blockList.length - 1) {
      pageBasedBlocks.push(blocksPerPage);
    }
  });
  return pageBasedBlocks;
};
var getDragEndResult = function (blockList, blockMapper, result) {
  var pageBasedBlocks = formPageBasedBlocks(blockList, blockMapper);
  var source = result.source,
    destination = result.destination;
  var destinationPageNumber = Number(destination === null || destination === void 0 ? void 0 : destination.droppableId.split("::")[1]);
  var sourcePageNumber = Number(source === null || source === void 0 ? void 0 : source.droppableId.split("::")[1]);
  if (isNaN(destinationPageNumber) || isNaN(sourcePageNumber) || !destination) {
    console.warn("Invalid destination page number");
    return {
      destinationBlockId: null,
      sourceBlockId: null,
      destinationPageBlocks: [],
      sourcePageBlocks: [],
      destinationPageNumber: 0,
      sourcePageNumber: 0
    };
  }
  var destinationPageBlocks = pageBasedBlocks[destinationPageNumber];
  var sourcePageBlocks = pageBasedBlocks[sourcePageNumber];
  var destinationBlockId = destinationPageBlocks[destination.index];
  var sourceBlockId = sourcePageBlocks[source.index];
  return {
    destinationBlockId: destinationBlockId,
    sourceBlockId: sourceBlockId,
    destinationPageBlocks: destinationPageBlocks,
    sourcePageBlocks: sourcePageBlocks,
    destinationPageNumber: destinationPageNumber,
    sourcePageNumber: sourcePageNumber
  };
};
var addBlockToDocument = function (blockList, blockMapper, result) {
  var _a;
  var destination = result.destination;
  if (!destination) {
    return;
  }
  var pageBasedBlocks = formPageBasedBlocks(blockList, blockMapper);
  var pageNumber = Number((_a = result.destination) === null || _a === void 0 ? void 0 : _a.droppableId.split("::")[1]);
  if (isNaN(pageNumber)) {
    console.warn("Invalid page number");
    return;
  }
  var pageBlocks = pageBasedBlocks[pageNumber];
  if (!pageBlocks && !pageBasedBlocks.length) {
    return destination.index;
  }
  var currentPresentBlock = pageBlocks[destination.index];
  var flatBlocksIndex = blockList.findIndex(function (blockId) {
    return blockId === currentPresentBlock;
  });
  if (flatBlocksIndex < 0) {
    // adding block to a new page other than first page
    var blocksAbove = tslib_es6.__spreadArray(tslib_es6.__spreadArray([], pageBasedBlocks === null || pageBasedBlocks === void 0 ? void 0 : pageBasedBlocks.slice(0, pageNumber), true), pageBlocks, true).flat();
    return blocksAbove.length;
  }
  return flatBlocksIndex;
};
var removeEstimatedPageBreaks = function (index) {
  var existingPageBreaks = document === null || document === void 0 ? void 0 : document.querySelectorAll(".estimated-page-break-".concat(index));
  existingPageBreaks.forEach(function (pageBreak) {
    var _a;
    (_a = pageBreak.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(pageBreak);
  });
};
var createAndAppendPageBreakElement = function (index, pageTop, currentPage) {
  var currentEstimatedPageBreak = document.createElement("div");
  currentEstimatedPageBreak.style.position = "absolute";
  currentEstimatedPageBreak.style.right = "-11rem";
  currentEstimatedPageBreak.className = "estimated-page-break-".concat(index);
  currentEstimatedPageBreak.innerHTML = "<div class=\"flex space-x-4 relative\">\n\t\t\t<div>----</div>\n\t\t\t<div class=\"flex flex-col items-center relative -top-2\">\n\t\t\t\t<div>Estimated</div> <div>page break</div>\n\t\t\t</div>\n\t\t</div>";
  currentEstimatedPageBreak.style.top = "".concat(pageTop, "px");
  currentPage.appendChild(currentEstimatedPageBreak);
};
var calculateEstimatedPageBreaks = function (index, isHeadersFootersActive) {
  var _a;
  var a4heightPx = Math.floor(ProposalBuilder.PX_PER_MM * 297);
  var pageTopPadding = isHeadersFootersActive ? 72 : 56;
  var topBottomPadding = pageTopPadding * 2;
  var a4ContentSize = a4heightPx - topBottomPadding;
  var currentPage = document.querySelector("#document-page-".concat(index));
  var currentPageHeight = Math.floor((_a = currentPage === null || currentPage === void 0 ? void 0 : currentPage.getBoundingClientRect().height) !== null && _a !== void 0 ? _a : 0);
  if (!currentPageHeight) {
    return;
  }
  if (!isHeadersFootersActive) {
    currentPageHeight -= topBottomPadding;
  }
  var estimatedPageBreaks = Math.floor(currentPageHeight / a4ContentSize);
  removeEstimatedPageBreaks(index);
  if (!currentPage || currentPageHeight === a4ContentSize || estimatedPageBreaks < 1) {
    return;
  }
  tslib_es6.__spreadArray([], new Array(estimatedPageBreaks), true).forEach(function (_pageBreak, pageNumber) {
    var pageTop = a4ContentSize * (1 + pageNumber) + (isHeadersFootersActive ? 0 : pageTopPadding);
    createAndAppendPageBreakElement(index, pageTop - 11, currentPage);
  });
};
var calculateAccurateEstimatedPageBreaks = function (index, isHeadersFootersActive) {
  var _a, _b, _c;
  var a4heightPx = Math.floor(ProposalBuilder.PX_PER_MM * 297);
  var pageTopPadding = isHeadersFootersActive ? 72 : 56;
  var topBottomPadding = pageTopPadding * 2;
  var a4ContentSize = a4heightPx - topBottomPadding;
  var currentPage = document.querySelector("#document-page-".concat(index));
  var currentPageHeight = Math.floor((_a = currentPage === null || currentPage === void 0 ? void 0 : currentPage.getBoundingClientRect().height) !== null && _a !== void 0 ? _a : 0);
  var currentPageTop = (_c = (_b = currentPage === null || currentPage === void 0 ? void 0 : currentPage.getBoundingClientRect()) === null || _b === void 0 ? void 0 : _b.top) !== null && _c !== void 0 ? _c : 0;
  if (!currentPageHeight) {
    return;
  }
  if (!isHeadersFootersActive) {
    currentPageHeight -= topBottomPadding;
  }
  var estimatedPageBreaks = Math.floor(currentPageHeight / a4ContentSize);
  removeEstimatedPageBreaks(index);
  if (!currentPage || currentPageHeight === a4ContentSize || estimatedPageBreaks < 1) {
    return;
  }
  var prohibitedElementsForPageBreak = currentPage === null || currentPage === void 0 ? void 0 : currentPage.querySelectorAll(".image-block.parent-block-container, .video-block.parent-block-container, .signature-block.parent-block-container, .quote-product__name, .quote-product__images, .quote-block-footer, .quote-section__header, div.mce-content-body > h1, h2, h3, h4, h5, h6, .risk-score-component");
  var allEstimatedPageBreaks = tslib_es6.__spreadArray([], new Array(estimatedPageBreaks), true).map(function (_pageBreak, pageNumber) {
    return a4ContentSize * (1 + pageNumber) + (isHeadersFootersActive ? 0 : pageTopPadding);
  });
  if (!!(prohibitedElementsForPageBreak === null || prohibitedElementsForPageBreak === void 0 ? void 0 : prohibitedElementsForPageBreak.length)) {
    var newEstimatedPageBreaks_1 = allEstimatedPageBreaks;
    //mapping over all the image and video blocks
    prohibitedElementsForPageBreak.forEach(function (block) {
      newEstimatedPageBreaks_1 = getNewEstimatedPageBreaks(block, newEstimatedPageBreaks_1, currentPageTop);
    });
    newEstimatedPageBreaks_1.forEach(function (pageBreak) {
      createAndAppendPageBreakElement(index, pageBreak - 11, currentPage);
    });
  } else {
    tslib_es6.__spreadArray([], new Array(estimatedPageBreaks), true).forEach(function (_pageBreak, pageNumber) {
      var pageTop = a4ContentSize * (1 + pageNumber) + (isHeadersFootersActive ? 0 : pageTopPadding);
      createAndAppendPageBreakElement(index, pageTop - 11, currentPage);
    });
  }
};
var getNewEstimatedPageBreaks = function (block, allEstimatedPageBreaks, currentPageTop) {
  var _a, _b, _c;
  var newEstimatedPageBreaks = tslib_es6.__spreadArray([], allEstimatedPageBreaks, true);
  // getting the block dimensions
  var blockRect = block === null || block === void 0 ? void 0 : block.getBoundingClientRect();
  var blockTop = (_a = Math.floor((blockRect === null || blockRect === void 0 ? void 0 : blockRect.top) - currentPageTop)) !== null && _a !== void 0 ? _a : 0;
  var blockBottom = (_b = Math.floor((blockRect === null || blockRect === void 0 ? void 0 : blockRect.bottom) - currentPageTop)) !== null && _b !== void 0 ? _b : 0;
  if (blockTop < 0 || blockBottom < 0) {
    return newEstimatedPageBreaks;
  }
  //checking if there is a estimated page break which is inside the image or video block
  var interferingPageBreakIndex = (_c = allEstimatedPageBreaks === null || allEstimatedPageBreaks === void 0 ? void 0 : allEstimatedPageBreaks.findIndex(function (pageBreak) {
    return pageBreak > blockTop && pageBreak < blockBottom;
  })) !== null && _c !== void 0 ? _c : -1;
  // if the above condition is not satisfied we should not change the page break thats why making the value to -1;
  if (interferingPageBreakIndex >= 0) {
    //getting the remaining margin of the block
    var remainingMargin = allEstimatedPageBreaks[interferingPageBreakIndex] - blockTop;
    //replacing the interfering page break with block top
    newEstimatedPageBreaks[interferingPageBreakIndex] = blockTop;
    //subtracting the remaining margin from all the later page breaks
    for (var i = interferingPageBreakIndex + 1; i < allEstimatedPageBreaks.length; i++) {
      var newPageBreak = allEstimatedPageBreaks[i] - remainingMargin;
      //setting the new page breaks after subtracting the remaining margin from it
      newEstimatedPageBreaks[i] = newPageBreak;
    }
  }
  return newEstimatedPageBreaks;
};
var getBlockName = function (bType) {
  var _a;
  var blockType = bType;
  switch (blockType) {
    case Block.BlockType.GRID:
      return "Grid";
    case Block.BlockType.QUOTE:
      return "Quote";
    default:
      return common.capitalizeFirstLetter((_a = blockType === null || blockType === void 0 ? void 0 : blockType.split("_")) === null || _a === void 0 ? void 0 : _a.join(" "));
  }
};
var isBlockReadOnly = function (blockType, editableBlocks) {
  return !editableBlocks.includes(blockType);
};
var fileReader = function (file) {
  var fileReader = new FileReader();
  return new Promise(function (resolve, reject) {
    fileReader.onerror = function () {
      fileReader.abort();
      reject(new Error("Problem parsing file"));
    };
    fileReader.onload = function () {
      resolve(fileReader.result);
    };
    fileReader.readAsText(file);
  });
};
var getNativeLanguageLabels = function (billingType, labels) {
  var _a, _b, _c, _d, _e;
  switch (billingType) {
    case Products.ProductBillingType.Annual:
      return (_a = labels === null || labels === void 0 ? void 0 : labels.annually_recurring_label) !== null && _a !== void 0 ? _a : "".concat(Products.productBillingTypeOptions[Products.ProductBillingType.Annual], " Recurring");
    case Products.ProductBillingType.Monthly:
      return (_b = labels === null || labels === void 0 ? void 0 : labels.monthly_recurring_label) !== null && _b !== void 0 ? _b : "".concat(Products.productBillingTypeOptions[Products.ProductBillingType.Monthly], " Recurring");
    case Products.ProductBillingType.Quarterly:
      return (_c = labels === null || labels === void 0 ? void 0 : labels.quarterly_recurring_label) !== null && _c !== void 0 ? _c : "".concat(Products.productBillingTypeOptions[Products.ProductBillingType.Quarterly], " Recurring");
    case Products.ProductBillingType.SemiAnnually:
      return (_d = labels === null || labels === void 0 ? void 0 : labels.semi_annually_recurring_label) !== null && _d !== void 0 ? _d : "".concat(Products.productBillingTypeOptions[Products.ProductBillingType.SemiAnnually], " Recurring");
    case Products.ProductBillingType.Weekly:
      return (_e = labels === null || labels === void 0 ? void 0 : labels.weekly_recurring_label) !== null && _e !== void 0 ? _e : "".concat(Products.productBillingTypeOptions[Products.ProductBillingType.Weekly], " Recurring");
    case Products.ProductBillingType.OneTime:
    default:
      return "";
  }
};
var getEditQuantityMessage = function (quantityRange) {
  if (quantityRange.max_quantity !== null || quantityRange.min_quantity !== null) {
    if (quantityRange.max_quantity !== null && quantityRange.min_quantity !== null) {
      return "Enter value between ".concat(quantityRange.min_quantity, " and ").concat(quantityRange.max_quantity);
    }
    if (quantityRange.max_quantity !== null) return "Value cannot be more than ".concat(quantityRange.max_quantity);
    if (quantityRange.min_quantity !== null) return "Value cannot be less than ".concat(quantityRange.min_quantity);
  }
  return "";
};
var validateInputNumber = function (value) {
  if (value === undefined || value === null) {
    return false;
  }
  var quantityValue = parseInt(value.toString());
  return !isNaN(quantityValue) && quantityValue < contracts.NumberLimit.NUMBER_MAX;
};

exports.addBlockToDocument = addBlockToDocument;
exports.calculateAccurateEstimatedPageBreaks = calculateAccurateEstimatedPageBreaks;
exports.calculateEstimatedPageBreaks = calculateEstimatedPageBreaks;
exports.fileReader = fileReader;
exports.formPageBasedBlocks = formPageBasedBlocks;
exports.getBlockName = getBlockName;
exports.getDragEndResult = getDragEndResult;
exports.getEditQuantityMessage = getEditQuantityMessage;
exports.getNativeLanguageLabels = getNativeLanguageLabels;
exports.isBlockReadOnly = isBlockReadOnly;
exports.removeEstimatedPageBreaks = removeEstimatedPageBreaks;
exports.validateInputNumber = validateInputNumber;
