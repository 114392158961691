import React, { memo } from "react";
import {
	OnboardingProvider,
	withOnboardingProvider
} from "@modules/onboarding/dist";
import { defaultParentContextProps } from "@modules/onboarding/dist/store/context";

const OnboardingWrapper: React.FC = ({ children }) => {
	return (
		<OnboardingProvider {...defaultParentContextProps}>
			{children}
		</OnboardingProvider>
	);
};

export default withOnboardingProvider(memo(OnboardingWrapper));
