import { Reducer } from "redux";
import {
	GlobalSearchActions,
	GlobalSearchActionTypes
} from "../../../Actions/Others/GlobalSearch";
import { GlobalSearchEntityObj } from "@zomentum/contracts/dist/Others/GlobalSearch";

export interface GlobalSearchState {
	readonly isLoading: boolean;
	readonly entityList: GlobalSearchEntityObj[];
	readonly nextCursor: string | null;
}

const initialState: GlobalSearchState = {
	isLoading: false,
	entityList: new Array<GlobalSearchEntityObj>(),
	nextCursor: null
};

export const GlobalSearchReducer: Reducer<
	GlobalSearchState,
	GlobalSearchActions
> = (
	state: GlobalSearchState = initialState,
	action: GlobalSearchActions
): GlobalSearchState => {
	switch (action.type) {
		case GlobalSearchActionTypes.SHOW_GLOBAL_SEARCH_LOADER: {
			return { ...state, isLoading: true };
		}
		case GlobalSearchActionTypes.HIDE_GLOBAL_SEARCH_LOADER: {
			return { ...state, isLoading: false };
		}
		case GlobalSearchActionTypes.GLOBAL_SEARCH_REQUESTED: {
			return state;
		}
		case GlobalSearchActionTypes.GLOBAL_SEARCH_FAILED: {
			return state;
		}
		case GlobalSearchActionTypes.GLOBAL_SEARCH_SUCCESSFUL: {
			const newEntityList = action.loadMoreAction
				? [...state.entityList, ...action.entityList]
				: action.entityList;
			return {
				...state,
				entityList: newEntityList,
				nextCursor: action.nextCursor
			};
		}
		case GlobalSearchActionTypes.GLOBAL_SEARCH_RESET: {
			return {
				...state,
				entityList: new Array<GlobalSearchEntityObj>(),
				nextCursor: null
			};
		}
		default: {
			return state;
		}
	}
};
