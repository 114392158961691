'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.UppyAllowedFileTypes = void 0;
(function (UppyAllowedFileTypes) {
    UppyAllowedFileTypes["ALL"] = "*/*";
    UppyAllowedFileTypes["IMAGE"] = "image/jpg,image/jpeg,image/png,image/gif,image/svg,image/webp, image/svg+xml";
    UppyAllowedFileTypes["VIDEO"] = "video/mp4,.mkv,video/webm,video/x-matroska,application/octet-stream";
    UppyAllowedFileTypes["PDF"] = "application/pdf";
    UppyAllowedFileTypes["FAVICON"] = "image/vnd.microsoft.icon,image/x-icon";
    UppyAllowedFileTypes["EXCEL"] = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
    UppyAllowedFileTypes["CSV"] = ".csv";
    UppyAllowedFileTypes["DOCX"] = ".docx,.doc";
    UppyAllowedFileTypes["TSV"] = ".tsv";
    UppyAllowedFileTypes["TXT"] = ".txt";
    UppyAllowedFileTypes["XML"] = ".xml";
    UppyAllowedFileTypes["PPTX"] = ".ppt,.pptx";
    UppyAllowedFileTypes["XLSX"] = ".xls,.xlsx";
})(exports.UppyAllowedFileTypes || (exports.UppyAllowedFileTypes = {}));
var NotAllowedExtensions = /\.(exe|js|htaccess)$/gi;
exports.UploadStage = void 0;
(function (UploadStage) {
    UploadStage[UploadStage["FILE_SELECT"] = 0] = "FILE_SELECT";
    UploadStage[UploadStage["UPLOAD_LINK"] = 1] = "UPLOAD_LINK";
    UploadStage[UploadStage["UPLOADING_IN_PROGRESS"] = 2] = "UPLOADING_IN_PROGRESS";
    UploadStage[UploadStage["UPLOADING_DONE"] = 3] = "UPLOADING_DONE";
})(exports.UploadStage || (exports.UploadStage = {}));

exports.NotAllowedExtensions = NotAllowedExtensions;
