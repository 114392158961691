'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var utils = require('@zomentum/hooks/dist/hooks/utils');
var React = require('react');
var index = require('../store/context/ParentContext/index.js');

var useAccessLevel = function useAccessLevel(id) {
  var parentContext = React.useContext(index.ParentContext);
  return utils.checkUseAccessLevel(parentContext.user.userData)(id);
};

exports["default"] = useAccessLevel;
