'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../node_modules/tslib/tslib.es6.mjs.js');
var contracts = require('@zomentum/contracts');

var baseAppUrl = process.env.REACT_APP_API_HOST;
var setHeaders = function (urlEncodedForm, multipartFormUpload, useToken, userIp, customHeaders) {
  var _a, _b;
  var authToken = contracts.cache.get(contracts.ZomentumStorageKeys.Token, true);
  var userIpAddress = (_b = ((_a = userIp === null || userIp === void 0 ? void 0 : userIp.trim()) === null || _a === void 0 ? void 0 : _a.length) && userIp) !== null && _b !== void 0 ? _b : contracts.cache.get(contracts.ZomentumStorageKeys.UserIp, true);
  var updatedCustomHeaders = customHeaders ? Object.entries(customHeaders).reduce(function (final, _a) {
    var _b;
    var key = _a[0],
      value = _a[1];
    if (value) {
      final = tslib_es6.__assign(tslib_es6.__assign({}, final), (_b = {}, _b[key] = value, _b));
    }
    return final;
  }, {}) : {};
  var additionalHeaders = tslib_es6.__assign({}, updatedCustomHeaders);
  if (authToken && useToken) {
    additionalHeaders["Authorization"] = "Bearer " + authToken.token;
  }
  if (urlEncodedForm) {
    additionalHeaders["Content-Type"] = "application/x-www-form-urlencoded; charset=utf-8";
  } else if (multipartFormUpload) {
    additionalHeaders["Content-Type"] = "multipart/form-data";
  } else {
    additionalHeaders["Content-Type"] = "application/json";
    additionalHeaders["Accept"] = "application/json";
  }
  if (userIpAddress) additionalHeaders["X-Forwarded-For"] = userIpAddress;
  additionalHeaders["Cache-Control"] = "no-cache";
  additionalHeaders["Pragma"] = "no-cache";
  return additionalHeaders;
};
var getRequest = function (url, data, useToken, urlEncoded, isFileDownload, userIp, customHeaders) {
  var options = {
    method: "GET",
    baseURL: baseAppUrl,
    url: url,
    curlirize: true
  };
  if (data) {
    options.data = data;
  }
  if (isFileDownload) {
    options.responseType = "blob";
  }
  options.headers = setHeaders(urlEncoded, false, useToken, userIp, customHeaders);
  return options;
};
var postRequest = function (url, data, useToken, urlEncoded, multipartFormUpload, isFileDownload, customHeaders) {
  var options = {
    method: "POST",
    baseURL: baseAppUrl,
    url: url,
    curlirize: false
  };
  if (data) {
    options.data = data;
  }
  if (isFileDownload) {
    options.responseType = "blob";
  }
  options.headers = setHeaders(urlEncoded || false, multipartFormUpload || false, useToken, undefined, customHeaders);
  return options;
};
var putRequest = function (url, data, useToken, urlEncoded, multipartFormUpload, customHeaders) {
  var options = {
    method: "PUT",
    baseURL: baseAppUrl,
    url: url,
    curlirize: false
  };
  if (data) {
    options.data = data;
  }
  options.headers = setHeaders(urlEncoded, multipartFormUpload, useToken, undefined, customHeaders);
  return options;
};
var deleteRequest = function (url, data, useToken, urlEncoded) {
  var options = {
    method: "DELETE",
    baseURL: baseAppUrl,
    url: url,
    curlirize: false
  };
  if (data) {
    options.data = data;
  }
  options.headers = setHeaders(urlEncoded, false, useToken);
  return options;
};
var patchRequest = function (url, data, useToken, urlEncoded, multipartFormUpload) {
  var options = {
    method: "PATCH",
    baseURL: baseAppUrl,
    url: url,
    curlirize: false
  };
  if (data) {
    options.data = data;
  }
  options.headers = setHeaders(urlEncoded, multipartFormUpload, useToken);
  return options;
};
var genericGetRespect = function (url, params, headers) {
  if (params === void 0) {
    params = {};
  }
  if (headers === void 0) {
    headers = {};
  }
  var options = {
    method: "GET",
    url: url,
    headers: headers,
    params: params,
    curlirize: false
  };
  return options;
};
var genericPostConfig = function (url, data, headers) {
  if (data === void 0) {
    data = {};
  }
  if (headers === void 0) {
    headers = {};
  }
  var options = {
    method: "POST",
    url: url,
    data: data,
    headers: headers,
    curlirize: false
  };
  return options;
};
var postFetchRequest = function (url, data, useToken, urlEncoded, multipartFormUpload) {
  var options = {
    keepalive: true,
    method: "PUT",
    headers: setHeaders(urlEncoded || false, multipartFormUpload || false, useToken),
    body: JSON.stringify(data)
  };
  return {
    options: options,
    apiUrl: baseAppUrl + url
  };
};

exports.deleteRequest = deleteRequest;
exports.genericGetRespect = genericGetRespect;
exports.genericPostConfig = genericPostConfig;
exports.getRequest = getRequest;
exports.patchRequest = patchRequest;
exports.postFetchRequest = postFetchRequest;
exports.postRequest = postRequest;
exports.putRequest = putRequest;
