import ZVideo from "@zomentum/organisms/dist/ZVideo";
import ZIcon, { Icons } from "@zomentum/atoms/dist/ZIcon";
import ZTooltip from "@zomentum/atoms/dist/ZTooltip";
import React from "react";
import { IVideoItemsProps } from "./interface";

const ZVideoItems: React.FC<IVideoItemsProps> = ({
	video,
	showPreviewVideo,
	deleteVideo,
	showVideoUploadModal,
	className
}) => {
	return (
		<div className={`flex items-center flex-wrap ${className}`}>
			{!!video?.length && (
				<div className="relative">
					<ZVideo
						video={video}
						isPreview={true}
						className="h-28"
						id="preview-video"
					/>
					<div className="absolute top-0.5 flex items-center justify-around opacity-0 hover:opacity-100 hover:bg-gray-10 hover:bg-opacity-50 w-full h-full">
						<ZTooltip title="Preview" placement="bottom">
							<ZIcon
								name={Icons.EYE}
								onClick={() => {
									showPreviewVideo(video);
								}}
								className={`cursor-pointer`}
								colorVariant={1}
							/>
						</ZTooltip>
						<ZTooltip title="Delete" placement="bottom">
							<ZIcon
								name={Icons.TRASH}
								onClick={() => {
									deleteVideo(video);
								}}
								className="cursor-pointer"
								colorVariant={1}
							/>
						</ZTooltip>
					</div>
				</div>
			)}
			{!video?.length && (
				<div
					className="border relative border-solid border-gray-5 rounded mr-2 h-28 w-28 flex flex-col items-center justify-center cursor-pointer hover:bg-gray-3 mb-2"
					onClick={showVideoUploadModal}
				>
					<div className="mb-2">
						<ZIcon name={Icons.ADD} />
					</div>
					<div>Add Video</div>
				</div>
			)}
		</div>
	);
};

export default ZVideoItems;
