import { ZTag } from "@zomentum/atoms";
import ZButton from "@zomentum/atoms/dist/ZButton";
import ZPopover from "@zomentum/atoms/dist/ZPopover";
import ZTypography from "@zomentum/atoms/dist/ZTypography";
import ZInput, { InputWidths } from "@zomentum/molecules/dist/ZInput";
import { Divider, List, Row } from "antd";
import React, { Fragment } from "react";
import "./index.less";
import { IFilterMenuUIProps } from "./interface";

const ZFilterMenu: React.FC<IFilterMenuUIProps> = ({
	isVisible = false,
	currentActiveFilter,
	fieldKeyword = "",
	filtersMap = [],
	showOperatorList = false,
	operatorKeyword = "",
	filteredAllowedOperators = [],
	showValueField = false,
	shouldDisableSubmitButton = true,
	...rest
}) => (
	<ZPopover
		size="sm"
		placement="rightTop"
		hideArrow={true}
		trigger="click"
		visible={isVisible}
		onVisibleChange={rest.onVisibleChange}
		destroyTooltipOnHide
		removeDefaultPadding={true}
		content={
			<div className="filter-container py-4">
				<Row className="filter-search-flow-container flex flex-col">
					{(!currentActiveFilter ||
						!currentActiveFilter?.field_name) && (
						<Fragment>
							<ZTypography.ZText
								colorVariant={7}
								className="block mb-2 px-4"
							>
								FIELD SELECTED
							</ZTypography.ZText>
							<ZInput
								width={InputWidths.full}
								inputType="search"
								placeholder="Search"
								allowClear
								value={fieldKeyword}
								onChange={rest.handleFieldKeywordChange}
								className="px-4"
							/>
						</Fragment>
					)}
					{!!currentActiveFilter?.field_display_name && (
						<Fragment>
							<ZTypography.ZText
								colorVariant={7}
								className="block mb-2 px-4"
							>
								FIELD SELECTED
							</ZTypography.ZText>
							<div className="px-4 w-full">
								<ZTag
									className="filter-tag max-w-full mb-2"
									closable={true}
									onClose={rest.removeSelectedFilterField}
								>
									<div className="truncate">
										{currentActiveFilter.field_display_name}
									</div>
								</ZTag>
							</div>
							{!!currentActiveFilter.filter_operator
								?.display_value && (
								<Fragment>
									<Divider className="my-2" />
									<ZTypography.ZText
										colorVariant={7}
										className="block mb-2 px-4"
									>
										CONDITION
									</ZTypography.ZText>
									<ZTag
										className="mb-2 mx-4 filter-tag"
										closable={true}
										onClose={rest.removeSelectedOperator}
									>
										{
											currentActiveFilter.filter_operator
												.display_value
										}
									</ZTag>
								</Fragment>
							)}
						</Fragment>
					)}
					{!currentActiveFilter?.field_display_name && (
						<div className="scroll-container w-full mt-4">
							{filtersMap?.map(listData => (
								<div
									className="field-list-data-container"
									key={listData.entity}
								>
									<List
										dataSource={listData.filters}
										renderItem={filter => (
											<List.Item
												className="px-4 cursor-pointer hover:bg-geekBlue-1 border-0"
												onClick={() =>
													rest.handleFilterFieldSelect(
														filter
													)
												}
											>
												<div className="truncate">
													{filter.field_display_name}
												</div>
											</List.Item>
										)}
									/>
								</div>
							))}
						</div>
					)}
					{showOperatorList && (
						<>
							<Divider className="my-2" />
							<ZTypography.ZText
								colorVariant={7}
								className="block mb-2 px-4"
							>
								CONDITION
							</ZTypography.ZText>
							<ZInput
								inputType="search"
								placeholder="Search"
								width={InputWidths.full}
								allowClear
								value={operatorKeyword}
								onChange={rest.handleOperatorKeywordChange}
								className="px-4"
							/>
							<div className="scroll-container w-full mt-4">
								<div className="field-list-data-container">
									<List
										dataSource={filteredAllowedOperators}
										renderItem={operator => (
											<List.Item
												className="px-4 cursor-pointer hover:bg-geekBlue-1 border-0"
												onClick={() =>
													rest.handleOperatorSelect(
														operator
													)
												}
											>
												<div>
													{operator.display_value}
												</div>
											</List.Item>
										)}
									/>
								</div>
							</div>
						</>
					)}
					{showValueField && (
						<>
							<Divider className="my-2" />
							<ZTypography.ZText
								colorVariant={7}
								className="block mb-2 px-4"
							>
								VALUE
							</ZTypography.ZText>
							<div className="value-input w-full px-4">
								{rest.getFieldForValue()}
							</div>
						</>
					)}
				</Row>
				<Row className="flex items-center justify-end pt-4 px-4 mt-4 border-0 border-t border-solid border-gray-5">
					<ZButton
						className="mr-4"
						onClick={rest.onFilterPopoverBackButtonClick}
						size="small"
					>
						{!!currentActiveFilter?.field_display_name ||
						!!currentActiveFilter?.filter_operator
							? "Back"
							: "Cancel"}
					</ZButton>
					<ZButton
						type="primary"
						disabled={shouldDisableSubmitButton}
						onClick={rest.handleFilterSubmit}
						data-testid="submit"
						size="small"
					>
						{!!rest.selectedFilter ? "Save" : "Apply"}
					</ZButton>
				</Row>
			</div>
		}
	>
		{rest.children}
	</ZPopover>
);

export default ZFilterMenu;
