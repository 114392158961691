'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

require('@zomentum/contracts/dist/Users');
var Block = require('@zomentum/contracts/dist/ProposalBuilder/Block');
var Products = require('@zomentum/contracts/dist/Products');
var Documents = require('@zomentum/contracts/dist/Documents');
var utils = require('@zomentum/utils');

var _a;
var calculateSubTotal = function calculateSubTotal(productData) {
  var selected_setup_price = productData.selected_setup_price,
    selected_cost_price = productData.selected_cost_price,
    selected_setup_discount = productData.selected_setup_discount,
    sell_price = productData.sell_price,
    quantity = productData.quantity,
    discount = productData.discount,
    should_add_setup_price_to_margin = productData.should_add_setup_price_to_margin,
    product_type = productData.product_type,
    show_individual_product_pricing = productData.show_individual_product_pricing,
    _a = productData.bundled_products,
    bundled_products = _a === void 0 ? [] : _a;
  var setupPriceTotal = quantity > 0 ? selected_setup_price - selected_setup_discount : 0;
  var totalSellPrice = utils.roundTwoDecimals(sell_price) * quantity;
  var selectedCostPrice = utils.roundTwoDecimals(selected_cost_price);
  var taxableSellPrice = utils.roundTwoDecimals(sell_price) * quantity;
  // check if bundle and has individual pricing,
  var isBundleProductWithIndividualPricing = product_type === Products.ProductType.Bundle && show_individual_product_pricing;
  if (isBundleProductWithIndividualPricing) {
    // removing unselected optional products
    var validBundledProducts = (bundled_products || []).filter(function (a) {
      return a.tax_category_id && (a.is_optional ? a.is_selected : true);
    });
    // adding the bundled items setup fee to the product setup fee
    setupPriceTotal += validBundledProducts.reduce(function (total, curr) {
      return total + (curr.selected_setup_price || 0);
    }, 0);
    // overriding the sell price tot he bundled item sell price.
    totalSellPrice = validBundledProducts.reduce(function (total, curr) {
      return total + (utils.roundTwoDecimals(curr.sell_price) || 0) * curr.quantity;
    }, 0);
    taxableSellPrice = validBundledProducts.reduce(function (total, curr) {
      if (!curr.tax_category_id) {
        return total;
      }
      return total + (utils.roundTwoDecimals(curr.sell_price) || 0) * curr.quantity;
    }, 0);
    // overriding the sell price to the bundled item sell price.
    selectedCostPrice = validBundledProducts.reduce(function (total, curr) {
      return total + (utils.roundTwoDecimals(curr.selected_cost_price) || 0) * curr.quantity;
    }, 0);
  }
  var totalCostPrice = selectedCostPrice * quantity;
  var finalSellPrice = totalSellPrice - discount * quantity;
  taxableSellPrice = taxableSellPrice - discount * quantity;
  var subTotal = !!should_add_setup_price_to_margin ? finalSellPrice + setupPriceTotal : finalSellPrice;
  var margin = Math.round((subTotal - totalCostPrice) * 100) / 100;
  var marginPercentage = !!subTotal && Math.round(margin / subTotal * 100 * 100) / 100 || 0;
  return {
    finalSellPrice: utils.roundTwoDecimals(finalSellPrice),
    margin: utils.roundTwoDecimals(margin),
    marginPercentage: utils.roundTwoDecimals(marginPercentage),
    setupPriceTotal: utils.roundTwoDecimals(setupPriceTotal),
    subTotal: utils.roundTwoDecimals(subTotal),
    totalCostPrice: utils.roundTwoDecimals(totalCostPrice),
    taxableSellPrice: utils.roundTwoDecimals(taxableSellPrice),
    totalSellPrice: utils.roundTwoDecimals(totalSellPrice)
  };
};
var calculateFlatTaxSubTotal = function calculateFlatTaxSubTotal(productData) {
  var selected_setup_price = productData.selected_setup_price,
    selected_cost_price = productData.selected_cost_price,
    selected_setup_discount = productData.selected_setup_discount,
    sell_price = productData.sell_price,
    quantity = productData.quantity,
    discount = productData.discount,
    should_add_setup_price_to_margin = productData.should_add_setup_price_to_margin,
    product_type = productData.product_type,
    show_individual_product_pricing = productData.show_individual_product_pricing,
    _a = productData.bundled_products,
    bundled_products = _a === void 0 ? [] : _a;
  var setupPriceTotal = quantity > 0 ? selected_setup_price - selected_setup_discount : 0;
  var totalSellPrice = utils.roundTwoDecimals(sell_price) * quantity;
  var selectedCostPrice = utils.roundTwoDecimals(selected_cost_price);
  var taxableSellPrice = productData.tax_type === Products.ProductTaxType.disabled ? 0 : utils.roundTwoDecimals(sell_price) * quantity;
  // check if bundle and has individual pricing,
  var isBundleProductWithIndividualPricing = product_type === Products.ProductType.Bundle && show_individual_product_pricing;
  if (isBundleProductWithIndividualPricing) {
    // removing unselected optional products
    var validBundledProducts = (bundled_products || []).filter(function (a) {
      return a.is_optional ? a.is_selected : true;
    });
    // adding the bundled items setup fee to the product setup fee
    setupPriceTotal += validBundledProducts.reduce(function (total, curr) {
      return total + (curr.selected_setup_price || 0);
    }, 0);
    // overriding the sell price tot he bundled item sell price.
    totalSellPrice = validBundledProducts.reduce(function (total, curr) {
      return total + (utils.roundTwoDecimals(curr.sell_price) || 0) * curr.quantity;
    }, 0);
    taxableSellPrice = validBundledProducts.reduce(function (total, curr) {
      if (curr.tax_type === Products.ProductTaxType.disabled) {
        return total;
      }
      return total + (utils.roundTwoDecimals(curr.sell_price) || 0) * curr.quantity;
    }, 0);
    // overriding the sell price tot he bundled item sell price.
    selectedCostPrice = validBundledProducts.reduce(function (total, curr) {
      return total + (utils.roundTwoDecimals(curr.selected_cost_price) || 0) * curr.quantity;
    }, 0);
  }
  var totalCostPrice = selectedCostPrice * quantity;
  var finalSellPrice = totalSellPrice - discount;
  taxableSellPrice = taxableSellPrice - discount;
  var subTotal = !!should_add_setup_price_to_margin ? finalSellPrice + setupPriceTotal : finalSellPrice;
  var margin = Math.round((subTotal - totalCostPrice) * 100) / 100;
  var marginPercentage = !!subTotal && Math.round(margin / subTotal * 100 * 100) / 100 || 0;
  return {
    finalSellPrice: utils.roundTwoDecimals(finalSellPrice),
    margin: utils.roundTwoDecimals(margin),
    marginPercentage: utils.roundTwoDecimals(marginPercentage),
    setupPriceTotal: utils.roundTwoDecimals(setupPriceTotal),
    subTotal: utils.roundTwoDecimals(subTotal),
    totalCostPrice: utils.roundTwoDecimals(totalCostPrice),
    taxableSellPrice: utils.roundTwoDecimals(taxableSellPrice),
    totalSellPrice: utils.roundTwoDecimals(totalSellPrice)
  };
};
var calculateBlockTotalWithoutTax = function calculateBlockTotalWithoutTax(blockData) {
  return blockData.total ? blockData.total.one_time + blockData.total.annual + blockData.total.monthly + blockData.total.semi_annually + blockData.total.weekly + blockData.total.quarterly : 0;
};
var calculateTax = function calculateTax(blockData, document, taxRegions, taxCategories) {
  var _a, _b;
  var selectedProducts = Block.QuoteBlockData.getSelectedProducts(blockData);
  var validProducts = selectedProducts.filter(function (currentProduct) {
    return currentProduct.tax_category_id !== null;
  });
  var totalTax = 0;
  var taxCategoryBreakdown = {};
  var taxRegionId = (_a = document === null || document === void 0 ? void 0 : document.tax_region_id) !== null && _a !== void 0 ? _a : (_b = taxRegions === null || taxRegions === void 0 ? void 0 : taxRegions.find(function (taxRegion) {
    return taxRegion.is_default;
  })) === null || _b === void 0 ? void 0 : _b.id;
  validProducts.forEach(function (product) {
    var _a, _b, _c;
    if (product.product_type === Products.ProductType.Bundle && product.show_individual_product_pricing) {
      var validBundledProducts = (product.bundled_products || []).filter(function (a) {
        return a.tax_category_id && (a.is_optional ? a.is_selected : true);
      });
      var validBundledProductsSetupPriceTotal_1 = validBundledProducts.reduce(function (total, curr) {
        return total + ((curr.sell_price || 0) + (curr.selected_setup_price || 0));
      }, 0);
      validBundledProducts === null || validBundledProducts === void 0 ? void 0 : validBundledProducts.forEach(function (bundledProduct) {
        var _a;
        var taxCategory = taxCategories === null || taxCategories === void 0 ? void 0 : taxCategories.find(function (category) {
          return category.id === bundledProduct.tax_category_id;
        });
        if (taxCategory && taxRegionId) {
          if (!taxCategoryBreakdown[taxCategory.id]) {
            taxCategoryBreakdown[taxCategory.id] = new Array();
          }
          var taxCodes = (_a = taxCategory === null || taxCategory === void 0 ? void 0 : taxCategory.tax_codes) === null || _a === void 0 ? void 0 : _a.filter(function (taxCode) {
            var _a;
            return ((_a = taxCode === null || taxCode === void 0 ? void 0 : taxCode.region) === null || _a === void 0 ? void 0 : _a.id) === taxRegionId;
          });
          var taxLevels = taxCodes === null || taxCodes === void 0 ? void 0 : taxCodes.map(function (taxCode) {
            return taxCode.levels;
          }).reduce(function (aggregate, taxCodeLevels) {
            return aggregate.concat(taxCodeLevels);
          }, new Array());
          var bundleProductSellPrice_1 = (bundledProduct.sell_price || 0) * (bundledProduct.quantity || 0) + (bundledProduct.selected_setup_price || 0);
          var weightage_ratio = validBundledProductsSetupPriceTotal_1 ? bundleProductSellPrice_1 / validBundledProductsSetupPriceTotal_1 : 0;
          bundleProductSellPrice_1 = bundleProductSellPrice_1 - weightage_ratio * (blockData.discount || 0) - weightage_ratio * (product.discount || 0);
          taxLevels === null || taxLevels === void 0 ? void 0 : taxLevels.forEach(function (taxLevel) {
            totalTax += (taxLevel === null || taxLevel === void 0 ? void 0 : taxLevel.tax_percentage) * bundleProductSellPrice_1 / 100;
            var index = taxCategoryBreakdown[taxCategory.id].findIndex(function (level) {
              return level.name === taxLevel.name;
            });
            if (index > -1) {
              taxCategoryBreakdown[taxCategory.id][index].tax_percentage += (taxLevel === null || taxLevel === void 0 ? void 0 : taxLevel.tax_percentage) * bundleProductSellPrice_1 / 100;
            } else {
              taxCategoryBreakdown[taxCategory.id].push({
                name: taxLevel.name,
                tax_percentage: (taxLevel === null || taxLevel === void 0 ? void 0 : taxLevel.tax_percentage) * bundleProductSellPrice_1 / 100
              });
            }
          });
        }
      });
      var bundleTaxCategory_1 = taxCategories === null || taxCategories === void 0 ? void 0 : taxCategories.find(function (category) {
        return category.id === product.tax_category_id;
      });
      if (bundleTaxCategory_1 && taxRegionId) {
        if (!taxCategoryBreakdown[bundleTaxCategory_1.id]) {
          taxCategoryBreakdown[bundleTaxCategory_1.id] = new Array();
        }
        var bundleTaxCodes = (_a = bundleTaxCategory_1 === null || bundleTaxCategory_1 === void 0 ? void 0 : bundleTaxCategory_1.tax_codes) === null || _a === void 0 ? void 0 : _a.filter(function (taxCode) {
          var _a;
          return ((_a = taxCode === null || taxCode === void 0 ? void 0 : taxCode.region) === null || _a === void 0 ? void 0 : _a.id) === taxRegionId;
        });
        var bundleTaxLevels = bundleTaxCodes === null || bundleTaxCodes === void 0 ? void 0 : bundleTaxCodes.map(function (taxCode) {
          return taxCode.levels;
        }).reduce(function (aggregate, taxCodeLevels) {
          return aggregate.concat(taxCodeLevels);
        }, new Array());
        var blockAdditionalPrice_1 = (product.selected_setup_price || 0) - (product.selected_setup_discount || 0);
        bundleTaxLevels === null || bundleTaxLevels === void 0 ? void 0 : bundleTaxLevels.forEach(function (taxLevel) {
          totalTax += (taxLevel === null || taxLevel === void 0 ? void 0 : taxLevel.tax_percentage) * blockAdditionalPrice_1 / 100;
          var index = taxCategoryBreakdown[bundleTaxCategory_1.id].findIndex(function (level) {
            return level.name === taxLevel.name;
          });
          if (index > -1) {
            taxCategoryBreakdown[bundleTaxCategory_1.id][index].tax_percentage += (taxLevel === null || taxLevel === void 0 ? void 0 : taxLevel.tax_percentage) * blockAdditionalPrice_1 / 100;
          } else {
            taxCategoryBreakdown[bundleTaxCategory_1.id].push({
              name: taxLevel.name,
              tax_percentage: (taxLevel === null || taxLevel === void 0 ? void 0 : taxLevel.tax_percentage) * blockAdditionalPrice_1 / 100
            });
          }
        });
      }
    } else {
      var taxCategory_1 = taxCategories === null || taxCategories === void 0 ? void 0 : taxCategories.find(function (category) {
        return category.id === product.tax_category_id;
      });
      if (taxCategory_1 && taxRegionId) {
        if (!taxCategoryBreakdown[taxCategory_1.id]) {
          taxCategoryBreakdown[taxCategory_1.id] = new Array();
        }
        var taxCodes = (_b = taxCategory_1 === null || taxCategory_1 === void 0 ? void 0 : taxCategory_1.tax_codes) === null || _b === void 0 ? void 0 : _b.filter(function (taxCode) {
          var _a;
          return ((_a = taxCode === null || taxCode === void 0 ? void 0 : taxCode.region) === null || _a === void 0 ? void 0 : _a.id) === taxRegionId;
        });
        var taxLevels = taxCodes === null || taxCodes === void 0 ? void 0 : taxCodes.map(function (taxCode) {
          return taxCode.levels;
        }).reduce(function (aggregate, taxCodeLevels) {
          return aggregate.concat(taxCodeLevels);
        }, new Array());
        var calculatedTotal = (document === null || document === void 0 ? void 0 : document.tax_region_id) && ((_c = document === null || document === void 0 ? void 0 : document.tax_region_id) === null || _c === void 0 ? void 0 : _c.length) > 0 ? calculateSubTotal(product) : calculateFlatTaxSubTotal(product);
        var finalSellPrice_1 = calculatedTotal.finalSellPrice;
        var setupPriceTotal = calculatedTotal.setupPriceTotal;
        // blockdData.total.grandTotal keeps recalculating tax, take separate values of all billing period
        var blockDataTotal = calculateBlockTotalWithoutTax(blockData);
        finalSellPrice_1 = finalSellPrice_1 + setupPriceTotal - getWeightedBlockDiscountForProduct(blockDataTotal, blockData.discount, finalSellPrice_1);
        taxLevels === null || taxLevels === void 0 ? void 0 : taxLevels.forEach(function (taxLevel) {
          totalTax += (taxLevel === null || taxLevel === void 0 ? void 0 : taxLevel.tax_percentage) * finalSellPrice_1 / 100;
          var index = taxCategoryBreakdown[taxCategory_1.id].findIndex(function (level) {
            return level.name === taxLevel.name;
          });
          if (index > -1) {
            taxCategoryBreakdown[taxCategory_1.id][index].tax_percentage += (taxLevel === null || taxLevel === void 0 ? void 0 : taxLevel.tax_percentage) * finalSellPrice_1 / 100;
          } else {
            taxCategoryBreakdown[taxCategory_1.id].push({
              name: taxLevel.name,
              tax_percentage: (taxLevel === null || taxLevel === void 0 ? void 0 : taxLevel.tax_percentage) * finalSellPrice_1 / 100
            });
          }
        });
      }
    }
  });
  return {
    tax: utils.roundTwoDecimals(totalTax),
    taxCategoryBreakdown: taxCategoryBreakdown
  };
};
var calculateFlatTax = function calculateFlatTax(blockData) {
  var selectedProducts = Block.QuoteBlockData.getSelectedProducts(blockData);
  var validCost = selectedProducts.reduce(function (costSum, currentProduct) {
    var taxableSellPrice = calculateFlatTaxSubTotal(currentProduct).taxableSellPrice;
    return costSum + taxableSellPrice;
  }, 0);
  var taxable = validCost - (blockData === null || blockData === void 0 ? void 0 : blockData.discount);
  var totalTax = taxable * (blockData === null || blockData === void 0 ? void 0 : blockData.tax_percentage) / 100;
  return utils.roundTwoDecimals(totalTax);
};
var getWeightedBlockDiscountForProduct = function getWeightedBlockDiscountForProduct(blockGrandTotal, blockDiscount, productSellPrice) {
  if (!blockGrandTotal || !blockDiscount || !productSellPrice) {
    return 0;
  }
  var ratio = productSellPrice / blockGrandTotal;
  return ratio * blockDiscount;
};
(_a = {}, _a[Products.ProductBillingType.OneTime] = "one_time", _a[Products.ProductBillingType.Weekly] = "weekly", _a[Products.ProductBillingType.Monthly] = "monthly", _a[Products.ProductBillingType.Annual] = "annual", _a[Products.ProductBillingType.Quarterly] = "quarterly", _a[Products.ProductBillingType.SemiAnnually] = "semi_annually", _a);
var calculateGrandTotal = function calculateGrandTotal(blockData, document, taxRegions, taxCategories) {
  var _a, _b;
  if (!blockData || !blockData.sections || !blockData.sections.length) {
    return new Documents.QuoteTotal();
  }
  var selectedProducts = Block.QuoteBlockData.getSelectedProducts(blockData);
  var totalObject = selectedProducts.reduce(function (calculatedTotal, currentProduct) {
    var _a;
    var selected_billing_period = currentProduct.selected_billing_period,
      show_individual_product_pricing = currentProduct.show_individual_product_pricing,
      product_type = currentProduct.product_type,
      _b = currentProduct.bundled_products,
      bundled_products = _b === void 0 ? [] : _b;
    var _c = (document === null || document === void 0 ? void 0 : document.tax_region_id) && ((_a = document === null || document === void 0 ? void 0 : document.tax_region_id) === null || _a === void 0 ? void 0 : _a.length) > 0 ? calculateSubTotal(currentProduct) : calculateFlatTaxSubTotal(currentProduct),
      setupPriceTotal = _c.setupPriceTotal,
      finalSellPrice = _c.finalSellPrice;
    if (product_type !== Products.ProductType.Bundle) {
      calculatedTotal[selected_billing_period] += finalSellPrice;
    } else {
      if (show_individual_product_pricing) {
        var validBundledProducts = (bundled_products || []).filter(function (a) {
          return a.is_optional ? a.is_selected : true;
        });
        validBundledProducts.forEach(function (bundledProduct) {
          var calculatedTotalSellPrice = (utils.roundTwoDecimals(bundledProduct.sell_price) || 0) * (bundledProduct.quantity || 1);
          calculatedTotal[bundledProduct.selected_billing_period] += calculatedTotalSellPrice;
          // calculatedTotal.one_time += bundledProduct.selected_setup_price;
        });
      } else {
        // adding bundle total to the grand total
        calculatedTotal[selected_billing_period] += finalSellPrice;
        // adding totals of selected optional products
        var validBundledProducts = (bundled_products || []).filter(function (a) {
          return a.is_optional && a.is_selected;
        });
        validBundledProducts.forEach(function (bundledProduct) {
          var calculatedTotalSellPrice = (utils.roundTwoDecimals(bundledProduct.sell_price) || 0) * (bundledProduct.quantity || 1) * (currentProduct.quantity || 1);
          calculatedTotal[bundledProduct.selected_billing_period] += calculatedTotalSellPrice;
          calculatedTotal.one_time += bundledProduct.selected_setup_price;
        });
      }
    }
    calculatedTotal.one_time += setupPriceTotal;
    return calculatedTotal;
  }, new Documents.QuoteTotal());
  totalObject.grand = totalObject.weekly + totalObject.annual + totalObject.monthly + totalObject.one_time + totalObject.quarterly + totalObject.semi_annually;
  totalObject.grand += (document === null || document === void 0 ? void 0 : document.tax_region_id) && ((_a = document === null || document === void 0 ? void 0 : document.tax_region_id) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (_b = calculateTax(blockData, document, taxRegions, taxCategories)) === null || _b === void 0 ? void 0 : _b.tax : calculateFlatTax(blockData);
  totalObject.grand -= blockData.discount;
  var result = {
    monthly: utils.roundTwoDecimals(totalObject.monthly),
    weekly: utils.roundTwoDecimals(totalObject.weekly),
    annual: utils.roundTwoDecimals(totalObject.annual),
    one_time: utils.roundTwoDecimals(totalObject.one_time),
    grand: utils.roundTwoDecimals(totalObject.grand),
    quarterly: utils.roundTwoDecimals(totalObject.quarterly),
    semi_annually: utils.roundTwoDecimals(totalObject.semi_annually)
  };
  return result;
};

exports.calculateBlockTotalWithoutTax = calculateBlockTotalWithoutTax;
exports.calculateFlatTax = calculateFlatTax;
exports.calculateFlatTaxSubTotal = calculateFlatTaxSubTotal;
exports.calculateGrandTotal = calculateGrandTotal;
exports.calculateSubTotal = calculateSubTotal;
exports.calculateTax = calculateTax;
exports.getWeightedBlockDiscountForProduct = getWeightedBlockDiscountForProduct;
