'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var utils = require('@zomentum/hooks/dist/hooks/utils');
var React = require('react');
var index = require('../store/context/ParentContext/index.js');

/**
 *
 * @param requiredPlan: Required pricing plan for the user to access this Feature.
 * If all users should be able to access this feature then pass **IGNITE**.
 * All the old accounts existing before pricing plan won't have any **PRICING PLAN** for their account
 * and will have access to all feature by default.
 *
 * @param requiredRole: Required User Role to access this feature.
 * If all users should be able to access this feature then pass **UserRole.UserRole**
 *
 * @description processes whether the user should be able to view feature based on the **USER ROLE** and
 * **CURRENT ACTIVE PRICING PLAN**
 */
var useFeatureAccess = function useFeatureAccess(requiredPlan, requiredRole, permissions, skipPermissions) {
  var _a, _b, _c;
  var parentContext = React.useContext(index.ParentContext);
  return utils.checkUseFeatureAccess((_a = parentContext.user.userData) === null || _a === void 0 ? void 0 : _a.role, (_c = (_b = parentContext.user.userData) === null || _b === void 0 ? void 0 : _b.roles_permissions) === null || _c === void 0 ? void 0 : _c.permissions, parentContext.user.subscriptions.find(function (plan) {
    return plan.current_plan;
  }))(requiredPlan, requiredRole, permissions, skipPermissions);
};

exports["default"] = useFeatureAccess;
