'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../node_modules/tslib/tslib.es6.mjs.js');
var common = require('../common.js');
var index = require('../Users/index.js');

var EmailTemplateRequest = /** @class */function () {
  function EmailTemplateRequest() {
    this.name = "";
    this.subject = "";
    this.body = "";
    this.file_details = new Array();
    this.custom_template_type = null;
    this.system_template_info = null;
  }
  return EmailTemplateRequest;
}();
var EmailTemplate = /** @class */function (_super) {
  tslib_es6.__extends(EmailTemplate, _super);
  function EmailTemplate() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.id = "";
    _this.name = "";
    _this.subject = "";
    _this.body = "";
    _this.file_details = new Array();
    _this.merge_tags_system_variables = new Array();
    _this.user = new index.UserData();
    _this.created = new Date();
    _this.updated = new Date();
    _this.custom_template_type = null;
    _this.deleted = false;
    _this.defaultTemplate = false;
    _this.system_template_info = null;
    return _this;
  }
  return EmailTemplate;
}(common.ZomentumEntity);
var EmailTemplatesDrawerFormValues = /** @class */function () {
  function EmailTemplatesDrawerFormValues() {
    this.title = "";
    this.subject = "";
    this.body = "";
  }
  return EmailTemplatesDrawerFormValues;
}();
exports.CustomTemplateType = void 0;
(function (CustomTemplateType) {
  CustomTemplateType["SendDocument"] = "send_document";
  CustomTemplateType["DocumentReminder"] = "document_reminder";
  CustomTemplateType["SignDocument"] = "sign_document";
  CustomTemplateType["DocumentExpired"] = "document_expired";
  CustomTemplateType["DocumentWon"] = "document_won";
  CustomTemplateType["Comment"] = "comment";
})(exports.CustomTemplateType || (exports.CustomTemplateType = {}));
var EmailTemplatesResponse = /** @class */function () {
  function EmailTemplatesResponse() {
    this.filtered_count_map = null;
    this.data = new Array();
  }
  return EmailTemplatesResponse;
}();
var EntitySelectionFormValues = /** @class */function () {
  function EntitySelectionFormValues() {
    this.client = "";
    this.opportunity = null;
    this.documents = null;
    this.recipients = new Array();
  }
  return EntitySelectionFormValues;
}();
var SelectedEntityValues = /** @class */function () {
  function SelectedEntityValues() {
    this.client = "";
    this.opportunity = null;
    this.documents = null;
    this.recipients = new Array();
  }
  return SelectedEntityValues;
}();
var UseTemplateValues = /** @class */function () {
  function UseTemplateValues() {
    this.subject = "";
    this.body = "";
    this.attachments = new Array();
    this.entity_details = new SelectedEntityValues();
  }
  return UseTemplateValues;
}();
var EmailTemplateGetRequest = /** @class */function () {
  function EmailTemplateGetRequest(filters, keywords) {
    this.filters = filters;
    this.keywords = keywords;
  }
  return EmailTemplateGetRequest;
}();
var PreviewEmailTemplateRequest = /** @class */function () {
  function PreviewEmailTemplateRequest() {
    this.from = null;
    this.subject = "";
    this.body = "";
    this.should_replace_with_empty_string = true;
    this.document_id = "";
    this.template_type = exports.CustomTemplateType.SendDocument;
  }
  return PreviewEmailTemplateRequest;
}();

exports.EmailTemplate = EmailTemplate;
exports.EmailTemplateGetRequest = EmailTemplateGetRequest;
exports.EmailTemplateRequest = EmailTemplateRequest;
exports.EmailTemplatesDrawerFormValues = EmailTemplatesDrawerFormValues;
exports.EmailTemplatesResponse = EmailTemplatesResponse;
exports.EntitySelectionFormValues = EntitySelectionFormValues;
exports.PreviewEmailTemplateRequest = PreviewEmailTemplateRequest;
exports.SelectedEntityValues = SelectedEntityValues;
exports.UseTemplateValues = UseTemplateValues;
