import {
	CustomViewsMetadata,
	CustomViewResponse,
	CustomView,
	ListPagePostRequest
} from "@zomentum/contracts/dist/UI";
import axios, { AxiosResponse } from "axios";
import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest
} from "@zomentum/utils/dist/Service/BackendService";
import {
	ChildEntityHydrationParams,
	includeChildEntities
} from "@zomentum/contracts/dist/Utils";
import {
	VendorTemplate,
	VendorTemplateDetail,
	VendorTemplateEditOrCloneRequest,
	VendorTemplateResponse
} from "@zomentum/contracts/dist/Vendor/Templates";
import { ZomentumEntities } from "@zomentum/contracts";

const getAllVendorTemplateDetailsPaginationURL = "/v2/templates/view/ ";
const cloneVendorTemplateURL = "/v2/templates/clone";
const editVendorTemplateURL = "/v2/templates/:id";
const deleteVendorTemplateURL = "/v2/templates/:id";
const getVendorTemplatesMetaDataURL = "/v2/views/metadata?entity_type=template";
const getVendorTemplateDetailURL = "/v2/templates/:id";
const reorderTemplateBlocksUrl = "/v2/templates/reorder/{template_id}";

const getAllDocumentViewURL = "/v2/views?entity_type={entity_type}";
const createDocumentViewURL = "/v2/views";
const updateDocumentViewURL = "/v2/views/{view_id}";
const deleteDocumentViewURL = "v2/views/{view_id}";

export const getTemplateListPagePost = async (
	TemplateRequest: ListPagePostRequest
): Promise<VendorTemplateResponse> => {
	try {
		const response: AxiosResponse<VendorTemplateResponse> = await axios(
			postRequest(
				getAllVendorTemplateDetailsPaginationURL,
				TemplateRequest,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const editVendorTemplate = async (
	id: string,
	editVendorTemplateRequest: VendorTemplateEditOrCloneRequest
): Promise<VendorTemplateDetail> => {
	const includedChildEntities = includeChildEntities(
		new Array<ChildEntityHydrationParams>(
			ChildEntityHydrationParams.BLOCKS_CURRENT_REVISION
		)
	);
	const url =
		editVendorTemplateURL.replace(":id", id) +
		(includedChildEntities && includedChildEntities.length > 0
			? "?" + includedChildEntities
			: "");
	try {
		const response: AxiosResponse<VendorTemplateDetail> = await axios(
			putRequest(url, editVendorTemplateRequest, true, false, false)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const cloneVendorTemplate = async (
	cloneVendorTemplateRequest: VendorTemplateEditOrCloneRequest
): Promise<VendorTemplate> => {
	try {
		const response: AxiosResponse<VendorTemplate> = await axios(
			postRequest(
				cloneVendorTemplateURL,
				cloneVendorTemplateRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteVendorTemplate = async (id: string): Promise<void> => {
	try {
		await axios(
			deleteRequest(
				deleteVendorTemplateURL.replace(":id", id),
				null,
				true,
				false
			)
		);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getVendorTemplatesMetaData =
	async (): Promise<CustomViewsMetadata> => {
		try {
			const response = await axios(
				getRequest(
					getVendorTemplatesMetaDataURL,
					null,
					true,
					false,
					false,
					undefined,
					undefined
				)
			);
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const getVendorTemplateDetails = async (
	id: string
): Promise<VendorTemplateDetail> => {
	const includedChildEntities = includeChildEntities(
		new Array<ChildEntityHydrationParams>(
			ChildEntityHydrationParams.BLOCKS_CURRENT_REVISION
		)
	);
	const url =
		getVendorTemplateDetailURL.replace(":id", id) +
		(includedChildEntities && includedChildEntities.length > 0
			? "?" + includedChildEntities
			: "");
	try {
		const response = await axios(
			getRequest(url, null, true, false, false, undefined, undefined)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateTemplateBlockList = async ({
	templateId,
	blockList
}: {
	templateId: string;
	blockList: string[];
}): Promise<VendorTemplateDetail> => {
	try {
		const response: AxiosResponse<VendorTemplateDetail> = await axios(
			putRequest(
				reorderTemplateBlocksUrl.replace("{template_id}", templateId),
				{ block_list: blockList },
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getAllTemplateViews = async (): Promise<CustomViewResponse> => {
	try {
		const response: AxiosResponse<CustomViewResponse> = await axios(
			getRequest(
				getAllDocumentViewURL.replace(
					"{entity_type}",
					ZomentumEntities.VendorTemplate
				),
				null,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const createTemplateView = async (
	customViewRequest: CustomView
): Promise<CustomView> => {
	try {
		const response: AxiosResponse<CustomView> = await axios(
			postRequest(
				createDocumentViewURL,
				customViewRequest,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const updateTemplateView = async (
	customViewRequest: CustomView
): Promise<CustomView> => {
	try {
		const response: AxiosResponse<CustomView> = await axios(
			putRequest(
				updateDocumentViewURL.replace(
					"{view_id}",
					customViewRequest.id
				),
				customViewRequest,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const deleteTemplateView = async (
	customViewId: string
): Promise<unknown> => {
	try {
		const response: AxiosResponse<unknown> = await axios(
			deleteRequest(
				deleteDocumentViewURL.replace("{view_id}", customViewId),
				null,
				true,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};
