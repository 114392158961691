'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var contracts = require('@zomentum/contracts');
var appCache = require('@zomentum/contracts/dist/appCache');

var useDatePattern = function () {
  var _a, _b;
  var userCompany = contracts.cache.get(appCache.cacheConstant.userCompany, true);
  var datePattern = (_b = (_a = userCompany === null || userCompany === void 0 ? void 0 : userCompany.settings) === null || _a === void 0 ? void 0 : _a.date_pattern) !== null && _b !== void 0 ? _b : "";
  return datePattern.replace("dd", "DD");
};
var useDatePattern$1 = useDatePattern;

exports["default"] = useDatePattern$1;
