import {
	MergeTagsAction,
	MergeTagsActionTypes
} from "@/Actions/Others/MergeTags";
import { ZomentumEntities } from "@zomentum/contracts";
import { MergeTagsState } from "@zomentum/contracts/dist/Others/MergeTags";
import { Reducer } from "redux";

export const initialState: MergeTagsState = {
	[ZomentumEntities.Document]: {
		isLoading: false,
		mergeTags: []
	}
};

const MergeTagsReducer: Reducer<MergeTagsState, MergeTagsAction> = (
	state: MergeTagsState = initialState,
	action: MergeTagsAction
): MergeTagsState => {
	switch (action.type) {
		case MergeTagsActionTypes.MERGE_TAGS_REQUESTED: {
			return {
				...state,
				[action.entityType]: {
					isLoading: true,
					mergeTags: [...state[action.entityType].mergeTags]
				}
			};
		}
		case MergeTagsActionTypes.MERGE_TAGS_SUCCESSFUL: {
			return {
				...state,
				[action.entityType]: {
					isLoading: false,
					mergeTags: [...action.mergeTags]
				}
			};
		}
		case MergeTagsActionTypes.MERGE_TAGS_FAILED: {
			return {
				...state,
				[action.entityType]: {
					isLoading: false,
					mergeTags: [...state[action.entityType].mergeTags]
				}
			};
		}

		default: {
			return state;
		}
	}
};

export default MergeTagsReducer;
