export const errors = [
	/ResizeObserver loop limit exceeded/gi,
	/Request failed with status code 401/gi,
	/Could not mount master component/gi,
	/Refresh token is failing with error Error: Login required/gi,
	/REPLY_TIMEOUT/gi,
	/Couldn't find your IP/gi,
	/You are publicly sharing your App Secret. Do not expose the App Secret in browsers, "native" mobile apps, or other non-trusted environments./gi,
	/ChunkLoadError/gi,
	/Collect JS Token is empty/gi,
	/Loading CSS chunk/gi,
	/[object ProgressEvent]/gi,
	/Cannot read properties of undefined (reading 'is')/gi,
	/{"cors":"rejected"}/gi,
	/Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node./gi,
	/Tiny mce subject editor not ready/gi,
	/Token expired/gi,
	/Please provide a feed slug and user id, ie client.feed('user', '1')/gi,
	/Cannot read properties of undefined (reading 'is')/gi,
	/cb-master-frame iframe load failed/gi,
	/Action timed out/gi,
	/DataCloneError/gi,
	/The following deprecated features are currently enabled, these will be removed in TinyMCE 6.0/gi,
	/The element or ID supplied is not valid/gi,
	/Error during service worker registration/gi,
	/Worker was terminated/gi,
	/Failed to load plugin/gi,
	/Failed to fetch/gi,
	/Login required/gi,
	/ETIMEDOUT/gi,
	/Cannot read properties of undefined (reading 'logEvent')/gi,
	/[object CustomEvent]/gi
];

export default (message?: string, extra?: string): boolean => {
	return !errors.some(
		msg => message?.match(msg)?.length || extra?.match(msg)?.length
	);
};
