'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.VendorOpportunityActionTypes = void 0;
(function (VendorOpportunityActionTypes) {
  VendorOpportunityActionTypes["SHOW_VENDOR_OPPORTUNITY_LOADER"] = "SHOW_VENDOR_OPPORTUNITY_LOADER";
  VendorOpportunityActionTypes["HIDE_VENDOR_OPPORTUNITY_LOADER"] = "HIDE_VENDOR_OPPORTUNITY_LOADER";
  VendorOpportunityActionTypes["OPEN_ADD_OPPORTUNITY_DRAWER"] = "OPEN_ADD_OPPORTUNITY_DRAWER";
  VendorOpportunityActionTypes["CLOSE_ADD_OPPORTUNITY_DRAWER"] = "CLOSE_ADD_OPPORTUNITY_DRAWER";
  VendorOpportunityActionTypes["OPEN_EDIT_OPPORTUNITY_DRAWER"] = "OPEN_EDIT_OPPORTUNITY_DRAWER";
  VendorOpportunityActionTypes["CLOSE_EDIT_OPPORTUNITY_DRAWER"] = "CLOSE_EDIT_OPPORTUNITY_DRAWER";
})(exports.VendorOpportunityActionTypes || (exports.VendorOpportunityActionTypes = {}));
var VendorOpportunityActionCreators = {
  showVendorOpportunityLoader: function showVendorOpportunityLoader() {
    return function (dispatch) {
      var showVendorOpportunityLoaderAction = {
        type: exports.VendorOpportunityActionTypes.SHOW_VENDOR_OPPORTUNITY_LOADER
      };
      return dispatch(showVendorOpportunityLoaderAction);
    };
  },
  hideVendorOpportunityLoader: function hideVendorOpportunityLoader() {
    return function (dispatch) {
      var hideVendorOpportunityLoaderAction = {
        type: exports.VendorOpportunityActionTypes.HIDE_VENDOR_OPPORTUNITY_LOADER
      };
      return dispatch(hideVendorOpportunityLoaderAction);
    };
  },
  openAddOpportunityDrawer: function openAddOpportunityDrawer() {
    return function (dispatch) {
      var openOpportunityDrawerAction = {
        type: exports.VendorOpportunityActionTypes.OPEN_ADD_OPPORTUNITY_DRAWER
      };
      return dispatch(openOpportunityDrawerAction);
    };
  },
  closeAddOpportunityDrawer: function closeAddOpportunityDrawer() {
    return function (dispatch) {
      var closeOpportunityDrawerAction = {
        type: exports.VendorOpportunityActionTypes.CLOSE_ADD_OPPORTUNITY_DRAWER
      };
      return dispatch(closeOpportunityDrawerAction);
    };
  },
  openEditOpportunityDrawer: function openEditOpportunityDrawer() {
    return function (dispatch) {
      var openOpportunityDrawerAction = {
        type: exports.VendorOpportunityActionTypes.OPEN_EDIT_OPPORTUNITY_DRAWER
      };
      return dispatch(openOpportunityDrawerAction);
    };
  },
  closeEditOpportunityDrawer: function closeEditOpportunityDrawer() {
    return function (dispatch) {
      var closeOpportunityDrawerAction = {
        type: exports.VendorOpportunityActionTypes.CLOSE_EDIT_OPPORTUNITY_DRAWER
      };
      return dispatch(closeOpportunityDrawerAction);
    };
  }
};

exports.VendorOpportunityActionCreators = VendorOpportunityActionCreators;
exports["default"] = VendorOpportunityActionCreators;
