import axios, { AxiosResponse } from "axios";

import {
	deleteRequest,
	getRequest,
	postRequest
} from "@zomentum/utils/dist/Service/BackendService";

import { DeletedRecord } from "@zomentum/contracts";
import {
	CreateLibraryItemRequest,
	GetLibraryRequest,
	GetLibraryResponse,
	LibraryItem,
	UserEmailSignature
} from "@zomentum/contracts/dist/Users";

// library
const getLibraryDataUrl = "/v2/library";
const createLibraryItemUrl = "/v2/library/";
const deleteLibraryItemUrl = "/v2/library/{library_item_id}";

// payments
const updateUserSignatureURL = "/v2/email/signatures";
const fetchUserSignatureURL = "/v2/email/signatures";

export const getLibraryData = async (
	fetchLibraryRequest: GetLibraryRequest
): Promise<GetLibraryResponse> => {
	try {
		const queryParam =
			"?included_child_entities=" +
			fetchLibraryRequest.included_child_entities.join(",");
		const response: AxiosResponse<GetLibraryResponse> = await axios(
			getRequest(getLibraryDataUrl + queryParam, null, true, false, false)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const createLibraryResource = async (
	createLibraryItemRequest: CreateLibraryItemRequest
): Promise<LibraryItem> => {
	try {
		const response: AxiosResponse<LibraryItem> = await axios(
			postRequest(
				createLibraryItemUrl,
				createLibraryItemRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteLibraryResource = async ({
	libraryItemId
}: {
	libraryItemId: string;
}): Promise<DeletedRecord> => {
	try {
		const response: AxiosResponse<DeletedRecord> = await axios(
			deleteRequest(
				deleteLibraryItemUrl.replace(
					"{library_item_id}",
					libraryItemId
				),
				null,
				true,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateUserSignature = async (
	updatedUserSignature: UserEmailSignature
): Promise<UserEmailSignature> => {
	try {
		const response: AxiosResponse<UserEmailSignature> = await axios(
			postRequest(
				updateUserSignatureURL,
				updatedUserSignature,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchUserSignature = async (): Promise<UserEmailSignature> => {
	try {
		const response: AxiosResponse<UserEmailSignature> = await axios(
			getRequest(fetchUserSignatureURL, null, true, false, false)
		);

		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
