import { Dispatch, Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";

import { GlobalState } from "@/Reducers/interface";
import { BulkImportResponse } from "@zomentum/contracts/dist/BulkImport";

import { VendorPartner } from "@zomentum/contracts/dist/Vendor/Partner";
import { vendorPartnersBulkImport } from "@/Services/Vendor/Partner";
import {
	VendorClient,
	VendorClientContact
} from "@zomentum/contracts/dist/Vendor/Clients";
import { VendorOpportunity } from "@zomentum/contracts/dist/Vendor/Opportunity";
import {
	vendorClientsBulkImport,
	vendorClientContactsBulkImport,
	vendorOpportunityBulkImport,
	vendorProductsBulkImport
} from "@/Services/Vendor/BulkImport";
import { SKUProduct } from "@zomentum/contracts/dist/Vendor/SKU";

export enum BulkImportActionTypes {
	SHOW_BULK_IMPORT_LOADER = "SHOW_BULK_IMPORT_LOADER",
	HIDE_BULK_IMPORT_LOADER = "HIDE_BULK_IMPORT_LOADER",
	BULK_IMPORT_RESET = "BULK_IMPORT_RESET",
	SPREADSHEET_BULK_IMPORT_DRAWER_OPENED = "SPREADSHEET_BULK_IMPORT_DRAWER_OPENED",
	SPREADSHEET_BULK_IMPORT_DRAWER_CLOSED = "SPREADSHEET_BULK_IMPORT_DRAWER_CLOSED",
	SOFTWARE_BULK_IMPORT_DRAWER_OPENED = "SOFTWARE_BULK_IMPORT_DRAWER_OPENED",
	SOFTWARE_BULK_IMPORT_DRAWER_CLOSED = "SOFTWARE_BULK_IMPORT_DRAWER_CLOSED",
	EMAIL_BULK_IMPORT_DRAWER_OPENED = "EMAIL_BULK_IMPORT_DRAWER_OPENED",
	EMAIL_BULK_IMPORT_DRAWER_CLOSED = "EMAIL_BULK_IMPORT_DRAWER_CLOSED",
	BULK_IMPORT_ENTITY_LIST_DRAWER_OPENED = "BULK_IMPORT_ENTITY_LIST_DRAWER_OPENED",
	BULK_IMPORT_ENTITY_LIST_DRAWER_CLOSED = "BULK_IMPORT_ENTITY_LIST_DRAWER_CLOSED",

	VENDOR_PARTNERS_BULK_IMPORT_SUCCESSFUL = "VENDOR_PARTNERS_BULK_IMPORT_SUCCESSFUL",
	VENDOR_CLIENTS_BULK_IMPORT_SUCCESSFUL = "VENDOR_CLIENTS_BULK_IMPORT_SUCCESSFUL",
	VENDOR_OPPORTUNITY_BULK_IMPORT_SUCCESSFUL = "VENDOR_OPPORTUNITY_BULK_IMPORT_SUCCESSFUL",
	VENDOR_PRODUCTS_BULK_IMPORT_SUCCESSFUL = "VENDOR_PRODUCTS_BULK_IMPORT_SUCCESSFUL",
	VENDOR_CLIENT_CONTACTS_BULK_IMPORT_SUCCESSFUL = "VENDOR_CLIENT_CONTACTS_BULK_IMPORT_SUCCESSFUL"
}

export type ShowBulkImportLoaderAction =
	Action<BulkImportActionTypes.SHOW_BULK_IMPORT_LOADER>;
export type HideBulkImportLoaderAction =
	Action<BulkImportActionTypes.HIDE_BULK_IMPORT_LOADER>;
export interface VendorPartnersBulkImportSuccessfulAction
	extends Action<BulkImportActionTypes.VENDOR_PARTNERS_BULK_IMPORT_SUCCESSFUL> {
	bulkImportData: BulkImportResponse<VendorPartner>;
}
export type ResetBulkImportAction =
	Action<BulkImportActionTypes.BULK_IMPORT_RESET>;
export type SpreadsheetBulkImportDrawerOpenedAction =
	Action<BulkImportActionTypes.SPREADSHEET_BULK_IMPORT_DRAWER_OPENED>;
export type SpreadsheetBulkImportDrawerClosedAction =
	Action<BulkImportActionTypes.SPREADSHEET_BULK_IMPORT_DRAWER_CLOSED>;
export type SoftwareBulkImportDrawerOpenedAction =
	Action<BulkImportActionTypes.SOFTWARE_BULK_IMPORT_DRAWER_OPENED>;
export type SoftwareBulkImportDrawerClosedAction =
	Action<BulkImportActionTypes.SOFTWARE_BULK_IMPORT_DRAWER_CLOSED>;
export type EmailBulkImportDrawerOpenedAction =
	Action<BulkImportActionTypes.EMAIL_BULK_IMPORT_DRAWER_OPENED>;
export type EmailBulkImportDrawerClosedAction =
	Action<BulkImportActionTypes.EMAIL_BULK_IMPORT_DRAWER_CLOSED>;
export type BulkImportEntityListDrawerOpenedAction =
	Action<BulkImportActionTypes.BULK_IMPORT_ENTITY_LIST_DRAWER_OPENED>;
export type BulkImportEntityListDrawerClosedAction =
	Action<BulkImportActionTypes.BULK_IMPORT_ENTITY_LIST_DRAWER_CLOSED>;
export interface VendorClientsBulkImportSuccessfulAction
	extends Action<BulkImportActionTypes.VENDOR_CLIENTS_BULK_IMPORT_SUCCESSFUL> {
	bulkImportData: BulkImportResponse<VendorClient>;
}
export interface VendorClientContactsBulkImportSuccessfulAction
	extends Action<BulkImportActionTypes.VENDOR_CLIENT_CONTACTS_BULK_IMPORT_SUCCESSFUL> {
	bulkImportData: BulkImportResponse<VendorClientContact>;
}
export interface VendorOpportunityBulkImportSuccessfulAction
	extends Action<BulkImportActionTypes.VENDOR_OPPORTUNITY_BULK_IMPORT_SUCCESSFUL> {
	bulkImportData: BulkImportResponse<VendorOpportunity>;
}
export interface VendorProductsBulkImportSuccessfulAction
	extends Action<BulkImportActionTypes.VENDOR_PRODUCTS_BULK_IMPORT_SUCCESSFUL> {
	bulkImportData: BulkImportResponse<SKUProduct>;
}

export type BulkImportActions =
	| ShowBulkImportLoaderAction
	| HideBulkImportLoaderAction
	| VendorPartnersBulkImportSuccessfulAction
	| SpreadsheetBulkImportDrawerOpenedAction
	| SpreadsheetBulkImportDrawerClosedAction
	| SoftwareBulkImportDrawerOpenedAction
	| SoftwareBulkImportDrawerClosedAction
	| EmailBulkImportDrawerOpenedAction
	| EmailBulkImportDrawerClosedAction
	| BulkImportEntityListDrawerOpenedAction
	| ResetBulkImportAction
	| BulkImportEntityListDrawerClosedAction
	| VendorClientsBulkImportSuccessfulAction
	| VendorClientContactsBulkImportSuccessfulAction
	| VendorOpportunityBulkImportSuccessfulAction
	| VendorProductsBulkImportSuccessfulAction;

export interface BulkImportDispatch {
	showBulkImportLoader: ActionCreator<
		ThunkAction<
			ShowBulkImportLoaderAction,
			GlobalState,
			null,
			ShowBulkImportLoaderAction
		>
	>;
	hideBulkImportLoader: ActionCreator<
		ThunkAction<
			HideBulkImportLoaderAction,
			GlobalState,
			null,
			HideBulkImportLoaderAction
		>
	>;
	bulkImportVendorPartners: ActionCreator<
		ThunkAction<
			Promise<VendorPartnersBulkImportSuccessfulAction>,
			GlobalState,
			FormData,
			VendorPartnersBulkImportSuccessfulAction
		>
	>;
	resetBulkImport: ActionCreator<
		ThunkAction<
			ResetBulkImportAction,
			GlobalState,
			null,
			ResetBulkImportAction
		>
	>;
	spreadsheetBulkImportDrawerOpened: ActionCreator<
		ThunkAction<
			SpreadsheetBulkImportDrawerOpenedAction,
			GlobalState,
			null,
			SpreadsheetBulkImportDrawerOpenedAction
		>
	>;
	spreadsheetBulkImportDrawerClosed: ActionCreator<
		ThunkAction<
			SpreadsheetBulkImportDrawerClosedAction,
			GlobalState,
			null,
			SpreadsheetBulkImportDrawerClosedAction
		>
	>;
	softwareBulkImportDrawerOpened: ActionCreator<
		ThunkAction<
			SoftwareBulkImportDrawerOpenedAction,
			GlobalState,
			null,
			SoftwareBulkImportDrawerOpenedAction
		>
	>;
	softwareBulkImportDrawerClosed: ActionCreator<
		ThunkAction<
			SoftwareBulkImportDrawerClosedAction,
			GlobalState,
			null,
			SoftwareBulkImportDrawerClosedAction
		>
	>;
	emailBulkImportDrawerOpened: ActionCreator<
		ThunkAction<
			EmailBulkImportDrawerOpenedAction,
			GlobalState,
			null,
			EmailBulkImportDrawerOpenedAction
		>
	>;
	emailBulkImportDrawerClosed: ActionCreator<
		ThunkAction<
			EmailBulkImportDrawerClosedAction,
			GlobalState,
			null,
			EmailBulkImportDrawerClosedAction
		>
	>;
	bulkImportEntityListDrawerOpened: ActionCreator<
		ThunkAction<
			BulkImportEntityListDrawerOpenedAction,
			GlobalState,
			null,
			BulkImportEntityListDrawerOpenedAction
		>
	>;
	bulkImportEntityListDrawerClosed: ActionCreator<
		ThunkAction<
			BulkImportEntityListDrawerClosedAction,
			GlobalState,
			null,
			BulkImportEntityListDrawerClosedAction
		>
	>;
	bulkImportVendorClients: ActionCreator<
		ThunkAction<
			Promise<VendorClientsBulkImportSuccessfulAction>,
			GlobalState,
			FormData,
			VendorClientsBulkImportSuccessfulAction
		>
	>;
	bulkImportVendorClientContacts: ActionCreator<
		ThunkAction<
			Promise<VendorClientContactsBulkImportSuccessfulAction>,
			GlobalState,
			FormData,
			VendorClientContactsBulkImportSuccessfulAction
		>
	>;
	bulkImportVendorOpportunity: ActionCreator<
		ThunkAction<
			Promise<VendorOpportunityBulkImportSuccessfulAction>,
			GlobalState,
			FormData,
			VendorOpportunityBulkImportSuccessfulAction
		>
	>;
	bulkImportVendorProducts: ActionCreator<
		ThunkAction<
			Promise<VendorProductsBulkImportSuccessfulAction>,
			GlobalState,
			FormData,
			VendorProductsBulkImportSuccessfulAction
		>
	>;
}

export const BulkImportActionCreators: BulkImportDispatch = {
	showBulkImportLoader: () => {
		return (dispatch: Dispatch): ShowBulkImportLoaderAction => {
			const showBulkImportLoaderAction: ShowBulkImportLoaderAction = {
				type: BulkImportActionTypes.SHOW_BULK_IMPORT_LOADER
			};
			return dispatch(showBulkImportLoaderAction);
		};
	},
	hideBulkImportLoader: () => {
		return (dispatch: Dispatch): HideBulkImportLoaderAction => {
			const hideBulkImportLoaderAction: HideBulkImportLoaderAction = {
				type: BulkImportActionTypes.HIDE_BULK_IMPORT_LOADER
			};
			return dispatch(hideBulkImportLoaderAction);
		};
	},
	bulkImportVendorPartners: (formData: FormData) => {
		return async (
			dispatch: Dispatch
		): Promise<VendorPartnersBulkImportSuccessfulAction> => {
			try {
				const vendorPartnersBulkImportResponse =
					await vendorPartnersBulkImport(formData);
				const vendorPartnersBulkImportSuccessfulAction: VendorPartnersBulkImportSuccessfulAction =
					{
						type: BulkImportActionTypes.VENDOR_PARTNERS_BULK_IMPORT_SUCCESSFUL,
						bulkImportData: vendorPartnersBulkImportResponse
					};
				return dispatch(vendorPartnersBulkImportSuccessfulAction);
			} catch (error) {
				return Promise.reject(error);
			}
		};
	},
	resetBulkImport: () => {
		return (dispatch: Dispatch): ResetBulkImportAction => {
			const resetBulkImportAction: ResetBulkImportAction = {
				type: BulkImportActionTypes.BULK_IMPORT_RESET
			};
			return dispatch(resetBulkImportAction);
		};
	},
	spreadsheetBulkImportDrawerOpened: () => {
		return (
			dispatch: Dispatch
		): SpreadsheetBulkImportDrawerOpenedAction => {
			const spreadsheetBulkImportDrawerOpenedAction: SpreadsheetBulkImportDrawerOpenedAction =
				{
					type: BulkImportActionTypes.SPREADSHEET_BULK_IMPORT_DRAWER_OPENED
				};
			return dispatch(spreadsheetBulkImportDrawerOpenedAction);
		};
	},
	spreadsheetBulkImportDrawerClosed: () => {
		return (
			dispatch: Dispatch
		): SpreadsheetBulkImportDrawerClosedAction => {
			const spreadsheetBulkImportDrawerClosedAction: SpreadsheetBulkImportDrawerClosedAction =
				{
					type: BulkImportActionTypes.SPREADSHEET_BULK_IMPORT_DRAWER_CLOSED
				};
			return dispatch(spreadsheetBulkImportDrawerClosedAction);
		};
	},
	softwareBulkImportDrawerOpened: () => {
		return (dispatch: Dispatch): SoftwareBulkImportDrawerOpenedAction => {
			const softwareBulkImportDrawerOpenedAction: SoftwareBulkImportDrawerOpenedAction =
				{
					type: BulkImportActionTypes.SOFTWARE_BULK_IMPORT_DRAWER_OPENED
				};
			return dispatch(softwareBulkImportDrawerOpenedAction);
		};
	},
	softwareBulkImportDrawerClosed: () => {
		return (dispatch: Dispatch): SoftwareBulkImportDrawerClosedAction => {
			const softwareBulkImportDrawerClosedAction: SoftwareBulkImportDrawerClosedAction =
				{
					type: BulkImportActionTypes.SOFTWARE_BULK_IMPORT_DRAWER_CLOSED
				};
			return dispatch(softwareBulkImportDrawerClosedAction);
		};
	},
	emailBulkImportDrawerOpened: () => {
		return (dispatch: Dispatch): EmailBulkImportDrawerOpenedAction => {
			const emailBulkImportDrawerOpenedAction: EmailBulkImportDrawerOpenedAction =
				{
					type: BulkImportActionTypes.EMAIL_BULK_IMPORT_DRAWER_OPENED
				};
			return dispatch(emailBulkImportDrawerOpenedAction);
		};
	},
	emailBulkImportDrawerClosed: () => {
		return (dispatch: Dispatch): EmailBulkImportDrawerClosedAction => {
			const emailBulkImportDrawerClosedAction: EmailBulkImportDrawerClosedAction =
				{
					type: BulkImportActionTypes.EMAIL_BULK_IMPORT_DRAWER_CLOSED
				};
			return dispatch(emailBulkImportDrawerClosedAction);
		};
	},
	bulkImportEntityListDrawerOpened: () => {
		return (dispatch: Dispatch): BulkImportEntityListDrawerOpenedAction => {
			const bulkImportEntityListDrawerOpenedAction: BulkImportEntityListDrawerOpenedAction =
				{
					type: BulkImportActionTypes.BULK_IMPORT_ENTITY_LIST_DRAWER_OPENED
				};
			return dispatch(bulkImportEntityListDrawerOpenedAction);
		};
	},
	bulkImportEntityListDrawerClosed: () => {
		return (dispatch: Dispatch): BulkImportEntityListDrawerClosedAction => {
			const bulkImportEntityListDrawerClosedAction: BulkImportEntityListDrawerClosedAction =
				{
					type: BulkImportActionTypes.BULK_IMPORT_ENTITY_LIST_DRAWER_CLOSED
				};
			return dispatch(bulkImportEntityListDrawerClosedAction);
		};
	},
	bulkImportVendorClients: (formData: FormData) => {
		return async (
			dispatch: Dispatch
		): Promise<VendorClientsBulkImportSuccessfulAction> => {
			try {
				const vendorClientsBulkImportResponse =
					await vendorClientsBulkImport(formData);
				const vendorClientsBulkImportSuccessfulAction: VendorClientsBulkImportSuccessfulAction =
					{
						type: BulkImportActionTypes.VENDOR_CLIENTS_BULK_IMPORT_SUCCESSFUL,
						bulkImportData: vendorClientsBulkImportResponse
					};
				return dispatch(vendorClientsBulkImportSuccessfulAction);
			} catch (error) {
				return Promise.reject(error);
			}
		};
	},
	bulkImportVendorClientContacts: (formData: FormData) => {
		return async (
			dispatch: Dispatch
		): Promise<VendorClientContactsBulkImportSuccessfulAction> => {
			try {
				const vendorClientContactsBulkImportResponse =
					await vendorClientContactsBulkImport(formData);
				const vendorClientContactsBulkImportSuccessfulAction: VendorClientContactsBulkImportSuccessfulAction =
					{
						type: BulkImportActionTypes.VENDOR_CLIENT_CONTACTS_BULK_IMPORT_SUCCESSFUL,
						bulkImportData: vendorClientContactsBulkImportResponse
					};
				return dispatch(vendorClientContactsBulkImportSuccessfulAction);
			} catch (error) {
				return Promise.reject(error);
			}
		};
	},
	bulkImportVendorOpportunity: (formData: FormData) => {
		return async (
			dispatch: Dispatch
		): Promise<VendorOpportunityBulkImportSuccessfulAction> => {
			try {
				const vendorOpportunityBulkImportResponse =
					await vendorOpportunityBulkImport(formData);
				const vendorOpportunityBulkImportSuccessfulAction: VendorOpportunityBulkImportSuccessfulAction =
					{
						type: BulkImportActionTypes.VENDOR_OPPORTUNITY_BULK_IMPORT_SUCCESSFUL,
						bulkImportData: vendorOpportunityBulkImportResponse
					};
				return dispatch(vendorOpportunityBulkImportSuccessfulAction);
			} catch (error) {
				return Promise.reject(error);
			}
		};
	},
	bulkImportVendorProducts: (formData: FormData) => {
		return async (
			dispatch: Dispatch
		): Promise<VendorProductsBulkImportSuccessfulAction> => {
			try {
				const vendorProductsBulkImportResponse =
					await vendorProductsBulkImport(formData);
				const vendorProductsBulkImportSuccessfulAction: VendorProductsBulkImportSuccessfulAction =
					{
						type: BulkImportActionTypes.VENDOR_PRODUCTS_BULK_IMPORT_SUCCESSFUL,
						bulkImportData: vendorProductsBulkImportResponse
					};
				return dispatch(vendorProductsBulkImportSuccessfulAction);
			} catch (error) {
				return Promise.reject(error);
			}
		};
	}
};

export default BulkImportActionCreators;
