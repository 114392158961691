import React, { useState, useEffect, useCallback } from "react";
import { IAddOrEditClientContactDrawerProps } from "./interface";
import ZForm, {
	getCustomFieldProps,
	getWidget
} from "@zomentum/organisms/dist/ZForm";
import {
	IFormSectionProps,
	EWidgets,
	IFormProps
} from "@zomentum/organisms/dist/ZForm/interface";
import ZDrawer from "@zomentum/molecules/dist/ZDrawer";
import Form from "antd/lib/form";
import { VendorClientAddOrEditContactRequest } from "@zomentum/contracts/dist/Vendor/Clients";
import { ClientUserContactType, EmailRegex } from "@zomentum/contracts";
import {
	convertEnumToSelectOptions,
	filterOutFormulaFields
} from "@zomentum/utils";
import { VendorClientAddOrEditContactFormValues } from "@zomentum/contracts/dist/Vendor/Clients";
import { ZIcon, ZTypography } from "@zomentum/atoms";
import { Icons } from "@zomentum/atoms/dist/ZIcon";
import { FontWeights } from "@zomentum/atoms/dist/ZTypography";
import { Row, Col } from "antd";
import {
	addVendorClientContact,
	editVendorClientContact
} from "@/Services/Vendor/Client";
import { ZTooltip } from "@zomentum/atoms";
import { getInitialValueForCustomField } from "@/Components/UI/CustomFields";
import { ZMessage } from "@zomentum/molecules";
import {
	CustomField,
	CustomFieldType
} from "@zomentum/contracts/dist/Settings";
import { processCustomFieldFormValues } from "@zomentum/utils";
import { useSelector } from "react-redux";
import { GlobalState } from "@/Reducers/interface";

const AddOrEditClientContactDrawer: React.FC<
	IAddOrEditClientContactDrawerProps
> = props => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const { customFieldList } = useSelector((state: GlobalState) => ({
		customFieldList: filterOutFormulaFields(
			state.customFields.customFieldList.vendor_client_user
		)
	}));

	const [primaryContactWarning, setPrimaryContactWarning] =
		useState<boolean>(false);

	const handleClose = () => {
		form.resetFields();
		setPrimaryContactWarning(false);
		props.onClose();
	};
	const handleAddVendorClientContact = async (
		addClientContactRequest: VendorClientAddOrEditContactRequest
	) => {
		try {
			const clientContact = await addVendorClientContact(
				addClientContactRequest
			);
			props.handleClientContactRefresh &&
				(await props.handleClientContactRefresh(clientContact));
		} catch (error) {
			console.error(error);
		}
	};
	const handleEditVendorClientContact = async (
		clientID: string,
		editClientContactRequest: VendorClientAddOrEditContactRequest
	) => {
		try {
			const clientContact = await editVendorClientContact(
				clientID,
				editClientContactRequest
			);
			props.handleClientContactRefresh &&
				(await props.handleClientContactRefresh(clientContact));
		} catch (error) {
			console.error(error);
		}
	};

	const handleSubmit = (
		formValues: VendorClientAddOrEditContactFormValues
	) => {
		if (!props.selectedClient?.length) {
			ZMessage.error("Please select a Client first");
			return;
		}
		onFormSubmit(formValues, customFieldList);
	};

	const onFormSubmit = useCallback(
		async (
			formValues: VendorClientAddOrEditContactFormValues,
			customList
		) => {
			try {
				setLoading(true);
				const customFieldFormValues =
					formValues.custom_field_form_values;
				let vendorClientAddContactRequest: VendorClientAddOrEditContactRequest =
					{
						name: {
							first: formValues.first_name,
							last: formValues.last_name,
							full:
								formValues.first_name +
								" " +
								formValues.last_name
						},
						email: formValues.email,
						phone: formValues.phone,
						mobile: formValues.mobile,
						job_title: formValues.job_title,
						department: formValues.department,
						contact_type: formValues.contact_type,
						vendor_client_company_id: props.selectedClient ?? ""
					};
				if (customFieldFormValues && customList?.length > 0) {
					vendorClientAddContactRequest = {
						...vendorClientAddContactRequest,
						custom_fields: processCustomFieldFormValues(
							customFieldFormValues,
							customList
						)
					};
				}
				if (props.currentActiveVendorClientContact) {
					await handleEditVendorClientContact(
						props.currentActiveVendorClientContact.id,
						vendorClientAddContactRequest
					);
				} else {
					await handleAddVendorClientContact(
						vendorClientAddContactRequest
					);
				}
				handleClose();
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		},
		[props.currentActiveVendorClientContact, props.selectedClient]
	);
	const contactTypeOptions = convertEnumToSelectOptions(
		ClientUserContactType,
		"select"
	);

	const handleOnContactTypeUpdate = (contactType: ClientUserContactType) => {
		if (contactType === ClientUserContactType.PrimaryContact) {
			setPrimaryContactWarning(true);
		} else {
			setPrimaryContactWarning(false);
		}
	};

	const sections: Array<IFormSectionProps> = [
		{
			meta: {
				formItemLayout: [4, 20],
				fields: [
					{
						key: "first_name",
						label: "First Name",
						hasFeedback: true,
						required: true,
						message: "Please enter first name",
						widget: EWidgets.INPUT,
						rules: [
							{
								whitespace: true,
								message: "Please enter first name"
							}
						],
						widgetProps: {
							width: "full",
							placeholder: "Please enter first name",
							"data-testid": "first-name"
						}
					},
					{
						key: "last_name",
						label: "Last Name",
						hasFeedback: true,
						required: true,
						message: "Please enter last name",
						rules: [
							{
								whitespace: true,
								message: "Please enter last name"
							}
						],
						widget: EWidgets.INPUT,
						widgetProps: {
							width: "full",
							placeholder: "Please enter last name",
							"data-testid": "last-name"
						}
					},
					{
						key: "contact_type",
						label: "Contact Type",
						hasFeedback: true,
						required: true,
						widget: EWidgets.SELECT,
						message: "Please enter contact type",
						disabled: props.initalVendorClientContact,
						initialValue: props.initalVendorClientContact
							? ClientUserContactType.PrimaryContact
							: ClientUserContactType.SecondaryContact,
						widgetProps: {
							width: "full",
							placeholder: "Please enter contact type",
							selectOptions: contactTypeOptions,
							onChange: handleOnContactTypeUpdate
						}
					},
					{
						key: "warning",
						render: () =>
							primaryContactWarning && (
								<Row className="mt-n4 mb-6">
									<Col
										offset={4}
										span={19}
										className="bg-gold-1 inline-flex items-center p-2 rounded"
									>
										<ZIcon
											name={Icons.WARNINGCIRCLE}
											size="sm"
											colorVariant={8}
											className="ml-0 mr-2"
										/>
										<ZTypography.ZText colorVariant={8}>
											The existing{" "}
											<ZTypography.ZText
												weight={FontWeights.MEDIUM}
											>
												Primary Contact
											</ZTypography.ZText>{" "}
											will be changed to{" "}
											<ZTypography.ZText
												weight={FontWeights.MEDIUM}
											>
												Secondary
											</ZTypography.ZText>
										</ZTypography.ZText>
									</Col>
								</Row>
							)
					},
					{
						key: "email",
						label: "Email",
						rules: [
							{
								pattern: EmailRegex,
								message: "Please enter email"
							}
						],
						widget: EWidgets.INPUT,
						required: customFieldList.find(
							field =>
								field.system_field_id ===
								"vendor_client_user_email"
						)?.mandatory_field_details?.for_ui,
						widgetProps: {
							width: "full",
							placeholder: "Please enter email",
							type: "email"
						}
					},
					{
						key: "phone",
						label: "Phone",
						message: "Please enter phone number",
						required: customFieldList.find(
							field =>
								field.system_field_id ===
								"vendor_client_user_phone"
						)?.mandatory_field_details?.for_ui,
						widget: EWidgets.INPUT,
						widgetProps: {
							width: "full",
							placeholder: "Please enter phone number"
						}
					},
					{
						key: "mobile",
						label: "Mobile",
						required: customFieldList.find(
							field =>
								field.system_field_id ===
								"vendor_client_user_mobile"
						)?.mandatory_field_details?.for_ui,
						message: "Please enter mobile number",
						widget: EWidgets.INPUT,
						widgetProps: {
							width: "full",
							placeholder: "Please enter mobile number"
						}
					},
					{
						key: "job_title",
						label: "Job Title",
						message: "Please enter job title",
						widget: EWidgets.INPUT,
						widgetProps: {
							width: "full",
							placeholder: "Please enter job title"
						}
					},
					{
						key: "department",
						label: "Department",
						message: "Please enter department",
						widget: EWidgets.INPUT,
						widgetProps: {
							width: "full",
							placeholder: "Please enter department"
						}
					},
					...customFieldList
						.filter(
							(customField: CustomField) =>
								!customField.system_field_id
						)
						.map((customField: CustomField) => {
							const isDateCustomField =
								customField.field_type ===
								CustomFieldType.DateField;
							const initialValue =
								props.currentActiveVendorClientContact
									? getInitialValueForCustomField(
											props.currentActiveVendorClientContact,
											isDateCustomField,
											customField
									  )
									: undefined;
							return {
								key: `custom_field_form_values.${customField.id}`,
								label: (
									<ZTooltip
										title={customField.display_name}
										placement="top"
										showtooltip={
											customField.display_name.length >=
											23
										}
									>
										<ZTypography.ZText level={1}>
											{customField.display_name}
										</ZTypography.ZText>
									</ZTooltip>
								),
								initialValue,
								required:
									!!customField.mandatory_field_details
										?.for_ui,
								message:
									"Please enter " + customField.display_name,
								widget: getWidget(customField.field_type),
								widgetProps: getCustomFieldProps(customField),
								className: "large-custom-field"
							};
						})
				]
			}
		}
	];
	const formProps: IFormProps = {
		onFinish: handleSubmit,
		key: "AddorEditClientContactForm",
		sections,
		loading: loading,
		submitButtonText: "Save",
		isStickFooter: true,
		form,
		isCancelAllowed: true,
		onCancel: handleClose,
		isRightFooter: true,
		shouldResetFormFields: true
	};

	useEffect(() => {
		if (props.currentActiveVendorClientContact) {
			form.setFieldsValue({
				first_name: props.currentActiveVendorClientContact.name.first,
				last_name: props.currentActiveVendorClientContact.name.last,
				email: props.currentActiveVendorClientContact.email,
				phone: props.currentActiveVendorClientContact.phone,
				mobile: props.currentActiveVendorClientContact.mobile,
				job_title: props.currentActiveVendorClientContact.job_title,
				department: props.currentActiveVendorClientContact.department,
				contact_type:
					props.currentActiveVendorClientContact.contact_type
			});
		}
	}, [props.currentActiveVendorClientContact]);

	return (
		<ZDrawer
			visible={props.visible}
			closable={true}
			destroyOnClose={true}
			onClose={handleClose}
			title={
				props.currentActiveVendorClientContact
					? "Edit Contact"
					: "Add Contact"
			}
			removeDefaultPadding={true}
		>
			<ZForm {...formProps} className="py-4" />
		</ZDrawer>
	);
};

export default React.memo(AddOrEditClientContactDrawer);
