import React, { memo, useCallback, useMemo } from "react";
import {
	ProposalBuilderProvider,
	withProposalBuilderProvider,
	defaultParentContext
} from "@modules/proposal-builder/dist/store";
import { useSelector } from "react-redux";
import { GlobalState } from "@/Reducers/interface";
import "@modules/proposal-builder/dist/index.css";
import {
	Template,
	UpdateBlockListSequenceRequest,
	VendorTemplateDetail
} from "@zomentum/contracts";
import useDispatch from "@/Components/Utils/useDispatch";
import UsersActionCreators from "@/Actions/User";
import {
	VendorTemplateActionCreators,
	VendorTemplateActionTypes
} from "@/Actions/Vendor/Template";
import AddProductSKUToQuoteBlockDrawer from "@pages/Vendor/Templates/TemplateDetailPage/Drawers/AddProductSKUToQuoteBlockDrawer";
import ConfigureProductSKUInQuoteBlockDrawer from "@pages/Vendor/Templates/TemplateDetailPage/Drawers/ConfigureProductSKUinQuoteBlockDrawer";
import ProductSKUDrawer from "@pages/Vendor/MarketplaceProductSKUs/ProductSKUDrawer";

const ProposalBuilderWrapper: React.FC = ({ children }) => {
	const { currentActiveTemplate, isTemplateLoading, activeTemplatePanel } =
		useSelector((state: GlobalState) => {
			return {
				currentActiveTemplate:
					state.vendorTemplate.currentActiveTemplate,
				isTemplateLoading: state.vendorTemplate.isLoading,
				activeTemplatePanel: state.vendorTemplate.activeTemplatePanel
			};
		});
	const dispatch = useDispatch();
	const showLibraryLoader = () => {
		dispatch(UsersActionCreators.showLibraryLoader());
	};
	const saveBlockToLibrary = async (createLibraryItemRequest: any) => {
		await dispatch(
			UsersActionCreators.saveBlockToLibrary(createLibraryItemRequest)
		);
	};
	const fetchLibrary = async (fetchLibraryRequest: any) => {
		await dispatch(UsersActionCreators.fetchLibrary(fetchLibraryRequest));
	};
	const hideLibraryLoader = () => {
		dispatch(UsersActionCreators.hideLibraryLoader());
	};
	const addBlocks = async ({
		draggableId,
		index
	}: UpdateBlockListSequenceRequest) => {
		await dispatch(
			VendorTemplateActionCreators.addBlockToList({
				draggableId,
				index
			})
		);
	};

	const deleteBlocks = async (blockIds: string[]) => {
		await dispatch(
			VendorTemplateActionCreators.deleteBlockFromRevision({
				blockIds: blockIds
			})
		);
	};

	const library = useMemo(
		() => ({
			showLibraryLoader,
			saveBlockToLibrary,
			fetchLibrary,
			hideLibraryLoader
		}),
		[]
	);

	const parentState = useMemo(
		() => ({
			currentActiveDocument: null,
			currentActiveTemplate,
			taxCategoriesList: [],
			taxRegionsList: []
		}),
		[currentActiveTemplate]
	);

	const parentContext = useMemo(
		() => ({
			...defaultParentContext,
			featureFlag: {
				...defaultParentContext.featureFlag,
				hideReceiverMergeTagFeatureFlag: true
			}
		}),
		[]
	);

	const permissions = useMemo(
		() => ({
			hasAccess: true,
			hasSignatureBlockReadPermission: true,
			showZPricingPlanErrorTooltip: true,
			hasClientAddPermission: true,
			hasProductAddPermission: true,
			hasProductEditPermission: true,
			hasProductCreateAndAddPermission: true
		}),
		[]
	);

	const drawers = useMemo(
		() => ({
			...defaultParentContext.drawers,
			AddProductToQuoteBlockDrawer: AddProductSKUToQuoteBlockDrawer,
			CloneProductToQuoteBlockDrawer:
				ConfigureProductSKUInQuoteBlockDrawer,
			AddProductDrawer: ProductSKUDrawer
		}),
		[]
	);

	const updateParentCurrentActiveTemplate = useCallback(
		(template: Template | VendorTemplateDetail) => {
			dispatch({
				type: VendorTemplateActionTypes.TEMPLATE_DETAILS_SUCCESSFUL,
				currentActiveTemplate: template
			});
		},
		[]
	);
	const setTemplateSidePanel = useCallback((panelTab: any) => {
		dispatch(VendorTemplateActionCreators.setSidePanel(panelTab));
	}, []);

	const template = useMemo(
		() => ({
			...defaultParentContext.template,
			isTemplateLoading,
			activeTemplatePanel,
			setTemplateSidePanel,
			updateParentCurrentActiveTemplate
		}),
		[isTemplateLoading, activeTemplatePanel]
	);

	const allowZeroOrNegativeQuantity = useMemo(
		() => ({
			negativeQuantity: {
				value: true
			},
			zeroQuantity: {
				value: true
			}
		}),
		[]
	);

	return (
		<ProposalBuilderProvider
			{...parentContext}
			template={template}
			library={library}
			addBlocks={addBlocks}
			deleteBlocks={deleteBlocks}
			parentState={parentState}
			permissions={permissions}
			drawers={drawers}
			allowZeroOrNegativeQuantity={allowZeroOrNegativeQuantity}
		>
			{children}
		</ProposalBuilderProvider>
	);
};

export default withProposalBuilderProvider(memo(ProposalBuilderWrapper));
