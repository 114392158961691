'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var Users = require('@zomentum/contracts/dist/Users');
var difference = require('../node_modules/lodash/difference.js');

var checkUseAccessLevel = function (userData) {
  return function (id) {
    if (process.env.REACT_APP_NAME === "vendorApp") return true;
    switch (userData.access_level) {
      case Users.UserAccess.SelfLevel:
        return id === userData.id;
      default:
        return true;
    }
  };
};
var checkUseRoleDisplay = function (userRole) {
  return function (requiredRole) {
    if (process.env.REACT_APP_NAME === "vendorApp") return true;
    var shouldRenderComponent = function () {
      switch (requiredRole) {
        case Users.UserRole.PrimaryRole:
        case Users.UserRole.AdminRole:
          {
            return !!userRole && !!Users.AdminRoleOnly.includes(userRole);
          }
        case Users.UserRole.SecondaryRole:
          {
            return !!userRole && !!Users.ManagerAndAdminRoleOnly.includes(userRole);
          }
        case Users.UserRole.UserRole:
          {
            return true;
          }
        default:
          return false;
      }
    };
    return shouldRenderComponent();
  };
};
/**
 *
 * @param requiredPlan: Required pricing plan for the user to access this Feature.
 * If all users should be able to access this feature then pass **IGNITE**.
 * All the old accounts existing before pricing plan won't have any **PRICING PLAN** for their account
 * and will have access to all feature by default.
 *
 * @param requiredRole: Required User Role to access this feature.
 * If all users should be able to access this feature then pass **UserRole.UserRole**
 *
 * @description processes whether the user should be able to view feature based on the **USER ROLE** and
 * **CURRENT ACTIVE PRICING PLAN**
 */
var checkUseFeatureAccess = function (userRole, rolePermissions, currentPlan) {
  return function (requiredPlan, requiredRole, permissions, skipPermissions) {
    if (process.env.REACT_APP_NAME === "vendorApp") return true;
    var hasPermission = function () {
      if (rolePermissions && difference["default"](permissions, rolePermissions).length === 0) return true;
      return false;
    };
    var isFeatureInPlan = function () {
      switch (currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.name) {
        case Users.PricingPlanNames.GROWTH:
          {
            return requiredPlan === Users.PricingPlanNames.GROWTH || requiredPlan === Users.PricingPlanNames.EXPAND || requiredPlan === Users.PricingPlanNames.LAUNCH || requiredPlan === Users.PricingPlanNames.IGNITE;
          }
        case Users.PricingPlanNames.EXPAND:
          {
            return requiredPlan === Users.PricingPlanNames.LAUNCH || requiredPlan === Users.PricingPlanNames.EXPAND || requiredPlan === Users.PricingPlanNames.IGNITE;
          }
        case Users.PricingPlanNames.LAUNCH:
          {
            return requiredPlan === Users.PricingPlanNames.LAUNCH || requiredPlan === Users.PricingPlanNames.IGNITE;
          }
        case Users.PricingPlanNames.IGNITE:
          {
            return requiredPlan === Users.PricingPlanNames.IGNITE;
          }
        default:
          {
            return true;
          }
      }
    };
    var shouldRenderComponent = function () {
      switch (requiredRole) {
        case Users.UserRole.PrimaryRole:
        case Users.UserRole.AdminRole:
          {
            return !!userRole && !!Users.AdminRoleOnly.includes(userRole);
          }
        case Users.UserRole.SecondaryRole:
          {
            return !!userRole && !!Users.ManagerAndAdminRoleOnly.includes(userRole);
          }
        case Users.UserRole.UserRole:
          {
            return true;
          }
        default:
          throw new Error("".concat(requiredRole, " isn't a valid user role"));
      }
    };
    var hasAccess = shouldRenderComponent() && isFeatureInPlan();
    if (!skipPermissions) return hasAccess && hasPermission();
    return hasAccess;
  };
};

exports.checkUseAccessLevel = checkUseAccessLevel;
exports.checkUseFeatureAccess = checkUseFeatureAccess;
exports.checkUseRoleDisplay = checkUseRoleDisplay;
