'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var contracts = require('@zomentum/contracts');
var SalesActivity = require('@zomentum/contracts/dist/Others/SalesActivity');
var common = require('../../common.js');

var hasStatusHasChangedToWonFilter = function (salesActivityAutomation) {
  var _a;
  return (_a = salesActivityAutomation === null || salesActivityAutomation === void 0 ? void 0 : salesActivityAutomation.filter_criteria) === null || _a === void 0 ? void 0 : _a.some(function (criteria) {
    var _a;
    return criteria.filter_entity_type == contracts.ZomentumEntities.Document && criteria.field_name == "status" && ((_a = criteria.filter_operator) === null || _a === void 0 ? void 0 : _a.id_value) == SalesActivity.SalesActivityAutomationMetadataFilterOperator.HasChangedTo && criteria.values.some(function (value) {
      return value.id_value == contracts.DocumentStatus.Won;
    });
  });
};
var resetSendEmailActionFieldsToDefault = function (salesActivityAutomation, hasAttachmentsFeature) {
  var _a;
  var hasNotStatusHasChangedToWonFilter = !hasStatusHasChangedToWonFilter(salesActivityAutomation);
  if (hasNotStatusHasChangedToWonFilter) {
    (_a = salesActivityAutomation === null || salesActivityAutomation === void 0 ? void 0 : salesActivityAutomation.actions) === null || _a === void 0 ? void 0 : _a.forEach(function (action) {
      var send_document_fields = action.action_details.send_document_fields;
      if (hasAttachmentsFeature && send_document_fields) {
        var contact = new SalesActivity.SalesActivityAutomationActionUpdatableFieldValue(common.capitalizeAfterUnderscore(SalesActivity.SalesActivityAutomationActionUpdatableFieldValueType.Contacts), SalesActivity.SalesActivityAutomationActionUpdatableFieldValueType.Contacts, SalesActivity.SalesActivityAutomationActionUpdatableFieldValueType.Contacts);
        send_document_fields.to = [contact];
        send_document_fields.attach_document = false;
        send_document_fields.attach_payment_log = false;
        send_document_fields.attach_signing_log = false;
        send_document_fields.attach_product_list = false;
      }
    });
  }
  return salesActivityAutomation;
};
var getCustomFieldIdFromSystemFieldId = function (customFields, systemFieldId) {
  var field = customFields.find(function (field) {
    return field.system_field_id === systemFieldId;
  });
  return field === null || field === void 0 ? void 0 : field.id;
};

exports.getCustomFieldIdFromSystemFieldId = getCustomFieldIdFromSystemFieldId;
exports.hasStatusHasChangedToWonFilter = hasStatusHasChangedToWonFilter;
exports.resetSendEmailActionFieldsToDefault = resetSendEmailActionFieldsToDefault;
