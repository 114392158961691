'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index = require('../Others/GlobalSearch/index.js');

exports.ChartType = void 0;
(function (ChartType) {
  ChartType["Line"] = "line";
  ChartType["Column"] = "column";
  ChartType["StackedColumn"] = "stacked_column";
  ChartType["Funnel"] = "funnel";
  ChartType["Pie"] = "pie";
  ChartType["Kpis"] = "kpis";
  ChartType["Tabular"] = "tabular";
  ChartType["Gauge"] = "gauge";
})(exports.ChartType || (exports.ChartType = {}));
// Pick<Partial<Report>, 'group_by_0' | 'group_by_1' | 'metric_0' | 'metric_1'> {}
var CreateReportFormState = /** @class */function () {
  function CreateReportFormState() {
    this.name = "New Report";
    this.entity_type = index.GlobalSearchEntityType.Opportunity;
    this.filters = [];
    this.chart_type = exports.ChartType.Line;
  }
  return CreateReportFormState;
}();
var chartsPalette = ["#FF6F42", "#BB6CF9", "#FFC12C", "#0076FF", "#23B899", "#8590B3", "#89C700", "#06E562", "#A904B7", "#334681", "#BAC6DD", "#EA0623", "#34D1BF", "#7676FF", "#E478FF", "#4CD9F9", "#FFE600", "#6C7293", "#3E3CA6", "#0A98B7", "#DCFF78", "#F94CD3", "#FF7880", "#662167"];
exports.ValidFilterDataTypes = void 0;
(function (ValidFilterDataTypes) {
  ValidFilterDataTypes["Text"] = "Text";
  ValidFilterDataTypes["Number"] = "Number";
  ValidFilterDataTypes["Dropdown"] = "Dropdown";
  ValidFilterDataTypes["Entity"] = "Entity";
  ValidFilterDataTypes["Date"] = "Date";
})(exports.ValidFilterDataTypes || (exports.ValidFilterDataTypes = {}));
// Create Report Form State
// export interface ReportsFormMetric {
// 	field: Omit<ReportsField, 'display_value'>;
// 	accumulator: string;
// }
// export interface ReportFormGroupByField {
// 	field: Omit<ReportsField, 'display_value'>;
// 	time_accumulator?: string | null;
// }
// export interface ReportFormFilterValues {
// 	id_value: string;
// 	display_value: string;
// }
// export interface ReportFormFilter {
// 	field: Omit<ReportsField, 'display_value'>;
// 	filter_operator: string;
// 	values: ReportFormFilterValues[];
// }

exports.CreateReportFormState = CreateReportFormState;
exports.chartsPalette = chartsPalette;
