'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var _a;
exports.OpportunitySource = void 0;
(function (OpportunitySource) {
  OpportunitySource["PARTNER"] = "partner";
  OpportunitySource["VENDOR"] = "vendor";
})(exports.OpportunitySource || (exports.OpportunitySource = {}));
exports.OpportunityShareStatus = void 0;
(function (OpportunityShareStatus) {
  OpportunityShareStatus["WAITING"] = "waiting";
  OpportunityShareStatus["ACCEPTED"] = "accepted";
  OpportunityShareStatus["REJECTED"] = "rejected";
  OpportunityShareStatus["SELF"] = "self";
  OpportunityShareStatus["SHARED_BY_VENDOR"] = "shared_by_vendor";
  OpportunityShareStatus["SHARED_BY_PARTNER"] = "shared_by_partner";
})(exports.OpportunityShareStatus || (exports.OpportunityShareStatus = {}));
exports.OpportunityType = void 0;
(function (OpportunityType) {
  OpportunityType["ApplicationDevelopment"] = "application_development";
  OpportunityType["Cabling"] = "cabling";
  OpportunityType["ManagedService"] = "managed_service";
  OpportunityType["Telephone"] = "telephone";
  OpportunityType["Training"] = "training";
  OpportunityType["VoIPSystem"] = "voip_system";
  OpportunityType["HardwareRefresh"] = "hardware_refresh";
  OpportunityType["HardwareSales"] = "hardware_sales";
  OpportunityType["SoftwareSales"] = "software_sales";
  OpportunityType["SoftwareRenewal"] = "software_renewal";
  OpportunityType["ComplianceServices"] = "compliance_services";
  OpportunityType["ContractRenewals"] = "contract_renewals";
  OpportunityType["UserSupport"] = "user_support";
  OpportunityType["RemoteSupport"] = "remote_support";
  OpportunityType["Warranty"] = "warranty";
  OpportunityType["ConsultingService"] = "consulting_service";
})(exports.OpportunityType || (exports.OpportunityType = {}));
var opportunityTypeOptions = (_a = {}, _a[exports.OpportunityType.ApplicationDevelopment] = "Application Development", _a[exports.OpportunityType.Cabling] = "Cabling", _a[exports.OpportunityType.ManagedService] = "Managed Service", _a[exports.OpportunityType.Telephone] = "Telephone", _a[exports.OpportunityType.Training] = "Training", _a[exports.OpportunityType.VoIPSystem] = "VoIP System", _a[exports.OpportunityType.HardwareRefresh] = "Hardware Refresh", _a[exports.OpportunityType.HardwareSales] = "Hardware Sales", _a[exports.OpportunityType.SoftwareSales] = "Software Sales", _a[exports.OpportunityType.SoftwareRenewal] = "Software Renewal", _a[exports.OpportunityType.ComplianceServices] = "Compliance Services", _a[exports.OpportunityType.ContractRenewals] = "Contract Renewals", _a[exports.OpportunityType.UserSupport] = "User Support", _a[exports.OpportunityType.RemoteSupport] = "Remote Support", _a[exports.OpportunityType.Warranty] = "Warranty", _a[exports.OpportunityType.ConsultingService] = "Consulting Service", _a);
exports.OpportunityRecentActivityTypeEnum = void 0;
(function (OpportunityRecentActivityTypeEnum) {
  OpportunityRecentActivityTypeEnum[OpportunityRecentActivityTypeEnum["COMMENT"] = 0] = "COMMENT";
  OpportunityRecentActivityTypeEnum[OpportunityRecentActivityTypeEnum["EMAIL"] = 1] = "EMAIL";
})(exports.OpportunityRecentActivityTypeEnum || (exports.OpportunityRecentActivityTypeEnum = {}));
var OpportunityRecentActivity = /** @class */function () {
  function OpportunityRecentActivity() {
    this.id = "";
    this.created = new Date();
    this.updated = new Date();
    this.type = null;
    this.when = new Date();
    this.message = "";
  }
  return OpportunityRecentActivity;
}();
exports.SalesActivitiesActionStatus = void 0;
(function (SalesActivitiesActionStatus) {
  SalesActivitiesActionStatus["ActionOverdue"] = "action_overdue";
  SalesActivitiesActionStatus["ActionRequired"] = "action_required";
  SalesActivitiesActionStatus["UpcomingActionPresent"] = "upcoming_action_present";
})(exports.SalesActivitiesActionStatus || (exports.SalesActivitiesActionStatus = {}));
var OpportunityCostAndRevenueData = /** @class */function () {
  function OpportunityCostAndRevenueData() {
    this.monthly = 0;
    this.annual = 0;
    this.grand = 0;
    this.one_time = 0;
    this.quarterly = 0;
    this.semi_annually = 0;
    this.weekly = 0;
    this.one_time_cost = 0;
    this.weekly_cost = 0;
    this.monthly_cost = 0;
    this.quarterly_cost = 0;
    this.semi_annually_cost = 0;
    this.annual_cost = 0;
    this.grand_cost = 0;
    this.total_cost = 0;
    this.total_revenue = 0;
    this.margin_percentage = 0;
    this.margin_value = 0;
  }
  return OpportunityCostAndRevenueData;
}();
exports.OpportunitySharingStatus = void 0;
(function (OpportunitySharingStatus) {
  OpportunitySharingStatus["SHARED"] = "shared";
  OpportunitySharingStatus["ACCEPTED"] = "accepted";
  OpportunitySharingStatus["REJECTED"] = "rejected";
})(exports.OpportunitySharingStatus || (exports.OpportunitySharingStatus = {}));
var AutotaskOpportunityIntegrationData = /** @class */function () {
  function AutotaskOpportunityIntegrationData() {
    this.recognize_revenue_type = null;
    this.recognizeRevenuePeriodType = null;
    this.recognizeRevenuePeriod = null;
    this.shouldSync = null;
  }
  return AutotaskOpportunityIntegrationData;
}();
var ConnectWiseOpportunityIntegrationData = /** @class */function () {
  function ConnectWiseOpportunityIntegrationData() {
    this.business_unit = null;
    this.shouldSync = null;
  }
  return ConnectWiseOpportunityIntegrationData;
}();
var OpportunityIntegrationData = /** @class */function () {
  function OpportunityIntegrationData() {
    this.autotaskOpportunityIntegrationData = null;
    this.connectWiseOpportunityIntegrationData = null;
  }
  return OpportunityIntegrationData;
}();
var OpportunityRequest = /** @class */function () {
  function OpportunityRequest(filters, keywords, included_child_entities, limit, selected_id, use_all_pipelines_flag) {
    this.filters = new Array();
    this.keywords = null;
    this.included_child_entities = new Array();
    this.limit = 0;
    this.filters = filters || [];
    this.keywords = keywords || "";
    this.included_child_entities = included_child_entities || [];
    this.limit = limit || 100;
    this.selected_id = selected_id;
    this.use_all_pipelines_flag = use_all_pipelines_flag;
  }
  return OpportunityRequest;
}();
var OpportunityResponse = /** @class */function () {
  function OpportunityResponse() {
    this.data = new Array();
    this.filtered_count_map = {};
  }
  return OpportunityResponse;
}();
var AddOpportunityRequest = /** @class */function () {
  function AddOpportunityRequest(args) {
    var _a, _b, _c, _d, _e;
    this.client_company_owner_id = null;
    this.included_child_entities = [];
    this.pipeline_stage = null;
    this.loss_reason = null;
    this.won_reason = null;
    this.campaign = null;
    this.source = null;
    this.mandatory_custom_field_check_require = false;
    this.name = args.name;
    this.client_company_id = args.client_company_id;
    this.opportunity_type = args.opportunity_type;
    this.primary_client_user_id = args.primary_client_user_id;
    this.linked_client_user_ids = ((_a = args.linked_client_user_ids) === null || _a === void 0 ? void 0 : _a.filter(Boolean)) || [];
    this.estimated_close_date = args.estimated_close_date;
    this.client_po_number = args.client_po_number;
    this.close_probability = args.close_probability;
    this.included_child_entities = args.included_child_entities || new Array();
    this.estimated_value = args.estimated_value;
    this.owner_user_id = args.owner_user_id;
    this.opportunity_pipeline_stage_id = args.opportunity_pipeline_stage_id;
    this.opportunity_pipeline_id = args.opportunity_pipeline_id;
    this.loss_reason = args.loss_reason;
    this.won_reason = args.won_reason;
    this.campaign = args.campaign;
    this.source = args.source;
    this.mandatory_custom_field_check_require = args.mandatory_custom_field_check_require;
    this.manual_total = args.manual_total;
    this.months_to_calculate_margin = args.months_to_calculate_margin;
    this.use_document_totals = args.use_document_totals;
    this.opportunity_vendor_details = !!args.share_opportunity_with_vendors && args.vendor_company_id ? {
      vendor_company_id: args.vendor_company_id,
      should_share_client_with_vendor: true,
      vendor_opportunity_source: exports.OpportunitySource.PARTNER,
      status: exports.OpportunitySharingStatus.SHARED,
      shared_at: (_c = (_b = args.opportunity_vendor_details) === null || _b === void 0 ? void 0 : _b.shared_at) !== null && _c !== void 0 ? _c : null,
      accepted_at: (_e = (_d = args.opportunity_vendor_details) === null || _d === void 0 ? void 0 : _d.accepted_at) !== null && _e !== void 0 ? _e : null
    } : undefined;
    this.is_onboarding_entity = args.is_onboarding_entity;
  }
  return AddOpportunityRequest;
}();
var EditOpportunityRequest = /** @class */function () {
  function EditOpportunityRequest(args) {
    this.estimated_close_date = new Date();
    this.client_po_number = null;
    this.client_company_owner_id = null;
    this.close_probability = null;
    this.estimated_value = null;
    this.pipeline_stage = null;
    this.loss_reason = null;
    this.won_reason = null;
    this.campaign = null;
    this.source = null;
    this.mandatory_custom_field_check_require = false;
    if (!args.id) {
      throw new Error("Invalid request: id is not available");
    }
    this.id = args.id;
    this.name = args.name;
    this.opportunity_type = args.opportunity_type;
    this.primary_client_user_id = args.primary_client_user_id;
    this.linked_client_user_ids = args.linked_client_user_ids || new Array();
    this.estimated_close_date = args.estimated_close_date;
    this.client_po_number = args.client_po_number;
    this.close_probability = args.close_probability;
    this.included_child_entities = args.included_child_entities || new Array();
    this.estimated_value = args.estimated_value;
    this.opportunity_pipeline_stage_id = args.opportunity_pipeline_stage_id;
    this.opportunity_pipeline_id = args.opportunity_pipeline_id;
    this.owner_user_id = args.owner_user_id;
    this.loss_reason = args.loss_reason;
    this.won_reason = args.won_reason;
    this.campaign = args.campaign;
    this.source = args.source;
    this.client_company_id = args.client_company_id;
    this.mandatory_custom_field_check_require = args.mandatory_custom_field_check_require;
    this.manual_total = args.manual_total;
    this.months_to_calculate_margin = args.months_to_calculate_margin;
    this.use_document_totals = args.use_document_totals;
    this.opportunity_vendor_details = args.opportunity_vendor_details;
  }
  return EditOpportunityRequest;
}();
exports.OpportunityView = void 0;
(function (OpportunityView) {
  OpportunityView["List"] = "LIST";
  OpportunityView["Kanban"] = "KANBAN";
})(exports.OpportunityView || (exports.OpportunityView = {}));
// export interface KanbanOpportunityCountResponse extends Pipeline {
// 	data: StageIdCountPair[];
// }
exports.OpportunityFilters = void 0;
(function (OpportunityFilters) {
  OpportunityFilters["MyOpportunities"] = "my_opportunities";
  OpportunityFilters["AllOpportunities"] = "all_opportunities";
  OpportunityFilters["NewThisWeek"] = "new_this_week";
  OpportunityFilters["RecentlyUpdated"] = "recently_updated";
  OpportunityFilters["OtherUsers"] = "selected_users";
  OpportunityFilters["ClosingThisMonth"] = "closing_this_month";
  OpportunityFilters["ClosingNextMonth"] = "closing_next_month";
  OpportunityFilters["ClosedOpportunities"] = "closed_opportunities";
})(exports.OpportunityFilters || (exports.OpportunityFilters = {}));
exports.OpportunityRottingLoadingState = void 0;
(function (OpportunityRottingLoadingState) {
  OpportunityRottingLoadingState[OpportunityRottingLoadingState["notLoading"] = 0] = "notLoading";
  OpportunityRottingLoadingState[OpportunityRottingLoadingState["win"] = 1] = "win";
  OpportunityRottingLoadingState[OpportunityRottingLoadingState["lost"] = -1] = "lost";
})(exports.OpportunityRottingLoadingState || (exports.OpportunityRottingLoadingState = {}));
var PendingOpportunitySharedWithPartnerResponse = /** @class */function () {
  function PendingOpportunitySharedWithPartnerResponse() {
    this.data = new Array();
  }
  return PendingOpportunitySharedWithPartnerResponse;
}();

exports.AddOpportunityRequest = AddOpportunityRequest;
exports.AutotaskOpportunityIntegrationData = AutotaskOpportunityIntegrationData;
exports.ConnectWiseOpportunityIntegrationData = ConnectWiseOpportunityIntegrationData;
exports.EditOpportunityRequest = EditOpportunityRequest;
exports.OpportunityCostAndRevenueData = OpportunityCostAndRevenueData;
exports.OpportunityIntegrationData = OpportunityIntegrationData;
exports.OpportunityRecentActivity = OpportunityRecentActivity;
exports.OpportunityRequest = OpportunityRequest;
exports.OpportunityResponse = OpportunityResponse;
exports.PendingOpportunitySharedWithPartnerResponse = PendingOpportunitySharedWithPartnerResponse;
exports.opportunityTypeOptions = opportunityTypeOptions;
