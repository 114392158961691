import React, { useEffect } from "react";
import { useForm } from "antd/lib/form/Form";
import { ISKUPricingPlansFormItemWidget } from "./interface";
import {
	handleMarginChange,
	handleMarkupChange,
	handleMarkupMargin
} from "@/Components/Utils/PricingCalculator";
import {
	ProductBillingType,
	productBillingTypeOptions
} from "@zomentum/contracts/dist/Products";
import { SKUPricingPlanFormValues } from "@zomentum/contracts/dist/Vendor/SKU";
import { EInputNumberWidths } from "@zomentum/atoms/dist/ZInputNumber/interface";
import ZForm from "@zomentum/organisms/dist/ZForm";
import {
	IFormSectionProps,
	EWidgets,
	IFormProps
} from "@zomentum/organisms/dist/ZForm/interface";
import { getCurrencySettings } from "@zomentum/utils/dist/Currency";
import ZInputNumber, {
	InputNumberWidths
} from "@zomentum/atoms/dist/ZInputNumber";
import ZButton from "@zomentum/atoms/dist/ZButton";
import ZTypography, { FontWeights } from "@zomentum/atoms/dist/ZTypography";
import ZIcon, { Icons } from "@zomentum/atoms/dist/ZIcon";

import "./index.less";
import ZCheckbox from "@zomentum/atoms/dist/ZCheckbox";
import {
	currencyFormatter,
	percentageFormatter
} from "@zomentum/utils/dist/Currency";

const { ZText } = ZTypography;

const ZSKUPricingPlanFormWidget: React.FC<ISKUPricingPlansFormItemWidget> = ({
	value,
	onChange,
	bundledSkus,
	unitOfMeasure,
	disabled
}) => {
	const [form] = useForm<SKUPricingPlanFormValues>();

	const getFieldValues = () => {
		return form.getFieldsValue([
			"cost_price",
			"sell_price",
			"setup_price",
			"margin",
			"markup",
			"vendor_sku_no",
			"should_add_setup_price_to_margin"
		]);
	};

	const onCostPriceChange = (newCostPrice?: number | string | null): void => {
		if (newCostPrice === undefined || newCostPrice === null) {
			return;
		}
		const values = getFieldValues();

		form.setFieldsValue(
			handleMarkupMargin(
				Number(newCostPrice),
				values.sell_price,
				1,
				false,
				values.selected_setup_price
			)
		);
	};

	const onMarkupChange = (newMarkup?: number | string | null): void => {
		if (newMarkup === undefined || form.getFieldValue("cost_price") === 0) {
			return;
		}
		const values = getFieldValues();
		form.setFieldsValue(handleMarkupChange(values, Number(newMarkup)));
	};

	const onMarginChange = (newMargin?: number | string | null): void => {
		if (
			newMargin === undefined ||
			newMargin === null ||
			form.getFieldValue("cost_price") === 0
		) {
			return;
		}
		const values = getFieldValues();

		if (
			Number(newMargin) >= 100 &&
			Number(getFieldValues().cost_price) !== 0
		) {
			return;
		}
		form.setFieldsValue(handleMarginChange(values, Number(newMargin)));
	};

	const onSellPriceChange = (newSellPrice?: number | string | null): void => {
		if (newSellPrice === undefined || newSellPrice === null) {
			return;
		}
		const values = getFieldValues();
		form.setFieldsValue(
			handleMarkupMargin(
				values.cost_price,
				Number(newSellPrice),
				1,
				false,
				values.setup_price
			)
		);
	};

	const updateTotalCostPrice = () => {
		const costPrice =
			bundledSkus?.reduce((acc, product) => {
				acc += product.is_optional
					? 0
					: product.pricings[0].cost_price *
					  (product.is_optional ? 1 : product.quantity);
				return acc;
			}, 0) ?? 0;
		form.setFieldsValue({ cost_price: costPrice });
		onCostPriceChange(costPrice);
	};
	const updateTotalSellPrice = () => {
		const sellPrice =
			bundledSkus?.reduce((acc, product) => {
				acc += product.is_optional
					? 0
					: product.pricings[0].sell_price *
					  (product.is_optional ? 1 : product.quantity);
				return acc;
			}, 0) ?? 0;
		form.setFieldsValue({
			sell_price: sellPrice
		});
		onSellPriceChange(sellPrice);
	};
	const updateTotalSetupPrice = () => {
		const setupFee =
			bundledSkus?.reduce((acc, product) => {
				acc += product.is_optional
					? 0
					: product.pricings[0].setup_price;
				return acc;
			}, 0) ?? 0;
		form.setFieldsValue({ setup_price: setupFee });
	};
	const onAddSetupPriceToMarginChange = (e): void => {
		const values = getFieldValues && getFieldValues();
		form.setFieldsValue(
			handleMarkupMargin(
				values?.cost_price,
				values?.sell_price,
				1,
				e.target.checked,
				values?.setup_price
			)
		);
	};

	useEffect(() => {
		if (value && value.length > 0) {
			const {
				cost_price,
				setup_price,
				sell_price,
				should_add_setup_price_to_margin
			} = value[0];

			form.setFieldsValue(
				handleMarkupMargin(
					cost_price,
					sell_price,
					1,
					should_add_setup_price_to_margin,
					setup_price
				)
			);
		}
	}, []);

	const InputWidget = ({ value, onChange, disabled, ...rest }) => {
		return (
			<div key={`${rest.id}_calc_total`}>
				<div
					className="flex items-center"
					key={`${rest.id}_calc_total_input_container`}
				>
					<ZInputNumber
						key={`${rest.id}_calc_total_input`}
						type="number"
						step=".01"
						min={0}
						precision={2}
						className="mr-2"
						width={InputNumberWidths.xl}
						value={value}
						onChange={onChange}
						addOnAfter={getCurrencySettings().currency}
						disabled={disabled}
					/>
					{unitOfMeasure && (
						<ZText className="ml-2 whitespace-nowrap leading-8">
							{unitOfMeasure}
						</ZText>
					)}
					<ZButton
						className={`sub-action-button m-0 ${rest.id}_total_button`}
						type="link"
						onClick={() => {
							if (rest.id === "cost_price")
								updateTotalCostPrice();

							if (rest.id === "sell_price")
								updateTotalSellPrice();
							if (rest.id === "setup_price")
								updateTotalSetupPrice();
						}}
					>
						<ZText className="text-blue-5 active:text-blue-6">
							<ZIcon
								name={Icons.COPY}
								size="sm"
								className="mx-1 stroke-current"
							/>
							Copy Product Total
						</ZText>
					</ZButton>
				</div>
			</div>
		);
	};

	const sections: IFormSectionProps[] = [
		{
			meta: {
				formItemLayout: [6, 18],
				fields: [
					{
						key: "cost_price",
						label: "Cost Price",
						className: "mb-4",
						initialValue:
							value && value.length > 0
								? value[0]?.cost_price
								: 0.0,
						widget: props => InputWidget({ ...props, disabled }),
						widgetProps: {
							onChange: onCostPriceChange,
							disabled: disabled
						},
						rules: [
							{
								required: true,
								validator: (_: any, value: any) => {
									if (Number(value) < 0) {
										return Promise.reject(
											new Error(
												"Invalid Entry. Cost price cannot be less than 0"
											)
										);
									}
									if (!value && value !== 0) {
										return Promise.reject(
											new Error(
												"Cost price cannot be empty"
											)
										);
									}
									return Promise.resolve();
								}
							}
						]
					},
					{
						key: "markup",
						label: "Markup",
						className: "mb-4",
						widget: EWidgets.INPUTNUMBER,
						initialValue:
							value && value.length > 0 ? value[0]?.markup : 0.0,
						disabled: disabled,
						widgetProps: {
							precision: 2,
							step: 0.01,
							min: 0,
							addOnAfter: "%",
							width: EInputNumberWidths.xl,
							onChange: onMarkupChange
						}
					},
					{
						key: "margin",
						label: "Margin",
						className: "mb-4",
						widget: EWidgets.INPUTNUMBER,
						initialValue:
							value && value.length > 0 ? value[0]?.margin : 0.0,
						disabled: disabled,
						widgetProps: {
							precision: 2,
							step: 0.01,
							max: 100,
							addOnAfter: "%",
							width: EInputNumberWidths.xl,
							onChange: onMarginChange
						},
						rules: [
							{
								validator: (_: any, value: any) => {
									if (
										Number(value) >= 100 &&
										form.getFieldValue("cost_price") > 0
									) {
										return Promise.reject(
											new Error(
												"Invalid Entry. Margin cannot be 100% unless cost price is 0"
											)
										);
									}
									return Promise.resolve();
								}
							}
						]
					},
					{
						key: "billing_period",
						label: "Billing Period",
						className: "mb-4",
						widget: EWidgets.SELECT,
						rules: [{ required: true }],
						initialValue:
							value && value.length > 0
								? value[0]?.billing_period
								: ProductBillingType.OneTime,
						disabled: disabled,
						widgetProps: {
							placeholder: "Please select billing period",
							selectOptions: (
								Object.keys(
									productBillingTypeOptions
								) as ProductBillingType[]
							).map((value, index) => ({
								name: productBillingTypeOptions[value],
								value,
								key: index
							}))
						}
					},
					{
						key: "sell_price",
						label: "Selling Price",
						className: "mb-4",
						required: true,
						initialValue:
							value && value.length > 0
								? value[0]?.sell_price
								: 0.0,
						widget: props => InputWidget({ ...props, disabled }),
						widgetProps: {
							disabled: disabled,
							onChange: onSellPriceChange
						}
					},
					{
						key: "setup_price",
						label: "Setup Fee",
						className: "mb-4",
						required: true,
						widget: props => InputWidget({ ...props, disabled }),
						initialValue:
							value && value.length > 0
								? value[0]?.setup_price
								: 0.0,
						widgetProps: {
							disabled: disabled
						}
					},
					{
						key: "vendor_sku_no",
						label: "Vendor SKU No",
						className: "mb-4",
						widget: EWidgets.INPUT,
						initialValue:
							value && value.length > 0
								? value[0]?.vendor_sku_no
								: "",
						disabled: disabled,
						widgetProps: {
							placeholder: "Please enter vendor sku no",
							className: "w-full"
						}
					},
					{
						key: "should_add_setup_price_to_margin",
						wrapperCol: { span: 24 },
						widget: ({ value, onChange }) => (
							<div>
								<ZCheckbox
									checked={value}
									disabled={disabled}
									onChange={e => {
										onChange(e.target.checked);
										onAddSetupPriceToMarginChange(e);
									}}
								>
									Add 'Setup Fee' to Margin
								</ZCheckbox>
							</div>
						)
					},
					{
						key: "margin_and_amount",
						wrapperCol: { span: 24 },
						widget: () => {
							const values = getFieldValues();
							const marginAmount =
								(values.sell_price || 0) -
								(values.cost_price || 0) +
								(!!values.should_add_setup_price_to_margin
									? values.setup_price || 0
									: 0);
							return (
								<div className="rounded bg-gray-3 p-2 ">
									<ZTypography.ZText
										weight={FontWeights.MEDIUM}
									>
										Item Margin:
									</ZTypography.ZText>{" "}
									<ZTypography.ZText>
										{percentageFormatter(
											values.margin,
											100
										)}{" "}
										({currencyFormatter(marginAmount)})
									</ZTypography.ZText>
								</div>
							);
						}
					}
				]
			}
		}
	];

	const formProps: IFormProps = {
		form,
		key: "sku-bundle-plan-pricing",
		isSubmitAllowed: false,
		isCancelAllowed: false,
		isRightFooter: false,
		isStickFooter: false,
		isDynamic: true,
		sections,
		shouldDisableBeforeTouch: false,
		onBlur: () => {
			onChange([{ ...form.getFieldsValue(), name: "Default Pricing" }]);
		},
		onFocus: event => {
			if (event.target.className.includes("sub-action-button")) {
				event.target.click();
				event.target.blur();
			}
		}
	};

	return (
		<ZForm {...formProps} className="bundle-plan-pricing bg-gray-3 p-2" />
	);
};

export default ZSKUPricingPlanFormWidget;
