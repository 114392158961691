import React, { FC, useMemo } from "react";
import { ISKUProductCatalogResultRowProps } from "./interface";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Avatar from "antd/lib/avatar";
import {
	getInitialsForAvatar,
	getProductCatalogResultDisplayPrice,
	ProductSource
} from "@zomentum/contracts";
import { ZTooltip, ZTypography } from "@zomentum/atoms";
import { shortenText } from "@zomentum/utils";
import { EFontWeights } from "@zomentum/atoms/dist/ZTypography/interface";
import he from "he";
import { currencyFormatter } from "@zomentum/utils";
import { PricingPopover } from "./PricingPopover";

const { ZText } = ZTypography;

export const SKUProductCatalogResultRow: FC<
	ISKUProductCatalogResultRowProps
> = ({ record, selectedPricingId, onPricingChange }) => {
	const showMoreButtonVisible = record?.pricings?.length > 1;

	const selectedPricing = useMemo(
		() => record.pricings.find(pricing => pricing.id === selectedPricingId),
		[record, selectedPricingId]
	);

	const onPricingIdChange = (pricingId: string) => {
		onPricingChange?.(record.id, pricingId);
	};

	return (
		<Row className="flex items-center px-4">
			<Col span={2}>
				<Avatar
					alt="Product"
					src={record?.images?.[0] ?? ""}
					shape="square"
					className="w-11 h-11"
				>
					{getInitialsForAvatar(record.name)}
				</Avatar>
			</Col>
			<Col span={15} className="px-1">
				<Row>
					<Col span={24}>
						<Row align="middle" className="flex-1">
							<Col>
								<ZTooltip
									className="block"
									title={record?.name}
								>
									<ZText
										colorVariant={10}
										weight={EFontWeights.MEDIUM}
									>
										{shortenText(record?.name ?? "--", 50)}
									</ZText>
								</ZTooltip>
								<ZText className="block">
									{he.decode(record?.description ?? "--")}
								</ZText>
							</Col>
						</Row>
					</Col>

					<div className="flex flex-wrap w-full">
						<ZTooltip
							title={`${"Vendor SKU"}: ${
								selectedPricing?.vendor_sku_no
									? selectedPricing?.vendor_sku_no
									: "--"
							}`}
							placement="topLeft"
						>
							<ZText level={0} colorVariant={8}>
								Vendor SKU:{" "}
								{selectedPricing?.vendor_sku_no
									? selectedPricing?.vendor_sku_no
									: "--"}
							</ZText>
						</ZTooltip>
					</div>
				</Row>
			</Col>
			<Col span={7}>
				{selectedPricing && (
					<div>
						<div className="text-right w-full">
							<ZTooltip title={selectedPricing.name}>
								<ZText
									colorVariant={7}
									className="product-pricing-name truncate inline-block"
								>
									{shortenText(selectedPricing.name, 30)}
								</ZText>
							</ZTooltip>
							<Row
								justify="end"
								align="middle"
								className="text-right"
							>
								<Col span={24}>
									<ZText
										weight={EFontWeights.MEDIUM}
										level={3}
									>
										{`${currencyFormatter(
											getProductCatalogResultDisplayPrice(
												selectedPricing,
												ProductSource.ZomentumMarketplace
											),
											"Not available"
										)}`}
									</ZText>
								</Col>
							</Row>
						</div>
						{showMoreButtonVisible && (
							<PricingPopover
								pricingDetails={record.pricings}
								selectedPricing={selectedPricing}
								onChange={onPricingIdChange}
							/>
						)}
					</div>
				)}
			</Col>
		</Row>
	);
};
