'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../node_modules/tslib/tslib.es6.mjs.js');
var Users = require('@zomentum/contracts/dist/Users');
var Permission = require('@zomentum/contracts/dist/Permission');

/**
 *
 * @param module: Required. To get permissions based on the module, the module name from ModuleEntities should be passed.
 *
 * @param modulePermission: Required. Base permission on which other permissions of the same module depend on.
 *
 * Example: **OPPORTUNITY_IMPORT** depends on **READ & CREDIT_EDIT_DELETE_OWNED** combination.
 *
 * @param extraPermissions?: Optional. Extra permissions as per the requirement can be passed.
 *
 * @description computes the permission array for a module inorder to view the feature.
 *
 * @returns Array of permissions.
 *
 * @author Idrees
 */
var usePermissions = function (module, modulePermission, extraPermissions) {
  var _a, _b;
  var READ = "".concat(module, "_READ");
  var CREATE = "".concat(module, "_CREATE");
  var EDIT_ALL = "".concat(module, "_EDIT_ALL");
  var mapper = (_a = {}, _a[module] = (_b = {}, _b[Permission.ModulePermission.READ] = [Users.AllRolePermissions[READ]], _b[Permission.ModulePermission.CREATE_EDIT_DELETE_OWNED] = [Users.AllRolePermissions[READ], Users.AllRolePermissions[CREATE]], _b[Permission.ModulePermission.EDIT_ALL] = [Users.AllRolePermissions[READ], Users.AllRolePermissions[CREATE], Users.AllRolePermissions[EDIT_ALL]], _b), _a);
  if (!!(extraPermissions === null || extraPermissions === void 0 ? void 0 : extraPermissions.length)) return tslib_es6.__spreadArray(tslib_es6.__spreadArray([], mapper[module][modulePermission], true), extraPermissions, true);
  return mapper[module][modulePermission];
};
var usePermissions$1 = usePermissions;

exports["default"] = usePermissions$1;
