'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index = require('../../Utils/index.js');

var ROOT_RESOURCE_ID = index.allZeroesGuid;
var VENDOR_RESOURCE_S3_DIRECTORY_KEY = "partnerAlignResources";
var VendorResource = /** @class */function () {
  function VendorResource(name, is_folder, mime_type, file_key, parent_dir_id, sharing_details, total_views, total_downloads) {
    if (is_folder === void 0) {
      is_folder = false;
    }
    if (mime_type === void 0) {
      mime_type = null;
    }
    if (file_key === void 0) {
      file_key = null;
    }
    if (parent_dir_id === void 0) {
      parent_dir_id = "";
    }
    if (total_views === void 0) {
      total_views = 0;
    }
    if (total_downloads === void 0) {
      total_downloads = 0;
    }
    this.id = "";
    this.name = "";
    this.is_folder = false;
    this.mime_type = null;
    this.file_key = null;
    this.parent_dir_id = "";
    this.vendor_company_id = "";
    this.vendor_user_id = "";
    this.updated = new Date();
    this.created = new Date();
    this.thumbnail = null;
    this.total_views = 0;
    this.total_downloads = 0;
    this.vendor_company_name = "";
    this.name = name;
    this.is_folder = is_folder;
    this.mime_type = mime_type;
    this.file_key = file_key;
    this.parent_dir_id = parent_dir_id;
    this.sharing_details = sharing_details;
    this.total_views = total_views;
    this.total_downloads = total_downloads;
  }
  return VendorResource;
}();
exports.VendorResourceSharingType = void 0;
(function (VendorResourceSharingType) {
  VendorResourceSharingType["PRIVATE"] = "private";
  VendorResourceSharingType["JOINEDPARTNERS"] = "joined_partners";
  VendorResourceSharingType["TIERSANDPARTNERS"] = "tiers_and_partners";
})(exports.VendorResourceSharingType || (exports.VendorResourceSharingType = {}));
var GetVendorResourcesResponse = /** @class */function () {
  function GetVendorResourcesResponse() {
    this.data = new Array();
  }
  return GetVendorResourcesResponse;
}();
var GetVendorResourcePathsResponse = /** @class */function () {
  function GetVendorResourcePathsResponse() {
    this.data = new Array();
  }
  return GetVendorResourcePathsResponse;
}();
var AddVendorResourcesRequest = /** @class */function () {
  function AddVendorResourcesRequest() {
    this.resources = new Array();
    this.replace_existing = false;
  }
  return AddVendorResourcesRequest;
}();
var VendorResourceAnalyticsRequest = /** @class */function () {
  function VendorResourceAnalyticsRequest() {
    this.activity_type = "view";
  }
  return VendorResourceAnalyticsRequest;
}();
var AddVendorResourcesResponse = /** @class */function () {
  function AddVendorResourcesResponse() {
    this.data = new Array();
  }
  return AddVendorResourcesResponse;
}();
exports.VendorResourceUploadStep = void 0;
(function (VendorResourceUploadStep) {
  VendorResourceUploadStep[VendorResourceUploadStep["UPLOAD_FILES"] = 0] = "UPLOAD_FILES";
  VendorResourceUploadStep[VendorResourceUploadStep["PREVIEW_FILES"] = 1] = "PREVIEW_FILES";
})(exports.VendorResourceUploadStep || (exports.VendorResourceUploadStep = {}));
exports.VendorResourceMode = void 0;
(function (VendorResourceMode) {
  VendorResourceMode["ADD"] = "add";
  VendorResourceMode["EDIT"] = "edit";
})(exports.VendorResourceMode || (exports.VendorResourceMode = {}));
var VendorResourceTierGetRequest = /** @class */function () {
  function VendorResourceTierGetRequest(tier_id, filters, keywords, included_child_entities, limit) {
    this.keywords = null;
    this.limit = 0;
    this.included_child_entities = new Array();
    this.filters = new Array();
    this.filters = filters || [];
    this.keywords = keywords || "";
    this.included_child_entities = included_child_entities || [];
    this.limit = limit || 100;
    this.tier_id = tier_id;
  }
  return VendorResourceTierGetRequest;
}();
exports.ResourceSelectType = void 0;
(function (ResourceSelectType) {
  ResourceSelectType["TIER"] = "tier";
  ResourceSelectType["PARTNER"] = "partner";
})(exports.ResourceSelectType || (exports.ResourceSelectType = {}));

exports.AddVendorResourcesRequest = AddVendorResourcesRequest;
exports.AddVendorResourcesResponse = AddVendorResourcesResponse;
exports.GetVendorResourcePathsResponse = GetVendorResourcePathsResponse;
exports.GetVendorResourcesResponse = GetVendorResourcesResponse;
exports.ROOT_RESOURCE_ID = ROOT_RESOURCE_ID;
exports.VENDOR_RESOURCE_S3_DIRECTORY_KEY = VENDOR_RESOURCE_S3_DIRECTORY_KEY;
exports.VendorResource = VendorResource;
exports.VendorResourceAnalyticsRequest = VendorResourceAnalyticsRequest;
exports.VendorResourceTierGetRequest = VendorResourceTierGetRequest;
