import React, { useState, useEffect, Fragment } from "react";
import EmailThreadActionCreators from "@/Actions/Others/EmailThread";
import UsersActionCreators from "@/Actions/User";
import VendorUsersActionCreators from "@/Actions/Vendor/User";
import { convertTextToMergeTag } from "@zomentum/utils/dist/MergeTags";
import {
	replaceMergeTagsWithId,
	updateMergeTagsDisplayName
} from "@zomentum/utils";
import { cache } from "@zomentum/contracts";
import {
	DefaultErrorMessage,
	FileUploadStatus,
	ZomentumRoutes,
	ZomentumStorageKeys,
	ZomentumWindow
} from "@zomentum/contracts";
import { MergeTagPreference } from "@zomentum/contracts/dist/Documents";
import {
	EmailTemplate,
	EmailTemplateFileDetails
} from "@zomentum/contracts/dist/EmailTemplates";
import { AuthToken } from "@zomentum/contracts/dist/Login";
import {
	EmailRecipientsTypes,
	EmailThreadFile,
	EmailThreadMessage,
	EmailThreadMessageEditorType,
	EmailThreadParticipant,
	EmailThreadRecipients,
	EmailTracking,
	EntityType,
	FetchMergeTagsRequest,
	GetDocumentEmailPreviewRequest,
	GetEmailPreviewRequest,
	SendEmailThreadMessageFormValues,
	SendEmailThreadMessageRequest,
	ValidateEmailMergeTagsRequest
} from "@zomentum/contracts/dist/Others/EmailThread";
import { ConnectedEmails } from "@zomentum/contracts/dist/Settings";
import {
	DocumentDefaultPreferenceLastUsedEmail,
	EmailSignature
} from "@zomentum/contracts/dist/Users";
import {
	checkForDocumentUrlPresent,
	emailRegex,
	TinyMceOptions,
	TinyMcePlugins
} from "@zomentum/contracts/dist/Utils";
import { initializeTinyMCEPrePastePrompt } from "@zomentum/utils/dist/tinyMCEPrePastePrompt";
import useMobileView from "@zomentum/hooks/dist/hooks/useMobileView";
import {
	formatNylasIntegratedEmailId,
	nylasConnectedEmailSuffix
} from "@/V2Utils";
import { Form } from "@ant-design/compatible";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { EColors } from "@zomentum/atoms/dist/ZColors/interface";
import ZIcon from "@zomentum/atoms/dist/ZIcon";
import { EIcons, ETwoToneIcons } from "@zomentum/atoms/dist/ZIcon/interface";
import ZMessage from "@zomentum/molecules/dist/ZMessage";
import ZSelect from "@zomentum/atoms/dist/ZSelect";
import { IOptionProps } from "@zomentum/atoms/dist/ZSelect/interface";
import ZTypography from "@zomentum/atoms/dist/ZTypography";
import { EFontWeights } from "@zomentum/atoms/dist/ZTypography/interface";
import ZModal from "@zomentum/atoms/dist/ZModal";
import ZSection from "@zomentum/molecules/dist/ZSection";
import { Editor } from "@tinymce/tinymce-react";
import { Modal, Spin } from "antd";
import Col from "antd/lib/col";
import { DrawerProps } from "antd/lib/drawer";
import ZDrawer from "@zomentum/molecules/dist/ZDrawer";
import Row from "antd/lib/row";
import Select from "antd/lib/select";
import Skeleton from "antd/lib/skeleton";
import Upload, { UploadChangeParam } from "antd/lib/upload";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { useSelector } from "react-redux";
import IFRAME from "../../Iframe";
import "./EmailThreadMessageDrawer.less";
import EmailSignatureComponent from "./FooterActivities/EmailSignatureComponent";
import TrackEmail from "./FooterActivities/TrackEmail";
import { ZButton } from "@zomentum/atoms";
import { GlobalState } from "@/Reducers/interface";
import ZMergeTagCascadeDropdown from "@zomentum/molecules/dist/ZMergeTag/ZMergeTagCascadeDropdown";
import EmptyMergeTagsAlertModal from "../Modals/EmptyMergeTags";
import ImportEmailTemplateModal from "./FooterActivities/ImportEmailTemplateModal";
import { VendorEmailTemplate } from "@zomentum/contracts/dist/Vendor/EmailTemplate";
import {
	MergeTagListToSkip,
	MergeTagChildrenListToSkip
} from "@zomentum/contracts/dist/Others/MergeTags";
import { Player } from "@lottiefiles/react-lottie-player";
import Send from "@/Assets/Others/SalesActivity/Send.json";
import useDispatch from "@/Components/Utils/useDispatch";
import { useHistory } from "react-router-dom";

declare let window: ZomentumWindow;

interface Props
	extends DrawerProps,
		FormComponentProps<SendEmailThreadMessageFormValues> {
	readonly isNewThread: boolean;
	readonly isDocumentMessage: boolean;
	readonly defaultRecipients: string[] | null;
	readonly emailThreadMessage: EmailThreadMessage | null;
	readonly ignoreAppendEmailSignature?: boolean;
	readonly defaultSubject?: string | null;
	readonly bodyPlaceholder?: string;
	readonly templateBody?: string | null;
	readonly templateAttachments?: EmailTemplateFileDetails[] | null;
	readonly partnerId?: string | null;
	readonly opportunityId?: string | null;
	readonly clientId?: string | null;
	readonly useTemplate: boolean;
	readonly isFromDocumentsPage?: boolean;
	readonly allEmailRecipients?: EmailThreadRecipients[];
	readonly isFromEmailTemplatesPage?: boolean;
	readonly onSubmit?: () => void;
	readonly onClose?: () => void;
	readonly refreshSalesActivities?: (
		shouldRefreshSalesActivities: boolean
	) => void;
	readonly sendDocumentFromConnectedEmailFlag?: boolean;
	readonly isFromVendorEmailTemplatespage?: boolean;
}

export const MERGE_TAG_START = "{{";
export const MERGE_TAG_END = "}}";

const { ZText } = ZTypography;

const EmailThreadMessageDrawerFuncComp: React.FC<Props> = props => {
	const textBlockContainerRef: React.RefObject<HTMLDivElement> =
		React.createRef();
	const subjectBlockContainerRef: React.RefObject<HTMLDivElement> =
		React.createRef();
	const recipientRef: React.RefObject<HTMLDivElement> = React.createRef();
	const isMobileView = useMobileView();
	const history = useHistory();
	const {
		isLoading,
		companyUserList,
		defaultFromValues,
		emailSignatures,
		emailThreadMergeTagVariables,
		emptyMergeTags,
		isSendingMs365InviteToClient,
		otherEntityEmailPreview,
		userData,
		userEmailIntegrationObj
	} = useSelector((state: GlobalState) => {
		const userData = state.vendorUser.vendorUserData;
		const userEmailIntegrationObj =
			state.vendorUser.vendorUserIntegration.email?.find(
				e => e.user_id === userData.id
			);
		return {
			isLoading:
				state.emailThread.isLoading ||
				state.recentActivityFeed.isLoading ||
				state.vendorUser.isLoading ||
				state.settings.isLoading,
			isSendingMs365InviteToClient:
				state.emailThread.isSendingMs365AssessmentsInviteToClient,
			userEmailIntegrationObj,
			emailThreadMergeTagVariables:
				state.emailThread.emailMergeTagsSystemVariable,
			otherEntityEmailPreview: state.emailThread.otherEntityEmailPreview,
			emptyMergeTags: state.emailThread.emptyMergeTags,
			userData,
			emailSignatures: state.user.emailSignatures,
			defaultFromValues: {
				email:
					process.env.REACT_APP_DEFAULT_EMAIL ??
					"noreply@zomentum.com",
				id: state.vendorUser.vendorUserData.id,
				name: state.vendorUser.vendorUserData.name.full
			},
			companyUserList: state.vendorUser.vendorUserList
		};
	});
	const dispatch = useDispatch();

	const [emailThreadMessageFiles, setEmailThreadMessageFiles] = useState<
		string[]
	>(props.templateAttachments?.map(file => file.file_id) ?? []);
	const [
		emailThreadMessageEditorLoading,
		setEmailThreadMessageEditorLoading
	] = useState(true);
	const [
		emailThreadMessageFileUploading,
		setEmailThreadMessageFileUploading
	] = useState(false);
	const [showCcRecipients, setShowCcRecipients] = useState(false);
	const [showBccRecipients, setShowBccRecipients] = useState(false);
	const [mergeTagDropdownX, setMergeTagDropdownX] = useState(0);
	const [mergeTagDropdownY, setMergeTagDropdownY] = useState(0);
	const [mergeTagDropdownVisible, setMergeTagDropdownVisibleState] =
		useState(false);
	const [isPreviewEmailModalOpen, setIsPreviewEmailModalOpen] =
		useState(false);
	const [isPreviewLoading, setIsPreviewLoading] = useState(false);
	const [isEmptyMergeTagsAlertModalOpen, setIsEmptyMergeTagsAlertModalOpen] =
		useState(false);
	const [emptyMergeTagsState, setEmptyMergeTags] = useState<Array<string>>(
		[]
	);
	const [
		isEmptyMergeTagsAlertFromSendEmail,
		setIsEmptyMergeTagsAlertFromSendEmail
	] = useState(false);
	const [shouldReplaceWithBlank, setShouldReplaceWithBlank] = useState(true);
	const [isImportEmailTemplateModalOpen, setIsImportEmailTemplateModalOpen] =
		useState(false);
	const [
		shouldEmptyMergeTagAlertOpenFromSend,
		setShouldEmptyMergeTagAlertOpenFromSend
	] = useState(true);
	const [isValidating, setIsValidating] = useState(false);
	const [emailTracking, setEmailTracking] = useState<EmailTracking | null>(
		userData?.user_settings?.preference_settings
			?.email_tracking_preference_settings ?? null
	);
	const [isLoadingState, setIsLoading] = useState(false);
	const [systemSendEmailTemplate, setSystemSendEmailTemplate] =
		useState<EmailTemplate | null>(null);
	const [emailSent, setEmailSent] = useState(false);
	const [isMandatoryMergeTagModalOpen, setIsMandatoryMergeTagModalOpen] =
		useState(false);
	const [currentActiveEmailSignature, setCurrentActiveEmailSignature] =
		useState<EmailSignature | null>(null);
	const [dkimConnectedEmails, setDkimConnectedEmails] = useState<
		ConnectedEmails[]
	>([]);
	const [emailExpiredModalVisible, setEmailExpiredModalVisible] =
		useState(false);
	const [emailExpiredMessage, setEmailExpiredMessage] = useState("");
	const [listOfAttachments, setListOfAttachments] = useState<
		UploadFile<unknown>[]
	>(
		props.templateAttachments?.map(
			file =>
				({
					uid: file.file_id,
					name: file.file_name,
					status: FileUploadStatus.DONE,
					size: file.file_size ?? 0
				} as UploadFile<any>)
		) ?? []
	);
	const [recipientSearch, setRecipientSearch] = useState("");
	const [shouldCollapse, setShouldCollapse] = useState(true);

	useEffect(() => {
		extractValidDkimData();
	}, []);

	const extractValidDkimData = () => {
		const connected_email: ConnectedEmails[] = [];
		setDkimConnectedEmails(connected_email);
	};

	useEffect(() => {
		if (props.visible) {
			if (mergeTagDropdownVisible) {
				setMergeTagDropdownVisible(false);
			}
			if (props.visible) {
				const { partnerId, opportunityId, clientId } = props;
				dispatch(
					EmailThreadActionCreators.fetchMergeTags(
						new FetchMergeTagsRequest({
							partnerId,
							opportunityId,
							clientId
						})
					)
				);
				document.addEventListener("click", handleCollapse);

				const defaultEmailSignature =
					emailSignatures.signatures.find(
						signature => signature.is_default
					) ?? null;

				setCurrentActiveEmailSignature(defaultEmailSignature);
				fetchAllEmailSignatures();

				if (props.isFromEmailTemplatesPage) {
					setEmailThreadMessageFiles(
						props.templateAttachments?.map(file => file.file_id) ??
							[]
					);
					setListOfAttachments(
						props.templateAttachments?.map(
							file =>
								({
									uid: file.file_id,
									name: file.file_name,
									status: FileUploadStatus.DONE,
									size: file.file_size ?? 0
								} as UploadFile<any>)
						) ?? []
					);
				}

				if (
					!!props?.partnerId?.length ||
					!!props?.opportunityId?.length ||
					!!props?.clientId?.length
				) {
					appendSignatureInEditor();
				}
				if (props.templateAttachments) {
					setEmailThreadMessageFiles(
						props.templateAttachments?.map(file => file.file_id) ??
							[]
					);
					setListOfAttachments(
						props.templateAttachments?.map(
							file =>
								({
									uid: file.file_id,
									name: file.file_name,
									status: FileUploadStatus.DONE,
									size: file.file_size ?? 0
								} as UploadFile<any>)
						) ?? []
					);
				}
			} else {
				document.removeEventListener("click", handleCollapse);
			}
		}
	}, [props.visible]);

	const appendSignatureInEditor = () => {
		const emailThreadMessageEditorElement = window.tinyMCE?.get(
			props.isNewThread
				? EmailThreadMessageEditorType.Compose
				: EmailThreadMessageEditorType.Reply
		);
		if (!emailThreadMessageEditorElement) {
			console.warn(" Tiny mce editor not ready");
			return;
		}
		const content = emailThreadMessageEditorElement.getContent({
			format: "raw"
		});
		const defaultEmailSignature =
			emailSignatures.signatures.find(
				signature => signature.is_default
			) ?? null;
		if (defaultEmailSignature) {
			emailThreadMessageEditorElement.setContent(
				content + defaultEmailSignature.body
			);
		}
	};

	const resetState = () => {
		setEmailThreadMessageFiles(new Array<string>());
		setListOfAttachments(new Array<UploadFile<any>>());
		setEmailThreadMessageEditorLoading(false);
		setEmailThreadMessageFileUploading(false);
		setShowCcRecipients(false);
		setShowBccRecipients(false);
	};

	const initializeEmailThreadMessageDrawerEditor = () => {
		setEmailThreadMessageEditorLoading(false);
	};

	const onClose = () => {
		if (!mergeTagDropdownVisible) {
			resetState();
			if (props.onClose) {
				setSystemSendEmailTemplate(null);
				dispatch(EmailThreadActionCreators.resetMergeTags());
				props.onClose();
				emailSent &&
					props.refreshSalesActivities &&
					props.refreshSalesActivities(false);

				setEmailSent(false);
			}
		} else {
			setMergeTagDropdownVisible(false);
		}
	};

	const toggleCcRecipients = () => {
		setShowCcRecipients(!showCcRecipients);
	};

	const toggleBccRecipients = () => {
		setShowBccRecipients(!showBccRecipients);
	};

	const beforeUploadEmailThreadFile = (file: RcFile, _fileList: RcFile[]) => {
		const totalSizeOfAttachments = listOfAttachments.reduce(
			(sum, attachment) => (attachment.size as number) + sum,
			0
		);
		const isFileSizeLessThan25MB =
			totalSizeOfAttachments + file.size < 25 * 1024 * 1024;
		const isFileSizeLessThan7MB =
			totalSizeOfAttachments + file.size < 7 * 1024 * 1024;
		const isSendingEmailFromDocumentPage =
			props.isDocumentMessage && !!props.isFromDocumentsPage;
		if (!isFileSizeLessThan7MB && isSendingEmailFromDocumentPage) {
			ZMessage.error(
				"Error: Total size of attachments exceeds maximum allowed size of 7 MB"
			);
			return isFileSizeLessThan7MB;
		}
		if (!isFileSizeLessThan25MB) {
			ZMessage.error(
				"Error: Total size of attachments exceeds maximum allowed size of 25 MB"
			);
		}
		return isFileSizeLessThan25MB;
	};

	const uploadEmailThreadFile = (
		info: UploadChangeParam<UploadFile<EmailThreadFile>>
	) => {
		try {
			setListOfAttachments([...info.fileList]);
			if (info.file.status === FileUploadStatus.DONE) {
				const uploadEmailThreadFileResponse = info.file.response;
				if (
					(uploadEmailThreadFileResponse &&
						uploadEmailThreadFileResponse.id &&
						uploadEmailThreadFileResponse.id.length > 0) ||
					(uploadEmailThreadFileResponse &&
						uploadEmailThreadFileResponse.file_id)
				) {
					let temp = "";
					if (uploadEmailThreadFileResponse.id) {
						temp = uploadEmailThreadFileResponse.id;
					} else if (uploadEmailThreadFileResponse.file_id) {
						temp = uploadEmailThreadFileResponse.file_id;
					}
					setEmailThreadMessageFiles(
						emailThreadMessageFiles.concat(temp)
					);
					setEmailThreadMessageFileUploading(false);
					ZMessage.success(
						`${info.file.name} file uploaded successfully`
					);
				} else {
					ZMessage.error(`${info.file.name} file upload failed.`);
					setEmailThreadMessageFileUploading(false);
				}
			} else if (info.file.status === FileUploadStatus.REMOVED) {
				const newListOfAttachments = listOfAttachments.filter(
					attachment => attachment.status !== FileUploadStatus.REMOVED
				);
				setEmailThreadMessageFileUploading(false);
				setListOfAttachments(newListOfAttachments);
			} else if (info.file.status === FileUploadStatus.ERROR) {
				ZMessage.error(`${info.file.name} file upload failed.`);
				const newListOfAttachments = listOfAttachments.filter(
					attachment => attachment.status !== FileUploadStatus.ERROR
				);
				setEmailThreadMessageFileUploading(false);
				setListOfAttachments(newListOfAttachments);
			} else if (info.file.status === undefined) {
				const newListOfAttachments = listOfAttachments.filter(
					attachment => attachment.status
				);
				setEmailThreadMessageFileUploading(false);
				setListOfAttachments(newListOfAttachments);
			} else {
				setEmailThreadMessageFileUploading(true);
			}
		} catch (error) {
			console.error(error);
			setEmailThreadMessageFileUploading(false);
		}
	};

	const deleteEmailThreadFile = (file: UploadFile) => {
		try {
			if (file.status === FileUploadStatus.ERROR) {
				throw new Error(`${file.name} file deletion failed.`);
			}
			let comparison: string;
			if (!!file.response?.id) {
				comparison = file.response.id;
			} else if (!!file.response?.file_id) {
				comparison = file.response.file_id;
			} else {
				comparison = file.uid;
			}
			setEmailThreadMessageFiles(
				emailThreadMessageFiles.filter(
					emailThreadMessageFile =>
						emailThreadMessageFile !== comparison
				)
			);
			ZMessage.success(`${file.name} file removed successfully`);
		} catch (error) {
			console.error(error);
		}
	};

	const getFormValuesForEmail = (
		sendEmailThreadMessageFormValues: SendEmailThreadMessageFormValues
	) => {
		try {
			const isTinyReady =
				process.env.REACT_APP_TINY_MCE &&
				process.env.REACT_APP_TINY_MCE.length > 0 &&
				props.emailThreadMessage &&
				window.tinyMCE &&
				window.tinyMCE.get;

			if (!isTinyReady) {
				console.warn("Tiny not ready");
				return null;
			}
			const emailThreadMessageEditorElement = window.tinyMCE.get(
				props.isNewThread
					? EmailThreadMessageEditorType.Compose
					: EmailThreadMessageEditorType.Reply
			);

			if (!emailThreadMessageEditorElement) {
				console.warn("Editor body not ready");
				return null;
			}

			const emailThreadMessageSubjectElement = window.tinyMCE.get(
				`${
					props.isNewThread
						? EmailThreadMessageEditorType.Compose
						: EmailThreadMessageEditorType.Reply
				}-subject`
			);

			if (!emailThreadMessageSubjectElement && props.isNewThread) {
				console.warn("Subject editor not ready");
				return null;
			}

			const alias = userEmailIntegrationObj?.email_aliases?.find(
				data => data.email === sendEmailThreadMessageFormValues.from
			);

			const dkim = dkimConnectedEmails?.find(
				data => data.email === sendEmailThreadMessageFormValues.from
			);

			const isNylasEmailID =
				sendEmailThreadMessageFormValues.from?.includes(
					nylasConnectedEmailSuffix
				);

			/**
			 *
			 *  Checks alias and dkim to setup "from" value,
			 *  also checks if the from email is default zomentum's email then it sets to undefined else if the email isn't verified it'll set to null.
			 *  * Make sure to check type of "from" cause undefined and null has different means in our context
			 *
			 */
			let from: EmailThreadRecipients | null | undefined;

			if (isNylasEmailID) {
				from = {
					email: formatNylasIntegratedEmailId(
						sendEmailThreadMessageFormValues.from,
						true
					),
					name: userData.name.full,
					id: userData.id,
					is_nylas: true
				};
			} else if (alias) {
				from = {
					email: alias.email,
					name: alias.name,
					id: userData.id
				};
			} else if (dkim) {
				from = {
					email: dkim.email,
					name: dkim.name,
					id: userData.id
				};
			} else if (
				sendEmailThreadMessageFormValues.from ===
				process.env.REACT_APP_DEFAULT_EMAIL
			) {
				from = undefined;
			} else if (sendEmailThreadMessageFormValues.from) {
				from = {
					email: sendEmailThreadMessageFormValues.from,
					name: userData.name.full,
					id: userData.id
				};
			}

			const emailBody: string =
				emailThreadMessageEditorElement.getContent();
			const emailSubject: string =
				!props.isNewThread && props.emailThreadMessage
					? props.emailThreadMessage?.subject
					: emailThreadMessageSubjectElement.settings?.target
							.innerText;

			const recipients =
				sendEmailThreadMessageFormValues.to &&
				sendEmailThreadMessageFormValues.to.length > 0
					? sendEmailThreadMessageFormValues.to.map(idOrEmail => {
							const recipient = props.allEmailRecipients?.find(
								user => user.id === idOrEmail
							);
							if (recipient) {
								const emailThreadRecipient =
									new EmailThreadRecipients();
								emailThreadRecipient.email = recipient.email;
								emailThreadRecipient.name = recipient.name;
								emailThreadRecipient.id = recipient.id;
								return emailThreadRecipient;
							} else {
								const emailThreadRecipient =
									new EmailThreadRecipients();
								emailThreadRecipient.email = idOrEmail;
								return emailThreadRecipient;
							}
					  })
					: new Array<EmailThreadRecipients>();
			const cc =
				sendEmailThreadMessageFormValues.cc &&
				sendEmailThreadMessageFormValues.cc.length > 0
					? sendEmailThreadMessageFormValues.cc.map(email => {
							const emailThreadParticipant =
								new EmailThreadParticipant();
							emailThreadParticipant.email = email;
							return emailThreadParticipant;
					  })
					: new Array<EmailThreadParticipant>();

			const bcc =
				sendEmailThreadMessageFormValues.bcc &&
				sendEmailThreadMessageFormValues.bcc.length > 0
					? sendEmailThreadMessageFormValues.bcc.map(email => {
							const emailThreadParticipant =
								new EmailThreadParticipant();
							emailThreadParticipant.email = email;
							return emailThreadParticipant;
					  })
					: new Array<EmailThreadParticipant>();

			const file_ids = [...emailThreadMessageFiles];

			const account_id = props.emailThreadMessage?.account_id;

			let currentMail_id = "";
			let currentMail_type: EntityType = EntityType.VendorOpportunity;
			const arePartnerAndOpportunitySelected =
				props.partnerId && props.opportunityId;
			if (arePartnerAndOpportunitySelected) {
				currentMail_id = props.opportunityId || "";
				currentMail_type = EntityType.VendorOpportunity;
			} else if (props.partnerId) {
				currentMail_id = props.partnerId || "";
				currentMail_type = EntityType.Partner;
			} else if (props.clientId) {
				currentMail_id = props.clientId || "";
				currentMail_type = EntityType.VendorClient;
			} else {
				currentMail_id = props.opportunityId || "";
				currentMail_type = EntityType.VendorOpportunity;
			}
			return {
				from,
				recipients,
				cc,
				bcc,
				file_ids,
				emailBody,
				emailSubject,
				account_id,
				currentMail_id,
				currentMail_type
			};
		} catch (error) {
			ZMessage.error(DefaultErrorMessage);
			console.error(error);
			return;
		}
	};

	const generateOtherEntityPreview = async (
		previewEmailRequest: Partial<GetEmailPreviewRequest>
	) => {
		setIsPreviewLoading(true);
		try {
			await dispatch(
				EmailThreadActionCreators.previewEmail(previewEmailRequest)
			);
		} catch (error) {
			setIsPreviewEmailModalOpen(false);
			ZMessage.error(DefaultErrorMessage);
			console.error(error);
		} finally {
			setIsPreviewLoading(false);
		}
	};

	const fetchAllEmailSignatures = () => {
		setIsLoading(true);
		try {
			dispatch(UsersActionCreators.fetchAllEmailSignatures());
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const verifyAndOpenPreviewModal = () => {
		props.form.validateFields(
			async (
				errors: unknown,
				sendEmailThreadMessageFormValues: SendEmailThreadMessageFormValues
			) => {
				if (!errors) {
					try {
						const formValues = getFormValuesForEmail(
							sendEmailThreadMessageFormValues
						);

						if (!formValues) {
							console.warn("No form values found");
							return;
						}

						const validateEmailMergeTagsRequest =
							new ValidateEmailMergeTagsRequest();

						validateEmailMergeTagsRequest.from =
							formValues?.from ?? defaultFromValues;
						validateEmailMergeTagsRequest.to =
							formValues.recipients;
						validateEmailMergeTagsRequest.cc = formValues.cc;
						validateEmailMergeTagsRequest.bcc = formValues.bcc;
						validateEmailMergeTagsRequest.body =
							replaceMergeTagsWithId(
								emailThreadMergeTagVariables,
								formValues.emailBody
							);
						validateEmailMergeTagsRequest.subject =
							replaceMergeTagsWithId(
								emailThreadMergeTagVariables,
								formValues.emailSubject
							);
						validateEmailMergeTagsRequest.mail_type =
							formValues.currentMail_type;
						validateEmailMergeTagsRequest.mail_id =
							formValues.currentMail_id;
						setIsValidating(true);
						dispatch(
							EmailThreadActionCreators.validateEmailMergeTags(
								validateEmailMergeTagsRequest
							)
						);
						setIsValidating(false);

						const emptyMergeTagsValue: string[] =
							emptyMergeTags || [];

						if (!!emptyMergeTagsValue?.length) {
							setEmptyMergeTags(emptyMergeTagsValue);
							setIsEmptyMergeTagsAlertModalOpen(true);
						} else {
							continueToPreviewEmail(MergeTagPreference.REPLACE);
						}
					} catch (error) {
						console.error(error);
					}
				}
			}
		);
	};

	const continueToPreviewEmail = (
		shouldReplaceWithBlankValue?: MergeTagPreference
	) => {
		setShouldReplaceWithBlank(() => {
			props.form.validateFields(
				(
					error,
					sendEmailThreadMessageFormValues: SendEmailThreadMessageFormValues
				) => {
					if (!error) {
						try {
							const formValues = getFormValuesForEmail(
								sendEmailThreadMessageFormValues
							);

							if (!formValues) {
								console.warn("No form values found");
								return;
							}

							const previewEmailRequest =
								new GetEmailPreviewRequest();

							previewEmailRequest.from = formValues?.from || null;
							previewEmailRequest.to = formValues.recipients;
							previewEmailRequest.subject =
								replaceMergeTagsWithId(
									emailThreadMergeTagVariables,
									formValues.emailSubject
								);
							previewEmailRequest.body = replaceMergeTagsWithId(
								emailThreadMergeTagVariables,
								formValues.emailBody
							);
							previewEmailRequest.should_replace_with_empty_string =
								shouldReplaceWithBlank;
							previewEmailRequest.mail_id =
								formValues.currentMail_id;
							previewEmailRequest.mail_type =
								formValues.currentMail_type;
							previewEmailRequest.file_ids = formValues.file_ids;
							previewEmailRequest.signature =
								currentActiveEmailSignature?.body ?? "";

							const documentEmailPreviewRequest =
								new GetDocumentEmailPreviewRequest();

							documentEmailPreviewRequest.to =
								formValues.recipients;
							documentEmailPreviewRequest.bcc = formValues.bcc;
							documentEmailPreviewRequest.cc = formValues.cc;
							documentEmailPreviewRequest.should_replace_with_empty_string =
								shouldReplaceWithBlank;
							documentEmailPreviewRequest.body =
								replaceMergeTagsWithId(
									emailThreadMergeTagVariables,
									formValues.emailBody
								);
							documentEmailPreviewRequest.subject =
								replaceMergeTagsWithId(
									emailThreadMergeTagVariables,
									formValues.emailSubject
								);

							documentEmailPreviewRequest.signature =
								currentActiveEmailSignature?.body ?? "";
							generateOtherEntityPreview(previewEmailRequest);
							setIsPreviewEmailModalOpen(true);
							closeEmptyMergeTagsAlertModal();
						} catch (error) {
							ZMessage.error(DefaultErrorMessage);
							console.error(error);
						}
					}
				}
			);
			return shouldReplaceWithBlankValue !== MergeTagPreference.BLANK
				? false
				: true;
		});
	};

	const onSubmit = ($event: React.FormEvent<HTMLFormElement>) => {
		$event.preventDefault();
		setIsEmptyMergeTagsAlertFromSendEmail(true);

		props.form.validateFieldsAndScroll(
			async (
				error,
				sendEmailThreadMessageFormValues: SendEmailThreadMessageFormValues
			) => {
				if (!error) {
					try {
						const formValues = getFormValuesForEmail(
							sendEmailThreadMessageFormValues
						);

						if (!formValues) {
							console.warn("No form values found");
							return;
						}

						if (formValues.from === null) {
							showEmailExpiredModal("");
							return;
						}

						const isDocumentUrlPresent = checkForDocumentUrlPresent(
							formValues.emailBody
						);

						if (
							!!systemSendEmailTemplate &&
							!!systemSendEmailTemplate?.id?.length &&
							!systemSendEmailTemplate.system_template_info &&
							!isDocumentUrlPresent
						) {
							setIsMandatoryMergeTagModalOpen(true);
							return;
						}

						const validateEmailMergeTagsRequest =
							new ValidateEmailMergeTagsRequest();

						validateEmailMergeTagsRequest.from =
							formValues.from ?? defaultFromValues;
						validateEmailMergeTagsRequest.to =
							formValues.recipients;
						validateEmailMergeTagsRequest.cc = formValues.cc;
						validateEmailMergeTagsRequest.bcc = formValues.bcc;
						validateEmailMergeTagsRequest.body =
							replaceMergeTagsWithId(
								emailThreadMergeTagVariables,
								formValues.emailBody
							);
						validateEmailMergeTagsRequest.subject =
							replaceMergeTagsWithId(
								emailThreadMergeTagVariables,
								formValues.emailSubject
							);
						validateEmailMergeTagsRequest.mail_type =
							formValues.currentMail_type;
						validateEmailMergeTagsRequest.mail_id =
							formValues.currentMail_id;

						const validateTo = validateEmails(
							validateEmailMergeTagsRequest.to
						);
						const validateCc = validateEmails(
							validateEmailMergeTagsRequest.cc
						);
						const validateBcc = validateEmails(
							validateEmailMergeTagsRequest.bcc
						);

						if (!validateTo) {
							ZMessage.error(
								"Invalid email entered in the TO field. Please verify the email address and try again"
							);
							return;
						} else if (!validateCc) {
							ZMessage.error(
								"Invalid email entered in the CC field. Please verify the email address and try again"
							);
							return;
						} else if (!validateBcc) {
							ZMessage.error(
								"Invalid email entered in the BCC field. Please verify the email address and try again"
							);
							return;
						}

						const emptyMergeTagsValue: string[] =
							shouldEmptyMergeTagAlertOpenFromSend
								? emptyMergeTags || []
								: [];
						if (
							emptyMergeTagsValue.length > 0 &&
							shouldEmptyMergeTagAlertOpenFromSend
						) {
							setEmptyMergeTags(emptyMergeTagsValue);
							setIsEmptyMergeTagsAlertModalOpen(true);
						} else {
							continueToSendEmail(MergeTagPreference.REPLACE);
						}
					} catch (error) {
						if (!error.message) {
							ZMessage.error(DefaultErrorMessage);
						}
						console.error(error);
					}
				}
			}
		);
	};

	const continueToSendEmail = (
		shouldReplaceWithBlankValue?: MergeTagPreference
	) => {
		const checkMergeTag =
			shouldReplaceWithBlankValue !== MergeTagPreference.BLANK
				? false
				: true;

		const shouldReplaceEmpyMergeTagWithBlank =
			shouldEmptyMergeTagAlertOpenFromSend
				? checkMergeTag
				: shouldReplaceWithBlank;

		setShouldReplaceWithBlank(() => {
			props.form.validateFields(
				(
					errors: unknown,
					sendEmailThreadMessageFormValues: SendEmailThreadMessageFormValues
				) => {
					if (!errors) {
						try {
							const formValues = getFormValuesForEmail(
								sendEmailThreadMessageFormValues
							);
							if (!formValues) {
								console.warn("No form values found");
								return;
							}

							const sendEmailThreadMessageRequest =
								new SendEmailThreadMessageRequest();

							sendEmailThreadMessageRequest.from =
								formValues.from ?? defaultFromValues;
							sendEmailThreadMessageRequest.to =
								formValues.recipients;
							sendEmailThreadMessageRequest.subject =
								replaceMergeTagsWithId(
									emailThreadMergeTagVariables,
									formValues?.emailSubject
								);
							sendEmailThreadMessageRequest.body =
								replaceMergeTagsWithId(
									emailThreadMergeTagVariables,
									formValues.emailBody
								);
							sendEmailThreadMessageRequest.bcc = formValues.bcc;
							sendEmailThreadMessageRequest.cc = formValues.cc;
							sendEmailThreadMessageRequest.mail_type =
								formValues.currentMail_type;
							sendEmailThreadMessageRequest.mail_id =
								formValues.currentMail_id;
							sendEmailThreadMessageRequest.account_id =
								formValues.account_id || "";
							sendEmailThreadMessageRequest.file_ids =
								formValues.file_ids;
							sendEmailThreadMessageRequest.should_replace_with_empty_string =
								shouldReplaceWithBlank;
							sendEmailThreadMessageRequest.tracking =
								emailTracking;
							sendEmailThreadMessageRequest.reply_to_message_id =
								props.emailThreadMessage
									? props.emailThreadMessage.message_ids[
											props.emailThreadMessage.message_ids
												.length - 1
									  ]
									: "";
							sendEmailThreadMessageRequest.signature =
								currentActiveEmailSignature?.body ?? "";

							sendEmailThreadMessage(
								sendEmailThreadMessageRequest
							);
						} catch (error) {
							console.error(error);
							ZMessage.error(DefaultErrorMessage);
						}
					}
				}
			);
			closeEmptyMergeTagsAlertModal();
			return shouldReplaceEmpyMergeTagWithBlank;
		});
	};

	const sendEmailThreadMessage = async (
		sendEmailThreadMessageRequest: SendEmailThreadMessageRequest
	) => {
		dispatch(EmailThreadActionCreators.showEmailThreadLoader());
		try {
			if (!props.emailThreadMessage) {
				throw new Error("Email thread message is empty");
			}

			if (
				!!props.emailThreadMessage?.account_id?.length &&
				props.emailThreadMessage.account_id ===
					sendEmailThreadMessageRequest.account_id
			) {
				await dispatch(
					EmailThreadActionCreators.sendEmailThreadMessage(
						sendEmailThreadMessageRequest
					)
				);
				setEmailSent(true);
				onClose();
				ZMessage.success("Email sent successfully");
				props.onSubmit?.();
			} else {
				throw new Error("Account id is missing");
			}
		} catch (error) {
			console.error(error);
			const errorRes = error?.message;
			if (
				errorRes?.indexOf(
					"The address you are attempting to send the document from is no longer authorized"
				) !== -1
			) {
				showEmailExpiredModal(errorRes);
			} else {
				if (error.error?.response?.data?.message) {
					const errorMessage =
						error.error.response.data.message +
						". " +
						error.displayMessage;
					console.error(errorMessage, error);
				} else {
					ZMessage.error(DefaultErrorMessage);
				}
			}
		} finally {
			const isLastUsedEmail =
				sendEmailThreadMessageRequest.from?.email ===
				userData.document_last_used_from_address;
			if (!isLastUsedEmail) {
				await dispatch(VendorUsersActionCreators.getVendorUserData());
			}
			dispatch(EmailThreadActionCreators.hideEmailThreadLoader());
		}
	};

	const showEmailExpiredModal = (errorRes: string) => {
		setEmailExpiredModalVisible(true);
		setEmailExpiredMessage(errorRes);
	};

	const closeEmailExpiredModal = () => {
		setEmailExpiredModalVisible(false);
		setEmailExpiredMessage("");
	};

	const handleEmailExpiration = () => {
		const emptyMergeTagsValue: string[] =
			shouldEmptyMergeTagAlertOpenFromSend ? emptyMergeTags || [] : [];
		if (emptyMergeTagsValue?.length) {
			setEmptyMergeTags(emptyMergeTagsValue);
			setIsEmptyMergeTagsAlertModalOpen(true);
		} else {
			continueToSendEmail(MergeTagPreference.REPLACE);
		}
		closeEmailExpiredModal();
	};

	const closeMandatoryMergeTagModal = () => {
		setIsEmptyMergeTagsAlertModalOpen(false);
	};

	const setMergeTagDropdownVisible = (isVisible: boolean) => {
		setMergeTagDropdownVisibleState(isVisible);
	};

	const closeEmptyMergeTagsAlertModal = () => {
		setEmptyMergeTags([]);
		setIsEmptyMergeTagsAlertModalOpen(false);
		setIsEmptyMergeTagsAlertFromSendEmail(false);
		dispatch(EmailThreadActionCreators.resetEmptyMergeTags());
	};

	const closePreviewModal = () => {
		setIsPreviewEmailModalOpen(false);
		setShouldEmptyMergeTagAlertOpenFromSend(false);
		dispatch(EmailThreadActionCreators.resetDocumentEmailPreview());
		dispatch(EmailThreadActionCreators.resetEmailPreview());
		dispatch(EmailThreadActionCreators.resetMs365ConsentEmailPreview());
	};

	const handleSubmitMergeTagVariable = (key: string) => {
		setMergeTagDropdownVisible(false);
		window.requestAnimationFrame(() => {
			window.tinyMCE.activeEditor.focus();
			mergeTagDropDownOnSubmit(key);
		});
	};

	const updateTextFromEmailBodyTinyMCEEditor = () => {
		setMergeTagDropdownVisible(false);
		setShouldEmptyMergeTagAlertOpenFromSend(true);

		if (window.tinyMCE.activeEditor.selection) {
			const boundingClientRect =
				window.tinyMCE.activeEditor.selection.getBoundingClientRect();
			if (
				boundingClientRect &&
				textBlockContainerRef &&
				textBlockContainerRef.current
			) {
				const parentRect = window.tinyMCE.activeEditor
					.getContentAreaContainer()
					.getBoundingClientRect();
				setMergeTagDropdownX(boundingClientRect.right);
				setMergeTagDropdownY(boundingClientRect.bottom + parentRect.y);
			}
			checkForMergeTagStart();
		}
	};

	const updateTextFromSubjectTinyMCEEditor = () => {
		setMergeTagDropdownVisible(false);
		setShouldEmptyMergeTagAlertOpenFromSend(true);

		if (window.tinyMCE.activeEditor.selection) {
			const boundingClientRect =
				window.tinyMCE.activeEditor.selection.getBoundingClientRect();
			if (
				boundingClientRect &&
				subjectBlockContainerRef &&
				subjectBlockContainerRef.current
			) {
				const parentRect =
					subjectBlockContainerRef.current?.getBoundingClientRect();
				setMergeTagDropdownX(boundingClientRect.right - parentRect.x);
				setMergeTagDropdownY(boundingClientRect.bottom);
			}
			checkForMergeTagStart();
		}
	};

	const mergeTagDropDownOnSubmit = (key: string) => {
		const editorRange = window.tinyMCE.activeEditor.selection.getRng();
		const node = editorRange.commonAncestorContainer;
		const newRange = document.createRange();
		newRange.selectNodeContents(node);
		newRange.setStart(node, editorRange.endOffset - MERGE_TAG_START.length);
		newRange.setEnd(node, editorRange.endOffset);
		newRange.deleteContents();
		window.tinyMCE.activeEditor.focus();

		const mergeTagDisplayName = emailThreadMergeTagVariables.find(
			mergeTag => mergeTag.variable_name === key
		)?.display_name;

		window.tinyMCE.activeEditor.insertContent(
			`<span class="merge-tag mceNonEditable" contentEditable="false" data-mce-contenteditable="false" data-id=${key}>${MERGE_TAG_START}${mergeTagDisplayName}${MERGE_TAG_END}</span>\ufeff `,
			{
				format: "html"
			}
		);

		setMergeTagDropdownVisible(false);
	};

	const handleCloseMergeTagDropdown = () => {
		setMergeTagDropdownVisible(false);
		window.requestAnimationFrame(() => {
			window.tinyMCE.activeEditor.focus();
		});
	};

	const showImportEmailTemplateModal = () => {
		setIsImportEmailTemplateModalOpen(true);
	};

	const closeImportEmailTemplateModal = () => {
		setIsImportEmailTemplateModalOpen(false);
	};

	const checkForMergeTagStart = () => {
		const currentRange = window.tinyMCE.activeEditor.selection.getRng(
			window.tinyMCE.activeEditor.selection.getNode()
		);
		if (
			currentRange &&
			currentRange.startContainer.textContent
				.substr(0, currentRange.startOffset)
				.endsWith(MERGE_TAG_START)
		) {
			window.requestAnimationFrame(() => {
				setMergeTagDropdownVisible(true);
			});
		}
	};

	const handleSelectEmailTemplate = (template: VendorEmailTemplate) => {
		const emailThreadMessageEditorElement = window.tinyMCE.get(
			props.isNewThread
				? EmailThreadMessageEditorType.Compose
				: EmailThreadMessageEditorType.Reply
		);

		const emailThreadMessageSubjectElement = window.tinyMCE.get(
			`${
				props.isNewThread
					? EmailThreadMessageEditorType.Compose
					: EmailThreadMessageEditorType.Reply
			}-subject`
		);

		closeImportEmailTemplateModal();

		if (!emailThreadMessageEditorElement) {
			console.warn(" Tiny mce editor not ready");
			return;
		}

		const content = emailThreadMessageEditorElement.getContent({
			format: "raw"
		});
		const domParser = new DOMParser();
		const emailEditorContent = domParser.parseFromString(
			content,
			"text/html"
		);
		const signature = emailEditorContent.querySelector(
			"div[data-email-signature][data-valid-signature=true]"
		) as HTMLElement;
		const signatureData = signature
			? `<div data-email-signature=${signature.dataset.emailSignature} data-valid-signature=true>${signature.innerHTML}</div>`
			: "";

		const updatedEditorText: string =
			updateMergeTagsDisplayName(
				emailThreadMergeTagVariables,
				template.body
			) + signatureData;

		emailThreadMessageEditorElement.setContent(updatedEditorText);

		if (!props.isNewThread) {
			return;
		}
		if (!emailThreadMessageSubjectElement) {
			console.warn("Tiny mce subject editor not ready");
			return;
		}

		const convertedTextToMergeTags = convertTextToMergeTag(
			emailThreadMergeTagVariables,
			template?.subject
		);

		emailThreadMessageSubjectElement.setContent(
			updateMergeTagsDisplayName(
				emailThreadMergeTagVariables,
				convertedTextToMergeTags
			)
		);
	};

	const updateEmailTrackingDetails = (trackDetails: EmailTracking) => {
		const emailTrackingDetails = Object.keys(trackDetails).some(
			k => (trackDetails as unknown as Record<string, string>)[k]
		)
			? trackDetails
			: null;
		setEmailTracking(emailTrackingDetails);
	};

	const handleCurrentActiveEmailSignature = (
		selectedSignature: EmailSignature | null
	) => {
		setCurrentActiveEmailSignature(selectedSignature);
	};

	const validateEmails = (emails: EmailThreadParticipant[]) => {
		return emails.every(email => email?.email?.match(emailRegex));
	};

	const getDefaultEmailPreference = () => {
		let defaultEmail: string | null | undefined = null;
		const documentPreferenceSettings =
			userData?.user_settings?.preference_settings
				?.document_preference_settings;

		if (documentPreferenceSettings && props.isFromDocumentsPage) {
			const isDefaultFromAddress =
				documentPreferenceSettings.default_from_address &&
				(!documentPreferenceSettings.default_from_address_type ||
					documentPreferenceSettings.default_from_address_type ===
						DocumentDefaultPreferenceLastUsedEmail.FIXED_ADDRESS_TYPE);

			const isLastUsedFromAddress =
				!documentPreferenceSettings.default_from_address &&
				(!documentPreferenceSettings.default_from_address_type ||
					documentPreferenceSettings.default_from_address_type ===
						DocumentDefaultPreferenceLastUsedEmail.LAST_USED_ADDRESS_TYPE);

			// default from address
			if (isDefaultFromAddress) {
				defaultEmail = documentPreferenceSettings.default_from_address;
			}

			// last used address type
			if (isLastUsedFromAddress) {
				defaultEmail = userData.document_last_used_from_address;
			}
		} else if (dkimConnectedEmails.length > 0) {
			if (userEmailIntegrationObj?.email) {
				const integratedEmail = dkimConnectedEmails.find(
					email => email.email === userEmailIntegrationObj?.email
				);
				if (integratedEmail) defaultEmail = integratedEmail.email;
			}
		}

		if (!defaultEmail) {
			if (props.opportunityId || props.partnerId || props.clientId) {
				if (userEmailIntegrationObj?.email) {
					if (isSendingMs365InviteToClient) {
						return formatNylasIntegratedEmailId(
							userEmailIntegrationObj?.email
						);
					} else {
						return userEmailIntegrationObj?.email;
					}
				}
			} else {
				defaultEmail = process.env.REACT_APP_DEFAULT_EMAIL;
			}
		}

		return defaultEmail;
	};

	const handleRecipientSearchStringChange = (value: string) => {
		setRecipientSearch(value);
	};

	const handleSelect = (_value: string) => {
		setRecipientSearch("");
	};

	const handleEmailValidation = (
		values: string[],
		emailType: EmailRecipientsTypes
	) => {
		try {
			if (!values.length && emailType === EmailRecipientsTypes.TO)
				throw new Error("Please add recipients");
			const allRecipientsIds =
				props.allEmailRecipients?.map(recipient => recipient.id) || [];
			const isAllEmailsValid = values.every(
				email =>
					allRecipientsIds.includes(email) || email.match(emailRegex)
			);
			if (isAllEmailsValid) return Promise.resolve();
			throw new Error(`Invalid email`);
		} catch (err) {
			return Promise.reject(err);
		}
	};

	const handleCollapse = (event: MouseEvent) => {
		if (!props.isFromDocumentsPage) {
			return;
		}
		if (recipientRef.current?.contains(event.target as HTMLElement)) {
			setShouldCollapse(false);
		} else {
			setShouldCollapse(true);
		}
	};

	const getRecipientOptions = (
		selectedRecipients: string[] | undefined
	): IOptionProps[] => {
		const inactiveUserIds = companyUserList
			.filter(user => user.is_disabled)
			.map(user => user.id);
		return (
			props.allEmailRecipients
				?.filter(recipient => {
					const key = recipientSearch.trim().toLowerCase();
					const email = recipient.email?.toLowerCase();
					const id = recipient.id;
					const name = recipient.name?.toLowerCase();

					if (!email) {
						return;
					}

					if (!!key.length) {
						return (
							(email?.includes(key) || name?.includes(key)) &&
							!selectedRecipients?.includes(id)
						);
					}

					return selectedRecipients?.includes(id);
				})
				.map((recipient, index) => ({
					name: `${recipient.name} <${
						recipient.email ?? "No Email"
					}> ${
						inactiveUserIds.includes(recipient.id)
							? "(Inactive User)"
							: ""
					}`,
					value: recipient.id,
					disabled:
						!recipient.email ||
						recipient.email.length === 0 ||
						props.isFromDocumentsPage,
					key: index
				})) ?? []
		);
	};

	const emailPreviewContent = () => {
		return (
			<Spin spinning={isPreviewLoading}>
				<div
					className="preview-email-subject"
					dangerouslySetInnerHTML={{
						__html: otherEntityEmailPreview?.subject || ""
					}}
				/>
				<IFRAME content={otherEntityEmailPreview?.body || ""} />
			</Spin>
		);
	};

	const url = props.isFromDocumentsPage
		? `${process.env.REACT_APP_API_HOST}/v2/files/upload`
		: `${process.env.REACT_APP_API_HOST}/v2/emails/attachments/upload`;
	const isMergeTagsLoading = emailThreadMergeTagVariables.length === 0;
	const isSkeletonLoading =
		process.env.NODE_ENV !== "test"
			? !process.env.REACT_APP_TINY_MCE ||
			  process.env.REACT_APP_TINY_MCE.length === 0
			: false ||
			  !props.emailThreadMessage ||
			  (props.emailThreadMessage &&
					!!!props.isFromDocumentsPage &&
					props.emailThreadMessage.account_id.length === 0);
	const defaultEmailPreference = getDefaultEmailPreference();

	const selectedRecipients: undefined | string[] =
		props.form.getFieldValue("to");
	const selectedCCRecipients: undefined | string[] =
		props.form.getFieldValue("cc");
	const selectedBCCRecipients: undefined | string[] =
		props.form.getFieldValue("bcc");

	const recipientOptions = getRecipientOptions(selectedRecipients);
	const ccRecipientOptions = getRecipientOptions(selectedCCRecipients);
	const bccRecipientOptions = getRecipientOptions(selectedBCCRecipients);

	return (
		<ZDrawer
			visible={props.visible}
			closable={true}
			destroyOnClose={true}
			onClose={onClose}
			className="email-thread-message-drawer zomentum-drawer"
			title={
				<Row>
					<Col span={24}>
						<div className="drawer-title-container">
							<Skeleton
								loading={!props.emailThreadMessage}
								active
								title
								paragraph={false}
							>
								<div className="drawer-title">
									{!props.isNewThread && (
										<Fragment>
											<ZText className="text-bold">
												Reply:{" "}
											</ZText>
											<span>
												{props.emailThreadMessage &&
												props.emailThreadMessage
													?.subject &&
												props.emailThreadMessage
													?.subject.length > 0
													? props.emailThreadMessage
															?.subject
													: "[NO SUBJECT]"}
											</span>
										</Fragment>
									)}
									{props.isNewThread && (
										<Fragment>
											<ZText>Send Email</ZText>
										</Fragment>
									)}
								</div>
							</Skeleton>
						</div>
					</Col>
				</Row>
			}
			data-testid="email-thread-message-drawer"
		>
			<Form
				layout="horizontal"
				labelCol={{ xs: 6, sm: 4, md: 3, lg: 2, xl: 2, xxl: 2 }}
				wrapperCol={{
					xs: 18,
					sm: 20,
					md: 21,
					lg: 22,
					xl: 22,
					xxl: 22
				}}
				onSubmit={onSubmit}
				autoComplete="off"
				className="email-thread-message-drawer-form"
			>
				<div className="drawer-form-container">
					<Skeleton
						loading={isSkeletonLoading}
						active
						paragraph={{ rows: 12 }}
					>
						<Row align="middle">
							<Col span={24}>
								<Form.Item
									label="From"
									labelAlign="left"
									className="recipients-from"
								>
									{props.form.getFieldDecorator("from", {
										rules: [
											{
												message:
													"Please select email id"
											}
										],
										initialValue: defaultEmailPreference
									})(
										<Select<string>
											getPopupContainer={trigger =>
												trigger.parentNode
											}
											size="small"
											data-testid="from"
											dropdownClassName="from-email-select-dropdown"
										>
											{defaultEmailPreference ===
												process.env
													.REACT_APP_DEFAULT_EMAIL && (
												<Select.Option
													key={
														process.env
															.REACT_APP_DEFAULT_EMAIL
													}
													value={
														process.env
															.REACT_APP_DEFAULT_EMAIL ??
														"noreply@zomentum.com"
													}
												>
													{`${userData.name.full} <${process.env.REACT_APP_DEFAULT_EMAIL}>`}
												</Select.Option>
											)}
											{!!props.isFromDocumentsPage
												? dkimConnectedEmails.length >
														0 &&
												  dkimConnectedEmails.map(
														(email, index) => {
															return (
																<Select.Option
																	key={index}
																	value={
																		email.email ??
																		undefined
																	}
																>
																	{`${
																		email.name
																	} <${
																		email.email ??
																		"No Email"
																	}>`}
																</Select.Option>
															);
														}
												  )
												: userEmailIntegrationObj?.email_aliases?.map(
														(alias, index) => {
															return (
																<Select.Option
																	key={index}
																	value={
																		alias.email ??
																		""
																	}
																>
																	{alias.email ??
																		"<No Email>"}
																</Select.Option>
															);
														}
												  )}
											{!props.isFromDocumentsPage &&
												!isSendingMs365InviteToClient &&
												userEmailIntegrationObj?.email && (
													<Select.Option
														value={
															userEmailIntegrationObj.email
														}
													>
														{
															userEmailIntegrationObj.email
														}
													</Select.Option>
												)}
										</Select>
									)}
									<div className="recipients-toggle">
										<ZText
											className={`${
												showCcRecipients
													? "recipients-cc-toggle active"
													: "recipients-cc-toggle"
											}`}
											onClick={toggleCcRecipients}
											data-testid="cc"
										>
											CC
										</ZText>
										<ZText
											className={`${
												!isMobileView ? "ml-3 " : ""
											}${
												showBccRecipients
													? "recipients-bcc-toggle active"
													: "recipients-bcc-toggle"
											}`}
											onClick={toggleBccRecipients}
											data-testid="bcc"
										>
											BCC
										</ZText>
									</div>
								</Form.Item>
								<div ref={recipientRef}>
									<Form.Item
										label="To"
										labelAlign="left"
										className="recipients-to"
									>
										{props.form.getFieldDecorator("to", {
											rules: [
												{
													required: true,
													validator: (_, values) =>
														handleEmailValidation(
															values,
															EmailRecipientsTypes.TO
														)
												}
											],
											initialValue:
												props.defaultRecipients &&
												props.defaultRecipients.length >
													0
													? props.defaultRecipients
													: new Array<string>()
										})(
											<ZSelect
												mode="tags"
												placeholder="Please add recipients"
												tokenSeparators={[",", " "]}
												autoClearSearchValue={true}
												dropdownClassName={`
													${props.isNewThread ? "" : "display-none"} ${
													!recipientOptions?.length &&
													recipientSearch.length === 0
														? "display-none"
														: ""
												}
												`}
												loading={isLoading}
												data-testid="recipients-select"
												maxTagCount={
													props.isFromDocumentsPage
														? shouldCollapse
															? "responsive"
															: undefined
														: "responsive"
												}
												onSearch={
													handleRecipientSearchStringChange
												}
												style={{ width: "98%" }}
												filterOption={(input, option) =>
													option?.children &&
													!props.isFromDocumentsPage
														? option.children
																?.toLowerCase()
																?.includes(
																	input.toLowerCase()
																)
														: false
												}
												onSelect={handleSelect}
												selectOptions={recipientOptions}
											/>
										)}
									</Form.Item>
								</div>
							</Col>
							{showCcRecipients && (
								<Col span={24}>
									<Form.Item
										label="CC"
										labelAlign="left"
										className="recipients-cc"
									>
										{props.form.getFieldDecorator("cc", {
											rules: [
												{
													required: false,
													validator: (_, values) =>
														handleEmailValidation(
															values,
															EmailRecipientsTypes.CC
														)
												}
											]
										})(
											<ZSelect<string[]>
												getPopupContainer={trigger =>
													trigger.parentNode
												}
												mode="tags"
												placeholder="Please add CC recipients"
												tokenSeparators={[",", " "]}
												autoClearSearchValue={true}
												notFoundContent={null}
												loading={isLoading}
												onSearch={
													handleRecipientSearchStringChange
												}
												filterOption={(input, option) =>
													option?.children &&
													!props.isFromDocumentsPage
														? option.children
																?.toLowerCase()
																?.includes(
																	input.toLowerCase()
																)
														: false
												}
												onSelect={handleSelect}
												selectOptions={
													ccRecipientOptions
												}
												data-testid="cc-select"
											/>
										)}
									</Form.Item>
								</Col>
							)}
							{showBccRecipients && (
								<Col span={24}>
									<Form.Item
										label="BCC"
										labelAlign="left"
										className="recipients-bcc"
									>
										{props.form.getFieldDecorator("bcc", {
											rules: [
												{
													required: false,
													validator: (_, values) =>
														handleEmailValidation(
															values,
															EmailRecipientsTypes.BCC
														)
												}
											]
										})(
											<ZSelect<string[]>
												getPopupContainer={trigger =>
													trigger.parentNode
												}
												mode="tags"
												placeholder="Please add BCC recipients"
												tokenSeparators={[",", " "]}
												autoClearSearchValue={true}
												notFoundContent={null}
												loading={isLoading}
												onSearch={
													handleRecipientSearchStringChange
												}
												filterOption={(input, option) =>
													option?.children &&
													!props.isFromDocumentsPage
														? option.children
																?.toLowerCase()
																?.includes(
																	input.toLowerCase()
																)
														: false
												}
												onSelect={handleSelect}
												selectOptions={
													bccRecipientOptions
												}
												data-testid="bcc-select"
											/>
										)}
									</Form.Item>
								</Col>
							)}
							{props.isNewThread && (
								<Col span={24}>
									<div ref={subjectBlockContainerRef}>
										<Form.Item
											label="Subject"
											labelAlign="left"
											className="subject"
										>
											<Skeleton
												loading={
													emailThreadMessageEditorLoading ||
													isLoadingState ||
													isMergeTagsLoading
												}
												active
												title
												paragraph={false}
											>
												{!isMergeTagsLoading && (
													<Editor
														id={`${
															props.isNewThread
																? EmailThreadMessageEditorType.Compose
																: EmailThreadMessageEditorType.Reply
														}-subject`}
														apiKey={
															process.env
																.REACT_APP_TINY_MCE
														}
														init={{
															...TinyMceOptions,
															plugins: [],
															menubar: false,
															inline: true,
															setup: (
																editor: any
															) => {
																editor.on(
																	"keydown",
																	(
																		event: KeyboardEvent
																	) => {
																		if (
																			event.keyCode ===
																			13
																		) {
																			event.preventDefault();
																		}
																	}
																);
															},
															content_style: `
																	.mce-content-body {
																		font-size: 14px;
																		font-family: 'Source Sans Pro';
																	}
																	#email-thread-message-compose-editor-subject p {
																		margin: 0 0 0 0.7rem;
																	}
																	.merge-tag {
																		background: #ffedb4;
																		cursor: auto !important;
																	}
																`,
															init_instance_callback:
																(
																	editor: any
																) => {
																	if (
																		isSendingMs365InviteToClient
																	) {
																		editor.setContent(
																			"Action required - Microsoft 365 Assessment"
																		);
																	} else if (
																		systemSendEmailTemplate
																	) {
																		const convertedSystemTemplateTextToMergeTags =
																			convertTextToMergeTag(
																				emailThreadMergeTagVariables,
																				systemSendEmailTemplate?.subject,
																				true
																			);

																		editor.setContent(
																			updateMergeTagsDisplayName(
																				emailThreadMergeTagVariables,
																				convertedSystemTemplateTextToMergeTags
																			)
																		);
																	} else if (
																		props.defaultSubject
																	) {
																		const convertedTextFromMergeTags: string =
																			props.useTemplate &&
																			props.defaultSubject
																				? convertTextToMergeTag(
																						emailThreadMergeTagVariables,
																						props.defaultSubject
																				  )
																				: props.defaultSubject;

																		editor.setContent(
																			updateMergeTagsDisplayName(
																				emailThreadMergeTagVariables,
																				convertedTextFromMergeTags
																			)
																		);
																	}
																}
														}}
														onEditorChange={
															updateTextFromSubjectTinyMCEEditor
														}
														onInit={
															initializeEmailThreadMessageDrawerEditor
														}
													/>
												)}
											</Skeleton>
										</Form.Item>
									</div>
								</Col>
							)}
						</Row>
						<Skeleton
							loading={
								emailThreadMessageEditorLoading ||
								isLoadingState ||
								isMergeTagsLoading
							}
							active
							paragraph={{ rows: 12 }}
						/>
						<div ref={textBlockContainerRef}>
							{!isMergeTagsLoading && (
								<Editor
									id={
										props.isNewThread
											? EmailThreadMessageEditorType.Compose
											: EmailThreadMessageEditorType.Reply
									}
									apiKey={process.env.REACT_APP_TINY_MCE}
									init={{
										...TinyMceOptions,
										plugins: TinyMcePlugins,
										placeholder: props.bodyPlaceholder,
										content_style: `
									.mce-content-body {
										font-size: 12px;
										font-family: 'Source Sans Pro';
										margin-left: 20px !important;
									}
									.merge-tag {
										background: #ffedb4;
										cursor: auto !important;
									}
								`,
										init_instance_callback: (
											editor: any
										) => {
											if (
												systemSendEmailTemplate &&
												!systemSendEmailTemplate.system_template_info
											) {
												editor.setContent(
													updateMergeTagsDisplayName(
														emailThreadMergeTagVariables,
														systemSendEmailTemplate?.body
													)
												);
											} else if (props.templateBody) {
												editor.setContent(
													updateMergeTagsDisplayName(
														emailThreadMergeTagVariables,
														props.templateBody
													)
												);
											}
											initializeTinyMCEPrePastePrompt(
												editor
											);
										}
									}}
									onEditorChange={
										updateTextFromEmailBodyTinyMCEEditor
									}
									onInit={
										initializeEmailThreadMessageDrawerEditor
									}
								/>
							)}
						</div>

						<ZSection
							className="border-none ml-5 z-section-preview-info"
							shouldApplyLeftPaddingForChildren={false}
							bottomBorder={false}
							first={false}
						>
							<div
								className={`${
									!!currentActiveEmailSignature?.body?.length
										? ""
										: "mb-4"
								} mt-4 font-medium text-gray-7`}
								dangerouslySetInnerHTML={{
									__html:
										currentActiveEmailSignature?.body ?? ""
								}}
							/>
						</ZSection>

						<div className="email-thread-message-files-container border-solid border-0 border-t border-b border-gray-5">
							<Row align="middle">
								<Col span={12}>
									<Upload
										name="file"
										id="file"
										method="POST"
										action={url}
										accept="*"
										headers={{
											Authorization: `Bearer ${
												(
													cache.get(
														ZomentumStorageKeys.Token,
														true
													) as AuthToken
												)?.token
											}`
										}}
										showUploadList={true}
										onChange={uploadEmailThreadFile}
										onRemove={deleteEmailThreadFile}
										beforeUpload={
											beforeUploadEmailThreadFile
										}
										className="email-thread-message-file-upload-container"
										fileList={listOfAttachments}
										data-testid="upload-attachment"
									>
										<div className="email-thread-upload-file-link">
											<ZIcon
												name={EIcons.UPLOADV2}
												size="sm"
											/>
											<ZText
												className="ml-2"
												weight={EFontWeights.MEDIUM}
												color={EColors.BLUE}
												colorVariant={6}
											>
												Upload Attachment
											</ZText>
										</div>
									</Upload>
								</Col>
							</Row>
						</div>
					</Skeleton>
					<div
						style={{
							position: "absolute",
							top: mergeTagDropdownY,
							left: mergeTagDropdownX,
							zIndex: 50
						}}
					>
						<ZMergeTagCascadeDropdown
							shouldAllowAddVariable={false}
							mergeTagSystemVariables={
								emailThreadMergeTagVariables
							}
							mergeTagCustomVariables={[]}
							visible={mergeTagDropdownVisible}
							onSubmit={handleSubmitMergeTagVariable}
							onClose={handleCloseMergeTagDropdown}
							MergeTagListToSkip={MergeTagListToSkip}
							MergeTagChildrenListToSkip={
								MergeTagChildrenListToSkip
							}
						/>
					</div>
				</div>
				<div className="ant-drawer-footer">
					{!isMobileView && (
						<div className="footer-activities">
							<div
								className={`footer-activities-text preview-email ${
									isPreviewEmailModalOpen ? "icon-active" : ""
								}`}
							>
								<ZButton
									type="link"
									onClick={verifyAndOpenPreviewModal}
									iconName={ETwoToneIcons.EYETWOTONE}
								>
									<ZText
										weight={EFontWeights.MEDIUM}
										className="truncate"
									>
										Preview Email
									</ZText>
								</ZButton>
							</div>
							{!props.isDocumentMessage && (
								<TrackEmail
									value={emailTracking}
									isTrackingEnabled={!!emailTracking}
									updateEmailTracking={
										updateEmailTrackingDetails
									}
								/>
							)}
							{!props.isFromEmailTemplatesPage && (
								<ZButton
									type="link"
									className="footer-activities-text templates-button"
									onClick={showImportEmailTemplateModal}
								>
									<ZText
										weight={EFontWeights.MEDIUM}
										colorVariant={6}
										color={EColors.BLUE}
									>
										Templates
									</ZText>
								</ZButton>
							)}
							<EmailSignatureComponent
								updateCurrentActiveEmailSignature={
									handleCurrentActiveEmailSignature
								}
								currentActiveSignature={
									currentActiveEmailSignature
								}
							/>
						</div>
					)}
					<div
						className={`${
							isMobileView ? "flex justify-end w-full mb-2" : ""
						}`}
					>
						<ZButton
							type="default"
							htmlType="button"
							disabled={
								isLoading || emailThreadMessageFileUploading
							}
							onClick={onClose}
							className="margin-right-8px"
						>
							Close
						</ZButton>
						<ZButton
							type="primary"
							htmlType="submit"
							loading={
								isLoading ||
								emailThreadMessageFileUploading ||
								emailThreadMessageEditorLoading ||
								isLoadingState
							}
							iconPlacement="right"
							icon={
								<Player
									className="w-4 h-4 ml-1"
									src={Send}
									autoplay
									loop
								/>
							}
						>
							Send
						</ZButton>
					</div>
				</div>
			</Form>

			<ZModal
				title="Preview Email"
				centered={true}
				visible={isPreviewEmailModalOpen}
				onOk={closePreviewModal}
				onCancel={closePreviewModal}
				wrapClassName="preview-email-modal"
				footer={[
					<ZButton
						key={`preview-close-button`}
						onClick={closePreviewModal}
					>
						Close
					</ZButton>
				]}
				size="lg"
			>
				<Fragment>
					{emailPreviewContent()}
					{!!listOfAttachments.length && (
						<div className="mt-4">
							<ZText weight={EFontWeights.MEDIUM}>
								Uploaded attachments:
							</ZText>
							<ul>
								{listOfAttachments.map((attachment, index) => (
									<li key={`attachment-${index}`}>
										<ZText>{attachment.name}</ZText>
									</li>
								))}
							</ul>
						</div>
					)}
				</Fragment>
			</ZModal>
			<Modal
				wrapClassName="validate-email-modal"
				footer={[null]}
				centered
				closable={false}
				visible={isValidating}
			>
				<Spin tip="Checking for empty merge tags" spinning={true} />
			</Modal>
			<EmptyMergeTagsAlertModal
				visible={isEmptyMergeTagsAlertModalOpen}
				closeModal={() => {
					closeEmptyMergeTagsAlertModal();
				}}
				emptyMergeTags={emptyMergeTagsState}
				isMergeTagMissing={!!emptyMergeTagsState.length}
				showEmptyFields={isEmptyMergeTagsAlertFromSendEmail}
				confirmText={
					isEmptyMergeTagsAlertFromSendEmail
						? "Proceed with Sending Email"
						: "Proceed with Preview Email"
				}
				confirmFromModal={
					isEmptyMergeTagsAlertFromSendEmail
						? shouldReplaceWithBlank =>
								continueToSendEmail(shouldReplaceWithBlank)
						: shouldReplaceWithBlank =>
								continueToPreviewEmail(shouldReplaceWithBlank)
				}
				clientId={props.clientId}
				partnerId={props.partnerId ?? null}
				opportunityId={props.opportunityId ?? null}
			/>
			<ImportEmailTemplateModal
				visible={isImportEmailTemplateModalOpen}
				onSelect={handleSelectEmailTemplate}
				onCancel={closeImportEmailTemplateModal}
			/>
			<Modal
				title="Missing mandatory merge tags"
				closable
				centered
				visible={isMandatoryMergeTagModalOpen}
				onCancel={closeMandatoryMergeTagModal}
				wrapClassName="mandatory-merge-tag-modal"
				footer={[
					<ZButton key={0} onClick={closeMandatoryMergeTagModal}>
						Close
					</ZButton>
				]}
			>
				<div
					style={{
						marginBottom: "15px",
						paddingBottom: "10px"
					}}
				>
					Send Document Email should contain&nbsp;
					<span className="merge-tag mceNonEditable">
						{MERGE_TAG_START}Receiver.Document.Url
						{MERGE_TAG_END}
					</span>
					&nbsp; merge tag.
				</div>
			</Modal>
			<Modal
				title="Address not authorized"
				closable
				centered
				visible={emailExpiredModalVisible}
				onCancel={closeEmailExpiredModal}
				footer={[
					<ZButton
						key={0}
						type="link"
						onClick={() =>
							history.push(ZomentumRoutes.EmailSettings)
						}
					>
						Reauthorize
					</ZButton>,
					<ZButton
						key={1}
						type="primary"
						onClick={handleEmailExpiration}
					>
						Use Default
					</ZButton>
				]}
			>
				{emailExpiredMessage !== "" ? (
					emailExpiredMessage?.split("Bad Request: ")[1]
				) : (
					<ZText>
						The address you are attempting to send the document from
						is no longer authorized. Please reauthorize the email
						address or send via Zomentum’s default email address{" "}
						<ZText weight={EFontWeights.MEDIUM}>
							({process.env.REACT_APP_DEFAULT_EMAIL})
						</ZText>
					</ZText>
				)}
			</Modal>
		</ZDrawer>
	);
};

export default Form.create<Props>({
	name: "email_thread_messages_form"
})(EmailThreadMessageDrawerFuncComp);
