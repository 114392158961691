import {
	applyMiddleware,
	combineReducers,
	compose,
	createStore,
	Middleware
} from "redux";
import ReduxLogger from "redux-logger";
import ReduxThunk from "redux-thunk";
import { cache, ZomentumWindow } from "@zomentum/contracts";
import BulkImportReducer from "./Reducers/Vendor/BulkImport";
// reducers
import LoginReducer from "./Reducers/Login";
import EmailThreadReducer from "./Reducers/Others/EmailThread";
import { GlobalSearchReducer } from "./Reducers/Others/GlobalSearch";
import MergeTagsReducer from "./Reducers/Others/MergeTags";
import NotificationFeedReducer from "./Reducers/Others/NotificationFeed";
import RecentActivityFeedReducer from "./Reducers/Others/RecentActivityFeed";
import SettingsReducer from "./Reducers/Settings";
import UIConfigReducer from "./Reducers/UI";
import UserReducer from "./Reducers/User";
import VendorOpportunityReducer from "./Reducers/Vendor/Opportunity";
import VendorPartnerReducer from "./Reducers/Vendor/Partner";
import VendorUserReducer from "./Reducers/Vendor/User";
import VendorTemplateReducer from "./Reducers/Vendor/Template";
import EmailTemplatesReducer from "./Reducers/Vendor/EmailTemplates";
import VendorTiersReducer from "./Reducers/Vendor/Tiers";
import VendorClientReducer from "./Reducers/Vendor/Client";
import PipelinesReducer from "./Reducers/Vendor/Pipelines";
import CustomFieldsReducer from "./Reducers/CustomFields/index";
import { hydrateFieldsMiddleware } from "@zomentum/utils";

declare let window: ZomentumWindow;

const middleWare: Array<Middleware> = [ReduxThunk, hydrateFieldsMiddleware];
let composeEnhancers = compose;

if (
	process.env.REACT_APP_NODE_ENV !== "production" &&
	process.env.NODE_ENV !== "test"
) {
	if (JSON.parse(process.env.REACT_APP_ENABLE_REDUX_LOGGER || "true")) {
		middleWare.push(ReduxLogger);
	}
	const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
	if (devToolsExtension && typeof devToolsExtension === "function") {
		composeEnhancers = devToolsExtension || compose;
	}
}

export const appliedMiddleware = composeEnhancers(
	applyMiddleware(...middleWare)
);

export const reducers = combineReducers({
	login: LoginReducer,
	settings: SettingsReducer,
	recentActivityFeed: RecentActivityFeedReducer,
	emailThread: EmailThreadReducer,
	notificationFeed: NotificationFeedReducer,
	// bannerNotification: BannerNotificationReducer,
	bulkImport: BulkImportReducer,
	globalSearch: GlobalSearchReducer,
	uiConfig: UIConfigReducer,
	mergeTags: MergeTagsReducer,
	vendorUser: VendorUserReducer,
	vendorOpportunity: VendorOpportunityReducer,
	vendorPartner: VendorPartnerReducer,
	vendorClient: VendorClientReducer,
	user: UserReducer,
	vendorTemplate: VendorTemplateReducer,
	// blocks: BlocksReducer,
	vendorEmailTemplate: EmailTemplatesReducer,
	vendorTiers: VendorTiersReducer,
	vendorPipelines: PipelinesReducer,
	customFields: CustomFieldsReducer
});

const store = createStore(reducers, appliedMiddleware);

store.subscribe(() => {
	const state = store.getState();

	cache.updateReduxKey("userData", state.vendorUser.vendorUserData);
	// cache.updateReduxKey("configurationSettings", state.user.configurationSettings);
	cache.updateReduxKey("companyUserList", state.vendorUser.vendorUserList);
	cache.updateReduxKey("userCompany", state.vendorUser.vendorUserCompany);
});

export default store;
