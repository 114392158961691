import { Dispatch, Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { ZomentumEntities } from "@zomentum/contracts";
import { GlobalState } from "@/Reducers/interface";
import { getMergeTags } from "@/Services/Others/MergeTags";
import { MergeTagVariable } from "@zomentum/contracts/dist/Documents";

export enum MergeTagsActionTypes {
	MERGE_TAGS_REQUESTED = "MERGE_TAGS_REQUESTED",
	MERGE_TAGS_SUCCESSFUL = "MERGE_TAGS_SUCCESSFUL",
	MERGE_TAGS_FAILED = "MERGE_TAGS_FAILED"
}

export interface MergeTagsRequestedAction
	extends Action<MergeTagsActionTypes.MERGE_TAGS_REQUESTED> {
	entityType: ZomentumEntities;
}

export interface MergeTagsSuccessFulAction
	extends Action<MergeTagsActionTypes.MERGE_TAGS_SUCCESSFUL> {
	entityType: ZomentumEntities;
	mergeTags: MergeTagVariable[];
}

export interface MergeTagsFailedAction
	extends Action<MergeTagsActionTypes.MERGE_TAGS_FAILED> {
	entityType: ZomentumEntities;
}

export type MergeTagsAction =
	| MergeTagsRequestedAction
	| MergeTagsSuccessFulAction
	| MergeTagsFailedAction;

export interface MergeTagsDispatch {
	getMergeTags: ActionCreator<
		ThunkAction<
			Promise<MergeTagsSuccessFulAction>,
			GlobalState,
			null,
			MergeTagsSuccessFulAction
		>
	>;
}

export const MergeTagsActionCreators: MergeTagsDispatch = {
	getMergeTags: (entityType: ZomentumEntities) => {
		return async (
			dispatch: Dispatch
		): Promise<MergeTagsSuccessFulAction> => {
			const mergeTagsRequestedAction: MergeTagsRequestedAction = {
				type: MergeTagsActionTypes.MERGE_TAGS_REQUESTED,
				entityType
			};
			dispatch(mergeTagsRequestedAction);
			try {
				const mergeTagsResponse: MergeTagVariable[] =
					await getMergeTags(entityType);

				const mergeTagsSuccessFulAction: MergeTagsSuccessFulAction = {
					type: MergeTagsActionTypes.MERGE_TAGS_SUCCESSFUL,
					entityType,
					mergeTags: mergeTagsResponse
				};
				return dispatch(mergeTagsSuccessFulAction);
			} catch (error) {
				const mergeTagsFailedAction: MergeTagsFailedAction = {
					type: MergeTagsActionTypes.MERGE_TAGS_FAILED,
					entityType
				};
				dispatch(mergeTagsFailedAction);
				return Promise.reject(error);
			}
		};
	}
};
