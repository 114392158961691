import { dropdownPlacements } from "@/Components/Utils";
import ZUserSelect from "@organisms/ZUserSelect";
import { normalizeZipcode } from "@/V2Utils";
import ZTypography from "@zomentum/atoms/dist/ZTypography";
import {
	VendorClient,
	clientCompanyTypeOptions,
	filterSelectByTextInput
} from "@zomentum/contracts";
import {
	EWidgets,
	IFormFieldProps
} from "@zomentum/organisms/dist/ZForm/interface";
import { capitalizeFirstLetter } from "@zomentum/utils/dist/common";
import Divider from "antd/lib/divider";
import React from "react";

export interface ClientFormItemsProps {
	readonly customFieldId?: string | null;
	readonly currentActiveVendorClient?: VendorClient | null;
	readonly isBulkUpdate?: boolean;
	readonly showOtherDetails?: boolean;
	readonly required?: boolean;
	readonly currentActiveUserId: string;
}

export const getClientFormItem = ({
	customFieldId,
	currentActiveVendorClient,
	isBulkUpdate,
	currentActiveUserId,
	required = false,
	showOtherDetails = true
}: ClientFormItemsProps): IFormFieldProps | IFormFieldProps[] | null => {
	const strToCompare = isBulkUpdate
		? `vendor_client_${customFieldId}`
		: customFieldId;

	switch (strToCompare) {
		case "vendor_client_phone": {
			return {
				key: "phone",
				label: "Phone",
				hasFeedback: true,
				initialValue: currentActiveVendorClient?.billing_address?.phone,
				required: isBulkUpdate || required,
				message: "Please enter phone number",
				widget: EWidgets.INPUT,
				widgetProps: {
					width: "full",
					type: "text",
					placeholder: "Please enter phone number"
				}
			};
		}
		case "vendor_client_partner_manager": {
			return {
				key: "partner_manager_id",
				label: "Partner Manager",
				required,
				initialValue: currentActiveVendorClient
					? currentActiveVendorClient?.partner_manager?.id
					: currentActiveUserId,
				widget: ({ value, onChange }) => (
					<ZUserSelect
						showSearch={true}
						allowClear={true}
						placeholder="Please select partner manager"
						filterCurrentUser={false}
						value={value}
						onChange={v => onChange(v)}
					/>
				)
			};
		}
		case "vendor_client_owner_user_id": {
			return {
				key: "owner_user_id",
				label: "Owner",
				required,
				initialValue: currentActiveVendorClient
					? currentActiveVendorClient.owner_user_id
					: currentActiveUserId,
				widget: ({ value, onChange }) => (
					<ZUserSelect
						showSearch={true}
						allowClear={true}
						placeholder="Please select owner"
						filterCurrentUser={false}
						value={value}
						onChange={v => onChange(v)}
					/>
				)
			};
		}
		case "vendor_client_fax": {
			return {
				key: "fax",
				label: "Fax",
				hasFeedback: true,
				required: isBulkUpdate || required,
				message: "Please enter fax number",
				initialValue: currentActiveVendorClient?.fax,
				widget: EWidgets.INPUT,
				widgetProps: {
					width: "full",
					type: "text",
					placeholder: "Please enter fax number"
				}
			};
		}
		case "vendor_client_website": {
			return {
				key: "website",
				label: "Website",
				hasFeedback: true,
				required: isBulkUpdate || required,
				message: "Please enter website",
				initialValue: currentActiveVendorClient?.website,
				widget: EWidgets.INPUT,
				widgetProps: {
					width: "full",
					placeholder: "Please enter website"
				}
			};
		}
		case "vendor_client_linked_in_url": {
			return {
				key: "linked_in_url",
				label: "LinkedIn URL",
				hasFeedback: true,
				required: isBulkUpdate || required,
				message: "Please enter linkedin url",
				initialValue: currentActiveVendorClient?.linked_in_url || "",
				widget: EWidgets.INPUT,
				widgetProps: {
					width: "full",
					placeholder: "Please enter linkedin url"
				}
			};
		}
		case "vendor_client_facebook_url": {
			return {
				key: "facebook_url",
				label: "Facebook URL",
				hasFeedback: true,
				required: isBulkUpdate || required,
				message: "Please enter facebook url",
				initialValue: currentActiveVendorClient?.facebook_url || "",
				widget: EWidgets.INPUT,
				widgetProps: {
					width: "full",
					placeholder: "Please enter facebook url"
				}
			};
		}
		case "vendor_client_twitter_url": {
			return {
				key: "twitter_url",
				label: "Twitter URL",
				hasFeedback: true,
				required: isBulkUpdate || required,
				message: "Please enter twitter url",
				initialValue: currentActiveVendorClient?.twitter_url || "",
				widget: EWidgets.INPUT,
				widgetProps: {
					width: "full",
					placeholder: "Please enter twitter url"
				}
			};
		}
		case "vendor_client_company_types": {
			return {
				key: "company_type",
				label: "Company Type",
				hasFeedback: true,
				required: isBulkUpdate || required,
				message: "Please select company type",
				initialValue:
					currentActiveVendorClient?.company_type ?? undefined,
				widget: EWidgets.SELECT,
				widgetProps: {
					showSearch: true,
					allowClear: false,
					placeholder: "Please select company type",
					filterOption: filterSelectByTextInput,
					selectOptions: clientCompanyTypeOptions,
					dropdownAlign: dropdownPlacements.bottomLeft,
					getPopupContainer: (trigger: { parentNode: any }) =>
						trigger.parentNode,
					"data-testid": "client-company-type"
				}
			};
		}
		case "vendor_client_billing_address": {
			return [
				{
					key: "billing_address",
					wrapperCol: { span: 24 },
					render: () => (
						<React.Fragment key="billing_address">
							<Divider />
							<ZTypography.ZSectionHeading className="block my-4">
								Billing Address
							</ZTypography.ZSectionHeading>
						</React.Fragment>
					)
				},
				...["street", "city", "state", "pincode", "country"].map(
					entity => ({
						key: entity,
						label:
							entity === "pincode"
								? "Zip Code"
								: capitalizeFirstLetter(entity),
						hasFeedback: true,
						required,
						widget: EWidgets.INPUT,
						initialValue: (() => {
							switch (entity) {
								case "country":
									return currentActiveVendorClient
										?.billing_address?.country;
								case "state":
									return currentActiveVendorClient
										?.billing_address?.state;
								case "city":
									return currentActiveVendorClient
										?.billing_address?.city;
								case "street":
									return currentActiveVendorClient
										?.billing_address?.address_line_1;
								case "pincode":
									return currentActiveVendorClient
										?.billing_address?.pincode;
								default:
									return "";
							}
						})(),
						normalize:
							entity === "pincode" ? normalizeZipcode : undefined,
						widgetProps: {
							width: "full",
							placeholder: `Please enter ${
								entity === "pincode"
									? "zip code"
									: `${entity} name`
							}`
						}
					})
				),
				...(showOtherDetails
					? [
							{
								key: "other_details",
								wrapperCol: { span: 24 },
								render: () => (
									<React.Fragment key="other_detail">
										<Divider className="mb-6" />
									</React.Fragment>
								)
							}
					  ]
					: [])
			];
		}
		case "vendor_client_country":
		case "vendor_client_state":
		case "vendor_client_city":
		case "vendor_client_street":
		case "vendor_client_pincode": {
			return {
				key: customFieldId as string,
				label:
					customFieldId === "pincode"
						? "Zip Code"
						: capitalizeFirstLetter(customFieldId),
				hasFeedback: true,
				required: true,
				message: `Please enter ${
					customFieldId === "pincode"
						? "zip code"
						: `${customFieldId} name`
				}`,
				widget: EWidgets.INPUT,
				normalize:
					customFieldId === "pincode" ? normalizeZipcode : undefined,
				widgetProps: {
					width: "full",
					placeholder: `Please enter ${
						customFieldId === "pincode"
							? "zip code"
							: `${customFieldId} name`
					}`
				}
			};
		}
		default:
			return null;
	}
};
