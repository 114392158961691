import ZDrawer from "@zomentum/molecules/dist/ZDrawer";
import React, { FC, ReactNode, ReactText, useEffect, useState } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { ZButton, ZIcon, ZTypography } from "@zomentum/atoms";
import { IAddProductSKUToQuoteBlockDrawerProps } from "./interface";
import { EFontWeights } from "@zomentum/atoms/dist/ZTypography/interface";
import ZTable from "@zomentum/organisms/dist/ZTable";
import { EIcons } from "@zomentum/atoms/dist/ZIcon/interface";
import { ZInput } from "@zomentum/molecules";
import Button from "antd/lib/button";
import {
	AddQuoteProductCatalogDrawerFormValues,
	GlobalSearchEntityType,
	GlobalSearchRequest,
	ListPagePostRequest,
	ProductBillingType,
	QuoteProduct,
	SalesActivityAutomationMetadataFilterOperator,
	SKUProduct,
	SKUProductStatus,
	ZomentumEntities
} from "@zomentum/contracts";
import { getProductSKUsView } from "@/Services/Vendor/SKU";
import { ColumnType } from "antd/lib/table/interface";
import { SKUProductCatalogResultRow } from "../SKUProductCatalogResultRow";
import "./index.less";
import { constructProductSKUIdToPricingMapping } from "@/V2Utils";
import { getGlobalSearch } from "@zomentum/utils/dist/Service/GlobalSearch";
import { useFeatureFlag } from "@zomentum/hooks/dist/hooks/useFeature";
const { ZText } = ZTypography;

const filterCriteria = [
	{
		filter_entity_type: ZomentumEntities.MarketplaceSKU,
		field_name: "status",
		field_display_name: "Status",
		filter_operator: {
			display_value: "Is",
			id_value: SalesActivityAutomationMetadataFilterOperator.Is,
			value_type: null,
			group_type: null
		},
		values: [
			{
				display_value: "Published",
				id_value: "published",
				value_type: null,
				group_type: null
			}
		],
		linked_entity_type: null
	}
];

const AddProductSKUToQuoteBlockDrawer: FC<
	IAddProductSKUToQuoteBlockDrawerProps
> = ({ visible, onSubmit, onClose }) => {
	const useAtlasSearch = useFeatureFlag("use_atlas_search");
	const [loading, setLoading] = useState(false);
	const [productSKUs, setProductSKUs] = useState<SKUProduct[]>([]);
	const [selectedProductSKUs, setSelectedProductSKUs] = useState<
		SKUProduct[]
	>([]);
	const [productVsPricing, setProductVsPricing] = useState<
		Record<string, string | null>
	>({});

	const handleOnClose = () => {
		setSelectedProductSKUs([]);
		onClose();
	};

	const getAllProductSkus = async (skuRequest: ListPagePostRequest) => {
		setLoading(true);
		try {
			const response = await getProductSKUsView(skuRequest);
			setProductSKUs(response.data);
			setProductVsPricing(prevState => ({
				...prevState,
				...constructProductSKUIdToPricingMapping(response.data)
			}));
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const onPricingSelect = (productId: string, selectedPricingId?: string) => {
		setProductVsPricing(prevState => ({
			...prevState,
			[productId]: selectedPricingId ?? null
		}));
	};

	const renderProductColumn = (
		_text: unknown,
		record: SKUProduct
	): ReactNode => {
		return (
			<SKUProductCatalogResultRow
				record={record}
				selectedPricingId={productVsPricing[record?.id]}
				onPricingChange={onPricingSelect}
			/>
		);
	};

	const productColumns: ColumnType<SKUProduct>[] = [
		{
			dataIndex: "name",
			key: "name",
			render: renderProductColumn
		}
	];

	const handleProductRowEvent = (record: SKUProduct) => ({
		onClick: () => {
			setSelectedProductSKUs(prevState =>
				prevState.some(product => product.id === record.id)
					? prevState.filter(product => product.id !== record.id)
					: [...prevState, record]
			);
		}
	});

	const rowSelection = {
		onChange: (
			_selectedProductIdList: ReactText[],
			selectedSKUProductList: SKUProduct[]
		) => setSelectedProductSKUs(selectedSKUProductList),
		selectedRowKeys: selectedProductSKUs.map(product => product.id),
		columnWidth: 20,
		preserveSelectedRowKeys: true
	};

	const handleSubmit = (selectedProductSKUs: SKUProduct[]) => {
		const newSelectedQuoteProducts: QuoteProduct[] = [];
		for (const selectedProduct of selectedProductSKUs) {
			const selectedPricing = selectedProduct.pricings.find(
				pricing => pricing?.id === productVsPricing[selectedProduct.id]
			);

			const quoteProductFormValues =
				AddQuoteProductCatalogDrawerFormValues.fromSKU(selectedProduct);

			quoteProductFormValues.bundled_skus =
				quoteProductFormValues.bundled_skus.map(item => ({
					...item,
					vendor_product_id: `marketplace:${item?.sku_id}`
				}));

			quoteProductFormValues.show_individual_product_pricing =
				selectedProduct.show_individual_pricing || false;
			quoteProductFormValues.selected_pricing_id =
				selectedPricing?.id ?? null;
			quoteProductFormValues.selected_billing_period =
				selectedPricing?.billing_period ?? ProductBillingType.OneTime;
			const costPrice = selectedPricing?.cost_price ?? 0;
			quoteProductFormValues.selected_cost_price = costPrice;
			quoteProductFormValues.selected_setup_price =
				selectedPricing?.setup_price ?? 0;
			quoteProductFormValues.hide_additional_price =
				!quoteProductFormValues?.selected_setup_price;

			const sellPrice = selectedPricing?.sell_price ?? 0;
			quoteProductFormValues.sell_price = sellPrice;
			quoteProductFormValues.sub_total =
				quoteProductFormValues?.sell_price +
				quoteProductFormValues?.selected_setup_price;
			quoteProductFormValues.selected_sell_price =
				selectedPricing?.sell_price ?? 0;
			quoteProductFormValues.vendor_sku_number =
				selectedPricing?.vendor_sku_no || undefined;

			newSelectedQuoteProducts.push({ ...quoteProductFormValues });
		}
		onSubmit(newSelectedQuoteProducts);
		handleOnClose();
	};

	const searchProducts = async (keyword: string) => {
		if (!keyword?.length) return;
		const globalSearchRequest = new GlobalSearchRequest(
			keyword,
			[GlobalSearchEntityType.MarketplaceSKU],
			50,
			null,
			useAtlasSearch
		);
		setLoading(true);
		try {
			const skuSearchResponse = await getGlobalSearch(
				globalSearchRequest
			);

			const publishedSKUs = skuSearchResponse.data
				.map(entity => entity.obj as SKUProduct)
				.filter(
					product => product.status === SKUProductStatus.PUBLISHED
				);

			setProductSKUs(publishedSKUs);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (visible) {
			getAllProductSkus(
				new ListPagePostRequest({
					count_query_flag: true,
					filter_criterion: filterCriteria,
					sort_criterion: [],
					page_no: 1,
					page_size: 25,
					includedChildEntities: []
				})
			);
		}
	}, [visible]);

	const renderTableHeader = (): React.ReactNode => {
		return (
			<Row className="document-product-drawer-product-header bg-gray-1">
				<Col
					span={24}
					className="document-product-drawer-product-button"
				>
					<ZInput
						placeholder="Enter product name"
						inputType="search"
						className="w-full"
						enterButton={
							<Button
								type="primary"
								htmlType="button"
								icon={
									<ZIcon
										name={EIcons.SEARCH}
										size="sm"
										colorVariant={1}
										className="mr-2"
									/>
								}
								loading={loading}
							>
								Search
							</Button>
						}
						prefix={null}
						onSearch={searchProducts}
					/>
				</Col>
			</Row>
		);
	};

	return (
		<ZDrawer
			visible={visible}
			closable={true}
			destroyOnClose={true}
			onClose={handleOnClose}
			removeDefaultPadding
			title={
				<Row>
					<Col span={24}>
						<ZText weight={EFontWeights.MEDIUM} level={2}>
							Search Product
						</ZText>
					</Col>
				</Row>
			}
			footer={
				<div className="flex justify-end">
					<ZButton
						type="default"
						disabled={loading}
						className="mr-4"
						onClick={onClose}
					>
						Cancel
					</ZButton>
					<ZButton
						type="primary"
						disabled={loading || !productSKUs.length}
						onClick={() => handleSubmit(selectedProductSKUs)}
					>
						Add Products
					</ZButton>
				</div>
			}
			className="add-product-sku-quote-drawer"
		>
			<ZTable<SKUProduct>
				title={renderTableHeader}
				showHeader={false}
				loading={loading}
				scroll={{ scrollToFirstRowOnChange: true }}
				size="small"
				rowKey="id"
				pagination={false}
				rowSelection={rowSelection}
				onRow={handleProductRowEvent}
				dataSource={productSKUs}
				columns={productColumns}
			/>
		</ZDrawer>
	);
};

export default AddProductSKUToQuoteBlockDrawer;
