import React from "react";
import { EmailSignature } from "@zomentum/contracts/dist/Users";
import { useSelector } from "react-redux";
import { ZomentumRoutes } from "@zomentum/contracts";
import ZTypography from "@zomentum/atoms/dist/ZTypography";
import ZButton from "@zomentum/atoms/dist/ZButton";
import ZMenu from "@zomentum/molecules/dist/ZMenu";
import ZDropdown from "@zomentum/molecules/dist/ZDropdown";
import { EIcons } from "@zomentum/atoms/dist/ZIcon/interface";
import { EFontWeights } from "@zomentum/atoms/dist/ZTypography/interface";
import { EColors } from "@zomentum/atoms/dist/ZColors/interface";
import { capitalizeFirstLetter } from "@zomentum/utils";
import { GlobalState } from "@/Reducers/interface";

interface Props {
	readonly currentActiveSignature: EmailSignature | null;
	readonly updateCurrentActiveEmailSignature: (
		signature: EmailSignature | null
	) => void;
}

const { ZText, ZLink } = ZTypography;
const EmailSignatureComponent: React.FC<Props> = props => {
	const emailSignatures = useSelector(
		(state: GlobalState) => state.user.emailSignatures
	);

	const handleSelectSignature = (signature: EmailSignature | null) => {
		props.updateCurrentActiveEmailSignature(signature);
	};

	const handleRouteToNewTab = () => {
		window.open(ZomentumRoutes.EmailSettings);
	};

	return (
		<div data-testid="signature-select">
			<ZDropdown
				trigger={["click"]}
				overlay={
					<ZMenu className="menu-item-container py-1">
						{emailSignatures.signatures?.map(
							(signature: EmailSignature, index: number) => (
								<ZMenu.Item
									key={index}
									onClick={() =>
										handleSelectSignature(signature)
									}
								>
									<ZText
										weight={
											props.currentActiveSignature
												?.name === signature?.name
												? EFontWeights.MEDIUM
												: EFontWeights.REGULAR
										}
									>
										{capitalizeFirstLetter(signature.name)}
									</ZText>
								</ZMenu.Item>
							)
						)}
						<ZMenu.Item
							key={emailSignatures.signatures.length + 1}
							onClick={() => handleSelectSignature(null)}
							className="border-0 border-solid border-t border-b border-gray-3"
						>
							<ZText
								weight={
									props.currentActiveSignature === null
										? EFontWeights.MEDIUM
										: EFontWeights.REGULAR
								}
							>
								None
							</ZText>
						</ZMenu.Item>
						<ZMenu.Item
							allowOverflow
							key={emailSignatures.signatures.length + 2}
							onClick={() => handleRouteToNewTab()}
							className="-m-0.5 text-blue-6 mb-1"
							iconName={EIcons.ADD}
						>
							<ZLink>Add Signature</ZLink>
						</ZMenu.Item>
					</ZMenu>
				}
			>
				<div className={`footer-activities-text`}>
					<ZButton
						data-testid="sign"
						type="primary"
						className="border-none bg-gray-1"
					>
						<ZText
							weight={EFontWeights.MEDIUM}
							color={EColors.BLUE}
							colorVariant={6}
						>
							Signature
						</ZText>
					</ZButton>
				</div>
			</ZDropdown>
		</div>
	);
};

export default EmailSignatureComponent;
