import { AxiosError } from "axios";
import { DefaultErrorMessage } from "@zomentum/contracts";

export enum ExceptionType {
	CfAuthorizationException = "cf_authorization_exception",
	InvalidMergeTagTypeException = "invalid_merge_tag_exception",
	UnexpectedDatabaseException = "unexpected_database_exception",
	CompanyNotFoundException = "company_not_found_exception",
	UserNotFoundException = "user_not_found_exception",
	PipelineException = "pipeline_exception",
	BadParamsQueryBuilderException = "bad_params_query_builder_exception",
	UniquenessConstraintException = "uniqueness_constraint_exception",
	DryRunException = "dry_run_exception",
	EntityNotFoundException = "entity_not_found_exception",
	ValidationException = "validation_exception",
	UnsafePutRequestException = "unsafe_put_request_exception",
	BadRequestException = "bad_request_exception",
	InternalServerException = "internal_server_exception",
	AuthSupportException = "auth_support_exception",
	ExpiredTokenException = "expired_Token_exception",
	ReAuthorizeException = "re_authorize_exception",
	AccessException = "access_exception",
	IntegrationException = "integration_exception",
	ServiceUnavailableException = "service_unavailable_exception",
	PdfGenerationException = "pdf_generation_exception",
	InsufficientRuleException = "insufficient_balance",
	ConflictException = "conflict_exception"
}
export interface ChildException {
	statusCode: number;
	description: string;
	exception_type: ExceptionType;
}
export interface ServerValidationErrorResponse {
	description: string;
	message: string;
	code: number;
	stacktrace?: string;
	child_exceptions: ChildException[];
}

export class ServerError extends Error {
	private error: AxiosError<ServerValidationErrorResponse>;
	displayMessage?: string;
	code?: number;

	constructor(error: AxiosError<ServerValidationErrorResponse>) {
		const description =
			error?.response?.data?.description ||
			error?.response?.data?.message;
		const errorMessage: string = !!description?.length
			? description
			: DefaultErrorMessage;

		super(errorMessage);

		this.error = error;
		this.displayMessage = errorMessage;
		this.code = error?.response?.data?.code;
		this.name = "ServerError";

		return this;
	}

	get statusCode(): string | undefined {
		return this.error.code;
	}
}

export class ValidationError extends Error {
	displayMessage?: string;

	constructor(error: string) {
		super(error);

		this.displayMessage = error;
		this.name = "ValidationError";

		return this;
	}
}
