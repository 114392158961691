'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../../../node_modules/tslib/tslib.es6.mjs.js');
var index = require('../../actions/SalesActivity/index.js');
var SalesActivity = require('@zomentum/contracts/dist/Others/SalesActivity');
var contracts = require('@zomentum/contracts');

var initialSalesActivityState = {
  isLoading: false,
  salesActivityActiveTab: null,
  allSalesActivity: new SalesActivity.AggregateSalesActivityResponse(),
  notesSalesActivity: new SalesActivity.AggregateSalesActivityResponse(),
  tasksSalesActivity: new SalesActivity.AggregateSalesActivityResponse(),
  meetingsSalesActivity: new SalesActivity.AggregateSalesActivityResponse(),
  callsSalesActivity: new SalesActivity.AggregateSalesActivityResponse(),
  emailsSalesActivity: new SalesActivity.AggregateSalesActivityResponse(),
  historyActivity: new Array(),
  currentActiveSalesActivity: null,
  currentActiveSalesActivityAutomation: null,
  salesActivityAutomationList: new Array(),
  salesActivityAutomationFiltersList: new Array(),
  addCommentDrawerOpen: false,
  editCommentDrawerOpen: false,
  addTaskDrawerOpen: false,
  editTaskDrawerOpen: false,
  addMeetingDrawerOpen: false,
  editMeetingDrawerOpen: false,
  addLogEmailDrawerOpen: false,
  editLogEmailDrawerOpen: false,
  addLogCallDrawerOpen: false,
  editLogCallDrawerOpen: false,
  upcomingActivitiesDrawerOpen: false,
  auditLogsDrawerOpen: false,
  userAllSalesActivities: new SalesActivity.SalesActivityResponse(),
  opportunities: null,
  opportunity_stages: null,
  clients: null,
  clientContacts: null,
  globalAddTaskDrawerOpen: false,
  globalAddLogEmailDrawerOpen: false,
  globalAddLogCallDrawerOpen: false,
  globalAddMeetingDrawerOpen: false,
  globalAddCommentDrawerOpen: false,
  globalEditTaskDrawerOpen: false,
  globalEditLogEmailDrawerOpen: false,
  globalEditLogCallDrawerOpen: false,
  globalEditMeetingDrawerOpen: false,
  globalEditCommentDrawerOpen: false,
  activeFilters: new Array(),
  salesMergeTags: new Array(),
  currentActiveSalesActivityAutomationActionIndex: 0,
  paymentDrawerOpen: false,
  selectedDocumentType: new Array(),
  viewMetadata: null,
  viewList: null,
  currentActiveView: null,
  isViewChanged: false,
  conflictMeetings: null,
  selectedOwnerView: contracts.SalesActivityUserViews.Owner,
  auditLogsCount: null
};
var SalesActivityReducer = function SalesActivityReducer(state, action) {
  var _a;
  var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
  if (state === void 0) {
    state = initialSalesActivityState;
  }
  switch (action.type) {
    // Sales Activity Get Actions
    case index.SalesActivityActionTypes.SHOW_SALES_ACTIVITY_LOADER:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          isLoading: true
        });
      }
    case index.SalesActivityActionTypes.HIDE_SALES_ACTIVITY_LOADER:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          isLoading: false
        });
      }
    case index.SalesActivityActionTypes.SALES_ACTIVITY_ACTIVE_TAB_CHANGED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          salesActivityActiveTab: action.salesActivityActiveTab
        });
      }
    case index.SalesActivityActionTypes.ALL_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ALL_SALES_ACTIVITY_SUCCESSFUL:
      {
        var allSalesActivity = new SalesActivity.AggregateSalesActivityResponse();
        if (allSalesActivity[SalesActivity.SalesActivitySection.Overdue]) {
          if (action.paginate) {
            allSalesActivity[SalesActivity.SalesActivitySection.Overdue].response = (state.allSalesActivity[SalesActivity.SalesActivitySection.Overdue] ? (_b = state.allSalesActivity[SalesActivity.SalesActivitySection.Overdue]) === null || _b === void 0 ? void 0 : _b.response : new Array()).concat(action.allSalesActivity[SalesActivity.SalesActivitySection.Overdue].response);
          } else {
            allSalesActivity[SalesActivity.SalesActivitySection.Overdue].response = (_c = action.allSalesActivity[SalesActivity.SalesActivitySection.Overdue]) === null || _c === void 0 ? void 0 : _c.response;
          }
          allSalesActivity[SalesActivity.SalesActivitySection.Overdue].next = action.allSalesActivity[SalesActivity.SalesActivitySection.Overdue].next;
        }
        if (allSalesActivity[SalesActivity.SalesActivitySection.Upcoming]) {
          if (action.paginate) {
            allSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response = (state.allSalesActivity[SalesActivity.SalesActivitySection.Upcoming] ? state.allSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response : new Array()).concat(action.allSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response);
          } else {
            allSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response = action.allSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response;
          }
          allSalesActivity[SalesActivity.SalesActivitySection.Upcoming].next = action.allSalesActivity[SalesActivity.SalesActivitySection.Upcoming].next;
        }
        if (allSalesActivity[SalesActivity.SalesActivitySection.Completed]) {
          if (action.paginate) {
            allSalesActivity[SalesActivity.SalesActivitySection.Completed].response = (state.allSalesActivity[SalesActivity.SalesActivitySection.Completed] ? state.allSalesActivity[SalesActivity.SalesActivitySection.Completed].response : new Array()).concat(action.allSalesActivity[SalesActivity.SalesActivitySection.Completed].response);
          } else {
            allSalesActivity[SalesActivity.SalesActivitySection.Completed].response = action.allSalesActivity[SalesActivity.SalesActivitySection.Completed].response;
          }
          allSalesActivity[SalesActivity.SalesActivitySection.Completed].next = action.allSalesActivity[SalesActivity.SalesActivitySection.Completed].next;
        }
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          allSalesActivity: allSalesActivity
        });
      }
    case index.SalesActivityActionTypes.ALL_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ALL_OVERDUE_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ALL_OVERDUE_SALES_ACTIVITY_SUCCESSFUL:
      {
        var allSalesActivity = new SalesActivity.AggregateSalesActivityResponse();
        if (allSalesActivity[SalesActivity.SalesActivitySection.Overdue]) {
          if (action.paginate) {
            allSalesActivity[SalesActivity.SalesActivitySection.Overdue].response = (state.allSalesActivity[SalesActivity.SalesActivitySection.Overdue] ? (_d = state.allSalesActivity[SalesActivity.SalesActivitySection.Overdue]) === null || _d === void 0 ? void 0 : _d.response : new Array()).concat(action.allSalesActivity[SalesActivity.SalesActivitySection.Overdue].response);
          } else {
            allSalesActivity[SalesActivity.SalesActivitySection.Overdue].response = (_e = action.allSalesActivity[SalesActivity.SalesActivitySection.Overdue]) === null || _e === void 0 ? void 0 : _e.response;
          }
          allSalesActivity[SalesActivity.SalesActivitySection.Overdue].next = (_f = action.allSalesActivity[SalesActivity.SalesActivitySection.Overdue]) === null || _f === void 0 ? void 0 : _f.next;
        }
        allSalesActivity[SalesActivity.SalesActivitySection.Upcoming] = state.allSalesActivity[SalesActivity.SalesActivitySection.Upcoming];
        allSalesActivity[SalesActivity.SalesActivitySection.Completed] = state.allSalesActivity[SalesActivity.SalesActivitySection.Completed];
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          allSalesActivity: allSalesActivity
        });
      }
    case index.SalesActivityActionTypes.ALL_OVERDUE_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ALL_UPCOMING_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ALL_UPCOMING_SALES_ACTIVITY_SUCCESSFUL:
      {
        var allSalesActivity = new SalesActivity.AggregateSalesActivityResponse();
        if (allSalesActivity[SalesActivity.SalesActivitySection.Upcoming]) {
          if (action.paginate) {
            allSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response = (state.allSalesActivity[SalesActivity.SalesActivitySection.Upcoming] ? state.allSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response : new Array()).concat(action.allSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response);
          } else {
            allSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response = action.allSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response;
          }
          allSalesActivity[SalesActivity.SalesActivitySection.Upcoming].next = action.allSalesActivity[SalesActivity.SalesActivitySection.Upcoming].next;
        }
        allSalesActivity[SalesActivity.SalesActivitySection.Overdue] = state.allSalesActivity[SalesActivity.SalesActivitySection.Overdue];
        allSalesActivity[SalesActivity.SalesActivitySection.Completed] = state.allSalesActivity[SalesActivity.SalesActivitySection.Completed];
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          allSalesActivity: allSalesActivity
        });
      }
    case index.SalesActivityActionTypes.ALL_UPCOMING_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ALL_COMPLETED_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ALL_COMPLETED_SALES_ACTIVITY_SUCCESSFUL:
      {
        var allSalesActivity = new SalesActivity.AggregateSalesActivityResponse();
        if (allSalesActivity[SalesActivity.SalesActivitySection.Completed]) {
          if (action.paginate) {
            allSalesActivity[SalesActivity.SalesActivitySection.Completed].response = (state.allSalesActivity[SalesActivity.SalesActivitySection.Completed] ? state.allSalesActivity[SalesActivity.SalesActivitySection.Completed].response : new Array()).concat(action.allSalesActivity[SalesActivity.SalesActivitySection.Completed].response);
          } else {
            allSalesActivity[SalesActivity.SalesActivitySection.Completed].response = action.allSalesActivity[SalesActivity.SalesActivitySection.Completed].response;
          }
          allSalesActivity[SalesActivity.SalesActivitySection.Completed].next = action.allSalesActivity[SalesActivity.SalesActivitySection.Completed].next;
        }
        allSalesActivity[SalesActivity.SalesActivitySection.Overdue] = state.allSalesActivity[SalesActivity.SalesActivitySection.Overdue];
        allSalesActivity[SalesActivity.SalesActivitySection.Upcoming] = state.allSalesActivity[SalesActivity.SalesActivitySection.Upcoming];
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          allSalesActivity: allSalesActivity
        });
      }
    case index.SalesActivityActionTypes.ALL_COMPLETED_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ALL_SALES_ACTIVITY_RESET:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          allSalesActivity: new SalesActivity.AggregateSalesActivityResponse()
        });
      }
    case index.SalesActivityActionTypes.NOTES_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.NOTES_SALES_ACTIVITY_SUCCESSFUL:
      {
        var notesSalesActivity = new SalesActivity.AggregateSalesActivityResponse();
        if (notesSalesActivity[SalesActivity.SalesActivitySection.Pinned]) {
          if (action.paginate) {
            notesSalesActivity[SalesActivity.SalesActivitySection.Pinned].response = (state.notesSalesActivity[SalesActivity.SalesActivitySection.Pinned] ? state.notesSalesActivity[SalesActivity.SalesActivitySection.Pinned].response : new Array()).concat(action.notesSalesActivity[SalesActivity.SalesActivitySection.Pinned].response);
          } else {
            notesSalesActivity[SalesActivity.SalesActivitySection.Pinned].response = action.notesSalesActivity[SalesActivity.SalesActivitySection.Pinned].response;
          }
          notesSalesActivity[SalesActivity.SalesActivitySection.Pinned].next = action.notesSalesActivity[SalesActivity.SalesActivitySection.Pinned].next;
        }
        if (notesSalesActivity[SalesActivity.SalesActivitySection.Completed]) {
          if (action.paginate) {
            notesSalesActivity[SalesActivity.SalesActivitySection.Completed].response = (state.notesSalesActivity[SalesActivity.SalesActivitySection.Completed] ? state.notesSalesActivity[SalesActivity.SalesActivitySection.Completed].response : new Array()).concat(action.notesSalesActivity[SalesActivity.SalesActivitySection.Completed].response);
          } else {
            notesSalesActivity[SalesActivity.SalesActivitySection.Completed].response = action.notesSalesActivity[SalesActivity.SalesActivitySection.Completed].response;
          }
          notesSalesActivity[SalesActivity.SalesActivitySection.Completed].next = action.notesSalesActivity[SalesActivity.SalesActivitySection.Completed].next;
        }
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          notesSalesActivity: notesSalesActivity
        });
      }
    case index.SalesActivityActionTypes.NOTES_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.NOTES_PINNED_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.NOTES_PINNED_SALES_ACTIVITY_SUCCESSFUL:
      {
        var notesSalesActivity = new SalesActivity.AggregateSalesActivityResponse();
        if (notesSalesActivity[SalesActivity.SalesActivitySection.Pinned]) {
          if (action.paginate) {
            notesSalesActivity[SalesActivity.SalesActivitySection.Pinned].response = (state.notesSalesActivity[SalesActivity.SalesActivitySection.Pinned] ? state.notesSalesActivity[SalesActivity.SalesActivitySection.Pinned].response : new Array()).concat(action.notesSalesActivity[SalesActivity.SalesActivitySection.Pinned].response);
          } else {
            notesSalesActivity[SalesActivity.SalesActivitySection.Pinned].response = action.notesSalesActivity[SalesActivity.SalesActivitySection.Pinned].response;
          }
          notesSalesActivity[SalesActivity.SalesActivitySection.Pinned].next = action.notesSalesActivity[SalesActivity.SalesActivitySection.Pinned].next;
        }
        notesSalesActivity[SalesActivity.SalesActivitySection.Completed] = state.notesSalesActivity[SalesActivity.SalesActivitySection.Completed];
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          notesSalesActivity: notesSalesActivity
        });
      }
    case index.SalesActivityActionTypes.NOTES_PINNED_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.NOTES_COMPLETED_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.NOTES_COMPLETED_SALES_ACTIVITY_SUCCESSFUL:
      {
        var notesSalesActivity = new SalesActivity.AggregateSalesActivityResponse();
        if (notesSalesActivity[SalesActivity.SalesActivitySection.Completed]) {
          if (action.paginate) {
            notesSalesActivity[SalesActivity.SalesActivitySection.Completed].response = (state.notesSalesActivity[SalesActivity.SalesActivitySection.Completed] ? state.notesSalesActivity[SalesActivity.SalesActivitySection.Completed].response : new Array()).concat(action.notesSalesActivity[SalesActivity.SalesActivitySection.Completed].response);
          } else {
            notesSalesActivity[SalesActivity.SalesActivitySection.Completed].response = action.notesSalesActivity[SalesActivity.SalesActivitySection.Completed].response;
          }
          notesSalesActivity[SalesActivity.SalesActivitySection.Completed].next = action.notesSalesActivity[SalesActivity.SalesActivitySection.Completed].next;
        }
        notesSalesActivity[SalesActivity.SalesActivitySection.Pinned] = state.notesSalesActivity[SalesActivity.SalesActivitySection.Pinned];
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          notesSalesActivity: notesSalesActivity
        });
      }
    case index.SalesActivityActionTypes.NOTES_COMPLETED_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.NOTES_SALES_ACTIVITY_RESET:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          notesSalesActivity: new SalesActivity.AggregateSalesActivityResponse()
        });
      }
    case index.SalesActivityActionTypes.TASKS_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.TASKS_SALES_ACTIVITY_SUCCESSFUL:
      {
        var tasksSalesActivity = new SalesActivity.AggregateSalesActivityResponse();
        if (tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue]) {
          if (action.paginate) {
            tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue].response = (state.tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue] ? state.tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue].response : new Array()).concat(action.tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue].response);
          } else {
            tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue].response = action.tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue].response;
          }
          tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue].next = action.tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue].next;
        }
        if (tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming]) {
          if (action.paginate) {
            tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response = (state.tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming] ? state.tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response : new Array()).concat(action.tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response);
          } else {
            tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response = action.tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response;
          }
          tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming].next = action.tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming].next;
        }
        if (tasksSalesActivity[SalesActivity.SalesActivitySection.Completed]) {
          if (action.paginate) {
            tasksSalesActivity[SalesActivity.SalesActivitySection.Completed].response = (state.tasksSalesActivity[SalesActivity.SalesActivitySection.Completed] ? state.tasksSalesActivity[SalesActivity.SalesActivitySection.Completed].response : new Array()).concat(action.tasksSalesActivity[SalesActivity.SalesActivitySection.Completed].response);
          } else {
            tasksSalesActivity[SalesActivity.SalesActivitySection.Completed].response = action.tasksSalesActivity[SalesActivity.SalesActivitySection.Completed].response;
          }
          tasksSalesActivity[SalesActivity.SalesActivitySection.Completed].next = action.tasksSalesActivity[SalesActivity.SalesActivitySection.Completed].next;
        }
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          tasksSalesActivity: tasksSalesActivity
        });
      }
    case index.SalesActivityActionTypes.TASKS_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.TASKS_OVERDUE_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.TASKS_OVERDUE_SALES_ACTIVITY_SUCCESSFUL:
      {
        var tasksSalesActivity = new SalesActivity.AggregateSalesActivityResponse();
        if (tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue]) {
          if (action.paginate) {
            tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue].response = (state.tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue] ? state.tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue].response : new Array()).concat(action.tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue].response);
          } else {
            tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue].response = action.tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue].response;
          }
          tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue].next = action.tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue].next;
        }
        tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming] = state.tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming];
        tasksSalesActivity[SalesActivity.SalesActivitySection.Completed] = state.tasksSalesActivity[SalesActivity.SalesActivitySection.Completed];
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          tasksSalesActivity: tasksSalesActivity
        });
      }
    case index.SalesActivityActionTypes.TASKS_OVERDUE_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.TASKS_UPCOMING_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.TASKS_UPCOMING_SALES_ACTIVITY_SUCCESSFUL:
      {
        var tasksSalesActivity = new SalesActivity.AggregateSalesActivityResponse();
        if (tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming]) {
          if (action.paginate) {
            tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response = (state.tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming] ? state.tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response : new Array()).concat(action.tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response);
          } else {
            tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response = action.tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response;
          }
          tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming].next = action.tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming].next;
        }
        tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue] = state.tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue];
        tasksSalesActivity[SalesActivity.SalesActivitySection.Completed] = state.tasksSalesActivity[SalesActivity.SalesActivitySection.Completed];
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          tasksSalesActivity: tasksSalesActivity
        });
      }
    case index.SalesActivityActionTypes.TASKS_UPCOMING_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.TASKS_COMPLETED_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.TASKS_COMPLETED_SALES_ACTIVITY_SUCCESSFUL:
      {
        var tasksSalesActivity = new SalesActivity.AggregateSalesActivityResponse();
        if (tasksSalesActivity[SalesActivity.SalesActivitySection.Completed]) {
          if (action.paginate) {
            tasksSalesActivity[SalesActivity.SalesActivitySection.Completed].response = (state.tasksSalesActivity[SalesActivity.SalesActivitySection.Completed] ? state.tasksSalesActivity[SalesActivity.SalesActivitySection.Completed].response : new Array()).concat(action.tasksSalesActivity[SalesActivity.SalesActivitySection.Completed].response);
          } else {
            tasksSalesActivity[SalesActivity.SalesActivitySection.Completed].response = action.tasksSalesActivity[SalesActivity.SalesActivitySection.Completed].response;
          }
          tasksSalesActivity[SalesActivity.SalesActivitySection.Completed].next = action.tasksSalesActivity[SalesActivity.SalesActivitySection.Completed].next;
        }
        tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue] = state.tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue];
        tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming] = state.tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming];
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          tasksSalesActivity: tasksSalesActivity
        });
      }
    case index.SalesActivityActionTypes.TASKS_COMPLETED_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.TASKS_SALES_ACTIVITY_RESET:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          tasksSalesActivity: new SalesActivity.AggregateSalesActivityResponse()
        });
      }
    case index.SalesActivityActionTypes.MEETINGS_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.MEETINGS_SALES_ACTIVITY_SUCCESSFUL:
      {
        var meetingsSalesActivity = new SalesActivity.AggregateSalesActivityResponse();
        if (meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming]) {
          if (action.paginate) {
            meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response = (state.meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming] ? state.meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response : new Array()).concat(action.meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response);
          } else {
            meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response = action.meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response;
          }
          meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming].next = action.meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming].next;
        }
        if (meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed]) {
          if (action.paginate) {
            meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed].response = (state.meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed] ? state.meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed].response : new Array()).concat(action.meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed].response);
          } else {
            meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed].response = action.meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed].response;
          }
          meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed].next = action.meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed].next;
        }
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          meetingsSalesActivity: meetingsSalesActivity
        });
      }
    case index.SalesActivityActionTypes.MEETINGS_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.MEETINGS_UPCOMING_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.MEETINGS_UPCOMING_SALES_ACTIVITY_SUCCESSFUL:
      {
        var meetingsSalesActivity = new SalesActivity.AggregateSalesActivityResponse();
        if (meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming]) {
          if (action.paginate) {
            meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response = (state.meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming] ? state.meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response : new Array()).concat(action.meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response);
          } else {
            meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response = action.meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming].response;
          }
          meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming].next = action.meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming].next;
        }
        meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed] = state.meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed];
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          meetingsSalesActivity: meetingsSalesActivity
        });
      }
    case index.SalesActivityActionTypes.MEETINGS_UPCOMING_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.MEETINGS_COMPLETED_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.MEETINGS_COMPLETED_SALES_ACTIVITY_SUCCESSFUL:
      {
        var meetingsSalesActivity = new SalesActivity.AggregateSalesActivityResponse();
        if (meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed]) {
          if (action.paginate) {
            meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed].response = (state.meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed] ? state.meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed].response : new Array()).concat(action.meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed].response);
          } else {
            meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed].response = action.meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed].response;
          }
          meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed].next = action.meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed].next;
        }
        meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming] = state.meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming];
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          meetingsSalesActivity: meetingsSalesActivity
        });
      }
    case index.SalesActivityActionTypes.MEETINGS_COMPLETED_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.MEETINGS_SALES_ACTIVITY_RESET:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          meetingsSalesActivity: new SalesActivity.AggregateSalesActivityResponse()
        });
      }
    case index.SalesActivityActionTypes.CALLS_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.CALLS_SALES_ACTIVITY_SUCCESSFUL:
      {
        var callsSalesActivity = new SalesActivity.AggregateSalesActivityResponse();
        if (callsSalesActivity[SalesActivity.SalesActivitySection.Completed]) {
          if (action.paginate) {
            callsSalesActivity[SalesActivity.SalesActivitySection.Completed].response = (state.callsSalesActivity[SalesActivity.SalesActivitySection.Completed] ? state.callsSalesActivity[SalesActivity.SalesActivitySection.Completed].response : new Array()).concat(action.callsSalesActivity[SalesActivity.SalesActivitySection.Completed].response);
          } else {
            callsSalesActivity[SalesActivity.SalesActivitySection.Completed].response = action.callsSalesActivity[SalesActivity.SalesActivitySection.Completed].response;
          }
          callsSalesActivity[SalesActivity.SalesActivitySection.Completed].next = action.callsSalesActivity[SalesActivity.SalesActivitySection.Completed].next;
        }
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          callsSalesActivity: callsSalesActivity
        });
      }
    case index.SalesActivityActionTypes.CALLS_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.CALLS_SALES_ACTIVITY_RESET:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          callsSalesActivity: new SalesActivity.AggregateSalesActivityResponse()
        });
      }
    case index.SalesActivityActionTypes.EMAILS_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.EMAILS_SALES_ACTIVITY_SUCCESSFUL:
      {
        var emailsSalesActivity = new SalesActivity.AggregateSalesActivityResponse();
        if (emailsSalesActivity[SalesActivity.SalesActivitySection.Completed]) {
          if (action.paginate) {
            emailsSalesActivity[SalesActivity.SalesActivitySection.Completed].response = (state.emailsSalesActivity[SalesActivity.SalesActivitySection.Completed] ? state.emailsSalesActivity[SalesActivity.SalesActivitySection.Completed].response : new Array()).concat(action.emailsSalesActivity[SalesActivity.SalesActivitySection.Completed].response);
          } else {
            emailsSalesActivity[SalesActivity.SalesActivitySection.Completed].response = action.emailsSalesActivity[SalesActivity.SalesActivitySection.Completed].response;
          }
          emailsSalesActivity[SalesActivity.SalesActivitySection.Completed].next = action.emailsSalesActivity[SalesActivity.SalesActivitySection.Completed].next;
        }
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          emailsSalesActivity: emailsSalesActivity
        });
      }
    case index.SalesActivityActionTypes.EMAILS_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.EMAILS_SALES_ACTIVITY_RESET:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          emailsSalesActivity: new SalesActivity.AggregateSalesActivityResponse()
        });
      }
    case index.SalesActivityActionTypes.UPDATE_CURRENT_ACTIVE_SALES_ACTIVITY:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentActiveSalesActivity: action.currentActiveSalesActivity
        });
      }
    // Sales Activity Delete / Add / Edit Actions
    case index.SalesActivityActionTypes.DELETE_COMMENT_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.DELETE_COMMENT_SUCCESSFUL:
      {
        return state;
      }
    case index.SalesActivityActionTypes.DELETE_COMMENT_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ADD_COMMENT_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ADD_COMMENT_SUCCESSFUL:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ADD_COMMENT_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.EDIT_COMMENT_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.EDIT_COMMENT_SUCCESSFUL:
      {
        return state;
      }
    case index.SalesActivityActionTypes.EDIT_COMMENT_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.DELETE_TASK_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.DELETE_TASK_SUCCESSFUL:
      {
        return state;
      }
    case index.SalesActivityActionTypes.DELETE_TASK_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ADD_TASK_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ADD_TASK_SUCCESSFUL:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ADD_TASK_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.EDIT_TASK_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.EDIT_TASK_SUCCESSFUL:
      {
        return state;
      }
    case index.SalesActivityActionTypes.EDIT_TASK_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.DELETE_MEETING_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.DELETE_MEETING_SUCCESSFUL:
      {
        return state;
      }
    case index.SalesActivityActionTypes.DELETE_MEETING_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ADD_MEETING_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ADD_MEETING_SUCCESSFUL:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ADD_MEETING_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.EDIT_MEETING_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.EDIT_MEETING_SUCCESSFUL:
      {
        return state;
      }
    case index.SalesActivityActionTypes.EDIT_MEETING_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.DELETE_LOG_CALL_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.DELETE_LOG_CALL_SUCCESSFUL:
      {
        return state;
      }
    case index.SalesActivityActionTypes.DELETE_LOG_CALL_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ADD_LOG_CALL_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ADD_LOG_CALL_SUCCESSFUL:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ADD_LOG_CALL_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.EDIT_LOG_CALL_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.EDIT_LOG_CALL_SUCCESSFUL:
      {
        return state;
      }
    case index.SalesActivityActionTypes.EDIT_LOG_CALL_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.DELETE_LOG_EMAIL_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.DELETE_LOG_EMAIL_SUCCESSFUL:
      {
        return state;
      }
    case index.SalesActivityActionTypes.DELETE_LOG_EMAIL_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ADD_LOG_EMAIL_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ADD_LOG_EMAIL_SUCCESSFUL:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ADD_LOG_EMAIL_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.EDIT_LOG_EMAIL_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.EDIT_LOG_EMAIL_SUCCESSFUL:
      {
        return state;
      }
    case index.SalesActivityActionTypes.EDIT_LOG_EMAIL_FAILED:
      {
        return state;
      }
    // Sales Activity Automation Actions
    case index.SalesActivityActionTypes.SALES_ACTIVITY_AUTOMATIONS_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.SALES_ACTIVITY_AUTOMATIONS_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          salesActivityAutomationList: action.salesActivityAutomationList
        });
      }
    case index.SalesActivityActionTypes.SALES_ACTIVITY_AUTOMATIONS_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.SALES_ACTIVITY_AUTOMATION_METADATA_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.SALES_ACTIVITY_AUTOMATION_METADATA_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          salesActivityAutomationFiltersList: action.salesActivityAutomationFiltersList,
          opportunity_stages: action.opportunity_stages,
          salesMergeTags: action.salesActivityAutomationMergeTags
        });
      }
    case index.SalesActivityActionTypes.SALES_ACTIVITY_AUTOMATION_METADATA_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.SALES_ACTIVITY_AUTOMATION_DETAILS_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.SALES_ACTIVITY_AUTOMATION_DETAILS_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentActiveSalesActivityAutomation: action.currentActiveSalesActivityAutomation
        });
      }
    case index.SalesActivityActionTypes.SALES_ACTIVITY_AUTOMATION_DETAILS_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.SALES_ACTIVITY_AUTOMATION_DETAILS_RESET:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentActiveSalesActivityAutomation: null
        });
      }
    case index.SalesActivityActionTypes.REORDER_SALES_ACTIVITY_AUTOMATION_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.REORDER_SALES_ACTIVITY_AUTOMATION_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          salesActivityAutomationList: action.salesActivityAutomationList
        });
      }
    case index.SalesActivityActionTypes.REORDER_SALES_ACTIVITY_AUTOMATION_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.RESET_SALES_ACTIVITY_AUTOMATIONS:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          salesActivityAutomationList: new Array()
        });
      }
    case index.SalesActivityActionTypes.DELETE_SALES_ACTIVITY_AUTOMATION_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.DELETE_SALES_ACTIVITY_AUTOMATION_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          salesActivityAutomationList: state.salesActivityAutomationList.filter(function (salesActivityAutomation) {
            return salesActivityAutomation.id !== action.deleteSalesActivityAutomation.id;
          })
        });
      }
    case index.SalesActivityActionTypes.DELETE_SALES_ACTIVITY_AUTOMATION_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ADD_SALES_ACTIVITY_AUTOMATION_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.ADD_SALES_ACTIVITY_AUTOMATION_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          salesActivityAutomationList: [action.addSalesActivityAutomation].concat(state.salesActivityAutomationList),
          currentActiveSalesActivityAutomation: state.currentActiveSalesActivityAutomation ? action.addSalesActivityAutomation : null
        });
      }
    case index.SalesActivityActionTypes.ADD_SALES_ACTIVITY_AUTOMATION_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.EDIT_SALES_ACTIVITY_AUTOMATION_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.EDIT_SALES_ACTIVITY_AUTOMATION_SUCCESSFUL:
      {
        var salesActivityAutomationList = state.salesActivityAutomationList.filter(function (salesActivityAutomation) {
          return salesActivityAutomation.id !== action.editSalesActivityAutomation.id;
        });
        var updateSalesActivityAutomationIndex = state.salesActivityAutomationList.findIndex(function (salesActivityAutomation) {
          return salesActivityAutomation.id === action.editSalesActivityAutomation.id;
        });
        if (updateSalesActivityAutomationIndex > -1) {
          salesActivityAutomationList.splice(updateSalesActivityAutomationIndex, 0, action.editSalesActivityAutomation);
        }
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          salesActivityAutomationList: salesActivityAutomationList,
          currentActiveSalesActivityAutomation: state.currentActiveSalesActivityAutomation ? action.editSalesActivityAutomation : null
        });
      }
    case index.SalesActivityActionTypes.EDIT_SALES_ACTIVITY_AUTOMATION_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.UPDATE_CURRENT_ACTIVE_SALES_ACTIVITY_AUTOMATION:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentActiveSalesActivityAutomation: action.currentActiveSalesActivityAutomation
        });
      }
    case index.SalesActivityActionTypes.HISTORY_ACTIVITIES_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.HISTORY_ACTIVITIES_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          historyActivity: tslib_es6.__spreadArray([], state.historyActivity, true).concat(action.historyResponse)
        });
      }
    case index.SalesActivityActionTypes.HISTORY_ACTIVITIES_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.HISTORY_ACTIVITIES_RESET:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          historyActivity: new Array()
        });
      }
    case index.SalesActivityActionTypes.GET_ONE_MEETING_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.GET_ONE_MEETING_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentActiveSalesActivity: action.salesActivity
        });
      }
    case index.SalesActivityActionTypes.GET_ONE_MEETING_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.GET_ONE_TASK_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.GET_ONE_TASK_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentActiveSalesActivity: action.salesActivity
        });
      }
    case index.SalesActivityActionTypes.GET_ONE_TASK_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.GET_ONE_NOTE_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.GET_ONE_NOTE_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentActiveSalesActivity: action.salesActivity
        });
      }
    case index.SalesActivityActionTypes.GET_ONE_NOTE_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.GET_ONE_LOG_CALL_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.GET_ONE_LOG_CALL_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentActiveSalesActivity: action.salesActivity
        });
      }
    case index.SalesActivityActionTypes.GET_ONE_LOG_CALL_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.GET_ONE_LOG_EMAIL_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.GET_ONE_LOG_EMAIL_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentActiveSalesActivity: action.salesActivity
        });
      }
    case index.SalesActivityActionTypes.GET_ONE_LOG_EMAIL_FAILED:
      {
        return state;
      }
    // Sales Activity Drawer Actions
    case index.SalesActivityActionTypes.ADD_COMMENT_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          addCommentDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.ADD_COMMENT_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          addCommentDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.EDIT_COMMENT_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          editCommentDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.EDIT_COMMENT_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          editCommentDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.ADD_TASK_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          addTaskDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.ADD_TASK_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          addTaskDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.EDIT_TASK_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          editTaskDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.EDIT_TASK_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          editTaskDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.ADD_MEETING_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          addMeetingDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.ADD_MEETING_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          addMeetingDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.EDIT_MEETING_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          editMeetingDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.EDIT_MEETING_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          editMeetingDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.ADD_LOG_EMAIL_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          addLogEmailDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.ADD_LOG_EMAIL_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          addLogEmailDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.EDIT_LOG_EMAIL_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          editLogEmailDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.EDIT_LOG_EMAIL_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          editLogEmailDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.ADD_LOG_CALL_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          addLogCallDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.ADD_LOG_CALL_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          addLogCallDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.EDIT_LOG_CALL_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          editLogCallDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.EDIT_LOG_CALL_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          editLogCallDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.UPCOMING_ACTIVITIES_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          upcomingActivitiesDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.UPCOMING_ACTIVITIES_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          upcomingActivitiesDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.AUDIT_LOGS_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          auditLogsDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.AUDIT_LOGS_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          auditLogsDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.FETCH_OPPORTUNITIES_FOR_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.FETCH_OPPORTUNITIES_FOR_SALES_ACTIVITY_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          opportunities: action.opportunities
        });
      }
    case index.SalesActivityActionTypes.FETCH_OPPORTUNITIES_FOR_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.FETCH_CLIENTS_FOR_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.FETCH_CLIENTS_FOR_SALES_ACTIVITY_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          clients: action.clients
        });
      }
    case index.SalesActivityActionTypes.FETCH_CLIENTS_FOR_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.FETCH_CLIENT_CONTACTS_FOR_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.FETCH_CLIENT_CONTACTS_FOR_SALES_ACTIVITY_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          clientContacts: action.clientContacts
        });
      }
    case index.SalesActivityActionTypes.FETCH_CLIENT_CONTACTS_FOR_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.RESET_SALES_ACTIVITY_OPPORTUNITIES:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          opportunities: null
        });
      }
    case index.SalesActivityActionTypes.RESET_SALES_ACTIVITY_CLIENTS:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          clients: null
        });
      }
    case index.SalesActivityActionTypes.RESET_SALES_ACTIVITY_CLIENT_CONTACTS:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          clientContacts: null
        });
      }
    case index.SalesActivityActionTypes.FETCH_USERS_ALL_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.FETCH_USERS_ALL_SALES_ACTIVITY_SUCCESSFULLY:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          userAllSalesActivities: action.salesActivities
        });
      }
    case index.SalesActivityActionTypes.FETCH_USERS_ALL_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.RESET_USERS_ALL_SALES_ACTIVITY:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          userAllSalesActivities: new SalesActivity.SalesActivityResponse()
        });
      }
    case index.SalesActivityActionTypes.ADD_GLOBAL_COMMENT_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          globalAddCommentDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.ADD_GLOBAL_COMMENT_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          globalAddCommentDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.ADD_GLOBAL_TASK_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          globalAddTaskDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.ADD_GLOBAL_TASK_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          globalAddTaskDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.ADD_GLOBAL_MEETING_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          globalAddMeetingDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.ADD_GLOBAL_MEETING_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          globalAddMeetingDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.ADD_GLOBAL_LOG_CALL_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          globalAddLogCallDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.ADD_GLOBAL_LOG_CALL_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          globalAddLogCallDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.ADD_GLOBAL_LOG_EMAIL_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          globalAddLogEmailDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.ADD_GLOBAL_LOG_EMAIL_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          globalAddLogEmailDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.EDIT_GLOBAL_COMMENT_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          globalEditCommentDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.EDIT_GLOBAL_COMMENT_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          globalEditCommentDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.EDIT_GLOBAL_TASK_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          globalEditTaskDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.EDIT_GLOBAL_TASK_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          globalEditTaskDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.EDIT_GLOBAL_MEETING_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          globalEditMeetingDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.EDIT_GLOBAL_MEETING_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          globalEditMeetingDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.EDIT_GLOBAL_LOG_CALL_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          globalEditLogCallDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.EDIT_GLOBAL_LOG_CALL_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          globalEditLogCallDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.EDIT_GLOBAL_LOG_EMAIL_DRAWER_OPENED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          globalEditLogEmailDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.EDIT_GLOBAL_LOG_EMAIL_DRAWER_CLOSED:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          globalEditLogEmailDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.UPDATE_ACTIVE_FILTERS:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          activeFilters: action.activeFilters
        });
      }
    case index.SalesActivityActionTypes.ADD_ACTIVITY_TO_USERS_SALES_ACTIVITY:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          userAllSalesActivities: tslib_es6.__assign(tslib_es6.__assign({}, state.userAllSalesActivities), {
            response: [action.salesActivity].concat(state.userAllSalesActivities.response)
          })
        });
      }
    case index.SalesActivityActionTypes.UPDATE_SINGLE_USERS_SALES_ACTIVITY:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          userAllSalesActivities: tslib_es6.__assign(tslib_es6.__assign({}, state.userAllSalesActivities), {
            response: state.userAllSalesActivities.response.map(function (activity) {
              if (activity.id === action.salesActivity.id) {
                return action.salesActivity;
              }
              return activity;
            })
          })
        });
      }
    case index.SalesActivityActionTypes.GET_ONE_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.GET_ONE_SALES_ACTIVITY_SUCCESSFUL:
      {
        return state;
      }
    case index.SalesActivityActionTypes.GET_ONE_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.DELETE_ONE_SALES_ACTIVITY_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.DELETE_ONE_SALES_ACTIVITY_SUCCESSFULLY:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          userAllSalesActivities: tslib_es6.__assign(tslib_es6.__assign({}, state.userAllSalesActivities), {
            response: state.userAllSalesActivities.response.filter(function (activity) {
              return activity.id !== action.salesActivityId;
            })
          })
        });
      }
    case index.SalesActivityActionTypes.DELETE_ONE_SALES_ACTIVITY_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.FETCH_ALL_PSA_AUDIT_LOGS_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.FETCH_ALL_PSA_AUDIT_LOGS_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          historyActivity: action.auditLogs,
          auditLogsCount: action.count
        });
      }
    case index.SalesActivityActionTypes.FETCH_ALL_PSA_AUDIT_LOGS_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.RETRY_FAILED_AUDIT_LOG_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.RETRY_FAILED_AUDIT_LOG_SUCCESFUL:
      {
        return state;
      }
    case index.SalesActivityActionTypes.RETRY_FAILED_AUDIT_LOG_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.UPDATE_CURRENT_ACTIVE_SALES_ACTIVITY_AUTOMATION_ACTION_INDEX:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentActiveSalesActivityAutomationActionIndex: action.index
        });
      }
    case index.SalesActivityActionTypes.SHOW_PAYMENT_DRAWER:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          paymentDrawerOpen: true
        });
      }
    case index.SalesActivityActionTypes.HIDE_PAYMENT_DRAWER:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          paymentDrawerOpen: false
        });
      }
    case index.SalesActivityActionTypes.UPDATE_SELECTED_DOCUMENT_TYPE:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          selectedDocumentType: tslib_es6.__assign(tslib_es6.__assign({}, state.selectedDocumentType), (_a = {}, _a[state.currentActiveSalesActivityAutomationActionIndex] = action.selectedDocumentType, _a))
        });
      }
    case index.SalesActivityActionTypes.FETCH_SALES_ACTIVITY_AND_AUTOMATION_VIEW_METADATA_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          viewMetadata: action.metadata
        });
      }
    case index.SalesActivityActionTypes.FETCH_SALES_ACTIVITY_AND_AUTOMATION_VIEWS_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          viewList: action.viewsList
        });
      }
    case index.SalesActivityActionTypes.UPDATE_SALES_ACTIVITY_AND_AUTOMATION_CURRENT_ACTIVE_VIEW:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentActiveView: action.currentActiveView,
          isViewChanged: action.isViewChanged
        });
      }
    case index.SalesActivityActionTypes.CREATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.CREATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentActiveView: action.view,
          viewList: state.viewList ? tslib_es6.__assign(tslib_es6.__assign({}, state.viewList), {
            data: tslib_es6.__spreadArray(tslib_es6.__spreadArray([], (_h = (_g = state.viewList) === null || _g === void 0 ? void 0 : _g.data) !== null && _h !== void 0 ? _h : [], true), [action.view], false)
          }) : state.viewList,
          isViewChanged: false
        });
      }
    case index.SalesActivityActionTypes.CREATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.UPDATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.UPDATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentActiveView: action.view,
          viewList: state.viewList ? tslib_es6.__assign(tslib_es6.__assign({}, state.viewList), {
            data: (_k = (_j = state.viewList) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.map(function (view) {
              return view.id === action.view.id ? action.view : view;
            })
          }) : state.viewList,
          isViewChanged: false
        });
      }
    case index.SalesActivityActionTypes.UPDATE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.DELETE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_REQUESTED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.DELETE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentActiveView: action.initialView,
          viewList: state.viewList ? tslib_es6.__assign(tslib_es6.__assign({}, state.viewList), {
            data: (_m = (_l = state.viewList) === null || _l === void 0 ? void 0 : _l.data) === null || _m === void 0 ? void 0 : _m.filter(function (view) {
              return view.id !== action.deletedView.id;
            })
          }) : state.viewList,
          isViewChanged: false
        });
      }
    case index.SalesActivityActionTypes.DELETE_SALES_ACTIVITY_AND_AUTOMATION_VIEW_FAILED:
      {
        return state;
      }
    case index.SalesActivityActionTypes.FETCH_SALES_ACTIVITY_AND_AUTOMATION_LIST_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          salesActivityAutomationList: action.salesAutomationList.data
        });
      }
    case index.SalesActivityActionTypes.GET_CONFLICT_MEETING_DETAIL_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          conflictMeetings: action.conflictMeetings
        });
      }
    case index.SalesActivityActionTypes.UPDATE_SELECTED_OWNER:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          selectedOwnerView: action.selectedOwnerView
        });
      }
    case index.SalesActivityActionTypes.UPDATE_AUDIT_LOGS_COUNT:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          auditLogsCount: action.auditLogsCount
        });
      }
    default:
      {
        return state;
      }
  }
};
var SalesActivityReducer$1 = SalesActivityReducer;

exports.SalesActivityReducer = SalesActivityReducer;
exports["default"] = SalesActivityReducer$1;
exports.initialSalesActivityState = initialSalesActivityState;
