import axios, { AxiosResponse } from "axios";

import {
	getRequest,
	postRequest,
	putRequest,
	deleteRequest
} from "@zomentum/utils/dist/Service/BackendService";

import { ZomentumEntityRequest, DeletedRecord } from "@zomentum/contracts";
import { getQueryParamAuthDetails } from "@zomentum/contracts/dist/Utils";
import {
	RecentActivityFeed,
	AddRecentActivityFeedCommentRequest,
	Comment,
	CommentsResponse,
	ActivitiesResponse,
	RecentActivityFeedResponse
} from "@zomentum/contracts/dist/Others/RecentActivityFeed";

const recentActivityFeedUrl =
	"/v2/feeds?entity_id={entity_id}&entity_type={entity_type}";
const getActivityListUrl =
	"/v2/activities?entity_id={entity_id}&entity_type={entity_type}";
const getCommentUrl =
	"/v2/comments?entity_id={entity_id}&entity_type={entity_type}";
const editCommentUrl = "/v2/comments/{comment_id}";
const deleteCommentUrl = "/v2/comments/{comment_id}";
const addCommentUrl = "/v2/comments";

export const getRecentActivityFeed = async (
	zomentumEntityRequest: ZomentumEntityRequest
): Promise<RecentActivityFeedResponse> => {
	try {
		const response: AxiosResponse<RecentActivityFeedResponse> = await axios(
			getRequest(
				recentActivityFeedUrl
					.replace("{entity_type}", zomentumEntityRequest.entityType)
					.replace("{entity_id}", zomentumEntityRequest.entityId),
				null,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getActivities = async (
	zomentumEntityRequest: ZomentumEntityRequest
): Promise<ActivitiesResponse> => {
	try {
		const response: AxiosResponse<ActivitiesResponse> = await axios(
			getRequest(
				getActivityListUrl
					.replace("{entity_type}", zomentumEntityRequest.entityType)
					.replace("{entity_id}", zomentumEntityRequest.entityId),
				null,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getComments = async (
	zomentumEntityRequest: ZomentumEntityRequest
): Promise<CommentsResponse> => {
	try {
		const response: AxiosResponse<CommentsResponse> = await axios(
			getRequest(
				getCommentUrl
					.replace("{entity_type}", zomentumEntityRequest.entityType)
					.replace("{entity_id}", zomentumEntityRequest.entityId),
				null,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getCommentsFromClient = async (
	zomentumEntityRequest: ZomentumEntityRequest
): Promise<CommentsResponse> => {
	try {
		const queryParamAuthDetails = getQueryParamAuthDetails();
		if (!queryParamAuthDetails) {
			throw new Error("This is not a valid url");
		}

		const response: AxiosResponse<CommentsResponse> = await axios(
			getRequest(
				getCommentUrl
					.replace("{entity_type}", zomentumEntityRequest.entityType)
					.replace("{entity_id}", zomentumEntityRequest.entityId) +
					"&" +
					queryParamAuthDetails.queryParam,
				null,
				false,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const editComment = async (
	comment: Comment
): Promise<RecentActivityFeed> => {
	try {
		const response: AxiosResponse<RecentActivityFeed> = await axios(
			putRequest(
				editCommentUrl.replace("{comment_id}", comment.id),
				comment,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteComment = async (
	commentId: string
): Promise<DeletedRecord> => {
	try {
		const response: AxiosResponse<DeletedRecord> = await axios(
			deleteRequest(
				deleteCommentUrl.replace("{comment_id}", commentId),
				null,
				true,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const addComment = async (
	addCommentRequest: AddRecentActivityFeedCommentRequest
): Promise<RecentActivityFeed> => {
	try {
		const response: AxiosResponse<RecentActivityFeed> = await axios(
			postRequest(addCommentUrl, addCommentRequest, true, false, false)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const addCommentFromClient = async (
	addCommentRequest: AddRecentActivityFeedCommentRequest
): Promise<RecentActivityFeedResponse> => {
	try {
		const queryParamAuthDetails = getQueryParamAuthDetails();
		if (!queryParamAuthDetails) {
			throw new Error("This is not a valid url");
		}

		const response: AxiosResponse<RecentActivityFeedResponse> = await axios(
			postRequest(
				`${addCommentUrl}?${queryParamAuthDetails.queryParam}`,
				addCommentRequest,
				false,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
