import { cache } from "@zomentum/contracts";
import { GlobalState } from "@/Reducers/interface";
import { ZomentumStorageKeys } from "@zomentum/contracts";
import {
	VendorEmailAliasItem,
	EmailAliasRequest
} from "@zomentum/contracts/dist/Users";
import { ChildEntityHydrationParams } from "@zomentum/contracts/dist/Utils";
import {
	AddVendorUserRequest,
	AddVendorAPIUserRequest,
	GetVendorUserCompanyRequest,
	VendorUserCompany,
	VendorUserData,
	VendorUserIntegration,
	VendorUserRole
} from "@zomentum/contracts/dist/Vendor/User";
import {
	addVendorUser,
	addVendorAPIUser,
	addVendorUserEmailAlias,
	deleteVendorUserEmailAlias,
	disableVendorUser,
	editVendorUser,
	enableVendorUser,
	getVendorConfigurationSettings,
	getVendorUserCompany,
	getVendorUserData,
	getVendorUserIntegration,
	getVendorUserList,
	getAllVendorUserViews
} from "@/Services/Vendor/User";
import { Action, ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { NotificationConfiguration } from "@zomentum/contracts";
import { CustomViewResponse, CustomView } from "@zomentum/contracts/dist/UI";
import difference from "lodash/difference";

export enum VendorUsersActionTypes {
	SHOW_VENDOR_USER_LOADER = "SHOW_VENDOR_USER_LOADER",
	HIDE_VENDOR_USER_LOADER = "HIDE_VENDOR_USER_LOADER",
	VENDOR_USER_DATA_REQUESTED = "VENDOR_USER_DATA_REQUESTED",
	VENDOR_USER_DATA_SUCCESSFUL = "VENDOR_USER_DATA_SUCCESSFUL",
	VENDOR_USER_DATA_FAILED = "VENDOR_USER_DATA_FAILED",
	VENDOR_USER_COMPANY_REQUESTED = "VENDOR_USER_COMPANY_REQUESTED",
	VENDOR_USER_COMPANY_SUCCESSFUL = "VENDOR_USER_COMPANY_SUCCESSFUL",
	VENDOR_USER_COMPANY_FAILED = "VENDOR_USER_COMPANY_FAILED",
	VENDOR_USER_INTEGRATION_REQUESTED = "VENDOR_USER_INTEGRATION_REQUESTED",
	VENDOR_USER_INTEGRATION_SUCCESSFUL = "VENDOR_USER_INTEGRATION_SUCCESSFUL",
	VENDOR_USER_INTEGRATION_FAILED = "VENDOR_USER_INTEGRATION_FAILED",
	ADD_VENDOR_USER_EMAIL_ALIAS_REQUESTED = "ADD_VENDOR_USER_EMAIL_ALIAS_REQUESTED",
	ADD_VENDOR_USER_EMAIL_ALIAS_SUCCESSFUL = "ADD_VENDOR_USER_EMAIL_ALIAS_SUCCESSFUL",
	ADD_VENDOR_USER_EMAIL_ALIAS_FAILED = "ADD_VENDOR_USER_EMAIL_ALIAS_FAILED",
	DELETE_VENDOR_USER_EMAIL_ALIAS_REQUESTED = "DELETE_VENDOR_USER_EMAIL_ALIAS_REQUESTED",
	DELETE_VENDOR_USER_EMAIL_ALIAS_SUCCESSFUL = "DELETE_VENDOR_USER_EMAIL_ALIAS_SUCCESSFUL",
	DELETE_VENDOR_USER_EMAIL_ALIAS_FAILED = "DELETE_VENDOR_USER_EMAIL_ALIAS_FAILED",
	RESET_VENDOR_USER_STATE = "RESET_VENDOR_USER_STATE",
	VENDOR_USER_LIST_REQUESTED = "VENDOR_USER_LIST_REQUESTED",
	VENDOR_USER_LIST_SUCCESSFUL = "VENDOR_USER_LIST_SUCCESSFUL",
	VENDOR_USER_LIST_FAILED = "VENDOR_USER_LIST_FAILED",
	ADD_VENDOR_USER_DRAWER_OPENED = "ADD_VENDOR_USER_DRAWER_OPENED",
	ADD_VENDOR_USER_DRAWER_CLOSED = "ADD_VENDOR_USER_DRAWER_CLOSED",
	EDIT_COMPANY_VENDOR_DRAWER_OPENED = "EDIT_COMPANY_VENDOR_DRAWER_OPENED",
	EDIT_COMPANY_VENDOR_DRAWER_CLOSED = "EDIT_COMPANY_VENDOR_DRAWER_CLOSED",
	DISABLE_VENDOR_USER_REQUESTED = "DISABLE_VENDOR_USER_REQUESTED",
	DISABLE_VENDOR_USER_SUCCESSFUL = "DISABLE_VENDOR_USER_SUCCESSFUL",
	DISABLE_VENDOR_USER_FAILED = "DISABLE_VENDOR_USER_FAILED",
	ENABLE_VENDOR_USER_REQUESTED = "ENABLE_VENDOR_USER_REQUESTED",
	ENABLE_VENDOR_USER_SUCCESSFUL = "ENABLE_VENDOR_USER_SUCCESSFUL",
	ENABLE_VENDOR_USER_FAILED = "ENABLE_VENDOR_USER_FAILED",
	ADD_VENDOR_USER_REQUESTED = "ADD_VENDOR_USER_REQUESTED",
	ADD_VENDOR_USER_SUCCESSFUL = "ADD_VENDOR_USER_SUCCESSFUL",
	ADD_VENDOR_USER_FAILED = "ADD_VENDOR_USER_FAILED",
	ADD_VENDOR_API_USER_REQUESTED = "ADD_VENDOR_API_USER_REQUESTED",
	ADD_VENDOR_API_USER_SUCCESSFUL = "ADD_VENDOR_API_USER_SUCCESSFUL",
	ADD_VENDOR_API_USER_FAILED = "ADD_VENDOR_API_USER_FAILED",
	EDIT_VENDOR_USER_REQUESTED = "EDIT_VENDOR_USER_REQUESTED",
	EDIT_VENDOR_USER_SUCCESSFUL = "EDIT_VENDOR_USER_SUCCESSFUL",
	EDIT_VENDOR_USER_FAILED = "EDIT_VENDOR_USER_FAILED",
	VENDOR_USER_CONFIG_REQUESTED = "VENDOR_USER_CONFIG_REQUESTED",
	VENDOR_USER_CONFIG_SUCCESSFUL = "VENDOR_USER_CONFIG_SUCCESSFUL",
	VENDOR_USER_CONFIG_FAILED = "VENDOR_USER_CONFIG_FAILED",
	UPDATE_VENDOR_USER_LIST = "UPDATE_VENDOR_USER_LIST",
	RESET_VENDOR_USER_CURRENT_ACTIVE_VIEW = "RESET_VENDOR_USER_CURRENT_ACTIVE_VIEW",
	UPDATE_VENDOR_USER_CURRENT_ACTIVE_VIEW = "UPDATE_VENDOR_USER_CURRENT_ACTIVE_VIEW",
	FETCH_ALL_VENDOR_USER_VIEWS_REQUESTED = "FETCH_ALL_VENDOR_USER_VIEWS_REQUESTED",
	FETCH_ALL_VENDOR_USER_VIEWS_SUCCESSFUL = "FETCH_ALL_VENDOR_USER_VIEWS_SUCCESSFUL",
	FETCH_ALL_VENDOR_USER_VIEWS_FAILED = "FETCH_ALL_VENDOR_USER_VIEWS_FAILED",

	EDIT_USER_COMPANY_REQUESTED = "EDIT_USER_COMPANY_REQUESTED",
	EDIT_USER_COMPANY_SUCCESSFUL = "EDIT_USER_COMPANY_SUCCESSFUL",
	EDIT_USER_COMPANY_FAILED = "EDIT_USER_COMPANY_FAILED",
	COMPANY_USERS_REQUESTED = "COMPANY_USERS_REQUESTED",
	COMPANY_USERS_SUCCESSFUL = "COMPANY_USERS_SUCCESSFUL",
	COMPANY_USERS_FAILED = "COMPANY_USERS_FAILED",
	EDIT_CURRENT_COMPANY_USER_REQUESTED = "EDIT_CURRENT_COMPANY_USER_REQUESTED",
	EDIT_CURRENT_COMPANY_USER_SUCCESSFUL = "EDIT_CURRENT_COMPANY_USER_SUCCESSFUL",
	EDIT_CURRENT_COMPANY_USER_FAILED = "EDIT_CURRENT_COMPANY_USER_FAILED"
}

export type ShowVendorUserLoaderAction =
	Action<VendorUsersActionTypes.SHOW_VENDOR_USER_LOADER>;
export type HideVendorUserLoaderAction =
	Action<VendorUsersActionTypes.HIDE_VENDOR_USER_LOADER>;

export type VendorUserDataRequestedAction =
	Action<VendorUsersActionTypes.VENDOR_USER_DATA_REQUESTED>;
export interface VendorUserDataSuccessfulAction
	extends Action<VendorUsersActionTypes.VENDOR_USER_DATA_SUCCESSFUL> {
	readonly vendorUserData: VendorUserData;
}
export type VendorUserDataFailedAction =
	Action<VendorUsersActionTypes.VENDOR_USER_DATA_FAILED>;

export type VendorUserCompanyRequestedAction =
	Action<VendorUsersActionTypes.VENDOR_USER_COMPANY_REQUESTED>;
export interface VendorUserCompanySuccessfulAction
	extends Action<VendorUsersActionTypes.VENDOR_USER_COMPANY_SUCCESSFUL> {
	readonly vendorUserCompany: VendorUserCompany;
}
export type VendorUserCompanyFailedAction =
	Action<VendorUsersActionTypes.VENDOR_USER_COMPANY_FAILED>;

export type VendorUserIntegrationRequestedAction =
	Action<VendorUsersActionTypes.VENDOR_USER_INTEGRATION_REQUESTED>;
export interface VendorUserIntegrationSuccessfulAction
	extends Action<VendorUsersActionTypes.VENDOR_USER_INTEGRATION_SUCCESSFUL> {
	readonly vendorUserIntegration: VendorUserIntegration;
}
export type VendorUserIntegrationFailedAction =
	Action<VendorUsersActionTypes.VENDOR_USER_INTEGRATION_FAILED>;

export type AddVendorUserEmailAliasRequestedAction =
	Action<VendorUsersActionTypes.ADD_VENDOR_USER_EMAIL_ALIAS_REQUESTED>;
export interface AddVendorUserEmailAliasSuccessfulAction
	extends Action<VendorUsersActionTypes.ADD_VENDOR_USER_EMAIL_ALIAS_SUCCESSFUL> {
	readonly addVendorUserEmailAliasResponse: VendorEmailAliasItem;
}
export type AddVendorUserEmailAliasFailedAction =
	Action<VendorUsersActionTypes.ADD_VENDOR_USER_EMAIL_ALIAS_FAILED>;

export type DeleteVendorUserEmailAliasRequestedAction =
	Action<VendorUsersActionTypes.DELETE_VENDOR_USER_EMAIL_ALIAS_REQUESTED>;
export interface DeleteVendorUserEmailAliasSuccessfulAction
	extends Action<VendorUsersActionTypes.DELETE_VENDOR_USER_EMAIL_ALIAS_SUCCESSFUL> {
	readonly deleteVendorUserEmailAliasResponse: VendorEmailAliasItem;
}
export type DeleteVendorUserEmailAliasFailedAction =
	Action<VendorUsersActionTypes.DELETE_VENDOR_USER_EMAIL_ALIAS_FAILED>;

export type ResetVendorUserStateAction =
	Action<VendorUsersActionTypes.RESET_VENDOR_USER_STATE>;

export type VendorUserListRequestedAction =
	Action<VendorUsersActionTypes.VENDOR_USER_LIST_REQUESTED>;
export interface VendorUserListSuccessfulAction
	extends Action<VendorUsersActionTypes.VENDOR_USER_LIST_SUCCESSFUL> {
	readonly vendorUserList: VendorUserData[];
}
export type VendorUserListFailedAction =
	Action<VendorUsersActionTypes.VENDOR_USER_LIST_FAILED>;
export type AddVendorUserDrawerOpenedAction =
	Action<VendorUsersActionTypes.ADD_VENDOR_USER_DRAWER_OPENED>;
export type AddVendorUserDrawerClosedAction =
	Action<VendorUsersActionTypes.ADD_VENDOR_USER_DRAWER_CLOSED>;
export type EditVendorUserDrawerOpenedAction =
	Action<VendorUsersActionTypes.EDIT_COMPANY_VENDOR_DRAWER_OPENED>;
export type EditVendorUserDrawerClosedAction =
	Action<VendorUsersActionTypes.EDIT_COMPANY_VENDOR_DRAWER_CLOSED>;
export type DisableVendorUserRequestedAction =
	Action<VendorUsersActionTypes.DISABLE_VENDOR_USER_REQUESTED>;
export interface DisableVendorUserSuccessfulAction
	extends Action<VendorUsersActionTypes.DISABLE_VENDOR_USER_SUCCESSFUL> {
	readonly userData: VendorUserData;
}
export type DisableVendorUserFailedAction =
	Action<VendorUsersActionTypes.DISABLE_VENDOR_USER_FAILED>;

export type EnableVendorUserRequestedAction =
	Action<VendorUsersActionTypes.ENABLE_VENDOR_USER_REQUESTED>;

export interface EnableVendorUserSuccessfulAction
	extends Action<VendorUsersActionTypes.ENABLE_VENDOR_USER_SUCCESSFUL> {
	readonly userData: VendorUserData;
}

export type EnableVendorUserFailedAction =
	Action<VendorUsersActionTypes.ENABLE_VENDOR_USER_FAILED>;

export type AddVendorUserRequestedAction =
	Action<VendorUsersActionTypes.ADD_VENDOR_USER_REQUESTED>;
export interface AddVendorUserSuccessfulAction
	extends Action<VendorUsersActionTypes.ADD_VENDOR_USER_SUCCESSFUL> {
	readonly addVendorUser: VendorUserData;
}
export type AddVendorUserFailedAction =
	Action<VendorUsersActionTypes.ADD_VENDOR_USER_FAILED>;
export interface EditVendorUserSuccessfulAction
	extends Action<VendorUsersActionTypes.EDIT_VENDOR_USER_SUCCESSFUL> {
	readonly editVendorUser: VendorUserData;
}

export type AddVendorAPIUserRequestedAction =
	Action<VendorUsersActionTypes.ADD_VENDOR_API_USER_REQUESTED>;
export interface AddVendorAPIUserSuccessfulAction
	extends Action<VendorUsersActionTypes.ADD_VENDOR_API_USER_SUCCESSFUL> {
	readonly addVendorUser: VendorUserData;
}
export type AddVendorAPIUserFailedAction =
	Action<VendorUsersActionTypes.ADD_VENDOR_API_USER_FAILED>;

export type VendorUserConfigRequestedAction =
	Action<VendorUsersActionTypes.VENDOR_USER_CONFIG_REQUESTED>;

export interface VendorUserConfigSuccessfulAction
	extends Action<VendorUsersActionTypes.VENDOR_USER_CONFIG_SUCCESSFUL> {
	readonly config: NotificationConfiguration;
}

export type VendorUserConfigFailedAction =
	Action<VendorUsersActionTypes.VENDOR_USER_CONFIG_FAILED>;

export type EditVendorUserFailedAction =
	Action<VendorUsersActionTypes.EDIT_VENDOR_USER_FAILED>;

export type EditVendorUserRequestedAction =
	Action<VendorUsersActionTypes.EDIT_VENDOR_USER_REQUESTED>;

export interface UpdateVendorUserListAction
	extends Action<VendorUsersActionTypes.UPDATE_VENDOR_USER_LIST> {
	readonly vendorUserList: VendorUserData[];
}

export interface UpdateCurrentActiveViewAction
	extends Action<VendorUsersActionTypes.UPDATE_VENDOR_USER_CURRENT_ACTIVE_VIEW> {
	readonly currentActiveView: CustomView;
	readonly isViewChanged: boolean;
}
export type ResetCurrentActiveViewAction =
	Action<VendorUsersActionTypes.RESET_VENDOR_USER_CURRENT_ACTIVE_VIEW>;

export type FetchAllVendorUserViewRequested =
	Action<VendorUsersActionTypes.FETCH_ALL_VENDOR_USER_VIEWS_REQUESTED>;
export interface FetchAllVendorUserViewSuccessful
	extends Action<VendorUsersActionTypes.FETCH_ALL_VENDOR_USER_VIEWS_SUCCESSFUL> {
	readonly viewList: CustomViewResponse;
}
export type FetchAllVendorUserViewFailed =
	Action<VendorUsersActionTypes.FETCH_ALL_VENDOR_USER_VIEWS_FAILED>;

export type VendorUsersActions =
	| ShowVendorUserLoaderAction
	| HideVendorUserLoaderAction
	| VendorUserDataRequestedAction
	| VendorUserDataSuccessfulAction
	| VendorUserDataFailedAction
	| VendorUserCompanyRequestedAction
	| VendorUserCompanySuccessfulAction
	| VendorUserCompanyFailedAction
	| VendorUserIntegrationRequestedAction
	| VendorUserIntegrationSuccessfulAction
	| VendorUserIntegrationFailedAction
	| AddVendorUserEmailAliasRequestedAction
	| AddVendorUserEmailAliasSuccessfulAction
	| AddVendorUserEmailAliasFailedAction
	| DeleteVendorUserEmailAliasRequestedAction
	| DeleteVendorUserEmailAliasSuccessfulAction
	| DeleteVendorUserEmailAliasFailedAction
	| ResetVendorUserStateAction
	| VendorUserListRequestedAction
	| VendorUserListSuccessfulAction
	| VendorUserListFailedAction
	| AddVendorUserDrawerOpenedAction
	| AddVendorUserDrawerClosedAction
	| EditVendorUserDrawerOpenedAction
	| EditVendorUserDrawerClosedAction
	| DisableVendorUserRequestedAction
	| DisableVendorUserSuccessfulAction
	| DisableVendorUserFailedAction
	| EnableVendorUserRequestedAction
	| EnableVendorUserSuccessfulAction
	| EnableVendorUserFailedAction
	| AddVendorUserRequestedAction
	| AddVendorUserRequestedAction
	| AddVendorUserFailedAction
	| AddVendorAPIUserRequestedAction
	| AddVendorAPIUserRequestedAction
	| AddVendorAPIUserFailedAction
	| EditVendorUserRequestedAction
	| EditVendorUserSuccessfulAction
	| EditVendorUserFailedAction
	| VendorUserConfigRequestedAction
	| VendorUserConfigSuccessfulAction
	| VendorUserConfigFailedAction
	| UpdateVendorUserListAction
	| UpdateCurrentActiveViewAction
	| FetchAllVendorUserViewRequested
	| FetchAllVendorUserViewSuccessful
	| FetchAllVendorUserViewFailed
	| ResetCurrentActiveViewAction;

export interface VendorUserDispatch {
	showVendorUserLoader: ActionCreator<
		ThunkAction<
			ShowVendorUserLoaderAction,
			GlobalState,
			null,
			ShowVendorUserLoaderAction
		>
	>;
	hideVendorUserLoader: ActionCreator<
		ThunkAction<
			HideVendorUserLoaderAction,
			GlobalState,
			null,
			HideVendorUserLoaderAction
		>
	>;
	getVendorUserData: ActionCreator<
		ThunkAction<
			Promise<VendorUserDataSuccessfulAction>,
			GlobalState,
			null,
			VendorUserDataSuccessfulAction
		>
	>;
	getVendorUserCompany: ActionCreator<
		ThunkAction<
			Promise<VendorUserCompanySuccessfulAction>,
			GlobalState,
			null,
			VendorUserCompanySuccessfulAction
		>
	>;
	getVendorUserIntegration: ActionCreator<
		ThunkAction<
			Promise<VendorUserIntegrationSuccessfulAction>,
			GlobalState,
			null,
			VendorUserCompanySuccessfulAction
		>
	>;
	addVendorUserEmailAlias: ActionCreator<
		ThunkAction<
			Promise<AddVendorUserEmailAliasSuccessfulAction>,
			GlobalState,
			{ addVendorUserEmailAlias: EmailAliasRequest },
			AddVendorUserEmailAliasSuccessfulAction
		>
	>;
	deleteVendorUserEmailAlias: ActionCreator<
		ThunkAction<
			Promise<DeleteVendorUserEmailAliasSuccessfulAction>,
			GlobalState,
			{ deleteVendorUserEmailAlias: EmailAliasRequest },
			DeleteVendorUserEmailAliasSuccessfulAction
		>
	>;
	resetVendorUserState: ActionCreator<
		ThunkAction<
			ResetVendorUserStateAction,
			GlobalState,
			null,
			ResetVendorUserStateAction
		>
	>;
	getVendorUserList: ActionCreator<
		ThunkAction<
			Promise<VendorUserListSuccessfulAction>,
			GlobalState,
			null,
			VendorUserListSuccessfulAction
		>
	>;
	addVendorUserDrawerOpened: ActionCreator<
		ThunkAction<
			AddVendorUserDrawerOpenedAction,
			GlobalState,
			null,
			AddVendorUserDrawerOpenedAction
		>
	>;
	addVendorUserDrawerClosed: ActionCreator<
		ThunkAction<
			AddVendorUserDrawerClosedAction,
			GlobalState,
			null,
			AddVendorUserDrawerClosedAction
		>
	>;
	editVendorUserDrawerOpened: ActionCreator<
		ThunkAction<
			EditVendorUserDrawerOpenedAction,
			GlobalState,
			null,
			EditVendorUserDrawerOpenedAction
		>
	>;
	editVendorUserDrawerClosed: ActionCreator<
		ThunkAction<
			EditVendorUserDrawerClosedAction,
			GlobalState,
			null,
			EditVendorUserDrawerClosedAction
		>
	>;

	disableVendorUser: ActionCreator<
		ThunkAction<
			Promise<DisableVendorUserSuccessfulAction>,
			GlobalState,
			{ userId: string },
			DisableVendorUserSuccessfulAction
		>
	>;
	enableVendorUser: ActionCreator<
		ThunkAction<
			Promise<EnableVendorUserSuccessfulAction>,
			GlobalState,
			{ userId: string; role: VendorUserRole },
			EnableVendorUserSuccessfulAction
		>
	>;
	addVendorUser: ActionCreator<
		ThunkAction<
			Promise<AddVendorUserSuccessfulAction>,
			GlobalState,
			AddVendorUserRequest,
			AddVendorUserSuccessfulAction
		>
	>;
	addVendorAPIUser: ActionCreator<
		ThunkAction<
			Promise<AddVendorAPIUserSuccessfulAction>,
			GlobalState,
			AddVendorUserRequest,
			AddVendorAPIUserSuccessfulAction
		>
	>;
	editVendorUser: ActionCreator<
		ThunkAction<
			Promise<EditVendorUserSuccessfulAction>,
			GlobalState,
			VendorUserData,
			EditVendorUserSuccessfulAction
		>
	>;
	getVendorUserConfig: ActionCreator<
		ThunkAction<
			Promise<VendorUserConfigSuccessfulAction>,
			GlobalState,
			null,
			VendorUserConfigSuccessfulAction
		>
	>;
	updateVendorUserList: ActionCreator<
		ThunkAction<
			UpdateVendorUserListAction,
			GlobalState,
			null,
			UpdateVendorUserListAction
		>
	>;
	updateCurrentActiveView: ActionCreator<
		ThunkAction<
			UpdateCurrentActiveViewAction,
			GlobalState,
			{ selectCurrentActiveView: CustomView; discardingView?: boolean },
			UpdateCurrentActiveViewAction
		>
	>;
	resetViews: ActionCreator<
		ThunkAction<
			ResetCurrentActiveViewAction,
			GlobalState,
			null,
			ResetCurrentActiveViewAction
		>
	>;
	fetchAllVendorUserView: ActionCreator<
		ThunkAction<
			Promise<FetchAllVendorUserViewSuccessful>,
			GlobalState,
			null,
			FetchAllVendorUserViewSuccessful
		>
	>;
}

export const VendorUsersActionCreators: VendorUserDispatch = {
	showVendorUserLoader: () => {
		return (dispatch: Dispatch): ShowVendorUserLoaderAction => {
			const showClientsLoaderAction: ShowVendorUserLoaderAction = {
				type: VendorUsersActionTypes.SHOW_VENDOR_USER_LOADER
			};
			return dispatch(showClientsLoaderAction);
		};
	},
	hideVendorUserLoader: () => {
		return (dispatch: Dispatch): HideVendorUserLoaderAction => {
			const hideClientsLoaderAction: HideVendorUserLoaderAction = {
				type: VendorUsersActionTypes.HIDE_VENDOR_USER_LOADER
			};
			return dispatch(hideClientsLoaderAction);
		};
	},
	getVendorUserData: () => {
		return async (
			dispatch: Dispatch
		): Promise<VendorUserDataSuccessfulAction> => {
			const vendorUserDataRequestedAction: VendorUserDataRequestedAction =
				{
					type: VendorUsersActionTypes.VENDOR_USER_DATA_REQUESTED
				};
			dispatch(vendorUserDataRequestedAction);
			try {
				const vendorUserDataResponse = await getVendorUserData();
				const vendorUserDataSuccessfulAction: VendorUserDataSuccessfulAction =
					{
						type: VendorUsersActionTypes.VENDOR_USER_DATA_SUCCESSFUL,
						vendorUserData: vendorUserDataResponse
					};
				return dispatch(vendorUserDataSuccessfulAction);
			} catch (error) {
				const vendorUserDataFailedAction: VendorUserDataFailedAction = {
					type: VendorUsersActionTypes.VENDOR_USER_DATA_FAILED
				};
				dispatch(vendorUserDataFailedAction);
				return Promise.reject(error);
			}
		};
	},
	getVendorUserCompany: (includeVendorUsers = true) => {
		return async (
			dispatch: Dispatch
		): Promise<VendorUserCompanySuccessfulAction> => {
			const vendorUserCompanyRequestedAction: VendorUserCompanyRequestedAction =
				{
					type: VendorUsersActionTypes.VENDOR_USER_COMPANY_REQUESTED
				};
			dispatch(vendorUserCompanyRequestedAction);
			try {
				let getVendorUserCompanyRequest: GetVendorUserCompanyRequest | null =
					null;
				if (includeVendorUsers) {
					getVendorUserCompanyRequest = {
						included_child_entities:
							new Array<ChildEntityHydrationParams>(
								ChildEntityHydrationParams.VENDOR_USERS_ALL
							)
					};
				}
				const vendorUserCompanyResponse = await getVendorUserCompany(
					getVendorUserCompanyRequest
				);

				// saving currency in the localStorage
				const { currency, locale } =
					vendorUserCompanyResponse?.settings;
				if (currency) {
					cache.save(ZomentumStorageKeys.Currency, currency);
				}
				if (locale) {
					cache.save(ZomentumStorageKeys.Locale, locale);
				}

				const vendorUserCompanySuccessfulAction: VendorUserCompanySuccessfulAction =
					{
						type: VendorUsersActionTypes.VENDOR_USER_COMPANY_SUCCESSFUL,
						vendorUserCompany: vendorUserCompanyResponse
					};
				if (includeVendorUsers) {
					const vendorUserListSuccessfulAction: VendorUserListSuccessfulAction =
						{
							type: VendorUsersActionTypes.VENDOR_USER_LIST_SUCCESSFUL,
							vendorUserList:
								vendorUserCompanyResponse.vendor_users?.data ??
								[]
						};
					dispatch(vendorUserListSuccessfulAction);
				}
				return dispatch(vendorUserCompanySuccessfulAction);
			} catch (error) {
				const userCompanyFailedAction: VendorUserCompanyFailedAction = {
					type: VendorUsersActionTypes.VENDOR_USER_COMPANY_FAILED
				};
				dispatch(userCompanyFailedAction);

				return Promise.reject(error);
			}
		};
	},
	getVendorUserIntegration: () => {
		return async (
			dispatch: Dispatch
		): Promise<VendorUserIntegrationSuccessfulAction> => {
			const vendorUserIntegrationRequestedAction: VendorUserIntegrationRequestedAction =
				{
					type: VendorUsersActionTypes.VENDOR_USER_INTEGRATION_REQUESTED
				};
			dispatch(vendorUserIntegrationRequestedAction);
			try {
				const vendorUserIntegrationResponse =
					await getVendorUserIntegration();
				const vendorUserIntegrationSuccessfulAction: VendorUserIntegrationSuccessfulAction =
					{
						type: VendorUsersActionTypes.VENDOR_USER_INTEGRATION_SUCCESSFUL,
						vendorUserIntegration: vendorUserIntegrationResponse
					};
				return dispatch(vendorUserIntegrationSuccessfulAction);
			} catch (error) {
				const vendorUserIntegrationFailedAction: VendorUserIntegrationFailedAction =
					{
						type: VendorUsersActionTypes.VENDOR_USER_INTEGRATION_FAILED
					};
				dispatch(vendorUserIntegrationFailedAction);
				return Promise.reject(error);
			}
		};
	},
	addVendorUserEmailAlias:
		(addVendorEmailAliasRequest: EmailAliasRequest) =>
		async (
			dispatch: Dispatch
		): Promise<AddVendorUserEmailAliasSuccessfulAction> => {
			const addVendorUserEmailAliasRequestedAction: AddVendorUserEmailAliasRequestedAction =
				{
					type: VendorUsersActionTypes.ADD_VENDOR_USER_EMAIL_ALIAS_REQUESTED
				};
			dispatch(addVendorUserEmailAliasRequestedAction);
			try {
				const addVendorUserEmailAliasResponse =
					await addVendorUserEmailAlias(addVendorEmailAliasRequest);
				const addVendorUserEmailAliasSuccessfulAction: AddVendorUserEmailAliasSuccessfulAction =
					{
						type: VendorUsersActionTypes.ADD_VENDOR_USER_EMAIL_ALIAS_SUCCESSFUL,
						addVendorUserEmailAliasResponse
					};
				return dispatch(addVendorUserEmailAliasSuccessfulAction);
			} catch (error) {
				const addVendorUserEmailAliasFailedAction: AddVendorUserEmailAliasFailedAction =
					{
						type: VendorUsersActionTypes.ADD_VENDOR_USER_EMAIL_ALIAS_FAILED
					};
				dispatch(addVendorUserEmailAliasFailedAction);
				return Promise.reject(error);
			}
		},
	deleteVendorUserEmailAlias:
		(deleteVendorEmailAliasRequest: EmailAliasRequest) =>
		async (
			dispatch: Dispatch
		): Promise<DeleteVendorUserEmailAliasSuccessfulAction> => {
			const deleteVendorUserEmailAliasRequestedAction: DeleteVendorUserEmailAliasRequestedAction =
				{
					type: VendorUsersActionTypes.DELETE_VENDOR_USER_EMAIL_ALIAS_REQUESTED
				};
			dispatch(deleteVendorUserEmailAliasRequestedAction);
			try {
				const deleteVendorUserEmailAliasResponse =
					await deleteVendorUserEmailAlias(
						deleteVendorEmailAliasRequest
					);
				const deleteVendorUserEmailAliasSuccessfulAction: DeleteVendorUserEmailAliasSuccessfulAction =
					{
						type: VendorUsersActionTypes.DELETE_VENDOR_USER_EMAIL_ALIAS_SUCCESSFUL,
						deleteVendorUserEmailAliasResponse
					};
				return dispatch(deleteVendorUserEmailAliasSuccessfulAction);
			} catch (error) {
				const deleteVendorUserEmailAliasFailedAction: DeleteVendorUserEmailAliasFailedAction =
					{
						type: VendorUsersActionTypes.DELETE_VENDOR_USER_EMAIL_ALIAS_FAILED
					};
				dispatch(deleteVendorUserEmailAliasFailedAction);
				return Promise.reject(error);
			}
		},
	resetVendorUserState: () => {
		return (dispatch: Dispatch): ResetVendorUserStateAction => {
			const resetVendorUserStateAction: ResetVendorUserStateAction = {
				type: VendorUsersActionTypes.RESET_VENDOR_USER_STATE
			};

			return dispatch(resetVendorUserStateAction);
		};
	},
	getVendorUserList: (limit?: number) => {
		return async (
			dispatch: Dispatch
		): Promise<VendorUserListSuccessfulAction> => {
			const vendorUserListRequestedAction: VendorUserListRequestedAction =
				{
					type: VendorUsersActionTypes.VENDOR_USER_LIST_REQUESTED
				};
			dispatch(vendorUserListRequestedAction);
			try {
				const vendorUserListResponse = await getVendorUserList(limit);
				const vendorUserListSuccessfulAction: VendorUserListSuccessfulAction =
					{
						type: VendorUsersActionTypes.VENDOR_USER_LIST_SUCCESSFUL,
						vendorUserList: vendorUserListResponse.data
					};
				return dispatch(vendorUserListSuccessfulAction);
			} catch (error) {
				const vendorUserListFailedAction: VendorUserListFailedAction = {
					type: VendorUsersActionTypes.VENDOR_USER_LIST_FAILED
				};
				dispatch(vendorUserListFailedAction);
				return Promise.reject(error);
			}
		};
	},
	addVendorUserDrawerOpened: () => {
		return (dispatch: Dispatch): AddVendorUserDrawerOpenedAction => {
			const AddVendorUserDrawerOpenedAction: AddVendorUserDrawerOpenedAction =
				{
					type: VendorUsersActionTypes.ADD_VENDOR_USER_DRAWER_OPENED
				};
			return dispatch(AddVendorUserDrawerOpenedAction);
		};
	},
	addVendorUserDrawerClosed: () => {
		return (dispatch: Dispatch): AddVendorUserDrawerClosedAction => {
			const AddVendorUserDrawerClosedAction: AddVendorUserDrawerClosedAction =
				{
					type: VendorUsersActionTypes.ADD_VENDOR_USER_DRAWER_CLOSED
				};
			return dispatch(AddVendorUserDrawerClosedAction);
		};
	},
	editVendorUserDrawerOpened: () => {
		return (dispatch: Dispatch): EditVendorUserDrawerOpenedAction => {
			const EditVendorUserDrawerOpenedAction: EditVendorUserDrawerOpenedAction =
				{
					type: VendorUsersActionTypes.EDIT_COMPANY_VENDOR_DRAWER_OPENED
				};
			return dispatch(EditVendorUserDrawerOpenedAction);
		};
	},
	editVendorUserDrawerClosed: () => {
		return (dispatch: Dispatch): EditVendorUserDrawerClosedAction => {
			const EditVendorUserDrawerClosedAction: EditVendorUserDrawerClosedAction =
				{
					type: VendorUsersActionTypes.EDIT_COMPANY_VENDOR_DRAWER_CLOSED
				};
			return dispatch(EditVendorUserDrawerClosedAction);
		};
	},
	disableVendorUser: (userId: string) => {
		return async (
			dispatch: Dispatch
		): Promise<DisableVendorUserSuccessfulAction> => {
			const disableUserRequestedAction: DisableVendorUserRequestedAction =
				{
					type: VendorUsersActionTypes.DISABLE_VENDOR_USER_REQUESTED
				};
			dispatch(disableUserRequestedAction);
			try {
				const updatedUserData = await disableVendorUser(userId);
				const disableUserSucccessfulAction: DisableVendorUserSuccessfulAction =
					{
						type: VendorUsersActionTypes.DISABLE_VENDOR_USER_SUCCESSFUL,
						userData: updatedUserData
					};
				return dispatch(disableUserSucccessfulAction);
			} catch (error) {
				const disableUserFailedAction: DisableVendorUserFailedAction = {
					type: VendorUsersActionTypes.DISABLE_VENDOR_USER_FAILED
				};
				dispatch(disableUserFailedAction);

				return Promise.reject(error);
			}
		};
	},
	enableVendorUser: (userId: string, role: VendorUserRole) => {
		return async (
			dispatch: Dispatch
		): Promise<EnableVendorUserSuccessfulAction> => {
			const enableUserRequestedAction: EnableVendorUserRequestedAction = {
				type: VendorUsersActionTypes.ENABLE_VENDOR_USER_REQUESTED
			};
			dispatch(enableUserRequestedAction);
			try {
				const updatedUserData = await enableVendorUser(userId, role);
				const enableUserSucccessfulAction: EnableVendorUserSuccessfulAction =
					{
						type: VendorUsersActionTypes.ENABLE_VENDOR_USER_SUCCESSFUL,
						userData: updatedUserData
					};
				return dispatch(enableUserSucccessfulAction);
			} catch (error) {
				const enableUserFailedAction: EnableVendorUserFailedAction = {
					type: VendorUsersActionTypes.ENABLE_VENDOR_USER_FAILED
				};
				dispatch(enableUserFailedAction);

				return Promise.reject(error);
			}
		};
	},
	addVendorUser: (addVendorUserRequest: AddVendorUserRequest) => {
		return async (
			dispatch: Dispatch
		): Promise<AddVendorUserSuccessfulAction> => {
			const addVendorUserRequestedAction: AddVendorUserRequestedAction = {
				type: VendorUsersActionTypes.ADD_VENDOR_USER_REQUESTED
			};
			dispatch(addVendorUserRequestedAction);
			try {
				const addVendorCompanyResponse = await addVendorUser(
					addVendorUserRequest
				);
				const addVendorUserSuccessfulAction: AddVendorUserSuccessfulAction =
					{
						type: VendorUsersActionTypes.ADD_VENDOR_USER_SUCCESSFUL,
						addVendorUser: addVendorCompanyResponse
					};
				return dispatch(addVendorUserSuccessfulAction);
			} catch (error) {
				const addVendorUserFailedAction: AddVendorUserFailedAction = {
					type: VendorUsersActionTypes.ADD_VENDOR_USER_FAILED
				};
				dispatch(addVendorUserFailedAction);

				return Promise.reject(error);
			}
		};
	},
	addVendorAPIUser: (addVendorAPIUserRequest: AddVendorAPIUserRequest) => {
		return async (
			dispatch: Dispatch
		): Promise<AddVendorAPIUserSuccessfulAction> => {
			const addVendorAPIUserRequestedAction: AddVendorAPIUserRequestedAction =
				{
					type: VendorUsersActionTypes.ADD_VENDOR_API_USER_REQUESTED
				};
			dispatch(addVendorAPIUserRequestedAction);
			try {
				const addVendorAPIResponse = await addVendorAPIUser(
					addVendorAPIUserRequest
				);
				const addVendorAPIUserSuccessfulAction: AddVendorAPIUserSuccessfulAction =
					{
						type: VendorUsersActionTypes.ADD_VENDOR_API_USER_SUCCESSFUL,
						addVendorUser: addVendorAPIResponse
					};
				return dispatch(addVendorAPIUserSuccessfulAction);
			} catch (error) {
				const addVendorAPIUserFailedAction: AddVendorAPIUserFailedAction =
					{
						type: VendorUsersActionTypes.ADD_VENDOR_API_USER_FAILED
					};
				dispatch(addVendorAPIUserFailedAction);

				return Promise.reject(error);
			}
		};
	},
	editVendorUser: (editVendorUserRequest: VendorUserData) => {
		return async (
			dispatch: Dispatch,
			getState: () => GlobalState
		): Promise<EditVendorUserSuccessfulAction> => {
			const editVendorUserRequestedAction: EditVendorUserRequestedAction =
				{
					type: VendorUsersActionTypes.EDIT_VENDOR_USER_REQUESTED
				};
			dispatch(editVendorUserRequestedAction);
			try {
				const editVendorUserResponse = await editVendorUser({
					id: editVendorUserRequest.id,
					name: editVendorUserRequest.name,
					email: editVendorUserRequest.email,
					phone: editVendorUserRequest.phone,
					is_view_tutorial_shown:
						editVendorUserRequest.is_view_tutorial_shown
				});
				const editVendorUserSuccessfulAction: EditVendorUserSuccessfulAction =
					{
						type: VendorUsersActionTypes.EDIT_VENDOR_USER_SUCCESSFUL,
						editVendorUser: editVendorUserResponse
					};
				const globalState = getState();
				if (
					globalState.vendorUser.vendorUserData.id ===
					editVendorUserRequest.id
				) {
					const vendorUserDataSuccessfulAction: VendorUserDataSuccessfulAction =
						{
							type: VendorUsersActionTypes.VENDOR_USER_DATA_SUCCESSFUL,
							vendorUserData: editVendorUserResponse
						};
					dispatch(vendorUserDataSuccessfulAction);
				}
				return dispatch(editVendorUserSuccessfulAction);
			} catch (error) {
				const editVendorUserFailedAction: EditVendorUserFailedAction = {
					type: VendorUsersActionTypes.EDIT_VENDOR_USER_FAILED
				};
				dispatch(editVendorUserFailedAction);

				return Promise.reject(error);
			}
		};
	},
	getVendorUserConfig: () => {
		return async (
			dispatch: Dispatch
		): Promise<VendorUserConfigSuccessfulAction> => {
			const vendorUserConfigRequestedAction: VendorUserConfigRequestedAction =
				{
					type: VendorUsersActionTypes.VENDOR_USER_CONFIG_REQUESTED
				};
			dispatch(vendorUserConfigRequestedAction);
			try {
				const config = await getVendorConfigurationSettings();
				const vendorUserConfigSuccessfulAction: VendorUserConfigSuccessfulAction =
					{
						type: VendorUsersActionTypes.VENDOR_USER_CONFIG_SUCCESSFUL,
						config
					};
				return dispatch(vendorUserConfigSuccessfulAction);
			} catch (error) {
				const vendorUserConfigFailedAction: VendorUserConfigFailedAction =
					{
						type: VendorUsersActionTypes.VENDOR_USER_CONFIG_FAILED
					};
				dispatch(vendorUserConfigFailedAction);

				return Promise.reject(error);
			}
		};
	},
	updateVendorUserList: (vendorUserList: VendorUserData[]) => {
		return (dispatch: Dispatch): UpdateVendorUserListAction => {
			const updateVendorUserListAction: UpdateVendorUserListAction = {
				type: VendorUsersActionTypes.UPDATE_VENDOR_USER_LIST,
				vendorUserList
			};
			return dispatch(updateVendorUserListAction);
		};
	},
	updateCurrentActiveView: (
		selectedCurrentActiveView: CustomView,
		discardingView?: boolean
	) => {
		return (
			dispatch: Dispatch,
			getState: () => GlobalState
		): UpdateCurrentActiveViewAction => {
			const currentActiveView = getState().vendorUser.currentActiveView;
			const prevViewData = getState().vendorUser.viewList?.data.find(
				view => view.id === selectedCurrentActiveView?.id
			);

			const isSameView =
				currentActiveView?.id === selectedCurrentActiveView?.id;

			if (isSameView) {
				// if its a custom view then it would have an id
				if (prevViewData?.id) {
					const hasFiltersChanged =
						!!difference(
							prevViewData.filter_criteria,
							selectedCurrentActiveView.filter_criteria
						).length ||
						prevViewData.filter_criteria.length !==
							selectedCurrentActiveView.filter_criteria.length;

					const hasColumnsChanged =
						JSON.stringify(selectedCurrentActiveView.columns) !==
						JSON.stringify(prevViewData.columns);

					const updateCurrentActiveViewAction: UpdateCurrentActiveViewAction =
						{
							type: VendorUsersActionTypes.UPDATE_VENDOR_USER_CURRENT_ACTIVE_VIEW,
							currentActiveView: selectedCurrentActiveView,
							isViewChanged: discardingView
								? false
								: hasFiltersChanged || hasColumnsChanged
						};
					return dispatch(updateCurrentActiveViewAction);
				}

				// if its a system view then no id

				if (currentActiveView) {
					const hasFiltersChanged =
						!!difference(
							currentActiveView?.filter_criteria,
							selectedCurrentActiveView.filter_criteria
						).length ||
						currentActiveView?.filter_criteria.length !==
							selectedCurrentActiveView.filter_criteria.length;

					const hasColumnsChanged =
						JSON.stringify(selectedCurrentActiveView.columns) !==
						JSON.stringify(currentActiveView?.columns);

					const updateCurrentActiveViewAction: UpdateCurrentActiveViewAction =
						{
							type: VendorUsersActionTypes.UPDATE_VENDOR_USER_CURRENT_ACTIVE_VIEW,
							currentActiveView: selectedCurrentActiveView,
							isViewChanged: discardingView
								? false
								: hasFiltersChanged || hasColumnsChanged
						};
					return dispatch(updateCurrentActiveViewAction);
				}
			}

			const updateCurrentActiveViewAction: UpdateCurrentActiveViewAction =
				{
					type: VendorUsersActionTypes.UPDATE_VENDOR_USER_CURRENT_ACTIVE_VIEW,
					currentActiveView: selectedCurrentActiveView,
					isViewChanged: !!discardingView
				};
			return dispatch(updateCurrentActiveViewAction);
		};
	},
	resetViews: () => {
		return (dispatch: Dispatch): ResetCurrentActiveViewAction => {
			const resetCurrentActiveViewAction: ResetCurrentActiveViewAction = {
				type: VendorUsersActionTypes.RESET_VENDOR_USER_CURRENT_ACTIVE_VIEW
			};

			return dispatch(resetCurrentActiveViewAction);
		};
	},
	fetchAllVendorUserView: () => {
		return async (
			dispatch: Dispatch
		): Promise<FetchAllVendorUserViewSuccessful> => {
			const fetchAllVendorUserViewRequested: FetchAllVendorUserViewRequested =
				{
					type: VendorUsersActionTypes.FETCH_ALL_VENDOR_USER_VIEWS_REQUESTED
				};

			dispatch(fetchAllVendorUserViewRequested);

			try {
				const response = await getAllVendorUserViews();
				const fetchAllVendorUserViewSuccessful: FetchAllVendorUserViewSuccessful =
					{
						type: VendorUsersActionTypes.FETCH_ALL_VENDOR_USER_VIEWS_SUCCESSFUL,
						viewList: response
					};
				return dispatch(fetchAllVendorUserViewSuccessful);
			} catch (error) {
				console.error(error);

				const fetchAllVendorUserViewFailed: FetchAllVendorUserViewFailed =
					{
						type: VendorUsersActionTypes.FETCH_ALL_VENDOR_USER_VIEWS_FAILED
					};
				dispatch(fetchAllVendorUserViewFailed);
				return Promise.reject(error);
			}
		};
	}
};

export default VendorUsersActionCreators;
