import { VendorTiersActionTypes } from "@/Actions/Vendor/Tiers";
import { VendorPartnerTier } from "@zomentum/contracts/dist/Vendor/Tiers";

export const initalVendorTiersState = {
	tierList: new Array<VendorPartnerTier>()
};

export class VendorTiersState {
	readonly tierList: VendorPartnerTier[] = new Array<VendorPartnerTier>();
	readonly isLoading: boolean = false;
}

const VendorTiersReducer = (state = initalVendorTiersState, action: any) => {
	switch (action.type) {
		case VendorTiersActionTypes.SHOW_VENDOR_TIERS_LOADER: {
			return {
				...state,
				isLoading: true
			};
		}
		case VendorTiersActionTypes.HIDE_VENDOR_TIERS_LOADER: {
			return {
				...state,
				isLoading: false
			};
		}
		case VendorTiersActionTypes.FETCH_VENDOR_TIERS_REQUESTED: {
			return state;
		}
		case VendorTiersActionTypes.FETCH_VENDOR_TIERS_SUCCESSFULL: {
			return {
				...state,
				tierList: action.tierList
			};
		}
		case VendorTiersActionTypes.FETCH_VENDOR_TIERS_FAILED: {
			return state;
		}
		case VendorTiersActionTypes.ADD_VENDOR_TIER_REQUESTED: {
			return state;
		}
		case VendorTiersActionTypes.ADD_VENDOR_TIER_SUCCESSFUL: {
			return {
				...state,
				tierList: [action.newTier].concat(state.tierList)
			};
		}
		case VendorTiersActionTypes.ADD_VENDOR_TIER_FAILED: {
			return state;
		}
		case VendorTiersActionTypes.DELETE_VENDOR_TIER_REQUESTED: {
			return state;
		}
		case VendorTiersActionTypes.DELETE_VENDOR_TIER_SUCCESSFUL: {
			return {
				...state,
				tierList: state.tierList.filter(
					tier => tier.id !== action.deletedTierId
				)
			};
		}
		case VendorTiersActionTypes.DELETE_VENDOR_TIER_FAILED: {
			return state;
		}
		case VendorTiersActionTypes.UPDATE_VENDOR_TIER_REQUESTED: {
			return state;
		}
		case VendorTiersActionTypes.UPDATE_VENDOR_TIER_SUCCESSFUL: {
			return {
				...state,
				tierList: state.tierList.map(tier => {
					if (tier.id === action.updatedVendorTier.id) {
						return action.updatedVendorTier;
					}
					return tier;
				})
			};
		}
		case VendorTiersActionTypes.UPDATE_VENDOR_TIER_FAILED: {
			return state;
		}
		default:
			return state;
	}
};

export default VendorTiersReducer;
