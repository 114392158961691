'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../../../../node_modules/tslib/tslib.es6.mjs.js');
var index = require('../../../actions/Vendor/Client/index.js');

var initialVendorClientState = {
  isLoading: false,
  editClientDrawerVisible: false
};
var VendorClientReducer = function VendorClientReducer(state, action) {
  if (state === void 0) {
    state = initialVendorClientState;
  }
  switch (action.type) {
    case index.VendorClientActionTypes.SHOW_VENDOR_CLIENT_LOADER:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          isLoading: true
        });
      }
    case index.VendorClientActionTypes.HIDE_VENDOR_CLIENT_LOADER:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          isLoading: false
        });
      }
    case index.VendorClientActionTypes.OPEN_EDIT_VENDOR_CLIENT_DRAWER:
      return tslib_es6.__assign(tslib_es6.__assign({}, state), {
        editClientDrawerVisible: true
      });
    case index.VendorClientActionTypes.CLOSE_EDIT_VENDOR_CLIENT_DRAWER:
      return tslib_es6.__assign(tslib_es6.__assign({}, state), {
        editClientDrawerVisible: false
      });
    default:
      {
        return state;
      }
  }
};
var VendorClientReducer$1 = VendorClientReducer;

exports.VendorClientReducer = VendorClientReducer;
exports["default"] = VendorClientReducer$1;
exports.initialVendorClientState = initialVendorClientState;
