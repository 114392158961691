import {
	filterOutFormulaFields,
	processCustomFieldFormValues
} from "@zomentum/utils";
import useDispatch from "@/Components/Utils/useDispatch";
import { GlobalState } from "@/Reducers/interface";
import { Address } from "@zomentum/contracts";
import { CustomFieldType } from "@zomentum/contracts/dist/Settings";
import {
	VendorClientEditOrAddRequest,
	VendorClientFormValues
} from "@zomentum/contracts/dist/Vendor/Clients";
import VendorUsersActionCreators from "@/Actions/Vendor/User";
import ZDrawer from "@zomentum/molecules/dist/ZDrawer";
import ZForm from "@zomentum/organisms/dist/ZForm";
import {
	EWidgets,
	IFormProps,
	IFormSectionProps
} from "@zomentum/organisms/dist/ZForm/interface";
import Form from "antd/lib/form";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAddOrEditClientDrawerProps } from "./interface";
import { getClientFormItem } from "./getClientFormItem";
import ZRenderCustomFieldFormItem from "@zomentum/organisms/dist/ZRenderCustomFieldFormItem";
import moment from "moment";

const AddOrEditClientDrawer: React.FC<IAddOrEditClientDrawerProps> = props => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const { currentActiveUser, customFieldList } = useSelector(
		(state: GlobalState) => {
			return {
				currentActiveUser: state.vendorUser.vendorUserData,
				customFieldList: filterOutFormulaFields(
					state.customFields.customFieldList.vendor_client_company
				)
			};
		}
	);

	const handleClose = () => {
		form.resetFields();
		props.onClose();
	};

	const onFormSubmit = useCallback(
		async (formValues: VendorClientFormValues) => {
			try {
				setLoading(true);
				const customFieldFormValues =
					formValues.custom_field_form_values;
				let clientRequest = new VendorClientEditOrAddRequest(
					formValues
				);
				clientRequest.billing_address = new Address();
				clientRequest.billing_address.address_line_1 =
					formValues.street ?? "";
				clientRequest.billing_address.city = formValues.city ?? "";
				clientRequest.billing_address.country =
					formValues.country ?? "";
				clientRequest.billing_address.pincode =
					formValues.pincode ?? "";
				clientRequest.billing_address.state = formValues.state ?? "";
				clientRequest.billing_address.phone = formValues.phone ?? "";

				if (customFieldFormValues && customFieldList?.length > 0) {
					clientRequest = {
						...clientRequest,
						custom_fields: processCustomFieldFormValues(
							customFieldFormValues,
							customFieldList
						)
					};
				}
				clientRequest = {
					...clientRequest,
					partner_manager_id: formValues.partner_manager_id ?? null
				};
				if (props.currentActiveVendorClient) {
					props.handleEditVendorClient &&
						(await props.handleEditVendorClient(
							props.currentActiveVendorClient.id,
							clientRequest
						));
				} else {
					props.handleAddVendorClient &&
						(await props.handleAddVendorClient(clientRequest));
				}
				handleClose();
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		},
		[
			props.currentActiveVendorClient,
			props.handleAddVendorClient,
			props.handleEditVendorClient
		]
	);

	const sections: Array<IFormSectionProps> = [
		{
			meta: {
				formItemLayout: [5, 19],
				fields: [
					{
						key: "name",
						label: "Name",
						widget: EWidgets.INPUT,
						widgetProps: {
							width: "full",
							placeholder: "Please enter client name"
						},
						required: true,
						message: "Please enter client name",
						hasFeedback: true,
						rules: [
							{
								whitespace: true,
								message: "Please enter client name"
							}
						]
					},
					...customFieldList
						?.map(customField => {
							const ClientFormItem = getClientFormItem({
								customFieldId: customField.system_field_id,
								currentActiveVendorClient:
									props.currentActiveVendorClient,
								isBulkUpdate: false,
								required:
									customField.mandatory_field_details?.for_ui,
								currentActiveUserId: currentActiveUser.id
							});

							const values =
								props.currentActiveVendorClient?.custom_fields?.find(
									field =>
										field.display_name ===
										customField.display_name
								)?.values;

							return (
								ClientFormItem ?? {
									key: `custom_client_form_item_${customField.id}`,
									render: () => (
										<ZRenderCustomFieldFormItem
											form={form}
											customField={customField}
											isLoading={loading}
											values={values}
										/>
									)
								}
							);
						})
						.flat()
				]
			}
		}
	];
	const formProps: IFormProps = {
		onFinish: onFormSubmit,
		key: "AddorEditClientForm",
		sections,
		loading: loading,
		submitButtonText: "Save",
		isStickFooter: true,
		form,
		isCancelAllowed: true,
		onCancel: handleClose,
		isRightFooter: true,
		shouldResetFormFields: true
	};

	useEffect(() => {
		if (props.currentActiveVendorClient) {
			const initialFormValues = {
				name: props.currentActiveVendorClient.name,
				phone: props.currentActiveVendorClient.billing_address?.phone,
				partner_manager_id:
					props.currentActiveVendorClient.partner_manager?.id,
				owner_user_id: props.currentActiveVendorClient.owner_user_id,
				fax: props.currentActiveVendorClient.fax,
				website: props.currentActiveVendorClient.website,
				linked_in_url: props.currentActiveVendorClient.linked_in_url,
				facebook_url: props.currentActiveVendorClient.facebook_url,
				twitter_url: props.currentActiveVendorClient.twitter_url,
				company_type: props.currentActiveVendorClient.company_type,
				street: props.currentActiveVendorClient.billing_address
					?.address_line_1,
				city: props.currentActiveVendorClient.billing_address?.city,
				state: props.currentActiveVendorClient.billing_address?.state,
				country:
					props.currentActiveVendorClient.billing_address?.country,
				pincode:
					props.currentActiveVendorClient.billing_address?.pincode,
				custom_field_form_values: {}
			};
			props.currentActiveVendorClient?.custom_fields
				?.filter(field => !field.system_field_id)
				.forEach(field => {
					const isDateCustomField =
						field.field_type === CustomFieldType.DateField;
					const initialValue = field.values
						? isDateCustomField && field.values[0]
							? moment(field.values[0])
							: field.values[0]
						: undefined;
					initialFormValues.custom_field_form_values[field.id] =
						initialValue;
				});
			form.setFieldsValue(initialFormValues);
		}
		dispatch(VendorUsersActionCreators.getVendorUserList());
	}, [props.currentActiveVendorClient]);

	return (
		<ZDrawer
			visible={props.visible}
			closable={true}
			destroyOnClose={true}
			onClose={handleClose}
			title={
				props.currentActiveVendorClient ? "Edit Client" : "Add Client"
			}
			removeDefaultPadding={true}
		>
			<ZForm {...formProps} className="py-4" />
		</ZDrawer>
	);
};

export default React.memo(AddOrEditClientDrawer);
