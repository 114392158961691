import React, { useState } from "react";
import { ConnectedEmails } from "@zomentum/contracts/dist/Settings";
import ZTooltip from "@zomentum/atoms/dist/ZTooltip";
import Select, { RefSelectProps } from "antd/lib/select";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "@zomentum/utils";
import { IUserSelectProps } from "./interface";
import { GlobalState } from "@/Reducers/interface";
import { VendorUserRole } from "@zomentum/contracts";

const UserSelect: React.FC<IUserSelectProps> = React.forwardRef(
	(props, ref) => {
		const selectRef = ref as React.RefObject<RefSelectProps>;
		const [customEmails, _setCustomEmails] = useState<string[]>([]);
		const [customDomainEmails, _setCustomDomainEmails] = useState<
			ConnectedEmails[]
		>([]);

		const {
			optGroup,
			filterCurrentUser,
			allowEmailIntegratedUsers,
			allowDKIMEmails,
			...selectProps
		} = props;
		const {
			companyUsersList,
			userId
			// email: integratedEmailUsers,
			// dkim_verification
		} = useSelector((state: GlobalState) => ({
			companyUsersList: state.vendorUser.vendorUserList,
			userId: state.vendorUser.vendorUserData.id
			// email: state.vendorUser.userCompany.integrations.email,
			// dkim_verification:
			// 	state.user.userCompany?.settings?.dkim_verification
		}));

		const usersList = filterCurrentUser
			? companyUsersList.filter(user => user.id !== userId)
			: companyUsersList;

		// const integratedEmails = integratedEmailUsers.map(user => user.email);
		const integratedEmails: string[] = [];

		// useEffect(() => {
		// 	if (allowDKIMEmails) {
		// 		const customEmails = dkim_verification?.email
		// 			.filter(email => email.status === DKIMStatus.Success)
		// 			.map(email => email.email);

		// 		const customDomainEmails = flatMap(
		// 			dkim_verification?.domain.filter(
		// 				domain => domain.status === DKIMStatus.Success
		// 			),
		// 			domain => domain.connected_emails
		// 		).filter(
		// 			connectedEmail =>
		// 				connectedEmail.status === DKIMStatus.Success
		// 		);

		// 		if (customEmails) setCustomEmails(customEmails);
		// 		if (customDomainEmails)
		// 			setCustomDomainEmails(customDomainEmails);
		// 	}
		// }, []);

		const shouldShowVerifiedEmails =
			!!customEmails.length || !!customDomainEmails.length;

		return !!props.optGroup ? (
			<Select<string>
				ref={selectRef}
				getPopupContainer={trigger => trigger.parentNode}
				{...selectProps}
			>
				{props.optGroup}
				<Select.OptGroup label="Users">
					{usersList.map((companyUser, index) => {
						if (
							!integratedEmails.includes(companyUser.email) &&
							allowEmailIntegratedUsers
						) {
							return (
								<Select.Option
									disabled={true}
									key={index}
									value={companyUser.id}
								>
									<ZTooltip
										showtooltip={true}
										placement="left"
										title="This user has not connected their inbox"
									>
										<span aria-disabled>
											{`${capitalizeFirstLetter(
												companyUser.name.full
											)} <${companyUser.email}>`}
										</span>
									</ZTooltip>
								</Select.Option>
							);
						} else {
							return (
								<Select.Option
									disabled={companyUser.is_disabled}
									key={index}
									value={companyUser.id}
								>
									{companyUser.is_disabled ? (
										<ZTooltip
											showtooltip
											title="Inactive user"
										>
											<span aria-disabled>
												{`${capitalizeFirstLetter(
													companyUser.name.full
												)} <${companyUser.email}>`}{" "}
												( Inactive user )
											</span>
										</ZTooltip>
									) : (
										<span>{`${capitalizeFirstLetter(
											companyUser.name.full
										)} <${companyUser.email}>`}</span>
									)}
								</Select.Option>
							);
						}
					})}
				</Select.OptGroup>
				{props.allowDKIMEmails && shouldShowVerifiedEmails && (
					<Select.OptGroup label="Verified emails">
						{customEmails.map(email => {
							return (
								<Select.Option key={email} value={email}>
									<span>{email}</span>
								</Select.Option>
							);
						})}

						{customDomainEmails.map(email => {
							return (
								<Select.Option
									key={email.name}
									value={email.email}
								>
									<span>{`${email.name}<${email.email}>`}</span>
								</Select.Option>
							);
						})}
					</Select.OptGroup>
				)}
			</Select>
		) : (
			<Select<string>
				ref={selectRef}
				data-testid="user-select"
				getPopupContainer={trigger => trigger.parentNode}
				{...selectProps}
			>
				{usersList.map((companyUser, index) => (
					<Select.Option
						disabled={
							companyUser.is_disabled ||
							companyUser.role ===
								VendorUserRole.VendorApiUserRole
						}
						key={index}
						value={companyUser.id}
					>
						{companyUser.is_disabled ? (
							<ZTooltip showtooltip title="Inactive user">
								<span>
									{`${capitalizeFirstLetter(
										companyUser.name.full
									)} <${
										companyUser.email
									}> ( Inactive user )`}
								</span>
							</ZTooltip>
						) : (
							`${capitalizeFirstLetter(companyUser.name.full)} <${
								companyUser.email
							}>`
						)}
					</Select.Option>
				))}
			</Select>
		);
	}
);

export default UserSelect;
