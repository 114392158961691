'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index = require('../../../contracts/index.js');

var _a;
var ModuleClassMap = (_a = {}, _a[index.Modules.ALIGN] = "marketplace", _a[index.Modules.OPPORTUNITY] = "opportunities", _a[index.Modules.DOCUMENT] = "file", _a[index.Modules.INTEGRATION] = "integrations", _a);

exports.ModuleClassMap = ModuleClassMap;
