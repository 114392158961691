import React, { useEffect } from "react";
import EmailThreadActionCreators from "@/Actions/Others/EmailThread";
import { ReactComponent as EmptyEmailThreadMessagesIcon } from "@/Assets/Others/EmailThread/EmptyEmailThreadMessagesIcon.svg";
import useDispatch from "@/Components/Utils/useDispatch";
import { GlobalState } from "@/Reducers/interface";
import {
	ZomentumEntities,
	ZomentumEntityRequest,
	ZomentumRoutes
} from "@zomentum/contracts";
import {
	DownloadEmailThreadMessageFileRequest,
	EmailThreadFile,
	EmailThreadMessage,
	EmailThreadParticipant,
	EmailThreadRecipients
} from "@zomentum/contracts/dist/Others/EmailThread";
import ZEmailThread from "@zomentum/organisms/dist/ZEmailThread";
import Modal from "antd/lib/modal";
import Skeleton from "antd/lib/skeleton";
import publicIp from "public-ip";
import { useSelector } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router-dom";
import EmailThreadMessageDrawer from "./Drawers/EmailThreadMessageDrawer";

export interface Props extends RouteComponentProps {
	readonly currentActiveEmailThread: EmailThreadMessage | null;
}

export const EmailThread: React.FC<Props> = props => {
	const {
		isLoading,
		emailThreadMessageDrawerOpen,
		emailThreadMessages,
		integrations,
		userData
	} = useSelector((state: GlobalState) => {
		const userData = state.vendorUser.vendorUserData;
		const integrations = state.vendorUser.vendorUserIntegration;
		return {
			isLoading: state.emailThread.isLoading,
			userData,
			integrations,
			emailThreadMessageDrawerOpen:
				state.emailThread.emailThreadMessageDrawerOpen,
			emailThreadMessages: state.emailThread.emailThreadMessages
		};
	});
	const history = useHistory();

	const dispatch = useDispatch();
	const resetEmailThreadsDispatch = () =>
		dispatch(EmailThreadActionCreators.resetEmailThreads());
	const emailThreadMessageDrawerClosedDispatch = () =>
		dispatch(EmailThreadActionCreators.emailThreadMessageDrawerClosed());

	useEffect(() => {
		if (props.currentActiveEmailThread) {
			getEmailThreadMessages(
				new ZomentumEntityRequest(
					ZomentumEntities.EmailThread,
					props.currentActiveEmailThread.id,
					props.currentActiveEmailThread.account_id
				)
			);
		}
		if (emailThreadMessageDrawerOpen) {
			emailThreadMessageDrawerClosedDispatch();
		}

		return () => {
			resetEmailThreadsDispatch();
		};
	}, []);

	useEffect(() => {
		if (props.currentActiveEmailThread) {
			getEmailThreadMessages(
				new ZomentumEntityRequest(
					ZomentumEntities.EmailThread,
					props.currentActiveEmailThread.id,
					props.currentActiveEmailThread.account_id
				)
			);
		} else if (!props.currentActiveEmailThread) {
			resetEmailThreadsDispatch();
		}
	}, [props.currentActiveEmailThread]);

	const showEmailThreadMessageDrawer = () => {
		if (
			userData &&
			integrations.email.findIndex(
				email => email.user_id === userData.id
			) > -1
		) {
			dispatch(
				EmailThreadActionCreators.emailThreadMessageDrawerOpened()
			);
		} else {
			Modal.confirm({
				title: "You must authenticate your email account before you can send an email",
				content:
					"If you click on OK, you will be redirected to the settings page where you can authenticate your email account",
				onOk: () => {
					history.push(ZomentumRoutes.EmailSettings);
				}
			});
		}
	};

	const closeEmailThreadMessageDrawer = () => {
		emailThreadMessageDrawerClosedDispatch();
	};

	const getEmailThreadMessages = async (
		zomentumEntityRequest: ZomentumEntityRequest
	) => {
		dispatch(EmailThreadActionCreators.showEmailThreadLoader());
		try {
			const IPv4 = await publicIp.v4();
			await dispatch(
				EmailThreadActionCreators.getEmailThreadMessages(
					zomentumEntityRequest,
					IPv4
				)
			);
		} catch (error) {
			console.error(error);
		} finally {
			dispatch(EmailThreadActionCreators.hideEmailThreadLoader());
		}
	};

	const downloadAttachment = async (file: EmailThreadFile) => {
		await dispatch(
			EmailThreadActionCreators.downloadEmailThreadMessageFile(
				new DownloadEmailThreadMessageFileRequest(file)
			)
		);
	};
	const allEmailRecipients: EmailThreadRecipients[] =
		new Array<EmailThreadRecipients>();

	const defaultRecipients: string[] | null = props.currentActiveEmailThread
		? props.currentActiveEmailThread.participants?.length
			? props.currentActiveEmailThread.participants.reduce(
					(final: string[], current: EmailThreadParticipant) => {
						if (
							current.email &&
							props.currentActiveEmailThread?.email_ids_to_fetch.includes(
								current.email
							) &&
							final.indexOf(current.email) === -1
						) {
							final.push(current.email);
						}

						return final;
					},
					[]
			  )
			: props.currentActiveEmailThread.from &&
			  props.currentActiveEmailThread.from.email &&
			  props.currentActiveEmailThread.from.email.length > 0
			? [props.currentActiveEmailThread.from.email]
			: null
		: null;

	return (
		<div className="email-thread-container">
			<EmailThreadMessageDrawer
				visible={emailThreadMessageDrawerOpen}
				onClose={closeEmailThreadMessageDrawer}
				isNewThread={false}
				isDocumentMessage={false}
				defaultRecipients={defaultRecipients}
				emailThreadMessage={props.currentActiveEmailThread}
				useTemplate={false}
				partnerId={null}
				opportunityId={null}
				allEmailRecipients={allEmailRecipients}
			/>
			<Skeleton
				loading={isLoading || !props.currentActiveEmailThread}
				avatar
				active
				paragraph={{ rows: 2 }}
				className="email-thread-messages-skeleton"
			>
				{emailThreadMessages.length === 0 && (
					<div className="drawer-form-container align-center margin-top-24px">
						<EmptyEmailThreadMessagesIcon />
						<div className="empty-component-text margin-top-24px">
							You have no emails to display in this thread
						</div>
					</div>
				)}
				<ZEmailThread
					threads={emailThreadMessages.map(emailThreadMessage => ({
						id: emailThreadMessage.id,
						from: emailThreadMessage.from,
						to: emailThreadMessage.to,
						cc: emailThreadMessage.cc,
						bcc: emailThreadMessage.bcc,
						replyTo: emailThreadMessage.reply_to,
						date: emailThreadMessage.date_time,
						body: emailThreadMessage.body,
						subject: emailThreadMessage.subject,
						snippet: emailThreadMessage.snippet,
						files: emailThreadMessage.files,
						onReply: showEmailThreadMessageDrawer,
						onDownload: downloadAttachment
					}))}
				/>
			</Skeleton>
		</div>
	);
};

export default EmailThread;
