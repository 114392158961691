import axios, { AxiosResponse } from "axios";

import {
	getRequest,
	postRequest,
	deleteRequest,
	putRequest
} from "@zomentum/utils/dist/Service/BackendService";
import { DeletedRecord } from "@zomentum/contracts/dist";
import { getTableQueryFilters } from "@zomentum/contracts/dist/Utils";
import { MergeTagVariable } from "@zomentum/contracts/dist/Documents";
import {
	VendorEmailTemplateRequest,
	VendorEmailTemplatesResponse,
	VendorEmailTemplate,
	VendorEmailTemplateGetRequest,
	VendorCustomTemplateType,
	VendorPreviewEmailTemplateRequest
} from "@zomentum/contracts/dist/Vendor/EmailTemplate";

const getAllTemplatesUrl = "/v2/email/templates";
const getOneTemmplateUrl = "/v2/email/templates/{template_id}";
const addEmailTemplatesUrl = "/v2/email/templates";
const deleteEmailTemplateUrl = "/v2/email/templates/{template_id}";
const updateEmailTemplateUrl = "/v2/email/templates/{template_id}";
const fetchMergeTagVariables = "/v2/emails/merge_tags";
const previewEmailtemplateUrl = "/v2/email/templates/preview";
const searchEmailTemplatesUrl = "v2/email/templates/search";

export const searchEmailTemplates = async (
	keyword = ""
): Promise<VendorEmailTemplatesResponse> => {
	const url = `${searchEmailTemplatesUrl}?q=${keyword}`;
	try {
		const response: AxiosResponse<VendorEmailTemplatesResponse> =
			await axios(getRequest(url, null, true, false, false));
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const getAllTemplates = async (
	getEmailTemplateRequest: VendorEmailTemplateGetRequest
): Promise<VendorEmailTemplatesResponse> => {
	let url = getAllTemplatesUrl;
	if (getEmailTemplateRequest.filters.length > 0) {
		const appliedFilters = getTableQueryFilters(
			getEmailTemplateRequest.filters,
			false
		);
		url =
			url +
			"?" +
			appliedFilters +
			(getEmailTemplateRequest.keywords &&
			getEmailTemplateRequest.keywords.length > 0
				? `&q=${getEmailTemplateRequest.keywords}`
				: "");
	} else {
		url =
			url +
			(getEmailTemplateRequest.keywords &&
			getEmailTemplateRequest.keywords.length > 0
				? `?q=${getEmailTemplateRequest.keywords}`
				: "");
	}
	try {
		const response: AxiosResponse<VendorEmailTemplatesResponse> =
			await axios(getRequest(url, null, true, false, false));
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const addEmailTemplate = async (
	addEmailTemplateRequest: VendorEmailTemplateRequest
): Promise<VendorEmailTemplate> => {
	try {
		const response: AxiosResponse<VendorEmailTemplate> = await axios(
			postRequest(
				addEmailTemplatesUrl,
				addEmailTemplateRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const deleteEmailTemplate = async (
	templateId: string
): Promise<DeletedRecord> => {
	try {
		const response: AxiosResponse<DeletedRecord> = await axios(
			deleteRequest(
				deleteEmailTemplateUrl.replace("{template_id}", templateId),
				null,
				true,
				false
			)
		);
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const getOneEmailTemplate = async (
	templateId: string
): Promise<VendorEmailTemplate> => {
	try {
		const response: AxiosResponse<VendorEmailTemplate> = await axios(
			getRequest(
				getOneTemmplateUrl.replace("{template_id}", templateId),
				null,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateEmailTemplate = async (
	emailTemplateId: string,
	updateEmailTemplateRequest: VendorEmailTemplateRequest
): Promise<VendorEmailTemplate> => {
	try {
		const response: AxiosResponse<VendorEmailTemplate> = await axios(
			putRequest(
				updateEmailTemplateUrl.replace(
					"{template_id}",
					emailTemplateId
				),
				updateEmailTemplateRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const getMergeTagVariables = async (): Promise<MergeTagVariable[]> => {
	try {
		const response: AxiosResponse<MergeTagVariable[]> = await axios(
			getRequest(fetchMergeTagVariables, null, true, false, false)
		);
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const fetchSystemEmailTemplateByType = async (
	_customTemplateType: VendorCustomTemplateType
): Promise<VendorEmailTemplatesResponse> => {
	try {
		const url = getAllTemplatesUrl;
		const response: AxiosResponse<VendorEmailTemplatesResponse> =
			await axios(getRequest(url, null, true, false, false));
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const previewEmailTemplate = async (
	previeEmailTemplateRequest: VendorPreviewEmailTemplateRequest
): Promise<any> => {
	try {
		const reponse: AxiosResponse<any> = await axios(
			postRequest(
				previewEmailtemplateUrl,
				previeEmailTemplateRequest,
				true,
				false,
				false
			)
		);
		return reponse.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};
