'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.CheckListBarMoreOptions = void 0;
(function (CheckListBarMoreOptions) {
  CheckListBarMoreOptions["HELP_CENTRE"] = "help_centre";
  CheckListBarMoreOptions["SKIP_MODULE"] = "skip_module";
  CheckListBarMoreOptions["QUIT_TUTORIAL"] = "quit_tutorial";
})(exports.CheckListBarMoreOptions || (exports.CheckListBarMoreOptions = {}));
var MainModulesIds = ["main_0", "main_1", "main_2"];

exports.MainModulesIds = MainModulesIds;
