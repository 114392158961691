'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function getWindow() {
  var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  return {
    w: w,
    h: h
  };
}
var safe = function (sum) {
  return sum < 0 ? 0 : sum;
};

exports.getWindow = getWindow;
exports.safe = safe;
