'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var React = require('react');

var useBreakpoint = function (query) {
  var _a = React.useState(false),
    queryMatch = _a[0],
    setQueryMatch = _a[1];
  React.useEffect(function () {
    var mql = window.matchMedia(query);
    // To check whether event listener is attached or not
    var isAttached = false;
    var handleQueryListener = function () {
      setQueryMatch(mql.matches);
    };
    if (window && window.matchMedia(query)) {
      setQueryMatch(mql.matches);
      isAttached = true;
      mql.addListener(handleQueryListener);
    }
    return function () {
      if (isAttached) {
        mql.removeListener(handleQueryListener);
      }
    };
  }, [query]);
  return queryMatch;
};
var useBreakpoint$1 = useBreakpoint;

exports["default"] = useBreakpoint$1;
