import React from "react";
import { EmailTemplateFileDetails } from "@zomentum/contracts/dist/EmailTemplates";
import {
	EmailThreadMessage,
	EmailThreadRecipients,
	SendEmailThreadMessageFormValues
} from "@zomentum/contracts/dist/Others/EmailThread";
import { Form } from "@ant-design/compatible";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { DrawerProps } from "antd/lib/drawer";

import { useFeatureFlag } from "@zomentum/hooks";
import EmailThreadMessageDrawerFuncComp from "./EmailThreadMessageDrawerFuncComp";
import EmailThreadMessageDrawerClassComp from "./EmailThreadMessageDrawerClassComp";

interface Props
	extends DrawerProps,
		FormComponentProps<SendEmailThreadMessageFormValues> {
	readonly isNewThread: boolean;
	readonly isDocumentMessage: boolean;
	readonly defaultRecipients: string[] | null;
	readonly emailThreadMessage: EmailThreadMessage | null;
	readonly ignoreAppendEmailSignature?: boolean;
	readonly defaultSubject?: string | null;
	readonly bodyPlaceholder?: string;
	readonly templateBody?: string | null;
	readonly templateAttachments?: EmailTemplateFileDetails[] | null;
	readonly partnerId?: string | null;
	readonly opportunityId?: string | null;
	readonly clientId?: string | null;
	readonly useTemplate: boolean;
	readonly isFromDocumentsPage?: boolean;
	readonly allEmailRecipients?: EmailThreadRecipients[];
	readonly isFromEmailTemplatesPage?: boolean;
	readonly onSubmit?: () => void;
	readonly onClose?: () => void;
	readonly refreshSalesActivities?: (
		shouldRefreshSalesActivities: boolean
	) => void;
	readonly sendDocumentFromConnectedEmailFlag?: boolean;
	readonly isFromVendorEmailTemplatespage?: boolean;
}

const EmailThreadMessageDrawer: React.FC<Props> = props => {
	// changes should be made to both the class and functional based component
	const isEmailThreadDrawerFnBased = useFeatureFlag(
		"email_thread_message_drawer_pa"
	);
	return isEmailThreadDrawerFnBased ? (
		<EmailThreadMessageDrawerFuncComp {...props} />
	) : (
		<EmailThreadMessageDrawerClassComp {...props} />
	);
};

export default Form.create<Props>({
	name: "email_thread_messages_form"
})(EmailThreadMessageDrawer);
