import { getPartnerListPagePost } from "@/Services/Vendor/Partner";
import ZVendorPartnerSelect from "@/V2Components/UI/organisms/ZPartnerSelect";
import { ZomentumEntities } from "@zomentum/contracts/dist/common";
import {
	SalesActivityAutomationActionUpdatableFieldValueType,
	SalesActivityAutomationMetadataFilterOperator
} from "@zomentum/contracts/dist/Others/SalesActivity";
import { ListPagePostRequest } from "@zomentum/contracts/dist/UI";
import { ChildEntityHydrationParams } from "@zomentum/contracts/dist/Utils";
import { VendorOpportunity } from "@zomentum/contracts/dist/Vendor/Opportunity";
import { VendorPartner } from "@zomentum/contracts/dist/Vendor/Partner";
import { useComponentDidUpdate } from "@zomentum/hooks";
import React, { ReactText, useState } from "react";
import { IVendorPartnerDrawerReturnType } from "./interface";

const useVendorPartnerDrawer = (
	visible: boolean,
	currentActiveOpportunity?: VendorOpportunity | null,
	currentActivePartner?: VendorPartner | null
): IVendorPartnerDrawerReturnType => {
	const [isPartnersLoading, setIsPartnersLoading] = useState(false);
	const [partners, setPartners] = useState<VendorPartner[]>([]);
	const fetchPartners = async () => {
		setIsPartnersLoading(true);
		const filterCriteria = [
			{
				field_display_name: "Status",
				field_name: "status",
				filter_entity_type: ZomentumEntities.VendorPartner,
				filter_operator: {
					display_value: "Is In List",
					id_value:
						SalesActivityAutomationMetadataFilterOperator.IsInList,
					group_type: null,
					value_type: null
				},
				values: [
					{
						display_value: "Accepted",
						id_value: "accepted",
						value_type:
							SalesActivityAutomationActionUpdatableFieldValueType.Fixed
					},
					{
						display_value: "Suspended",
						id_value: "suspended",
						value_type:
							SalesActivityAutomationActionUpdatableFieldValueType.Fixed
					}
				],
				linked_entity_type: null
			}
		];
		try {
			const response = await getPartnerListPagePost(
				new ListPagePostRequest({
					count_query_flag: true,
					filter_criterion: filterCriteria,
					sort_criterion: [],
					page_no: 1,
					page_size: 10,
					includedChildEntities: [
						ChildEntityHydrationParams.USERS_ALL
					]
				})
			);
			let partners = response.data;
			if (currentActiveOpportunity?.partner_company) {
				partners = partners.filter(
					value =>
						value.id !==
						currentActiveOpportunity?.partner_company?.id
				);
				partners = [
					...partners,
					currentActiveOpportunity?.partner_company
				];
			}
			if (currentActivePartner) {
				partners = partners.filter(
					value => value.id !== currentActivePartner?.id
				);
				partners = [...partners, currentActivePartner];
			}
			setPartners(partners);
		} catch (error) {
			console.error(error);
		} finally {
			setIsPartnersLoading(false);
		}
	};
	const renderVendorPartnerSelectField = (
		value: ReactText,
		onChange: (value: ReactText | React.ReactText[]) => void,
		disabled?: boolean
	) => (
		<ZVendorPartnerSelect
			value={value}
			onChange={onChange}
			defaultOptions={partners}
			placeholder="Select partner"
			showSuspendedPartners={true}
			disabled={disabled}
		/>
	);
	useComponentDidUpdate(() => {
		if (!visible) {
			return;
		}
		fetchPartners();
	}, [visible]);
	return {
		renderVendorPartnerSelectField,
		isPartnersLoading
	};
};

export default useVendorPartnerDrawer;
