'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var common = require('../../common.js');
var index = require('../../Settings/index.js');

exports.VendorUserPermissionRole = void 0;
(function (VendorUserPermissionRole) {
  VendorUserPermissionRole["VENDOR_PRIMARY"] = "VENDOR_PRIMARY";
  VendorUserPermissionRole["VENDOR_ADMIN"] = "VENDOR_ADMIN";
  VendorUserPermissionRole["USER_MANAGEMENT"] = "USER_MANAGEMENT";
  VendorUserPermissionRole["CRM_ADMIN"] = "CRM_ADMIN";
})(exports.VendorUserPermissionRole || (exports.VendorUserPermissionRole = {}));
exports.VendorUserRole = void 0;
(function (VendorUserRole) {
  VendorUserRole["VendorPrimaryRole"] = "VENDOR_PRIMARY";
  VendorUserRole["VendorSecondaryRole"] = "VENDOR_SECONDARY";
  VendorUserRole["VendorUserRole"] = "VENDOR_USER";
  VendorUserRole["VendorAdminRole"] = "ADMIN";
  VendorUserRole["VendorClientUserRole"] = "CLIENT_USER";
  VendorUserRole["VendorApiUserRole"] = "VENDOR_API_ACCESS_USER";
  VendorUserRole["VendorCustomRole"] = "CUSTOM";
})(exports.VendorUserRole || (exports.VendorUserRole = {}));
var VendorUserSettings = /** @class */function () {
  function VendorUserSettings() {
    this.time_zone = null;
    this.time_zone_offset = 0;
    this.preference_settings = null;
  }
  return VendorUserSettings;
}();
var VendorUserData = /** @class */function () {
  function VendorUserData() {
    this.vendor_company_id = "";
    this.role = null;
    this.roles = new Array();
    this.created = new Date();
    this.email = "";
    this.id = "";
    this.sync_data = new Array();
    this.name = new common.Name();
    this.phone = "";
    this.updated = new Date();
    this.user_settings = new VendorUserSettings();
    this.is_disabled = false;
    this.document_last_used_from_address = "";
    this.deleted = false;
    this.is_view_tutorial_shown = false;
  }
  return VendorUserData;
}();
var VendorCompanySettings = /** @class */function () {
  function VendorCompanySettings() {
    this.currency = "";
    this.locale = "";
    this.time_zone = null;
  }
  return VendorCompanySettings;
}();
var VendorUsers = /** @class */function () {
  function VendorUsers() {
    this.data = new Array();
  }
  return VendorUsers;
}();
var VendorUserCompany = /** @class */function () {
  function VendorUserCompany() {
    this.billing_address = new common.Address();
    this.created = new Date();
    this.sub_domain = "";
    this.id = "";
    this.logo = "";
    this.favicon = "";
    this.name = "";
    this.settings = new VendorCompanySettings();
    this.updated = new Date();
    this.facebook_url = "";
    this.twitter_url = "";
    this.website_url = "";
    this.vendor_users = new VendorUsers();
    this.vendor_user_intergration = new VendorUserIntegration();
  }
  return VendorUserCompany;
}();
var VendorUserIntegration = /** @class */function () {
  function VendorUserIntegration() {
    this.email = new Array();
    this.hubspot = new index.VendorHubspotIntegration();
  }
  return VendorUserIntegration;
}();
var GetVendorUserCompanyRequest = /** @class */function () {
  function GetVendorUserCompanyRequest() {
    this.included_child_entities = new Array();
  }
  return GetVendorUserCompanyRequest;
}();
var VendorUsersCount = /** @class */function () {
  function VendorUsersCount() {
    this.all = 0;
  }
  return VendorUsersCount;
}();
var VendorUserListRequest = /** @class */function () {
  function VendorUserListRequest() {
    this.data = new Array();
    this.filtered_count_map = null;
  }
  return VendorUserListRequest;
}();
var AddVendorUserRequest = /** @class */function () {
  function AddVendorUserRequest() {
    this.email = "";
    this.name = new common.Name();
    this.phone = "";
  }
  return AddVendorUserRequest;
}();
var AddVendorAPIUserRequest = /** @class */function () {
  function AddVendorAPIUserRequest() {
    this.name = new common.Name();
    this.default_owner_id = "";
  }
  return AddVendorAPIUserRequest;
}();
var VendorUserFormValues = /** @class */function () {
  function VendorUserFormValues() {
    this.first_name = "";
    this.last_name = "";
    this.email = "";
    this.phone = "";
    this.default_owner = "";
    this.role = undefined;
  }
  return VendorUserFormValues;
}();
var EditVendorUserRequest = /** @class */function () {
  function EditVendorUserRequest() {
    this.id = "";
    this.email = "";
    this.name = new common.Name();
    this.phone = "";
  }
  return EditVendorUserRequest;
}();

exports.AddVendorAPIUserRequest = AddVendorAPIUserRequest;
exports.AddVendorUserRequest = AddVendorUserRequest;
exports.EditVendorUserRequest = EditVendorUserRequest;
exports.GetVendorUserCompanyRequest = GetVendorUserCompanyRequest;
exports.VendorCompanySettings = VendorCompanySettings;
exports.VendorUserCompany = VendorUserCompany;
exports.VendorUserData = VendorUserData;
exports.VendorUserFormValues = VendorUserFormValues;
exports.VendorUserIntegration = VendorUserIntegration;
exports.VendorUserListRequest = VendorUserListRequest;
exports.VendorUserSettings = VendorUserSettings;
exports.VendorUsers = VendorUsers;
exports.VendorUsersCount = VendorUsersCount;
