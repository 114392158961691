import React from "react";
import ReactDOM from "react-dom";
import PendingOpportunitiesModal from "./PendingOpportunityModal";
import ZMessage from "@zomentum/molecules/dist/ZMessage";
import { DefaultErrorMessage } from "@zomentum/contracts/dist/common";
import { NotificationModalType } from "@zomentum/contracts/dist/Others/NotificationFeed";
import ChangeSuggestionModal from "./ChangeSuggestionModal";

const showNotificationModal = (modalUri: string) => {
	const div = document.createElement("div");
	document.body.appendChild(div);
	const destroy = () => {
		const unmountResult = ReactDOM.unmountComponentAtNode(div);
		if (unmountResult && div.parentNode) {
			div.parentNode.removeChild(div);
		}
	};
	const [, type, id] = modalUri.split(":");
	switch (type) {
		case NotificationModalType.PendingOpportunities:
			ReactDOM.render(
				<PendingOpportunitiesModal id={id} onClose={destroy} />,
				div
			);
			break;
		case NotificationModalType.ChangeSuggestions:
			ReactDOM.render(
				<ChangeSuggestionModal id={id} onClose={destroy} />,
				div
			);
			break;
		default:
			return;
	}
};

export const handleNotificationMenuRedirect = (redirectUrl: string) => {
	if (redirectUrl && redirectUrl.length > 0) {
		if (
			redirectUrl.startsWith("https://") ||
			redirectUrl.startsWith("http://")
		)
			window.location.href = redirectUrl;
		else if (redirectUrl.startsWith("modal:")) {
			showNotificationModal(redirectUrl);
		}
	} else {
		ZMessage.error(DefaultErrorMessage);
	}
};
