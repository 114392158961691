'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var React = require('react');

var useComponentDidUpdate = function (effect, deps) {
  var hasMounted = React.useRef(false);
  React.useEffect(function () {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }
    effect();
  }, deps);
};
var useComponentDidUpdate$1 = useComponentDidUpdate;

exports["default"] = useComponentDidUpdate$1;
