'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../../node_modules/tslib/tslib.es6.mjs.js');

var Dialect = /** @class */function () {
  function Dialect() {
    this.lineterminator = "\n";
    this.delimiter = ",";
    this.quotechar = '"';
    this.doublequote = true;
    this.skipinitialspace = true;
    this.skipinitialrows = 0;
  }
  return Dialect;
}();
var regexForInt = /^\d+$/,
  regexForFloat = /^\d*\.\d+$|^\d+\.\d*$/;
function chomp(s, lineterminator) {
  if (s.charAt(s.length - lineterminator.length) !== lineterminator) {
    // Does not end with \n, just return string
    return s;
  } else {
    // Remove the \n
    return s.substring(0, s.length - lineterminator.length);
  }
}
function normalizeLineTerminator(csvString, dialect) {
  // Try to guess line terminator if it's not provided.
  if (!(dialect === null || dialect === void 0 ? void 0 : dialect.lineterminator)) {
    return csvString.replace(/(\r\n|\n|\r)/gm, "\n");
  }
  // if not return the string untouched.
  return csvString;
}
/**
 * This function takes a string(ideally a line of csv file) and tries to guess the separator type of the file
 */
function guessDelimiter(input) {
  var bestDelim, bestDelta, fieldCountPrevRow, maxFieldCount;
  var delimitersToGuess = [",", "\t", "|", ";"];
  for (var i = 0; i < delimitersToGuess.length; i++) {
    var delim = delimitersToGuess[i];
    var delta = 0,
      avgFieldCount = 0;
    fieldCountPrevRow = undefined;
    var preview = parse(input, {
      delimiter: delimitersToGuess[i]
    });
    for (var j = 0; j < preview.length; j++) {
      var fieldCount = preview[j].length;
      avgFieldCount += fieldCount;
      if (typeof fieldCountPrevRow === "undefined") {
        fieldCountPrevRow = fieldCount;
        continue;
      } else if (fieldCount > 0) {
        delta += Math.abs(fieldCount - fieldCountPrevRow);
        fieldCountPrevRow = fieldCount;
      }
    }
    if ((typeof bestDelta === "undefined" || delta <= bestDelta) && (typeof maxFieldCount === "undefined" || avgFieldCount > maxFieldCount) && avgFieldCount > 1.99) {
      bestDelta = delta;
      bestDelim = delim;
      maxFieldCount = avgFieldCount;
    }
  }
  return bestDelim;
}
/**
 * This function is part of csv.js -> https://github.com/okfn/csv.js/tree/master
 */
function parse(data, dialect) {
  if (!(data === null || data === void 0 ? void 0 : data.length)) return [];
  // When line terminator is not provided then we try to guess it
  // and normalize it across the file.
  if (!dialect || dialect && !dialect.lineterminator) {
    data = normalizeLineTerminator(data, dialect);
  }
  var options = tslib_es6.__assign(tslib_es6.__assign({}, new Dialect()), dialect);
  if (!(dialect === null || dialect === void 0 ? void 0 : dialect.delimiter)) {
    // only checking the first line in the file
    var delimiter = guessDelimiter(data.slice(0, data.indexOf("\n")));
    options.delimiter = delimiter !== null && delimiter !== void 0 ? delimiter : options.delimiter;
  }
  data = chomp(data, options.lineterminator);
  var cur = "",
    // The character we are currently processing.
    inQuote = false,
    fieldQuoted = false,
    field = "",
    // Buffer for building up the current field
    row = [],
    out = [],
    i;
  function processField(field) {
    var _a;
    var processedField = field;
    if (!fieldQuoted) {
      // If field is empty set to null
      if (field === "") {
        processedField = null;
        // If the field was not quoted and we are trimming fields, trim it
      } else if (options.skipinitialspace === true) {
        processedField = field.trim();
      }
      // Convert unquoted numbers to their appropriate types
      if (regexForInt.test(field)) {
        processedField = parseInt(field, 10);
      } else if (regexForFloat.test(field)) {
        processedField = parseFloat(field);
      }
    }
    return (_a = processedField === null || processedField === void 0 ? void 0 : processedField.toString()) !== null && _a !== void 0 ? _a : "null";
  }
  for (i = 0; i < data.length; i += 1) {
    cur = data.charAt(i);
    // If we are at a EOF or EOR
    if (inQuote === false && (cur === options.delimiter || cur === options.lineterminator)) {
      field = processField(field);
      // Add the current field to the current row
      row.push(field);
      // If this is EOR append row to output and flush row
      if (cur === options.lineterminator) {
        out.push(row);
        row = [];
      }
      // Flush the field buffer
      field = "";
      fieldQuoted = false;
    } else {
      // If it's not a quotechar, add it to the field buffer
      if (cur !== options.quotechar) {
        field += cur;
      } else {
        if (!inQuote) {
          // We are not in a quote, start a quote
          inQuote = true;
          fieldQuoted = true;
        } else {
          // Next char is quotechar, this is an escaped quotechar
          if (data.charAt(i + 1) === options.quotechar) {
            field += options.quotechar;
            // Skip the next char
            i += 1;
          } else {
            // It's not escaping, so end quote
            inQuote = false;
          }
        }
      }
    }
  }
  // Add the last field
  field = processField(field);
  row.push(field);
  out.push(row);
  // Expose the ability to discard initial rows
  if (options.skipinitialrows) out = out.slice(options.skipinitialrows);
  return out;
}

exports.parse = parse;
