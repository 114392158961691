import React, { useEffect, useState } from "react";
import { BrowserRouter, Redirect } from "react-router-dom";
import { Provider } from "react-redux";

import {
	ErrorBoundary as SentryErrorBoundary,
	Event,
	EventHint,
	init as SentryInit,
	Replay
} from "@sentry/react";

import "@ant-design/compatible/assets/index.css";
import "./App.less";

import store from "./redux-store";
import { ZomentumWindow } from "@zomentum/contracts";

import { message } from "antd";
import { Auth0Provider } from "@auth0/auth0-react";
import { CaptureConsole, Dedupe } from "@sentry/integrations";

import AppDashboard from "./Components/UI/Dashboard";
import shouldSentryLogMessage, { errors } from "@/V2Utils/commonErrors";
import { Auth0LoginState } from "@zomentum/contracts/dist/Login";
import ProposalBuilderWrapper from "@pages/ProposalBuilderWrapper";
import SalesActivityWrapper from "@pages/SalesActivity/SalesActivityWrapper";
import OnboardingWrapper from "@pages/OnboardingWrapper";
import { FlagsProviderComponent } from "@zomentum/utils";

declare let window: ZomentumWindow;

window.isLocalhost = Boolean(
	window.location.hostname === "localhost" ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === "[::1]" ||
		// 127.0.0.1/8 is considered localhost for IPv4.
		window.location.hostname.match(
			/^127(?:\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)){3}$/
		)
);

window.isDevelopment =
	process.env.REACT_APP_NODE_ENV === "development" || window.isLocalhost;

window.isStaging =
	process.env.REACT_APP_NODE_ENV === "staging" || window.isLocalhost;

const ErrorFallback = () => {
	useEffect(() => {
		if (process.env.REACT_APP_NODE_ENV === "production") {
			window.location.reload();
		}
	}, []);
	return <Redirect to="/error" />;
};

export interface Props {
	updateServiceWorker: (registration: ServiceWorkerRegistration) => void;
}

const App: React.FC<Props> = props => {
	useEffect(() => {
		initializeSentry();
		message.config({ maxCount: 2 });
	}, []);

	const initializeSentry = () => {
		if (
			process.env.NODE_ENV === "production" &&
			process.env.REACT_APP_SENTRY
		) {
			try {
				SentryInit({
					enabled: true,
					ignoreErrors: errors,
					dsn: process.env.REACT_APP_SENTRY,
					beforeSend: (event: Event, hint?: EventHint) => {
						const updatedEvent = { ...event };

						const extraArguments = updatedEvent?.extra
							?.arguments as
							| Array<Record<string, string | number>>
							| undefined;

						const errorsWithCode = extraArguments?.filter(
							extra => extra?.code === 400 || extra?.code === 401
						);

						if (!!errorsWithCode?.length) return null;

						const shouldLogMessage = shouldSentryLogMessage(
							updatedEvent?.message ?? "",
							JSON.stringify(
								updatedEvent?.extra?.arguments ??
									updatedEvent?.extra ??
									""
							)
						);

						if (!shouldLogMessage) return null;

						if (
							updatedEvent.logger === "console" &&
							updatedEvent.extra &&
							updatedEvent.message &&
							updatedEvent.message.includes("[object Object]")
						) {
							try {
								const argsAsJSON = JSON.stringify(
									updatedEvent.extra.arguments
								)?.slice(1, -1);
								updatedEvent.message = argsAsJSON;
							} catch (error) {
								console.error(error);
							}
						}

						updatedEvent.extra = {
							...updatedEvent.extra,
							hint: JSON.stringify(hint)
						};

						return updatedEvent;
					},
					release: process.env.REACT_APP_SHA,
					environment: process.env.REACT_APP_NODE_ENV,
					attachStacktrace: true,
					maxBreadcrumbs: 50,
					integrations: [
						new CaptureConsole({
							levels: ["error", "warn"]
						}),
						new Dedupe(),
						new Replay({
							// Additional SDK configuration goes in here, for example:
							maskAllText: true,
							blockAllMedia: true
						})
					],
					replaysSessionSampleRate: 0.1, // This sets the sample rate to be 10%. You may want this to be 100% while in development and sample at a lower rate in production
					replaysOnErrorSampleRate: 1.0 // If the entire session is not sampled, use this sample rate to sample sessions when an error occurs.
				});
			} catch (error) {
				console.error(error);
			}
		}
	};

	const isInIframe = () => {
		try {
			return window.origin !== window.parent.origin;
		} catch (error) {
			// if throws error on accessing parent, it is in iframe
			console.error(error);
			return true;
		}
	};

	const [auth0LoginState, setAuth0LoginState] = useState<Auth0LoginState>({});

	if (isInIframe()) {
		return <h1>Sorry! This site cannot be operated in an iframe</h1>;
	} else {
		return (
			<Auth0Provider
				domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ""}
				clientId={process.env.REACT_APP_AUTH0_CLIENT ?? ""}
				authorizationParams={{
					redirectUri: `${window.location.origin}/authorize`,
					scope: "openid profile",
					audience: process.env.REACT_APP_AUTH0_AUDIENCE ?? ""
				}}
				onRedirectCallback={appState =>
					setAuth0LoginState(appState as Auth0LoginState)
				}
			>
				<Provider store={store}>
					<BrowserRouter>
						<SentryErrorBoundary fallback={<ErrorFallback />}>
							<FlagsProviderComponent>
								<ProposalBuilderWrapper>
									<SalesActivityWrapper>
										<OnboardingWrapper>
											<AppDashboard
												updateServiceWorker={
													props.updateServiceWorker
												}
												auth0LoginState={
													auth0LoginState
												}
											/>
										</OnboardingWrapper>
									</SalesActivityWrapper>
								</ProposalBuilderWrapper>
							</FlagsProviderComponent>
						</SentryErrorBoundary>
					</BrowserRouter>
				</Provider>
			</Auth0Provider>
		);
	}
};

export default App;
