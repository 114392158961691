import {
	AutotaskConnectFormValues,
	AutotaskMapping,
	AutotaskTaxImportRequest,
	ConnectwiseTaxImport,
	ImportTaxRequest,
	KaseyaCredentialsTestRequest,
	RepairShoprCredentailsTestResponse,
	RepairShoprCredentialsTestRequest
} from "@zomentum/contracts/dist/Settings/index";
import {
	importTaxForAutotask,
	importTaxForConnectwise,
	testKaseyaCredentials,
	testRepairShoprCredentials,
	testSyncroCredentials
} from "@/Services/Settings/index";
import { Action, ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { SyncData } from "@zomentum/contracts";
import { GlobalState } from "@/Reducers/interface";
import {
	AutotaskIntegrationResponse,
	ConnectwiseCredentailsTestResponse,
	ConnectwiseCredentialsTestRequest,
	SyncroCredentailsTestResponse,
	SyncroCredentialsTestRequest
} from "@zomentum/contracts/dist/Settings";
import {
	getAutotaskStageMapping,
	resetPasswordEmail,
	testAutotaskCredentials,
	testConnectwiseCredentials
} from "@/Services/Settings";
import { SyncEntityRequest } from "@zomentum/contracts/dist/Others/RecentActivityFeed";
import { getUpdatedSyncData } from "@modules/sales-activity/dist/services/SalesActivity";

export enum SettingsActionTypes {
	SHOW_SETTINGS_LOADER = "SHOW_SETTINGS_LOADER",
	HIDE_SETTINGS_LOADER = "HIDE_SETTINGS_LOADER",
	SHOW_APP_UPDATE_BANNER = "SHOW_APP_UPDATE_BANNER",
	HIDE_APP_UPDATE_BANNER = "HIDE_APP_UPDATE_BANNER",
	ADD_CUSTOM_FIELD_LIST_REQUESTED = "ADD_CUSTOM_FIELD_LIST_REQUESTED",
	ADD_CUSTOM_FIELD_LIST_SUCCESSFUL = "ADD_CUSTOM_FIELD_LIST_SUCCESSFUL",
	ADD_CUSTOM_FIELD_LIST_FAILED = "ADD_CUSTOM_FIELD_LIST_FAILED",
	EDIT_CUSTOM_FIELD_LIST_REQUESTED = "EDIT_CUSTOM_FIELD_LIST_REQUESTED",
	EDIT_CUSTOM_FIELD_LIST_SUCCESSFUL = "EDIT_CUSTOM_FIELD_LIST_SUCCESSFUL",
	EDIT_CUSTOM_FIELD_LIST_FAILED = "EDIT_CUSTOM_FIELD_LIST_FAILED",
	CONNECTWISE_DRAWER_OPENED = "CONNECTWISE_DRAWER_OPENED",
	CONNECTWISE_DRAWER_CLOSED = "CONNECTWISE_DRAWER_CLOSED",
	AUTOTASK_DRAWER_OPENED = "AUTOTASK_DRAWER_OPENED",
	AUTOTASK_DRAWER_CLOSED = "AUTOTASK_DRAWER_CLOSED",
	ZAPIER_DRAWER_OPENED = "ZAPIER_DRAWER_OPENED",
	ZAPIER_DRAWER_CLOSED = "ZAPIER_DRAWER_CLOSED",
	KASEYA_DRAWER_OPENED = "KASEYA_DRAWER_OPENED",
	KASEYA_DRAWER_CLOSED = "KASEYA_DRAWER_CLOSED",
	INGRAM_DRAWER_OPENED = "INGRAM_DRAWER_OPENED",
	INGRAM_DRAWER_CLOSED = "INGRAM_DRAWER_CLOSED",
	TECH_DATA_DRAWER_OPENED = "TECH_DATA_DRAWER_OPENED",
	TECH_DATA_DRAWER_CLOSED = "TECH_DATA_DRAWER_CLOSED",
	D_AND_H_DRAWER_OPENED = "D_AND_H_DRAWER_OPENED",
	D_AND_H_DRAWER_CLOSED = "D_AND_H_DRAWER_CLOSED",
	SYNNEX_DRAWER_OPENED = "SYNNEX_DRAWER_OPENED",
	SYNNEX_DRAWER_CLOSED = "SYNNEX_DRAWER_CLOSED",
	CB_DRAWER_OPENED = "CB_DRAWER_OPENED",
	CB_DRAWER_CLOSED = "CB_DRAWER_CLOSED",
	ACCOUNT_SETTINGS_DRAWER_OPENED = "ACCOUNT_SETTINGS_DRAWER_OPENED",
	ACCOUNT_SETTINGS_DRAWER_CLOSED = "ACCOUNT_SETTINGS_DRAWER_CLOSED",
	COMMISSIONS_DRAWER_OPENED = "COMMISSIONS_DRAWER_OPENED",
	COMMISSIONS_DRAWER_CLOSED = "COMMISSIONS_DRAWER_CLOSED",
	SEND_PASSWORD_RESET_EMAIL_REQUESTED = "SEND_PASSWORD_RESET_EMAIL_REQUESTED",
	SEND_PASSWORD_RESET_EMAIL_FAILED = "SEND_PASSWORD_RESET_EMAIL_FAILED",
	SEND_PASSWORD_RESET_EMAIL_SUCCESSFULL = "SEND_PASSWORD_RESET_EMAIL_SUCCESSFULL",
	ADD_COMPANY_USER_DRAWER_OPENED = "ADD_COMPANY_USER_DRAWER_OPENED",
	ADD_COMPANY_USER_DRAWER_CLOSED = "ADD_COMPANY_USER_DRAWER_CLOSED",
	EDIT_COMPANY_USER_DRAWER_OPENED = "EDIT_COMPANY_USER_DRAWER_OPENED",
	EDIT_COMPANY_USER_DRAWER_CLOSED = "EDIT_COMPANY_USER_DRAWER_CLOSED",
	COMPANY_LOGO_MODAL_OPENED = "COMPANY_LOGO_MODAL_OPENED",
	COMPANY_LOGO_MODAL_CLOSED = "COMPANY_LOGO_MODAL_CLOSED",
	ADD_CUSTOM_FIELD_DRAWER_OPENED = "ADD_CUSTOM_FIELD_DRAWER_OPENED",
	ADD_CUSTOM_FIELD_DRAWER_CLOSED = "ADD_CUSTOM_FIELD_DRAWER_CLOSED",
	EDIT_CUSTOM_FIELD_DRAWER_OPENED = "EDIT_CUSTOM_FIELD_DRAWER_OPENED",
	EDIT_CUSTOM_FIELD_DRAWER_CLOSED = "EDIT_CUSTOM_FIELD_DRAWER_CLOSED",
	TEST_AUTOTASK_CREDENTIALS_REQUESTED = "TEST_AUTOTASK_CREDENTIALS_REQUESTED",
	TEST_AUTOTASK_CREDENTIALS_SUCCESSFUL = "TEST_AUTOTASK_CREDENTIALS_SUCCESSFUL",
	TEST_AUTOTASK_CREDENTIALS_FAILED = "TEST_AUTOTASK_CREDENTIALS_FAILED",
	TEST_CONNECTWISE_CREDENTIALS_REQUESTED = "TEST_CONNECTWISE_CREDENTIALS_REQUESTED",
	TEST_CONNECTWISE_CREDENTIALS_SUCCESSFUL = "TEST_CONNECTWISE_CREDENTIALS_SUCCESSFUL",
	TEST_CONNECTWISE_CREDENTIALS_FAILED = "TEST_CONNECTWISE_CREDENTIALS_FAILED",
	TEST_SYNCRO_CREDENTIALS_REQUESTED = "TEST_SYNCRO_CREDENTIALS_REQUESTED",
	TEST_SYNCRO_CREDENTIALS_SUCCESSFUL = "TEST_SYNCRO_CREDENTIALS_SUCCESSFUL",
	TEST_SYNCRO_CREDENTIALS_FAILED = "TEST_SYNCRO_CREDENTIALS_FAILED",
	TEST_KASEYA_CREDENTIALS_REQUESTED = "TEST_KASEYA_CREDENTIALS_REQUESTED",
	TEST_KASEYA_CREDENTIALS_SUCCESSFUL = "TEST_KASEYA_CREDENTIALS_SUCCESSFUL",
	TEST_KASEYA_CREDENTIALS_FAILED = "TEST_KASEYA_CREDENTIALS_FAILED",
	TEST_REPAIRSHOPR_CREDENTIALS_REQUESTED = "TEST_REPAIRSHOPR_CREDENTIALS_REQUESTED",
	TEST_REPAIRSHOPR_CREDENTIALS_SUCCESSFUL = "TEST_REPAIRSHOPR_CREDENTIALS_SUCCESSFUL",
	TEST_REPAIRSHOPR_CREDENTIALS_FAILED = "TEST_REPAIRSHOPR_CREDENTIALS_FAILED",

	CONNECTWISE_TAX_IMPORT_REQUESTED = "CONNECTWISE_TAX_IMPORT_REQUESTED",
	CONNECTWISE_TAX_IMPORT_SUCCESSFUL = "CONNECTWISE_TAX_IMPORT_SUCCESSFUL",
	CONNECTWISE_TAX_IMPORT_FAILED = "CONNECTWISE_TAX_IMPORT_FAILED",

	AUTOTASK_TAX_IMPORT_REQUESTED = "AUTOTASK_TAX_IMPORT_REQUESTED",
	AUTOTASK_TAX_IMPORT_SUCCESSFUL = "AUTOTASK_TAX_IMPORT_SUCCESSFUL",
	AUTOTASK_TAX_IMPORT_FAILED = "AUTOTASK_TAX_IMPORT_FAILED",

	SHOW_DICKER_DATA_DRAWER = "SHOW_DICKER_DATA_DRAWER",
	HIDE_DICKER_DATA_DRAWER = "HIDE_DICKER_DATA_DRAWER",
	SHOW_WESTCOAST_DRAWER = "SHOW_WESTCOAST_DRAWER",
	HIDE_WESTCOAST_DRAWER = "HIDE_WESTCOAST_DRAWER",
	SHOW_ROLES_PERMISSIONS_DRAWER = "SHOW_ROLES_PERMISSIONS_DRAWER",
	HIDE_ROLES_PERMISSIONS_DRAWER = "HIDE_ROLES_PERMISSIONS_DRAWER",
	SHOW_VIEW_PERMISSIONS_DRAWER = "SHOW_VIEW_PERMISSIONS_DRAWER",
	HIDE_VIEW_PERMISSIONS_DRAWER = "HIDE_VIEW_PERMISSIONS_DRAWER",
	AUTOTASK_STAGE_MAPPING_REQUESTED = "AUTOTASK_STAGE_MAPPING_REQUESTED",
	AUTOTASK_STAGE_MAPPING_SUCCESSFUL = "AUTOTASK_STAGE_MAPPING_SUCCESSFUL",
	AUTOTASK_STAGE_MAPPING_FAILED = "AUTOTASK_STAGE_MAPPING_FAILED",

	UPDATED_SYNC_DATA_REQUESTED = "UPDATED_SYNC_DATA_REQUESTED",
	UPDATED_SYNC_DATA_SUCCESSFUL = "UPDATED_SYNC_DATA_SUCCESSFUL",
	UPDATED_SYNC_DATA_FAILED = "UPDATED_SYNC_DATA_FAILED"
}

export type ShowSettingsLoaderAction =
	Action<SettingsActionTypes.SHOW_SETTINGS_LOADER>;
export type HideSettingsLoaderAction =
	Action<SettingsActionTypes.HIDE_SETTINGS_LOADER>;
export type ShowAppUpdateBannerAction =
	Action<SettingsActionTypes.SHOW_APP_UPDATE_BANNER>;
export type HideAppUpdateBannerAction =
	Action<SettingsActionTypes.HIDE_APP_UPDATE_BANNER>;
export type ConnectWiseDrawerOpenedAction =
	Action<SettingsActionTypes.CONNECTWISE_DRAWER_OPENED>;
export type ConnectWiseDrawerClosedAction =
	Action<SettingsActionTypes.CONNECTWISE_DRAWER_CLOSED>;
export type KaseyaDrawerOpenedAction =
	Action<SettingsActionTypes.KASEYA_DRAWER_OPENED>;
export type KaseyaDrawerClosedAction =
	Action<SettingsActionTypes.KASEYA_DRAWER_CLOSED>;
export type ZapierDrawerOpenedAction =
	Action<SettingsActionTypes.ZAPIER_DRAWER_OPENED>;
export type ZapierDrawerClosedAction =
	Action<SettingsActionTypes.ZAPIER_DRAWER_CLOSED>;
export type IngramDrawerOpenedAction =
	Action<SettingsActionTypes.INGRAM_DRAWER_OPENED>;
export type IngramDrawerClosedAction =
	Action<SettingsActionTypes.INGRAM_DRAWER_CLOSED>;
export type TechDataDrawerOpenedAction =
	Action<SettingsActionTypes.TECH_DATA_DRAWER_OPENED>;
export type TechDataDrawerClosedAction =
	Action<SettingsActionTypes.TECH_DATA_DRAWER_CLOSED>;
export type DAndHDrawerOpenedAction =
	Action<SettingsActionTypes.D_AND_H_DRAWER_OPENED>;
export type DAndHDrawerClosedAction =
	Action<SettingsActionTypes.D_AND_H_DRAWER_CLOSED>;
export type SynnexDrawerClosedAction =
	Action<SettingsActionTypes.SYNNEX_DRAWER_CLOSED>;
export type SynnexDrawerOpenedAction =
	Action<SettingsActionTypes.SYNNEX_DRAWER_OPENED>;
export type CBDrawerClosedAction = Action<SettingsActionTypes.CB_DRAWER_CLOSED>;
export type CBDrawerOpenedAction = Action<SettingsActionTypes.CB_DRAWER_OPENED>;
export type RolesPermissionsDrawerOpenedAction =
	Action<SettingsActionTypes.SHOW_ROLES_PERMISSIONS_DRAWER>;
export type RolesPermissionsDrawerClosedAction =
	Action<SettingsActionTypes.HIDE_ROLES_PERMISSIONS_DRAWER>;
export type ViewPermissionsDrawerOpenedAction =
	Action<SettingsActionTypes.SHOW_VIEW_PERMISSIONS_DRAWER>;
export type ViewPermissionsDrawerClosedAction =
	Action<SettingsActionTypes.HIDE_VIEW_PERMISSIONS_DRAWER>;

export type AccountSettingsDrawerOpenedAction =
	Action<SettingsActionTypes.ACCOUNT_SETTINGS_DRAWER_OPENED>;
export type AccountSettingsDrawerClosedAction =
	Action<SettingsActionTypes.ACCOUNT_SETTINGS_DRAWER_CLOSED>;
export type CommissionsDrawerOpenedAction =
	Action<SettingsActionTypes.COMMISSIONS_DRAWER_OPENED>;
export type CommissionsDrawerClosedAction =
	Action<SettingsActionTypes.COMMISSIONS_DRAWER_CLOSED>;
export type SendPasswordEmailRequested =
	Action<SettingsActionTypes.SEND_PASSWORD_RESET_EMAIL_REQUESTED>;
export type SendPasswordEmailSuccessful =
	Action<SettingsActionTypes.SEND_PASSWORD_RESET_EMAIL_SUCCESSFULL>;
export type SendPasswordEmailFailed =
	Action<SettingsActionTypes.SEND_PASSWORD_RESET_EMAIL_FAILED>;
export type AddCompanyUserDrawerOpenedAction =
	Action<SettingsActionTypes.ADD_COMPANY_USER_DRAWER_OPENED>;
export type AddCompanyUserDrawerClosedAction =
	Action<SettingsActionTypes.ADD_COMPANY_USER_DRAWER_CLOSED>;
export type EditCompanyUserDrawerOpenedAction =
	Action<SettingsActionTypes.EDIT_COMPANY_USER_DRAWER_OPENED>;
export type EditCompanyUserDrawerClosedAction =
	Action<SettingsActionTypes.EDIT_COMPANY_USER_DRAWER_CLOSED>;
export type CompanyLogoModalOpenedAction =
	Action<SettingsActionTypes.COMPANY_LOGO_MODAL_OPENED>;
export type CompanyLogoModalClosedAction =
	Action<SettingsActionTypes.COMPANY_LOGO_MODAL_CLOSED>;
export type AddCustomFieldDrawerOpenedAction =
	Action<SettingsActionTypes.ADD_CUSTOM_FIELD_DRAWER_OPENED>;
export type AddCustomFieldDrawerClosedAction =
	Action<SettingsActionTypes.ADD_CUSTOM_FIELD_DRAWER_CLOSED>;
export type EditCustomFieldDrawerOpenedAction =
	Action<SettingsActionTypes.EDIT_CUSTOM_FIELD_DRAWER_OPENED>;
export type EditCustomFieldDrawerClosedAction =
	Action<SettingsActionTypes.EDIT_CUSTOM_FIELD_DRAWER_CLOSED>;

export type TestAutotaskCredentialsRequestedAction =
	Action<SettingsActionTypes.TEST_AUTOTASK_CREDENTIALS_REQUESTED>;
export interface TestAutotaskCredentialsSuccessfulAction
	extends Action<SettingsActionTypes.TEST_AUTOTASK_CREDENTIALS_SUCCESSFUL> {
	readonly autotaskCompanyConfig: AutotaskIntegrationResponse;
}
export type TestAutotaskCredentialsFailedAction =
	Action<SettingsActionTypes.TEST_AUTOTASK_CREDENTIALS_FAILED>;
export type TestConnectwiseCredentialsRequestedAction =
	Action<SettingsActionTypes.TEST_CONNECTWISE_CREDENTIALS_REQUESTED>;
export interface TestConnectwiseCredentialsSuccessfulAction
	extends Action<SettingsActionTypes.TEST_CONNECTWISE_CREDENTIALS_SUCCESSFUL> {
	readonly connectwiseCompanyConfig: ConnectwiseCredentailsTestResponse;
}
export type TestConnectwiseCredentialsFailedAction =
	Action<SettingsActionTypes.TEST_CONNECTWISE_CREDENTIALS_FAILED>;
export type TestSyncroCredentialsRequestedAction =
	Action<SettingsActionTypes.TEST_SYNCRO_CREDENTIALS_REQUESTED>;
export interface TestSyncroCredentialsSuccessfulAction
	extends Action<SettingsActionTypes.TEST_SYNCRO_CREDENTIALS_SUCCESSFUL> {
	readonly syncroCompanyConfig: SyncroCredentailsTestResponse;
}
export type TestSyncroCredentialsFailedAction =
	Action<SettingsActionTypes.TEST_SYNCRO_CREDENTIALS_FAILED>;
export type TestKaseyaCredentialsRequestedAction =
	Action<SettingsActionTypes.TEST_KASEYA_CREDENTIALS_REQUESTED>;
export interface TestKaseyaCredentialsSuccessfulAction
	extends Action<SettingsActionTypes.TEST_KASEYA_CREDENTIALS_SUCCESSFUL> {
	readonly kaseyaCompanyConfig: any;
}
export type TestKaseyaCredentialsFailedAction =
	Action<SettingsActionTypes.TEST_KASEYA_CREDENTIALS_FAILED>;

export type ConnectwiseTaxImportRequestedAction =
	Action<SettingsActionTypes.CONNECTWISE_TAX_IMPORT_REQUESTED>;
export interface ConnectwiseTaxImportSuccessfulAction
	extends Action<SettingsActionTypes.CONNECTWISE_TAX_IMPORT_SUCCESSFUL> {
	readonly taxMappings: ConnectwiseTaxImport;
}
export type ConnectwiseTaxImportFailedAction =
	Action<SettingsActionTypes.CONNECTWISE_TAX_IMPORT_FAILED>;

export type AutotaskTaxImportRequestedAction =
	Action<SettingsActionTypes.AUTOTASK_TAX_IMPORT_REQUESTED>;
export interface AutotaskTaxImportSuccessfulAction
	extends Action<SettingsActionTypes.AUTOTASK_TAX_IMPORT_SUCCESSFUL> {
	readonly taxMappings: AutotaskMapping;
}
export type AutotaskTaxImportFailedAction =
	Action<SettingsActionTypes.AUTOTASK_TAX_IMPORT_FAILED>;

export type ShowDickerDataDrawerAction =
	Action<SettingsActionTypes.SHOW_DICKER_DATA_DRAWER>;
export type HideDickerDataDrawerAction =
	Action<SettingsActionTypes.HIDE_DICKER_DATA_DRAWER>;
export type ShowWestcoastDrawerAction =
	Action<SettingsActionTypes.SHOW_WESTCOAST_DRAWER>;
export type HideWestcoastDrawerAction =
	Action<SettingsActionTypes.HIDE_WESTCOAST_DRAWER>;
export type AutotaskStageMappingRequestedAction =
	Action<SettingsActionTypes.AUTOTASK_STAGE_MAPPING_REQUESTED>;
export interface AutotaskStageMappingSuccessfulAction
	extends Action<SettingsActionTypes.AUTOTASK_STAGE_MAPPING_SUCCESSFUL> {
	readonly stageMappings: AutotaskMapping;
}
export type AutotaskStageMappingFailedAction =
	Action<SettingsActionTypes.AUTOTASK_STAGE_MAPPING_FAILED>;

export type UpdatedSyncDataRequestedAction =
	Action<SettingsActionTypes.UPDATED_SYNC_DATA_REQUESTED>;
export interface UpdatedSyncDataSuccessfulAction
	extends Action<SettingsActionTypes.UPDATED_SYNC_DATA_SUCCESSFUL> {
	readonly syncData: SyncData[];
}
export type UpdatedSyncDataFailedAction =
	Action<SettingsActionTypes.UPDATED_SYNC_DATA_FAILED>;

export type TestRepairShoprCredentialsRequestedAction =
	Action<SettingsActionTypes.TEST_REPAIRSHOPR_CREDENTIALS_REQUESTED>;
export interface TestRepairShoprCredentialsSuccessfulAction
	extends Action<SettingsActionTypes.TEST_REPAIRSHOPR_CREDENTIALS_SUCCESSFUL> {
	readonly repairShoprCompanyConfig: RepairShoprCredentailsTestResponse;
}
export type TestRepairShoprCredentialsFailedAction =
	Action<SettingsActionTypes.TEST_REPAIRSHOPR_CREDENTIALS_FAILED>;

export type SettingsActions =
	| ShowSettingsLoaderAction
	| HideSettingsLoaderAction
	| ShowAppUpdateBannerAction
	| HideAppUpdateBannerAction
	| ConnectWiseDrawerOpenedAction
	| ConnectWiseDrawerClosedAction
	| ZapierDrawerOpenedAction
	| ZapierDrawerClosedAction
	| IngramDrawerOpenedAction
	| IngramDrawerClosedAction
	| TechDataDrawerOpenedAction
	| TechDataDrawerClosedAction
	| DAndHDrawerOpenedAction
	| DAndHDrawerClosedAction
	| SynnexDrawerOpenedAction
	| SynnexDrawerClosedAction
	| AccountSettingsDrawerOpenedAction
	| AccountSettingsDrawerClosedAction
	| CommissionsDrawerOpenedAction
	| CommissionsDrawerClosedAction
	| SendPasswordEmailRequested
	| SendPasswordEmailSuccessful
	| SendPasswordEmailFailed
	| AddCompanyUserDrawerOpenedAction
	| AddCompanyUserDrawerClosedAction
	| EditCompanyUserDrawerOpenedAction
	| EditCompanyUserDrawerClosedAction
	| CompanyLogoModalClosedAction
	| CompanyLogoModalOpenedAction
	| AddCustomFieldDrawerOpenedAction
	| AddCustomFieldDrawerClosedAction
	| EditCustomFieldDrawerOpenedAction
	| EditCustomFieldDrawerClosedAction
	| KaseyaDrawerOpenedAction
	| KaseyaDrawerClosedAction
	| CBDrawerClosedAction
	| CBDrawerOpenedAction
	| RolesPermissionsDrawerOpenedAction
	| RolesPermissionsDrawerClosedAction
	| ViewPermissionsDrawerOpenedAction
	| ViewPermissionsDrawerClosedAction
	| TestConnectwiseCredentialsRequestedAction
	| TestConnectwiseCredentialsSuccessfulAction
	| TestConnectwiseCredentialsFailedAction
	| TestSyncroCredentialsRequestedAction
	| TestSyncroCredentialsSuccessfulAction
	| TestSyncroCredentialsFailedAction
	| TestKaseyaCredentialsRequestedAction
	| TestKaseyaCredentialsSuccessfulAction
	| TestKaseyaCredentialsFailedAction
	| ConnectwiseTaxImportRequestedAction
	| ConnectwiseTaxImportSuccessfulAction
	| ConnectwiseTaxImportFailedAction
	| AutotaskTaxImportRequestedAction
	| AutotaskTaxImportSuccessfulAction
	| AutotaskTaxImportFailedAction
	| ShowDickerDataDrawerAction
	| HideDickerDataDrawerAction
	| ShowWestcoastDrawerAction
	| HideWestcoastDrawerAction
	| AutotaskStageMappingRequestedAction
	| AutotaskStageMappingSuccessfulAction
	| AutotaskStageMappingFailedAction
	| UpdatedSyncDataRequestedAction
	| UpdatedSyncDataSuccessfulAction
	| UpdatedSyncDataFailedAction;

export interface SettingsDispatch {
	showSettingsLoader: ActionCreator<
		ThunkAction<
			ShowSettingsLoaderAction,
			GlobalState,
			null,
			ShowSettingsLoaderAction
		>
	>;
	hideSettingsLoader: ActionCreator<
		ThunkAction<
			HideSettingsLoaderAction,
			GlobalState,
			null,
			HideSettingsLoaderAction
		>
	>;
	showAppUpdateBanner: ActionCreator<
		ThunkAction<
			ShowAppUpdateBannerAction,
			GlobalState,
			null,
			ShowAppUpdateBannerAction
		>
	>;
	hideAppUpdateBanner: ActionCreator<
		ThunkAction<
			HideAppUpdateBannerAction,
			GlobalState,
			null,
			HideAppUpdateBannerAction
		>
	>;
	connectWiseDrawerOpened: ActionCreator<
		ThunkAction<
			ConnectWiseDrawerOpenedAction,
			GlobalState,
			null,
			ConnectWiseDrawerOpenedAction
		>
	>;
	connectWiseDrawerClosed: ActionCreator<
		ThunkAction<
			ConnectWiseDrawerClosedAction,
			GlobalState,
			null,
			ConnectWiseDrawerClosedAction
		>
	>;
	zapierDrawerOpened: ActionCreator<
		ThunkAction<
			ZapierDrawerOpenedAction,
			GlobalState,
			null,
			ZapierDrawerOpenedAction
		>
	>;
	zapierDrawerClosed: ActionCreator<
		ThunkAction<
			ZapierDrawerClosedAction,
			GlobalState,
			null,
			ZapierDrawerClosedAction
		>
	>;
	kaseyaDrawerOpened: ActionCreator<
		ThunkAction<
			KaseyaDrawerOpenedAction,
			GlobalState,
			null,
			KaseyaDrawerOpenedAction
		>
	>;
	kaseyaDrawerClosed: ActionCreator<
		ThunkAction<
			KaseyaDrawerClosedAction,
			GlobalState,
			null,
			KaseyaDrawerClosedAction
		>
	>;
	ingramDrawerOpened: ActionCreator<
		ThunkAction<
			IngramDrawerOpenedAction,
			GlobalState,
			null,
			IngramDrawerOpenedAction
		>
	>;
	ingramDrawerClosed: ActionCreator<
		ThunkAction<
			IngramDrawerClosedAction,
			GlobalState,
			null,
			IngramDrawerClosedAction
		>
	>;
	techDataDrawerOpened: ActionCreator<
		ThunkAction<
			TechDataDrawerOpenedAction,
			GlobalState,
			null,
			TechDataDrawerOpenedAction
		>
	>;
	techDataDrawerClosed: ActionCreator<
		ThunkAction<
			TechDataDrawerClosedAction,
			GlobalState,
			null,
			TechDataDrawerClosedAction
		>
	>;
	dAndHDrawerOpened: ActionCreator<
		ThunkAction<
			DAndHDrawerOpenedAction,
			GlobalState,
			null,
			DAndHDrawerOpenedAction
		>
	>;
	dAndHDrawerClosed: ActionCreator<
		ThunkAction<
			DAndHDrawerClosedAction,
			GlobalState,
			null,
			DAndHDrawerClosedAction
		>
	>;
	synnexDrawerOpened: ActionCreator<
		ThunkAction<
			SynnexDrawerOpenedAction,
			GlobalState,
			null,
			SynnexDrawerOpenedAction
		>
	>;
	synnexDrawerClosed: ActionCreator<
		ThunkAction<
			SynnexDrawerClosedAction,
			GlobalState,
			null,
			SynnexDrawerClosedAction
		>
	>;
	connectBoosterDrawerOpened: ActionCreator<
		ThunkAction<
			CBDrawerOpenedAction,
			GlobalState,
			null,
			CBDrawerOpenedAction
		>
	>;
	connectBoosterDrawerClosed: ActionCreator<
		ThunkAction<
			CBDrawerClosedAction,
			GlobalState,
			null,
			CBDrawerClosedAction
		>
	>;
	rolesPermissionsDrawerOpened: ActionCreator<
		ThunkAction<
			RolesPermissionsDrawerOpenedAction,
			GlobalState,
			null,
			RolesPermissionsDrawerOpenedAction
		>
	>;
	rolesPermissionsDrawerClosed: ActionCreator<
		ThunkAction<
			RolesPermissionsDrawerClosedAction,
			GlobalState,
			null,
			RolesPermissionsDrawerClosedAction
		>
	>;
	viewPermissionsDrawerOpened: ActionCreator<
		ThunkAction<
			ViewPermissionsDrawerOpenedAction,
			GlobalState,
			null,
			RolesPermissionsDrawerOpenedAction
		>
	>;
	viewPermissionsDrawerClosed: ActionCreator<
		ThunkAction<
			ViewPermissionsDrawerClosedAction,
			GlobalState,
			null,
			RolesPermissionsDrawerClosedAction
		>
	>;
	accountSettingsDrawerOpened: ActionCreator<
		ThunkAction<
			AccountSettingsDrawerOpenedAction,
			GlobalState,
			null,
			AccountSettingsDrawerOpenedAction
		>
	>;
	accountSettingsDrawerClosed: ActionCreator<
		ThunkAction<
			AccountSettingsDrawerClosedAction,
			GlobalState,
			null,
			AccountSettingsDrawerClosedAction
		>
	>;
	commissionsDrawerOpened: ActionCreator<
		ThunkAction<
			CommissionsDrawerOpenedAction,
			GlobalState,
			null,
			CommissionsDrawerOpenedAction
		>
	>;
	commissionsDrawerClosed: ActionCreator<
		ThunkAction<
			CommissionsDrawerClosedAction,
			GlobalState,
			null,
			CommissionsDrawerClosedAction
		>
	>;
	sendPasswordResetEmail: ActionCreator<
		ThunkAction<
			Promise<SendPasswordEmailSuccessful>,
			GlobalState,
			null,
			SendPasswordEmailSuccessful
		>
	>;
	addCompanyUserDrawerOpened: ActionCreator<
		ThunkAction<
			AddCompanyUserDrawerOpenedAction,
			GlobalState,
			null,
			AddCompanyUserDrawerOpenedAction
		>
	>;
	addCompanyUserDrawerClosed: ActionCreator<
		ThunkAction<
			AddCompanyUserDrawerClosedAction,
			GlobalState,
			null,
			AddCompanyUserDrawerClosedAction
		>
	>;
	editCompanyUserDrawerOpened: ActionCreator<
		ThunkAction<
			EditCompanyUserDrawerOpenedAction,
			GlobalState,
			null,
			EditCompanyUserDrawerOpenedAction
		>
	>;
	editCompanyUserDrawerClosed: ActionCreator<
		ThunkAction<
			EditCompanyUserDrawerClosedAction,
			GlobalState,
			null,
			EditCompanyUserDrawerClosedAction
		>
	>;
	companyLogoModalOpened: ActionCreator<
		ThunkAction<
			CompanyLogoModalOpenedAction,
			GlobalState,
			null,
			CompanyLogoModalOpenedAction
		>
	>;
	companyLogoModalClosed: ActionCreator<
		ThunkAction<
			CompanyLogoModalClosedAction,
			GlobalState,
			null,
			CompanyLogoModalClosedAction
		>
	>;
	addCustomFieldDrawerOpened: ActionCreator<
		ThunkAction<
			AddCustomFieldDrawerOpenedAction,
			GlobalState,
			null,
			AddCustomFieldDrawerOpenedAction
		>
	>;
	addCustomFieldDrawerClosed: ActionCreator<
		ThunkAction<
			AddCustomFieldDrawerClosedAction,
			GlobalState,
			null,
			AddCustomFieldDrawerClosedAction
		>
	>;
	editCustomFieldDrawerOpened: ActionCreator<
		ThunkAction<
			EditCustomFieldDrawerOpenedAction,
			GlobalState,
			null,
			EditCustomFieldDrawerOpenedAction
		>
	>;
	editCustomFieldDrawerClosed: ActionCreator<
		ThunkAction<
			EditCustomFieldDrawerClosedAction,
			GlobalState,
			null,
			EditCustomFieldDrawerClosedAction
		>
	>;
	testAutotaskCredentials: ActionCreator<
		ThunkAction<
			Promise<TestAutotaskCredentialsSuccessfulAction>,
			GlobalState,
			AutotaskIntegrationResponse,
			TestAutotaskCredentialsSuccessfulAction
		>
	>;
	testConnectwiseCredentials: ActionCreator<
		ThunkAction<
			Promise<TestConnectwiseCredentialsSuccessfulAction>,
			GlobalState,
			ConnectwiseCredentialsTestRequest,
			TestConnectwiseCredentialsSuccessfulAction
		>
	>;
	testSyncroCredentials: ActionCreator<
		ThunkAction<
			Promise<TestSyncroCredentialsSuccessfulAction>,
			GlobalState,
			SyncroCredentialsTestRequest,
			TestSyncroCredentialsSuccessfulAction
		>
	>;
	testKaseyaCredentials: ActionCreator<
		ThunkAction<
			Promise<TestKaseyaCredentialsSuccessfulAction>,
			GlobalState,
			KaseyaCredentialsTestRequest,
			TestKaseyaCredentialsSuccessfulAction
		>
	>;
	testRepairShoprCredentials: ActionCreator<
		ThunkAction<
			Promise<TestRepairShoprCredentialsSuccessfulAction>,
			GlobalState,
			RepairShoprCredentialsTestRequest,
			TestRepairShoprCredentialsSuccessfulAction
		>
	>;
	importTaxForConnectwise: ActionCreator<
		ThunkAction<
			Promise<ConnectwiseTaxImportSuccessfulAction>,
			GlobalState,
			ImportTaxRequest,
			ConnectwiseTaxImportSuccessfulAction
		>
	>;
	importTaxForAutotask: ActionCreator<
		ThunkAction<
			Promise<AutotaskTaxImportSuccessfulAction>,
			GlobalState,
			AutotaskTaxImportRequest,
			AutotaskTaxImportSuccessfulAction
		>
	>;
	showDickerDataDrawer: ActionCreator<
		ThunkAction<
			ShowDickerDataDrawerAction,
			GlobalState,
			null,
			ShowDickerDataDrawerAction
		>
	>;
	hideDickerDataDrawer: ActionCreator<
		ThunkAction<
			HideDickerDataDrawerAction,
			GlobalState,
			null,
			HideDickerDataDrawerAction
		>
	>;
	showWestcoastDrawer: ActionCreator<
		ThunkAction<
			ShowWestcoastDrawerAction,
			GlobalState,
			null,
			ShowWestcoastDrawerAction
		>
	>;
	hideWestcoastDrawer: ActionCreator<
		ThunkAction<
			HideWestcoastDrawerAction,
			GlobalState,
			null,
			HideWestcoastDrawerAction
		>
	>;
	getAutotaskStageMapping: ActionCreator<
		ThunkAction<
			Promise<AutotaskStageMappingSuccessfulAction>,
			GlobalState,
			AutotaskConnectFormValues,
			AutotaskStageMappingSuccessfulAction
		>
	>;
	getUpdatedSyncData: ActionCreator<
		ThunkAction<
			Promise<UpdatedSyncDataSuccessfulAction>,
			GlobalState,
			SyncEntityRequest,
			UpdatedSyncDataSuccessfulAction
		>
	>;
}

export const SettingsActionCreators: SettingsDispatch = {
	showSettingsLoader: () => {
		return (dispatch: Dispatch): ShowSettingsLoaderAction => {
			const showSettingsLoaderAction: ShowSettingsLoaderAction = {
				type: SettingsActionTypes.SHOW_SETTINGS_LOADER
			};
			return dispatch(showSettingsLoaderAction);
		};
	},
	hideSettingsLoader: () => {
		return (dispatch: Dispatch): HideSettingsLoaderAction => {
			const hideSettingsLoaderAction: HideSettingsLoaderAction = {
				type: SettingsActionTypes.HIDE_SETTINGS_LOADER
			};
			return dispatch(hideSettingsLoaderAction);
		};
	},
	showAppUpdateBanner: () => {
		return (dispatch: Dispatch): ShowAppUpdateBannerAction => {
			const showAppUpdateBannerAction: ShowAppUpdateBannerAction = {
				type: SettingsActionTypes.SHOW_APP_UPDATE_BANNER
			};
			return dispatch(showAppUpdateBannerAction);
		};
	},
	hideAppUpdateBanner: () => {
		return (dispatch: Dispatch): HideAppUpdateBannerAction => {
			const hideAppUpdateBannerAction: HideAppUpdateBannerAction = {
				type: SettingsActionTypes.HIDE_APP_UPDATE_BANNER
			};
			return dispatch(hideAppUpdateBannerAction);
		};
	},
	connectWiseDrawerOpened: () => {
		return (dispatch: Dispatch): ConnectWiseDrawerOpenedAction => {
			const connectWiseDrawerOpenedAction: ConnectWiseDrawerOpenedAction =
				{
					type: SettingsActionTypes.CONNECTWISE_DRAWER_OPENED
				};
			return dispatch(connectWiseDrawerOpenedAction);
		};
	},
	connectWiseDrawerClosed: () => {
		return (dispatch: Dispatch): ConnectWiseDrawerClosedAction => {
			const connectWiseDrawerClosedAction: ConnectWiseDrawerClosedAction =
				{
					type: SettingsActionTypes.CONNECTWISE_DRAWER_CLOSED
				};
			return dispatch(connectWiseDrawerClosedAction);
		};
	},
	zapierDrawerOpened: () => {
		return (dispatch: Dispatch): ZapierDrawerOpenedAction => {
			const zapierDrawerOpenedAction: ZapierDrawerOpenedAction = {
				type: SettingsActionTypes.ZAPIER_DRAWER_OPENED
			};
			return dispatch(zapierDrawerOpenedAction);
		};
	},
	zapierDrawerClosed: () => {
		return (dispatch: Dispatch): ZapierDrawerClosedAction => {
			const zapierDrawerClosedAction: ZapierDrawerClosedAction = {
				type: SettingsActionTypes.ZAPIER_DRAWER_CLOSED
			};
			return dispatch(zapierDrawerClosedAction);
		};
	},
	kaseyaDrawerOpened: () => {
		return (dispatch: Dispatch): KaseyaDrawerOpenedAction => {
			const kaseyaDrawerOpenedAction: KaseyaDrawerOpenedAction = {
				type: SettingsActionTypes.KASEYA_DRAWER_OPENED
			};
			return dispatch(kaseyaDrawerOpenedAction);
		};
	},
	kaseyaDrawerClosed: () => {
		return (dispatch: Dispatch): KaseyaDrawerClosedAction => {
			const kaseyaDrawerClosedAction: KaseyaDrawerClosedAction = {
				type: SettingsActionTypes.KASEYA_DRAWER_CLOSED
			};
			return dispatch(kaseyaDrawerClosedAction);
		};
	},
	ingramDrawerOpened: () => {
		return (dispatch: Dispatch): IngramDrawerOpenedAction => {
			const ingramDrawerOpenedAction: IngramDrawerOpenedAction = {
				type: SettingsActionTypes.INGRAM_DRAWER_OPENED
			};
			return dispatch(ingramDrawerOpenedAction);
		};
	},
	ingramDrawerClosed: () => {
		return (dispatch: Dispatch): IngramDrawerClosedAction => {
			const ingramDrawerClosedAction: IngramDrawerClosedAction = {
				type: SettingsActionTypes.INGRAM_DRAWER_CLOSED
			};
			return dispatch(ingramDrawerClosedAction);
		};
	},
	techDataDrawerOpened: () => {
		return (dispatch: Dispatch): TechDataDrawerOpenedAction => {
			const techDataDrawerOpenedAction: TechDataDrawerOpenedAction = {
				type: SettingsActionTypes.TECH_DATA_DRAWER_OPENED
			};
			return dispatch(techDataDrawerOpenedAction);
		};
	},
	techDataDrawerClosed: () => {
		return (dispatch: Dispatch): TechDataDrawerClosedAction => {
			const techDataDrawerClosedAction: TechDataDrawerClosedAction = {
				type: SettingsActionTypes.TECH_DATA_DRAWER_CLOSED
			};
			return dispatch(techDataDrawerClosedAction);
		};
	},
	dAndHDrawerOpened: () => {
		return (dispatch: Dispatch): DAndHDrawerOpenedAction => {
			const dAndHDrawerOpenedAction: DAndHDrawerOpenedAction = {
				type: SettingsActionTypes.D_AND_H_DRAWER_OPENED
			};
			return dispatch(dAndHDrawerOpenedAction);
		};
	},
	dAndHDrawerClosed: () => {
		return (dispatch: Dispatch): DAndHDrawerClosedAction => {
			const dAndHDrawerClosedAction: DAndHDrawerClosedAction = {
				type: SettingsActionTypes.D_AND_H_DRAWER_CLOSED
			};
			return dispatch(dAndHDrawerClosedAction);
		};
	},
	synnexDrawerOpened: () => {
		return (dispatch: Dispatch): SynnexDrawerOpenedAction => {
			const synnexDrawerOpenedAction: SynnexDrawerOpenedAction = {
				type: SettingsActionTypes.SYNNEX_DRAWER_OPENED
			};
			return dispatch(synnexDrawerOpenedAction);
		};
	},
	synnexDrawerClosed: () => {
		return (dispatch: Dispatch): SynnexDrawerClosedAction => {
			const synnexDrawerClosedAction: SynnexDrawerClosedAction = {
				type: SettingsActionTypes.SYNNEX_DRAWER_CLOSED
			};
			return dispatch(synnexDrawerClosedAction);
		};
	},
	connectBoosterDrawerOpened: () => {
		return (dispatch: Dispatch): CBDrawerOpenedAction => {
			const connectBoosterDrawerOpenedAction: CBDrawerOpenedAction = {
				type: SettingsActionTypes.CB_DRAWER_OPENED
			};
			return dispatch(connectBoosterDrawerOpenedAction);
		};
	},
	connectBoosterDrawerClosed: () => {
		return (dispatch: Dispatch): CBDrawerClosedAction => {
			const connectBoosterDrawerClosedAction: CBDrawerClosedAction = {
				type: SettingsActionTypes.CB_DRAWER_CLOSED
			};
			return dispatch(connectBoosterDrawerClosedAction);
		};
	},
	rolesPermissionsDrawerOpened: () => {
		return (dispatch: Dispatch): RolesPermissionsDrawerOpenedAction => {
			const rolesPermissionsDrawerOpenedAction: RolesPermissionsDrawerOpenedAction =
				{
					type: SettingsActionTypes.SHOW_ROLES_PERMISSIONS_DRAWER
				};
			return dispatch(rolesPermissionsDrawerOpenedAction);
		};
	},
	rolesPermissionsDrawerClosed: () => {
		return (dispatch: Dispatch): RolesPermissionsDrawerClosedAction => {
			const rolesPermissionsDrawerClosedAction: RolesPermissionsDrawerClosedAction =
				{
					type: SettingsActionTypes.HIDE_ROLES_PERMISSIONS_DRAWER
				};
			return dispatch(rolesPermissionsDrawerClosedAction);
		};
	},
	viewPermissionsDrawerOpened: () => {
		return (dispatch: Dispatch): ViewPermissionsDrawerOpenedAction => {
			const viewPermissionsDrawerOpenedAction: ViewPermissionsDrawerOpenedAction =
				{
					type: SettingsActionTypes.SHOW_VIEW_PERMISSIONS_DRAWER
				};
			return dispatch(viewPermissionsDrawerOpenedAction);
		};
	},
	viewPermissionsDrawerClosed: () => {
		return (dispatch: Dispatch): ViewPermissionsDrawerClosedAction => {
			const viewPermissionsDrawerClosedAction: ViewPermissionsDrawerClosedAction =
				{
					type: SettingsActionTypes.HIDE_VIEW_PERMISSIONS_DRAWER
				};
			return dispatch(viewPermissionsDrawerClosedAction);
		};
	},
	accountSettingsDrawerOpened: () => {
		return (dispatch: Dispatch): AccountSettingsDrawerOpenedAction => {
			const accountSettingsDrawerOpenedAction: AccountSettingsDrawerOpenedAction =
				{
					type: SettingsActionTypes.ACCOUNT_SETTINGS_DRAWER_OPENED
				};
			return dispatch(accountSettingsDrawerOpenedAction);
		};
	},
	accountSettingsDrawerClosed: () => {
		return (dispatch: Dispatch): AccountSettingsDrawerClosedAction => {
			const accountSettingsDrawerClosedAction: AccountSettingsDrawerClosedAction =
				{
					type: SettingsActionTypes.ACCOUNT_SETTINGS_DRAWER_CLOSED
				};
			return dispatch(accountSettingsDrawerClosedAction);
		};
	},
	commissionsDrawerOpened: () => {
		return (dispatch: Dispatch): CommissionsDrawerOpenedAction => {
			const commissionsDrawerOpenedAction: CommissionsDrawerOpenedAction =
				{
					type: SettingsActionTypes.COMMISSIONS_DRAWER_OPENED
				};
			return dispatch(commissionsDrawerOpenedAction);
		};
	},
	commissionsDrawerClosed: () => {
		return (dispatch: Dispatch): CommissionsDrawerClosedAction => {
			const commissionsDrawerClosedAction: CommissionsDrawerClosedAction =
				{
					type: SettingsActionTypes.COMMISSIONS_DRAWER_CLOSED
				};
			return dispatch(commissionsDrawerClosedAction);
		};
	},
	sendPasswordResetEmail: () => {
		return async (
			dispatch: Dispatch
		): Promise<SendPasswordEmailSuccessful> => {
			const sendPasswordResetEmailRequested: SendPasswordEmailRequested =
				{
					type: SettingsActionTypes.SEND_PASSWORD_RESET_EMAIL_REQUESTED
				};
			dispatch(sendPasswordResetEmailRequested);
			try {
				await resetPasswordEmail();
				const sendPasswordResetEmailSuccessfulAction: SendPasswordEmailSuccessful =
					{
						type: SettingsActionTypes.SEND_PASSWORD_RESET_EMAIL_SUCCESSFULL
					};
				return dispatch(sendPasswordResetEmailSuccessfulAction);
			} catch (error) {
				const sendPasswordEmailFailed: SendPasswordEmailFailed = {
					type: SettingsActionTypes.SEND_PASSWORD_RESET_EMAIL_FAILED
				};
				dispatch(sendPasswordEmailFailed);

				return Promise.reject(error);
			}
		};
	},
	addCompanyUserDrawerOpened: () => {
		return (dispatch: Dispatch): AddCompanyUserDrawerOpenedAction => {
			const addCompanyUserDrawerOpenedAction: AddCompanyUserDrawerOpenedAction =
				{
					type: SettingsActionTypes.ADD_COMPANY_USER_DRAWER_OPENED
				};
			return dispatch(addCompanyUserDrawerOpenedAction);
		};
	},
	addCompanyUserDrawerClosed: () => {
		return (dispatch: Dispatch): AddCompanyUserDrawerClosedAction => {
			const addCompanyUserDrawerClosedAction: AddCompanyUserDrawerClosedAction =
				{
					type: SettingsActionTypes.ADD_COMPANY_USER_DRAWER_CLOSED
				};
			return dispatch(addCompanyUserDrawerClosedAction);
		};
	},
	editCompanyUserDrawerOpened: () => {
		return (dispatch: Dispatch): EditCompanyUserDrawerOpenedAction => {
			const editCompanyUserDrawerOpenedAction: EditCompanyUserDrawerOpenedAction =
				{
					type: SettingsActionTypes.EDIT_COMPANY_USER_DRAWER_OPENED
				};
			return dispatch(editCompanyUserDrawerOpenedAction);
		};
	},
	editCompanyUserDrawerClosed: () => {
		return (dispatch: Dispatch): EditCompanyUserDrawerClosedAction => {
			const editCompanyUserDrawerClosedAction: EditCompanyUserDrawerClosedAction =
				{
					type: SettingsActionTypes.EDIT_COMPANY_USER_DRAWER_CLOSED
				};
			return dispatch(editCompanyUserDrawerClosedAction);
		};
	},
	companyLogoModalOpened: () => {
		return (dispatch: Dispatch): CompanyLogoModalOpenedAction => {
			const companyLogoModalOpenedAction: CompanyLogoModalOpenedAction = {
				type: SettingsActionTypes.COMPANY_LOGO_MODAL_OPENED
			};
			return dispatch(companyLogoModalOpenedAction);
		};
	},
	companyLogoModalClosed: () => {
		return (dispatch: Dispatch): CompanyLogoModalClosedAction => {
			const companyLogoModalClosedAction: CompanyLogoModalClosedAction = {
				type: SettingsActionTypes.COMPANY_LOGO_MODAL_CLOSED
			};
			return dispatch(companyLogoModalClosedAction);
		};
	},
	addCustomFieldDrawerOpened: () => {
		return (dispatch: Dispatch): AddCustomFieldDrawerOpenedAction => {
			const addCustomFieldDrawerOpenedAction: AddCustomFieldDrawerOpenedAction =
				{
					type: SettingsActionTypes.ADD_CUSTOM_FIELD_DRAWER_OPENED
				};
			return dispatch(addCustomFieldDrawerOpenedAction);
		};
	},
	addCustomFieldDrawerClosed: () => {
		return (dispatch: Dispatch): AddCustomFieldDrawerClosedAction => {
			const addCustomFieldDrawerClosedAction: AddCustomFieldDrawerClosedAction =
				{
					type: SettingsActionTypes.ADD_CUSTOM_FIELD_DRAWER_CLOSED
				};
			return dispatch(addCustomFieldDrawerClosedAction);
		};
	},
	editCustomFieldDrawerOpened: () => {
		return (dispatch: Dispatch): EditCustomFieldDrawerOpenedAction => {
			const editCustomFieldDrawerOpenedAction: EditCustomFieldDrawerOpenedAction =
				{
					type: SettingsActionTypes.EDIT_CUSTOM_FIELD_DRAWER_OPENED
				};
			return dispatch(editCustomFieldDrawerOpenedAction);
		};
	},
	editCustomFieldDrawerClosed: () => {
		return (dispatch: Dispatch): EditCustomFieldDrawerClosedAction => {
			const editCustomFieldDrawerClosedAction: EditCustomFieldDrawerClosedAction =
				{
					type: SettingsActionTypes.EDIT_CUSTOM_FIELD_DRAWER_CLOSED
				};
			return dispatch(editCustomFieldDrawerClosedAction);
		};
	},
	testAutotaskCredentials: (
		autotaskIntegrationResponse: AutotaskIntegrationResponse
	) => {
		return async (
			dispatch: Dispatch
		): Promise<TestAutotaskCredentialsSuccessfulAction> => {
			const testAutotaskCredentialsRequestedAction: TestAutotaskCredentialsRequestedAction =
				{
					type: SettingsActionTypes.TEST_AUTOTASK_CREDENTIALS_REQUESTED
				};

			dispatch(testAutotaskCredentialsRequestedAction);
			try {
				const response = await testAutotaskCredentials(
					autotaskIntegrationResponse
				);

				const testAutotaskCredentialsSuccessfulAction: TestAutotaskCredentialsSuccessfulAction =
					{
						type: SettingsActionTypes.TEST_AUTOTASK_CREDENTIALS_SUCCESSFUL,
						autotaskCompanyConfig: response
					};

				return dispatch(testAutotaskCredentialsSuccessfulAction);
			} catch (error) {
				const testAutotaskCredentialsFailedAction: TestAutotaskCredentialsFailedAction =
					{
						type: SettingsActionTypes.TEST_AUTOTASK_CREDENTIALS_FAILED
					};

				dispatch(testAutotaskCredentialsFailedAction);

				return Promise.reject(error);
			}
		};
	},
	testConnectwiseCredentials: (
		connectwiseCredentialsTestRequest: ConnectwiseCredentialsTestRequest
	) => {
		return async (
			dispatch: Dispatch
		): Promise<TestConnectwiseCredentialsSuccessfulAction> => {
			const testConnectwiseCredentialsRequestedAction: TestConnectwiseCredentialsRequestedAction =
				{
					type: SettingsActionTypes.TEST_CONNECTWISE_CREDENTIALS_REQUESTED
				};

			dispatch(testConnectwiseCredentialsRequestedAction);
			try {
				const response = await testConnectwiseCredentials(
					connectwiseCredentialsTestRequest
				);

				const testConnectwiseCredentialsSuccessfulAction: TestConnectwiseCredentialsSuccessfulAction =
					{
						type: SettingsActionTypes.TEST_CONNECTWISE_CREDENTIALS_SUCCESSFUL,
						connectwiseCompanyConfig: response
					};

				return dispatch(testConnectwiseCredentialsSuccessfulAction);
			} catch (error) {
				const testConnectwiseCredentialsFailedAction: TestConnectwiseCredentialsFailedAction =
					{
						type: SettingsActionTypes.TEST_CONNECTWISE_CREDENTIALS_FAILED
					};

				dispatch(testConnectwiseCredentialsFailedAction);

				return Promise.reject(error);
			}
		};
	},
	testSyncroCredentials: (
		syncroCredentialsTestRequest: SyncroCredentialsTestRequest
	) => {
		return async (
			dispatch: Dispatch
		): Promise<TestSyncroCredentialsSuccessfulAction> => {
			const testSyncroCredentialsRequestedAction: TestSyncroCredentialsRequestedAction =
				{
					type: SettingsActionTypes.TEST_SYNCRO_CREDENTIALS_REQUESTED
				};
			dispatch(testSyncroCredentialsRequestedAction);
			try {
				const response = await testSyncroCredentials(
					syncroCredentialsTestRequest
				);
				const testSyncroCredentialsSuccessfulAction: TestSyncroCredentialsSuccessfulAction =
					{
						type: SettingsActionTypes.TEST_SYNCRO_CREDENTIALS_SUCCESSFUL,
						syncroCompanyConfig: response
					};
				return dispatch(testSyncroCredentialsSuccessfulAction);
			} catch (error) {
				const testSyncroCredentialsFailedAction: TestSyncroCredentialsFailedAction =
					{
						type: SettingsActionTypes.TEST_SYNCRO_CREDENTIALS_FAILED
					};
				dispatch(testSyncroCredentialsFailedAction);

				return Promise.reject(error);
			}
		};
	},
	testKaseyaCredentials: (
		kaseyaCredentialsTestRequest: KaseyaCredentialsTestRequest
	) => {
		return async (
			dispatch: Dispatch
		): Promise<TestKaseyaCredentialsSuccessfulAction> => {
			const testKaseyaCredentialsRequestedAction: TestKaseyaCredentialsRequestedAction =
				{
					type: SettingsActionTypes.TEST_KASEYA_CREDENTIALS_REQUESTED
				};
			dispatch(testKaseyaCredentialsRequestedAction);
			try {
				const response = await testKaseyaCredentials(
					kaseyaCredentialsTestRequest
				);
				const testKaseyaCredentialsSuccessfulAction: TestKaseyaCredentialsSuccessfulAction =
					{
						type: SettingsActionTypes.TEST_KASEYA_CREDENTIALS_SUCCESSFUL,
						kaseyaCompanyConfig: response
					};
				return dispatch(testKaseyaCredentialsSuccessfulAction);
			} catch (error) {
				const testKaseyaCredentialsFailedAction: TestKaseyaCredentialsFailedAction =
					{
						type: SettingsActionTypes.TEST_KASEYA_CREDENTIALS_FAILED
					};
				dispatch(testKaseyaCredentialsFailedAction);
				return Promise.reject(error);
			}
		};
	},
	importTaxForConnectwise: (importTaxRequest: ImportTaxRequest) => {
		return async (
			dispatch: Dispatch
		): Promise<ConnectwiseTaxImportSuccessfulAction> => {
			const connectwiseTaxImportRequestedAction: ConnectwiseTaxImportRequestedAction =
				{
					type: SettingsActionTypes.CONNECTWISE_TAX_IMPORT_REQUESTED
				};
			dispatch(connectwiseTaxImportRequestedAction);
			try {
				const response = await importTaxForConnectwise(
					importTaxRequest
				);
				const connectwiseTaxImportSuccessfulAction: ConnectwiseTaxImportSuccessfulAction =
					{
						type: SettingsActionTypes.CONNECTWISE_TAX_IMPORT_SUCCESSFUL,
						taxMappings: response
					};
				return dispatch(connectwiseTaxImportSuccessfulAction);
			} catch (error) {
				const connectwiseTaxImportFailedAction: ConnectwiseTaxImportFailedAction =
					{
						type: SettingsActionTypes.CONNECTWISE_TAX_IMPORT_FAILED
					};
				dispatch(connectwiseTaxImportFailedAction);

				return Promise.reject(error);
			}
		};
	},
	importTaxForAutotask: (autotaskRequest: AutotaskTaxImportRequest) => {
		return async (
			dispatch: Dispatch
		): Promise<AutotaskTaxImportSuccessfulAction> => {
			const autotaskTaxImportRequestedAction: AutotaskTaxImportRequestedAction =
				{
					type: SettingsActionTypes.AUTOTASK_TAX_IMPORT_REQUESTED
				};
			dispatch(autotaskTaxImportRequestedAction);
			try {
				const response = await importTaxForAutotask(autotaskRequest);
				const autotaskTaxImportSuccessfulAction: AutotaskTaxImportSuccessfulAction =
					{
						type: SettingsActionTypes.AUTOTASK_TAX_IMPORT_SUCCESSFUL,
						taxMappings: response
					};
				return dispatch(autotaskTaxImportSuccessfulAction);
			} catch (error) {
				const autotaskTaxImportFailedAction: AutotaskTaxImportFailedAction =
					{
						type: SettingsActionTypes.AUTOTASK_TAX_IMPORT_FAILED
					};
				dispatch(autotaskTaxImportFailedAction);

				return Promise.reject(error);
			}
		};
	},
	showDickerDataDrawer: () => {
		return (dispatch: Dispatch): ShowDickerDataDrawerAction => {
			const showDickerDataDrawerAction: ShowDickerDataDrawerAction = {
				type: SettingsActionTypes.SHOW_DICKER_DATA_DRAWER
			};

			return dispatch(showDickerDataDrawerAction);
		};
	},
	hideDickerDataDrawer: () => {
		return (dispatch: Dispatch): HideDickerDataDrawerAction => {
			const hideDickerDataDrawerAction: HideDickerDataDrawerAction = {
				type: SettingsActionTypes.HIDE_DICKER_DATA_DRAWER
			};

			return dispatch(hideDickerDataDrawerAction);
		};
	},
	showWestcoastDrawer: () => {
		return (dispatch: Dispatch): ShowWestcoastDrawerAction => {
			const showWestcoastDrawerAction: ShowWestcoastDrawerAction = {
				type: SettingsActionTypes.SHOW_WESTCOAST_DRAWER
			};

			return dispatch(showWestcoastDrawerAction);
		};
	},
	hideWestcoastDrawer: () => {
		return (dispatch: Dispatch): HideWestcoastDrawerAction => {
			const hideWestcoastDrawerAction: HideWestcoastDrawerAction = {
				type: SettingsActionTypes.HIDE_WESTCOAST_DRAWER
			};

			return dispatch(hideWestcoastDrawerAction);
		};
	},
	getAutotaskStageMapping: (
		autotaskRequest: AutotaskConnectFormValues,
		selectedPipelineId: string
	) => {
		return async (
			dispatch: Dispatch
		): Promise<AutotaskStageMappingSuccessfulAction> => {
			const autotaskStageMappingRequestedAction: AutotaskStageMappingRequestedAction =
				{
					type: SettingsActionTypes.AUTOTASK_STAGE_MAPPING_REQUESTED
				};
			dispatch(autotaskStageMappingRequestedAction);
			try {
				const response = await getAutotaskStageMapping(
					autotaskRequest,
					selectedPipelineId
				);
				const autotaskStageMappingSuccessfulAction: AutotaskStageMappingSuccessfulAction =
					{
						type: SettingsActionTypes.AUTOTASK_STAGE_MAPPING_SUCCESSFUL,
						stageMappings: response
					};
				return dispatch(autotaskStageMappingSuccessfulAction);
			} catch (error) {
				const autotaskStageMappingFailedAction: AutotaskStageMappingFailedAction =
					{
						type: SettingsActionTypes.AUTOTASK_STAGE_MAPPING_FAILED
					};
				dispatch(autotaskStageMappingFailedAction);

				return Promise.reject(error);
			}
		};
	},
	getUpdatedSyncData: (request: SyncEntityRequest) => {
		return async (
			dispatch: Dispatch
		): Promise<UpdatedSyncDataSuccessfulAction> => {
			const requestedAction: UpdatedSyncDataRequestedAction = {
				type: SettingsActionTypes.UPDATED_SYNC_DATA_REQUESTED
			};
			dispatch(requestedAction);
			try {
				const response = await getUpdatedSyncData(request);
				const successfulAction: UpdatedSyncDataSuccessfulAction = {
					type: SettingsActionTypes.UPDATED_SYNC_DATA_SUCCESSFUL,
					syncData: response
				};
				return dispatch(successfulAction);
			} catch (error) {
				const failedAction: UpdatedSyncDataFailedAction = {
					type: SettingsActionTypes.UPDATED_SYNC_DATA_FAILED
				};
				dispatch(failedAction);

				return Promise.reject(error);
			}
		};
	},
	testRepairShoprCredentials: (
		repairShoprCredentialsTestRequest: RepairShoprCredentialsTestRequest
	) => {
		return async (
			dispatch: Dispatch
		): Promise<TestRepairShoprCredentialsSuccessfulAction> => {
			const testRepairShoprCredentialsRequestedAction: TestRepairShoprCredentialsRequestedAction =
				{
					type: SettingsActionTypes.TEST_REPAIRSHOPR_CREDENTIALS_REQUESTED
				};
			dispatch(testRepairShoprCredentialsRequestedAction);
			try {
				const response = await testRepairShoprCredentials(
					repairShoprCredentialsTestRequest
				);
				const testRepairShoprCredentialsSuccessfulAction: TestRepairShoprCredentialsSuccessfulAction =
					{
						type: SettingsActionTypes.TEST_REPAIRSHOPR_CREDENTIALS_SUCCESSFUL,
						repairShoprCompanyConfig: response
					};
				return dispatch(testRepairShoprCredentialsSuccessfulAction);
			} catch (error) {
				const testRepairShoprCredentialsFailedAction: TestRepairShoprCredentialsFailedAction =
					{
						type: SettingsActionTypes.TEST_REPAIRSHOPR_CREDENTIALS_FAILED
					};
				dispatch(testRepairShoprCredentialsFailedAction);

				return Promise.reject(error);
			}
		};
	}
};

export default SettingsActionCreators;
