import React from "react";
import { ITableRecordsSelectionProps } from "./interface";
import ZTypography from "@zomentum/atoms/dist/ZTypography";
import ZSelect from "@zomentum/atoms/dist/ZSelect";

const ZTableRecordsSelection: React.FC<ITableRecordsSelectionProps> = props => (
	<>
		<ZTypography.ZText className="mr-2">Display records</ZTypography.ZText>
		<ZSelect
			getPopupContainer={trigger => trigger.parentNode}
			value={props.displayRecords}
			onChange={props.updateSelectedRecords}
			selectOptions={[10, 25, 50, 100]}
		/>
	</>
);

export default ZTableRecordsSelection;
