import { GlobalState } from "@/Reducers/interface";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useUserReady = () => {
	const { userData, userCompany, companyUserList } = useSelector(
		(state: GlobalState) => ({
			userData: state.vendorUser.vendorUserData,
			userCompany: state.vendorUser.vendorUserCompany,
			companyUserList: state.vendorUser.vendorUserList
		})
	);
	const [isUserReady, setUserReady] = useState(false);
	const newValue =
		!!userData &&
		userData.id?.length > 0 &&
		!!userCompany &&
		userCompany.id?.length > 0 &&
		!!companyUserList;
	useEffect(() => {
		if (isUserReady !== newValue) {
			setUserReady(newValue);
		}
	}, [userData, userCompany, companyUserList, isUserReady]);
	return [
		isUserReady || isUserReady !== newValue
			? { userData, userCompany }
			: null
	];
};
