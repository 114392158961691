import { BundledSKUSProduct, QuoteProduct } from "@zomentum/contracts";

export interface IAddProductSKUToQuoteBlockDrawerProps {
	readonly onSubmit: (products: QuoteProduct[]) => void;
	readonly visible: boolean;
	readonly onClose: () => void;
	readonly addNewProducts?: boolean;
}
export interface IAddProductSKUToBundleQuoteBlockDrawerProps {
	readonly onSubmitBundleProducts: (
		bundledProducts: BundledSKUSProduct[]
	) => void;
	readonly addedProducts: BundledSKUSProduct[];
	readonly visible: boolean;
	readonly onClose: () => void;
	readonly addNewProducts?: boolean;
}

export enum EditOrCloneProductDrawerMode {
	EDIT = "edit",
	CLONE = "clone"
}
