'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../../node_modules/tslib/tslib.es6.mjs.js');
var index$2 = require('../../store/actions/SalesActivity/index.js');
var SalesActivity = require('@zomentum/contracts/dist/Others/SalesActivity');
var contracts = require('@zomentum/contracts');
var useRefreshSalesActivity = require('./useRefreshSalesActivity.js');
var index$1 = require('../../store/index.js');
var React = require('react');
var index = require('../../store/context/ParentContext/index.js');
var utils = require('@zomentum/utils');

var useHandleAddSalesActivity = function useHandleAddSalesActivity() {
  var parentContext = React.useContext(index.ParentContext);
  var currentActiveOpportunity = parentContext.opportunity.currentActiveOpportunity;
  var companyUserList = parentContext.user.companyUserList;
  var dispatch = index$1.useSalesActivityModuleDispatch();
  // const location = useLocation();
  var refreshSalesActivity = useRefreshSalesActivity["default"]();
  var fetchUpdatedOpportunities = function fetchUpdatedOpportunities(opportunityId) {
    var _a;
    parentContext.opportunity.syncKanbanOpportunityDataById(opportunityId || ((_a = currentActiveOpportunity === null || currentActiveOpportunity === void 0 ? void 0 : currentActiveOpportunity.id) !== null && _a !== void 0 ? _a : ""));
  };
  var handleAddSalesActivity = function handleAddSalesActivity(salesActivity, opportunityId) {
    return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
      var taskActivity, error_1;
      return tslib_es6.__generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 5,, 6]);
            if (!(window.location.pathname === contracts.ZomentumRoutes.SalesActivityPage)) return [3 /*break*/, 3];
            return [4 /*yield*/, dispatch(index$2.SalesActivityActionCreators.fetchOneSalesActivity(new SalesActivity.GetOneSaleActivityRequest(salesActivity.id, salesActivity.feed_type), companyUserList))];
          case 1:
            taskActivity = _a.sent();
            return [4 /*yield*/, dispatch(index$2.SalesActivityActionCreators.addActivityToUsersSalesActivity(taskActivity.salesActivity))];
          case 2:
            _a.sent();
            return [3 /*break*/, 4];
          case 3:
            if (window.location.pathname === contracts.ZomentumRoutes.OpportunityList || window.location.pathname === contracts.ZomentumRoutes.VendorOpportunityList) {
              fetchUpdatedOpportunities(opportunityId);
            } else if (window.location.pathname.startsWith("".concat(contracts.ZomentumRoutes.OpportunityList, "/")) || window.location.pathname.startsWith("".concat(contracts.ZomentumRoutes.ClientsList, "/")) || window.location.pathname.startsWith("".concat(contracts.ZomentumRoutes.VendorOpportunityList, "/")) || window.location.pathname.startsWith("".concat(contracts.ZomentumRoutes.VendorClientList, "/")) || window.location.pathname.startsWith("".concat(contracts.ZomentumRoutes.VendorPartnerList, "/"))) {
              refreshSalesActivity();
            }
            _a.label = 4;
          case 4:
            return [3 /*break*/, 6];
          case 5:
            error_1 = _a.sent();
            console.error(error_1);
            return [3 /*break*/, 6];
          case 6:
            return [2 /*return*/];
        }
      });
    });
  };

  var handleCloseAddSalesActivity = function handleCloseAddSalesActivity() {
    if (window.location.pathname === contracts.ZomentumRoutes.OpportunityList && utils.isPartnerApp()) {
      parentContext.opportunity.resetOpportunityDetails();
    } else if (window.location.pathname === contracts.ZomentumRoutes.ClientsList && utils.isPartnerApp()) {
      parentContext.client.resetClientDetails();
    } else if (window.location.pathname.startsWith("".concat(contracts.ZomentumRoutes.OpportunityList, "/")) || window.location.pathname.startsWith("".concat(contracts.ZomentumRoutes.ClientsList, "/")) || window.location.pathname.startsWith("".concat(contracts.ZomentumRoutes.VendorPartnerList, "/")) || window.location.pathname.startsWith("".concat(contracts.ZomentumRoutes.VendorOpportunityList, "/"))) {
      refreshSalesActivity();
    }
  };
  return {
    handleAddSalesActivity: handleAddSalesActivity,
    handleCloseAddSalesActivity: handleCloseAddSalesActivity
  };
};

exports["default"] = useHandleAddSalesActivity;
