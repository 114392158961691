'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../../node_modules/tslib/tslib.es6.mjs.js');
var index$2 = require('../../store/actions/SalesActivity/index.js');
var index$1 = require('../../store/index.js');
var contracts = require('@zomentum/contracts');
var RecentActivityFeed = require('@zomentum/contracts/dist/Others/RecentActivityFeed');
var SalesActivity = require('@zomentum/contracts/dist/Others/SalesActivity');
var React = require('react');
var index = require('../../store/context/ParentContext/index.js');
var utils = require('@zomentum/utils');

var useRefreshSalesActivity = function useRefreshSalesActivity() {
  var parentContext = React.useContext(index.ParentContext);
  var currentActiveOpportunity = parentContext.opportunity.currentActiveOpportunity;
  var currentActiveClient = parentContext.client.currentActiveClient;
  var currentActiveVendorClient = parentContext.vendorClient.currentActiveVendorClient;
  var currentActivePartner = parentContext.vendorPartner.currentActivePartner;
  var _a = index$1.useSalesActivityModuleSelector(function (state) {
      return {
        activeFilters: state.salesActivity.activeFilters,
        salesActivityActiveTab: state.salesActivity.salesActivityActiveTab,
        allSalesActivity: state.salesActivity.allSalesActivity,
        tasksSalesActivity: state.salesActivity.tasksSalesActivity,
        notesSalesActivity: state.salesActivity.notesSalesActivity,
        meetingsSalesActivity: state.salesActivity.meetingsSalesActivity,
        callsSalesActivity: state.salesActivity.callsSalesActivity,
        emailsSalesActivity: state.salesActivity.emailsSalesActivity
      };
    }),
    activeFilters = _a.activeFilters,
    salesActivityActiveTab = _a.salesActivityActiveTab,
    allSalesActivity = _a.allSalesActivity,
    tasksSalesActivity = _a.tasksSalesActivity,
    notesSalesActivity = _a.notesSalesActivity,
    meetingsSalesActivity = _a.meetingsSalesActivity,
    callsSalesActivity = _a.callsSalesActivity,
    emailsSalesActivity = _a.emailsSalesActivity;
  var dispatch = index$1.useSalesActivityModuleDispatch();
  var getActiveEntityId = function getActiveEntityId() {
    var _a, _b, _c, _d;
    if (utils.isVendorApp()) {
      var isPartnersPage = window.location.pathname.startsWith("".concat(contracts.ZomentumRoutes.VendorPartnerList, "/"));
      var isClientsPage = window.location.pathname.startsWith("".concat(contracts.ZomentumRoutes.VendorClientList, "/"));
      var activeEntityId = isPartnersPage ? (_a = currentActivePartner === null || currentActivePartner === void 0 ? void 0 : currentActivePartner.id) !== null && _a !== void 0 ? _a : "" : isClientsPage ? (_b = currentActiveVendorClient === null || currentActiveVendorClient === void 0 ? void 0 : currentActiveVendorClient.id) !== null && _b !== void 0 ? _b : "" : (_c = currentActiveOpportunity === null || currentActiveOpportunity === void 0 ? void 0 : currentActiveOpportunity.id) !== null && _c !== void 0 ? _c : "";
      return activeEntityId;
    } else {
      var activeEntityId = currentActiveOpportunity ? currentActiveOpportunity.id : currentActiveClient ? (_d = currentActiveClient === null || currentActiveClient === void 0 ? void 0 : currentActiveClient.id) !== null && _d !== void 0 ? _d : "" : "";
      return activeEntityId;
    }
  };
  var getActiveEntityType = function getActiveEntityType() {
    if (utils.isVendorApp()) {
      var isPartnersPage = window.location.pathname.startsWith("".concat(contracts.ZomentumRoutes.VendorPartnerList, "/"));
      var isClientsPage = window.location.pathname.startsWith("".concat(contracts.ZomentumRoutes.VendorClientList, "/"));
      var activeEntityType = isPartnersPage ? contracts.ZomentumEntities.VendorCompany : isClientsPage ? contracts.ZomentumEntities.VendorClient : contracts.ZomentumEntities.VendorOpportunity;
      return activeEntityType;
    } else {
      var activeEntityType = !!currentActiveOpportunity ? contracts.ZomentumEntities.Opportunity : !!currentActiveClient ? contracts.ZomentumEntities.Client : contracts.ZomentumEntities.Opportunity;
      return activeEntityType;
    }
  };
  var refreshAllTabSalesActivity = function refreshAllTabSalesActivity(salesActivityRequest) {
    return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
      var error_1;
      return tslib_es6.__generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 4, 5, 6]);
            dispatch(index$2.SalesActivityActionCreators.showSalesActivityLoader());
            salesActivityRequest.next = allSalesActivity && allSalesActivity[SalesActivity.SalesActivitySection.Overdue] ? allSalesActivity[SalesActivity.SalesActivitySection.Overdue].next : null;
            return [4 /*yield*/, dispatch(index$2.SalesActivityActionCreators.getAllOverdueSalesActivity(salesActivityRequest))];
          case 1:
            _a.sent();
            salesActivityRequest.next = allSalesActivity && allSalesActivity[SalesActivity.SalesActivitySection.Upcoming] ? allSalesActivity[SalesActivity.SalesActivitySection.Upcoming].next : null;
            return [4 /*yield*/, dispatch(index$2.SalesActivityActionCreators.getAllUpcomingSalesActivity(salesActivityRequest))];
          case 2:
            _a.sent();
            salesActivityRequest.next = allSalesActivity && allSalesActivity[SalesActivity.SalesActivitySection.Completed] ? allSalesActivity[SalesActivity.SalesActivitySection.Completed].next : null;
            return [4 /*yield*/, dispatch(index$2.SalesActivityActionCreators.getAllCompletedSalesActivity(salesActivityRequest))];
          case 3:
            _a.sent();
            return [3 /*break*/, 6];
          case 4:
            error_1 = _a.sent();
            console.error(error_1);
            return [3 /*break*/, 6];
          case 5:
            dispatch(index$2.SalesActivityActionCreators.hideSalesActivityLoader());
            return [7 /*endfinally*/];
          case 6:
            return [2 /*return*/];
        }
      });
    });
  };

  var refreshEntitySalesActivity = function refreshEntitySalesActivity(entity, salesActivityRequest) {
    return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
      var _a, error_2;
      return tslib_es6.__generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            _b.trys.push([0, 18, 19, 20]);
            dispatch(index$2.SalesActivityActionCreators.showSalesActivityLoader());
            _a = entity;
            switch (_a) {
              case RecentActivityFeed.RecentActivityFeedType.Task:
                return [3 /*break*/, 1];
              case RecentActivityFeed.RecentActivityFeedType.Comment:
                return [3 /*break*/, 5];
              case RecentActivityFeed.RecentActivityFeedType.Meeting:
                return [3 /*break*/, 8];
              case RecentActivityFeed.RecentActivityFeedType.LogCall:
                return [3 /*break*/, 11];
              case RecentActivityFeed.RecentActivityFeedType.LogEmail:
                return [3 /*break*/, 13];
              case RecentActivityFeed.RecentActivityFeedType.History:
                return [3 /*break*/, 15];
            }
            return [3 /*break*/, 16];
          case 1:
            salesActivityRequest.next = tasksSalesActivity && tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue] ? tasksSalesActivity[SalesActivity.SalesActivitySection.Overdue].next : null;
            return [4 /*yield*/, dispatch(index$2.SalesActivityActionCreators.getTasksOverdueSalesActivity(salesActivityRequest))];
          case 2:
            _b.sent();
            salesActivityRequest.next = tasksSalesActivity && tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming] ? tasksSalesActivity[SalesActivity.SalesActivitySection.Upcoming].next : null;
            return [4 /*yield*/, dispatch(index$2.SalesActivityActionCreators.getTasksUpcomingSalesActivity(salesActivityRequest))];
          case 3:
            _b.sent();
            salesActivityRequest.next = tasksSalesActivity && tasksSalesActivity[SalesActivity.SalesActivitySection.Completed] ? tasksSalesActivity[SalesActivity.SalesActivitySection.Completed].next : null;
            return [4 /*yield*/, dispatch(index$2.SalesActivityActionCreators.getTasksCompletedSalesActivity(salesActivityRequest))];
          case 4:
            _b.sent();
            return [2 /*return*/];
          case 5:
            salesActivityRequest.next = notesSalesActivity && notesSalesActivity[SalesActivity.SalesActivitySection.Pinned] ? notesSalesActivity[SalesActivity.SalesActivitySection.Pinned].next : null;
            return [4 /*yield*/, dispatch(index$2.SalesActivityActionCreators.getNotesPinnedSalesActivity(salesActivityRequest))];
          case 6:
            _b.sent();
            salesActivityRequest.next = notesSalesActivity && notesSalesActivity[SalesActivity.SalesActivitySection.Completed] ? notesSalesActivity[SalesActivity.SalesActivitySection.Completed].next : null;
            return [4 /*yield*/, dispatch(index$2.SalesActivityActionCreators.getNotesCompletedSalesActivity(salesActivityRequest))];
          case 7:
            _b.sent();
            return [2 /*return*/];
          case 8:
            salesActivityRequest.next = meetingsSalesActivity && meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming] ? meetingsSalesActivity[SalesActivity.SalesActivitySection.Upcoming].next : null;
            return [4 /*yield*/, dispatch(index$2.SalesActivityActionCreators.getMeetingsUpcomingSalesActivity(salesActivityRequest))];
          case 9:
            _b.sent();
            salesActivityRequest.next = meetingsSalesActivity && meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed] ? meetingsSalesActivity[SalesActivity.SalesActivitySection.Completed].next : null;
            return [4 /*yield*/, dispatch(index$2.SalesActivityActionCreators.getMeetingsCompletedSalesActivity(salesActivityRequest))];
          case 10:
            _b.sent();
            return [2 /*return*/];
          case 11:
            salesActivityRequest.next = callsSalesActivity && callsSalesActivity[SalesActivity.SalesActivitySection.Completed] ? callsSalesActivity[SalesActivity.SalesActivitySection.Completed].next : null;
            return [4 /*yield*/, dispatch(index$2.SalesActivityActionCreators.getCallsSalesActivity(salesActivityRequest))];
          case 12:
            _b.sent();
            return [2 /*return*/];
          case 13:
            salesActivityRequest.next = emailsSalesActivity && emailsSalesActivity[SalesActivity.SalesActivitySection.Completed] ? emailsSalesActivity[SalesActivity.SalesActivitySection.Completed].next : null;
            return [4 /*yield*/, dispatch(index$2.SalesActivityActionCreators.getEmailsSalesActivity(salesActivityRequest))];
          case 14:
            _b.sent();
            return [2 /*return*/];
          case 15:
            return [2 /*return*/];
          case 16:
            console.error("Invalid refresh option");
            return [2 /*return*/];
          case 17:
            return [3 /*break*/, 20];
          case 18:
            error_2 = _b.sent();
            console.error(error_2);
            return [3 /*break*/, 20];
          case 19:
            dispatch(index$2.SalesActivityActionCreators.hideSalesActivityLoader());
            return [7 /*endfinally*/];
          case 20:
            return [2 /*return*/];
        }
      });
    });
  };

  var refreshSalesActivity = function refreshSalesActivity() {
    return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
      var activeEntityId, activeEntityType, isClientEntity, relatedOpportunities, salesActivityRequest;
      var _a, _b, _c;
      return tslib_es6.__generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            activeEntityId = getActiveEntityId();
            activeEntityType = getActiveEntityType();
            isClientEntity = activeEntityType === contracts.ZomentumEntities.Client;
            relatedOpportunities = isClientEntity ? (_c = (_b = (_a = currentActiveClient === null || currentActiveClient === void 0 ? void 0 : currentActiveClient.opportunities) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.map(function (opportunity) {
              return opportunity === null || opportunity === void 0 ? void 0 : opportunity.id;
            })) !== null && _c !== void 0 ? _c : [] : [];
            salesActivityRequest = new SalesActivity.SalesActivityRequest(new contracts.ZomentumEntityRequest(activeEntityType, activeEntityId), null, false, activeFilters, relatedOpportunities);
            if (!(salesActivityActiveTab === RecentActivityFeed.RecentActivityFeedType.All)) return [3 /*break*/, 2];
            return [4 /*yield*/, refreshAllTabSalesActivity(salesActivityRequest)];
          case 1:
            _d.sent();
            return [3 /*break*/, 3];
          case 2:
            if (salesActivityActiveTab) {
              refreshEntitySalesActivity(salesActivityActiveTab, salesActivityRequest);
            }
            _d.label = 3;
          case 3:
            return [2 /*return*/];
        }
      });
    });
  };

  return refreshSalesActivity;
};

exports["default"] = useRefreshSalesActivity;
