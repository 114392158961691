import {
	BulkUpdateRecordsRequest,
	ListPagePostRequest,
	CustomViewResponse,
	CustomView,
	CustomViewsMetadata
} from "@zomentum/contracts/dist/UI";
import {
	VendorPartner,
	VendorPartnerBulkUpdateServiceResponse,
	VendorPartnerResponse,
	InviteVendorPartnerRequest,
	EditVendorPartnerRequest,
	VendorPartnerInviteDetails,
	VendorInviteDataRequest,
	UnsuspendPartnerRequest,
	SuspendPartnerRequest,
	UpdatePartnerTierRequest,
	GetVendorPartnersRequest,
	updatePartnerStatusRequest
} from "@zomentum/contracts/dist/Vendor/Partner";
import axios, { AxiosResponse } from "axios";
import {
	getRequest,
	postRequest,
	putRequest,
	deleteRequest
} from "@zomentum/utils/dist/Service/BackendService";
import {
	getTableQueryFilters,
	ChildEntityHydrationParams,
	includeChildEntities
} from "@zomentum/contracts/dist/Utils";
import {
	BulkImportResponse,
	BulkImportTemplateFileType
} from "@zomentum/contracts/dist/BulkImport";
import {
	VendorOpportunityResponse,
	ZomentumEntities
} from "@zomentum/contracts";

const inviteVendorPartnerURL = "/v1/marketplace/vendors/partner/invite";
const getVendorPartnerDetailsURL = "/v1/marketplace/vendors/partner/{id}";
const updateVendorPartnerURL = "/v1/marketplace/vendors/partner/{id}";
const getAllVendorPartnerDetailsURL = "/v1/marketplace/vendors/partner";
const getAllVendorPartnerDetailsURLFilter =
	"/v1/marketplace/vendors/partners/filter";
const deleteVendorPartnerURL = "/v1/marketplace/vendor/partner/{id}/delete";
const bulkActionURL = "/v2/async/operation";
const getAllVendorPartnerDetailsPaginationURL =
	"/v1/marketplace/vendors/partner/view";
const vendorPartnersBulkImportUrl = "/v1/marketplace/bulk-import-partners";
const vendorPartnersBulkImportTemplateDownloadUrl =
	"/v1/marketplace/vendors/partners/bulk/template?file_type={file_type}";

const getInvitePartnerDetailsURL =
	"/v1/marketplace/vendors/partner/metadata?email={email}";
const getVendorPartnerMetaDataURL =
	"/v2/views/metadata?entity_type={entity_type}";

const downloadAllPartnersCSVURL =
	"/v1/marketplace/vendor-partners/bulk/export/";

const suspendPartnerURL = "/v1/marketplace/vendors/partner/:id/suspend/";
const unsuspendPartnerURL = "/v1/marketplace/vendors/partner/:id/unsuspend/";

const sendReminderToPartnerURL = "v1/marketplace/vendors/partner/{id}/remind/";

const getAllPartnerViewURL = "/v2/views?entity_type={entity_type}";
const createPartnerViewURL = "/v2/views";
const updatePartnerViewURL = "/v2/views/{view_id}";
const deletePartnerViewURL = "v2/views/{view_id}";

const getOpportunitiesURL = "v1/marketplace/vendors/partner/{id}/opportunities";
const updatePartnerStatusURL =
	"/v1/marketplace/vendors/partner/:id/update-status";
const reInviteVendorPartnerURL = "/v1/marketplace/vendors/partner/:id/reinvite";

export const getPartnerListPagePost = async (
	PartnerRequest: ListPagePostRequest
): Promise<VendorPartnerResponse> => {
	try {
		const includedChildEntities = includeChildEntities(
			PartnerRequest.included_child_entities
		);
		const response: AxiosResponse<VendorPartnerResponse> = await axios(
			postRequest(
				getAllVendorPartnerDetailsPaginationURL +
					`?${includedChildEntities}`,
				PartnerRequest,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const updateVendorPartner = async (
	updatePartnerRequest: EditVendorPartnerRequest
): Promise<void> => {
	try {
		const response = await axios(
			putRequest(
				updateVendorPartnerURL.replace(
					"{id}",
					updatePartnerRequest.partner_id
				),
				updatePartnerRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const sendReminder = async (
	partnerId: string
): Promise<VendorPartnerResponse> => {
	try {
		const response: AxiosResponse<VendorPartnerResponse> = await axios(
			putRequest(
				sendReminderToPartnerURL.replace("{id}", partnerId),
				{},
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deletevendorPartner = async (
	partner: VendorPartner
): Promise<VendorPartnerResponse> => {
	try {
		const response: AxiosResponse<VendorPartnerResponse> = await axios(
			postRequest(
				deleteVendorPartnerURL.replace("{id}", partner.id),
				{},
				true,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getVendorPartnerDetails = async (
	vendorId: string
): Promise<VendorPartner> => {
	try {
		const includedChildEntities = includeChildEntities([
			ChildEntityHydrationParams.USERS_ALL,
			ChildEntityHydrationParams.PARTNER_TIERS_ALL
		]);
		const response: AxiosResponse<VendorPartner> = await axios(
			getRequest(
				getVendorPartnerDetailsURL.replace("{id}", vendorId) +
					`?${includedChildEntities}`,
				null,
				true,
				false,
				false,
				undefined,
				undefined
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getVendorPartnerOpportunities = async (
	partnerId: string
): Promise<VendorOpportunityResponse> => {
	try {
		const response: AxiosResponse<VendorOpportunityResponse> = await axios(
			getRequest(
				getOpportunitiesURL.replace("{id}", partnerId),
				null,
				true,
				false,
				false,
				undefined,
				undefined
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getAllVendorPartnerDetails =
	async (): Promise<VendorPartnerResponse> => {
		try {
			const response: AxiosResponse<VendorPartnerResponse> = await axios(
				getRequest(
					getAllVendorPartnerDetailsURL,
					null,
					true,
					false,
					false,
					undefined,
					undefined
				)
			);
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const bulkUpdatePartners = async (
	bulkUpdateRecordsRequest: BulkUpdateRecordsRequest
): Promise<VendorPartnerBulkUpdateServiceResponse> => {
	try {
		const response: AxiosResponse<any> = await axios(
			postRequest(
				bulkActionURL,
				bulkUpdateRecordsRequest,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const inviteVendorPartner = async (
	inviteVendorPartnerRequest: InviteVendorPartnerRequest
): Promise<VendorPartner> => {
	try {
		const response: AxiosResponse<VendorPartner> = await axios(
			postRequest(
				inviteVendorPartnerURL,
				inviteVendorPartnerRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const vendorPartnersBulkImport = async (
	formData: FormData
): Promise<BulkImportResponse<VendorPartner>> => {
	try {
		const response: AxiosResponse<BulkImportResponse<VendorPartner>> =
			await axios(
				postRequest(
					vendorPartnersBulkImportUrl,
					formData,
					true,
					false,
					true
				)
			);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const vendorPartnersBulkImportTemplateDownload = async (
	bulkImportTemplateFileType: BulkImportTemplateFileType
): Promise<Blob> => {
	try {
		const response: AxiosResponse<Blob> = await axios(
			getRequest(
				vendorPartnersBulkImportTemplateDownloadUrl.replace(
					"{file_type}",
					bulkImportTemplateFileType
				),
				null,
				true,
				false,
				true
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const vendorPartnerBulkExportPartnersCSV = async (): Promise<Blob> => {
	try {
		const response: AxiosResponse<Blob> = await axios(
			getRequest(downloadAllPartnersCSVURL, null, true, false, true)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getInvitePartnerDetails = async (
	partnerRequest: VendorInviteDataRequest
): Promise<VendorPartnerInviteDetails> => {
	try {
		const encodedEmail = encodeURIComponent(partnerRequest.email);
		const response: AxiosResponse<VendorPartnerInviteDetails> = await axios(
			getRequest(
				getInvitePartnerDetailsURL.replace("{email}", encodedEmail),
				null,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getVendorPartnerMetaData =
	async (): Promise<CustomViewsMetadata> => {
		try {
			const response = await axios(
				getRequest(
					getVendorPartnerMetaDataURL.replace(
						"{entity_type}",
						ZomentumEntities.VendorPartner
					),
					null,
					true,
					false,
					false
				)
			);
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const suspendPartner = async (
	partnerId: string,
	suspendPartnerRequest: SuspendPartnerRequest
): Promise<void> => {
	try {
		const response = await axios(
			putRequest(
				suspendPartnerURL.replace(":id", partnerId),
				suspendPartnerRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const unsuspendPartner = async (
	partnerId: string,
	unsuspendPartnerRequest: UnsuspendPartnerRequest
): Promise<void> => {
	try {
		const response = await axios(
			putRequest(
				unsuspendPartnerURL.replace(":id", partnerId),
				unsuspendPartnerRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateVendorPartnerTier = async (
	partnerId: string,
	updatePartnerTierRequest: UpdatePartnerTierRequest
): Promise<void> => {
	try {
		const response = await axios(
			putRequest(
				updateVendorPartnerURL.replace("{id}", partnerId),
				updatePartnerTierRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getAllPartnersForTier = async (
	getPartnerTierRequest: GetVendorPartnersRequest
): Promise<VendorPartnerResponse> => {
	try {
		let queryParams = `limit=${getPartnerTierRequest.limit}`;
		const included_child_entities = includeChildEntities(
			getPartnerTierRequest.included_child_entities
		);
		queryParams = `${queryParams}${
			included_child_entities && included_child_entities.length > 0
				? `&${included_child_entities}`
				: ""
		}`;
		queryParams = `${queryParams}${
			getPartnerTierRequest.keywords &&
			getPartnerTierRequest.keywords.length > 0
				? `&q=${getPartnerTierRequest.keywords}`
				: ""
		}`;
		if (getPartnerTierRequest.filters.length > 0) {
			const appliedFilters = getTableQueryFilters(
				getPartnerTierRequest.filters,
				false
			);
			queryParams = `${queryParams}${
				appliedFilters && appliedFilters.length > 0
					? `&${appliedFilters}`
					: ""
			}`;
		}
		const response: AxiosResponse<VendorPartnerResponse> = await axios(
			getRequest(
				`${getAllVendorPartnerDetailsURLFilter}?${queryParams}`,
				null,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const getAllPartnerViews = async (): Promise<CustomViewResponse> => {
	try {
		const response: AxiosResponse<CustomViewResponse> = await axios(
			getRequest(
				getAllPartnerViewURL.replace(
					"{entity_type}",
					ZomentumEntities.VendorPartner
				),
				null,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const createPartnerView = async (
	customViewRequest: CustomView
): Promise<CustomView> => {
	try {
		const response: AxiosResponse<CustomView> = await axios(
			postRequest(
				createPartnerViewURL,
				customViewRequest,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const updatePartnerView = async (
	customViewRequest: CustomView
): Promise<CustomView> => {
	try {
		const response: AxiosResponse<CustomView> = await axios(
			putRequest(
				updatePartnerViewURL.replace("{view_id}", customViewRequest.id),
				customViewRequest,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const deletePartnerView = async (
	customViewId: string
): Promise<unknown> => {
	try {
		const response: AxiosResponse<unknown> = await axios(
			deleteRequest(
				deletePartnerViewURL.replace("{view_id}", customViewId),
				null,
				true,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const getAllPartnersForResource = async (
	getPartnerResourceRequest: GetVendorPartnersRequest
): Promise<VendorPartnerResponse> => {
	try {
		let queryParams = `limit=${getPartnerResourceRequest.limit}`;
		const included_child_entities = includeChildEntities(
			getPartnerResourceRequest.included_child_entities
		);
		queryParams = `${queryParams}${
			included_child_entities && included_child_entities.length > 0
				? `&${included_child_entities}`
				: ""
		}`;
		queryParams = `${queryParams}${
			!!getPartnerResourceRequest?.keywords?.length
				? `&q=${getPartnerResourceRequest.keywords}`
				: ""
		}`;
		if (!!getPartnerResourceRequest.filters.length) {
			const appliedFilters = getTableQueryFilters(
				getPartnerResourceRequest.filters,
				false
			);
			queryParams = `${queryParams}${
				!!appliedFilters?.length ? `&${appliedFilters}` : ""
			}`;
		}
		const response: AxiosResponse<VendorPartnerResponse> = await axios(
			getRequest(
				`${getAllVendorPartnerDetailsURLFilter}?${queryParams}`,
				null,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const updatePartnerStatus = async (
	partnerId: string,
	updatePartnerStatusRequest: updatePartnerStatusRequest
): Promise<VendorPartner> => {
	try {
		const response: AxiosResponse<VendorPartner> = await axios(
			postRequest(
				updatePartnerStatusURL.replace(":id", partnerId),
				updatePartnerStatusRequest,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const reinviteVendorPartner = async (
	partnerId: string
): Promise<void> => {
	try {
		const response = await axios(
			putRequest(
				reInviteVendorPartnerURL.replace(":id", partnerId),
				{},
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
