'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var getTechnographics = function () {
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = "" + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;
    var browserNames;
    (function (browserNames) {
        browserNames["OPERA"] = "Opera";
        browserNames["MSIE"] = "MSIE";
        browserNames["CHROME"] = "Chrome";
        browserNames["SAFARI"] = "Safari";
        browserNames["FIREFOX"] = "Firefox";
    })(browserNames || (browserNames = {}));
    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf(browserNames.OPERA)) != -1) {
        browserName = browserNames.OPERA;
        fullVersion = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf("Version")) != -1)
            fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf(browserNames.MSIE)) != -1) {
        browserName = "Microsoft Internet Explorer";
        fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf(browserNames.CHROME)) != -1) {
        browserName = browserNames.CHROME;
        fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf(browserNames.SAFARI)) != -1) {
        browserName = browserNames.SAFARI;
        fullVersion = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf("Version")) != -1)
            fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf(browserNames.FIREFOX)) != -1) {
        browserName = browserNames.FIREFOX;
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if ((nameOffset = nAgt.lastIndexOf(" ") + 1) <
        (verOffset = nAgt.lastIndexOf("/"))) {
        browserName = nAgt.substring(nameOffset, verOffset);
        fullVersion = nAgt.substring(verOffset + 1);
        if (browserName.toLowerCase() == browserName.toUpperCase()) {
            browserName = navigator.appName;
        }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
        fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
        fullVersion = fullVersion.substring(0, ix);
    majorVersion = parseInt("" + fullVersion, 10);
    if (isNaN(majorVersion)) {
        fullVersion = "" + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
    }
    var OSName = "Unknown OS";
    if (navigator.appVersion.indexOf("Win") != -1)
        OSName = "Windows";
    if (navigator.appVersion.indexOf("Mac") != -1)
        OSName = "MacOS";
    if (navigator.appVersion.indexOf("X11") != -1)
        OSName = "UNIX";
    if (navigator.appVersion.indexOf("Linux") != -1)
        OSName = "Linux";
    return {
        OSName: OSName,
        browserName: browserName,
        fullVersion: fullVersion,
        majorVersion: majorVersion,
        appName: navigator.appName,
        userAgent: navigator.userAgent
    };
};
var getTechnographics$1 = getTechnographics;

exports["default"] = getTechnographics$1;
