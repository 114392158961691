import { Reducer } from "redux";

import { UIConfigActionTypes, UIConfigActions } from "../../Actions/UI";
import { BulkUpdatesActionResult } from "@zomentum/contracts/dist/UI";

export interface UIConfigState {
	readonly showBeamerUpdateIcon: boolean;
	readonly bulkUpdateResult: BulkUpdatesActionResult[];
	readonly showFreshChatUnreadMessageBubble: boolean;
	readonly collectJSToken: string | null;
	readonly isMobileView: boolean;
}

export const initialUIState: UIConfigState = {
	showBeamerUpdateIcon: false,
	bulkUpdateResult: [],
	showFreshChatUnreadMessageBubble: false,
	collectJSToken: null,
	isMobileView: false
};

const UIReducer: Reducer<UIConfigState, UIConfigActions> = (
	state: UIConfigState = initialUIState,
	action: UIConfigActions
): UIConfigState => {
	switch (action.type) {
		case UIConfigActionTypes.CHECK_BEAMER_STATUS: {
			return {
				...state,
				showBeamerUpdateIcon: action.shouldShowBeamerIcon
			};
		}
		case UIConfigActionTypes.BULK_UPDATES_REQUESTED: {
			return state;
		}
		case UIConfigActionTypes.BULK_UPDATES_SUCCESSFUL: {
			return {
				...state,
				bulkUpdateResult: [...action.bulkUpdatesResult.data]
			};
		}
		case UIConfigActionTypes.BULK_UPDATES_RESET: {
			return { ...state, bulkUpdateResult: [] };
		}
		case UIConfigActionTypes.CHECK_FRESHCHAT_UNREAD_MESSAGES: {
			return {
				...state,
				showFreshChatUnreadMessageBubble:
					action.shouldShowFreshChatBubble
			};
		}
		case UIConfigActionTypes.COLLECTJS_TOKEN_SUCCESSFUL:
			return {
				...state,
				collectJSToken: action.collectjsToken
			};

		case UIConfigActionTypes.UPDATE_MOBILE_VIEW:
			return {
				...state,
				isMobileView: action.isMobileView
			};

		default: {
			return { ...state };
		}
	}
};
export default UIReducer;
