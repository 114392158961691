'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var useBreakpoint = require('./useBreakpoint.js');

var useMobileView = (function () {
  return useBreakpoint["default"]("(max-width: 600px)");
});

exports["default"] = useMobileView;
