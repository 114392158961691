'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var _commonjsHelpers = require('../../../../_virtual/_commonjsHelpers.js');

var index_module_less = _commonjsHelpers.createCommonjsModule(function (module, exports) {

Object.defineProperty(exports, '__esModule', {
  value: true
});
var styles = {
  "color-fill-transparent-1": "index-module_color-fill-transparent-1__iIVM9",
  "color-red-10": "index-module_color-red-10__GtKA5",
  "color-fill-stroke-same-red-10": "index-module_color-fill-stroke-same-red-10__u5GoE",
  "color-fill-red-10": "index-module_color-fill-red-10__pqOOR",
  "color-red-9": "index-module_color-red-9__ZNGTc",
  "color-fill-stroke-same-red-9": "index-module_color-fill-stroke-same-red-9__3sLra",
  "color-fill-red-9": "index-module_color-fill-red-9__8syIL",
  "color-red-8": "index-module_color-red-8__N2v-Z",
  "color-fill-stroke-same-red-8": "index-module_color-fill-stroke-same-red-8__F8u4s",
  "color-fill-red-8": "index-module_color-fill-red-8__VqQTG",
  "color-red-7": "index-module_color-red-7__-t7GE",
  "color-fill-stroke-same-red-7": "index-module_color-fill-stroke-same-red-7__frtiO",
  "color-fill-red-7": "index-module_color-fill-red-7__6WkZl",
  "color-red-6": "index-module_color-red-6__4SLjb",
  "color-fill-stroke-same-red-6": "index-module_color-fill-stroke-same-red-6__fbSg7",
  "color-fill-red-6": "index-module_color-fill-red-6__yMBcV",
  "color-red-5": "index-module_color-red-5__9XQL6",
  "color-fill-stroke-same-red-5": "index-module_color-fill-stroke-same-red-5__sH5Ct",
  "color-fill-red-5": "index-module_color-fill-red-5__9RT4E",
  "color-red-4": "index-module_color-red-4__cMN84",
  "color-fill-stroke-same-red-4": "index-module_color-fill-stroke-same-red-4__3-Xxp",
  "color-fill-red-4": "index-module_color-fill-red-4__hZf-H",
  "color-red-3": "index-module_color-red-3__jsR3N",
  "color-fill-stroke-same-red-3": "index-module_color-fill-stroke-same-red-3__Dqi5-",
  "color-fill-red-3": "index-module_color-fill-red-3__5xBod",
  "color-red-2": "index-module_color-red-2__W1oAB",
  "color-fill-stroke-same-red-2": "index-module_color-fill-stroke-same-red-2__XjkVg",
  "color-fill-red-2": "index-module_color-fill-red-2__vIvLG",
  "color-red-1": "index-module_color-red-1__YCg72",
  "color-fill-stroke-same-red-1": "index-module_color-fill-stroke-same-red-1__wpF7N",
  "color-fill-red-1": "index-module_color-fill-red-1__TZCf1",
  "color-volcano-10": "index-module_color-volcano-10__C4T5-",
  "color-fill-stroke-same-volcano-10": "index-module_color-fill-stroke-same-volcano-10__38JHi",
  "color-fill-volcano-10": "index-module_color-fill-volcano-10__Ii-br",
  "color-volcano-9": "index-module_color-volcano-9__Xqzzk",
  "color-fill-stroke-same-volcano-9": "index-module_color-fill-stroke-same-volcano-9__HXap8",
  "color-fill-volcano-9": "index-module_color-fill-volcano-9__OVJ7Y",
  "color-volcano-8": "index-module_color-volcano-8__IlzPn",
  "color-fill-stroke-same-volcano-8": "index-module_color-fill-stroke-same-volcano-8__CWTzi",
  "color-fill-volcano-8": "index-module_color-fill-volcano-8__L1qRE",
  "color-volcano-7": "index-module_color-volcano-7__oyykY",
  "color-fill-stroke-same-volcano-7": "index-module_color-fill-stroke-same-volcano-7__6-uEA",
  "color-fill-volcano-7": "index-module_color-fill-volcano-7__s1lWe",
  "color-volcano-6": "index-module_color-volcano-6__dTxCE",
  "color-fill-stroke-same-volcano-6": "index-module_color-fill-stroke-same-volcano-6__-URbO",
  "color-fill-volcano-6": "index-module_color-fill-volcano-6__r5ZL6",
  "color-volcano-5": "index-module_color-volcano-5__-bJ3T",
  "color-fill-stroke-same-volcano-5": "index-module_color-fill-stroke-same-volcano-5__KOIhD",
  "color-fill-volcano-5": "index-module_color-fill-volcano-5__uub-6",
  "color-volcano-4": "index-module_color-volcano-4__vjm4A",
  "color-fill-stroke-same-volcano-4": "index-module_color-fill-stroke-same-volcano-4__zmmL-",
  "color-fill-volcano-4": "index-module_color-fill-volcano-4__M78j3",
  "color-volcano-3": "index-module_color-volcano-3__sGkBt",
  "color-fill-stroke-same-volcano-3": "index-module_color-fill-stroke-same-volcano-3__Byi52",
  "color-fill-volcano-3": "index-module_color-fill-volcano-3__6-k1v",
  "color-volcano-2": "index-module_color-volcano-2__W6xen",
  "color-fill-stroke-same-volcano-2": "index-module_color-fill-stroke-same-volcano-2__X5uN4",
  "color-fill-volcano-2": "index-module_color-fill-volcano-2__NkT7K",
  "color-volcano-1": "index-module_color-volcano-1__--4hp",
  "color-fill-stroke-same-volcano-1": "index-module_color-fill-stroke-same-volcano-1__YuWLW",
  "color-fill-volcano-1": "index-module_color-fill-volcano-1__jmh-O",
  "color-orange-10": "index-module_color-orange-10__vg1Lx",
  "color-fill-stroke-same-orange-10": "index-module_color-fill-stroke-same-orange-10__ZHHTy",
  "color-fill-orange-10": "index-module_color-fill-orange-10__DSCkn",
  "color-orange-9": "index-module_color-orange-9__nye-2",
  "color-fill-stroke-same-orange-9": "index-module_color-fill-stroke-same-orange-9__5teUI",
  "color-fill-orange-9": "index-module_color-fill-orange-9__iqQv5",
  "color-orange-8": "index-module_color-orange-8__x5wxt",
  "color-fill-stroke-same-orange-8": "index-module_color-fill-stroke-same-orange-8__A4HCM",
  "color-fill-orange-8": "index-module_color-fill-orange-8__8Y1C6",
  "color-orange-7": "index-module_color-orange-7__eDsJ0",
  "color-fill-stroke-same-orange-7": "index-module_color-fill-stroke-same-orange-7__--eOu",
  "color-fill-orange-7": "index-module_color-fill-orange-7__r-5Zt",
  "color-orange-6": "index-module_color-orange-6__dkC0U",
  "color-fill-stroke-same-orange-6": "index-module_color-fill-stroke-same-orange-6__U3zCj",
  "color-fill-orange-6": "index-module_color-fill-orange-6__7Cn6r",
  "color-orange-5": "index-module_color-orange-5__XLlbk",
  "color-fill-stroke-same-orange-5": "index-module_color-fill-stroke-same-orange-5__y3a-c",
  "color-fill-orange-5": "index-module_color-fill-orange-5__-SliD",
  "color-orange-4": "index-module_color-orange-4__YNisX",
  "color-fill-stroke-same-orange-4": "index-module_color-fill-stroke-same-orange-4__VUM7i",
  "color-fill-orange-4": "index-module_color-fill-orange-4__3-Tap",
  "color-orange-3": "index-module_color-orange-3__Wbulw",
  "color-fill-stroke-same-orange-3": "index-module_color-fill-stroke-same-orange-3__etwCQ",
  "color-fill-orange-3": "index-module_color-fill-orange-3__Y-DG9",
  "color-orange-2": "index-module_color-orange-2__sjxrh",
  "color-fill-stroke-same-orange-2": "index-module_color-fill-stroke-same-orange-2__tE0C8",
  "color-fill-orange-2": "index-module_color-fill-orange-2__YAgu3",
  "color-orange-1": "index-module_color-orange-1__DtnVO",
  "color-fill-stroke-same-orange-1": "index-module_color-fill-stroke-same-orange-1__dr4l8",
  "color-fill-orange-1": "index-module_color-fill-orange-1__GA8mE",
  "color-gold-10": "index-module_color-gold-10__kjext",
  "color-fill-stroke-same-gold-10": "index-module_color-fill-stroke-same-gold-10__4oe6H",
  "color-fill-gold-10": "index-module_color-fill-gold-10__BS3sQ",
  "color-gold-9": "index-module_color-gold-9__83j83",
  "color-fill-stroke-same-gold-9": "index-module_color-fill-stroke-same-gold-9__8tU-s",
  "color-fill-gold-9": "index-module_color-fill-gold-9__b-ibp",
  "color-gold-8": "index-module_color-gold-8__vNajR",
  "color-fill-stroke-same-gold-8": "index-module_color-fill-stroke-same-gold-8__EMDX-",
  "color-fill-gold-8": "index-module_color-fill-gold-8__z2OFc",
  "color-gold-7": "index-module_color-gold-7__HvJvq",
  "color-fill-stroke-same-gold-7": "index-module_color-fill-stroke-same-gold-7__jnBRk",
  "color-fill-gold-7": "index-module_color-fill-gold-7__fWV2C",
  "color-gold-6": "index-module_color-gold-6__ApW2c",
  "color-fill-stroke-same-gold-6": "index-module_color-fill-stroke-same-gold-6__1IP43",
  "color-fill-gold-6": "index-module_color-fill-gold-6__Fe2gx",
  "color-gold-5": "index-module_color-gold-5__6mG3K",
  "color-fill-stroke-same-gold-5": "index-module_color-fill-stroke-same-gold-5__ReePe",
  "color-fill-gold-5": "index-module_color-fill-gold-5__NHMcC",
  "color-gold-4": "index-module_color-gold-4__9UXq5",
  "color-fill-stroke-same-gold-4": "index-module_color-fill-stroke-same-gold-4__wXyti",
  "color-fill-gold-4": "index-module_color-fill-gold-4__hIjLE",
  "color-gold-3": "index-module_color-gold-3__TgFBg",
  "color-fill-stroke-same-gold-3": "index-module_color-fill-stroke-same-gold-3__Q-JTc",
  "color-fill-gold-3": "index-module_color-fill-gold-3__LdRzc",
  "color-gold-2": "index-module_color-gold-2__rJ5uv",
  "color-fill-stroke-same-gold-2": "index-module_color-fill-stroke-same-gold-2__zYgYD",
  "color-fill-gold-2": "index-module_color-fill-gold-2__HsLnd",
  "color-gold-1": "index-module_color-gold-1__tVKtO",
  "color-fill-stroke-same-gold-1": "index-module_color-fill-stroke-same-gold-1__uuht6",
  "color-fill-gold-1": "index-module_color-fill-gold-1__Oup2n",
  "color-yellow-10": "index-module_color-yellow-10__93p8G",
  "color-fill-stroke-same-yellow-10": "index-module_color-fill-stroke-same-yellow-10__tuqKD",
  "color-fill-yellow-10": "index-module_color-fill-yellow-10__jOvpJ",
  "color-yellow-9": "index-module_color-yellow-9__xVGwm",
  "color-fill-stroke-same-yellow-9": "index-module_color-fill-stroke-same-yellow-9__sMlOC",
  "color-fill-yellow-9": "index-module_color-fill-yellow-9__vWNIv",
  "color-yellow-8": "index-module_color-yellow-8__BIlzN",
  "color-fill-stroke-same-yellow-8": "index-module_color-fill-stroke-same-yellow-8__N5Bu3",
  "color-fill-yellow-8": "index-module_color-fill-yellow-8__YcWKm",
  "color-yellow-7": "index-module_color-yellow-7__n7aGB",
  "color-fill-stroke-same-yellow-7": "index-module_color-fill-stroke-same-yellow-7__W-17z",
  "color-fill-yellow-7": "index-module_color-fill-yellow-7__Ad3gp",
  "color-yellow-6": "index-module_color-yellow-6__--5bs",
  "color-fill-stroke-same-yellow-6": "index-module_color-fill-stroke-same-yellow-6__uxX-N",
  "color-fill-yellow-6": "index-module_color-fill-yellow-6__-d-vZ",
  "color-yellow-5": "index-module_color-yellow-5__blxBH",
  "color-fill-stroke-same-yellow-5": "index-module_color-fill-stroke-same-yellow-5__MUIf5",
  "color-fill-yellow-5": "index-module_color-fill-yellow-5__dzqbd",
  "color-yellow-4": "index-module_color-yellow-4__hfRpB",
  "color-fill-stroke-same-yellow-4": "index-module_color-fill-stroke-same-yellow-4__jyuGb",
  "color-fill-yellow-4": "index-module_color-fill-yellow-4__C-ZxG",
  "color-yellow-3": "index-module_color-yellow-3__rvNZs",
  "color-fill-stroke-same-yellow-3": "index-module_color-fill-stroke-same-yellow-3__fdi99",
  "color-fill-yellow-3": "index-module_color-fill-yellow-3__AVNPG",
  "color-yellow-2": "index-module_color-yellow-2__bLc07",
  "color-fill-stroke-same-yellow-2": "index-module_color-fill-stroke-same-yellow-2__vWLdH",
  "color-fill-yellow-2": "index-module_color-fill-yellow-2__-qrb5",
  "color-yellow-1": "index-module_color-yellow-1__MlHCj",
  "color-fill-stroke-same-yellow-1": "index-module_color-fill-stroke-same-yellow-1__Rckt2",
  "color-fill-yellow-1": "index-module_color-fill-yellow-1__WYLRA",
  "color-lime-10": "index-module_color-lime-10__w7rPI",
  "color-fill-stroke-same-lime-10": "index-module_color-fill-stroke-same-lime-10__diFed",
  "color-fill-lime-10": "index-module_color-fill-lime-10__NPE71",
  "color-lime-9": "index-module_color-lime-9__Ucyef",
  "color-fill-stroke-same-lime-9": "index-module_color-fill-stroke-same-lime-9__rc6T3",
  "color-fill-lime-9": "index-module_color-fill-lime-9__VNbi0",
  "color-lime-8": "index-module_color-lime-8__vcXbp",
  "color-fill-stroke-same-lime-8": "index-module_color-fill-stroke-same-lime-8__qb7OA",
  "color-fill-lime-8": "index-module_color-fill-lime-8__f-35c",
  "color-lime-7": "index-module_color-lime-7__UtBjk",
  "color-fill-stroke-same-lime-7": "index-module_color-fill-stroke-same-lime-7__7a-A-",
  "color-fill-lime-7": "index-module_color-fill-lime-7__7jZB9",
  "color-lime-6": "index-module_color-lime-6__j8P2b",
  "color-fill-stroke-same-lime-6": "index-module_color-fill-stroke-same-lime-6__GvpVr",
  "color-fill-lime-6": "index-module_color-fill-lime-6__FgwRY",
  "color-lime-5": "index-module_color-lime-5__xOV-M",
  "color-fill-stroke-same-lime-5": "index-module_color-fill-stroke-same-lime-5__-Lgku",
  "color-fill-lime-5": "index-module_color-fill-lime-5__hAN5D",
  "color-lime-4": "index-module_color-lime-4__aPS-O",
  "color-fill-stroke-same-lime-4": "index-module_color-fill-stroke-same-lime-4__U6OxC",
  "color-fill-lime-4": "index-module_color-fill-lime-4__Sk-Cy",
  "color-lime-3": "index-module_color-lime-3__FcWyD",
  "color-fill-stroke-same-lime-3": "index-module_color-fill-stroke-same-lime-3__E9YCx",
  "color-fill-lime-3": "index-module_color-fill-lime-3__NyrG8",
  "color-lime-2": "index-module_color-lime-2__e2rCu",
  "color-fill-stroke-same-lime-2": "index-module_color-fill-stroke-same-lime-2__WinGL",
  "color-fill-lime-2": "index-module_color-fill-lime-2__nLyP-",
  "color-lime-1": "index-module_color-lime-1__v9cGb",
  "color-fill-stroke-same-lime-1": "index-module_color-fill-stroke-same-lime-1__UNw0r",
  "color-fill-lime-1": "index-module_color-fill-lime-1__20xJi",
  "color-green-10": "index-module_color-green-10__M5-rx",
  "color-fill-stroke-same-green-10": "index-module_color-fill-stroke-same-green-10__R8gsO",
  "color-fill-green-10": "index-module_color-fill-green-10__kSttn",
  "color-green-9": "index-module_color-green-9__rEHxM",
  "color-fill-stroke-same-green-9": "index-module_color-fill-stroke-same-green-9__vYJjD",
  "color-fill-green-9": "index-module_color-fill-green-9__tNuxM",
  "color-green-8": "index-module_color-green-8__Tnp44",
  "color-fill-stroke-same-green-8": "index-module_color-fill-stroke-same-green-8__4JGbX",
  "color-fill-green-8": "index-module_color-fill-green-8__vdWZq",
  "color-green-7": "index-module_color-green-7__f-bVJ",
  "color-fill-stroke-same-green-7": "index-module_color-fill-stroke-same-green-7__Momho",
  "color-fill-green-7": "index-module_color-fill-green-7__1SXOC",
  "color-green-6": "index-module_color-green-6__V77sR",
  "color-fill-stroke-same-green-6": "index-module_color-fill-stroke-same-green-6__mZxAm",
  "color-fill-green-6": "index-module_color-fill-green-6__d-Ysm",
  "color-green-5": "index-module_color-green-5__zYMa0",
  "color-fill-stroke-same-green-5": "index-module_color-fill-stroke-same-green-5__B6Zcg",
  "color-fill-green-5": "index-module_color-fill-green-5__decI2",
  "color-green-4": "index-module_color-green-4__oZOTB",
  "color-fill-stroke-same-green-4": "index-module_color-fill-stroke-same-green-4__PuBlZ",
  "color-fill-green-4": "index-module_color-fill-green-4__mEjRV",
  "color-green-3": "index-module_color-green-3__20RUL",
  "color-fill-stroke-same-green-3": "index-module_color-fill-stroke-same-green-3__NgzcT",
  "color-fill-green-3": "index-module_color-fill-green-3__8uqRA",
  "color-green-2": "index-module_color-green-2__zuz5X",
  "color-fill-stroke-same-green-2": "index-module_color-fill-stroke-same-green-2__tN1g2",
  "color-fill-green-2": "index-module_color-fill-green-2__GFs1O",
  "color-green-1": "index-module_color-green-1__9P83d",
  "color-fill-stroke-same-green-1": "index-module_color-fill-stroke-same-green-1__mXpJW",
  "color-fill-green-1": "index-module_color-fill-green-1__OmbW1",
  "color-cyan-10": "index-module_color-cyan-10__HIaf3",
  "color-fill-stroke-same-cyan-10": "index-module_color-fill-stroke-same-cyan-10__Arpa9",
  "color-fill-cyan-10": "index-module_color-fill-cyan-10__IxxGt",
  "color-cyan-9": "index-module_color-cyan-9__GjdSU",
  "color-fill-stroke-same-cyan-9": "index-module_color-fill-stroke-same-cyan-9__D3Ec5",
  "color-fill-cyan-9": "index-module_color-fill-cyan-9__ygxYh",
  "color-cyan-8": "index-module_color-cyan-8__Vr4x4",
  "color-fill-stroke-same-cyan-8": "index-module_color-fill-stroke-same-cyan-8__bm4pS",
  "color-fill-cyan-8": "index-module_color-fill-cyan-8__Q-m9k",
  "color-cyan-7": "index-module_color-cyan-7__MaViW",
  "color-fill-stroke-same-cyan-7": "index-module_color-fill-stroke-same-cyan-7__WuAu4",
  "color-fill-cyan-7": "index-module_color-fill-cyan-7__2-Ckv",
  "color-cyan-6": "index-module_color-cyan-6__Fnh-e",
  "color-fill-stroke-same-cyan-6": "index-module_color-fill-stroke-same-cyan-6__0pD6i",
  "color-fill-cyan-6": "index-module_color-fill-cyan-6__p4CzR",
  "color-cyan-5": "index-module_color-cyan-5__8aaxd",
  "color-fill-stroke-same-cyan-5": "index-module_color-fill-stroke-same-cyan-5__n3K83",
  "color-fill-cyan-5": "index-module_color-fill-cyan-5__QaSJq",
  "color-cyan-4": "index-module_color-cyan-4__O-w1D",
  "color-fill-stroke-same-cyan-4": "index-module_color-fill-stroke-same-cyan-4__7DGik",
  "color-fill-cyan-4": "index-module_color-fill-cyan-4__uttP1",
  "color-cyan-3": "index-module_color-cyan-3__MDhDd",
  "color-fill-stroke-same-cyan-3": "index-module_color-fill-stroke-same-cyan-3__jXP5D",
  "color-fill-cyan-3": "index-module_color-fill-cyan-3__JZg3N",
  "color-cyan-2": "index-module_color-cyan-2__4Ikux",
  "color-fill-stroke-same-cyan-2": "index-module_color-fill-stroke-same-cyan-2__YznoB",
  "color-fill-cyan-2": "index-module_color-fill-cyan-2__WGu-p",
  "color-cyan-1": "index-module_color-cyan-1__ttM5P",
  "color-fill-stroke-same-cyan-1": "index-module_color-fill-stroke-same-cyan-1__5RVqI",
  "color-fill-cyan-1": "index-module_color-fill-cyan-1__pYopp",
  "color-blue-10": "index-module_color-blue-10__fyO6-",
  "color-fill-stroke-same-blue-10": "index-module_color-fill-stroke-same-blue-10__ivbCK",
  "color-fill-blue-10": "index-module_color-fill-blue-10__Q6u3q",
  "color-blue-9": "index-module_color-blue-9__aEhEl",
  "color-fill-stroke-same-blue-9": "index-module_color-fill-stroke-same-blue-9__v352K",
  "color-fill-blue-9": "index-module_color-fill-blue-9__E8Jnj",
  "color-blue-8": "index-module_color-blue-8__6zhZB",
  "color-fill-stroke-same-blue-8": "index-module_color-fill-stroke-same-blue-8__to8Z0",
  "color-fill-blue-8": "index-module_color-fill-blue-8__LOy6D",
  "color-blue-7": "index-module_color-blue-7__6Vkdl",
  "color-fill-stroke-same-blue-7": "index-module_color-fill-stroke-same-blue-7__Eokfs",
  "color-fill-blue-7": "index-module_color-fill-blue-7__cGcJx",
  "color-blue-6": "index-module_color-blue-6__1XiJg",
  "color-fill-stroke-same-blue-6": "index-module_color-fill-stroke-same-blue-6__qHHG4",
  "color-fill-blue-6": "index-module_color-fill-blue-6__GESJO",
  "color-blue-5": "index-module_color-blue-5__OffWU",
  "color-fill-stroke-same-blue-5": "index-module_color-fill-stroke-same-blue-5__0DoHU",
  "color-fill-blue-5": "index-module_color-fill-blue-5__gD-Rq",
  "color-blue-4": "index-module_color-blue-4__3u01N",
  "color-fill-stroke-same-blue-4": "index-module_color-fill-stroke-same-blue-4__W4fag",
  "color-fill-blue-4": "index-module_color-fill-blue-4__DMXPO",
  "color-blue-3": "index-module_color-blue-3__m6UtY",
  "color-fill-stroke-same-blue-3": "index-module_color-fill-stroke-same-blue-3__HgKtA",
  "color-fill-blue-3": "index-module_color-fill-blue-3__uvHee",
  "color-blue-2": "index-module_color-blue-2__mk7Al",
  "color-fill-stroke-same-blue-2": "index-module_color-fill-stroke-same-blue-2__DWY-w",
  "color-fill-blue-2": "index-module_color-fill-blue-2__96Wbj",
  "color-blue-1": "index-module_color-blue-1__NAMAy",
  "color-fill-stroke-same-blue-1": "index-module_color-fill-stroke-same-blue-1__yceSK",
  "color-fill-blue-1": "index-module_color-fill-blue-1__9lfEJ",
  "color-geekBlue-10": "index-module_color-geekBlue-10__I9bkR",
  "color-fill-stroke-same-geekBlue-10": "index-module_color-fill-stroke-same-geekBlue-10__dS8YA",
  "color-fill-geekBlue-10": "index-module_color-fill-geekBlue-10__CYL9q",
  "color-geekBlue-9": "index-module_color-geekBlue-9__GyS-b",
  "color-fill-stroke-same-geekBlue-9": "index-module_color-fill-stroke-same-geekBlue-9__fuP6x",
  "color-fill-geekBlue-9": "index-module_color-fill-geekBlue-9__XamS2",
  "color-geekBlue-8": "index-module_color-geekBlue-8__bI6kQ",
  "color-fill-stroke-same-geekBlue-8": "index-module_color-fill-stroke-same-geekBlue-8__NMP57",
  "color-fill-geekBlue-8": "index-module_color-fill-geekBlue-8__gpzzg",
  "color-geekBlue-7": "index-module_color-geekBlue-7__kOYvP",
  "color-fill-stroke-same-geekBlue-7": "index-module_color-fill-stroke-same-geekBlue-7__VQjS4",
  "color-fill-geekBlue-7": "index-module_color-fill-geekBlue-7__oylIC",
  "color-geekBlue-6": "index-module_color-geekBlue-6__0xox2",
  "color-fill-stroke-same-geekBlue-6": "index-module_color-fill-stroke-same-geekBlue-6__h06YW",
  "color-fill-geekBlue-6": "index-module_color-fill-geekBlue-6__FOsdx",
  "color-geekBlue-5": "index-module_color-geekBlue-5__lo-cR",
  "color-fill-stroke-same-geekBlue-5": "index-module_color-fill-stroke-same-geekBlue-5__Oa9pH",
  "color-fill-geekBlue-5": "index-module_color-fill-geekBlue-5__IG3y2",
  "color-geekBlue-4": "index-module_color-geekBlue-4__2TTSG",
  "color-fill-stroke-same-geekBlue-4": "index-module_color-fill-stroke-same-geekBlue-4__fgiXU",
  "color-fill-geekBlue-4": "index-module_color-fill-geekBlue-4__fI4oE",
  "color-geekBlue-3": "index-module_color-geekBlue-3__sqU-j",
  "color-fill-stroke-same-geekBlue-3": "index-module_color-fill-stroke-same-geekBlue-3__UqUmY",
  "color-fill-geekBlue-3": "index-module_color-fill-geekBlue-3__vP81y",
  "color-geekBlue-2": "index-module_color-geekBlue-2__W-8Z1",
  "color-fill-stroke-same-geekBlue-2": "index-module_color-fill-stroke-same-geekBlue-2__o3OYi",
  "color-fill-geekBlue-2": "index-module_color-fill-geekBlue-2__geEPJ",
  "color-geekBlue-1": "index-module_color-geekBlue-1__A9bgk",
  "color-fill-stroke-same-geekBlue-1": "index-module_color-fill-stroke-same-geekBlue-1__I2ClB",
  "color-fill-geekBlue-1": "index-module_color-fill-geekBlue-1__TzLcW",
  "color-purple-10": "index-module_color-purple-10__xdEXX",
  "color-fill-stroke-same-purple-10": "index-module_color-fill-stroke-same-purple-10__UamvW",
  "color-fill-purple-10": "index-module_color-fill-purple-10__4dh-L",
  "color-purple-9": "index-module_color-purple-9__J8HXl",
  "color-fill-stroke-same-purple-9": "index-module_color-fill-stroke-same-purple-9__JtlmP",
  "color-fill-purple-9": "index-module_color-fill-purple-9__KVxPm",
  "color-purple-8": "index-module_color-purple-8__YfmQd",
  "color-fill-stroke-same-purple-8": "index-module_color-fill-stroke-same-purple-8__RT-Kq",
  "color-fill-purple-8": "index-module_color-fill-purple-8__fdVV1",
  "color-purple-7": "index-module_color-purple-7__1PTB7",
  "color-fill-stroke-same-purple-7": "index-module_color-fill-stroke-same-purple-7__D76zR",
  "color-fill-purple-7": "index-module_color-fill-purple-7__wUePu",
  "color-purple-6": "index-module_color-purple-6__otvN5",
  "color-fill-stroke-same-purple-6": "index-module_color-fill-stroke-same-purple-6__QCr-Q",
  "color-fill-purple-6": "index-module_color-fill-purple-6__QA7hh",
  "color-purple-5": "index-module_color-purple-5__DCq6u",
  "color-fill-stroke-same-purple-5": "index-module_color-fill-stroke-same-purple-5__GvPQV",
  "color-fill-purple-5": "index-module_color-fill-purple-5__X1uAj",
  "color-purple-4": "index-module_color-purple-4__gK5NP",
  "color-fill-stroke-same-purple-4": "index-module_color-fill-stroke-same-purple-4__cIif0",
  "color-fill-purple-4": "index-module_color-fill-purple-4__LnshN",
  "color-purple-3": "index-module_color-purple-3__Ygd3O",
  "color-fill-stroke-same-purple-3": "index-module_color-fill-stroke-same-purple-3__t4o3P",
  "color-fill-purple-3": "index-module_color-fill-purple-3__tDWYK",
  "color-purple-2": "index-module_color-purple-2__6rbSW",
  "color-fill-stroke-same-purple-2": "index-module_color-fill-stroke-same-purple-2__nzRZv",
  "color-fill-purple-2": "index-module_color-fill-purple-2__7l9bx",
  "color-purple-1": "index-module_color-purple-1__YdT8N",
  "color-fill-stroke-same-purple-1": "index-module_color-fill-stroke-same-purple-1__qKp6j",
  "color-fill-purple-1": "index-module_color-fill-purple-1__WSSlf",
  "color-magenta-10": "index-module_color-magenta-10__xtAJO",
  "color-fill-stroke-same-magenta-10": "index-module_color-fill-stroke-same-magenta-10__ghsOi",
  "color-fill-magenta-10": "index-module_color-fill-magenta-10__PEgoH",
  "color-magenta-9": "index-module_color-magenta-9__j8uD4",
  "color-fill-stroke-same-magenta-9": "index-module_color-fill-stroke-same-magenta-9__1pVQE",
  "color-fill-magenta-9": "index-module_color-fill-magenta-9__P-CA-",
  "color-magenta-8": "index-module_color-magenta-8__EzXlR",
  "color-fill-stroke-same-magenta-8": "index-module_color-fill-stroke-same-magenta-8__n6jaX",
  "color-fill-magenta-8": "index-module_color-fill-magenta-8__xqwim",
  "color-magenta-7": "index-module_color-magenta-7__2tznh",
  "color-fill-stroke-same-magenta-7": "index-module_color-fill-stroke-same-magenta-7__dYyCk",
  "color-fill-magenta-7": "index-module_color-fill-magenta-7__quN3J",
  "color-magenta-6": "index-module_color-magenta-6__gDzob",
  "color-fill-stroke-same-magenta-6": "index-module_color-fill-stroke-same-magenta-6__Mpvli",
  "color-fill-magenta-6": "index-module_color-fill-magenta-6__zGI9u",
  "color-magenta-5": "index-module_color-magenta-5__zWmQm",
  "color-fill-stroke-same-magenta-5": "index-module_color-fill-stroke-same-magenta-5__QUNgN",
  "color-fill-magenta-5": "index-module_color-fill-magenta-5__5Z4n8",
  "color-magenta-4": "index-module_color-magenta-4__mhvsT",
  "color-fill-stroke-same-magenta-4": "index-module_color-fill-stroke-same-magenta-4__lN0sY",
  "color-fill-magenta-4": "index-module_color-fill-magenta-4__TdHJi",
  "color-magenta-3": "index-module_color-magenta-3__DqezG",
  "color-fill-stroke-same-magenta-3": "index-module_color-fill-stroke-same-magenta-3__RLnKF",
  "color-fill-magenta-3": "index-module_color-fill-magenta-3__KH7CR",
  "color-magenta-2": "index-module_color-magenta-2__nFy2V",
  "color-fill-stroke-same-magenta-2": "index-module_color-fill-stroke-same-magenta-2__a04je",
  "color-fill-magenta-2": "index-module_color-fill-magenta-2__YpQNz",
  "color-magenta-1": "index-module_color-magenta-1__fwLBl",
  "color-fill-stroke-same-magenta-1": "index-module_color-fill-stroke-same-magenta-1__ByY9h",
  "color-fill-magenta-1": "index-module_color-fill-magenta-1__e52DT",
  "color-gray-10": "index-module_color-gray-10__vgqZu",
  "color-fill-stroke-same-gray-10": "index-module_color-fill-stroke-same-gray-10__Vnqo1",
  "color-fill-gray-10": "index-module_color-fill-gray-10__zYoO7",
  "color-gray-9": "index-module_color-gray-9__4SY0z",
  "color-fill-stroke-same-gray-9": "index-module_color-fill-stroke-same-gray-9__SBLq5",
  "color-fill-gray-9": "index-module_color-fill-gray-9__90gtI",
  "color-gray-8": "index-module_color-gray-8__CEUrV",
  "color-fill-stroke-same-gray-8": "index-module_color-fill-stroke-same-gray-8__WnzKa",
  "color-fill-gray-8": "index-module_color-fill-gray-8__ALpwZ",
  "color-gray-7": "index-module_color-gray-7__bTZ7c",
  "color-fill-stroke-same-gray-7": "index-module_color-fill-stroke-same-gray-7__jOBAv",
  "color-fill-gray-7": "index-module_color-fill-gray-7__nJggi",
  "color-gray-6": "index-module_color-gray-6__D9vPh",
  "color-fill-stroke-same-gray-6": "index-module_color-fill-stroke-same-gray-6__on5EX",
  "color-fill-gray-6": "index-module_color-fill-gray-6__nK9f9",
  "color-gray-5": "index-module_color-gray-5__pYRBR",
  "color-fill-stroke-same-gray-5": "index-module_color-fill-stroke-same-gray-5__eTL49",
  "color-fill-gray-5": "index-module_color-fill-gray-5__bVhUt",
  "color-gray-4": "index-module_color-gray-4__mt7iT",
  "color-fill-stroke-same-gray-4": "index-module_color-fill-stroke-same-gray-4__xFeFb",
  "color-fill-gray-4": "index-module_color-fill-gray-4__DQwCu",
  "color-gray-3": "index-module_color-gray-3__s43DA",
  "color-fill-stroke-same-gray-3": "index-module_color-fill-stroke-same-gray-3__pGxIM",
  "color-fill-gray-3": "index-module_color-fill-gray-3__cxcy7",
  "color-gray-2": "index-module_color-gray-2__DD3JU",
  "color-fill-stroke-same-gray-2": "index-module_color-fill-stroke-same-gray-2__YFI6f",
  "color-fill-gray-2": "index-module_color-fill-gray-2__HCqXx",
  "color-gray-1": "index-module_color-gray-1__IsK2h",
  "color-fill-stroke-same-gray-1": "index-module_color-fill-stroke-same-gray-1__SFvE-",
  "color-fill-gray-1": "index-module_color-fill-gray-1__B8DDA",
  "color_fill_transparent_1": "index-module_color-fill-transparent-1__iIVM9",
  "color_red_10": "index-module_color-red-10__GtKA5",
  "color_fill_stroke_same_red_10": "index-module_color-fill-stroke-same-red-10__u5GoE",
  "color_fill_red_10": "index-module_color-fill-red-10__pqOOR",
  "color_red_9": "index-module_color-red-9__ZNGTc",
  "color_fill_stroke_same_red_9": "index-module_color-fill-stroke-same-red-9__3sLra",
  "color_fill_red_9": "index-module_color-fill-red-9__8syIL",
  "color_red_8": "index-module_color-red-8__N2v-Z",
  "color_fill_stroke_same_red_8": "index-module_color-fill-stroke-same-red-8__F8u4s",
  "color_fill_red_8": "index-module_color-fill-red-8__VqQTG",
  "color_red_7": "index-module_color-red-7__-t7GE",
  "color_fill_stroke_same_red_7": "index-module_color-fill-stroke-same-red-7__frtiO",
  "color_fill_red_7": "index-module_color-fill-red-7__6WkZl",
  "color_red_6": "index-module_color-red-6__4SLjb",
  "color_fill_stroke_same_red_6": "index-module_color-fill-stroke-same-red-6__fbSg7",
  "color_fill_red_6": "index-module_color-fill-red-6__yMBcV",
  "color_red_5": "index-module_color-red-5__9XQL6",
  "color_fill_stroke_same_red_5": "index-module_color-fill-stroke-same-red-5__sH5Ct",
  "color_fill_red_5": "index-module_color-fill-red-5__9RT4E",
  "color_red_4": "index-module_color-red-4__cMN84",
  "color_fill_stroke_same_red_4": "index-module_color-fill-stroke-same-red-4__3-Xxp",
  "color_fill_red_4": "index-module_color-fill-red-4__hZf-H",
  "color_red_3": "index-module_color-red-3__jsR3N",
  "color_fill_stroke_same_red_3": "index-module_color-fill-stroke-same-red-3__Dqi5-",
  "color_fill_red_3": "index-module_color-fill-red-3__5xBod",
  "color_red_2": "index-module_color-red-2__W1oAB",
  "color_fill_stroke_same_red_2": "index-module_color-fill-stroke-same-red-2__XjkVg",
  "color_fill_red_2": "index-module_color-fill-red-2__vIvLG",
  "color_red_1": "index-module_color-red-1__YCg72",
  "color_fill_stroke_same_red_1": "index-module_color-fill-stroke-same-red-1__wpF7N",
  "color_fill_red_1": "index-module_color-fill-red-1__TZCf1",
  "color_volcano_10": "index-module_color-volcano-10__C4T5-",
  "color_fill_stroke_same_volcano_10": "index-module_color-fill-stroke-same-volcano-10__38JHi",
  "color_fill_volcano_10": "index-module_color-fill-volcano-10__Ii-br",
  "color_volcano_9": "index-module_color-volcano-9__Xqzzk",
  "color_fill_stroke_same_volcano_9": "index-module_color-fill-stroke-same-volcano-9__HXap8",
  "color_fill_volcano_9": "index-module_color-fill-volcano-9__OVJ7Y",
  "color_volcano_8": "index-module_color-volcano-8__IlzPn",
  "color_fill_stroke_same_volcano_8": "index-module_color-fill-stroke-same-volcano-8__CWTzi",
  "color_fill_volcano_8": "index-module_color-fill-volcano-8__L1qRE",
  "color_volcano_7": "index-module_color-volcano-7__oyykY",
  "color_fill_stroke_same_volcano_7": "index-module_color-fill-stroke-same-volcano-7__6-uEA",
  "color_fill_volcano_7": "index-module_color-fill-volcano-7__s1lWe",
  "color_volcano_6": "index-module_color-volcano-6__dTxCE",
  "color_fill_stroke_same_volcano_6": "index-module_color-fill-stroke-same-volcano-6__-URbO",
  "color_fill_volcano_6": "index-module_color-fill-volcano-6__r5ZL6",
  "color_volcano_5": "index-module_color-volcano-5__-bJ3T",
  "color_fill_stroke_same_volcano_5": "index-module_color-fill-stroke-same-volcano-5__KOIhD",
  "color_fill_volcano_5": "index-module_color-fill-volcano-5__uub-6",
  "color_volcano_4": "index-module_color-volcano-4__vjm4A",
  "color_fill_stroke_same_volcano_4": "index-module_color-fill-stroke-same-volcano-4__zmmL-",
  "color_fill_volcano_4": "index-module_color-fill-volcano-4__M78j3",
  "color_volcano_3": "index-module_color-volcano-3__sGkBt",
  "color_fill_stroke_same_volcano_3": "index-module_color-fill-stroke-same-volcano-3__Byi52",
  "color_fill_volcano_3": "index-module_color-fill-volcano-3__6-k1v",
  "color_volcano_2": "index-module_color-volcano-2__W6xen",
  "color_fill_stroke_same_volcano_2": "index-module_color-fill-stroke-same-volcano-2__X5uN4",
  "color_fill_volcano_2": "index-module_color-fill-volcano-2__NkT7K",
  "color_volcano_1": "index-module_color-volcano-1__--4hp",
  "color_fill_stroke_same_volcano_1": "index-module_color-fill-stroke-same-volcano-1__YuWLW",
  "color_fill_volcano_1": "index-module_color-fill-volcano-1__jmh-O",
  "color_orange_10": "index-module_color-orange-10__vg1Lx",
  "color_fill_stroke_same_orange_10": "index-module_color-fill-stroke-same-orange-10__ZHHTy",
  "color_fill_orange_10": "index-module_color-fill-orange-10__DSCkn",
  "color_orange_9": "index-module_color-orange-9__nye-2",
  "color_fill_stroke_same_orange_9": "index-module_color-fill-stroke-same-orange-9__5teUI",
  "color_fill_orange_9": "index-module_color-fill-orange-9__iqQv5",
  "color_orange_8": "index-module_color-orange-8__x5wxt",
  "color_fill_stroke_same_orange_8": "index-module_color-fill-stroke-same-orange-8__A4HCM",
  "color_fill_orange_8": "index-module_color-fill-orange-8__8Y1C6",
  "color_orange_7": "index-module_color-orange-7__eDsJ0",
  "color_fill_stroke_same_orange_7": "index-module_color-fill-stroke-same-orange-7__--eOu",
  "color_fill_orange_7": "index-module_color-fill-orange-7__r-5Zt",
  "color_orange_6": "index-module_color-orange-6__dkC0U",
  "color_fill_stroke_same_orange_6": "index-module_color-fill-stroke-same-orange-6__U3zCj",
  "color_fill_orange_6": "index-module_color-fill-orange-6__7Cn6r",
  "color_orange_5": "index-module_color-orange-5__XLlbk",
  "color_fill_stroke_same_orange_5": "index-module_color-fill-stroke-same-orange-5__y3a-c",
  "color_fill_orange_5": "index-module_color-fill-orange-5__-SliD",
  "color_orange_4": "index-module_color-orange-4__YNisX",
  "color_fill_stroke_same_orange_4": "index-module_color-fill-stroke-same-orange-4__VUM7i",
  "color_fill_orange_4": "index-module_color-fill-orange-4__3-Tap",
  "color_orange_3": "index-module_color-orange-3__Wbulw",
  "color_fill_stroke_same_orange_3": "index-module_color-fill-stroke-same-orange-3__etwCQ",
  "color_fill_orange_3": "index-module_color-fill-orange-3__Y-DG9",
  "color_orange_2": "index-module_color-orange-2__sjxrh",
  "color_fill_stroke_same_orange_2": "index-module_color-fill-stroke-same-orange-2__tE0C8",
  "color_fill_orange_2": "index-module_color-fill-orange-2__YAgu3",
  "color_orange_1": "index-module_color-orange-1__DtnVO",
  "color_fill_stroke_same_orange_1": "index-module_color-fill-stroke-same-orange-1__dr4l8",
  "color_fill_orange_1": "index-module_color-fill-orange-1__GA8mE",
  "color_gold_10": "index-module_color-gold-10__kjext",
  "color_fill_stroke_same_gold_10": "index-module_color-fill-stroke-same-gold-10__4oe6H",
  "color_fill_gold_10": "index-module_color-fill-gold-10__BS3sQ",
  "color_gold_9": "index-module_color-gold-9__83j83",
  "color_fill_stroke_same_gold_9": "index-module_color-fill-stroke-same-gold-9__8tU-s",
  "color_fill_gold_9": "index-module_color-fill-gold-9__b-ibp",
  "color_gold_8": "index-module_color-gold-8__vNajR",
  "color_fill_stroke_same_gold_8": "index-module_color-fill-stroke-same-gold-8__EMDX-",
  "color_fill_gold_8": "index-module_color-fill-gold-8__z2OFc",
  "color_gold_7": "index-module_color-gold-7__HvJvq",
  "color_fill_stroke_same_gold_7": "index-module_color-fill-stroke-same-gold-7__jnBRk",
  "color_fill_gold_7": "index-module_color-fill-gold-7__fWV2C",
  "color_gold_6": "index-module_color-gold-6__ApW2c",
  "color_fill_stroke_same_gold_6": "index-module_color-fill-stroke-same-gold-6__1IP43",
  "color_fill_gold_6": "index-module_color-fill-gold-6__Fe2gx",
  "color_gold_5": "index-module_color-gold-5__6mG3K",
  "color_fill_stroke_same_gold_5": "index-module_color-fill-stroke-same-gold-5__ReePe",
  "color_fill_gold_5": "index-module_color-fill-gold-5__NHMcC",
  "color_gold_4": "index-module_color-gold-4__9UXq5",
  "color_fill_stroke_same_gold_4": "index-module_color-fill-stroke-same-gold-4__wXyti",
  "color_fill_gold_4": "index-module_color-fill-gold-4__hIjLE",
  "color_gold_3": "index-module_color-gold-3__TgFBg",
  "color_fill_stroke_same_gold_3": "index-module_color-fill-stroke-same-gold-3__Q-JTc",
  "color_fill_gold_3": "index-module_color-fill-gold-3__LdRzc",
  "color_gold_2": "index-module_color-gold-2__rJ5uv",
  "color_fill_stroke_same_gold_2": "index-module_color-fill-stroke-same-gold-2__zYgYD",
  "color_fill_gold_2": "index-module_color-fill-gold-2__HsLnd",
  "color_gold_1": "index-module_color-gold-1__tVKtO",
  "color_fill_stroke_same_gold_1": "index-module_color-fill-stroke-same-gold-1__uuht6",
  "color_fill_gold_1": "index-module_color-fill-gold-1__Oup2n",
  "color_yellow_10": "index-module_color-yellow-10__93p8G",
  "color_fill_stroke_same_yellow_10": "index-module_color-fill-stroke-same-yellow-10__tuqKD",
  "color_fill_yellow_10": "index-module_color-fill-yellow-10__jOvpJ",
  "color_yellow_9": "index-module_color-yellow-9__xVGwm",
  "color_fill_stroke_same_yellow_9": "index-module_color-fill-stroke-same-yellow-9__sMlOC",
  "color_fill_yellow_9": "index-module_color-fill-yellow-9__vWNIv",
  "color_yellow_8": "index-module_color-yellow-8__BIlzN",
  "color_fill_stroke_same_yellow_8": "index-module_color-fill-stroke-same-yellow-8__N5Bu3",
  "color_fill_yellow_8": "index-module_color-fill-yellow-8__YcWKm",
  "color_yellow_7": "index-module_color-yellow-7__n7aGB",
  "color_fill_stroke_same_yellow_7": "index-module_color-fill-stroke-same-yellow-7__W-17z",
  "color_fill_yellow_7": "index-module_color-fill-yellow-7__Ad3gp",
  "color_yellow_6": "index-module_color-yellow-6__--5bs",
  "color_fill_stroke_same_yellow_6": "index-module_color-fill-stroke-same-yellow-6__uxX-N",
  "color_fill_yellow_6": "index-module_color-fill-yellow-6__-d-vZ",
  "color_yellow_5": "index-module_color-yellow-5__blxBH",
  "color_fill_stroke_same_yellow_5": "index-module_color-fill-stroke-same-yellow-5__MUIf5",
  "color_fill_yellow_5": "index-module_color-fill-yellow-5__dzqbd",
  "color_yellow_4": "index-module_color-yellow-4__hfRpB",
  "color_fill_stroke_same_yellow_4": "index-module_color-fill-stroke-same-yellow-4__jyuGb",
  "color_fill_yellow_4": "index-module_color-fill-yellow-4__C-ZxG",
  "color_yellow_3": "index-module_color-yellow-3__rvNZs",
  "color_fill_stroke_same_yellow_3": "index-module_color-fill-stroke-same-yellow-3__fdi99",
  "color_fill_yellow_3": "index-module_color-fill-yellow-3__AVNPG",
  "color_yellow_2": "index-module_color-yellow-2__bLc07",
  "color_fill_stroke_same_yellow_2": "index-module_color-fill-stroke-same-yellow-2__vWLdH",
  "color_fill_yellow_2": "index-module_color-fill-yellow-2__-qrb5",
  "color_yellow_1": "index-module_color-yellow-1__MlHCj",
  "color_fill_stroke_same_yellow_1": "index-module_color-fill-stroke-same-yellow-1__Rckt2",
  "color_fill_yellow_1": "index-module_color-fill-yellow-1__WYLRA",
  "color_lime_10": "index-module_color-lime-10__w7rPI",
  "color_fill_stroke_same_lime_10": "index-module_color-fill-stroke-same-lime-10__diFed",
  "color_fill_lime_10": "index-module_color-fill-lime-10__NPE71",
  "color_lime_9": "index-module_color-lime-9__Ucyef",
  "color_fill_stroke_same_lime_9": "index-module_color-fill-stroke-same-lime-9__rc6T3",
  "color_fill_lime_9": "index-module_color-fill-lime-9__VNbi0",
  "color_lime_8": "index-module_color-lime-8__vcXbp",
  "color_fill_stroke_same_lime_8": "index-module_color-fill-stroke-same-lime-8__qb7OA",
  "color_fill_lime_8": "index-module_color-fill-lime-8__f-35c",
  "color_lime_7": "index-module_color-lime-7__UtBjk",
  "color_fill_stroke_same_lime_7": "index-module_color-fill-stroke-same-lime-7__7a-A-",
  "color_fill_lime_7": "index-module_color-fill-lime-7__7jZB9",
  "color_lime_6": "index-module_color-lime-6__j8P2b",
  "color_fill_stroke_same_lime_6": "index-module_color-fill-stroke-same-lime-6__GvpVr",
  "color_fill_lime_6": "index-module_color-fill-lime-6__FgwRY",
  "color_lime_5": "index-module_color-lime-5__xOV-M",
  "color_fill_stroke_same_lime_5": "index-module_color-fill-stroke-same-lime-5__-Lgku",
  "color_fill_lime_5": "index-module_color-fill-lime-5__hAN5D",
  "color_lime_4": "index-module_color-lime-4__aPS-O",
  "color_fill_stroke_same_lime_4": "index-module_color-fill-stroke-same-lime-4__U6OxC",
  "color_fill_lime_4": "index-module_color-fill-lime-4__Sk-Cy",
  "color_lime_3": "index-module_color-lime-3__FcWyD",
  "color_fill_stroke_same_lime_3": "index-module_color-fill-stroke-same-lime-3__E9YCx",
  "color_fill_lime_3": "index-module_color-fill-lime-3__NyrG8",
  "color_lime_2": "index-module_color-lime-2__e2rCu",
  "color_fill_stroke_same_lime_2": "index-module_color-fill-stroke-same-lime-2__WinGL",
  "color_fill_lime_2": "index-module_color-fill-lime-2__nLyP-",
  "color_lime_1": "index-module_color-lime-1__v9cGb",
  "color_fill_stroke_same_lime_1": "index-module_color-fill-stroke-same-lime-1__UNw0r",
  "color_fill_lime_1": "index-module_color-fill-lime-1__20xJi",
  "color_green_10": "index-module_color-green-10__M5-rx",
  "color_fill_stroke_same_green_10": "index-module_color-fill-stroke-same-green-10__R8gsO",
  "color_fill_green_10": "index-module_color-fill-green-10__kSttn",
  "color_green_9": "index-module_color-green-9__rEHxM",
  "color_fill_stroke_same_green_9": "index-module_color-fill-stroke-same-green-9__vYJjD",
  "color_fill_green_9": "index-module_color-fill-green-9__tNuxM",
  "color_green_8": "index-module_color-green-8__Tnp44",
  "color_fill_stroke_same_green_8": "index-module_color-fill-stroke-same-green-8__4JGbX",
  "color_fill_green_8": "index-module_color-fill-green-8__vdWZq",
  "color_green_7": "index-module_color-green-7__f-bVJ",
  "color_fill_stroke_same_green_7": "index-module_color-fill-stroke-same-green-7__Momho",
  "color_fill_green_7": "index-module_color-fill-green-7__1SXOC",
  "color_green_6": "index-module_color-green-6__V77sR",
  "color_fill_stroke_same_green_6": "index-module_color-fill-stroke-same-green-6__mZxAm",
  "color_fill_green_6": "index-module_color-fill-green-6__d-Ysm",
  "color_green_5": "index-module_color-green-5__zYMa0",
  "color_fill_stroke_same_green_5": "index-module_color-fill-stroke-same-green-5__B6Zcg",
  "color_fill_green_5": "index-module_color-fill-green-5__decI2",
  "color_green_4": "index-module_color-green-4__oZOTB",
  "color_fill_stroke_same_green_4": "index-module_color-fill-stroke-same-green-4__PuBlZ",
  "color_fill_green_4": "index-module_color-fill-green-4__mEjRV",
  "color_green_3": "index-module_color-green-3__20RUL",
  "color_fill_stroke_same_green_3": "index-module_color-fill-stroke-same-green-3__NgzcT",
  "color_fill_green_3": "index-module_color-fill-green-3__8uqRA",
  "color_green_2": "index-module_color-green-2__zuz5X",
  "color_fill_stroke_same_green_2": "index-module_color-fill-stroke-same-green-2__tN1g2",
  "color_fill_green_2": "index-module_color-fill-green-2__GFs1O",
  "color_green_1": "index-module_color-green-1__9P83d",
  "color_fill_stroke_same_green_1": "index-module_color-fill-stroke-same-green-1__mXpJW",
  "color_fill_green_1": "index-module_color-fill-green-1__OmbW1",
  "color_cyan_10": "index-module_color-cyan-10__HIaf3",
  "color_fill_stroke_same_cyan_10": "index-module_color-fill-stroke-same-cyan-10__Arpa9",
  "color_fill_cyan_10": "index-module_color-fill-cyan-10__IxxGt",
  "color_cyan_9": "index-module_color-cyan-9__GjdSU",
  "color_fill_stroke_same_cyan_9": "index-module_color-fill-stroke-same-cyan-9__D3Ec5",
  "color_fill_cyan_9": "index-module_color-fill-cyan-9__ygxYh",
  "color_cyan_8": "index-module_color-cyan-8__Vr4x4",
  "color_fill_stroke_same_cyan_8": "index-module_color-fill-stroke-same-cyan-8__bm4pS",
  "color_fill_cyan_8": "index-module_color-fill-cyan-8__Q-m9k",
  "color_cyan_7": "index-module_color-cyan-7__MaViW",
  "color_fill_stroke_same_cyan_7": "index-module_color-fill-stroke-same-cyan-7__WuAu4",
  "color_fill_cyan_7": "index-module_color-fill-cyan-7__2-Ckv",
  "color_cyan_6": "index-module_color-cyan-6__Fnh-e",
  "color_fill_stroke_same_cyan_6": "index-module_color-fill-stroke-same-cyan-6__0pD6i",
  "color_fill_cyan_6": "index-module_color-fill-cyan-6__p4CzR",
  "color_cyan_5": "index-module_color-cyan-5__8aaxd",
  "color_fill_stroke_same_cyan_5": "index-module_color-fill-stroke-same-cyan-5__n3K83",
  "color_fill_cyan_5": "index-module_color-fill-cyan-5__QaSJq",
  "color_cyan_4": "index-module_color-cyan-4__O-w1D",
  "color_fill_stroke_same_cyan_4": "index-module_color-fill-stroke-same-cyan-4__7DGik",
  "color_fill_cyan_4": "index-module_color-fill-cyan-4__uttP1",
  "color_cyan_3": "index-module_color-cyan-3__MDhDd",
  "color_fill_stroke_same_cyan_3": "index-module_color-fill-stroke-same-cyan-3__jXP5D",
  "color_fill_cyan_3": "index-module_color-fill-cyan-3__JZg3N",
  "color_cyan_2": "index-module_color-cyan-2__4Ikux",
  "color_fill_stroke_same_cyan_2": "index-module_color-fill-stroke-same-cyan-2__YznoB",
  "color_fill_cyan_2": "index-module_color-fill-cyan-2__WGu-p",
  "color_cyan_1": "index-module_color-cyan-1__ttM5P",
  "color_fill_stroke_same_cyan_1": "index-module_color-fill-stroke-same-cyan-1__5RVqI",
  "color_fill_cyan_1": "index-module_color-fill-cyan-1__pYopp",
  "color_blue_10": "index-module_color-blue-10__fyO6-",
  "color_fill_stroke_same_blue_10": "index-module_color-fill-stroke-same-blue-10__ivbCK",
  "color_fill_blue_10": "index-module_color-fill-blue-10__Q6u3q",
  "color_blue_9": "index-module_color-blue-9__aEhEl",
  "color_fill_stroke_same_blue_9": "index-module_color-fill-stroke-same-blue-9__v352K",
  "color_fill_blue_9": "index-module_color-fill-blue-9__E8Jnj",
  "color_blue_8": "index-module_color-blue-8__6zhZB",
  "color_fill_stroke_same_blue_8": "index-module_color-fill-stroke-same-blue-8__to8Z0",
  "color_fill_blue_8": "index-module_color-fill-blue-8__LOy6D",
  "color_blue_7": "index-module_color-blue-7__6Vkdl",
  "color_fill_stroke_same_blue_7": "index-module_color-fill-stroke-same-blue-7__Eokfs",
  "color_fill_blue_7": "index-module_color-fill-blue-7__cGcJx",
  "color_blue_6": "index-module_color-blue-6__1XiJg",
  "color_fill_stroke_same_blue_6": "index-module_color-fill-stroke-same-blue-6__qHHG4",
  "color_fill_blue_6": "index-module_color-fill-blue-6__GESJO",
  "color_blue_5": "index-module_color-blue-5__OffWU",
  "color_fill_stroke_same_blue_5": "index-module_color-fill-stroke-same-blue-5__0DoHU",
  "color_fill_blue_5": "index-module_color-fill-blue-5__gD-Rq",
  "color_blue_4": "index-module_color-blue-4__3u01N",
  "color_fill_stroke_same_blue_4": "index-module_color-fill-stroke-same-blue-4__W4fag",
  "color_fill_blue_4": "index-module_color-fill-blue-4__DMXPO",
  "color_blue_3": "index-module_color-blue-3__m6UtY",
  "color_fill_stroke_same_blue_3": "index-module_color-fill-stroke-same-blue-3__HgKtA",
  "color_fill_blue_3": "index-module_color-fill-blue-3__uvHee",
  "color_blue_2": "index-module_color-blue-2__mk7Al",
  "color_fill_stroke_same_blue_2": "index-module_color-fill-stroke-same-blue-2__DWY-w",
  "color_fill_blue_2": "index-module_color-fill-blue-2__96Wbj",
  "color_blue_1": "index-module_color-blue-1__NAMAy",
  "color_fill_stroke_same_blue_1": "index-module_color-fill-stroke-same-blue-1__yceSK",
  "color_fill_blue_1": "index-module_color-fill-blue-1__9lfEJ",
  "color_geekBlue_10": "index-module_color-geekBlue-10__I9bkR",
  "color_fill_stroke_same_geekBlue_10": "index-module_color-fill-stroke-same-geekBlue-10__dS8YA",
  "color_fill_geekBlue_10": "index-module_color-fill-geekBlue-10__CYL9q",
  "color_geekBlue_9": "index-module_color-geekBlue-9__GyS-b",
  "color_fill_stroke_same_geekBlue_9": "index-module_color-fill-stroke-same-geekBlue-9__fuP6x",
  "color_fill_geekBlue_9": "index-module_color-fill-geekBlue-9__XamS2",
  "color_geekBlue_8": "index-module_color-geekBlue-8__bI6kQ",
  "color_fill_stroke_same_geekBlue_8": "index-module_color-fill-stroke-same-geekBlue-8__NMP57",
  "color_fill_geekBlue_8": "index-module_color-fill-geekBlue-8__gpzzg",
  "color_geekBlue_7": "index-module_color-geekBlue-7__kOYvP",
  "color_fill_stroke_same_geekBlue_7": "index-module_color-fill-stroke-same-geekBlue-7__VQjS4",
  "color_fill_geekBlue_7": "index-module_color-fill-geekBlue-7__oylIC",
  "color_geekBlue_6": "index-module_color-geekBlue-6__0xox2",
  "color_fill_stroke_same_geekBlue_6": "index-module_color-fill-stroke-same-geekBlue-6__h06YW",
  "color_fill_geekBlue_6": "index-module_color-fill-geekBlue-6__FOsdx",
  "color_geekBlue_5": "index-module_color-geekBlue-5__lo-cR",
  "color_fill_stroke_same_geekBlue_5": "index-module_color-fill-stroke-same-geekBlue-5__Oa9pH",
  "color_fill_geekBlue_5": "index-module_color-fill-geekBlue-5__IG3y2",
  "color_geekBlue_4": "index-module_color-geekBlue-4__2TTSG",
  "color_fill_stroke_same_geekBlue_4": "index-module_color-fill-stroke-same-geekBlue-4__fgiXU",
  "color_fill_geekBlue_4": "index-module_color-fill-geekBlue-4__fI4oE",
  "color_geekBlue_3": "index-module_color-geekBlue-3__sqU-j",
  "color_fill_stroke_same_geekBlue_3": "index-module_color-fill-stroke-same-geekBlue-3__UqUmY",
  "color_fill_geekBlue_3": "index-module_color-fill-geekBlue-3__vP81y",
  "color_geekBlue_2": "index-module_color-geekBlue-2__W-8Z1",
  "color_fill_stroke_same_geekBlue_2": "index-module_color-fill-stroke-same-geekBlue-2__o3OYi",
  "color_fill_geekBlue_2": "index-module_color-fill-geekBlue-2__geEPJ",
  "color_geekBlue_1": "index-module_color-geekBlue-1__A9bgk",
  "color_fill_stroke_same_geekBlue_1": "index-module_color-fill-stroke-same-geekBlue-1__I2ClB",
  "color_fill_geekBlue_1": "index-module_color-fill-geekBlue-1__TzLcW",
  "color_purple_10": "index-module_color-purple-10__xdEXX",
  "color_fill_stroke_same_purple_10": "index-module_color-fill-stroke-same-purple-10__UamvW",
  "color_fill_purple_10": "index-module_color-fill-purple-10__4dh-L",
  "color_purple_9": "index-module_color-purple-9__J8HXl",
  "color_fill_stroke_same_purple_9": "index-module_color-fill-stroke-same-purple-9__JtlmP",
  "color_fill_purple_9": "index-module_color-fill-purple-9__KVxPm",
  "color_purple_8": "index-module_color-purple-8__YfmQd",
  "color_fill_stroke_same_purple_8": "index-module_color-fill-stroke-same-purple-8__RT-Kq",
  "color_fill_purple_8": "index-module_color-fill-purple-8__fdVV1",
  "color_purple_7": "index-module_color-purple-7__1PTB7",
  "color_fill_stroke_same_purple_7": "index-module_color-fill-stroke-same-purple-7__D76zR",
  "color_fill_purple_7": "index-module_color-fill-purple-7__wUePu",
  "color_purple_6": "index-module_color-purple-6__otvN5",
  "color_fill_stroke_same_purple_6": "index-module_color-fill-stroke-same-purple-6__QCr-Q",
  "color_fill_purple_6": "index-module_color-fill-purple-6__QA7hh",
  "color_purple_5": "index-module_color-purple-5__DCq6u",
  "color_fill_stroke_same_purple_5": "index-module_color-fill-stroke-same-purple-5__GvPQV",
  "color_fill_purple_5": "index-module_color-fill-purple-5__X1uAj",
  "color_purple_4": "index-module_color-purple-4__gK5NP",
  "color_fill_stroke_same_purple_4": "index-module_color-fill-stroke-same-purple-4__cIif0",
  "color_fill_purple_4": "index-module_color-fill-purple-4__LnshN",
  "color_purple_3": "index-module_color-purple-3__Ygd3O",
  "color_fill_stroke_same_purple_3": "index-module_color-fill-stroke-same-purple-3__t4o3P",
  "color_fill_purple_3": "index-module_color-fill-purple-3__tDWYK",
  "color_purple_2": "index-module_color-purple-2__6rbSW",
  "color_fill_stroke_same_purple_2": "index-module_color-fill-stroke-same-purple-2__nzRZv",
  "color_fill_purple_2": "index-module_color-fill-purple-2__7l9bx",
  "color_purple_1": "index-module_color-purple-1__YdT8N",
  "color_fill_stroke_same_purple_1": "index-module_color-fill-stroke-same-purple-1__qKp6j",
  "color_fill_purple_1": "index-module_color-fill-purple-1__WSSlf",
  "color_magenta_10": "index-module_color-magenta-10__xtAJO",
  "color_fill_stroke_same_magenta_10": "index-module_color-fill-stroke-same-magenta-10__ghsOi",
  "color_fill_magenta_10": "index-module_color-fill-magenta-10__PEgoH",
  "color_magenta_9": "index-module_color-magenta-9__j8uD4",
  "color_fill_stroke_same_magenta_9": "index-module_color-fill-stroke-same-magenta-9__1pVQE",
  "color_fill_magenta_9": "index-module_color-fill-magenta-9__P-CA-",
  "color_magenta_8": "index-module_color-magenta-8__EzXlR",
  "color_fill_stroke_same_magenta_8": "index-module_color-fill-stroke-same-magenta-8__n6jaX",
  "color_fill_magenta_8": "index-module_color-fill-magenta-8__xqwim",
  "color_magenta_7": "index-module_color-magenta-7__2tznh",
  "color_fill_stroke_same_magenta_7": "index-module_color-fill-stroke-same-magenta-7__dYyCk",
  "color_fill_magenta_7": "index-module_color-fill-magenta-7__quN3J",
  "color_magenta_6": "index-module_color-magenta-6__gDzob",
  "color_fill_stroke_same_magenta_6": "index-module_color-fill-stroke-same-magenta-6__Mpvli",
  "color_fill_magenta_6": "index-module_color-fill-magenta-6__zGI9u",
  "color_magenta_5": "index-module_color-magenta-5__zWmQm",
  "color_fill_stroke_same_magenta_5": "index-module_color-fill-stroke-same-magenta-5__QUNgN",
  "color_fill_magenta_5": "index-module_color-fill-magenta-5__5Z4n8",
  "color_magenta_4": "index-module_color-magenta-4__mhvsT",
  "color_fill_stroke_same_magenta_4": "index-module_color-fill-stroke-same-magenta-4__lN0sY",
  "color_fill_magenta_4": "index-module_color-fill-magenta-4__TdHJi",
  "color_magenta_3": "index-module_color-magenta-3__DqezG",
  "color_fill_stroke_same_magenta_3": "index-module_color-fill-stroke-same-magenta-3__RLnKF",
  "color_fill_magenta_3": "index-module_color-fill-magenta-3__KH7CR",
  "color_magenta_2": "index-module_color-magenta-2__nFy2V",
  "color_fill_stroke_same_magenta_2": "index-module_color-fill-stroke-same-magenta-2__a04je",
  "color_fill_magenta_2": "index-module_color-fill-magenta-2__YpQNz",
  "color_magenta_1": "index-module_color-magenta-1__fwLBl",
  "color_fill_stroke_same_magenta_1": "index-module_color-fill-stroke-same-magenta-1__ByY9h",
  "color_fill_magenta_1": "index-module_color-fill-magenta-1__e52DT",
  "color_gray_10": "index-module_color-gray-10__vgqZu",
  "color_fill_stroke_same_gray_10": "index-module_color-fill-stroke-same-gray-10__Vnqo1",
  "color_fill_gray_10": "index-module_color-fill-gray-10__zYoO7",
  "color_gray_9": "index-module_color-gray-9__4SY0z",
  "color_fill_stroke_same_gray_9": "index-module_color-fill-stroke-same-gray-9__SBLq5",
  "color_fill_gray_9": "index-module_color-fill-gray-9__90gtI",
  "color_gray_8": "index-module_color-gray-8__CEUrV",
  "color_fill_stroke_same_gray_8": "index-module_color-fill-stroke-same-gray-8__WnzKa",
  "color_fill_gray_8": "index-module_color-fill-gray-8__ALpwZ",
  "color_gray_7": "index-module_color-gray-7__bTZ7c",
  "color_fill_stroke_same_gray_7": "index-module_color-fill-stroke-same-gray-7__jOBAv",
  "color_fill_gray_7": "index-module_color-fill-gray-7__nJggi",
  "color_gray_6": "index-module_color-gray-6__D9vPh",
  "color_fill_stroke_same_gray_6": "index-module_color-fill-stroke-same-gray-6__on5EX",
  "color_fill_gray_6": "index-module_color-fill-gray-6__nK9f9",
  "color_gray_5": "index-module_color-gray-5__pYRBR",
  "color_fill_stroke_same_gray_5": "index-module_color-fill-stroke-same-gray-5__eTL49",
  "color_fill_gray_5": "index-module_color-fill-gray-5__bVhUt",
  "color_gray_4": "index-module_color-gray-4__mt7iT",
  "color_fill_stroke_same_gray_4": "index-module_color-fill-stroke-same-gray-4__xFeFb",
  "color_fill_gray_4": "index-module_color-fill-gray-4__DQwCu",
  "color_gray_3": "index-module_color-gray-3__s43DA",
  "color_fill_stroke_same_gray_3": "index-module_color-fill-stroke-same-gray-3__pGxIM",
  "color_fill_gray_3": "index-module_color-fill-gray-3__cxcy7",
  "color_gray_2": "index-module_color-gray-2__DD3JU",
  "color_fill_stroke_same_gray_2": "index-module_color-fill-stroke-same-gray-2__YFI6f",
  "color_fill_gray_2": "index-module_color-fill-gray-2__HCqXx",
  "color_gray_1": "index-module_color-gray-1__IsK2h",
  "color_fill_stroke_same_gray_1": "index-module_color-fill-stroke-same-gray-1__SFvE-",
  "color_fill_gray_1": "index-module_color-fill-gray-1__B8DDA"
};
exports["default"] = styles;
});

_commonjsHelpers.unwrapExports(index_module_less);

exports.__moduleExports = index_module_less;
