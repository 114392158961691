import {
	ProposalBuilderState,
	useProposalBuilderDispatch,
	useProposalBuilderSelector
} from "@modules/proposal-builder/dist/store";
import BlocksActionCreators from "@modules/proposal-builder/dist/store/actions/Blocks";
import { QuoteProduct } from "@zomentum/contracts";
import React, { FC } from "react";
import ConfigureProductSKUInQuoteBlockDrawer from "@pages/Vendor/Templates/TemplateDetailPage/Drawers/ConfigureProductSKUinQuoteBlockDrawer";

const EditProductInQuoteBlockDrawer: FC = () => {
	const proposalBuilderDispatch = useProposalBuilderDispatch();

	const { isDrawerVisible, productData, blockId, sectionIndex } =
		useProposalBuilderSelector((state: ProposalBuilderState) => {
			return {
				isDrawerVisible: state.blocks.isProductConfigureDrawerVisible,
				productData: state.blocks.productData,
				blockId: state.blocks.blockId,
				sectionIndex: state.blocks.sectionIndex
			};
		});

	const handleProductClose = () => {
		proposalBuilderDispatch(
			BlocksActionCreators.hideProductConfigureDrawer()
		);
	};

	const handleEditQuoteProduct = async (quoteProduct: QuoteProduct) => {
		await proposalBuilderDispatch(
			BlocksActionCreators.editProductInQuoteBlockSectionFromDrawer({
				blockId,
				sectionIndex,
				quoteProductId: quoteProduct.id,
				data: quoteProduct
			})
		);
	};

	const handleSubmit = async (data: QuoteProduct) => {
		try {
			await handleEditQuoteProduct({
				...productData,
				...data
			});
			handleProductClose();
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<ConfigureProductSKUInQuoteBlockDrawer
			visible={isDrawerVisible}
			onSubmit={handleSubmit}
			onClose={handleProductClose}
			productData={productData}
		/>
	);
};

export default EditProductInQuoteBlockDrawer;
