'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../../../node_modules/tslib/tslib.es6.mjs.js');
var index = require('../../../services/Block/index.js');

exports.ParentStateActionTypes = void 0;
(function (ParentStateActionTypes) {
  ParentStateActionTypes["UPDATE_PARENT_STATE"] = "UPDATE_PARENT_STATE";
  ParentStateActionTypes["UPDATE_CURRENT_ACTIVE_DOCUMENT"] = "UPDATE_CURRENT_ACTIVE_DOCUMENT";
  ParentStateActionTypes["UPDATE_CURRENT_ACTIVE_TEMPLATE"] = "UPDATE_CURRENT_ACTIVE_TEMPLATE";
  ParentStateActionTypes["UPDATE_CURRENT_DOC_PAYMENT_SUMMARY_SUCCESSFUL"] = "UPDATE_CURRENT_DOC_PAYMENT_SUMMARY_SUCCESSFUL";
})(exports.ParentStateActionTypes || (exports.ParentStateActionTypes = {}));
var ParentActionCreators = {
  updateParentState: function updateParentState(parentState) {
    return function (dispatch) {
      var updateParentStateAction = {
        type: exports.ParentStateActionTypes.UPDATE_PARENT_STATE,
        parentState: parentState
      };
      return dispatch(updateParentStateAction);
    };
  },
  updateCurrentActiveDocument: function updateCurrentActiveDocument(currentActiveDocument) {
    return function (dispatch) {
      var updateCurrentActiveDocumentAction = {
        type: exports.ParentStateActionTypes.UPDATE_CURRENT_ACTIVE_DOCUMENT,
        currentActiveDocument: currentActiveDocument
      };
      return dispatch(updateCurrentActiveDocumentAction);
    };
  },
  updateDocumentPaymentSummary: function updateDocumentPaymentSummary(paymentSummary) {
    return function (dispatch) {
      var updateCurrentDocPaymentSummarySuccessfulAction = {
        type: exports.ParentStateActionTypes.UPDATE_CURRENT_DOC_PAYMENT_SUMMARY_SUCCESSFUL,
        paymentSummary: paymentSummary
      };
      return dispatch(updateCurrentDocPaymentSummarySuccessfulAction);
    };
  },
  updateDocumentPageDetails: function updateDocumentPageDetails(pageDetails, documentId) {
    return function (dispatch, getState) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var currentActiveDocument, updateCurrentActiveDocumentAction_1, document, updateCurrentActiveDocumentAction;
        var _a;
        return tslib_es6.__generator(this, function (_b) {
          switch (_b.label) {
            case 0:
              currentActiveDocument = getState().parentState.currentActiveDocument;
              if ((_a = currentActiveDocument === null || currentActiveDocument === void 0 ? void 0 : currentActiveDocument.revisions) === null || _a === void 0 ? void 0 : _a[0]) {
                currentActiveDocument.revisions[0].page_details = pageDetails;
                updateCurrentActiveDocumentAction_1 = {
                  type: exports.ParentStateActionTypes.UPDATE_CURRENT_ACTIVE_DOCUMENT,
                  currentActiveDocument: currentActiveDocument
                };
                dispatch(updateCurrentActiveDocumentAction_1);
              }
              return [4 /*yield*/, index.updateDocumentPageDetails({
                page_detail: pageDetails
              }, documentId)];
            case 1:
              document = _b.sent();
              updateCurrentActiveDocumentAction = {
                type: exports.ParentStateActionTypes.UPDATE_CURRENT_ACTIVE_DOCUMENT,
                currentActiveDocument: document
              };
              return [2 /*return*/, dispatch(updateCurrentActiveDocumentAction)];
          }
        });
      });
    };
  },
  updateTemplatePageDetails: function updateTemplatePageDetails(pageDetails, templateId) {
    return function (dispatch, getState) {
      return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
        var currentActiveTemplate, updateCurrentActiveDocumentAction_2, template, updateCurrentActiveDocumentAction;
        return tslib_es6.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              currentActiveTemplate = getState().parentState.currentActiveTemplate;
              if (currentActiveTemplate) {
                currentActiveTemplate.page_details = pageDetails;
                updateCurrentActiveDocumentAction_2 = {
                  type: exports.ParentStateActionTypes.UPDATE_CURRENT_ACTIVE_TEMPLATE,
                  currentActiveTemplate: currentActiveTemplate
                };
                dispatch(updateCurrentActiveDocumentAction_2);
              }
              return [4 /*yield*/, index.updateTemplatePageDetails({
                page_detail: pageDetails
              }, templateId)];
            case 1:
              template = _a.sent();
              updateCurrentActiveDocumentAction = {
                type: exports.ParentStateActionTypes.UPDATE_CURRENT_ACTIVE_TEMPLATE,
                currentActiveTemplate: template
              };
              return [2 /*return*/, dispatch(updateCurrentActiveDocumentAction)];
          }
        });
      });
    };
  }
};

exports.ParentActionCreators = ParentActionCreators;
exports["default"] = ParentActionCreators;
