import React from "react";

interface MyProps {
	children?: string;
	content?: string;
}

const IFRAME: React.FunctionComponent<MyProps> = props => {
	const writeHTML = (frame: any) => {
		if (!frame) {
			return;
		}
		const document = frame.contentDocument;
		document.open();
		document.write(props.content || "");
		document.close();
		frame.style.width = "100%";
		frame.style.height = "0px";
		frame.contentDocument.body.style =
			'font-family: "Source Sans Pro", sans-serif, Helvetica, Arial !important;font-size: 14px;color: rgb(0,0,0);';
		const frameHeight = frame.contentDocument.body.scrollHeight + 20;
		frame.style.height = `${Math.min(frameHeight, 500)}px`;
		frame.style.overflowY = "auto";
	};
	return <iframe src="about:blank" frameBorder="0" ref={writeHTML} />;
};

export default IFRAME;
