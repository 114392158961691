import { MarketplaceProductRequest } from "@zomentum/contracts/dist/Vendor/MarketplaceListings";
import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest
} from "@zomentum/utils/dist/Service/BackendService";
import {
	MarketplaceProductsResponse,
	MarketplaceProduct
} from "@zomentum/contracts/dist/Marketplace/Product";
import axios, { AxiosResponse } from "axios";

const getMarketplaceProductsCollectionsURL = "/v1/marketplace/listings";
const getMarketplaceProductDetailsURL = "/v1/marketplace/listings/:id";
const disableMarketplaceProductUrl = "/v1/marketplace/listings/:id/disable";
const enableMarketplaceProductUrl = "/v1/marketplace/listings/:id/enable";

export const getMarketplaceProductsCollections =
	async (): Promise<MarketplaceProductsResponse> => {
		try {
			const response: AxiosResponse<MarketplaceProductsResponse> =
				await axios(
					getRequest(
						getMarketplaceProductsCollectionsURL,
						null,
						true,
						false,
						false
					)
				);
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const addMarketplaceProduct = async (
	addMarketProductRequest: MarketplaceProductRequest
): Promise<MarketplaceProduct> => {
	try {
		const response: AxiosResponse<MarketplaceProduct> = await axios(
			postRequest(
				getMarketplaceProductsCollectionsURL,
				addMarketProductRequest,
				true
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getMarketplaceProductDetails = async (
	id: string
): Promise<MarketplaceProduct> => {
	try {
		const response: AxiosResponse<MarketplaceProduct> = await axios(
			getRequest(
				getMarketplaceProductDetailsURL.replace(":id", id),
				null,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const editMarketplaceProduct = async (
	id: string,
	editMarketProductRequest: MarketplaceProductRequest
): Promise<MarketplaceProduct> => {
	try {
		const response: AxiosResponse<MarketplaceProduct> = await axios(
			putRequest(
				getMarketplaceProductDetailsURL.replace(":id", id),
				editMarketProductRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteMarketplaceProduct = async (id: string): Promise<void> => {
	try {
		await axios(
			deleteRequest(
				getMarketplaceProductDetailsURL.replace(":id", id),
				null,
				true,
				false
			)
		);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const changeMarketplaceProductStatus = async (
	id: string,
	newStatus: boolean
): Promise<void> => {
	try {
		const url = (
			newStatus
				? enableMarketplaceProductUrl
				: disableMarketplaceProductUrl
		).replace(":id", id);
		await axios(putRequest(url, {}, true, false, false));
	} catch (error) {
		return Promise.reject(error);
	}
};
