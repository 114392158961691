import { GlobalState } from "@/Reducers/interface";
import { VendorPartner } from "@zomentum/contracts/dist/Vendor/Partner";
import {
	getVendorPartnerDetails,
	createPartnerView,
	updatePartnerView,
	getAllPartnerViews,
	deletePartnerView
} from "@/Services/Vendor/Partner";
import { Action, ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { CustomViewResponse, CustomView } from "@zomentum/contracts/dist/UI";
import { areFiltersChanged } from "@zomentum/utils/dist/common";

export enum VendorPartnerActionTypes {
	SHOW_VENDOR_PARTNER_LOADER = "SHOW_VENDOR_PARTNER_LOADER",
	HIDE_VENDOR_PARTNER_LOADER = "HIDE_VENDOR_PARTNER_LOADER",
	VENDOR_PARTNER_DETAILS_REQUESTED = "VENDOR_PARTNER_DETAILS_REQUESTED",
	VENDOR_PARTNER_DETAILS_SUCCESSFUL = "VENDOR_PARTNER_DETAILS_SUCCESSFUL",
	VENDOR_PARTNER_DETAILS_FAILED = "VENDOR_PARTNER_DETAILS_FAILED",
	EDIT_VENDOR_PARTNER_DRAWER_OPENED = "EDIT_VENDOR_PARTNER_DRAWER_OPENED",
	EDIT_VENDOR_PARTNER_DRAWER_CLOSED = "EDIT_VENDOR_PARTNER_DRAWER_CLOSED",

	// VIEWS
	CREATE_PARTNER_VIEW_REQUESTED = "CREATE_PARTNER_VIEW_REQUESTED",
	CREATE_PARTNER_VIEW_SUCCESSFUL = "CREATE_PARTNER_VIEW_SUCCESSFUL",
	CREATE_PARTNER_VIEW_FAILED = "CREATE_PARTNER_VIEW_FAILED",

	UPDATE_PARTNER_VIEW_REQUESTED = "UPDATE_PARTNER_VIEW_REQUESTED",
	UPDATE_PARTNER_VIEW_SUCCESSFUL = "UPDATE_PARTNER_VIEW_SUCCESSFUL",
	UPDATE_PARTNER_VIEW_FAILED = "UPDATE_PARTNER_VIEW_FAILED",

	DELETE_PARTNER_VIEW_REQUESTED = "DELETE_PARTNER_VIEW_REQUESTED",
	DELETE_PARTNER_VIEW_SUCCESSFUL = "DELETE_PARTNER_VIEW_SUCCESSFUL",
	DELETE_PARTNER_VIEW_FAILED = "DELETE_PARTNER_VIEW_FAILED",

	UPDATE_PARTNER_CURRENT_ACTIVE_VIEW = "UPDATE_PARTNER_CURRENT_ACTIVE_VIEW",
	RESET_PARTNER_CURRENT_ACTIVE_VIEW = "RESET_PARTNER_CURRENT_ACTIVE_VIEW",

	FETCH_ALL_PARTNER_VIEWS_REQUESTED = "FETCH_ALL_PARTNER_VIEWS_REQUESTED",
	FETCH_ALL_PARTNER_VIEWS_SUCCESSFUL = "FETCH_ALL_PARTNER_VIEWS_SUCCESSFUL",
	FETCH_ALL_PARTNER_VIEWS_FAILED = "FETCH_ALL_PARTNER_VIEWS_FAILED",
	PARTNER_RESET = "PARTNER_RESET"
}

export type ShowVendorPartnerLoaderAction =
	Action<VendorPartnerActionTypes.SHOW_VENDOR_PARTNER_LOADER>;
export type HideVendorPartnerLoaderAction =
	Action<VendorPartnerActionTypes.HIDE_VENDOR_PARTNER_LOADER>;
export type VendorPartnerDetailsRequestedAction =
	Action<VendorPartnerActionTypes.VENDOR_PARTNER_DETAILS_REQUESTED>;
export interface VendorPartnerDetailsSuccessfulAction
	extends Action<VendorPartnerActionTypes.VENDOR_PARTNER_DETAILS_SUCCESSFUL> {
	readonly currentActivePartner: VendorPartner;
}
export type VendorPartnerDetailsFailedAction =
	Action<VendorPartnerActionTypes.VENDOR_PARTNER_DETAILS_FAILED>;

export type EditVendorPartnerDrawerOpenedAction =
	Action<VendorPartnerActionTypes.EDIT_VENDOR_PARTNER_DRAWER_OPENED>;
export type EditVendorPartnerDrawerClosedAction =
	Action<VendorPartnerActionTypes.EDIT_VENDOR_PARTNER_DRAWER_CLOSED>;

export interface UpdateCurrentActiveViewAction
	extends Action<VendorPartnerActionTypes.UPDATE_PARTNER_CURRENT_ACTIVE_VIEW> {
	readonly currentActiveView: CustomView;
	readonly isViewChanged: boolean;
}

export type CreatePartnerViewRequestedAction =
	Action<VendorPartnerActionTypes.CREATE_PARTNER_VIEW_REQUESTED>;
export interface CreatePartnerViewSuccessfulAction
	extends Action<VendorPartnerActionTypes.CREATE_PARTNER_VIEW_SUCCESSFUL> {
	readonly view: CustomView;
}
export type CreatePartnerViewFailedAction =
	Action<VendorPartnerActionTypes.CREATE_PARTNER_VIEW_FAILED>;

export type UpdatePartnerViewRequestedAction =
	Action<VendorPartnerActionTypes.UPDATE_PARTNER_VIEW_REQUESTED>;
export interface UpdatePartnerViewSuccessfulAction
	extends Action<VendorPartnerActionTypes.UPDATE_PARTNER_VIEW_SUCCESSFUL> {
	readonly view: CustomView;
}
export type UpdatePartnerViewFailedAction =
	Action<VendorPartnerActionTypes.UPDATE_PARTNER_VIEW_FAILED>;

export type DeletePartnerViewRequestedAction =
	Action<VendorPartnerActionTypes.DELETE_PARTNER_VIEW_REQUESTED>;
export interface DeletePartnerViewSuccessfulAction
	extends Action<VendorPartnerActionTypes.DELETE_PARTNER_VIEW_SUCCESSFUL> {
	readonly deletedView: CustomView;
	readonly initialView: CustomView;
}
export type PartnerResetAction = Action<VendorPartnerActionTypes.PARTNER_RESET>;
export type DeletePartnerViewFailedAction =
	Action<VendorPartnerActionTypes.DELETE_PARTNER_VIEW_FAILED>;

export type ResetCurrentActiveViewAction =
	Action<VendorPartnerActionTypes.RESET_PARTNER_CURRENT_ACTIVE_VIEW>;

export type FetchAllPartnerViewRequested =
	Action<VendorPartnerActionTypes.FETCH_ALL_PARTNER_VIEWS_REQUESTED>;
export interface FetchAllPartnerViewSuccessful
	extends Action<VendorPartnerActionTypes.FETCH_ALL_PARTNER_VIEWS_SUCCESSFUL> {
	readonly viewList: CustomViewResponse;
}
export type FetchAllPartnerViewFailed =
	Action<VendorPartnerActionTypes.FETCH_ALL_PARTNER_VIEWS_FAILED>;

export type VendorPartnerActions =
	| ShowVendorPartnerLoaderAction
	| HideVendorPartnerLoaderAction
	| VendorPartnerDetailsRequestedAction
	| VendorPartnerDetailsSuccessfulAction
	| VendorPartnerDetailsFailedAction
	| UpdateCurrentActiveViewAction
	| CreatePartnerViewRequestedAction
	| CreatePartnerViewSuccessfulAction
	| CreatePartnerViewFailedAction
	| UpdatePartnerViewRequestedAction
	| UpdatePartnerViewSuccessfulAction
	| UpdatePartnerViewFailedAction
	| DeletePartnerViewRequestedAction
	| DeletePartnerViewSuccessfulAction
	| DeletePartnerViewFailedAction
	| PartnerResetAction
	| ResetCurrentActiveViewAction
	| FetchAllPartnerViewRequested
	| FetchAllPartnerViewSuccessful
	| FetchAllPartnerViewFailed
	| EditVendorPartnerDrawerOpenedAction
	| EditVendorPartnerDrawerClosedAction;

export interface VendorPartnerDispatch {
	showVendorPartnerLoader: ActionCreator<
		ThunkAction<
			ShowVendorPartnerLoaderAction,
			GlobalState,
			null,
			ShowVendorPartnerLoaderAction
		>
	>;
	hideVendorPartnerLoader: ActionCreator<
		ThunkAction<
			HideVendorPartnerLoaderAction,
			GlobalState,
			null,
			HideVendorPartnerLoaderAction
		>
	>;
	getVendorPartnerDetails: ActionCreator<
		ThunkAction<
			Promise<VendorPartnerDetailsSuccessfulAction>,
			GlobalState,
			string,
			VendorPartnerDetailsSuccessfulAction
		>
	>;
	showEditVendorPartnerDrawer: ActionCreator<
		ThunkAction<
			EditVendorPartnerDrawerOpenedAction,
			GlobalState,
			null,
			EditVendorPartnerDrawerOpenedAction
		>
	>;
	hideEditVendorPartnerDrawer: ActionCreator<
		ThunkAction<
			EditVendorPartnerDrawerClosedAction,
			GlobalState,
			null,
			EditVendorPartnerDrawerClosedAction
		>
	>;
	updateCurrentActiveView: ActionCreator<
		ThunkAction<
			UpdateCurrentActiveViewAction,
			GlobalState,
			{ selectCurrentActiveView: CustomView; discardingView?: boolean },
			UpdateCurrentActiveViewAction
		>
	>;
	resetPartner: ActionCreator<
		ThunkAction<
			PartnerResetAction,
			GlobalState,
			VendorPartner,
			PartnerResetAction
		>
	>;
	createPartnerView: ActionCreator<
		ThunkAction<
			Promise<CreatePartnerViewSuccessfulAction>,
			GlobalState,
			CustomView,
			CreatePartnerViewSuccessfulAction
		>
	>;
	updatePartnerView: ActionCreator<
		ThunkAction<
			Promise<UpdatePartnerViewSuccessfulAction>,
			GlobalState,
			CustomView,
			UpdatePartnerViewSuccessfulAction
		>
	>;
	deletePartnerView: ActionCreator<
		ThunkAction<
			Promise<DeletePartnerViewSuccessfulAction>,
			GlobalState,
			{ deleteView: CustomView; initialView: CustomView },
			DeletePartnerViewSuccessfulAction
		>
	>;
	resetViews: ActionCreator<
		ThunkAction<
			ResetCurrentActiveViewAction,
			GlobalState,
			null,
			ResetCurrentActiveViewAction
		>
	>;
	fetchAllPartnerView: ActionCreator<
		ThunkAction<
			Promise<FetchAllPartnerViewSuccessful>,
			GlobalState,
			null,
			FetchAllPartnerViewSuccessful
		>
	>;
}

export const VendorPartnerActionCreators: VendorPartnerDispatch = {
	showVendorPartnerLoader: () => {
		return (dispatch: Dispatch): ShowVendorPartnerLoaderAction => {
			const showVendorPartnerLoaderAction: ShowVendorPartnerLoaderAction =
				{
					type: VendorPartnerActionTypes.SHOW_VENDOR_PARTNER_LOADER
				};
			return dispatch(showVendorPartnerLoaderAction);
		};
	},
	hideVendorPartnerLoader: () => {
		return (dispatch: Dispatch): HideVendorPartnerLoaderAction => {
			const hideVendorPartnerLoaderAction: HideVendorPartnerLoaderAction =
				{
					type: VendorPartnerActionTypes.HIDE_VENDOR_PARTNER_LOADER
				};
			return dispatch(hideVendorPartnerLoaderAction);
		};
	},
	getVendorPartnerDetails: (partnerId: string) => {
		return async (
			dispatch: Dispatch
		): Promise<VendorPartnerDetailsSuccessfulAction> => {
			const vendorPartnerDetailsRequestedAction: VendorPartnerDetailsRequestedAction =
				{
					type: VendorPartnerActionTypes.VENDOR_PARTNER_DETAILS_REQUESTED
				};
			dispatch(vendorPartnerDetailsRequestedAction);
			try {
				const vendorPartnerDetailsResponse =
					await getVendorPartnerDetails(partnerId);
				const vendorPartnerDetailsSuccessfulAction: VendorPartnerDetailsSuccessfulAction =
					{
						type: VendorPartnerActionTypes.VENDOR_PARTNER_DETAILS_SUCCESSFUL,
						currentActivePartner: vendorPartnerDetailsResponse
					};
				return dispatch(vendorPartnerDetailsSuccessfulAction);
			} catch (error) {
				const vendorPartnerDetailsFailedAction: VendorPartnerDetailsFailedAction =
					{
						type: VendorPartnerActionTypes.VENDOR_PARTNER_DETAILS_FAILED
					};
				dispatch(vendorPartnerDetailsFailedAction);

				return Promise.reject(error);
			}
		};
	},
	resetPartner: () => {
		return (dispatch: Dispatch) => {
			const partnerResetAction: PartnerResetAction = {
				type: VendorPartnerActionTypes.PARTNER_RESET
			};
			return dispatch(partnerResetAction);
		};
	},
	updateCurrentActiveView: (
		selectedCurrentActiveView: CustomView,
		discardingView?: boolean
	) => {
		return (
			dispatch: Dispatch,
			getState: () => GlobalState
		): UpdateCurrentActiveViewAction => {
			const currentActiveView =
				getState().vendorPartner.currentActiveView;
			const prevViewData = getState().vendorPartner.viewList?.data.find(
				view => view.id === selectedCurrentActiveView?.id
			);
			const isSameView =
				currentActiveView?.id === selectedCurrentActiveView?.id;

			if (isSameView) {
				// if its a custom view then it would have an id
				if (!!prevViewData) {
					const hasFiltersChanged = areFiltersChanged(
						prevViewData.filter_criteria,
						selectedCurrentActiveView.filter_criteria
					);

					const hasColumnsChanged =
						JSON.stringify(selectedCurrentActiveView.columns) !==
						JSON.stringify(prevViewData.columns);

					const updateCurrentActiveViewAction: UpdateCurrentActiveViewAction =
						{
							type: VendorPartnerActionTypes.UPDATE_PARTNER_CURRENT_ACTIVE_VIEW,
							currentActiveView: selectedCurrentActiveView,
							isViewChanged: discardingView
								? false
								: hasFiltersChanged || hasColumnsChanged
						};
					return dispatch(updateCurrentActiveViewAction);
				}
			}

			const updateCurrentActiveViewAction: UpdateCurrentActiveViewAction =
				{
					type: VendorPartnerActionTypes.UPDATE_PARTNER_CURRENT_ACTIVE_VIEW,
					currentActiveView: selectedCurrentActiveView,
					isViewChanged: discardingView ? true : false
				};
			return dispatch(updateCurrentActiveViewAction);
		};
	},
	createPartnerView: (view: CustomView) => {
		return async (
			dispatch: Dispatch
		): Promise<CreatePartnerViewSuccessfulAction> => {
			const createPartnerViewRequestedAction: CreatePartnerViewRequestedAction =
				{
					type: VendorPartnerActionTypes.CREATE_PARTNER_VIEW_REQUESTED
				};

			dispatch(createPartnerViewRequestedAction);

			try {
				const response = await createPartnerView(view);
				const createPartnerViewSuccessfulAction: CreatePartnerViewSuccessfulAction =
					{
						type: VendorPartnerActionTypes.CREATE_PARTNER_VIEW_SUCCESSFUL,
						view: response
					};
				return dispatch(createPartnerViewSuccessfulAction);
			} catch (error) {
				const createPartnerViewFailedAction: CreatePartnerViewFailedAction =
					{
						type: VendorPartnerActionTypes.CREATE_PARTNER_VIEW_FAILED
					};
				dispatch(createPartnerViewFailedAction);

				return Promise.reject(error);
			}
		};
	},
	updatePartnerView: (view: CustomView) => {
		return async (
			dispatch: Dispatch
		): Promise<UpdatePartnerViewSuccessfulAction> => {
			const updatePartnerViewRequestedAction: UpdatePartnerViewRequestedAction =
				{
					type: VendorPartnerActionTypes.UPDATE_PARTNER_VIEW_REQUESTED
				};

			dispatch(updatePartnerViewRequestedAction);

			try {
				const response = await updatePartnerView(view);
				const updatePartnerViewSuccessfulAction: UpdatePartnerViewSuccessfulAction =
					{
						type: VendorPartnerActionTypes.UPDATE_PARTNER_VIEW_SUCCESSFUL,
						view: response
					};
				return dispatch(updatePartnerViewSuccessfulAction);
			} catch (error) {
				const updatePartnerViewFailedAction: UpdatePartnerViewFailedAction =
					{
						type: VendorPartnerActionTypes.UPDATE_PARTNER_VIEW_FAILED
					};
				dispatch(updatePartnerViewFailedAction);

				return Promise.reject(error);
			}
		};
	},
	deletePartnerView: (deleteView: CustomView, initialView: CustomView) => {
		return async (
			dispatch: Dispatch
		): Promise<DeletePartnerViewSuccessfulAction> => {
			const deletePartnerViewRequestedAction: DeletePartnerViewRequestedAction =
				{
					type: VendorPartnerActionTypes.DELETE_PARTNER_VIEW_REQUESTED
				};

			dispatch(deletePartnerViewRequestedAction);

			try {
				await deletePartnerView(deleteView.id);
				const deletePartnerViewSuccessfulAction: DeletePartnerViewSuccessfulAction =
					{
						type: VendorPartnerActionTypes.DELETE_PARTNER_VIEW_SUCCESSFUL,
						deletedView: deleteView,
						initialView: initialView
					};
				return dispatch(deletePartnerViewSuccessfulAction);
			} catch (error) {
				const deletePartnerViewFailedAction: DeletePartnerViewFailedAction =
					{
						type: VendorPartnerActionTypes.DELETE_PARTNER_VIEW_FAILED
					};
				dispatch(deletePartnerViewFailedAction);

				return Promise.reject(error);
			}
		};
	},
	resetViews: () => {
		return (dispatch: Dispatch): ResetCurrentActiveViewAction => {
			const resetCurrentActiveViewAction: ResetCurrentActiveViewAction = {
				type: VendorPartnerActionTypes.RESET_PARTNER_CURRENT_ACTIVE_VIEW
			};

			return dispatch(resetCurrentActiveViewAction);
		};
	},
	fetchAllPartnerView: () => {
		return async (
			dispatch: Dispatch
		): Promise<FetchAllPartnerViewSuccessful> => {
			const fetchAllPartnerViewRequested: FetchAllPartnerViewRequested = {
				type: VendorPartnerActionTypes.FETCH_ALL_PARTNER_VIEWS_REQUESTED
			};

			dispatch(fetchAllPartnerViewRequested);

			try {
				const response = await getAllPartnerViews();
				const fetchAllPartnerViewSuccessful: FetchAllPartnerViewSuccessful =
					{
						type: VendorPartnerActionTypes.FETCH_ALL_PARTNER_VIEWS_SUCCESSFUL,
						viewList: response
					};
				return dispatch(fetchAllPartnerViewSuccessful);
			} catch (error) {
				console.error(error);

				const fetchAllPartnerViewFailed: FetchAllPartnerViewFailed = {
					type: VendorPartnerActionTypes.FETCH_ALL_PARTNER_VIEWS_FAILED
				};
				dispatch(fetchAllPartnerViewFailed);
				return Promise.reject(error);
			}
		};
	},
	showEditVendorPartnerDrawer: () => {
		return (dispatch: Dispatch): EditVendorPartnerDrawerOpenedAction => {
			const editVendorPartnerDrawerOpenedAction: EditVendorPartnerDrawerOpenedAction =
				{
					type: VendorPartnerActionTypes.EDIT_VENDOR_PARTNER_DRAWER_OPENED
				};
			return dispatch(editVendorPartnerDrawerOpenedAction);
		};
	},
	hideEditVendorPartnerDrawer: () => {
		return (dispatch: Dispatch): EditVendorPartnerDrawerClosedAction => {
			const editVendorPartnerDrawerClosedAction: EditVendorPartnerDrawerClosedAction =
				{
					type: VendorPartnerActionTypes.EDIT_VENDOR_PARTNER_DRAWER_CLOSED
				};
			return dispatch(editVendorPartnerDrawerClosedAction);
		};
	}
};
