import { QuoteProduct } from "@zomentum/contracts";

export interface IConfigureProductSKUInQuoteBlockDrawer {
	readonly visible: boolean;
	readonly onClose: () => void;
	readonly onSubmit: (product: QuoteProduct) => Promise<void>;
	readonly productData: QuoteProduct | null;
	readonly drawerMode?: "edit" | "clone";
}

export enum EditOrCloneProductDrawerMode {
	EDIT = "edit",
	CLONE = "clone"
}

export enum EProductSKUDrawerState {
	ADD = "add",
	EDITORCLONE = "editorclone"
}
