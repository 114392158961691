import axios, { AxiosResponse } from "axios";
import {
	BulkUpdatesActionResultResponse,
	BulkUpdatesResultRequest,
	GetCollectJSTokenResponse
} from "@zomentum/contracts/dist/UI";
import { getTableQueryFilters } from "@zomentum/contracts/dist/Utils";
import { getRequest } from "@zomentum/utils/dist/Service/BackendService";

const getBulkUpdateRecords = "/v2/async/operation";
const getCollectJSTokenURL = "/v2/document/:id/getCollectjsToken/";
export const fetchBulkUpdatesRecords = async (
	bulkUpdatesResultRequest: BulkUpdatesResultRequest
): Promise<BulkUpdatesActionResultResponse> => {
	try {
		const tableQueryFilters = getTableQueryFilters(
			bulkUpdatesResultRequest.filters,
			false
		);

		const url =
			getBulkUpdateRecords +
			`?limit=${bulkUpdatesResultRequest.limit}&page=${bulkUpdatesResultRequest.page}&${tableQueryFilters}`;

		const response: AxiosResponse<BulkUpdatesActionResultResponse> =
			await axios(getRequest(url, null, true, true, false));

		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchCollectJsToken = async (id: string) => {
	try {
		const response: AxiosResponse<GetCollectJSTokenResponse> = await axios(
			getRequest(
				getCollectJSTokenURL.replace(":id", id),
				null,
				true,
				false,
				false
			)
		);
		return response.data.token;
	} catch (error) {
		return Promise.reject(error);
	}
};
