import React, { ReactText, useEffect, useState } from "react";
import ZModal from "@zomentum/atoms/dist/ZModal";
import { ModalProps } from "antd";
import ZTable from "@zomentum/organisms/dist/ZTable";
import { TableRowSelection } from "antd/lib/table/interface";
import ZIcon from "@zomentum/atoms/dist/ZIcon";
import ZInput from "@zomentum/molecules/dist/ZInput";
import { EIcons } from "@zomentum/atoms/dist/ZIcon/interface";
import { EColors } from "@zomentum/atoms/dist/ZColors/interface";
import useDebounce from "use-debounce/lib/useDebounce";
import { searchEmailTemplates } from "@/Services/Vendor/EmailTemplates";
import { VendorEmailTemplate } from "@zomentum/contracts/dist/Vendor/EmailTemplate";

interface IImportEmailTemplateModalProps extends ModalProps {
	readonly onSelect: (template: VendorEmailTemplate) => void;
}

const ImportEmailTemplateModal: React.FC<
	IImportEmailTemplateModalProps
> = props => {
	const [emailTemplateList, setEmailTemplateList] = useState<
		VendorEmailTemplate[]
	>([]);

	const [searchString, setSearchString] = useState("");

	const [deBouncedSearchString] = useDebounce(searchString, 600);

	const [selectedTemplate, setSelectedTemplate] =
		useState<VendorEmailTemplate | null>(null);

	const [isLoading, setisLoading] = useState(false);

	const useSelectedTemplate = () => {
		if (selectedTemplate) {
			props.onSelect(selectedTemplate);
			setSelectedTemplate(null);
		}
	};

	const emptyEmailTemplates = () => {
		return (
			<div className="no-templates-found">
				No email templates are present.
			</div>
		);
	};

	const columns = [
		{
			dataIndex: "name",
			key: "name",
			title: "Templates"
		}
	];

	const rowSelection: TableRowSelection<VendorEmailTemplate> = {
		onChange: (
			_selectedEmailTemplateId: ReactText[],
			selectedEmailTemplates: VendorEmailTemplate[]
		) => {
			setSelectedTemplate(selectedEmailTemplates[0]);
		},
		type: "radio"
	};

	const searchEmailTemplate = async (keyword = "") => {
		try {
			setisLoading(true);
			const emailTemplatesResponse = await searchEmailTemplates(keyword);
			setEmailTemplateList(emailTemplatesResponse.data);
			setisLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	const customEmailTemplates = emailTemplateList.filter(
		(template: VendorEmailTemplate) => !template.custom_template_type
	);

	useEffect(() => {
		if (props.visible) searchEmailTemplate();
	}, [props.visible]);

	useEffect(() => {
		searchEmailTemplate(deBouncedSearchString);
	}, [deBouncedSearchString]);

	return (
		<ZModal
			centered
			destroyOnClose={true}
			title="Email Templates"
			visible={props.visible}
			onCancel={props.onCancel}
			cancelText="Close"
			wrapClassName="import-email-templates-modal"
			size="md"
			okText="Use"
			okButtonProps={{
				onClick: useSelectedTemplate,
				disabled: !!!selectedTemplate
			}}
		>
			<ZInput
				type="search"
				placeholder="Search Email Templates"
				suffix={
					!searchString && (
						<ZIcon
							name={EIcons.SEARCH}
							size="sm"
							color={EColors.BLUE}
							colorVariant={7}
						/>
					)
				}
				className="mb-4"
				onChange={e => setSearchString(e.target.value)}
				value={searchString}
				allowClear
			/>
			{customEmailTemplates.length > 0 ? (
				<ZTable
					columns={columns}
					showHeader={true}
					rowKey="id"
					pagination={false}
					rowSelection={rowSelection}
					dataSource={customEmailTemplates}
					loading={isLoading}
					size="small"
				/>
			) : (
				emptyEmailTemplates()
			)}
		</ZModal>
	);
};

export default ImportEmailTemplateModal;
