'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.EWidgets = void 0;
(function (EWidgets) {
  EWidgets["INPUT"] = "zinput";
  EWidgets["INPUTPASSWORD"] = "zpassword";
  EWidgets["INPUTSEARCH"] = "zsearch";
  EWidgets["INPUTTEXTAREA"] = "textarea";
  EWidgets["INPUTNUMBER"] = "zinputnumber";
  EWidgets["INPUTNUMBERWITHSELECT"] = "zinputnumberwithselect";
  EWidgets["SELECT"] = "zselect";
  EWidgets["DATEPICKER"] = "z-date-picker";
  EWidgets["TIMEPICKER"] = "z-time-picker";
  EWidgets["RADIO"] = "radio";
  EWidgets["CHECKBOX"] = "zcheckbox";
  EWidgets["CHECKBOXGROUP"] = "zcheckboxgroup";
  EWidgets["SWITCH"] = "switch";
  EWidgets["RADIOGROUP"] = "zradiogroup";
  EWidgets["RATE"] = "rate";
})(exports.EWidgets || (exports.EWidgets = {}));
exports.EValidateStatus = void 0;
(function (EValidateStatus) {
  EValidateStatus["SUCCESS"] = "success";
  EValidateStatus["WARNING"] = "warning";
  EValidateStatus["ERROR"] = "error";
  EValidateStatus["VALIDATING"] = "validating";
})(exports.EValidateStatus || (exports.EValidateStatus = {}));
