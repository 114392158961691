export enum MarketplaceProductMediaType {
	IMAGE = "image",
	VIDEO = "video"
}

export interface IMediaPreviewProps {
	readonly visible: boolean;
	readonly onClose?: () => void;
	readonly fileType: MarketplaceProductMediaType;
	readonly mediaUrl: string | null;
}
