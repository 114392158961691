'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var VendorInsight = /** @class */function () {
  function VendorInsight(net_revenue, transacting_partners, view_count, invited_partners, requested_partners, accepted_partners, created_deals, closed_deals, average_deal_size, revenue_per_month, total_partners) {
    this.net_revenue = net_revenue;
    this.transacting_partners = transacting_partners;
    this.view_count = view_count;
    this.invited_partners = invited_partners;
    this.requested_partners = requested_partners;
    this.accepted_partners = accepted_partners;
    this.created_deals = created_deals;
    this.closed_deals = closed_deals;
    this.average_deal_size = average_deal_size;
    this.revenue_per_month = revenue_per_month;
    this.total_partners = total_partners;
  }
  return VendorInsight;
}();
var RevenuePerMonth = /** @class */function () {
  function RevenuePerMonth(month) {
    this.month = month;
  }
  return RevenuePerMonth;
}();
var VendorRevenue = /** @class */function () {
  function VendorRevenue(partner_company_id, partner_company_name, revenue, won_opportunities) {
    this.partner_company_id = partner_company_id;
    this.partner_company_name = partner_company_name;
    this.revenue = revenue;
    this.won_opportunities = won_opportunities;
  }
  return VendorRevenue;
}();
var GetVendorRevenueResponse = /** @class */function () {
  function GetVendorRevenueResponse() {
    this.data = new Array();
  }
  return GetVendorRevenueResponse;
}();
var VendorDashboardResource = /** @class */function () {
  function VendorDashboardResource(resource_id, id, resource_name, views_count, downloads_count, mime_type, file_key, thumbnail) {
    this.resource_id = resource_id;
    this.id = id;
    this.resource_name = resource_name;
    this.views_count = views_count;
    this.downloads_count = downloads_count;
    this.mime_type = mime_type;
    this.file_key = file_key;
    this.thumbnail = thumbnail;
  }
  return VendorDashboardResource;
}();
var GetVendorDashboardResourceResponse = /** @class */function () {
  function GetVendorDashboardResourceResponse() {
    this.data = new Array();
  }
  return GetVendorDashboardResourceResponse;
}();
var VendorDashboardResourceDetails = /** @class */function () {
  function VendorDashboardResourceDetails(partner_name, views_count, downloads_count) {
    this.partner_name = partner_name;
    this.views_count = views_count;
    this.downloads_count = downloads_count;
  }
  return VendorDashboardResourceDetails;
}();
var GetVendorDashboardResourceDetailsResponse = /** @class */function () {
  function GetVendorDashboardResourceDetailsResponse() {
    this.data = new Array();
  }
  return GetVendorDashboardResourceDetailsResponse;
}();

exports.GetVendorDashboardResourceDetailsResponse = GetVendorDashboardResourceDetailsResponse;
exports.GetVendorDashboardResourceResponse = GetVendorDashboardResourceResponse;
exports.GetVendorRevenueResponse = GetVendorRevenueResponse;
exports.RevenuePerMonth = RevenuePerMonth;
exports.VendorDashboardResource = VendorDashboardResource;
exports.VendorDashboardResourceDetails = VendorDashboardResourceDetails;
exports.VendorInsight = VendorInsight;
exports.VendorRevenue = VendorRevenue;
