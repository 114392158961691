import { ThunkAction } from "redux-thunk";
import { Action, ActionCreator, Dispatch } from "redux";
import { GlobalState } from "@/Reducers/interface";
import {
	VendorPartnerTier,
	VendorPartnerAddTierRequest,
	VendorPartnerEditTierRequest
} from "@zomentum/contracts/dist/Vendor/Tiers";
import {
	addVendorTier,
	deleteVendorTier,
	getAllVendorTiers,
	updateVendorTier
} from "@/Services/Vendor/Tier";

export enum VendorTiersActionTypes {
	FETCH_VENDOR_TIERS_REQUESTED = "FETCH_VENDOR_TIERS_REQUESTED",
	FETCH_VENDOR_TIERS_SUCCESSFULL = "FETCH_VENDOR_TIERS_SUCCESSFULL",
	FETCH_VENDOR_TIERS_FAILED = "FETCH_VENDOR_TIERS_FAILED",
	ADD_VENDOR_TIER_REQUESTED = "ADD_VENDOR_TIER_REQUESTED",
	ADD_VENDOR_TIER_SUCCESSFUL = "ADD_VENDOR_TIER_SUCCESSFUL",
	ADD_VENDOR_TIER_FAILED = "ADD_VENDOR_TIER_FAILED",
	DELETE_VENDOR_TIER_REQUESTED = "DELETE_VENDOR_TIER_REQUESTED",
	DELETE_VENDOR_TIER_SUCCESSFUL = "DELETE_VENDOR_TIER_SUCCESSFUL",
	DELETE_VENDOR_TIER_FAILED = "DELETE_VENDOR_TIER_FAILED",
	UPDATE_VENDOR_TIER_REQUESTED = "UPDATE_VENDOR_TIER_REQUESTED",
	UPDATE_VENDOR_TIER_SUCCESSFUL = "UPDATE_VENDOR_TIER_SUCCESSFUL",
	UPDATE_VENDOR_TIER_FAILED = "UPDATE_VENDOR_TIER_FAILED",
	SHOW_VENDOR_TIERS_LOADER = "SHOW_VENDOR_TIERS_LOADER",
	HIDE_VENDOR_TIERS_LOADER = "HIDE_VENDOR_TIERS_LOADER"
}

export type ShowVendorTiersLoaderAction =
	Action<VendorTiersActionTypes.SHOW_VENDOR_TIERS_LOADER>;
export type HideVendorTiersLoaderAction =
	Action<VendorTiersActionTypes.HIDE_VENDOR_TIERS_LOADER>;

export type FetchVendorTiersRequestedAction =
	Action<VendorTiersActionTypes.FETCH_VENDOR_TIERS_REQUESTED>;
export interface FetchVendorTiersSuccessfulAction
	extends Action<VendorTiersActionTypes.FETCH_VENDOR_TIERS_SUCCESSFULL> {
	readonly tierList: VendorPartnerTier[];
}
export type FetchVendorTiersFailedAction =
	Action<VendorTiersActionTypes.FETCH_VENDOR_TIERS_FAILED>;

export type AddVendorTierRequestedAction =
	Action<VendorTiersActionTypes.ADD_VENDOR_TIER_REQUESTED>;

export type AddVendorTierFailedAction =
	Action<VendorTiersActionTypes.ADD_VENDOR_TIER_FAILED>;

export interface AddVendorTierSuccessfulAction
	extends Action<VendorTiersActionTypes.ADD_VENDOR_TIER_SUCCESSFUL> {
	readonly newTier: VendorPartnerTier;
}

export type DeleteVendorTierRequestedAction =
	Action<VendorTiersActionTypes.DELETE_VENDOR_TIER_REQUESTED>;
export interface DeleteVendorTierSuccessfulAction
	extends Action<VendorTiersActionTypes.DELETE_VENDOR_TIER_SUCCESSFUL> {
	readonly deletedTierId: string;
}
export type DeleteVendorTierFailedAction =
	Action<VendorTiersActionTypes.DELETE_VENDOR_TIER_FAILED>;

export type UpdateVendorTierRequestedAction =
	Action<VendorTiersActionTypes.UPDATE_VENDOR_TIER_REQUESTED>;

export type UpdateVendorTierFailedAction =
	Action<VendorTiersActionTypes.UPDATE_VENDOR_TIER_FAILED>;

export interface UpdateVendorTierSuccessfulAction
	extends Action<VendorTiersActionTypes.UPDATE_VENDOR_TIER_SUCCESSFUL> {
	readonly updatedVendorTier: VendorPartnerTier;
}

export type VendorTiersAction =
	| FetchVendorTiersFailedAction
	| FetchVendorTiersSuccessfulAction
	| FetchVendorTiersRequestedAction
	| AddVendorTierRequestedAction
	| AddVendorTierSuccessfulAction
	| AddVendorTierFailedAction
	| DeleteVendorTierRequestedAction
	| DeleteVendorTierSuccessfulAction
	| DeleteVendorTierFailedAction
	| UpdateVendorTierRequestedAction
	| UpdateVendorTierSuccessfulAction
	| UpdateVendorTierFailedAction
	| ShowVendorTiersLoaderAction
	| HideVendorTiersLoaderAction;

export type VendorTiersDispatch = {
	showLoader: ActionCreator<
		ThunkAction<
			ShowVendorTiersLoaderAction,
			GlobalState,
			null,
			ShowVendorTiersLoaderAction
		>
	>;
	hideLoader: ActionCreator<
		ThunkAction<
			HideVendorTiersLoaderAction,
			GlobalState,
			null,
			HideVendorTiersLoaderAction
		>
	>;
	fetchVendorTiers: ActionCreator<
		ThunkAction<
			Promise<FetchVendorTiersSuccessfulAction>,
			GlobalState,
			null,
			FetchVendorTiersSuccessfulAction
		>
	>;
	addVendorTier: ActionCreator<
		ThunkAction<
			Promise<AddVendorTierSuccessfulAction>,
			GlobalState,
			{ addVendorTierRequest: VendorPartnerAddTierRequest },
			AddVendorTierSuccessfulAction
		>
	>;
	deleteVendorTier: ActionCreator<
		ThunkAction<
			Promise<DeleteVendorTierSuccessfulAction>,
			GlobalState,
			{ tierId: string },
			DeleteVendorTierSuccessfulAction
		>
	>;
	updateVendorTier: ActionCreator<
		ThunkAction<
			Promise<UpdateVendorTierSuccessfulAction>,
			GlobalState,
			{
				tierId: string;
				updateVendorTierRequest: VendorPartnerEditTierRequest;
			},
			UpdateVendorTierSuccessfulAction
		>
	>;
};

export const VendorTiersActionCreators: VendorTiersDispatch = {
	showLoader: () => {
		return (dispatch: Dispatch): ShowVendorTiersLoaderAction => {
			const showVendorTiersLoaderAction: ShowVendorTiersLoaderAction = {
				type: VendorTiersActionTypes.SHOW_VENDOR_TIERS_LOADER
			};
			return dispatch(showVendorTiersLoaderAction);
		};
	},
	hideLoader: () => {
		return (dispatch: Dispatch): HideVendorTiersLoaderAction => {
			const hideVendorTiersrawerAction: HideVendorTiersLoaderAction = {
				type: VendorTiersActionTypes.HIDE_VENDOR_TIERS_LOADER
			};
			return dispatch(hideVendorTiersrawerAction);
		};
	},
	fetchVendorTiers: () => {
		return async (
			dispatch: Dispatch
		): Promise<FetchVendorTiersSuccessfulAction> => {
			const fetchAllTtetmplatesRequestedAction: FetchVendorTiersRequestedAction =
				{
					type: VendorTiersActionTypes.FETCH_VENDOR_TIERS_REQUESTED
				};
			dispatch(fetchAllTtetmplatesRequestedAction);
			try {
				const response = await getAllVendorTiers();
				const tierList = response.data.map(tier => ({
					...tier,
					partners_count: response.filtered_count_map?.[tier.id] ?? 0
				}));
				const fetchalltemplatesSuccessfulAction: FetchVendorTiersSuccessfulAction =
					{
						type: VendorTiersActionTypes.FETCH_VENDOR_TIERS_SUCCESSFULL,
						tierList
					};
				return dispatch(fetchalltemplatesSuccessfulAction);
			} catch (error) {
				const FetchVendorTiersFailedAction: FetchVendorTiersFailedAction =
					{
						type: VendorTiersActionTypes.FETCH_VENDOR_TIERS_FAILED
					};
				dispatch(FetchVendorTiersFailedAction);

				return Promise.reject(error);
			}
		};
	},
	addVendorTier: (addVendorTierRequest: VendorPartnerAddTierRequest) => {
		return async (
			dispatch: Dispatch
		): Promise<AddVendorTierSuccessfulAction> => {
			const addVendorTierRequestedAction: AddVendorTierRequestedAction = {
				type: VendorTiersActionTypes.ADD_VENDOR_TIER_REQUESTED
			};
			dispatch(addVendorTierRequestedAction);
			try {
				const response = await addVendorTier(addVendorTierRequest);
				const addVendorTierSuccessfulAction: AddVendorTierSuccessfulAction =
					{
						type: VendorTiersActionTypes.ADD_VENDOR_TIER_SUCCESSFUL,
						newTier: response
					};
				return dispatch(addVendorTierSuccessfulAction);
			} catch (error) {
				const addVendorTierFailedAction: AddVendorTierFailedAction = {
					type: VendorTiersActionTypes.ADD_VENDOR_TIER_FAILED
				};
				dispatch(addVendorTierFailedAction);

				return Promise.reject(error);
			}
		};
	},
	deleteVendorTier: (tierId: string) => {
		return async (
			dispatch: Dispatch
		): Promise<DeleteVendorTierSuccessfulAction> => {
			const deleteVendorTierRequestedAction: DeleteVendorTierRequestedAction =
				{
					type: VendorTiersActionTypes.DELETE_VENDOR_TIER_REQUESTED
				};
			dispatch(deleteVendorTierRequestedAction);
			try {
				const deletedResponse = await deleteVendorTier(tierId);
				if (deletedResponse.deleted) {
					const deleteVendorTierSuccessfulAction: DeleteVendorTierSuccessfulAction =
						{
							type: VendorTiersActionTypes.DELETE_VENDOR_TIER_SUCCESSFUL,
							deletedTierId: tierId
						};
					return dispatch(deleteVendorTierSuccessfulAction);
				}
				throw new Error("Cannot delete email template");
			} catch (error) {
				const deleteVendorTierFailedAction: DeleteVendorTierFailedAction =
					{
						type: VendorTiersActionTypes.DELETE_VENDOR_TIER_FAILED
					};
				dispatch(deleteVendorTierFailedAction);

				return Promise.reject(error);
			}
		};
	},
	updateVendorTier: (
		tierId: string,
		updateVendorTierRequest: VendorPartnerEditTierRequest
	) => {
		return async (
			dispatch: Dispatch
		): Promise<UpdateVendorTierSuccessfulAction> => {
			const updateVendorTierRequestedAction: UpdateVendorTierRequestedAction =
				{
					type: VendorTiersActionTypes.UPDATE_VENDOR_TIER_REQUESTED
				};
			dispatch(updateVendorTierRequestedAction);

			try {
				const response = await updateVendorTier(
					tierId,
					updateVendorTierRequest
				);
				const updateVendorTierSuccessfulAction: UpdateVendorTierSuccessfulAction =
					{
						type: VendorTiersActionTypes.UPDATE_VENDOR_TIER_SUCCESSFUL,
						updatedVendorTier: response
					};
				return dispatch(updateVendorTierSuccessfulAction);
			} catch (error) {
				console.error(error);

				return Promise.reject(error);
			}
		};
	}
};

export default VendorTiersActionCreators;
