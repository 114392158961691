import ZIcon from "@zomentum/atoms/dist/ZIcon";
import ZImage from "@zomentum/atoms/dist/ZImage";
import ZTooltip from "@zomentum/atoms/dist/ZTooltip";
import React, { memo } from "react";
import { IScreenshotImagesProps } from "./interface";
import { EIcons } from "@zomentum/atoms/dist/ZIcon/interface";

const ZScreenshotImages: React.FC<IScreenshotImagesProps> = ({
	imagesList,
	deleteImage,
	showImageUploadModal,
	showPreviewImageModal,
	isSingleImage = false,
	uploadButtonIcon = EIcons.ADD,
	uploadButtonText = "Add Image",
	className
}) => {
	const shouldShowUploadButton = isSingleImage ? !imagesList.length : true;

	return (
		<div className={`flex items-center flex-wrap ${className}`}>
			{imagesList?.map((image, index) => {
				return (
					<div
						key={index}
						className="border relative border-solid border-gray-5 rounded h-28 w-28 mr-2 mb-2"
					>
						<ZImage
							src={image}
							alt="Product"
							wrapperClassName="h-28 w-28 flex items-center justify-center"
							preview={false}
							className="object-contain p-2 w-full h-full"
						/>
						<div className="absolute top-0 flex items-center justify-around opacity-0 hover:opacity-100 hover:bg-gray-10 hover:bg-opacity-50 h-full w-full">
							<ZTooltip title="Preview" placement="bottom">
								<ZIcon
									name={EIcons.EYE}
									onClick={() => {
										showPreviewImageModal(image);
									}}
									className={`cursor-pointer`}
									colorVariant={1}
								/>
							</ZTooltip>
							<ZTooltip title="Delete" placement="bottom">
								<ZIcon
									name={EIcons.TRASH}
									onClick={() => {
										deleteImage(image);
									}}
									className="cursor-pointer"
									colorVariant={1}
								/>
							</ZTooltip>
						</div>
					</div>
				);
			})}
			{shouldShowUploadButton && (
				<div
					className="border relative border-solid border-gray-5 rounded mr-2 h-28 w-28 flex flex-col items-center justify-center cursor-pointer hover:bg-gray-3 mb-2"
					onClick={showImageUploadModal}
				>
					<div className="mb-2">
						<ZIcon name={uploadButtonIcon} />
					</div>
					<div>{uploadButtonText}</div>
				</div>
			)}
		</div>
	);
};

export default memo(ZScreenshotImages);
