import React, { useState } from "react";
import ZTooltip from "@zomentum/atoms/dist/ZTooltip";
import ZTypography from "@zomentum/atoms/dist/ZTypography";
import { RefSelectProps } from "antd/lib/select";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "@zomentum/utils";
import { IUserSelectProps } from "./interface";
import { ConnectedEmails } from "@zomentum/contracts/dist/Settings";
import {
	IOptionGroupProps,
	IOptionProps
} from "@zomentum/atoms/dist/ZSelect/interface";
import { GlobalState } from "@/Reducers/interface";
import { getGlobalSearch } from "@zomentum/utils/dist/Service/GlobalSearch";
import {
	GlobalSearchEntityType,
	GlobalSearchRequest
} from "@zomentum/contracts/dist/Others/GlobalSearch";
import { VendorUserData } from "@zomentum/contracts";
import ZDynamicSelect from "@zomentum/molecules/dist/ZDynamicSelect";

const { ZText } = ZTypography;

const ZUserSelect: React.FC<IUserSelectProps> = React.forwardRef(
	(props, ref) => {
		const selectRef = ref as React.RefObject<RefSelectProps>;
		const [customEmails, _setCustomEmails] = useState<string[]>([]);
		const [customDomainEmails, _setCustomDomainEmails] = useState<
			ConnectedEmails[]
		>([]);
		const [searchedUsers, setSearchedUsers] = useState<IOptionProps[]>([]);

		const {
			selectedUser,
			optGroup,
			filterCurrentUser,
			mode,
			allowEmailIntegratedUsers,
			allowDKIMEmails,
			shouldIncludeBotUsers,
			...selectProps
		} = props;

		const {
			companyUsersList,
			userId,
			email: integratedEmailUsers
			// dkim_verification
		} = useSelector((state: GlobalState) => ({
			companyUsersList:
				state.vendorUser.vendorUserCompany.vendor_users.data,
			userId: state.vendorUser.vendorUserData.id,
			email: state.vendorUser.vendorUserIntegration.email
			// dkim_verification:
			// 	state.user.userCompany?.settings?.dkim_verification
		}));

		const usersList = filterCurrentUser
			? companyUsersList.filter(user => user.id !== userId)
			: companyUsersList;
		if (
			selectedUser &&
			!usersList.find(user => user.id === selectedUser.id)
		) {
			usersList.push(selectedUser);
		}

		const integratedEmails = integratedEmailUsers.map(user => user.email);

		// useEffect(() => {
		// 	if (allowDKIMEmails) {
		// 		const customEmails = dkim_verification?.email
		// 			.filter(email => email.status === DKIMStatus.Success)
		// 			.map(email => email.email);

		// 		const customDomainEmails = flatMap(
		// 			dkim_verification?.domain.filter(
		// 				domain => domain.status === DKIMStatus.Success
		// 			),
		// 			domain => domain.connected_emails
		// 		).filter(
		// 			connectedEmail =>
		// 				connectedEmail.status === DKIMStatus.Success
		// 		);

		// 		if (customEmails) setCustomEmails(customEmails);
		// 		if (customDomainEmails)
		// 			setCustomDomainEmails(customDomainEmails);
		// 	}
		// }, []);

		const shouldShowVerifiedEmails =
			!!customEmails.length || !!customDomainEmails.length;

		const optionsGroup: IOptionGroupProps[] = [
			{
				label: "Users",
				selectOptions: usersList
					.filter((user: any) =>
						shouldIncludeBotUsers ? true : !user.bot_user_source
					)
					.map((companyUser: any) => {
						if (
							!integratedEmails.includes(companyUser.email) &&
							allowEmailIntegratedUsers &&
							mode !== "multiple"
						) {
							return {
								key: companyUser.id,
								disabled: true,
								value: companyUser.id,
								name: (
									<ZTooltip
										showtooltip={true}
										placement="left"
										title="This user has not connected their inbox"
									>
										<ZText colorVariant={6} aria-disabled>
											{`${capitalizeFirstLetter(
												companyUser.name.full
											)} <${companyUser.email}>`}
										</ZText>
									</ZTooltip>
								)
							};
						} else {
							return {
								disabled: companyUser.is_disabled,
								key: companyUser.id,
								value: companyUser.id,
								name: companyUser.is_disabled ? (
									<ZTooltip
										showtooltip
										placement="left"
										title="Inactive user"
									>
										<ZText colorVariant={6} aria-disabled>
											{`${capitalizeFirstLetter(
												companyUser.name.full
											)} <${companyUser.email}>`}{" "}
											( Inactive user )
										</ZText>
									</ZTooltip>
								) : (
									<ZText>{`${capitalizeFirstLetter(
										companyUser.name.full
									)} <${companyUser.email}>`}</ZText>
								)
							};
						}
					})
			}
		];

		if (optGroup) optionsGroup.unshift(...optGroup);

		// Show SES & DKIM emails
		if (props.allowDKIMEmails && shouldShowVerifiedEmails) {
			const emails = customEmails.map(email => {
				return {
					key: email,
					value: email,
					name: <span>{email}</span>
				};
			});

			const domainEmails = customDomainEmails.map(email => {
				return {
					key: email.name,
					value: email.email,
					name: <span>{`${email.name}<${email.email}>`}</span>
				};
			});

			const verifiedEmailsGroup: IOptionGroupProps = {
				label: "Verified emails",
				selectOptions: [...emails, ...domainEmails]
			};

			optionsGroup.push(verifiedEmailsGroup);
		}

		const searchUsers = async (value: string) => {
			try {
				const userSearchRequest = new GlobalSearchRequest(
					value,
					[GlobalSearchEntityType.VendorUser],
					10,
					null
				);
				const searchResult = await getGlobalSearch(userSearchRequest);
				setSearchedUsers(
					searchResult.data.map(entity => {
						const user = entity.obj as VendorUserData;
						return getUserOptionItemProps(user);
					})
				);
			} catch (error) {
				console.error(error);
			}
		};

		const getUserSelectOptions = () => {
			const optionProps: IOptionProps[] = usersList.map(
				(companyUser: VendorUserData) => {
					return getUserOptionItemProps(companyUser);
				}
			);
			return optionProps;
		};

		const getUserOptionItemProps = (user: VendorUserData) => {
			return {
				disabled: user.is_disabled,
				key: user.id,
				name: user.is_disabled ? (
					<ZTooltip
						showtooltip
						placement="left"
						title="Inactive user"
					>
						<ZText colorVariant={6} aria-disabled>
							{`${capitalizeFirstLetter(user.name.full)} <${
								user.email
							}>`}{" "}
							( Inactive user )
						</ZText>
					</ZTooltip>
				) : (
					<ZText>{`${capitalizeFirstLetter(user.name.full)} <${
						user.email
					}>`}</ZText>
				),
				value: user.id
			};
		};

		return !!props.optGroup ? (
			<ZDynamicSelect
				zref={selectRef}
				getPopupContainer={trigger => trigger.parentNode}
				mode={mode}
				isOptionsGroup={true}
				optionsGroup={optionsGroup}
				showSearch={props.showSearch ?? false}
				options={searchedUsers}
				fetchData={searchUsers}
				{...selectProps}
			/>
		) : (
			<ZDynamicSelect
				zref={selectRef}
				data-testid="user-select"
				mode={mode}
				options={searchedUsers}
				getPopupContainer={trigger => trigger.parentNode}
				showSearch={props.showSearch ?? false}
				defaultOptions={getUserSelectOptions()}
				optionsGroup={optionsGroup}
				fetchData={searchUsers}
				{...selectProps}
			/>
		);
	}
);

export default ZUserSelect;
