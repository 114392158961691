'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var common = require('../common.js');

/*
    filter_entity_type: ZomentumEntities | null;
    field_name: string | null;
    field_display_name: string | null;
    filter_operator: SalesActivityAutomationActionUpdatableFieldValue<SalesActivityAutomationMetadataFilterOperator> | null;
    values: SalesActivityAutomationActionUpdatableFieldValue<...>[];
*/
exports.CustomViewColumnStatus = void 0;
(function (CustomViewColumnStatus) {
  CustomViewColumnStatus["Enabled"] = "enabled";
  CustomViewColumnStatus["Disabled"] = "disabled";
  CustomViewColumnStatus["AlwaysEnabled"] = "always_enabled";
})(exports.CustomViewColumnStatus || (exports.CustomViewColumnStatus = {}));
var CustomView = /** @class */function () {
  function CustomView() {
    this.id = "";
    this.name = "";
    this.company_id = "";
    this.entity_type = common.ZomentumEntities.Opportunity;
    this.filter_criteria = new Array();
    this.columns = new Array();
    this.is_system_view = false;
  }
  return CustomView;
}();
exports.SortCriterionOrder = void 0;
(function (SortCriterionOrder) {
  SortCriterionOrder["Ascending"] = "asc";
  SortCriterionOrder["Descending"] = "desc";
})(exports.SortCriterionOrder || (exports.SortCriterionOrder = {}));
var ListPagePostRequest = /** @class */function () {
  function ListPagePostRequest(args) {
    this.filter_criterion = new Array();
    this.sort_criterion = [];
    this.page_no = 1;
    this.page_size = 10;
    this.included_child_entities = new Array();
    this.count_query_flag = args.count_query_flag;
    this.filter_criterion = args.filter_criterion;
    this.sort_criterion = args.sort_criterion;
    this.page_no = args.page_no;
    this.page_size = args.page_size;
    this.included_child_entities = args.includedChildEntities;
  }
  return ListPagePostRequest;
}();
exports.BulkActions = void 0;
(function (BulkActions) {
  BulkActions["Delete"] = "delete";
  BulkActions["Update"] = "update";
})(exports.BulkActions || (exports.BulkActions = {}));
var BulkDeleteRecordsRequest = /** @class */function () {
  function BulkDeleteRecordsRequest() {
    this.async_operation_type = exports.BulkActions.Update;
    this.metadata = new BulkDeleteMetadataRequest();
  }
  return BulkDeleteRecordsRequest;
}();
var BulkUpdateRecordsRequest = /** @class */function () {
  function BulkUpdateRecordsRequest() {
    this.async_operation_type = exports.BulkActions.Update;
    this.metadata = new BulkUpdateMetadataRequest();
  }
  return BulkUpdateRecordsRequest;
}();
var BulkDeleteMetadataRequest = /** @class */function () {
  function BulkDeleteMetadataRequest() {
    this.filters = new Array();
    this.entity_type = common.ZomentumEntities.Opportunity;
  }
  return BulkDeleteMetadataRequest;
}();
var BulkUpdateMetadataRequest = /** @class */function () {
  function BulkUpdateMetadataRequest() {
    this.filters = new Array();
    this.entity_type = common.ZomentumEntities.Opportunity;
    this.fields = new Array();
  }
  return BulkUpdateMetadataRequest;
}();
var IntegrationPageDetailsUpdateRequest = /** @class */function () {
  function IntegrationPageDetailsUpdateRequest() {
    this.id = "";
    this.integrations_page_details = new IntegrationPageDetailsUpdateData();
  }
  return IntegrationPageDetailsUpdateRequest;
}();
var IntegrationPageDetailsUpdateData = /** @class */function () {
  function IntegrationPageDetailsUpdateData() {
    this.favourite_integrations = [];
    this.integrations_page_visited = false;
  }
  return IntegrationPageDetailsUpdateData;
}();
exports.BulkOperationStatus = void 0;
(function (BulkOperationStatus) {
  BulkOperationStatus["Initiated"] = "initiated";
  BulkOperationStatus["InProgress"] = "in_progress";
  BulkOperationStatus["Complete"] = "complete";
  BulkOperationStatus["TimedOut"] = "timed_out";
  BulkOperationStatus["Failed"] = "failed";
})(exports.BulkOperationStatus || (exports.BulkOperationStatus = {}));
var BulkUpdatesResultRequest = /** @class */function () {
  function BulkUpdatesResultRequest() {
    this.limit = 10;
    this.filters = [];
    this.page = 1;
  }
  return BulkUpdatesResultRequest;
}();
exports.ViewType = void 0;
(function (ViewType) {
  ViewType["LIST_VIEW"] = "LIST_VIEW";
  ViewType["GRID_VIEW"] = "GRID_VIEW";
})(exports.ViewType || (exports.ViewType = {}));

exports.BulkDeleteMetadataRequest = BulkDeleteMetadataRequest;
exports.BulkDeleteRecordsRequest = BulkDeleteRecordsRequest;
exports.BulkUpdateMetadataRequest = BulkUpdateMetadataRequest;
exports.BulkUpdateRecordsRequest = BulkUpdateRecordsRequest;
exports.BulkUpdatesResultRequest = BulkUpdatesResultRequest;
exports.CustomView = CustomView;
exports.IntegrationPageDetailsUpdateData = IntegrationPageDetailsUpdateData;
exports.IntegrationPageDetailsUpdateRequest = IntegrationPageDetailsUpdateRequest;
exports.ListPagePostRequest = ListPagePostRequest;
