'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var Store = require('@zomentum/utils/dist/Store');
var index$2 = require('./reducers/index.js');
require('react');

var _a = Store.generateStore(index$2["default"]),
  withOnboardingProvider = _a.withProvider,
  onbordingStore = _a.store,
  useOnboardingDispatch = _a.useDispatch,
  useSelector = _a.useSelector;
var useOnboardingSelector = useSelector;

exports.onbordingStore = onbordingStore;
exports.useOnboardingDispatch = useOnboardingDispatch;
exports.useOnboardingSelector = useOnboardingSelector;
exports.withOnboardingProvider = withOnboardingProvider;
