import {
	convertEnumToSelectOptions,
	filterOutFormulaFields
} from "@zomentum/utils";
import { capitalizeFirstLetter } from "@zomentum/utils";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import {
	EditOrCloneProductDrawerMode,
	EProductSKUDrawerState,
	IProdctSKUDrawerProps
} from "./interface";
import ZUploadMediaWithPreview from "@organisms/ZForm/ZUploadMediaWithPreviewWidget";
import ZSKUPricingPlanFormWidget from "@templates/ZVendor/ZProductSKUs/molecules/ZSKUPricingPlanFormWidget";
import ZSKUPricingPlanFormBudgetWidget from "@templates/ZVendor/ZProductSKUs/molecules/ZSKUPricingPlanFormBudgetWidget";
import { ProductType, UOM } from "@zomentum/contracts/dist/Products";
import {
	SKUProduct,
	BundledSKUSProduct,
	BundlePricing,
	SKUProductFormValues,
	SKUProductRequest,
	SKUProductStatus
} from "@zomentum/contracts/dist/Vendor/SKU";
import { MarketplaceProduct } from "@zomentum/contracts/dist/Marketplace/Product";
import { getMarketplaceProductsCollections } from "@/Services/Vendor/MarketplaceListings";
import { addProductSKU, editProductSKU } from "@/Services/Vendor/SKU";
import {
	EWidgets,
	IFormFieldProps,
	IFormProps,
	IFormSectionProps
} from "@zomentum/organisms/dist/ZForm/interface";
import { EIcons } from "@zomentum/atoms/dist/ZIcon/interface";
import ZButton from "@zomentum/atoms/dist/ZButton";
import ZDrawer from "@zomentum/molecules/dist/ZDrawer";
import ZForm from "@zomentum/organisms/dist/ZForm";
import ZSKUPublishModal from "@templates/ZVendor/ZProductSKUs/molecules/ZSKUPublishModal";
import ZMessage from "@zomentum/molecules/dist/ZMessage";
import { CustomFieldType } from "@zomentum/contracts/dist/Settings";
import { processCustomFieldFormValues } from "@zomentum/utils";
import { ZIcon, ZTypography } from "@zomentum/atoms";
import { IOptionProps } from "@zomentum/atoms/dist/ZSelect/interface";

import ConfigProvider from "antd/lib/config-provider";
import ZTable from "@zomentum/organisms/dist/ZTable";
import BundledProductTableColumns from "@templates/ZDrawers/ZProducts/molecules/ZBundledProductTableColumns";
import { ReactComponent as EmptyBundleProductsIcon } from "@/Assets/ProductsPage/EmptyBundleProductsIcon.svg";

import AddProductSKUToQuoteBlockDrawer from "@pages/Vendor/Templates/TemplateDetailPage/Drawers/AddProduct";

import { ProductBillingType } from "@zomentum/contracts";
import ZCheckbox from "@zomentum/atoms/dist/ZCheckbox";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import ZRenderCustomFieldFormItem from "@zomentum/organisms/dist/ZRenderCustomFieldFormItem";
import Divider from "antd/lib/divider";
import moment from "moment";
import { Switch } from "antd";
import { useFeatureFlag } from "@zomentum/hooks/dist/hooks/useFeature";
import { useBundlesSettings } from "@zomentum/hooks";
import { useSelector } from "react-redux";
import { GlobalState } from "@/Reducers/interface";

const ProductSKUDrawer: React.FC<IProdctSKUDrawerProps> = ({
	visible,
	drawerState,
	mode,
	onClose,
	currentSKU,
	onAdd,
	publishOnly = false,
	showBundles = false,
	handleEditBundleProduct,
	bundleClone = false
}) => {
	const [form] = useForm<SKUProductFormValues>();

	const [loading, setLoading] = useState(false);

	const { customFieldList } = useSelector((state: GlobalState) => ({
		customFieldList: filterOutFormulaFields(
			state.customFields.customFieldList.marketplace_sku
		)
	}));

	const [marketplaceListings, setMarketplaceListings] =
		useState<MarketplaceProduct[]>();
	const [publishModalVisible, setPublishModalVisible] = useState(false);
	const [requiredFieldsFilled, setRequiredFieldsFilled] = useState(false);
	const [editSKUProduct, setEditSKUProduct] = useState<SKUProduct>();
	const [bundledSkus, setBundledSkus] = useState<BundledSKUSProduct[]>([]);
	const [showProductSearch, setShowProductSearch] = useState(false);

	const excludedTypes: string[] = showBundles
		? [ProductType.Other]
		: [ProductType.Bundle, ProductType.Other];

	const typeOptions = (
		convertEnumToSelectOptions(ProductType, "select") as IOptionProps[]
	)?.filter(option => !excludedTypes.includes(option.value.toString()));
	const uomOptions = convertEnumToSelectOptions(UOM, "select");
	const listingOptions =
		marketplaceListings
			?.filter(listing => listing.is_active)
			.map(marketplaceListing => ({
				value: marketplaceListing.id,
				name: marketplaceListing.name
			})) ?? [];

	const [createProductDrawerVisible, setShowCreateProductDrawerVisible] =
		useState(false);
	const {
		pricingDisabled,
		showChildItems,
		showQuantity,
		showPrice,
		setShowChildItems,
		setShowQuantity,
		setShowPrice,
		handleChangeChildItems,
		handleChangeQuantity,
		handleChangePrice
	} = useBundlesSettings();
	const enable_bundles_enchancements = useFeatureFlag(
		"enable_bundles_enchancements"
	);

	const onProductCollectionSelect = (value: string) => {
		if (value !== "none") {
			form.setFieldsValue({
				marketplace_listings: form
					.getFieldValue("marketplace_listings")
					.filter(value => value !== "none")
			});
			return;
		}
		form.setFieldsValue({
			marketplace_listings: ["none"]
		});
	};

	const handleUpdatePriceFields = (
		updateKey: string,
		value: string | number,
		identifier: string
	) => {
		const updatedBundleSkus = bundledSkus.map(item => {
			if (item.id === identifier) {
				const updatePricings = item.pricings[0];
				updatePricings[updateKey] = value;
				return { ...item, pricings: [updatePricings] };
			} else return item;
		});
		setBundledSkus([...updatedBundleSkus]);
	};

	const handleUpdateFields = (
		updateKey: string,
		value: number | boolean,
		identifier: string
	) => {
		if (updateKey === "removeSKU") {
			const filteredSkus = bundledSkus.filter(
				item => item.id !== identifier
			);
			setBundledSkus([...filteredSkus]);
		} else {
			const updatedBundleSkus = bundledSkus.map(item => {
				if (item.id === identifier) {
					const updatedProductDetails = item;
					updatedProductDetails[updateKey] = value;
					return updatedProductDetails;
				} else return item;
			});
			setBundledSkus([...updatedBundleSkus]);
		}
	};

	const handlePricingChange = (productId, pricingId) => {
		setBundledSkus(bundledProducts => {
			const findProduct = bundledProducts.find(
				item => item.id === productId
			);
			if (findProduct) {
				const findPricing = findProduct.pricingPlans?.find(
					pricing => pricing.id === pricingId
				);
				if (findPricing) {
					return bundledProducts.map(item => {
						if (findProduct.id === item.id)
							return {
								...item,
								selected_pricing_id: pricingId,
								pricings: [
									{
										cost_price: findPricing.cost_price,
										sell_price: findPricing.sell_price,
										setup_price: findPricing.setup_price,
										billing_period:
											findPricing.billing_period
									}
								]
							};
						return item;
					});
				} else return bundledProducts;
			} else return bundledProducts;
		});
	};

	const onEditDrawerOpen = val => setEditSKUProduct(val);

	const getBundledProductTableColumns = () =>
		BundledProductTableColumns({
			handleUpdatePriceFields: handleUpdatePriceFields,
			handleUpdateFields: handleUpdateFields,
			onEditDrawerOpen: onEditDrawerOpen,
			handlePricingChange: handlePricingChange
		});

	const customEmptyBundleProductsTable = () => {
		return (
			<div className="flex flex-col items-center justify-center">
				<EmptyBundleProductsIcon className="mb-3" />
				<ZTypography.ZText level={1} className="mb-2">
					You have no products to display in this bundle.
				</ZTypography.ZText>
				<ZTypography.ZText level={1}>
					Click on 'Add Product' button to add a product to this
					bundle
				</ZTypography.ZText>
			</div>
		);
	};

	const skuFormSections: IFormSectionProps[] = [
		{
			hideDivider: true,
			getMeta() {
				const meta = {
					formItemLayout: [6, 18],
					fields: [
						{
							key: "name",
							label: "Name",
							required: true,
							widget: EWidgets.INPUT,
							widgetProps: {
								placeholder: "Enter product name",
								className: "w-full"
							}
						},
						...customFieldList
							?.map(customField => {
								const ProductFormItem = getProductFormItem({
									customFieldId: customField.system_field_id
								});

								const values = currentSKU?.custom_fields?.find(
									field =>
										field.display_name ===
										customField.display_name
								)?.values;

								return (
									ProductFormItem ?? {
										key: `custom_client_form_item_${customField.id}`,
										render: () => (
											<ZRenderCustomFieldFormItem
												form={form}
												customField={customField}
												isLoading={loading}
												values={values}
											/>
										)
									}
								);
							})
							.flat()
					]
				};
				if (enable_bundles_enchancements) {
					if (form.getFieldValue("type") !== ProductType.Bundle) {
						meta.fields = meta.fields.filter(
							field => field.key !== "bundle_settings"
						);
					}

					if (
						form.getFieldValue("type") === ProductType.Bundle &&
						form.getFieldValue("bundle_settings")
					) {
						meta.fields = meta.fields.filter(
							field => field.key !== "pricings"
						);
					}
				} else {
					if (form.getFieldValue("type") !== ProductType.Bundle) {
						meta.fields = meta.fields.filter(
							field => field.key !== "show_individual_pricing"
						);
					}

					if (
						form.getFieldValue("type") === ProductType.Bundle &&
						form.getFieldValue("show_individual_pricing")
					) {
						meta.fields = meta.fields.filter(
							field => field.key !== "pricings"
						);
					}
				}
				return meta;
			}
		},
		{
			isFooterSection: true,
			meta: {
				fields: [
					{
						key: "cancelButton",
						render: () => (
							<ZButton onClick={handleClose}>Cancel</ZButton>
						)
					}
				]
			}
		}
	];

	const fetchMarketplaceListings = async () => {
		setLoading(true);
		try {
			const response = await getMarketplaceProductsCollections();
			setMarketplaceListings(response.data);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const onFinish = async (
		values: SKUProductFormValues,
		shouldPublish = false
	) => {
		setLoading(true);
		const marketplaceListings = values.marketplace_listings.includes("none")
			? []
			: values.marketplace_listings;

		let skuProductRequest = new SKUProductRequest(
			values.name,
			values.description,
			values.type,
			values.images,
			values.unit_of_measure,
			marketplaceListings,
			values.pricings,
			!!values.pricings?.length
				? values.pricings[0].should_add_setup_price_to_margin
				: false,
			values.bundled_skus,
			undefined,
			values.show_individual_pricing
		);
		if (enable_bundles_enchancements) {
			skuProductRequest = {
				...skuProductRequest,
				show_individual_pricing: showPrice,
				show_child_quantity: showQuantity,
				hide_child_items_in_quote_block: showChildItems
			};
		}
		const customFieldFormValues = values.custom_field_form_values;
		if (customFieldFormValues && !!customFieldList?.length) {
			skuProductRequest = {
				...skuProductRequest,
				custom_fields: processCustomFieldFormValues(
					customFieldFormValues,
					customFieldList
				)
			};
		}
		try {
			if (shouldPublish) {
				skuProductRequest.status = SKUProductStatus.PUBLISHED;
			}
			if (
				drawerState === EProductSKUDrawerState.ADD ||
				mode === EditOrCloneProductDrawerMode.CLONE
			) {
				await addProductSKU(skuProductRequest);
				mode === EditOrCloneProductDrawerMode.CLONE
					? ZMessage.success("Product cloned successfully")
					: ZMessage.success("Product added successfully");
			} else if (
				drawerState === EProductSKUDrawerState.EDITORCLONE &&
				mode === EditOrCloneProductDrawerMode.EDIT &&
				!!currentSKU
			) {
				if (bundleClone) {
					await handleEditBundleProduct?.({
						...currentSKU,
						...values
					});
				} else await editProductSKU(currentSKU.id, skuProductRequest);
				ZMessage.success("Product edited successfully");
			}

			onAdd?.();
			setPublishModalVisible(false);
			form.resetFields();
			onClose(true);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
			setShowChildItems(false);
			setShowQuantity(false);
			setShowPrice(false);
		}
	};

	const handleSubmit = async (shouldPublish = false) => {
		try {
			let values = await form.validateFields();
			if (values.type === ProductType.Bundle) {
				if (bundledSkus.length === 0) {
					ZMessage.error("Please add atleast 1 product to bundle");
					return;
				} else {
					const formatBundledSkus = bundledSkus.map(product => ({
						description: product.description,
						type: product.type,
						name: product.name,
						sku_id: product.id,
						selected_setup_price:
							product.pricings[0].setup_price ?? 0,
						selected_cost_price:
							product.pricings[0].cost_price ?? 0,
						selected_billing_period:
							product.pricings[0].billing_period ??
							ProductBillingType.OneTime,
						selected_pricing_id: product.selected_pricing_id,
						sell_price: product.pricings[0].sell_price ?? 0,
						quantity: product.quantity,
						is_optional: product.is_optional
					}));
					if (enable_bundles_enchancements) {
						if (showPrice) {
							values = {
								...values,
								pricings: form.getFieldValue("pricings") || [
									new BundlePricing()
								]
							};
						}
					} else {
						if (values.show_individual_pricing) {
							values = {
								...values,
								pricings: form.getFieldValue("pricings") || [
									new BundlePricing()
								]
							};
						}
					}
					values = {
						...values,
						should_add_setup_price_to_margin:
							values.pricings[0]
								?.should_add_setup_price_to_margin,
						bundled_skus: formatBundledSkus
					};
				}
			}
			onFinish(values, shouldPublish);
		} catch (error) {
			ZMessage.error("Fill required fields");
		}
	};

	const handlePublish = async () => {
		try {
			const values = await form.validateFields();
			if (values.type === ProductType.Bundle && bundledSkus.length === 0)
				ZMessage.error("Please add atleast 1 product to bundle");
			else {
				setPublishModalVisible(true);
			}
		} catch (error) {
			ZMessage.error("Fill required fields");
		}
	};

	const handleValuesChanges = (
		_changedValues: Partial<SKUProductFormValues>,
		allValues: SKUProductFormValues
	) => {
		if (
			!allValues.name?.length ||
			!allValues.description?.length ||
			!allValues.type?.length ||
			!allValues.unit_of_measure?.length ||
			(allValues.type !== ProductType.Bundle &&
				!allValues.pricings?.length)
		) {
			return setRequiredFieldsFilled(false);
		}
		if (allValues.type === ProductType.Bundle) {
			if (enable_bundles_enchancements) {
				if (pricingDisabled && !allValues.pricings?.length) {
					return setRequiredFieldsFilled(false);
				}
			} else {
				if (
					!allValues.show_individual_pricing &&
					!allValues.pricings?.length
				) {
					return setRequiredFieldsFilled(false);
				}
			}
		}
		return setRequiredFieldsFilled(true);
	};

	const formProps: IFormProps = {
		key: "ProductSKUForm",
		sections: skuFormSections,
		isStickFooter: true,
		form,
		submitButtonText: bundleClone ? "Save" : "Save And Publish",
		isRightFooter: true,
		isDynamic: true,
		isCancelAllowed: bundleClone ? false : !publishOnly,
		cancelButtonText: "Save",
		onCancel: () => handleSubmit(),
		shouldResetFormOnCancel: false,
		cancelButtonProps: {
			disabled: !requiredFieldsFilled
		},
		shouldDisableBeforeTouch: drawerState === EProductSKUDrawerState.ADD,
		submitButtonProps: {
			htmlType: "button",
			onClick: bundleClone ? handleSubmit : handlePublish,
			disabled: !requiredFieldsFilled
		},
		onFinish,
		loading,
		onValuesChange: handleValuesChanges
	};

	const getDrawerTitle = () => {
		switch (drawerState) {
			case EProductSKUDrawerState.EDITORCLONE:
				return `${capitalizeFirstLetter(mode)} Product`;
			default:
				return "Add Product";
		}
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	const handlePublishSKUClose = () => {
		setPublishModalVisible(false);
	};

	useEffect(() => {
		if (visible) {
			fetchMarketplaceListings();
			setRequiredFieldsFilled(
				drawerState === EProductSKUDrawerState.EDITORCLONE
			);
			if (drawerState === EProductSKUDrawerState.ADD) {
				setBundledSkus([]);
			}
		}
	}, [visible]);

	useEffect(() => {
		if (!currentSKU) return;

		if (currentSKU.type === ProductType.Bundle && currentSKU.bundled_skus)
			setBundledSkus(
				currentSKU.bundled_skus.map(product => ({
					description: product.description,
					name: product.name,
					id: product.sku_id,
					quantity: product.quantity,
					is_optional: product.is_optional,
					type: product?.marketplace_sku?.type || "",
					unit_of_measure: product?.marketplace_sku?.unit_of_measure,
					images: product?.marketplace_sku?.images,
					selected_pricing_id: product.selected_pricing_id,
					pricingPlans: product?.marketplace_sku?.pricings || [],
					marketplace_listings: product?.marketplace_sku
						?.marketplace_listings.length
						? product?.marketplace_sku.marketplace_listings.map(
								listing => listing.id || listing
						  )
						: ["none"],
					pricings: [
						{
							setup_price: product.selected_setup_price ?? 0,
							cost_price: product.selected_cost_price ?? 0,
							billing_period:
								product.selected_billing_period ??
								ProductBillingType.OneTime,
							sell_price: product.sell_price ?? 0
						}
					]
				}))
			);
		const initialFormValues = {
			name: currentSKU.name,
			description: currentSKU.description,
			type: currentSKU.type,
			images: currentSKU.images,
			unit_of_measure: currentSKU.unit_of_measure,
			marketplace_listings: !!currentSKU.marketplace_listings?.length
				? currentSKU.marketplace_listings?.map(
						listing => listing.id || listing
				  )
				: ["none"],
			show_individual_pricing: currentSKU.show_individual_pricing,
			pricings: currentSKU.pricings.map(pricing => {
				return {
					...pricing,
					should_add_setup_price_to_margin:
						currentSKU.should_add_setup_price_to_margin,
					id:
						mode === EditOrCloneProductDrawerMode.CLONE
							? undefined
							: pricing.id
				};
			}),
			custom_field_form_values: {}
		};
		currentSKU.custom_fields
			?.filter(field => !field.system_field_id)
			.forEach(field => {
				const isDateCustomField =
					field.field_type === CustomFieldType.DateField;
				const initialValue = field.values
					? isDateCustomField && field.values[0]
						? moment(field.values[0])
						: field.values[0]
					: undefined;
				initialFormValues.custom_field_form_values[field.id] =
					initialValue;
			});
		form.setFieldsValue(initialFormValues);

		if (enable_bundles_enchancements) {
			setShowChildItems(
				currentSKU.hide_child_items_in_quote_block ?? false
			);
			setShowPrice(currentSKU.show_individual_pricing ?? false);
			setShowQuantity(currentSKU.show_child_quantity ?? false);
		}
	}, [currentSKU, visible, customFieldList]);

	const bundledSkuDataSource = !!bundledSkus.length
		? bundledSkus.map((sku, index) => {
				return {
					...sku,
					index
				};
		  })
		: [];

	const handleDragEnd = ({ ...props }) => {
		const { oldIndex, newIndex } = props;
		if (oldIndex === newIndex) return;
		const newBundledSKUs = [...bundledSkus];
		const destinationIndex = newIndex ? newIndex : 0;
		const sourceIndex = oldIndex ? oldIndex : 0;
		const [removed] = newBundledSKUs.splice(sourceIndex, 1);
		newBundledSKUs.splice(destinationIndex, 0, removed);

		setBundledSkus(newBundledSKUs);
	};

	const getProductFormItem = ({
		customFieldId
	}: {
		customFieldId?: string | null;
	}): IFormFieldProps | IFormFieldProps[] | null => {
		switch (customFieldId) {
			case "marketplace_sku_type": {
				return [
					{
						key: "type",
						label: "Type",
						hasFeedback: true,
						initialValue: currentSKU?.type,
						required: true,
						message: "Please select Type",
						widget: EWidgets.SELECT,
						widgetProps: {
							placeholder: "Select type",
							selectOptions: typeOptions
						}
					},
					{
						key: "bundled_skus",
						label: "bundleType",
						render: () => {
							return (
								<>
									{form.getFieldValue("type") ===
										ProductType.Bundle && (
										<ConfigProvider
											renderEmpty={
												customEmptyBundleProductsTable
											}
										>
											<ZTable
												loading={false}
												scroll={{
													x: true,
													scrollToFirstRowOnChange:
														true
												}}
												size="small"
												columns={getBundledProductTableColumns()}
												dataSource={
													bundledSkuDataSource
												}
												pagination={false}
												className="mb-8 border-solid border-0 border-b-2 border-gray-5 sku-table"
												footer={() => {
													return (
														<div className="flex justify-center">
															<ZButton
																type="primary"
																ghost
																className="h-6"
																onClick={() =>
																	setShowProductSearch(
																		true
																	)
																}
															>
																Add Product
															</ZButton>
															{enable_bundles_enchancements && (
																<ZButton
																	type="primary"
																	ghost
																	className="h-6 ml-4"
																	onClick={() =>
																		setShowCreateProductDrawerVisible(
																			true
																		)
																	}
																>
																	Create
																	Products
																</ZButton>
															)}
														</div>
													);
												}}
												isDraggable={
													enable_bundles_enchancements &&
													bundledSkus.length > 1
												}
												onDragEnd={handleDragEnd}
												draggableRowInDrawers={true}
											/>
										</ConfigProvider>
									)}
								</>
							);
						}
					}
				];
			}
			case "marketplace_sku_product_collection": {
				return {
					key: "marketplace_listings",
					label: "Product Collection",
					hasFeedback: true,
					required: true,
					widget: EWidgets.SELECT,
					widgetProps: {
						placeholder: "Select product collection",
						selectOptions: [
							...listingOptions,
							{
								name: "None of the above",
								value: "none"
							}
						],
						mode: "multiple",
						onSelect: onProductCollectionSelect,
						showArrow: true
					},
					message: "Please select at least one product collection."
				};
			}
			case "marketplace_sku_unit_of_measure": {
				return {
					key: "unit_of_measure",
					label: "UOM",
					hasFeedback: true,
					required: true,
					message: "Please select unit of measure",
					widget: EWidgets.SELECT,
					widgetProps: {
						placeholder: "Select unit of measure",
						selectOptions: uomOptions
					}
				};
			}
			case "marketplace_sku_product_images": {
				return {
					key: "images",
					required: true,
					label: "Product Images",
					widget: ZUploadMediaWithPreview,
					widgetProps: {
						fileType: "image",
						uploadButtonText: "Upload",
						uploadButtonIcon: EIcons.UPLOAD,
						maxFileSize: 26214400
					}
				};
			}
			case "marketplace_sku_description": {
				const pricingsWidget =
					form.getFieldValue("type") !== ProductType.Bundle
						? ZSKUPricingPlanFormWidget
						: ZSKUPricingPlanFormBudgetWidget;
				let descriptionFields: IFormFieldProps[] = [
					{
						key: "description",
						label: "Description",
						hasFeedback: true,
						widget: EWidgets.INPUTTEXTAREA,
						className: "mb-4",
						required: true,
						message: "Please enter product description",
						widgetProps: {
							placeholder: "Enter product description",
							rows: 4,
							className: "mb-0"
						}
					},
					{
						key: "pricings",
						widget: pricingsWidget,
						required: true,
						message: "Pricing Plan is required",
						wrapperCol: { span: 24 },
						widgetProps: {
							bundledSkus,
							unitOfMeasure:
								form.getFieldValue("unit_of_measure"),
							disabled: pricingDisabled
						}
					},
					{
						key: "other_details",
						wrapperCol: { span: 24 },
						render: () => (
							<React.Fragment key="other_detail">
								<Divider className="mb-6" />
							</React.Fragment>
						)
					}
				];
				descriptionFields = [
					...descriptionFields?.slice(0, 1),
					enable_bundles_enchancements
						? {
								key: "bundle_settings",
								render: () => {
									return (
										<div className="mb-6 mt-6">
											<ZTypography.ZText className="mr-4">
												Show child items
											</ZTypography.ZText>
											<Switch
												onChange={
													handleChangeChildItems
												}
												size="small"
												checked={showChildItems}
											/>
											{showChildItems && (
												<>
													<div className="flex items-center mt-1">
														<ZIcon
															name={
																EIcons.INFOCIRCLE
															}
															size="sm"
															className="m-0"
														/>
														<ZTypography.ZText
															colorVariant={8}
															level={0}
															className="ml-2"
														>
															Please note that you
															will not be able to
															edit the product
															bundle pricing
															fields if the{" "}
															<ZTypography.ZText
																colorVariant={9}
																level={0}
															>
																Show Price
															</ZTypography.ZText>{" "}
															is toggled on.
														</ZTypography.ZText>
													</div>
													<div className="ml-9 mt-4">
														<ZTypography.ZText className="mr-4">
															Show quantity
														</ZTypography.ZText>
														<Switch
															onChange={
																handleChangeQuantity
															}
															size="small"
															checked={
																showQuantity
															}
														/>
														<div className="mb-2"></div>
														<ZTypography.ZText className="mr-9">
															Show price
														</ZTypography.ZText>
														<Switch
															onChange={
																handleChangePrice
															}
															size="small"
															checked={showPrice}
														/>
													</div>
												</>
											)}
										</div>
									);
								}
						  }
						: {
								key: "show_individual_pricing",
								className: "w-full mb-0",
								wrapperCol: { span: 24 },
								widget: ({ value, onChange }) => {
									return (
										<div
											className={`bg-gray-3 w-full p-2 ${
												value && "mb-6"
											}`}
										>
											<ZCheckbox
												checkboxOption="Show individual product pricing"
												checked={value}
												onChange={(
													e: CheckboxChangeEvent
												) => {
													onChange(e.target.checked);
												}}
											/>
										</div>
									);
								}
						  },
					...descriptionFields?.slice(1)
				];
				return descriptionFields;
			}
			default:
				return null;
		}
	};

	return (
		<ZDrawer
			title={getDrawerTitle()}
			visible={visible}
			onClose={handleClose}
			removeDefaultPadding
			closable
			destroyOnClose
		>
			<ZForm {...formProps} className="py-4" />
			<AddProductSKUToQuoteBlockDrawer
				visible={showProductSearch}
				onClose={() => setShowProductSearch(false)}
				onSubmitBundleProducts={values => {
					setBundledSkus(
						values.map(item => ({
							...item,
							quantity: 1,
							is_optional: false
						}))
					);
				}}
				addedProducts={bundledSkus}
				addNewProducts
			/>
			{!!editSKUProduct && (
				<ProductSKUDrawer
					currentSKU={editSKUProduct}
					visible={!!editSKUProduct}
					onClose={() => setEditSKUProduct(undefined)}
					bundleClone={true}
					drawerState={EProductSKUDrawerState.EDITORCLONE}
					mode={EditOrCloneProductDrawerMode.EDIT}
					handleEditBundleProduct={val => {
						setBundledSkus(
							bundledSkus.map(product => {
								if (product.id === val.id) {
									return val;
								}
								return product;
							})
						);
					}}
				/>
			)}
			<ZSKUPublishModal
				visible={publishModalVisible}
				onCancel={handlePublishSKUClose}
				onPublish={() => handleSubmit(true)}
				loading={loading}
				currentSKU={
					new SKUProduct(
						form.getFieldsValue(),
						marketplaceListings?.filter(listing =>
							form
								.getFieldsValue()
								?.marketplace_listings?.includes(listing.id)
						) ?? []
					)
				}
				showSaveButton={!publishOnly}
				onSave={() => handleSubmit()}
			/>
			<ProductSKUDrawer
				visible={createProductDrawerVisible}
				onClose={() => {
					setShowCreateProductDrawerVisible(false);
					setShowProductSearch(true);
				}}
				drawerState={EProductSKUDrawerState.ADD}
				showBundles
			/>
		</ZDrawer>
	);
};

export default ProductSKUDrawer;
