import {
	VendorPartnerActions,
	VendorPartnerActionTypes
} from "@/Actions/Vendor/Partner";
import { VendorPartner } from "@zomentum/contracts/dist/Vendor/Partner";
import { Reducer } from "redux";
import { CustomViewResponse, CustomView } from "@zomentum/contracts/dist/UI";

export class VendorPartnerState {
	isLoading = false;
	currentActivePartner: VendorPartner | null = null;
	readonly viewList: CustomViewResponse | null = null;
	readonly currentActiveView: CustomView | null = null;
	readonly isViewChanged: boolean = false;
	readonly editVendorPartnerDrawerOpen: boolean = false;
}

export const initialVendorPartnerState: VendorPartnerState =
	new VendorPartnerState();

export const VendorPartnerReducer: Reducer<
	VendorPartnerState,
	VendorPartnerActions
> = (
	state: VendorPartnerState = initialVendorPartnerState,
	action: VendorPartnerActions
) => {
	switch (action.type) {
		case VendorPartnerActionTypes.SHOW_VENDOR_PARTNER_LOADER:
			return {
				...state,
				isLoading: true
			};
		case VendorPartnerActionTypes.HIDE_VENDOR_PARTNER_LOADER:
			return {
				...state,
				isLoading: false
			};
		case VendorPartnerActionTypes.VENDOR_PARTNER_DETAILS_SUCCESSFUL:
			return {
				...state,
				currentActivePartner: action.currentActivePartner
			};
		case VendorPartnerActionTypes.FETCH_ALL_PARTNER_VIEWS_REQUESTED: {
			return state;
		}
		case VendorPartnerActionTypes.FETCH_ALL_PARTNER_VIEWS_SUCCESSFUL: {
			return {
				...state,
				viewList: action.viewList
			};
		}
		case VendorPartnerActionTypes.FETCH_ALL_PARTNER_VIEWS_FAILED: {
			return state;
		}
		case VendorPartnerActionTypes.CREATE_PARTNER_VIEW_REQUESTED: {
			return state;
		}
		case VendorPartnerActionTypes.CREATE_PARTNER_VIEW_SUCCESSFUL: {
			return {
				...state,
				currentActiveView: action.view,
				viewList: state.viewList
					? {
							...state.viewList,
							data: [...(state.viewList?.data ?? []), action.view]
					  }
					: state.viewList,
				isViewChanged: false
			};
		}
		case VendorPartnerActionTypes.CREATE_PARTNER_VIEW_FAILED: {
			return state;
		}
		case VendorPartnerActionTypes.UPDATE_PARTNER_VIEW_REQUESTED: {
			return state;
		}
		case VendorPartnerActionTypes.UPDATE_PARTNER_VIEW_SUCCESSFUL: {
			return {
				...state,
				currentActiveView: action.view,
				viewList: state.viewList
					? {
							...state.viewList,
							data: state.viewList?.data?.map(view =>
								view.id === action.view.id ? action.view : view
							)
					  }
					: state.viewList,
				isViewChanged: false
			};
		}
		case VendorPartnerActionTypes.UPDATE_PARTNER_VIEW_FAILED: {
			return state;
		}
		case VendorPartnerActionTypes.DELETE_PARTNER_VIEW_REQUESTED: {
			return state;
		}
		case VendorPartnerActionTypes.DELETE_PARTNER_VIEW_SUCCESSFUL: {
			return {
				...state,
				currentActiveView: action.initialView,
				viewList: state.viewList
					? {
							...state.viewList,
							data: state.viewList?.data?.filter(
								view => view.id !== action.deletedView.id
							)
					  }
					: state.viewList,
				isViewChanged: false
			};
		}
		case VendorPartnerActionTypes.DELETE_PARTNER_VIEW_FAILED: {
			return state;
		}
		case VendorPartnerActionTypes.UPDATE_PARTNER_CURRENT_ACTIVE_VIEW: {
			return {
				...state,
				currentActiveView: action.currentActiveView,
				isViewChanged: action.isViewChanged
			};
		}
		case VendorPartnerActionTypes.RESET_PARTNER_CURRENT_ACTIVE_VIEW: {
			return {
				...state,
				currentActiveView: null,
				isViewChanged: false,
				viewList: null,
				viewMetadata: null
			};
		}
		case VendorPartnerActionTypes.EDIT_VENDOR_PARTNER_DRAWER_OPENED: {
			return {
				...state,
				editVendorPartnerDrawerOpen: true
			};
		}
		case VendorPartnerActionTypes.EDIT_VENDOR_PARTNER_DRAWER_CLOSED: {
			return {
				...state,
				editVendorPartnerDrawerOpen: false
			};
		}
		default:
			return state;
	}
};

export default VendorPartnerReducer;
