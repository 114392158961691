import axios, { AxiosResponse } from "axios";

import {
	getRequest,
	postRequest,
	putRequest
} from "@zomentum/utils/dist/Service/BackendService";
import {
	DeletePipelineOrStageRequest,
	DeletePipelineOrStageResponse,
	Pipeline,
	PipelineListResponse,
	ReorderOpportunitiesRequest
} from "@zomentum/contracts/dist/Pipelines";

const getPipelineListUrl = "/v2/pipelines";
const reorderPipelineUrl = "/v2/pipelines/reorder/{pipeline_id}";
const deletePipelineOrStageUrl = "/v2/pipelines/async_delete";

export const getPipelines = async (): Promise<PipelineListResponse> => {
	try {
		const response: AxiosResponse<PipelineListResponse> = await axios(
			getRequest(getPipelineListUrl, null, true, false, false)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deletePipelineOrStage = async (
	data: DeletePipelineOrStageRequest
): Promise<DeletePipelineOrStageResponse> => {
	try {
		const response: AxiosResponse<DeletePipelineOrStageResponse> =
			await axios(
				postRequest(deletePipelineOrStageUrl, data, true, false, false)
			);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const reorderPipeline = async ({
	opportunityStages,
	pipelineId
}: ReorderOpportunitiesRequest): Promise<Pipeline> => {
	try {
		const response: AxiosResponse<Pipeline> = await axios(
			putRequest(
				reorderPipelineUrl.replace("{pipeline_id}", pipelineId),
				{ opportunity_stages: opportunityStages },
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
