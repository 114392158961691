import React, { useEffect, useState } from "react";
import ZChangeSuggestionUpdatesModal from "@zomentum/organisms/dist/ZChangeSuggestionUpdates/ZChangeSuggestionUpdatesModal";
import { IChangeSuggestionModal } from "./interface";
import {
	ChangeSuggestion,
	ChangeSuggestionStatus,
	Client
} from "@zomentum/contracts";
import ZMessage from "@zomentum/molecules/dist/ZMessage";
import {
	getChangeSuggestion,
	updateChangeSuggestionsStatus
} from "@zomentum/utils/dist/Service/ChangeSuggestions";

const ChangeSuggestionModal: React.FC<IChangeSuggestionModal> = ({
	id,
	onClose
}) => {
	const [changeSuggestion, setChangeSuggestion] =
		useState<ChangeSuggestion | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const onCancelViewChangeSuggestionUpdatesModal = () => {
		setChangeSuggestion(null);
		onClose();
	};
	const acceptChangeSuggestion = async () => {
		try {
			await updateChangeSuggestionsStatus(changeSuggestion?.id ?? "", {
				status: ChangeSuggestionStatus.Accepted
			});
			ZMessage.success("You have successfully updated Client Details");
			setChangeSuggestion(null);
		} catch (error) {
			console.error(error);
		}
	};
	const rejectChangeSuggestion = async () => {
		try {
			await updateChangeSuggestionsStatus(changeSuggestion?.id ?? "", {
				status: ChangeSuggestionStatus.Rejected
			});
			setChangeSuggestion(null);
		} catch (error) {
			console.error(error);
		}
	};
	const fetchChangeSuggestion = async () => {
		try {
			setIsLoading(true);
			const changeSuggestion = await getChangeSuggestion(id);
			if (changeSuggestion.status === ChangeSuggestionStatus.Accepted)
				ZMessage.error(
					"This change suggestion has already been accepted!"
				);
			else if (
				changeSuggestion.status === ChangeSuggestionStatus.Rejected
			)
				ZMessage.error(
					"This change suggestion has already been rejected!"
				);
			else setChangeSuggestion(changeSuggestion);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};
	useEffect(() => {
		fetchChangeSuggestion();
	}, []);
	return (
		<ZChangeSuggestionUpdatesModal
			isLoading={isLoading}
			visible={!!changeSuggestion}
			changeSuggestion={changeSuggestion}
			onCancel={onCancelViewChangeSuggestionUpdatesModal}
			onAccept={acceptChangeSuggestion}
			onReject={rejectChangeSuggestion}
			clientCompanyName={
				(changeSuggestion?.parent_entity as Client)?.name
			}
		/>
	);
};

export default ChangeSuggestionModal;
