import React, { Fragment, useEffect, useState } from "react";
import ZDynamicSelect from "@zomentum/molecules/dist/ZDynamicSelect";
import { IPartnerOptionProps, IPartnerSelectProps } from "./interface";
import { IOptionGroupProps } from "@zomentum/atoms/dist/ZSelect/interface";
import {
	VendorPartner,
	VendorPartnerStatus,
	GetVendorPartnersRequest
} from "@zomentum/contracts/dist/Vendor/Partner";
import { ZTag, ZIcon, ZModal } from "@zomentum/atoms";
import { EColors } from "@zomentum/atoms/dist/ZColors/interface";
import { EIcons } from "@zomentum/atoms/dist/ZIcon/interface";
import {
	TableQueryFilter,
	TableQueryFilterOperands
} from "@zomentum/contracts";
import { ChildEntityHydrationParams } from "@zomentum/contracts/dist/Utils";
import { getAllPartnersForTier } from "@/Services/Vendor/Partner";
import "./index.less";
import { ZTypography } from "@zomentum/atoms";
import { EFontWeights } from "@zomentum/atoms/dist/ZTypography/interface";
import { isArray } from "lodash";

const ZVendorPartnerSelect: React.FC<IPartnerSelectProps> = props => {
	const {
		defaultOptions,
		className,
		onChange,
		optGroup,
		showTierTag = false,
		showSuspendedPartners,
		...rest
	} = props;
	const getPartnerOptionsWithTierTag = (partners: VendorPartner[]) => [
		...partners.map(partner => ({
			name: (
				<Fragment>
					{partner.name}{" "}
					{partner.tier_detail && (
						<ZTag color={EColors.GREEN} className="ml-2">
							{partner.tier_detail?.name} Tier
						</ZTag>
					)}
				</Fragment>
			),
			value: partner.id,
			tierDetails: partner.tier_detail,
			title: partner.name
		}))
	];
	const getPartnerOptionsWithSuspendedTag = (partners: VendorPartner[]) => [
		...partners.map(partner => ({
			name: (
				<Fragment>
					{partner.name}{" "}
					{partner.status === VendorPartnerStatus.SUSPENDED && (
						<>
							<ZTag color={"error"} className="ml-2 mr-2">
								Suspended
							</ZTag>
							<ZTypography.ZText colorVariant={7}>
								Unsuspend partner to share opportunity with them
							</ZTypography.ZText>
						</>
					)}
				</Fragment>
			),
			value: partner.id,
			title: partner.name,
			disabled: partner.status === VendorPartnerStatus.SUSPENDED
		}))
	];
	const makePartnerDefaultOptions = () => {
		const formattedDefaultOptions = showTierTag
			? getPartnerOptionsWithTierTag(defaultOptions)
			: showSuspendedPartners
			? getPartnerOptionsWithSuspendedTag(defaultOptions)
			: defaultOptions.map(currOption => ({
					name: currOption.name,
					value: currOption.id
			  })) ?? [];

		setPartners(formattedDefaultOptions);
	};
	useEffect(() => {
		makePartnerDefaultOptions();
	}, [defaultOptions, showSuspendedPartners, showTierTag]);

	const onSearch = (value: string) => {
		if (!value.length) {
			makePartnerDefaultOptions();
		}
	};

	const [partners, setPartners] = useState<IPartnerOptionProps[]>([]);

	const searchPartners = async (value: string) => {
		try {
			const getVendorPartnersRequestFilters: TableQueryFilter[] = [
				new TableQueryFilter(
					showSuspendedPartners
						? TableQueryFilterOperands.MongoDbMatches
						: TableQueryFilterOperands.MongoDbEqualTo,
					"status",
					showSuspendedPartners
						? `${VendorPartnerStatus.ACCEPTED},${VendorPartnerStatus.SUSPENDED}`
						: VendorPartnerStatus.ACCEPTED,
					null,
					true
				)
			];
			if (!!props.excludeTierId) {
				getVendorPartnersRequestFilters.push(
					new TableQueryFilter(
						TableQueryFilterOperands.MongoDbNotEqualTo,
						"tier_ids",
						props.excludeTierId,
						null,
						true
					)
				);
			}
			const getVendorPartnersRequest = new GetVendorPartnersRequest(
				getVendorPartnersRequestFilters,
				value,
				showTierTag
					? [ChildEntityHydrationParams.PARTNER_TIERS_ALL]
					: [],
				100
			);
			const result = await getAllPartnersForTier(
				getVendorPartnersRequest
			);
			const searchResult = result.data.map(partner => {
				return {
					name: partner.name,
					value: partner.id
				};
			});
			setPartners(
				showTierTag
					? getPartnerOptionsWithTierTag(result.data)
					: showSuspendedPartners
					? getPartnerOptionsWithSuspendedTag(result.data)
					: searchResult
			);
		} catch (error) {
			console.error(error);
		}
	};
	const partnerTagRender = (props: any) => {
		const { label, onClose, closable } = props;
		return (
			<ZTag
				closeIcon={
					<ZIcon
						name={EIcons.CANCEL}
						color={EColors.GEEKBLUE}
						colorVariant={6}
						size="xs"
					/>
				}
				className="text-geekBlue-6 border-geekBlue-3 bg-geekBlue-1"
				closable={closable}
				onClose={onClose}
			>
				{label}
			</ZTag>
		);
	};

	const optionsGroup: IOptionGroupProps[] = [
		{
			label: "Partners Please type at least 3 letters to start searching partners",
			selectOptions: partners
		}
	];

	if (optGroup) optionsGroup.unshift(...optGroup);

	const handleChange = (
		selectedPartnerIds: React.ReactText | React.ReactText[],
		options: IPartnerOptionProps | IPartnerOptionProps[]
	) => {
		if (!isArray(selectedPartnerIds)) {
			onChange?.(selectedPartnerIds);
		} else {
			const partnerOptions = options as IPartnerOptionProps[];
			const selectedOption = partnerOptions[partnerOptions.length - 1];
			if (
				rest.value === undefined ||
				(isArray(rest.value) &&
					rest.value.length < partnerOptions.length)
			) {
				if (selectedOption.tierDetails) {
					ZModal.confirm({
						title: (
							<ZTypography.ZText
								colorVariant={9}
								weight={EFontWeights.MEDIUM}
								level={2}
							>
								{`Change Tier of ${selectedOption.title}?`}
							</ZTypography.ZText>
						),
						content: (
							<ZTypography.ZText colorVariant={8} level={1}>
								{`${selectedOption.title} exists in the ${selectedOption.tierDetails.name} Adding partner to this tier will change the partner tier and access to previous tier resources will be withdrawn. `}
							</ZTypography.ZText>
						),
						onOk: () => onChange?.(selectedPartnerIds),
						okText: "Confirm Selection",
						okType: "primary",
						icon: (
							<ZIcon
								name={EIcons.CIRCLEQUESTION}
								color={EColors.GOLD}
								colorVariant={6}
								className="anticon"
								size="md"
							/>
						),
						width: "600px",
						centered: true
					});
				} else {
					onChange?.(selectedPartnerIds);
				}
			} else {
				onChange?.(selectedPartnerIds);
			}
		}
	};

	return (
		<ZDynamicSelect<React.ReactText | React.ReactText[]>
			defaultOptions={partners}
			fetchData={searchPartners}
			options={partners}
			onChange={handleChange}
			className={`${className} partner-select-wrap`}
			tagRender={partnerTagRender}
			isOptionsGroup={!!optGroup?.length}
			optionsGroup={optionsGroup}
			onSearch={onSearch}
			{...rest}
		/>
	);
};

export default ZVendorPartnerSelect;
