'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../../node_modules/tslib/tslib.es6.mjs.js');
var index = require('../actions/index.js');

var initialState = {
  currentGuideProgress: {
    activeModule: null,
    activeStage: null,
    activeStep: null
  },
  checkListBarData: null,
  moduleOrder: [],
  clientDetail: null,
  documentTemplate: null,
  onboardingMaps: null,
  isProgressInitialized: false
};
var OnboardingStateReducer = function OnboardingStateReducer(state, action) {
  if (state === void 0) {
    state = initialState;
  }
  switch (action.type) {
    case index.OnboardingStateActionTypes.UPDATE_CURRENT_GUIDE_PROGRESS:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          currentGuideProgress: action.currentGuideProgress
        });
      }
    case index.OnboardingStateActionTypes.UPDATE_PROGRESS_INITIALIZATION:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          isProgressInitialized: action.isProgressInitialized
        });
      }
    case index.OnboardingStateActionTypes.UPDATE_MODULE_ORDER:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          moduleOrder: action.moduleOrder
        });
      }
    case index.OnboardingStateActionTypes.UPDATE_ONBOARDING_MAPS:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          onboardingMaps: action.onboardingMaps
        });
      }
    case index.OnboardingStateActionTypes.CHECKLIST_BAR_DATA_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          checkListBarData: action.checkListBarData
        });
      }
    case index.OnboardingStateActionTypes.DUMMY_CLIENT_DATA_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          clientDetail: action.clientDetail
        });
      }
    case index.OnboardingStateActionTypes.DOCUMENT_TEMPLATE_DATA_SUCCESSFUL:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          documentTemplate: action.documentTemplate
        });
      }
    default:
      {
        return state;
      }
  }
};
var OnboardingStateReducer$1 = OnboardingStateReducer;

exports["default"] = OnboardingStateReducer$1;
exports.initialState = initialState;
