import { ZAlert, ZButton, ZTypography } from "@zomentum/atoms";
import { ZModal } from "@zomentum/atoms";
import { EIcons } from "@zomentum/atoms/dist/ZIcon/interface";
import {
	CustomFieldType,
	PendingOpportunitySharedWithVendor,
	VendorClient,
	VendorClientEditOrAddRequest,
	ZomentumRoutes
} from "@zomentum/contracts";
import { useComponentDidUpdate } from "@zomentum/hooks";
import { Col, Row, Skeleton } from "antd";
import React, { useState } from "react";
import { IViewOpportunityRequestModalProps } from "./interface";
import ZEntityDetailsTable from "./ZEntityDetailsTable";
import { IEntityDetailsTableRecord } from "./ZEntityDetailsTable/interface";
import { customDateFormatter } from "@zomentum/utils";
import useDatePattern from "@zomentum/hooks/dist/hooks/useDatePattern";
import ZClientConflictModal from "./ZClientConflictModal";
import "./index.less";
import {
	getShippingAddressRowText,
	isVendorApp
} from "@zomentum/utils/dist/common";
import ZRejectRequestModal from "./ZRejectRequestModal";
import { useHistory } from "react-router-dom";

const ZViewOpportunityRequestModal: React.FC<
	IViewOpportunityRequestModalProps
> = ({
	visible,
	onCancelViewOpportunityRequestModal,
	pendingOpportunityId,
	getPendingOpportunity,
	checkConflictForPendingOpportunity,
	acceptPendingOpportunity,
	rejectPendingOpportunity,
	createClient,
	disableTransition
}) => {
	const history = useHistory();
	const datePattern = useDatePattern();
	const [isLoading, setIsLoading] = useState(false);
	const [pendingOpportunity, setPendingOpportunity] =
		useState<PendingOpportunitySharedWithVendor | null>(null);
	const [conflictedVendorClient, setConflictedVendorClient] =
		useState<VendorClient | null>(null);
	const [showClientConflictModal, setShowClientConflictModal] =
		useState(false);
	const [showRejectRequestModal, setShowRejectRequestModal] = useState(false);
	const fetchPendingOpportunity = async () => {
		try {
			setIsLoading(true);
			const pendingOpportunity = await getPendingOpportunity(
				pendingOpportunityId
			);
			setPendingOpportunity(pendingOpportunity);
			if (!pendingOpportunity.existing_vendor_client_id) {
				const checkConflictForPendingOpportunityResponse =
					await checkConflictForPendingOpportunity(
						pendingOpportunityId
					);
				if (!!checkConflictForPendingOpportunityResponse.data.length) {
					const conflictedVendorClient =
						checkConflictForPendingOpportunityResponse.data[0];
					setConflictedVendorClient(conflictedVendorClient);
				}
			}
		} catch (error) {
			onCancelViewOpportunityRequestModal();
		} finally {
			setIsLoading(false);
		}
	};
	useComponentDidUpdate(() => {
		if (visible) {
			fetchPendingOpportunity();
		}
	}, [visible]);
	const getPartnerDetailsRecords = () => {
		const partnerDetailsRecords: IEntityDetailsTableRecord[] = [
			{
				field: "Partner Name",
				value: pendingOpportunity?.partner_company.name ?? ""
			},
			{
				field: "Partner Manager",
				value:
					pendingOpportunity?.partner_company.partner_manager?.name
						.full ?? ""
			},
			{
				field: "Tier for Partner",
				value:
					pendingOpportunity?.partner_company.tier_detail?.name ?? ""
			}
		];
		return partnerDetailsRecords;
	};
	const getClientDetailsRecords = () => {
		const clientDetailsRecords: IEntityDetailsTableRecord[] = [
			{
				field: "Client Name",
				value: pendingOpportunity?.client_company?.name ?? ""
			}
		];
		if (pendingOpportunity?.client_company?.billing_address) {
			clientDetailsRecords.push({
				field: "Billing Address",
				value: getShippingAddressRowText(
					pendingOpportunity.client_company.billing_address
				)
			});
		}

		pendingOpportunity?.client_company?.vendor_custom_fields.forEach(
			customField => {
				clientDetailsRecords.push({
					field: customField.display_name,
					value: customField.values?.[0] ?? ""
				});
			}
		);
		return clientDetailsRecords;
	};
	const getOpportunityDetailsRecords = () => {
		const opportunityDetailsRecords: IEntityDetailsTableRecord[] = [
			{
				field: "Opportunity Name",
				value: pendingOpportunity?.name ?? ""
			},
			{
				field: `Opportunity Type${isVendorApp() ? ` (Partner)` : ""}`,
				value: pendingOpportunity?.opportunity_type ?? ""
			},
			{
				field: "Opportunity Stage of Partner",
				value: pendingOpportunity?.opportunity_stage ?? ""
			},
			{
				field: "Estimated Value",
				value: `${pendingOpportunity?.estimated_value ?? ""}`
			},
			{
				field: "Estimated Close Date",
				value: pendingOpportunity?.estimated_close_date
					? customDateFormatter(
							pendingOpportunity.estimated_close_date as Date,
							datePattern
					  )
					: "--"
			}
		];
		pendingOpportunity?.vendor_custom_fields?.forEach(customField => {
			const formattedValue =
				customField?.field_type === CustomFieldType.DateField
					? customDateFormatter(
							new Date(customField?.values?.[0]),
							datePattern
					  )
					: customField?.values?.[0] ?? "";
			let fieldName = customField.display_name;
			if (
				customField.system_field_id ===
					"vendor_opportunity_opportunity_type" &&
				isVendorApp()
			) {
				fieldName += ` (Vendor)`;
			}
			opportunityDetailsRecords.push({
				field: fieldName,
				value: formattedValue
			});
		});
		return opportunityDetailsRecords;
	};
	const onAcceptRequest = async () => {
		try {
			setIsLoading(true);
			if (!!pendingOpportunity?.duplicate_opportunity_id) {
				const redirectToDuplicateOpportunity = () => {
					history.push(
						ZomentumRoutes.OpportunityDetail.replace(
							":id",
							pendingOpportunity.duplicate_opportunity_id ?? ""
						)
					);
					duplicateOpportunityModal.destroy();
				};
				const duplicateOpportunityModal = ZModal.warning({
					title: "Opportunity with same name already exists",
					content: (
						<>
							An opportunity with the same name{" "}
							<ZTypography.ZLink
								onClick={redirectToDuplicateOpportunity}
							>
								{pendingOpportunity.name}
							</ZTypography.ZLink>{" "}
							already exists in your CRM. Please update the name
							of the existing opportunity{" "}
							<ZTypography.ZLink
								onClick={redirectToDuplicateOpportunity}
							>
								{pendingOpportunity.name}
							</ZTypography.ZLink>{" "}
							to proceed further.
						</>
					),
					width: 600
				});
			} else if (conflictedVendorClient) {
				setShowClientConflictModal(true);
			} else {
				if (!!pendingOpportunity?.existing_vendor_client_id) {
					await acceptPendingOpportunity(
						pendingOpportunity.existing_vendor_client_id
					);
				} else {
					const createClientRequest: VendorClientEditOrAddRequest = {
						name: pendingOpportunity?.client_company?.name ?? "",
						partner_manager_id: null,
						owner_user_id: null,
						fax: pendingOpportunity?.client_company?.fax ?? "",
						website:
							pendingOpportunity?.client_company?.website ?? "",
						linked_in_url:
							pendingOpportunity?.client_company?.linked_in_url ??
							"",
						facebook_url:
							pendingOpportunity?.client_company?.facebook_url ??
							"",
						twitter_url:
							pendingOpportunity?.client_company?.twitter_url ??
							"",
						company_type:
							pendingOpportunity?.client_company?.company_type ??
							pendingOpportunity?.client_company
								?.company_types?.[0] ??
							null,
						billing_address:
							pendingOpportunity?.client_company
								?.billing_address ?? null
					};
					createClientRequest.custom_fields =
						pendingOpportunity?.client_company
							?.vendor_custom_fields ?? [];
					const newClient = await createClient(createClientRequest);
					await acceptPendingOpportunity(newClient.id);
				}
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};
	const onRejectRequest = async (rejectReason: string) => {
		try {
			setIsLoading(true);
			await rejectPendingOpportunity(pendingOpportunityId, rejectReason);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
			setShowRejectRequestModal(false);
		}
	};

	useComponentDidUpdate(() => {
		if (!visible) {
			setShowClientConflictModal(false);
			setConflictedVendorClient(null);
		}
	}, [visible]);
	return (
		<>
			<ZModal
				className="z-view-opportunity-request-modal"
				visible={visible}
				size="xl"
				onCancel={onCancelViewOpportunityRequestModal}
				title={
					isLoading ? (
						<span className="text-gray-1">Loading</span>
					) : (
						pendingOpportunity?.name
					)
				}
				okText="Accept Request"
				transitionName={disableTransition ? "" : undefined}
				mask={disableTransition ? false : true}
				cancelText="Reject Request"
				cancelButtonProps={{
					danger: true
				}}
				footer={[
					<ZButton
						type="link"
						key="back-link"
						onClick={onCancelViewOpportunityRequestModal}
						iconName={EIcons.ARROWLEFT}
						loading={isLoading}
						className="p-0"
					>
						Back
					</ZButton>,
					<div key="empty-div" className="flex-1" />,
					<ZButton
						key="reject-request-button"
						onClick={() => setShowRejectRequestModal(true)}
						className="border-red-6 bg-gray-1 hover:bg-red-6"
						danger
						loading={isLoading}
					>
						Reject Request
					</ZButton>,
					<ZButton
						key="accept-request-button"
						onClick={onAcceptRequest}
						type="primary"
						loading={isLoading}
					>
						Accept Request
					</ZButton>
				]}
			>
				<Skeleton loading={isLoading}>
					{conflictedVendorClient && (
						<ZAlert
							className="mb-4"
							showIcon={true}
							type="warning"
							message={`Another client with the same name ${conflictedVendorClient.name} already exists in your CRM.`}
						/>
					)}
					<Row gutter={16}>
						<Col span={12}>
							<ZEntityDetailsTable
								title="Partner Details"
								records={getPartnerDetailsRecords()}
								className="mb-4"
								labelWidth={200}
							/>
							<ZEntityDetailsTable
								title="Client Details"
								records={getClientDetailsRecords()}
								labelWidth={200}
							/>
						</Col>
						<Col span={12}>
							<ZEntityDetailsTable
								title="Opportunity Details"
								records={getOpportunityDetailsRecords()}
							/>
						</Col>
					</Row>
				</Skeleton>
			</ZModal>
			{conflictedVendorClient && pendingOpportunity && (
				<ZClientConflictModal
					visible={showClientConflictModal}
					conflictedVendorClient={conflictedVendorClient}
					pendingOpportunity={pendingOpportunity}
					onCancel={() => setShowClientConflictModal(false)}
					acceptPendingOpportunity={acceptPendingOpportunity}
					createClient={createClient}
				/>
			)}
			{pendingOpportunity && (
				<ZRejectRequestModal
					visible={showRejectRequestModal}
					onCancel={() => setShowRejectRequestModal(false)}
					pendingOpportunity={pendingOpportunity}
					onReject={onRejectRequest}
					isLoading={isLoading}
				/>
			)}
		</>
	);
};

export default ZViewOpportunityRequestModal;
