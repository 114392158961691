import React, { ReactText, useCallback, useEffect, useState } from "react";
import {
	addVendorClient,
	getAllVendorClientContacts,
	getClientListPagePost
} from "@/Services/Vendor/Client";
import ZVendorClientContactSelect from "@organisms/ZClientContactSelect";
import ZVendorClientSelect from "@organisms/ZClientSelect";
import {
	ChildEntityHydrationParams,
	ClientUserContactType,
	ListPagePostRequest,
	TableQueryFilter,
	VendorClient,
	VendorClientContact,
	VendorClientContactRequest,
	VendorClientEditOrAddRequest,
	VendorOpportunity
} from "@zomentum/contracts";
import { ZMessage } from "@zomentum/molecules";
import { FormInstance } from "antd";
import AddOrEditClientDrawer from "@pages/Vendor/Clients/Drawers/AddOrEditClient/index";
import AddOrEditClientContact from "@pages/Vendor/Clients/Drawers/AddOrEditClientContact/index";
import { IClientAndContactDrawerReturnType } from "./interface";

/**
 * a custom hook to render client and contact field in opportunity drawers
 * @param form antd FormInstace
 * @param visible visiblity of the drawer using the hook
 * @param currentActiveOpportunity active opportunity if being used while editing opportunity
 * @returns IClientAndContactDrawerReturnType - object with form fields to render and state related to form fields
 */
const useClientAndContactsDrawer = (
	form: FormInstance<any>,
	visible: boolean,
	currentActiveOpportunity?: VendorOpportunity | null
): IClientAndContactDrawerReturnType => {
	const [clients, setClients] = useState<VendorClient[]>([]);
	const [selectedClient, setSelectedClient] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);
	const [initialPrimaryContact, setInitialPrimaryContact] =
		useState<string>();

	const [
		currentActiveClientPrimaryContacts,
		setCurrentActiveClientPrimaryContacts
	] = useState<VendorClientContact[] | []>([]);

	const [currentActiveClientContacts, setCurrentActiveClientContacts] =
		useState<VendorClientContact[] | []>([]);

	const [
		currentActiveClientLinkedContacts,
		setCurrentActiveClientLinkedContacts
	] = useState<VendorClientContact[] | []>([]);

	const [isAddClientDrawerVisible, setIsAddClientDrawerVisible] =
		useState(false);

	const [showAddClientContactDrawer, setShowAddClientContactDrawer] =
		useState<boolean>(false);

	const showClientDrawer = () => {
		setIsAddClientDrawerVisible(true);
	};

	const handleAddVendorClientContactDrawer = () => {
		if (!selectedClient) {
			ZMessage.error("Please select a Client first");
			return;
		}
		setShowAddClientContactDrawer(true);
	};

	const handleCloseVendorClientContactDrawer = () => {
		setShowAddClientContactDrawer(false);
	};

	const handleClientChange = async (value: string) => {
		setSelectedClient(value);
	};

	const handlePrimaryContactSelect = useCallback(
		(contactId: string) => {
			const updatedLinkedContacts = currentActiveClientContacts.filter(
				contact => contact.id !== contactId
			);
			let updatedPrimaryContacts = currentActiveClientContacts.filter(
				contact => contact.id === contactId
			);
			updatedPrimaryContacts = [
				...updatedPrimaryContacts,
				...updatedLinkedContacts.filter(
					contact =>
						!form
							.getFieldValue(`linked_client_user_ids`)
							?.includes(contact.id)
				)
			];
			setCurrentActiveClientPrimaryContacts(updatedPrimaryContacts);
			setCurrentActiveClientLinkedContacts(updatedLinkedContacts);
		},
		[currentActiveClientContacts]
	);

	useEffect(() => {
		if (!currentActiveOpportunity) {
			const primary = currentActiveClientContacts.find(
				contact =>
					contact.contact_type == ClientUserContactType.PrimaryContact
			);
			setInitialPrimaryContact(primary?.id);
			setCurrentActiveClientLinkedContacts(
				currentActiveClientLinkedContacts.filter(
					contact => contact.id !== primary?.id
				)
			);
		}
	}, [currentActiveClientContacts]);

	const fetchClients = async () => {
		setLoading(true);
		try {
			const clientResponse = await getClientListPagePost(
				new ListPagePostRequest({
					count_query_flag: true,
					filter_criterion: [],
					sort_criterion: [],
					page_no: 1,
					page_size: 10,
					includedChildEntities: [
						ChildEntityHydrationParams.VENDOR_CLIENT_COUNT
					]
				})
			);
			if (currentActiveOpportunity?.vendor_client_company) {
				setClients([
					...clientResponse.data.filter(
						value =>
							value.id !==
							currentActiveOpportunity?.vendor_client_company?.id
					),
					currentActiveOpportunity?.vendor_client_company
				]);
			} else {
				setClients(clientResponse.data);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const handleLinkedContactSelect = (value: string) => {
		const selectedContactIds = value.split(",");
		const updatedPrimaryContacts = currentActiveClientContacts.filter(
			contact => !selectedContactIds.includes(contact.id)
		);
		setCurrentActiveClientPrimaryContacts(updatedPrimaryContacts);
	};

	const resetClientContacts = () => {
		form.setFieldsValue({
			primary_client_user_id: undefined,
			linked_client_user_ids: []
		});
	};

	const handleResetClient = () => {
		setSelectedClient(null);
		setCurrentActiveClientContacts([]);
		setCurrentActiveClientLinkedContacts([]);
		setCurrentActiveClientPrimaryContacts([]);
	};

	const handleAddVendorClient = async (
		addClientRequest: VendorClientEditOrAddRequest
	) => {
		try {
			const addVendorClientResponse = await addVendorClient(
				addClientRequest
			);
			fetchClients();
			form.setFieldsValue({
				vendor_client_company_id: addVendorClientResponse.id
			});
			setSelectedClient(addVendorClientResponse.id);
			resetClientContacts();
		} catch (error) {
			console.error(error);
		}
	};

	const fetchClientContacts = useCallback(async () => {
		if (!selectedClient?.length) {
			ZMessage.error("Please select a Client first");
			return;
		}
		const clientContactRequest: VendorClientContactRequest = {
			filters: new Array<TableQueryFilter>(),
			page: 1,
			limit: 25,
			clientId: selectedClient
		};
		try {
			const clientContactResponse = await getAllVendorClientContacts(
				clientContactRequest
			);
			const contacts = clientContactResponse.data;

			setCurrentActiveClientContacts(contacts);
			const primaryContacts = contacts.filter(
				contact =>
					contact.contact_type ===
						ClientUserContactType.PrimaryContact ||
					contact.id ===
						currentActiveOpportunity?.primary_client_user_id
			);
			const linkedContacts = contacts.filter(
				contact =>
					contact.contact_type ===
						ClientUserContactType.SecondaryContact &&
					contact.id !==
						currentActiveOpportunity?.primary_client_user_id
			);
			const shouldPopulatePrimaryClientId = !!contacts.filter(
				contact =>
					contact.id ===
					currentActiveOpportunity?.primary_client_user_id
			).length;

			const shouldPopulateLinkedClientId =
				contacts.filter(contact =>
					currentActiveOpportunity?.linked_client_user_ids?.includes(
						contact.id
					)
				).length ===
				currentActiveOpportunity?.linked_client_user_ids?.length;

			let primaryClientUserId: string | undefined = undefined;
			if (
				!!currentActiveOpportunity?.primary_client_user_id?.length &&
				shouldPopulatePrimaryClientId
			) {
				primaryClientUserId =
					currentActiveOpportunity?.primary_client_user_id;
			} else {
				primaryClientUserId = primaryContacts.find(
					contact =>
						contact.contact_type ===
						ClientUserContactType.PrimaryContact
				)?.id;
			}
			setInitialPrimaryContact(primaryClientUserId);
			let linkedClientUserId: string[] = [];
			if (
				!!currentActiveOpportunity?.linked_client_user_ids?.length &&
				shouldPopulateLinkedClientId
			) {
				linkedClientUserId =
					currentActiveOpportunity?.linked_client_user_ids;
			}
			form.setFieldsValue({
				linked_client_user_ids: linkedClientUserId
			});
			setCurrentActiveClientPrimaryContacts([
				...primaryContacts.filter(
					contact => !linkedClientUserId.includes(contact.id)
				),
				...linkedContacts.filter(
					contact => !linkedClientUserId.includes(contact.id)
				)
			]);

			setCurrentActiveClientLinkedContacts([
				...linkedContacts.filter(
					contact => contact.id !== primaryClientUserId
				),
				...primaryContacts.filter(
					contact => contact.id !== primaryClientUserId
				)
			]);
		} catch (error) {
			console.error(error);
		}
	}, [selectedClient, currentActiveOpportunity]);

	const handleClientContactRefresh = async () => {
		try {
			await fetchClientContacts();
		} catch (error) {
			console.error(error);
		}
	};

	const renderClientSelectField = (
		value: ReactText,
		onChange: (value: ReactText) => void,
		disabled?: boolean
	) => (
		<ZVendorClientSelect
			value={value}
			onChange={value => {
				onChange(value as ReactText);
				handleClientChange(value + "");
			}}
			defaultOptions={clients}
			placeholder="Select client"
			showClientDrawer={showClientDrawer}
			disabled={disabled}
		/>
	);

	const renderPrimaryContactField = (
		value: ReactText,
		onChange: (value: ReactText) => void
	) => (
		<ZVendorClientContactSelect
			value={value}
			onChange={value => {
				handlePrimaryContactSelect(value + "");
				onChange(value as ReactText);
			}}
			defaultOptions={currentActiveClientPrimaryContacts}
			placeholder="Please choose primary contact"
			isMultiSelect={false}
			selectedClient={selectedClient}
			showAddClientContactDrawer={handleAddVendorClientContactDrawer}
		/>
	);

	const renderLinkedContactsField = (
		value: ReactText,
		onChange: (value: ReactText) => void
	) => (
		<ZVendorClientContactSelect
			value={value}
			onChange={value => {
				handleLinkedContactSelect(value + "");
				onChange(value as ReactText);
			}}
			defaultOptions={currentActiveClientLinkedContacts}
			placeholder="Please choose linked contacts"
			isMultiSelect={true}
			selectedClient={selectedClient}
			showAddClientContactDrawer={handleAddVendorClientContactDrawer}
		/>
	);

	const clientAndContactDrawers = (
		<>
			<AddOrEditClientDrawer
				visible={isAddClientDrawerVisible}
				currentActiveVendorClient={null}
				onClose={() => setIsAddClientDrawerVisible(false)}
				handleAddVendorClient={handleAddVendorClient}
			/>
			<AddOrEditClientContact
				visible={showAddClientContactDrawer}
				currentActiveVendorClientContact={null}
				onClose={handleCloseVendorClientContactDrawer}
				handleClientContactRefresh={handleClientContactRefresh}
				initalVendorClientContact={!currentActiveClientContacts.length}
				selectedClient={selectedClient}
			/>
		</>
	);

	useEffect(() => {
		if (!visible) return;
		fetchClients();
	}, [visible]);

	useEffect(() => {
		if (!!selectedClient?.length && visible) {
			fetchClientContacts();
		}
	}, [selectedClient, visible]);

	return {
		clients,
		loading,
		renderClientSelectField,
		renderPrimaryContactField,
		renderLinkedContactsField,
		clientAndContactDrawers,
		handleResetClient,
		selectedClient,
		setSelectedClient,
		initialPrimaryContact
	};
};

export default useClientAndContactsDrawer;
