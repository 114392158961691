import React from "react";
import { currencyInputProps } from "@zomentum/utils/dist/Currency";
import ZPopInput from "@zomentum/molecules/dist/ZPopInput";
import { Row, Col } from "antd";

import {
	ProductBillingType,
	productBillingTypeOptions
} from "@zomentum/contracts/dist/Products";

import { ColumnType } from "antd/lib/table";
import { Menu } from "antd";
import ZIcon, { Icons } from "@zomentum/atoms/dist/ZIcon";
import {
	IBundledProductTableColumnsProps,
	PricingNameInputInterface
} from "./interface";
import { BundledSKUSProduct } from "@zomentum/contracts";
import { ZTag } from "@zomentum/atoms";
import { ZDropdown } from "@zomentum/molecules";

import { EditOutlined, EnterOutlined } from "@ant-design/icons";
import { Radio } from "antd";
import { ZPopover } from "@zomentum/atoms";

import { RadioChangeEvent } from "antd/es/radio/interface";

import { ZRadioGroup } from "@zomentum/atoms";
import "./index.less";

interface BundledProductRowFormOptionsProps {
	readonly toggleBundleProductOptional: () => void;
	readonly deleteBundleProductChild: () => void;
	readonly onEditDrawerOpen: () => void;
	readonly isReadonly?: boolean;
	readonly isOptional?: boolean;
	readonly enableOnlyDelete?: boolean;
}

export const ProductRowDropDown: React.FC<
	BundledProductRowFormOptionsProps
> = ({
	isReadonly,
	isOptional,
	onEditDrawerOpen,
	deleteBundleProductChild,
	toggleBundleProductOptional,
	...props
}) => (
	<Menu {...props}>
		{!props.enableOnlyDelete && (
			<Menu.Item onClick={onEditDrawerOpen}>
				{" "}
				{isReadonly ? "View Product" : "Edit Product"}
			</Menu.Item>
		)}

		{!isReadonly && !props.enableOnlyDelete && (
			<Menu.Item onClick={toggleBundleProductOptional}>
				{isOptional ? "Remove it as optional" : "Make Optional"}
			</Menu.Item>
		)}

		{!isReadonly && (
			<Menu.Item
				className="text-red-4"
				onClick={deleteBundleProductChild}
			>
				Remove
			</Menu.Item>
		)}
	</Menu>
);

export const BundledProductRowFormOptions: React.FC<
	BundledProductRowFormOptionsProps
> = props => {
	return (
		<ZDropdown overlay={<ProductRowDropDown {...props} />}>
			<ZIcon
				data-testid="more-options-dropdown-trigger"
				name={Icons.MOREVERTICAL}
				size="sm"
				fill
			/>
		</ZDropdown>
	);
};

export const PricingNameInput: React.FC<PricingNameInputInterface> = ({
	selectedPricingId,
	pricingArray,
	onChange,
	disabled
}) => {
	const selectedPricing = pricingArray?.find(a => a.id === selectedPricingId);

	const handleChange = (event: RadioChangeEvent) => {
		onChange(event.target.value);
	};
	const content = (
		<ZRadioGroup
			onChange={handleChange}
			value={selectedPricingId}
			className="flex flex-col  w-40"
			disabled={!!disabled}
		>
			{pricingArray?.map(p => (
				<Radio
					key={p.id || undefined}
					value={p.id}
					className={`radio-popover-pricing-plans`}
				>
					{p.name || "Default Pricing"}
				</Radio>
			))}
		</ZRadioGroup>
	);

	return selectedPricing ? (
		<>
			{!!pricingArray && pricingArray.length > 1 && (
				<ZPopover
					placement="rightTop"
					title={null}
					content={content}
					trigger="hover"
					className="block"
				>
					<div className="inline-block text-0 text-gray-7">
						<EnterOutlined className="mr-1  text-0" />
						{selectedPricing?.name || "Default Pricing"}
						<EditOutlined className="ml-1" />
					</div>
				</ZPopover>
			)}

			{!!pricingArray && pricingArray.length === 1 && (
				<div className="inline-block  text-gray-7">
					<EnterOutlined className="mr-1" />
					{selectedPricing?.name || "Default Pricing"}
				</div>
			)}
		</>
	) : (
		<></>
	);
};

export interface BundledSKUSProductIndex extends BundledSKUSProduct {
	index: number;
}

export default ({
	handleUpdateFields,
	onEditDrawerOpen,
	handleUpdatePriceFields,
	handlePricingChange
}: IBundledProductTableColumnsProps): ColumnType<BundledSKUSProductIndex>[] => [
	{
		title: "Product name",
		dataIndex: "name",
		key: "name",
		width: 300,
		className: "drag-visible-z-table-row",
		render: (name: "string", product) => {
			const { setup_price, billing_period } = product.pricings[0];

			return (
				<div className="bundled-product-row-name-container">
					{billing_period &&
						billing_period !== ProductBillingType.OneTime && (
							<ZTag color="blue">
								{productBillingTypeOptions[billing_period]}{" "}
								Recurring
							</ZTag>
						)}
					{product.is_optional && (
						<ZTag
							color="#eff1f7"
							className="optional-tag"
							style={{ color: "#5670a1" }}
						>
							Optional
						</ZTag>
					)}
					<div className="product-name">{name}</div>
					<PricingNameInput
						pricingArray={product.pricingPlans}
						selectedPricingId={product.selected_pricing_id}
						onChange={pricingId =>
							handlePricingChange(product?.id || "", pricingId)
						}
					/>
					<Row className="mt-3 items-center">
						<Col span={6}>
							<span>Setup Fee:</span>
						</Col>
						<Col offset={1} span={9}>
							<ZPopInput
								value={setup_price || 0}
								min={0}
								onChange={value => {
									if (product.id)
										handleUpdatePriceFields(
											"setup_price",
											value,
											product.id
										);
								}}
								inputAlign="left"
								{...currencyInputProps}
								decimal
							/>
						</Col>
					</Row>
				</div>
			);
		}
	},
	{
		title: "Cost Price",
		dataIndex: "selected_cost_price",
		key: "cost_price",
		className: "drag-visible-z-table-row",
		render: (_text, product) => {
			const { cost_price } = product.pricings[0];
			return (
				<div>
					<ZPopInput
						value={cost_price || 0}
						min={0}
						onChange={value => {
							if (product.id)
								handleUpdatePriceFields(
									"cost_price",
									value,
									product.id
								);
						}}
						{...currencyInputProps}
						decimal
					/>
				</div>
			);
		}
	},
	{
		title: "Selling Price",
		dataIndex: "sell_price",
		key: "sell_price",
		className: "drag-visible-z-table-row",
		render: (_text, product) => {
			const { sell_price } = product.pricings[0];
			return (
				<div>
					<ZPopInput
						value={sell_price || 0}
						min={0}
						{...currencyInputProps}
						onChange={value => {
							if (product.id)
								handleUpdatePriceFields(
									"sell_price",
									value,
									product.id
								);
						}}
						decimal
					/>
				</div>
			);
		}
	},
	{
		title: "Quantity",
		dataIndex: "quantity",
		key: "quantity",
		className: "drag-visible-z-table-row",
		render: (_text, product) => {
			const { quantity } = product;
			return (
				<div>
					<ZPopInput
						value={quantity || 1}
						min={1}
						onChange={value => {
							if (product.id)
								handleUpdateFields(
									"quantity",
									Number(value),
									product.id
								);
						}}
					/>
				</div>
			);
		}
	},
	{
		title: "",
		dataIndex: "name",
		key: "options",
		render: (_text: string, product) => (
			<div>
				<BundledProductRowFormOptions
					isOptional={product.is_optional}
					deleteBundleProductChild={() => {
						if (product.id)
							handleUpdateFields("removeSKU", false, product.id);
					}}
					onEditDrawerOpen={() => onEditDrawerOpen(product)}
					toggleBundleProductOptional={() => {
						if (product.id)
							handleUpdateFields(
								"is_optional",
								!product.is_optional,
								product.id
							);
					}}
				/>
			</div>
		)
	}
];
