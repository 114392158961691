'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index = require('../../Documents/index.js');

// export enum VendorTemplateThumbnailType {
// 	PROPOSAL = "proposal",
// 	CONTRACT = "contract",
// 	QUOTE = "quote"
// }
var VendorName = /** @class */function () {
  function VendorName() {
    this.first = "";
    this.last = "";
    this.full = "";
  }
  return VendorName;
}();
var Vendor = /** @class */function () {
  function Vendor(name) {
    this.name = name;
  }
  return Vendor;
}();
exports.VendorTemplateStatus = void 0;
(function (VendorTemplateStatus) {
  VendorTemplateStatus["DRAFT"] = "draft";
  VendorTemplateStatus["PUBLISHED"] = "published";
})(exports.VendorTemplateStatus || (exports.VendorTemplateStatus = {}));
var VendorTemplate = /** @class */function () {
  function VendorTemplate(id, name, vendor, status, document_type, updated, created) {
    this.id = "";
    this.name = "";
    this.document_type = index.DocumentType.PROPOSAL;
    this.id = id;
    this.name = name;
    this.vendor = vendor;
    this.status = status;
    this.document_type = document_type;
    this.created = created;
    this.updated = updated;
  }
  return VendorTemplate;
}();
var VendorTemplateCount = /** @class */function () {
  function VendorTemplateCount() {
    this.all = 0;
  }
  return VendorTemplateCount;
}();
var VendorTemplateResponse = /** @class */function () {
  function VendorTemplateResponse() {
    this.data = new Array();
    this.filtered_count_map = new VendorTemplateCount();
  }
  return VendorTemplateResponse;
}();
exports.VendorTemplateMenuItems = void 0;
(function (VendorTemplateMenuItems) {
  VendorTemplateMenuItems["PREVIEW"] = "Preview";
  VendorTemplateMenuItems["EDIT"] = "Edit";
  VendorTemplateMenuItems["CLONE"] = "Clone";
  VendorTemplateMenuItems["DELETE"] = "Delete";
})(exports.VendorTemplateMenuItems || (exports.VendorTemplateMenuItems = {}));
exports.VendorTemplateSidePanelEnum = void 0;
(function (VendorTemplateSidePanelEnum) {
  VendorTemplateSidePanelEnum["EDIT"] = "EDIT";
  VendorTemplateSidePanelEnum["SETTINGS"] = "SETTINGS";
})(exports.VendorTemplateSidePanelEnum || (exports.VendorTemplateSidePanelEnum = {}));
var LOADING_BLOCK_PLACEHOLDER = "LOADING_BLOCK";

exports.LOADING_BLOCK_PLACEHOLDER = LOADING_BLOCK_PLACEHOLDER;
exports.Vendor = Vendor;
exports.VendorName = VendorName;
exports.VendorTemplate = VendorTemplate;
exports.VendorTemplateCount = VendorTemplateCount;
exports.VendorTemplateResponse = VendorTemplateResponse;
