'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.EFontWeights = void 0;
(function (EFontWeights) {
  EFontWeights["REGULAR"] = "normal";
  EFontWeights["MEDIUM"] = "medium";
  EFontWeights["SEMIBOLD"] = "semibold";
})(exports.EFontWeights || (exports.EFontWeights = {}));
