import axios, { AxiosResponse } from "axios";

import { postRequest } from "@zomentum/utils/dist/Service/BackendService";

import {
	AuthToken,
	EmailVerificationResponse,
	LoginRequest,
	ResendEmailVerificationRequest,
	SignupRequest
} from "@zomentum/contracts/dist/Login";
import { UserData } from "@zomentum/contracts/dist/Users";

const loginUrl = "/v2/users/login";
const signupUrl = "/v2/users/signup";
const resendVerifyEmailUrl = "/v2/users/email/verify";

export const login = async (loginRequest: LoginRequest): Promise<AuthToken> => {
	try {
		const response: AxiosResponse<AuthToken> = await axios(
			postRequest(loginUrl, loginRequest, false, false, false)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const signup = async (
	signupRequest: SignupRequest
): Promise<UserData> => {
	try {
		const response: AxiosResponse<UserData> = await axios(
			postRequest(signupUrl, signupRequest, false, false, false)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const resendVerifyEmail = async (
	resendEmailVerificationRequest: ResendEmailVerificationRequest
): Promise<EmailVerificationResponse> => {
	try {
		const response: AxiosResponse<EmailVerificationResponse> = await axios(
			postRequest(
				resendVerifyEmailUrl,
				resendEmailVerificationRequest,
				false,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
