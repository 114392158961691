'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index$1 = require('../../Products/index.js');
var index = require('../../Marketplace/Product/index.js');

var MarketplaceProductPricingTrialPeriod = /** @class */function () {
  function MarketplaceProductPricingTrialPeriod(noOfDays) {
    this.no_of_days = null;
    this.no_of_days = noOfDays;
  }
  return MarketplaceProductPricingTrialPeriod;
}();
var AddMarketplaceProductPricingRequest = /** @class */function () {
  function AddMarketplaceProductPricingRequest() {
    this.name = "";
    this.additional_benefits = null;
    this.features = [];
    this.uom = index.MarketplaceProductPricingUOM.USER;
    this.plan_details = new index.MarketplaceProductPricingRequest();
    this.trial_period = null;
    this.billing_period = index$1.ProductBillingType.OneTime;
  }
  return AddMarketplaceProductPricingRequest;
}();
var generateMarketplaceProductPricingRequest = function generateMarketplaceProductPricingRequest(pricingPlan) {
  var _a, _b;
  var request = new AddMarketplaceProductPricingRequest();
  request.name = pricingPlan.name;
  request.additional_benefits = pricingPlan.additional_benefits;
  request.features = pricingPlan.features;
  request.uom = pricingPlan.uom;
  request.billing_period = pricingPlan.billing_period;
  !!((_a = pricingPlan === null || pricingPlan === void 0 ? void 0 : pricingPlan.id) === null || _a === void 0 ? void 0 : _a.length) && (request.id = pricingPlan.id);
  var getPlanDetailsRequest = function getPlanDetailsRequest() {
    if (pricingPlan.plan_details.plan_type === index.MarketplacePricingPlanType.FLAT_PRICING) {
      var flatPricingPlan = new index.MarketplaceProductPricingFlatPricingRequest();
      flatPricingPlan.cost_detail = pricingPlan.plan_details.data.cost_detail;
      return {
        plan_type: pricingPlan.plan_details.plan_type,
        data: flatPricingPlan
      };
    } else {
      var tierPricingPlan = new index.MarketplaceProductPricingTierRequest();
      tierPricingPlan.tier_details = pricingPlan.plan_details.data.tier_details;
      return {
        plan_type: pricingPlan.plan_details.plan_type,
        data: tierPricingPlan
      };
    }
  };
  request.plan_details = getPlanDetailsRequest();
  request.trial_period = ((_b = pricingPlan === null || pricingPlan === void 0 ? void 0 : pricingPlan.trial_period) === null || _b === void 0 ? void 0 : _b.days) ? new MarketplaceProductPricingTrialPeriod(pricingPlan === null || pricingPlan === void 0 ? void 0 : pricingPlan.trial_period.days) : null;
  return request;
};
var MarketplaceProductRequest = /** @class */function () {
  function MarketplaceProductRequest() {
    this.name = "";
    this.description = "";
    this.category = null;
    this.sub_category = [];
    this.thumbnail = null;
    this.images = [];
    this.videos = [];
    this.screenshots = [];
    this.billing_terms = index.MarketplaceProductBillingTerms.USAGE;
    this.hipaa_status = false;
    this.pci_status = false;
    this.gdpr_status = false;
    this.key_points = [];
    this.pricings = [];
  }
  return MarketplaceProductRequest;
}();

exports.AddMarketplaceProductPricingRequest = AddMarketplaceProductPricingRequest;
exports.MarketplaceProductRequest = MarketplaceProductRequest;
exports.generateMarketplaceProductPricingRequest = generateMarketplaceProductPricingRequest;
