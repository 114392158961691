import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import sysend from "sysend";

if (!process.env.REACT_APP_BROADCAST_CHANNEL) {
	throw new Error("missing env variable: REACT_APP_BROADCAST_CHANNEL ");
}

const updateServiceWorker = (registration: ServiceWorkerRegistration) => {
	if (registration.waiting) {
		navigator.serviceWorker.oncontrollerchange = () => {
			try {
				if (!process.env.REACT_APP_BROADCAST_CHANNEL) {
					throw new Error(
						"missing env variable: REACT_APP_BROADCAST_CHANNEL "
					);
				}
				sysend.broadcast(process.env.REACT_APP_BROADCAST_CHANNEL, {
					updateApp: true
				});
				window.location.reload();
			} catch (error) {
				console.error(error);
			}
		};
		registration.waiting?.postMessage("skipWaiting");
	}
};

sysend.on(process.env.REACT_APP_BROADCAST_CHANNEL, (data: any) => {
	if (data.updateApp) {
		window.location.reload();
	}
});

ReactDOM.render(
	<App updateServiceWorker={updateServiceWorker} />,
	document.getElementById("root")
);

if (module.hot && process.env.NODE_ENV === "development") {
	module.hot.accept("./App", () => {
		const HMRApp = require("./App").default;
		ReactDOM.render(
			<HMRApp updateServiceWorker={updateServiceWorker} />,
			document.getElementById("root")
		);
	});
}

serviceWorker.register({
	onUpdate: registration => {
		const $customEvent = new CustomEvent("updateServiceWorker", {
			detail: { registration }
		});
		document.dispatchEvent($customEvent);
	}
});
