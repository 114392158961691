import { Action, ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { GlobalState } from "@/Reducers/interface";
import {
	Auth0LoginState,
	ResendEmailVerificationRequest,
	SignupRequest
} from "@zomentum/contracts/dist/Login";

import { resendVerifyEmail, signup } from "@/Services/Login";
import { cache } from "@zomentum/contracts";
import { Auth0ContextInterface } from "@auth0/auth0-react";

export enum LoginActionTypes {
	SHOW_LOGIN_LOADER = "SHOW_LOGIN_LOADER",
	HIDE_LOGIN_LOADER = "HIDE_LOGIN_LOADER",
	LOGIN_REQUESTED = "LOGIN_REQUESTED",
	LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL",
	LOGIN_FAILED = "LOGIN_FAILED",
	LOGIN_SESSION_SUCCESSFUL = "LOGIN_SESSION_SUCCESSFUL",
	LOGIN_AUTH0_SUCCESSFUL = "LOGIN_AUTH0_SUCCESSFUL",
	SIGNUP_REQUESTED = "SIGNUP_REQUESTED",
	SIGNUP_SUCCESSFUL = "SIGNUP_SUCCESSFUL",
	SIGNUP_FAILED = "SIGNUP_FAILED",
	LOGOUT_SUCCESSFUL = "LOGOUT_SUCCESSFUL",
	REFRESH_TOKEN_SUCCESSFUL = "REFRESH_TOKEN_SUCCESSFUL",
	REFRESH_TOKEN_FAILED = "REFRESH_TOKEN_FAILED",
	RESEND_EMAIL_VERIFICATION_REQUESTED = "RESEND_EMAIL_VERIFICATION_REQUESTED",
	RESEND_EMAIL_VERIFICATION_SUCCESSFUL = "RESEND_EMAIL_VERIFICATION_SUCCESSFUL",
	RESEND_EMAIL_VERIFICATION_FAILED = "RESEND_EMAIL_VERIFICATION_FAILED",
	LOGIN_AUTH0_STATE_RECEIVED = "LOGIN_AUTH0_STATE_RECEIVED"
}

export type ShowLoginLoaderAction = Action<LoginActionTypes.SHOW_LOGIN_LOADER>;
export type HideLoginLoaderAction = Action<LoginActionTypes.HIDE_LOGIN_LOADER>;
export type LoginRequestedAction = Action<LoginActionTypes.LOGIN_REQUESTED>;
export type LoginSuccessfulAction = Action<LoginActionTypes.LOGIN_SUCCESSFUL>;
export type LoginFailedAction = Action<LoginActionTypes.LOGIN_FAILED>;
export type LoginSessionSuccessfulAction =
	Action<LoginActionTypes.LOGIN_SESSION_SUCCESSFUL>;
export type LoginAuth0SuccessfulAction =
	Action<LoginActionTypes.LOGIN_AUTH0_SUCCESSFUL>;
export type LogoutSuccessfulAction = Action<LoginActionTypes.LOGOUT_SUCCESSFUL>;
export type RefreshTokenSuccessfulAction =
	Action<LoginActionTypes.REFRESH_TOKEN_SUCCESSFUL>;
export type RefreshTokenFailedAction =
	Action<LoginActionTypes.REFRESH_TOKEN_FAILED>;
export type SignupRequestedAction = Action<LoginActionTypes.SIGNUP_REQUESTED>;
export type SignupSuccessfulAction = Action<LoginActionTypes.SIGNUP_SUCCESSFUL>;
export type SignupFailedAction = Action<LoginActionTypes.SIGNUP_FAILED>;
export type ResendEmailVerificationRequestedAction =
	Action<LoginActionTypes.RESEND_EMAIL_VERIFICATION_REQUESTED>;
export type ResendEmailVerificationSuccessfulAction =
	Action<LoginActionTypes.RESEND_EMAIL_VERIFICATION_SUCCESSFUL>;
export type ResendEmailVerificationFailedAction =
	Action<LoginActionTypes.RESEND_EMAIL_VERIFICATION_FAILED>;

export interface LoginAuth0StateReceivedAction
	extends Action<LoginActionTypes.LOGIN_AUTH0_STATE_RECEIVED> {
	readonly auth0LoginState: Auth0LoginState;
}

export type LoginActions =
	| ShowLoginLoaderAction
	| HideLoginLoaderAction
	| LoginRequestedAction
	| LoginSuccessfulAction
	| LoginFailedAction
	| LoginSessionSuccessfulAction
	| LoginAuth0SuccessfulAction
	| LogoutSuccessfulAction
	| RefreshTokenSuccessfulAction
	| RefreshTokenFailedAction
	| SignupRequestedAction
	| SignupSuccessfulAction
	| SignupFailedAction
	| ResendEmailVerificationRequestedAction
	| ResendEmailVerificationSuccessfulAction
	| ResendEmailVerificationFailedAction
	| LoginAuth0StateReceivedAction;

export interface LoginDispatch {
	showLoginLoader: ActionCreator<
		ThunkAction<
			ShowLoginLoaderAction,
			GlobalState,
			null,
			ShowLoginLoaderAction
		>
	>;
	hideLoginLoader: ActionCreator<
		ThunkAction<
			HideLoginLoaderAction,
			GlobalState,
			null,
			HideLoginLoaderAction
		>
	>;
	loginFromSession: ActionCreator<
		ThunkAction<
			LoginSessionSuccessfulAction,
			GlobalState,
			null,
			LoginSessionSuccessfulAction
		>
	>;
	loginFromAuth0: ActionCreator<
		ThunkAction<
			LoginAuth0SuccessfulAction,
			GlobalState,
			null,
			LoginAuth0SuccessfulAction
		>
	>;
	logout: ActionCreator<
		ThunkAction<
			LogoutSuccessfulAction,
			GlobalState,
			null,
			LogoutSuccessfulAction
		>
	>;
	refreshTokenSuccessful: ActionCreator<
		ThunkAction<
			RefreshTokenSuccessfulAction,
			GlobalState,
			null,
			RefreshTokenSuccessfulAction
		>
	>;
	refreshTokenFailed: ActionCreator<
		ThunkAction<
			RefreshTokenFailedAction,
			GlobalState,
			null,
			RefreshTokenFailedAction
		>
	>;
	signup: ActionCreator<
		ThunkAction<
			Promise<SignupSuccessfulAction>,
			GlobalState,
			SignupRequest,
			SignupSuccessfulAction
		>
	>;
	resendVerifyEmail: ActionCreator<
		ThunkAction<
			Promise<ResendEmailVerificationSuccessfulAction>,
			GlobalState,
			ResendEmailVerificationRequest,
			ResendEmailVerificationSuccessfulAction
		>
	>;
	auth0LoginStateReceived: ActionCreator<
		ThunkAction<
			LoginAuth0StateReceivedAction,
			GlobalState,
			Auth0LoginState,
			LoginAuth0StateReceivedAction
		>
	>;
}

export const LoginActionCreators: LoginDispatch = {
	showLoginLoader: () => {
		return (dispatch: Dispatch): ShowLoginLoaderAction => {
			const showLoginLoaderAction: ShowLoginLoaderAction = {
				type: LoginActionTypes.SHOW_LOGIN_LOADER
			};
			return dispatch(showLoginLoaderAction);
		};
	},
	hideLoginLoader: () => {
		return (dispatch: Dispatch): HideLoginLoaderAction => {
			const hideLoginLoaderAction: HideLoginLoaderAction = {
				type: LoginActionTypes.HIDE_LOGIN_LOADER
			};
			return dispatch(hideLoginLoaderAction);
		};
	},
	loginFromSession: () => {
		return (dispatch: Dispatch): LoginSessionSuccessfulAction => {
			const loginSessionSuccessfulAction: LoginSessionSuccessfulAction = {
				type: LoginActionTypes.LOGIN_SESSION_SUCCESSFUL
			};
			return dispatch(loginSessionSuccessfulAction);
		};
	},
	loginFromAuth0: () => {
		return (dispatch: Dispatch): LoginAuth0SuccessfulAction => {
			const loginAuth0SuccessfulAction: LoginAuth0SuccessfulAction = {
				type: LoginActionTypes.LOGIN_AUTH0_SUCCESSFUL
			};
			return dispatch(loginAuth0SuccessfulAction);
		};
	},
	logout: (auth0: Auth0ContextInterface) => {
		return (dispatch: Dispatch): LogoutSuccessfulAction => {
			const logoutSuccessfulAction: LogoutSuccessfulAction = {
				type: LoginActionTypes.LOGOUT_SUCCESSFUL
			};
			cache.deleteAll();
			auth0.logout({
				logoutParams: {
					returnTo:
						process.env.REACT_APP_AUTH0_RETURN_TO_URL ||
						window.location.origin
				}
			});
			return dispatch(logoutSuccessfulAction);
		};
	},
	refreshTokenSuccessful: () => {
		return (dispatch: Dispatch): RefreshTokenSuccessfulAction => {
			const refreshTokenSuccessfulAction: RefreshTokenSuccessfulAction = {
				type: LoginActionTypes.REFRESH_TOKEN_SUCCESSFUL
			};
			return dispatch(refreshTokenSuccessfulAction);
		};
	},
	refreshTokenFailed: () => {
		return (dispatch: Dispatch): RefreshTokenFailedAction => {
			const refreshTokenFailedAction: RefreshTokenFailedAction = {
				type: LoginActionTypes.REFRESH_TOKEN_FAILED
			};
			return dispatch(refreshTokenFailedAction);
		};
	},
	signup: (signupRequest: SignupRequest) => {
		return async (dispatch: Dispatch): Promise<SignupSuccessfulAction> => {
			const signupRequestedAction: SignupRequestedAction = {
				type: LoginActionTypes.SIGNUP_REQUESTED
			};
			dispatch(signupRequestedAction);
			try {
				await signup(signupRequest);
				const signupSuccessfulAction: SignupSuccessfulAction = {
					type: LoginActionTypes.SIGNUP_SUCCESSFUL
				};
				return dispatch(signupSuccessfulAction);
			} catch (error) {
				const signupFailedAction: SignupFailedAction = {
					type: LoginActionTypes.SIGNUP_FAILED
				};
				dispatch(signupFailedAction);

				return Promise.reject(error);
			}
		};
	},
	resendVerifyEmail: (
		resendEmailVerificationRequest: ResendEmailVerificationRequest
	) => {
		return async (
			dispatch: Dispatch
		): Promise<ResendEmailVerificationSuccessfulAction> => {
			const resendEmailVerificationRequestedAction: ResendEmailVerificationRequestedAction =
				{
					type: LoginActionTypes.RESEND_EMAIL_VERIFICATION_REQUESTED
				};
			dispatch(resendEmailVerificationRequestedAction);
			try {
				await resendVerifyEmail(resendEmailVerificationRequest);
				const resendEmailVerificationSuccessfulAction: ResendEmailVerificationSuccessfulAction =
					{
						type: LoginActionTypes.RESEND_EMAIL_VERIFICATION_SUCCESSFUL
					};
				return dispatch(resendEmailVerificationSuccessfulAction);
			} catch (error) {
				const resendEmailVerificationFailedAction: ResendEmailVerificationFailedAction =
					{
						type: LoginActionTypes.RESEND_EMAIL_VERIFICATION_FAILED
					};
				dispatch(resendEmailVerificationFailedAction);

				return Promise.reject(error);
			}
		};
	},
	auth0LoginStateReceived: (auth0LoginState: Auth0LoginState) => {
		return (dispatch: Dispatch): LoginAuth0StateReceivedAction => {
			const loginAuth0StateReceivedAction: LoginAuth0StateReceivedAction =
				{
					type: LoginActionTypes.LOGIN_AUTH0_STATE_RECEIVED,
					auth0LoginState
				};
			return dispatch(loginAuth0StateReceivedAction);
		};
	}
};

export default LoginActionCreators;
