'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index = require('../../Products/index.js');

exports.SKUProductStatus = void 0;
(function (SKUProductStatus) {
  SKUProductStatus["DRAFT"] = "draft";
  SKUProductStatus["PUBLISHED"] = "published";
  SKUProductStatus["UNPUBLISHED"] = "unpublished";
})(exports.SKUProductStatus || (exports.SKUProductStatus = {}));
var BundlePricing = /** @class */function () {
  function BundlePricing() {
    this.created = new Date();
    this.id = null;
    this.item_id = null;
    this.session_id = null;
    this.name = "";
    this.description = "";
    this.billing_period = index.ProductBillingType.OneTime;
    this.cost_price = 0;
    this.sell_price = 0;
    this.setup_price = 0;
    this.updated = new Date();
    this.distributor_pricing_details = null;
    this.markup = 0;
    this.margin = 0;
    this.currency_exchange_details = null;
  }
  return BundlePricing;
}();
var SKUProductRequest = /** @class */function () {
  function SKUProductRequest(name, description, type, images, unit_of_measure, marketplace_listings, pricings, should_add_setup_price_to_margin, bundled_skus, custom_fields, show_individual_pricing, show_child_quantity, hide_child_items_in_quote_block) {
    this.name = name;
    this.description = description;
    this.type = type;
    this.images = images;
    this.unit_of_measure = unit_of_measure;
    this.marketplace_listings = marketplace_listings;
    this.pricings = pricings;
    this.should_add_setup_price_to_margin = should_add_setup_price_to_margin;
    this.bundled_skus = bundled_skus;
    this.custom_fields = custom_fields;
    this.show_individual_pricing = show_individual_pricing;
    this.show_child_quantity = show_child_quantity;
    this.hide_child_items_in_quote_block = hide_child_items_in_quote_block;
    this.status = exports.SKUProductStatus.DRAFT;
    this.name = name;
    this.description = description;
    this.type = type;
    this.images = images;
    this.unit_of_measure = unit_of_measure;
    this.marketplace_listings = marketplace_listings;
    this.pricings = pricings;
    this.bundled_skus = bundled_skus;
    this.custom_fields = custom_fields;
    this.should_add_setup_price_to_margin = should_add_setup_price_to_margin;
    this.show_individual_pricing = show_individual_pricing;
    this.show_child_quantity = show_child_quantity;
    this.hide_child_items_in_quote_block = hide_child_items_in_quote_block;
  }
  return SKUProductRequest;
}();
var bulkUpdateProductFormValues = /** @class */function () {
  function bulkUpdateProductFormValues() {
    this.description = "";
    this.unit_of_measure = index.UOM.EACH;
    this.marketplace_listings = [];
    this.custom_field_form_values = {};
  }
  return bulkUpdateProductFormValues;
}();
var SKUProduct = /** @class */function () {
  function SKUProduct(formValues, marketplaceListings) {
    var _a;
    this.id = "";
    this.name = "";
    this.description = "";
    this.type = index.ProductType.Hardware;
    this.images = [];
    this.unit_of_measure = index.UOM.EACH;
    this.marketplace_listings = [];
    this.pricings = [];
    this.vendor_company_id = "";
    this.status = exports.SKUProductStatus.DRAFT;
    this.created_at = new Date();
    this.updated = new Date();
    this.name = formValues.name;
    this.description = formValues.description;
    this.type = formValues.type;
    this.images = formValues.images;
    this.unit_of_measure = formValues.unit_of_measure;
    this.pricings = formValues.pricings;
    this.bundled_skus = formValues.bundled_skus;
    this.show_individual_pricing = formValues.show_individual_pricing;
    this.marketplace_listings = marketplaceListings;
    this.should_add_setup_price_to_margin = !!((_a = formValues.pricings) === null || _a === void 0 ? void 0 : _a.length) ? formValues.pricings[0].should_add_setup_price_to_margin : false;
  }
  return SKUProduct;
}();
var SKUProductResponse = /** @class */function () {
  function SKUProductResponse() {
    this.data = [];
    this.filtered_count_map = null;
  }
  return SKUProductResponse;
}();

exports.BundlePricing = BundlePricing;
exports.SKUProduct = SKUProduct;
exports.SKUProductRequest = SKUProductRequest;
exports.SKUProductResponse = SKUProductResponse;
exports.bulkUpdateProductFormValues = bulkUpdateProductFormValues;
