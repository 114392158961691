'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index = require('../../Products/index.js');
var index$1 = require('../Vendor/index.js');

var _a;
var _b, _c, _d, _e, _f, _g, _h, _j;
exports.MarketplaceCategory = void 0;
(function (MarketplaceCategory) {
  MarketplaceCategory["PRODUCTIVITY"] = "productivity";
  MarketplaceCategory["INFRASTRUCTURE"] = "infrastructure";
  MarketplaceCategory["CONTINUITY"] = "continuity";
  MarketplaceCategory["SECURITY"] = "security";
  MarketplaceCategory["COMMUNICATION"] = "communication";
  MarketplaceCategory["COMPLIANCE"] = "compliance";
  MarketplaceCategory["TRAINING"] = "training";
  MarketplaceCategory["BILLING_AUTOMATION"] = "billing_automation";
})(exports.MarketplaceCategory || (exports.MarketplaceCategory = {}));
var MarketplaceSubcategory = (_a = {}, _a[exports.MarketplaceCategory.PRODUCTIVITY] = ["Email Service", "Business Applications", "Business Intelligence", "Commnucations", "MSP Tools", "Productivity Monitoring", "Project Management", "Distributed Spend Management", "Travel Management", "Financial Reporting & Analytics", "RPA", "Customer Experience", "Automation", "Data Collaboration"], _a[exports.MarketplaceCategory.COMMUNICATION] = ["Unified Communications", "Contact Centre"], _a[exports.MarketplaceCategory.INFRASTRUCTURE] = ["laaS", "Storage", "DaaS", "laaS DaaS Management", "laaS Migration"], _a[exports.MarketplaceCategory.CONTINUITY] = ["Email Archiving", "High Availability", "Disaster Recovery", "File Sharing", "SaaS Backup", "Backup"], _a[exports.MarketplaceCategory.SECURITY] = ["Security Assessment", "Data Protection & Compliance", "Email Security", "Managed Security", "Moblile Security", "Network Security", "Endpoint Security", "Web Security", "Identity Managment", "Security Training"], _a);
var MarketplaceProductCountMap = /** @class */function () {
  function MarketplaceProductCountMap() {
    this[_b] = 0;
    this[_c] = 0;
    this[_d] = 0;
    this[_e] = 0;
    this[_f] = 0;
    this[_g] = 0;
    this[_h] = 0;
    this[_j] = 0;
  }
  return MarketplaceProductCountMap;
}();
_b = exports.MarketplaceCategory.PRODUCTIVITY, _c = exports.MarketplaceCategory.INFRASTRUCTURE, _d = exports.MarketplaceCategory.CONTINUITY, _e = exports.MarketplaceCategory.SECURITY, _f = exports.MarketplaceCategory.COMMUNICATION, _g = exports.MarketplaceCategory.COMPLIANCE, _h = exports.MarketplaceCategory.TRAINING, _j = exports.MarketplaceCategory.BILLING_AUTOMATION;
exports.MarketplaceProductBillingTerms = void 0;
(function (MarketplaceProductBillingTerms) {
  MarketplaceProductBillingTerms["USAGE"] = "usage";
  MarketplaceProductBillingTerms["LICENSE"] = "license";
})(exports.MarketplaceProductBillingTerms || (exports.MarketplaceProductBillingTerms = {}));
exports.MarketplacePricingPlanType = void 0;
(function (MarketplacePricingPlanType) {
  MarketplacePricingPlanType["FLAT_PRICING"] = "flat_pricing";
  MarketplacePricingPlanType["TIER_BASED_PRICING"] = "tier_based_pricing";
  MarketplacePricingPlanType["VOLUME_BASED_PRICING"] = "volume_based_pricing";
})(exports.MarketplacePricingPlanType || (exports.MarketplacePricingPlanType = {}));
exports.MarketplaceProductPricingUOM = void 0;
(function (MarketplaceProductPricingUOM) {
  MarketplaceProductPricingUOM["USER"] = "user";
  MarketplaceProductPricingUOM["ENVIRONMENT"] = "environment";
  MarketplaceProductPricingUOM["DATA"] = "data";
  MarketplaceProductPricingUOM["RESOURCE"] = "resource";
})(exports.MarketplaceProductPricingUOM || (exports.MarketplaceProductPricingUOM = {}));
var MarketplaceProductPricingPlanTierDetails = /** @class */function () {
  function MarketplaceProductPricingPlanTierDetails() {
    this.cost_to_msp = 0;
    this.cost_to_end_user = 0;
    this.starting_unit = 0;
    this.ending_unit = 0;
  }
  return MarketplaceProductPricingPlanTierDetails;
}();
var MarketplaceProductPricingPlanV2 = /** @class */function () {
  function MarketplaceProductPricingPlanV2() {
    this.cost_to_end_user = 0;
    this.cost_to_msp = 0;
    this.plan_type = exports.MarketplacePricingPlanType.FLAT_PRICING;
    this.tier_level = [];
  }
  return MarketplaceProductPricingPlanV2;
}();
var MarketplaceProductPricingTierCostDetails = /** @class */function () {
  function MarketplaceProductPricingTierCostDetails() {
    this.cost_to_msp = 0;
    this.cost_to_end_user = 0;
  }
  return MarketplaceProductPricingTierCostDetails;
}();
var MarketplaceProductPricingTierDetailsRequest = /** @class */function () {
  function MarketplaceProductPricingTierDetailsRequest() {
    this.starting_unit = 0;
    this.ending_unit = 0;
    this.cost_detail = new MarketplaceProductPricingTierCostDetails();
  }
  return MarketplaceProductPricingTierDetailsRequest;
}();
var MarketplaceProductPricingTierRequest = /** @class */function () {
  function MarketplaceProductPricingTierRequest() {
    this.tier_details = [];
  }
  return MarketplaceProductPricingTierRequest;
}();
var MarketplaceProductPricingFlatPricingRequest = /** @class */function () {
  function MarketplaceProductPricingFlatPricingRequest() {
    this.cost_detail = new MarketplaceProductPricingTierCostDetails();
  }
  return MarketplaceProductPricingFlatPricingRequest;
}();
var MarketplaceProductPricingRequest = /** @class */function () {
  function MarketplaceProductPricingRequest() {
    this.plan_type = exports.MarketplacePricingPlanType.FLAT_PRICING;
    this.data = new MarketplaceProductPricingFlatPricingRequest();
  }
  return MarketplaceProductPricingRequest;
}();
var MarketplaceProductPricing = /** @class */function () {
  function MarketplaceProductPricing() {
    this.additional_benefits = null;
    this.features = [];
    this.created = new Date();
    this.id = "";
    this.item_id = ""; // refers to marketplace listing id
    this.name = "";
    this.plan_details = new MarketplaceProductPricingRequest();
    this.should_show_trial_price = false;
    this.trial_period = null;
    this.uom = exports.MarketplaceProductPricingUOM.USER;
    this.updated = new Date();
    this.billing_period = index.ProductBillingType.OneTime;
  }
  return MarketplaceProductPricing;
}();
var MarketplaceProduct = /** @class */function () {
  function MarketplaceProduct() {
    this.billing_terms = exports.MarketplaceProductBillingTerms.USAGE;
    this.category = exports.MarketplaceCategory.PRODUCTIVITY;
    this.created = new Date();
    this.description = "";
    this.gdpr_status = false;
    this.hipaa_status = false;
    this.id = "";
    this.images = [];
    this.key_points = [];
    this.name = "";
    this.pci_status = false;
    this.pricings = [];
    this.screenshots = [];
    this.sub_category = [];
    this.thumbnail = undefined;
    this.updated = new Date();
    this.videos = [];
    this.vendor_details = new index$1.VendorCompany();
    this.is_active = true;
    this.subscription_status = index$1.VendorSubscriptionStatus.NOTAPPLIED;
  }
  return MarketplaceProduct;
}();
var MarketplaceProductsResponse = /** @class */function () {
  function MarketplaceProductsResponse() {
    this.data = new Array();
    this.filtered_count_map = new MarketplaceProductCountMap();
  }
  return MarketplaceProductsResponse;
}();
exports.MarketplaceProductCompliance = void 0;
(function (MarketplaceProductCompliance) {
  MarketplaceProductCompliance["GDPR"] = "gdpr";
  MarketplaceProductCompliance["PCI"] = "pci";
  MarketplaceProductCompliance["HIPAA"] = "hipaa";
})(exports.MarketplaceProductCompliance || (exports.MarketplaceProductCompliance = {}));
var MarketplaceProductBillingTermsRadioOptions = [{
  label: "Usage",
  value: exports.MarketplaceProductBillingTerms.USAGE
}, {
  label: "License",
  value: exports.MarketplaceProductBillingTerms.LICENSE
}];

exports.MarketplaceProduct = MarketplaceProduct;
exports.MarketplaceProductBillingTermsRadioOptions = MarketplaceProductBillingTermsRadioOptions;
exports.MarketplaceProductCountMap = MarketplaceProductCountMap;
exports.MarketplaceProductPricing = MarketplaceProductPricing;
exports.MarketplaceProductPricingFlatPricingRequest = MarketplaceProductPricingFlatPricingRequest;
exports.MarketplaceProductPricingPlanTierDetails = MarketplaceProductPricingPlanTierDetails;
exports.MarketplaceProductPricingPlanV2 = MarketplaceProductPricingPlanV2;
exports.MarketplaceProductPricingRequest = MarketplaceProductPricingRequest;
exports.MarketplaceProductPricingTierCostDetails = MarketplaceProductPricingTierCostDetails;
exports.MarketplaceProductPricingTierDetailsRequest = MarketplaceProductPricingTierDetailsRequest;
exports.MarketplaceProductPricingTierRequest = MarketplaceProductPricingTierRequest;
exports.MarketplaceProductsResponse = MarketplaceProductsResponse;
exports.MarketplaceSubcategory = MarketplaceSubcategory;
