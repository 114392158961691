import { Reducer } from "redux";

import { BulkImportResponse } from "@zomentum/contracts/dist/BulkImport";

import {
	BulkImportActionTypes,
	BulkImportActions
} from "@/Actions/Vendor/BulkImport";
import { VendorPartner } from "@zomentum/contracts/dist/Vendor/Partner";
import {
	VendorClient,
	VendorClientContact
} from "@zomentum/contracts/dist/Vendor/Clients";
import { VendorOpportunity } from "@zomentum/contracts/dist/Vendor/Opportunity";
import { SKUProduct } from "@zomentum/contracts/dist/Vendor/SKU";

export interface BulkImportState {
	readonly isLoading: boolean;
	readonly bulkImportData: BulkImportResponse<
		| VendorClient
		| VendorPartner
		| VendorOpportunity
		| SKUProduct
		| VendorClientContact
	> | null;
	readonly spreadsheetBulkImportDrawerOpen: boolean;
	readonly softwareBulkImportDrawerOpen: boolean;
	readonly emailBulkImportDrawerOpen: boolean;
	readonly bulkImportEntityListDrawerOpen: boolean;
}

const initialState: BulkImportState = {
	isLoading: false,
	bulkImportData: null,
	spreadsheetBulkImportDrawerOpen: false,
	softwareBulkImportDrawerOpen: false,
	emailBulkImportDrawerOpen: false,
	bulkImportEntityListDrawerOpen: false
};

export const BulkImportReducer: Reducer<BulkImportState, BulkImportActions> = (
	state: BulkImportState = initialState,
	action: BulkImportActions
): BulkImportState => {
	switch (action.type) {
		case BulkImportActionTypes.SHOW_BULK_IMPORT_LOADER: {
			return {
				...state,
				isLoading: true
			};
		}
		case BulkImportActionTypes.HIDE_BULK_IMPORT_LOADER: {
			return {
				...state,
				isLoading: false
			};
		}
		case BulkImportActionTypes.VENDOR_PARTNERS_BULK_IMPORT_SUCCESSFUL: {
			return {
				...state,
				bulkImportData: action.bulkImportData
			};
		}
		case BulkImportActionTypes.BULK_IMPORT_RESET: {
			return {
				...state,
				bulkImportData: null
			};
		}
		case BulkImportActionTypes.SPREADSHEET_BULK_IMPORT_DRAWER_OPENED: {
			return {
				...state,
				spreadsheetBulkImportDrawerOpen: true
			};
		}
		case BulkImportActionTypes.SPREADSHEET_BULK_IMPORT_DRAWER_CLOSED: {
			return {
				...state,
				spreadsheetBulkImportDrawerOpen: false
			};
		}
		case BulkImportActionTypes.SOFTWARE_BULK_IMPORT_DRAWER_OPENED: {
			return {
				...state,
				softwareBulkImportDrawerOpen: true
			};
		}
		case BulkImportActionTypes.SOFTWARE_BULK_IMPORT_DRAWER_CLOSED: {
			return {
				...state,
				softwareBulkImportDrawerOpen: false
			};
		}
		case BulkImportActionTypes.EMAIL_BULK_IMPORT_DRAWER_OPENED: {
			return {
				...state,
				emailBulkImportDrawerOpen: true
			};
		}
		case BulkImportActionTypes.EMAIL_BULK_IMPORT_DRAWER_CLOSED: {
			return {
				...state,
				emailBulkImportDrawerOpen: false
			};
		}
		case BulkImportActionTypes.BULK_IMPORT_ENTITY_LIST_DRAWER_OPENED: {
			return {
				...state,
				bulkImportEntityListDrawerOpen: true
			};
		}
		case BulkImportActionTypes.BULK_IMPORT_ENTITY_LIST_DRAWER_CLOSED: {
			return {
				...state,
				bulkImportEntityListDrawerOpen: false
			};
		}
		case BulkImportActionTypes.VENDOR_CLIENTS_BULK_IMPORT_SUCCESSFUL: {
			return {
				...state,
				bulkImportData: action.bulkImportData
			};
		}
		case BulkImportActionTypes.VENDOR_OPPORTUNITY_BULK_IMPORT_SUCCESSFUL: {
			return {
				...state,
				bulkImportData: action.bulkImportData
			};
		}
		case BulkImportActionTypes.VENDOR_PRODUCTS_BULK_IMPORT_SUCCESSFUL: {
			return {
				...state,
				bulkImportData: action.bulkImportData
			};
		}
		case BulkImportActionTypes.VENDOR_CLIENT_CONTACTS_BULK_IMPORT_SUCCESSFUL: {
			return {
				...state,
				bulkImportData: action.bulkImportData
			};
		}
		default: {
			return state;
		}
	}
};

export default BulkImportReducer;
