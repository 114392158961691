import React, { Fragment, useEffect, useRef, useState } from "react";
import {
	NavLink,
	RouteComponentProps,
	useHistory,
	withRouter
} from "react-router-dom";
import { useSelector } from "react-redux";
import "./index.less";
import Menu from "antd/lib/menu";
import Layout from "antd/lib/layout";
import {
	DefaultPartnerAlignPrivateRoute,
	ZomentumRoutes,
	ZomentumStorageKeys,
	ZomentumWindow
} from "@zomentum/contracts";
import { shouldHideLayoutForPath } from "@zomentum/contracts/dist/Utils";
import { AuthToken } from "@zomentum/contracts/dist/Login";
import { cache } from "@zomentum/contracts";
import LoginActionCreators from "@/Actions/Login";
import { isRouteAllowed } from "@/Components/Utils";
import { useFeatureFlag } from "@zomentum/hooks/dist/hooks/useFeature";
import useDispatch from "@/Components/Utils/useDispatch";
import Badge from "antd/lib/badge";
import ZTooltip from "@zomentum/atoms/dist/ZTooltip";
import ZIcon from "@zomentum/atoms/dist/ZIcon";
import { EIcons } from "@zomentum/atoms/dist/ZIcon/interface";
import { EColors } from "@zomentum/atoms/dist/ZColors/interface";
import Divider from "antd/lib/divider";
import { ILeftMenuItem } from "./interface";
import getLeftMenuItems from "./LeftMenuItems";
import SettingsMenuItems from "./SettingsMenuItems";
import { useAuth0 } from "@auth0/auth0-react";
import ZBetaTag from "@zomentum/atoms/dist/ZBetaTag";
import SettingsActionCreators from "@/Actions/Settings";
import VendorUsersActionCreators from "@/Actions/Vendor/User";
import { GlobalState } from "@/Reducers/interface";
import PartnerAlignAvo, {
	PsaName,
	ProductName,
	UserAccess as AvoUserAccess
} from "@zomentum/utils/dist/PartnerAlignAvo";
import { VendorUserData } from "@zomentum/contracts/dist/Vendor/User";
import { handleAvoCallForUserRole } from "@zomentum/utils";

declare let window: ZomentumWindow;

const WrappedChatIcon: React.FC<{ status: boolean }> = props => {
	if (props.status) return <Badge dot>{props.children}</Badge>;
	return <>{props.children}</>;
};

const WrappedMenuItem: React.FC<{
	title: string;
	onMouseDown?: () => void;
}> = props => {
	return (
		<>
			<div className="menu-item-container h-full	relative">
				{props.children}
				<ZTooltip showtooltip={true} title={props.title}>
					<div
						className="mask h-full	absolute top-0"
						onMouseDown={props.onMouseDown}
					/>
				</ZTooltip>
			</div>
		</>
	);
};

const handleAvoLeftMenuCall = (
	leftMenu: string,
	fromPage: string,
	userData: VendorUserData,
	groupSubdomain: string
) => {
	const avoObject = {
		psaName: PsaName.NONE,
		permissionLevel: handleAvoCallForUserRole(userData?.role),
		userAccess: AvoUserAccess.GLOBAL,
		fromPage,
		planType: "No dedicated plan",
		groupSubdomain,
		productName: ProductName.PARTNER_ALIGN
	};
	switch (leftMenu) {
		case "Partners": {
			PartnerAlignAvo.partnersClicked({
				...avoObject,
				pageName: "Partners"
			});
			break;
		}
		case "Dashboard": {
			PartnerAlignAvo.dashboardClicked({
				...avoObject,
				pageName: "Dashboard"
			});
			break;
		}
		case "Account": {
			PartnerAlignAvo.accountClicked({
				...avoObject,
				pageName: "Account"
			});
			break;
		}
		case "Tiering": {
			PartnerAlignAvo.tieringClicked({
				...avoObject,
				pageName: "Tiering"
			});
			break;
		}
		case "Finance": {
			PartnerAlignAvo.financeModuleClicked({
				...avoObject,
				pageName: "Finance"
			});
			break;
		}
		default:
			return;
	}
};

export interface IAppLeftMenu extends RouteComponentProps {
	readonly updateServiceWorker: (
		registration: ServiceWorkerRegistration
	) => void;
}

const AppLeftMenu: React.FC<IAppLeftMenu> = props => {
	const dispatch = useDispatch();
	const auth0 = useAuth0();
	const { isLoggedIn, user, appUpdateBannerVisible, vendorUserCompany } =
		useSelector((state: GlobalState) => ({
			isLoggedIn: state.login.isLoggedIn,
			user: state.vendorUser.vendorUserData,
			appUpdateBannerVisible: state.settings.appUpdateBannerVisible,
			vendorUserCompany: state.vendorUser.vendorUserCompany
		}));

	const [settingsMenuCollapsed, setSettingMenuCollapsed] =
		useState<boolean>(true);
	// const [shouldShowNavItems, setShouldShowNavItems] = useState(false);
	const { shouldShowFreshChatBubble } = useSelector((state: GlobalState) => ({
		shouldShowFreshChatBubble:
			state.uiConfig.showFreshChatUnreadMessageBubble
	}));

	const settingsMenuRef = useRef<HTMLDivElement>(null);
	const showChatIcon = useFeatureFlag("chat_icon");
	const customRolesFlag = false;
	const marketplaceSkuFlag = useFeatureFlag("marketplace_sku");
	const vendorHubspotIntegration = useFeatureFlag(
		"vendor_hubspot_integration"
	);

	const [serviceWorkerRegistration, setServiceWorkerRegistration] =
		useState<ServiceWorkerRegistration | null>(null);

	if (
		appUpdateBannerVisible &&
		process.env.REACT_APP_NODE_ENV !== "production"
	) {
		console.log("update is available");
		console.log({ serviceWorker: serviceWorkerRegistration });
	}

	const handleLeftMenuClick = (event: MouseEvent) => {
		if (
			settingsMenuRef.current &&
			!settingsMenuRef.current.contains(event.target as Node) &&
			!settingsMenuCollapsed
		) {
			setSettingMenuCollapsed(true);
		}
	};

	const handleUpdateServiceWorkerEvent = ($event: Event) => {
		dispatch(SettingsActionCreators.showAppUpdateBanner());
		const $customEvent = $event as CustomEvent<{
			registration: ServiceWorkerRegistration;
		}>;

		console.log(
			"current service worker --> ",
			$customEvent.detail.registration
		);

		if (
			$customEvent &&
			$customEvent.detail &&
			$customEvent.detail.registration
		) {
			setServiceWorkerRegistration($customEvent.detail.registration);
		}
	};
	const handleUpdateServiceWorker = () => {
		if (props.updateServiceWorker && serviceWorkerRegistration) {
			console.log(
				"triggering update with --> ",
				serviceWorkerRegistration
			);
			props.updateServiceWorker(serviceWorkerRegistration);
		}
	};

	// useEffect(() => {
	// 	if (!!permissions?.length) {
	// 		setShouldShowNavItems(true);
	// 	}
	// }, [permissions]);

	useEffect(() => {
		document.addEventListener("mousedown", handleLeftMenuClick);
		document.addEventListener(
			"updateServiceWorker",
			handleUpdateServiceWorkerEvent
		);
		return () => {
			document.removeEventListener(
				"updateServiceWorker",
				handleUpdateServiceWorkerEvent
			);
			document.removeEventListener("mousedown", handleLeftMenuClick);
		};
	});

	const handleLogoutUser = () => {
		destroyThirdPartyScripts();
		dispatch(LoginActionCreators.logout(auth0));
		dispatch(VendorUsersActionCreators.resetVendorUserState());
		props.history.push(ZomentumRoutes.Login);
	};

	const destroyThirdPartyScripts = () => {
		destroyFreshchat();
	};

	const destroyFreshchat = () => {
		if (
			showChatIcon &&
			process.env.REACT_APP_FRESHCHAT &&
			window.fcWidget
		) {
			window.fcWidget.destroy();
		}
	};

	const history = useHistory();

	const moveToRoute = (menuItem: ILeftMenuItem) => {
		const { title, route } = menuItem;

		const pathArray = props.location.pathname.split("/");
		const fromPage = pathArray[1];
		!!title &&
			handleAvoLeftMenuCall(
				title,
				fromPage,
				user,
				vendorUserCompany?.sub_domain
			);

		if (route?.includes(ZomentumRoutes.Settings)) {
			setSettingMenuCollapsed(!settingsMenuCollapsed);
		} else {
			if (appUpdateBannerVisible) {
				console.log(
					"updating service worker with --> ",
					serviceWorkerRegistration
				);
				handleUpdateServiceWorker();
			}
			route && history.push(route);
		}
	};
	const settingsMenuItems = [...SettingsMenuItems()];

	settingsMenuItems.splice(0, 0, {
		title: user?.name?.full,
		permission: null,
		disabled: true
	});

	// const isPermissionEnabled = (permission?: AllRolePermissions | null) => {
	// 	if (permission === null) return true;
	// 	else return permission && permissions?.includes(permission);
	// };

	const renderSettingsMenu = () => {
		return (
			<div ref={settingsMenuRef}>
				<Layout.Sider
					collapsible={true}
					collapsed={settingsMenuCollapsed}
					trigger={null}
					className={`settings-sider overflow-auto fixed`}
					collapsedWidth={0}
					width={256}
				>
					{user && (
						<>
							<Menu mode="inline" openKeys={["Email"]}>
								{settingsMenuItems
									.filter(
										menuItem =>
											!(
												!vendorHubspotIntegration &&
												menuItem.route ===
													ZomentumRoutes.IntegrationSettings
											)
									)
									.map((menuItem: ILeftMenuItem) =>
										menuItem.disabled ? (
											<Menu.Item
												key={menuItem.title}
												disabled
												className="fixed-menu-tile font-medium"
											>
												{menuItem.title}
											</Menu.Item>
										) : (
											menuItem.route &&
											// isPermissionEnabled(
											// 	menuItem.permission
											// ) &&
											isRouteAllowed(
												menuItem.route,
												user.role,
												customRolesFlag
												// permissions
											) && (
												<Menu.Item
													key={menuItem.route}
													onClick={() => {
														const pathArray =
															props.location.pathname.split(
																"/"
															);
														const fromPage =
															pathArray[1];
														menuItem?.title &&
															handleAvoLeftMenuCall(
																menuItem?.title,
																fromPage,
																user,
																vendorUserCompany?.sub_domain
															);
														setSettingMenuCollapsed(
															true
														);
													}}
													className={
														props.location.pathname.includes(
															menuItem.route
														)
															? "ant-menu-item-selected"
															: ""
													}
												>
													<NavLink
														to={menuItem.route}
														exact={true}
														strict={true}
													>
														{menuItem.componentIcon ? (
															<menuItem.componentIcon />
														) : (
															<ZIcon
																name={
																	menuItem.icon
																}
																size="sm"
															/>
														)}
														{menuItem.title}
													</NavLink>
												</Menu.Item>
											)
										)
									)}
							</Menu>
							<Menu mode="inline">
								<Menu.Item
									key="logout-title"
									className="logout-menu-title"
									onClick={handleLogoutUser}
								>
									<NavLink to="#">
										<ZIcon name={EIcons.LOGOUT} size="sm" />
										Logout
									</NavLink>
								</Menu.Item>
							</Menu>
						</>
					)}
				</Layout.Sider>
			</div>
		);
	};

	const authToken = cache.get(ZomentumStorageKeys.Token, true) as AuthToken;

	const shouldShowSidebar =
		!shouldHideLayoutForPath(props.location.pathname) &&
		(isLoggedIn || (authToken && authToken.token));
	const leftMenuItems = getLeftMenuItems(props.location.pathname);

	const AppSider = (
		<Fragment>
			<Layout.Sider
				data-testid="left-menu"
				collapsible={true}
				collapsed={true}
				trigger={null}
				collapsedWidth={64}
				className={`app-sider`}
			>
				<Menu
					mode="inline"
					style={{ width: "fit-content" }}
					defaultSelectedKeys={[DefaultPartnerAlignPrivateRoute]}
					selectedKeys={[props.location.pathname]}
					key="Zomentum-Left-Menu"
				>
					{leftMenuItems
						.filter((menuItem: ILeftMenuItem) => {
							return !(
								!marketplaceSkuFlag &&
								menuItem.route ===
									ZomentumRoutes.VendorMarketplaceSKUs
							);
						})
						.map(
							(menuItem: ILeftMenuItem, index) =>
								menuItem.route &&
								// isPermissionEnabled(menuItem.permission) &&
								isRouteAllowed(menuItem.route, user.role) && (
									<Menu.Item
										key={`menu-item-${index}`}
										title={null}
										className={`${
											menuItem.selected
												? "ant-menu-item-selected"
												: ""
										} ${
											menuItem.isBeta
												? "show-beta-tag"
												: ""
										} ${menuItem.className}`}
									>
										<WrappedMenuItem
											title={`${menuItem.title}`}
											onMouseDown={() =>
												moveToRoute(menuItem)
											}
										>
											{menuItem.componentIcon ? (
												<menuItem.componentIcon />
											) : (
												<ZIcon
													name={menuItem.icon}
													color={EColors.GRAY}
													colorVariant={2}
													fill={menuItem.fillIcon}
												/>
											)}
											{menuItem.isBeta && (
												<ZBetaTag className="absolute" />
											)}
										</WrappedMenuItem>
									</Menu.Item>
								)
						)}
					{process.env.REACT_APP_NODE_ENV === "production" && (
						<Menu.Item
							key={"chat-popup"}
							title={null}
							className={`chat-menuitem`}
							onClick={() => window.FreshworksWidget("open")}
						>
							<Divider className="mb-2" />
							<div className="flex justify-center">
								<WrappedChatIcon
									status={shouldShowFreshChatBubble}
								>
									<ZIcon
										name={EIcons.CIRCLECHATDOT}
										color={EColors.GEEKBLUE}
										colorVariant={2}
									/>
								</WrappedChatIcon>
							</div>
						</Menu.Item>
					)}
				</Menu>
			</Layout.Sider>
			{renderSettingsMenu()}
		</Fragment>
	);

	return shouldShowSidebar ? AppSider : <Fragment />;
};

export default withRouter(AppLeftMenu);
