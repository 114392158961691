import {
	DeletedRecord,
	PendingOpportunitySharedWithVendor,
	PendingOpportunitySharedWithVendorResponse,
	VendorClientResponse,
	ZomentumEntities
} from "@zomentum/contracts";
import {
	CustomViewResponse,
	CustomViewsMetadata,
	ListPagePostRequest,
	CustomView
} from "@zomentum/contracts/dist/UI";
import {
	getTableQueryFilters,
	ChildEntityHydrationParams,
	includeChildEntities
} from "@zomentum/contracts/dist/Utils";
import {
	AcceptPendingOpportunitySharedWithVendorRequest,
	RejectPendingOpportunitySharedWithVendorRequest
} from "@zomentum/contracts/dist/Vendor/Opportunity";
import {
	VendorOpportunity,
	VendorOpportunityResponse,
	VendorKanbanOpportunityResponse,
	VendorKanbanOpportunityCountResponse,
	VendorOpportunityRequest,
	OpportunityRequestForPartner
} from "@zomentum/contracts/dist/Vendor/Opportunity";
import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest
} from "@zomentum/utils/dist/Service/BackendService";
import axios, { AxiosResponse } from "axios";

const getOpportunityKanbanPostURL = "/v1/vendor/opportunities/view/kanban";
const getOpportunityCountKanbanPostURL = "/v1/vendor/opportunities/view/count";
const getOpportunityListPostURL = "/v1/vendor/opportunities/view";

const getVendorOpportunityDetailsURL = "/v1/vendor/opportunities/{id}";
const addVendorOpportunityURL = "/v1/vendor/opportunities";
const deleteVendorOpportunityURL = "/v1/vendor/opportunities/{id}";

const getAllOpportunityViewURL = "/v2/views?entity_type={entity_type}";
const getOpportunitiesURL = "v1/vendor/opportunities";

const createOpportunityViewURL = "/v2/views";
const updateOpportunityViewURL = "/v2/views/{view_id}";
const deleteOpportunityViewURL = "v2/views/{view_id}";

const getPendingOpportunitiesShareWithVendorURL =
	"/v1/vendor/opportunities/pending";
const getPendingOpportunityShareWithVendorURL =
	"/v1/vendor/opportunities/pending/:opportunityId";
const checkConflictForPendingOpportunityShareWithVendorURL =
	"/v1/vendor/opportunities/pending/:opportunityId/check-conflict";

const acceptPendingOpportunitySharedWithVendorURL =
	"/v1/vendor/opportunities/pending/:opportunityId/accept";

const rejectPendingOpportunitySharedWithVendorURL =
	"/v1/vendor/opportunities/pending/:opportunityId/reject";

export const getOpportunities = async (
	opportunityRequest: OpportunityRequestForPartner
): Promise<VendorOpportunityResponse> => {
	try {
		let queryParams = `limit=${opportunityRequest.limit}`;
		const included_child_entities = includeChildEntities(
			opportunityRequest.included_child_entities
		);
		queryParams = `${queryParams}${
			included_child_entities && included_child_entities.length > 0
				? `&${included_child_entities}`
				: ""
		}`;
		if (opportunityRequest.filters.length > 0) {
			const appliedFilters = getTableQueryFilters(
				opportunityRequest.filters,
				false
			);
			queryParams = `${queryParams}${
				appliedFilters && appliedFilters.length > 0
					? `&${appliedFilters}`
					: ""
			}`;
		}
		queryParams = `${queryParams}${
			opportunityRequest.keywords &&
			opportunityRequest.keywords.length > 0
				? `&q=${opportunityRequest.keywords}`
				: ""
		}`;

		const response: AxiosResponse<VendorOpportunityResponse> = await axios(
			getRequest(
				`${getOpportunitiesURL}?${queryParams}`,
				null,
				true,
				true,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getVendorOpportunityViewMetadataURL =
	"/v2/views/metadata?entity_type={entity_type}";

export const getOpportunitiesForKanbanPost = async (
	opportunityRequest: ListPagePostRequest
): Promise<VendorKanbanOpportunityResponse> => {
	try {
		const response: AxiosResponse<VendorKanbanOpportunityResponse> =
			await axios(
				postRequest(
					getOpportunityKanbanPostURL,
					opportunityRequest,
					true,
					false,
					false
				)
			);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const getOpportunitiesCountForKanbanPost = async (
	opportunityRequest: ListPagePostRequest
): Promise<VendorKanbanOpportunityCountResponse[]> => {
	try {
		const response: AxiosResponse<VendorKanbanOpportunityCountResponse[]> =
			await axios(
				postRequest(
					getOpportunityCountKanbanPostURL,
					opportunityRequest,
					true,
					false,
					false
				)
			);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const getOpportunityListPost = async (
	opportunityRequest: ListPagePostRequest
): Promise<VendorOpportunityResponse> => {
	try {
		const response: AxiosResponse<VendorOpportunityResponse> = await axios(
			postRequest(
				getOpportunityListPostURL,
				opportunityRequest,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const getVendorOpportunityDetails = async (
	opportunityId: string
): Promise<VendorOpportunity> => {
	try {
		const includedChildEntities = includeChildEntities([
			ChildEntityHydrationParams.COMPANIES_ALL,
			ChildEntityHydrationParams.VENDOR_CLIENT_COMPANIES_NAME,
			ChildEntityHydrationParams.VENDOR_CLIENT_COMPANIES_ALL,
			ChildEntityHydrationParams.USERS_ALL,
			ChildEntityHydrationParams.PARTNER_MANAGER_ALL,
			ChildEntityHydrationParams.PARTNER_OPPORTUNITIES_INSIGHTS
		]);
		const response: AxiosResponse<VendorOpportunity> = await axios(
			getRequest(
				getVendorOpportunityDetailsURL.replace("{id}", opportunityId) +
					`?${includedChildEntities}`,
				null,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getVendorOpportunityViewMetadata =
	async (): Promise<CustomViewsMetadata> => {
		try {
			const response: AxiosResponse<CustomViewsMetadata> = await axios(
				getRequest(
					getVendorOpportunityViewMetadataURL.replace(
						"{entity_type}",
						ZomentumEntities.VendorOpportunity
					),
					null,
					true,
					false,
					false
				)
			);
			return response.data;
		} catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	};

export const addVendorOpportunity = async (
	opportunityRequest: VendorOpportunityRequest
): Promise<VendorOpportunity> => {
	try {
		const includedChildEntities = includeChildEntities([
			ChildEntityHydrationParams.COMPANIES_ALL,
			ChildEntityHydrationParams.VENDOR_CLIENT_COMPANIES_NAME,
			ChildEntityHydrationParams.VENDOR_CLIENT_COMPANIES_ALL,
			ChildEntityHydrationParams.COMPANIES_ALL,
			ChildEntityHydrationParams.PARTNER_MANAGER_ALL
		]);
		const response: AxiosResponse<VendorOpportunity> = await axios(
			postRequest(
				addVendorOpportunityURL + `?${includedChildEntities}`,
				opportunityRequest,
				true
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const editVendorOpportunity = async (
	opportunityId: string,
	opportunityRequest: Partial<VendorOpportunityRequest>
): Promise<VendorOpportunity> => {
	try {
		const includedChildEntities = includeChildEntities([
			ChildEntityHydrationParams.COMPANIES_ALL,
			ChildEntityHydrationParams.VENDOR_CLIENT_COMPANIES_NAME,
			ChildEntityHydrationParams.VENDOR_CLIENT_COMPANIES_ALL,
			ChildEntityHydrationParams.USERS_ALL,
			ChildEntityHydrationParams.PARTNER_MANAGER_ALL,
			ChildEntityHydrationParams.PARTNER_OPPORTUNITIES_INSIGHTS
		]);
		const response: AxiosResponse<VendorOpportunity> = await axios(
			putRequest(
				getVendorOpportunityDetailsURL.replace("{id}", opportunityId) +
					`?${includedChildEntities}`,
				opportunityRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteVendorOpportunity = async (
	opportunity: VendorOpportunity
): Promise<DeletedRecord> => {
	try {
		const response: AxiosResponse<DeletedRecord> = await axios(
			deleteRequest(
				deleteVendorOpportunityURL.replace("{id}", opportunity.id),
				null,
				true,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getOpportunityDocuments = async (
	opportunityId: string
): Promise<VendorOpportunity> => {
	try {
		const includedChildEntities = includeChildEntities(
			new Array<ChildEntityHydrationParams>(
				ChildEntityHydrationParams.DOCUMENTS_ALL
			)
		);
		const url =
			getVendorOpportunityDetailsURL.replace(
				"{opportunity_id}",
				opportunityId
			) +
			(includedChildEntities && includedChildEntities.length > 0
				? "?" + includedChildEntities
				: "");
		const response: AxiosResponse<VendorOpportunity> = await axios(
			getRequest(url, null, true, false, false)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getAllOpportunityViews = async (): Promise<CustomViewResponse> => {
	try {
		const response: AxiosResponse<CustomViewResponse> = await axios(
			getRequest(
				getAllOpportunityViewURL.replace(
					"{entity_type}",
					ZomentumEntities.VendorOpportunity
				),
				null,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const createOpportunityView = async (
	customViewRequest: CustomView
): Promise<CustomView> => {
	try {
		const response: AxiosResponse<CustomView> = await axios(
			postRequest(
				createOpportunityViewURL,
				customViewRequest,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const updateOpportunityView = async (
	customViewRequest: CustomView
): Promise<CustomView> => {
	try {
		const response: AxiosResponse<CustomView> = await axios(
			putRequest(
				updateOpportunityViewURL.replace(
					"{view_id}",
					customViewRequest.id
				),
				customViewRequest,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const deleteOpportunityView = async (
	customViewId: string
): Promise<unknown> => {
	try {
		const response: AxiosResponse<unknown> = await axios(
			deleteRequest(
				deleteOpportunityViewURL.replace("{view_id}", customViewId),
				null,
				true,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const getPendingOpportunitiesSharedWithVendorRequest =
	async (): Promise<PendingOpportunitySharedWithVendorResponse> => {
		try {
			const includedChildEntities = includeChildEntities([
				ChildEntityHydrationParams.COMPANIES_ALL
			]);
			const response: AxiosResponse<PendingOpportunitySharedWithVendorResponse> =
				await axios(
					getRequest(
						getPendingOpportunitiesShareWithVendorURL +
							`?${includedChildEntities}`,
						null,
						true,
						true,
						false
					)
				);
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const getPendingOpportunitySharedWithVendorRequest = async (
	opportunityId: string
): Promise<PendingOpportunitySharedWithVendor> => {
	try {
		const includedChildEntities = includeChildEntities([
			ChildEntityHydrationParams.COMPANIES_ALL,
			ChildEntityHydrationParams.PARTNER_TIERS_ALL,
			ChildEntityHydrationParams.SHARED_CLIENTS_ALL,
			ChildEntityHydrationParams.OPPORTUNITIES_IS_DUPLICATE
		]);
		const response: AxiosResponse<PendingOpportunitySharedWithVendor> =
			await axios(
				getRequest(
					getPendingOpportunityShareWithVendorURL.replace(
						":opportunityId",
						opportunityId
					) + `?${includedChildEntities}`,
					null,
					true,
					true,
					false
				)
			);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const checkConflictForPendingOpportunityShareWithVendor = async (
	opportunityId: string
): Promise<VendorClientResponse> => {
	try {
		const response: AxiosResponse<VendorClientResponse> = await axios(
			getRequest(
				checkConflictForPendingOpportunityShareWithVendorURL.replace(
					":opportunityId",
					opportunityId
				),
				null,
				true,
				true,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const acceptPendingOpportunitySharedWithVendor = async (
	opportunityId: string,
	clientId?: string
): Promise<VendorOpportunity> => {
	try {
		const acceptPendingOpportunitySharedWithVendorRequest: AcceptPendingOpportunitySharedWithVendorRequest =
			{
				vendor_client_company_id: clientId
			};
		const includedChildEntities = includeChildEntities([
			ChildEntityHydrationParams.VENDOR_CLIENT_COMPANIES_NAME,
			ChildEntityHydrationParams.COMPANIES_ALL,
			ChildEntityHydrationParams.VENDOR_CLIENT_COMPANIES_ALL,
			ChildEntityHydrationParams.VENDOR_CLIENT_COMPANIES_NAME,
			ChildEntityHydrationParams.PARTNER_MANAGER_ALL,
			ChildEntityHydrationParams.PARTNER_OPPORTUNITIES_INSIGHTS
		]);
		const response: AxiosResponse<VendorOpportunity> = await axios(
			postRequest(
				`${acceptPendingOpportunitySharedWithVendorURL.replace(
					":opportunityId",
					opportunityId
				)}?${includedChildEntities}`,
				acceptPendingOpportunitySharedWithVendorRequest,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const rejectPendingOpportunitySharedWithVendor = async (
	opportunityId: string,
	rejectReason: string
): Promise<VendorOpportunity> => {
	try {
		const rejectPendingOpportunitySharedWithVendorRequest: RejectPendingOpportunitySharedWithVendorRequest =
			{
				reject_reason: rejectReason
			};
		const response: AxiosResponse<VendorOpportunity> = await axios(
			postRequest(
				rejectPendingOpportunitySharedWithVendorURL.replace(
					":opportunityId",
					opportunityId
				),
				rejectPendingOpportunitySharedWithVendorRequest,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};
