'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.VendorTemplateEditorType = void 0;
(function (VendorTemplateEditorType) {
  VendorTemplateEditorType["TEMPLATE"] = "template";
})(exports.VendorTemplateEditorType || (exports.VendorTemplateEditorType = {}));
var VendorTemplateDocumentDefaults = /** @class */function () {
  function VendorTemplateDocumentDefaults() {
    this.font_color = "";
    this.font_face = "";
    this.font_size = "";
  }
  return VendorTemplateDocumentDefaults;
}();

exports.VendorTemplateDocumentDefaults = VendorTemplateDocumentDefaults;
