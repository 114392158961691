'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.ClientRequestValues = void 0;
(function (ClientRequestValues) {
  ClientRequestValues[ClientRequestValues["PAGE_SIZE"] = 50] = "PAGE_SIZE";
})(exports.ClientRequestValues || (exports.ClientRequestValues = {}));
exports.OpportunityRequestValues = void 0;
(function (OpportunityRequestValues) {
  OpportunityRequestValues[OpportunityRequestValues["PAGE_SIZE"] = 50] = "PAGE_SIZE";
})(exports.OpportunityRequestValues || (exports.OpportunityRequestValues = {}));
var MeetingFormValuesCacheKey = "MEETING_FORM_VALUES";

exports.MeetingFormValuesCacheKey = MeetingFormValuesCacheKey;
