'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var common = require('../../common.js');

var VendorClient = /** @class */function () {
  function VendorClient(id, name, partner_companies, owner_user, sync_data, created, updated, vendor_opportunities, vendor_client_users, partner_manager) {
    if (sync_data === void 0) {
      sync_data = new Array();
    }
    this.id = "";
    this.name = "";
    this.owner_user_id = "";
    this.sync_data = new Array();
    this.website = "";
    this.fax = "";
    this.facebook_url = null;
    this.twitter_url = null;
    this.linked_in_url = null;
    this.partner_ids = [];
    this.billing_address = new common.Address();
    this.shipping_addresses = new Array();
    this.company_type = null;
    this.company_types = new Array();
    this.id = id;
    this.name = name;
    this.partner_companies = partner_companies;
    this.owner_user = owner_user;
    this.sync_data = sync_data;
    this.created = created;
    this.updated = updated;
    this.vendor_opportunities = vendor_opportunities;
    this.vendor_client_users = vendor_client_users;
    this.partner_manager = partner_manager;
  }
  return VendorClient;
}();
var VendorClientContact = /** @class */function () {
  function VendorClientContact() {
    this.id = "";
    this.is_primary = false;
    this.phone = "";
    this.email = "";
    this.name = new common.Name();
    this.contact_type = null;
    this.vendor_client_company_id = "";
    this.vendor_client_company_name = "";
    this.mobile = "";
    this.job_title = "";
    this.department = "";
  }
  return VendorClientContact;
}();
var VendorClientContactRequest = /** @class */function () {
  function VendorClientContactRequest(id) {
    this.filters = new Array();
    this.limit = 50;
    this.page = 1;
    this.clientId = "";
    this.keywords = "";
    this.selected_id = [];
    this.countQueryFlag = false;
    this.sortQuery = "";
    this.localeQuery = "";
    this.clientId = id;
  }
  return VendorClientContactRequest;
}();
var VendorClientCount = /** @class */function () {
  function VendorClientCount() {
    this.all = 0;
  }
  return VendorClientCount;
}();
var VendorClientResponse = /** @class */function () {
  function VendorClientResponse() {
    this.data = new Array();
    this.filtered_count_map = new VendorClientCount();
  }
  return VendorClientResponse;
}();
var VendorClientEditOrAddRequest = /** @class */function () {
  function VendorClientEditOrAddRequest(formValues) {
    var _a;
    this.name = "";
    this.fax = null;
    this.website = null;
    this.linked_in_url = null;
    this.facebook_url = null;
    this.twitter_url = null;
    this.company_type = null;
    this.billing_address = null;
    this.custom_fields = new Array();
    this.name = formValues.name;
    this.partner_manager_id = (_a = formValues.partner_manager_id) !== null && _a !== void 0 ? _a : null;
    this.owner_user_id = formValues.owner_user_id;
    this.fax = formValues.fax;
    this.website = formValues.website;
    this.linked_in_url = formValues.linked_in_url;
    this.facebook_url = formValues.facebook_url;
    this.twitter_url = formValues.twitter_url;
    this.company_type = formValues.company_type;
  }
  return VendorClientEditOrAddRequest;
}();
var VendorClientFormValues = /** @class */function () {
  function VendorClientFormValues() {
    this.name = "";
    this.owner_user_id = "";
    this.phone = null;
    this.country = null;
    this.state = null;
    this.city = null;
    this.street = null;
    this.pincode = null;
    this.fax = "";
    this.website = "";
    this.linked_in_url = null;
    this.facebook_url = null;
    this.twitter_url = null;
    this.company_type = null;
    this.custom_field_form_values = {};
  }
  return VendorClientFormValues;
}();

exports.VendorClient = VendorClient;
exports.VendorClientContact = VendorClientContact;
exports.VendorClientContactRequest = VendorClientContactRequest;
exports.VendorClientCount = VendorClientCount;
exports.VendorClientEditOrAddRequest = VendorClientEditOrAddRequest;
exports.VendorClientFormValues = VendorClientFormValues;
exports.VendorClientResponse = VendorClientResponse;
