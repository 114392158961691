import { EmailTemplatesActionTypes } from "@/Actions/Vendor/EmailTemplate";
import { VendorEmailTemplate } from "@zomentum/contracts/dist/Vendor/EmailTemplate";
import { MergeTagVariable } from "@zomentum/contracts/dist/Documents";

export const initialEmailTemplatesState = {
	isAddEmailTemplateDrawerOpen: false,
	isEditEmailTemplateDrawerOpen: false,
	templateList: new Array<VendorEmailTemplate>(),
	currentActiveEmailTemplate: null,
	mergeTagVariables: new Array<MergeTagVariable>()
};

export class EmailTemplatesState {
	readonly isAddEmailTemplateDrawerOpen: boolean = false;
	readonly isEditEmailTemplateDrawerOpen: boolean = false;
	readonly isLoading: boolean = false;
	readonly templateList: VendorEmailTemplate[] =
		new Array<VendorEmailTemplate>();
	readonly currentActiveEmailTemplate: VendorEmailTemplate | null = null;
	readonly mergeTagVariables: MergeTagVariable[] =
		new Array<MergeTagVariable>();
}

const EmailTemplatesReducer = (
	state = initialEmailTemplatesState,
	action: any
) => {
	switch (action.type) {
		case EmailTemplatesActionTypes.SHOW_ADD_EMAIL_TEMPLATES_DRAWER: {
			return {
				...state,
				isAddEmailTemplateDrawerOpen: true
			};
		}
		case EmailTemplatesActionTypes.HIDE_ADD_EMAIL_TEMPLATES_DRAWER: {
			return {
				...state,
				isAddEmailTemplateDrawerOpen: false
			};
		}
		case EmailTemplatesActionTypes.SHOW_EDIT_EMAIL_TEMPLATES_DRAWER: {
			return {
				...state,
				isEditEmailTemplateDrawerOpen: true
			};
		}
		case EmailTemplatesActionTypes.HIDE_EDIT_EMAIL_TEMPLATES_DRAWER: {
			return {
				...state,
				isEditEmailTemplateDrawerOpen: false
			};
		}
		case EmailTemplatesActionTypes.SHOW_EMAIL_TEMPLATES_LOADER: {
			return {
				...state,
				isLoading: true
			};
		}
		case EmailTemplatesActionTypes.HIDE_EMAIL_TEMPLATES_LOADER: {
			return {
				...state,
				isLoading: false
			};
		}
		case EmailTemplatesActionTypes.FETCH_ALL_EMAIL_TEMPLATES_REQUESTED: {
			return state;
		}
		case EmailTemplatesActionTypes.FETCH_ALL_EMAIL_TEMPLATES_SUCCESSFUL: {
			return {
				...state,
				templateList: action.templates
			};
		}
		case EmailTemplatesActionTypes.FETCH_ALL_EMAIL_TEMPLATES_FAILED: {
			return state;
		}
		case EmailTemplatesActionTypes.ADD_EMAIL_TEMPLATE_REQUESTED: {
			return state;
		}
		case EmailTemplatesActionTypes.ADD_EMAIL_TEMPLATE_SUCCESSFUL: {
			return {
				...state,
				templateList: [action.newTemplate].concat(state.templateList)
			};
		}
		case EmailTemplatesActionTypes.ADD_EMAIL_TEMPLATE_FAILED: {
			return state;
		}
		case EmailTemplatesActionTypes.DELETE_EMAIL_TEMPLATE_REQUESTED: {
			return state;
		}
		case EmailTemplatesActionTypes.DELETE_EMAIL_TEMPLATE_SUCCESSFUL: {
			return {
				...state,
				templateList: state.templateList.filter(
					template => template.id !== action.deletedTemplateId
				)
			};
		}
		case EmailTemplatesActionTypes.DELETE_EMAIL_TEMPLATE_FAILED: {
			return state;
		}
		case EmailTemplatesActionTypes.UPDATE_CURRENT_ACTIVE_EMAIL_TEMPLATE: {
			return {
				...state,
				currentActiveEmailTemplate: action.currentActiveEmailTemplate
			};
		}
		case EmailTemplatesActionTypes.RESET_CURRENT_ACTIVE_EMAIL_TEMPLATE: {
			return {
				...state,
				currentActiveEmailTemplate: null
			};
		}
		case EmailTemplatesActionTypes.UPDATE_EMAIL_TEMPLATE_REQUESTED: {
			return state;
		}
		case EmailTemplatesActionTypes.UPDATE_EMAIL_TEMPLATE_SUCCESSFUL: {
			return {
				...state,
				templateList: state.templateList.map(template => {
					if (template.id === action.updatedEmailTemplate.id) {
						return action.updatedEmailTemplate;
					}
					return template;
				})
			};
		}
		case EmailTemplatesActionTypes.UPDATE_EMAIL_TEMPLATE_FAILED: {
			return state;
		}
		case EmailTemplatesActionTypes.FETCH_MERGE_TAG_VARIABLES_REQUESTED: {
			return state;
		}
		case EmailTemplatesActionTypes.FETCH_MERGE_TAG_VARIABLES_SUCCESSFUL: {
			return {
				...state,
				mergeTagVariables: action.mergeTagVariables
			};
		}
		case EmailTemplatesActionTypes.FETCH_MERGE_TAG_VARIABLES_FAILED: {
			return state;
		}
		case EmailTemplatesActionTypes.RESET_EMAIL_TEMPLATE_MERGE_TAG_VARIABLES: {
			return {
				...state,
				mergeTagVariables: new Array<MergeTagVariable>()
			};
		}
		case EmailTemplatesActionTypes.RESET_EMAIL_TEMPLATES_LIST: {
			return {
				...state,
				templateList: new Array<VendorEmailTemplate>()
			};
		}
		case EmailTemplatesActionTypes.FETCH_EMAIL_TEMPLATE_BY_CUSTOM_TEMPLATE_TYPE_REQUESTED: {
			return state;
		}
		case EmailTemplatesActionTypes.FETCH_EMAIL_TEMPLATE_BY_CUSTOM_TEMPLATE_TYPE_SUCCESSFUL: {
			return state;
		}
		case EmailTemplatesActionTypes.FETCH_EMAIL_TEMPLATE_BY_CUSTOM_TEMPLATE_TYPE_FAILED: {
			return state;
		}
		default:
			return state;
	}
};

export default EmailTemplatesReducer;
