'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var ComissionRevenuePerMonth = /** @class */function () {
  function ComissionRevenuePerMonth(month) {
    this.month = month;
  }
  return ComissionRevenuePerMonth;
}();
var ComissionDashboardInsights = /** @class */function () {
  function ComissionDashboardInsights(commission_per_month) {
    this.total_commission = 0;
    this.pending_commission = 0;
    this.commission_per_month = commission_per_month;
  }
  return ComissionDashboardInsights;
}();

exports.ComissionDashboardInsights = ComissionDashboardInsights;
exports.ComissionRevenuePerMonth = ComissionRevenuePerMonth;
