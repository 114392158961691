import {
	CreateLibraryItemRequest,
	GetLibraryRequest,
	LibraryItem,
	UserEmailSignature
} from "@zomentum/contracts/dist/Users";
import { Action, ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { GlobalState } from "@/Reducers/interface";

import {
	createLibraryResource,
	deleteLibraryResource,
	fetchUserSignature,
	getLibraryData,
	updateUserSignature
} from "@/Services/User";
import { deleteCompanyEmailIntegration } from "@/Services/Settings";

export enum UsersActionTypes {
	SHOW_LIBRARY_LOADER = "SHOW_LIBRARY_LOADER",
	HIDE_LIBRARY_LOADER = "HIDE_LIBRARY_LOADER",
	DELETE_COMPANY_EMAIL_INTEGRATION_REQUESTED = "DELETE_COMPANY_EMAIL_INTEGRATION_REQUESTED",
	DELETE_COMPANY_EMAIL_INTEGRATION_SUCCESSFUL = "DELETE_COMPANY_EMAIL_INTEGRATION_SUCCESSFUL",
	DELETE_COMPANY_EMAIL_INTEGRATION_FAILED = "DELETE_COMPANY_EMAIL_INTEGRATION_FAILED",
	// Library
	FETCH_LIBRARY_LIST_REQUESTED = "FETCH_LIBRARY_LIST_REQUESTED",
	FETCH_LIBRARY_LIST_SUCCESSFUL = "FETCH_LIBRARY_LIST_SUCCESSFUL",
	FETCH_LIBRARY_LIST_FAILED = "FETCH_LIBRARY_LIST_FAILED",
	SAVE_BLOCK_TO_LIBRARY_LIST_REQUESTED = "SAVE_BLOCK_TO_LIBRARY_LIST_REQUESTED",
	SAVE_BLOCK_TO_LIBRARY_LIST_SUCCESSFUL = "SAVE_BLOCK_TO_LIBRARY_LIST_SUCCESSFUL",
	SAVE_BLOCK_TO_LIBRARY_LIST_FAILED = "SAVE_BLOCK_TO_LIBRARY_LIST_FAILED",
	DELETE_BLOCK_TO_LIBRARY_LIST_REQUESTED = "DELETE_BLOCK_TO_LIBRARY_LIST_REQUESTED",
	DELETE_BLOCK_TO_LIBRARY_LIST_SUCCESSFUL = "DELETE_BLOCK_TO_LIBRARY_LIST_SUCCESSFUL",
	DELETE_BLOCK_TO_LIBRARY_LIST_FAILED = "DELETE_BLOCK_TO_LIBRARY_LIST_FAILED",

	FETCH_EMAIL_SIGNATURES_REQUESTED = "FETCH_EMAIL_SIGNATURES_REQUESTED",
	FETCH_EMAIL_SIGNATURES_SUCCESSFUL = "FETCH_EMAIL_SIGNATURES_SUCCESSFUL",
	FETCH_EMAIL_SIGNATURES_FAILED = "FETCH_EMAIL_SIGNATURES_FAILED",

	UPDATE_EMAIL_SIGNATURES_REQUESTED = "UPDATE_EMAIL_SIGNATURES_REQUESTED",
	UPDATE_EMAIL_SIGNATURES_SUCCESSFUL = "UPDATE_EMAIL_SIGNATURES_SUCCESSFUL",
	UPDATE_EMAIL_SIGNATURES_FAILED = "UPDATE_EMAIL_SIGNATURES_FAILED"
}

export type ShowLibraryLoaderAction =
	Action<UsersActionTypes.SHOW_LIBRARY_LOADER>;
export type HideLibraryLoaderAction =
	Action<UsersActionTypes.HIDE_LIBRARY_LOADER>;

export type DeleteCompanyEmailIntegrationRequestedAction =
	Action<UsersActionTypes.DELETE_COMPANY_EMAIL_INTEGRATION_REQUESTED>;
export type DeleteCompanyEmailIntegrationSuccessfulAction =
	Action<UsersActionTypes.DELETE_COMPANY_EMAIL_INTEGRATION_SUCCESSFUL>;
export type DeleteCompanyEmailIntegrationFailedAction =
	Action<UsersActionTypes.DELETE_COMPANY_EMAIL_INTEGRATION_FAILED>;

export type FetchLibraryListRequestedAction =
	Action<UsersActionTypes.FETCH_LIBRARY_LIST_REQUESTED>;

export interface FetchLibraryListSuccessfulAction
	extends Action<UsersActionTypes.FETCH_LIBRARY_LIST_SUCCESSFUL> {
	readonly libraryList: LibraryItem[];
}

export type FetchLibraryListFailedAction =
	Action<UsersActionTypes.FETCH_LIBRARY_LIST_FAILED>;
export type SaveBlockToLibraryListRequestedAction =
	Action<UsersActionTypes.SAVE_BLOCK_TO_LIBRARY_LIST_REQUESTED>;
export type SaveBlockToLibraryListSuccessfulAction =
	Action<UsersActionTypes.SAVE_BLOCK_TO_LIBRARY_LIST_SUCCESSFUL>;
export type SaveBlockToLibraryListFailedAction =
	Action<UsersActionTypes.SAVE_BLOCK_TO_LIBRARY_LIST_FAILED>;

export type DeleteBlockToLibraryListRequestedAction =
	Action<UsersActionTypes.DELETE_BLOCK_TO_LIBRARY_LIST_REQUESTED>;
export type DeleteBlockToLibraryListSuccessfulAction =
	Action<UsersActionTypes.DELETE_BLOCK_TO_LIBRARY_LIST_SUCCESSFUL>;
export type DeleteBlockToLibraryListFailedAction =
	Action<UsersActionTypes.DELETE_BLOCK_TO_LIBRARY_LIST_FAILED>;

export type UpdateEmailSignaturesRequested =
	Action<UsersActionTypes.UPDATE_EMAIL_SIGNATURES_REQUESTED>;

export interface UpdateEmailSignaturesSuccessful
	extends Action<UsersActionTypes.UPDATE_EMAIL_SIGNATURES_SUCCESSFUL> {
	readonly emailSignature: UserEmailSignature;
}

export type UpdateEmailSignaturesFailed =
	Action<UsersActionTypes.UPDATE_EMAIL_SIGNATURES_FAILED>;

export type FetchAllEmailSignaturesRequested =
	Action<UsersActionTypes.FETCH_EMAIL_SIGNATURES_REQUESTED>;

export interface FetchAllEmailSignaturesSuccessful
	extends Action<UsersActionTypes.FETCH_EMAIL_SIGNATURES_SUCCESSFUL> {
	readonly emailSignature: UserEmailSignature;
}

export type FetchAllEmailSignaturesFailed =
	Action<UsersActionTypes.FETCH_EMAIL_SIGNATURES_FAILED>;

// export type ResetUserStateAction = Action<UsersActionTypes.RESET_USER_STATE>;

export type UsersActions =
	| DeleteCompanyEmailIntegrationRequestedAction
	| DeleteCompanyEmailIntegrationSuccessfulAction
	| DeleteCompanyEmailIntegrationFailedAction
	// library
	| ShowLibraryLoaderAction
	| HideLibraryLoaderAction
	| FetchLibraryListRequestedAction
	| FetchLibraryListSuccessfulAction
	| FetchLibraryListFailedAction
	| SaveBlockToLibraryListRequestedAction
	| SaveBlockToLibraryListSuccessfulAction
	| SaveBlockToLibraryListFailedAction
	| DeleteBlockToLibraryListRequestedAction
	| DeleteBlockToLibraryListSuccessfulAction
	| DeleteBlockToLibraryListFailedAction
	| UpdateEmailSignaturesRequested
	| UpdateEmailSignaturesSuccessful
	| UpdateEmailSignaturesFailed
	| FetchAllEmailSignaturesRequested
	| FetchAllEmailSignaturesSuccessful
	| FetchAllEmailSignaturesFailed;

export interface UserDispatch {
	showLibraryLoader: ActionCreator<
		ThunkAction<
			ShowLibraryLoaderAction,
			GlobalState,
			null,
			ShowLibraryLoaderAction
		>
	>;
	hideLibraryLoader: ActionCreator<
		ThunkAction<
			HideLibraryLoaderAction,
			GlobalState,
			null,
			HideLibraryLoaderAction
		>
	>;
	deleteCompanyEmailIntegration: ActionCreator<
		ThunkAction<
			Promise<DeleteCompanyEmailIntegrationSuccessfulAction>,
			GlobalState,
			null,
			DeleteCompanyEmailIntegrationSuccessfulAction
		>
	>;
	fetchLibrary: ActionCreator<
		ThunkAction<
			Promise<FetchLibraryListSuccessfulAction>,
			GlobalState,
			GetLibraryRequest,
			FetchLibraryListSuccessfulAction
		>
	>;
	saveBlockToLibrary: ActionCreator<
		ThunkAction<
			Promise<SaveBlockToLibraryListSuccessfulAction>,
			GlobalState,
			CreateLibraryItemRequest,
			SaveBlockToLibraryListSuccessfulAction
		>
	>;
	deleteBlockFromLibrary: ActionCreator<
		ThunkAction<
			Promise<DeleteBlockToLibraryListSuccessfulAction>,
			GlobalState,
			{ libraryItemId: string },
			DeleteBlockToLibraryListSuccessfulAction
		>
	>;
	fetchAllEmailSignatures: ActionCreator<
		ThunkAction<
			Promise<FetchAllEmailSignaturesSuccessful>,
			GlobalState,
			null,
			FetchAllEmailSignaturesSuccessful
		>
	>;
	updateEmailSignatures: ActionCreator<
		ThunkAction<
			Promise<UpdateEmailSignaturesSuccessful>,
			GlobalState,
			UserEmailSignature,
			UpdateEmailSignaturesSuccessful
		>
	>;
}

export const UsersActionCreators: UserDispatch = {
	showLibraryLoader: () => {
		return (dispatch: Dispatch): ShowLibraryLoaderAction => {
			const showLibraryLoaderAction: ShowLibraryLoaderAction = {
				type: UsersActionTypes.SHOW_LIBRARY_LOADER
			};
			return dispatch(showLibraryLoaderAction);
		};
	},
	hideLibraryLoader: () => {
		return (dispatch: Dispatch): HideLibraryLoaderAction => {
			const hideLibraryLoaderAction: HideLibraryLoaderAction = {
				type: UsersActionTypes.HIDE_LIBRARY_LOADER
			};
			return dispatch(hideLibraryLoaderAction);
		};
	},
	deleteCompanyEmailIntegration: () => {
		return async (
			dispatch: Dispatch
		): Promise<DeleteCompanyEmailIntegrationSuccessfulAction> => {
			const deleteCompanyEmailIntegrationRequestedAction: DeleteCompanyEmailIntegrationRequestedAction =
				{
					type: UsersActionTypes.DELETE_COMPANY_EMAIL_INTEGRATION_REQUESTED
				};
			dispatch(deleteCompanyEmailIntegrationRequestedAction);
			try {
				await deleteCompanyEmailIntegration();
				const deleteCompanyEmailIntegrationSuccessfulAction: DeleteCompanyEmailIntegrationSuccessfulAction =
					{
						type: UsersActionTypes.DELETE_COMPANY_EMAIL_INTEGRATION_SUCCESSFUL
					};
				return dispatch(deleteCompanyEmailIntegrationSuccessfulAction);
			} catch (error) {
				const deleteCompanyEmailIntegrationFailedAction: DeleteCompanyEmailIntegrationFailedAction =
					{
						type: UsersActionTypes.DELETE_COMPANY_EMAIL_INTEGRATION_FAILED
					};
				dispatch(deleteCompanyEmailIntegrationFailedAction);

				return Promise.reject(error);
			}
		};
	},
	fetchLibrary:
		fetchLibraryRequest =>
		async (
			dispatch: Dispatch
		): Promise<FetchLibraryListSuccessfulAction> => {
			try {
				const fetchLibraryListRequested: FetchLibraryListRequestedAction =
					{
						type: UsersActionTypes.FETCH_LIBRARY_LIST_REQUESTED
					};
				dispatch(fetchLibraryListRequested);

				const libraryListResponse = await getLibraryData(
					fetchLibraryRequest
				);

				const fetchLibraryListSuccessfulAction: FetchLibraryListSuccessfulAction =
					{
						type: UsersActionTypes.FETCH_LIBRARY_LIST_SUCCESSFUL,
						libraryList: libraryListResponse.data
					};
				return dispatch(fetchLibraryListSuccessfulAction);
			} catch (error) {
				const fetchLibraryListFailedAction: FetchLibraryListFailedAction =
					{
						type: UsersActionTypes.FETCH_LIBRARY_LIST_FAILED
					};
				dispatch(fetchLibraryListFailedAction);

				return Promise.reject(error);
			}
		},
	saveBlockToLibrary:
		createLibraryItemRequest =>
		async (
			dispatch: Dispatch
		): Promise<SaveBlockToLibraryListSuccessfulAction> => {
			try {
				const saveBlockToLibraryListRequested: SaveBlockToLibraryListRequestedAction =
					{
						type: UsersActionTypes.SAVE_BLOCK_TO_LIBRARY_LIST_REQUESTED
					};
				dispatch(saveBlockToLibraryListRequested);

				await createLibraryResource(createLibraryItemRequest);

				const saveBlockToLibraryListSuccessfulAction: SaveBlockToLibraryListSuccessfulAction =
					{
						type: UsersActionTypes.SAVE_BLOCK_TO_LIBRARY_LIST_SUCCESSFUL
					};
				return dispatch(saveBlockToLibraryListSuccessfulAction);
			} catch (error) {
				const saveBlockToLibraryListFailedAction: SaveBlockToLibraryListFailedAction =
					{
						type: UsersActionTypes.SAVE_BLOCK_TO_LIBRARY_LIST_FAILED
					};
				dispatch(saveBlockToLibraryListFailedAction);

				return Promise.reject(error);
			}
		},
	deleteBlockFromLibrary:
		({ libraryItemId }: { libraryItemId: string }) =>
		async (
			dispatch: Dispatch
		): Promise<DeleteBlockToLibraryListSuccessfulAction> => {
			try {
				const deleteBlockToLibraryListRequestedAction: DeleteBlockToLibraryListRequestedAction =
					{
						type: UsersActionTypes.DELETE_BLOCK_TO_LIBRARY_LIST_REQUESTED
					};
				dispatch(deleteBlockToLibraryListRequestedAction);

				await deleteLibraryResource({ libraryItemId });

				const saveBlockToLibraryListSuccessfulAction: DeleteBlockToLibraryListSuccessfulAction =
					{
						type: UsersActionTypes.DELETE_BLOCK_TO_LIBRARY_LIST_SUCCESSFUL
					};
				return dispatch(saveBlockToLibraryListSuccessfulAction);
			} catch (error) {
				const saveBlockToLibraryListFailedAction: DeleteBlockToLibraryListFailedAction =
					{
						type: UsersActionTypes.DELETE_BLOCK_TO_LIBRARY_LIST_FAILED
					};
				dispatch(saveBlockToLibraryListFailedAction);

				return Promise.reject(error);
			}
		},
	fetchAllEmailSignatures: () => {
		return async (
			dispatch: Dispatch
		): Promise<FetchAllEmailSignaturesSuccessful> => {
			const fetchAllEmailSignaturesRequestedAction: FetchAllEmailSignaturesRequested =
				{
					type: UsersActionTypes.FETCH_EMAIL_SIGNATURES_REQUESTED
				};
			dispatch(fetchAllEmailSignaturesRequestedAction);
			try {
				const response = await fetchUserSignature();
				const fetchAllEmailSignaturesSuccessfulAction: FetchAllEmailSignaturesSuccessful =
					{
						type: UsersActionTypes.FETCH_EMAIL_SIGNATURES_SUCCESSFUL,
						emailSignature: response
					};
				return dispatch(fetchAllEmailSignaturesSuccessfulAction);
			} catch (error) {
				const fetchAllEmailSignaturesFailedAction: FetchAllEmailSignaturesFailed =
					{
						type: UsersActionTypes.FETCH_EMAIL_SIGNATURES_FAILED
					};
				dispatch(fetchAllEmailSignaturesFailedAction);

				return Promise.reject(error);
			}
		};
	},
	updateEmailSignatures: (updatedSignatureList: UserEmailSignature) => {
		return async (
			dispatch: Dispatch
		): Promise<UpdateEmailSignaturesSuccessful> => {
			const updateEmailSignaturesRequestedAction: UpdateEmailSignaturesRequested =
				{
					type: UsersActionTypes.UPDATE_EMAIL_SIGNATURES_REQUESTED
				};
			dispatch(updateEmailSignaturesRequestedAction);
			try {
				const response = await updateUserSignature(
					updatedSignatureList
				);
				const updateEmailSignaturesSuccessfulAction: UpdateEmailSignaturesSuccessful =
					{
						type: UsersActionTypes.UPDATE_EMAIL_SIGNATURES_SUCCESSFUL,
						emailSignature: response
					};
				return dispatch(updateEmailSignaturesSuccessfulAction);
			} catch (error) {
				const updateEmailSignaturesFailedAction: UpdateEmailSignaturesFailed =
					{
						type: UsersActionTypes.UPDATE_EMAIL_SIGNATURES_FAILED
					};
				dispatch(updateEmailSignaturesFailedAction);

				return Promise.reject(error);
			}
		};
	}
};

export default UsersActionCreators;
