import React, { useState } from "react";
import ZDynamicSelect from "@zomentum/molecules/dist/ZDynamicSelect";
import { IClientContactSelectProps } from "./interface";
import {
	IOptionGroupProps,
	IOptionProps
} from "@zomentum/atoms/dist/ZSelect/interface";
import { TableQueryFilter } from "@zomentum/contracts";
import { VendorClientContactRequest } from "@zomentum/contracts";
import { getAllVendorClientContacts } from "@/Services/Vendor/Client";
import { VendorClientContact } from "@zomentum/contracts/dist/Vendor/Clients";
import { ZMessage } from "@zomentum/molecules";

const ZVendorClientContactSelect: React.FC<
	IClientContactSelectProps
> = props => {
	const { defaultOptions, className, onChange, ...rest } = props;

	const formattedDefaultOptions =
		defaultOptions?.map(currOption => ({
			name: `${currOption.name.full} ${
				currOption.email ? `<${currOption.email}>` : ""
			}`,
			value: currOption.id
		})) ?? [];

	const [contacts, setContacts] = useState<IOptionProps[]>([]);

	const searchContacts = async (value: string) => {
		try {
			if (!props.selectedClient) {
				ZMessage.error("Please select a client");
				return;
			}
			const clientContactRequest: VendorClientContactRequest = {
				filters: new Array<TableQueryFilter>(),
				page: 1,
				limit: 25,
				clientId: props.selectedClient,
				keywords: value
			};
			const searchResult = await getAllVendorClientContacts(
				clientContactRequest
			);
			setContacts(
				searchResult.data.map(entity => {
					const client = entity as VendorClientContact;
					return {
						name: `${client.name.full} ${
							client.email ? `<${client.email}>` : ""
						}`,
						value: client.id,
						disabled: props.disabledContacts?.includes(client.id)
					};
				})
			);
		} catch (error) {
			console.error(error);
		}
	};

	const optionsGroup: IOptionGroupProps[] = [
		{
			label: "Contacts Please type at least 3 letters to start searching contacts",
			selectOptions: contacts
		}
	];

	if (props.optGroup) optionsGroup.unshift(...props.optGroup);

	return (
		<ZDynamicSelect
			defaultOptions={formattedDefaultOptions}
			fetchData={searchContacts}
			options={contacts}
			onChange={onChange}
			className={className}
			dropdownRender={menu => {
				return (
					<div>
						{menu}
						{!props.hideAddClientContactAction && (
							<div
								className="add-entity-action"
								onMouseDown={$event => {
									$event.preventDefault();
								}}
								onClick={props?.showAddClientContactDrawer}
							>
								Add Contact
							</div>
						)}
					</div>
				);
			}}
			isOptionsGroup={!!props.optGroup?.length}
			optionsGroup={optionsGroup}
			{...rest}
		/>
	);
};

export default ZVendorClientContactSelect;
