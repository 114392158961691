'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index = require('../Documents/index.js');

var _a, _b, _c, _d, _e, _f, _g, _h;
var AssignUserToSignatureBlock = /** @class */function () {
  function AssignUserToSignatureBlock() {
    this.id = "";
    this.block_id = "";
  }
  return AssignUserToSignatureBlock;
}();
var TemplatesCount = /** @class */function () {
  function TemplatesCount() {
    this[_a] = 0;
    this[_b] = 0;
    this[_c] = 0;
    this[_d] = 0;
    this[_e] = 0;
    this[_f] = 0;
    this[_g] = 0;
    this[_h] = 0;
  }
  return TemplatesCount;
}();
_a = index.DocumentType.GENERIC_ORDER_FORM, _b = index.DocumentType.SALES_PRESENTATION, _c = index.DocumentType.QUOTE, _d = index.DocumentType.QBR, _e = index.DocumentType.PROPOSAL, _f = index.DocumentType.SPECIFIC_ORDER_FORM, _g = index.DocumentType.ASSESSMENT, _h = index.DocumentType.CONTRACT;
exports.TemplateFilterType = void 0;
(function (TemplateFilterType) {
  TemplateFilterType["Global"] = "global";
  TemplateFilterType["Custom"] = "custom";
})(exports.TemplateFilterType || (exports.TemplateFilterType = {}));
exports.TemplateSidePanelEnum = void 0;
(function (TemplateSidePanelEnum) {
  TemplateSidePanelEnum["EDIT"] = "EDIT";
  TemplateSidePanelEnum["SETTINGS"] = "SETTINGS";
  TemplateSidePanelEnum["ISSUES"] = "ISSUES";
})(exports.TemplateSidePanelEnum || (exports.TemplateSidePanelEnum = {}));
exports.Error = void 0;
(function (Error) {
  Error["SUCCESS"] = "success";
  Error["ERROR"] = "error";
})(exports.Error || (exports.Error = {}));
exports.TemplateOwnerType = void 0;
(function (TemplateOwnerType) {
  TemplateOwnerType["Global"] = "Global";
  TemplateOwnerType["Company"] = "Company";
})(exports.TemplateOwnerType || (exports.TemplateOwnerType = {}));

exports.AssignUserToSignatureBlock = AssignUserToSignatureBlock;
exports.TemplatesCount = TemplatesCount;
