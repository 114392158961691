'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var _commonjsHelpers = require('./_commonjsHelpers.js');
var index = require('../node_modules/@ant-design/compatible/node_modules/@ant-design/icons/es/index.js');

var require$$6 = /*@__PURE__*/_commonjsHelpers.getAugmentedNamespace(index);

exports["default"] = require$$6;
