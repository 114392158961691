import React, { ReactNode } from "react";
import { Empty, Skeleton } from "antd";
import ZInputNumber from "@zomentum/atoms/dist/ZInputNumber";
import ZInput from "@zomentum/molecules/dist/ZInput";
import ZSelect from "@zomentum/atoms/dist/ZSelect";
import ZDatePicker from "@zomentum/molecules/dist/ZDatePicker";
import { LabeledValue } from "antd/lib/select";
import {
	SalesActivityAutomationActionUpdatableFieldValue,
	SalesActivityAutomationMetadataFilterOperator,
	SalesActivityAutomationMetadataFilterValueTypes,
	MultipleValueSalesActivityAutomationMetadataFilterOperators
} from "@zomentum/contracts/dist/Others/SalesActivity";
import {
	Dictionary,
	filterSelectByTextInput
} from "@zomentum/contracts/dist/Utils";
import groupBy from "lodash/groupBy";
import moment from "moment";
import { IFieldForValue } from "./interface";
import {
	getZomentumEntityDisplayValue,
	ZomentumEntities
} from "@zomentum/contracts";
import ZIcon, { Icons } from "@zomentum/atoms/dist/ZIcon";
import { EColors } from "@zomentum/atoms/dist/ZColors/interface";

export default ({
	taxCategories,
	taxRegions,
	currentActiveFilter,
	initialLabelvalue,
	selectedMetaFilter,
	handleCurrentFilterSelectArrayValue,
	handleCurrentFilterValue,
	dynamicDataLoader,
	dynamicSearchTerm,
	dynamicSearchResult,
	handleSearch,
	getOptionsProps,
	handleCurrentFilterSelectValue,
	handleCurrentFilterDateValue
}: IFieldForValue): ReactNode => {
	const labeledValueArray: LabeledValue[] | undefined = !!currentActiveFilter
		?.values?.length
		? currentActiveFilter.values.reduce(
				(finalValue: LabeledValue[], current) => {
					finalValue.push({
						label: initialLabelvalue(current),
						value: current.id_value ?? ""
					});
					return finalValue;
				},
				[]
		  )
		: undefined;

	const labeledValue: LabeledValue | undefined = !!currentActiveFilter?.values
		?.length
		? {
				label: initialLabelvalue(currentActiveFilter?.values[0]),
				value: currentActiveFilter?.values[0].id_value ?? ""
		  }
		: undefined;

	const stringValue = !!currentActiveFilter?.values?.length
		? currentActiveFilter.values[0].display_value + ""
		: undefined;

	const stringValueDays = !!currentActiveFilter?.values?.length
		? currentActiveFilter.values[0].display_value?.replace(" days", "") + ""
		: undefined;

	const numberValue = !!currentActiveFilter?.values?.length
		? Number(currentActiveFilter?.values[0].display_value)
		: undefined;

	const selectPlaceholder = !!currentActiveFilter
		? `Select value for ${
				currentActiveFilter.field_display_name
		  } of ${getZomentumEntityDisplayValue(
				currentActiveFilter.filter_entity_type,
				true
		  )}`
		: undefined;

	switch (selectedMetaFilter?.value_type) {
		case SalesActivityAutomationMetadataFilterValueTypes.PlainText: {
			if (!currentActiveFilter?.filter_operator?.id_value) {
				throw new Error("No operator selected");
			}

			return MultipleValueSalesActivityAutomationMetadataFilterOperators.includes(
				currentActiveFilter?.filter_operator?.id_value
			) ? (
				<ZSelect<LabeledValue[]>
					placeholder={selectPlaceholder}
					mode="tags"
					getPopupContainer={trigger => trigger.parentNode}
					notFoundContent={null}
					autoClearSearchValue={true}
					dropdownClassName={"display-none"}
					tokenSeparators={[","]}
					labelInValue={true}
					showSearch={true}
					value={labeledValueArray}
					onChange={handleCurrentFilterSelectArrayValue}
				/>
			) : (
				<ZInput
					value={stringValue}
					width="full"
					placeholder={`Enter value for ${currentActiveFilter.field_display_name}`}
					onChange={e => handleCurrentFilterValue(e.target.value)}
				/>
			);
		}

		case SalesActivityAutomationMetadataFilterValueTypes.DoubleNumber:
		case SalesActivityAutomationMetadataFilterValueTypes.IntNumber:
		case SalesActivityAutomationMetadataFilterValueTypes.LongNumber: {
			if (!currentActiveFilter?.filter_operator?.id_value) {
				throw new Error("No operator selected");
			}

			return MultipleValueSalesActivityAutomationMetadataFilterOperators.includes(
				currentActiveFilter?.filter_operator?.id_value
			) ? (
				<ZSelect<LabeledValue[]>
					placeholder={selectPlaceholder}
					mode="tags"
					getPopupContainer={trigger => trigger.parentNode}
					notFoundContent={null}
					autoClearSearchValue={true}
					dropdownClassName={"display-none"}
					tokenSeparators={[",", " "]}
					labelInValue={true}
					showSearch={true}
					onChange={handleCurrentFilterSelectArrayValue}
					value={labeledValueArray}
				/>
			) : (
				<ZInputNumber
					value={numberValue}
					width="full"
					placeholder={`Enter value for ${currentActiveFilter.field_display_name}`}
					onChange={handleCurrentFilterValue}
				/>
			);
		}

		case SalesActivityAutomationMetadataFilterValueTypes.FixedListDropdown: {
			if (!currentActiveFilter?.filter_operator?.id_value) {
				throw new Error("No operator selected");
			}
			if (!selectedMetaFilter) {
				throw new Error("No filter selected");
			}
			const allowedEntityTypesForDynamicSearch = [
				ZomentumEntities.Opportunity,
				ZomentumEntities.VendorClient,
				ZomentumEntities.ClientContact,
				ZomentumEntities.VendorPartner
			];

			const shouldSearchDynamically =
				!!currentActiveFilter?.linked_entity_type &&
				allowedEntityTypesForDynamicSearch.some(
					e => e === currentActiveFilter.linked_entity_type
				);
			let allOptions = [...(selectedMetaFilter.valid_values ?? [])];
			let refactoredOptions: Dictionary<
				SalesActivityAutomationActionUpdatableFieldValue<string>[]
			> | null = null;

			if (shouldSearchDynamically) {
				allOptions = [...allOptions, ...dynamicSearchResult];
			}

			if (currentActiveFilter.field_name === "tax_region_id") {
				const convertedTaxRegion: SalesActivityAutomationActionUpdatableFieldValue<string>[] =
					taxRegions.map(region => ({
						id_value: region.id,
						display_value: region.name,
						value_type: null
					}));

				allOptions = [...allOptions, ...convertedTaxRegion];
			}

			if (currentActiveFilter.field_name === "tax_category_id") {
				const convertedTaxCategory: SalesActivityAutomationActionUpdatableFieldValue<string>[] =
					taxCategories.map(region => ({
						id_value: region.id,
						display_value: region.name,
						value_type: null
					}));

				allOptions = [...allOptions, ...convertedTaxCategory];
			}

			if (
				currentActiveFilter.field_name ===
				"opportunity_pipeline_stage_id"
			) {
				refactoredOptions = groupBy(allOptions, "group_type");
			}

			return MultipleValueSalesActivityAutomationMetadataFilterOperators.includes(
				currentActiveFilter?.filter_operator?.id_value
			) ? (
				<ZSelect
					mode="multiple"
					placeholder={selectPlaceholder}
					showArrow={shouldSearchDynamically ? true : false}
					suffixIcon={
						shouldSearchDynamically ? (
							<ZIcon
								name={Icons.SEARCH}
								size="sm"
								className="-mt-1"
								color={EColors.GRAY}
								colorVariant={6}
							/>
						) : undefined
					}
					filterOption={filterSelectByTextInput}
					labelInValue={true}
					loading={dynamicDataLoader}
					notFoundContent={
						dynamicSearchTerm && dynamicSearchTerm.length >= 3 ? (
							dynamicDataLoader ? (
								<Skeleton
									title={false}
									active
									paragraph={{ rows: 1 }}
								/>
							) : (
								<Empty />
							)
						) : shouldSearchDynamically ? (
							<div className="flex items-center justify-center p-4">
								Please type at least 3 letters to start search
							</div>
						) : (
							<Empty />
						)
					}
					onSearch={
						shouldSearchDynamically ? handleSearch : undefined
					}
					onChange={handleCurrentFilterSelectArrayValue}
					value={labeledValueArray}
					{...getOptionsProps(refactoredOptions, allOptions)}
				/>
			) : (
				<ZSelect
					value={labeledValue}
					showSearch={shouldSearchDynamically ? true : false}
					suffixIcon={
						shouldSearchDynamically ? (
							<ZIcon
								name={Icons.SEARCH}
								size="sm"
								className="-mt-1"
								color={EColors.GRAY}
								colorVariant={6}
							/>
						) : undefined
					}
					placeholder={selectPlaceholder}
					filterOption={filterSelectByTextInput}
					labelInValue={true}
					loading={dynamicDataLoader}
					notFoundContent={
						dynamicSearchTerm && dynamicSearchTerm.length >= 3 ? (
							dynamicDataLoader ? (
								<Skeleton
									title={false}
									active
									paragraph={{ rows: 1 }}
								/>
							) : (
								<Empty />
							)
						) : shouldSearchDynamically ? (
							<div className="flex items-center justify-center p-4">
								Please type at least 3 letters to start search
							</div>
						) : (
							<Empty />
						)
					}
					onSearch={
						shouldSearchDynamically ? handleSearch : undefined
					}
					onChange={handleCurrentFilterSelectValue}
					className="w-full"
					{...getOptionsProps(refactoredOptions, allOptions)}
				/>
			);
		}

		case SalesActivityAutomationMetadataFilterValueTypes.Date: {
			if (!currentActiveFilter?.filter_operator?.id_value) {
				throw new Error("No operator selected");
			}

			const dateValue = !!currentActiveFilter.values.length
				? moment(currentActiveFilter.values[0].display_value)
				: undefined;

			const showInputField = [
				SalesActivityAutomationMetadataFilterOperator.IsBefore,
				SalesActivityAutomationMetadataFilterOperator.IsAfter,
				SalesActivityAutomationMetadataFilterOperator.IsExactlyIn,
				SalesActivityAutomationMetadataFilterOperator.IsInNext,
				SalesActivityAutomationMetadataFilterOperator.IsAfterNext,
				SalesActivityAutomationMetadataFilterOperator.IsExactly
			];

			const showDatePicker = !showInputField.includes(
				currentActiveFilter.filter_operator.id_value
			);

			return showDatePicker ? (
				<ZDatePicker
					value={dateValue}
					placeholder={`Enter value for ${currentActiveFilter.field_display_name}`}
					onChange={value =>
						handleCurrentFilterDateValue(
							value,
							currentActiveFilter.filter_operator?.id_value
						)
					}
				/>
			) : (
				<ZInput
					value={stringValueDays}
					width="full"
					type="number"
					onChange={e =>
						handleCurrentFilterValue(e.target.value, true)
					}
					placeholder={`Enter value for ${currentActiveFilter.field_display_name}`}
					addonAfter={`Days ${
						currentActiveFilter.filter_operator.id_value ===
						SalesActivityAutomationMetadataFilterOperator.IsBefore
							? "Ago"
							: currentActiveFilter.filter_operator.id_value ===
							  SalesActivityAutomationMetadataFilterOperator.IsAfter
							? "After"
							: ""
					}`}
				/>
			);
		}

		default:
			throw new Error(
				`Invalid value type ${selectedMetaFilter?.value_type}`
			);
	}
};
