import { Reducer } from "redux";

import {
	EmailThreadMessage,
	OtherEntityEmailPreview
} from "@zomentum/contracts/dist/Others/EmailThread";
import { MergeTagVariable } from "@zomentum/contracts/dist/Documents";

import {
	EmailThreadActionTypes,
	EmailThreadActions
} from "../../../Actions/Others/EmailThread";

export interface EmailThreadState {
	readonly isLoading: boolean;
	readonly emailThreadMessageDrawerOpen: boolean;
	readonly missingEmailModalOpen: boolean;
	readonly emailThreadMessages: EmailThreadMessage[];
	readonly emailMergeTagsSystemVariable: MergeTagVariable[];
	readonly documentEmailPreview: string | null;
	readonly otherEntityEmailPreview: OtherEntityEmailPreview | null;
	readonly ms365ConsentEmailPreview: string | null;
	readonly emptyMergeTags: string[] | null;
	readonly isSendingMs365AssessmentsInviteToClient: boolean;
	readonly sendEmailWithoutDocument: boolean;
}

export const initialEmailThreadState: EmailThreadState = {
	isLoading: false,
	emailThreadMessageDrawerOpen: false,
	missingEmailModalOpen: false,
	emailThreadMessages: new Array<EmailThreadMessage>(),
	emailMergeTagsSystemVariable: new Array<MergeTagVariable>(),
	documentEmailPreview: null,
	otherEntityEmailPreview: null,
	ms365ConsentEmailPreview: null,
	emptyMergeTags: null,
	isSendingMs365AssessmentsInviteToClient: false,
	sendEmailWithoutDocument: false
};

export const EmailThreadReducer: Reducer<
	EmailThreadState,
	EmailThreadActions
> = (
	state: EmailThreadState = initialEmailThreadState,
	action: EmailThreadActions
): EmailThreadState => {
	switch (action.type) {
		case EmailThreadActionTypes.SHOW_EMAIL_THREAD_LOADER: {
			return {
				...state,
				isLoading: true
			};
		}
		case EmailThreadActionTypes.HIDE_EMAIL_THREAD_LOADER: {
			return {
				...state,
				isLoading: false
			};
		}
		case EmailThreadActionTypes.EMAIL_THREAD_MESSAGES_REQUESTED: {
			return state;
		}
		case EmailThreadActionTypes.EMAIL_THREAD_MESSAGES_SUCCESSFUL: {
			return {
				...state,
				emailThreadMessages: action.emailThreadMessages
			};
		}
		case EmailThreadActionTypes.EMAIL_THREAD_MESSAGES_FAILED: {
			return state;
		}
		case EmailThreadActionTypes.EMAIL_THREAD_MESSAGES_RESET: {
			return {
				...state,
				emailThreadMessages: new Array<EmailThreadMessage>()
			};
		}
		case EmailThreadActionTypes.SEND_EMAIL_THREAD_MESSAGE_REQUESTED: {
			return state;
		}
		case EmailThreadActionTypes.SEND_EMAIL_THREAD_MESSAGE_SUCCESSFUL: {
			return {
				...state,
				emailThreadMessages: [
					...action.emailThreadMessages,
					...state.emailThreadMessages
				]
			};
		}
		case EmailThreadActionTypes.SEND_EMAIL_THREAD_MESSAGE_FAILED: {
			return state;
		}
		case EmailThreadActionTypes.SEND_EMAIL_DOCUMENT_MESSAGE_REQUESTED: {
			return state;
		}
		case EmailThreadActionTypes.SEND_EMAIL_DOCUMENT_MESSAGE_SUCCESSFUL: {
			return state;
		}
		case EmailThreadActionTypes.SEND_EMAIL_DOCUMENT_MESSAGE_FAILED: {
			return state;
		}
		case EmailThreadActionTypes.EMAIL_THREAD_MESSAGE_DRAWER_OPENED: {
			return {
				...state,
				sendEmailWithoutDocument: action.sendEmailWithoutDocument,
				emailThreadMessageDrawerOpen: true
			};
		}
		case EmailThreadActionTypes.EMAIL_THREAD_MESSAGE_DRAWER_CLOSED: {
			return {
				...state,
				emailThreadMessageDrawerOpen: false
			};
		}

		case EmailThreadActionTypes.MISSING_EMAIL_MODAL_OPENED: {
			return {
				...state,
				missingEmailModalOpen: true
			};
		}
		case EmailThreadActionTypes.MISSING_EMAIL_MODAL_CLOSED: {
			return {
				...state,
				missingEmailModalOpen: false
			};
		}
		case EmailThreadActionTypes.FETCH_EMAIL_MERGE_TAGS_REQUESTED: {
			return state;
		}
		case EmailThreadActionTypes.FETCH_EMAIL_MERGE_TAGS_SUCCESSFUL: {
			return {
				...state,
				emailMergeTagsSystemVariable: action.mergeTags
			};
		}
		case EmailThreadActionTypes.FETCH_EMAIL_MERGE_TAGS_FAILED: {
			return state;
		}
		case EmailThreadActionTypes.FETCH_EMAIL_MERGE_TAGS_WITH_NO_ENTITY_ID_REQUESTED: {
			return state;
		}
		case EmailThreadActionTypes.FETCH_EMAIL_MERGE_TAGS_WITH_NO_ENTITY_ID_SUCCESSFUL: {
			return {
				...state,
				emailMergeTagsSystemVariable: action.mergeTags
			};
		}
		case EmailThreadActionTypes.FETCH_EMAIL_MERGE_TAGS_WITH_NO_ENTITY_ID_FAILED: {
			return state;
		}
		case EmailThreadActionTypes.RESET_EMAIL_MERGE_TAGS: {
			return {
				...state,
				emailMergeTagsSystemVariable: []
			};
		}
		case EmailThreadActionTypes.FETCH_DOCUMENT_EMAIL_PREVIEW_REQUESTED: {
			return state;
		}
		case EmailThreadActionTypes.FETCH_DOCUMENT_EMAIL_PREVIEW_SUCCESSFUL: {
			return {
				...state,
				documentEmailPreview: action.documentEmailPreview
			};
		}
		case EmailThreadActionTypes.FETCH_DOCUMENT_EMAIL_PREVIEW_FAILED: {
			return state;
		}
		case EmailThreadActionTypes.RESET_DOCUMENT_EMAIL_PREVIEW: {
			return {
				...state,
				documentEmailPreview: null
			};
		}
		case EmailThreadActionTypes.FETCH_OTHER_ENTITY_EMAIL_PREVIEW_REQUESTED: {
			return state;
		}
		case EmailThreadActionTypes.FETCH_OTHER_ENTITY_EMAIL_PREVIEW_SUCCESSFUL: {
			return {
				...state,
				otherEntityEmailPreview: action.otherEntityEmailPreview
			};
		}
		case EmailThreadActionTypes.FETCH_OTHER_ENTITY_EMAIL_PREVIEW_FAILED: {
			return state;
		}
		case EmailThreadActionTypes.RESET_EMAIL_PREVIEW: {
			return {
				...state,
				otherEntityEmailPreview: null
			};
		}
		case EmailThreadActionTypes.FETCH_MS365_CONSENT_EMAIL_PREVIEW_SUCCESSFUL: {
			return {
				...state,
				ms365ConsentEmailPreview: action.ms365ConsentEmailPreview
			};
		}
		case EmailThreadActionTypes.RESET_MS365_CONSENT_EMAIL_PREVIEW: {
			return {
				...state,
				documentEmailPreview: null
			};
		}
		case EmailThreadActionTypes.VALIDATE_EMAIL_MERGE_TAGS_REQUESTED: {
			return state;
		}
		case EmailThreadActionTypes.VALIDATE_EMAIL_MERGE_TAGS_SUCCESSFUL: {
			return {
				...state,
				emptyMergeTags: action.emptyMergeTags
			};
		}
		case EmailThreadActionTypes.VALIDATE_EMAIL_MERGE_TAGS_FAILED: {
			return state;
		}
		default: {
			return state;
		}
	}
};

export default EmailThreadReducer;
