import axios, { AxiosResponse } from "axios";

import {
	getRequest,
	postRequest
} from "@zomentum/utils/dist/Service/BackendService";

import {
	BulkImportTemplateFileType,
	BulkImportResponse
} from "@zomentum/contracts/dist/BulkImport";
import {
	VendorClient,
	VendorClientContact
} from "@zomentum/contracts/dist/Vendor/Clients";
import { VendorOpportunity } from "@zomentum/contracts/dist/Vendor/Opportunity";
import { SKUProduct } from "@zomentum/contracts/dist/Vendor/SKU";

const vendorClientsBulkImportUrl = "/v1/vendor/client/companies/import/";
const vendorClientsBulkImportTemplateDownloadUrl =
	"/v1/vendor/client/companies/bulk/template/?file_type={file_type}";
const downloadAllVendorClientsCSVURL = "/v1/vendor/client/companies/export/";

const vendorOpportunityBulkImportUrl = "/v1/vendor/opportunities/import/";
const vendorOpportunityBulkImportTemplateDownloadUrl =
	"/v1/vendor/opportunities/bulk/template/?file_type={file_type}";
const downloadAllVendorOpportunityCSVURL = "/v1/vendor/opportunities/export/";

const vendorProductsBulkImportUrl = "/v1/marketplace/sku/import/";
const vendorProductsBulkImportTemplateDownloadUrl =
	"/v1/marketplace/sku/bulk/template/?file_type={file_type}";
const downloadAllVendorProductsCSVURL = "/v1/marketplace/sku/export/";

const vendorClientContactsBulkImportUrl =
	"/v1/vendor/client/users/bulk/import/";
const vendorClientContactsBulkImportTemplateDownloadUrl =
	"/v1/vendor/client/users/bulk/template/?file_type={file_type}";
const downloadAllVendorClientContactsCSVURL =
	"/v1/vendor/client/users/bulk/export/";

export const vendorClientsBulkImportTemplateDownload = async (
	bulkImportTemplateFileType: BulkImportTemplateFileType
): Promise<Blob> => {
	try {
		const response: AxiosResponse<Blob> = await axios(
			getRequest(
				vendorClientsBulkImportTemplateDownloadUrl.replace(
					"{file_type}",
					bulkImportTemplateFileType
				),
				null,
				true,
				false,
				true
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const downloadAllVendorClientsCSV = async (): Promise<Blob> => {
	try {
		const filters = {
			filter_criterion: [],
			sort_criterion: [],
			included_child_entities: []
		};
		const response: AxiosResponse<Blob> = await axios(
			postRequest(
				downloadAllVendorClientsCSVURL,
				filters,
				true,
				false,
				false,
				true
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const vendorClientsBulkImport = async (
	formData: FormData
): Promise<BulkImportResponse<VendorClient>> => {
	try {
		const response: AxiosResponse<BulkImportResponse<VendorClient>> =
			await axios(
				postRequest(
					vendorClientsBulkImportUrl,
					formData,
					true,
					false,
					true
				)
			);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const vendorOpportunityBulkImportTemplateDownload = async (
	bulkImportTemplateFileType: BulkImportTemplateFileType
): Promise<Blob> => {
	try {
		const response: AxiosResponse<Blob> = await axios(
			getRequest(
				vendorOpportunityBulkImportTemplateDownloadUrl.replace(
					"{file_type}",
					bulkImportTemplateFileType
				),
				null,
				true,
				false,
				true
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const downloadAllVendorOpportunityCSV = async (): Promise<Blob> => {
	try {
		const filters = {
			filter_criterion: [],
			sort_criterion: [],
			included_child_entities: []
		};
		const response: AxiosResponse<Blob> = await axios(
			postRequest(
				downloadAllVendorOpportunityCSVURL,
				filters,
				true,
				false,
				false,
				true
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const vendorOpportunityBulkImport = async (
	formData: FormData
): Promise<BulkImportResponse<VendorOpportunity>> => {
	try {
		const response: AxiosResponse<BulkImportResponse<VendorOpportunity>> =
			await axios(
				postRequest(
					vendorOpportunityBulkImportUrl,
					formData,
					true,
					false,
					true
				)
			);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const vendorProductsBulkImportTemplateDownload = async (
	bulkImportTemplateFileType: BulkImportTemplateFileType
): Promise<Blob> => {
	try {
		const response: AxiosResponse<Blob> = await axios(
			getRequest(
				vendorProductsBulkImportTemplateDownloadUrl.replace(
					"{file_type}",
					bulkImportTemplateFileType
				),
				null,
				true,
				false,
				true
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const downloadAllVendorProductsCSV = async (): Promise<Blob> => {
	try {
		const filters = {
			filter_criterion: [],
			sort_criterion: [],
			included_child_entities: []
		};
		const response: AxiosResponse<Blob> = await axios(
			postRequest(
				downloadAllVendorProductsCSVURL,
				filters,
				true,
				false,
				false,
				true
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const vendorProductsBulkImport = async (
	formData: FormData
): Promise<BulkImportResponse<SKUProduct>> => {
	try {
		const response: AxiosResponse<BulkImportResponse<SKUProduct>> =
			await axios(
				postRequest(
					vendorProductsBulkImportUrl,
					formData,
					true,
					false,
					true
				)
			);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const vendorClientContactsBulkImportTemplateDownload = async (
	bulkImportTemplateFileType: BulkImportTemplateFileType
): Promise<Blob> => {
	try {
		const response: AxiosResponse<Blob> = await axios(
			getRequest(
				vendorClientContactsBulkImportTemplateDownloadUrl.replace(
					"{file_type}",
					bulkImportTemplateFileType
				),
				null,
				true,
				false,
				true
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const downloadAllVendorClientContactsCSV = async (): Promise<Blob> => {
	try {
		const response: AxiosResponse<Blob> = await axios(
			getRequest(
				downloadAllVendorClientContactsCSVURL,
				null,
				true,
				false,
				true
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const vendorClientContactsBulkImport = async (
	formData: FormData
): Promise<BulkImportResponse<VendorClientContact>> => {
	try {
		const response: AxiosResponse<BulkImportResponse<VendorClientContact>> =
			await axios(
				postRequest(
					vendorClientContactsBulkImportUrl,
					formData,
					true,
					false,
					true
				)
			);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
