'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.RequiredOpportunityStage = void 0;
(function (RequiredOpportunityStage) {
  RequiredOpportunityStage["Initial"] = "initial";
  RequiredOpportunityStage["Win"] = "win";
  RequiredOpportunityStage["Lost"] = "lost";
})(exports.RequiredOpportunityStage || (exports.RequiredOpportunityStage = {}));
exports.AmountTypeToShowInPipeline = void 0;
(function (AmountTypeToShowInPipeline) {
  AmountTypeToShowInPipeline["Estimated"] = "estimated";
  AmountTypeToShowInPipeline["Forecasted"] = "forecasted";
  AmountTypeToShowInPipeline["TotalRevenue"] = "total_revenue";
  AmountTypeToShowInPipeline["TotalCost"] = "total_cost";
})(exports.AmountTypeToShowInPipeline || (exports.AmountTypeToShowInPipeline = {}));
