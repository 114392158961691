import EmailThreadActionCreators from "@/Actions/Others/EmailThread";
import UsersActionCreators from "@/Actions/User";
import VendorUsersActionCreators from "@/Actions/Vendor/User";
import { convertTextToMergeTag } from "@zomentum/utils/dist/MergeTags";
import {
	replaceMergeTagsWithId,
	updateMergeTagsDisplayName
} from "@zomentum/utils";
import { cache } from "@zomentum/contracts";
import { useFeatureFlag } from "@zomentum/hooks/dist/hooks/useFeature";
import {
	DefaultErrorMessage,
	FileUploadStatus,
	ZomentumRouteParams,
	ZomentumRoutes,
	ZomentumStorageKeys,
	ZomentumWindow
} from "@zomentum/contracts";
import { MergeTagPreference } from "@zomentum/contracts/dist/Documents";
import {
	EmailTemplate,
	EmailTemplateFileDetails
} from "@zomentum/contracts/dist/EmailTemplates";
import { AuthToken } from "@zomentum/contracts/dist/Login";
import {
	EmailRecipientsTypes,
	EmailThreadFile,
	EmailThreadMessage,
	EmailThreadMessageEditorType,
	EmailThreadParticipant,
	EmailThreadRecipients,
	EmailTracking,
	EntityType,
	FetchMergeTagsRequest,
	GetDocumentEmailPreviewRequest,
	GetEmailPreviewRequest,
	SendDocumentEmailThreadMessageRequest,
	SendEmailThreadMessageFormValues,
	SendEmailThreadMessageRequest,
	ValidateEmailMergeTagsRequest
} from "@zomentum/contracts/dist/Others/EmailThread";
import { ConnectedEmails } from "@zomentum/contracts/dist/Settings";
import {
	DocumentDefaultPreferenceLastUsedEmail,
	EmailSignature
} from "@zomentum/contracts/dist/Users";
import {
	checkForDocumentUrlPresent,
	emailRegex,
	TinyMceOptions,
	TinyMcePlugins
} from "@zomentum/contracts/dist/Utils";
import { initializeTinyMCEPrePastePrompt } from "@zomentum/utils/dist/tinyMCEPrePastePrompt";
import useMobileView from "@zomentum/hooks/dist/hooks/useMobileView";
import {
	formatNylasIntegratedEmailId,
	nylasConnectedEmailSuffix
} from "@/V2Utils";
import { Form } from "@ant-design/compatible";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { EColors } from "@zomentum/atoms/dist/ZColors/interface";
import ZIcon from "@zomentum/atoms/dist/ZIcon";
import { EIcons, ETwoToneIcons } from "@zomentum/atoms/dist/ZIcon/interface";
import ZMessage from "@zomentum/molecules/dist/ZMessage";
import ZSelect from "@zomentum/atoms/dist/ZSelect";
import { IOptionProps } from "@zomentum/atoms/dist/ZSelect/interface";
import ZTypography from "@zomentum/atoms/dist/ZTypography";
import { EFontWeights } from "@zomentum/atoms/dist/ZTypography/interface";
import ZModal from "@zomentum/atoms/dist/ZModal";
import ZSection from "@zomentum/molecules/dist/ZSection";
import {
	captureEvent as SentryCaptureEvent,
	withScope as SentryWithScope
} from "@sentry/react";
import { Editor } from "@tinymce/tinymce-react";
import { Modal, Spin } from "antd";
import Button from "antd/lib/button";
import Col from "antd/lib/col";
import Drawer, { DrawerProps } from "antd/lib/drawer";
import Row from "antd/lib/row";
import Select from "antd/lib/select";
import Skeleton from "antd/lib/skeleton";
import Upload, { UploadChangeParam } from "antd/lib/upload";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import React, { Component, ErrorInfo, Fragment } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import IFRAME from "../../Iframe";
import "./EmailThreadMessageDrawer.less";
import EmailSignatureComponent from "./FooterActivities/EmailSignatureComponent";
import TrackEmail from "./FooterActivities/TrackEmail";
import { ZButton } from "@zomentum/atoms";
import { GlobalState } from "@/Reducers/interface";
import ZMergeTagCascadeDropdown from "@zomentum/molecules/dist/ZMergeTag/ZMergeTagCascadeDropdown";
import EmptyMergeTagsAlertModal from "../Modals/EmptyMergeTags";
import ImportEmailTemplateModal from "./FooterActivities/ImportEmailTemplateModal";
import { VendorEmailTemplate } from "@zomentum/contracts/dist/Vendor/EmailTemplate";
import {
	MergeTagListToSkip,
	MergeTagChildrenListToSkip
} from "@zomentum/contracts/dist/Others/MergeTags";
import { Player } from "@lottiefiles/react-lottie-player";
import Send from "@/Assets/Others/SalesActivity/Send.json";

declare let window: ZomentumWindow;

export const mapStateToProps = (state: GlobalState) => {
	const userData = state.vendorUser.vendorUserData;
	const userEmailIntegrationObj =
		state.vendorUser.vendorUserIntegration.email?.find(
			e => e.user_id === userData.id
		);

	return {
		isLoading:
			state.emailThread.isLoading ||
			state.recentActivityFeed.isLoading ||
			state.vendorUser.isLoading ||
			state.settings.isLoading,
		isSendingMs365InviteToClient:
			state.emailThread.isSendingMs365AssessmentsInviteToClient,
		userEmailIntegrationObj,
		emailThreadMergeTagVariables:
			state.emailThread.emailMergeTagsSystemVariable,
		emailPreview: state.emailThread.documentEmailPreview,
		otherEntityEmailPreview: state.emailThread.otherEntityEmailPreview,
		ms365ConsentEmailPreview: state.emailThread.ms365ConsentEmailPreview,
		emptyMergeTags: state.emailThread.emptyMergeTags,
		userData,
		userCompany: state.vendorUser.vendorUserCompany,
		emailSignatures: state.user.emailSignatures,
		defaultFromValues: {
			email:
				process.env.REACT_APP_DEFAULT_EMAIL ?? "noreply@zomentum.com",
			id: state.vendorUser.vendorUserData.id,
			name: state.vendorUser.vendorUserData.name.full
		},
		companyUserList: state.vendorUser.vendorUserList
	};
};

export const mapDispatchToProps = (
	dispatch: ThunkDispatch<GlobalState, any, Action>
) => {
	return {
		showEmailThreadLoader: () =>
			dispatch(EmailThreadActionCreators.showEmailThreadLoader()),
		hideEmailThreadLoader: () =>
			dispatch(EmailThreadActionCreators.hideEmailThreadLoader()),
		sendEmailThreadMessage: (
			sendEmailThreadMessageRequest: SendEmailThreadMessageRequest
		) =>
			dispatch(
				EmailThreadActionCreators.sendEmailThreadMessage(
					sendEmailThreadMessageRequest
				)
			),
		sendEmailDocumentMessage: (
			sendEmailThreadMessageRequest: SendDocumentEmailThreadMessageRequest
		) =>
			dispatch(
				EmailThreadActionCreators.sendEmailDocumentMessage(
					sendEmailThreadMessageRequest
				)
			),
		fetchMergeTags: (fetchMergeTtagsRequest: FetchMergeTagsRequest) =>
			dispatch(
				EmailThreadActionCreators.fetchMergeTags(fetchMergeTtagsRequest)
			),
		previewDocumentEmail: (
			sendEmailThreadMessageRequest: Partial<GetEmailPreviewRequest>
		) =>
			dispatch(
				EmailThreadActionCreators.previewDocumentEmail(
					sendEmailThreadMessageRequest
				)
			),
		resetEmailPreview: () =>
			dispatch(EmailThreadActionCreators.resetEmailPreview()),
		resetMergeTags: () =>
			dispatch(EmailThreadActionCreators.resetMergeTags()),
		resetDocumentEmailPreview: () =>
			dispatch(EmailThreadActionCreators.resetDocumentEmailPreview()),
		previewMs365ConsentEmail: (
			clientId: string,
			sendMs365InviteToClientRequest: Partial<SendEmailThreadMessageRequest>
		) =>
			dispatch(
				EmailThreadActionCreators.previewMs365ConsentEmail(
					clientId,
					sendMs365InviteToClientRequest
				)
			),
		resetMs365ConsentEmailPreview: () =>
			dispatch(EmailThreadActionCreators.resetMs365ConsentEmailPreview()),
		previewEmail: (
			getEmailPreviewRequest: Partial<GetEmailPreviewRequest>
		) =>
			dispatch(
				EmailThreadActionCreators.previewEmail(getEmailPreviewRequest)
			),
		resetEmptyMergeTags: () =>
			dispatch(EmailThreadActionCreators.resetEmptyMergeTags()),
		validateEmailMergeTags: (
			validateEmailMergeTagsRequest: ValidateEmailMergeTagsRequest
		) =>
			dispatch(
				EmailThreadActionCreators.validateEmailMergeTags(
					validateEmailMergeTagsRequest
				)
			),
		getUserData: () =>
			dispatch(VendorUsersActionCreators.getVendorUserData()),
		getVendorUserData: () =>
			dispatch(VendorUsersActionCreators.getVendorUserData()),
		fetchAllEmailSignatures: () =>
			dispatch(UsersActionCreators.fetchAllEmailSignatures())
	};
};

interface Props
	extends DrawerProps,
		RouteComponentProps<ZomentumRouteParams>,
		FormComponentProps<SendEmailThreadMessageFormValues>,
		ReturnType<typeof mapDispatchToProps>,
		ReturnType<typeof mapStateToProps> {
	readonly isNewThread: boolean;
	readonly isDocumentMessage: boolean;
	readonly defaultRecipients: string[] | null;
	readonly emailThreadMessage: EmailThreadMessage | null;
	readonly ignoreAppendEmailSignature?: boolean;
	readonly defaultSubject?: string | null;
	readonly bodyPlaceholder?: string;
	readonly templateBody?: string | null;
	readonly templateAttachments?: EmailTemplateFileDetails[] | null;
	readonly partnerId?: string | null;
	readonly opportunityId?: string | null;
	readonly clientId?: string | null;
	readonly useTemplate: boolean;
	readonly isFromDocumentsPage?: boolean;
	readonly allEmailRecipients?: EmailThreadRecipients[];
	readonly isFromEmailTemplatesPage?: boolean;
	readonly onSubmit?: () => void;
	readonly onClose?: () => void;
	readonly refreshSalesActivities?: (
		shouldRefreshSalesActivities: boolean
	) => void;
	readonly sendDocumentFromConnectedEmailFlag?: boolean;
	readonly isMobileView?: boolean;
	readonly isFromVendorEmailTemplatespage?: boolean;
}
interface HOCProps
	extends DrawerProps,
		RouteComponentProps<ZomentumRouteParams>,
		ReturnType<typeof mapDispatchToProps>,
		ReturnType<typeof mapStateToProps> {
	readonly isNewThread: boolean;
	readonly isDocumentMessage: boolean;
	readonly defaultRecipients: string[] | null;
	readonly emailThreadMessage: EmailThreadMessage | null;
	readonly ignoreAppendEmailSignature?: boolean;
	readonly defaultSubject?: string | null;
	readonly bodyPlaceholder?: string;
	readonly templateBody?: string | null;
	readonly templateAttachments?: EmailTemplateFileDetails[] | null;
	readonly partnerId?: string | null;
	readonly opportunityId?: string | null;
	readonly clientId?: string | null;
	readonly useTemplate: boolean;
	readonly isFromDocumentsPage?: boolean;
	readonly allEmailRecipients?: EmailThreadRecipients[];
	readonly isFromEmailTemplatesPage?: boolean;
	readonly onSubmit?: () => void;
	readonly onClose?: () => void;
	readonly refreshSalesActivities?: (
		shouldRefreshSalesActivities: boolean
	) => void;
	readonly sendDocumentFromConnectedEmailFlag?: boolean;
	readonly isFromVendorEmailTemplatespage?: boolean;
}

interface State {
	readonly emailThreadMessageFiles: string[];
	readonly emailThreadMessageEditorLoading: boolean;
	readonly emailThreadMessageFileUploading: boolean;
	readonly showCcRecipients: boolean;
	readonly showBccRecipients: boolean;
	readonly mergeTagDropdownX: number;
	readonly mergeTagDropdownY: number;
	readonly mergeTagDropdownVisible: boolean;
	readonly isPreviewEmailModalOpen: boolean;
	readonly isPreviewLoading: boolean;
	readonly isEmptyMergeTagsAlertModalOpen: boolean;
	readonly emptyMergeTags: Array<string>;
	readonly isEmptyMergeTagsAlertFromSendEmail: boolean;
	readonly shouldReplaceWithBlank: boolean;
	readonly isImportEmailTemplateModalOpen: boolean;
	readonly shouldEmptyMergeTagAlertOpenFromSend: boolean;
	readonly isValidating: boolean;
	readonly emailTracking: EmailTracking | null;
	readonly isLoading: boolean;
	readonly systemSendEmailTemplate: EmailTemplate | null;
	readonly emailSent: boolean;
	readonly isMandatoryMergeTagModalOpen: boolean;
	readonly currentActiveEmailSignature: EmailSignature | null;
	readonly dkimConnectedEmails: ConnectedEmails[];
	readonly emailExpiredModalVisible: boolean;
	readonly emailExpiredMessage: string;
	readonly listOfAttachments: UploadFile<any>[];
	readonly recipientSearch: string;
	readonly shouldCollapse: boolean;
}

export const MERGE_TAG_START = "{{";
export const MERGE_TAG_END = "}}";

class EmailThreadMessageDrawerClassComp extends Component<Props, State> {
	textBlockContainerRef: React.RefObject<HTMLDivElement> = React.createRef();
	subjectBlockContainerRef: React.RefObject<HTMLDivElement> =
		React.createRef();
	recipientRef: React.RefObject<HTMLDivElement> = React.createRef();
	state: State = {
		emailThreadMessageFiles:
			this.props.templateAttachments?.map(file => file.file_id) ?? [],
		emailThreadMessageEditorLoading: true,
		emailThreadMessageFileUploading: false,
		showCcRecipients: false,
		showBccRecipients: false,
		mergeTagDropdownX: 0,
		mergeTagDropdownY: 0,
		mergeTagDropdownVisible: false,
		isPreviewEmailModalOpen: false,
		isPreviewLoading: false,
		isEmptyMergeTagsAlertModalOpen: false,
		emptyMergeTags: [],
		isEmptyMergeTagsAlertFromSendEmail: false,
		shouldReplaceWithBlank: true,
		isImportEmailTemplateModalOpen: false,
		shouldEmptyMergeTagAlertOpenFromSend: true,
		isValidating: false,
		emailTracking:
			this.props.userData?.user_settings?.preference_settings
				?.email_tracking_preference_settings ?? null,
		isLoading: false,
		systemSendEmailTemplate: null,
		emailSent: false,
		isMandatoryMergeTagModalOpen: false,
		currentActiveEmailSignature: null,
		dkimConnectedEmails: [],
		emailExpiredModalVisible: false,
		emailExpiredMessage: "",
		listOfAttachments:
			this.props.templateAttachments?.map(
				file =>
					({
						uid: file.file_id,
						name: file.file_name,
						status: FileUploadStatus.DONE,
						size: file.file_size ?? 0
					} as UploadFile<any>)
			) ?? [],
		recipientSearch: "",
		shouldCollapse: true
	};

	componentDidMount() {
		this.extractValidDkimData();
	}

	extractValidDkimData = () => {
		const connected_email: ConnectedEmails[] = [];
		// this.props.userCompany?.settings?.dkim_verification?.domain?.map(
		// 	domain => {
		// 		if (domain.status === "success") {
		// 			domain.connected_emails?.map(email => {
		// 				if (email.status === "success")
		// 					connected_email.push(email);
		// 				return;
		// 			});
		// 		}
		// 		return;
		// 	}
		// );
		// this.props.userCompany?.settings?.dkim_verification?.email?.map(
		// 	email => {
		// 		if (email.status === "success") {
		// 			const modifiedData = {
		// 				...email,
		// 				name: email.email
		// 			};
		// 			connected_email.push(modifiedData);
		// 		}
		// 		return;
		// 	}
		// );
		this.setState({
			dkimConnectedEmails: connected_email
		});
	};

	componentDidUpdate(prevProps: Props) {
		if (this.props.visible !== prevProps.visible) {
			if (this.state.mergeTagDropdownVisible) {
				this.setState({
					mergeTagDropdownVisible: false
				});
			}
			if (this.props.visible) {
				const { partnerId, opportunityId, clientId } = this.props;
				this.props.fetchMergeTags(
					new FetchMergeTagsRequest({
						partnerId,
						opportunityId,
						clientId
					})
				);

				document.addEventListener("click", this.handleCollapse);

				const defaultEmailSignature =
					this.props.emailSignatures.signatures.find(
						signature => signature.is_default
					) ?? null;

				this.setState({
					currentActiveEmailSignature: defaultEmailSignature
				});
				this.fetchAllEmailSignatures();

				if (this.props.isFromEmailTemplatesPage) {
					this.setState({
						emailThreadMessageFiles:
							this.props.templateAttachments?.map(
								file => file.file_id
							) ?? [],
						listOfAttachments:
							this.props.templateAttachments?.map(
								file =>
									({
										uid: file.file_id,
										name: file.file_name,
										status: FileUploadStatus.DONE,
										size: file.file_size ?? 0
									} as UploadFile<any>)
							) ?? []
					});
				}

				if (
					!!this.props?.partnerId?.length ||
					!!this.props?.opportunityId?.length ||
					!!this.props?.clientId?.length
				) {
					this.appendSignatureInEditor();
				}
				if (
					this.props.templateAttachments !==
					prevProps.templateAttachments
				) {
					this.setState({
						emailThreadMessageFiles:
							this.props.templateAttachments?.map(
								file => file.file_id
							) ?? [],
						listOfAttachments:
							this.props.templateAttachments?.map(
								file =>
									({
										uid: file.file_id,
										name: file.file_name,
										status: FileUploadStatus.DONE,
										size: file.file_size ?? 0
									} as UploadFile<any>)
							) ?? []
					});
				}
			} else {
				document.removeEventListener("click", this.handleCollapse);
			}
		}
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error(error);
		if (!window.isDevelopment && process.env.REACT_APP_SENTRY) {
			SentryWithScope(scope => {
				scope.setExtras({ stacktrace: errorInfo });
				SentryCaptureEvent(error);
			});
		}
	}

	appendSignatureInEditor = () => {
		const emailThreadMessageEditorElement = window.tinyMCE?.get(
			this.props.isNewThread
				? EmailThreadMessageEditorType.Compose
				: EmailThreadMessageEditorType.Reply
		);
		if (!emailThreadMessageEditorElement) {
			console.warn(" Tiny mce editor not ready");
			return;
		}
		const content = emailThreadMessageEditorElement.getContent({
			format: "raw"
		});
		const defaultEmailSignature =
			this.props.emailSignatures.signatures.find(
				signature => signature.is_default
			) ?? null;
		if (defaultEmailSignature) {
			emailThreadMessageEditorElement.setContent(
				content + defaultEmailSignature.body
			);
		}
	};

	resetState = () => {
		this.setState({
			emailThreadMessageFiles: new Array<string>(),
			listOfAttachments: new Array<UploadFile<any>>(),
			emailThreadMessageEditorLoading: false,
			emailThreadMessageFileUploading: false,
			showCcRecipients: false,
			showBccRecipients: false
		});
	};

	initializeEmailThreadMessageDrawerEditor = () => {
		this.setState({ emailThreadMessageEditorLoading: false });
	};

	onClose = () => {
		if (!this.state.mergeTagDropdownVisible) {
			this.resetState();
			if (this.props.onClose) {
				this.setState({
					systemSendEmailTemplate: null
				});
				this.props.resetMergeTags();
				this.props.onClose();
				this.state.emailSent &&
					this.props.refreshSalesActivities &&
					this.props.refreshSalesActivities(false);
				this.setState({
					emailSent: false
				});
			}
		} else {
			this.setState({
				mergeTagDropdownVisible: false
			});
		}
	};

	toggleCcRecipients = () => {
		this.setState({ showCcRecipients: !this.state.showCcRecipients });
	};

	toggleBccRecipients = () => {
		this.setState({ showBccRecipients: !this.state.showBccRecipients });
	};

	beforeUploadEmailThreadFile = (file: RcFile, _fileList: RcFile[]) => {
		const totalSizeOfAttachments = this.state.listOfAttachments.reduce(
			(sum, attachment) => (attachment.size as number) + sum,
			0
		);
		const isFileSizeLessThan25MB =
			totalSizeOfAttachments + file.size < 25 * 1024 * 1024;
		const isFileSizeLessThan7MB =
			totalSizeOfAttachments + file.size < 7 * 1024 * 1024;
		const isSendingEmailFromDocumentPage =
			this.props.isDocumentMessage && !!this.props.isFromDocumentsPage;
		if (!isFileSizeLessThan7MB && isSendingEmailFromDocumentPage) {
			ZMessage.error(
				"Error: Total size of attachments exceeds maximum allowed size of 7 MB"
			);
			return isFileSizeLessThan7MB;
		}
		if (!isFileSizeLessThan25MB) {
			ZMessage.error(
				"Error: Total size of attachments exceeds maximum allowed size of 25 MB"
			);
		}
		return isFileSizeLessThan25MB;
	};

	uploadEmailThreadFile = (
		info: UploadChangeParam<UploadFile<EmailThreadFile>>
	) => {
		try {
			this.setState({
				listOfAttachments: [...info.fileList]
			});
			if (info.file.status === FileUploadStatus.DONE) {
				const uploadEmailThreadFileResponse = info.file.response;
				if (
					(uploadEmailThreadFileResponse &&
						uploadEmailThreadFileResponse.id &&
						uploadEmailThreadFileResponse.id.length > 0) ||
					(uploadEmailThreadFileResponse &&
						uploadEmailThreadFileResponse.file_id)
				) {
					let temp = "";
					if (uploadEmailThreadFileResponse.id) {
						temp = uploadEmailThreadFileResponse.id;
					} else if (uploadEmailThreadFileResponse.file_id) {
						temp = uploadEmailThreadFileResponse.file_id;
					}
					this.setState({
						emailThreadMessageFiles:
							this.state.emailThreadMessageFiles.concat(temp),
						emailThreadMessageFileUploading: false
					});
					ZMessage.success(
						`${info.file.name} file uploaded successfully`
					);
				} else {
					ZMessage.error(`${info.file.name} file upload failed.`);
					this.setState({ emailThreadMessageFileUploading: false });
				}
			} else if (info.file.status === FileUploadStatus.REMOVED) {
				const newListOfAttachments =
					this.state.listOfAttachments.filter(
						attachment =>
							attachment.status !== FileUploadStatus.REMOVED
					);
				this.setState({
					emailThreadMessageFileUploading: false,
					listOfAttachments: newListOfAttachments
				});
			} else if (info.file.status === FileUploadStatus.ERROR) {
				ZMessage.error(`${info.file.name} file upload failed.`);
				const newListOfAttachments =
					this.state.listOfAttachments.filter(
						attachment =>
							attachment.status !== FileUploadStatus.ERROR
					);
				this.setState({
					emailThreadMessageFileUploading: false,
					listOfAttachments: newListOfAttachments
				});
			} else if (info.file.status === undefined) {
				const newListOfAttachments =
					this.state.listOfAttachments.filter(
						attachment => attachment.status
					);
				this.setState({
					emailThreadMessageFileUploading: false,
					listOfAttachments: newListOfAttachments
				});
			} else {
				this.setState({ emailThreadMessageFileUploading: true });
			}
		} catch (error) {
			console.error(error);
			this.setState({ emailThreadMessageFileUploading: false });
		}
	};

	deleteEmailThreadFile = (file: UploadFile) => {
		try {
			if (file.status === FileUploadStatus.ERROR) {
				throw new Error(`${file.name} file deletion failed.`);
			}
			let comparison: string;
			if (!!file.response?.id) {
				comparison = file.response.id;
			} else if (!!file.response?.file_id) {
				comparison = file.response.file_id;
			} else {
				comparison = file.uid;
			}
			this.setState({
				emailThreadMessageFiles:
					this.state.emailThreadMessageFiles.filter(
						emailThreadMessageFile =>
							emailThreadMessageFile !== comparison
					)
			});
			ZMessage.success(`${file.name} file removed successfully`);
		} catch (error) {
			console.error(error);
		}
	};

	getFormValuesForEmail = (
		sendEmailThreadMessageFormValues: SendEmailThreadMessageFormValues
	) => {
		try {
			const isTinyReady =
				process.env.REACT_APP_TINY_MCE &&
				process.env.REACT_APP_TINY_MCE.length > 0 &&
				this.props.emailThreadMessage &&
				window.tinyMCE &&
				window.tinyMCE.get;

			if (!isTinyReady) {
				console.warn("Tiny not ready");
				return null;
			}
			const emailThreadMessageEditorElement = window.tinyMCE.get(
				this.props.isNewThread
					? EmailThreadMessageEditorType.Compose
					: EmailThreadMessageEditorType.Reply
			);

			if (!emailThreadMessageEditorElement) {
				console.warn("Editor body not ready");
				return null;
			}

			const emailThreadMessageSubjectElement = window.tinyMCE.get(
				`${
					this.props.isNewThread
						? EmailThreadMessageEditorType.Compose
						: EmailThreadMessageEditorType.Reply
				}-subject`
			);

			if (!emailThreadMessageSubjectElement && this.props.isNewThread) {
				console.warn("Subject editor not ready");
				return null;
			}

			const alias =
				this.props.userEmailIntegrationObj?.email_aliases?.find(
					data => data.email === sendEmailThreadMessageFormValues.from
				);

			const dkim = this.state.dkimConnectedEmails?.find(
				data => data.email === sendEmailThreadMessageFormValues.from
			);

			const isNylasEmailID =
				sendEmailThreadMessageFormValues.from?.includes(
					nylasConnectedEmailSuffix
				);

			/**
			 *
			 *  Checks alias and dkim to setup "from" value,
			 *  also checks if the from email is default zomentum's email then it sets to undefined else if the email isn't verified it'll set to null.
			 *  * Make sure to check type of "from" cause undefined and null has different means in our context
			 *
			 */
			let from: EmailThreadRecipients | null | undefined;

			if (isNylasEmailID) {
				from = {
					email: formatNylasIntegratedEmailId(
						sendEmailThreadMessageFormValues.from,
						true
					),
					name: this.props.userData.name.full,
					id: this.props.userData.id,
					is_nylas: true
				};
			} else if (alias) {
				from = {
					email: alias.email,
					name: alias.name,
					id: this.props.userData.id
				};
			} else if (dkim) {
				from = {
					email: dkim.email,
					name: dkim.name,
					id: this.props.userData.id
				};
			} else if (
				sendEmailThreadMessageFormValues.from ===
				process.env.REACT_APP_DEFAULT_EMAIL
			) {
				from = undefined;
			} else if (sendEmailThreadMessageFormValues.from) {
				from = {
					email: sendEmailThreadMessageFormValues.from,
					name: this.props.userData.name.full,
					id: this.props.userData.id
				};
			}

			const emailBody: string =
				emailThreadMessageEditorElement.getContent();
			const emailSubject: string =
				!this.props.isNewThread && this.props.emailThreadMessage
					? this.props.emailThreadMessage?.subject
					: emailThreadMessageSubjectElement.settings?.target
							.innerText;

			const recipients =
				sendEmailThreadMessageFormValues.to &&
				sendEmailThreadMessageFormValues.to.length > 0
					? sendEmailThreadMessageFormValues.to.map(idOrEmail => {
							const recipient =
								this.props.allEmailRecipients?.find(
									user => user.id === idOrEmail
								);
							if (recipient) {
								const emailThreadRecipient =
									new EmailThreadRecipients();
								emailThreadRecipient.email = recipient.email;
								emailThreadRecipient.name = recipient.name;
								emailThreadRecipient.id = recipient.id;
								return emailThreadRecipient;
							} else {
								const emailThreadRecipient =
									new EmailThreadRecipients();
								emailThreadRecipient.email = idOrEmail;
								return emailThreadRecipient;
							}
					  })
					: new Array<EmailThreadRecipients>();
			const cc =
				sendEmailThreadMessageFormValues.cc &&
				sendEmailThreadMessageFormValues.cc.length > 0
					? sendEmailThreadMessageFormValues.cc.map(email => {
							const emailThreadParticipant =
								new EmailThreadParticipant();
							emailThreadParticipant.email = email;
							return emailThreadParticipant;
					  })
					: new Array<EmailThreadParticipant>();

			const bcc =
				sendEmailThreadMessageFormValues.bcc &&
				sendEmailThreadMessageFormValues.bcc.length > 0
					? sendEmailThreadMessageFormValues.bcc.map(email => {
							const emailThreadParticipant =
								new EmailThreadParticipant();
							emailThreadParticipant.email = email;
							return emailThreadParticipant;
					  })
					: new Array<EmailThreadParticipant>();

			const file_ids = [...this.state.emailThreadMessageFiles];

			const account_id = this.props.emailThreadMessage?.account_id;

			let currentMail_id = "";
			let currentMail_type: EntityType = EntityType.VendorOpportunity;
			const arePartnerAndOpportunitySelected =
				this.props.partnerId && this.props.opportunityId;
			if (arePartnerAndOpportunitySelected) {
				currentMail_id = this.props.opportunityId || "";
				currentMail_type = EntityType.VendorOpportunity;
			} else if (this.props.partnerId) {
				currentMail_id = this.props.partnerId || "";
				currentMail_type = EntityType.Partner;
			} else if (this.props.clientId) {
				currentMail_id = this.props.clientId || "";
				currentMail_type = EntityType.VendorClient;
			} else {
				currentMail_id = this.props.opportunityId || "";
				currentMail_type = EntityType.VendorOpportunity;
			}
			return {
				from,
				recipients,
				cc,
				bcc,
				file_ids,
				emailBody,
				emailSubject,
				account_id,
				currentMail_id,
				currentMail_type
			};
		} catch (error) {
			ZMessage.error(DefaultErrorMessage);
			console.error(error);
			return;
		}
	};

	generateDocumentEmailPreview = async (
		documentEmailPreviewRequest: GetDocumentEmailPreviewRequest
	) => {
		this.setState({
			isPreviewLoading: true
		});
		try {
			await this.props.previewDocumentEmail(documentEmailPreviewRequest);
		} catch (error) {
			ZMessage.error(DefaultErrorMessage);
			console.error(error);
		} finally {
			this.setState({
				isPreviewLoading: false
			});
		}
	};

	generateOtherEntityPreview = async (
		previewEmailRequest: Partial<GetEmailPreviewRequest>
	) => {
		this.setState({
			isPreviewLoading: true
		});
		try {
			await this.props.previewEmail(previewEmailRequest);
		} catch (error) {
			this.setState({
				isPreviewEmailModalOpen: false
			});
			ZMessage.error(DefaultErrorMessage);
			console.error(error);
		} finally {
			this.setState({
				isPreviewLoading: false
			});
		}
	};

	fetchAllEmailSignatures = () => {
		this.setState({
			isLoading: true
		});
		try {
			this.props.fetchAllEmailSignatures();
		} catch (error) {
			console.error(error);
		} finally {
			this.setState({
				isLoading: false
			});
		}
	};

	verifyAndOpenPreviewModal = () => {
		this.props.form.validateFields(
			async (
				errors: unknown,
				sendEmailThreadMessageFormValues: SendEmailThreadMessageFormValues
			) => {
				if (!errors) {
					try {
						const formValues = this.getFormValuesForEmail(
							sendEmailThreadMessageFormValues
						);

						if (!formValues) {
							console.warn("No form values found");
							return;
						}

						const validateEmailMergeTagsRequest =
							new ValidateEmailMergeTagsRequest();

						validateEmailMergeTagsRequest.from =
							formValues?.from ?? this.props.defaultFromValues;
						validateEmailMergeTagsRequest.to =
							formValues.recipients;
						validateEmailMergeTagsRequest.cc = formValues.cc;
						validateEmailMergeTagsRequest.bcc = formValues.bcc;
						validateEmailMergeTagsRequest.body =
							replaceMergeTagsWithId(
								this.props.emailThreadMergeTagVariables,
								formValues.emailBody
							);
						validateEmailMergeTagsRequest.subject =
							replaceMergeTagsWithId(
								this.props.emailThreadMergeTagVariables,
								formValues.emailSubject
							);
						validateEmailMergeTagsRequest.mail_type =
							formValues.currentMail_type;
						validateEmailMergeTagsRequest.mail_id =
							formValues.currentMail_id;

						this.setState({
							isValidating: true
						});

						await this.props.validateEmailMergeTags(
							validateEmailMergeTagsRequest
						);

						this.setState({
							isValidating: false
						});

						const emptyMergeTags: string[] =
							this.props.emptyMergeTags || [];

						if (!!emptyMergeTags?.length) {
							this.setState({
								emptyMergeTags,
								isEmptyMergeTagsAlertModalOpen: true
							});
						} else {
							this.continueToPreviewEmail(
								MergeTagPreference.REPLACE
							);
						}
					} catch (error) {
						console.error(error);
					}
				}
			}
		);
	};

	continueToPreviewEmail = (shouldReplaceWithBlank?: MergeTagPreference) => {
		this.setState(
			{
				shouldReplaceWithBlank:
					shouldReplaceWithBlank !== MergeTagPreference.BLANK
						? false
						: true
			},
			() => {
				this.props.form.validateFields(
					(
						error,
						sendEmailThreadMessageFormValues: SendEmailThreadMessageFormValues
					) => {
						if (!error) {
							try {
								const formValues = this.getFormValuesForEmail(
									sendEmailThreadMessageFormValues
								);

								if (!formValues) {
									console.warn("No form values found");
									return;
								}

								const previewEmailRequest =
									new GetEmailPreviewRequest();

								previewEmailRequest.from =
									formValues?.from || null;
								previewEmailRequest.to = formValues.recipients;
								previewEmailRequest.subject =
									replaceMergeTagsWithId(
										this.props.emailThreadMergeTagVariables,
										formValues.emailSubject
									);
								previewEmailRequest.body =
									replaceMergeTagsWithId(
										this.props.emailThreadMergeTagVariables,
										formValues.emailBody
									);
								previewEmailRequest.should_replace_with_empty_string =
									this.state.shouldReplaceWithBlank;
								previewEmailRequest.mail_id =
									formValues.currentMail_id;
								previewEmailRequest.mail_type =
									formValues.currentMail_type;
								previewEmailRequest.file_ids =
									formValues.file_ids;
								previewEmailRequest.signature =
									this.state.currentActiveEmailSignature
										?.body ?? "";

								const documentEmailPreviewRequest =
									new GetDocumentEmailPreviewRequest();

								documentEmailPreviewRequest.to =
									formValues.recipients;
								documentEmailPreviewRequest.bcc =
									formValues.bcc;
								documentEmailPreviewRequest.cc = formValues.cc;
								documentEmailPreviewRequest.should_replace_with_empty_string =
									this.state.shouldReplaceWithBlank;
								documentEmailPreviewRequest.body =
									replaceMergeTagsWithId(
										this.props.emailThreadMergeTagVariables,
										formValues.emailBody
									);
								documentEmailPreviewRequest.subject =
									replaceMergeTagsWithId(
										this.props.emailThreadMergeTagVariables,
										formValues.emailSubject
									);

								documentEmailPreviewRequest.signature =
									this.state.currentActiveEmailSignature
										?.body ?? "";
								this.generateOtherEntityPreview(
									previewEmailRequest
								);

								this.setState({
									isPreviewEmailModalOpen: true
								});

								this.closeEmptyMergeTagsAlertModal();
							} catch (error) {
								ZMessage.error(DefaultErrorMessage);
								console.error(error);
							}
						}
					}
				);
			}
		);
	};

	onSubmit = ($event: React.FormEvent<HTMLFormElement>) => {
		$event.preventDefault();

		this.setState({
			isEmptyMergeTagsAlertFromSendEmail: true
		});

		this.props.form.validateFieldsAndScroll(
			async (
				error,
				sendEmailThreadMessageFormValues: SendEmailThreadMessageFormValues
			) => {
				if (!error) {
					try {
						const formValues = this.getFormValuesForEmail(
							sendEmailThreadMessageFormValues
						);

						if (!formValues) {
							console.warn("No form values found");
							return;
						}

						if (formValues.from === null) {
							this.showEmailExpiredModal("");
							return;
						}

						const isDocumentUrlPresent = checkForDocumentUrlPresent(
							formValues.emailBody
						);

						if (
							!!this.state.systemSendEmailTemplate &&
							!!this.state.systemSendEmailTemplate?.id?.length &&
							!this.state.systemSendEmailTemplate
								.system_template_info &&
							!isDocumentUrlPresent
						) {
							this.setState({
								isMandatoryMergeTagModalOpen: true
							});
							return;
						}

						const validateEmailMergeTagsRequest =
							new ValidateEmailMergeTagsRequest();

						validateEmailMergeTagsRequest.from =
							formValues.from ?? this.props.defaultFromValues;
						validateEmailMergeTagsRequest.to =
							formValues.recipients;
						validateEmailMergeTagsRequest.cc = formValues.cc;
						validateEmailMergeTagsRequest.bcc = formValues.bcc;
						validateEmailMergeTagsRequest.body =
							replaceMergeTagsWithId(
								this.props.emailThreadMergeTagVariables,
								formValues.emailBody
							);
						validateEmailMergeTagsRequest.subject =
							replaceMergeTagsWithId(
								this.props.emailThreadMergeTagVariables,
								formValues.emailSubject
							);
						validateEmailMergeTagsRequest.mail_type =
							formValues.currentMail_type;
						validateEmailMergeTagsRequest.mail_id =
							formValues.currentMail_id;

						const validateTo = this.validateEmails(
							validateEmailMergeTagsRequest.to
						);
						const validateCc = this.validateEmails(
							validateEmailMergeTagsRequest.cc
						);
						const validateBcc = this.validateEmails(
							validateEmailMergeTagsRequest.bcc
						);

						if (!validateTo) {
							ZMessage.error(
								"Invalid email entered in the TO field. Please verify the email address and try again"
							);
							return;
						} else if (!validateCc) {
							ZMessage.error(
								"Invalid email entered in the CC field. Please verify the email address and try again"
							);
							return;
						} else if (!validateBcc) {
							ZMessage.error(
								"Invalid email entered in the BCC field. Please verify the email address and try again"
							);
							return;
						}

						const emptyMergeTags: string[] = this.state
							.shouldEmptyMergeTagAlertOpenFromSend
							? this.props.emptyMergeTags || []
							: [];
						if (
							emptyMergeTags.length > 0 &&
							this.state.shouldEmptyMergeTagAlertOpenFromSend
						) {
							this.setState({
								emptyMergeTags,
								isEmptyMergeTagsAlertModalOpen: true
							});
						} else {
							this.continueToSendEmail(
								MergeTagPreference.REPLACE
							);
						}
					} catch (error) {
						if (!error.message) {
							ZMessage.error(DefaultErrorMessage);
						}
						console.error(error);
					}
				}
			}
		);
	};

	continueToSendEmail = (shouldReplaceWithBlank?: MergeTagPreference) => {
		const checkMergeTag =
			shouldReplaceWithBlank !== MergeTagPreference.BLANK ? false : true;

		const shouldReplaceEmpyMergeTagWithBlank = this.state
			.shouldEmptyMergeTagAlertOpenFromSend
			? checkMergeTag
			: this.state.shouldReplaceWithBlank;

		this.setState(
			{
				shouldReplaceWithBlank: shouldReplaceEmpyMergeTagWithBlank
			},
			() => {
				this.props.form.validateFields(
					(
						errors: unknown,
						sendEmailThreadMessageFormValues: SendEmailThreadMessageFormValues
					) => {
						if (!errors) {
							try {
								const formValues = this.getFormValuesForEmail(
									sendEmailThreadMessageFormValues
								);

								if (!formValues) {
									console.warn("No form values found");
									return;
								}

								const sendEmailThreadMessageRequest =
									new SendEmailThreadMessageRequest();

								sendEmailThreadMessageRequest.from =
									formValues.from ??
									this.props.defaultFromValues;
								sendEmailThreadMessageRequest.to =
									formValues.recipients;
								sendEmailThreadMessageRequest.subject =
									replaceMergeTagsWithId(
										this.props.emailThreadMergeTagVariables,
										formValues?.emailSubject
									);
								sendEmailThreadMessageRequest.body =
									replaceMergeTagsWithId(
										this.props.emailThreadMergeTagVariables,
										formValues.emailBody
									);
								sendEmailThreadMessageRequest.bcc =
									formValues.bcc;
								sendEmailThreadMessageRequest.cc =
									formValues.cc;
								sendEmailThreadMessageRequest.mail_type =
									formValues.currentMail_type;
								sendEmailThreadMessageRequest.mail_id =
									formValues.currentMail_id;
								sendEmailThreadMessageRequest.account_id =
									formValues.account_id || "";
								sendEmailThreadMessageRequest.file_ids =
									formValues.file_ids;
								sendEmailThreadMessageRequest.should_replace_with_empty_string =
									this.state.shouldReplaceWithBlank;
								sendEmailThreadMessageRequest.tracking =
									this.state.emailTracking;
								sendEmailThreadMessageRequest.reply_to_message_id =
									this.props.emailThreadMessage
										? this.props.emailThreadMessage
												.message_ids[
												this.props.emailThreadMessage
													.message_ids.length - 1
										  ]
										: "";
								sendEmailThreadMessageRequest.signature =
									this.state.currentActiveEmailSignature
										?.body ?? "";

								this.sendEmailThreadMessage(
									sendEmailThreadMessageRequest
								);
							} catch (error) {
								console.error(error);
								ZMessage.error(DefaultErrorMessage);
							}
						}
					}
				);
				this.closeEmptyMergeTagsAlertModal();
			}
		);
	};

	sendEmailThreadMessage = async (
		sendEmailThreadMessageRequest: SendEmailThreadMessageRequest
	) => {
		this.props.showEmailThreadLoader();
		try {
			if (!this.props.emailThreadMessage) {
				throw new Error("Email thread message is empty");
			}

			if (
				!!this.props.emailThreadMessage?.account_id?.length &&
				this.props.emailThreadMessage.account_id ===
					sendEmailThreadMessageRequest.account_id
			) {
				await this.props.sendEmailThreadMessage(
					sendEmailThreadMessageRequest
				);
				this.setState({ emailSent: true });
				this.onClose();
				ZMessage.success("Email sent successfully");
				this.props.onSubmit?.();
			} else {
				throw new Error("Account id is missing");
			}
		} catch (error) {
			console.error(error);
			const errorRes = error?.message;
			if (
				errorRes?.indexOf(
					"The address you are attempting to send the document from is no longer authorized"
				) !== -1
			) {
				this.showEmailExpiredModal(errorRes);
			} else {
				if (error.error?.response?.data?.message) {
					const errorMessage =
						error.error.response.data.message +
						". " +
						error.displayMessage;
					console.error(errorMessage, error);
				} else {
					ZMessage.error(DefaultErrorMessage);
				}
			}
		} finally {
			const isLastUsedEmail =
				sendEmailThreadMessageRequest.from?.email ===
				this.props.userData.document_last_used_from_address;
			if (!isLastUsedEmail) {
				await this.props.getVendorUserData();
			}
			this.props.hideEmailThreadLoader();
		}
	};

	showEmailExpiredModal = (errorRes: string) => {
		this.setState({
			emailExpiredModalVisible: true,
			emailExpiredMessage: errorRes
		});
	};

	closeEmailExpiredModal = () => {
		this.setState({
			emailExpiredModalVisible: false,
			emailExpiredMessage: ""
		});
	};

	handleEmailExpiration = () => {
		const emptyMergeTags: string[] = this.state
			.shouldEmptyMergeTagAlertOpenFromSend
			? this.props.emptyMergeTags || []
			: [];
		if (emptyMergeTags?.length) {
			this.setState({
				emptyMergeTags,
				isEmptyMergeTagsAlertModalOpen: true
			});
		} else {
			this.continueToSendEmail(MergeTagPreference.REPLACE);
		}
		this.closeEmailExpiredModal();
	};

	closeMandatoryMergeTagModal = () => {
		this.setState({
			isMandatoryMergeTagModalOpen: false
		});
	};

	setMergeTagDropdownVisible = (isVisible: boolean) => {
		this.setState({
			mergeTagDropdownVisible: isVisible
		});
	};

	closeEmptyMergeTagsAlertModal = () => {
		this.setState({
			emptyMergeTags: [],
			isEmptyMergeTagsAlertModalOpen: false,
			isEmptyMergeTagsAlertFromSendEmail: false
		});
		this.props.resetEmptyMergeTags();
	};

	closePreviewModal = () => {
		this.setState({
			isPreviewEmailModalOpen: false,
			shouldEmptyMergeTagAlertOpenFromSend: false
		});
		this.props.resetDocumentEmailPreview();
		this.props.resetEmailPreview();
		this.props.resetMs365ConsentEmailPreview();
	};

	handleSubmitMergeTagVariable = (key: string) => {
		this.setMergeTagDropdownVisible(false);
		window.requestAnimationFrame(() => {
			window.tinyMCE.activeEditor.focus();
			this.mergeTagDropDownOnSubmit(key);
		});
	};

	updateTextFromEmailBodyTinyMCEEditor = () => {
		this.setMergeTagDropdownVisible(false);
		this.setState({
			shouldEmptyMergeTagAlertOpenFromSend: true
		});

		if (window.tinyMCE.activeEditor.selection) {
			const boundingClientRect =
				window.tinyMCE.activeEditor.selection.getBoundingClientRect();
			if (
				boundingClientRect &&
				this.textBlockContainerRef &&
				this.textBlockContainerRef.current
			) {
				const parentRect = window.tinyMCE.activeEditor
					.getContentAreaContainer()
					.getBoundingClientRect();
				this.setState({
					mergeTagDropdownX: boundingClientRect.right,
					mergeTagDropdownY: boundingClientRect.bottom + parentRect.y
				});
			}
			this.checkForMergeTagStart();
		}
	};

	updateTextFromSubjectTinyMCEEditor = () => {
		this.setMergeTagDropdownVisible(false);
		this.setState({
			shouldEmptyMergeTagAlertOpenFromSend: true
		});

		if (window.tinyMCE.activeEditor.selection) {
			const boundingClientRect =
				window.tinyMCE.activeEditor.selection.getBoundingClientRect();
			if (
				boundingClientRect &&
				this.subjectBlockContainerRef &&
				this.subjectBlockContainerRef.current
			) {
				const parentRect =
					this.subjectBlockContainerRef.current?.getBoundingClientRect();

				this.setState({
					mergeTagDropdownX: boundingClientRect.right - parentRect.x,
					mergeTagDropdownY: boundingClientRect.bottom
				});
			}
			this.checkForMergeTagStart();
		}
	};

	mergeTagDropDownOnSubmit = (key: string) => {
		const editorRange = window.tinyMCE.activeEditor.selection.getRng();
		const node = editorRange.commonAncestorContainer;
		const newRange = document.createRange();
		newRange.selectNodeContents(node);
		newRange.setStart(node, editorRange.endOffset - MERGE_TAG_START.length);
		newRange.setEnd(node, editorRange.endOffset);
		newRange.deleteContents();
		window.tinyMCE.activeEditor.focus();

		const mergeTagDisplayName =
			this.props.emailThreadMergeTagVariables.find(
				mergeTag => mergeTag.variable_name === key
			)?.display_name;

		window.tinyMCE.activeEditor.insertContent(
			`<span class="merge-tag mceNonEditable" contentEditable="false" data-mce-contenteditable="false" data-id=${key}>${MERGE_TAG_START}${mergeTagDisplayName}${MERGE_TAG_END}</span>\ufeff `,
			{
				format: "html"
			}
		);

		this.setMergeTagDropdownVisible(false);
	};

	handleCloseMergeTagDropdown = () => {
		this.setMergeTagDropdownVisible(false);
		window.requestAnimationFrame(() => {
			window.tinyMCE.activeEditor.focus();
		});
	};

	showImportEmailTemplateModal = () => {
		this.setState({
			isImportEmailTemplateModalOpen: true
		});
	};

	closeImportEmailTemplateModal = () => {
		this.setState({
			isImportEmailTemplateModalOpen: false
		});
	};

	checkForMergeTagStart = () => {
		const currentRange = window.tinyMCE.activeEditor.selection.getRng(
			window.tinyMCE.activeEditor.selection.getNode()
		);
		if (
			currentRange &&
			currentRange.startContainer.textContent
				.substr(0, currentRange.startOffset)
				.endsWith(MERGE_TAG_START)
		) {
			window.requestAnimationFrame(() => {
				this.setMergeTagDropdownVisible(true);
			});
		}
	};

	handleSelectEmailTemplate = (template: VendorEmailTemplate) => {
		const emailThreadMessageEditorElement = window.tinyMCE.get(
			this.props.isNewThread
				? EmailThreadMessageEditorType.Compose
				: EmailThreadMessageEditorType.Reply
		);

		const emailThreadMessageSubjectElement = window.tinyMCE.get(
			`${
				this.props.isNewThread
					? EmailThreadMessageEditorType.Compose
					: EmailThreadMessageEditorType.Reply
			}-subject`
		);

		this.closeImportEmailTemplateModal();

		if (!emailThreadMessageEditorElement) {
			console.warn(" Tiny mce editor not ready");
			return;
		}

		const content = emailThreadMessageEditorElement.getContent({
			format: "raw"
		});
		const domParser = new DOMParser();
		const emailEditorContent = domParser.parseFromString(
			content,
			"text/html"
		);
		const signature = emailEditorContent.querySelector(
			"div[data-email-signature][data-valid-signature=true]"
		) as HTMLElement;
		const signatureData = signature
			? `<div data-email-signature=${signature.dataset.emailSignature} data-valid-signature=true>${signature.innerHTML}</div>`
			: "";

		const updatedEditorText: string =
			updateMergeTagsDisplayName(
				this.props.emailThreadMergeTagVariables,
				template.body
			) + signatureData;

		emailThreadMessageEditorElement.setContent(updatedEditorText);

		if (!this.props.isNewThread) {
			return;
		}
		if (!emailThreadMessageSubjectElement) {
			console.warn("Tiny mce subject editor not ready");
			return;
		}

		const convertedTextToMergeTags = convertTextToMergeTag(
			this.props.emailThreadMergeTagVariables,
			template?.subject
		);

		emailThreadMessageSubjectElement.setContent(
			updateMergeTagsDisplayName(
				this.props.emailThreadMergeTagVariables,
				convertedTextToMergeTags
			)
		);
	};

	updateEmailTrackingDetails = (trackDetails: EmailTracking) => {
		const emailTrackingDetails = Object.keys(trackDetails).some(
			k => (trackDetails as unknown as Record<string, string>)[k]
		)
			? trackDetails
			: null;
		this.setState({
			emailTracking: emailTrackingDetails
		});
	};

	cleanStringForSignature = (value: string): string => {
		return value
			.split(`<br data-mce-bogus="1">`)
			.join("")
			.replace(/\uFEFF/g, "")
			.split(/data-mce-selected="\S*"/gm)
			.join("")
			.split(" ")
			.join("")
			.split("/")
			.join("")
			.split(/data-mce-src\S+"/gm)
			.join("")
			.split(/data-mce-style\S+?"\S+?"/gm)
			.join("");
	};

	extractSignatureBodyContent = (html: string): string =>
		html.replace(/(^.*?<body.*?>)(\n)?(.*?)(\n)?(<\/body>.*?$)/gm, "$3");

	handleCurrentActiveEmailSignature = (
		selectedSignature: EmailSignature | null
	) => {
		this.setState({
			currentActiveEmailSignature: selectedSignature
		});
	};

	validateEmails = (emails: EmailThreadParticipant[]) => {
		return emails.every(email => email?.email?.match(emailRegex));
	};

	getDefaultEmailPreference = () => {
		let defaultEmail: string | null | undefined = null;
		const documentPreferenceSettings =
			this.props.userData?.user_settings?.preference_settings
				?.document_preference_settings;
		// const userEmailIntegrationObj =
		// 	this.props.userCompany.integrations?.email?.find(
		// 		e => e.user_id === this.props.userData.id
		// 	);

		if (documentPreferenceSettings && this.props.isFromDocumentsPage) {
			const isDefaultFromAddress =
				documentPreferenceSettings.default_from_address &&
				(!documentPreferenceSettings.default_from_address_type ||
					documentPreferenceSettings.default_from_address_type ===
						DocumentDefaultPreferenceLastUsedEmail.FIXED_ADDRESS_TYPE);

			const isLastUsedFromAddress =
				!documentPreferenceSettings.default_from_address &&
				(!documentPreferenceSettings.default_from_address_type ||
					documentPreferenceSettings.default_from_address_type ===
						DocumentDefaultPreferenceLastUsedEmail.LAST_USED_ADDRESS_TYPE);

			// default from address
			if (isDefaultFromAddress) {
				defaultEmail = documentPreferenceSettings.default_from_address;
			}

			// last used address type
			if (isLastUsedFromAddress) {
				defaultEmail =
					this.props.userData.document_last_used_from_address;
			}

			// if (
			// 	this.props.sendDocumentFromConnectedEmailFlag &&
			// 	documentPreferenceSettings.is_nylas &&
			// 	userEmailIntegrationObj
			// ) {
			// 	defaultEmail = formatNylasIntegratedEmailId(
			// 		userEmailIntegrationObj.email
			// 	);
			// }
		} else if (this.state.dkimConnectedEmails.length > 0) {
			if (this.props.userEmailIntegrationObj?.email) {
				const integratedEmail = this.state.dkimConnectedEmails.find(
					email =>
						email.email ===
						this.props.userEmailIntegrationObj?.email
				);
				if (integratedEmail) defaultEmail = integratedEmail.email;
			}
		}

		if (!defaultEmail) {
			if (
				this.props.opportunityId ||
				this.props.partnerId ||
				this.props.clientId
			) {
				if (this.props.userEmailIntegrationObj?.email) {
					if (this.props.isSendingMs365InviteToClient) {
						return formatNylasIntegratedEmailId(
							this.props.userEmailIntegrationObj?.email
						);
					} else {
						return this.props.userEmailIntegrationObj?.email;
					}
				}
			} else {
				defaultEmail = process.env.REACT_APP_DEFAULT_EMAIL;
			}
		}

		return defaultEmail;
	};

	handleRecipientSearchStringChange = (value: string) => {
		this.setState({ recipientSearch: value });
	};

	handleSelect = (_value: string) => {
		this.setState({ recipientSearch: "" });
	};

	handleEmailValidation = (
		values: string[],
		emailType: EmailRecipientsTypes
	) => {
		try {
			if (!values.length && emailType == EmailRecipientsTypes.TO)
				throw new Error("Please add recipients");
			const allRecipientsIds =
				this.props.allEmailRecipients?.map(recipient => recipient.id) ||
				[];
			const isAllEmailsValid = values.every(
				email =>
					allRecipientsIds.includes(email) || email.match(emailRegex)
			);
			if (isAllEmailsValid) return Promise.resolve();
			throw new Error(`Invalid email`);
		} catch (err) {
			return Promise.reject(err);
		}
	};

	handleCollapse = (event: MouseEvent) => {
		if (!this.props.isFromDocumentsPage) {
			return;
		}
		if (this.recipientRef.current?.contains(event.target as HTMLElement)) {
			this.setState({
				shouldCollapse: false
			});
		} else {
			this.setState({
				shouldCollapse: true
			});
		}
	};

	getRecipientOptions = (
		selectedRecipients: string[] | undefined
	): IOptionProps[] => {
		const inactiveUserIds = this.props.companyUserList
			.filter(user => user.is_disabled)
			.map(user => user.id);
		return (
			this.props.allEmailRecipients
				?.filter(recipient => {
					const key = this.state.recipientSearch.trim().toLowerCase();
					const email = recipient.email?.toLowerCase();
					const id = recipient.id;
					const name = recipient.name?.toLowerCase();

					if (!email) {
						return;
					}

					if (!!key.length) {
						return (
							(email?.includes(key) || name?.includes(key)) &&
							!selectedRecipients?.includes(id)
						);
					}

					return selectedRecipients?.includes(id);
				})
				.map((recipient, index) => ({
					name: `${recipient.name} <${
						recipient.email ?? "No Email"
					}> ${
						inactiveUserIds.includes(recipient.id)
							? "(Inactive User)"
							: ""
					}`,
					value: recipient.id,
					disabled:
						!recipient.email ||
						recipient.email.length === 0 ||
						this.props.isFromDocumentsPage,
					key: index
				})) ?? []
		);
	};

	emailPreviewContent = () => {
		return (
			<Spin spinning={this.state.isPreviewLoading}>
				<div
					className="preview-email-subject"
					dangerouslySetInnerHTML={{
						__html:
							this.props.otherEntityEmailPreview?.subject || ""
					}}
				/>
				<IFRAME
					content={this.props.otherEntityEmailPreview?.body || ""}
				/>
			</Spin>
		);
	};

	render() {
		const url = this.props.isFromDocumentsPage
			? `${process.env.REACT_APP_API_HOST}/v2/files/upload`
			: `${process.env.REACT_APP_API_HOST}/v2/emails/attachments/upload`;
		const isMergeTagsLoading =
			this.props.emailThreadMergeTagVariables.length === 0;
		const isSkeletonLoading =
			process.env.NODE_ENV !== "test"
				? !process.env.REACT_APP_TINY_MCE ||
				  process.env.REACT_APP_TINY_MCE.length === 0
				: false ||
				  !this.props.emailThreadMessage ||
				  (this.props.emailThreadMessage &&
						!!!this.props.isFromDocumentsPage &&
						this.props.emailThreadMessage.account_id.length === 0);
		const defaultEmailPreference = this.getDefaultEmailPreference();

		const selectedRecipients: undefined | string[] =
			this.props.form.getFieldValue("to");
		const selectedCCRecipients: undefined | string[] =
			this.props.form.getFieldValue("cc");
		const selectedBCCRecipients: undefined | string[] =
			this.props.form.getFieldValue("bcc");

		const recipientOptions = this.getRecipientOptions(selectedRecipients);
		const ccRecipientOptions =
			this.getRecipientOptions(selectedCCRecipients);
		const bccRecipientOptions = this.getRecipientOptions(
			selectedBCCRecipients
		);

		// const userEmailIntegrationObj =
		// 	this.props.userCompany.integrations?.email?.find(
		// 		e => e.user_id === this.props.userData.id
		// 	);

		const { ZText } = ZTypography;

		// const showConnectedInbox =
		// 	(this.props.sendDocumentFromConnectedEmailFlag &&
		// 		this.props.isFromDocumentsPage) ||
		// 	this.props.isSendingMs365InviteToClient;

		return (
			<Drawer
				visible={this.props.visible}
				closable={true}
				destroyOnClose={true}
				onClose={this.onClose}
				className="email-thread-message-drawer zomentum-drawer"
				title={
					<Row>
						<Col span={24}>
							<div className="drawer-title-container">
								<Skeleton
									loading={!this.props.emailThreadMessage}
									active
									title
									paragraph={false}
								>
									<div className="drawer-title">
										{!this.props.isNewThread && (
											<Fragment>
												<span className="text-bold">
													Reply:{" "}
												</span>
												<span>
													{this.props
														.emailThreadMessage &&
													this.props
														.emailThreadMessage
														?.subject &&
													this.props
														.emailThreadMessage
														?.subject.length > 0
														? this.props
																.emailThreadMessage
																?.subject
														: "[NO SUBJECT]"}
												</span>
											</Fragment>
										)}
										{this.props.isNewThread && (
											<Fragment>
												<span>Send Email</span>
											</Fragment>
										)}
									</div>
								</Skeleton>
							</div>
						</Col>
					</Row>
				}
				data-testid="email-thread-message-drawer"
			>
				<Form
					layout="horizontal"
					labelCol={{ xs: 6, sm: 4, md: 3, lg: 2, xl: 2, xxl: 2 }}
					wrapperCol={{
						xs: 18,
						sm: 20,
						md: 21,
						lg: 22,
						xl: 22,
						xxl: 22
					}}
					onSubmit={this.onSubmit}
					autoComplete="off"
					className="email-thread-message-drawer-form"
				>
					<div className="drawer-form-container">
						<Skeleton
							loading={isSkeletonLoading}
							active
							paragraph={{ rows: 12 }}
						>
							<Row align="middle">
								<Col span={24}>
									<Form.Item
										label="From"
										labelAlign="left"
										className="recipients-from"
									>
										{this.props.form.getFieldDecorator(
											"from",
											{
												rules: [
													{
														message:
															"Please select email id"
													}
												],
												initialValue:
													defaultEmailPreference
											}
										)(
											<Select<string>
												getPopupContainer={trigger =>
													trigger.parentNode
												}
												size="small"
												data-testid="from"
												dropdownClassName="from-email-select-dropdown"
											>
												{defaultEmailPreference ===
													process.env
														.REACT_APP_DEFAULT_EMAIL && (
													<Select.Option
														key={
															process.env
																.REACT_APP_DEFAULT_EMAIL
														}
														value={
															process.env
																.REACT_APP_DEFAULT_EMAIL ??
															"noreply@zomentum.com"
														}
													>
														{`${this.props.userData.name.full} <${process.env.REACT_APP_DEFAULT_EMAIL}>`}
													</Select.Option>
												)}
												{/* {showConnectedInbox &&
													userEmailIntegrationObj && (
														<Select.Option
															value={formatNylasIntegratedEmailId(
																userEmailIntegrationObj.email
															)}
														>{`${this.props.userData.name.full} <${userEmailIntegrationObj.email}>  [ Connected Inbox ]`}</Select.Option>
													)} */}
												{!!this.props
													.isFromDocumentsPage
													? this.state
															.dkimConnectedEmails
															.length > 0 &&
													  this.state.dkimConnectedEmails.map(
															(email, index) => {
																return (
																	<Select.Option
																		key={
																			index
																		}
																		value={
																			email.email ??
																			undefined
																		}
																	>
																		{`${
																			email.name
																		} <${
																			email.email ??
																			"No Email"
																		}>`}
																	</Select.Option>
																);
															}
													  )
													: this.props.userEmailIntegrationObj?.email_aliases?.map(
															(alias, index) => {
																return (
																	<Select.Option
																		key={
																			index
																		}
																		value={
																			alias.email ??
																			""
																		}
																	>
																		{alias.email ??
																			"<No Email>"}
																	</Select.Option>
																);
															}
													  )}
												{!this.props
													.isFromDocumentsPage &&
													!this.props
														.isSendingMs365InviteToClient &&
													this.props
														.userEmailIntegrationObj
														?.email && (
														<Select.Option
															value={
																this.props
																	.userEmailIntegrationObj
																	.email
															}
														>
															{
																this.props
																	.userEmailIntegrationObj
																	.email
															}
														</Select.Option>
													)}
											</Select>
										)}
										<div className="recipients-toggle">
											<div
												className={`
													${
														this.state
															.showCcRecipients
															? "recipients-cc-toggle active"
															: "recipients-cc-toggle"
													}`}
												onClick={
													this.toggleCcRecipients
												}
												data-testid="cc"
											>
												CC
											</div>
											<div
												className={`${
													!this.props.isMobileView
														? "ml-3 "
														: ""
												}${
													this.state.showBccRecipients
														? "recipients-bcc-toggle active"
														: "recipients-bcc-toggle"
												}`}
												onClick={
													this.toggleBccRecipients
												}
												data-testid="bcc"
											>
												BCC
											</div>
										</div>
									</Form.Item>
									<div ref={this.recipientRef}>
										<Form.Item
											label="To"
											labelAlign="left"
											className="recipients-to"
										>
											{this.props.form.getFieldDecorator(
												"to",
												{
													rules: [
														{
															required: true,
															validator: (
																_,
																values
															) =>
																this.handleEmailValidation(
																	values,
																	EmailRecipientsTypes.TO
																)
														}
													],
													initialValue:
														this.props
															.defaultRecipients &&
														this.props
															.defaultRecipients
															.length > 0
															? this.props
																	.defaultRecipients
															: new Array<string>()
												}
											)(
												<ZSelect
													mode="tags"
													placeholder="Please add recipients"
													tokenSeparators={[",", " "]}
													autoClearSearchValue={true}
													dropdownClassName={`
														${this.props.isNewThread ? "" : "display-none"} ${
														!recipientOptions?.length &&
														this.state
															.recipientSearch
															.length === 0
															? "display-none"
															: ""
													}
													`}
													loading={
														this.props.isLoading
													}
													data-testid="recipients-select"
													maxTagCount={
														this.props
															.isFromDocumentsPage
															? this.state
																	.shouldCollapse
																? "responsive"
																: undefined
															: "responsive"
													}
													onSearch={
														this
															.handleRecipientSearchStringChange
													}
													style={{ width: "98%" }}
													filterOption={(
														input,
														option
													) =>
														option?.children &&
														!this.props
															.isFromDocumentsPage
															? option.children
																	?.toLowerCase()
																	?.includes(
																		input.toLowerCase()
																	)
															: false
													}
													onSelect={this.handleSelect}
													selectOptions={
														recipientOptions
													}
												/>
											)}
										</Form.Item>
									</div>
								</Col>
								{this.state.showCcRecipients && (
									<Col span={24}>
										<Form.Item
											label="CC"
											labelAlign="left"
											className="recipients-cc"
										>
											{this.props.form.getFieldDecorator(
												"cc",
												{
													rules: [
														{
															required: false,
															validator: (
																_,
																values
															) =>
																this.handleEmailValidation(
																	values,
																	EmailRecipientsTypes.CC
																)
														}
													]
												}
											)(
												<ZSelect<string[]>
													getPopupContainer={trigger =>
														trigger.parentNode
													}
													mode="tags"
													placeholder="Please add CC recipients"
													tokenSeparators={[",", " "]}
													autoClearSearchValue={true}
													notFoundContent={null}
													loading={
														this.props.isLoading
													}
													onSearch={
														this
															.handleRecipientSearchStringChange
													}
													filterOption={(
														input,
														option
													) =>
														option?.children &&
														!this.props
															.isFromDocumentsPage
															? option.children
																	?.toLowerCase()
																	?.includes(
																		input.toLowerCase()
																	)
															: false
													}
													onSelect={this.handleSelect}
													selectOptions={
														ccRecipientOptions
													}
													data-testid="cc-select"
												/>
											)}
										</Form.Item>
									</Col>
								)}
								{this.state.showBccRecipients && (
									<Col span={24}>
										<Form.Item
											label="BCC"
											labelAlign="left"
											className="recipients-bcc"
										>
											{this.props.form.getFieldDecorator(
												"bcc",
												{
													rules: [
														{
															required: false,
															validator: (
																_,
																values
															) =>
																this.handleEmailValidation(
																	values,
																	EmailRecipientsTypes.BCC
																)
														}
													]
												}
											)(
												<ZSelect<string[]>
													getPopupContainer={trigger =>
														trigger.parentNode
													}
													mode="tags"
													placeholder="Please add BCC recipients"
													tokenSeparators={[",", " "]}
													autoClearSearchValue={true}
													notFoundContent={null}
													loading={
														this.props.isLoading
													}
													onSearch={
														this
															.handleRecipientSearchStringChange
													}
													filterOption={(
														input,
														option
													) =>
														option?.children &&
														!this.props
															.isFromDocumentsPage
															? option.children
																	?.toLowerCase()
																	?.includes(
																		input.toLowerCase()
																	)
															: false
													}
													onSelect={this.handleSelect}
													selectOptions={
														bccRecipientOptions
													}
													data-testid="bcc-select"
												/>
											)}
										</Form.Item>
									</Col>
								)}
								{this.props.isNewThread && (
									<Col span={24}>
										<div
											ref={this.subjectBlockContainerRef}
										>
											<Form.Item
												label="Subject"
												labelAlign="left"
												className="subject"
											>
												<Skeleton
													loading={
														this.state
															.emailThreadMessageEditorLoading ||
														this.state.isLoading ||
														isMergeTagsLoading
													}
													active
													title
													paragraph={false}
												>
													{!isMergeTagsLoading && (
														<Editor
															id={`${
																this.props
																	.isNewThread
																	? EmailThreadMessageEditorType.Compose
																	: EmailThreadMessageEditorType.Reply
															}-subject`}
															apiKey={
																process.env
																	.REACT_APP_TINY_MCE
															}
															init={{
																...TinyMceOptions,
																plugins: [],
																menubar: false,
																inline: true,
																setup: (
																	editor: any
																) => {
																	editor.on(
																		"keydown",
																		(
																			event: KeyboardEvent
																		) => {
																			if (
																				event.keyCode ===
																				13
																			) {
																				event.preventDefault();
																			}
																		}
																	);
																},
																content_style: `
																		.mce-content-body {
																			font-size: 14px;
																			font-family: 'Source Sans Pro';
																		}
																		#email-thread-message-compose-editor-subject p {
																			margin: 0 0 0 0.7rem;
																		}
																		.merge-tag {
																			background: #ffedb4;
																			cursor: auto !important;
																		}
																	`,
																init_instance_callback:
																	(
																		editor: any
																	) => {
																		if (
																			this
																				.props
																				.isSendingMs365InviteToClient
																		) {
																			editor.setContent(
																				"Action required - Microsoft 365 Assessment"
																			);
																		} else if (
																			this
																				.state
																				.systemSendEmailTemplate
																		) {
																			const convertedSystemTemplateTextToMergeTags =
																				convertTextToMergeTag(
																					this
																						.props
																						.emailThreadMergeTagVariables,
																					this
																						.state
																						.systemSendEmailTemplate
																						?.subject,
																					true
																				);

																			editor.setContent(
																				updateMergeTagsDisplayName(
																					this
																						.props
																						.emailThreadMergeTagVariables,
																					convertedSystemTemplateTextToMergeTags
																				)
																			);
																		} else if (
																			this
																				.props
																				.defaultSubject
																		) {
																			const convertedTextFromMergeTags: string =
																				this
																					.props
																					.useTemplate &&
																				this
																					.props
																					.defaultSubject
																					? convertTextToMergeTag(
																							this
																								.props
																								.emailThreadMergeTagVariables,
																							this
																								.props
																								.defaultSubject
																					  )
																					: this
																							.props
																							.defaultSubject;

																			editor.setContent(
																				updateMergeTagsDisplayName(
																					this
																						.props
																						.emailThreadMergeTagVariables,
																					convertedTextFromMergeTags
																				)
																			);
																		}
																	}
															}}
															onEditorChange={
																this
																	.updateTextFromSubjectTinyMCEEditor
															}
															onInit={
																this
																	.initializeEmailThreadMessageDrawerEditor
															}
														/>
													)}
												</Skeleton>
											</Form.Item>
										</div>
									</Col>
								)}
							</Row>
							<Skeleton
								loading={
									this.state
										.emailThreadMessageEditorLoading ||
									this.state.isLoading ||
									isMergeTagsLoading
								}
								active
								paragraph={{ rows: 12 }}
							/>
							<div ref={this.textBlockContainerRef}>
								{!isMergeTagsLoading && (
									<Editor
										id={
											this.props.isNewThread
												? EmailThreadMessageEditorType.Compose
												: EmailThreadMessageEditorType.Reply
										}
										apiKey={process.env.REACT_APP_TINY_MCE}
										init={{
											...TinyMceOptions,
											plugins: TinyMcePlugins,
											placeholder:
												this.props.bodyPlaceholder,
											content_style: `
										.mce-content-body {
											font-size: 12px;
											font-family: 'Source Sans Pro';
											margin-left: 20px !important;
										}
										.merge-tag {
											background: #ffedb4;
											cursor: auto !important;
										}
									`,
											init_instance_callback: (
												editor: any
											) => {
												if (
													this.state
														.systemSendEmailTemplate &&
													!this.state
														.systemSendEmailTemplate
														.system_template_info
												) {
													editor.setContent(
														updateMergeTagsDisplayName(
															this.props
																.emailThreadMergeTagVariables,
															this.state
																.systemSendEmailTemplate
																?.body
														)
													);
												} else if (
													this.props.templateBody
												) {
													editor.setContent(
														updateMergeTagsDisplayName(
															this.props
																.emailThreadMergeTagVariables,
															this.props
																.templateBody
														)
													);
												}
												initializeTinyMCEPrePastePrompt(
													editor
												);
											}
										}}
										onEditorChange={
											this
												.updateTextFromEmailBodyTinyMCEEditor
										}
										onInit={
											this
												.initializeEmailThreadMessageDrawerEditor
										}
									/>
								)}
							</div>

							<ZSection
								className="border-none ml-5 z-section-preview-info"
								shouldApplyLeftPaddingForChildren={false}
								bottomBorder={false}
								first={false}
							>
								<div
									className={`${
										!!this.state.currentActiveEmailSignature
											?.body?.length
											? ""
											: "mb-4"
									} mt-4 font-medium text-gray-7`}
									dangerouslySetInnerHTML={{
										__html:
											this.state
												.currentActiveEmailSignature
												?.body ?? ""
									}}
								/>
							</ZSection>

							<div className="email-thread-message-files-container border-solid border-0 border-t border-b border-gray-5">
								<Row align="middle">
									<Col span={12}>
										<Upload
											name="file"
											id="file"
											method="POST"
											action={url}
											accept="*"
											headers={{
												Authorization: `Bearer ${
													(
														cache.get(
															ZomentumStorageKeys.Token,
															true
														) as AuthToken
													)?.token
												}`
											}}
											showUploadList={true}
											onChange={
												this.uploadEmailThreadFile
											}
											onRemove={
												this.deleteEmailThreadFile
											}
											beforeUpload={
												this.beforeUploadEmailThreadFile
											}
											className="email-thread-message-file-upload-container"
											fileList={
												this.state.listOfAttachments
											}
											data-testid="upload-attachment"
										>
											<div className="email-thread-upload-file-link">
												<ZIcon
													name={EIcons.UPLOADV2}
													size="sm"
												/>
												<ZText
													className="ml-2"
													weight={EFontWeights.MEDIUM}
													color={EColors.BLUE}
													colorVariant={6}
												>
													Upload Attachment
												</ZText>
											</div>
										</Upload>
									</Col>
								</Row>
							</div>
						</Skeleton>
						<div
							style={{
								position: "absolute",
								top: this.state.mergeTagDropdownY,
								left: this.state.mergeTagDropdownX,
								zIndex: 50
							}}
						>
							<ZMergeTagCascadeDropdown
								shouldAllowAddVariable={false}
								mergeTagSystemVariables={
									this.props.emailThreadMergeTagVariables
								}
								mergeTagCustomVariables={[]}
								visible={this.state.mergeTagDropdownVisible}
								onSubmit={this.handleSubmitMergeTagVariable}
								onClose={this.handleCloseMergeTagDropdown}
								MergeTagListToSkip={MergeTagListToSkip}
								MergeTagChildrenListToSkip={
									MergeTagChildrenListToSkip
								}
							/>
						</div>
					</div>
					<div className="ant-drawer-footer">
						{!this.props.isMobileView && (
							<div className="footer-activities">
								<div
									className={`footer-activities-text preview-email ${
										this.state.isPreviewEmailModalOpen
											? "icon-active"
											: ""
									}`}
								>
									<ZButton
										type="link"
										onClick={this.verifyAndOpenPreviewModal}
										iconName={ETwoToneIcons.EYETWOTONE}
									>
										<ZText
											weight={EFontWeights.MEDIUM}
											className="truncate"
										>
											Preview Email
										</ZText>
									</ZButton>
								</div>
								{!this.props.isDocumentMessage && (
									<TrackEmail
										value={this.state.emailTracking}
										isTrackingEnabled={
											!!this.state.emailTracking
										}
										updateEmailTracking={
											this.updateEmailTrackingDetails
										}
									/>
								)}
								{!this.props.isFromEmailTemplatesPage && (
									<ZButton
										type="link"
										className="footer-activities-text templates-button"
										onClick={
											this.showImportEmailTemplateModal
										}
									>
										<ZText
											weight={EFontWeights.MEDIUM}
											colorVariant={6}
											color={EColors.BLUE}
										>
											Templates
										</ZText>
									</ZButton>
								)}

								<EmailSignatureComponent
									updateCurrentActiveEmailSignature={
										this.handleCurrentActiveEmailSignature
									}
									currentActiveSignature={
										this.state.currentActiveEmailSignature
									}
								/>
							</div>
						)}
						<div
							className={`${
								this.props.isMobileView
									? "flex justify-end w-full mb-2"
									: ""
							}`}
						>
							<ZButton
								type="default"
								htmlType="button"
								disabled={
									this.props.isLoading ||
									this.state.emailThreadMessageFileUploading
								}
								onClick={this.onClose}
								className="margin-right-8px"
							>
								Close
							</ZButton>
							<ZButton
								type="primary"
								htmlType="submit"
								loading={
									this.props.isLoading ||
									this.state
										.emailThreadMessageFileUploading ||
									this.state
										.emailThreadMessageEditorLoading ||
									this.state.isLoading
								}
								iconPlacement="right"
								icon={
									<Player
										className="w-4 h-4 ml-1"
										src={Send}
										autoplay
										loop
									/>
								}
							>
								Send
							</ZButton>
						</div>
					</div>
				</Form>

				<ZModal
					title="Preview Email"
					centered={true}
					visible={this.state.isPreviewEmailModalOpen}
					onOk={this.closePreviewModal}
					onCancel={this.closePreviewModal}
					wrapClassName="preview-email-modal"
					footer={[
						<Button
							key={`preview-close-button`}
							onClick={this.closePreviewModal}
						>
							Close
						</Button>
					]}
					size="lg"
				>
					<Fragment>
						{this.emailPreviewContent()}
						{!!this.state.listOfAttachments.length && (
							<div className="mt-4">
								<ZTypography.ZText weight={EFontWeights.MEDIUM}>
									Uploaded attachments:
								</ZTypography.ZText>
								<ul>
									{this.state.listOfAttachments.map(
										(attachment, index) => (
											<li key={`attachment-${index}`}>
												<ZTypography.ZText>
													{attachment.name}
												</ZTypography.ZText>
											</li>
										)
									)}
								</ul>
							</div>
						)}
					</Fragment>
				</ZModal>

				<Modal
					wrapClassName="validate-email-modal"
					footer={[null]}
					centered
					closable={false}
					visible={this.state.isValidating}
				>
					<Spin tip="Checking for empty merge tags" spinning={true} />
				</Modal>
				<EmptyMergeTagsAlertModal
					visible={this.state.isEmptyMergeTagsAlertModalOpen}
					closeModal={() => {
						this.closeEmptyMergeTagsAlertModal();
					}}
					emptyMergeTags={this.state.emptyMergeTags}
					isMergeTagMissing={!!this.state.emptyMergeTags.length}
					showEmptyFields={
						this.state.isEmptyMergeTagsAlertFromSendEmail
					}
					confirmText={
						this.state.isEmptyMergeTagsAlertFromSendEmail
							? "Proceed with Sending Email"
							: "Proceed with Preview Email"
					}
					confirmFromModal={
						this.state.isEmptyMergeTagsAlertFromSendEmail
							? shouldReplaceWithBlank =>
									this.continueToSendEmail(
										shouldReplaceWithBlank
									)
							: shouldReplaceWithBlank =>
									this.continueToPreviewEmail(
										shouldReplaceWithBlank
									)
					}
					clientId={this.props.clientId}
					partnerId={this.props.partnerId ?? null}
					opportunityId={this.props.opportunityId ?? null}
				/>
				<ImportEmailTemplateModal
					visible={this.state.isImportEmailTemplateModalOpen}
					onSelect={this.handleSelectEmailTemplate}
					onCancel={this.closeImportEmailTemplateModal}
				/>

				<Modal
					title="Missing mandatory merge tags"
					closable
					centered
					visible={this.state.isMandatoryMergeTagModalOpen}
					onCancel={this.closeMandatoryMergeTagModal}
					wrapClassName="mandatory-merge-tag-modal"
					footer={[
						<Button
							key={0}
							onClick={this.closeMandatoryMergeTagModal}
						>
							Close
						</Button>
					]}
				>
					<div
						style={{
							marginBottom: "15px",
							paddingBottom: "10px"
						}}
					>
						Send Document Email should contain&nbsp;
						<span className="merge-tag mceNonEditable">
							{MERGE_TAG_START}Receiver.Document.Url
							{MERGE_TAG_END}
						</span>
						&nbsp; merge tag.
					</div>
				</Modal>

				<Modal
					title="Address not authorized"
					closable
					centered
					visible={this.state.emailExpiredModalVisible}
					onCancel={this.closeEmailExpiredModal}
					footer={[
						<Button
							key={0}
							type="link"
							onClick={() =>
								this.props.history.push(
									ZomentumRoutes.EmailSettings
								)
							}
						>
							Reauthorize
						</Button>,
						<Button
							key={1}
							type="primary"
							onClick={this.handleEmailExpiration}
						>
							Use Default
						</Button>
					]}
				>
					{this.state.emailExpiredMessage !== "" ? (
						this.state.emailExpiredMessage?.split(
							"Bad Request: "
						)[1]
					) : (
						<ZText>
							The address you are attempting to send the document
							from is no longer authorized. Please reauthorize the
							email address or send via Zomentum’s default email
							address{" "}
							<ZText weight={EFontWeights.MEDIUM}>
								({process.env.REACT_APP_DEFAULT_EMAIL})
							</ZText>
						</ZText>
					)}
				</Modal>
			</Drawer>
		);
	}
}

const ConnectedForm = Form.create<Props>({
	name: "email_thread_messages_form"
})(EmailThreadMessageDrawerClassComp);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	withRouter((props: HOCProps) => {
		const send_doc_using_nylas = useFeatureFlag("send_doc_using_nylas");
		const isMobileView = useMobileView();

		return (
			<ConnectedForm
				{...props}
				sendDocumentFromConnectedEmailFlag={send_doc_using_nylas}
				isMobileView={isMobileView}
			/>
		);
	})
);
