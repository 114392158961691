'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var common = require('../common.js');
var index = require('../Users/index.js');

var _a;
exports.DocumentApproverType = void 0;
(function (DocumentApproverType) {
  DocumentApproverType["FixedSet"] = "fixed_set";
  DocumentApproverType["AllAdmins"] = "all_admin";
})(exports.DocumentApproverType || (exports.DocumentApproverType = {}));
var ApprovalAutomationDetails = /** @class */function () {
  function ApprovalAutomationDetails() {
    this.document_approver_type = exports.DocumentApproverType.FixedSet;
    this.approver_user_ids = new Array();
  }
  return ApprovalAutomationDetails;
}();
var DocumentApproval = /** @class */function () {
  function DocumentApproval() {
    this.id = "";
    this.company_id = "";
    this.deleted = false;
    this.created = new Date();
    this.updated = new Date();
    this.creator_user_id = "";
    this.creator_user = new index.UserData();
    this.trigger_entity_type = null;
    this.trigger_action_type = null;
    this.filter_criteria = [];
    this.is_enabled = false;
    this.filter_criteria_combiner = null;
    this.title = "";
    this.description = "";
    this.trigger_popover_visible = false;
    this.approver_automation_details = new ApprovalAutomationDetails();
  }
  return DocumentApproval;
}();
var UpdatedFilterNameMap = (_a = {}, _a[common.ZomentumEntities.DocumentBlock] = "Quote Line Items", _a);

exports.ApprovalAutomationDetails = ApprovalAutomationDetails;
exports.DocumentApproval = DocumentApproval;
exports.UpdatedFilterNameMap = UpdatedFilterNameMap;
