import { getProductSKUDetails } from "@/Services/Vendor/SKU";
import ZUploadMediaWithPreview from "@organisms/ZForm/ZUploadMediaWithPreviewWidget";
import { ZTooltip, ZTypography } from "@zomentum/atoms";
import { FontWeights } from "@zomentum/atoms/dist/ZTypography";
import { EIcons } from "@zomentum/atoms/dist/ZIcon/interface";
import { EFontWeights } from "@zomentum/atoms/dist/ZTypography/interface";
import ConfigProvider from "antd/lib/config-provider";
import ZTable from "@zomentum/organisms/dist/ZTable";
import ZButton from "@zomentum/atoms/dist/ZButton";
import ZMessage from "@zomentum/molecules/dist/ZMessage";
import { Col, Row, Switch } from "antd";
import {
	CustomField,
	CustomFieldType,
	ProductBillingType,
	productBillingTypeOptions,
	ProductType,
	QuoteProduct,
	SKUProduct,
	UOM
} from "@zomentum/contracts";
import {
	BundledSKUS,
	BundledSKUSProduct
} from "@zomentum/contracts/dist/Vendor/SKU";
import ProductSKUDrawer from "@pages/Vendor/MarketplaceProductSKUs/ProductSKUDrawer";
import AddProductSKUToQuoteBlockDrawer from "@pages/Vendor/Templates/TemplateDetailPage/Drawers/AddProduct";

import {
	handleMarginChange,
	handleMarkupChange,
	handleMarkupMargin
} from "@/Components/Utils/PricingCalculator";
import { ZDrawer } from "@zomentum/molecules";
import ZForm, {
	getCustomFieldProps,
	getWidget
} from "@zomentum/organisms/dist/ZForm";

import ZInputNumber from "@zomentum/atoms/dist/ZInputNumber";
import ZIcon, { Icons } from "@zomentum/atoms/dist/ZIcon";
import ZCheckbox from "@zomentum/atoms/dist/ZCheckbox";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
	EWidgets,
	IFormProps,
	IFormSectionProps
} from "@zomentum/organisms/dist/ZForm/interface";
import {
	convertEnumToSelectOptions,
	filterOutFormulaFields,
	processCustomFieldFormValues
} from "@zomentum/utils";
import { useForm } from "antd/lib/form/Form";
import React, { FC, useState, useEffect, ReactText } from "react";
import { getInitialValueForCustomField } from "@/Components/UI/CustomFields";
import { RadioChangeEvent } from "antd";
import { EInputNumberWidths } from "@zomentum/atoms/dist/ZInputNumber/interface";
import { IOptionProps } from "@zomentum/atoms/dist/ZSelect/interface";
import { Rule } from "antd/lib/form";
import { IConfigureProductSKUInQuoteBlockDrawer } from "./interface";
import { ReactComponent as EmptyBundleProductsIcon } from "@/Assets/ProductsPage/EmptyBundleProductsIcon.svg";
import {
	EditOrCloneProductDrawerMode,
	EProductSKUDrawerState
} from "./interface";

import BundledProductTableColumns from "@templates/ZDrawers/ZProducts/molecules/ZBundledProductTableColumns";

import {
	getCurrencySettings,
	currencyFormatter,
	percentageFormatter
} from "@zomentum/utils/dist/Currency";
import { useFeatureFlag } from "@zomentum/hooks/dist/hooks/useFeature";
import { useBundlesSettings } from "@zomentum/hooks";
import { useSelector } from "react-redux";
import { GlobalState } from "@/Reducers/interface";

const { ZText } = ZTypography;

const excludedTypes: string[] = [ProductType.Bundle, ProductType.Other];

const typeOptions = (
	convertEnumToSelectOptions(ProductType, "select") as IOptionProps[]
)?.filter(option => !excludedTypes.includes(option.value.toString()));

const uomOptions = convertEnumToSelectOptions(UOM, "select");

const ConfigureProductSKUInQuoteBlockDrawer: FC<
	IConfigureProductSKUInQuoteBlockDrawer
> = ({ visible, onClose, onSubmit, productData, drawerMode = "edit" }) => {
	const currency = getCurrencySettings().currency;
	const [form] = useForm();
	const { customFieldListMarketPlace } = useSelector(
		(state: GlobalState) => ({
			customFieldListMarketPlace: filterOutFormulaFields(
				state.customFields.customFieldList.marketplace_sku
			)
		})
	);

	const [currentActiveSKU, setCurrentActiveSKU] = useState<SKUProduct | null>(
		null
	);
	const [loading, setLoading] = useState(false);
	const [customFieldList, setCustomFieldList] = useState<CustomField[]>(
		new Array<CustomField>()
	);
	const [editSKUProduct, setEditSKUProduct] = useState<SKUProduct>();
	const [addSKUProduct, setAddSKUProduct] = useState<boolean>();
	const [showIndividualPricing, setShowIndividualPricing] =
		useState<boolean>();
	const [bundledSkus, setBundledSkus] = useState<BundledSKUSProduct[]>([]);
	const [showProductSearch, setShowProductSearch] = useState(false);

	const handleProductClose = () => {
		onClose();
		form.resetFields();
	};

	const getProductDetails = async () => {
		if (!productData?.vendor_product_id.length) return;
		const productSKUId = productData.vendor_product_id.split(":")?.[1];
		setLoading(true);
		try {
			const response = await getProductSKUDetails(productSKUId);
			setCurrentActiveSKU(response);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const getCustomFieldsList = () => {
		setCustomFieldList(
			customFieldListMarketPlace.filter(field => !field.system_field_id)
		);
	};

	const handlePricingChange = (selectedPricingId?: string | null) => {
		if (!selectedPricingId?.length) return;
		const pricings = currentActiveSKU?.pricings;
		const selectedPricing = pricings?.find(
			pricing => pricing.id === selectedPricingId
		);

		const vendorSKUNumber =
			selectedPricing?.vendor_sku_no ??
			form.getFieldValue("vendor_sku_number");

		const margin: number = form.getFieldValue("margin") ?? 0;
		const markup: number = form.getFieldValue("markup") ?? 0;
		const costPrice: number = form.getFieldValue("cost_price") ?? 0;
		const calculatedSellPrice = !!selectedPricing
			? selectedPricing?.sell_price
			: markup * 0.01 * costPrice + costPrice;

		form.setFieldsValue({
			vendor_sku_number: vendorSKUNumber,
			selected_cost_price: selectedPricing?.cost_price ?? 0,
			selected_setup_price: selectedPricing?.setup_price ?? 0,
			selected_billing_period:
				selectedPricing?.billing_period ?? ProductBillingType.OneTime,
			sell_price: calculatedSellPrice,
			margin,
			markup
		});
	};

	const pricingOptions = [
		...(currentActiveSKU?.pricings.map(pricing => ({
			value: pricing.id,
			label: pricing.name
		})) ?? []),
		{
			value: "custom",
			label: "Custom"
		}
	];

	const getFieldValues = () => {
		return form.getFieldsValue([
			"selected_cost_price",
			"sell_price",
			"selected_setup_price",
			"margin",
			"markup",
			"vendor_sku_no",
			"quantity"
		]);
	};

	const onCostPriceChange = (newCostPrice?: number | string | null): void => {
		if (newCostPrice === undefined || newCostPrice === null) {
			return;
		}
		const values = getFieldValues();
		form.setFieldsValue(
			handleMarkupMargin(
				Number(newCostPrice),
				values.sell_price,
				values.quantity,
				false,
				values.selected_setup_price
			)
		);
	};

	const onMarkupChange = (newMarkup?: number | string | null): void => {
		if (newMarkup === undefined || form.getFieldValue("cost_price") === 0) {
			return;
		}
		const values = getFieldValues();
		form.setFieldsValue(handleMarkupChange(values, Number(newMarkup)));
	};

	const onMarginChange = (newMargin?: number | string | null): void => {
		if (
			newMargin === undefined ||
			newMargin === null ||
			form.getFieldValue("selected_cost_price") === 0
		) {
			return;
		}
		const values = getFieldValues();

		if (
			Number(newMargin) >= 100 &&
			Number(getFieldValues().selected_cost_price) !== 0
		) {
			return;
		}
		form.setFieldsValue(handleMarginChange(values, Number(newMargin)));
	};

	const onSellPriceChange = (newSellPrice?: number | string | null): void => {
		if (newSellPrice === undefined || newSellPrice === null) {
			return;
		}
		const values = getFieldValues();
		form.setFieldsValue(
			handleMarkupMargin(
				values.selected_cost_price,
				Number(newSellPrice),
				values.quantity,
				false,
				values.selected_setup_price
			)
		);
	};

	const onQuantityChange = (newQuantity?: number | string | null): void => {
		if (newQuantity === undefined || newQuantity === null) {
			return;
		}
		const values = getFieldValues();
		form.setFieldsValue(
			handleMarkupMargin(
				values.selected_cost_price,
				values.sell_price,
				Number(newQuantity),
				values.should_add_setup_price_to_margin,
				values.selected_setup_price
			)
		);
	};

	const onSetupPriceChange = (newSetupPrice?: ReactText | null): void => {
		if (newSetupPrice === undefined || newSetupPrice === null) {
			return;
		}
		const values = getFieldValues();
		if (!values.should_add_setup_price_to_margin) {
			return;
		}
		form.setFieldsValue(
			handleMarkupMargin(
				values.selected_cost_price,
				values.sell_price,
				values.quantity,
				values.should_add_setup_price_to_margin,
				Number(newSetupPrice)
			)
		);
	};

	const onAddSetupPriceToMarginChange = (e): void => {
		const values = getFieldValues && getFieldValues();
		form.setFieldsValue(
			handleMarkupMargin(
				values?.selected_cost_price,
				values?.sell_price,
				values.quantity,
				e.target.checked,
				values?.selected_setup_price
			)
		);
	};

	const {
		pricingDisabled,
		showChildItems,
		showQuantity,
		showPrice,
		setShowChildItems,
		setShowQuantity,
		setShowPrice,
		handleChangeChildItems,
		handleChangeQuantity,
		handleChangePrice
	} = useBundlesSettings();
	const enable_bundles_enchancements = useFeatureFlag(
		"enable_bundles_enchancements"
	);

	const onFinish = (values: QuoteProduct) => {
		const { custom_field_form_values, ...formValues } = values;

		let updateQuoteProduct: QuoteProduct = {
			...productData,
			...formValues
		};

		if (enable_bundles_enchancements) {
			updateQuoteProduct = {
				...updateQuoteProduct,
				hide_child_items_in_quote_block: !showChildItems,
				show_child_quantity: showQuantity,
				show_individual_product_pricing: showPrice
			};
		}

		if (custom_field_form_values && !!customFieldList.length) {
			updateQuoteProduct.custom_fields = processCustomFieldFormValues(
				custom_field_form_values,
				customFieldList
			);
		}

		if (updateQuoteProduct.product_type === ProductType.Bundle) {
			if (bundledSkus.length === 0) {
				ZMessage.error("Please add atleast 1 product to bundle");
				return;
			} else {
				const formatBundledSkus = bundledSkus.map(product => ({
					description: product.description,
					type: product.type,
					name: product.name,
					sku_id: product.id,
					vendor_product_id: `marketplace:${product.id}`,
					selected_setup_price: product.pricings[0].setup_price ?? 0,
					selected_cost_price: product.pricings[0].cost_price ?? 0,
					selected_pricing_id: product.selected_pricing_id,
					selected_billing_period:
						product.pricings[0].billing_period ??
						ProductBillingType.OneTime,
					sell_price: product.pricings[0].sell_price ?? 0,
					quantity: product.quantity,
					is_optional: product.is_optional
				}));

				if (updateQuoteProduct.show_individual_product_pricing) {
					updateQuoteProduct = {
						...updateQuoteProduct,
						show_individual_product_pricing:
							updateQuoteProduct.show_individual_product_pricing,
						pricing: form.getFieldValue("pricings") || []
					};
				}
				updateQuoteProduct = {
					...updateQuoteProduct,
					bundled_skus: formatBundledSkus
				};
			}
		}
		onSubmit({ ...updateQuoteProduct });
	};

	const handleUpdatePriceFields = (
		updateKey: string,
		value: string | number,
		identifier: string
	) => {
		const updatedBundleSkus = bundledSkus.map(item => {
			if (item.id === identifier) {
				const updatePricings = item.pricings[0];
				updatePricings[updateKey] = value;
				return { ...item, pricings: [updatePricings] };
			} else return item;
		});
		setBundledSkus([...updatedBundleSkus]);
	};

	const handleUpdateFields = (
		updateKey: string,
		value: number | boolean,
		identifier: string
	) => {
		if (updateKey === "removeSKU") {
			const filteredSkus = bundledSkus.filter(
				item => item.id !== identifier
			);
			setBundledSkus([...filteredSkus]);
		} else {
			const updatedBundleSkus = bundledSkus.map(item => {
				if (item.id === identifier) {
					const updatedProductDetails = item;
					updatedProductDetails[updateKey] = value;
					return updatedProductDetails;
				} else return item;
			});
			setBundledSkus([...updatedBundleSkus]);
		}
	};

	const onEditDrawerOpen = val => setEditSKUProduct(val);

	const handleSkuPriceChange = (productId, pricingId) => {
		setBundledSkus(bundledProducts => {
			const findProduct = bundledProducts.find(
				item => item.id === productId
			);
			if (findProduct) {
				const findPricing = findProduct.pricingPlans?.find(
					pricing => pricing.id === pricingId
				);
				if (findPricing) {
					return bundledProducts.map(item => {
						if (findProduct.id === item.id)
							return {
								...item,
								selected_pricing_id: pricingId,
								pricings: [
									{
										cost_price: findPricing.cost_price,
										sell_price: findPricing.sell_price,
										setup_price: findPricing.setup_price,
										billing_period:
											findPricing.billing_period
									}
								]
							};
						return item;
					});
				} else return bundledProducts;
			} else return bundledProducts;
		});
	};

	const getBundledProductTableColumns = () =>
		BundledProductTableColumns({
			handleUpdatePriceFields: handleUpdatePriceFields,
			handleUpdateFields: handleUpdateFields,
			onEditDrawerOpen,
			handlePricingChange: handleSkuPriceChange
		});

	useEffect(() => {
		if (!visible) return;
		getProductDetails();
		getCustomFieldsList();
	}, [visible]);

	useEffect(() => {
		if (!currentActiveSKU) return;

		const selectedPricing = currentActiveSKU.pricings.find(
			pricing => pricing.id === productData?.selected_pricing_id
		);

		const costPrice =
			productData?.selected_cost_price ??
			selectedPricing?.cost_price ??
			0;

		const sellPrice =
			productData?.sell_price ?? selectedPricing?.sell_price ?? 0;

		const setupPrice =
			productData?.selected_setup_price ??
			selectedPricing?.setup_price ??
			0;

		const { markup, margin } = handleMarkupMargin(
			costPrice,
			sellPrice,
			productData?.quantity ?? 0,
			!!productData?.should_add_setup_price_to_margin,
			setupPrice
		);

		const customFieldFormValues = customFieldList.reduce(
			(prev, curr) => {
				const isDateCustomField =
					curr.field_type === CustomFieldType.DateField;
				const initialValue = currentActiveSKU
					? getInitialValueForCustomField(
							currentActiveSKU,
							isDateCustomField,
							curr
					  )
					: undefined;
				return {
					custom_field_form_values: {
						...prev.custom_field_form_values,
						[curr.id]: initialValue
					}
				};
			},
			{ custom_field_form_values: {} }
		);

		if (
			currentActiveSKU.type === ProductType.Bundle &&
			currentActiveSKU.bundled_skus &&
			productData &&
			productData?.bundled_skus &&
			productData?.bundled_skus.length > 0
		) {
			const bundleSkuMap = productData.bundled_skus.reduce(
				(acc, item) => {
					if (item?.vendor_product_id)
						acc[item?.vendor_product_id] = item;
					return acc;
				},
				{} as any
			);
			const currentActiveSKUBundleSKUs: BundledSKUS[] = [];
			for (const sku of productData.bundled_skus) {
				for (const bundled_skus of currentActiveSKU.bundled_skus) {
					if (
						sku.vendor_product_id ===
						`marketplace:${bundled_skus.sku_id}`
					) {
						currentActiveSKUBundleSKUs.push(bundled_skus);
					}
				}
			}

			setBundledSkus(
				currentActiveSKUBundleSKUs.map(product => {
					return {
						description:
							product.sku_id &&
							bundleSkuMap[`marketplace:${product.sku_id}`]
								.description,
						name:
							product.sku_id &&
							bundleSkuMap[`marketplace:${product.sku_id}`].name,
						id: product.sku_id,
						quantity:
							product.sku_id &&
							bundleSkuMap[`marketplace:${product.sku_id}`]
								.quantity,
						is_optional:
							product.sku_id &&
							bundleSkuMap[`marketplace:${product.sku_id}`]
								.is_optional,
						type: product?.marketplace_sku?.type || "",
						unit_of_measure:
							product?.marketplace_sku?.unit_of_measure,
						images: product?.marketplace_sku?.images,
						selected_pricing_id: product?.selected_pricing_id,
						pricingPlans: product?.marketplace_sku?.pricings || [],
						marketplace_listings: product?.marketplace_sku
							?.marketplace_listings.length
							? product?.marketplace_sku.marketplace_listings.map(
									listing => listing.id || listing
							  )
							: ["none"],
						pricings: [
							{
								setup_price:
									product.sku_id &&
									bundleSkuMap[
										`marketplace:${product.sku_id}`
									].selected_setup_price,
								cost_price:
									product.sku_id &&
									bundleSkuMap[
										`marketplace:${product.sku_id}`
									].selected_cost_price,
								billing_period:
									(product.sku_id &&
										bundleSkuMap[
											`marketplace:${product.sku_id}`
										].selected_billing_period) ??
									ProductBillingType.OneTime,
								sell_price:
									product.sku_id &&
									bundleSkuMap[
										`marketplace:${product.sku_id}`
									].sell_price
							}
						]
					};
				})
			);
			setShowIndividualPricing(
				productData?.show_individual_product_pricing
			);
			if (enable_bundles_enchancements) {
				setShowChildItems(
					!productData?.hide_child_items_in_quote_block
				);
				setShowQuantity(productData?.show_child_quantity ?? false);
				setShowPrice(productData?.show_individual_product_pricing);
			}
		}

		form.setFieldsValue({
			name: productData?.name,
			description: productData?.description,
			type: productData?.product_type,
			images: productData?.item_images,
			unit_of_measure: productData?.unit_of_measure,
			selected_pricing_id: productData?.selected_pricing_id,
			quantity: productData?.quantity,
			billing_period: productData?.selected_billing_period,
			selected_setup_fee: productData?.selected_setup_price,
			vendor_sku_number:
				productData?.vendor_sku_number ??
				selectedPricing?.vendor_sku_no,
			selected_cost_price: costPrice,
			selected_setup_price: setupPrice,
			selected_billing_period:
				productData?.selected_billing_period ??
				selectedPricing?.billing_period ??
				ProductBillingType.OneTime,
			sell_price: sellPrice,
			margin,
			markup,
			hide_child_items_in_quote_block:
				productData?.hide_child_items_in_quote_block,
			show_child_quantity: productData?.show_child_quantity,
			show_individual_product_pricing:
				productData?.show_individual_product_pricing,
			...customFieldFormValues
		});
	}, [currentActiveSKU, visible, productData]);

	const customEmptyBundleProductsTable = () => {
		return (
			<div className="flex flex-col items-center justify-center">
				<EmptyBundleProductsIcon className="mb-3" />
				<ZTypography.ZText level={1} className="mb-2">
					You have no products to display in this bundle.
				</ZTypography.ZText>
				<ZTypography.ZText level={1}>
					Click on 'Add Product' button to add a product to this
					bundle
				</ZTypography.ZText>
			</div>
		);
	};

	const updateTotalCostPrice = () => {
		const costPrice = bundledSkus.reduce((acc, product) => {
			acc += product.is_optional
				? 0
				: product.pricings[0].cost_price *
				  (product.is_optional ? 1 : product.quantity);
			return acc;
		}, 0);
		form.setFieldsValue({ selected_cost_price: costPrice });
		onCostPriceChange(costPrice);
	};

	const updateTotalSellPrice = () => {
		const sellPrice = bundledSkus.reduce((acc, product) => {
			acc += product.is_optional
				? 0
				: product.pricings[0].sell_price *
				  (product.is_optional ? 1 : product.quantity);
			return acc;
		}, 0);
		form.setFieldsValue({
			sell_price: sellPrice
		});
		onSellPriceChange(sellPrice);
	};
	const updateTotalSetupPrice = () => {
		const setupFee = bundledSkus.reduce((acc, product) => {
			acc += product.is_optional ? 0 : product.pricings[0].setup_price;
			return acc;
		}, 0);
		form.setFieldsValue({ selected_setup_price: setupFee });
	};

	const InputWidget = ({ value, onChange, disabled, ...rest }) => {
		return (
			<div key={`${rest.id}_calc_total`} className="ml-2">
				<div
					className="flex items-center"
					key={`${rest.id}_calc_total_input_container`}
				>
					<ZInputNumber
						key={`${rest.id}_calc_total_input`}
						type="number"
						step=".01"
						min={0}
						precision={2}
						className="mr-2"
						width={"10rem"}
						value={value}
						onChange={onChange}
						addOnAfter={getCurrencySettings().currency}
						disabled={disabled}
					/>
					<ZButton
						className={`sub-action-button m-0 ${rest.id}_total_button`}
						type="link"
						onClick={() => {
							if (rest.id === "selected_cost_price")
								updateTotalCostPrice();
							if (rest.id === "sell_price")
								updateTotalSellPrice();
							if (rest.id === "selected_setup_price")
								updateTotalSetupPrice();
						}}
					>
						<ZText className="text-blue-5 active:text-blue-6">
							<ZIcon
								name={Icons.COPY}
								size="sm"
								className="mx-1 stroke-current"
							/>
							Copy Product Total
						</ZText>
					</ZButton>
				</div>
			</div>
		);
	};

	const marginFields = bundleProducts => {
		if (!bundleProducts) return [];
		return [
			{
				key: "should_add_setup_price_to_margin",
				wrapperCol: { span: 24 },
				className: "bg-gray-3 w-full p-2 mb-0",
				widget: ({ value, onChange }) => (
					<ZCheckbox
						checked={value}
						disabled={pricingDisabled}
						onChange={e => {
							onChange(e.target.checked);
							onAddSetupPriceToMarginChange(e);
						}}
					>
						Add 'Setup Fee' to Margin
					</ZCheckbox>
				)
			},
			{
				key: "margin_and_amount",
				wrapperCol: { span: 24 },
				widget: () => {
					const values = getFieldValues();
					const marginAmount =
						(values.sell_price || 0) -
						(values.selected_cost_price || 0) +
						(!!values.should_add_setup_price_to_margin
							? values.selected_setup_price || 0
							: 0);
					return (
						<div className="rounded bg-gray-3 p-2 ">
							<ZTypography.ZText weight={FontWeights.MEDIUM}>
								Item Margin:
							</ZTypography.ZText>{" "}
							<ZTypography.ZText>
								{percentageFormatter(values.margin, 100)} (
								{currencyFormatter(marginAmount)})
							</ZTypography.ZText>
						</div>
					);
				}
			}
		];
	};

	const pricingCommonFields = (bundleProducts: boolean) => [
		bundleProducts
			? {
					key: "selected_cost_price",
					label: "Cost Price",
					className: "bg-gray-3 w-full p-2 mb-0",
					widget: props =>
						InputWidget({ ...props, disabled: pricingDisabled }),
					widgetProps: {
						precision: 2,
						step: 0.01,
						min: 0,
						addOnAfter: currency,
						onChange: onCostPriceChange
					},
					rules: [
						{
							required: true,
							validator: (_: any, value: any) => {
								if (Number(value) < 0) {
									return Promise.reject(
										new Error(
											"Invalid Entry. Cost price cannot be less than 0"
										)
									);
								}
								if (!value && value !== 0) {
									return Promise.reject(
										new Error("Cost price cannot be empty")
									);
								}
								return Promise.resolve();
							}
						}
					]
			  }
			: {
					key: "selected_cost_price",
					label: "Cost Price",
					widget: EWidgets.INPUTNUMBER,
					className: `${
						bundleProducts && `bg-gray-3 w-full p-2 mb-0`
					} `,
					rules: [
						{
							required: true,
							validator: (
								_: Rule,
								value: number | null | undefined
							) => {
								if (Number(value) < 0) {
									return Promise.reject(
										new Error(
											"Invalid Entry. Cost price cannot be less than 0"
										)
									);
								}
								if (!value && value !== 0) {
									return Promise.reject(
										new Error("Cost price cannot be empty")
									);
								}
								return Promise.resolve();
							}
						}
					],
					widgetProps: {
						precision: 2,
						step: 0.01,
						min: 0,
						addOnAfter: currency,
						width: EInputNumberWidths.full,
						onChange: onCostPriceChange
					}
			  },
		{
			key: "markup",
			label: "Markup",
			className: `${bundleProducts && `bg-gray-3 w-full p-2 mb-0`} `,
			widget: EWidgets.INPUTNUMBER,
			initialValue: 0.0,
			disabled: pricingDisabled,
			widgetProps: {
				precision: 2,
				step: 0.01,
				min: 0,
				addOnAfter: "%",
				width: EInputNumberWidths.full,
				onChange: onMarkupChange
			}
		},
		{
			key: "margin",
			label: "Margin",
			className: `${bundleProducts && `bg-gray-3 w-full p-2 mb-0`} `,
			widget: EWidgets.INPUTNUMBER,
			initialValue: 0.0,
			disabled: pricingDisabled,
			widgetProps: {
				precision: 2,
				step: 0.01,
				max: 100,
				addOnAfter: "%",
				width: EInputNumberWidths.full,
				onChange: onMarginChange
			},
			rules: [
				{
					validator: (_: Rule, value: number | null | undefined) => {
						if (
							Number(value) >= 100 &&
							form.getFieldValue("selected_cost_price") > 0
						) {
							return Promise.reject(
								new Error(
									"Invalid Entry. Margin cannot be 100% unless cost price is 0"
								)
							);
						}
						return Promise.resolve();
					}
				}
			]
		},
		{
			key: "billing_period",
			label: "Billing Period",
			className: `${bundleProducts && `bg-gray-3 w-full p-2 mb-0`} `,
			required: true,
			widget: EWidgets.SELECT,
			disabled: pricingDisabled,
			widgetProps: {
				placeholder: "Please select billing period",
				selectOptions: (
					Object.keys(
						productBillingTypeOptions
					) as ProductBillingType[]
				).map((value, index) => ({
					name: productBillingTypeOptions[value],
					value,
					key: index
				}))
			}
		},
		{
			key: "quantity",
			label: "Quantity",
			className: `${bundleProducts && `bg-gray-3 w-full p-2 mb-0`} `,
			widget: EWidgets.INPUTNUMBER,
			required: true,
			message: "Please enter product quantity",
			disabled: pricingDisabled,
			widgetProps: {
				placeholder: "Please enter product quantity",
				min: Number.MIN_SAFE_INTEGER,
				className: `${bundleProducts ? "w-11/12 ml-2" : "w-11/12"}`,
				precision: 0,
				onChange: onQuantityChange
			}
		},
		bundleProducts
			? {
					key: "sell_price",
					label: "Selling Price",
					className: "bg-gray-3 w-full p-2 mb-0",
					widget: props =>
						InputWidget({ ...props, disabled: pricingDisabled }),
					widgetProps: {
						precision: 2,
						step: 0.01,
						min: 0,
						addOnAfter: currency,
						onChange: onSellPriceChange
					},
					rules: [
						{
							required: true,
							validator: (_: any, value: any) => {
								if (Number(value) < 0) {
									return Promise.reject(
										new Error(
											"Invalid Entry. Selling price cannot be less than 0"
										)
									);
								}
								if (!value && value !== 0) {
									return Promise.reject(
										new Error(
											"Selling price cannot be empty"
										)
									);
								}
								return Promise.resolve();
							}
						}
					]
			  }
			: {
					key: "sell_price",
					label: "Selling Price",
					className: `${
						bundleProducts && `bg-gray-3 w-full p-2 mb-0`
					} `,
					required: true,
					widget: EWidgets.INPUTNUMBER,
					initialValue: 0.0,
					widgetProps: {
						precision: 2,
						step: 0.01,
						min: 0,
						addOnAfter: currency,
						width: EInputNumberWidths.full,
						onChange: onSellPriceChange
					}
			  },

		bundleProducts
			? {
					key: "selected_setup_price",
					label: "Setup Fee",
					className: "bg-gray-3 w-full p-2 mb-0",
					widget: props =>
						InputWidget({ ...props, disabled: pricingDisabled }),
					widgetProps: {
						precision: 2,
						step: 0.01,
						min: 0,
						addOnAfter: currency,
						onChange: onSetupPriceChange
					}
			  }
			: {
					key: "selected_setup_price",
					label: "Setup Fee",
					required: true,
					widget: EWidgets.INPUTNUMBER,
					initialValue: 0.0,
					widgetProps: {
						precision: 2,
						step: 0.01,
						min: 0,
						addOnAfter: currency,
						width: EInputNumberWidths.full,
						onChange: onSetupPriceChange
					}
			  },
		...marginFields(bundleProducts)
	];

	const pricingFields = () => {
		const bundleProducts = currentActiveSKU?.type === ProductType.Bundle;

		if (bundleProducts) {
			let commonFields = !showIndividualPricing
				? pricingCommonFields(true)
				: [];
			if (enable_bundles_enchancements) {
				commonFields = pricingCommonFields(true);
			}

			return [
				enable_bundles_enchancements
					? {
							key: "bundle_settings",
							render: () => {
								return (
									<div className="mb-6 mt-6">
										<ZTypography.ZText className="mr-4">
											Show child items
										</ZTypography.ZText>
										<Switch
											onChange={handleChangeChildItems}
											size="small"
											checked={showChildItems}
										/>
										{showChildItems && (
											<>
												<div className="flex items-center mt-1">
													<ZIcon
														name={EIcons.INFOCIRCLE}
														size="sm"
														className="m-0"
													/>
													<ZTypography.ZText
														colorVariant={8}
														level={0}
														className="ml-2"
													>
														Please note that you
														will not be able to edit
														the product bundle
														pricing fields if the{" "}
														<ZTypography.ZText
															colorVariant={9}
															level={0}
														>
															Show Price
														</ZTypography.ZText>{" "}
														is toggled on.
													</ZTypography.ZText>
												</div>
												<div className="ml-9 mt-4">
													<ZTypography.ZText className="mr-4">
														Show quantity
													</ZTypography.ZText>
													<Switch
														onChange={
															handleChangeQuantity
														}
														size="small"
														checked={showQuantity}
													/>
													<div className="mb-2"></div>
													<ZTypography.ZText className="mr-9">
														Show price
													</ZTypography.ZText>
													<Switch
														onChange={
															handleChangePrice
														}
														size="small"
														checked={showPrice}
													/>
												</div>
											</>
										)}
									</div>
								);
							}
					  }
					: {
							key: "show_individual_product_pricing",
							className: "w-full mb-0",
							wrapperCol: { span: 24 },
							widget: ({ value, onChange }) => {
								return (
									<div
										className={`bg-gray-3 w-full p-2 ${
											value && "mb-6"
										}`}
									>
										<ZCheckbox
											checkboxOption="Show individual product pricing"
											checked={value}
											onChange={(
												e: CheckboxChangeEvent
											) => {
												onChange(e.target.checked);
												setShowIndividualPricing(
													e.target.checked
												);
											}}
										/>
									</div>
								);
							}
					  },
				...commonFields
			];
		}

		if (!bundleProducts) return [...pricingCommonFields(false)];
		return [];
	};

	const bundledSkuDataSource = !!bundledSkus.length
		? bundledSkus.map((sku, index) => {
				return {
					...sku,
					index
				};
		  })
		: [];

	const handleDragEnd = ({ ...props }) => {
		const { oldIndex, newIndex } = props;
		const newBundledSKUs = [...bundledSkus];
		const destinationIndex = newIndex ? newIndex : 0;
		const sourceIndex = oldIndex ? oldIndex : 0;
		const [removed] = newBundledSKUs.splice(sourceIndex, 1);
		newBundledSKUs.splice(destinationIndex, 0, removed);

		setBundledSkus(newBundledSKUs);
	};

	const sections: IFormSectionProps[] = [
		{
			hideDivider: true,
			meta: {
				formItemLayout: [6, 18],
				fields: [
					{
						key: "name",
						label: "Name",
						required: true,
						widget: EWidgets.INPUT,
						widgetProps: {
							placeholder: "Enter product name",
							className: "w-full"
						}
					},
					{
						key: "type",
						label: "Type",
						required: true,
						widget: EWidgets.SELECT,
						widgetProps: {
							placeholder: "Select type",
							selectOptions: typeOptions,
							disabled:
								form.getFieldValue("type") ===
								ProductType.Bundle
						}
					},
					{
						key: "hide_child_items_in_quote_block",
						wrapperCol: 24,
						className: "m-0",
						widget: ({ onChange, value }) => {
							return !enable_bundles_enchancements ? (
								<Col span={24} className="mb-3">
									{form.getFieldValue("type") ===
										ProductType.Bundle &&
										!form.getFieldValue(
											"show_individual_product_pricing"
										) && (
											<Row justify="end">
												<ZButton
													type="link"
													onClick={() =>
														onChange(!value)
													}
												>
													{value ? `Show` : `Hide`}{" "}
													All Products
												</ZButton>
											</Row>
										)}
								</Col>
							) : null;
						}
					},
					{
						key: "bundled_skus",
						label: "bundleType",
						render: () => {
							return (
								<>
									{form.getFieldValue("type") ===
										ProductType.Bundle && (
										<ConfigProvider
											renderEmpty={
												customEmptyBundleProductsTable
											}
										>
											<ZTable
												loading={false}
												scroll={{
													x: true,
													scrollToFirstRowOnChange:
														true
												}}
												size="small"
												columns={getBundledProductTableColumns()}
												dataSource={
													bundledSkuDataSource
												}
												pagination={false}
												className="mb-8 border-solid border-0 border-b-2 border-gray-5"
												footer={() => {
													return (
														<div className="flex justify-center">
															<ZButton
																className="h-6"
																type="primary"
																ghost
																onClick={() =>
																	setShowProductSearch(
																		true
																	)
																}
															>
																Add Product
															</ZButton>{" "}
															<ZButton
																className="h-6 ml-4"
																type="primary"
																ghost
																onClick={() =>
																	setAddSKUProduct(
																		true
																	)
																}
															>
																Create Product
															</ZButton>
														</div>
													);
												}}
												isDraggable={
													enable_bundles_enchancements &&
													bundledSkus.length > 1
												}
												onDragEnd={handleDragEnd}
												draggableRowInDrawers={true}
											/>
										</ConfigProvider>
									)}
								</>
							);
						}
					},
					{
						key: "show_child_quantity",
						wrapperCol: 24,
						className: "m-0 mb-3",
						widget: ({ onChange, value }) => {
							return !enable_bundles_enchancements ? (
								<Col span={24} className="mb-3">
									<Row justify="end">
										<ZCheckbox
											onChange={() => onChange(!value)}
											defaultChecked={value}
										>
											Display individual quantity on quote
											block
										</ZCheckbox>
									</Row>
								</Col>
							) : null;
						}
					},
					{
						key: "unit_of_measure",
						label: "UOM",
						required: true,
						widget: EWidgets.SELECT,
						widgetProps: {
							placeholder: "Select unit of measure",
							selectOptions: uomOptions
						}
					},
					{
						key: "images",
						required: true,
						label: "Product Images",
						widget: ZUploadMediaWithPreview,
						widgetProps: {
							fileType: "image",
							uploadButtonText: "Upload",
							uploadButtonIcon: EIcons.UPLOAD
						}
					},
					{
						key: "description",
						label: "Description",
						widget: EWidgets.INPUTTEXTAREA,
						className: "mb-4",
						required: true,
						widgetProps: {
							placeholder: "Enter product description",
							rows: 4
						}
					},
					{
						key: "selected_pricing_id",
						label: "Pricing",
						widget: EWidgets.RADIOGROUP,
						widgetProps: {
							options: pricingOptions,
							vertical: true,
							space: "middle",
							onChange: (e: RadioChangeEvent) =>
								handlePricingChange(e.target.value)
						}
					},
					{
						key: "vendor_sku_number",
						label: "Vendor SKU",
						widget: EWidgets.INPUT,
						widgetProps: {
							placeholder: "Please enter vendor SKU",
							className: "w-full"
						}
					},
					...pricingFields(),
					...customFieldList.map((customField: CustomField) => {
						return {
							key: `custom_field_form_values.${customField.id}`,
							label: (
								<ZTooltip
									title={customField.display_name}
									placement="top"
									showtooltip={
										customField.display_name.length >= 23
									}
								>
									<ZTypography.ZText level={1}>
										{customField.display_name}
									</ZTypography.ZText>
								</ZTooltip>
							),
							required:
								!!customField.mandatory_field_details?.for_ui,
							message: "Please enter " + customField.display_name,
							widget: getWidget(customField.field_type),
							widgetProps: getCustomFieldProps(customField),
							className: "large-custom-field"
						};
					})
				]
			}
		}
	];

	const formProps: IFormProps = {
		key: "ProductSKUForm",
		sections,
		isStickFooter: true,
		form,
		submitButtonText:
			drawerMode === "edit" ? "Update Product" : "Clone Product",
		isRightFooter: true,
		isDynamic: true,
		isCancelAllowed: true,
		onFinish,
		shouldDisableBeforeTouch: false,
		loading,
		onCancel: handleProductClose
	};

	return (
		<ZDrawer
			visible={visible}
			closable={true}
			destroyOnClose={true}
			onClose={handleProductClose}
			title={
				<ZText
					weight={EFontWeights.MEDIUM}
					level={2}
					className="drawer-title"
				>
					Configure Product
				</ZText>
			}
			removeDefaultPadding
		>
			<ZForm {...formProps} className="py-4" />
			<AddProductSKUToQuoteBlockDrawer
				visible={showProductSearch}
				onClose={() => setShowProductSearch(false)}
				onSubmitBundleProducts={values => {
					setBundledSkus(
						values.map(item => ({
							...item,
							quantity: 1,
							is_optional: false
						}))
					);
				}}
				addedProducts={bundledSkus}
				addNewProducts
			/>
			{(!!editSKUProduct || addSKUProduct) && (
				<ProductSKUDrawer
					currentSKU={addSKUProduct ? null : editSKUProduct}
					visible={!!editSKUProduct || !!addSKUProduct}
					onClose={val => {
						if (addSKUProduct) {
							setAddSKUProduct(false);
							if (val) setShowProductSearch(true);
						} else setEditSKUProduct(undefined);
					}}
					bundleClone={true}
					drawerState={
						addSKUProduct
							? EProductSKUDrawerState.ADD
							: EProductSKUDrawerState.EDITORCLONE
					}
					mode={
						!addSKUProduct
							? EditOrCloneProductDrawerMode.EDIT
							: null
					}
					handleEditBundleProduct={val => {
						setBundledSkus(
							bundledSkus.map(product => {
								if (product.id === val.id) {
									return val;
								}
								return product;
							})
						);
					}}
				/>
			)}
		</ZDrawer>
	);
};

export default ConfigureProductSKUInQuoteBlockDrawer;
