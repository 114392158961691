import { customCurrencyFormatter } from "@zomentum/utils/dist/Currency";
import {
	customDateFormatter,
	capitalizeAfterUnderscore,
	capitalizeFirstLetter,
	isGivenDocumentOrderForm,
	isVendorApp
} from "@zomentum/utils";
import {
	Address,
	DefaultTimestampFormat,
	SKUProduct,
	VendorCustomTemplateType,
	VendorPartnerTier
} from "@zomentum/contracts";
import {
	AssessmentLikelihood,
	AssessmentRiskLevel,
	AssessmentSeverity
} from "@zomentum/contracts/dist/Assessments";
import {
	Document,
	DocumentStatus,
	DocumentType,
	MergeTagVariable
} from "@zomentum/contracts/dist/Documents";
import {
	AddQuoteProductCatalogDrawerFormValues,
	BlockMapper,
	BlockType,
	QuoteBlockData,
	QuoteProduct,
	TextBlockData
} from "@zomentum/contracts/dist/ProposalBuilder/Block";
import { CustomTemplateType } from "@zomentum/contracts/dist/EmailTemplates";
import { Product, ProductType } from "@zomentum/contracts/dist/Products";
import {
	CustomField,
	CustomFieldType,
	DatePatterns
} from "@zomentum/contracts/dist/Settings";
import {
	AllRolePermissions,
	InitializationStep,
	PricingAddOnUpdate,
	PricingPlan,
	PricingPlanEstimateData,
	TaxCategory,
	UpdatePricingPlanConstants,
	UserAccess,
	UserData,
	UserRole
} from "@zomentum/contracts/dist/Users";
import ZTypography from "@zomentum/atoms/dist/ZTypography";
import { EFontWeights } from "@zomentum/atoms/dist/ZTypography/interface";
// import { EMergeTagStatus } from "@molecules/ZEditorMergetag/interface";
import { EWidgets } from "@zomentum/organisms/dist/ZForm/interface";
import DownloadFile from "downloadjs";
import { filter } from "lodash";
import { EIcons } from "@zomentum/atoms/dist/ZIcon/interface";
import { extension } from "mime-types";
import moment from "moment";
import React, { Fragment } from "react";
import ZModal from "@zomentum/atoms/dist/ZModal";
import { EColors } from "@zomentum/atoms/dist/ZColors/interface";
import colors from "@/stylesheets/colors";
import { VendorPartner } from "@zomentum/contracts/dist/Vendor/Partner";
import { ZIcon, ZTag } from "@zomentum/atoms";
import {
	VendorResource,
	VendorResourceSharingType
} from "@zomentum/contracts/dist/Vendor/Resources";
import { ReactComponent as EmptyTierPartners } from "@/Assets/Vendor/EmptyTierPartners.svg";
import { ReactComponent as EmptyTierResources } from "@/Assets/Vendor/EmptyTierResources.svg";
import { ReactComponent as PlaceHolderImage } from "@/Assets/Vendor/PlaceHolderImage.svg";

import Avatar from "antd/lib/avatar";
import { FileOutlined, FolderAddOutlined } from "@ant-design/icons";

const { ZText } = ZTypography;

export const MergeTagsDisplayName = (MergeTags: MergeTagVariable[]) => {
	return MergeTags.map(val => {
		return "{{" + val.display_name + "}}";
	});
};

export const nylasConnectedEmailSuffix = ":nylas";
export const formatNylasIntegratedEmailId = (
	emailId: string,
	shouldClean?: boolean
) => {
	if (shouldClean) {
		return emailId.replace(nylasConnectedEmailSuffix, "");
	} else {
		return emailId + nylasConnectedEmailSuffix;
	}
};

export const formatCustomFieldValue = (
	customField: CustomField,
	datePattern?: string
) => {
	if (customField.field_type === CustomFieldType.DateField) {
		return customField.values
			.map(value =>
				customDateFormatter(
					value,
					datePattern ?? DatePatterns.DD_HYPHEN_MMM_COMMA_YYYY
				)
			)
			.join(", ");
	}

	if (customField.field_type === CustomFieldType.DropdownFieldWithKeyValue) {
		const value = customField.allowed_dropdown_key_values?.find(
			field => field.key === customField.values[0]
		);

		return value?.value ?? "--";
	}

	return customField.values
		.map(value => capitalizeFirstLetter(value))
		.join(", ");
};

export const defaultProductTypeFormItem = {
	key: "default_for_types",
	label: "Default Product Type",
	widget: EWidgets.CHECKBOXGROUP,
	widgetProps: {
		options: [
			{
				label: capitalizeAfterUnderscore(ProductType.Hardware),
				value: ProductType.Hardware
			},
			{
				label: capitalizeAfterUnderscore(ProductType.Service),
				value: ProductType.Service
			},
			{
				label: capitalizeAfterUnderscore(ProductType.Software),
				value: ProductType.Software
			},
			{
				label: capitalizeAfterUnderscore(ProductType.Bundle),
				value: ProductType.Bundle
			},
			{
				label: capitalizeAfterUnderscore(ProductType.Labor),
				value: ProductType.Labor
			},
			{
				label: capitalizeAfterUnderscore(ProductType.Other),
				value: ProductType.Other
			}
		]
	}
};

export const normalizeZipcode = (value: string): string =>
	value ? value.substr(0, 20).toUpperCase() : "";

export const getUpdatePricingPlanMessages = (
	currentPlan: PricingPlan,
	subscriptionEstimate: PricingPlanEstimateData,
	planUpdate: string,
	planTier: string,
	isSeatAddition: boolean,
	isRemoveSeat: boolean,
	addOn?: string,
	currentPlanFromState?: PricingPlan
) => {
	const currentPlanTier = currentPlan.plan_tiers.find(
		planTier => planTier.active
	);
	const nextBillingDate = moment(
		subscriptionEstimate.next_billing_date
	).format(DefaultTimestampFormat);
	const nextBillingAmount = customCurrencyFormatter(
		subscriptionEstimate.next_billing_amount,
		currentPlan.currency
	);
	const proratedAmount = customCurrencyFormatter(
		subscriptionEstimate.prorated_amount,
		currentPlan.currency
	);
	const creditNotes = subscriptionEstimate.credit_notes;
	const creditNoteTotal = creditNotes?.reduce(
		(acc, note) => acc + note.total,
		0
	);

	const nextBillingDateText = (
		<ZText colorVariant={8} weight={EFontWeights.MEDIUM}>
			{nextBillingDate}
		</ZText>
	);
	const nextBillingAmountText = (
		<ZText colorVariant={8} weight={EFontWeights.MEDIUM}>
			{nextBillingAmount}
		</ZText>
	);
	const proratedAmountText = (
		<ZText colorVariant={8} weight={EFontWeights.MEDIUM}>
			{proratedAmount}
		</ZText>
	);
	const creditNoteAmountAvailableText = (
		<ZText colorVariant={8} weight={EFontWeights.MEDIUM}>
			{customCurrencyFormatter(creditNoteTotal, currentPlan.currency)}
		</ZText>
	);

	if (
		(planUpdate === UpdatePricingPlanConstants.UPGRADE || isSeatAddition) &&
		(planTier === currentPlanTier?.period ||
			currentPlan.hierarchy !== currentPlanFromState?.hierarchy)
	) {
		return (
			<>
				Approximate prorated invoice of {proratedAmountText} will be
				generated immediately. An invoice of {nextBillingAmountText}{" "}
				will be generated for the next billing cycle on{" "}
				{nextBillingDateText}
			</>
		);
	} else if (
		((planUpdate === UpdatePricingPlanConstants.DOWNGRADE ||
			isRemoveSeat) &&
			planTier === currentPlanTier?.period) ||
		currentPlanFromState?.hierarchy !== currentPlan.hierarchy
	) {
		return (
			<>
				Approximate Prorated credits of {creditNoteAmountAvailableText}{" "}
				will be added to your account. These credits will be adjusted in
				your next billing cycle. An invoice of {nextBillingAmountText}{" "}
				will be generated for the next billing cycle on{" "}
				{nextBillingDateText}
			</>
		);
	} else if (
		planTier !== currentPlanTier?.period &&
		planUpdate === UpdatePricingPlanConstants.UPGRADE
	) {
		return (
			<>
				Approximate prorated invoice of {proratedAmountText} will be
				generated immediately. An invoice of {nextBillingAmountText}{" "}
				will be generated for the next billing cycle on{" "}
				{nextBillingDateText}
			</>
		);
	} else if (
		planTier !== currentPlanTier?.period &&
		currentPlanFromState?.hierarchy === currentPlan.hierarchy &&
		planUpdate === UpdatePricingPlanConstants.DOWNGRADE
	) {
		return <>Contact support for downgrading</>;
	} else if (addOn === PricingAddOnUpdate.ADD) {
		return (
			<>
				You will be charged {proratedAmountText} immediately. Your
				future {currentPlanTier?.period} recurring cost will be{" "}
				{nextBillingAmountText}
			</>
		);
	} else if (addOn === PricingAddOnUpdate.REMOVE) {
		return (
			<>
				The add-on will be removed at the end of current billing cycle.
				Your {planTier} cost will be {nextBillingAmountText}
			</>
		);
	} else return "";
};

// Pricing is sorted in ascending order.
// Zero pricing is not picked as the lowest.
// Lowest price which has stock is picked over the one without stock.
export const getPreferredPricingForProduct = (product: Product) =>
	[...product.pricing]?.sort((a, b) => {
		const aHasStock =
			a.distributor_pricing_details?.total_availability || -1 > 0;

		return !aHasStock || a.cost_price === 0
			? 1
			: a.cost_price - b.cost_price;
	})[0]?.id;

export const constructProductIdToPricingMapping = (
	products: Product[]
): Record<string, string> => {
	return products.reduce(
		(total, product) => ({
			...total,
			[product.id]: getPreferredPricingForProduct(product)
		}),
		{}
	);
};

export const getPreferredPricingForProductSKU = (product: SKUProduct) =>
	[...product.pricings]?.sort((a, b) => a.cost_price - b.cost_price)[0]?.id;

export const constructProductSKUIdToPricingMapping = (
	products: SKUProduct[]
): Record<string, string> => {
	return products.reduce(
		(total, product) => ({
			...total,
			[product.id]: getPreferredPricingForProductSKU(product)
		}),
		{}
	);
};

export const areRequiredFieldsFilledForAddProductToQuoteBlockForm = (
	quoteProductFormValues:
		| AddQuoteProductCatalogDrawerFormValues
		| QuoteProduct,
	taxCategoriesList: TaxCategory[]
) => {
	const isTaxCategoryDisabled =
		taxCategoriesList.find(
			taxCategory =>
				taxCategory.id === quoteProductFormValues.tax_category_id
		)?.disabled ?? true;

	const areNonCustomFieldsUnfilled =
		!quoteProductFormValues.name ||
		!quoteProductFormValues.product_type ||
		!quoteProductFormValues.tax_category_id ||
		!quoteProductFormValues.quantity ||
		isTaxCategoryDisabled;
	if (areNonCustomFieldsUnfilled) {
		return false;
	}
	for (const customField of quoteProductFormValues.custom_fields ?? []) {
		if (
			customField.mandatory_field_details?.for_ui &&
			!customField.values
		) {
			return false;
		}
	}
	return true;
};

export const getSeverityLikelihoodFromRisk = (risk: AssessmentRiskLevel) => {
	switch (risk) {
		case AssessmentRiskLevel.LOW:
			return {
				severity: AssessmentSeverity.ACCEPTABLE,
				likelihood: AssessmentLikelihood.UNLIKELY
			};
		case AssessmentRiskLevel.MEDIUM:
			return {
				severity: AssessmentSeverity.TOLERABLE,
				likelihood: AssessmentLikelihood.POSSIBLE
			};
		case AssessmentRiskLevel.HIGH:
			return {
				severity: AssessmentSeverity.INTOLERABLE,
				likelihood: AssessmentLikelihood.HIGHLY_LIKELY
			};
		default:
			return {
				severity: AssessmentSeverity.ACCEPTABLE,
				likelihood: AssessmentLikelihood.UNLIKELY
			};
	}
};

export const getRiskFromSeverityLikelihood = ({
	severity,
	likelihood
}: {
	severity: AssessmentSeverity;
	likelihood: AssessmentLikelihood;
}) => {
	switch (severity) {
		case AssessmentSeverity.INTOLERABLE:
			return likelihood === AssessmentLikelihood.UNLIKELY
				? AssessmentRiskLevel.MEDIUM
				: AssessmentRiskLevel.HIGH;
		case AssessmentSeverity.TOLERABLE:
			return likelihood === AssessmentLikelihood.UNLIKELY
				? AssessmentRiskLevel.LOW
				: AssessmentRiskLevel.MEDIUM;
		case AssessmentSeverity.ACCEPTABLE:
			return likelihood === AssessmentLikelihood.HIGHLY_LIKELY
				? AssessmentRiskLevel.MEDIUM
				: AssessmentRiskLevel.LOW;
	}
};

export const getDefaultEmails = (companyUserList: UserData[]) => {
	const defaultUsers: string[] = [];
	companyUserList.forEach(user => {
		const defaultUserEmail =
			user?.user_settings?.preference_settings
				?.document_preference_settings?.default_from_address;
		if (
			defaultUserEmail &&
			Array.isArray(defaultUsers) &&
			!defaultUsers?.includes(defaultUserEmail)
		)
			defaultUsers.push(defaultUserEmail);
	});
	return defaultUsers;
};

export const downloadReceivedFile = (
	fileImportResponse: Blob,
	fileName: string
) => {
	const ext = extension(fileImportResponse.type);
	DownloadFile(fileImportResponse, [fileName, ext].join("."));
};
export const autoPopulateEmailSubjectBasedOnTemplateType = (
	overridingTemplateType: CustomTemplateType | null
) => {
	switch (overridingTemplateType) {
		case CustomTemplateType.SendDocument: {
			return `<p><span class="merge-tag mceNonEditable" contenteditable="false"> {{Document.Owner.Name}}</span> has sent <span class="merge-tag mceNonEditable" contenteditable="false">{{Document.Name}}</span> document</p>`;
		}
		case CustomTemplateType.DocumentReminder: {
			return `<p>Reminder to approve <span class="merge-tag mceNonEditable" contenteditable="false">{{Document.Name}}</span>&nbsp;</p>`;
		}
		case CustomTemplateType.DocumentExpired: {
			return `<p>Expiry Reminder: <span class="merge-tag mceNonEditable" contenteditable="false">{{Document.Name}}</span> document</p>`;
		}
		case CustomTemplateType.DocumentWon: {
			return `<p>All participants have approved the document <span class="merge-tag mceNonEditable" contenteditable="false">{{Document.Name}}</span></p>`;
		}
		case CustomTemplateType.SignDocument: {
			return `<p><span class="merge-tag mceNonEditable" contenteditable="false">{{Document.Name}}</span> document is ready for your approval</p>`;
		}
		default:
			return "";
	}
};

export const autoPopulateEmailBodyBasedOnTemplateType = (
	overridingTemplateType: CustomTemplateType | null
) => {
	switch (overridingTemplateType) {
		case CustomTemplateType.SendDocument: {
			return `<p><span class="merge-tag mceNonEditable">{{Sender.FirstName}}</span> has sent you <span class="merge-tag mceNonEditable">{{Document.Name}}</span> .</p>
				<p>The Document will Expire on: <span class="merge-tag mceNonEditable">{{Document.ExpiresAt}}</span>&nbsp;</p>
				<p>View Document here: <span class="merge-tag mceNonEditable">{{Receiver.Document.Url}}</span>&nbsp;</p>`;
		}
		case CustomTemplateType.DocumentReminder: {
			return `<p>The document <span class="merge-tag mceNonEditable">{{Document.Name}}</span> is waiting for your approval.</p>
				<p>To review and approve the document click on the below link.</p>
				<p><span class="merge-tag mceNonEditable">{{Receiver.Document.Url}}</span>&nbsp;</p>`;
		}
		case CustomTemplateType.DocumentExpired: {
			return `<p>The document <span class="merge-tag mceNonEditable">{{Document.Name}}</span> is available for approval until <span class="merge-tag mceNonEditable">{{Document.ExpiresAt}}</span> and will expire thereafter.</p>
				<p>Please review and approve the document or contact the sender for more information.</p>
				<p>View Document: <span class="merge-tag mceNonEditable">{{Receiver.Document.Url}}</span>&nbsp;</p>`;
		}
		case CustomTemplateType.DocumentWon: {
			return `<p>All Participants have signed and approved the <span class="merge-tag mceNonEditable">{{Document.Name}}</span> document.</p>
				<p>The signed copy of the document is attached in this email for your reference.</p>`;
		}
		case CustomTemplateType.SignDocument: {
			return `<p><span class="merge-tag mceNonEditable">{{Document.Name}}</span> document is ready for your approval.</p>
				<p>You can approve the Document here: <span class="merge-tag mceNonEditable">{{Receiver.Document.Url}}</span></p>`;
		}
		default:
			return "";
	}
};

export const autoPopulateEmailEndSalutationBasedOnTemplateType = (
	overridingTemplateType: CustomTemplateType | null
) => {
	switch (overridingTemplateType) {
		case CustomTemplateType.SendDocument: {
			return `<p>Thanks,</p>
			<p><span class="merge-tag mceNonEditable">{{Sender.FirstName}}</span>&nbsp;</p>`;
		}
		case CustomTemplateType.DocumentReminder: {
			return `<p>Thanks,</p>
			<p><span class="merge-tag mceNonEditable">{{Sender.FirstName}}</span>&nbsp;</p>`;
		}
		case CustomTemplateType.DocumentExpired: {
			return `<p>Thanks,</p>
			<p><span class="merge-tag mceNonEditable">{{Document.Owner.Name}}</span>&nbsp;</p>`;
		}
		case CustomTemplateType.DocumentWon: {
			return `<p>Thank you,</p>
			<p><span class="merge-tag mceNonEditable">{{MyCompany.Name}}</span></p>`;
		}
		case CustomTemplateType.SignDocument: {
			return `<p>Thanks,</p>
			<p><span class="merge-tag mceNonEditable">{{MyCompany.Name}}</span></p>`;
		}
		default:
			return "";
	}
};

export const autoPopulateEmailHeadingBasedOnTemplateType = (
	overridingTemplateType: CustomTemplateType | null
) => {
	switch (overridingTemplateType) {
		case CustomTemplateType.SendDocument: {
			return `<p>Hey <span class="merge-tag mceNonEditable">{{Receiver.FirstName}}</span> ,</p>`;
		}
		case CustomTemplateType.DocumentReminder: {
			return `<p>Hey <span class="merge-tag mceNonEditable">{{Receiver.FirstName}}</span> ,</p>`;
		}
		case CustomTemplateType.DocumentExpired: {
			return `<p>Hey <span class="merge-tag mceNonEditable">{{Receiver.FirstName}}</span>&nbsp;</p>`;
		}
		case CustomTemplateType.DocumentWon: {
			return `<p>Congratulations!</p>`;
		}
		case CustomTemplateType.SignDocument: {
			return `<p>Hi <span class="merge-tag mceNonEditable">{{Receiver.FirstName}}</span></p>`;
		}
		default:
			return "";
	}
};

export const getAdminRoleState = (): UserData => {
	const defaultAdminData = new UserData();
	defaultAdminData.name = {
		first: "Abhishek",
		last: "Kumar",
		full: "Abhishek Kumar"
	};
	defaultAdminData.email = "abhishek@zomentum.com";
	defaultAdminData.company_id = "1ec9a1b3e87d4b9a841d16ed3d11349f";
	defaultAdminData.id = "3ea4ce4aa5f948d8b2822adea321a3bc";
	defaultAdminData.role = UserRole.PrimaryRole;
	defaultAdminData.access_level = UserAccess.CompanyLevel;
	defaultAdminData.roles_permissions = {
		role: "admin",
		permissions: Object.values(AllRolePermissions)
	};
	return defaultAdminData;
};

export const isGivenDocumentRequestedQuote = (
	currentActiveDocument?: Document | null
) => {
	const { document_type, status } = currentActiveDocument || {};
	return (
		document_type === DocumentType.QUOTE &&
		status === DocumentStatus.Requested
	);
};

export const checkMandatoryDocumentSystemFields = (
	currentActiveDocument: Document
) => {
	const isOrderForm = isGivenDocumentOrderForm(currentActiveDocument);
	return (
		!currentActiveDocument?.name?.length ||
		!(currentActiveDocument?.recipients?.length || isOrderForm) ||
		!(currentActiveDocument?.client_company_id?.length || isOrderForm) ||
		(!currentActiveDocument?.tax_region_id?.length &&
			!!currentActiveDocument.revisions?.filter(revision =>
				revision.blocks?.data?.some(data => {
					const quoteBlockData = data.data as QuoteBlockData;
					return quoteBlockData?.sections?.some(
						section => !!section?.products?.length
					);
				})
			)?.length) ||
		!currentActiveDocument?.user_id?.length
	);
};

export const getPropertyByPath = (object: any, path: string, fallback = "") => {
	const fragments = path.split(".");
	for (let i = 0; i < fragments.length; i++) {
		if (!Object.prototype.hasOwnProperty.call(object, fragments[i])) {
			return fallback;
		}
		object = object[fragments[i]];
	}
	return object;
};

export const getSigneesAndInputFieldsWithInactiveUser = (
	blockMapper: BlockMapper,
	currentActiveDocument: Document | null,
	inactiveUserIds: string[]
) => {
	const signBlocksWithInactiveSignees = filter(blockMapper, block => {
		return (
			block.type === BlockType.SIGNATURE &&
			!!currentActiveDocument?.recipients.find(recipient => {
				return (
					Array.isArray(recipient.blocks_to_sign) &&
					recipient.blocks_to_sign?.includes(block.id) &&
					Array.isArray(inactiveUserIds) &&
					inactiveUserIds?.includes(recipient.id)
				);
			})
		);
	});
	const textBlocksWithInactiveSignees = Object.values(blockMapper).filter(
		block => {
			const blockData = block?.data as TextBlockData;
			const html = document
				.createRange()
				.createContextualFragment(blockData?.text);
			const inputFieldElements = html.querySelectorAll(
				'[class="input-field mceNonEditable"]'
			);
			return (
				block.type === BlockType.TEXT &&
				Array.from(inputFieldElements).some(inputFieldElement => {
					const htmlElement = inputFieldElement as HTMLElement;
					const recipientId = htmlElement.dataset.fieldAssignee;
					return (
						Array.isArray(inactiveUserIds) &&
						inactiveUserIds?.includes(recipientId ?? "")
					);
				})
			);
		}
	);

	return {
		signBlocksWithInactiveSignees,
		textBlocksWithInactiveSignees
	};
};

export const handleDeleteShippingAddress = (
	deleteSelectedShippingAddress: Address,
	index: number,
	setShippingAddresses: (value: React.SetStateAction<Address[]>) => void
): void => {
	ZModal.confirm({
		title: "Are you sure you want to delete this Shipping Address?",
		onOk: () => {
			setShippingAddresses(prevState =>
				deleteSelectedShippingAddress.is_default
					? [
							...prevState
								.filter((_, idx) => idx !== index)
								?.map((address, idx) =>
									idx === 0
										? {
												...address,
												is_default: true
										  }
										: address
								)
					  ]
					: [...prevState.filter((_, idx) => idx !== index)]
			);
		},
		okType: "danger",
		okText: "Delete"
	});
};

export const shippingAddressEditHandler = (
	editSelectedShippingAddress: Address,
	shippingAddresses: Address[],
	index: number
): Address[] => {
	let updatedShippingAddresses = [...shippingAddresses];
	if (editSelectedShippingAddress.is_default) {
		updatedShippingAddresses = shippingAddresses?.filter(
			(_, idx) => idx !== index
		);
		updatedShippingAddresses = updatedShippingAddresses?.map(address => ({
			...address,
			is_default: false
		}));
		updatedShippingAddresses.unshift(editSelectedShippingAddress);
	} else {
		updatedShippingAddresses = shippingAddresses?.map((address, idx) =>
			idx === index ? editSelectedShippingAddress : address
		);
	}
	return updatedShippingAddresses;
};

export const shippingAddressAddHandler = (
	addSelectedShippingAddress: Address,
	shippingAddresses: Address[]
): { updatedShippingAddresses: Address[]; selectedIndex: number } => {
	let updatedShippingAddresses;
	if (!shippingAddresses.length) {
		addSelectedShippingAddress.is_default = true;
	} else if (addSelectedShippingAddress?.is_default) {
		const newShippingAddresses = shippingAddresses.map(address => {
			if (address.is_default) {
				address.is_default = false;
			}
			return address;
		});
		newShippingAddresses.unshift(addSelectedShippingAddress);
		updatedShippingAddresses = [...newShippingAddresses];
		return { updatedShippingAddresses, selectedIndex: 0 };
	}
	updatedShippingAddresses = [
		...shippingAddresses.concat(addSelectedShippingAddress)
	];
	return {
		updatedShippingAddresses,
		selectedIndex: updatedShippingAddresses.length - 1
	};
};

export const setupPending = (value: {
	company_initialization_step?: InitializationStep;
	user_initialization_step?: InitializationStep;
}): boolean => {
	return (
		(!!value.company_initialization_step &&
			value.company_initialization_step !==
				InitializationStep.PaymentScreenPresented) ||
		(!!value.user_initialization_step &&
			value.user_initialization_step !==
				InitializationStep.PasswordResetDone)
	);
};

export const setupDone = (value: {
	company_initialization_step?: InitializationStep;
	user_initialization_step?: InitializationStep;
}): boolean => {
	return (
		(value.company_initialization_step ===
			InitializationStep.PaymentScreenPresented &&
			value.user_initialization_step ===
				InitializationStep.PasswordResetDone) ||
		isVendorApp()
	);
};

export const timeAgo = (date: Date): string => {
	const seconds = Math.floor((+new Date() - +date) / 1000);

	let interval = seconds / 31536000;

	if (interval > 1) {
		return Math.floor(interval) + " years";
	}
	interval = seconds / 2592000;
	if (interval > 1) {
		return Math.floor(interval) + " months";
	}
	interval = seconds / 86400;
	if (interval > 1) {
		return Math.floor(interval) + " days";
	}
	interval = seconds / 3600;
	if (interval > 1) {
		return Math.floor(interval) + " hours";
	}
	interval = seconds / 60;
	if (interval > 1) {
		return Math.floor(interval) + " minutes";
	}
	return Math.floor(seconds) + " seconds";
};

export const getHexColorCode = (
	color: EColors,
	colorVariant: number
): string => {
	if (color === EColors.TRANSPARENT) return "#00000000";
	const colorIndex =
		colorVariant >= 1 && colorVariant <= 10 ? colorVariant - 1 : 10;
	return colors[color][colorIndex];
};

export const autoPopulateVendorEmailSubjectBasedOnTemplateType = (
	overridingTemplateType: VendorCustomTemplateType | null
) => {
	switch (overridingTemplateType) {
		case VendorCustomTemplateType.PublishTemplate: {
			return `<p><span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span>has published a new template</p>`;
		}
		case VendorCustomTemplateType.UnpublishTemplate: {
			return `<p><span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span>has upublished a template</p>`;
		}
		case VendorCustomTemplateType.OpportunityShared: {
			return `<p><span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span>has shared an opportunity with you</p>`;
		}
		case VendorCustomTemplateType.OpportunityReceived: {
			return `<p><span class="merge-tag mceNonEditable" contenteditable="false"> {{Partner.Name}} </span>has shared an opportunity with you</p>`;
		}
		case VendorCustomTemplateType.AcceptInviteFlow: {
			return `<p><span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span>has shared an opportunity with you</p>`;
		}
		case VendorCustomTemplateType.JoinVendorProgram: {
			return `<p><span class="merge-tag mceNonEditable" contenteditable="false"> {{Partner.Name}} </span>has shown interest in you</p>`;
		}
		case VendorCustomTemplateType.InviteExistingPartner: {
			return `<p>Welcome! Your <span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span>Partner Portal Awaits!</p>`;
		}
		case VendorCustomTemplateType.InviteNewPartner: {
			return `<p>Welcome! Your New <span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span>Partner Portal Awaits!</p>`;
		}
		case VendorCustomTemplateType.AssignPartnerTier: {
			return `<p>Welcome to the <span class="merge-tag mceNonEditable" contenteditable="false">{{PartnerTier.Name}} </span>, <span class="merge-tag mceNonEditable" contenteditable="false">{{MyCompany.Name}} </span></p>`;
		}
		case VendorCustomTemplateType.ChangePartnerTier: {
			return `<p>You Zomentum Tier has been changed, <span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span>!</p>`;
		}
		default:
			return "";
	}
};

export const autoPopulateVendorEmailBodyBasedOnTemplateType = (
	overridingTemplateType: VendorCustomTemplateType | null
) => {
	switch (overridingTemplateType) {
		case VendorCustomTemplateType.PublishTemplate: {
			return `<span style="font-size: 14px;line-height: 25px;">We just published a new template about <b><span class="merge-tag mceNonEditable">{{Template.Name}}</span></b>. You can access this template in your partner portal and use it according to your business needs.
            To check out this template click on <a style="text-decoration:none" href="{{Template.Url}}"><span style="color:#3300FF" class="merge-tag mceNonEditable">this link</span></a>. 
            If you have any questions or need help reach out to us at <a href="mailto:support@zomentum.com">support@zomentum.com</a>
            </span>`;
		}
		case VendorCustomTemplateType.UnpublishTemplate: {
			return `<span style="font-size: 14px;line-height: 25px;">We have an unpublished template - <b><span class="merge-tag mceNonEditable">{{Template.Name}}</span></b>. You will not be able to use this document template going forward, but existing documents where this template is used, will not get affected.
			If you have any questions or need help reach out to us at <a href="mailto:support@zomentum.com">support@zomentum.com</a> 
			</span>`;
		}
		case VendorCustomTemplateType.OpportunityShared: {
			return `<p>We have shared a new opportunity with you. Here are the details:</p>
			<p>Opportunity Id: <span class="merge-tag mceNonEditable" contenteditable="false"> {{Opportunity.Name}} </span></p>
			<p>Partner Name: <span class="merge-tag mceNonEditable" contenteditable="false"> {{Partner.Name}} </span></p>
			<p>Link: <span class="merge-tag mceNonEditable" contenteditable="false"> {{Opportunity.Url}} </span></p>
			<p>If you have any questions or need help reach out to us at <a href="mailto:support@zomentum.com">support@zomentum.com</a></p>`;
		}
		case VendorCustomTemplateType.OpportunityReceived: {
			return `<p>Partner has shared a new opportunity with you. Here are the details:</p>
			<p>Opportunity Id: <span class="merge-tag mceNonEditable" contenteditable="false"> {{Opportunity.Name}} </span></p>
			<p>Partner Name: <span class="merge-tag mceNonEditable" contenteditable="false"> {{Partner.Name}} </span></p>
			<p>Link: <span class="merge-tag mceNonEditable" contenteditable="false"> {{Opportunity.Url}} </span></p>
			<p>If you have any questions, reach out to <span class="merge-tag mceNonEditable" contenteditable="false">{{Partner.Email}}</span>. <span class="merge-tag mceNonEditable"  contenteditable="false">{{Partner.Url}}</span></p>`;
		}
		case VendorCustomTemplateType.AcceptInviteFlow: {
			return `<p>We just published a new template about <span class="merge-tag mceNonEditable" contenteditable="false"> {{Template.Name}} </span>. You can access this template in your partner portal and use it according to your business needs. </p>
			<p>To check out this template click on this link - <span class="merge-tag mceNonEditable" contenteditable="false"> {{Template.Url}} </span></p>
			<p>If you have any questions or need help reach out to us at <a href="mailto:support@zomentum.com">support@zomentum.com</a></p>`;
		}
		case VendorCustomTemplateType.JoinVendorProgram: {
			return `<span style="font-size: 14px;line-height: 25px;"><span class="merge-tag mceNonEditable" contenteditable="false"> {{Partner.Name}}</span> has shown interest in working with you. They clicked on ‘Join now’ on your Zomentum Partner Align listing page.</span>
			<br>
			<span style="font-size: 14px;line-height: 25px;">You can accept or decline this request by logging into your Partner Align account.</span>
		    <br>
			<span style="font-size: 14px;line-height: 25px;">If you need any help reach out to us at <a href="mailto:support@zomentum.com">support@zomentum.com</a></span>`;
		}
		case VendorCustomTemplateType.InviteExistingPartner: {
			return `<span style="font-size: 14px;line-height: 25px;">Welcome to Zomentum, the new Partner Portal of <span class="merge-tag mceNonEditable">{{MyCompany.Name}}.</span>
            Lucky you, <span class="merge-tag mceNonEditable">{{MyCompany.Name}} </span> is supplying you the most powerful partner portal in the Channel, for free.
            </span>
			<p><b>But this is the important part -</b> from now on, all <span class="merge-tag mceNonEditable"  contenteditable="false">{{MyCompany.Name}}</span> invoices are charged through Zomentum.</p>`;
		}
		case VendorCustomTemplateType.InviteNewPartner: {
			return `<p>Welcome to Zomentum PartnerAlign, the new Partner Portal of <span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span>. Lucky you, <span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span> is supplying you the most powerful partner portal in the Channel, for free.</p>
			<p><b>But this is the important part -</b> from now on, all <span class="merge-tag mceNonEditable"  contenteditable="false">{{MyCompany.Name}}</span> invoices are charged through Zomentum.</p>
			<div style="padding:16px;font-size:14px;line-height: 22px;">
			<div style="padding:16px;font-size:14px;line-height: 22px;background-color:#FEFBE6;border-color:#FFE58F;border-style: solid">
			<span style="font-size: 14px;margin-bottom:4px;line-height: 24px;"><b>Here are your credentials</b></span>
			<br>
			<span style="font-size: 14px;line-height: 25px">Username - <span class="merge-tag mceNonEditable" contenteditable="false"> {{Partner.Email}}</span></span>
			<br>
			<span style="font-size: 14px;line-height: 25px;">Password - <span class="merge-tag mceNonEditable" contenteditable="false"> {{Partner.Password}}</span></span>
			</div>`;
		}
		case VendorCustomTemplateType.AssignPartnerTier: {
			return `<span style="font-size: 14px;line-height: 25px;">You have been added to <span class="merge-tag mceNonEditable" contenteditable="false"> {{PartnerTier.Name}}</span> tier by <span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}}</span>.</span>
			<br>
			<br>
			<span style="font-size: 14px;line-height: 25px;">Explore the resources shared by the vendor within the <span class="merge-tag mceNonEditable">{{PartnerTier.Name}}</span> tier.</span>`;
		}
		case VendorCustomTemplateType.ChangePartnerTier: {
			return `
			<span style="font-size: 14px;line-height: 25px;">You Tier has been changed to <span class="merge-tag mceNonEditable">{{PartnerTier.Name}}</span> tier by <span class="merge-tag mceNonEditable"> {{MyCompany.Name}}</span>.</span>
			<br>
			<span style="font-size: 14px;line-height: 25px;">Explore the resources shared by the vendor within the <span class="merge-tag mceNonEditable">{{PartnerTier.Name}}</span> tier.</span>`;
		}
		default:
			return "";
	}
};

export const autoPopulateVendorEmailEndSalutationBasedOnTemplateType = (
	overridingTemplateType: VendorCustomTemplateType | null
) => {
	switch (overridingTemplateType) {
		case VendorCustomTemplateType.PublishTemplate: {
			return `<p>Regards,</p>
			<p><span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span></p>`;
		}
		case VendorCustomTemplateType.UnpublishTemplate: {
			return `<p>Regards,</p>
			<p><span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span></p>`;
		}
		case VendorCustomTemplateType.OpportunityShared: {
			return `<p>Regards,</p>
			<p><span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span></p>`;
		}
		case VendorCustomTemplateType.OpportunityReceived: {
			return `<p>Regards,</p>
			<p>Team Zomentum</p>`;
		}
		case VendorCustomTemplateType.AcceptInviteFlow: {
			return `<p>Regards,</p>
			<p><span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span></p>`;
		}
		case VendorCustomTemplateType.JoinVendorProgram: {
			return `<p>Regards,</p>
			<p>Team Zomentum</p>`;
		}
		case VendorCustomTemplateType.InviteExistingPartner: {
			return `<p><b>From Zomentum you will be able to</b></p>
			<table style="border-collapse:separate;border-spacing:0 15px;">
				<tr>
					<td>
						<img class="frame-icon" style="width: 92px;height: 75px;" src="https://zitto-email-templates-na.s3.ap-southeast-1.amazonaws.com/email_images/zomentum/partner-invite-access.png">
					</td>
					<td style="display:inline">
						<div style="margin-left: 16px;"><span><b>Access</b></span></div>
						<p style="margin-left: 16px;margin-top: 10px;padding-left:0;"><span class="merge-tag mceNonEditable" contenteditable="false">{{MyCompany.Name}}</span> marketing and sales materials</p>
					</td>
				</tr>
				<tr>
					<td>
						<img class="frame-icon" style="width: 92px;height: 75px;" src="https://zitto-email-templates-na.s3.ap-southeast-1.amazonaws.com/email_images/zomentum/partner-invite-drag-and-drop.png">
					</td>
					<td style="display:inline">
						<div style="margin-left: 16px;"><span><b>Drag and drop</b></span></div>
						<div style="margin-left: 16px;margin-top: 10px;"><span>Enablement materials to custom proposal templates and quotes</span></div>
					</td>
				</tr>
				<tr>
					<td>
						<img class="frame-icon" style="width: 92px;height: 75px;" src="https://zitto-email-templates-na.s3.ap-southeast-1.amazonaws.com/email_images/zomentum/partner-invite-work-opportunities.png">
					</td>
					<td style="display:inline">
						<div style="margin-left: 16px;"><span><b>Work opportunities</b></span></div>
						<p style="margin-left: 16px;margin-top: 10px;padding-left:0;">with <span class="merge-tag mceNonEditable" contenteditable="false">{{MyCompany.Name}}</span> support via live chat</p>
					</td>
				</tr>
				<tr>
					<td>
						<img class="frame-icon" style="width: 92px;height: 75px;" src="https://zitto-email-templates-na.s3.ap-southeast-1.amazonaws.com/email_images/zomentum/partner-invite-view-and-pay.png">
					</td>
					<td style="display:inline">
						<div style="margin-left: 16px;"><span><b>View and pay</b></span></div>
						<p style="margin-left: 16px;margin-top: 10px;padding-left:0;">View and pay <span class="merge-tag mceNonEditable" contenteditable="false">{{MyCompany.Name}}</span> invoices</p>
					</td>
				</tr>
			</table>
			<br>
			<p>Thanks</p>
			<p>Team Zomentum</p>`;
		}
		case VendorCustomTemplateType.InviteNewPartner: {
			return `</div>
			<p><b>From Zomentum you will be able to</b></p>
			<table style="border-collapse:separate;border-spacing:0 15px;">
				<tr>
					<td>
						<img class="frame-icon" style="width: 92px;height: 75px;" src="https://zitto-email-templates-na.s3.ap-southeast-1.amazonaws.com/email_images/zomentum/partner-invite-access.png">
					</td>
					<td style="display:inline">
						<div style="margin-left: 16px;"><span><b>Access</b></span></div>
						<p style="margin-left: 16px;margin-top: 10px;padding-left:0;"><span class="merge-tag mceNonEditable" contenteditable="false">{{MyCompany.Name}}</span> marketing and sales materials</p>
					</td>
				</tr>
				<tr>
					<td>
						<img class="frame-icon" style="width: 92px;height: 75px;" src="https://zitto-email-templates-na.s3.ap-southeast-1.amazonaws.com/email_images/zomentum/partner-invite-drag-and-drop.png">
					</td>
					<td style="display:inline">
						<div style="margin-left: 16px;"><span><b>Drag and drop</b></span></div>
						<div style="margin-left: 16px;margin-top: 10px;"><span>Enablement materials to custom proposal templates and quotes</span></div>
					</td>
				</tr>
				<tr>
					<td>
						<img class="frame-icon" style="width: 92px;height: 75px;" src="https://zitto-email-templates-na.s3.ap-southeast-1.amazonaws.com/email_images/zomentum/partner-invite-work-opportunities.png">
					</td>
					<td style="display:inline">
						<div style="margin-left: 16px;"><span><b>Work opportunities</b></span></div>
						<p style="margin-left: 16px;margin-top: 10px;padding-left:0;">with <span class="merge-tag mceNonEditable" contenteditable="false">{{MyCompany.Name}}</span> support via live chat</p>
					</td>
				</tr>
				<tr>
					<td>
						<img class="frame-icon" style="width: 92px;height: 75px;" src="https://zitto-email-templates-na.s3.ap-southeast-1.amazonaws.com/email_images/zomentum/partner-invite-view-and-pay.png">
					</td>
					<td style="display:inline">
						<div style="margin-left: 16px;"><span><b>View and pay</b></span></div>
						<p style="margin-left: 16px;margin-top: 10px;padding-left:0;">View and pay <span class="merge-tag mceNonEditable" contenteditable="false">{{MyCompany.Name}}</span> invoices</p>
					</td>
				</tr>
			</table>
			<br>
			<p>Thanks</p>
			<p>Team Zomentum</p>`;
		}
		case VendorCustomTemplateType.AssignPartnerTier: {
			return `<p>Thanks</p>
			<p>Team Zomentum</p>`;
		}
		case VendorCustomTemplateType.ChangePartnerTier: {
			return `<p>Thanks</p>
			<p>Team Zomentum</p>`;
		}
		default:
			return "";
	}
};

export const autoPopulateVendorEmailHeadingBasedOnTemplateType = (
	overridingTemplateType: VendorCustomTemplateType | null
) => {
	switch (overridingTemplateType) {
		case VendorCustomTemplateType.PublishTemplate: {
			return `<p>Hi <span class="merge-tag mceNonEditable"  contenteditable="false"> {{Partner.Name}} </span>!</p>`;
		}
		case VendorCustomTemplateType.UnpublishTemplate: {
			return `<p>Hi <span class="merge-tag mceNonEditable" contenteditable="false"> {{Partner.Name}} </span>!</p>`;
		}
		case VendorCustomTemplateType.OpportunityShared: {
			return `<p>Hi <span class="merge-tag mceNonEditable" contenteditable="false"> {{Partner.Name}} </span> ,</p>`;
		}
		case VendorCustomTemplateType.OpportunityReceived: {
			return `<p>Hi <span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span>!</p>`;
		}
		case VendorCustomTemplateType.AcceptInviteFlow: {
			return `<p>Hi <span class="merge-tag mceNonEditable" contenteditable="false"> {{Partner.Name}} </span> ,</p>`;
		}
		case VendorCustomTemplateType.JoinVendorProgram: {
			return `<p>Hello!</p>`;
		}
		case VendorCustomTemplateType.InviteExistingPartner: {
			return `<p>Hi, <span class="merge-tag mceNonEditable" contenteditable="false"> {{Partner.Name}} </span>!</p>`;
		}
		case VendorCustomTemplateType.InviteNewPartner: {
			return `<p>Hi, <span class="merge-tag mceNonEditable" contenteditable="false"> {{Partner.Name}} </span>!</p>`;
		}
		case VendorCustomTemplateType.AssignPartnerTier: {
			return `<p>Hi, <span class="merge-tag mceNonEditable" contenteditable="false"> {{Partner.Name}} </span>!</p>`;
		}
		case VendorCustomTemplateType.ChangePartnerTier: {
			return `<p>Hi, <span class="merge-tag mceNonEditable" contenteditable="false"> {{Partner.Name}} </span>!</p>`;
		}
		default:
			return "";
	}
};

export const autoPopulateAddVendorEmailBodyBasedOnTemplateType = (
	editor: any,
	overridingTemplateType: VendorCustomTemplateType | null
) => {
	switch (overridingTemplateType) {
		case VendorCustomTemplateType.PublishTemplate: {
			return editor.setContent(
				`<p>Hi <span class="merge-tag mceNonEditable">{{Partner.Name}}</span>!</p>
            <span style="font-size: 14px;line-height: 25px;">We just published a new template about <b><span class="merge-tag mceNonEditable">{{Template.Name}}</span></b>. You can access this template in your partner portal and use it according to your business needs.
            To check out this template click on <a style="text-decoration:none" href="{{Template.Url}}"><span style="color:#3300FF" class="merge-tag mceNonEditable">this link</span></a>. 
            If you have any questions or need help reach out to us at <a href="mailto:support@zomentum.com">support@zomentum.com</a>
            </span>
			<br>
			<button style="margin-top: 24px;cursor: pointer;width: auto;height: 32px;color: #ffffff;background-color: #2f70ff;border-radius: 4px;font-size: 14px;line-height: 3.3px;border: none;outline: none;margin-bottom: 4px;display: block;">Browse Templates</button>
			<br>
			<p style="color:#595959">Button isn't working? Copy-paste this URL in your browser's address bar:</p>
			<a style="text-decoration:none" class="merge-tag mceNonEditable" href="{{Template.Url}}">{{Template.Url}}</a>
			<br>
			<br>
			<span>Regards,</span>
			<br>
			<p><span class="merge-tag mceNonEditable">{{MyCompany.Name}}</span>&nbsp;</p>`
			);
		}
		case VendorCustomTemplateType.UnpublishTemplate: {
			return editor.setContent(
				`<p>Hi <span class="merge-tag mceNonEditable">{{Partner.Name}}</span>!</p>
        <span style="font-size: 14px;line-height: 25px;">We have an unpublished template - <b><span class="merge-tag mceNonEditable">{{Template.Name}}</span></b>. You will not be able to use this document template going forward, but existing documents where this template is used, will not get affected.
        If you have any questions or need help reach out to us at <a href="mailto:support@zomentum.com">support@zomentum.com</a> 
        </span>
		<br>
		<br>
		<span>Regards,</span>
		<br>
		<p><span class="merge-tag mceNonEditable">{{MyCompany.Name}}</span>&nbsp;</p>`
			);
		}
		case VendorCustomTemplateType.OpportunityShared: {
			return editor.setContent(
				`<p>Hi <span class="merge-tag mceNonEditable">{{Partner.Name}}</span> ,</p>
				<p>We have shared a new opportunity with you. Here are the details:</p>
				<p>Opportunity Id: <span class="merge-tag mceNonEditable">{{Opportunity.Name}}</span>&nbsp;</p>
				<p>Partner Name: <span class="merge-tag mceNonEditable">{{Partner.Name}}</span>&nbsp;</p>
				<p>Link: <span class="merge-tag mceNonEditable">{{Opportunity.Url}}</span>&nbsp;</p>
				<p>If you have any questions or need help reach out to us at <a href="mailto:support@zomentum.com">support@zomentum.com</a></p>
				<p><span class="merge-tag mceNonEditable">{{MyCompany.Name}}</span>&nbsp;</p>`
			);
		}
		case VendorCustomTemplateType.OpportunityReceived: {
			return editor.setContent(
				`<p>Hi <span class="merge-tag mceNonEditable">{{MyCompany.Name}}</span> ,</p>
				<p>Partner has shared a new opportunity with you. Here are the details:</p>
				<p>Opportunity Id: <span class="merge-tag mceNonEditable" contenteditable="false"> {{Opportunity.Name}} </span></p>
				<p>Partner Name: <span class="merge-tag mceNonEditable" contenteditable="false"> {{Partner.Name}} </span></p>
				<p>Link: <span class="merge-tag mceNonEditable" contenteditable="false"> {{Opportunity.Url}} </span></p>
				<p>If you have any questions, reach out to <span class="merge-tag mceNonEditable" contenteditable="false">{{Partner.Email}}</span>. <span class="merge-tag mceNonEditable"  contenteditable="false">{{Partner.Url}}</span></p>`
			);
		}
		case VendorCustomTemplateType.AcceptInviteFlow: {
			return editor.setContent(
				`<p>Hi <span class="merge-tag mceNonEditable">{{Partner.Name}}</span> ,</p>
				<p>We just published a new template about <span class="merge-tag mceNonEditable">{{Template.Name}}</span>. You can access this template in your partner portal and use it according to your business needs. </p>
				<p>To check out this template click on this link - <span class="merge-tag mceNonEditable">{{Template.Url}}</span>&nbsp;</p>
				<p>If you have any questions or need help reach out to us at <a href="mailto:support@zomentum.com">support@zomentum.com</a></p>
				<p><span class="merge-tag mceNonEditable">{{MyCompany.Name}}</span>&nbsp;</p>`
			);
		}
		case VendorCustomTemplateType.JoinVendorProgram: {
			return editor.setContent(
				`<p>Hello!</p>
				<span style="font-size: 14px;line-height: 25px;"><span class="merge-tag mceNonEditable">{{Partner.Name}}</span> has shown interest in working with you. They clicked on ‘Join now’ on your Zomentum Partner Align listing page.</span>
				<br>
				<span style="font-size: 14px;line-height: 25px;">You can accept or decline this request by logging into your Partner Align account.</span>
			<span style="font-size: 14px;line-height: 25px;">If you need any help reach out to us at <a href="mailto:support@zomentum.com">support@zomentum.com</a></span>
			<br>
			<br>
			<span style="font-size: 14px;line-height: 25px;">Regards,</span>
			<br>
			<span style="font-size: 14px;line-height: 25px;">Team Zomentum</span>`
			);
		}
		case VendorCustomTemplateType.InviteExistingPartner: {
			return editor.setContent(
				`<p>Hi, <span class="merge-tag mceNonEditable">{{Partner.Name}}!</span></p>
				<span style="font-size: 14px;line-height: 25px;">Welcome to Zomentum, the new Partner Portal of <span class="merge-tag mceNonEditable">{{MyCompany.Name}}.</span>
            	Lucky you, <span class="merge-tag mceNonEditable">{{MyCompany.Name}}</span> is supplying you the most powerful partner portal in the Channel, for free.
       			 </span>
				<p><b>But this is the important part -</b> from now on, all <span class="merge-tag mceNonEditable"  contenteditable="false">{{MyCompany.Name}}</span> invoices are charged through Zomentum.</p>
				<a href="{{Partner.InvitationUrl}}" style="cursor: pointer;box-sizing: border-box;display: flex;font-family:arial,
			helvetica,sans-serif;text-decoration: none;-webkit-text-size-adjust: none;
			text-align: center;color: #FFFFFF; background-color: #2f70ff;
			border-radius: 4px; -webkit-border-radius: 4px; -moz-border-radius: 4px;
			width:fit-content; margin-top: 20px; max-width:100%; overflow-wrap: break-word;
			word-break: break-word; word-wrap:break-word; mso-border-alt: none;padding: 8px 16px;line-height:16px"">
		    <span
			style="font-size:14px;line-height:16px;display: flex;
			align-items: center;justify-content: center;" contenteditable="false">Accept Invite</span>
			</a>
			<br>
			<p style="color:#595959">Button isn't working? Copy-paste this URL in your browser's address bar:</p>
			<a style="text-decoration:none" class="merge-tag mceNonEditable" href="{{Partner.InvitationUrl}}">{{Partner.InvitationUrl}}</a>			   
			    <br>
				<p><b>From Zomentum you will be able to</b></p>
				<table style="border-collapse:separate;border-spacing:0 15px;">
					<tr>
						<td>
							<img class="frame-icon" style="width: 92px;height: 75px;" src="https://zitto-email-templates-na.s3.ap-southeast-1.amazonaws.com/email_images/zomentum/partner-invite-access.png">
						</td>
						<td style="display:inline">
							<div style="margin-left: 16px;"><span><b>Access</b></span></div>
							<p style="margin-left: 16px;margin-top: 10px;padding-left:0;"><span class="merge-tag mceNonEditable" contenteditable="false">{{MyCompany.Name}}</span> marketing and sales materials</p>
						</td>
					</tr>
					<tr>
						<td>
							<img class="frame-icon" style="width: 92px;height: 75px;" src="https://zitto-email-templates-na.s3.ap-southeast-1.amazonaws.com/email_images/zomentum/partner-invite-drag-and-drop.png">
						</td>
						<td style="display:inline">
							<div style="margin-left: 16px;"><span><b>Drag and drop</b></span></div>
							<div style="margin-left: 16px;margin-top: 10px;"><span>Enablement materials to custom proposal templates and quotes</span></div>
						</td>
					</tr>
					<tr>
						<td>
							<img class="frame-icon" style="width: 92px;height: 75px;" src="https://zitto-email-templates-na.s3.ap-southeast-1.amazonaws.com/email_images/zomentum/partner-invite-work-opportunities.png">
						</td>
						<td style="display:inline">
							<div style="margin-left: 16px;"><span><b>Work opportunities</b></span></div>
							<p style="margin-left: 16px;margin-top: 10px;padding-left:0;">with <span class="merge-tag mceNonEditable" contenteditable="false">{{MyCompany.Name}}</span> support via live chat</p>
						</td>
					</tr>
					<tr>
						<td>
							<img class="frame-icon" style="width: 92px;height: 75px;" src="https://zitto-email-templates-na.s3.ap-southeast-1.amazonaws.com/email_images/zomentum/partner-invite-view-and-pay.png">
						</td>
						<td style="display:inline">
							<div style="margin-left: 16px;"><span><b>View and pay</b></span></div>
							<p style="margin-left: 16px;margin-top: 10px;padding-left:0;">View and pay <span class="merge-tag mceNonEditable" contenteditable="false">{{MyCompany.Name}}</span> invoices</p>
						</td>
					</tr>
				</table>
				<p>Thanks</p>
				<p>Team Zomentum</p>`
			);
		}
		case VendorCustomTemplateType.InviteNewPartner: {
			return editor.setContent(
				`<p>Hi, <span class="merge-tag mceNonEditable">{{Partner.Name}}!</span></p>
				<p>Welcome to Zomentum PartnerAlign, the new Partner Portal of <span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}}.</span>
				<p>Lucky you, <span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span> is supplying you the most powerful partner portal in the Channel, for free.</p>
				<p><b>But this is the important part -</b> from now on, all <span class="merge-tag mceNonEditable"  contenteditable="false">{{MyCompany.Name}}</span> invoices are charged through Zomentum.</p>
				<div style="padding:16px;font-size:14px;line-height: 22px;background-color:#FEFBE6;border-color:#FFE58F;border-style: solid">
				<span style="font-size: 14px;margin-bottom:4px;line-height: 24px;"><b>Here are your credentials</b></span>
				<br>
				<span style="font-size: 14px;line-height: 25px">Username - <span class="merge-tag mceNonEditable"> {{Partner.Email}}</span></span>
				<br>
				<span style="font-size: 14px;line-height: 25px;">Password - <span class="merge-tag mceNonEditable"> {{Partner.Password}}</span></span>
				</div>
				<a href="{{Partner.InvitationUrl}}" style="cursor: pointer;box-sizing: border-box;display: flex;font-family:arial,
			helvetica,sans-serif;text-decoration: none;-webkit-text-size-adjust: none;
			text-align: center;color: #FFFFFF; background-color: #2f70ff;
			border-radius: 4px; -webkit-border-radius: 4px; -moz-border-radius: 4px;
			width:fit-content; margin-top: 20px; max-width:100%; overflow-wrap: break-word;
			word-break: break-word; word-wrap:break-word; mso-border-alt: none;padding: 8px 16px;line-height:16px"">
		    <span
			style="font-size:14px;line-height:16px;display: flex;
			align-items: center;justify-content: center;" contenteditable="false">Accept
		 Invite</span>
			</a>
			<br>
			<p style="color:#595959">Button isn't working? Copy-paste this URL in your browser's address bar:</p>
			<a style="text-decoration:none" class="merge-tag mceNonEditable" href="{{Partner.InvitationUrl}}">{{Partner.InvitationUrl}}</a>	
				<p><b>From Zomentum you will be able to</b></p>
				<table style="border-collapse:separate;border-spacing:0 15px;">
					<tr>
						<td>
							<img class="frame-icon" style="width: 92px;height: 75px;" src="https://zitto-email-templates-na.s3.ap-southeast-1.amazonaws.com/email_images/zomentum/partner-invite-access.png">
						</td>
						<td style="display:inline">
							<div style="margin-left: 16px;"><span><b>Access</b></span></div>
							<p style="margin-left: 16px;margin-top: 10px;padding-left:0;"><span class="merge-tag mceNonEditable" contenteditable="false">{{MyCompany.Name}}</span> marketing and sales materials</p>
						</td>
					</tr>
					<tr>
						<td>
							<img class="frame-icon" style="width: 92px;height: 75px;" src="https://zitto-email-templates-na.s3.ap-southeast-1.amazonaws.com/email_images/zomentum/partner-invite-drag-and-drop.png">
						</td>
						<td style="display:inline">
							<div style="margin-left: 16px;"><span><b>Drag and drop</b></span></div>
							<div style="margin-left: 16px;margin-top: 10px;"><span>Enablement materials to custom proposal templates and quotes</span></div>
						</td>
					</tr>
					<tr>
						<td>
							<img class="frame-icon" style="width: 92px;height: 75px;" src="https://zitto-email-templates-na.s3.ap-southeast-1.amazonaws.com/email_images/zomentum/partner-invite-work-opportunities.png">
						</td>
						<td style="display:inline">
							<div style="margin-left: 16px;"><span><b>Work opportunities</b></span></div>
							<p style="margin-left: 16px;margin-top: 10px;padding-left:0;">with <span class="merge-tag mceNonEditable" contenteditable="false">{{MyCompany.Name}}</span> support via live chat</p>
						</td>
					</tr>
					<tr>
						<td>
							<img class="frame-icon" style="width: 92px;height: 75px;" src="https://zitto-email-templates-na.s3.ap-southeast-1.amazonaws.com/email_images/zomentum/partner-invite-view-and-pay.png">
						</td>
						<td style="display:inline">
							<div style="margin-left: 16px;"><span><b>View and pay</b></span></div>
							<p style="margin-left: 16px;margin-top: 10px;padding-left:0;">View and pay <span class="merge-tag mceNonEditable" contenteditable="false">{{MyCompany.Name}}</span> invoices</p>
						</td>
					</tr>
				</table>
				<p>Thanks</p>
				<p>Team Zomentum</p>`
			);
		}
		case VendorCustomTemplateType.AssignPartnerTier: {
			return editor.setContent(
				`<p>Hi <span class="merge-tag mceNonEditable">{{Partner.Name}}</span>!</p>
        <span style="font-size: 14px;line-height: 25px;">You have been added to <span class="merge-tag mceNonEditable" contenteditable="false">{{PartnerTier.Name}}</span> tier by <span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}}</span>.</span>
        <br>
        <br>
        <span style="font-size: 14px;line-height: 25px;">Explore the resources shared by the vendor within the <span class="merge-tag mceNonEditable" contenteditable="false">{{PartnerTier.Name}}</span> tier.</span>
        <br>
        <br>
        <span style="box-sizing: border-box;display: inline-block;font-family:arial,helvetica,sans-serif;text-decoration: none;-webkit-text-size-adjust: none;text-align: center;color: #FFFFFF; background-color: #2F70FF; border-radius: 4px; -webkit-border-radius: 4px; -moz-border-radius: 4px; width:auto; max-width:100%; overflow-wrap: break-word; word-break: break-word; word-wrap:break-word; mso-border-alt: none;">
        <span style="font-size: 14px;line-height: 25px;color:#FFFFFF;padding:16px;">View Vendor</span>
		<br>
		<p style="color:#595959">Button isn't working? Copy-paste this URL in your browser's address bar:</p>
		<a style="text-decoration:none" class="merge-tag mceNonEditable" href="{{MyCompany.Url}}">{{MyCompany.Url}}</a>	
        </span>`
			);
		}
		case VendorCustomTemplateType.ChangePartnerTier: {
			return editor.setContent(
				`<p>Hi <span class="merge-tag mceNonEditable">{{Partner.Name}}</span>!</p>
        <span style="font-size: 14px;line-height: 25px;">You Tier has been changed to <span class="merge-tag mceNonEditable">{{PartnerTier.Name}}</span> tier by <span class="merge-tag mceNonEditable">{{MyCompany.Name}}</span>.</span>
        <br>
        <span style="font-size: 14px;line-height: 25px;">Explore the resources shared by the vendor within the <span class="merge-tag mceNonEditable" contenteditable="false">{{PartnerTier.Name}}</span> tier.</span>
        <br>
        <br>
        <span style="box-sizing: border-box;display: inline-block;font-family:arial,helvetica,sans-serif;text-decoration: none;-webkit-text-size-adjust: none;text-align: center;color: #FFFFFF; background-color: #2F70FF; border-radius: 4px; -webkit-border-radius: 4px; -moz-border-radius: 4px; width:auto; max-width:100%; overflow-wrap: break-word; word-break: break-word; word-wrap:break-word; mso-border-alt: none;">
        <span style="font-size: 14px;line-height: 25px;color:#FFFFFF;padding:16px;">View Vendor</span>
		<br>
		<p style="color:#595959">Button isn't working? Copy-paste this URL in your browser's address bar:</p>
		<a style="text-decoration:none" class="merge-tag mceNonEditable" href="{{MyCompany.Url}}">{{MyCompany.Url}}</a>
        </span>`
			);
		}
		default:
			return;
	}
};

export const autoPopulateAddVendorEmailSubjectBasedOnTemplateType = (
	editor: any,
	overridingTemplateType: VendorCustomTemplateType | null
) => {
	switch (overridingTemplateType) {
		case VendorCustomTemplateType.PublishTemplate: {
			return editor.setContent(
				`<p><span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span>has published a new template</p>`
			);
		}
		case VendorCustomTemplateType.UnpublishTemplate: {
			return editor.setContent(
				`<p><span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span>has unpublished a template</p>`
			);
		}
		case VendorCustomTemplateType.OpportunityShared: {
			return editor.setContent(
				`<p><span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span>has shared an opportunity with you</p>`
			);
		}
		case VendorCustomTemplateType.OpportunityReceived: {
			return editor.setContent(
				`<p><span class="merge-tag mceNonEditable" contenteditable="false"> {{Partner.Name}} </span>has shared an opportunity with you</p>`
			);
		}
		case VendorCustomTemplateType.AcceptInviteFlow: {
			return editor.setContent(
				`<p><span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span>has shared an opportunity with you</p>`
			);
		}
		case VendorCustomTemplateType.JoinVendorProgram: {
			return editor.setContent(
				`<p><span class="merge-tag mceNonEditable" contenteditable="false"> {{Partner.Name}} </span> has shown interest in working with you.</p>`
			);
		}
		case VendorCustomTemplateType.InviteExistingPartner: {
			return editor.setContent(
				`<p>Welcome! Your <span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span>Partner Portal Awaits!</p>`
			);
		}
		case VendorCustomTemplateType.InviteNewPartner: {
			return editor.setContent(
				`<p>Welcome! Your New <span class="merge-tag mceNonEditable" contenteditable="false"> {{MyCompany.Name}} </span>Partner Portal Awaits!</p>`
			);
		}
		case VendorCustomTemplateType.AssignPartnerTier: {
			return editor.setContent(
				`<p>Welcome to the <span class="merge-tag mceNonEditable" contenteditable="false">{{PartnerTier.Name}}</span>, <span class="merge-tag mceNonEditable" contenteditable="false">{{MyCompany.Name}} </span></p>`
			);
		}
		case VendorCustomTemplateType.ChangePartnerTier: {
			return editor.setContent(
				`<p>Your Zomentum tier has been changed, <span class="merge-tag mceNonEditable" contenteditable="false">{{PartnerTier.Name}} </span>!</p>`
			);
		}
		default:
			return;
	}
};

export const shareResourceInfoText = (infoText: string) => {
	return (
		<div className="align-text-top grid grid-flow-col justify-start mt-4">
			<ZIcon
				name={EIcons.INFOCIRCLE}
				size="sm"
				className="mt-1"
				color={EColors.GRAY}
				colorVariant={7}
			/>
			<ZTypography.ZText
				level={1}
				color={EColors.GRAY}
				colorVariant={7}
				className="ml-2"
			>
				{infoText}
			</ZTypography.ZText>
		</div>
	);
};

export const ShareResourceType = [
	{
		label: "All Joined Partners",
		value: VendorResourceSharingType.JOINEDPARTNERS
	},
	{
		label: "Specific Partners and Tiers",
		value: VendorResourceSharingType.TIERSANDPARTNERS
	}
];

export const makePartnersAndTiersOptions = (
	selectedTiers: VendorPartnerTier[],
	tierList: VendorPartnerTier[],
	partners: VendorPartner[],
	selectedPartnersAndTierIds: string[]
) => [
	...selectedTiers.map(tier => ({
		name: (
			<Fragment>
				{tier.name}{" "}
				{
					<ZTag color={EColors.GREEN} className="ml-2">
						Tier
					</ZTag>
				}
			</Fragment>
		),
		value: `tier-${tier.id}`,
		title: tier.name
	})),
	...partners.map(partner => ({
		name: (
			<Fragment>
				{partner.name}{" "}
				{selectedPartnersAndTierIds?.includes(
					`tier-${partner.tier_id}`
				) && (
					<ZTypography.ZText
						colorVariant={6}
						className="ml-2"
					>{`Resource already shared with the ${
						tierList.find(tier => partner.tier_id === tier.id)?.name
					}`}</ZTypography.ZText>
				)}
			</Fragment>
		),
		value: `partner-${partner.id}`,
		title: partner.name
	}))
];

export const shareResourceWithPartnerConfirm = (
	name: string,
	tierName: string,
	newSelectedPartnersAndTierIds: string[],
	shareResourceWithPartnerCallback: (partnerAndTierIds: string[]) => void
) => {
	ZModal.confirm({
		title: (
			<ZTypography.ZText
				colorVariant={9}
				weight={EFontWeights.MEDIUM}
				level={1}
			>
				Partner already exists in the Tier you chose to share resource
				with
			</ZTypography.ZText>
		),
		content: (
			<ZTypography.ZText colorVariant={8} level={1}>
				{`${name} already exists in the ${tierName} tier you chose to share resource with. Do you still want to share resource with ${name}?`}
			</ZTypography.ZText>
		),
		onOk: () =>
			shareResourceWithPartnerCallback(newSelectedPartnersAndTierIds),
		okText: "Share with Partner",
		okType: "primary",
		icon: (
			<ZIcon
				name={EIcons.CIRCLEQUESTION}
				color={EColors.GOLD}
				colorVariant={6}
				className="anticon"
				size="md"
			/>
		),
		width: "600px",
		centered: true
	});
	return;
};

export const handleStopSharingResourceConfirm = (
	currentActiveResource: VendorResource | null,
	record: VendorPartnerTier | VendorPartner,
	handleStopSharingResourceConfirmCallback
) => {
	ZModal.confirm({
		title: (
			<ZTypography.ZText
				colorVariant={9}
				weight={EFontWeights.MEDIUM}
				level={1}
			>
				{`Are you sure you want to stop sharing ${currentActiveResource?.name} to ${record.name}?`}
			</ZTypography.ZText>
		),
		content: (
			<ZTypography.ZText colorVariant={8} level={1}>
				Once you stop sharing this resource, all the partners in this
				tier will no longer be able to access this resource.
			</ZTypography.ZText>
		),
		onOk: async () =>
			await handleStopSharingResourceConfirmCallback(record),
		okText: "Yes, Stop Sharing",
		okType: "danger",
		width: "600px",
		centered: true
	});
};

export const ManageAccessEmptyTab: React.FC<{
	message: string;
	isForTiers: boolean;
}> = props => {
	return (
		<div className="my-5 flex flex-col text-center">
			{props.isForTiers ? <EmptyTierResources /> : <EmptyTierPartners />}
			<ZTypography.ZText
				level={1}
				colorVariant={9}
				color={EColors.GRAY}
				className="mt-4 mb-2"
			>
				{props.message}
			</ZTypography.ZText>
		</div>
	);
};

export enum DateRange {
	"ThisCalendarYear" = "this-calendar-year",
	"LastCalendarYear" = "last-calendar-year",
	"ThisMonth" = "current-month",
	"LastMonth" = "last-month",
	"AllTime" = "all-time",
	"Custom" = "custom-date"
}

export const InsightsDateSelectOptions = [
	{
		label: "This Month",
		value: DateRange.ThisMonth
	},
	{
		label: "Last Month",
		value: DateRange.LastMonth
	},
	{
		label: "This Calender Year",
		value: DateRange.ThisCalendarYear
	},
	{
		label: "Last Calender Year",
		value: DateRange.LastCalendarYear
	},
	{
		label: "All Time",
		value: DateRange.AllTime
	}
];

export function getDateStartEndRange(range: string): {
	start_date: string;
	end_date: string;
} {
	const date = new Date();
	const currentYear = date.getFullYear();
	const currentMonth = date.getMonth();

	const firstDayOfMonth = (year, month) =>
		moment(new Date(year, month, 1)).format("YYYY-MM-DD");

	const lastDayOfMonth = (year, month) =>
		moment(new Date(year, month + 1, 0)).format("YYYY-MM-DD");

	let dateRange = {
		start_date: "2017-01-01",
		end_date: moment().format("YYYY-MM-DD")
	};

	switch (range) {
		case DateRange.ThisMonth:
			dateRange = {
				start_date: firstDayOfMonth(currentYear, currentMonth),
				end_date: lastDayOfMonth(currentYear, currentMonth)
			};
			break;

		case DateRange.LastMonth:
			dateRange = {
				start_date: firstDayOfMonth(currentYear, currentMonth - 1),
				end_date: lastDayOfMonth(currentYear, currentMonth - 1)
			};
			break;

		case DateRange.ThisCalendarYear:
			dateRange = {
				start_date: firstDayOfMonth(currentYear, 0),
				end_date: lastDayOfMonth(currentYear, 11)
			};
			break;

		case DateRange.LastCalendarYear:
			dateRange = {
				start_date: firstDayOfMonth(currentYear - 1, 0),
				end_date: lastDayOfMonth(currentYear - 1, 11)
			};
			break;
	}

	return dateRange;
}

export const getFileExtension = (fileName: string): string => {
	const parts = fileName.split(".");
	return parts.length > 1 ? parts[parts.length - 1].toLocaleUpperCase() : "";
};

export const getFileTypeIcons = (fileName: string): JSX.Element => {
	const extension = getFileExtension(fileName);
	switch (extension) {
		case "PDF":
			return (
				<Avatar
					className="mr-3"
					style={{ backgroundColor: "#E2EBFF" }}
					size="large"
					icon={<FileOutlined className="text-3 text-blue-6" />}
				/>
			);
		case "PNG":
		case "JPEG":
		case "JPG":
			return (
				<div>
					<PlaceHolderImage className="mr-3" />
				</div>
			);
		default:
			return (
				<Avatar
					className="mr-3"
					style={{ backgroundColor: "#E2EBFF" }}
					size="large"
					icon={<FolderAddOutlined className="text-3 text-blue-6" />}
				/>
			);
	}
};
