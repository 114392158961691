'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.FormIdentifier = void 0;
(function (FormIdentifier) {
  FormIdentifier["document_page_push_to_psa_form"] = "document_page_push_to_psa_form";
  FormIdentifier["opportunity_push_to_psa_form"] = "opportunity_push_to_psa_form";
})(exports.FormIdentifier || (exports.FormIdentifier = {}));
var fieldValueAdapter = function fieldValueAdapter(formValues) {
  return function (field) {
    var _a, _b, _c;
    var value = formValues[field.key];
    var responseField = {
      key: field.key
    };
    if (field.data_type === "multi_select_drop_down" && Array.isArray(value)) {
      responseField.multi_select_drop_down_value = value;
    }
    if (field.data_type === "text" && typeof value == "string") {
      responseField.text_value = value;
    }
    if (field.data_type === "number" && typeof value == "number") {
      responseField.numeric_value = value;
    }
    if (field.data_type === "checkbox") {
      responseField.checkbox_value = !!value;
    }
    if (field.data_type === "date") {
      responseField.date_value = (_a = value) === null || _a === void 0 ? void 0 : _a.toISOString();
    }
    if (field.data_type === "drop_down" && typeof value == "string") {
      responseField.drop_down_value = value;
    }
    responseField.children = (_c = (_b = field.children) === null || _b === void 0 ? void 0 : _b.map(fieldValueAdapter(formValues))) !== null && _c !== void 0 ? _c : [];
    return responseField;
  };
};

exports.fieldValueAdapter = fieldValueAdapter;
