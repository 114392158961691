import { ZModal, ZTypography } from "@zomentum/atoms";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import { FC } from "react";
import ZForm from "@zomentum/organisms/dist/ZForm";
import {
	EWidgets,
	IFormSectionProps
} from "@zomentum/organisms/dist/ZForm/interface";
import { IRejectRequestModalProps } from "./interface";

const ZRejectRequestModal: FC<IRejectRequestModalProps> = ({
	visible,
	onCancel,
	pendingOpportunity,
	onReject,
	isLoading
}) => {
	const [form] = useForm();
	const sections: IFormSectionProps[] = [
		{
			meta: {
				fields: [
					{
						wrapperCol: 24,
						key: "rejectReason",
						widget: EWidgets.INPUTTEXTAREA,
						required: true,
						message:
							"Please mention your reason for rejecting request",
						widgetProps: {
							placeholder:
								"Please mention your reason for rejecting request",
							rows: "4",
							className: "w-full"
						}
					}
				]
			}
		}
	];
	const onSubmit = async (values: { rejectReason: string }) => {
		try {
			await form.validateFields();
			await onReject(values.rejectReason);
			form.resetFields();
		} catch (error) {
			console.error();
		}
	};
	return (
		<ZModal
			visible={visible}
			onCancel={onCancel}
			title="Reject Request"
			okText="Yes, Reject"
			okButtonProps={{ danger: true, ghost: true }}
			onOk={form.submit}
			confirmLoading={isLoading}
			size="md"
		>
			<ZTypography.ZTitle level={1} className="mb-4">
				Are you sure you want to reject this {pendingOpportunity.name}{" "}
				opportunity request?
			</ZTypography.ZTitle>
			<ZForm
				key="reject-request-form"
				form={form}
				sections={sections}
				onFinish={onSubmit}
				isSubmitAllowed={false}
			/>
		</ZModal>
	);
};

export default ZRejectRequestModal;
