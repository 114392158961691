import ZScreenshotImages from "@templates/ZVendor/ZProductsCollections/molecules/ZScreenshotImages";
import ZFileUpload from "@zomentum/molecules/dist/ZFileUpload";
import React, { useCallback, useState, useEffect } from "react";
import { IUploadMediaWithPreviewWidgetProps } from "./interface";
import { DefaultErrorMessage, UppyFileMetaData } from "@zomentum/contracts";
import { UppyAllowedFileTypes as AllowedFileTypes } from "@zomentum/molecules/dist/ZFileUpload/interface";
import { UploadResult } from "@uppy/core";
import ZModal from "@zomentum/atoms/dist/ZModal";
import ZVideoItems from "@templates/ZVendor/ZProductsCollections/molecules/ZVideoItems";
import ZMediaPreview from "@organisms/ZMediaPreview";
import { MarketplaceProductMediaType } from "@organisms/ZMediaPreview/interface";

const ZUploadMediaWithPreview: React.FC<IUploadMediaWithPreviewWidgetProps> = ({
	value,
	onChange,
	fileType = "image",
	uploadButtonIcon,
	uploadButtonText,
	className,
	...rest
}) => {
	const [uploadModalOpen, setUploadModalOpen] = React.useState(false);
	const [mediasList, setMediasList] = useState<string[]>([]);

	const deleteMedia = (selectedMediaDelete: string) => {
		ZModal.confirm({
			title: `Are you sure you want to delete this ${
				fileType === "image" ? "image" : "video"
			}?`,
			onOk: () => {
				setMediasList(prev => {
					const newMediasList = prev.filter(
						media => media !== selectedMediaDelete
					);
					onChange(newMediasList);
					return newMediasList;
				});
			},
			okType: "danger",
			okText: "Delete"
		});
	};

	const uploadMedia = useCallback(
		async (result: UploadResult<UppyFileMetaData>) => {
			try {
				if (!result?.successful?.[0]?.uploadURL?.length) {
					throw new Error(DefaultErrorMessage);
				}

				const files = result.successful.map(file => file.uploadURL);
				setMediasList(prev => {
					const newMediasList = [...prev, ...files];
					onChange(newMediasList);
					return newMediasList;
				});
				setUploadModalOpen(false);
			} catch (error) {
				console.error(error);
			}
		},
		[mediasList]
	);

	useEffect(() => {
		if (value) {
			setMediasList(value);
		}
	}, [value]);

	const allowedFileTypes = [
		fileType === "image" ? AllowedFileTypes.IMAGE : AllowedFileTypes.VIDEO
	];

	return (
		<>
			{fileType === "image" ? (
				<ZScreenshotImages
					imagesList={value}
					deleteImage={deleteMedia}
					showImageUploadModal={() => setUploadModalOpen(true)}
					showPreviewImageModal={(image: string) =>
						ZMediaPreview.showPreviewModal(
							MarketplaceProductMediaType.IMAGE,
							image
						)
					}
					className={className}
					uploadButtonIcon={uploadButtonIcon}
					uploadButtonText={uploadButtonText}
				/>
			) : (
				<ZVideoItems
					video={value?.[0] ?? null}
					showVideoUploadModal={() => setUploadModalOpen(true)}
					showPreviewVideo={() =>
						ZMediaPreview.showPreviewModal(
							MarketplaceProductMediaType.VIDEO,
							value?.[0]
						)
					}
					className={className}
					deleteVideo={() => deleteMedia(value[0])}
				/>
			)}
			<ZFileUpload
				maxNumberOfFiles={fileType === "image" ? 5 : 1}
				minNumberOfFiles={1}
				useModal={{
					visible: uploadModalOpen,
					title:
						fileType === "image" ? "Upload image" : "Upload video"
				}}
				useURL
				allowedFileTypes={allowedFileTypes}
				maxFileSize={rest?.maxFileSize || 5e8}
				allowMultipleUploads={true}
				onCancel={() => setUploadModalOpen(false)}
				onUploadSuccess={uploadMedia}
				allowYoutubeLink={true}
			/>
		</>
	);
};

export default ZUploadMediaWithPreview;
