import { VendorOpportunity } from "@zomentum/contracts/dist/Vendor/Opportunity";

export enum OpportunityDrawerMode {
	ADD = "add",
	EDIT = "edit"
}

export interface IOpportunityDrawerProps {
	readonly visible: boolean;
	readonly onClose: (
		opportunity?: VendorOpportunity,
		oldOpportunityPipelineStageId?: string
	) => void;
	readonly mode: OpportunityDrawerMode;
	readonly currentActiveOpportunity: VendorOpportunity | null;
	readonly vendorEndClientId?: string;
	readonly isPreviewFromClientsPage?: boolean;
	readonly defaultOpportunityStage?: string | null;
}
