import React, { Fragment, useState } from "react";
import { ReactComponent as EmptyProductPricingPlansIcon } from "@/Assets/ProductsPage/EmptyProductPricingPlansIcon.svg";
import { capitalizeFirstLetter } from "@zomentum/utils";
import { useForm } from "antd/lib/form/Form";
import { ISKUPricingPlansFormItemWidget } from "./interface";
import {
	handleMarginChange,
	handleMarkupChange,
	handleMarkupMargin
} from "@/Components/Utils/PricingCalculator";
import {
	ProductBillingType,
	productBillingTypeOptions
} from "@zomentum/contracts/dist/Products";
import ZPricingPlanBar from "@templates/ZVendor/ZPricingPlanBar";
import { SKUPricingPlanFormValues } from "@zomentum/contracts/dist/Vendor/SKU";
import { ZTypography, ZButton, ZModal } from "@zomentum/atoms";
import { EIcons } from "@zomentum/atoms/dist/ZIcon/interface";
import { EInputNumberWidths } from "@zomentum/atoms/dist/ZInputNumber/interface";
import ZDrawer from "@zomentum/molecules/dist/ZDrawer";
import { ZForm } from "@zomentum/organisms";
import {
	IFormSectionProps,
	EWidgets,
	IFormProps
} from "@zomentum/organisms/dist/ZForm/interface";
import { EColors } from "@zomentum/atoms/dist/ZColors/interface";
import { EFontWeights } from "@zomentum/atoms/dist/ZTypography/interface";
import { ReactComponent as EmptySkuArrow } from "@/Assets/Vendor/EmptySkuArrow.svg";
import { ZMessage } from "@zomentum/molecules";
import { GlobalState } from "@/Reducers/interface";
import { useSelector } from "react-redux";

const { ZText } = ZTypography;

const ZSKUPricingPlanFormWidget: React.FC<ISKUPricingPlansFormItemWidget> = ({
	value,
	onChange
}) => {
	const [form] = useForm<SKUPricingPlanFormValues>();

	const [isLoading, setLoading] = useState(false);

	const [action, setAction] = useState<"add" | "edit">("add");
	const [pricingPlanDrawerOpen, setPricingPlanDrawerOpen] = useState(false);

	const [currentEditIndex, setCurrentEditIndex] = useState<number | null>(
		null
	);

	const { vendorUserCompany } = useSelector((state: GlobalState) => ({
		vendorUserCompany: state.vendorUser.vendorUserCompany
	}));

	const onOpenPricingPlanDrawer = () => {
		setPricingPlanDrawerOpen(true);
	};

	const onClosePricingPlanDrawer = () => {
		setPricingPlanDrawerOpen(false);
		setCurrentEditIndex(null);
		form.resetFields();
		setAction("add");
	};

	const getFieldValues = () => {
		return form.getFieldsValue([
			"cost_price",
			"sell_price",
			"setup_price",
			"margin",
			"markup",
			"vendor_sku_no"
		]);
	};

	const onCostPriceChange = (newCostPrice?: number | string | null): void => {
		if (newCostPrice === undefined || newCostPrice === null) {
			return;
		}
		const values = getFieldValues();
		form.setFieldsValue(
			handleMarkupMargin(
				Number(newCostPrice),
				values.sell_price,
				values.quantity,
				false,
				values.selected_setup_price
			)
		);
	};

	const onMarkupChange = (newMarkup?: number | string | null): void => {
		if (newMarkup === undefined || form.getFieldValue("cost_price") === 0) {
			return;
		}
		const values = getFieldValues();
		form.setFieldsValue(handleMarkupChange(values, Number(newMarkup)));
	};

	const onMarginChange = (newMargin?: number | string | null): void => {
		if (
			newMargin === undefined ||
			newMargin === null ||
			form.getFieldValue("cost_price") === 0
		) {
			return;
		}
		const values = getFieldValues();

		if (
			Number(newMargin) >= 100 &&
			Number(getFieldValues().cost_price) !== 0
		) {
			return;
		}
		form.setFieldsValue(handleMarginChange(values, Number(newMargin)));
	};

	const onSellPriceChange = (newSellPrice?: number | string | null): void => {
		if (newSellPrice === undefined || newSellPrice === null) {
			return;
		}
		const values = getFieldValues();
		form.setFieldsValue(
			handleMarkupMargin(
				values.cost_price,
				Number(newSellPrice),
				values.quantity,
				false,
				values.setup_price
			)
		);
	};

	const onPricingPlanEdit = (index: number) => {
		setAction("edit");
		setCurrentEditIndex(index);
		setPricingPlanDrawerOpen(true);
		form.setFieldsValue({
			name: value[index].name,
			cost_price: value[index].cost_price,
			sell_price: value[index].sell_price,
			setup_price: value[index].setup_price,
			margin: value[index]?.margin,
			markup: value[index]?.markup,
			vendor_sku_no: value[index]?.vendor_sku_no,
			description: value[index].description,
			billing_period: value[index].billing_period
		});
		onSellPriceChange(value[index].sell_price);
	};

	const onPricingPlanDelete = (index: number) => {
		ZModal.confirm({
			content: "Do you want to delete this product pricing?",
			okText: "Delete",
			okButtonProps: {
				danger: true,
				type: "default"
			},
			onOk: () => onChange(value.filter((_, i) => i !== index))
		});
	};

	const sections: IFormSectionProps[] = [
		{
			meta: {
				fields: [
					{
						key: "name",
						label: "Name",
						required: true,
						widget: EWidgets.INPUT,
						initialValue: "Default Pricing",
						widgetProps: {
							placeholder: "Enter pricing plan name",
							className: "w-full"
						}
					},
					{
						key: "description",
						label: "Description",
						required: true,
						widget: EWidgets.INPUTTEXTAREA,
						widgetProps: {
							placeholder: "Enter pricing plan description",
							rows: 4
						}
					},
					{
						key: "billing_period",
						label: "Billing Period",
						required: true,
						widget: EWidgets.SELECT,
						widgetProps: {
							placeholder: "Please select billing period",
							selectOptions: (
								Object.keys(
									productBillingTypeOptions
								) as ProductBillingType[]
							).map((value, index) => ({
								name: productBillingTypeOptions[value],
								value,
								key: index
							}))
						}
					},
					{
						key: "cost_price",
						label: "Cost Price",
						required: true,
						widget: EWidgets.INPUTNUMBER,
						initialValue: 0.0,
						widgetProps: {
							precision: 2,
							step: 0.01,
							min: 0,
							addOnAfter: `${vendorUserCompany.settings.currency}`,
							width: EInputNumberWidths.full,
							onChange: onCostPriceChange
						},
						rules: [
							{
								required: true,
								validator: (_: any, value: any) => {
									if (Number(value) < 0) {
										return Promise.reject(
											new Error(
												"Invalid Entry. Cost price cannot be less than 0"
											)
										);
									}
									if (!value && value !== 0) {
										return Promise.reject(
											new Error(
												"Cost price cannot be empty"
											)
										);
									}
									return Promise.resolve();
								}
							}
						]
					},
					{
						key: "markup",
						label: "Markup",
						widget: EWidgets.INPUTNUMBER,
						initialValue: 0.0,
						widgetProps: {
							precision: 2,
							step: 0.01,
							min: 0,
							addOnAfter: "%",
							width: EInputNumberWidths.full,
							onChange: onMarkupChange
						}
					},
					{
						key: "margin",
						label: "Margin",
						widget: EWidgets.INPUTNUMBER,
						initialValue: 0.0,
						widgetProps: {
							precision: 2,
							step: 0.01,
							max: 100,
							addOnAfter: "%",
							width: EInputNumberWidths.full,
							onChange: onMarginChange
						},
						rules: [
							{
								validator: (_: any, value: any) => {
									if (
										Number(value) >= 100 &&
										form.getFieldValue("cost_price") > 0
									) {
										return Promise.reject(
											new Error(
												"Invalid Entry. Margin cannot be 100% unless cost price is 0"
											)
										);
									}
									return Promise.resolve();
								}
							}
						]
					},
					{
						key: "sell_price",
						label: "Selling Price",
						required: true,
						widget: EWidgets.INPUTNUMBER,
						initialValue: 0.0,
						widgetProps: {
							precision: 2,
							step: 0.01,
							min: 0,
							addOnAfter: `${vendorUserCompany.settings.currency}`,
							width: EInputNumberWidths.full,
							onChange: onSellPriceChange
						}
					},
					{
						key: "setup_price",
						label: "Setup Fee",
						required: true,
						widget: EWidgets.INPUTNUMBER,
						initialValue: 0.0,
						widgetProps: {
							precision: 2,
							step: 0.01,
							min: 0,
							addOnAfter: `${vendorUserCompany.settings.currency}`,
							width: EInputNumberWidths.full
						}
					},
					{
						key: "vendor_sku_no",
						label: "Vendor SKU No",
						widget: EWidgets.INPUT,
						widgetProps: {
							placeholder: "Please enter vendor sku no",
							className: "w-full"
						}
					}
				]
			}
		}
	];

	const onFinish = (values: SKUPricingPlanFormValues) => {
		const isPlanNameInValid = value?.find((v, index) => {
			if (currentEditIndex === index) {
				return false;
			}
			return (
				v.name.trim().toLowerCase() === values.name.trim().toLowerCase()
			);
		});

		if (isPlanNameInValid) {
			ZMessage.error("Pricing plan name must be unique");
			return;
		}
		setLoading(true);
		if (action === "add") {
			onChange(!!value?.length ? [...value, values] : [values]);
			ZMessage.success("Pricing plan added successfully");
		} else {
			const newValues = values;
			const id =
				currentEditIndex !== null ? value[currentEditIndex].id : "";
			if (!!id?.length) newValues.id = id;
			onChange(
				value.map((v, i) => (i === currentEditIndex ? values : v))
			);
			ZMessage.success("Pricing plan edited successfully");
		}
		onClosePricingPlanDrawer();
		setLoading(false);
	};

	const formProps: IFormProps = {
		form,
		key: "skuPricingPlanForm",
		submitButtonText: action === "edit" ? "Update" : "Add",
		isCancelAllowed: true,
		isRightFooter: true,
		isStickFooter: true,
		isDynamic: true,
		sections,
		loading: isLoading,
		shouldDisableBeforeTouch: false,
		onFinish,
		onCancel: onClosePricingPlanDrawer
	};

	return (
		<Fragment>
			<div className="mb-4 flex justify-end">
				<ZButton
					type="default"
					htmlType="button"
					iconName={EIcons.ADD}
					iconSize="small"
					iconPlacement={"left"}
					loading={isLoading}
					onClick={onOpenPricingPlanDrawer}
					className="border-blue-6 text-blue-6"
				>
					Add Plan
				</ZButton>
			</div>
			{!!value?.length && (
				<ZPricingPlanBar
					pricingPlans={value}
					onPricingPlanDelete={onPricingPlanDelete}
					onPricingPlanEdit={onPricingPlanEdit}
				/>
			)}
			{!value?.length && (
				<div className="mb-6">
					<div className="flex flex-col items-center justify-center relative">
						<EmptyProductPricingPlansIcon />
						<ZText
							level={1}
							className="block mt-4"
							color={EColors.GRAY}
							colorVariant={10}
							weight={EFontWeights.MEDIUM}
						>
							No pricing plans added
						</ZText>
						<ZText
							className="block mt-2 mb-6"
							color={EColors.GRAY}
							colorVariant={10}
						>
							Click ‘Add Plan’ to create a new one
						</ZText>
						<EmptySkuArrow className="absolute right-28 top-2" />
					</div>
				</div>
			)}
			<ZDrawer
				visible={pricingPlanDrawerOpen}
				onClose={onClosePricingPlanDrawer}
				title={`${capitalizeFirstLetter(action)} Pricing Plan`}
				removeDefaultPadding
				closable
				destroyOnClose
			>
				<ZForm {...formProps} className="py-4" />
			</ZDrawer>
		</Fragment>
	);
};

export default ZSKUPricingPlanFormWidget;
