import {
	CustomViewsMetadata,
	CustomView,
	CustomViewResponse,
	ListPagePostRequest,
	ZomentumEntities
} from "@zomentum/contracts";
import {
	SKUProduct,
	SKUProductRequest,
	SKUProductResponse
} from "@zomentum/contracts/dist/Vendor/SKU";
import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest
} from "@zomentum/utils/dist/Service/BackendService";
import axios, { AxiosResponse } from "axios";

const getProductSKUsURL = "/v1/marketplace/sku";
const getProductSKUsViewURL = "/v1/marketplace/sku/view";
const getProductSKUDetailsURL = "/v1/marketplace/sku/{id}";
const publishProductSKUURL = "/v1/marketplace/sku/{id}/publish";
const unpublishProductSKUURL = "/v1/marketplace/sku/{id}/unpublish";
const getSKUViewMetadataURL = "/v2/views/metadata?entity_type={entity_type}";

// VIEWS
const createProductViewURL = "/v2/views";
const updateProductViewURL = "/v2/views/{view_id}";
const deleteProductViewURL = "v2/views/{view_id}";
const getAllProductViewURL = "/v2/views?entity_type={entity_type}";

export const getProductSKUs = async (): Promise<SKUProductResponse> => {
	try {
		const response: AxiosResponse<SKUProductResponse> = await axios(
			getRequest(getProductSKUsURL, null, true, false, false)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getProductSKUsView = async (
	skuRequest: ListPagePostRequest
): Promise<SKUProductResponse> => {
	try {
		const response: AxiosResponse<SKUProductResponse> = await axios(
			postRequest(getProductSKUsViewURL, skuRequest, true, false, false)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const getSKUViewMetadata = async (): Promise<CustomViewsMetadata> => {
	try {
		const response: AxiosResponse<CustomViewsMetadata> = await axios(
			getRequest(
				getSKUViewMetadataURL.replace(
					"{entity_type}",
					ZomentumEntities.MarketplaceSKU
				),
				null,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const getProductSKUDetails = async (
	skuId: string
): Promise<SKUProduct> => {
	try {
		const response: AxiosResponse<SKUProduct> = await axios(
			getRequest(
				getProductSKUDetailsURL.replace("{id}", skuId),
				null,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const addProductSKU = async (
	addSKURequest: SKUProductRequest
): Promise<SKUProduct> => {
	try {
		const response: AxiosResponse<SKUProduct> = await axios(
			postRequest(getProductSKUsURL, addSKURequest, true, false, false)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const editProductSKU = async (
	id: string,
	editSKURequest: SKUProductRequest
): Promise<SKUProduct> => {
	try {
		const response: AxiosResponse<SKUProduct> = await axios(
			putRequest(
				getProductSKUDetailsURL.replace("{id}", id),
				editSKURequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteProductSKU = async (skuId: string): Promise<void> => {
	try {
		await axios(
			deleteRequest(
				getProductSKUDetailsURL.replace("{id}", skuId),
				null,
				true,
				false
			)
		);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const publishProductSKU = async (skuId: string): Promise<SKUProduct> => {
	try {
		const response: AxiosResponse<SKUProduct> = await axios(
			putRequest(
				publishProductSKUURL.replace("{id}", skuId),
				{},
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const unpublishProductSKU = async (
	skuId: string
): Promise<SKUProduct> => {
	try {
		const response: AxiosResponse<SKUProduct> = await axios(
			putRequest(
				unpublishProductSKUURL.replace("{id}", skuId),
				{},
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getAllProductViews = async (): Promise<CustomViewResponse> => {
	try {
		const response: AxiosResponse<CustomViewResponse> = await axios(
			getRequest(
				getAllProductViewURL.replace(
					"{entity_type}",
					ZomentumEntities.MarketplaceSKU
				),
				null,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const createProductView = async (
	customViewRequest: CustomView
): Promise<CustomView> => {
	try {
		const response: AxiosResponse<CustomView> = await axios(
			postRequest(
				createProductViewURL,
				customViewRequest,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const updateProductView = async (
	customViewRequest: CustomView
): Promise<CustomView> => {
	try {
		const response: AxiosResponse<CustomView> = await axios(
			putRequest(
				updateProductViewURL.replace("{view_id}", customViewRequest.id),
				customViewRequest,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const deleteProductView = async (
	customViewId: string
): Promise<unknown> => {
	try {
		const response: AxiosResponse<unknown> = await axios(
			deleteRequest(
				deleteProductViewURL.replace("{view_id}", customViewId),
				null,
				true,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};
