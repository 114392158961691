import { ZomentumRoutes } from "@zomentum/contracts";
import { EIcons } from "@zomentum/atoms/dist/ZIcon/interface";
import { ReactComponent as CompanyDetailsIcon } from "@/Assets/Vendor/CompanyDetails.svg";

/* 
	{
		...
		permission: (AllRolePermission | null)
		Item is shown if the mentioned permission is enabled. By default null for items which do not require a permission. 
	}
*/

export default () => {
	const routes = [
		{
			route: ZomentumRoutes.VendorCompanyDetails,
			componentIcon: CompanyDetailsIcon,
			title: "Company Details",
			permission: null
		},
		{
			route: ZomentumRoutes.VendorEmailTemplateSettings,
			icon: EIcons.EMAILTEMPLATE,
			title: "Email Templates",
			permission: null
		},
		{
			title: "Marketplace",
			disabled: true,
			permission: null
		},
		{
			title: "Marketplace Listings",
			icon: EIcons.MARKETPLACE,
			permission: null,
			route: ZomentumRoutes.VendorMarketplaceProductListings
		},
		{
			title: "Partners",
			disabled: true,
			permission: null
		},
		{
			route: ZomentumRoutes.VendorPartnerTiering,
			icon: EIcons.VENDORTIERS,
			title: "Tiering",
			permission: null
		},
		{
			title: "Settings",
			disabled: true,
			permission: null
		},
		{
			route: ZomentumRoutes.EmailSettings,
			icon: EIcons.MAIL,
			title: "Email Settings",
			permission: null
		},
		{
			route: ZomentumRoutes.VendorAccountSettings,
			icon: EIcons.USERCIRCLE,
			title: "Account",
			permission: null
		},
		{
			route: ZomentumRoutes.VendorUsers,
			icon: EIcons.USERSGROUP,
			title: "Users",
			permission: null
		},
		{
			route: ZomentumRoutes.VendorPipelineSettings,
			icon: EIcons.PIPELINES,
			title: "Pipeline",
			permission: null
		},
		{
			route: ZomentumRoutes.VendorCustomFieldsSettings,
			icon: EIcons.CUSTOMEFIELDS,
			title: "Custom Fields",
			permission: null
		},
		{
			route: ZomentumRoutes.IntegrationSettings,
			icon: EIcons.INTEGRATIONS,
			title: "Integrations",
			permission: null
		}
	];
	return routes;
};
