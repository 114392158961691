import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
	withSalesActivityModuleProvider,
	SalesActivityProvider
} from "@modules/sales-activity/dist/store";
import { defaultParentContext } from "@modules/sales-activity/dist/store";
import "@modules/sales-activity/dist/index.css";
import { fetchMergeTagsWithNoEntityId } from "@/Services/Others/EmailThread";
import ZTableWithDecorators from "@organisms/ZTableWithDecorators";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { EmailTemplatesActionCreators } from "@/Actions/Vendor/EmailTemplate";
import { CustomTemplateType } from "@zomentum/contracts/dist/EmailTemplates";
import useDispatch from "@/Components/Utils/useDispatch";
import { useSelector } from "react-redux";
import { GlobalState } from "@/Reducers/interface";
import VendorUsersActionCreators from "@/Actions/Vendor/User";
import ZUserSelect from "@organisms/ZUserSelect";
import EmailThreadActionCreators from "@/Actions/Others/EmailThread";
import EmailThreadComponent from "@/Components/Others/EmailThread";
import EmailThreadMessageDrawer from "@/Components/Others/EmailThread/Drawers/EmailThreadMessageDrawer";
import ImportEmailTemplateModal from "@/Components/Others/EmailThread/Drawers/FooterActivities/ImportEmailTemplateModal";
import TrackEmail from "@/Components/Others/EmailThread/Drawers/FooterActivities/TrackEmail";
import EmailSignatureComponent from "@/Components/Others/EmailThread/Drawers/FooterActivities/EmailSignatureComponent";
import RecentActivityFeedActionCreators from "@/Actions/Others/RecentActivityFeed";
import ZVendorPartnerSelect from "@organisms/ZPartnerSelect";
import UserSelectComponent from "@/Components/UI/UserSelect";
import { getPartnerListPagePost } from "@/Services/Vendor/Partner";
import { getClientListPagePost } from "@/Services/Vendor/Client";
import { VendorPartnerActionCreators } from "@/Actions/Vendor/Partner";
import { VendorOpportunityActionCreators } from "@/Actions/Vendor/Opportunity";
import "@/Components/Others/EmailThread/Drawers/EmailThreadMessageDrawer.less";
import { ZomentumEntities, ZomentumRoutes } from "@zomentum/contracts";
import { getOpportunityListPost } from "@/Services/Vendor/Opportunity";
import ZVendorOpportunitySelect from "@organisms/ZVendorOpportunitySelect";
import ZClientSelect from "@organisms/ZClientSelect";
import ZVendorClientSelect from "@organisms/ZClientSelect";
import ZClientContactSelect from "@organisms/ZClientContactSelect";
import OpportunityDrawer from "@pages/Vendor/Opportunities/Drawers/OpportunityDrawer";
import { VendorClientActionCreators } from "@/Actions/Vendor/Client";
import PipelinesActionCreators from "@/Actions/Vendor/Pipelines";
import AddOrEditClientContact from "@pages/Vendor/Clients/Drawers/AddOrEditClientContact";
import { CustomFieldsActionCreators } from "@/Actions/CustomFields";

const SalesActivityWrapper: React.FC = ({ children }) => {
	const isSalesActivityPage = !!useRouteMatch(
		ZomentumRoutes.SalesActivityPage
	);
	const isSalesAutomationPage = !!useRouteMatch(
		ZomentumRoutes.SalesActivityAutomationsList
	);
	const dispatch = useDispatch();
	useEffect(() => {
		if (isSalesActivityPage || isSalesAutomationPage) {
			dispatch(VendorUsersActionCreators.getVendorUserList(100));
			dispatch(PipelinesActionCreators.getPipeline());
		}
	}, [isSalesActivityPage, isSalesAutomationPage]);

	const { currentActivePartner, editVendorPartnerDrawerOpen } = useSelector(
		(state: GlobalState) => {
			return {
				currentActivePartner: state.vendorPartner.currentActivePartner,
				editVendorPartnerDrawerOpen:
					state.vendorPartner.editVendorPartnerDrawerOpen
			};
		}
	);

	const showEditVendorPartnerDrawer = useCallback(() => {
		dispatch(VendorPartnerActionCreators.showEditVendorPartnerDrawer());
	}, []);

	const hideEditVendorPartnerDrawer = useCallback(() => {
		dispatch(VendorPartnerActionCreators.hideEditVendorPartnerDrawer());
	}, []);

	const { currentActiveVendorClient, editVendorClientDrawerOpen } =
		useSelector((state: GlobalState) => {
			return {
				currentActiveVendorClient:
					state.vendorClient.currentActiveClient,
				editVendorClientDrawerOpen:
					state.vendorClient.editVendorClientDrawerOpen
			};
		});

	const showEditVendorClientDrawer = useCallback(() => {
		dispatch(VendorClientActionCreators.showEditVendorClientDrawer());
	}, []);

	const hideEditVendorClientDrawer = useCallback(() => {
		dispatch(VendorClientActionCreators.hideEditVendorClientDrawer());
	}, []);

	const {
		isOpportunityLoading,
		opportunityList,
		currentActiveOpportunity,
		addOpportunityDrawerOpen,
		editOpportunityDrawerOpen,
		activeOpportunityView
	} = useSelector((state: GlobalState) => {
		return {
			isOpportunityLoading: state.vendorOpportunity.loading,
			opportunityList: state.vendorOpportunity.opportunityList,
			currentActiveOpportunity:
				state.vendorOpportunity.currentActiveOpportunity,
			addOpportunityDrawerOpen:
				state.vendorOpportunity.opportunityDrawerVisible,
			editOpportunityDrawerOpen:
				state.vendorOpportunity.opportunityDrawerVisible,
			activeOpportunityView: state.vendorOpportunity.activeOpportunityView
		};
	});

	const showOpportunitiesLoader = useCallback(() => {
		dispatch(VendorOpportunityActionCreators.showOpportunityLoader());
	}, []);

	const hideOpportunitiesLoader = useCallback(() => {
		dispatch(VendorOpportunityActionCreators.hideOpportunityLoader());
	}, []);

	const addOpportunityDrawerOpened = useCallback(() => {
		dispatch(VendorOpportunityActionCreators.openAddOpportunityDrawer());
	}, []);

	const addOpportunityDrawerClosed = useCallback(() => {
		dispatch(VendorOpportunityActionCreators.closeAddOpportunityDrawer());
	}, []);

	const opportunity = useMemo(
		() => ({
			...defaultParentContext.opportunity,
			isLoading: isOpportunityLoading,
			opportunityList,
			currentActiveOpportunity,
			addOpportunityDrawerOpen,
			editOpportunityDrawerOpen,
			activeOpportunityView,
			showOpportunitiesLoader,
			hideOpportunitiesLoader,
			addOpportunityDrawerOpened,
			addOpportunityDrawerClosed,
			editOpportunityDrawerOpened: addOpportunityDrawerOpened,
			editOpportunityDrawerClosed: addOpportunityDrawerClosed,
			// syncKanbanOpportunityDataById,
			// resetOpportunityDetails,
			ZOpportunitySelect: ZVendorOpportunitySelect,
			AddOpportunityDrawer: OpportunityDrawer,
			getOpportunityListPost
		}),
		[
			isOpportunityLoading,
			opportunityList,
			currentActiveOpportunity,
			addOpportunityDrawerOpen,
			editOpportunityDrawerOpen,
			activeOpportunityView
		]
	);

	const [editClientDrawerOpen, setEditClientDrawerOpen] = useState(false);
	const editClientDrawerOpened = useCallback(() => {
		setEditClientDrawerOpen(true);
	}, []);
	const editClientDrawerClosed = useCallback(() => {
		setEditClientDrawerOpen(false);
	}, []);

	const [addClientContactDrawerOpen, setAddClientContactDrawerOpen] =
		useState(false);

	const addClientContactDrawerOpened = useCallback(() => {
		setAddClientContactDrawerOpen(true);
	}, []);

	const addClientContactDrawerClosed = useCallback(() => {
		setAddClientContactDrawerOpen(false);
	}, []);

	const client = useMemo(
		() => ({
			...defaultParentContext.client,
			ZClientSelect,
			ZClientContactSelect,
			editClientDrawerOpen,
			editClientDrawerOpened,
			editClientDrawerClosed,
			addClientContactDrawerOpen,
			addClientContactDrawerOpened,
			addClientContactDrawerClosed,
			AddClientContactDrawer: AddOrEditClientContact
		}),
		[editClientDrawerOpen, addClientContactDrawerOpen]
	);

	const vendorPartner = useMemo(
		() => ({
			currentActivePartner,
			ZVendorPartnerSelect,
			getPartnerListPagePost,
			editVendorPartnerDrawerOpen,
			showEditVendorPartnerDrawer,
			hideEditVendorPartnerDrawer
		}),
		[currentActivePartner, editVendorPartnerDrawerOpen]
	);

	const vendorClient = useMemo(
		() => ({
			currentActiveVendorClient,
			ZVendorClientSelect,
			getClientListPagePost,
			editVendorClientDrawerOpen,
			showEditVendorClientDrawer,
			hideEditVendorClientDrawer
		}),
		[currentActiveVendorClient, editVendorClientDrawerOpen]
	);

	const { isRecentActivityFeedLoading, emailThreadDrawerOpen } = useSelector(
		(state: GlobalState) => {
			return {
				isRecentActivityFeedLoading: state.recentActivityFeed.isLoading,
				emailThreadDrawerOpen:
					state.recentActivityFeed.emailThreadDrawerOpen
			};
		}
	);
	const emailThreadDrawerOpened = useCallback(() => {
		dispatch(RecentActivityFeedActionCreators.emailThreadDrawerOpened());
	}, []);
	const emailThreadDrawerClosed = useCallback(() => {
		dispatch(RecentActivityFeedActionCreators.emailThreadDrawerClosed());
	}, []);

	const recentActivityFeed = useMemo(
		() => ({
			isLoading: isRecentActivityFeedLoading,
			emailThreadDrawerOpen,
			emailThreadDrawerOpened,
			emailThreadDrawerClosed
		}),
		[isRecentActivityFeedLoading, emailThreadDrawerOpen]
	);

	const {
		isEmailThreadLoading,
		emailThreadMessageDrawerOpen,
		missingEmailModalOpen,
		emailMergeTagsSystemVariable
	} = useSelector((state: GlobalState) => {
		return {
			isEmailThreadLoading: state.emailThread.isLoading,
			emailThreadMessageDrawerOpen:
				state.emailThread.emailThreadMessageDrawerOpen,
			missingEmailModalOpen: state.emailThread.missingEmailModalOpen,
			emailMergeTagsSystemVariable:
				state.emailThread.emailMergeTagsSystemVariable
		};
	});
	const emailThreadMessageDrawerOpened = useCallback(
		(sendEmailWithoutDocument?: boolean) => {
			dispatch(
				EmailThreadActionCreators.emailThreadMessageDrawerOpened(
					sendEmailWithoutDocument
				)
			);
		},
		[]
	);
	const emailThreadMessageDrawerClosed = useCallback(() => {
		dispatch(EmailThreadActionCreators.emailThreadMessageDrawerClosed());
	}, []);

	const missingEmailModalOpened = useCallback(() => {
		dispatch(EmailThreadActionCreators.missingEmailModalOpened());
	}, []);

	const missingEmailModalClosed = useCallback(() => {
		dispatch(EmailThreadActionCreators.missingEmailModalClosed());
	}, []);

	const resetMergeTags = useCallback(() => {
		dispatch(EmailThreadActionCreators.resetMergeTags());
	}, []);

	const fetchMergeTagsWithoutId = useCallback(async (entityType: string) => {
		await dispatch(
			EmailThreadActionCreators.fetchMergeTagsWithoutId(entityType)
		);
	}, []);

	const emailThread = useMemo(
		() => ({
			isLoading: isEmailThreadLoading,
			emailThreadMessageDrawerOpen,
			missingEmailModalOpen,
			emailMergeTagsSystemVariable,
			emailThreadMessageDrawerOpened,
			emailThreadMessageDrawerClosed,
			missingEmailModalOpened,
			missingEmailModalClosed,
			resetMergeTags,
			fetchMergeTagsWithoutId,
			EmailThreadComponent,
			EmailThreadMessageDrawer,
			ImportEmailTemplateModal,
			TrackEmail,
			EmailSignatureComponent
		}),
		[
			isEmailThreadLoading,
			emailThreadMessageDrawerOpen,
			missingEmailModalOpen,
			emailMergeTagsSystemVariable
		]
	);

	const {
		isUserLoading,
		userData,
		userCompany,
		companyUserList,
		integrations
	} = useSelector((state: GlobalState) => {
		return {
			isUserLoading: state.vendorUser.isLoading,
			userData: state.vendorUser.vendorUserData,
			userCompany: state.vendorUser.vendorUserCompany,
			companyUserList: state.vendorUser.vendorUserList,
			integrations: state.vendorUser.vendorUserIntegration
		};
	});
	const getUserCompany = useCallback(async () => {
		await dispatch(VendorUsersActionCreators.getVendorUserCompany());
	}, []);
	const user = useMemo(
		() => ({
			...defaultParentContext.user,
			isLoading: isUserLoading,
			userData,
			userCompany,
			companyUserList,
			getUserCompany,
			ZUserSelect,
			UserSelectComponent,
			integrations
		}),
		[isUserLoading, userData, userCompany, companyUserList]
	);

	const fetchTemplateByType = useCallback(
		async (customTemplateType: CustomTemplateType) => {
			return await dispatch(
				EmailTemplatesActionCreators.fetchTemplateByType(
					customTemplateType
				)
			);
		},
		[]
	);

	const emailTemplate = useMemo(
		() => ({
			fetchTemplateByType
		}),
		[]
	);

	const { isSettingsLoading, appUpdateBannerVisible, customFieldList } =
		useSelector((state: GlobalState) => {
			return {
				isSettingsLoading: state.settings.isLoading,
				appUpdateBannerVisible: state.settings.appUpdateBannerVisible,
				customFieldList: state.customFields.customFieldList.log_call
			};
		});

	const getCustomFields = useCallback(
		async (entityType: ZomentumEntities) => {
			await dispatch(
				CustomFieldsActionCreators.getCustomFields(entityType)
			);
		},
		[]
	);

	const setting = useMemo(
		() => ({
			isLoading: isSettingsLoading,
			appUpdateBannerVisible,
			customFieldList,
			getCustomFields
		}),
		[isSettingsLoading, appUpdateBannerVisible, customFieldList]
	);

	const history = useHistory();
	const historyPush = useCallback(
		(location: string) => history.push(location),
		[]
	);

	const misc = useMemo(
		() => ({
			...defaultParentContext.misc,
			fetchMergeTagsWithNoEntityId,
			ZTableWithDecorators,
			Link,
			historyPush
		}),
		[]
	);

	const { currentActivePipeline } = useSelector((state: GlobalState) => {
		return {
			currentActivePipeline: state.vendorPipelines.currentActivePipeline
		};
	});

	const pipeline = useMemo(
		() => ({
			...defaultParentContext.pipeline,
			pipelineList: currentActivePipeline ? [currentActivePipeline] : []
		}),
		[currentActivePipeline]
	);

	return (
		<SalesActivityProvider
			{...defaultParentContext}
			opportunity={opportunity}
			vendorPartner={vendorPartner}
			recentActivityFeed={recentActivityFeed}
			emailThread={emailThread}
			user={user}
			emailTemplate={emailTemplate}
			setting={setting}
			misc={misc}
			client={client}
			vendorClient={vendorClient}
			integrations={integrations}
			pipeline={pipeline}
		>
			{children}
		</SalesActivityProvider>
	);
};

export default withSalesActivityModuleProvider(memo(SalesActivityWrapper));
