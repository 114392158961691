import React, { useState } from "react";
import {
	CustomViewColumnStatus,
	CustomViewColumns
} from "@zomentum/contracts/dist/UI";
import { IColumnDnDProps } from "./interface";
import {
	DragDropContext,
	Draggable,
	DraggableProvided,
	DraggableStateSnapshot,
	Droppable,
	DropResult
} from "react-beautiful-dnd";
import ZTypography, { FontWeights } from "@zomentum/atoms/dist/ZTypography";
import ZIcon, { Icons } from "@zomentum/atoms/dist/ZIcon";
import { EColors } from "@zomentum/atoms/dist/ZColors/interface";
import ZButton from "@zomentum/atoms/dist/ZButton";
import ZTooltip from "@zomentum/atoms/dist/ZTooltip";
import { shortenText } from "@zomentum/utils";
import "./index.less";

const ColumnsSettingsDnD: React.FC<IColumnDnDProps> = props => {
	const allEnabledColumns = () =>
		props.columns.filter(
			column =>
				column.view_column_status === CustomViewColumnStatus.Enabled
		);

	const allDisabledColumns = () =>
		props.columns.filter(
			column =>
				column.view_column_status === CustomViewColumnStatus.Disabled
		);

	const [enabledColumns, setEnableColumns] = useState<CustomViewColumns[]>(
		() => allEnabledColumns()
	);
	const [disabledColumns, setDisabledColumns] = useState<CustomViewColumns[]>(
		() => allDisabledColumns()
	);

	const handleDrop = (result: DropResult) => {
		if (result.destination?.index === result.source.index) {
			return;
		}

		if (
			typeof result.source.index !== "number" ||
			typeof result.destination?.index !== "number"
		) {
			console.warn("Invalid indices");
			return;
		}

		const updatedColumns = [...enabledColumns];

		const column = updatedColumns.splice(result.source.index, 1)[0];
		updatedColumns.splice(result.destination?.index, 0, column);
		setEnableColumns(updatedColumns);
	};

	const addColumn = (column: CustomViewColumns) => {
		const updatedColumn = {
			...column,
			view_column_status: CustomViewColumnStatus.Enabled
		};
		setEnableColumns(prevState => prevState.concat(updatedColumn));
		setDisabledColumns(
			disabledColumns.filter(
				currColumn => currColumn.display_name !== column.display_name
			)
		);
	};
	const removeColumn = (column: CustomViewColumns) => {
		setEnableColumns(
			enabledColumns.filter(
				currColumn => currColumn.display_name !== column.display_name
			)
		);
		const updatedColumn = {
			...column,
			view_column_status: CustomViewColumnStatus.Disabled
		};
		setDisabledColumns(prevState => [updatedColumn].concat(prevState));
	};

	const fixedColumns = props.columns.filter(
		column =>
			column.view_column_status === CustomViewColumnStatus.AlwaysEnabled
	);

	const applySelectedColumns = () => {
		const columns = [
			...fixedColumns,
			...enabledColumns,
			...disabledColumns
		];
		props.onSubmit(columns);
		props.hidePopover();
	};

	return (
		<div className="column-dnd-container">
			<div className="active-columns px-4">
				<ZTypography.ZText
					className="sticky top-0 z-50 bg-gray-1 block pt-4 pb-2"
					weight={FontWeights.MEDIUM}
				>
					Columns you’ve added
				</ZTypography.ZText>
				{fixedColumns.map((column, index) => (
					<div
						className="rounded bg-gray-3 p-1 my-2 justify-between flex items-center"
						key={index}
					>
						<div className="flex items-center">
							<ZIcon
								name={Icons.REMOVE}
								size="sm"
								className="mr-2 opacity-60"
							/>
							<ZTypography.ZText>
								{column.display_name}
							</ZTypography.ZText>
						</div>
					</div>
				))}
				<DragDropContext onDragEnd={handleDrop}>
					<Droppable droppableId="columns">
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								style={{ position: "static" }}
								className={`${
									snapshot.isDraggingOver ? "active" : ""
								}`}
							>
								{enabledColumns.map((column, index) => (
									<Draggable
										draggableId={
											!!column.system_field
												? `${index}::${column.system_field}`
												: `${index}::${column.custom_field_id}`
										}
										index={index}
										key={index}
									>
										{(
											draggableProvided: DraggableProvided,
											draggableSnapshot: DraggableStateSnapshot
										) => (
											<div
												ref={draggableProvided.innerRef}
												{...draggableProvided.draggableProps}
												className={`${
													draggableSnapshot.isDragging
														? "is-dragging"
														: ""
												}`}
											>
												<div className="column-item rounded p-1 justify-between flex items-center my-2">
													<div className="name-icon flex items-center">
														<ZIcon
															name={Icons.REMOVE}
															onClick={() =>
																removeColumn(
																	column
																)
															}
															size="sm"
															color={EColors.RED}
															colorVariant={4}
															className="cursor-pointer mr-2"
														/>
														<ZTooltip
															showtooltip={
																column
																	.display_name
																	.length > 34
															}
															placement="top"
															title={
																column.display_name
															}
														>
															<ZTypography.ZText>
																{shortenText(
																	column.display_name,
																	27
																)}
															</ZTypography.ZText>
														</ZTooltip>
													</div>
													<div
														className="handle-icon"
														{...draggableProvided.dragHandleProps}
													>
														<ZIcon
															name={Icons.DRAG}
															colorVariant={7}
														/>
													</div>
												</div>
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
			<div className="inactive-columns px-4">
				<ZTypography.ZText
					className="sticky top-0 z-50 bg-gray-1 block pb-2"
					weight={FontWeights.MEDIUM}
				>
					Columns you can add
				</ZTypography.ZText>
				{disabledColumns ? (
					disabledColumns.map((column, index) => (
						<div
							className="rounded p-1 justify-between flex items-center my-2"
							key={index}
						>
							<div className="flex items-center">
								<ZIcon
									name={Icons.ADD}
									onClick={() => addColumn(column)}
									size="sm"
									color={EColors.GREEN}
									colorVariant={6}
									className="cursor-pointer mr-2"
								/>
								<ZTooltip
									showtooltip={
										column.display_name.length > 34
									}
									placement="top"
									title={column.display_name}
								>
									<ZTypography.ZText>
										{shortenText(column.display_name, 31)}
									</ZTypography.ZText>
								</ZTooltip>
							</div>
						</div>
					))
				) : (
					<div>No columns disabled</div>
				)}
			</div>
			<div className="flex items-center justify-end p-4 border-0 border-t border-solid border-gray-5">
				<ZButton
					className="mr-4"
					size="small"
					onClick={props.hidePopover}
				>
					Cancel
				</ZButton>
				<ZButton
					type="primary"
					size="small"
					onClick={applySelectedColumns}
				>
					Apply
				</ZButton>
			</div>
		</div>
	);
};

export default ColumnsSettingsDnD;
