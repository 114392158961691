import { SKUProduct } from "@zomentum/contracts/dist/Vendor/SKU";

export enum EditOrCloneProductDrawerMode {
	EDIT = "edit",
	CLONE = "clone"
}

export enum EProductSKUDrawerState {
	ADD = "add",
	EDITORCLONE = "editorclone"
}

export interface IProdctSKUDrawerProps {
	readonly visible: boolean;
	readonly onClose: (shouldUpdateData?: boolean) => void;
	readonly drawerState: EProductSKUDrawerState;
	readonly mode?: EditOrCloneProductDrawerMode | null;
	readonly currentSKU?: SKUProduct | null;
	readonly publishOnly?: boolean;
	readonly bundleClone?: boolean;
	readonly showBundles?: boolean;
	readonly onAdd?: () => void;
	readonly handleEditBundleProduct?: (a: any) => void;
}
