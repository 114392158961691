import { Action, ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import {
	addCustomField,
	countCustomFields,
	deleteCustomFieldAsync,
	editCustomField,
	getCustomFields,
	reorderCustomField
} from "@/Services/Settings/CustomFields";

import {
	CustomField,
	CustomFieldCountPartnerAlign,
	CustomFieldFormValues,
	ReorderCustomFieldRequest
} from "@zomentum/contracts/dist/Settings";
import { GlobalState } from "@/Reducers/interface";
import { ZomentumEntities } from "@zomentum/contracts";

export enum CustomFieldsActionTypes {
	VENDOR_OPPORTUNITY_CUSTOM_FIELDS_SUCCESSFUL = "VENDOR_OPPORTUNITY_CUSTOM_FIELDS_SUCCESSFUL",
	VENDOR_CLIENTS_CUSTOM_FIELDS_SUCCESSFUL = "VENDOR_CLIENTS_CUSTOM_FIELDS_SUCCESSFUL",
	VENDOR_CLIENTS_CONTACTS_CUSTOM_FIELDS_SUCCESSFUL = "VENDOR_CLIENTS_CONTACTS_CUSTOM_FIELDS_SUCCESSFUL",
	VENDOR_PARTNER_CUSTOM_FIELDS_SUCCESSFUL = "VENDOR_PARTNER_CUSTOM_FIELDS_SUCCESSFUL",
	VENDOR_MARKETPLACE_SKU_CUSTOM_FIELDS_SUCCESSFUL = "VENDOR_MARKETPLACE_SKU_CUSTOM_FIELDS_SUCCESSFUL",
	VENDOR_SALES_LOG_CALL_CUSTOM_FIELDS_SUCCESSFUL = "VENDOR_SALES_LOG_CALL_CUSTOM_FIELDS_SUCCESSFUL",
	VENDOR_TEMPLATES_CUSTOM_FIELDS_SUCCESSFUL = "VENDOR_TEMPLATES_CUSTOM_FIELDS_SUCCESSFUL"
}

export enum CountCustomFieldsActionTypes {
	COUNT_CUSTOM_FIELD_LIST_SUCCESSFUL = "COUNT_CUSTOM_FIELD_LIST_SUCCESSFUL"
}

export enum CustomFieldsOperationActionTypes {
	REORDER_CUSTOM_FIELDS_SUCCESSFUL = "REORDER_CUSTOM_FIELDS_SUCCESSFUL",
	DELETE_CUSTOM_FIELD_LIST_SUCCESSFUL = "DELETE_CUSTOM_FIELD_LIST_SUCCESSFUL",
	ADD_CUSTOM_FIELD_LIST_SUCCESSFUL = "ADD_CUSTOM_FIELD_LIST_SUCCESSFUL",
	EDIT_CUSTOM_FIELD_LIST_SUCCESSFUL = "EDIT_CUSTOM_FIELD_LIST_SUCCESSFUL",
	RESET_CUSTOM_FIELD_LIST_SUCCESSFUL = "RESET_CUSTOM_FIELD_LIST_SUCCESSFUL"
}

export interface CustomFieldsSuccessfulAction
	extends Action<CustomFieldsActionTypes> {
	readonly customFieldList: CustomField[];
}

export interface CountCustomFieldsSuccessfulAction
	extends Action<CountCustomFieldsActionTypes> {
	readonly customFieldCount: CustomFieldCountPartnerAlign;
}

export interface ReorderCustomFieldSuccessfulAction
	extends Action<CustomFieldsOperationActionTypes.REORDER_CUSTOM_FIELDS_SUCCESSFUL> {
	readonly customFieldList: CustomField[];
	readonly entityType: ZomentumEntities;
}

export interface DeleteCustomFieldListSuccessfulAction
	extends Action<CustomFieldsOperationActionTypes.DELETE_CUSTOM_FIELD_LIST_SUCCESSFUL> {
	readonly message: string;
}

export interface AddCustomFieldListSuccessfulAction
	extends Action<CustomFieldsOperationActionTypes.ADD_CUSTOM_FIELD_LIST_SUCCESSFUL> {
	readonly addCustomField: CustomField;
	readonly entityType: ZomentumEntities;
}

export interface EditCustomFieldListSuccessfulAction
	extends Action<CustomFieldsOperationActionTypes.EDIT_CUSTOM_FIELD_LIST_SUCCESSFUL> {
	readonly editCustomField: CustomField;
}

export type ResetCustomFieldListSuccessfulAction =
	Action<CustomFieldsOperationActionTypes.RESET_CUSTOM_FIELD_LIST_SUCCESSFUL>;

export type CustomFieldsActions =
	| CustomFieldsSuccessfulAction
	| CountCustomFieldsSuccessfulAction
	| ReorderCustomFieldSuccessfulAction
	| DeleteCustomFieldListSuccessfulAction
	| AddCustomFieldListSuccessfulAction
	| EditCustomFieldListSuccessfulAction
	| ResetCustomFieldListSuccessfulAction;

export const EntityTypeToCustomFieldMapping: Record<
	Extract<
		ZomentumEntities,
		| ZomentumEntities.VendorOpportunity
		| ZomentumEntities.VendorPartner
		| ZomentumEntities.VendorClient
		| ZomentumEntities.VendorClientContact
		| ZomentumEntities.MarketplaceSKU
		| ZomentumEntities.SalesLogCall
		| ZomentumEntities.VendorTemplate
	>,
	CustomFieldsActionTypes
> = {
	[ZomentumEntities.VendorOpportunity]:
		CustomFieldsActionTypes.VENDOR_OPPORTUNITY_CUSTOM_FIELDS_SUCCESSFUL,
	[ZomentumEntities.VendorClient]:
		CustomFieldsActionTypes.VENDOR_CLIENTS_CUSTOM_FIELDS_SUCCESSFUL,
	[ZomentumEntities.VendorClientContact]:
		CustomFieldsActionTypes.VENDOR_CLIENTS_CONTACTS_CUSTOM_FIELDS_SUCCESSFUL,
	[ZomentumEntities.VendorPartner]:
		CustomFieldsActionTypes.VENDOR_PARTNER_CUSTOM_FIELDS_SUCCESSFUL,
	[ZomentumEntities.MarketplaceSKU]:
		CustomFieldsActionTypes.VENDOR_MARKETPLACE_SKU_CUSTOM_FIELDS_SUCCESSFUL,
	[ZomentumEntities.SalesLogCall]:
		CustomFieldsActionTypes.VENDOR_SALES_LOG_CALL_CUSTOM_FIELDS_SUCCESSFUL,
	[ZomentumEntities.VendorTemplate]:
		CustomFieldsActionTypes.VENDOR_TEMPLATES_CUSTOM_FIELDS_SUCCESSFUL
};

export interface CustomFieldsDispatch {
	getCustomFields: ActionCreator<
		ThunkAction<
			Promise<CustomFieldsSuccessfulAction>,
			GlobalState,
			ZomentumEntities,
			CustomFieldsSuccessfulAction
		>
	>;
	countCustomFields: ActionCreator<
		ThunkAction<
			Promise<CountCustomFieldsSuccessfulAction>,
			GlobalState,
			null,
			CountCustomFieldsSuccessfulAction
		>
	>;
	reorderCustomFields: ActionCreator<
		ThunkAction<
			Promise<ReorderCustomFieldSuccessfulAction>,
			GlobalState,
			ReorderCustomFieldRequest,
			ReorderCustomFieldSuccessfulAction
		>
	>;
	deleteCustomField: ActionCreator<
		ThunkAction<
			Promise<DeleteCustomFieldListSuccessfulAction>,
			GlobalState,
			CustomField,
			DeleteCustomFieldListSuccessfulAction
		>
	>;
	addCustomField: ActionCreator<
		ThunkAction<
			Promise<AddCustomFieldListSuccessfulAction>,
			GlobalState,
			CustomFieldFormValues,
			AddCustomFieldListSuccessfulAction
		>
	>;
	editCustomField: ActionCreator<
		ThunkAction<
			Promise<EditCustomFieldListSuccessfulAction>,
			GlobalState,
			CustomFieldFormValues,
			EditCustomFieldListSuccessfulAction
		>
	>;
	resetCustomFields: ActionCreator<
		ThunkAction<
			Promise<ResetCustomFieldListSuccessfulAction>,
			GlobalState,
			CustomFieldFormValues,
			ResetCustomFieldListSuccessfulAction
		>
	>;
}

export const CustomFieldsActionCreators: CustomFieldsDispatch = {
	getCustomFields:
		(
			entityType: Extract<
				ZomentumEntities,
				| ZomentumEntities.VendorOpportunity
				| ZomentumEntities.VendorPartner
				| ZomentumEntities.VendorClient
				| ZomentumEntities.VendorClientContact
				| ZomentumEntities.MarketplaceSKU
				| ZomentumEntities.SalesLogCall
				| ZomentumEntities.VendorTemplate
			>
		) =>
		async (dispatch: Dispatch): Promise<CustomFieldsSuccessfulAction> => {
			try {
				const getCustomFieldsResponse = await getCustomFields(
					entityType
				);

				const getCustomFieldsSuccessfulAction: CustomFieldsSuccessfulAction =
					{
						type: EntityTypeToCustomFieldMapping[entityType],
						customFieldList: getCustomFieldsResponse.data
					};
				return dispatch(getCustomFieldsSuccessfulAction);
			} catch (error) {
				console.error(error);
				throw error;
			}
		},
	countCustomFields:
		() =>
		async (
			dispatch: Dispatch
		): Promise<CountCustomFieldsSuccessfulAction> => {
			try {
				const getCountCustomFieldsResponse = await countCustomFields();

				const getCountCustomFieldsSuccessfulAction: CountCustomFieldsSuccessfulAction =
					{
						type: CountCustomFieldsActionTypes.COUNT_CUSTOM_FIELD_LIST_SUCCESSFUL,
						customFieldCount: getCountCustomFieldsResponse
					};
				return dispatch(getCountCustomFieldsSuccessfulAction);
			} catch (error) {
				console.error(error);
				throw error;
			}
		},
	reorderCustomFields:
		(reorderCustomFieldRequest: ReorderCustomFieldRequest) =>
		async (
			dispatch: Dispatch
		): Promise<ReorderCustomFieldSuccessfulAction> => {
			try {
				const reorderCustomFieldResponse = await reorderCustomField(
					reorderCustomFieldRequest.fields,
					reorderCustomFieldRequest.entityType
				);

				const reorderCustomFieldSuccessfulAction: ReorderCustomFieldSuccessfulAction =
					{
						type: CustomFieldsOperationActionTypes.REORDER_CUSTOM_FIELDS_SUCCESSFUL,
						customFieldList: reorderCustomFieldResponse.data,
						entityType: reorderCustomFieldRequest.entityType
					};
				return dispatch(reorderCustomFieldSuccessfulAction);
			} catch (error) {
				console.error(error);
				throw error;
			}
		},
	deleteCustomField: (deleteCustomFieldListRequest: CustomField) => {
		return async (
			dispatch: Dispatch
		): Promise<DeleteCustomFieldListSuccessfulAction> => {
			try {
				const deleteCustomFieldListResponse =
					await deleteCustomFieldAsync(deleteCustomFieldListRequest);
				const deleteCustomFieldListSuccessfulAction: DeleteCustomFieldListSuccessfulAction =
					{
						type: CustomFieldsOperationActionTypes.DELETE_CUSTOM_FIELD_LIST_SUCCESSFUL,
						message: deleteCustomFieldListResponse
					};
				return dispatch(deleteCustomFieldListSuccessfulAction);
			} catch (error) {
				console.error(error);
				throw error;
			}
		};
	},
	addCustomField: (addCustomFieldListRequest: CustomFieldFormValues) => {
		return async (
			dispatch: Dispatch
		): Promise<AddCustomFieldListSuccessfulAction> => {
			try {
				const addCustomFieldListResponse = await addCustomField(
					addCustomFieldListRequest
				);
				const addCustomFieldListSuccessfulAction: AddCustomFieldListSuccessfulAction =
					{
						type: CustomFieldsOperationActionTypes.ADD_CUSTOM_FIELD_LIST_SUCCESSFUL,
						addCustomField: addCustomFieldListResponse,
						entityType: addCustomFieldListRequest.entity_type
					};
				return dispatch(addCustomFieldListSuccessfulAction);
			} catch (error) {
				console.error(error);
				throw error;
			}
		};
	},
	editCustomField: (editCustomFieldListRequest: CustomFieldFormValues) => {
		return async (
			dispatch: Dispatch
		): Promise<EditCustomFieldListSuccessfulAction> => {
			try {
				const editCustomFieldListResponse = await editCustomField(
					editCustomFieldListRequest
				);
				const editCustomFieldListSuccessfulAction: EditCustomFieldListSuccessfulAction =
					{
						type: CustomFieldsOperationActionTypes.EDIT_CUSTOM_FIELD_LIST_SUCCESSFUL,
						editCustomField: editCustomFieldListResponse
					};
				return dispatch(editCustomFieldListSuccessfulAction);
			} catch (error) {
				console.error(error);
				throw error;
			}
		};
	},
	resetCustomFields: () => {
		return async (
			dispatch: Dispatch
		): Promise<ResetCustomFieldListSuccessfulAction> => {
			const customFieldsResetAction: ResetCustomFieldListSuccessfulAction =
				{
					type: CustomFieldsOperationActionTypes.RESET_CUSTOM_FIELD_LIST_SUCCESSFUL
				};
			return dispatch(customFieldsResetAction);
		};
	}
};
