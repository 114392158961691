'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../../node_modules/tslib/tslib.es6.mjs.js');
var index$2 = require('../../store/actions/SalesActivity/index.js');
var contracts = require('@zomentum/contracts');
var useRefreshSalesActivity = require('./useRefreshSalesActivity.js');
var index$1 = require('../../store/index.js');
var React = require('react');
var index = require('../../store/context/ParentContext/index.js');

var useHandleEditSalesActivity = function useHandleEditSalesActivity() {
  var parentContext = React.useContext(index.ParentContext);
  var currentActiveOpportunity = parentContext.opportunity.currentActiveOpportunity;
  var companyUserList = parentContext.user.companyUserList;
  var userAllSalesActivities = index$1.useSalesActivityModuleSelector(function (state) {
    return {
      userAllSalesActivities: state.salesActivity.userAllSalesActivities
    };
  }).userAllSalesActivities;
  var dispatch = index$1.useSalesActivityModuleDispatch();
  // const location = useLocation();
  var refreshSalesActivity = useRefreshSalesActivity["default"]();
  var fetchUpdatedOpportunities = function fetchUpdatedOpportunities(opportunityId) {
    var _a;
    parentContext.opportunity.syncKanbanOpportunityDataById((_a = opportunityId !== null && opportunityId !== void 0 ? opportunityId : currentActiveOpportunity === null || currentActiveOpportunity === void 0 ? void 0 : currentActiveOpportunity.id) !== null && _a !== void 0 ? _a : "");
  };
  var handleEditSalesActivity = function handleEditSalesActivity(salesActivity, opportunityId) {
    return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
      var activity;
      return tslib_es6.__generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!(!!userAllSalesActivities.response.length && window.location.pathname === contracts.ZomentumRoutes.SalesActivityPage)) return [3 /*break*/, 2];
            return [4 /*yield*/, dispatch(index$2.SalesActivityActionCreators.fetchOneSalesActivity(new contracts.GetOneSaleActivityRequest(salesActivity.id, salesActivity.feed_type), companyUserList))];
          case 1:
            activity = _a.sent();
            dispatch(index$2.SalesActivityActionCreators.updateSingleUsersSalesActivity(activity.salesActivity));
            return [3 /*break*/, 3];
          case 2:
            if (window.location.pathname === contracts.ZomentumRoutes.OpportunityList || window.location.pathname === contracts.ZomentumRoutes.VendorOpportunityList) {
              fetchUpdatedOpportunities(opportunityId);
            } else if (window.location.pathname.startsWith("".concat(contracts.ZomentumRoutes.OpportunityList, "/")) || window.location.pathname.startsWith("".concat(contracts.ZomentumRoutes.ClientsList, "/")) || window.location.pathname.startsWith("".concat(contracts.ZomentumRoutes.VendorOpportunityList, "/")) || window.location.pathname.startsWith("".concat(contracts.ZomentumRoutes.VendorClientList, "/")) || window.location.pathname.startsWith("".concat(contracts.ZomentumRoutes.VendorPartnerList, "/"))) {
              refreshSalesActivity();
            }
            _a.label = 3;
          case 3:
            return [2 /*return*/];
        }
      });
    });
  };

  return handleEditSalesActivity;
};

exports["default"] = useHandleEditSalesActivity;
