import { NotificationConfiguration } from "@zomentum/contracts/dist/common";
import {
	ConnectCalendarPostRequest,
	EmailIntegration
} from "@zomentum/contracts/dist/Settings";
import {
	VendorEmailAliasItem,
	EmailAliasRequest,
	UserCalendarResponse,
	AccessAndRefreshTokenResponse
} from "@zomentum/contracts/dist/Users";
import {
	allZeroesGuid,
	includeChildEntities
} from "@zomentum/contracts/dist/Utils";
import {
	AddVendorUserRequest,
	AddVendorAPIUserRequest,
	EditVendorUserRequest,
	GetVendorUserCompanyRequest,
	VendorUserCompany,
	VendorUserData,
	VendorUserIntegration,
	VendorUserIntegrationResponse,
	VendorUserListRequest,
	VendorUserRole
} from "@zomentum/contracts/dist/Vendor/User";
import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest
} from "@zomentum/utils/dist/Service/BackendService";
import axios, { AxiosResponse } from "axios";
import {
	CustomViewsMetadata,
	ListPagePostRequest,
	CustomViewResponse
} from "@zomentum/contracts/dist/UI";
import { ZomentumEntities } from "@zomentum/contracts";
const getVendorUserMetadataURL = "/v2/views/metadata?entity_type={entity_type}";

const getVendorUserDataUrl = `/v1/marketplace/vendor-users/${allZeroesGuid}`;
const getVendorUserCompanyUrl = `/v1/vendors`;
const getVendorUserIntegrationUrl = "/v1/vendors/integrations";

const addVendorUserEmailAliasUrl = "/v1/vendors/emails/alias";
const deleteVendorUserEmailAliasUrl = "/v1/vendors/emails/alias";
const updateVendorUserEmailPreferencesUrl = "/v1/vendors/emails/settings";
const deleteVendorUserEmailIntegrationUrl = "/v1/vendors/emails";
const fetchVendorUserCalendarUrl = "/v1/vendors/calendars";
const connectVendorUserCalendarUrl = "/v1/vendors/calendars/settings";
const getVendorUserListUrl = `/v1/marketplace/vendors/vendor-users`;
const getVendorUserListPostUrl = `/v1/marketplace/vendors/vendor-users/view`;
const enableVendorUserUrl = `/v1/marketplace/vendors/vendor-users/enable/:id`;
const disableVendorUserUrl = `/v1/marketplace/vendors/vendor-users/disable/:id`;
const addVendorUserUrl = `/v1/marketplace/vendors/vendor-users`;
const addVendorAPIUserUrl = `/v1/marketplace/vendors/vendor-users/api_user`;
const editVendorUserUrl = `/v1/marketplace/vendors/vendor-users/:id`;
const fetchAcessAndRefreshTokensUrl = `/v1/marketplace/vendors/vendor-users/access_token/{id}/?regenerate_access_token={regenerate_token}`;

const getVendorUserConfigUrl = "/v1/marketplace/vendors/vendor-users/config";

const getAllVendorUserViewURL = "/v2/views?entity_type={entity_type}";

const resetPasswordUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`;

export const getVendorUserData = async (): Promise<VendorUserData> => {
	try {
		const response: AxiosResponse<VendorUserData> = await axios(
			getRequest(getVendorUserDataUrl, null, true, false, false)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getVendorUserCompany = async (
	getVendorUserCompanyRequest: GetVendorUserCompanyRequest | null
): Promise<VendorUserCompany> => {
	try {
		const includedChildEntities = includeChildEntities(
			getVendorUserCompanyRequest?.included_child_entities
		);
		const url =
			getVendorUserCompanyUrl +
			(!!includedChildEntities?.length
				? "?" + includedChildEntities
				: "");
		const response: AxiosResponse<VendorUserCompany> = await axios(
			getRequest(url, null, true, false, false)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchAccessAndRefreshTokens = async (
	userId: string,
	regenerateToken = false
): Promise<AccessAndRefreshTokenResponse> => {
	try {
		const response = await axios(
			getRequest(
				fetchAcessAndRefreshTokensUrl
					.replace("{id}", userId)
					.replace(
						"{regenerate_token}",
						regenerateToken ? "true" : "false"
					),
				null,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getVendorUserIntegration =
	async (): Promise<VendorUserIntegration> => {
		try {
			const response: AxiosResponse<VendorUserIntegrationResponse> =
				await axios(
					getRequest(
						getVendorUserIntegrationUrl,
						null,
						true,
						false,
						false
					)
				);
			return response.data.integrations;
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const addVendorUserEmailAlias = async (
	addEmailAliasRequest: EmailAliasRequest
): Promise<VendorEmailAliasItem> => {
	try {
		const response: AxiosResponse<VendorEmailAliasItem> = await axios(
			putRequest(
				addVendorUserEmailAliasUrl,
				addEmailAliasRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteVendorUserEmailAlias = async (
	deleteVendorUserEmailAliasRequest: EmailAliasRequest
): Promise<VendorEmailAliasItem> => {
	try {
		const response: AxiosResponse<VendorEmailAliasItem> = await axios(
			deleteRequest(
				deleteVendorUserEmailAliasUrl,
				deleteVendorUserEmailAliasRequest,
				true,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteVendorUserEmailIntegration = async (): Promise<void> => {
	try {
		const response: AxiosResponse<void> = await axios(
			deleteRequest(deleteVendorUserEmailIntegrationUrl, {}, true, false)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchVendorUserCalendars =
	async (): Promise<UserCalendarResponse> => {
		try {
			const response: AxiosResponse<UserCalendarResponse> = await axios(
				getRequest(fetchVendorUserCalendarUrl, null, true, false, false)
			);
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const connectVendorUserCalendar = async (
	connectVendorUserCalendarRequest: ConnectCalendarPostRequest
) => {
	try {
		const response: AxiosResponse<any> = await axios(
			postRequest(
				connectVendorUserCalendarUrl,
				connectVendorUserCalendarRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateVendorUserEmailSettings = async (
	vendorUserEmailIntegrationRequest: EmailIntegration
): Promise<EmailIntegration> => {
	try {
		const response: AxiosResponse<EmailIntegration> = await axios(
			putRequest(
				updateVendorUserEmailPreferencesUrl,
				vendorUserEmailIntegrationRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getVendorUserList = async (
	limit?: number
): Promise<VendorUserListRequest> => {
	try {
		const response: AxiosResponse<VendorUserListRequest> = await axios(
			getRequest(
				`${getVendorUserListUrl}${limit ? `?limit=${limit}` : ""}`,
				null,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getVendorUserListPost = async (
	vendorUserRequest: ListPagePostRequest
): Promise<VendorUserListRequest> => {
	try {
		const response: AxiosResponse<VendorUserListRequest> = await axios(
			postRequest(
				getVendorUserListPostUrl,
				vendorUserRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const disableVendorUser = async (
	userId: string
): Promise<VendorUserData> => {
	try {
		const response: AxiosResponse<VendorUserData> = await axios(
			putRequest(
				disableVendorUserUrl.replace(":id", userId),
				{},
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const enableVendorUser = async (
	userId: string,
	role: VendorUserRole
): Promise<VendorUserData> => {
	try {
		const response: AxiosResponse<VendorUserData> = await axios(
			putRequest(
				enableVendorUserUrl.replace(":id", userId),
				{ role },
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const editVendorUser = async (
	editVendorUserRequest: EditVendorUserRequest
): Promise<VendorUserData> => {
	try {
		const response: AxiosResponse<VendorUserData> = await axios(
			putRequest(
				editVendorUserUrl.replace(":id", editVendorUserRequest.id),
				editVendorUserRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
export const addVendorUser = async (
	addVendorUserRequest: AddVendorUserRequest
): Promise<VendorUserData> => {
	try {
		const response: AxiosResponse<VendorUserData> = await axios(
			postRequest(
				addVendorUserUrl,
				addVendorUserRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const addVendorAPIUser = async (
	addVendorAPIUserRequest: AddVendorAPIUserRequest
): Promise<VendorUserData> => {
	try {
		const response: AxiosResponse<VendorUserData> = await axios(
			postRequest(
				addVendorAPIUserUrl,
				addVendorAPIUserRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getVendorConfigurationSettings =
	async (): Promise<NotificationConfiguration> => {
		try {
			const response: AxiosResponse<NotificationConfiguration> =
				await axios(
					getRequest(getVendorUserConfigUrl, null, true, false, false)
				);
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const getVendorUserMetadata = async (): Promise<CustomViewsMetadata> => {
	try {
		const response: AxiosResponse<CustomViewsMetadata> = await axios(
			getRequest(
				getVendorUserMetadataURL.replace(
					"{entity_type}",
					ZomentumEntities.VendorUser
				),
				null,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const getAllVendorUserViews = async (): Promise<CustomViewResponse> => {
	try {
		const response: AxiosResponse<CustomViewResponse> = await axios(
			getRequest(
				getAllVendorUserViewURL.replace(
					"{entity_type}",
					ZomentumEntities.VendorUser
				),
				null,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const resetPassword = async (email: string): Promise<void> => {
	try {
		await axios.post(
			resetPasswordUrl,
			{
				client_id: process.env.REACT_APP_AUTH0_CLIENT,
				email,
				connection: "Partner-Align"
			},
			{
				headers: {
					"Content-Type": "application/json"
				}
			}
		);
	} catch (error) {
		return Promise.reject(error);
	}
};
