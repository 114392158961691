import { getZomentumEntityDisplayValue } from "@zomentum/contracts";
import useMobileView from "@zomentum/hooks/dist/hooks/useMobileView";
import ZButton from "@zomentum/atoms/dist/ZButton";
import ZTypography from "@zomentum/atoms/dist/ZTypography";
import ZModal from "@zomentum/atoms/dist/ZModal";
import React from "react";
import "./index.less";
import { IBulkActionBarProps } from "./interface";

const ZBulkActionsBar: React.FC<IBulkActionBarProps> = props => {
	const handleRecordSelection = (value: boolean) => {
		if (props.handleRecordSelection) props.handleRecordSelection(value);
	};

	const confirmDelete = () => {
		ZModal.confirm({
			title: `Do you want to delete the selected ${props.selectedRecords}
			${getZomentumEntityDisplayValue(props.entity, !props.selectedRecords)} ?`,
			onOk: props?.handleDelete,
			okType: "danger",
			okText: "Delete"
		});
	};

	const isMobileView = useMobileView();

	return (
		<div className={`bulk-action-bar ${isMobileView ? "mobile-view" : ""}`}>
			{!props.hideDefaultActionButtons && (
				<div className="bulk-action-bar-buttons">
					{props.featureAccess?.bulkUpdate && (
						<ZButton
							className="mr-2"
							onClick={props.openBulkUpdateDrawer}
						>
							Bulk Update
						</ZButton>
					)}
					{props?.handleDelete && props.featureAccess?.bulkDelete && (
						<ZButton danger onClick={confirmDelete}>
							Delete
						</ZButton>
					)}
				</div>
			)}
			{props.customActionButtons && (
				<div className="flex mr-4 items-center">
					{props.customActionButtons}
				</div>
			)}

			{props.showInfoText && (
				<div className="bulk-action-bar-infotext">
					{props.selectedRecords === props.totalRecords &&
					!props.selectAllRecords ? (
						<ZTypography.ZText>
							{props.selectedRecords}{" "}
							{getZomentumEntityDisplayValue(
								props.entity,
								!props.selectedRecords
							)}{" "}
							selected.
						</ZTypography.ZText>
					) : props.selectedRecords === props.totalRecords &&
					  props.selectAllRecords ? (
						<ZTypography.ZText>
							{props.totalRecords}{" "}
							{getZomentumEntityDisplayValue(
								props.entity,
								!props.totalRecords
							)}{" "}
							selected.{" "}
							<span onClick={() => handleRecordSelection(false)}>
								<ZTypography.ZLink>Undo</ZTypography.ZLink>
							</span>
						</ZTypography.ZText>
					) : (
						<ZTypography.ZText>
							{props.selectedRecords}{" "}
							{getZomentumEntityDisplayValue(
								props.entity,
								!props.selectedRecords
							)}{" "}
							selected.{" "}
							<span onClick={() => handleRecordSelection(true)}>
								<ZTypography.ZLink>
									Select all {props.totalRecords}{" "}
									{getZomentumEntityDisplayValue(
										props.entity,
										!props.totalRecords
									)}
								</ZTypography.ZLink>
							</span>
						</ZTypography.ZText>
					)}
				</div>
			)}
		</div>
	);
};

export default ZBulkActionsBar;
