import { Reducer } from "redux";

import { RecentActivityFeed } from "@zomentum/contracts/dist/Others/RecentActivityFeed";

import {
	RecentActivityFeedActionTypes,
	RecentActivityFeedActions
} from "../../../Actions/Others/RecentActivityFeed";

export interface RecentActivityFeedState {
	readonly isLoading: boolean;
	readonly recentActivityFeedList: RecentActivityFeed[];
	readonly emailThreadDrawerOpen: boolean;
	readonly addCommentDrawerOpen: boolean;
	readonly editCommentDrawerOpen: boolean;
}

export const initialRecentActivityFeedState: RecentActivityFeedState = {
	isLoading: false,
	recentActivityFeedList: new Array<RecentActivityFeed>(),
	emailThreadDrawerOpen: false,
	addCommentDrawerOpen: false,
	editCommentDrawerOpen: false
};

export const RecentActivityFeedReducer: Reducer<
	RecentActivityFeedState,
	RecentActivityFeedActions
> = (
	state: RecentActivityFeedState = initialRecentActivityFeedState,
	action: RecentActivityFeedActions
): RecentActivityFeedState => {
	switch (action.type) {
		case RecentActivityFeedActionTypes.SHOW_RECENT_ACTIVITY_FEED_LOADER: {
			return {
				...state,
				isLoading: true
			};
		}
		case RecentActivityFeedActionTypes.HIDE_RECENT_ACTIVITY_FEED_LOADER: {
			return {
				...state,
				isLoading: false
			};
		}
		case RecentActivityFeedActionTypes.RECENT_ACTIVITY_FEED_REQUESTED: {
			return state;
		}
		case RecentActivityFeedActionTypes.RECENT_ACTIVITY_FEED_SUCCESSFUL: {
			return {
				...state,
				recentActivityFeedList: action.recentActivityFeed
			};
		}
		case RecentActivityFeedActionTypes.RECENT_ACTIVITY_FEED_FAILED: {
			return state;
		}
		case RecentActivityFeedActionTypes.RECENT_ACTIVITY_FEED_RESET: {
			return {
				...state,
				recentActivityFeedList: new Array<RecentActivityFeed>()
			};
		}
		case RecentActivityFeedActionTypes.DELETE_COMMENT_REQUESTED: {
			return state;
		}
		case RecentActivityFeedActionTypes.DELETE_COMMENT_SUCCESSFUL: {
			return {
				...state,
				recentActivityFeedList: state.recentActivityFeedList.filter(
					comment => comment.id !== action.deleteComment.id
				)
			};
		}
		case RecentActivityFeedActionTypes.DELETE_COMMENT_FAILED: {
			return state;
		}
		case RecentActivityFeedActionTypes.ADD_COMMENT_REQUESTED: {
			return state;
		}
		case RecentActivityFeedActionTypes.ADD_COMMENT_SUCCESSFUL: {
			return {
				...state,
				recentActivityFeedList: [action.addComment].concat(
					state.recentActivityFeedList
				)
			};
		}
		case RecentActivityFeedActionTypes.ADD_COMMENT_FAILED: {
			return state;
		}
		case RecentActivityFeedActionTypes.EDIT_COMMENT_REQUESTED: {
			return state;
		}
		case RecentActivityFeedActionTypes.EDIT_COMMENT_SUCCESSFUL: {
			const recentActivityFeedList = state.recentActivityFeedList.filter(
				comment => comment.id !== action.editComment.id
			);
			const editCommentIndex = state.recentActivityFeedList.findIndex(
				comment => comment.id === action.editComment.id
			);
			if (editCommentIndex > -1) {
				recentActivityFeedList.splice(
					editCommentIndex,
					0,
					action.editComment
				);
			}
			return {
				...state,
				recentActivityFeedList
			};
		}
		case RecentActivityFeedActionTypes.EDIT_COMMENT_FAILED: {
			return state;
		}
		case RecentActivityFeedActionTypes.EMAIL_THREAD_DRAWER_OPENED: {
			return {
				...state,
				emailThreadDrawerOpen: true
			};
		}
		case RecentActivityFeedActionTypes.EMAIL_THREAD_DRAWER_CLOSED: {
			return {
				...state,
				emailThreadDrawerOpen: false
			};
		}
		case RecentActivityFeedActionTypes.ADD_COMMENT_DRAWER_OPENED: {
			return {
				...state,
				addCommentDrawerOpen: true
			};
		}
		case RecentActivityFeedActionTypes.ADD_COMMENT_DRAWER_CLOSED: {
			return {
				...state,
				addCommentDrawerOpen: false
			};
		}
		case RecentActivityFeedActionTypes.EDIT_COMMENT_DRAWER_OPENED: {
			return {
				...state,
				editCommentDrawerOpen: true
			};
		}
		case RecentActivityFeedActionTypes.EDIT_COMMENT_DRAWER_CLOSED: {
			return {
				...state,
				editCommentDrawerOpen: false
			};
		}
		default: {
			return state;
		}
	}
};

export default RecentActivityFeedReducer;
