import { Action, ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { GlobalState } from "@/Reducers/interface";
import {
	GlobalSearchEntityObj,
	GlobalSearchRequest
} from "@zomentum/contracts/dist/Others/GlobalSearch";
import { getGlobalSearch } from "@zomentum/utils/dist/Service/GlobalSearch";

export enum GlobalSearchActionTypes {
	SHOW_GLOBAL_SEARCH_LOADER = "SHOW_GLOBAL_SEARCH_LOADER",
	HIDE_GLOBAL_SEARCH_LOADER = "HIDE_GLOBAL_SEARCH_LOADER",
	GLOBAL_SEARCH_REQUESTED = "GLOBAL_SEARCH_REQUESTED",
	GLOBAL_SEARCH_FAILED = "GLOBAL_SEARCH_FAILED",
	GLOBAL_SEARCH_SUCCESSFUL = "GLOBAL_SEARCH_SUCCESSFUL",
	GLOBAL_SEARCH_RESET = "GLOBAL_SEARCH_RESET"
}

export type ShowGlobalSearchLoaderAction =
	Action<GlobalSearchActionTypes.SHOW_GLOBAL_SEARCH_LOADER>;
export type HideGlobalSearchLoaderAction =
	Action<GlobalSearchActionTypes.HIDE_GLOBAL_SEARCH_LOADER>;
export type GlobalSearchRequestedAction =
	Action<GlobalSearchActionTypes.GLOBAL_SEARCH_REQUESTED>;
export type GlobalSearchFailedAction =
	Action<GlobalSearchActionTypes.GLOBAL_SEARCH_FAILED>;
export interface GlobalSearchSuccessfulAction
	extends Action<GlobalSearchActionTypes.GLOBAL_SEARCH_SUCCESSFUL> {
	readonly entityList: GlobalSearchEntityObj[];
	readonly nextCursor: string | null;
	readonly loadMoreAction: boolean;
}
export type GlobalSearchResetAction =
	Action<GlobalSearchActionTypes.GLOBAL_SEARCH_RESET>;

export type GlobalSearchActions =
	| ShowGlobalSearchLoaderAction
	| HideGlobalSearchLoaderAction
	| GlobalSearchRequestedAction
	| GlobalSearchFailedAction
	| GlobalSearchSuccessfulAction
	| GlobalSearchResetAction;

export interface GlobalSearchDispatch {
	showGlobalSearchLoader: ActionCreator<
		ThunkAction<
			ShowGlobalSearchLoaderAction,
			GlobalState,
			null,
			ShowGlobalSearchLoaderAction
		>
	>;
	hideGlobalSearchLoader: ActionCreator<
		ThunkAction<
			HideGlobalSearchLoaderAction,
			GlobalState,
			null,
			HideGlobalSearchLoaderAction
		>
	>;
	getGlobalSearch: ActionCreator<
		ThunkAction<
			Promise<GlobalSearchSuccessfulAction>,
			GlobalState,
			GlobalSearchRequest,
			GlobalSearchSuccessfulAction
		>
	>;
	resetGlobalSearch: ActionCreator<
		ThunkAction<
			GlobalSearchResetAction,
			GlobalState,
			null,
			GlobalSearchResetAction
		>
	>;
}

export const GlobalSearchActionCreators: GlobalSearchDispatch = {
	showGlobalSearchLoader: () => {
		return (dispatch: Dispatch): ShowGlobalSearchLoaderAction => {
			const showGlobalSearchLoaderAction: ShowGlobalSearchLoaderAction = {
				type: GlobalSearchActionTypes.SHOW_GLOBAL_SEARCH_LOADER
			};
			return dispatch(showGlobalSearchLoaderAction);
		};
	},
	hideGlobalSearchLoader: () => {
		return (dispatch: Dispatch): HideGlobalSearchLoaderAction => {
			const hideGlobalSearchLoaderAction: HideGlobalSearchLoaderAction = {
				type: GlobalSearchActionTypes.HIDE_GLOBAL_SEARCH_LOADER
			};
			return dispatch(hideGlobalSearchLoaderAction);
		};
	},
	getGlobalSearch: (globalSearchRequest: GlobalSearchRequest) => {
		return async (
			dispatch: Dispatch
		): Promise<GlobalSearchSuccessfulAction> => {
			const globalSearchRequestedAction: GlobalSearchRequestedAction = {
				type: GlobalSearchActionTypes.GLOBAL_SEARCH_REQUESTED
			};
			dispatch(globalSearchRequestedAction);
			try {
				const globalSearchResponse = await getGlobalSearch(
					globalSearchRequest
				);
				const globalSearchSuccessfulAction: GlobalSearchSuccessfulAction =
					{
						type: GlobalSearchActionTypes.GLOBAL_SEARCH_SUCCESSFUL,
						entityList: globalSearchResponse.data,
						nextCursor: globalSearchResponse.next
							? globalSearchResponse.next
							: null,
						loadMoreAction: !!globalSearchRequest.nextCursor
					};
				return dispatch(globalSearchSuccessfulAction);
			} catch (error) {
				console.error(error);
				const globalSearchFailedAction: GlobalSearchFailedAction = {
					type: GlobalSearchActionTypes.GLOBAL_SEARCH_FAILED
				};
				dispatch(globalSearchFailedAction);

				return Promise.reject(error);
			}
		};
	},
	resetGlobalSearch: () => {
		return (dispatch: Dispatch): GlobalSearchResetAction => {
			const globalSearchResetAction: GlobalSearchResetAction = {
				type: GlobalSearchActionTypes.GLOBAL_SEARCH_RESET
			};
			return dispatch(globalSearchResetAction);
		};
	}
};

export default GlobalSearchActionCreators;
