'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cacheConstant = {
  userData: "USER_DATA",
  configurationSettings: "CONFIGURATION_SETTINGS",
  companyUserList: "COMPANY_USER_LIST",
  userCompany: "USER_COMPANY",
  pdfUrl: "PDF_URL"
};
var AppCache = /** @class */function () {
  function AppCache() {
    var _this = this;
    this.data = {};
    this.updateCacheFromLocalStorage = function () {
      _this.data = Object.keys(cacheConstant).reduce(function (total, key) {
        var currKey = key;
        var cacheDataString = localStorage.getItem(cacheConstant[currKey]);
        if (!!!cacheDataString) {
          return total;
        }
        var cacheData = null;
        try {
          cacheData = !!cacheDataString && JSON.parse(cacheDataString) || null;
        } catch (error) {
          console.error(error);
        }
        total[currKey] = cacheData;
        return total;
      }, {});
    };
    this.updateReduxKey = function (key, data) {
      if (!data) {
        localStorage.removeItem(cacheConstant[key]);
      }
      var stringifiedData = JSON.stringify(data);
      if (!(stringifiedData === null || stringifiedData === void 0 ? void 0 : stringifiedData.length)) {
        localStorage.removeItem(cacheConstant[key]);
      }
      localStorage.setItem(cacheConstant[key], stringifiedData);
      _this.updateCacheFromLocalStorage();
    };
    this.get = function (key, parseJson) {
      if (parseJson === void 0) {
        parseJson = false;
      }
      var data = localStorage.getItem(key) || null;
      if (data && parseJson) {
        try {
          data = JSON.parse(data);
        } catch (error) {
          console.error("Error: ".concat(error, " :: key: ").concat(key, " :: data: ").concat(data));
        }
      }
      return data;
    };
    this.save = function (key, data) {
      localStorage.setItem(key, JSON.stringify(data));
    };
    this.deleteItem = function (key) {
      localStorage.removeItem(key);
    };
    this.deleteAll = function () {
      localStorage.clear();
    };
    this.updateCacheFromLocalStorage();
  }
  return AppCache;
}();
var cache = new AppCache();

exports.cacheConstant = cacheConstant;
exports["default"] = cache;
