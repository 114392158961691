import LoginActionCreators from "@/Actions/Login";
import VendorUsersActionCreators from "@/Actions/Vendor/User";
import {
	cache,
	DefaultPartnerAlignPrivateRoute,
	VendorUserRole,
	ZomentumEntities,
	ZomentumLoginRoutes,
	ZomentumRoutes,
	ZomentumStorageKeys,
	ZomentumWindow
} from "@zomentum/contracts";
import { useComponentDidUpdate, useNotForPdfView } from "@zomentum/hooks";
import useDispatch from "@/Components/Utils/useDispatch";
import { AuthToken } from "@zomentum/contracts/dist/Login";
import {
	isDocumentCustomerViewUrl,
	isDocumentPdfViewUrl,
	isVerifyPaymentViewUrl
} from "@zomentum/contracts/dist/Utils";
import { useAuth0 } from "@auth0/auth0-react";
import { setUser as SentrySetUser } from "@sentry/react";
import { mixpanel } from "@zomentum/utils/dist/Mixpanel";
import publicIp from "public-ip";
import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

// import css was conflicting with antd so loading package css here
import "@zomentum/atoms/dist/index.css";
import "@zomentum/molecules/dist/index.css";
import "@zomentum/organisms/dist/index.css";
import { GlobalState } from "@/Reducers/interface";
import NotificationFeedActionCreators from "@/Actions/Others/NotificationFeed";
import UsersActionCreators from "@/Actions/User";
import { CustomFieldsActionCreators } from "@/Actions/CustomFields";

declare let window: ZomentumWindow;

type Props = RouteComponentProps;

const LoadThirdPartyScripts: React.FC<Props> = props => {
	const { notForPdfView } = useNotForPdfView();
	const dispatch = useDispatch();
	const auth0 = useAuth0();
	const { vendorUserData, vendorUserCompany, isLoggedIn } = useSelector(
		(state: GlobalState) => ({
			vendorUserData: state.vendorUser.vendorUserData,
			isLoggedIn: state.login.isLoggedIn,
			vendorUserCompany: state.vendorUser.vendorUserCompany
		}),
		shallowEqual
	);

	const preloadCurrentApiUrl = () => {
		if (process.env.REACT_APP_API_HOST) {
			const res = document.createElement("link");
			res.rel = "preconnect";
			res.as = "href";
			res.href = process.env.REACT_APP_API_HOST;
			document.head.appendChild(res);
		}
	};

	const updateThirdPartyScripts = async () => {
		await updateAuth0();
		updateSentry();
		updateMixPanel();
	};

	const updateMixPanel = () => {
		mixpanel
			?.get_group("group_subdomain", vendorUserCompany?.sub_domain)
			.set({
				number_of_active_users:
					vendorUserCompany?.vendor_users?.data.filter(
						user =>
							!user.is_disabled &&
							user.role !== VendorUserRole.VendorApiUserRole
					)?.length || 0,
				number_of_users:
					vendorUserCompany?.vendor_users?.data?.filter(
						user => user.role !== VendorUserRole.VendorApiUserRole
					)?.length || 0
			});
	};

	useComponentDidUpdate(() => {
		if (vendorUserCompany.id && vendorUserData.id) {
			updateMixPanel();
		}
	}, [vendorUserCompany, vendorUserData]);

	const updateSentry = () => {
		try {
			if (!!vendorUserData?.id?.length) {
				SentrySetUser({
					id: vendorUserData.id,
					email: vendorUserData.email,
					username: vendorUserData.name.full ?? ""
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const updateAuth0 = async () => {
		const auth0CheckSessionInterval = setInterval(async () => {
			try {
				const token = await auth0.getAccessTokenSilently();
				cache.save(ZomentumStorageKeys.Token, new AuthToken(token));
				dispatch(LoginActionCreators.refreshTokenSuccessful());
			} catch (error) {
				console.error("Refresh token is failing with error", error);
				clearInterval(auth0CheckSessionInterval);
				dispatch(LoginActionCreators.refreshTokenFailed());
			}
		}, 2 * 60 * 60 * 1000);
	};

	const getAllVendorUser = async () => {
		try {
			dispatch(VendorUsersActionCreators.showVendorUserLoader());

			await Promise.all([
				dispatch(VendorUsersActionCreators.getVendorUserData()),
				dispatch(VendorUsersActionCreators.getVendorUserCompany()),
				dispatch(VendorUsersActionCreators.getVendorUserIntegration()),
				dispatch(VendorUsersActionCreators.getVendorUserConfig()),
				dispatch(UsersActionCreators.fetchAllEmailSignatures())
			]);
		} catch (error) {
			console.error(error);
		} finally {
			dispatch(VendorUsersActionCreators.hideVendorUserLoader());
		}
	};

	const getNotificationData = async () => {
		try {
			dispatch(
				NotificationFeedActionCreators.showNotificationFeedLoader()
			);

			await dispatch(VendorUsersActionCreators.getVendorUserConfig());

			const feedActionResult = await dispatch(
				NotificationFeedActionCreators.getNotificationFeed()
			);
			const notifications = feedActionResult.notificationFeedActivityList;

			await dispatch(
				NotificationFeedActionCreators.subscribeNotificationFeedActivity()
			);

			const isInitialSyncEventRelated = notifications.some(
				n => n.title === "Initial sync event"
			);

			if (isInitialSyncEventRelated) {
				await dispatch(
					VendorUsersActionCreators.getVendorUserCompany()
				);
			}
		} catch (error) {
			console.error(error);
		} finally {
			dispatch(
				NotificationFeedActionCreators.hideNotificationFeedLoader()
			);
		}
	};

	const getCustomFieldsData = async () => {
		try {
			const customFieldsEntities = [
				ZomentumEntities.VendorOpportunity,
				ZomentumEntities.VendorPartner,
				ZomentumEntities.VendorClient,
				ZomentumEntities.VendorClientContact,
				ZomentumEntities.MarketplaceSKU,
				ZomentumEntities.SalesLogCall,
				ZomentumEntities.VendorTemplate
			];
			await Promise.all(
				customFieldsEntities.map(customFieldEntity =>
					dispatch(
						CustomFieldsActionCreators.getCustomFields(
							customFieldEntity
						)
					)
				)
			);
			await dispatch(CustomFieldsActionCreators.countCustomFields());
		} catch (error) {
			console.error(error);
		}
	};

	const initializeDashboard = async () => {
		const authToken = cache.get(
			ZomentumStorageKeys.Token,
			true
		) as AuthToken;
		if ((authToken && authToken.token) || isLoggedIn) {
			try {
				await notForPdfView(getCustomFieldsData);
				await notForPdfView(getAllVendorUser);

				await Promise.all([
					notForPdfView(getNotificationData),
					notForPdfView(updateThirdPartyScripts)
				]);

				const userIpAddress = await publicIp.v4();
				cache.save(ZomentumStorageKeys.UserIp, userIpAddress);

				dispatch(LoginActionCreators.loginFromSession());
				if (ZomentumLoginRoutes.indexOf(props.location.pathname) > -1) {
					props.history.push(DefaultPartnerAlignPrivateRoute);
				}
			} catch (error) {
				console.error(error);
			}
		} else {
			const isPreviewRoute =
				isDocumentCustomerViewUrl(props.location.pathname) ||
				isDocumentPdfViewUrl(props.location.pathname) ||
				isVerifyPaymentViewUrl(props.location.pathname);

			if (
				ZomentumLoginRoutes.indexOf(props.location.pathname) === -1 &&
				props.location.pathname !== ZomentumRoutes.Error &&
				!isPreviewRoute &&
				props.location.pathname !== "/" &&
				props.location.pathname !== ""
			) {
				cache.deleteAll();
				props.history.push(ZomentumRoutes.Login);
			}
		}
	};

	const updateFreshDesk = () => {
		if (vendorUserData) {
			window.FreshworksWidget("identify", "ticketForm", {
				name: vendorUserData.name.full,
				email: vendorUserData.email
			});
		}
	};

	/*
	TODO: implement checkTimeOffset change later by Harmeet

	const checkTimeOffset = () => {
		const today = new Date();
		const userOffset = today.getTimezoneOffset();
		const serverOffset = userCompany?.settings?.time_zone_offset;
		const newTimezone: any = filter(timezones, timezone => {
			if (timezone.offset === userOffset) return timezone;
			return;
		});
		const newTimezoneName = newTimezone[0].name;
		const key = "timezoneAlert";
		const btn = (
			<div>
				<Button
					type="default"
					onClick={() => {
						notification.close("timezoneAlert");
					}}
				>
					Remind me Later
				</Button>
				<Tooltip title={`Change to ${newTimezoneName}`}>
					<Button
						type="primary"
						onClick={() => {
							handleTimezoneChange(userOffset);
						}}
						className="margin-left-8px"
					>
						Update Now
					</Button>
				</Tooltip>
			</div>
		);
		if (
			userOffset !== serverOffset &&
			userData?.user_settings?.time_zone_display_browser_pop_up
		) {
			notification.open({
				message: "Attention",
				description:
					"Your detected browser timezone does not match the one set in your profile. To ensure best experience, please update your timezone",
				duration: 10,
				btn,
				key,
				type: "warning",
				placement: "bottomRight",
				onClose: handleTimezoneLater
			});
		}
	};
	*/

	/*
	TODO: implement timezone change later by Harmeet

	const handleTimezoneChange = async (offset: number) => {
		const newTimezone: any = filter(timezones, timezone => {
			if (timezone.offset === offset) return timezone;
			return;
		});
		const value = newTimezone[0].name;
		try {
			const editCompanyUserRequest = userCompany;
			editCompanyUserRequest.settings.time_zone = value;
			await dispatch(
				UsersActionCreators.editUserCompany(editCompanyUserRequest)
			);
			ZMessage.success("Timezone has been changed to " + value);
		} catch (error) {
			console.error(error);
		} finally {
			notification.close("timezoneAlert");
		}
	};

	const handleTimezoneLater = async () => {
		notification.close("timezoneAlert");
		try {
			const editCompanyUserRequest = userData;
			editCompanyUserRequest.user_settings.time_zone_display_browser_pop_up = false;
			await dispatch(
				UsersActionCreators.editCurrentCompanyUser(
					editCompanyUserRequest
				)
			);
		} catch (error) {
			console.error(error);
		}
	};
	*/

	useEffect(() => {
		initializeScripts();
	}, []);

	const initializeScripts = async () => {
		const authToken = cache.get(
			ZomentumStorageKeys.Token,
			true
		) as AuthToken;

		if (authToken && authToken.token) {
			// TODO: implement timezone change later by Harmeet
			// checkTimeOffset();

			await initializeDashboard();
		}

		preloadCurrentApiUrl();
		initializeTinyMCE();
	};

	const initializeTinyMCE = () => {
		if (!process.env.REACT_APP_TINY_MCE) {
			throw new Error("Mandatory tiny mce env variable missing!");
		}
		const existingScript = document.getElementById("tinymceinitialization");
		if (!existingScript) {
			const script: HTMLScriptElement = document.createElement("script");
			script.src = "https://tinymce.zomentum.com/tinymce.min.js";
			script.referrerPolicy = "origin";
			script.id = "tinymceinitialization";
			document.head.appendChild(script);
		}
	};

	useEffect(() => {
		updateFreshDesk();
	}, [vendorUserData]);

	// Call the initializeDashboard when the token is set to localStorage
	useEffect(() => {
		const initDashBoard = () => {
			initializeDashboard();
		};
		document.addEventListener("InitializeDashboard", initDashBoard);
		return () => {
			document.removeEventListener("initializeDashboard", initDashBoard);
		};
	}, []);

	useComponentDidUpdate(() => {
		if (
			props.location.pathname !== ZomentumRoutes.Error &&
			props.location.pathname !== ZomentumRoutes.Authorize &&
			!vendorUserData.id
		) {
			initializeDashboard();
		}
	}, [props.location.pathname]);

	return null;
};

export default withRouter(LoadThirdPartyScripts);
