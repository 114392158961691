import { Dispatch, Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";

import {
	ZomentumEntityRequest,
	DefaultErrorMessage
} from "@zomentum/contracts";
import { GlobalState } from "@/Reducers/interface";
import {
	RecentActivityFeed,
	AddRecentActivityFeedCommentRequest
} from "@zomentum/contracts/dist/Others/RecentActivityFeed";

import {
	getRecentActivityFeed,
	deleteComment,
	addComment,
	editComment
} from "../../../Services/Others/RecentActivityFeed";

export enum RecentActivityFeedActionTypes {
	SHOW_RECENT_ACTIVITY_FEED_LOADER = "SHOW_RECENT_ACTIVITY_FEED_LOADER",
	HIDE_RECENT_ACTIVITY_FEED_LOADER = "HIDE_RECENT_ACTIVITY_FEED_LOADER",
	RECENT_ACTIVITY_FEED_REQUESTED = "RECENT_ACTIVITY_FEED_REQUESTED",
	RECENT_ACTIVITY_FEED_SUCCESSFUL = "RECENT_ACTIVITY_FEED_SUCCESSFUL",
	RECENT_ACTIVITY_FEED_FAILED = "RECENT_ACTIVITY_FEED_FAILED",
	RECENT_ACTIVITY_FEED_RESET = "RECENT_ACTIVITY_FEED_RESET",
	DELETE_COMMENT_REQUESTED = "DELETE_COMMENT_REQUESTED",
	DELETE_COMMENT_SUCCESSFUL = "DELETE_COMMENT_SUCCESSFUL",
	DELETE_COMMENT_FAILED = "DELETE_COMMENT_FAILED",
	ADD_COMMENT_REQUESTED = "ADD_COMMENT_REQUESTED",
	ADD_COMMENT_SUCCESSFUL = "ADD_COMMENT_SUCCESSFUL",
	ADD_COMMENT_FAILED = "ADD_COMMENT_FAILED",
	EDIT_COMMENT_REQUESTED = "EDIT_COMMENT_REQUESTED",
	EDIT_COMMENT_SUCCESSFUL = "EDIT_COMMENT_SUCCESSFUL",
	EDIT_COMMENT_FAILED = "EDIT_COMMENT_FAILED",
	EMAIL_THREAD_DRAWER_OPENED = "EMAIL_THREAD_DRAWER_OPENED",
	EMAIL_THREAD_DRAWER_CLOSED = "EMAIL_THREAD_DRAWER_CLOSED",
	ADD_COMMENT_DRAWER_OPENED = "ADD_COMMENT_DRAWER_OPENED",
	ADD_COMMENT_DRAWER_CLOSED = "ADD_COMMENT_DRAWER_CLOSED",
	EDIT_COMMENT_DRAWER_OPENED = "EDIT_COMMENT_DRAWER_OPENED",
	EDIT_COMMENT_DRAWER_CLOSED = "EDIT_COMMENT_DRAWER_CLOSED"
}

export type ShowRecentActivityFeedLoaderAction =
	Action<RecentActivityFeedActionTypes.SHOW_RECENT_ACTIVITY_FEED_LOADER>;
export type HideRecentActivityFeedLoaderAction =
	Action<RecentActivityFeedActionTypes.HIDE_RECENT_ACTIVITY_FEED_LOADER>;
export type RecentActivityFeedRequestedAction =
	Action<RecentActivityFeedActionTypes.RECENT_ACTIVITY_FEED_REQUESTED>;
export interface RecentActivityFeedSuccessfulAction
	extends Action<RecentActivityFeedActionTypes.RECENT_ACTIVITY_FEED_SUCCESSFUL> {
	readonly recentActivityFeed: RecentActivityFeed[];
}
export type RecentActivityFeedFailedAction =
	Action<RecentActivityFeedActionTypes.RECENT_ACTIVITY_FEED_FAILED>;
export type RecentActivityFeedResetAction =
	Action<RecentActivityFeedActionTypes.RECENT_ACTIVITY_FEED_RESET>;
export type DeleteCommentRequestedAction =
	Action<RecentActivityFeedActionTypes.DELETE_COMMENT_REQUESTED>;
export interface DeleteCommentSuccessfulAction
	extends Action<RecentActivityFeedActionTypes.DELETE_COMMENT_SUCCESSFUL> {
	readonly deleteComment: RecentActivityFeed;
}
export type DeleteCommentFailedAction =
	Action<RecentActivityFeedActionTypes.DELETE_COMMENT_FAILED>;
export type AddRecentActivityFeedCommentRequestedAction =
	Action<RecentActivityFeedActionTypes.ADD_COMMENT_REQUESTED>;
export interface AddCommentSuccessfulAction
	extends Action<RecentActivityFeedActionTypes.ADD_COMMENT_SUCCESSFUL> {
	readonly addComment: RecentActivityFeed;
}
export type AddCommentFailedAction =
	Action<RecentActivityFeedActionTypes.ADD_COMMENT_FAILED>;
export type EditCommentRequestedAction =
	Action<RecentActivityFeedActionTypes.EDIT_COMMENT_REQUESTED>;
export interface EditCommentSuccessfulAction
	extends Action<RecentActivityFeedActionTypes.EDIT_COMMENT_SUCCESSFUL> {
	readonly editComment: RecentActivityFeed;
}
export type EditCommentFailedAction =
	Action<RecentActivityFeedActionTypes.EDIT_COMMENT_FAILED>;
export type EmailThreadDrawerOpenedAction =
	Action<RecentActivityFeedActionTypes.EMAIL_THREAD_DRAWER_OPENED>;
export type EmailThreadDrawerClosedAction =
	Action<RecentActivityFeedActionTypes.EMAIL_THREAD_DRAWER_CLOSED>;
export type AddCommentDrawerOpenedAction =
	Action<RecentActivityFeedActionTypes.ADD_COMMENT_DRAWER_OPENED>;
export type AddCommentDrawerClosedAction =
	Action<RecentActivityFeedActionTypes.ADD_COMMENT_DRAWER_CLOSED>;
export type EditCommentDrawerOpenedAction =
	Action<RecentActivityFeedActionTypes.EDIT_COMMENT_DRAWER_OPENED>;
export type EditCommentDrawerClosedAction =
	Action<RecentActivityFeedActionTypes.EDIT_COMMENT_DRAWER_CLOSED>;

export type RecentActivityFeedActions =
	| ShowRecentActivityFeedLoaderAction
	| HideRecentActivityFeedLoaderAction
	| RecentActivityFeedRequestedAction
	| RecentActivityFeedSuccessfulAction
	| RecentActivityFeedFailedAction
	| RecentActivityFeedResetAction
	| DeleteCommentRequestedAction
	| DeleteCommentSuccessfulAction
	| DeleteCommentFailedAction
	| AddRecentActivityFeedCommentRequestedAction
	| AddCommentSuccessfulAction
	| AddCommentFailedAction
	| EditCommentRequestedAction
	| EditCommentSuccessfulAction
	| EditCommentFailedAction
	| EmailThreadDrawerOpenedAction
	| EmailThreadDrawerClosedAction
	| AddCommentDrawerOpenedAction
	| AddCommentDrawerClosedAction
	| EditCommentDrawerOpenedAction
	| EditCommentDrawerClosedAction;

export interface RecentActivityFeedDispatch {
	showRecentActivityFeedLoader: ActionCreator<
		ThunkAction<
			ShowRecentActivityFeedLoaderAction,
			GlobalState,
			null,
			ShowRecentActivityFeedLoaderAction
		>
	>;
	hideRecentActivityFeedLoader: ActionCreator<
		ThunkAction<
			HideRecentActivityFeedLoaderAction,
			GlobalState,
			null,
			HideRecentActivityFeedLoaderAction
		>
	>;
	getRecentActivityFeed: ActionCreator<
		ThunkAction<
			Promise<RecentActivityFeedSuccessfulAction>,
			GlobalState,
			ZomentumEntityRequest,
			RecentActivityFeedSuccessfulAction
		>
	>;
	resetRecentActivityFeed: ActionCreator<
		ThunkAction<
			RecentActivityFeedResetAction,
			GlobalState,
			null,
			RecentActivityFeedResetAction
		>
	>;
	deleteComment: ActionCreator<
		ThunkAction<
			Promise<DeleteCommentSuccessfulAction>,
			GlobalState,
			RecentActivityFeed,
			DeleteCommentSuccessfulAction
		>
	>;
	addComment: ActionCreator<
		ThunkAction<
			Promise<AddCommentSuccessfulAction>,
			GlobalState,
			AddRecentActivityFeedCommentRequest,
			AddCommentSuccessfulAction
		>
	>;
	editComment: ActionCreator<
		ThunkAction<
			Promise<EditCommentSuccessfulAction>,
			GlobalState,
			RecentActivityFeed,
			EditCommentSuccessfulAction
		>
	>;
	emailThreadDrawerOpened: ActionCreator<
		ThunkAction<
			EmailThreadDrawerOpenedAction,
			GlobalState,
			null,
			EmailThreadDrawerOpenedAction
		>
	>;
	emailThreadDrawerClosed: ActionCreator<
		ThunkAction<
			EmailThreadDrawerClosedAction,
			GlobalState,
			null,
			EmailThreadDrawerClosedAction
		>
	>;
	addCommentDrawerOpened: ActionCreator<
		ThunkAction<
			AddCommentDrawerOpenedAction,
			GlobalState,
			null,
			AddCommentDrawerOpenedAction
		>
	>;
	addCommentDrawerClosed: ActionCreator<
		ThunkAction<
			AddCommentDrawerClosedAction,
			GlobalState,
			null,
			AddCommentDrawerClosedAction
		>
	>;
	editCommentDrawerOpened: ActionCreator<
		ThunkAction<
			EditCommentDrawerOpenedAction,
			GlobalState,
			null,
			EditCommentDrawerOpenedAction
		>
	>;
	editCommentDrawerClosed: ActionCreator<
		ThunkAction<
			EditCommentDrawerClosedAction,
			GlobalState,
			null,
			EditCommentDrawerClosedAction
		>
	>;
}

export const RecentActivityFeedActionCreators: RecentActivityFeedDispatch = {
	showRecentActivityFeedLoader: () => {
		return (dispatch: Dispatch): ShowRecentActivityFeedLoaderAction => {
			const showRecentActivityFeedLoaderAction: ShowRecentActivityFeedLoaderAction =
				{
					type: RecentActivityFeedActionTypes.SHOW_RECENT_ACTIVITY_FEED_LOADER
				};
			return dispatch(showRecentActivityFeedLoaderAction);
		};
	},
	hideRecentActivityFeedLoader: () => {
		return (dispatch: Dispatch): HideRecentActivityFeedLoaderAction => {
			const hideRecentActivityFeedLoaderAction: HideRecentActivityFeedLoaderAction =
				{
					type: RecentActivityFeedActionTypes.HIDE_RECENT_ACTIVITY_FEED_LOADER
				};
			return dispatch(hideRecentActivityFeedLoaderAction);
		};
	},
	getRecentActivityFeed: (zomentumEntityRequest: ZomentumEntityRequest) => {
		return async (
			dispatch: Dispatch
		): Promise<RecentActivityFeedSuccessfulAction> => {
			const recentActivityFeedRequestedAction: RecentActivityFeedRequestedAction =
				{
					type: RecentActivityFeedActionTypes.RECENT_ACTIVITY_FEED_REQUESTED
				};
			dispatch(recentActivityFeedRequestedAction);
			try {
				const recentActivityFeedResponse = await getRecentActivityFeed(
					zomentumEntityRequest
				);
				const recentActivityFeedSuccessfulAction: RecentActivityFeedSuccessfulAction =
					{
						type: RecentActivityFeedActionTypes.RECENT_ACTIVITY_FEED_SUCCESSFUL,
						recentActivityFeed: recentActivityFeedResponse.data
					};
				return dispatch(recentActivityFeedSuccessfulAction);
			} catch (error) {
				const recentActivityFeedFailedAction: RecentActivityFeedFailedAction =
					{
						type: RecentActivityFeedActionTypes.RECENT_ACTIVITY_FEED_FAILED
					};
				dispatch(recentActivityFeedFailedAction);

				return Promise.reject(error);
			}
		};
	},
	resetRecentActivityFeed: () => {
		return (dispatch: Dispatch): RecentActivityFeedResetAction => {
			const recentActivityFeedResetAction: RecentActivityFeedResetAction =
				{
					type: RecentActivityFeedActionTypes.RECENT_ACTIVITY_FEED_RESET
				};
			return dispatch(recentActivityFeedResetAction);
		};
	},
	deleteComment: (deleteCommentRequest: RecentActivityFeed) => {
		return async (
			dispatch: Dispatch
		): Promise<DeleteCommentSuccessfulAction> => {
			const deleteCommentRequestedAction: DeleteCommentRequestedAction = {
				type: RecentActivityFeedActionTypes.DELETE_COMMENT_REQUESTED
			};
			dispatch(deleteCommentRequestedAction);
			try {
				const deleteCommentResponse = await deleteComment(
					deleteCommentRequest.id
				);
				if (deleteCommentResponse.deleted) {
					const deleteCommentSuccessfulAction: DeleteCommentSuccessfulAction =
						{
							type: RecentActivityFeedActionTypes.DELETE_COMMENT_SUCCESSFUL,
							deleteComment: deleteCommentRequest
						};
					return dispatch(deleteCommentSuccessfulAction);
				} else {
					const deleteCommentFailedAction: DeleteCommentFailedAction =
						{
							type: RecentActivityFeedActionTypes.DELETE_COMMENT_FAILED
						};
					dispatch(deleteCommentFailedAction);
					return Promise.reject(DefaultErrorMessage);
				}
			} catch (error) {
				const deleteCommentFailedAction: DeleteCommentFailedAction = {
					type: RecentActivityFeedActionTypes.DELETE_COMMENT_FAILED
				};
				dispatch(deleteCommentFailedAction);

				return Promise.reject(error);
			}
		};
	},
	addComment: (addCommentRequest: AddRecentActivityFeedCommentRequest) => {
		return async (
			dispatch: Dispatch
		): Promise<AddCommentSuccessfulAction> => {
			const addCommentRequestedAction: AddRecentActivityFeedCommentRequestedAction =
				{
					type: RecentActivityFeedActionTypes.ADD_COMMENT_REQUESTED
				};
			dispatch(addCommentRequestedAction);
			try {
				const addCommentResponse = await addComment(addCommentRequest);
				addCommentResponse.last_message_time_stamp =
					addCommentResponse.created;
				const addCommentSuccessfulAction: AddCommentSuccessfulAction = {
					type: RecentActivityFeedActionTypes.ADD_COMMENT_SUCCESSFUL,
					addComment: addCommentResponse
				};
				return dispatch(addCommentSuccessfulAction);
			} catch (error) {
				const addCommentFailedAction: AddCommentFailedAction = {
					type: RecentActivityFeedActionTypes.ADD_COMMENT_FAILED
				};
				dispatch(addCommentFailedAction);

				return Promise.reject(error);
			}
		};
	},
	editComment: (editCommentRequest: RecentActivityFeed) => {
		return async (
			dispatch: Dispatch
		): Promise<EditCommentSuccessfulAction> => {
			const editCommentRequestedAction: EditCommentRequestedAction = {
				type: RecentActivityFeedActionTypes.EDIT_COMMENT_REQUESTED
			};
			dispatch(editCommentRequestedAction);
			try {
				const editCommentResponse = await editComment(
					editCommentRequest
				);
				editCommentResponse.last_message_time_stamp =
					editCommentResponse.body_updated;
				const editCommentSuccessfulAction: EditCommentSuccessfulAction =
					{
						type: RecentActivityFeedActionTypes.EDIT_COMMENT_SUCCESSFUL,
						editComment: editCommentResponse
					};
				return dispatch(editCommentSuccessfulAction);
			} catch (error) {
				const editCommentFailedAction: EditCommentFailedAction = {
					type: RecentActivityFeedActionTypes.EDIT_COMMENT_FAILED
				};
				dispatch(editCommentFailedAction);

				return Promise.reject(error);
			}
		};
	},
	emailThreadDrawerOpened: () => {
		return (dispatch: Dispatch): EmailThreadDrawerOpenedAction => {
			const emailThreadDrawerOpenedAction: EmailThreadDrawerOpenedAction =
				{
					type: RecentActivityFeedActionTypes.EMAIL_THREAD_DRAWER_OPENED
				};
			return dispatch(emailThreadDrawerOpenedAction);
		};
	},
	emailThreadDrawerClosed: () => {
		return (dispatch: Dispatch): EmailThreadDrawerClosedAction => {
			const emailThreadDrawerClosedAction: EmailThreadDrawerClosedAction =
				{
					type: RecentActivityFeedActionTypes.EMAIL_THREAD_DRAWER_CLOSED
				};
			return dispatch(emailThreadDrawerClosedAction);
		};
	},
	addCommentDrawerOpened: () => {
		return (dispatch: Dispatch): AddCommentDrawerOpenedAction => {
			const addCommentDrawerOpenedAction: AddCommentDrawerOpenedAction = {
				type: RecentActivityFeedActionTypes.ADD_COMMENT_DRAWER_OPENED
			};
			return dispatch(addCommentDrawerOpenedAction);
		};
	},
	addCommentDrawerClosed: () => {
		return (dispatch: Dispatch): AddCommentDrawerClosedAction => {
			const addCommentDrawerClosedAction: AddCommentDrawerClosedAction = {
				type: RecentActivityFeedActionTypes.ADD_COMMENT_DRAWER_CLOSED
			};
			return dispatch(addCommentDrawerClosedAction);
		};
	},
	editCommentDrawerOpened: () => {
		return (dispatch: Dispatch): EditCommentDrawerOpenedAction => {
			const editCommentDrawerOpenedAction: EditCommentDrawerOpenedAction =
				{
					type: RecentActivityFeedActionTypes.EDIT_COMMENT_DRAWER_OPENED
				};
			return dispatch(editCommentDrawerOpenedAction);
		};
	},
	editCommentDrawerClosed: () => {
		return (dispatch: Dispatch): EditCommentDrawerClosedAction => {
			const editCommentDrawerClosedAction: EditCommentDrawerClosedAction =
				{
					type: RecentActivityFeedActionTypes.EDIT_COMMENT_DRAWER_CLOSED
				};
			return dispatch(editCommentDrawerClosedAction);
		};
	}
};

export default RecentActivityFeedActionCreators;
