import { ZomentumEntities } from "@zomentum/contracts";
import { getRequest } from "@zomentum/utils/dist/Service/BackendService";
import axios, { AxiosResponse } from "axios";
import { MergeTagVariable } from "@zomentum/contracts/dist/Documents";

export const getMergeTagsURL = "/v2/merge_tags";
export const getMergeTagsForFormulaFieldURL = "/v2/merge_tags/formula-field";

export const getMergeTags = async (
	entityType: ZomentumEntities
): Promise<MergeTagVariable[]> => {
	try {
		const mergeTagsResponse: AxiosResponse<MergeTagVariable[]> =
			await axios(
				getRequest(
					`${getMergeTagsURL}?entity_type=${entityType}`,
					null,
					true,
					false,
					false
				)
			);

		return mergeTagsResponse.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getMergeTagsForFormulaField = async (
	entityType: ZomentumEntities
): Promise<MergeTagVariable[]> => {
	try {
		const mergeTagsResponse: AxiosResponse<MergeTagVariable[]> =
			await axios(
				getRequest(
					`${getMergeTagsForFormulaFieldURL}?entity_type=${entityType}`,
					null,
					true,
					false,
					false
				)
			);

		return mergeTagsResponse.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
