import {
	ListPagePostRequest,
	CustomViewsMetadata,
	CustomView,
	CustomViewResponse
} from "@zomentum/contracts/dist/UI";
import axios, { AxiosResponse } from "axios";
import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest
} from "@zomentum/utils/dist/Service/BackendService";
import {
	getTableQueryFilters,
	includeChildEntities
} from "@zomentum/contracts/dist/Utils";
import {
	TableQueryFilter,
	TableQueryFilterOperands,
	ZomentumEntities
} from "@zomentum/contracts";
import {
	VendorClient,
	VendorClientResponse,
	VendorClientEditOrAddRequest,
	VendorClientContactResponse,
	VendorClientAddOrEditContactRequest,
	VendorClientContact,
	VendorClientContactRequest,
	VendorClientContactBulkUpdateServiceResponse,
	VendorClientContactBulkUpdateRequest
} from "@zomentum/contracts/dist/Vendor/Clients";
import { ChildEntityHydrationParams } from "@zomentum/contracts/dist/Utils";
const editVendorClientRequestURL = "/v1/vendor/client/companies/{id}";
const addVendorClientRequestURL = "/v1/vendor/client/companies";
const deleteVendorClientURL = "/v1/vendor/client/companies/{id}";
const getAllVendorClientDetailsPaginationURL =
	"/v1/vendor/client/companies/view";
const getVendorClientMetaDataURL =
	"/v2/views/metadata?entity_type={entity_type}";
const getVendorClientDetailsURL = `/v1/vendor/client/companies/{id}`;
const getVendorClientContactDetailsURL = "/v1/vendor/client/users";
const addVendorClientContactRequestURL = "/v1/vendor/client/users";
const editVendorClientContactRequestURL = "/v1/vendor/client/users/{id}";
const deleteVendorClientContactURL = "/v1/vendor/client/users/{id}";
const vendorClientContactBulkUpdateURL = "/v1/vendor/client/users/bulk/update/";
const getLinkedClientsForPartnerURL =
	"/v1/vendor/client/companies?filters=partner_and_client_id.company_id:eq:{partnerId}";

// VIEWS
const createClientViewURL = "/v2/views";
const updateClientViewURL = "/v2/views/{view_id}";
const deleteClientViewURL = "v2/views/{view_id}";
const getAllClientViewURL = "/v2/views?entity_type={entity_type}";

export const getClientDetails = async (
	clientId: string
): Promise<VendorClient> => {
	try {
		const includedChildEntities = includeChildEntities([
			ChildEntityHydrationParams.OPPORTUNITIES_ALL,
			ChildEntityHydrationParams.COMPANIES_ALL,
			ChildEntityHydrationParams.PARTNER_MANAGER_ALL
		]);
		const response = await axios(
			getRequest(
				getVendorClientDetailsURL.replace("{id}", clientId) +
					`?${includedChildEntities}`,
				null,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getClientListPagePost = async (
	ClientRequest: ListPagePostRequest
): Promise<VendorClientResponse> => {
	try {
		const includedChildEntities = includeChildEntities(
			ClientRequest.included_child_entities
		);
		const response: AxiosResponse<VendorClientResponse> = await axios(
			postRequest(
				getAllVendorClientDetailsPaginationURL +
					`?${includedChildEntities}`,
				ClientRequest,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteVendorClient = async (id: string): Promise<void> => {
	try {
		await axios(
			deleteRequest(
				deleteVendorClientURL.replace("{id}", id),
				{},
				true,
				false
			)
		);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getVendorClientMetaData =
	async (): Promise<CustomViewsMetadata> => {
		try {
			const response = await axios(
				getRequest(
					getVendorClientMetaDataURL.replace(
						"{entity_type}",
						ZomentumEntities.VendorClient
					),
					null,
					true,
					false,
					false
				)
			);
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const editVendorClient = async (
	id: string,
	editVendorClientRequest: VendorClientEditOrAddRequest
): Promise<VendorClient> => {
	try {
		const includedChildEntities = includeChildEntities([
			ChildEntityHydrationParams.PARTNER_MANAGER_ALL
		]);
		const response: AxiosResponse<VendorClient> = await axios(
			putRequest(
				`${editVendorClientRequestURL.replace(
					"{id}",
					id
				)}?${includedChildEntities}`,
				editVendorClientRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const addVendorClient = async (
	addVendorClientRequest: VendorClientEditOrAddRequest
): Promise<VendorClient> => {
	try {
		const includedChildEntities = includeChildEntities([
			ChildEntityHydrationParams.PARTNER_MANAGER_ALL
		]);
		const response: AxiosResponse<VendorClient> = await axios(
			postRequest(
				`${addVendorClientRequestURL}?${includedChildEntities}`,
				addVendorClientRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
export const getAllVendorClientContacts = async (
	clientContactRequest: VendorClientContactRequest
): Promise<VendorClientContactResponse> => {
	try {
		let url = getVendorClientContactDetailsURL;
		const appliedFilters = getTableQueryFilters(
			[
				...(!!clientContactRequest.filters.length
					? clientContactRequest.filters
					: []),
				new TableQueryFilter(
					TableQueryFilterOperands.MongoDbEqualTo,
					"vendor_client_company_id",
					clientContactRequest.clientId,
					null,
					true
				)
			],
			false
		);
		url =
			url +
			"?" +
			appliedFilters +
			`&page=${clientContactRequest.page}&limit=${clientContactRequest.limit}`;

		if (clientContactRequest.keywords) {
			url = url + `&q=${clientContactRequest.keywords}`;
		}
		if (clientContactRequest.selected_id) {
			url =
				url +
				`&selected_id=${clientContactRequest.selected_id.join(",")}`;
		}
		if (clientContactRequest.countQueryFlag) {
			url += `&count_query_flag=true`;
		}
		if (clientContactRequest.sortQuery) {
			url += `&${clientContactRequest.sortQuery}`;
		}
		if (clientContactRequest.localeQuery) {
			url += `&${clientContactRequest.localeQuery}`;
		}
		const response: AxiosResponse<VendorClientContactResponse> =
			await axios(getRequest(url, null, true, true, false));
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
export const editVendorClientContact = async (
	id: string,
	editVendorClientContactRequest: VendorClientAddOrEditContactRequest
): Promise<VendorClientContact> => {
	try {
		const response: AxiosResponse<VendorClientContact> = await axios(
			putRequest(
				editVendorClientContactRequestURL.replace("{id}", id),
				editVendorClientContactRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const addVendorClientContact = async (
	addVendorClientContactRequest: VendorClientAddOrEditContactRequest
): Promise<VendorClientContact> => {
	try {
		const response: AxiosResponse<VendorClientContact> = await axios(
			postRequest(
				addVendorClientContactRequestURL,
				addVendorClientContactRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteVendorClientContact = async (id: string): Promise<void> => {
	try {
		await axios(
			deleteRequest(
				deleteVendorClientContactURL.replace("{id}", id),
				{},
				true,
				false
			)
		);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const makePrimaryVendorClientContact = async (
	makePrimaryContactRequest: VendorClientContact
) => {
	try {
		const response: AxiosResponse<VendorClientContact> = await axios(
			putRequest(
				editVendorClientContactRequestURL.replace(
					"{id}",
					makePrimaryContactRequest.id
				),
				makePrimaryContactRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const bulkUpdateVendorClientContact = async (
	payload: VendorClientContactBulkUpdateRequest
): Promise<VendorClientContactBulkUpdateServiceResponse> => {
	try {
		const response: AxiosResponse<VendorClientContactBulkUpdateServiceResponse> =
			await axios(
				postRequest(
					vendorClientContactBulkUpdateURL,
					payload,
					true,
					false,
					false
				)
			);
		return response.data;
	} catch (err) {
		console.error(err);
		return Promise.reject(err);
	}
};

export const getAllClientViews = async (): Promise<CustomViewResponse> => {
	try {
		const response: AxiosResponse<CustomViewResponse> = await axios(
			getRequest(
				getAllClientViewURL.replace(
					"{entity_type}",
					ZomentumEntities.VendorClient
				),
				null,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const createClientView = async (
	customViewRequest: CustomView
): Promise<CustomView> => {
	try {
		const response: AxiosResponse<CustomView> = await axios(
			postRequest(
				createClientViewURL,
				customViewRequest,
				true,
				false,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const updateClientView = async (
	customViewRequest: CustomView
): Promise<CustomView> => {
	try {
		const response: AxiosResponse<CustomView> = await axios(
			putRequest(
				updateClientViewURL.replace("{view_id}", customViewRequest.id),
				customViewRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const deleteClientView = async (
	customViewId: string
): Promise<unknown> => {
	try {
		const response: AxiosResponse<unknown> = await axios(
			deleteRequest(
				deleteClientViewURL.replace("{view_id}", customViewId),
				null,
				true,
				false
			)
		);

		return response.data;
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const getLinkedClientsForPartner = async (
	partnerId: string
): Promise<VendorClientResponse> => {
	try {
		const response = await axios(
			getRequest(
				getLinkedClientsForPartnerURL.replace("{partnerId}", partnerId),
				null,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
