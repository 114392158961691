import { roundTwoDecimals } from ".";

const calculateSellPrice = (
	total: number,
	quantity: number,
	shouldAddSetupPrice: boolean,
	setupPrice: number
): number => {
	return (
		(total * (quantity || 1) - (shouldAddSetupPrice ? setupPrice : 0)) /
		(quantity || 1)
	);
};

export const calculateMargin = (
	costPrice: number,
	sellPrice: number,
	defaultPrice: number
): number => {
	const newMargin =
		sellPrice !== 0
			? ((sellPrice - costPrice) / sellPrice) * 100
			: defaultPrice;
	if (isNaN(newMargin)) return 0;
	return newMargin;
};

const calculateMarkup = (
	costPrice: number,
	sellPrice: number,
	defaultPrice: number
): any => {
	if (costPrice === 0) {
		return undefined;
	}
	const newMarkup =
		costPrice !== 0
			? ((sellPrice - costPrice) / costPrice) * 100
			: defaultPrice;
	if (isNaN(newMarkup)) return 0;
	return newMarkup;
};

export const handleMarkupChange = (values: any, newMarkup: number) => {
	const newSellPrice =
		(values.selected_cost_price
			? values.selected_cost_price
			: values.cost_price) +
		((values.selected_cost_price
			? values.selected_cost_price
			: values.cost_price) *
			newMarkup) /
			100;

	const margin = calculateMargin(
		values.selected_cost_price
			? values.selected_cost_price
			: values.cost_price,
		newSellPrice,
		newMarkup
	);

	const sell_price = calculateSellPrice(
		newSellPrice,
		values.quantity,
		values.should_add_setup_price_to_margin,
		values.selected_setup_price
	);
	return { sell_price, margin };
};

export const handleMarginChange = (values: any, newMargin: number) => {
	const newSellPrice =
		((values.selected_cost_price
			? values.selected_cost_price
			: values.cost_price) *
			100) /
		(100 - newMargin);

	const markup = calculateMarkup(
		values.selected_cost_price
			? values.selected_cost_price
			: values.cost_price,
		newSellPrice,
		newMargin
	);

	const sell_price = calculateSellPrice(
		newSellPrice,
		values.quantity,
		values.should_add_setup_price_to_margin,
		values.selected_setup_price
	);

	return { sell_price, markup };
};

export const handleMarkupMargin = (
	cost: number,
	sellPrice: number,
	quantity: number,
	shouldAddSetupPrice: boolean,
	setupPrice: number
) => {
	const setupPriceAddedSellPrice =
		(sellPrice * (quantity || 1) + (shouldAddSetupPrice ? setupPrice : 0)) /
		(quantity || 1);

	const newMargin = calculateMargin(cost, setupPriceAddedSellPrice, 0);
	const newMarkup = calculateMarkup(cost, setupPriceAddedSellPrice, 0);

	return {
		markup: roundTwoDecimals(newMarkup),
		margin: roundTwoDecimals(newMargin),
		should_add_setup_price_to_margin: shouldAddSetupPrice
	};
};
