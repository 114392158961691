import React, { useState, useEffect } from "react";
import { addVendorClient } from "@/Services/Vendor/Client";
import {
	acceptPendingOpportunitySharedWithVendor,
	rejectPendingOpportunitySharedWithVendor,
	getPendingOpportunitySharedWithVendorRequest,
	checkConflictForPendingOpportunityShareWithVendor
} from "@/Services/Vendor/Opportunity";
import ZViewOpportunityRequestModal from "@organisms/ZViewOpportunityRequest/ZViewOpportunityRequestModal";
import { ZMessage } from "@zomentum/molecules";
import { IPendingOpportunityModal } from "./interface";
import NotificationFeedActionCreators from "@/Actions/Others/NotificationFeed";
import store from "@/redux-store";

const PendingOpportunitiesModal: React.FC<IPendingOpportunityModal> = ({
	id,
	onClose
}) => {
	const [viewOpportunityRequestModalId, setViewOpportunityRequestModalId] =
		useState<string | null>(null);
	const acceptPendingOpportunityRequest = async (clientId: string) => {
		const acceptedPendingOpportunity =
			await acceptPendingOpportunitySharedWithVendor(
				viewOpportunityRequestModalId ?? "",
				clientId
			);
		ZMessage.success(
			`${acceptedPendingOpportunity.name} Successfully Accepted`
		);
		refreshNotificationsFeed();
		onClose();
		return acceptedPendingOpportunity;
	};
	const rejectPendingOpportunityRequest = async (
		opportunityId: string,
		clientId: string
	) => {
		const rejectedPendingOpportunity =
			await rejectPendingOpportunitySharedWithVendor(
				opportunityId,
				clientId
			);
		ZMessage.success(
			`${rejectedPendingOpportunity.name} Successfully Rejected`
		);
		refreshNotificationsFeed();
		return rejectedPendingOpportunity;
	};
	useEffect(() => {
		setViewOpportunityRequestModalId(id);
	}, []);
	const refreshNotificationsFeed = () =>
		store.dispatch(NotificationFeedActionCreators.getNotificationFeed());
	return (
		<ZViewOpportunityRequestModal
			visible={!!viewOpportunityRequestModalId}
			onCancelViewOpportunityRequestModal={onClose}
			pendingOpportunityId={viewOpportunityRequestModalId ?? ""}
			getPendingOpportunity={getPendingOpportunitySharedWithVendorRequest}
			checkConflictForPendingOpportunity={
				checkConflictForPendingOpportunityShareWithVendor
			}
			acceptPendingOpportunity={acceptPendingOpportunityRequest}
			rejectPendingOpportunity={rejectPendingOpportunityRequest}
			createClient={addVendorClient}
		/>
	);
};

export default PendingOpportunitiesModal;
