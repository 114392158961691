'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.VendorPartnerActionTypes = void 0;
(function (VendorPartnerActionTypes) {
  VendorPartnerActionTypes["SHOW_VENDOR_PARTNER_LOADER"] = "SHOW_VENDOR_PARTNER_LOADER";
  VendorPartnerActionTypes["HIDE_VENDOR_PARTNER_LOADER"] = "HIDE_VENDOR_PARTNER_LOADER";
  VendorPartnerActionTypes["OPEN_EDIT_VENDOR_PARTNER_DRAWER"] = "OPEN_EDIT_VENDOR_PARTNER_DRAWER";
  VendorPartnerActionTypes["CLOSE_EDIT_VENDOR_PARTNER_DRAWER"] = "CLOSE_EDIT_VENDOR_PARTNER_DRAWER";
})(exports.VendorPartnerActionTypes || (exports.VendorPartnerActionTypes = {}));
var VendorPartnerActionCreators = {
  showVendorPartnerLoader: function showVendorPartnerLoader() {
    return function (dispatch) {
      var showVendorOpportunityLoaderAction = {
        type: exports.VendorPartnerActionTypes.SHOW_VENDOR_PARTNER_LOADER
      };
      return dispatch(showVendorOpportunityLoaderAction);
    };
  },
  hideVendorPartnerLoader: function hideVendorPartnerLoader() {
    return function (dispatch) {
      var hideVendorOpportunityLoaderAction = {
        type: exports.VendorPartnerActionTypes.HIDE_VENDOR_PARTNER_LOADER
      };
      return dispatch(hideVendorOpportunityLoaderAction);
    };
  },
  openEditVendorPartnerDrawer: function openEditVendorPartnerDrawer() {
    return function (dispatch) {
      var openOpportunityDrawerAction = {
        type: exports.VendorPartnerActionTypes.OPEN_EDIT_VENDOR_PARTNER_DRAWER
      };
      return dispatch(openOpportunityDrawerAction);
    };
  },
  closeEditVendorPartnerDrawer: function closeEditVendorPartnerDrawer() {
    return function (dispatch) {
      var closeOpportunityDrawerAction = {
        type: exports.VendorPartnerActionTypes.CLOSE_EDIT_VENDOR_PARTNER_DRAWER
      };
      return dispatch(closeOpportunityDrawerAction);
    };
  }
};

exports.VendorPartnerActionCreators = VendorPartnerActionCreators;
exports["default"] = VendorPartnerActionCreators;
