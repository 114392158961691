'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var _commonjsHelpers = require('./_commonjsHelpers.js');
var Animate = require('../node_modules/rc-animate/es/Animate.js');

var require$$3 = /*@__PURE__*/_commonjsHelpers.getAugmentedNamespace(Animate);

exports["default"] = require$$3;
