'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

// Generated by Avo VERSION 122.38.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
// fetch() polyfill
(function () {
  if (typeof window === "undefined") {
    return;
  }
  var support = {
    searchParams: "URLSearchParams" in self,
    iterable: "Symbol" in self && "iterator" in Symbol,
    blob: "FileReader" in self && "Blob" in self && function () {
      try {
        new Blob();
        return true;
      } catch (e) {
        return false;
      }
    }(),
    formData: "FormData" in self,
    arrayBuffer: "ArrayBuffer" in self
  };
  function isDataView(obj) {
    return obj && DataView.prototype.isPrototypeOf(obj);
  }
  if (support.arrayBuffer) {
    var viewClasses = ["[object Int8Array]", "[object Uint8Array]", "[object Uint8ClampedArray]", "[object Int16Array]", "[object Uint16Array]", "[object Int32Array]", "[object Uint32Array]", "[object Float32Array]", "[object Float64Array]"];
    var isArrayBufferView = ArrayBuffer.isView || function (obj) {
      return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1;
    };
  }
  function normalizeName(name) {
    if (typeof name !== "string") {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError("Invalid character in header field name");
    }
    return name.toLowerCase();
  }
  function normalizeValue(value) {
    if (typeof value !== "string") {
      value = String(value);
    }
    return value;
  }
  // Build a destructive iterator for the value list
  function iteratorFor(items) {
    var iterator = {
      next: function () {
        var value = items.shift();
        return {
          done: value === undefined,
          value: value
        };
      }
    };
    if (support.iterable) {
      iterator[Symbol.iterator] = function () {
        return iterator;
      };
    }
    return iterator;
  }
  function Headers(headers) {
    // @ts-ignore
    this.map = {};
    if (headers instanceof Headers) {
      headers.forEach(function (value, name) {
        // @ts-ignore
        this.append(name, value);
        // @ts-ignore
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function (header) {
        // @ts-ignore
        this.append(header[0], header[1]);
        // @ts-ignore
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function (name) {
        // @ts-ignore
        this.append(name, headers[name]);
        // @ts-ignore
      }, this);
    }
  }
  Headers.prototype.append = function (name, value) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ", " + value : value;
  };
  Headers.prototype["delete"] = function (name) {
    delete this.map[normalizeName(name)];
  };
  Headers.prototype.get = function (name) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null;
  };
  Headers.prototype.has = function (name) {
    return this.map.hasOwnProperty(normalizeName(name));
  };
  Headers.prototype.set = function (name, value) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };
  Headers.prototype.forEach = function (callback, thisArg) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };
  Headers.prototype.keys = function () {
    var items = [];
    this.forEach(function (_value, name) {
      items.push(name);
    });
    return iteratorFor(items);
  };
  Headers.prototype.values = function () {
    var items = [];
    this.forEach(function (value) {
      items.push(value);
    });
    return iteratorFor(items);
  };
  Headers.prototype.entries = function () {
    var items = [];
    this.forEach(function (value, name) {
      items.push([name, value]);
    });
    return iteratorFor(items);
  };
  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }
  function consumed(body) {
    if (body.bodyUsed) {
      return true;
    }
    body.bodyUsed = true;
    return false;
  }
  function fileReaderReady(reader) {
    return new Promise(function (resolve, reject) {
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function () {
        reject(reader.error);
      };
    });
  }
  function readBlobAsArrayBuffer(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise;
  }
  function readBlobAsText(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise;
  }
  function readArrayBufferAsText(buf) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);
    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]);
    }
    return chars.join("");
  }
  function bufferClone(buf) {
    if (buf.slice) {
      return buf.slice(0);
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer;
    }
  }
  function Body() {
    // @ts-ignore
    this.bodyUsed = false;
    // @ts-ignore
    this._initBody = function (body) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = "";
      } else if (typeof body === "string") {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }
      if (!this.headers.get("content-type")) {
        if (typeof body === "string") {
          this.headers.set("content-type", "text/plain;charset=UTF-8");
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set("content-type", this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set("content-type", "application/x-www-form-urlencoded;charset=UTF-8");
        }
      }
    };
    if (support.blob) {
      // @ts-ignore
      this.blob = function () {
        var rejected = consumed(this);
        if (rejected) {
          return Promise.reject(new TypeError("Already read"));
        }
        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob);
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]));
        } else if (this._bodyFormData) {
          throw new Error("could not read FormData body as blob");
        } else {
          return Promise.resolve(new Blob([this._bodyText]));
        }
      };
      // @ts-ignore
      this.arrayBuffer = function () {
        if (this._bodyArrayBuffer) {
          if (consumed(this)) {
            return Promise.reject(new TypeError("Already read"));
          } else {
            return Promise.resolve(this._bodyArrayBuffer);
          }
        } else {
          return this.blob().then(readBlobAsArrayBuffer);
        }
      };
    }
    // @ts-ignore
    this.text = function () {
      var rejected = consumed(this);
      if (rejected) {
        return Promise.reject(new TypeError("Already read"));
      }
      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob);
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer));
      } else if (this._bodyFormData) {
        throw new Error("could not read FormData body as text");
      } else {
        return Promise.resolve(this._bodyText);
      }
    };
    if (support.formData) {
      // @ts-ignore
      this.formData = function () {
        return this.text().then(decode);
      };
    }
    // @ts-ignore
    this.json = function () {
      return this.text().then(JSON.parse);
    };
    // @ts-ignore
    return this;
  }
  // HTTP methods whose capitalization should be normalized
  var methods = ["DELETE", "GET", "HEAD", "OPTIONS", "POST", "PUT"];
  function normalizeMethod(method) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method;
  }
  function Request(input, options) {
    options = options || {};
    var body = options.body;
    if (input instanceof Request) {
      if (input.bodyUsed) {
        throw new TypeError("Already read");
      }
      // @ts-ignore
      this.url = input.url;
      // @ts-ignore
      this.credentials = input.credentials;
      if (!options.headers) {
        // @ts-ignore
        this.headers = new Headers(input.headers);
      }
      // @ts-ignore
      this.method = input.method;
      // @ts-ignore
      this.mode = input.mode;
      // @ts-ignore
      this.signal = input.signal;
      if (!body && input._bodyInit != null) {
        body = input._bodyInit;
        input.bodyUsed = true;
      }
    } else {
      // @ts-ignore
      this.url = String(input);
    }
    // @ts-ignore
    this.credentials = options.credentials || this.credentials || "same-origin";
    // @ts-ignore
    if (options.headers || !this.headers) {
      // @ts-ignore
      this.headers = new Headers(options.headers);
    }
    // @ts-ignore
    this.method = normalizeMethod(options.method || this.method || "GET");
    // @ts-ignore
    this.mode = options.mode || this.mode || null;
    // @ts-ignore
    this.signal = options.signal || this.signal;
    // @ts-ignore
    this.referrer = null;
    // @ts-ignore
    if ((this.method === "GET" || this.method === "HEAD") && body) {
      throw new TypeError("Body not allowed for GET or HEAD requests");
    }
    // @ts-ignore
    this._initBody(body);
  }
  Request.prototype.clone = function () {
    // @ts-ignore
    return new Request(this, {
      body: this._bodyInit
    });
  };
  function decode(body) {
    var form = new FormData();
    body.trim().split("&").forEach(function (bytes) {
      if (bytes) {
        var split = bytes.split("=");
        var name = split.shift().replace(/\+/g, " ");
        var value = split.join("=").replace(/\+/g, " ");
        form.append(decodeURIComponent(name), decodeURIComponent(value));
      }
    });
    return form;
  }
  function parseHeaders(rawHeaders) {
    // @ts-ignore
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, " ");
    preProcessedHeaders.split(/\r?\n/).forEach(function (line) {
      var parts = line.split(":");
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(":").trim();
        headers.append(key, value);
      }
    });
    return headers;
  }
  Body.call(Request.prototype);
  function Response(bodyInit, options) {
    if (!options) {
      options = {};
    }
    // @ts-ignore
    this.type = "default";
    // @ts-ignore
    this.status = options.status === undefined ? 200 : options.status;
    // @ts-ignore
    this.ok = this.status >= 200 && this.status < 300;
    // @ts-ignore
    this.statusText = "statusText" in options ? options.statusText : "OK";
    // @ts-ignore
    this.headers = new Headers(options.headers);
    // @ts-ignore
    this.url = options.url || "";
    // @ts-ignore
    this._initBody(bodyInit);
  }
  Body.call(Response.prototype);
  Response.prototype.clone = function () {
    // @ts-ignore
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      // @ts-ignore
      headers: new Headers(this.headers),
      url: this.url
    });
  };
  Response.error = function () {
    // @ts-ignore
    var response = new Response(null, {
      status: 0,
      statusText: ""
    });
    response.type = "error";
    return response;
  };
  var redirectStatuses = [301, 302, 303, 307, 308];
  Response.redirect = function (url, status) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError("Invalid status code");
    }
    // @ts-ignore
    return new Response(null, {
      status: status,
      headers: {
        location: url
      }
    });
  };
  self.DOMException = self.DOMException;
  try {
    new self.DOMException();
  } catch (err) {
    self.DOMException = function (message, name) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    self.DOMException.prototype = Object.create(Error.prototype);
    self.DOMException.prototype.constructor = self.DOMException;
  }
  function fetch(input, init) {
    return new Promise(function (resolve, reject) {
      // @ts-ignore
      var request = new Request(input, init);
      if (request.signal && request.signal.aborted) {
        return reject(new self.DOMException("Aborted", "AbortError"));
      }
      var xhr = new XMLHttpRequest();
      function abortXhr() {
        xhr.abort();
      }
      xhr.onload = function () {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || "")
        };
        options.url = "responseURL" in xhr ? xhr.responseURL : options.headers.get("X-Request-URL");
        var body = "response" in xhr ? xhr.response : xhr.responseText;
        // @ts-ignore
        resolve(new Response(body, options));
      };
      xhr.onerror = function () {
        reject(new TypeError("Network request failed"));
      };
      xhr.ontimeout = function () {
        reject(new TypeError("Network request failed"));
      };
      xhr.onabort = function () {
        reject(new self.DOMException("Aborted", "AbortError"));
      };
      xhr.open(request.method, request.url, true);
      if (request.credentials === "include") {
        xhr.withCredentials = true;
      } else if (request.credentials === "omit") {
        xhr.withCredentials = false;
      }
      if ("responseType" in xhr && support.blob) {
        xhr.responseType = "blob";
      }
      request.headers.forEach(function (value, name) {
        xhr.setRequestHeader(name, value);
      });
      if (request.signal) {
        request.signal.addEventListener("abort", abortXhr);
        xhr.onreadystatechange = function () {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener("abort", abortXhr);
          }
        };
      }
      xhr.send(typeof request._bodyInit === "undefined" ? null : request._bodyInit);
    });
  }
  fetch.polyfill = true;
  if (!self.fetch) {
    self.fetch = fetch;
    self.Headers = Headers;
    self.Request = Request;
    self.Response = Response;
  }
})();
exports.AvoEnv = void 0;
(function (AvoEnv) {
  AvoEnv["Prod"] = "prod";
  AvoEnv["Staging"] = "staging";
  AvoEnv["Dev"] = "dev";
})(exports.AvoEnv || (exports.AvoEnv = {}));
var __AVO_ENV__ = null;
// @ts-ignore
var __AVO_NOOP__ = false;
// @ts-ignore
var __AVO_LOGGER__ = null;
// @ts-ignore
var __WEB_DEBUGGER__ = true;
var avoInspectorApiKey = "k0pAl02dolcOOCmSKFS4";
var __INSPECTOR__ = null;
// @ts-ignore
if (typeof Object.assign !== "function") {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target, _varArgs) {
      // .length of function is 2
      if (target == null) {
        // TypeError if undefined or null
        throw new TypeError("Cannot convert undefined or null to object");
      }
      var to = Object(target);
      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];
        if (nextSource != null) {
          // Skip over if undefined or null
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}
var webDebuggerArea;
(function (webDebuggerArea) {
  webDebuggerArea["BottomRight"] = "BottomRight";
  webDebuggerArea["BottomLeft"] = "BottomLeft";
  webDebuggerArea["TopRight"] = "TopRight";
  webDebuggerArea["TopLeft"] = "TopLeft";
})(webDebuggerArea || (webDebuggerArea = {}));
var InternalAvoLogger = {
  logEventSent: function logEventSent(eventName, eventProperties, userProperties) {
    var message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties);
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return;
    }
    typeof console !== "undefined" && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
  },
  log: function log(message) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return;
    }
    typeof console !== "undefined" && console.log("[avo] " + message);
  },
  warn: function warn(message) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
      return;
    }
    typeof console !== "undefined" && console.warn("[avo] " + message);
  },
  error: function error(message, error) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
      return;
    }
    typeof console !== "undefined" && console.error("[avo] " + message, error);
  }
};
function convertPropertiesArrayToMap(propertiesArray) {
  var result = {};
  propertiesArray.forEach(function (value) {
    result[value.name] = value.value;
  });
  return result;
}
var _avo_invoke;
var _avo_invoke_meta;
var _avo_sampling_rate = 1.0;
_avo_invoke = function _avo_invoke(env, eventId, hash, messages, origin) {
  // @ts-ignore
  if (typeof window === "undefined") {
    return;
  }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ac: "sryK3MBZXhfHCFgntxyH",
          br: "Yo9Selmx1",
          en: env,
          ev: eventId,
          ha: hash,
          sc: "Xe7nuN8AtWMZ3ADRuw3t",
          se: new Date().toISOString(),
          so: "VrBBh4Ux4",
          va: messages.length === 0,
          me: messages,
          or: origin
        })
      }).then(function (res) {
        return res.json();
      }).then(function (data) {
        _avo_sampling_rate = data.sa;
      }).catch(function () {});
    }
  }
};
_avo_invoke_meta = function _avo_invoke_meta(env, type, messages, origin) {
  // @ts-ignore
  if (typeof window === "undefined") {
    return;
  }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ac: "sryK3MBZXhfHCFgntxyH",
          br: "Yo9Selmx1",
          en: env,
          ty: type,
          sc: "Xe7nuN8AtWMZ3ADRuw3t",
          se: new Date().toISOString(),
          so: "VrBBh4Ux4",
          va: messages.length === 0,
          me: messages,
          or: origin
        })
      }).then(function (res) {
        return res.json();
      }).then(function (data) {
        _avo_sampling_rate = data.sa;
      }).catch(function () {});
    }
  }
};
var _avo_debugger_log;
var _avo_debugger_events_during_boot = [];
var _avo_debugger_ready = false;
if (typeof window !== "undefined") {
  window.addEventListener("message", function (event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    var iframe = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      var message = {
        type_: "avo-debugger-boot-events",
        schemaId: "Xe7nuN8AtWMZ3ADRuw3t",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger");
    }
  });
}
_avo_debugger_log = function _avo_debugger_log(eventId, eventName, messages, eventProperties, userProperties, groupProperties) {
  if (typeof window === "undefined") {
    return;
  }
  var event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties: eventProperties,
    userProperties: userProperties,
    groupProperties: groupProperties
  };
  if (_avo_debugger_ready) {
    var message = {
      type_: "avo-debugger-events",
      events: [event]
    };
    document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger");
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
};
function _avo_debugger_send_position(position) {
  if (typeof window === "undefined") {
    return;
  }
  window.addEventListener("message", function (event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    if (event.data.type_ === "avo-debugger-ready" && position !== null) {
      var message = {
        type_: "avo-debugger-position",
        position: position
      };
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger");
    }
  });
}
var MarketingConnected = {
  HUBSPOT: "Hubspot",
  INFUSIONSOFT: "Infusionsoft",
  NONE: "None"
};
var PermissionLevel = {
  USER: "User",
  ADMIN: "Admin",
  MANAGER: "Manager"
};
var ProductName = {
  GROW: "Grow",
  PARTNER_ALIGN: "PartnerAlign",
  CONNECT: "Connect"
};
var ConnectedMarketing = {
  HUBSPOT: "Hubspot",
  INFUSIONSOFT: "Infusionsoft",
  NONE: "None"
};
var UserAccess = {
  GLOBAL: "Global",
  ASSIGNED: "Assigned"
};
var PsaName = {
  HALO_PS_A: "HaloPSA",
  CW_MANAGE: "CW Manage",
  AUTOTASK: "Autotask",
  KASEYA_BMS: "Kaseya BMS",
  SYNCRO: "Syncro",
  REPAIRSHOPR: "Repairshopr",
  NONE: "None"
};
var SignUpType = {
  INVITED_VIA_VENDOR: "Invited via vendor",
  INVITED_VIA_SALES: "Invited via sales",
  OTHER: "Other"
};
var Action = {
  RECURRING_COMMISSION: "Recurring commission",
  ONE_TIME_COMMISSION: "One time commission",
  ONE_TIME_AND_RECURRING_COMMISSION: "One time and recurring commission"
};
var OnboardingCompleted = {
  YES: "Yes",
  NO: "No"
};
var ImportStatus = {
  SUCCESSFUL: "Successful",
  FAILED: "Failed"
};
var BillTo = {
  PARTNER: "Partner",
  CLIENT: "Client"
};
var TransactionType = {
  COLLECT: "Collect",
  PAY: "Pay"
};
var OnboardingAbandoned = {
  ALIGN_MODULE: "Align module",
  OPPORTUNITY_MODULE: "Opportunity module",
  DOCUMENT_MODULE: "Document module",
  NO: "No"
};
var MixpanelP1Only;
function initAvo(options, destinationOptions, MixpanelP1OnlyDestination) {
  var _a;
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env;
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == exports.AvoEnv.Prod) {
    InternalAvoLogger.warn("[avo] ****************************************************");
    InternalAvoLogger.warn("[avo] WARNING Avo cannot be initialized in noop mode in production:");
    InternalAvoLogger.warn("[avo] - Overwriting configuration with noop=false.");
    InternalAvoLogger.warn("[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true");
    InternalAvoLogger.warn("[avo] ****************************************************");
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log("[avo] ****************************************************");
    InternalAvoLogger.log("[avo] Avo is now initialized in noop mode. This means:");
    InternalAvoLogger.log("[avo] - No events will be sent");
    InternalAvoLogger.log("[avo] - No network requests are made");
    InternalAvoLogger.log("[avo] ****************************************************");
  }
  if (options.strict !== undefined) {
    options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) ;
  __WEB_DEBUGGER__ = !__AVO_NOOP__ && (typeof window !== "undefined" && window.location.search.indexOf("avo_debug=1") > -1 || options.webDebugger !== false && __AVO_ENV__ !== exports.AvoEnv.Prod);
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== "prod") {
    InternalAvoLogger.warn("[avo] Avo Inspector not provided in initAvo() call");
  }
  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if ((_a = options.webDebuggerOptions) === null || _a === void 0 ? void 0 : _a.position) {
      _avo_debugger_send_position(options.webDebuggerOptions.position);
    }
    (function () {
      if (typeof window === "undefined") {
        return;
      }
      var init = function () {
        var iframe = document.createElement("iframe");
        document.body.appendChild(iframe);
        iframe.id = "avo-debugger";
        iframe.src = "https://www.avo.app/_debugger";
        iframe.style = "display: none;";
      };
      if (document.body) {
        init();
      } else {
        document.addEventListener("DOMContentLoaded", init);
      }
    })();
  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === exports.AvoEnv.Prod) ;
    if (__AVO_ENV__ === exports.AvoEnv.Dev) ;
    if (__AVO_ENV__ === exports.AvoEnv.Staging) ;
    MixpanelP1Only = MixpanelP1OnlyDestination;
    if (__AVO_ENV__ === "prod") {
      MixpanelP1Only && MixpanelP1Only.make && MixpanelP1Only.make(__AVO_ENV__, "ab0e48c071b928b660d611dc3a006f7f");
    } else if (__AVO_ENV__ === "dev") {
      MixpanelP1Only && MixpanelP1Only.make && MixpanelP1Only.make(__AVO_ENV__, "0e536b0a12b3755c34362d585d41aca3");
    } else {
      MixpanelP1Only && MixpanelP1Only.make && MixpanelP1Only.make(__AVO_ENV__, "0e536b0a12b3755c34362d585d41aca3");
    }
    if (__AVO_ENV__ === exports.AvoEnv.Dev) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, "init", [], "init");
    }
  }
}
/**
 * Account clicked: Triggered when Settings option is clicked on the side bar
 *
 * When to trigger this event:
 * 1. When Settings is clicked on nav bar.
 * View in Avo: https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/O_yiX4RI5H/trigger/ztDkUyjCC
 * 2. when the sidebar navigation is clicked
 * View in Avo: https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/O_yiX4RI5H/trigger/AbAICj06G
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/O_yiX4RI5H}
 */
function accountClicked(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "O_yiX4RI5H", "ff6d47e0e487bebd6cc609482884ff94b60c39c86111c5f96d2e28187e184d88", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Account clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("O_yiX4RI5H", "Account clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Account clicked", eventProperties, "O_yiX4RI5H", "ff6d47e0e487bebd6cc609482884ff94b60c39c86111c5f96d2e28187e184d88");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.logEvent("Account clicked", Object.assign({}, eventProperties));
  }
}
/**
 * Hubspot integration disconnected: No description
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 * @param properties.marketingConnected: no description
 * @param properties.updateGroupPropertiesForGroupSubdomain (id of the group_subdomain group to update group properties for): no description
 * @param properties.connectedMarketing (group_subdomain group property): no description
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/iFqqNajPyakV}
 */
function hubspotIntegrationDisconnected(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  userPropertiesArray.push({
    id: "0fzPdT7qRB",
    name: "marketing_connected",
    value: properties.marketingConnected
  });
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "iFqqNajPyakV", "1a45ca4dc06b55f5bdf6731fe46427f808a9a37b20f91e55f25b7364daba40f6", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Hubspot integration disconnected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("iFqqNajPyakV", "Hubspot integration disconnected", messages, eventPropertiesArray, userPropertiesArray, [{
        id: "BWkfSNpQC",
        groupName: "group_subdomain",
        groupId: properties.updateGroupPropertiesForGroupSubdomain,
        name: "connected_marketing",
        value: properties.connectedMarketing
      }]);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Hubspot integration disconnected", eventProperties, "iFqqNajPyakV", "1a45ca4dc06b55f5bdf6731fe46427f808a9a37b20f91e55f25b7364daba40f6");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.setUserProperties(properties.userId_, Object.assign({}, userProperties));
    MixpanelP1Only.setGroupProperties("group_subdomain", properties.updateGroupPropertiesForGroupSubdomain, {
      connected_marketing: properties.connectedMarketing
    });
    MixpanelP1Only.logEvent("Hubspot integration disconnected", Object.assign({}, eventProperties));
  }
}
/**
 * New Email template added: Triggered when a new email template is created
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/cBTNQPH7BSlz}
 */
function newEmailTemplateAdded(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "cBTNQPH7BSlz", "5cdf2d196b963276fdbb04c6665fcaf4fa4de06350db28287975b2379db7e3da", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("New Email template added", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("cBTNQPH7BSlz", "New Email template added", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("New Email template added", eventProperties, "cBTNQPH7BSlz", "5cdf2d196b963276fdbb04c6665fcaf4fa4de06350db28287975b2379db7e3da");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.logEvent("New Email template added", Object.assign({}, eventProperties));
  }
}
/**
 * Partners clicked: When the partners icon is clicked on the left nav bar.
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/sFac8idU2jtf}
 */
function partnersClicked(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "sFac8idU2jtf", "8f769aa1396892ec9105880b4c04e95487dbd99bc69abd714cf40259fa715049", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Partners clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("sFac8idU2jtf", "Partners clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Partners clicked", eventProperties, "sFac8idU2jtf", "8f769aa1396892ec9105880b4c04e95487dbd99bc69abd714cf40259fa715049");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.logEvent("Partners clicked", Object.assign({}, eventProperties));
  }
}
/**
 * Pipeline stages reordered: Triggered when the stages of a pipeline are reordered
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/AByYxXNX04qd}
 */
function pipelineStagesReordered(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "AByYxXNX04qd", "8c894b526c9e3936cfc644f984d886de05767b5244c4315181948a79d886d0ee", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Pipeline stages reordered", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("AByYxXNX04qd", "Pipeline stages reordered", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Pipeline stages reordered", eventProperties, "AByYxXNX04qd", "8c894b526c9e3936cfc644f984d886de05767b5244c4315181948a79d886d0ee");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.logEvent("Pipeline stages reordered", Object.assign({}, eventProperties));
  }
}
/**
 * Dashboard clicked: Whenever the dashboard icon is clicked on the left nav bar
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/fuHjAXwbKIGN}
 */
function dashboardClicked(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "fuHjAXwbKIGN", "a40afcef981843f1eb5647801461bd177ee145b7ca2a8bb80eaad227a34957d5", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Dashboard clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("fuHjAXwbKIGN", "Dashboard clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Dashboard clicked", eventProperties, "fuHjAXwbKIGN", "a40afcef981843f1eb5647801461bd177ee145b7ca2a8bb80eaad227a34957d5");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.logEvent("Dashboard clicked", Object.assign({}, eventProperties));
  }
}
/**
 * Another user added to account: For a new user added to an existing account
 *
 * When to trigger this event:
 * 1. From Users page manually
 * View in Avo: https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/k5MU15fRX/trigger/a_OuDBvEZ
 * 2. From backend API
 * View in Avo: https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/k5MU15fRX/trigger/O6S430toj
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 * @param properties.userEmailid: no description
 * @param properties.updateGroupPropertiesForGroupSubdomain (id of the group_subdomain group to update group properties for): no description
 * @param properties.numberOfUsers (group_subdomain group property): no description
 * @param properties.numberOfActiveUsers (group_subdomain group property): This is will keep track of number of users in a company setting up their account
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/k5MU15fRX}
 */
function anotherUserAddedToAccount(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  eventPropertiesArray.push({
    id: "5LXlRqSQM",
    name: "user_emailid",
    value: properties.userEmailid
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "k5MU15fRX", "42af7fc35d0690bfe247e0159cad6affb1ec5d99f27ea0ff389ae47f77a37266", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Another user added to account", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("k5MU15fRX", "Another user added to account", messages, eventPropertiesArray, userPropertiesArray, [{
        id: "f8YNNXDkwW",
        groupName: "group_subdomain",
        groupId: properties.updateGroupPropertiesForGroupSubdomain,
        name: "number_of_users",
        value: properties.numberOfUsers
      }, {
        id: "qoiwk1HJEb",
        groupName: "group_subdomain",
        groupId: properties.updateGroupPropertiesForGroupSubdomain,
        name: "number_of_active_users",
        value: properties.numberOfActiveUsers
      }]);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Another user added to account", eventProperties, "k5MU15fRX", "42af7fc35d0690bfe247e0159cad6affb1ec5d99f27ea0ff389ae47f77a37266");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.setGroupProperties("group_subdomain", properties.updateGroupPropertiesForGroupSubdomain, {
      number_of_users: properties.numberOfUsers,
      number_of_active_users: properties.numberOfActiveUsers
    });
    MixpanelP1Only.logEvent("Another user added to account", Object.assign({}, eventProperties));
  }
}
/**
 * User logged in: Whenever a user logs in to the account
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 * @param properties.name: no description
 * @param properties.email: no description
 * @param properties.associateUserWithGroupSubdomain (id of the group_subdomain group to associate current user with): no description
 * @param properties.updateGroupPropertiesForGroupSubdomain (id of the group_subdomain group to update group properties for): no description
 * @param properties.companyName (group_subdomain group property): Name of the company
 * @param properties.companyDomain (group_subdomain group property): domain of the company - same as group_subdomain value
 * @param properties.numberOfUsers (group_subdomain group property): no description
 * @param properties.numberOfActiveUsers (group_subdomain group property): This is will keep track of number of users in a company setting up their account
 * @param properties.signUpType (group_subdomain group property): via which method has the user signed up on zomentum
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/hSaKsVCU8}
 */
function userLoggedIn(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  userPropertiesArray.push({
    id: "70pew0ubSb",
    name: "$name",
    value: properties.name
  });
  userPropertiesArray.push({
    id: "8SPu6gsq_F",
    name: "$email",
    value: properties.email
  });
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "hSaKsVCU8", "cc5a78bf99e36ef2e09f665913c9655b9958d0ed5f9340dd5b23f4905c70d474", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("User logged in", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("hSaKsVCU8", "User logged in", messages, eventPropertiesArray, userPropertiesArray, [{
        id: "2d902b4b-fb88-4844-9dc1-88e8cb0c5a22user",
        groupName: "group_subdomain",
        groupId: "2d902b4b-fb88-4844-9dc1-88e8cb0c5a22",
        name: "Associates user with group",
        value: properties.associateUserWithGroupSubdomain
      }, {
        id: "Vk4yuaNXS",
        groupName: "group_subdomain",
        groupId: properties.updateGroupPropertiesForGroupSubdomain,
        name: "$name",
        value: properties.companyName
      }, {
        id: "-FOdb7Yg0",
        groupName: "group_subdomain",
        groupId: properties.updateGroupPropertiesForGroupSubdomain,
        name: "company_domain",
        value: properties.companyDomain
      }, {
        id: "f8YNNXDkwW",
        groupName: "group_subdomain",
        groupId: properties.updateGroupPropertiesForGroupSubdomain,
        name: "number_of_users",
        value: properties.numberOfUsers
      }, {
        id: "qoiwk1HJEb",
        groupName: "group_subdomain",
        groupId: properties.updateGroupPropertiesForGroupSubdomain,
        name: "number_of_active_users",
        value: properties.numberOfActiveUsers
      }, {
        id: "LtoVPnShGf",
        groupName: "group_subdomain",
        groupId: properties.updateGroupPropertiesForGroupSubdomain,
        name: "sign_up_type",
        value: properties.signUpType
      }]);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("User logged in", eventProperties, "hSaKsVCU8", "cc5a78bf99e36ef2e09f665913c9655b9958d0ed5f9340dd5b23f4905c70d474");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.identify(properties.userId_);
    MixpanelP1Only.setUserProperties(properties.userId_, Object.assign({}, userProperties));
    MixpanelP1Only.addCurrentUserToGroup("group_subdomain", properties.associateUserWithGroupSubdomain, {
      $name: properties.companyName,
      company_domain: properties.companyDomain,
      number_of_users: properties.numberOfUsers,
      number_of_active_users: properties.numberOfActiveUsers,
      sign_up_type: properties.signUpType
    });
    MixpanelP1Only.setGroupProperties("group_subdomain", properties.updateGroupPropertiesForGroupSubdomain, {
      $name: properties.companyName,
      company_domain: properties.companyDomain,
      number_of_users: properties.numberOfUsers,
      number_of_active_users: properties.numberOfActiveUsers,
      sign_up_type: properties.signUpType
    });
    MixpanelP1Only.logEvent("User logged in", Object.assign({}, eventProperties));
  }
}
/**
 * Another user account activated: Whenever a user is activated
 *
 * When to trigger this event:
 * 1. Users list page
 * View in Avo: https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/Q9WBSq6zP/trigger/VAKes_7Jb
 * 2. From API
 * View in Avo: https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/Q9WBSq6zP/trigger/pelX3mGND
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 * @param properties.userEmailid: no description
 * @param properties.updateGroupPropertiesForGroupSubdomain (id of the group_subdomain group to update group properties for): no description
 * @param properties.numberOfActiveUsers (group_subdomain group property): This is will keep track of number of users in a company setting up their account
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/Q9WBSq6zP}
 */
function anotherUserAccountActivated(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  eventPropertiesArray.push({
    id: "5LXlRqSQM",
    name: "user_emailid",
    value: properties.userEmailid
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Q9WBSq6zP", "a472c27e0a337557a6a93b10ad79271359ee107760dd998ab27995501df25a25", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Another user account activated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Q9WBSq6zP", "Another user account activated", messages, eventPropertiesArray, userPropertiesArray, [{
        id: "qoiwk1HJEb",
        groupName: "group_subdomain",
        groupId: properties.updateGroupPropertiesForGroupSubdomain,
        name: "number_of_active_users",
        value: properties.numberOfActiveUsers
      }]);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Another user account activated", eventProperties, "Q9WBSq6zP", "a472c27e0a337557a6a93b10ad79271359ee107760dd998ab27995501df25a25");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.setGroupProperties("group_subdomain", properties.updateGroupPropertiesForGroupSubdomain, {
      number_of_active_users: properties.numberOfActiveUsers
    });
    MixpanelP1Only.logEvent("Another user account activated", Object.assign({}, eventProperties));
  }
}
/**
 * Another user account deactivated: Whenever a user is deactivated
 *
 * When to trigger this event:
 * 1. Users list page
 * View in Avo: https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/9Ak8FvFaj/trigger/AdNHe1BKcX
 * 2. From API
 * View in Avo: https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/9Ak8FvFaj/trigger/1jjX5V3Pin
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 * @param properties.userEmailid: no description
 * @param properties.updateGroupPropertiesForGroupSubdomain (id of the group_subdomain group to update group properties for): no description
 * @param properties.numberOfActiveUsers (group_subdomain group property): This is will keep track of number of users in a company setting up their account
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/9Ak8FvFaj}
 */
function anotherUserAccountDeactivated(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  eventPropertiesArray.push({
    id: "5LXlRqSQM",
    name: "user_emailid",
    value: properties.userEmailid
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "9Ak8FvFaj", "b45cd2ac3b71081fa594e8ed7a07a0e75eacb25f2b2df2d57bc184915d03112a", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Another user account deactivated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("9Ak8FvFaj", "Another user account deactivated", messages, eventPropertiesArray, userPropertiesArray, [{
        id: "qoiwk1HJEb",
        groupName: "group_subdomain",
        groupId: properties.updateGroupPropertiesForGroupSubdomain,
        name: "number_of_active_users",
        value: properties.numberOfActiveUsers
      }]);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Another user account deactivated", eventProperties, "9Ak8FvFaj", "b45cd2ac3b71081fa594e8ed7a07a0e75eacb25f2b2df2d57bc184915d03112a");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.setGroupProperties("group_subdomain", properties.updateGroupPropertiesForGroupSubdomain, {
      number_of_active_users: properties.numberOfActiveUsers
    });
    MixpanelP1Only.logEvent("Another user account deactivated", Object.assign({}, eventProperties));
  }
}
/**
 * Hubspot integration connected: No description
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 * @param properties.marketingConnected: no description
 * @param properties.updateGroupPropertiesForGroupSubdomain (id of the group_subdomain group to update group properties for): no description
 * @param properties.connectedMarketing (group_subdomain group property): no description
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/_7F8PnTTT}
 */
function hubspotIntegrationConnected(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  userPropertiesArray.push({
    id: "0fzPdT7qRB",
    name: "marketing_connected",
    value: properties.marketingConnected
  });
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "_7F8PnTTT", "92347ea02e0af227daa1543d47144dad3d5af29e4149d534968498a8ffc7716f", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Hubspot integration connected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("_7F8PnTTT", "Hubspot integration connected", messages, eventPropertiesArray, userPropertiesArray, [{
        id: "BWkfSNpQC",
        groupName: "group_subdomain",
        groupId: properties.updateGroupPropertiesForGroupSubdomain,
        name: "connected_marketing",
        value: properties.connectedMarketing
      }]);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Hubspot integration connected", eventProperties, "_7F8PnTTT", "92347ea02e0af227daa1543d47144dad3d5af29e4149d534968498a8ffc7716f");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.setUserProperties(properties.userId_, Object.assign({}, userProperties));
    MixpanelP1Only.setGroupProperties("group_subdomain", properties.updateGroupPropertiesForGroupSubdomain, {
      connected_marketing: properties.connectedMarketing
    });
    MixpanelP1Only.logEvent("Hubspot integration connected", Object.assign({}, eventProperties));
  }
}
/**
 * Tiering clicked: When tiering is clicked in Settings
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/V8J63phut}
 */
function tieringClicked(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "V8J63phut", "d3491f781c0cb48296a4e4dde22dda53765faaa22ff0967b9ba9d8916ca6159e", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Tiering clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("V8J63phut", "Tiering clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Tiering clicked", eventProperties, "V8J63phut", "d3491f781c0cb48296a4e4dde22dda53765faaa22ff0967b9ba9d8916ca6159e");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.logEvent("Tiering clicked", Object.assign({}, eventProperties));
  }
}
/**
   * User activated: Whenever a new user activates their account by setting up the password.
   *
   * When to trigger this event:
   * 1. When an account creator is creating the account for the first time, the trigger will be when they click on "Get Started"
   * View in Avo: https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/Wsjh6HMiJz/trigger/SSESPt6ph
   * 2. For a user (who is not the creator of the account), trigger will be clicking on "continue" button
   * View in Avo: https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/Wsjh6HMiJz/trigger/cKDlHkZdO
   *
   * @param properties the properties associatied with this event
   * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
   * @param properties.onboardingCompleted: This property will tell the onboarding status of the user
  It will be 'yes if user has completed all the modules and 'no' otherwise. When a user activates the account, this will by default be 'no'
   * @param properties.onboardingAbandoned: updated when user quits tutorial when going through onboarding.
  If the user resumes and completes the tutorial this property will be updated accordingly. This property will track the module at which the user has abandoned the onboarding
  If the user's onboarding is completed then this should be updated as 'No'
   * @param properties.name: no description
   * @param properties.email: no description
   * @param properties.associateUserWithGroupSubdomain (id of the group_subdomain group to associate current user with): no description
   * @param properties.updateGroupPropertiesForGroupSubdomain (id of the group_subdomain group to update group properties for): no description
   * @param properties.numberOfActiveUsers (group_subdomain group property): This is will keep track of number of users in a company setting up their account
   *
   * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/Wsjh6HMiJz}
   */
function userActivated(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  userPropertiesArray.push({
    id: "XXtHuNlP03",
    name: "onboarding_completed",
    value: properties.onboardingCompleted
  });
  userPropertiesArray.push({
    id: "vLQDZe7bSh",
    name: "onboarding_abandoned",
    value: properties.onboardingAbandoned
  });
  userPropertiesArray.push({
    id: "70pew0ubSb",
    name: "$name",
    value: properties.name
  });
  userPropertiesArray.push({
    id: "8SPu6gsq_F",
    name: "$email",
    value: properties.email
  });
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Wsjh6HMiJz", "f1582258509f61b719372c4a5fae6a61a8af0507cce421c0914bfcf827000670", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("User activated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Wsjh6HMiJz", "User activated", messages, eventPropertiesArray, userPropertiesArray, [{
        id: "2d902b4b-fb88-4844-9dc1-88e8cb0c5a22user",
        groupName: "group_subdomain",
        groupId: "2d902b4b-fb88-4844-9dc1-88e8cb0c5a22",
        name: "Associates user with group",
        value: properties.associateUserWithGroupSubdomain
      }, {
        id: "qoiwk1HJEb",
        groupName: "group_subdomain",
        groupId: properties.updateGroupPropertiesForGroupSubdomain,
        name: "number_of_active_users",
        value: properties.numberOfActiveUsers
      }]);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("User activated", eventProperties, "Wsjh6HMiJz", "f1582258509f61b719372c4a5fae6a61a8af0507cce421c0914bfcf827000670");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.setUserProperties(properties.userId_, Object.assign({}, userProperties));
    MixpanelP1Only.addCurrentUserToGroup("group_subdomain", properties.associateUserWithGroupSubdomain, {
      number_of_active_users: properties.numberOfActiveUsers
    });
    MixpanelP1Only.setGroupProperties("group_subdomain", properties.updateGroupPropertiesForGroupSubdomain, {
      number_of_active_users: properties.numberOfActiveUsers
    });
    MixpanelP1Only.logEvent("User activated", Object.assign({}, eventProperties));
  }
}
/**
 * Commission rule created: Whenever a new commission rule is created in a partnerAlign account
 *
 * When to trigger this event:
 * 1. when create button is clicked and response is successful from be - the rule is successfully created
 * View in Avo: https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/hdjBlDh92F/trigger/sJddmY19V
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 * @param properties.action: which action has been triggered?
 * @param properties.updateGroupPropertiesForGroupSubdomain (id of the group_subdomain group to update group properties for): no description
 * @param properties.numberOfActiveCommissionRules (group_subdomain group property): Number of active commission rules in the customer account
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/hdjBlDh92F}
 */
function commissionRuleCreated(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  eventPropertiesArray.push({
    id: "Tgdvdil1Y",
    name: "action",
    value: properties.action
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "hdjBlDh92F", "811d2f37e0d215e664cc2a65f4ded89ee295c448d74b7b912045b5eec33f1bcc", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Commission rule created", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("hdjBlDh92F", "Commission rule created", messages, eventPropertiesArray, userPropertiesArray, [{
        id: "MN0Mf9XpDA",
        groupName: "group_subdomain",
        groupId: properties.updateGroupPropertiesForGroupSubdomain,
        name: "number_of_active_commission_rules",
        value: properties.numberOfActiveCommissionRules
      }]);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Commission rule created", eventProperties, "hdjBlDh92F", "811d2f37e0d215e664cc2a65f4ded89ee295c448d74b7b912045b5eec33f1bcc");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.setGroupProperties("group_subdomain", properties.updateGroupPropertiesForGroupSubdomain, {
      number_of_active_commission_rules: properties.numberOfActiveCommissionRules
    });
    MixpanelP1Only.logEvent("Commission rule created", Object.assign({}, eventProperties));
  }
}
/**
 * Commission rule re-activated: Whenever a commission rule is re-activated in a partnerAlign account
 *
 * When to trigger this event:
 * 1.
 * View in Avo: https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/alT6S7iF4/trigger/s76Suqppn8
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 * @param properties.updateGroupPropertiesForGroupSubdomain (id of the group_subdomain group to update group properties for): no description
 * @param properties.numberOfActiveCommissionRules (group_subdomain group property): Number of active commission rules in the customer account
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/alT6S7iF4}
 */
function commissionRuleReActivated(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "alT6S7iF4", "5e7517e96908afe8c0d0fc4b47ca5219baaa081a29537c49c8621116c60c07dc", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Commission rule re-activated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("alT6S7iF4", "Commission rule re-activated", messages, eventPropertiesArray, userPropertiesArray, [{
        id: "MN0Mf9XpDA",
        groupName: "group_subdomain",
        groupId: properties.updateGroupPropertiesForGroupSubdomain,
        name: "number_of_active_commission_rules",
        value: properties.numberOfActiveCommissionRules
      }]);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Commission rule re-activated", eventProperties, "alT6S7iF4", "5e7517e96908afe8c0d0fc4b47ca5219baaa081a29537c49c8621116c60c07dc");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.setGroupProperties("group_subdomain", properties.updateGroupPropertiesForGroupSubdomain, {
      number_of_active_commission_rules: properties.numberOfActiveCommissionRules
    });
    MixpanelP1Only.logEvent("Commission rule re-activated", Object.assign({}, eventProperties));
  }
}
/**
 * Commission rule deactivated: Whenever a commission rule is deactivated in a partnerAlign account
 *
 * When to trigger this event:
 * 1.
 * View in Avo: https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/duVF_bCmo/trigger/Zc4piIqr69
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 * @param properties.updateGroupPropertiesForGroupSubdomain (id of the group_subdomain group to update group properties for): no description
 * @param properties.numberOfActiveCommissionRules (group_subdomain group property): Number of active commission rules in the customer account
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/duVF_bCmo}
 */
function commissionRuleDeactivated(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "duVF_bCmo", "21d327c3b3571d1c79c6ca829dee68086c057e2c2a9deaef0f9c91ac63324c78", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Commission rule deactivated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("duVF_bCmo", "Commission rule deactivated", messages, eventPropertiesArray, userPropertiesArray, [{
        id: "MN0Mf9XpDA",
        groupName: "group_subdomain",
        groupId: properties.updateGroupPropertiesForGroupSubdomain,
        name: "number_of_active_commission_rules",
        value: properties.numberOfActiveCommissionRules
      }]);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Commission rule deactivated", eventProperties, "duVF_bCmo", "21d327c3b3571d1c79c6ca829dee68086c057e2c2a9deaef0f9c91ac63324c78");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.setGroupProperties("group_subdomain", properties.updateGroupPropertiesForGroupSubdomain, {
      number_of_active_commission_rules: properties.numberOfActiveCommissionRules
    });
    MixpanelP1Only.logEvent("Commission rule deactivated", Object.assign({}, eventProperties));
  }
}
/**
 * Recurring commission record deleted: whenever a new recurring commission record is deleted
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/3gVklAuPA}
 */
function recurringCommissionRecordDeleted(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "3gVklAuPA", "d62380c403cc42b44b2c3346de3a8bcb9d9c87c590064589ab4ef2e14181c223", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Recurring commission record deleted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("3gVklAuPA", "Recurring commission record deleted", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Recurring commission record deleted", eventProperties, "3gVklAuPA", "d62380c403cc42b44b2c3346de3a8bcb9d9c87c590064589ab4ef2e14181c223");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.logEvent("Recurring commission record deleted", Object.assign({}, eventProperties));
  }
}
/**
 * Finance module clicked: whenever the finance module icon is clicked on the navbar - or if the url is opened directly.
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/_NiRUFoNcZ}
 */
function financeModuleClicked(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "_NiRUFoNcZ", "cfe6ba6aeea842e786d04ae35f2580b32629b0f630259540dfaca5c321d6a39a", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Finance module clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("_NiRUFoNcZ", "Finance module clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Finance module clicked", eventProperties, "_NiRUFoNcZ", "cfe6ba6aeea842e786d04ae35f2580b32629b0f630259540dfaca5c321d6a39a");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.logEvent("Finance module clicked", Object.assign({}, eventProperties));
  }
}
/**
 * Invoice added from ui: whenever an invoice is added tov partneralign manually from the UI
 *
 * When to trigger this event:
 * 1.
 * View in Avo: https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/-6oQRLOOHA/trigger/lwXAs1cD9
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 * @param properties.billTo: no description
 * @param properties.amount: no description
 * @param properties.transactionCreated: no description
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/-6oQRLOOHA}
 */
function invoiceAddedFromUi(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  eventPropertiesArray.push({
    id: "hm7XYDtml",
    name: "bill_to",
    value: properties.billTo
  });
  eventPropertiesArray.push({
    id: "R8F_Q6qtJ",
    name: "amount",
    value: properties.amount
  });
  eventPropertiesArray.push({
    id: "c3Z9mZa6D",
    name: "transaction_created",
    value: properties.transactionCreated
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "-6oQRLOOHA", "cb3880a0b46ec10b83275ec4bc4e6c31760570dcaf27435efad94c1490b0899d", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Invoice added from ui", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("-6oQRLOOHA", "Invoice added from ui", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Invoice added from ui", eventProperties, "-6oQRLOOHA", "cb3880a0b46ec10b83275ec4bc4e6c31760570dcaf27435efad94c1490b0899d");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.logEvent("Invoice added from ui", Object.assign({}, eventProperties));
  }
}
/**
   * Invoices imported: whenever invoices are imported - failed or successfully imported
   *
   * When to trigger this event:
   * 1. when all the steps are completed and final done is clicked
   * View in Avo: https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/fp-3AUmgq0/trigger/hcbZNce2l
   *
   * @param properties the properties associatied with this event
   * @param properties.psaName: no description
   * @param properties.permissionLevel: no description
   * @param properties.userAccess: User's access permission in grow
   * @param properties.fromPage: page that the user is coming from
   * @param properties.pageName: The page where the user is in the app
   * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
   * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
   * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
   * @param properties.transactionCreated: no description
   * @param properties.numberOfInvoicesImported: no description
   * @param properties.importStatus: when the import is a success (even if with 0 rows) - successful
  if the import fails for some reason - failed
   *
   * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/fp-3AUmgq0}
   */
function invoicesImported(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  eventPropertiesArray.push({
    id: "c3Z9mZa6D",
    name: "transaction_created",
    value: properties.transactionCreated
  });
  eventPropertiesArray.push({
    id: "g0vfYeHgG",
    name: "number_of_invoices_imported",
    value: properties.numberOfInvoicesImported
  });
  eventPropertiesArray.push({
    id: "ZX28yOzvq",
    name: "import_status",
    value: properties.importStatus
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "fp-3AUmgq0", "f00b7575fbc03f8f79a096ff6e49ebceda876b47aaf7d3807b350bebf3569c38", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Invoices imported", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("fp-3AUmgq0", "Invoices imported", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Invoices imported", eventProperties, "fp-3AUmgq0", "f00b7575fbc03f8f79a096ff6e49ebceda876b47aaf7d3807b350bebf3569c38");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.logEvent("Invoices imported", Object.assign({}, eventProperties));
  }
}
/**
 * Transaction created: whenever a transaction is created - manually from ui
 *
 * When to trigger this event:
 * 1.
 * View in Avo: https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/vVbH4oL4rb/trigger/wru1p2JZ1
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 * @param properties.transactionType: no description
 * @param properties.amount: no description
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/vVbH4oL4rb}
 */
function transactionCreated(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  eventPropertiesArray.push({
    id: "otCXf6c5f",
    name: "transaction_type",
    value: properties.transactionType
  });
  eventPropertiesArray.push({
    id: "R8F_Q6qtJ",
    name: "amount",
    value: properties.amount
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "vVbH4oL4rb", "c7d1067cbc4ca5b83364e0d8ee588cd8739cd4ca0a5fd960e65d3d12d692886c", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Transaction created", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("vVbH4oL4rb", "Transaction created", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Transaction created", eventProperties, "vVbH4oL4rb", "c7d1067cbc4ca5b83364e0d8ee588cd8739cd4ca0a5fd960e65d3d12d692886c");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.logEvent("Transaction created", Object.assign({}, eventProperties));
  }
}
/**
 * Recurring commission record created [from ui]: whenever a new recurring commission record is created - either from ui
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 * @param properties.updateGroupPropertiesForGroupSubdomain (id of the group_subdomain group to update group properties for): no description
 * @param properties.numberOfRecurringCommissionRecords (group_subdomain group property): Total number of recurring commission records on the company account
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/0YHrF7qbQ}
 */
function recurringCommissionRecordCreatedFromUi(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "0YHrF7qbQ", "ff1ee2a494f1debdcc84d35f7a3828b36d5cf4a603755329313b1e275a6a650e", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Recurring commission record created [from ui]", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("0YHrF7qbQ", "Recurring commission record created [from ui]", messages, eventPropertiesArray, userPropertiesArray, [{
        id: "hCP0SlCNWb",
        groupName: "group_subdomain",
        groupId: properties.updateGroupPropertiesForGroupSubdomain,
        name: "number_of_recurring_commission_records",
        value: properties.numberOfRecurringCommissionRecords
      }]);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Recurring commission record created [from ui]", eventProperties, "0YHrF7qbQ", "ff1ee2a494f1debdcc84d35f7a3828b36d5cf4a603755329313b1e275a6a650e");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.setGroupProperties("group_subdomain", properties.updateGroupPropertiesForGroupSubdomain, {
      number_of_recurring_commission_records: properties.numberOfRecurringCommissionRecords
    });
    MixpanelP1Only.logEvent("Recurring commission record created [from ui]", Object.assign({}, eventProperties));
  }
}
/**
   * Transactions imported: whenever a transaction is imported - manually from ui
   *
   * When to trigger this event:
   * 1.
   * View in Avo: https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/--pPRL374/trigger/NJESVM02y5
   *
   * @param properties the properties associatied with this event
   * @param properties.psaName: no description
   * @param properties.permissionLevel: no description
   * @param properties.userAccess: User's access permission in grow
   * @param properties.fromPage: page that the user is coming from
   * @param properties.pageName: The page where the user is in the app
   * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
   * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
   * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
   * @param properties.numberOfTransactionsImported: no description
   * @param properties.importStatus: when the import is a success (even if with 0 rows) - successful
  if the import fails for some reason - failed
   *
   * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/--pPRL374}
   */
function transactionsImported(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  eventPropertiesArray.push({
    id: "gS7RKf5W8",
    name: "number_of_transactions_imported",
    value: properties.numberOfTransactionsImported
  });
  eventPropertiesArray.push({
    id: "ZX28yOzvq",
    name: "import_status",
    value: properties.importStatus
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "--pPRL374", "ce0213386e676b5b765094f7026a083e19d858f4dc385cff5599338058c5d59c", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Transactions imported", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("--pPRL374", "Transactions imported", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Transactions imported", eventProperties, "--pPRL374", "ce0213386e676b5b765094f7026a083e19d858f4dc385cff5599338058c5d59c");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.logEvent("Transactions imported", Object.assign({}, eventProperties));
  }
}
/**
 * Commission rule deleted: Whenever a commission rule is deleted in a partnerAlign account
 *
 * When to trigger this event:
 * 1.
 * View in Avo: https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/Luvmm0EHJ/trigger/OYBjtgM8Pf
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 * @param properties.updateGroupPropertiesForGroupSubdomain (id of the group_subdomain group to update group properties for): no description
 * @param properties.numberOfActiveCommissionRules (group_subdomain group property): Number of active commission rules in the customer account
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/Luvmm0EHJ}
 */
function commissionRuleDeleted(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Luvmm0EHJ", "733df7b72d9f5dfb781e061e438d251f831111da3d25ea7fc6dea94ecd4b47dc", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Commission rule deleted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Luvmm0EHJ", "Commission rule deleted", messages, eventPropertiesArray, userPropertiesArray, [{
        id: "MN0Mf9XpDA",
        groupName: "group_subdomain",
        groupId: properties.updateGroupPropertiesForGroupSubdomain,
        name: "number_of_active_commission_rules",
        value: properties.numberOfActiveCommissionRules
      }]);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Commission rule deleted", eventProperties, "Luvmm0EHJ", "733df7b72d9f5dfb781e061e438d251f831111da3d25ea7fc6dea94ecd4b47dc");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.setGroupProperties("group_subdomain", properties.updateGroupPropertiesForGroupSubdomain, {
      number_of_active_commission_rules: properties.numberOfActiveCommissionRules
    });
    MixpanelP1Only.logEvent("Commission rule deleted", Object.assign({}, eventProperties));
  }
}
/**
 * Commission records loaded: when the commisiions records tab is opened first time on the finace page in a session
 *
 * @param properties the properties associatied with this event
 * @param properties.psaName: no description
 * @param properties.permissionLevel: no description
 * @param properties.userAccess: User's access permission in grow
 * @param properties.fromPage: page that the user is coming from
 * @param properties.pageName: The page where the user is in the app
 * @param properties.planType: The pricing-plan that the account is on. Comes from Chargebee.
 * @param properties.groupSubdomain: Which company is this? (multiple users are assigned to same company)
 * @param properties.productName: Which product is this event sent from? (Grow/PartnerAlign/Connect)
 * @param properties.updateGroupPropertiesForGroupSubdomain (id of the group_subdomain group to update group properties for): no description
 * @param properties.numberOfActiveCommissionRules (group_subdomain group property): Number of active commission rules in the customer account
 * @param properties.numberOfRecurringCommissionRecords (group_subdomain group property): Total number of recurring commission records on the company account
 *
 * @see {@link https://www.avo.app/schemas/Xe7nuN8AtWMZ3ADRuw3t/branches/Yo9Selmx1/events/_8GqnZMsy}
 */
function commissionRecordsLoaded(properties) {
  // @ts-ignore
  var eventPropertiesArray = [];
  eventPropertiesArray.push({
    id: "JVmKkV_QNx",
    name: "psa_name",
    value: properties.psaName
  });
  eventPropertiesArray.push({
    id: "18LcHu1o3I",
    name: "permission_level",
    value: properties.permissionLevel
  });
  eventPropertiesArray.push({
    id: "HbdjmfmFnj",
    name: "user_access",
    value: properties.userAccess
  });
  eventPropertiesArray.push({
    id: "Xp0Sq8ChAW",
    name: "from_page",
    value: properties.fromPage
  });
  eventPropertiesArray.push({
    id: "920I6P4XgJ",
    name: "page_name",
    value: properties.pageName
  });
  eventPropertiesArray.push({
    id: "njwviXV-v",
    name: "plan_type",
    value: properties.planType
  });
  eventPropertiesArray.push({
    id: "5Tza6DGAx",
    name: "group_subdomain",
    value: properties.groupSubdomain
  });
  eventPropertiesArray.push({
    id: "6DfMEJCoi",
    name: "product_name",
    value: properties.productName
  });
  var eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  var userPropertiesArray = [];
  var userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== exports.AvoEnv.Prod || __WEB_DEBUGGER__) {
    var messages = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "_8GqnZMsy", "f54543b120150c3fb0c8d7b090f0263b6ddfbaa595147f9ea8e1b31e539ce548", messages.map(function (m) {
        return Object.assign({}, {
          tag: m.tag,
          propertyId: m.propertyId,
          additionalProperties: m.additionalProperties,
          actualType: m.actualType
        });
      }), "event");
    }
    InternalAvoLogger.logEventSent("Commission records loaded", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("_8GqnZMsy", "Commission records loaded", messages, eventPropertiesArray, userPropertiesArray, [{
        id: "MN0Mf9XpDA",
        groupName: "group_subdomain",
        groupId: properties.updateGroupPropertiesForGroupSubdomain,
        name: "number_of_active_commission_rules",
        value: properties.numberOfActiveCommissionRules
      }, {
        id: "hCP0SlCNWb",
        groupName: "group_subdomain",
        groupId: properties.updateGroupPropertiesForGroupSubdomain,
        name: "number_of_recurring_commission_records",
        value: properties.numberOfRecurringCommissionRecords
      }]);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Commission records loaded", eventProperties, "_8GqnZMsy", "f54543b120150c3fb0c8d7b090f0263b6ddfbaa595147f9ea8e1b31e539ce548");
    }
    // destination MixpanelP1Only
    MixpanelP1Only.setGroupProperties("group_subdomain", properties.updateGroupPropertiesForGroupSubdomain, {
      number_of_active_commission_rules: properties.numberOfActiveCommissionRules,
      number_of_recurring_commission_records: properties.numberOfRecurringCommissionRecords
    });
    MixpanelP1Only.logEvent("Commission records loaded", Object.assign({}, eventProperties));
  }
}
var PartnerAlignAvo = {
  AvoEnv: exports.AvoEnv,
  initAvo: initAvo,
  avoInspectorApiKey: avoInspectorApiKey,
  MarketingConnected: MarketingConnected,
  PermissionLevel: PermissionLevel,
  ProductName: ProductName,
  ConnectedMarketing: ConnectedMarketing,
  UserAccess: UserAccess,
  PsaName: PsaName,
  SignUpType: SignUpType,
  Action: Action,
  OnboardingCompleted: OnboardingCompleted,
  ImportStatus: ImportStatus,
  BillTo: BillTo,
  TransactionType: TransactionType,
  OnboardingAbandoned: OnboardingAbandoned,
  accountClicked: accountClicked,
  hubspotIntegrationDisconnected: hubspotIntegrationDisconnected,
  newEmailTemplateAdded: newEmailTemplateAdded,
  partnersClicked: partnersClicked,
  pipelineStagesReordered: pipelineStagesReordered,
  dashboardClicked: dashboardClicked,
  anotherUserAddedToAccount: anotherUserAddedToAccount,
  userLoggedIn: userLoggedIn,
  anotherUserAccountActivated: anotherUserAccountActivated,
  anotherUserAccountDeactivated: anotherUserAccountDeactivated,
  hubspotIntegrationConnected: hubspotIntegrationConnected,
  tieringClicked: tieringClicked,
  userActivated: userActivated,
  commissionRuleCreated: commissionRuleCreated,
  commissionRuleReActivated: commissionRuleReActivated,
  commissionRuleDeactivated: commissionRuleDeactivated,
  recurringCommissionRecordDeleted: recurringCommissionRecordDeleted,
  financeModuleClicked: financeModuleClicked,
  invoiceAddedFromUi: invoiceAddedFromUi,
  invoicesImported: invoicesImported,
  transactionCreated: transactionCreated,
  recurringCommissionRecordCreatedFromUi: recurringCommissionRecordCreatedFromUi,
  transactionsImported: transactionsImported,
  commissionRuleDeleted: commissionRuleDeleted,
  commissionRecordsLoaded: commissionRecordsLoaded
};
// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["accountClicked","hubspotIntegrationDisconnected","newEmailTemplateAdded","partnersClicked","pipelineStagesReordered","dashboardClicked","anotherUserAddedToAccount","userLoggedIn","anotherUserAccountActivated","anotherUserAccountDeactivated","hubspotIntegrationConnected","tieringClicked","userActivated","commissionRuleCreated","commissionRuleReActivated","commissionRuleDeactivated","recurringCommissionRecordDeleted","financeModuleClicked","invoiceAddedFromUi","invoicesImported","transactionCreated","recurringCommissionRecordCreatedFromUi","transactionsImported","commissionRuleDeleted","commissionRecordsLoaded"]

exports.Action = Action;
exports.BillTo = BillTo;
exports.ConnectedMarketing = ConnectedMarketing;
exports.ImportStatus = ImportStatus;
exports.MarketingConnected = MarketingConnected;
exports.OnboardingAbandoned = OnboardingAbandoned;
exports.OnboardingCompleted = OnboardingCompleted;
exports.PermissionLevel = PermissionLevel;
exports.ProductName = ProductName;
exports.PsaName = PsaName;
exports.SignUpType = SignUpType;
exports.TransactionType = TransactionType;
exports.UserAccess = UserAccess;
exports.accountClicked = accountClicked;
exports.anotherUserAccountActivated = anotherUserAccountActivated;
exports.anotherUserAccountDeactivated = anotherUserAccountDeactivated;
exports.anotherUserAddedToAccount = anotherUserAddedToAccount;
exports.avoInspectorApiKey = avoInspectorApiKey;
exports.commissionRecordsLoaded = commissionRecordsLoaded;
exports.commissionRuleCreated = commissionRuleCreated;
exports.commissionRuleDeactivated = commissionRuleDeactivated;
exports.commissionRuleDeleted = commissionRuleDeleted;
exports.commissionRuleReActivated = commissionRuleReActivated;
exports.dashboardClicked = dashboardClicked;
exports["default"] = PartnerAlignAvo;
exports.financeModuleClicked = financeModuleClicked;
exports.hubspotIntegrationConnected = hubspotIntegrationConnected;
exports.hubspotIntegrationDisconnected = hubspotIntegrationDisconnected;
exports.initAvo = initAvo;
exports.invoiceAddedFromUi = invoiceAddedFromUi;
exports.invoicesImported = invoicesImported;
exports.newEmailTemplateAdded = newEmailTemplateAdded;
exports.partnersClicked = partnersClicked;
exports.pipelineStagesReordered = pipelineStagesReordered;
exports.recurringCommissionRecordCreatedFromUi = recurringCommissionRecordCreatedFromUi;
exports.recurringCommissionRecordDeleted = recurringCommissionRecordDeleted;
exports.tieringClicked = tieringClicked;
exports.transactionCreated = transactionCreated;
exports.transactionsImported = transactionsImported;
exports.userActivated = userActivated;
exports.userLoggedIn = userLoggedIn;
