'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../node_modules/tslib/tslib.es6.mjs.js');
var React = require('react');

var useHubspotFieldMapping = function (hubspotIntegration, isAlreadyConnected) {
  var getInitialSelectedPipelineStages = function () {
    var options = hubspotIntegration === null || hubspotIntegration === void 0 ? void 0 : hubspotIntegration.hubspot_opportunity_pipeline_mapping.reduce(function (finalResult, current) {
      var stagesUnderCurrentPipeline = current.hubspot_pipeline_stage_mapping.map(function (stageMapping) {
        return stageMapping.hubspot_stage_details.hubspot_stage_id;
      });
      return tslib_es6.__spreadArray(tslib_es6.__spreadArray([], finalResult, true), stagesUnderCurrentPipeline, true);
    }, []);
    return options !== null && options !== void 0 ? options : [];
  };
  var getInitialSelectedHubspotPipelineStageMapping = function () {
    var mappings = hubspotIntegration === null || hubspotIntegration === void 0 ? void 0 : hubspotIntegration.hubspot_opportunity_pipeline_mapping.reduce(function (finalResult, current) {
      current.hubspot_pipeline_stage_mapping.forEach(function (stageMapping) {
        var _a;
        finalResult = tslib_es6.__assign(tslib_es6.__assign({}, finalResult), (_a = {}, _a[stageMapping.zomentum_stage_details.zomentum_stage_id] = stageMapping.hubspot_stage_details.hubspot_stage_id, _a));
      });
      return finalResult;
    }, {});
    return mappings !== null && mappings !== void 0 ? mappings : {};
  };
  var _a = React.useState(getInitialSelectedPipelineStages()),
    selectedHubspotPipelineStages = _a[0],
    setSelectedHubspotPipelineStages = _a[1];
  var _b = React.useState(getInitialSelectedHubspotPipelineStageMapping()),
    selectedHubspotPipelineStageMapping = _b[0],
    setSelectedHubspotPipelineStageMapping = _b[1];
  var initialValueForPipelineStage = function (stageId) {
    var _a;
    if (isAlreadyConnected) {
      var selectedPipeline = (_a = hubspotIntegration === null || hubspotIntegration === void 0 ? void 0 : hubspotIntegration.hubspot_opportunity_pipeline_mapping.find(function (pipelineMapping) {
        return pipelineMapping.hubspot_pipeline_stage_mapping.find(function (stage) {
          return stage.zomentum_stage_details.zomentum_stage_id === stageId;
        });
      })) === null || _a === void 0 ? void 0 : _a.hubspot_pipeline_stage_mapping.find(function (stage) {
        return stage.zomentum_stage_details.zomentum_stage_id === stageId;
      });
      if (!selectedPipeline) {
        return undefined;
      }
      return [selectedPipeline.hubspot_pipeline_id, selectedPipeline.hubspot_stage_details.hubspot_stage_id];
    }
    return undefined;
  };
  var handleStageSelect = function (stageId, value) {
    var _a, _b;
    if (!selectedHubspotPipelineStageMapping) {
      if (value) {
        var obj = (_a = {}, _a[stageId] = value, _a);
        if (!selectedHubspotPipelineStages.includes(value)) {
          setSelectedHubspotPipelineStages(function (state) {
            return tslib_es6.__spreadArray(tslib_es6.__spreadArray([], state, true), [value], false);
          });
        }
        setSelectedHubspotPipelineStageMapping(obj);
        return;
      }
    }
    if (!value) {
      var prevValue_1 = selectedHubspotPipelineStageMapping[stageId];
      var updatedObj = tslib_es6.__assign({}, selectedHubspotPipelineStageMapping);
      delete updatedObj[stageId];
      setSelectedHubspotPipelineStages(function (state) {
        return state.filter(function (s) {
          return s !== prevValue_1;
        });
      });
      setSelectedHubspotPipelineStageMapping(updatedObj);
    }
    var prevSelectedValue = selectedHubspotPipelineStageMapping[stageId];
    var updatedSelectedHubspotPipelineStages = prevSelectedValue ? selectedHubspotPipelineStages.map(function (stage) {
      if (stage === prevSelectedValue) {
        return value;
      }
      return stage;
    }) : tslib_es6.__spreadArray(tslib_es6.__spreadArray([], selectedHubspotPipelineStages, true), [value], false);
    var newObj = tslib_es6.__assign(tslib_es6.__assign({}, selectedHubspotPipelineStageMapping), (_b = {}, _b[stageId] = value, _b));
    setSelectedHubspotPipelineStages(updatedSelectedHubspotPipelineStages);
    setSelectedHubspotPipelineStageMapping(newObj);
  };
  var initialDefaultZomentumStage = function () {
    var _a, _b;
    var defaultZomentumStage = (_b = (_a = hubspotIntegration === null || hubspotIntegration === void 0 ? void 0 : hubspotIntegration.hubspot_pull_settings) === null || _a === void 0 ? void 0 : _a.hubspot_default_pull_entities) === null || _b === void 0 ? void 0 : _b.default_zomentum_pipeline_details;
    if (!defaultZomentumStage) {
      return undefined;
    }
    return [defaultZomentumStage.zomentum_pipeline_id, defaultZomentumStage.zomentum_stage_id];
  };
  var initialDefaultHubspotStage = function () {
    var _a, _b;
    var defaultHubspotStage = (_b = (_a = hubspotIntegration === null || hubspotIntegration === void 0 ? void 0 : hubspotIntegration.hubspot_pull_settings) === null || _a === void 0 ? void 0 : _a.hubspot_default_pull_entities) === null || _b === void 0 ? void 0 : _b.default_hubspot_pipeline_details;
    if (!defaultHubspotStage) {
      return undefined;
    }
    return [defaultHubspotStage.hubspot_pipeline_id, defaultHubspotStage.hubspot_stage_id];
  };
  var loadInitialMappingValues = function () {
    setSelectedHubspotPipelineStages(getInitialSelectedPipelineStages());
    setSelectedHubspotPipelineStageMapping(getInitialSelectedHubspotPipelineStageMapping());
  };
  var initialValueForOpportunityField = function (opportunityField) {
    var _a;
    if (isAlreadyConnected) {
      var hubspotField = (_a = hubspotIntegration === null || hubspotIntegration === void 0 ? void 0 : hubspotIntegration.opportunity_field_mapping.find(function (opportunityMapping) {
        return opportunityMapping.zomentum_field === opportunityField;
      })) === null || _a === void 0 ? void 0 : _a.hubspot_field;
      if (!hubspotField) {
        return undefined;
      }
      return hubspotField;
    }
    return undefined;
  };
  var initialValueForClientField = function (clientField) {
    var _a, _b;
    if (isAlreadyConnected) {
      var hubspotField = (_b = (_a = hubspotIntegration === null || hubspotIntegration === void 0 ? void 0 : hubspotIntegration.client_company_field_mapping) === null || _a === void 0 ? void 0 : _a.find(function (clientMapping) {
        return clientMapping.zomentum_field === clientField;
      })) === null || _b === void 0 ? void 0 : _b.hubspot_field;
      if (!hubspotField) {
        return undefined;
      }
      return hubspotField;
    }
    return undefined;
  };
  var initialValueForPartnerField = function (clientField) {
    var _a;
    if (isAlreadyConnected) {
      var hubspotField = (_a = hubspotIntegration === null || hubspotIntegration === void 0 ? void 0 : hubspotIntegration.client_company_field_mapping.find(function (clientMapping) {
        return clientMapping.zomentum_field === clientField;
      })) === null || _a === void 0 ? void 0 : _a.hubspot_field;
      if (!hubspotField) {
        return undefined;
      }
      return hubspotField;
    }
    return undefined;
  };
  var initialValueForClientContactField = function (contactField) {
    var _a, _b;
    if (isAlreadyConnected) {
      var hubspotField = (_b = (_a = hubspotIntegration === null || hubspotIntegration === void 0 ? void 0 : hubspotIntegration.client_user_field_mapping) === null || _a === void 0 ? void 0 : _a.find(function (userMapping) {
        return userMapping.zomentum_field === contactField;
      })) === null || _b === void 0 ? void 0 : _b.hubspot_field;
      if (!hubspotField) {
        return undefined;
      }
      return hubspotField;
    }
    return undefined;
  };
  var initialValueForPartnerContactField = function (contactField) {
    var _a;
    if (isAlreadyConnected) {
      var hubspotField = (_a = hubspotIntegration === null || hubspotIntegration === void 0 ? void 0 : hubspotIntegration.client_user_field_mapping.find(function (userMapping) {
        return userMapping.zomentum_field === contactField;
      })) === null || _a === void 0 ? void 0 : _a.hubspot_field;
      if (!hubspotField) {
        return undefined;
      }
      return hubspotField;
    }
    return undefined;
  };
  return {
    handleStageSelect: handleStageSelect,
    getInitialSelectedHubspotPipelineStageMapping: getInitialSelectedHubspotPipelineStageMapping,
    getInitialSelectedPipelineStages: getInitialSelectedPipelineStages,
    initialValueForPipelineStage: initialValueForPipelineStage,
    selectedHubspotPipelineStages: selectedHubspotPipelineStages,
    selectedHubspotPipelineStageMapping: selectedHubspotPipelineStageMapping,
    initialDefaultHubspotStage: initialDefaultHubspotStage,
    initialDefaultZomentumStage: initialDefaultZomentumStage,
    loadInitialMappingValues: loadInitialMappingValues,
    initialValueForOpportunityField: initialValueForOpportunityField,
    initialValueForClientField: initialValueForClientField,
    initialValueForPartnerField: initialValueForPartnerField,
    initialValueForPartnerContactField: initialValueForPartnerContactField,
    initialValueForClientContactField: initialValueForClientContactField
  };
};
var useHubspotFieldMapping$1 = useHubspotFieldMapping;

exports["default"] = useHubspotFieldMapping$1;
