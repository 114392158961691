'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var common = require('../common.js');

exports.BulkImportConflictResolution = void 0;
(function (BulkImportConflictResolution) {
  BulkImportConflictResolution["IgnoreUpdates"] = "ignore_updates";
  BulkImportConflictResolution["PreferExistingValue"] = "prefer_existing_value";
  BulkImportConflictResolution["OverrideExistingValue"] = "override_existing_value";
})(exports.BulkImportConflictResolution || (exports.BulkImportConflictResolution = {}));
exports.BulkImportErrorSeverityTypes = void 0;
(function (BulkImportErrorSeverityTypes) {
  BulkImportErrorSeverityTypes["SheetImportBlocked"] = "sheet_import_blocked";
  BulkImportErrorSeverityTypes["EntityInsertBlocked"] = "entity_insert_blocked";
  BulkImportErrorSeverityTypes["RowIgnored"] = "row_ignored";
})(exports.BulkImportErrorSeverityTypes || (exports.BulkImportErrorSeverityTypes = {}));
exports.BulkImportTemplateFileType = void 0;
(function (BulkImportTemplateFileType) {
  BulkImportTemplateFileType["csv"] = "csv";
  BulkImportTemplateFileType["xls"] = "xls";
})(exports.BulkImportTemplateFileType || (exports.BulkImportTemplateFileType = {}));
var getBulkImportEntityRoute = function getBulkImportEntityRoute(entity) {
  switch (entity) {
    case common.ZomentumEntities.Opportunity:
      {
        return common.ZomentumRoutes.OpportunityList;
      }
    case common.ZomentumEntities.Products:
      {
        return common.ZomentumRoutes.ProductsList;
      }
    case common.ZomentumEntities.Client:
    case common.ZomentumEntities.ClientContact:
      {
        return common.ZomentumRoutes.ClientsList;
      }
    default:
      {
        return common.ZomentumRoutes.Error;
      }
  }
};
var getBulkImportEntityLabel = function getBulkImportEntityLabel(entity) {
  switch (entity) {
    case common.ZomentumEntities.VendorOpportunity:
    case common.ZomentumEntities.Opportunity:
      {
        return "Opportunities";
      }
    case common.ZomentumEntities.MarketplaceSKU:
    case common.ZomentumEntities.Products:
      {
        return "Products";
      }
    case common.ZomentumEntities.VendorClient:
    case common.ZomentumEntities.Client:
      {
        return "Clients";
      }
    case common.ZomentumEntities.VendorClientContact:
    case common.ZomentumEntities.ClientContact:
      {
        return "Contacts";
      }
    case common.ZomentumEntities.Assessment:
      {
        return "Assessment Issues";
      }
    case common.ZomentumEntities.Qbr:
      {
        return "QBR Issues";
      }
    case common.ZomentumEntities.VendorPartner:
      {
        return "Partners";
      }
    default:
      {
        return "";
      }
  }
};

exports.getBulkImportEntityLabel = getBulkImportEntityLabel;
exports.getBulkImportEntityRoute = getBulkImportEntityRoute;
