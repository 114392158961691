'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../../../../node_modules/tslib/tslib.es6.mjs.js');
var index = require('../../../actions/Vendor/Opportunity/index.js');

var initialVendorOpportunityState = {
  isLoading: false,
  addOpportunityDrawerVisible: false,
  editOpportunityDrawerVisible: false
};
var VendorOpportunityReducer = function VendorOpportunityReducer(state, action) {
  if (state === void 0) {
    state = initialVendorOpportunityState;
  }
  switch (action.type) {
    case index.VendorOpportunityActionTypes.SHOW_VENDOR_OPPORTUNITY_LOADER:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          isLoading: true
        });
      }
    case index.VendorOpportunityActionTypes.HIDE_VENDOR_OPPORTUNITY_LOADER:
      {
        return tslib_es6.__assign(tslib_es6.__assign({}, state), {
          isLoading: false
        });
      }
    case index.VendorOpportunityActionTypes.OPEN_ADD_OPPORTUNITY_DRAWER:
      return tslib_es6.__assign(tslib_es6.__assign({}, state), {
        addOpportunityDrawerVisible: true
      });
    case index.VendorOpportunityActionTypes.CLOSE_ADD_OPPORTUNITY_DRAWER:
      return tslib_es6.__assign(tslib_es6.__assign({}, state), {
        addOpportunityDrawerVisible: false
      });
    case index.VendorOpportunityActionTypes.OPEN_EDIT_OPPORTUNITY_DRAWER:
      return tslib_es6.__assign(tslib_es6.__assign({}, state), {
        editOpportunityDrawerVisible: true
      });
    case index.VendorOpportunityActionTypes.CLOSE_EDIT_OPPORTUNITY_DRAWER:
      return tslib_es6.__assign(tslib_es6.__assign({}, state), {
        editOpportunityDrawerVisible: false
      });
    default:
      {
        return state;
      }
  }
};
var VendorOpportunityReducer$1 = VendorOpportunityReducer;

exports.VendorOpportunityReducer = VendorOpportunityReducer;
exports["default"] = VendorOpportunityReducer$1;
exports.initialVendorOpportunityState = initialVendorOpportunityState;
