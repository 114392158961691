import axios, { AxiosResponse } from "axios";

import {
	getRequest,
	postRequest,
	deleteRequest,
	putRequest
} from "@zomentum/utils/dist/Service/BackendService";

import { DeletedRecord } from "@zomentum/contracts";
import { getQueryParamAuthDetails } from "@zomentum/contracts/dist/Utils";
import {
	BlockListResponse,
	BlockRequest,
	Block,
	CloneBlockRequest,
	BlockResponse
} from "@zomentum/contracts/dist/ProposalBuilder/Block";
import { deepFreeze } from "@zomentum/utils";

const blockUrl = "/v2/blocks";
const getBlockListUrl =
	"/v2/blocks?document_id={document_id}&revision_id={revision_id}";
const getBlockDetailsUrl = "/v2/blocks/{block_id}";
const editBlockUrl = "/v2/blocks/{block_id}";
const editBlockFromClientUrl = "/v2/blocks/{blockId}/client";
const editBlockFromPublicClientUrl = "/v2/blocks/{blockId}/public-client";
const reorderChildrenBlocksUrl = "/v2/blocks/reorder/{block_id}";
const cloneBlockUrl = "/v2/blocks/clone";

// get block
export const getBlock = async (blockId: string): Promise<BlockResponse> => {
	try {
		const response: AxiosResponse<BlockResponse> = await axios(
			getRequest(
				getBlockDetailsUrl.replace("{block_id}", blockId),
				null,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

// get blocks by documentId
export const getBlockList = async (
	documentId: string,
	revisionId: string,
	limit: number | null,
	lastBlocKId: string | null
): Promise<BlockListResponse> => {
	try {
		let url = getBlockListUrl
			.replace("{document_id}", documentId)
			.replace("{revision_id}", revisionId);
		url += limit ? `&limit=${limit}` : "";
		url += lastBlocKId ? `&last_block_id=${lastBlocKId}` : "";
		const response: AxiosResponse<BlockListResponse> = await axios(
			getRequest(url, null, true, false, false)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

// add a new block
export const addBlock = async (
	addBlockRequest: BlockRequest
): Promise<BlockResponse> => {
	try {
		const response: AxiosResponse<BlockResponse> = await axios(
			postRequest(blockUrl, addBlockRequest, true, false, false)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

// update block
export const editBlock = async (block: Block): Promise<BlockResponse> => {
	try {
		const response: AxiosResponse<BlockResponse> = await axios(
			putRequest(
				editBlockUrl.replace("{block_id}", block.id),
				block,
				true,
				false,
				false
			)
		);
		return deepFreeze(response.data);
	} catch (error) {
		return Promise.reject(error);
	}
};

// reorder children blocks
export const reorderChildrenBlock = async (block: Block): Promise<Block> => {
	try {
		const response: AxiosResponse<Block> = await axios(
			putRequest(
				reorderChildrenBlocksUrl.replace("{block_id}", block.id),
				{
					children_blocks: block.children_blocks
				},
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

// delete block
export const deleteBlock = async (blockId: string): Promise<DeletedRecord> => {
	try {
		const response: AxiosResponse<DeletedRecord> = await axios(
			deleteRequest(`${blockUrl}/${blockId}`, null, true, false)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

// update block from client
export const editBlockFromClient = async (
	block: Block
): Promise<BlockResponse> => {
	try {
		// grab client id
		const queryParamAuthDetails = getQueryParamAuthDetails();
		const queryParam = queryParamAuthDetails
			? "?" + queryParamAuthDetails.queryParam + "&"
			: "";
		const response: AxiosResponse<BlockResponse> = await axios(
			putRequest(
				editBlockFromClientUrl.replace("{blockId}", block.id) +
					queryParam,
				block,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

// update block from public client - generic order form case
export const editBlockFromPublicClient = async (
	block: Block,
	companyId?: string
): Promise<BlockResponse> => {
	try {
		const queryParam = companyId ? `?company_id=${companyId}` : "";
		const response: AxiosResponse<BlockResponse> = await axios(
			putRequest(
				editBlockFromPublicClientUrl.replace("{blockId}", block.id) +
					queryParam,
				block,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

// clone a block
export const cloneBlock = async (
	cloneBlockRequest: CloneBlockRequest
): Promise<BlockResponse> => {
	try {
		const response: AxiosResponse<BlockResponse> = await axios(
			postRequest(cloneBlockUrl, cloneBlockRequest, true, false, false)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
