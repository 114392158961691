import { Dispatch, Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { GlobalState } from "@/Reducers/interface";
import {
	BulkUpdatesActionResultResponse,
	BulkUpdatesResultRequest
} from "@zomentum/contracts/dist/UI";
import { fetchBulkUpdatesRecords, fetchCollectJsToken } from "@/Services/UI";

export enum UIConfigActionTypes {
	CHECK_BEAMER_STATUS = "CHECK_BEAMER_STATUS",

	BULK_UPDATES_REQUESTED = "BULK_UPDATES_REQUESTED",
	BULK_UPDATES_SUCCESSFUL = "BULK_UPDATES_SUCCESSFUL",
	BULK_UPDATES_FAILED = "BULK_UPDATES_FAILED",

	BULK_UPDATES_RESET = "BULK_UPDATES_RESET",
	CHECK_FRESHCHAT_UNREAD_MESSAGES = "CHECK_FRESHCHAT_UNREAD_MESSAGES",

	ERROR_MESSAGE = "ERROR_MESSAGE",

	COLLECTJS_TOKEN_REQUESTED = "COLLECTJS_TOKEN_REQUESTED",
	COLLECTJS_TOKEN_FAILED = "COLLECTJS_TOKEN_FAILED",
	COLLECTJS_TOKEN_SUCCESSFUL = "COLLECTJS_TOKEN_SUCCESSFUL",

	UPDATE_MOBILE_VIEW = "UPDATE_MOBILE_VIEW"
}

export interface ShouldShowBeamerIconAction
	extends Action<UIConfigActionTypes.CHECK_BEAMER_STATUS> {
	readonly shouldShowBeamerIcon: boolean;
}

export type BulkUpdatesRequestedAction =
	Action<UIConfigActionTypes.BULK_UPDATES_REQUESTED>;
export interface BulkUpdatesSuccessfulAction
	extends Action<UIConfigActionTypes.BULK_UPDATES_SUCCESSFUL> {
	readonly bulkUpdatesResult: BulkUpdatesActionResultResponse;
}
export type BulkUpdatesFailedAction =
	Action<UIConfigActionTypes.BULK_UPDATES_FAILED>;
export type BulkUpdatesResetAction =
	Action<UIConfigActionTypes.BULK_UPDATES_RESET>;

export interface ShouldShowFreshChatBubbleAction
	extends Action<UIConfigActionTypes.CHECK_FRESHCHAT_UNREAD_MESSAGES> {
	readonly shouldShowFreshChatBubble: boolean;
}

export type CollectJSTokenRequestedAction =
	Action<UIConfigActionTypes.COLLECTJS_TOKEN_REQUESTED>;
export type CollectJSTokenFailedAction =
	Action<UIConfigActionTypes.COLLECTJS_TOKEN_FAILED>;
export interface CollectJSTokenSuccessfulAction
	extends Action<UIConfigActionTypes.COLLECTJS_TOKEN_SUCCESSFUL> {
	readonly collectjsToken: string;
}
export interface UpdateMobileViewAction
	extends Action<UIConfigActionTypes.UPDATE_MOBILE_VIEW> {
	readonly isMobileView: boolean;
}

export type UIConfigActions =
	| ShouldShowBeamerIconAction
	| BulkUpdatesRequestedAction
	| BulkUpdatesSuccessfulAction
	| BulkUpdatesFailedAction
	| BulkUpdatesResetAction
	| ShouldShowFreshChatBubbleAction
	| CollectJSTokenRequestedAction
	| CollectJSTokenFailedAction
	| CollectJSTokenSuccessfulAction
	| UpdateMobileViewAction;

export interface UIConfigDispatch {
	getBeamerIconStatus: ActionCreator<
		ThunkAction<
			Promise<ShouldShowBeamerIconAction>,
			GlobalState,
			number,
			ShouldShowBeamerIconAction
		>
	>;
	fetchBulkRecords: ActionCreator<
		ThunkAction<
			Promise<BulkUpdatesSuccessfulAction>,
			GlobalState,
			BulkUpdatesResultRequest,
			BulkUpdatesSuccessfulAction
		>
	>;
	resetBulkRecords: ActionCreator<
		ThunkAction<
			BulkUpdatesResetAction,
			GlobalState,
			null,
			BulkUpdatesResetAction
		>
	>;
	getFreshChatMessageStatus: ActionCreator<
		ThunkAction<void, GlobalState, null, ShouldShowFreshChatBubbleAction>
	>;
	getCollectJsToken: ActionCreator<
		ThunkAction<
			Promise<CollectJSTokenSuccessfulAction>,
			GlobalState,
			string,
			CollectJSTokenSuccessfulAction
		>
	>;
	updateMobileView: ActionCreator<
		ThunkAction<
			UpdateMobileViewAction,
			GlobalState,
			boolean,
			UpdateMobileViewAction
		>
	>;
}

export const UIConfigActionCreators: UIConfigDispatch = {
	getBeamerIconStatus:
		(unreadPostCoint: number) =>
		async (dispatch: Dispatch): Promise<ShouldShowBeamerIconAction> => {
			try {
				const shouldShowBeamerIconAction: ShouldShowBeamerIconAction = {
					type: UIConfigActionTypes.CHECK_BEAMER_STATUS,
					shouldShowBeamerIcon: unreadPostCoint > 0
				};

				return dispatch(shouldShowBeamerIconAction);
			} catch (err) {
				console.error(err);
				const shouldShowBeamerIconAction: ShouldShowBeamerIconAction = {
					type: UIConfigActionTypes.CHECK_BEAMER_STATUS,
					shouldShowBeamerIcon: false
				};
				return dispatch(shouldShowBeamerIconAction);
			}
		},
	fetchBulkRecords: (bulkUpdatesResultRequest: BulkUpdatesResultRequest) => {
		return async (
			dispatch: Dispatch
		): Promise<BulkUpdatesSuccessfulAction> => {
			const bulkUpdatesRequestedAction: BulkUpdatesRequestedAction = {
				type: UIConfigActionTypes.BULK_UPDATES_REQUESTED
			};

			dispatch(bulkUpdatesRequestedAction);

			try {
				const response = await fetchBulkUpdatesRecords(
					bulkUpdatesResultRequest
				);
				const bulkUpdateSuccessfulAction: BulkUpdatesSuccessfulAction =
					{
						type: UIConfigActionTypes.BULK_UPDATES_SUCCESSFUL,
						bulkUpdatesResult: response
					};

				return dispatch(bulkUpdateSuccessfulAction);
			} catch (error) {
				const bulkUpdatesFailedAction: BulkUpdatesFailedAction = {
					type: UIConfigActionTypes.BULK_UPDATES_FAILED
				};

				dispatch(bulkUpdatesFailedAction);

				return Promise.reject(error);
			}
		};
	},
	resetBulkRecords: () => {
		return (dispatch: Dispatch): BulkUpdatesResetAction => {
			const bulkUpdatesReset: BulkUpdatesResetAction = {
				type: UIConfigActionTypes.BULK_UPDATES_RESET
			};

			return dispatch(bulkUpdatesReset);
		};
	},
	getFreshChatMessageStatus:
		(count: number) =>
		(dispatch: Dispatch): void => {
			const shouldShowFreshChatBubbleAction: ShouldShowFreshChatBubbleAction =
				{
					type: UIConfigActionTypes.CHECK_FRESHCHAT_UNREAD_MESSAGES,
					shouldShowFreshChatBubble: count > 0
				};
			dispatch(shouldShowFreshChatBubbleAction);
		},
	getCollectJsToken:
		(documentId: string) =>
		async (dispatch: Dispatch): Promise<CollectJSTokenSuccessfulAction> => {
			const collectJSTokenRequestedAction: CollectJSTokenRequestedAction =
				{
					type: UIConfigActionTypes.COLLECTJS_TOKEN_REQUESTED
				};
			dispatch(collectJSTokenRequestedAction);

			try {
				const collectjsToken = await fetchCollectJsToken(documentId);
				if (!collectjsToken) {
					throw new Error("Collect JS Token is empty");
				}
				const collectJSTokenSuccessfulAction: CollectJSTokenSuccessfulAction =
					{
						type: UIConfigActionTypes.COLLECTJS_TOKEN_SUCCESSFUL,
						collectjsToken
					};
				return dispatch(collectJSTokenSuccessfulAction);
			} catch (err) {
				console.error(err.message);
				const collectJsTokenFailedAction: CollectJSTokenFailedAction = {
					type: UIConfigActionTypes.COLLECTJS_TOKEN_FAILED
				};
				dispatch(collectJsTokenFailedAction);
				return Promise.reject(err);
			}
		},
	updateMobileView: (isMobileView: boolean) => {
		return (dispatch: Dispatch): UpdateMobileViewAction => {
			const updateMobileView: UpdateMobileViewAction = {
				type: UIConfigActionTypes.UPDATE_MOBILE_VIEW,
				isMobileView
			};
			return dispatch(updateMobileView);
		};
	}
};
