import React, { Fragment, useEffect } from "react";
import BulkActionsBar from "./ZBulkActionsBar";
import FilterSettingsBar from "./ZFilterSettingsBar";
import ZTable from "@zomentum/organisms/dist/ZTable";
import TableRecordsSelection from "./ZTableRecordsSelection";
import { ITableWithDecoratorsProps } from "./interface";
import useMobileView from "@zomentum/hooks/dist/hooks/useMobileView";
import { TablePaginationConfig } from "antd/lib/table";
import {
	FilterValue,
	SorterResult,
	TableCurrentDataSource
} from "antd/lib/table/interface";
import { useHistory, useLocation } from "react-router-dom";
import "./index.less";

const ZTableWithDecorators = <T extends Record<string, any>>({
	isBulkActionsRecordsSelected = false,
	selectedRecords = 0,
	totalRecords = 0,
	selectAllRecords = false,
	filters = [],
	settingsColumns = [],
	activeFilters = [],
	isLoading = true,
	dataSource = [],
	columns = [],
	selectedRowKeys = [],
	isFilterNeeded = true,
	isBulkActionsNeeded = true,
	appUpdateBannerVisible = false,
	isBannerOpen = false,
	taxCategories = [],
	taxRegions = [],
	shouldShowDisplayRecords = true,
	allowHorizontalScroll = true,
	allowVerticalScroll = true,
	showInfoText = true,
	leftComponents,
	rightComponents,
	...rest
}: ITableWithDecoratorsProps<T>): React.ReactElement => {
	const isMobileView = useMobileView();
	const history = useHistory();

	let tableHeight = !selectedRecords ? 19 : 22.2;
	if (appUpdateBannerVisible) tableHeight += 2.3;
	if (isBannerOpen) tableHeight += 2;
	if (isMobileView) tableHeight -= isBulkActionsRecordsSelected ? -0.5 : 3;

	const hasActionHeader = isBulkActionsRecordsSelected
		? isBulkActionsNeeded
		: isFilterNeeded && !!filters.length;
	if (!hasActionHeader) tableHeight -= 3;

	const location = useLocation();

	const query = new URLSearchParams(location.search);

	useEffect(() => {
		if (query.get("page_size")) {
			rest.updateSelectedRecords?.(Number(query.get("page_size") ?? 25));
		}
		if (query.get("page")) {
			rest.onChange?.(
				{
					current: Number(query.get("page") ?? 1)
				},
				{},
				[],
				{} as TableCurrentDataSource<any>
			);
		}
	}, []);

	const tableOnChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<T> | SorterResult<T>[],
		extra: TableCurrentDataSource<T>
	) => {
		rest?.onChange?.(pagination, filters, sorter, extra);
		history.push({
			search: `?page=${pagination.current}&page_size=${pagination.pageSize}`
		});
	};

	const updateDisplayRecords = (displayRecords: number) => {
		history.push({
			search: `?page=1&page_size=${displayRecords}`
		});
		rest.updateSelectedRecords?.(displayRecords);
	};
	const containerClasses = `${isMobileView ? "mobile-view" : ""} ${
		isMobileView && !isBulkActionsRecordsSelected ? "mt-0" : ""
	} ${hasActionHeader ? "" : "mt-0"}`;

	const scrollAttributes =
		allowHorizontalScroll && allowVerticalScroll
			? {
					x: "calc(100vw - 120px)",
					y: `calc(var(--vh, 1vh) * 100 - ${tableHeight}rem)`
			  }
			: allowHorizontalScroll
			? {
					x: "calc(100vw - 120px)"
			  }
			: allowVerticalScroll
			? {
					y: `calc(var(--vh, 1vh) * 100 - ${tableHeight}rem)`
			  }
			: undefined;

	return (
		<Fragment>
			{isBulkActionsRecordsSelected
				? isBulkActionsNeeded && (
						<BulkActionsBar
							entity={rest.entity}
							selectedRecords={selectedRecords}
							totalRecords={totalRecords}
							handleRecordSelection={rest.handleRecordSelection}
							handleDelete={rest.handleDelete ?? undefined}
							openBulkUpdateDrawer={rest.openBulkUpdateDrawer}
							selectAllRecords={selectAllRecords}
							featureAccess={rest.featureAccess}
							showInfoText={showInfoText}
							customActionButtons={rest.customActionButtons}
							hideDefaultActionButtons={
								rest.hideDefaultActionButtons
							}
						/>
				  )
				: isFilterNeeded &&
				  !!filters.length && (
						<FilterSettingsBar
							filters={filters}
							settingsColumns={settingsColumns}
							onFilterSubmit={rest.onFilterSubmit}
							onColumnsSubmit={rest.onColumnsSubmit}
							activeFilters={activeFilters}
							taxCategories={taxCategories}
							taxRegions={taxRegions}
							leftComponents={leftComponents}
							rightComponents={rightComponents}
						/>
				  )}
			<div
				className={`z-table-container ${containerClasses}`}
				style={hasActionHeader ? { height: "calc(100% - 48px)" } : {}}
			>
				{!rest.isDraggableRow ? (
					<ZTable
						className="z-table-with-decorators"
						loading={isLoading}
						scroll={scrollAttributes}
						dataSource={dataSource}
						rowKey="id"
						pagination={
							rest.currentPagination instanceof Object
								? {
										...rest.currentPagination,
										pageSize: rest.displayRecords,
										showSizeChanger: isMobileView,
										simple: isMobileView
								  }
								: false
						}
						columns={columns}
						onChange={tableOnChange}
						rowSelection={
							rest.handleTableRecordSelection
								? {
										onChange:
											rest.handleTableRecordSelection,
										selectedRowKeys,
										getCheckboxProps:
											rest.getCheckboxProps ?? undefined
								  }
								: undefined
						}
					/>
				) : (
					<ZTable
						className="z-table-with-decorators"
						loading={isLoading}
						scroll={scrollAttributes}
						dataSource={dataSource}
						pagination={false}
						columns={columns}
						isDragDisabled={rest?.isDragDisabled}
						rowClassName={rest.rowClassName}
						onDragEnd={rest.onDragEnd}
						isDraggable={rest.isDraggable}
					/>
				)}
				{shouldShowDisplayRecords && !rest.isDraggableRow && (
					<div className="records-selection">
						<TableRecordsSelection
							displayRecords={rest.displayRecords}
							updateSelectedRecords={updateDisplayRecords}
						/>
					</div>
				)}
			</div>
		</Fragment>
	);
};

export default ZTableWithDecorators;
