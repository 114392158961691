import { Reducer } from "redux";

import { NotificationActivity } from "@zomentum/contracts/dist/Others/NotificationFeed";

import {
	NotificationFeedActionTypes,
	NotificationFeedActions
} from "../../../Actions/Others/NotificationFeed";

export interface NotificationFeedState {
	readonly isLoading: boolean;
	readonly markedAsRead: boolean;
	readonly notificationFeedActivityList: NotificationActivity[];
}

const initialState: NotificationFeedState = {
	isLoading: false,
	markedAsRead: false,
	notificationFeedActivityList: new Array<NotificationActivity>()
};

export const NotificationFeedReducer: Reducer<
	NotificationFeedState,
	NotificationFeedActions
> = (
	state: NotificationFeedState = initialState,
	action: NotificationFeedActions
): NotificationFeedState => {
	switch (action.type) {
		case NotificationFeedActionTypes.SHOW_NOTIFICATION_FEED_LOADER: {
			return {
				...state,
				isLoading: true
			};
		}
		case NotificationFeedActionTypes.HIDE_NOTIFICATION_FEED_LOADER: {
			return {
				...state,
				isLoading: false
			};
		}
		case NotificationFeedActionTypes.NOTIFICATION_FEED_REQUESTED: {
			return state;
		}
		case NotificationFeedActionTypes.NOTIFICATION_FEED_SUCCESSFUL: {
			return {
				...state,
				notificationFeedActivityList:
					action.notificationFeedActivityList
			};
		}
		case NotificationFeedActionTypes.NOTIFICATION_FEED_FAILED: {
			return state;
		}
		case NotificationFeedActionTypes.SUBSCRIBE_NOTIFICATION_FEED_ACTIVITY_REQUESTED: {
			return state;
		}
		case NotificationFeedActionTypes.SUBSCRIBE_NOTIFICATION_FEED_ACTIVITY_SUCCESSFUL: {
			return state;
		}
		case NotificationFeedActionTypes.SUBSCRIBE_NOTIFICATION_FEED_ACTIVITY_FAILED: {
			return state;
		}
		case NotificationFeedActionTypes.RESET_NOTIFICATION_FEED: {
			return {
				...state,
				notificationFeedActivityList: new Array<NotificationActivity>()
			};
		}
		case NotificationFeedActionTypes.DELETE_NOTIFICATION_FEED_ACTIVITY_REQUESTED: {
			return state;
		}
		case NotificationFeedActionTypes.DELETE_NOTIFICATION_FEED_ACTIVITY_SUCCESSFUL: {
			return {
				...state,
				notificationFeedActivityList:
					state.notificationFeedActivityList.filter(
						notificationFeedActivity =>
							notificationFeedActivity.id !==
							action.deleteNotificationFeedActivity.id
					)
			};
		}

		case NotificationFeedActionTypes.DELETE_NOTIFICATION_FEED_ACTIVITY_FAILED: {
			return state;
		}

		case NotificationFeedActionTypes.BULK_DELETE_NOTIFICATION_FEED_ACTIVITY_REQUESTED: {
			return state;
		}
		case NotificationFeedActionTypes.BULK_DELETE_NOTIFICATION_FEED_ACTIVITY_SUCCESSFUL: {
			return {
				...state,
				notificationFeedActivityList:
					state.notificationFeedActivityList.filter(
						notificationFeedActivity =>
							notificationFeedActivity.foreign_id !==
							action.foreignId
					)
			};
		}

		case NotificationFeedActionTypes.BULK_DELETE_NOTIFICATION_FEED_ACTIVITY_FAILED: {
			return state;
		}

		case NotificationFeedActionTypes.MARK_NOTIFICATION_FEED_ACTIVITY_AS_READ_REQUESTED: {
			return {
				...state,
				markedAsRead: false
			};
		}

		case NotificationFeedActionTypes.MARK_NOTIFICATION_FEED_ACTIVITY_AS_READ_SUCCESSFUL: {
			return {
				...state,
				markedAsRead: true,
				notificationFeedActivityList:
					action.updatedNotificationFeedActivityList
			};
		}

		case NotificationFeedActionTypes.ADD_NOTIFICATION_FEED_SUBSCRIPTION_ACTIVITY: {
			return {
				...state,
				notificationFeedActivityList: [
					action.addNotificationFeedSubscriptionActivity
				].concat(state.notificationFeedActivityList)
			};
		}
		default: {
			return state;
		}
	}
};

export default NotificationFeedReducer;
