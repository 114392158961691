import { ThunkAction } from "redux-thunk";
import { Action, ActionCreator, Dispatch } from "redux";
import { GlobalState } from "@/Reducers/interface";
import { MergeTagVariable } from "@zomentum/contracts/dist/Documents";
import {
	VendorEmailTemplate,
	VendorEmailTemplateRequest,
	VendorEmailTemplateGetRequest,
	VendorCustomTemplateType
} from "@zomentum/contracts/dist/Vendor/EmailTemplate";

import {
	getAllTemplates,
	addEmailTemplate,
	deleteEmailTemplate,
	updateEmailTemplate,
	getMergeTagVariables,
	fetchSystemEmailTemplateByType
} from "@/Services/Vendor/EmailTemplates";

export enum EmailTemplatesActionTypes {
	SHOW_ADD_EMAIL_TEMPLATES_DRAWER = "SHOW_ADD_EMAIL_TEMPLATES_DRAWER",
	HIDE_ADD_EMAIL_TEMPLATES_DRAWER = "HIDE_ADD_EMAIL_TEMPLATES_DRAWER",

	SHOW_EDIT_EMAIL_TEMPLATES_DRAWER = "SHOW_EDIT_EMAIL_TEMPLATES_DRAWER",
	HIDE_EDIT_EMAIL_TEMPLATES_DRAWER = "HIDE_EDIT_EMAIL_TEMPLATES_DRAWER",

	FETCH_ALL_EMAIL_TEMPLATES_REQUESTED = "FETCH_ALL_EMAIL_TEMPLATES_REQUESTED",
	FETCH_ALL_EMAIL_TEMPLATES_SUCCESSFUL = "FETCH_ALL_TEMPLATES_SUCCESSFUL",
	FETCH_ALL_EMAIL_TEMPLATES_FAILED = "FETCH_ALL_TEMPLATES_FAILED",

	RESET_EMAIL_TEMPLATES_LIST = "RESET_EMAIL_TEMPLATES_LIST",

	ADD_EMAIL_TEMPLATE_REQUESTED = "ADD_EMAIL_TEMPLATE_REQUESTED",
	ADD_EMAIL_TEMPLATE_SUCCESSFUL = "ADD_EMAIL_TEMPLATE_SUCCESSFUL",
	ADD_EMAIL_TEMPLATE_FAILED = "ADD_EMAIL_TEMPLATE_FAILED",

	UPDATE_EMAIL_TEMPLATE_REQUESTED = "UPDATE_EMAIL_TEMPLATE_REQUESTED",
	UPDATE_EMAIL_TEMPLATE_SUCCESSFUL = "UPDATE_EMAIL_TEMPLATE_SUCCESSFUL",
	UPDATE_EMAIL_TEMPLATE_FAILED = "UPDATE_EMAIL_TEMPLATE_FAILED",

	DELETE_EMAIL_TEMPLATE_REQUESTED = "DELETE_EMAIL_TEMPLATE_REQUESTED",
	DELETE_EMAIL_TEMPLATE_SUCCESSFUL = "DELETE_EMAIL_TEMPLATE_SUCCESSFUL",
	DELETE_EMAIL_TEMPLATE_FAILED = "DELETE_EMAIL_TEMPLATE_FAILED",

	UPDATE_CURRENT_ACTIVE_EMAIL_TEMPLATE = "UPDATE_CURRENT_ACTIVE_EMAIL_TEMPLATE",
	RESET_CURRENT_ACTIVE_EMAIL_TEMPLATE = "RESET_CURRENT_ACTIVE_EMAIL_TEMPLATE",

	SHOW_EMAIL_TEMPLATES_LOADER = "SHOW_EMAIL_TEMPLATES_LOADER",
	HIDE_EMAIL_TEMPLATES_LOADER = "HIDE_EMAIL_TEMPLATES_LOADER",

	FETCH_MERGE_TAG_VARIABLES_REQUESTED = "FETCH_MERGE_TAG_VARIABLES_REQUESTED",
	FETCH_MERGE_TAG_VARIABLES_SUCCESSFUL = "FETCH_MERGE_TAG_VARIABLES_SUCCESSFUL",
	FETCH_MERGE_TAG_VARIABLES_FAILED = "FETCH_MERGE_TAG_VARIABLES_FAILED",

	RESET_EMAIL_TEMPLATE_MERGE_TAG_VARIABLES = "RESET_EMAIL_TEMPLATE_MERGE_TAG_VARIABLES",

	FETCH_EMAIL_TEMPLATE_BY_CUSTOM_TEMPLATE_TYPE_REQUESTED = "FETCH_EMAIL_TEMPLATE_BY_CUSTOM_TEMPLATE_TYPE_REQUESTED",
	FETCH_EMAIL_TEMPLATE_BY_CUSTOM_TEMPLATE_TYPE_SUCCESSFUL = "FETCH_EMAIL_TEMPLATE_BY_CUSTOM_TEMPLATE_TYPE_SUCCESSFUL",
	FETCH_EMAIL_TEMPLATE_BY_CUSTOM_TEMPLATE_TYPE_FAILED = "FETCH_EMAIL_TEMPLATE_BY_CUSTOM_TEMPLATE_TYPE_FAILED"
}

export type ShowAddEmailTeamplatesDrawerAction =
	Action<EmailTemplatesActionTypes.SHOW_ADD_EMAIL_TEMPLATES_DRAWER>;
export type HideAddEmailTeamplatesDrawerAction =
	Action<EmailTemplatesActionTypes.HIDE_ADD_EMAIL_TEMPLATES_DRAWER>;

export type ShowEditEmailTeamplatesDrawerAction =
	Action<EmailTemplatesActionTypes.SHOW_EDIT_EMAIL_TEMPLATES_DRAWER>;
export type HideEditEmailTeamplatesDrawerAction =
	Action<EmailTemplatesActionTypes.HIDE_EDIT_EMAIL_TEMPLATES_DRAWER>;

export type FetchAllTemplateRequestedAction =
	Action<EmailTemplatesActionTypes.FETCH_ALL_EMAIL_TEMPLATES_REQUESTED>;
export interface FetchAllTemplateSuccessfulAction
	extends Action<EmailTemplatesActionTypes.FETCH_ALL_EMAIL_TEMPLATES_SUCCESSFUL> {
	readonly templates: VendorEmailTemplate[];
}
export type FetchAllTemplateFailedAction =
	Action<EmailTemplatesActionTypes.FETCH_ALL_EMAIL_TEMPLATES_FAILED>;

export type AddEmailTemplateRequestedAction =
	Action<EmailTemplatesActionTypes.ADD_EMAIL_TEMPLATE_REQUESTED>;
export type AddEmailTemplateFailedAction =
	Action<EmailTemplatesActionTypes.ADD_EMAIL_TEMPLATE_FAILED>;

export interface AddEmailTemplateSuccessfulAction
	extends Action<EmailTemplatesActionTypes.ADD_EMAIL_TEMPLATE_SUCCESSFUL> {
	readonly newTemplate: VendorEmailTemplate;
}
export type AddEmailTemplateFailed =
	Action<EmailTemplatesActionTypes.ADD_EMAIL_TEMPLATE_FAILED>;

export type ShowEmailTemplatesLoaderAction =
	Action<EmailTemplatesActionTypes.SHOW_EMAIL_TEMPLATES_LOADER>;
export type HideEmailTemplatesLoaderAction =
	Action<EmailTemplatesActionTypes.HIDE_EMAIL_TEMPLATES_LOADER>;

export type DeleteEmailTemplateRequestedAction =
	Action<EmailTemplatesActionTypes.DELETE_EMAIL_TEMPLATE_REQUESTED>;
export interface DeleteEmailTemplateSuccessfulAction
	extends Action<EmailTemplatesActionTypes.DELETE_EMAIL_TEMPLATE_SUCCESSFUL> {
	readonly deletedTemplateId: string;
}
export type DeleteEmailTemplateFailedAction =
	Action<EmailTemplatesActionTypes.DELETE_EMAIL_TEMPLATE_FAILED>;

export interface UpdateCurrentActiveEmailTemplateAction
	extends Action<EmailTemplatesActionTypes.UPDATE_CURRENT_ACTIVE_EMAIL_TEMPLATE> {
	readonly currentActiveEmailTemplate: VendorEmailTemplate;
}

export type ResetCurrentActiveEmailTemplateAction =
	Action<EmailTemplatesActionTypes.RESET_CURRENT_ACTIVE_EMAIL_TEMPLATE>;

export type UpdateEmailTemplateRequestedAction =
	Action<EmailTemplatesActionTypes.UPDATE_EMAIL_TEMPLATE_REQUESTED>;
export type UpdateEmailTemplateFailedAction =
	Action<EmailTemplatesActionTypes.UPDATE_EMAIL_TEMPLATE_FAILED>;

export interface UpdateEmailTemplateSuccessfulAction
	extends Action<EmailTemplatesActionTypes.UPDATE_EMAIL_TEMPLATE_SUCCESSFUL> {
	readonly updatedEmailTemplate: VendorEmailTemplate;
}

export type FetchMergeTagVariablesRequestedAction =
	Action<EmailTemplatesActionTypes.FETCH_MERGE_TAG_VARIABLES_REQUESTED>;
export type FetchMergeTagVariablesFailedAction =
	Action<EmailTemplatesActionTypes.FETCH_MERGE_TAG_VARIABLES_FAILED>;

export interface FetchMergeTagVariablesSuccessfulAction
	extends Action<EmailTemplatesActionTypes.FETCH_MERGE_TAG_VARIABLES_SUCCESSFUL> {
	readonly mergeTagVariables: MergeTagVariable[];
}

export type ResetEmailTemplatesListAction =
	Action<EmailTemplatesActionTypes.RESET_EMAIL_TEMPLATES_LIST>;
export type ResetEmailTemplateMergeTagsAction =
	Action<EmailTemplatesActionTypes.RESET_EMAIL_TEMPLATE_MERGE_TAG_VARIABLES>;

export type FetchTemplateByCustomTemplateTypeRequestedAction =
	Action<EmailTemplatesActionTypes.FETCH_EMAIL_TEMPLATE_BY_CUSTOM_TEMPLATE_TYPE_REQUESTED>;
export interface FetchTemplateByCustomTemplateTypeSuccessfulAction
	extends Action<EmailTemplatesActionTypes.FETCH_EMAIL_TEMPLATE_BY_CUSTOM_TEMPLATE_TYPE_SUCCESSFUL> {
	readonly templates: VendorEmailTemplate[]; // TODO: Change later when filter is introduced in the backend
}
export type FetchTemplateByCustomTemplateTypeFailedAction =
	Action<EmailTemplatesActionTypes.FETCH_EMAIL_TEMPLATE_BY_CUSTOM_TEMPLATE_TYPE_FAILED>;

export type EmailTemplatesAction =
	| ShowAddEmailTeamplatesDrawerAction
	| HideAddEmailTeamplatesDrawerAction
	| ShowEditEmailTeamplatesDrawerAction
	| HideEditEmailTeamplatesDrawerAction
	| ShowEmailTemplatesLoaderAction
	| HideEmailTemplatesLoaderAction
	| FetchAllTemplateFailedAction
	| FetchAllTemplateSuccessfulAction
	| FetchAllTemplateRequestedAction
	| AddEmailTemplateRequestedAction
	| AddEmailTemplateSuccessfulAction
	| AddEmailTemplateFailedAction
	| DeleteEmailTemplateRequestedAction
	| DeleteEmailTemplateSuccessfulAction
	| DeleteEmailTemplateFailedAction
	| UpdateCurrentActiveEmailTemplateAction
	| ResetCurrentActiveEmailTemplateAction
	| UpdateEmailTemplateRequestedAction
	| UpdateEmailTemplateSuccessfulAction
	| UpdateEmailTemplateFailedAction
	| FetchMergeTagVariablesRequestedAction
	| FetchMergeTagVariablesSuccessfulAction
	| FetchMergeTagVariablesFailedAction
	| ResetEmailTemplatesListAction
	| ResetEmailTemplateMergeTagsAction
	| FetchTemplateByCustomTemplateTypeRequestedAction
	| FetchTemplateByCustomTemplateTypeSuccessfulAction
	| FetchTemplateByCustomTemplateTypeFailedAction;

export type EmailTemplateDispatch = {
	showLoader: ActionCreator<
		ThunkAction<
			ShowEmailTemplatesLoaderAction,
			GlobalState,
			null,
			ShowEmailTemplatesLoaderAction
		>
	>;
	hideLoader: ActionCreator<
		ThunkAction<
			HideEmailTemplatesLoaderAction,
			GlobalState,
			null,
			HideEmailTemplatesLoaderAction
		>
	>;
	showAddEmailTemplateDrawer: ActionCreator<
		ThunkAction<
			ShowAddEmailTeamplatesDrawerAction,
			GlobalState,
			null,
			ShowAddEmailTeamplatesDrawerAction
		>
	>;
	hideAddEmailTemplateDrawer: ActionCreator<
		ThunkAction<
			HideAddEmailTeamplatesDrawerAction,
			GlobalState,
			null,
			HideAddEmailTeamplatesDrawerAction
		>
	>;
	showEditEmailTemplateDrawer: ActionCreator<
		ThunkAction<
			ShowEditEmailTeamplatesDrawerAction,
			GlobalState,
			null,
			ShowEditEmailTeamplatesDrawerAction
		>
	>;
	hideEditEmailTemplateDrawer: ActionCreator<
		ThunkAction<
			HideEditEmailTeamplatesDrawerAction,
			GlobalState,
			null,
			HideEditEmailTeamplatesDrawerAction
		>
	>;
	fetchAllTemplates: ActionCreator<
		ThunkAction<
			Promise<FetchAllTemplateSuccessfulAction>,
			GlobalState,
			{ getEmailTemplateRequest: VendorEmailTemplateGetRequest },
			FetchAllTemplateSuccessfulAction
		>
	>;
	addTemplate: ActionCreator<
		ThunkAction<
			Promise<AddEmailTemplateSuccessfulAction>,
			GlobalState,
			{ addEmailTemplateRequest: VendorEmailTemplateRequest },
			AddEmailTemplateSuccessfulAction
		>
	>;
	deleteTemplate: ActionCreator<
		ThunkAction<
			Promise<DeleteEmailTemplateSuccessfulAction>,
			GlobalState,
			{ templateId: string },
			DeleteEmailTemplateSuccessfulAction
		>
	>;
	updateCurrentActiveEmailTemplate: ActionCreator<
		ThunkAction<
			UpdateCurrentActiveEmailTemplateAction,
			GlobalState,
			{ currentActiveEmailTemplat: VendorEmailTemplate },
			UpdateCurrentActiveEmailTemplateAction
		>
	>;
	resetCurrentActiveEmailTemplate: ActionCreator<
		ThunkAction<
			ResetCurrentActiveEmailTemplateAction,
			GlobalState,
			null,
			ResetCurrentActiveEmailTemplateAction
		>
	>;
	updateEmailTemplate: ActionCreator<
		ThunkAction<
			Promise<UpdateEmailTemplateSuccessfulAction>,
			GlobalState,
			{
				emailTemplateId: string;
				updateEmailTemplateRequest: VendorEmailTemplateRequest;
			},
			UpdateEmailTemplateSuccessfulAction
		>
	>;
	fetchAllMergeTags: ActionCreator<
		ThunkAction<
			Promise<FetchMergeTagVariablesSuccessfulAction>,
			GlobalState,
			null,
			FetchMergeTagVariablesSuccessfulAction
		>
	>;
	resetEmailTemplates: ActionCreator<
		ThunkAction<
			ResetEmailTemplatesListAction,
			GlobalState,
			null,
			ResetEmailTemplatesListAction
		>
	>;
	resetEmailTemplateMergeTags: ActionCreator<
		ThunkAction<
			ResetEmailTemplateMergeTagsAction,
			GlobalState,
			null,
			ResetEmailTemplateMergeTagsAction
		>
	>;
	fetchTemplateByType: ActionCreator<
		ThunkAction<
			Promise<FetchTemplateByCustomTemplateTypeSuccessfulAction>,
			GlobalState,
			VendorCustomTemplateType,
			FetchTemplateByCustomTemplateTypeSuccessfulAction
		>
	>;
};

export const EmailTemplatesActionCreators: EmailTemplateDispatch = {
	showLoader: () => {
		return (dispatch: Dispatch): ShowEmailTemplatesLoaderAction => {
			const showEmailTemplateLoaderAction: ShowEmailTemplatesLoaderAction =
				{
					type: EmailTemplatesActionTypes.SHOW_EMAIL_TEMPLATES_LOADER
				};
			return dispatch(showEmailTemplateLoaderAction);
		};
	},
	hideLoader: () => {
		return (dispatch: Dispatch): HideEmailTemplatesLoaderAction => {
			const hideEmailTeamplatesDrawerAction: HideEmailTemplatesLoaderAction =
				{
					type: EmailTemplatesActionTypes.HIDE_EMAIL_TEMPLATES_LOADER
				};
			return dispatch(hideEmailTeamplatesDrawerAction);
		};
	},
	showAddEmailTemplateDrawer: () => {
		return (dispatch: Dispatch): ShowAddEmailTeamplatesDrawerAction => {
			const showEmailTeamplatesDrawerAction: ShowAddEmailTeamplatesDrawerAction =
				{
					type: EmailTemplatesActionTypes.SHOW_ADD_EMAIL_TEMPLATES_DRAWER
				};
			return dispatch(showEmailTeamplatesDrawerAction);
		};
	},
	hideAddEmailTemplateDrawer: () => {
		return (dispatch: Dispatch): HideAddEmailTeamplatesDrawerAction => {
			const hideEmailTeamplatesDrawer: HideAddEmailTeamplatesDrawerAction =
				{
					type: EmailTemplatesActionTypes.HIDE_ADD_EMAIL_TEMPLATES_DRAWER
				};
			return dispatch(hideEmailTeamplatesDrawer);
		};
	},
	showEditEmailTemplateDrawer: () => {
		return (dispatch: Dispatch): ShowEditEmailTeamplatesDrawerAction => {
			const showEmailTeamplatesDrawerAction: ShowEditEmailTeamplatesDrawerAction =
				{
					type: EmailTemplatesActionTypes.SHOW_EDIT_EMAIL_TEMPLATES_DRAWER
				};
			return dispatch(showEmailTeamplatesDrawerAction);
		};
	},
	hideEditEmailTemplateDrawer: () => {
		return (dispatch: Dispatch): HideEditEmailTeamplatesDrawerAction => {
			const hideEmailTeamplatesDrawer: HideEditEmailTeamplatesDrawerAction =
				{
					type: EmailTemplatesActionTypes.HIDE_EDIT_EMAIL_TEMPLATES_DRAWER
				};
			return dispatch(hideEmailTeamplatesDrawer);
		};
	},
	fetchAllTemplates: (
		getEmailTemplateRequest: VendorEmailTemplateGetRequest
	) => {
		return async (
			dispatch: Dispatch
		): Promise<FetchAllTemplateSuccessfulAction> => {
			const fetchAllTtetmplatesRequestedAction: FetchAllTemplateRequestedAction =
				{
					type: EmailTemplatesActionTypes.FETCH_ALL_EMAIL_TEMPLATES_REQUESTED
				};
			dispatch(fetchAllTtetmplatesRequestedAction);
			try {
				const response = await getAllTemplates(getEmailTemplateRequest);
				const fetchalltemplatesSuccessfulAction: FetchAllTemplateSuccessfulAction =
					{
						type: EmailTemplatesActionTypes.FETCH_ALL_EMAIL_TEMPLATES_SUCCESSFUL,
						templates: response.data
					};
				return dispatch(fetchalltemplatesSuccessfulAction);
			} catch (error) {
				const fetchAllTemplateFailedAction: FetchAllTemplateFailedAction =
					{
						type: EmailTemplatesActionTypes.FETCH_ALL_EMAIL_TEMPLATES_FAILED
					};
				dispatch(fetchAllTemplateFailedAction);

				return Promise.reject(error);
			}
		};
	},
	addTemplate: (addEmailTemplateRequest: VendorEmailTemplateRequest) => {
		return async (
			dispatch: Dispatch
		): Promise<AddEmailTemplateSuccessfulAction> => {
			const addEmailTemplateRequestedAction: AddEmailTemplateRequestedAction =
				{
					type: EmailTemplatesActionTypes.ADD_EMAIL_TEMPLATE_REQUESTED
				};
			dispatch(addEmailTemplateRequestedAction);
			try {
				const response = await addEmailTemplate(
					addEmailTemplateRequest
				);
				const addEmailTemplateSuccessfulAction: AddEmailTemplateSuccessfulAction =
					{
						type: EmailTemplatesActionTypes.ADD_EMAIL_TEMPLATE_SUCCESSFUL,
						newTemplate: response
					};
				return dispatch(addEmailTemplateSuccessfulAction);
			} catch (error) {
				const addEmailTemplateFailedAction: AddEmailTemplateFailedAction =
					{
						type: EmailTemplatesActionTypes.ADD_EMAIL_TEMPLATE_FAILED
					};
				dispatch(addEmailTemplateFailedAction);

				return Promise.reject(error);
			}
		};
	},
	deleteTemplate: (templateId: string) => {
		return async (
			dispatch: Dispatch
		): Promise<DeleteEmailTemplateSuccessfulAction> => {
			const deleteEmailTemplateRequestedAction: DeleteEmailTemplateRequestedAction =
				{
					type: EmailTemplatesActionTypes.DELETE_EMAIL_TEMPLATE_REQUESTED
				};
			dispatch(deleteEmailTemplateRequestedAction);
			try {
				const deletedResponse = await deleteEmailTemplate(templateId);
				if (deletedResponse.deleted) {
					const deleteEmailTemplateSuccessfulAction: DeleteEmailTemplateSuccessfulAction =
						{
							type: EmailTemplatesActionTypes.DELETE_EMAIL_TEMPLATE_SUCCESSFUL,
							deletedTemplateId: templateId
						};
					return dispatch(deleteEmailTemplateSuccessfulAction);
				}
				throw new Error("Cannot delete email template");
			} catch (error) {
				const deleteEmailTemplateFailedAction: DeleteEmailTemplateFailedAction =
					{
						type: EmailTemplatesActionTypes.DELETE_EMAIL_TEMPLATE_FAILED
					};
				dispatch(deleteEmailTemplateFailedAction);

				return Promise.reject(error);
			}
		};
	},
	updateCurrentActiveEmailTemplate: (
		currentActiveEmailTemplate: VendorEmailTemplate
	) => {
		return (dispatch: Dispatch): UpdateCurrentActiveEmailTemplateAction => {
			const updateCurrentActiveEmailTemplateAction: UpdateCurrentActiveEmailTemplateAction =
				{
					type: EmailTemplatesActionTypes.UPDATE_CURRENT_ACTIVE_EMAIL_TEMPLATE,
					currentActiveEmailTemplate: currentActiveEmailTemplate
				};
			return dispatch(updateCurrentActiveEmailTemplateAction);
		};
	},
	resetCurrentActiveEmailTemplate: () => {
		return (dispatch: Dispatch): ResetCurrentActiveEmailTemplateAction => {
			const resetCurrentActiveEmailTemplateAction: ResetCurrentActiveEmailTemplateAction =
				{
					type: EmailTemplatesActionTypes.RESET_CURRENT_ACTIVE_EMAIL_TEMPLATE
				};
			return dispatch(resetCurrentActiveEmailTemplateAction);
		};
	},
	updateEmailTemplate: (
		emailTemplateId: string,
		updateEmailTemplateRequest: VendorEmailTemplateRequest
	) => {
		return async (
			dispatch: Dispatch
		): Promise<UpdateEmailTemplateSuccessfulAction> => {
			const updateEmailTemplateRequestedAction: UpdateEmailTemplateRequestedAction =
				{
					type: EmailTemplatesActionTypes.UPDATE_EMAIL_TEMPLATE_REQUESTED
				};
			dispatch(updateEmailTemplateRequestedAction);

			try {
				const response = await updateEmailTemplate(
					emailTemplateId,
					updateEmailTemplateRequest
				);
				const updateEmailTemplateSuccessfulAction: UpdateEmailTemplateSuccessfulAction =
					{
						type: EmailTemplatesActionTypes.UPDATE_EMAIL_TEMPLATE_SUCCESSFUL,
						updatedEmailTemplate: response
					};
				return dispatch(updateEmailTemplateSuccessfulAction);
			} catch (error) {
				console.error(error);

				return Promise.reject(error);
			}
		};
	},
	fetchAllMergeTags: () => {
		return async (
			dispatch: Dispatch
		): Promise<FetchMergeTagVariablesSuccessfulAction> => {
			const fetchMergeTagVariablesRequestedAction: FetchMergeTagVariablesRequestedAction =
				{
					type: EmailTemplatesActionTypes.FETCH_MERGE_TAG_VARIABLES_REQUESTED
				};
			dispatch(fetchMergeTagVariablesRequestedAction);
			try {
				const response = await getMergeTagVariables();
				const fetchMergeTagVariablesSuccessfulAction: FetchMergeTagVariablesSuccessfulAction =
					{
						type: EmailTemplatesActionTypes.FETCH_MERGE_TAG_VARIABLES_SUCCESSFUL,
						mergeTagVariables: response
					};
				return dispatch(fetchMergeTagVariablesSuccessfulAction);
			} catch (error) {
				const fetchMergeTagVariablesFailedAction: FetchMergeTagVariablesFailedAction =
					{
						type: EmailTemplatesActionTypes.FETCH_MERGE_TAG_VARIABLES_FAILED
					};
				dispatch(fetchMergeTagVariablesFailedAction);

				return Promise.reject(error);
			}
		};
	},
	resetEmailTemplates: () => {
		return (dispatch: Dispatch): ResetEmailTemplatesListAction => {
			const resetEmailTemplatesListAction: ResetEmailTemplatesListAction =
				{
					type: EmailTemplatesActionTypes.RESET_EMAIL_TEMPLATES_LIST
				};
			return dispatch(resetEmailTemplatesListAction);
		};
	},
	resetEmailTemplateMergeTags: () => {
		return (dispatch: Dispatch): ResetEmailTemplateMergeTagsAction => {
			const resetEmailTemplateMergeTagsAction: ResetEmailTemplateMergeTagsAction =
				{
					type: EmailTemplatesActionTypes.RESET_EMAIL_TEMPLATE_MERGE_TAG_VARIABLES
				};
			return dispatch(resetEmailTemplateMergeTagsAction);
		};
	},
	fetchTemplateByType: (customTemplateType: VendorCustomTemplateType) => {
		return async (
			dispatch: Dispatch
		): Promise<FetchTemplateByCustomTemplateTypeSuccessfulAction> => {
			const fetchTemplateByCustomTemplateTypeRequestedAction: FetchTemplateByCustomTemplateTypeRequestedAction =
				{
					type: EmailTemplatesActionTypes.FETCH_EMAIL_TEMPLATE_BY_CUSTOM_TEMPLATE_TYPE_REQUESTED
				};
			dispatch(fetchTemplateByCustomTemplateTypeRequestedAction);
			try {
				const response = await fetchSystemEmailTemplateByType(
					customTemplateType
				);
				const fetchTemplateByCustomTemplateTypeSuccessfulAction: FetchTemplateByCustomTemplateTypeSuccessfulAction =
					{
						type: EmailTemplatesActionTypes.FETCH_EMAIL_TEMPLATE_BY_CUSTOM_TEMPLATE_TYPE_SUCCESSFUL,
						templates: response.data
					};
				return dispatch(
					fetchTemplateByCustomTemplateTypeSuccessfulAction
				);
			} catch (error) {
				const fetchTemplateByCustomTemplateTypeFailedAction: FetchTemplateByCustomTemplateTypeFailedAction =
					{
						type: EmailTemplatesActionTypes.FETCH_EMAIL_TEMPLATE_BY_CUSTOM_TEMPLATE_TYPE_FAILED
					};
				dispatch(fetchTemplateByCustomTemplateTypeFailedAction);

				return Promise.reject(error);
			}
		};
	}
};

export default EmailTemplatesActionCreators;
