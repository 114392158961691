import axios, { AxiosResponse } from "axios";

import {
	getRequest,
	postRequest,
	putRequest,
	deleteRequest
} from "@zomentum/utils/dist/Service/BackendService";

import { ZomentumEntities } from "@zomentum/contracts";

import {
	CustomFieldsResponse,
	CustomField,
	CustomFieldFormValues,
	ValidateDeleteCustomFieldResponse,
	CustomFieldCountPartnerAlign
} from "@zomentum/contracts/dist/Settings";

const customFieldsUrl =
	"/v2/custom/fields?entity_type={entity_type}&include_system_fields={include_system_fields}";
const deleteCustomFieldUrlAsync = "/v2/custom/fields/async/{custom_field_id}";
const addCustomFieldUrl = "/v2/custom/fields";
const editCustomFieldUrl = "/v2/custom/fields/{custom_field_id}";
const reorderCustomFieldUrl = "/v2/custom/fields/reorder";
const countCustomFieldsUrl = "/v2/custom/fields/count";
const validateDeleteCustomFieldUrl = "/v2/custom/fields/delete/validate/:id";

export const getCustomFields = async (
	entityType: ZomentumEntities,
	includeSystemFields = true
): Promise<CustomFieldsResponse> => {
	try {
		const response: AxiosResponse<CustomFieldsResponse> = await axios(
			getRequest(
				customFieldsUrl
					.replace("{entity_type}", entityType)
					.replace(
						"{include_system_fields}",
						`${includeSystemFields}`
					),
				null,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteCustomFieldAsync = async (
	customField: CustomField
): Promise<string> => {
	try {
		const response: AxiosResponse<string> = await axios(
			deleteRequest(
				deleteCustomFieldUrlAsync.replace(
					"{custom_field_id}",
					customField.id
				),
				null,
				true,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const addCustomField = async (
	addCustomFieldRequest: CustomFieldFormValues
): Promise<CustomField> => {
	try {
		const response: AxiosResponse<CustomField> = await axios(
			postRequest(
				addCustomFieldUrl,
				addCustomFieldRequest,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const editCustomField = async (
	customField: CustomFieldFormValues
): Promise<CustomField> => {
	try {
		const response: AxiosResponse<CustomField> = await axios(
			putRequest(
				editCustomFieldUrl.replace("{custom_field_id}", customField.id),
				customField,
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const reorderCustomField = async (
	reorderedCustomFieldList: CustomField[],
	entityType: ZomentumEntities
): Promise<CustomFieldsResponse> => {
	try {
		const response: AxiosResponse<CustomFieldsResponse> = await axios(
			putRequest(
				reorderCustomFieldUrl,
				{
					entity_type: entityType,
					fields: reorderedCustomFieldList.map(
						salesActivityAutomation => salesActivityAutomation.id
					)
				},
				true,
				false,
				false
			)
		);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const countCustomFields =
	async (): Promise<CustomFieldCountPartnerAlign> => {
		try {
			const response: AxiosResponse<CustomFieldCountPartnerAlign> =
				await axios(
					getRequest(countCustomFieldsUrl, null, true, false, false)
				);
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const validateDeleteCustomField = async (
	id: string
): Promise<ValidateDeleteCustomFieldResponse> => {
	try {
		const response: AxiosResponse<ValidateDeleteCustomFieldResponse> =
			await axios(
				getRequest(
					validateDeleteCustomFieldUrl.replace(":id", id),
					null,
					true,
					false,
					false
				)
			);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
